import { EditCompany } from "domain/model/Questions"
import CompanyRepository from "domain/repository/Admin/CompanyRepository"

export interface UpdateCompanyUseCase {
  invoke(editCompany: EditCompany): Promise<any>
}

export class UpdateCompany implements UpdateCompanyUseCase {
  private repository: CompanyRepository

  constructor(repository: CompanyRepository) {
    this.repository = repository
  }

  async invoke(editCompany: EditCompany) {
    return await this.repository.updateCompany(editCompany)
  }
}
