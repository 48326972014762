import { API_GET_ACTIVE_STUDENTS_OF_BATCH_DATA, API_V4_ADMIN } from "core/constants/strings"
import { getAPI } from "core/utils/axios"
import pn, { query } from "core/utils/pn"
import BatchStudentsDataSource from "data/dataSource/Admin/BatchStudentsDataSource"
import { Auth } from "domain/model/Auth"

export class BatchStudentsAPIDataSourceImpl implements BatchStudentsDataSource {
  async getAllStudentOfBatchList(auth: Auth, batch: string): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_ACTIVE_STUDENTS_OF_BATCH_DATA), {
          id_token: auth?.id_token,
          batch: batch,
        })
      )
      return response
    } catch (error) {
      return error
    }
  }
}
