export default function ErrorWarning({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 8.00004C1.3335 11.682 4.31816 14.6667 8.00016 14.6667C11.6822 14.6667 14.6668 11.682 14.6668 8.00004C14.6668 4.31804 11.6822 1.33337 8.00016 1.33337C4.31816 1.33337 1.3335 4.31804 1.3335 8.00004ZM13.3335 8.00004C13.3335 10.9456 10.9457 13.3334 8.00016 13.3334C5.05464 13.3334 2.66683 10.9456 2.66683 8.00004C2.66683 5.05452 5.05464 2.66671 8.00016 2.66671C10.9457 2.66671 13.3335 5.05452 13.3335 8.00004ZM8.66683 10V11.3334H7.3335V10H8.66683ZM8.66683 8.66671V4.66671H7.3335V8.66671H8.66683Z"
        fill="currentColor"
      />
    </svg>
  )
}
