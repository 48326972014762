import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import {
  API_GET_ALL_INSTRUCTOR_DETAILS,
  API_GET_INSTRUCTOR_DETAILS,
  API_UPDATE_INSTRUCTOR_DETAILS,
  API_UPDATE_INSTRUCTOR_COMEPNSATION_DETAILS,
  API_V4_ADMIN,
  API_GET_ALL_SESSION_OF_MONTH_YEAR,
  API_FINALISE_INVOICE_OF_A_MENTOR,
  API_GET_INSTRUCTOR_AVERAGE_RATING,
  API_UPDATE_INSTRUCTOR_INCENTIVE_DETAILS,
  API_GET_INSTRUCTOR_INCENTIVE_DETAILS,
  API_CONFIRM_INVOICE_OF_MENTOR,
  API_UPDATE_MENTOR_COMEPNSATION_DETAILS,
  API_UPDATE_MENTOR_DETAILS,
  API_GET_MENTOR_SESSION_OF_MONTH_YEAR,
  API_GET_ALL_MENTORS_LIST,
  API_GET_GROUP_MENTOR_SESSION_OF_MONTH_YEAR,
  API_REMOVE_COMPENSATION,
  API_REMOVE_MENTOR_COMPENSATION,
  API_EDIT_COMPENSATION,
  API_EDIT_MENTOR_COMPENSATION,
  API_REMOVE_INCENTIVE,
} from "core/constants/strings"
import GenerateInvoiceDataSource from "data/dataSource/Admin/GenerateInvoiceDataSource"

export default class GenerateInvoiceAPIDataSourceImpl implements GenerateInvoiceDataSource {
  async getAllInstructorDetails(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_INSTRUCTOR_DETAILS), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getInstructorDetails(auth: Auth, instructor_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_INSTRUCTOR_DETAILS), {
        id_token: auth?.id_token,
        instructor_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateInstructorDetails(auth: Auth, instructor_id: string, time_given: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_INSTRUCTOR_DETAILS), {
        id_token: auth?.id_token,
        instructor_id,
        time_given,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateInstructorCompensationDetails(
    auth: Auth,
    instructor_id: string,
    compensation_amount: number,
    year_month: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_INSTRUCTOR_COMEPNSATION_DETAILS), {
        id_token: auth?.id_token,
        instructor_id,
        compensation_amount,
        year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllSessionOfMonthYear(auth: Auth, instructor: string, year: string, month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_SESSION_OF_MONTH_YEAR), {
        id_token: auth?.id_token,
        instructor,
        year,
        month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async finaliseInvoiceOfAMentor(auth: Auth, instructor_id: string, year_month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_FINALISE_INVOICE_OF_A_MENTOR), {
        id_token: auth?.id_token,
        instructor_id,
        year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getInstructorAverageRating(auth: Auth, instructor_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_INSTRUCTOR_AVERAGE_RATING), {
        id_token: auth?.id_token,
        instructor_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateInstructorIncentiveDetails(
    auth: Auth,
    instructor_id: string,
    incentive_amount: number,
    incentive_year_month: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_INSTRUCTOR_INCENTIVE_DETAILS), {
        id_token: auth?.id_token,
        instructor_id,
        incentive_amount,
        year_month: incentive_year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getInstructorIncentiveDetails(auth: Auth, instructor_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_INSTRUCTOR_INCENTIVE_DETAILS), {
        id_token: auth?.id_token,
        instructor_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async confirmInvoiceOfMentor(auth: Auth, instructor_id: string, year_month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CONFIRM_INVOICE_OF_MENTOR), {
        id_token: auth?.id_token,
        instructor_id,
        year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateMentorCompensationDetails(
    auth: Auth,
    mentor_id: string,
    compensation_amount: number,
    year_month: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_MENTOR_COMEPNSATION_DETAILS), {
        id_token: auth?.id_token,
        mentor_id,
        compensation_amount,
        year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateMentorDetails(auth: Auth, instructor_id: string, time_given: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_MENTOR_DETAILS), {
        id_token: auth?.id_token,
        instructor_id,
        time_given,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorSessionOfMonthYear(
    auth: Auth,
    mentor_id: string,
    batch: string,
    year: string,
    month: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_SESSION_OF_MONTH_YEAR), {
        id_token: auth?.id_token,
        mentor_id,
        batch,
        year,
        month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllMentorsList(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_MENTORS_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getGroupMentorSessionOfMonthYear(
    auth: Auth,
    mentor_id: string,
    batch: string,
    year: string,
    month: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_GROUP_MENTOR_SESSION_OF_MONTH_YEAR), {
        id_token: auth?.id_token,
        mentor_id,
        batch,
        year,
        month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async removeCompensation(auth: Auth, instructor_id: string, index: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_REMOVE_COMPENSATION), {
        id_token: auth?.id_token,
        instructor_id,
        index,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async removeMentorCompensation(auth: Auth, instructor_id: string, index: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_REMOVE_MENTOR_COMPENSATION), {
        id_token: auth?.id_token,
        instructor_id,
        index,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async editMentorCompensation(auth: Auth, instructor_id: string, index: number, amount: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_EDIT_MENTOR_COMPENSATION), {
        id_token: auth?.id_token,
        instructor_id,
        index,
        amount,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async editCompensation(auth: Auth, instructor_id: string, index: number, amount: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_EDIT_COMPENSATION), {
        id_token: auth?.id_token,
        instructor_id,
        index,
        amount,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async removeIncentive(auth: Auth, instructor_id: string, incentive_year_month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_REMOVE_INCENTIVE), {
        id_token: auth?.id_token,
        instructor_id,
        yearMonth: incentive_year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
