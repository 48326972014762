import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"
import { FeedbackResponse } from "domain/model/FeedbackResponse"

export interface CreateFeedbackResponseUseCase {
  invoke(
    auth: Auth,
    session_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any>
}

export default class CreateFeedbackResponse implements CreateFeedbackResponseUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    session_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ) {
    return await this.repository.createFeedbackResponse(auth, session_id, student_email, formData, feedbackResponse)
  }
}
