import { Auth } from "domain/model/Auth"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface GetMentorsDetailListUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetMentorsDetailList implements GetMentorsDetailListUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getMentorsDetailList(auth)
  }
}
