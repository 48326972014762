import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import {
  API_CREATE_GROUP_MENTOR_SESSION,
  API_DELETE_GROUP_MENTOR_SESSION,
  API_FINALIZE_MENTOR_SESSION_INVOICING,
  API_GET_GROUP_MENTOR_SESSION,
  API_GET_MENTOR_LIST,
  API_GET_MENTOR_RATING,
  API_GET_MENTOR_SESSIONS,
  API_MENTOR_SESSION_INVOICING,
  API_UPDATE_GROUP_MENTOR_SESSION,
  API_UPDATE_MENTOR_SESSION,
  API_V4_ADMIN,
} from "core/constants/strings"
import MentorSessionsDataSource from "data/dataSource/Admin/MentorSessionsDataSource"
import { GroupMentorSession } from "domain/model/GroupMentorSession"

export default class MentorSessionsAPIDataSourceImpl implements MentorSessionsDataSource {
  async getMentorList(auth: Auth, mentor_email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_LIST), {
        id_token: auth?.id_token,
        mentor_email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorRating(auth: Auth, mentor_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_RATING), {
        id_token: auth?.id_token,
        mentor_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorSessions(auth: Auth, mentor_id: string, batch: string, month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_SESSIONS), {
        id_token: auth?.id_token,
        mentor_id,
        batch,
        month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateMentorSession(
    auth: Auth,
    session_id: string,
    status: string,
    actual_duration: number,
    considered_duration: number
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_MENTOR_SESSION), {
        id_token: auth?.id_token,
        session_id,
        status,
        actual_duration,
        considered_duration,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async mentorSessionInvoicing(auth: Auth, mentor_id: string, year_month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_MENTOR_SESSION_INVOICING), {
        id_token: auth?.id_token,
        mentor_id,
        year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async finalizeMentorSessionInvoicing(auth: Auth, mentor_id: string, year_month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_FINALIZE_MENTOR_SESSION_INVOICING), {
        id_token: auth?.id_token,
        mentor_id,
        year_month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createGroupMentorSession(auth: Auth, group_session: GroupMentorSession): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_GROUP_MENTOR_SESSION), {
        id_token: auth?.id_token,
        group_session,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateGroupMentorSession(auth: Auth, session_id: string, group_session: GroupMentorSession): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_GROUP_MENTOR_SESSION), {
        id_token: auth?.id_token,
        session_id,
        group_session,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteGroupMentorSession(auth: Auth, session_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_GROUP_MENTOR_SESSION), {
        id_token: auth?.id_token,
        session_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getGroupMentorSession(auth: Auth, mentor_id: string, batch: string, month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_GROUP_MENTOR_SESSION), {
        id_token: auth?.id_token,
        mentor_id,
        batch,
        month,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
