import { postAPI } from "core/utils/axios"
import { StoreDataSource } from "data/dataSource/Student/StoreDataSource"
import {
  API_ADD_STUDENT_ADDRESS,
  API_DELETE_STUDENT_ADDRESS,
  API_GET_STORE_ITEMS,
  API_GET_STORE_ORDER_STATUS,
  API_GET_STUDENT_STORE_ORDERS,
  API_PLACE_STORE_ORDER,
  API_STUDENT_ALL_ADDRESSES,
  API_UPDATE_STUDENT_ADDRESS,
  API_V4_STUDENT,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"

export class StoreAPIDataSourceImpl implements StoreDataSource {
  async getStoreItems(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STORE_ITEMS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentAllAddresses(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_STUDENT_ALL_ADDRESSES), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async addStudentAddress(auth: Auth, address: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_ADD_STUDENT_ADDRESS), {
        id_token: auth.id_token,
        ...address,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateStudentAddress(auth: Auth, address: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_UPDATE_STUDENT_ADDRESS), {
        id_token: auth.id_token,
        ...address,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteStudentAddress(auth: Auth, address_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_DELETE_STUDENT_ADDRESS), {
        id_token: auth.id_token,
        address_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async placeOrder(auth: Auth, product_id: any, address_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_PLACE_STORE_ORDER), {
        id_token: auth.id_token,
        product_id,
        address_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async orderHistory(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_STORE_ORDERS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async orderStatus(auth: Auth, order_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STORE_ORDER_STATUS), {
        id_token: auth.id_token,
        order_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
