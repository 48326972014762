import {
  API_BOOK_MENTOR_SESSION_COUPON,
  API_GET_EXTRA_SESSION,
  API_GET_ORDER_HISTORY,
  API_REDEEM_COINS,
  API_V4_STUDENT,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { RedeemCoinsDataSource } from "data/dataSource/Student/RedeemCoinsDataSource"
import { MentorSessionCoupon, RedeemStoreCoins } from "domain/model/RedeemCoins"

export default class RedeemCoinsAPIDataSourceImpl implements RedeemCoinsDataSource {
  async redeemCoins(redeemCoins: RedeemStoreCoins) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_REDEEM_COINS), redeemCoins)
      return response
    } catch (error) {
      return error
    }
  }

  async getOrderHistory(auth: Auth, email: string) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_ORDER_HISTORY), {
        id_token: auth.id_token,
        email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async bookMentorSessionCoupon(mentorSessionCoupon: MentorSessionCoupon) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_BOOK_MENTOR_SESSION_COUPON), mentorSessionCoupon)
      return response
    } catch (error) {
      return error
    }
  }

  async getExtraSession(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_EXTRA_SESSION), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
