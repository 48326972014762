import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import RedeemCoinsAPIDataSourceImpl from "data/API/Admin/RedeemCoinsAPIDataSourceImpl"
import { RedeemCoinsRepositoryImpl } from "data/repository/Admin/RedeemCoinsRepositoryImpl"
import CreateCoupon from "domain/useCase/Admin/RedeemCoins/CreateCoupon"
import GetAllCoupons from "domain/useCase/Admin/RedeemCoins/GetAllCoupons"
import React from "react"
import EditCoins from "domain/useCase/Admin/RedeemCoins/EditCoupon"
import DeleteCoupon from "domain/useCase/Admin/RedeemCoins/DeleteCoupon"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import AllowRedeemCoins from "domain/useCase/Admin/RedeemCoins/AllowRedeemCoins"
import GetBatchesAllowedRedeemCoins from "domain/useCase/Admin/RedeemCoins/GetBatchesAllowedRedeemCoins"

const statuses = ["Order Placed", "Dispatched ", "Delivered"]

export default function StoreViewModel() {
  const { auth } = useAuth()
  const [amount, setAmount] = React.useState<any>()
  const [id, setId] = React.useState<any>("")
  const [loading, setLoading] = React.useState<boolean>(false)
  const [couponId, setCouponId] = React.useState<string>("")
  const [couponType, setCouponType] = React.useState<string>("Amazon Coupon")
  const [purchaseDate, setPurchaseDate] = React.useState<any>()
  const [referenceId, setReferenceId] = React.useState<any>("")
  const [activeFilter, setActiveFilter] = React.useState<any>("All")
  const [coupons, setCoupons] = React.useState<any>()
  const [expiryDate, setExpiryDate] = React.useState<any>()
  const [allCoupons, setAllCoupons] = React.useState<any>()
  const [updateCoupon, setUpdateCoupon] = React.useState<any>()
  const [selectBatch, setSelectBatch] = React.useState<any>([])
  const [batch, setBatch] = React.useState([])
  const [currentSelectBatch, setCurrentSelectBatch] = React.useState("")
  const [createCoupon, setCreateCoupon] = React.useState<any>()
  const [deleteModel, setDeleteModel] = React.useState(false)
  const [editModel, setEditModel] = React.useState(false)
  const [editModalData, setEditModalData] = React.useState<any>()
  const [deleteModalData, setDeleteModalData] = React.useState<any>()
  const [reload, setReload] = React.useState<boolean>(false)
  const [updateCouponLoader, setUpdateCouponLoader] = React.useState<boolean>(false)
  const [editStatus, setEditStatus] = React.useState<any>()
  const [createCouponLoader, setCreateCouponLoader] = React.useState<boolean>(false)
  const [deleteCouponLoader, setDeleteCouponLoader] = React.useState<boolean>(false)
  const [saveBatchLoader, setSaveBatchLoader] = React.useState<boolean>(false)
  const [addCouponModal, setAddCouponModal] = React.useState<boolean>(false)
  const [activeButtonType, setActiveButtonType] = React.useState<number>(1)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getAllCouponsUseCase = new GetAllCoupons(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  const createCouponsUseCase = new CreateCoupon(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  const editCouponUseCase = new EditCoins(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  const deleteCouponUseCase = new DeleteCoupon(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  const allowRedeemCoinsUseCase = new AllowRedeemCoins(
    new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl())
  )

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getBatchesAllowedRedeemCoinsUseCase = new GetBatchesAllowedRedeemCoins(
    new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl())
  )

  function handleDeleteModel(data: any) {
    setDeleteModel(!deleteModel)
    setDeleteModalData(data)
  }

  function handleEditModel(data: any) {
    setEditModel(!editModel)
    setEditModalData(data)
  }

  function handleAmount(e: any) {
    setAmount(e.target.value)
  }

  function handleChangePurchaseDate(e: any) {
    setPurchaseDate(e.target.value)
  }

  function handleReferenceId(e: any) {
    setReferenceId(e.target.value)
  }

  function handleChangeExpiryDate(e: any) {
    setExpiryDate(e.target.value)
  }

  function handleCouponType(e: any) {
    setCouponType(e.target.value)
  }

  function handleChangeCouponId(e: any) {
    setCouponId(e.target.value)
  }

  function handleAddCouponModal() {
    setAddCouponModal(!addCouponModal)
  }

  function handleEditStatus() {
    setEditStatus(true)
  }

  function handleBatchChange(e: any) {
    const selectedBatch = e.target.value
    if (!selectBatch.includes(selectedBatch)) {
      setSelectBatch((prevSelectBatch: any) => [...prevSelectBatch, selectedBatch])
    }
    setCurrentSelectBatch(selectedBatch)
  }

  function handleActiveFilter(type: string) {
    setActiveFilter(type)
    if (type == "All") {
      setCoupons(allCoupons)
      return
    }
    let used = allCoupons.used.filter((coupon: any) => {
      // Check if the coupon amount matches the filter type
      if (type.includes("Rupees")) {
        let amount = parseInt(type) // Extract the numeric part from the type

        if (!isNaN(amount)) {
          // Filter coupons based on the 'used' and 'unused' arrays
          return coupon.amount === amount
        }
      }
      return false // Default: Filtered out
    })
    let unused = allCoupons.unused.filter((coupon: any) => {
      // Check if the coupon amount matches the filter type
      if (type.includes("Rupees")) {
        let amount = parseInt(type) // Extract the numeric part from the type

        if (!isNaN(amount)) {
          // Filter coupons based on the 'used' and 'unused' arrays
          return coupon.amount === amount
        }
      }
      return false // Default: Filtered out
    })
    let obj = { used: used, unused: unused }
    setCoupons(obj)
  }

  async function changeActiveButtonType(index: number) {
    const i = index
    setActiveButtonType(index)
  }

  async function fetchAllCoupons() {
    const response = await getAllCouponsUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
    setLoading(false)
    setAllCoupons(response?.data)
    handleActiveFilter(activeFilter)
  }

  async function fetchBatchesAllowedRedeemCoins() {
    const response = await getBatchesAllowedRedeemCoinsUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Unable to fetch Batches Allowed Coins")
      changeToastVisibility(true)
      return
    }
    setSelectBatch(response?.data)
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatch(response.data)
    }
  }

  async function updateBatchRedeemCoins() {
    setSaveBatchLoader(true)
    const response = await allowRedeemCoinsUseCase.invoke(auth, selectBatch)
    setSaveBatchLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response.error)
      changeToastVisibility(true)
      return
    }
    changeToastDetails(STR_SUCCESS, "Batches Added")
    changeToastVisibility(true)
  }

  async function AddNewCoupon() {
    setCreateCouponLoader(true)
    const response = await createCouponsUseCase.invoke({
      id_token: auth?.id_token,
      coupon_id: couponId,
      type: "Amazon Coupon",
      purchase_date: Date.parse(purchaseDate) / 1000,
      expiry_date: Date.parse(expiryDate) / 1000,
      amount: parseInt(amount),
      reference_id: referenceId,
    })
    setCreateCouponLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response.error)
      changeToastVisibility(true)
      return
    }
    setReferenceId("")
    setAmount("")
    setCouponId("")
    setCouponType("")
    setPurchaseDate("")
    setExpiryDate("")
    changeToastDetails(STR_SUCCESS, "Coupon Added")
    changeToastVisibility(true)
    handleAddCouponModal()
    setReload(!reload)
    setCreateCoupon(response?.data)
  }

  async function UpdateCoupon(
    id: string,
    amount: any,
    couponId: any,
    couponType: any,
    purchaseDate: any,
    expiryDate: any,
    referenceId: any
  ) {
    setUpdateCouponLoader(true)
    const response = await editCouponUseCase.invoke({
      id_token: auth?.id_token,
      id: id,
      coupon_id: couponId,
      type: "Amazon Coupon",
      purchase_date: Date.parse(purchaseDate) / 1000,
      expiry_date: Date.parse(expiryDate) / 1000,
      amount: amount,
      reference_id: referenceId,
    })
    setUpdateCouponLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response.error)
      changeToastVisibility(true)
      return
    }
    handleEditModel({})
    changeToastDetails(STR_SUCCESS, "Coupon Updated")
    changeToastVisibility(true)
    setReload(!reload)
    setUpdateCoupon(response?.data)
  }

  async function DeleteCouponFunc(id: string) {
    setDeleteCouponLoader(true)
    const response = await deleteCouponUseCase.invoke(auth, id)
    setDeleteCouponLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response.error)
      changeToastVisibility(true)
      return
    }
    handleDeleteModel({})
    setDeleteModel(!deleteModel)
    changeToastDetails(STR_SUCCESS, "Coupon Deleted")
    changeToastVisibility(true)
    setReload(!reload)
  }

  return {
    auth,
    toast,
    amount,
    couponType,
    purchaseDate,
    expiryDate,
    referenceId,
    couponId,
    allCoupons,
    updateCoupon,
    loading,
    activeButtonType,
    addCouponModal,
    createCouponLoader,
    editStatus,
    updateCouponLoader,
    reload,
    editModel,
    deleteModel,
    editModalData,
    deleteModalData,
    deleteCouponLoader,
    activeFilter,
    coupons,
    batch,
    currentSelectBatch,
    selectBatch,
    saveBatchLoader,
    getBatches,
    setSelectBatch,
    setCurrentSelectBatch,
    handleEditModel,
    handleDeleteModel,
    setReload,
    setReferenceId,
    setCouponId,
    setCouponType,
    setPurchaseDate,
    setExpiryDate,
    setAmount,
    handleBatchChange,
    handleActiveFilter,
    handleEditStatus,
    handleAmount,
    handleReferenceId,
    handleChangeCouponId,
    handleChangeExpiryDate,
    handleChangePurchaseDate,
    handleCouponType,
    handleAddCouponModal,
    setActiveButtonType,
    changeToastDetails,
    changeActiveButtonType,
    changeToastVisibility,
    updateBatchRedeemCoins,
    fetchBatchesAllowedRedeemCoins,
    AddNewCoupon,
    UpdateCoupon,
    DeleteCouponFunc,
    fetchAllCoupons,
  }
}
