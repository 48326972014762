import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetClassReminderDataUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export default class GetClassReminderData implements GetClassReminderDataUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: any) {
    return await this.repository.getClassReminderData(auth, id)
  }
}
