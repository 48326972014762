import DocRepository from "domain/repository/Admin/DocRepository"
import { Auth } from "domain/model/Auth"

export interface UploadDocumentUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UploadDocument implements UploadDocumentUseCase {
  private repository: DocRepository

  constructor(repository: DocRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.upload_doc(auth, data)
  }
}
