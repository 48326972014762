import { Auth } from "domain/model/Auth"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface ToggleLockMentorUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export class ToggleLockMentor implements ToggleLockMentorUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: any): Promise<any> {
    return await this.repository.toggleLockMentor(auth, id)
  }
}
