import CustomViewModel from "./CustomViewModel"
import { Button } from "core/components/v2/Button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "core/components/v2/ui/dropdown-menu"
import { useState } from "react"

export default function CommentTable(props: any) {
  const [model, setModel] = useState("")
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <CustomViewModel isOpen={model} heading={"Reason"} onClose={() => setModel("")}>
        <p>{model}</p>
      </CustomViewModel>
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {!props.studentComments?.length && !props.loading && <div className="m-2">No data found</div>}
        <tbody>
          {props.studentComments?.map((data: any, index: any) => {
            const formattedDate = new Date(data.comment_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            const formattedTime = new Date(data.comment_date).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })
            const date = new Date(data.comment_date)
            const stateDate = date.toISOString().split("T")[0]
            // const stateDate = date.toLocaleDateString("en-US")
            return (
              <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                <td className="px-6 py-4 ">
                  <p>{index + 1}</p>
                </td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                  {formattedDate} - {formattedTime}
                </td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.updated_by}</td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                  <button
                    onClick={() => {
                      setModel(data.reason)
                    }}
                    className="rounded-[4px] px-[16px] py-[5px] border-[1px] text-[14px] hover:bg-[#F9F9F9]"
                  >
                    View
                  </button>
                </td>
                <td className="relative px-6 py-4 text-[16px] font-medium text-new-accent ">
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <button className="rotate-90">...</button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white w-[304px]">
                      <DropdownMenuItem>
                        <Button
                          onClick={() => {
                            props?.setEditCommentModel(true)
                            props?.setEditCommentDetails({ ...data, comment_date: stateDate })
                          }}
                          variant={"ternary"}
                          className="w-full flex justify-start"
                        >
                          <span>Update Reason</span>
                        </Button>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Button
                          onClick={() => {
                            props?.setDeleteCommentModel(true)
                            props?.setDeleteCommentDetails({ id: data?.id })
                          }}
                          variant={"cancel"}
                          className="w-full flex justify-start"
                        >
                          <span>Delete Reason</span>
                        </Button>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
