import GetStudentListofBatch from "domain/useCase/Admin/AllClasses/getStudentListofBatch"
import GetBatchAssignment from "domain/useCase/Admin/AllClasses/getBatchAssignment"
import GetFeedbackResponse from "domain/useCase/Admin/AllClasses/GetFeedbackResponse"
import GetAllClassesAPIDataSourceImpl from "data/API/Admin/GetAllClassesAPIDataSourceImpl"
import { GetAllClassesRepositoryImpl } from "data/repository/Admin/GetAllClassesRepositoryImpl"
import { useAuth } from "core/context/auth"
import { useParams, useNavigate } from "react-router-dom"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import React from "react"
import GetSSMAndSubmissionButtonStatus from "domain/useCase/Admin/AllClasses/GetSSMAndSubmissionButtonStatus"

export default function BatchAssessmentViewModel() {
  const [studentData, setStudentData] = React.useState([])
  const [selectedBatch, setSelectedBatch] = React.useState("")
  const [classData, setClassData] = React.useState<any>([])
  const [page, setPage] = React.useState(1)
  const [totalDataList, setTotalDataList] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [feedbackResponse, setFeedbackResponse] = React.useState<any>([])
  const [session_timestamp, setSessionTimestamp] = React.useState<any>([])
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false)
  const [selectedQuestion, setSelectedQuestion] = React.useState(0)
  const [student_email, setStudentEmail] = React.useState("")
  const [buttonStatus, setButtonStatus] = React.useState<any>([])

  const pageLimit = 30

  const { id } = useParams()
  const session_id = id
  const navigate = useNavigate()

  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const handleSelectBatchClick = (item: any) => {
    setSelectedBatch(item)
  }

  const getBatchAssignmentUseCase = new GetBatchAssignment(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const getStudentListofBatchUseCase = new GetStudentListofBatch(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )
  const getFeedbackResponseUseCase = new GetFeedbackResponse(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const GetSSMAndSubmissionButtonStatusUseCase = new GetSSMAndSubmissionButtonStatus(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const order = {
    rating: "Assignment Rating",
    marks: "Assignment Marks",
    feedback_response: "SSM Feedback",
    code_snippet: "Code Snippet",
    files: "Refrences",
  }

  async function fetchSSMAndSubmissionStatus(session_id: string, student_email: string, index: number) {
    const response = await GetSSMAndSubmissionButtonStatusUseCase.invoke(auth, session_id, student_email)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.message)
      changeToastVisibility(true)
      return
    }
    setButtonStatus((prevState: any) => ({
      ...prevState,
      [index]: response?.data,
    }))
  }

  const fetchFeedbackResponse = async (student_email: string) => {
    if (!session_id) {
      changeToastDetails(STR_FAILURE, "Session ID not found")
      changeToastVisibility(true)
      return
    }
    const response = await getFeedbackResponseUseCase.invoke(auth, session_id, student_email)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Feedbaack response not found")
      changeToastVisibility(true)
      return
    }
    setFeedbackResponse(response?.data?.feedback_response)
    setSessionTimestamp(response?.data?.session_timestamp)
    changeToastDetails(STR_SUCCESS, "Feedback response found")
    changeToastVisibility(true)
  }
  const fetchClassDetails = async () => {
    if (!session_id) {
      changeToastDetails(STR_FAILURE, "Session ID not found")
      changeToastVisibility(true)
      return
    }
    const response = await getBatchAssignmentUseCase.invoke(auth, session_id)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.message)
      changeToastVisibility(true)
      return
    }
    setClassData(response?.data)
    setSelectedBatch(response.data.batches[0])
    if (response.data.batches[0] !== "") {
      fetchAllStudentList(response.data.batches[0], page, pageLimit)
    }
  }
  const fetchAllStudentList = async (batch: string, page: number, pageLimit: number) => {
    setLoading(true)
    const response = await getStudentListofBatchUseCase.invoke(auth, batch, page, pageLimit)
    if (!response?.success) {
      return
    }
    setStudentData(response?.data?.student_data)
    setTotalDataList(response?.data?.length)
    setLoading(false)
  }

  const handleNavigateClick = () => {
    navigate(`/admin/all-classes`)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    fetchAllStudentList(selectedBatch, page, pageLimit)
  }
  const handleSubmissionClick = (urlpath: string, email_id: string) => {
    // Check if email string is present in urlpath then don't append email_id
    const path = urlpath.includes(email_id) ? urlpath : `${urlpath}/${email_id}`
    navigate(path)
  }

  const handleSSMFeedback = async (student_email: string) => {
    await fetchFeedbackResponse(student_email)
    setStudentEmail(student_email)
    setOpenFeedbackModal(true)
  }
  const handleCloseBtnClick = () => {
    setOpenFeedbackModal(false)
  }

  const handleSelectedQuestionBtnClick = (number: number) => {
    setSelectedQuestion(number)
  }
  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    changeToastDetails(STR_SUCCESS, "Copied to clipboard")
    changeToastVisibility(true)
  }

  const getDate = (timestamp: number) => {
    const DateObj = new Date(timestamp)
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const month = monthNames[DateObj.getMonth()]
    return `${DateObj.getDate()} ${month} ${DateObj.getFullYear()}`
  }

  return {
    handleSelectBatchClick,
    selectedBatch,
    fetchAllStudentList,
    studentData,
    toast,
    changeToastVisibility,
    fetchClassDetails,
    handleNavigateClick,
    classData,
    page,
    handlePageChange,
    totalDataList,
    pageLimit,
    loading,
    handleSubmissionClick,
    handleSSMFeedback,
    handleCloseBtnClick,
    feedbackResponse,
    session_timestamp,
    openFeedbackModal,
    handleSelectedQuestionBtnClick,
    getDate,
    selectedQuestion,
    student_email,
    order,
    handleCopyToClipboard,
    buttonStatus,
    fetchSSMAndSubmissionStatus,
    refreshed,
  }
}
