import Spinner from "./Spinner"

type TProps = {
  xs?: boolean
  small?: boolean
  height?: string
  dark?: boolean
}

export default function Loader({ xs, small, height, dark }: TProps) {
  return (
    <div
      role="status"
      className="flex w-full items-center justify-center"
      style={{
        minHeight: height ?? "200px",
      }}
    >
      <Spinner dark={dark} small={small} xs={xs} />
      <span className="sr-only">Loading...</span>
    </div>
  )
}
