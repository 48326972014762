import { useAuth } from "core/context/auth"
import ModuleClassAPIDataSourceImpl from "data/API/Admin/ModuleClassAPIDataSourceImpl"
import { ModuleClassRepositoryImpl } from "data/repository/Admin/ModuleClassRepositoryImpl"
import { Auth } from "domain/model/Auth"
import { CreateModuleClass } from "domain/model/ModuleClass"
import { CreateModuleClasses } from "domain/useCase/Admin/ModuleClasses/CreateModuleClass"
import { DeleteModuleClasses } from "domain/useCase/Admin/ModuleClasses/DeleteModuleClass"
import { GetModuleClasses } from "domain/useCase/Admin/ModuleClasses/GetModuleClass"
import { UpdateModuleClasses } from "domain/useCase/Admin/ModuleClasses/UpdateModuleClass"
import { UpdateModuleClass } from "domain/model/ModuleClass"

import React from "react"

export default function ModuleClassViewModel() {
  const { auth } = useAuth()
  const [createModuleClass, setModuleCreatedClass] = React.useState<any>()
  const [getModuleClasses, setGetModuleClasses] = React.useState<any>()
  const [updateModuleClass, setUpdateModuleClass] = React.useState<any>()
  const [deleteModuleClass, setDeleteModuleClass] = React.useState<any>()
  const [createModuleName, setCreateModuleName] = React.useState("")
  const [createModuleBatch, setCreateModuleBatch] = React.useState("")
  const [createModuleStartDate, setCreateModuleStartDate] = React.useState("")
  const [createModuleEndDate, setCreateModuleEndDate] = React.useState("")
  const [createModuleStatus, setCreatedModuleStatus] = React.useState("")
  const [createModuleAmount, setCreatedModuleAmount] = React.useState(0)
  const [moduleLoader, setModuleloader] = React.useState(false)
  const [otherModules, setOtherModules] = React.useState("")

  function handleCreateModuleName(e: any) {
    setCreateModuleName(e.target.value)
  }

  function handleOtherModules(e: any) {
    setOtherModules(e.target.value)
  }

  function handleCreateStatus(e: any) {
    setCreatedModuleStatus(e.target.value)
  }

  function handleCreateAmount(e: any) {
    setCreatedModuleAmount(e.target.value)
  }

  function handleCreateBatch(e: any) {
    setCreateModuleBatch(e.target.value)
  }

  function handleCreateStartDate(e: any) {
    setCreateModuleStartDate(e.target.value)
  }

  function handleCreateEndDate(e: any) {
    setCreateModuleEndDate(e.target.value)
  }

  const createModuleClassesUseCase = new CreateModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )

  const deleteModuleClassesUseCase = new DeleteModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )

  const getModuleClassesUseCase = new GetModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )

  const updateModuleClassesUseCase = new UpdateModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )

  const fetchModuleClasses = async (auth: Auth, instructor_id: string) => {
    const response = await getModuleClassesUseCase.invoke(auth, instructor_id)
    if (!response?.success) {
      return
    }

    setGetModuleClasses(response?.data)
  }

  const fetchCreatedModuleClass = async (instructor_id: string) => {
    if (createModuleName == "other") {
      setCreateModuleName(otherModules)
    }
    const batches: any[] = []
    batches.push(createModuleBatch)
    const createModuleClass: CreateModuleClass = {
      auth,
      instructor_id: instructor_id,
      module: createModuleName,
      batch: batches,
      start_date: createModuleStartDate as string,
      end_date: createModuleEndDate as string,
      status: createModuleStatus,
      amount: createModuleAmount,
    }
    const response = await createModuleClassesUseCase.invoke(createModuleClass)
    if (!response?.success) {
      return
    }

    setModuleCreatedClass(response?.data)
  }

  const fetchUpdateModuleClass = async (updateModuleClass: UpdateModuleClass) => {
    const response = await updateModuleClassesUseCase.invoke(updateModuleClass)

    if (!response?.success) {
      return
    }

    setUpdateModuleClass(response?.data)
  }

  const fetchDeleteModuleClass = async (auth: Auth, module_id: string) => {
    const response = await deleteModuleClassesUseCase.invoke(auth, module_id)
    if (!response?.success) {
      return
    }

    setDeleteModuleClass(response?.data)
  }

  return {
    auth,
    otherModules,
    getModuleClasses,
    createModuleClass,
    updateModuleClass,
    deleteModuleClass,
    createModuleAmount,
    createModuleBatch,
    createModuleEndDate,
    createModuleStartDate,
    createModuleStatus,
    createModuleName,
    moduleLoader,
    setCreateModuleName,
    setModuleloader,
    handleOtherModules,
    handleCreateAmount,
    handleCreateBatch,
    handleCreateModuleName,
    handleCreateStatus,
    handleCreateEndDate,
    handleCreateStartDate,
    fetchModuleClasses,
    fetchCreatedModuleClass,
    fetchDeleteModuleClass,
    fetchUpdateModuleClass,
  }
}
