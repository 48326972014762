import { Auth } from "domain/model/Auth"
import { RedeemCoinsRepository } from "domain/repository/Admin/RedeemCoinsRepository"

export interface DeleteCouponUseCase {
  invoke(auth: Auth, id: string): Promise<any>
}

export default class DeleteCoupon implements DeleteCouponUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string) {
    return this.repository.deleteCoupon(auth, id)
  }
}
