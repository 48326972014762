import React, { useEffect } from "react"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import Button from "core/components/new/Button"
import { useLocation } from "react-router-dom"
import { format } from "date-fns"
const FeedbackQuestion = ({ question, maxMarks, feedbackResponses }: any) => {
  let title = question

  const formattedQuestion = question.replace(/\s+/g, "_")
  const feedback = feedbackResponses[formattedQuestion]
  let subtitle = ""
  if (feedback.type === "Case Study") {
    title = feedback.questionTitle || question
    subtitle = feedback.subTitle || ""
  } else if (feedback?.type === "MCQs" || feedback?.type === "Subjective") {
    title = feedback?.questionTitle || question
  }

  return (
    <div className="w-4/5 flex flex-col flex-wrap">
      <h3 className="flex text-base font-semibold w-full text-new-accent capitalize">
        {subtitle ? `${title} - ${subtitle}` : title}
      </h3>
      <div className="flex items-baseline">
        <p className=" text-xs font-[500]">Max Marks: </p>
        <p className=" text-xs pl-2 ml-1">{maxMarks === 0 ? "10" : maxMarks}</p>
      </div>
    </div>
  )
}

const FeedbackModal = (props: any) => {
  const {
    selectedQuestion,
    feedback_response,
    onClose,
    handleQuestionTypeClick,
    handleMainQuestionClick,
    selectedQuestionType,
    selectedMainQuestion,
    handleSelectedQuestionBtnClick,
  } = props
  const location = useLocation()
  const getMainQuestionLabel = (index: number) => `CS${index + 1}`
  useEffect(() => {
    if (selectedQuestionType === "Coding" || selectedQuestionType === "MCQs" || selectedQuestionType === "Subjective") {
      const firstKey = Object.keys(feedback_response).find(
        (key) => feedback_response[key].type === selectedQuestionType
      )
      if (firstKey) handleSelectedQuestionBtnClick(firstKey)
    } else if (selectedQuestionType === "Case Study") {
      const firstMainKey = Object.keys(feedback_response)
        .filter((key) => feedback_response[key].type === "Case Study")
        .map((key) => key.split("_")[0])
        .filter((value, index, self) => self.indexOf(value) === index)[0]

      if (firstMainKey && selectedMainQuestion !== firstMainKey) {
        handleMainQuestionClick(firstMainKey)
      }
    }
  }, [selectedQuestionType, feedback_response])

  // useEffect(() => {
  //   if (selectedQuestionType === "Case Study" && selectedMainQuestion) {
  //     const firstSubKey = Object.keys(feedback_response).find((key) =>
  //       key.startsWith(`${selectedMainQuestion}_`)
  //     )
  //     if (firstSubKey) handleSelectedQuestionBtnClick(firstSubKey)
  //   }
  // }, [selectedMainQuestion, selectedQuestionType, feedback_response])
  useEffect(() => {
    if (selectedQuestionType === "Case Study" && selectedMainQuestion) {
      const firstSubKey = Object.keys(feedback_response)
        .filter((key) => key.startsWith(`${selectedMainQuestion}_`))
        .sort((a, b) => (feedback_response[a]?.order || 0) - (feedback_response[b]?.order || 0))[0]

      if (firstSubKey) handleSelectedQuestionBtnClick(firstSubKey)
    }
  }, [selectedMainQuestion, selectedQuestionType, feedback_response])

  const sortedFeedbackResponse = Object.fromEntries(
    Object.entries(feedback_response).sort((a, b) => {
      const orderA = (a[1] as { order: number }).order || 0
      const orderB = (b[1] as { order: number }).order || 0

      return orderA - orderB
    })
  )
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[#999999] bg-opacity-50 z-50">
      <div className={`relative flex w-[824px] h-[520px] bg-[#FFF] p-4 m-2 rounded-lg`}>
        {/* Left side */}
        <div className="overflow-auto h-full custom-scrollbar-2">
          <div className="w-[245px] space-y-4 px-2 py-3">
            <div>
              <h3 className="text-lg text-[#312E81] font-semibold">Submission Feedback</h3>
              <p className=" text-xs text-[#414141] font-normal">
                {format(parseInt(props.contest_timestamp) * 1000, "do MMMM, yyyy")}
              </p>
            </div>

            <div className="flex flex-wrap gap-2 w-full">
              {["Coding", "MCQs", "Subjective", "Case Study"].map((type) => (
                <button
                  key={type}
                  className={`flex items-center justify-center w-[100px] h-[40px] font-semibold px-6 py-2 text-sm rounded-[4px] ${selectedQuestionType === type ? "text-white bg-new-accent" : "text-black bg-[#FAFAFA]"}`}
                  onClick={() => handleQuestionTypeClick(type)}
                >
                  {type}
                </button>
              ))}
            </div>

            <div className="flex">
              {selectedQuestionType === "Case Study"
                ? feedback_response &&
                  Object.keys(feedback_response)
                    .filter((key) => feedback_response[key].type === "Case Study")
                    .map((key) => key.split("_")[0])
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .map((mainId, index) => (
                      <div key={mainId}>
                        <div className="flex gap-2 mt-2 flex-wrap">
                          <button
                            className={`flex items-center justify-center w-[60px] h-[40px] font-semibold px-6 py-2 text-sm rounded-[4px] ${selectedMainQuestion === mainId ? "text-white bg-new-accent" : "text-black bg-[#FAFAFA]"}`}
                            onClick={() => handleMainQuestionClick(mainId)}
                          >
                            {getMainQuestionLabel(index)}
                          </button>
                        </div>
                      </div>
                    ))
                : Object.keys(feedback_response)
                    .filter((key) => feedback_response[key].type === selectedQuestionType)
                    .map((key, index) => (
                      <button
                        key={key}
                        className={`flex items-center justify-center w-[60px] h-[40px] font-semibold px-6 py-2 text-sm rounded-[4px] ${selectedQuestion === key ? "text-white bg-new-accent" : "text-black bg-[#FAFAFA]"}`}
                        onClick={() => handleSelectedQuestionBtnClick(key)}
                      >
                        {index + 1}
                      </button>
                    ))}
            </div>
            {/* Render Subquestions below the main question buttons */}
            {selectedMainQuestion && (
              <div className="flex flex-wrap gap-2 mt-4">
                {Object.keys(feedback_response)
                  .filter((key) => key.startsWith(`${selectedMainQuestion}_`))
                  .sort((a, b) => (feedback_response[a]?.order || 0) - (feedback_response[b]?.order || 0))
                  .map((subKey, subIndex) => (
                    <button
                      key={subIndex}
                      className={`flex items-center justify-center w-[60px] h-[40px] font-semibold px-6 py-2 text-sm rounded-[4px] ${selectedQuestion === subKey ? "text-white bg-new-accent" : "text-black bg-[#FAFAFA]"}`}
                      onClick={() => handleSelectedQuestionBtnClick(subKey)}
                    >
                      {subIndex + 1}
                    </button>
                  ))}
              </div>
            )}

            <Button
              outlined
              className="w-fit"
              onClick={() => props.handleSubmissionClick(location.pathname, props.student_email)}
            >
              View Submissions
            </Button>
          </div>
        </div>
        {/* Right side */}
        <div className="flex flex-col space-y-4 w-[579px] p-3 border-l-2 overflow-auto h-full custom-scrollbar-2">
          {/* Top part */}
          <div className="flex justify-between">
            <div className="space-y-2 w-full">
              {/* {Object.keys(feedback_response).map((key) => {
                if (selectedQuestion === key) {
                  return (
                    <FeedbackQuestion
                      key={key}
                      question={key.split("_").join(" ")}
                      maxMarks={feedback_response[key]?.max_marks}
                      feedbackResponses={feedback_response}
                    />
                  )
                }
                return null
              })} */}
              {Object.keys(sortedFeedbackResponse).map((key) => {
                if (selectedQuestion === key) {
                  return (
                    <FeedbackQuestion
                      key={key}
                      question={key.split("_").join(" ")}
                      maxMarks={(sortedFeedbackResponse[key] as any)?.max_marks}
                      feedbackResponses={sortedFeedbackResponse}
                    />
                  )
                }
                return null
              })}
            </div>
            <div className="relative top-2 right-6">
              <button className="fixed" onClick={onClose}>
                <CloseSharpIcon className="hover:text-red-500" />
              </button>
            </div>
          </div>
          {/* Bottom part */}
          <div className="h-full overflow-y-auto my-4 custom-scrollbar-2">
            <div className="space-y-2 pb-2">
              {Object.keys(feedback_response).map((key) => {
                if (selectedQuestion === key) {
                  const feedbackData = feedback_response[key]

                  return Object.keys(props.order).map((key2) => {
                    const fieldValue = feedbackData[key2]
                    if (fieldValue === undefined || fieldValue === null) return null

                    const keyWidth = "w-1/3"
                    const valueWidth = key2 === "code_snippet" ? "w-2/3 overflow-x-auto" : "w-2/3"

                    return (
                      <div key={key2} className={`flex`}>
                        {(key2 !== "code_snippet" ||
                          (key2 === "code_snippet" && feedbackData["code_snippet"]?.length > 0)) && (
                          <p className={`${keyWidth} text-xs font-normal text-[#414141]`}>{props.order[key2]}:</p>
                        )}
                        {key2 === "code_snippet" && feedbackData["code_snippet"].length > 0 && (
                          <pre
                            className={`${valueWidth} text-sm text-[#414141] bg-[#F7F7F7] rounded-[4px] px-3 py-[6px]`}
                            dangerouslySetInnerHTML={{ __html: fieldValue }}
                          ></pre>
                        )}
                        {key2 === "rating" && (
                          <div className={`${valueWidth} pl-2 text-[#FBBF24] text-sm font-semibold`}>
                            {fieldValue} / 5
                          </div>
                        )}
                        {key2 === "marks" && (
                          <div className={`${valueWidth} pl-2 text-sm text-[#414141] font-semibold`}>
                            {fieldValue} /{" "}
                            {feedback_response[key]?.max_marks === 0 ? "10" : feedback_response[key]?.max_marks}
                          </div>
                        )}
                        {key2 === "feedback_response" && (
                          <div
                            className={`${valueWidth} h-[150px] text-sm font-semibold text-[#414141] bg-[#F7F7F7] px-3 py-[6px] rounded-[4px] break-words`}
                          >
                            {fieldValue}
                          </div>
                        )}
                        {key2 === "files" && Array.isArray(fieldValue) && fieldValue.length > 0 && (
                          <div className={`${valueWidth} bg-[#F7F7F7] p-2 rounded`}>
                            <ul className="list-disc list-inside space-y-2">
                              {fieldValue.map((file, idx) => (
                                <li key={idx}>
                                  <a
                                    className="px-2 font-semibold text-sm underline text-blue-500"
                                    href={file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Reference {idx + 1}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )
                  })
                }
                return null
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackModal
