import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import SessionsCard from "./components/SessionsCard"
import GenerateInvoiceViewModel from "./GenerateMentorInvoiceViewModel"
import { isEmpty } from "core/utils/misc"
import { useParams } from "react-router-dom"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import { STR_SUCCESS } from "core/constants/strings"
import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import Modal from "core/components/Modal"
import ButtonGroup from "core/components/ButtonGroup"
import GroupMentorSessionCard from "./components/GroupMentorSessionCard"
import CreateGroupSessionForm from "./components/CreateGroupSessionForm"
import Model from "core/components/Model"
import GenerateMentorInvoiceViewModel from "./GenerateMentorInvoiceViewModel"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"

export default function MonthSessionsView() {
  const {
    auth,
    allSessionMonthYear,
    batch,
    getBatchList,
    confirmInvoice,
    groupSessionMonthYear,
    getBatches,
    setBatch,
    confirmInvoiceOfMentor,
    fetchInvoiceOfMentor,
    fetchGroupMentorSessionMonthyear,
    fetchMentorSessionMonthyear,
    setAllSessionMonthYear,
  } = GenerateInvoiceViewModel()
  const { fetchUpdatedMentorDetails } = GenerateMentorInvoiceViewModel()
  const { id, yearMonth } = useParams()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [loading, setLoading] = React.useState<boolean>(false)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const [apiData, setData] = React.useState<any>()
  const [reloadData, setReloadData] = React.useState(false)
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false)
  const [isCreateGroupSessionOpen, setIsCreateGroupSessionOpen] = React.useState<boolean>(false)
  const yearMonthString = yearMonth as string
  const year = yearMonthString.slice(0, 4)
  const month = yearMonthString.slice(4)
  function handleSearchByBatch(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }
  async function handleConfirmModal() {
    await setConfirmModal(!confirmModal)
    if (confirmModal == true) {
      await confirmInvoiceOfMentor(auth, id as string, yearMonth as string)
    }
  }
  async function changeActiveButtonType(index: number) {
    const i = index
    setActiveButtonType(index)
  }

  function handleCreateGroupSession() {
    setIsCreateGroupSessionOpen(!isCreateGroupSessionOpen)
  }

  async function handleBatchSearch() {
    setLoading(true)
    await fetchMentorSessionMonthyear(auth, id as string, batch, year, months[parseInt(month, 10) - 1])
    setLoading(false)
  }
  async function previousData() {
    const rspns = await fetchUpdatedMentorDetails(auth, id as string, yearMonth as string)
    setData(rspns)
  }
  async function handleInvoiceSubmit() {
    const value = await fetchInvoiceOfMentor(auth, id as string, yearMonth as string)
    changeToastDetails(STR_SUCCESS, `Invoice Generation ${value}`)
    changeToastVisibility(true)
  }
  const [monthName, setMonthName] = React.useState<string>("")
  React.useEffect(() => {
    setMonthName(months[parseInt(month, 10) - 1])
    fetchMentorSessionMonthyear(auth, id as string, batch, year, months[parseInt(month, 10) - 1])
    fetchGroupMentorSessionMonthyear(auth, id as string, batch, year, months[parseInt(month, 10) - 1])
    previousData()
    getBatches()
  }, [id, yearMonth, reloadData])
  return (
    <DashboardLayout>
      <div className="flex flex-col gap-y-6">
        <div className="">
          <h1>{`${monthName} ${(yearMonth as string).slice(0, 4)}  All Sessions`}</h1>
        </div>
        <div className="flex">
          <StandaredSelect
            required
            id="Batch"
            className="mx-4 w-[209px]"
            options={getBatchList}
            placeholder="Select Batch"
            onChange={handleSearchByBatch}
            value={batch}
          />
          <Button loading={loading} className="h-[37px] w-fit" onClick={handleBatchSearch}>
            Search
          </Button>
        </div>
        <div className="mt-3 px-5">
          <ButtonGroup
            buttons={["Mentor Sessions", "Group Mentor Sessions"]}
            active={activeButtonType}
            onChange={changeActiveButtonType}
          />
        </div>
        {activeButtonType === 0 ? (
          isEmpty(allSessionMonthYear) ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mx-4 my-4 flex flex-col space-y-4">
              <div className="space-y-4">
                {!isEmpty(allSessionMonthYear?.mentor_sessions) ? (
                  allSessionMonthYear?.mentor_sessions?.map((data: any, i: number) => (
                    <SessionsCard
                      key={i}
                      index={i}
                      data={data}
                      reloadData={reloadData}
                      setReloadData={setReloadData}
                      changeToastDetails={changeToastDetails}
                      changeToastVisibility={changeToastVisibility}
                      onChange={setAllSessionMonthYear}
                      sessions={allSessionMonthYear}
                    />
                  ))
                ) : (
                  <div>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
            </div>
          )
        ) : activeButtonType === 1 ? (
          isEmpty(groupSessionMonthYear) ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mx-4 my-4 flex flex-col space-y-4">
              <div className="space-y-4">
                <Button className="h-[37px] w-fit" onClick={handleCreateGroupSession}>
                  Add Group Session
                </Button>
                {!isEmpty(groupSessionMonthYear?.group_mentor_sessions) ? (
                  groupSessionMonthYear?.group_mentor_sessions?.map((data: any, i: number) => (
                    <GroupMentorSessionCard
                      reloadData={reloadData}
                      setReloadData={setReloadData}
                      changeToastDetails={changeToastDetails}
                      changeToastVisibility={changeToastVisibility}
                      key={i}
                      data={data}
                    />
                  ))
                ) : (
                  <div>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <div>
            <Loader />
          </div>
        )}
        {apiData?.monthly_data.invoice_id != "" && (
          <div>
            <p className="">Do you want to Generate Invoice Again </p>
            <h2>Initial Amount={apiData?.monthly_data.payable_amount}</h2>
          </div>
        )}
        <div className="justify-end">
          <div className="max flex w-[609px] justify-between">
            <div className="">
              <p>
                <strong>Generate Invoice For The Month</strong>
              </p>
            </div>
            <Button className="max w-[214px]" onClick={handleConfirmModal}>
              Generate Invoice
            </Button>
          </div>
        </div>
      </div>
      <Modal open={confirmModal} onClose={handleConfirmModal}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Generate Invoice</h3>
            <p>Are you sure you want to generate invoice for the month {monthName}</p>
            <p>Group Mentor Session Rs {confirmInvoice?.group_session_total}</p>
            <p>Mentor Session Rs {confirmInvoice?.mentor_session_amount}</p>
            <p>Total Amount Rs {confirmInvoice?.total_amount}</p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={handleConfirmModal}>
              No
            </Button>
            <Button outlined onClick={handleInvoiceSubmit}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Model isOpen={isCreateGroupSessionOpen} onClose={handleCreateGroupSession}>
        <CreateGroupSessionForm reloadData={reloadData} setReloadData={setReloadData} />
      </Model>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
