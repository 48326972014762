const CaseStudy = ({ questions, selectedQuestion, activeTabContent }: any) => {
  return (
    <>
      <div className=" bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 p-2 rounded">
        {activeTabContent === 0 ? (
          <div>
            {questions
              ?.sort((a: any, b: any) => a.order - b.order) // Ensure questions are sorted by order
              .map((question: any, idx: any) =>
                question.order === selectedQuestion ? (
                  <div key={idx} className="mb-4">
                    <h4 className="text-sm font-semibold mb-2">{question.subTitle}</h4>
                    <p className="mb-2 whitespace-pre-wrap text-sm">{question.content}</p>
                    {question.questionType === "MCQ" && (
                      <div>
                        {Object.keys(question.options).map((key) => (
                          <div key={key} className="flex items-baseline py-2">
                            <span className="text-new-neutral-light mr-2">
                              {String.fromCharCode(65 + parseInt(key))}.{" "}
                            </span>
                            {question.options[key][0].type === "code" && (
                              <pre className="bg-gray-200 rounded-md">{question.options[key][0].content}</pre>
                            )}
                            {question.options[key][0].type === "image" && (
                              <img
                                src={question.options[key][0].content}
                                alt={`Option ${String.fromCharCode(65 + parseInt(key))}`}
                                className="max-w-full h-auto rounded-md"
                              />
                            )}
                            {question.options[key][0].type === "text" && (
                              <p className="text-new-neutral-light">{question.options[key][0].content}</p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : null
              )}
          </div>
        ) : (
          <div className="flex items-baseline flex-col">
            <h4 className="py-1 text-new-neutral-light">Solution : </h4>
            {questions[selectedQuestion]?.questionType === "MCQ" ? (
              Object.keys(questions[selectedQuestion]?.solution)?.map((key: string, idx: number) => {
                const value = questions[selectedQuestion]?.solution[key]
                // if value is true then display it
                if (value === "true") {
                  return (
                    <div key={key} className="flex items-baseline py-1">
                      <span className="text-new-neutral-light mr-2">{String.fromCharCode(65 + parseInt(key))}. </span>
                      {questions[selectedQuestion]?.options[key][0]?.type === "code" && (
                        <pre className="bg-gray-200 rounded-md">
                          {questions[selectedQuestion]?.options[key][0]?.content}
                        </pre>
                      )}
                      {questions[selectedQuestion]?.options[key][0]?.type === "image" && (
                        <img
                          src={questions[selectedQuestion]?.options[key][0]?.content}
                          alt={`Option ${String.fromCharCode(65 + parseInt(key))}`}
                          className="max-w-full h-auto rounded-md"
                        />
                      )}
                      {questions[selectedQuestion]?.options[key][0]?.type === "text" && (
                        <p className="text-new-neutral-light">
                          {questions[selectedQuestion]?.options[key][0]?.content}
                        </p>
                      )}
                    </div>
                  )
                }
                return null // If value is false or undefined, don't render anything
              })
            ) : (
              <p className="text-new-neutral-light">{questions[selectedQuestion]?.solution}</p>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CaseStudy
