import Button from "core/components/new/Button"
import useResumeViewModel from "./ResumeViewModel"
import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import { Link } from "react-router-dom"

export default function ResumeView() {
  const {
    toast,
    uploadedResume,
    resumeURL,
    isUploading,
    handleSubmitResume,
    handleCancel,
    handleChangeResume,
    changeToastVisibility,
  } = useResumeViewModel()

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-2">
          <h2 className="text-new-accent">Complete your user profile!</h2>
          <p className="text-new-neutral-dark">
            You need to complete your user profile in order to start with the placement cell.
          </p>
        </div>
        <div className="flex flex-col items-center gap-6 rounded p-6 shadow">
          <div className="flex gap-6">
            <div className="flex flex-1 flex-col gap-6 rounded bg-primary-100 p-6 text-new-accent">
              <div className="flex h-[196px] rounded-sm bg-new-solid-white">
                {resumeURL ? (
                  <object data={resumeURL} type="application/pdf" width="100%" height="100%">
                    <p>
                      Alternative text - include a link{" "}
                      <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a>
                    </p>
                  </object>
                ) : (
                  <div className="m-auto">No resume selected</div>
                )}
              </div>
              <div className="space-y-2">
                <h3>Upload Resume</h3>
                <p>
                  Upload your built resume and our team will provide helpful feedback on how you can improve your
                  resume.
                </p>
              </div>
              <div className="mt-auto">
                <label
                  htmlFor="upload-resume"
                  className="block w-fit rounded-sm border border-new-accent bg-new-solid-white px-4 py-2 text-sm font-medium leading-4 text-new-accent hover:bg-new-accent hover:text-new-solid-white"
                >
                  {uploadedResume?.name || "Upload"}
                  <input
                    type="file"
                    id="upload-resume"
                    className="hidden"
                    accept="application/pdf"
                    onChange={handleChangeResume}
                  />
                </label>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-6 rounded bg-primary-100 p-6 text-new-accent">
              <div className="h-[196px] rounded-sm bg-new-solid-white"></div>
              <div className="space-y-2">
                <h3>Build Resume</h3>
                <p>Start building you resume from scratch and stand out of the crowd.</p>
              </div>
              <Link to="create" className="mt-auto w-fit font-semibold">
                <Button className="w-fit" outlined>
                  Start Creating
                </Button>
              </Link>
            </div>
          </div>
          <div className="flex w-full justify-center gap-4">
            <Button outlined onClick={handleCancel} className="w-40">
              Cancel
            </Button>
            <Button small className="w-40" onClick={handleSubmitResume} loading={isUploading} disabled={isUploading}>
              Save Resume
            </Button>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
