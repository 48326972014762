import Toggle from "core/components/Toggle"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import ConfirmationPopUpDeleteStudent from "../StudentDashboard/components/ConfirmationPopUpDeleteStudent"
import { capitalize } from "core/utils/string"
import { STR_PAID, STR_TEXT_FIELD } from "core/constants/strings"
import { InfoIcon } from "lucide-react"
import { cn } from "core/lib/utils"
import Spinner from "core/components/Spinner"
import IconButton from "core/components/IconButton"
import { Link } from "react-router-dom"
import useCompanyViewModel from "./CompanyViewModel"
import Input from "core/components/Input"
import Button from "core/components/Button"
import { format } from "date-fns"
import Avatar from "react-avatar"
import { useAuth } from "core/context/auth"
import Modal from "core/components/Modal"
import Toast from "core/components/Toast"

export default function CompanyView() {
  const {
    toast,
    name,
    editName,
    imgSrc,
    companyFilter,
    editImgSrc,
    setEditName,
    setEditImgSrc,
    setId,
    setName,
    setCompanyFilter,
    openEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    setOpenEditModal,
    createLoader,
    editLoader,
    deleteLoader,
    handleEditName,
    handleName,
    handleImgSrc,
    handleEditImgSrc,
    createCompanyResponse,
    getCompanyResponse,
    fetchAllCompanies,
    handleDeleteCompany,
    handleCompanyFilter,
    handleCreateCompany,
    handleUpdateCompany,
    changeToastVisibility,
  } = useCompanyViewModel()
  const { auth } = useAuth()

  React.useEffect(() => {
    fetchAllCompanies()
  }, [auth, createCompanyResponse])
  const tableHeaders = ["Serial No.", "Company", "Logo", "Edit", "Delete"]
  return (
    <DashboardLayout>
      {" "}
      <div className="relative mx-4 overflow-x-auto rounded-md shadow-md">
        <div className="flex justify-center py-[50px]">
          <div className="round-2 flex w-2/4 flex-col items-center p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
            <form className="mb-4 w-full flex flex-col gap-y-3" id="main-form" onSubmit={handleCreateCompany}>
              <Input
                name="Name Of Company"
                type={STR_TEXT_FIELD}
                value={name}
                onChange={handleName}
                placeholder="Company Name"
                className="mb-4"
                required
              />
              <div className="flex flex-col gap-2">
                <h4 className="text-sm">Company Logo</h4>
                <div className="flex gap-3">
                  {imgSrc && (
                    <div className="w-20 rounded-xl">
                      <img src={imgSrc} alt="Company Logo" width={100} height={100} className="w-full rounded-lg" />
                    </div>
                  )}
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                    onChange={handleImgSrc}
                  />
                </div>
              </div>
              <Button
                className={imgSrc != "" && name != "" ? "bg-[#2246C3] text-new-solid-white" : ""}
                isLoading={createLoader}
              >
                Add Company
              </Button>
            </form>
          </div>
        </div>
        <div>
          <Input
            name="Company Filter"
            value={companyFilter}
            onChange={handleCompanyFilter}
            placeholder="Search Company Name"
            className="w-[400px] mx-3"
          />
          <div className="relative my-4 overflow-x-auto rounded-md shadow-md">
            <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
              <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
                <tr>
                  {tableHeaders?.map((title: any, index: any) => (
                    <th key={index} className="px-4 py-4 text-[16px] font-medium  ">
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getCompanyResponse?.length > 0 ? (
                  getCompanyResponse?.map((data: any, index: any) => (
                    <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                      <td className="px-6 py-4 text-new-solid-black">{index + 1}</td>
                      <td className="px-6 py-4 text-new-solid-black">{data?.name}</td>
                      <td className="px-6 py-4">
                        <Avatar className="py-1" name={data.img_src} src={data.img_src} round size="60" />
                      </td>
                      <td className="px-6 py-4 text-[14px] ">
                        <IconButton
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                          }
                          onClick={() => {
                            setOpenEditModal(true)
                            setEditName(data.name)
                            setEditImgSrc(data.img_src)
                            setId(data.id)
                          }}
                        />
                      </td>
                      <td className="px-6 py-4 text-[14px] ">
                        <IconButton
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="black"
                              className="h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          }
                          onClick={() => {
                            setId(data.id)
                            setOpenDeleteModal(true)
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={tableHeaders?.length} className="py-2 text-center font-medium leading-6 text-gray-500">
                      No Company Added yet!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} loading={deleteLoader}>
          <form className="mb-4 w-full flex flex-col gap-y-3 my-5" id="main-form" onSubmit={handleUpdateCompany}>
            <Input
              name="Name Of Company"
              type={STR_TEXT_FIELD}
              value={editName}
              onChange={handleEditName}
              placeholder="Name"
              className="mb-4"
              required
            />
            <div className="flex flex-col gap-2">
              <h4 className="text-sm">Company Logo</h4>
              <div className="flex gap-3">
                {imgSrc && (
                  <div className="w-20 rounded-xl">
                    <img src={editImgSrc} alt="Company Logo" width={100} height={100} className="w-full rounded-lg" />
                  </div>
                )}
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                  onChange={handleEditImgSrc}
                />
              </div>
            </div>
            <Button
              className={editName != "" && editImgSrc != "" ? "bg-[#2246C3] text-new-solid-white" : ""}
              isLoading={editLoader}
            >
              Edit Company
            </Button>
          </form>
        </Modal>
        <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} loading={deleteLoader}>
          <div className="flex flex-col gap-4 my-2">
            <div className="space-y-2">
              <h3 className="text-new-accent">Delete Company</h3>
              <p>Are you sure you want to delete this company?</p>
            </div>
            <div className="flex items-center gap-2 self-end">
              <Button
                className="bg-new-failure text-new-solid-white hover:bg-new-solid-red-dark"
                outlined
                failure
                onClick={() => setOpenDeleteModal(false)}
              >
                No
              </Button>
              <Button
                className="bg-new-success text-new-solid-white hover:bg-new-solid-green-dark"
                outlined
                onClick={handleDeleteCompany}
                isLoading={deleteLoader}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </div>
    </DashboardLayout>
  )
}
