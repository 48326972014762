import React from "react"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import Button from "core/components/new/Button"
import { useLocation } from "react-router-dom"
import { format } from "date-fns"
// FeedbackQuestion Component to display each question in the feedback modal
const FeedbackQuestion = ({ question, maxMarks }: any) => (
  <div className="w-4/5 flex flex-col flex-wrap">
    <h3 className="flex text-base font-semibold w-full text-new-accent capitalize">{question}</h3>
    <div className="flex items-baseline">
      <p className=" text-xs font-[500]">Max Marks: </p>
      <p className=" text-xs pl-2 ml-1">{maxMarks === 0 ? "10" : maxMarks}</p>
    </div>
  </div>
)

const FeedbackModal = (props: any) => {
  const { selectedQuestion, feedback_response, onClose } = props
  const location = useLocation()
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[#999999] bg-opacity-50 z-50">
      <div className={`relative flex w-[824px] h-[520px] bg-[#FFF] p-4 m-2 rounded-lg`}>
        {/* Left side */}
        <div className="w-[245px] space-y-4 px-2 py-3">
          <div>
            <h3 className="text-lg text-[#312E81] font-semibold">Submission Feedback</h3>
            <p className=" text-xs text-[#414141] font-normal">
              {format(parseInt(props.session_timestamp) * 1000, "do MMMM, yyyy")}
            </p>
          </div>
          <div className="flex flex-wrap gap-2 w-full">
            {[...Array(Object.keys(feedback_response).length)].map((_, index) => (
              <button
                key={index}
                className={`flex items-center justify-center w-[60px] h-[40px] font-semibold px-6 py-2 text-sm   rounded-[4px]  ${selectedQuestion === index ? "text-white bg-new-accent" : "text-black bg-[#FAFAFA]"}`}
                onClick={() => props.handleSelectedQuestionBtnClick(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <Button
            outlined
            className="w-fit"
            onClick={() => props.handleSubmissionClick(location.pathname, props.student_email)}
          >
            View Submissions
          </Button>
        </div>
        {/* Right side */}
        <div className="flex flex-col  space-y-4 w-[579px] p-3 border-l-2 overflow-auto h-full custom-scrollbar-2">
          {/* Top part */}
          <div className="flex justify-between">
            <div className="space-y-2 w-full">
              {Object.keys(feedback_response).map((key, index) => {
                if (selectedQuestion === index) {
                  return (
                    <FeedbackQuestion
                      key={index}
                      question={key.split("_").join(" ")}
                      maxMarks={feedback_response[key]?.max_marks}
                    />
                  )
                }
                return null
              })}
            </div>
            <div className="relative top-2 right-6">
              <button className="fixed" onClick={onClose}>
                <CloseSharpIcon className="hover:text-red-500" />
              </button>
            </div>
          </div>
          {/* Bottom part */}
          <div className="h-full overflow-y-auto my-4 custom-scrollbar-2">
            <div className="space-y-2 pb-2">
              {Object.keys(feedback_response).map((key, index) => {
                if (selectedQuestion === index) {
                  const feedbackData = feedback_response[key]

                  return Object.keys(props.order).map((key2, index2) => {
                    const fieldValue = feedbackData[key2]
                    if (fieldValue === undefined || fieldValue === null) return null

                    // Determine the width classes for key and value
                    const keyWidth = "w-1/3"
                    const valueWidth = key2 === "code_snippet" ? "w-2/3 overflow-x-auto" : "w-2/3"

                    // Render the field based on its type
                    return (
                      <div key={index2} className={`flex`}>
                        {(key2 !== "code_snippet" ||
                          (key2 === "code_snippet" && feedbackData["code_snippet"]?.length > 0)) && (
                          <p className={`${keyWidth} text-xs font-normal text-[#414141]`}>{props.order[key2]}:</p>
                        )}
                        {/* Render code_snippet */}
                        {key2 === "code_snippet" && feedbackData["code_snippet"].length > 0 && (
                          <pre
                            className={`${valueWidth} text-sm text-[#414141] bg-[#F7F7F7] rounded-[4px] px-3 py-[6px]`}
                            dangerouslySetInnerHTML={{ __html: fieldValue }}
                          ></pre>
                        )}
                        {/* Render other values */}
                        {key2 === "rating" && (
                          <div className={`${valueWidth} pl-2 text-[#FBBF24] text-sm font-semibold`}>
                            {fieldValue} / 5
                          </div>
                        )}
                        {key2 === "marks" && (
                          <div className={`${valueWidth} pl-2 text-sm text-[#414141] font-semibold`}>
                            {fieldValue} /{" "}
                            {feedback_response[key]?.max_marks === 0 ? "10" : feedback_response[key]?.max_marks}
                          </div>
                        )}
                        {key2 === "feedback_response" && (
                          <div
                            className={`${valueWidth} h-[150px] text-sm font-semibold text-[#414141] bg-[#F7F7F7] px-3 py-[6px] rounded-[4px] break-words`}
                          >
                            {fieldValue}
                          </div>
                        )}
                        {/* Render files as <a> tags if files field exists and is an array */}
                        {key2 === "files" && Array.isArray(fieldValue) && fieldValue.length > 0 && (
                          <div className={`${valueWidth} bg-[#F7F7F7] p-2 rounded`}>
                            <ul className="list-disc list-inside space-y-2">
                              {fieldValue.map((file, idx) => (
                                <li className="">
                                  <a
                                    className="px-2 font-semibold text-sm underline text-blue-500"
                                    key={idx}
                                    href={file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Refrence {idx + 1}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )
                  })
                }
                return null
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackModal
