import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { ClassDataSource } from "data/dataSource/Mentor/ClassDataSource"
import {
  API_V4_MENTOR,
  STR_GET_ALL_CLASSES,
  STR_JOIN_CLASS,
  API_V4_CLASS_REMINDER_SCHEDULING,
} from "core/constants/strings"

export default class ClassAPIDataSourceImpl implements ClassDataSource {
  async getAllClasses(auth: Auth) {
    const response = await postAPI(pn(API_V4_MENTOR, STR_GET_ALL_CLASSES), {
      id_token: auth.id_token,
    })
    return response
  }

  async joinClass(auth: Auth, class_id: string) {
    const response = await postAPI(pn(API_V4_MENTOR, STR_JOIN_CLASS), {
      id_token: auth.id_token,
      class_id,
    })
    return response
  }

  async classReminderScheduling(auth: Auth, id: any) {
    const response = await postAPI(pn(API_V4_MENTOR, API_V4_CLASS_REMINDER_SCHEDULING), {
      id_token: auth.id_token,
      id: id,
    })
    return response
  }
}
