import Button from "core/components/Button"
import { STR_CANCEL, STR_SUBMIT } from "core/constants/strings"
import { CrossIcon, StarIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import getOrdinal from "core/utils/ordinal"
import { format } from "date-fns"
import React from "react"

export default function PastSessionTable({ header, sessions, data, addMentorFeedbacks }: any) {
  const [selectedReviewIdx, setSelectedReviewIndex] = React.useState<number>(-1)
  const [selectedFeedbackIdx, setSelectedFeedbackIndex] = React.useState<number>(-1)
  const [rating, setRating] = React.useState<number>(0)
  const [comment, setComment] = React.useState<string>("")
  const [actionItem, setActionItem] = React.useState<string>("")

  React.useEffect(() => {
    if (selectedReviewIdx !== -1 && sessions[selectedReviewIdx]?.mentor_feedback?.action_items) {
      setActionItem(sessions[selectedReviewIdx].mentor_feedback.action_items)
    } else {
      setActionItem("") // Reset actionItem if not present or on deselect
    }
    if (selectedReviewIdx !== -1 && sessions[selectedReviewIdx]?.mentor_feedback?.feedback) {
      setComment(sessions[selectedReviewIdx].mentor_feedback.feedback)
    } else {
      setComment("") // Reset actionItem if not present or on deselect
    }
  }, [selectedReviewIdx, sessions])
  function closeSelectedFeedbackDialog(type: string) {
    if (type === "review") setSelectedReviewIndex(-1)
    else setSelectedFeedbackIndex(-1)
    setRating(0)
    setComment("")
  }

  async function handleSubmit() {
    if (comment === "" || selectedReviewIdx === -1) return
    addMentorFeedbacks(comment, sessions[selectedReviewIdx]?.room_id, actionItem)
    closeSelectedFeedbackDialog("review")
    // await fetchBookedSessions();
  }
  return (
    <div>
      <div className="my-8">
        <h3 className="pb-2 text-2xl font-bold text-new-accent">Past Sessions</h3>
        <p className="text-[16px]"> List of Past Mentor Sessions.</p>
      </div>
      <div className="relative overflow-x-auto shadow">
        <table className="w-full table-fixed text-left text-sm text-gray-500">
          <thead className="rounded-lg bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-white">
            <tr>
              {header?.map((title: any, index: any) => (
                <th
                  key={index}
                  className={cn(
                    "whitespace-nowrap py-2 font-medium leading-6",
                    index === 0 ? "rounded-l-lg pl-8 pr-2" : "px-2",
                    index === header?.length - 1 && "rounded-r-lg pl-2 pr-8 text-right",
                    index < 3 ? "text-left" : index === header?.length - 1 ? "pl-2 pr-6 text-right" : "text-center"
                  )}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((row: any, rowIndex: number) => (
                <React.Fragment key={rowIndex}>
                  <tr className={cn(rowIndex % 2 ? "bg-[#F5F5F5]" : "bg-new-solid-white")}>
                    {row?.map((cell: any, cellIndex: number) => (
                      <td
                        key={cellIndex}
                        className={cn(
                          "py-2 font-medium leading-6",
                          (cellIndex === 0 || cellIndex === row.length - 1 || cellIndex === row.length - 2) &&
                            "text-new-accent",
                          cellIndex === 0 ? "pl-8 pr-2" : "px-2",
                          cellIndex === row.length - 1 && "rounded-r-lg pl-2 pr-8 text-right"
                        )}
                      >
                        {cellIndex >= row.length - 2 ? (
                          <button
                            className="text-new-accent"
                            onClick={() => {
                              if (cellIndex === row.length - 2) {
                                setSelectedReviewIndex(rowIndex)
                              } else {
                                setSelectedFeedbackIndex(rowIndex)
                              }
                            }}
                          >
                            {cellIndex === row.length - 2 ? "Feedback" : "Review"}
                          </button>
                        ) : (
                          cell
                        )}
                      </td>
                    ))}
                  </tr>
                  {rowIndex === selectedReviewIdx && (
                    <tr className="bg-new-solid-white">
                      <td colSpan={row.length} className="p-8">
                        <div className="rounded-lg border border-new-accent bg-[#F5F5F5] p-8 font-medium">
                          <div className="flex justify-between">
                            <div className="flex gap-4 text-new-accent">
                              <p>Mentor Review For Student</p>
                              <p>{getOrdinal(rowIndex + 1)} Session</p>
                            </div>
                            <button onClick={() => closeSelectedFeedbackDialog("review")}>
                              <CrossIcon className="h-6 w-6" />
                            </button>
                          </div>
                          <p>
                            Mentor's Feedback for the mentor session on{" "}
                            {format(new Date(sessions[rowIndex]?.date), "dd-MM-yyyy")} from{" "}
                            {format(new Date(sessions[rowIndex]?.start_timestamp * 1000), "h:mm a")} to{" "}
                            {format(new Date(sessions[rowIndex]?.end_timestamp * 1000), "h:mm a")}.
                          </p>
                          <div className="mt-8 flex flex-col gap-8">
                            <div className="flex gap-6">
                              <p>Feedback</p>
                              <div className="w-full">
                                <textarea
                                  value={comment}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 500) setComment(e.target.value)
                                  }}
                                  className="h-20 w-full rounded-lg border border-border bg-transparent p-4"
                                  placeholder="Write your feedback here..."
                                />
                                <p>{500 - comment.length} Characters left</p>
                              </div>
                            </div>
                            <div className="flex gap-6">
                              <p>Action Items</p>
                              <div className="w-full">
                                <textarea
                                  value={actionItem}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 500) setActionItem(e.target.value)
                                  }}
                                  className="h-20 w-full rounded-lg border border-border bg-transparent p-4"
                                  placeholder="Write action items here..."
                                />
                                <p>{500 - actionItem.length} Characters left</p>
                              </div>
                            </div>
                            {!sessions[rowIndex]?.student_review?.rating && (
                              <div className="flex justify-end gap-4">
                                <button
                                  className="rounded bg-[#EF4444] py-2 px-4 font-medium leading-[19px] text-white"
                                  type="button"
                                  onClick={() => closeSelectedFeedbackDialog("review")}
                                >
                                  {STR_CANCEL}
                                </button>
                                <Button className="w-fit" onClick={() => handleSubmit()}>
                                  {STR_SUBMIT}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {rowIndex === selectedFeedbackIdx && (
                    <tr className="bg-new-solid-white">
                      <td colSpan={row.length} className="p-8">
                        <div className="rounded-lg border border-new-accent bg-[#F5F5F5] p-8 font-medium">
                          <div className="flex justify-between">
                            <div className="flex gap-4 text-new-accent">
                              <p>Student Review For Mentor</p>
                              <p>{getOrdinal(rowIndex + 1)} Session</p>
                            </div>
                            <button onClick={() => closeSelectedFeedbackDialog("feedback")}>
                              <CrossIcon className="h-6 w-6" />
                            </button>
                          </div>
                          <p>
                            Student's Feedback for the mentor session on{" "}
                            {format(new Date(sessions[rowIndex]?.date), "dd-MM-yyyy")} from{" "}
                            {format(new Date(sessions[rowIndex]?.start_timestamp * 1000), "h:mm a")} to{" "}
                            {format(new Date(sessions[rowIndex]?.end_timestamp * 1000), "h:mm a")}.
                          </p>
                          <div className="mt-8 flex flex-col gap-8">
                            <div className="flex items-center gap-8">
                              <p>Rate</p>
                              <div className="flex gap-4">
                                {Array(5)
                                  .fill(0)
                                  .map((_, i) => (
                                    <span key={i}>
                                      <StarIcon
                                        className="h-6 w-6"
                                        pathClassName={
                                          sessions[rowIndex]?.student_review?.rating &&
                                          sessions[rowIndex]?.student_review?.rating >= i + 1
                                            ? "fill-[#FBBF24]"
                                            : "fill-border"
                                        }
                                      />
                                    </span>
                                  ))}
                              </div>
                            </div>
                            <div className="flex gap-6">
                              <p>Feedback</p>
                              <div className="h-fit w-full rounded-lg border border-border bg-transparent p-4">
                                {sessions[rowIndex]?.student_review?.comment}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr className="h-[50px] w-full bg-[#F5F5F5]">
                <td colSpan={header.length} className="py-2 text-center font-medium leading-6 text-gray-500">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
