import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"
import { SessionDataSource } from "data/dataSource/Mentor/SessionDataSource"

export class SessionRepositoryImpl implements SessionRepository {
  private dataSource: SessionDataSource

  constructor(dataSource: SessionDataSource) {
    this.dataSource = dataSource
  }

  async getMentorSessions(auth: Auth) {
    return await this.dataSource.getMentorSessions(auth)
  }

  async cancelSession(auth: Auth, date: string, start: string, end: string) {
    return await this.dataSource.cancelSession(auth, date, start, end)
  }

  async writeStudentFeedback(room_id: string, feedback: string, action_items: string) {
    return await this.dataSource.writeStudentFeedback(room_id, feedback, action_items)
  }

  async fetchMonthlyMentorSession(auth: Auth, start: string, end: string) {
    return await this.dataSource.fetchMonthlyMentorSession(auth, start, end)
  }
}
