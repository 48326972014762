import { ReferAndEarnDataSource } from "data/dataSource/Student/ReferAndEarnAPIDataSourceImpl"
import { Auth } from "domain/model/Auth"
import { referCandidate } from "domain/model/ReferandEarn"
import { ReferringDetails } from "domain/model/ReferringDetails"
import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"

export class ReferAndEarnRepositoryImpl implements ReferAndEarnRepository {
  private dataSource: ReferAndEarnDataSource

  constructor(dataSource: ReferAndEarnDataSource) {
    this.dataSource = dataSource
  }

  async referCandidates(request: referCandidate): Promise<any> {
    return this.dataSource.referCandidates(request)
  }

  async myReferredCandidates(auth: Auth): Promise<any> {
    return this.dataSource.myReferredCandidates(auth)
  }

  async getReferralData(auth: Auth): Promise<any> {
    return this.dataSource.getReferralData(auth)
  }
  async postReferralFormData(request: ReferringDetails, auth: Auth): Promise<any> {
    return this.dataSource.postReferralFormData(request, auth)
  }
  async postBankFormData(request: any, auth: Auth): Promise<any> {
    return this.dataSource.postBankFormData(request, auth)
  }
  async editReferralFormData(request: ReferringDetails, auth: Auth): Promise<any> {
    return this.dataSource.editReferralFormData(request, auth)
  }
  async deleteReferralFormData(email: string, auth: Auth): Promise<any> {
    return this.dataSource.deleteReferralFormData(email, auth)
  }
  async getReferrerStudentData(email: string): Promise<any> {
    return this.dataSource.getReferrerStudentData(email)
  }
}
