import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import ProjectDataSource from "data/dataSource/Admin/ProjectDataSource"
import { CreateNewProject } from "domain/model/ProjectSection"
import {
  API_CREATE_PROJECT,
  API_GET_PROJECT,
  API_V4_ADMIN,
  API_GET_PROJECT_DETAILS,
  API_EDIT_PROJECT,
  API_TOGGLE_PROJECT_STATUS,
  API_DELETE_PROJECT,
} from "core/constants/strings"

export default class ProjectSectionDataSourceImpl implements ProjectDataSource {
  async createProject(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_PROJECT), {
        id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getProject(auth: Auth): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_GET_PROJECT), {
        id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getProjectDetails(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_PROJECT_DETAILS), {
        id_token,
        project_id: data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async editProject(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_EDIT_PROJECT), {
        id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async toggleProjectStatus(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_TOGGLE_PROJECT_STATUS), {
        id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteProject(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_PROJECT), {
        id_token,
        project_id: data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
