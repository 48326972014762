import React from "react"

const LineBottomRot = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="372" height="305" viewBox="0 0 372 305" fill="none">
      <path
        d="M209.089 324H-18.3706L144.54 -0.000244141H372L209.089 324Z"
        fill="url(#paint0_linear_2874_66451)"
        fill-opacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2874_66451"
          x1="0.0720703"
          y1="560.118"
          x2="311.396"
          y2="140.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LineBottomRot
