import { cn } from "core/lib/utils"
import Spinner from "./Spinner"

export default function Button({
  children,
  outlined,
  disabled,
  className,
  isLoading,
  secondary,
  danger,
  ...props
}: any) {
  return (
    <button
      className={cn(
        "flex w-full select-none items-center justify-center rounded border py-2 px-4 text-base font-medium leading-[19px]",
        className,
        disabled && "cursor-not-allowed opacity-50"
      )}
      style={disabled ? { pointerEvents: "none" } : {}}
      {...{ disabled }}
      {...props}
    >
      {isLoading ? <Spinner small /> : children}
    </button>
  )
}
