import DashboardLayoutV2 from "core/layouts/DashboardLayoutv2"
import OrderedItemCard from "./OrderedItemCard"
import OrderStatusCard from "./OrderStatusCard"
import useStoreViewModel from "../StoreViewModel"
import React from "react"
import OrderStatusModal from "./OrderStatusModal"

export default function OrderHistory() {
  const { orderHistory, orderStatus, fetchOrderHistory, fetchOrderStatus } = useStoreViewModel()
  const [selectedItemIndex, setSelectedItemIndex] = React.useState<any>({})

  React.useEffect(() => {
    fetchOrderHistory()
  }, [])

  const [show, setShow] = React.useState(false)

  const onHide = () => setShow(false)

  const handleShow = async (index: number) => {
    setShow(true)
    setSelectedItemIndex(index)
    await fetchOrderStatus(orderHistory?.current[index]?.order_id)
  }

  return (
    <>
      <OrderStatusModal
        item={orderHistory?.current && orderHistory?.current?.length > 0 && orderHistory?.current[selectedItemIndex]}
        status={orderStatus}
        show={show}
        onHide={onHide}
      />
      <DashboardLayoutV2>
        <div className="divide-y divide-new-neutral-light text-new-neutral-dark">
          {orderHistory?.current && orderHistory?.current?.length > 0 && (
            <div className="space-y-8 p-6">
              <div className="space-y-2">
                <h2 className="text-new-accent">Current Orders</h2>
                <p>All your ongoing and past orders history will be visible here!</p>
              </div>
              <div className="flex flex-col gap-6">
                {orderHistory?.current?.length > 0 &&
                  orderHistory?.current?.map((item: any, index: number) => (
                    <OrderStatusCard onShow={() => handleShow(index)} item={item} key={index} />
                  ))}
              </div>
            </div>
          )}
          <div className="space-y-8 p-6">
            <div className="space-y-2">
              <h2 className="text-new-accent">Past Order History</h2>
              <p>All your past orders history will be visible here!</p>
            </div>
            {orderHistory?.past && orderHistory?.past?.length > 0 ? (
              <div className="flex flex-col gap-6">
                {orderHistory?.past?.length > 0 &&
                  orderHistory?.past?.map((item: any, index: number) => <OrderedItemCard item={item} key={index} />)}
              </div>
            ) : (
              <div className="flex h-64">
                <span className="m-auto">No past orders found!</span>
              </div>
            )}
          </div>
        </div>
      </DashboardLayoutV2>
    </>
  )
}
