import HomeRepository from "domain/repository/Admin/HomeRepository"

export interface GetProductAnalyticsUseCase {
  invoke(data: any): Promise<any>
}

export class GetProductAnalytics implements GetProductAnalyticsUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(data: any) {
    return this.repository.getProductAnalytics(data)
  }
}
