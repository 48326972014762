import ButtonGroup from "core/components/ButtonGroup"
import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/Button"
import { STR_ENTER_BATCH, STR_RECORDED_CLASSES, STR_TEXT_FIELD, STR_UPCOMING_CLASSES } from "core/constants/strings"
import DashboardLayout from "core/layouts/DashboardLayout"
import ScheduledClassCard from "./components/ScheduledClassCard"
import useScheduledClassViewModel from "./ScheduledClassesViewModel"
import { useEffect } from "react"

export default function ScheduledClassesView() {
  const {
    batch,
    activeButtonType,
    tabsData,
    loading,
    cancelLoading,
    getBatchList,
    instructorList,
    getModulesList,
    getTracksList,
    selectAssigmentsQuestions,
    selectHomeworkQuestions,
    selectPracticalQuestions,
    selectWarmupQuestions,
    currentSelectAssignmentsQuestions,
    setCancelLoading,
    handleBatchChange,
    getScheduledClasses,
    deleteScheduledClass,
    changeActiveButtonType,
    getBatches,
    getAllModules,
    getAllTracks,
    getInstructorsList,
  } = useScheduledClassViewModel()

  useEffect(() => {
    getBatches()
    getAllModules()
    getAllTracks()
    getInstructorsList()
  }, [])

  return (
    <DashboardLayout>
      <div className="p-6">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Scheduled Classes</h1>
          <p className="font-medium">See all scheduled classes batch wise</p>
        </div>
      </div>
      <div className="flex items-center justify-center ">
        <div className="w-1/4">
          <form onSubmit={getScheduledClasses}>
            <StandaredSelect
              type={STR_TEXT_FIELD}
              value={batch}
              options={getBatchList}
              onChange={handleBatchChange}
              placeholder={STR_ENTER_BATCH}
              className="mb-4 "
            />
            <Button>{loading ? "Searching" : "Search"}</Button>
          </form>
        </div>
      </div>
      <div className="mt-10 px-5">
        <ButtonGroup
          buttons={[STR_UPCOMING_CLASSES, STR_RECORDED_CLASSES]}
          active={activeButtonType}
          onChange={changeActiveButtonType}
        />
      </div>
      {tabsData?.map((classSchedule: any, index: any) => {
        const sessionDate = new Date(classSchedule.session_timestamp * 1000)
        const formattedDate = sessionDate.toLocaleDateString()
        const formattedTime = sessionDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
        return (
          <div className="m-8" key={index}>
            <ScheduledClassCard
              chapter_name={classSchedule.chapter_name}
              session_id={classSchedule.session_id}
              getBatchList={getBatchList}
              instructorList={instructorList}
              getModulesList={getModulesList}
              getTracksList={getTracksList}
              oldClassData={classSchedule}
              cancelLoading={cancelLoading}
              formattedDate={formattedDate}
              formattedTime={formattedTime}
              setCancelLoading={setCancelLoading}
              deleteScheduleClass={deleteScheduledClass}
              selectAssigmentsQuestions={selectAssigmentsQuestions}
              selectHomeworkQuestions={selectHomeworkQuestions}
              selectPracticalQuestions={selectPracticalQuestions}
              selectWarmupQuestions={selectWarmupQuestions}
              currentSelectAssignmentsQuestions={currentSelectAssignmentsQuestions}
            />
          </div>
        )
      })}
      ;
    </DashboardLayout>
  )
}
