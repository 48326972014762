import SubmissionDataSource from "data/dataSource/Student/SubmissionDataSource"
import SubmissionRepository from "domain/repository/Student/SubmissionRepository"
import { Auth } from "domain/model/Auth"

export class SubmissionRepositoryImpl implements SubmissionRepository {
  private dataSource: SubmissionDataSource

  constructor(dataSource: SubmissionDataSource) {
    this.dataSource = dataSource
  }

  async submitAssignmentQuestion(auth: Auth, data: any): Promise<any> {
    return this.dataSource.submitAssignmentQuestion(auth, data)
  }

  async getSubmission(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getSubmission(auth, data)
  }
}
