import { Auth } from "domain/model/Auth"
import { SSMHistoryRepository } from "domain/repository/Student/SSMHistoryRepository"

export interface GetAllSSMHistorysUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllSSMHistorys implements GetAllSSMHistorysUseCase {
  private repository: SSMHistoryRepository

  constructor(repository: SSMHistoryRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getAllSSMHistory(auth)
  }
}
