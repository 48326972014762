import AwardEmpty from "assets/svgs/AwardEmpty"
import Spinner from "core/components/Spinner"
import { Button } from "core/components/v2/Button"
import StandardSelect from "core/components/v2/StandardSelect"
import { STR_CANCEL, STR_SUBMIT } from "core/constants/strings"
import { AwardIcon, CrossIcon, StarIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { useState } from "react"
import { CustomScroll } from "react-custom-scroll"

const FeedBackModal = ({
  mentorDetails,
  oldRating,
  activeSessionIndex,
  setOldRating,
  comment,
  setComment,
  isOpen,
  onClose,
  newMentorshipFlow,
  session,
  onSubmit,
  loading,
  setMentorAbsentModal,
  setIndexToBeMarkedAbsent,
}: any) => {
  if (!isOpen) return null

  const [rating, setRating] = useState([
    {
      title: "Clarity of Explanation",
      star: 0,
    },
    {
      title: "Session Structure & Flow",
      star: 0,
    },
    {
      title: "Depth of Knowledge",
      star: 0,
    },
    {
      title: "Engagement & Interaction",
      star: 0,
    },
    {
      title: "Feedback & Guidance",
      star: 0,
    },
    {
      title: "Time Management",
      star: 0,
    },
    {
      title: "Encouragement & Motivation",
      star: 0,
    },
  ])

  const [status, setStatus] = useState("")
  const [newComment, setNewComment] = useState("")

  const handleClick = (index: number, i: number) => {
    const newRating = rating
    newRating[i].star = index + 1

    setRating([...newRating])
  }
  return newMentorshipFlow ? (
    <div className="relative z-30">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0"></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative flex flex-col bg-white w-[450px] h-[535px] overflow-y-auto rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
            <div className="p-[24px] ">
              <div className="flex justify-between">
                <div>
                  <p className="text-[#333] text-[16px] font-[600]">{session?.name}</p>
                  <p className="text-[#646464] text-[14px] font-[500]">Mentor Session #{activeSessionIndex + 1} </p>
                </div>
                <button className="hover:bg-slate-200 rounded-[4px] size-[24px]" onClick={onClose}>
                  <CrossIcon className="size-[24px]" />
                </button>
              </div>

              <div className="my-[16px] text-[14px] font-[500]">
                <p>{mentorDetails?.name}</p>
                <p>
                  {mentorDetails?.current_company} | {mentorDetails?.experience} Years of Exp. | +91{" "}
                  {mentorDetails?.phone}
                </p>
              </div>
              <StandardSelect
                dimensions="w-[300px]"
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="status"
                value={status}
                onChange={(value: string) => setStatus(value)}
                options={[
                  (session?.session_status === "Completed" || session?.session_status === "Taken Elsewhere") &&
                    "Session Conducted",
                  session?.session_status === "Booked" && "Mentor Absent",
                ].filter(Boolean)}
                required
              />

              {status !== "Session Conducted" && (
                <div className="p-[12px] bg-[#FFEDED] rounded-[8px] my-[16px]">
                  <p className="text-[#DC2626] text-[14px] font-[600]">Mentor Session Confirmation!</p>
                  <p className="text-[12px] font-[500]">
                    It is mandatory for each student to provide mentor session status in order to activate next mentor
                    session.
                  </p>
                </div>
              )}

              {status === "Session Conducted" && (
                <div className="my-[16px] text-[#333]">
                  <p className="text-[14px] font-[600]">
                    Student Feedback For Mentor <span className="text-[#DC2626]">*</span>
                  </p>
                  <p className="text-[14px] font-[400]">Rate between 1 to 5 (with 1 = poor; 5= excellent)</p>

                  <div>
                    {rating.map((item: any, i: number) => (
                      <div className="flex justify-between my-[16px] items-center">
                        <li>{item.title}</li>

                        <div className="flex gap-[8px] mt-[8px]">
                          {Array.from({ length: 5 }, (_, index) => (
                            <div key={index} onClick={() => handleClick(index, i)} className="cursor-pointer">
                              {index < item.star ? (
                                <AwardIcon fill="#FBBF24" className="size-[16px]" />
                              ) : (
                                <AwardEmpty className="size-[16px]" />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    <p className="text-[14px] font-[600] my-[6px]">Custom Feedback</p>
                    <textarea
                      onChange={(e: any) => setNewComment(e.target.value)}
                      value={newComment}
                      className="my-1 h-[195px] w-full border-[#D3D3D3] focus:border-[#D3D3D3] rounded-[4px]"
                      placeholder="Write your feedback..."
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className={cn(
                "bottom-0 flex justify-end w-full h-[59px] bg-[#F9F9F9] px-[24px] py-[12px]",
                status === "Session Conducted" ? "sticky" : "absolute"
              )}
            >
              <Button
                onClick={() => {
                  if (status === "Session Conducted") onSubmit(rating, newComment)
                  else {
                    setMentorAbsentModal(true)
                    setIndexToBeMarkedAbsent(session?.id)
                  }
                }}
                size={"sm"}
                variant={"primary"}
                className="px-[16px] py-[9px]"
                disabled={loading || !status}
              >
                {loading ? <Spinner small={true} /> : "Submit"}
              </Button>
            </div>
          </CustomScroll>
        </div>
      </div>
    </div>
  ) : (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="m-auto min-w-[500px] transform space-y-8 rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="flex items-start justify-between gap-4">
              <div className="flex flex-col gap-2 text-new-neutral">
                <div className="flex gap-4 text-new-accent">
                  <p>{"Mentor Feedback"}</p>
                  <p> Session</p>
                </div>
                {!newMentorshipFlow ? (
                  <p>
                    {/* {isReview ? "Your" : "Mentor"} feedback for the mentor session
                on {format(new Date(session?.date), "dd-MM-yyyy")}{" "}
                from{" "}
                {format(
                  new Date(Number(session?.start_timestamp) * 1000),
                  "h:mm a"
                )}{" "}
                to{" "}
                {format(
                  new Date(Number(session?.end_timestamp) * 1000),
                  "h:mm a"
                )}
                . */}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
              <button onClick={onClose} className="p-2">
                <CrossIcon className="h-6 w-6" />
              </button>
            </div>
            {/* {isReview ? ( */}
            <div className="flex flex-col gap-6">
              <div className="flex items-center gap-6">
                <p>Rate</p>
                <div className="flex gap-4">
                  {Array(5)
                    .fill(0)
                    .map((_, i) =>
                      !isEmpty(session?.student_review) ? (
                        <span key={i}>
                          <StarIcon
                            className="h-6 w-6"
                            pathClassName={session?.student_review?.rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"}
                          />
                        </span>
                      ) : (
                        <button type="button" key={i} onClick={() => setOldRating(i + 1)}>
                          <StarIcon
                            className="h-6 w-6"
                            pathClassName={oldRating !== -1 && oldRating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"}
                          />
                        </button>
                      )
                    )}
                </div>
              </div>
              <div className="flex gap-6">
                <p>Feedback</p>
                {!isEmpty(session?.student_review) ? (
                  <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
                    {session?.student_review?.comment}
                  </p>
                ) : (
                  <div className="w-full">
                    <textarea
                      value={comment}
                      onChange={(e) => {
                        if (e.target.value.length <= 400) setComment(e.target.value)
                      }}
                      className="h-32 w-full focus:border-black rounded-sm border border-border bg-transparent p-4"
                      placeholder="Write your feedback here..."
                    />
                    <p>{400 - comment?.length || 0} Characters left</p>
                  </div>
                )}
              </div>
              {isEmpty(session?.student_review) && (
                <div className="flex justify-end gap-4">
                  <Button className="w-fit" type="button" onClick={onClose}>
                    {STR_CANCEL}
                  </Button>
                  <Button className="w-fit" onClick={() => onSubmit(oldRating, comment)}>
                    {STR_SUBMIT}
                  </Button>
                </div>
              )}
            </div>
            {/* // ) : !isEmpty(session?.mentor_feedback) ? (
            //   <div className="flex flex-col gap-6">
            //     <div className="flex gap-6">
            //       <p>Actions</p>
            //       <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
            //         {session?.mentor_feedback?.action_items}
            //       </p>
            //     </div>
            //     <div className="flex gap-6">
            //       <p>Feedback</p>
            //       <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
            //         {session?.mentor_feedback?.feedback}
            //       </p>
            //     </div>
            //   </div>
            // ) : (
            //   <p className="text-center">No mentor feedback is provided by mentor yet.</p>
            // )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedBackModal
