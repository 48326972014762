import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Student/ContestRepository"

export interface GetAllContestUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllContest implements GetAllContestUseCase {
  private repository: ContestRepository
  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getAllContest(auth)
  }
}
