import { PaymentInvoice } from "domain/model/PaymentInvoice"
import { PaymentInvoiceRepository } from "domain/repository/Admin/PaymentInvoiceRepository"
import { Auth } from "domain/model/Auth"

export interface UpdateInvoiceUseCase {
  invoke(auth: Auth, updateInvoice: PaymentInvoice, oldInvoice: PaymentInvoice): Promise<any>
}

export class UpdateInvoice implements UpdateInvoiceUseCase {
  private repository: PaymentInvoiceRepository

  constructor(repository: PaymentInvoiceRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, updateInvoice: PaymentInvoice, oldInvoice: PaymentInvoice) {
    return await this.repository.updateInvoice(auth, updateInvoice, oldInvoice)
  }
}
