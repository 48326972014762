import MonthlyFeedbackRepository from "domain/repository/Student/MonthlyFeedbackRepository"

export interface GetMonthlyFeedbackArchivedUseCase {
  invoke(auth: any, data: any): Promise<any>
}

export class GetMonthlyFeedbackArchived implements GetMonthlyFeedbackArchivedUseCase {
  private readonly repository: MonthlyFeedbackRepository

  constructor(repository: MonthlyFeedbackRepository) {
    this.repository = repository
  }

  async invoke(auth: any, data: any): Promise<any> {
    return await this.repository.getMonthlyFeedbackArchived(auth, data)
  }
}
