import React from "react"

const LockClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.03271 4.83301V5.49967H0.699382C0.331192 5.49967 0.0327148 5.79815 0.0327148 6.16634V14.1663C0.0327148 14.5345 0.331192 14.833 0.699382 14.833H11.366C11.7342 14.833 12.0327 14.5345 12.0327 14.1663V6.16634C12.0327 5.79815 11.7342 5.49967 11.366 5.49967H10.0327V4.83301C10.0327 2.62387 8.24185 0.833008 6.03271 0.833008C3.82358 0.833008 2.03271 2.62387 2.03271 4.83301ZM1.36605 13.4997V6.83301H10.6994V13.4997H1.36605ZM5.36607 10.6543C4.84345 10.3526 4.58864 9.73744 4.74483 9.15453C4.90103 8.57162 5.42926 8.16629 6.03274 8.16629C6.63621 8.16629 7.16445 8.57162 7.32064 9.15453C7.47683 9.73744 7.22203 10.3526 6.6994 10.6543V12.1663H5.36607V10.6543ZM8.69938 4.83301V5.49967H3.36605V4.83301C3.36605 3.36025 4.55996 2.16634 6.03271 2.16634C7.50547 2.16634 8.69938 3.36025 8.69938 4.83301Z"
        fill="white"
      />
    </svg>
  )
}

export default LockClose
