import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import { useAuth } from "core/context/auth"
import React from "react"
import ModuleClassViewModel from "../ModuleClassViewModel"
import GenerateInvoiceViewModel from "../GenerateInvoiceViewModel"
import { useParams } from "react-router-dom"
import { UpdateModuleClass } from "domain/model/ModuleClass"
import { STR_FAILURE, STR_SUCCESS, STR_TEXT_FIELD } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import { genError } from "core/utils/string"
import ModuleClassAPIDataSourceImpl from "data/API/Admin/ModuleClassAPIDataSourceImpl"
import { ModuleClassRepositoryImpl } from "data/repository/Admin/ModuleClassRepositoryImpl"
import { UpdateModuleClasses } from "domain/useCase/Admin/ModuleClasses/UpdateModuleClass"

export default function EditModuleClass(props: any) {
  const classDetails = props.clasDetails
  const { fetchModuleClasses } = ModuleClassViewModel()
  const { getBatchList, moduleId, getAllModules, getBatches } = GenerateInvoiceViewModel()

  const { auth } = useAuth()
  const { id } = useParams()
  const [updateModuleClass, setUpdateModuleClass] = React.useState<any>()
  const [createModuleName, setCreateModuleName] = React.useState("")
  const [createModuleBatch, setCreateModuleBatch] = React.useState([])
  const [createModuleStartDate, setCreateModuleStartDate] = React.useState("")
  const [createModuleEndDate, setCreateModuleEndDate] = React.useState("")
  const [createModuleStatus, setCreatedModuleStatus] = React.useState("")
  const [createModuleAmount, setCreatedModuleAmount] = React.useState(0)
  const [updateModuleLoader, setUpdateModuleloader] = React.useState(false)
  const [otherModules, setOtherModules] = React.useState("")
  const ModuleStatusArr: string[] = ["InProgress", "Completed"]
  const [moduleClassBatch, setModuleClassBatch] = React.useState("")
  const [batchArray, setBatchArray] = React.useState<any>()

  const updateModuleClassesUseCase = new UpdateModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )

  const fetchUpdateModuleClass = async (updateModuleClass: UpdateModuleClass) => {
    const response = await updateModuleClassesUseCase.invoke(updateModuleClass)

    if (!response?.success) {
      props.changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      props.changeToastVisibility(true)
      return
    }
    props.changeToastDetails(STR_SUCCESS, "Module Updated!")
    props.changeToastVisibility(true)
    props.setReloadData(!props.reloadData)
  }

  function setFunction() {
    setCreateModuleName(classDetails.module)
    setBatchArray(classDetails.batch)
    setCreateModuleEndDate(classDetails.end_date)
    setCreateModuleStartDate(classDetails.start_date)
    setCreatedModuleAmount(classDetails.amount)
    setCreatedModuleStatus(classDetails.status)
  }

  async function handleEditModuleClass() {
    setUpdateModuleloader(true)
    const UpdateModuleClass: UpdateModuleClass = {
      auth,
      module_id: classDetails.id,
      instructor_id: id as string,
      module: createModuleName,
      batch: batchArray,
      start_date: createModuleStartDate as string,
      end_date: createModuleEndDate as string,
      status: createModuleStatus,
      amount: createModuleAmount,
    }
    await fetchUpdateModuleClass(UpdateModuleClass)
    await fetchModuleClasses(auth, id as string)
    setUpdateModuleloader(false)
  }

  function handleCreateModuleName(e: any) {
    setCreateModuleName(e.target.value)
  }

  function handleOtherModules(e: any) {
    setOtherModules(e.target.value)
  }

  function handleCreateStatus(e: any) {
    setCreatedModuleStatus(e.target.value)
  }

  function handleCreateAmount(e: any) {
    setCreatedModuleAmount(e.target.value)
  }

  function handleCreateStartDate(e: any) {
    setCreateModuleStartDate(e.target.value)
  }

  function handleCreateEndDate(e: any) {
    setCreateModuleEndDate(e.target.value)
  }

  function handleModuleClassBatch(e: any) {
    setModuleClassBatch(e.target.value)
  }

  function handleBatchArray() {
    if (moduleClassBatch === "") return
    if (batchArray.includes(moduleClassBatch)) return

    setBatchArray([...batchArray, moduleClassBatch])
    setModuleClassBatch("")
  }
  function handleRemoveBatch(index: number) {
    let newBatchArray = batchArray
    newBatchArray = newBatchArray.filter((m: string, i: number) => i !== index)
    setBatchArray(newBatchArray)
  }

  React.useEffect(() => {
    setFunction()
    getAllModules()
    getBatches()
  }, [auth])

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex">
        <h3>Edit Module Session</h3>
      </div>
      <div className="flex justify-between gap-x-3">
        <StandaredSelect
          className="h-[40px] w-[150px]"
          options={moduleId}
          placeholder="Modules"
          onChange={handleCreateModuleName}
          value={createModuleName}
        />
        {createModuleName == "other" && (
          <Input type="text" value={otherModules} onChange={handleOtherModules} placeholder="Module Name" />
        )}
        <Input
          className="h-[40px] w-[150px]"
          id="Amount"
          type="text"
          placeholder="Amount"
          value={createModuleAmount}
          onChange={handleCreateAmount}
          onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
          onFocus={(e) => (e.target.type = "number")}
        />
        <Input
          className="h-[40px] w-[150px]"
          id="startMonth"
          type="text"
          placeholder="Start MM/YY"
          value={createModuleStartDate}
          onChange={handleCreateStartDate}
          onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
          onFocus={(e) => (e.target.type = "month")}
        />
        <div className="flex flex-col">
          <div className="flex flex-col gap-y-1">
            <div className="flex">
              {" "}
              <select value={moduleClassBatch} onChange={handleModuleClassBatch} className="h-[40px] w-[100px]">
                <option value="">Select Batch</option>
                {getBatchList?.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <Button type="button" onClick={handleBatchArray} className="h-[40px] w-[50px]">
                Add Batch
              </Button>
            </div>
          </div>
          <div className="flex gap-x-1">
            {batchArray?.map((batchValue: any, i: number) => (
              <div
                key={i}
                className="flex w-fit items-center gap-2 rounded-sm border border-new-neutral-light py-1 px-2 text-new-neutral-dark"
              >
                <p>{batchValue}</p>
                <button
                  type="button"
                  className="inline-flex h-4 w-4 items-center justify-center"
                  onClick={() => handleRemoveBatch(i)}
                >
                  <CrossIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <StandaredSelect
          className="h-[40px] w-[150px]"
          options={ModuleStatusArr}
          placeholder="Status"
          onChange={handleCreateStatus}
          value={createModuleStatus}
        />
        <Input
          className="h-[40px] w-[150px]"
          id="endMonth"
          type="text"
          placeholder="End MM/YY"
          value={createModuleEndDate}
          onChange={handleCreateEndDate}
          onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
          onFocus={(e) => (e.target.type = "month")}
        />
      </div>
      <Button small className="w-[150px] self-end" onClick={handleEditModuleClass} loading={updateModuleLoader}>
        Edit
      </Button>
    </div>
  )
}
