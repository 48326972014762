import { BATCH_MONTHLY_REPORT } from "core/constants/strings"
import { Server, postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import GetBatchMonthlyReport from "domain/model/GetBatchMonthlyReport"
import BatchMonthlyReportDataSource from "data/dataSource/Admin/BatchMonthlyReportDataSource"
const server = new Server()

export class BatchMonthlyReportAPIDataSourceImpl implements BatchMonthlyReportDataSource {
  async getBatchMonthlyReport(getBatchMonthlyReport: GetBatchMonthlyReport): Promise<any> {
    try {
      const response = await server.post(pn(BATCH_MONTHLY_REPORT), getBatchMonthlyReport, {
        Authorization: `Bearer ${getBatchMonthlyReport.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
