function CopyIcon({ className }: any) {
  return (
    <svg
      className={className}
      stroke="currentColor"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5835 4.00832C7.5835 3.45145 8.03675 3 8.59587 3H19.4876C20.0467 3 20.5 3.45144 20.5 4.00832V16.9426C20.5 17.4995 20.0467 17.951 19.4876 17.951H17.4164V19.9917C17.4164 20.5486 16.9631 21 16.404 21H5.51236C4.95325 21 4.5 20.5486 4.5 19.9917V7.05739C4.5 6.50052 4.95325 6.04907 5.51236 6.04907H7.5835V4.00832ZM9.32897 6.04907H16.404C16.9631 6.04907 17.4164 6.50051 17.4164 7.05739V16.2125H18.7545V4.73848H9.32897V6.04907ZM6.24545 19.2616V7.78756H15.6709V19.2616H6.24545Z"
      />
    </svg>
  )
}

export default CopyIcon
