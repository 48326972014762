import Email from "data/dataSource/Admin/Email"
import { Auth } from "domain/model/Auth"
import { OnboardingData, ManagerFeedback } from "domain/model/OnboardingMeet"
import { CreateOnboardingMeetRepository } from "domain/repository/Admin/CreateOnboardingMeetRepository"

export interface GetOnboardingMeetUseCase {
  invoke(auth: Auth, email: Email): Promise<any>
}
export class GetOnboardingMeet implements GetOnboardingMeetUseCase {
  private repository: CreateOnboardingMeetRepository

  constructor(repository: CreateOnboardingMeetRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: Email) {
    return await this.repository.getOnboardingMeet(auth, email)
  }
}
