import { Batch } from "domain/model/Batch"
import { BatchRepository } from "domain/repository/Admin/BatchRepository"

export interface UpdateBatchUseCase {
  invoke(updateBatch: Batch): Promise<any>
}

export class UpdateBatchDetails implements UpdateBatchUseCase {
  private repository: BatchRepository

  constructor(repository: BatchRepository) {
    this.repository = repository
  }

  async invoke(updateBatch: Batch) {
    return await this.repository.updateBatchDetails(updateBatch)
  }
}
