import { Auth } from "domain/model/Auth"
import CreateCCAvPaymentAdminRepository from "domain/repository/Admin/CreateCCAvPaymentRepository"

export interface GetCCAvPaymentDetailAdminUseCase {
  invoke(auth: Auth, paymentData: any): Promise<any>
}

export class GetCCAvPaymentDetailAdmin implements GetCCAvPaymentDetailAdminUseCase {
  private repository: CreateCCAvPaymentAdminRepository

  constructor(repository: CreateCCAvPaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, paymentData: any) {
    return await this.repository.getPaymentDetailAdmin(auth, paymentData)
  }
}
