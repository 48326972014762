import QuestionChamberRespository from "domain/repository/Admin/QuestionChamberRepository"
import { Auth } from "domain/model/Auth"

export interface CountSubCaseQuestionsUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class CountSubCaseQuestions implements CountSubCaseQuestionsUseCase {
  private repository: QuestionChamberRespository

  constructor(repository: QuestionChamberRespository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.get_sub_case_counts(auth, data)
  }
}
