export default function Bin({ className }: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.3334 4.00065H14.6667V5.33398H13.3334V14.0007C13.3334 14.1775 13.2631 14.347 13.1381 14.4721C13.0131 14.5971 12.8435 14.6673 12.6667 14.6673H3.33337C3.15656 14.6673 2.98699 14.5971 2.86197 14.4721C2.73695 14.347 2.66671 14.1775 2.66671 14.0007V5.33398H1.33337V4.00065H4.66671V2.00065C4.66671 1.82384 4.73695 1.65427 4.86197 1.52925C4.98699 1.40422 5.15656 1.33398 5.33337 1.33398H10.6667C10.8435 1.33398 11.0131 1.40422 11.1381 1.52925C11.2631 1.65427 11.3334 1.82384 11.3334 2.00065V4.00065ZM12 5.33398H4.00004V13.334H12V5.33398ZM6.00004 2.66732V4.00065H10V2.66732H6.00004Z"
        fill="#ADADAD"
      />
    </svg>
  )
}
