import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface GetFullCourseContentUseCase {
  invoke(auth: Auth, module_id: string): Promise<any>
}

export class GetFullCourseContent implements GetFullCourseContentUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, module_id: string) {
    return this.repository.getFullCourseContent(auth, module_id)
  }
}
