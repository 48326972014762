import { useAuth } from "core/context/auth"
import { StoreAPIDataSourceImpl } from "data/API/Admin/StoreAPIDataSourceImpl"
import { StoreRepositoryImpl } from "data/repository/Admin/StoreRepositoryImpl"
import { GetOrderHistory } from "domain/useCase/Admin/Store/GetOrderHistory"
import { GetTop100StudentsByCoins } from "domain/useCase/Admin/Store/GetTop100StudentsByCoins"
import React from "react"
import useStudentDashboardViewModel from "../StudentDashboard/StudentDashboardViewModel"
import { GetNext30StudentsByCoins } from "domain/useCase/Admin/Store/GetNext30StudentsByCoins"
import useCreateClassViewModel from "../CreateClass/CreateClassViewModel"
import { GetStudentsByBatch } from "domain/useCase/Admin/StudentDashboard/GetStudentsByBatch"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { isEmpty } from "core/utils/misc"
import { useNavigate } from "react-router-dom"
import Button from "core/components/new/Button"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function CoinsTracker() {
  const {
    students,
    mailSendLoading,
    setStudents,
    searchingforEmail,
    searchByEmail,
    searchByBatch,
    searchByName,
    toast,
    refreshed,
    emailInputRef,
    disableStudentEmail,
    disableReason,
    updateReasonLoading,
    fetchStudentsByBatch,
    fetchStudentsByName,
    fetchStudentByEmail,
    changeToastVisibility,
    handleSearchByEmail,
    handleSearchByBatch,
    handleSearchByName,
    paidToUnpaid,
    setMailSendLoading,
    toggleDisableStudent,
    sendConfirmationMail,
    loadNextData,
    getTop100Students,
    openDisableStudentModal,
    closeDisableStudentModal,
    handleDisableStudentReasonChange,
    handleSubmitDisableStudentReason,
    viewDisableReason,
  } = useStudentDashboardViewModel()
  const { auth } = useAuth()
  async function loadNext100StudentsData() {
    const response = await getNext30StudentsByCoinsUseCase.invoke(auth, lastStudentId)
    return response.data
  }
  const [isLoading, setIsLoading] = React.useState(true)
  const [lastStudentId, setLastStudentId] = React.useState<string>("")
  const [nextStudentsLoading, setNextStudentsLoading] = React.useState(false)
  const [showLoadMore, setShowLoadMore] = React.useState(true)
  const getTop100StudentsbyCoinsUseCase = new GetTop100StudentsByCoins(
    new StoreRepositoryImpl(new StoreAPIDataSourceImpl())
  )
  const getNext30StudentsByCoinsUseCase = new GetNext30StudentsByCoins(
    new StoreRepositoryImpl(new StoreAPIDataSourceImpl())
  )
  const GetOrderHistoryUseCase = new GetOrderHistory(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))
  async function getTop100StudentsByCoins() {
    setIsLoading(true)
    const response = await getTop100StudentsbyCoinsUseCase.invoke(auth)
    if (response) {
      setStudents(response.data)
      setLastStudentId(response.data[response.data.length - 1].email)
    }
    setIsLoading(false)
  }
  async function loadNextDataByCoins() {
    setNextStudentsLoading(true)

    const data = await loadNext100StudentsData()
    if (data.length > 0) {
      const students_data = data
      const previous_data = students
      const new_data = previous_data.concat(students_data)
      setStudents(new_data)
      setLastStudentId(students_data[students_data.length - 1].email)
      setNextStudentsLoading(false)
    } else {
      setNextStudentsLoading(false)
      alert("No more data to load")
    }
  }
  const { batch, getBatches } = useCreateClassViewModel()

  const tableHeaders = ["Name", "Email", "Batch", "Coins", "Order History"]
  const getStudentsByBatchUseCase = new GetStudentsByBatch(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const fetchStudentsByBatchByCoins = async (batch: string) => {
    setIsLoading(true)
    setShowLoadMore(false)
    if (batch === "ALL") {
      setShowLoadMore(true)
      getTop100StudentsByCoins()
    } else {
      const response = await getStudentsByBatchUseCase.invoke({ batch })

      if (!isEmpty(response?.data)) {
        const sortedStudents = response.data.sort((a: any, b: any) => b.points - a.points)
        setStudents(sortedStudents)
      } else {
        setStudents([])
        setShowLoadMore(true)
      }
    }
    setIsLoading(false)
  }

  const navigate = useNavigate()
  const navigateToHistory = (student: any) => {
    navigate(`/admin/coins-tracker/transaction-history/${student.email}`, {
      state: { studentData: student },
    })
  }
  const tableData = {
    header: tableHeaders,
    rows: students.map((student: any) => ({
      Name: student.name,
      Email: student.email,
      Batch: student.batch,
      Coins: student.points,
      TransactionHistory: <Button onClick={() => navigateToHistory(student)}>View Details</Button>,
    })),
  }

  return {
    auth,
    batch,
    students,
    mailSendLoading,
    searchByEmail,
    searchByBatch,
    searchByName,
    toast,
    refreshed,
    emailInputRef,
    disableStudentEmail,
    disableReason,
    updateReasonLoading,
    getTop100StudentsbyCoinsUseCase,
    getNext30StudentsByCoinsUseCase,
    GetOrderHistoryUseCase,
    getStudentsByBatchUseCase,
    tableHeaders,
    isLoading,
    tableData,
    showLoadMore,
    nextStudentsLoading,
    setStudents,
    searchingforEmail,
    fetchStudentsByBatch,
    fetchStudentsByName,
    fetchStudentByEmail,
    changeToastVisibility,
    handleSearchByEmail,
    handleSearchByBatch,
    handleSearchByName,
    paidToUnpaid,
    setMailSendLoading,
    toggleDisableStudent,
    sendConfirmationMail,
    loadNextData,
    getTop100Students,
    openDisableStudentModal,
    closeDisableStudentModal,
    handleDisableStudentReasonChange,
    handleSubmitDisableStudentReason,
    viewDisableReason,
    getBatches,
    setIsLoading,
    loadNext100StudentsData,
    getTop100StudentsByCoins,
    loadNextDataByCoins,
    fetchStudentsByBatchByCoins,
    navigateToHistory,
  }
}
