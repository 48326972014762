import { API_V4_ADMIN, API_V4_GET_OVERALL_REPORT } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import GetOverAllReport from "domain/model/GetOverAllReport"
import OverallReportDataSource from "data/dataSource/Admin/OverallReportDataSource"

export class OverallReportAPIDataSourceImpl implements OverallReportDataSource {
  async getOverallReport(getOverallReport: GetOverAllReport): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_OVERALL_REPORT), getOverallReport)
      return response
    } catch (error) {
      return error
    }
  }
}
