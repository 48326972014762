import { MainLogo } from "core/constants/svgs"
import useViewModel from "./ViewModel"
import QuestionView from "./Components/QuestionView"
import Toast from "core/components/Toast"
import React, { Fragment, useEffect, useLayoutEffect } from "react"
import { useParams } from "react-router-dom"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import TabsGroup from "../Components/TabsGroup"
import ConfirmationPopUp from "../Components/ConfirmationPopup"
import SubmitButton from "../Components/SubmitButton"
import FieldsView from "../Components/FieldsView"

export default function MCQView() {
  const {
    toast,
    pageLoading,
    loading,
    question,
    optionSelected,
    TABS,
    activeTab,
    viewSolution,
    isSolutionViewed,
    scoredPointsAfterSubmission,
    changeToastVisibility,
    fetchQuestion,
    fetchSubmission,
    handleOptionChange,
    handleSolutionSubmit,
    confirmViewSoluton,
    denyViewSolution,
    handelTabChange,
  } = useViewModel()

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      fetchQuestion(id)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      fetchSubmission(id)
    }
  }, [id, question])

  useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  return (
    <div className="flex w-full h-screen bg-new-editor-dark-700">
      {!isSolutionViewed && viewSolution && (
        <ConfirmationPopUp confirmViewSoluton={confirmViewSoluton} denyViewSolution={denyViewSolution} />
      )}
      <ConditionalLoader isLoading={pageLoading} loader={<Loader />}>
        <div className="flex flex-1 flex-col border-r-2 border-new-neutral-light overflow-y-auto text-white gap-4 p-8">
          <TabsGroup TABS={TABS} activeTab={activeTab} handelTabChange={handelTabChange} />
          {activeTab === 0 ? (
            <QuestionView question={question} optionSelected={optionSelected} handleOptionChange={handleOptionChange} />
          ) : (
            <Fragment>
              <div className="border-b border-new-neutral-light pb-2 text-2xl font-semibold">Solution</div>
              <div className="p-2 pl-0">
                <p className="mb-2">
                  <strong className="text-lg">Problem solution:</strong>
                </p>
                <p>Correct Solution is option {question?.solution}.</p>
              </div>
              {question?.solutionFields && <FieldsView headerTitle={"Description:"} fields={question.solutionFields} />}
            </Fragment>
          )}
        </div>
        <div className="relative flex-1 flex flex-col p-8 gap-6 text-white">
          <div className="w-full flex justify-between items-center">
            {scoredPointsAfterSubmission && !isNaN(scoredPointsAfterSubmission) ? (
              <p className="text-v2-white">
                🎉 You scored <code className="text-v2-red">{scoredPointsAfterSubmission}</code> coins!
              </p>
            ) : null}
            <MainLogo className="w-40 h-10 ml-auto" />
          </div>
          <div className="text-xl">
            {!isNaN(optionSelected) && optionSelected >= 0 ? (
              <>
                Your selected option is{" "}
                <code className="bg-v2-gray-600 px-4 py-2 rounded border-v2-gray-500">{optionSelected + 1}</code>.
              </>
            ) : (
              "No option selected"
            )}
          </div>
          <SubmitButton id={id} loading={loading} handleSolutionSubmit={handleSolutionSubmit} />
        </div>
      </ConditionalLoader>
      <Toast data={toast} onClick={changeToastVisibility} />
    </div>
  )
}
