import { TPaymentStatusReq } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface updatePaidToUnpaidUseCase {
  invoke(details: TPaymentStatusReq): Promise<any>
}

export class UpdatePaidToUnpaid implements updatePaidToUnpaidUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: TPaymentStatusReq): Promise<any> {
    return this.repository.updatePaidToUnpaid(details)
  }
}
