import { useEffect } from "react"
import ProgressManagerModel from "./ProgressManagerViewModel"
import ProgressMeetingCard from "./components/ProgressMeetingCard"
import HistoryTable from "./components/HistoryTable"
import DetailSession from "presentation/Admin/StudentDashboard/components/DetailSession"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function ScheduleView() {
  const {
    tableHeaders,
    fetchStudentProgressHistory,
    history,
    isSessionDetailOpen,
    setIsSessionDetailOpen,
    handleSessionDetail,
    handleUpdateDetailButton,
    meetingAgenda,
    meetingDate,
    meetingSsmRemark,
    feedback,
    rating,
    status,
    problemSolvingMilestone,
    problemSolvingMilestoneDueDate,
    developmentMilestone,
    developmentMilestoneDueDate,
    scheduledCnt,
    formatDate,
    removeHypens,
    currentDate,
  } = ProgressManagerModel()
  useEffect(() => {
    fetchStudentProgressHistory()
  }, [])
  return (
    <DashboardLayoutv2>
      <div className="flex-row space-y-6 p-6">
        {scheduledCnt != 0 && (
          <div className="mb-4">
            <ProgressMeetingCard data={history[0][Object.keys(history[0])[0]]} formatDate={formatDate} />
          </div>
        )}

        <div className="">
          <div className="font-montserrat text-[18px] font-semibold leading-normal text-[#312E81]">
            Meeting Details :
          </div>
          <div className="font-montserrat leading-160 mb-4 text-[14px] font-normal text-[#737373]">
            Track each meeting data with ease.
          </div>
          <HistoryTable
            tableHeaders={tableHeaders}
            historys={history}
            formatDate={formatDate}
            setIsSessionDetailOpen={setIsSessionDetailOpen}
            handleUpdateDetailButton={handleUpdateDetailButton}
            currentDate={currentDate}
          />
          <DetailSession
            handleIsSessionDetailopen={handleSessionDetail}
            isSessionDetailOpen={isSessionDetailOpen}
            status={status}
            date={meetingDate}
            problemSolvingMSDate={problemSolvingMilestoneDueDate}
            problemSolvingMS={problemSolvingMilestone}
            developmentMSDate={developmentMilestoneDueDate}
            developmentMS={developmentMilestone}
            studentRating={rating}
            ssmRemark={meetingSsmRemark}
            meetingAgenda={meetingAgenda}
            studentFeedback={feedback}
            formatDate={formatDate}
            removeHypens={removeHypens}
          />
        </div>
      </div>
    </DashboardLayoutv2>
  )
}
