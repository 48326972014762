import React from "react"

const GoldCoin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M13 25.2933C19.7719 25.2933 25.2934 20.3851 25.2934 13.4777C25.2934 6.59145 19.7936 1.00148 13 1.00148C6.20635 1.00148 0.706558 6.59145 0.706558 13.4777C0.706558 20.3851 6.22814 25.2933 13 25.2933Z"
        fill="#BE2C01"
        stroke="#BB2900"
        stroke-width="0.586883"
      />
      <g filter="url(#filter0_ddi_3034_109559)">
        <path
          d="M24.4215 12.6547C24.4215 19.0914 19.3078 23.6775 12.9998 23.6775C6.69179 23.6775 1.57812 19.0914 1.57812 12.6547C1.57812 6.21798 6.69179 1 12.9998 1C19.3078 1 24.4215 6.21798 24.4215 12.6547Z"
          fill="url(#paint0_linear_3034_109559)"
        />
      </g>
      <g filter="url(#filter1_di_3034_109559)">
        <ellipse cx="13.0008" cy="12.3768" rx="8.53012" ry="8.66002" fill="url(#paint1_linear_3034_109559)" />
        <path
          d="M21.482 12.3768C21.482 17.1333 17.6842 20.9879 13.0008 20.9879C8.31747 20.9879 4.51961 17.1333 4.51961 12.3768C4.51961 7.62033 8.31747 3.7657 13.0008 3.7657C17.6842 3.7657 21.482 7.62033 21.482 12.3768Z"
          stroke="#D98C00"
          stroke-width="0.0978139"
        />
      </g>
      <path
        d="M15.9889 10.0055L15.6423 11.5053H9.16355L9.51507 10.0055H15.9889ZM12.7325 18.2542L9.13201 13.938L9.12713 12.8H10.9993C11.4029 12.8 11.7397 12.7299 12.0099 12.5898C12.2833 12.4463 12.49 12.2464 12.6299 11.9901C12.7731 11.7303 12.8447 11.4262 12.8447 11.0776C12.8447 10.5547 12.6983 10.1344 12.4053 9.81657C12.1124 9.49875 11.6437 9.33984 10.9993 9.33984L9.16107 9.25563L9.54436 7.75586H10.9993C11.8195 7.75586 12.4981 7.88914 13.0351 8.1557C13.5754 8.41884 13.979 8.79134 14.2459 9.2732C14.5161 9.75506 14.6512 10.3189 14.6512 10.9648C14.6512 11.5355 14.5519 12.0447 14.3533 12.4924C14.1548 12.9401 13.8423 13.3075 13.416 13.5945C12.9896 13.8816 12.4314 14.073 11.7414 14.1687L11.7714 14.2815L14.857 18.2542H14.7683H12.7325ZM15.9987 7.75586L15.6423 9.11942L10.389 9.09891L10.7454 7.75586H15.9987Z"
        fill="#E97A07"
      />
      <path
        d="M16.5074 10.0055L16.1608 11.5053H9.6821L10.0336 10.0055H16.5074ZM13.251 18.2542L9.65056 13.938L9.64568 12.8H11.5178C11.9214 12.8 12.2583 12.7299 12.5284 12.5898C12.8018 12.4463 13.0085 12.2464 13.1485 11.9901C13.2917 11.7303 13.3633 11.4262 13.3633 11.0776C13.3633 10.5547 13.2168 10.1344 12.9239 9.81657C12.631 9.49875 12.1623 9.33984 11.5178 9.33984L9.67962 9.25563L10.0629 7.75586H11.5178C12.338 7.75586 13.0167 7.88914 13.5537 8.1557C14.094 8.41884 14.4976 8.79134 14.7645 9.2732C15.0346 9.75506 15.1697 10.3189 15.1697 10.9648C15.1697 11.5355 15.0704 12.0447 14.8719 12.4924C14.6734 12.9401 14.3609 13.3075 13.9345 13.5945C13.5081 13.8816 12.9499 14.073 12.2599 14.1687L12.29 14.2815L15.3755 18.2542H15.2869H13.251ZM16.5172 7.75586L16.1608 9.11942L10.9075 9.09891L11.2639 7.75586H16.5172Z"
        fill="#FFF100"
      />
      <path
        d="M16.0465 7.99036H10.0195L10.0878 7.75H16.5185L16.1677 9.10161H14.688L14.7027 9.1286C14.853 9.40407 14.9757 9.69368 15.0692 9.99322H16.5185L16.1677 11.5152C15.8495 11.5152 15.1823 11.5709 15.1547 11.5709C15.1777 12.1672 15.0131 12.5723 14.5733 13.0402C14.2292 13.4063 13.6714 13.9922 12.2979 14.1738L15.398 18.2542H14.7305L11.5794 14.0599C14.4379 13.5096 14.8428 11.8314 14.688 11.0611H15.6885L15.8671 10.3378H14.688L14.1163 8.73252H15.8671L16.0465 7.99036Z"
        fill="white"
      />
      <path
        d="M6.13268 17.659C6.57501 17.5237 7.04024 17.7735 7.17179 18.217L7.37957 18.9174C6.93724 19.0526 6.47201 18.8028 6.34046 18.3593L6.13268 17.659Z"
        fill="#FFF73A"
      />
      <path
        d="M7.76585 22.5C8.29734 22.0234 9.11754 22.066 9.59784 22.595L10.3366 23.4088C9.80509 23.8854 8.98488 23.8428 8.50459 23.3138L7.76585 22.5Z"
        fill="#EEA50A"
      />
      <mask
        id="mask0_3034_109559"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <path
          d="M13 25.2914C19.7719 25.2914 25.2934 20.3832 25.2934 13.4757C25.2934 6.5895 19.7936 0.999527 13 0.999527C6.20635 0.999527 0.706558 6.5895 0.706558 13.4757C0.706558 20.3832 6.22814 25.2914 13 25.2914Z"
          fill="#BE2C01"
          stroke="#BB2900"
          stroke-width="0.586883"
        />
      </mask>
      <g mask="url(#mask0_3034_109559)">
        <g style={{ mixBlendMode: "soft-light" }}>
          <ellipse cx="23.2661" cy="7.38637" rx="13.4458" ry="13.6505" fill="white" fill-opacity="0.4" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddi_3034_109559"
          x="1.57812"
          y="1"
          width="22.8438"
          height="23.9493"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.27158" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.807843 0 0 0 0 0.372549 0 0 0 0 0.0235294 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3034_109559" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.293442" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.933333 0 0 0 0 0.647059 0 0 0 0 0.0392157 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3034_109559" result="effect2_dropShadow_3034_109559" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3034_109559" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.293442" dy="0.293442" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_3034_109559" />
        </filter>
        <filter
          id="filter1_di_3034_109559"
          x="4.37289"
          y="3.7168"
          width="17.1584"
          height="17.7111"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.0978139" dy="0.391256" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.960784 0 0 0 0 0.215686 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3034_109559" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3034_109559" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.586883" dy="0.978139" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.904167 0 0 0 0 0.48414 0 0 0 0 0.0791146 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3034_109559" />
        </filter>
        <linearGradient
          id="paint0_linear_3034_109559"
          x1="17.3372"
          y1="0.229405"
          x2="8.02375"
          y2="23.9129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF649" />
          <stop offset="1" stop-color="#FDC101" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3034_109559"
          x1="15.8562"
          y1="3.7168"
          x2="8.72898"
          y2="23.0594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FECB02" />
          <stop offset="1" stop-color="#FC9700" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GoldCoin
