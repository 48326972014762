import React from "react"
import { FilterIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"

export default function Select({ id, className, value, onChange, options, mentorFilter }: any) {
  const selectArray = ["Choose Below", ...options]

  return (
    <div className={`relative ${mentorFilter ? "w-[250px]" : "w-full"}`}>
      <div className="absolute inset-y-0 right-0 flex items-center px-1 pointer-events-none">
        <label className="rounded px-0 py-1 text-sm text-new-neutral">
          <FilterIcon />
        </label>
      </div>
      <select
        id={id}
        className={cn(
          "w-full max-w-lg para text-new-neutral px-1 py-1 bg-neutral-50 rounded border border-new-neutral-light outline-none appearance-none"
        )}
        value={value}
        onChange={onChange}
      >
        {selectArray?.map((header: any, index: number) => (
          <option key={index} value={header}>
            {header}
          </option>
        ))}
      </select>
    </div>
  )
}
