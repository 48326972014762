import { useNavigate } from "react-router-dom"
import { STR_FAILURE } from "core/constants/strings"

import { GetDocumentationFiles } from "domain/useCase/Admin/DocumentationFiles/GetDocumentationFiles"
import { DocumentationFilesRepositoryImpl } from "data/repository/Admin/DocumentationFilesRepositoryImpl"
import DocumentationFilesAPIDataSourceImpl from "data/API/Admin/DocumentationFilesAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import React from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import { isEmpty } from "core/utils/misc"

const DocumentationViewModel = () => {
  const [documentationListLocally, setDocumentationListLocally] = useLocalStorage<any>("list", [])

  const [documentationList, setDocumentationList] = React.useState<any[]>(() => documentationListLocally)
  const [searchFile, setSearchFile] = React.useState<any>("")
  const [loading, setLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const getDocumentationFilesUseCase = new GetDocumentationFiles(
    new DocumentationFilesRepositoryImpl(new DocumentationFilesAPIDataSourceImpl())
  )

  const fetchDocumentationFiles = async () => {
    if (isEmpty(documentationList)) setLoading(true)

    const response = await getDocumentationFilesUseCase.invoke(auth)
    if (isEmpty(documentationList)) setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.error, "Couldn't fetch document files !!"))
      changeToastVisibility(true)
      return
    }

    setDocumentationList(response?.data)
    setDocumentationListLocally(response?.data)
  }
  const handleSearchDocumentationFileChange = (e: any) => {
    setSearchFile(e.target.value)
  }
  const handleSearchDocumentation = () => {
    return function (documentationfile: any) {
      return searchFile === "" || documentationfile?.feature?.toLowerCase()?.includes(searchFile?.toLowerCase())
    }
  }
  const redirectToDocmentationFile = (id: string) => {
    navigate(`/admin/documentation_files/${id}`)
  }

  return {
    toast,
    searchFile,
    documentationList,
    loading,
    fetchDocumentationFiles,
    redirectToDocmentationFile,
    handleSearchDocumentation,
    handleSearchDocumentationFileChange,
  }
}

export default DocumentationViewModel
