export default function getTimeLeft(e: number) {
  const total: number = (new Date(e).getTime() - new Date().getTime()) / 1000
  const seconds = Math.max(Math.floor(total % 60), 0)
  const minutes = Math.max(Math.floor((total / 60) % 60), 0)
  const hours = Math.max(Math.floor((total / 60 / 60) % 24), 0)
  const days = Math.max(Math.floor(total / 60 / 60 / 24), 0)
  return {
    days,
    hours,
    minutes,
    seconds,
  }
}
