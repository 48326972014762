import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface getSubjectiveQuestionListUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetSubjectiveQuestionList implements getSubjectiveQuestionListUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getsubjectiveQuestionList(auth)
  }
}
