export default function ThumbDown(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.27119 10.6686H2.00356C1.64986 10.6686 1.31064 10.5281 1.06054 10.278C0.81043 10.0279 0.669923 9.6887 0.669923 9.335V7.93201C0.669743 7.75773 0.703724 7.58511 0.769945 7.4239L2.83441 2.41343C2.88461 2.29123 2.96997 2.18671 3.07967 2.1131C3.18937 2.0395 3.31845 2.00013 3.45055 2H14.6731C14.85 2 15.0196 2.07025 15.1446 2.19531C15.2697 2.32036 15.3399 2.48997 15.3399 2.66682V9.335C15.3399 9.51185 15.2697 9.68146 15.1446 9.80651C15.0196 9.93156 14.85 10.0018 14.6731 10.0018H12.3512C12.2445 10.0018 12.1393 10.0274 12.0445 10.0765C11.9497 10.1256 11.8681 10.1967 11.8065 10.2839L8.17029 15.4357C8.12433 15.5009 8.05654 15.5474 7.97922 15.5668C7.90191 15.5863 7.82018 15.5774 7.74886 15.5417L6.53926 14.9363C6.19884 14.7661 5.92689 14.4846 5.76859 14.1385C5.61029 13.7925 5.57517 13.4026 5.66906 13.0338L6.27119 10.6686ZM11.339 8.94291V3.33364H3.89732L2.00356 7.93201V9.335H6.27119C6.47431 9.33503 6.67473 9.38145 6.85717 9.47073C7.03962 9.56 7.19926 9.68977 7.32392 9.85013C7.44858 10.0105 7.53496 10.1972 7.57648 10.396C7.618 10.5948 7.61356 10.8005 7.56349 10.9974L6.96135 13.3632C6.94251 13.437 6.9495 13.5151 6.98116 13.5843C7.01283 13.6536 7.06725 13.71 7.13539 13.744L7.57616 13.9641L10.7169 9.51504C10.8836 9.27899 11.097 9.08561 11.339 8.94291ZM12.6726 8.66818H14.0063V3.33364H12.6726V8.66818Z"
        fill="currentColor"
      />
    </svg>
  )
}
