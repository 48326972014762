import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { isEmpty } from "core/utils/misc"
import { genError } from "core/utils/string"
import { useState, useEffect, useLayoutEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { GetModuleClasses } from "domain/useCase/Admin/CreateClass/GetModuleClasses"
import DocDataSourceImpl from "data/API/Admin/DocDataSourceImpl"
import { DocRepositoryImpl } from "data/repository/Admin/DocRepositoyImpl"
import { UploadImage } from "domain/useCase/Admin/Document/UploadImage"
import { UploadDocument } from "domain/useCase/Admin/Document/UploadDocument"
import QuestionChamberDataSourceImpl from "data/API/Admin/QuestionChamberDataSourceImpl"
import { QuestionChamberRepositoryImpl } from "data/repository/Admin/QuestionChamberRepositoryImpl"
import { CreateSubCaseQuestion } from "domain/useCase/Admin/QuestionChamber/CreateSubCaseQuestion"
import { UpdateSubCaseQuestion } from "domain/useCase/Admin/QuestionChamber/UpdateCaseStudyQuestion"
import { GetAllQuestions } from "domain/useCase/Admin/QuestionChamber/GetAllQuestion"
import { GetQuestion } from "domain/useCase/Admin/QuestionChamber/GetQuestion"

export default function SubjectiveQuestionViewModel() {
  const navigate = useNavigate()
  const { op, id } = useParams()
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  /**
   * Navigate to previous page
   */
  const GoBack: Function = () => {
    try {
      navigate(-1)
    } catch (error) {
      console.error("Navigation error:", error)
    }
  }

  const [allCourses, setAllCourses] = useState<any[]>([])
  const [allModules, setAllModules] = useState<any[]>([])
  const [allTopics, setAllTopics] = useState<any[]>([])
  const [selectedTopic, setSelectedTopic] = useState<string>("")
  /**
   * All required interfaces
   */
  interface Field {
    type: string
    content: string
    file?: Blob
  }

  interface Response {
    questionType: string
    subTitle: string
    content: string
    coins: number
    id: string
    order: number
    fields: Array<Field>
    options?: { [key: number]: Array<Field> }
    solution?: string | { [key: number]: string }
    solutionDescription?: any
  }

  interface Question {
    questionType: string
    status: string
    course: string
    module: string
    topic: string
    otherTopic: string
    questionTitle: string
    difficultyLevel: string
    coins: number
    fields: Array<Field>
    response?: Array<Response>
    solution?: string
  }

  /**
   * Main tabs
   */
  const TABS: string[] = ["Question", "Response", "Solution"]

  /**
   * Subjective Type questions data
   */
  const [questionDetails, setQuestionDetails] = useState<{
    type: string
    status: string
    course: string
    module: string
    topic: string
    otherTopic: string
    title: string
    difficultyLevel: string
    coins: number
  }>({} as any)
  const [questionFields, setQuestionFields] = useState<Field[]>([])
  const [subjectiveSolution, setSubjectiveSolution] = useState<string>("")
  /**
   * Question Response is only for casestudy type questions
   */
  const [questionResponse, setQuestionResponse] = useState<
    {
      type: string
      subTitle: string
      content: string
      coins: number
      order: number
    }[]
  >([])

  const [questionResponseFields, setQuestionResponseFields] = useState<{
    [key: number]: Field[]
  }>()

  const [questionResponseOptions, setQuestionResponseOptions] = useState<{
    [key: number]: { [key: number]: Field[] }
  }>()

  const [caseStudySolution, setCaseStudySolution] = useState<{
    [key: number]: string | { [key: number]: string }
  }>({} as any)
  const [solutionFields, setSolutionFields] = useState<{
    [key: number]: Field[]
  }>({})

  useEffect(() => {
    fetchAllCourses()
  }, [])

  /**
   * ALL APIs Use Cases
   */
  const getAllCoursesUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllModulesUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  const getAllTopicsUseCase = new GetModuleClasses(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const uploadImageUseCase = new UploadImage(new DocRepositoryImpl(new DocDataSourceImpl()))

  const uploadDocumentUseCase = new UploadDocument(new DocRepositoryImpl(new DocDataSourceImpl()))

  const getAllQuestionsUseCase = new GetAllQuestions(
    new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl())
  )

  const getQuestionUseCase = new GetQuestion(new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl()))

  const createSubCaseQuestionUseCase = new CreateSubCaseQuestion(
    new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl())
  )

  const updateSubCaseQuestionUseCase = new UpdateSubCaseQuestion(
    new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl())
  )

  /**
   * Duplicate Question
   */
  const loadQuestion = async (id: string) => {
    setPageLoading(true)
    const response = await getQuestionUseCase.invoke(auth, { id: id })
    setPageLoading(false)

    if (!response?.success) {
      changeToastDetails(
        STR_FAILURE,
        genError(response?.message, "Error in loading the question, please refresh the page")
      )
      changeToastVisibility(true)
      return
    }

    const data = response.data
    const questionType = data.questionType || ""
    fetchAllModules(data.course)
    fetchAllTopics(data.module)

    setQuestionDetails({
      type: data.questionType,
      status: data.status,
      course: data.course,
      module: data.module,
      topic: data.topic,
      otherTopic: data.otherTopic,
      title: data.questionTitle,
      difficultyLevel: data.difficultyLevel || data.diffcultyLevel,
      coins: data.coins,
    })
    setQuestionFields(data.fields)

    if (questionType === "Case Study") {
      // set the question response
      const allResponses = data.subQuestions
      allResponses.sort((a: any, b: any) => a.order - b.order)
      // const questionresponse = allResponses.map((res: any) => ({
      //   type: res.questionType,
      //   subTitle: res.subTitle,
      //   content: res.content,
      //   coins: res.coins,
      //   id: res.id,
      //   order: res.order,
      // }))

      setQuestionResponse(
        allResponses.map((res: any) => ({
          type: res.questionType,
          subTitle: res.subTitle,
          content: res.content,
          coins: res.coins,
          id: res.id,
          order: res.order,
        }))
      )

      const responseFields: { [key: number]: Field[] } = {}
      allResponses.forEach((res: any, index: number) => {
        responseFields[index] = res.fields
      })
      setQuestionResponseFields(responseFields)

      const responseOptions: { [key: number]: {} } = {}
      const responseSolution: { [key: number]: string | {} } = {}
      const responseSolutionDescription: any = {}

      allResponses.forEach((res: any, index: number) => {
        const resOptions = res.options
        const resSolution = res.solution
        const resSolutionDescription = res?.solutionDescription

        if (!isEmpty(resOptions)) {
          responseOptions[index] = resOptions
        }

        if (!isEmpty(resSolution)) {
          responseSolution[index] = resSolution
        }

        if (!isEmpty(resSolutionDescription)) {
          responseSolutionDescription[index] = resSolutionDescription
        }
      })
      setCaseStudySolution(responseSolution)
      setQuestionResponseOptions(responseOptions)
      setSolutionFields(responseSolutionDescription)
    } else {
      setSubjectiveSolution(data.solution)
    }
  }

  /**
   * Fetch all the courses or tracks
   */
  const fetchAllCourses = async () => {
    setLoading(true)
    const response = await getAllCoursesUseCase.invoke(auth)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the courses"))
      changeToastVisibility(true)
      return
    }
    setAllCourses(response.data)
  }

  /**
   * Fetch all the Modules based on the selected course
   * @param course
   */
  const fetchAllModules = async (course: string) => {
    setLoading(true)
    const response = await getAllModulesUseCase.invoke(auth, course)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the modules"))
      changeToastVisibility(true)
      return
    }
    setAllModules(response.data?.modules)
  }

  /**
   * Fetch all the topics based on selected course
   * @param module
   */
  const fetchAllTopics = async (module: string) => {
    setLoading(true)
    const response = await getAllTopicsUseCase.invoke({
      id_token: auth.id_token,
      module: module,
    })
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the topics"))
      changeToastVisibility(true)
      return
    }
    setAllTopics(response.data)
  }

  useLayoutEffect(() => {
    if ((op === "duplicate" || op === "edit") && id !== undefined) {
      loadQuestion(id)
    }
  }, [])

  /**
   * Read the Image data DataURL
   * @param file
   * @param filename
   * @returns {Promise<any>}
   */
  function readImageAsDataURL(file: File, filename: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (evt) => {
        const data = evt?.target?.result
        resolve(data)
      }

      reader.onerror = () => {
        reject("Failed to load the image")
      }

      reader.readAsDataURL(file)
    })
  }

  // Assuming readDocument is a function to read document files asynchronously
  const readDocument = (file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
      // Example asynchronous file reading logic
      const reader = new FileReader()
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          resolve(event.target.result)
        } else {
          reject("Failed to read the document")
        }
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  function resetQuestionResponse() {
    setQuestionResponse([])
    setQuestionResponseFields(undefined)
    setQuestionResponseOptions(undefined)
  }

  function resetQuestionResponseOptions(responseId: number) {
    delete questionResponseOptions?.[responseId]
    setQuestionResponseOptions(questionResponseOptions)
  }

  function resetQuestionSolution() {
    setSubjectiveSolution("")
    setCaseStudySolution({} as any)
  }

  function resetAllValues() {
    setQuestionDetails({} as any)
    setQuestionFields([])
    setSolutionFields({})
    resetQuestionResponse()
    resetQuestionSolution()
  }

  /*
    For handling the Subject Type question
    */
  const handleQuestionDetails: Function = (e: any, name: any) => {
    if (name === "type") {
      // when the question type change reset the [Subjective and Case Study]
      // question response and solution
      resetQuestionResponse()
      resetQuestionSolution()
      setQuestionFields([])
    }

    if (name === "course") {
      fetchAllModules(e.target.value)
    }

    if (name === "module") {
      setAllTopics([])
      fetchAllTopics(e.target.value)
      setQuestionDetails((questionDetails) => ({
        ...questionDetails,
        topic: "",
      }))
    }

    setQuestionDetails({
      ...questionDetails!,
      [name]: e.target.value,
    })
  }

  const handleQuestionFields: Function = (op: string, id: number) => {
    if (op === "Add") {
      setQuestionFields([
        ...questionFields!,
        {
          type: "text",
          content: "",
        },
      ])
    } else {
      setQuestionFields(questionFields.filter((_, index) => index !== id))
    }
  }

  const handleAddSolutionFields: Function = (responseId: number) => {
    setSolutionFields((prevFields: any) => {
      let solFields = prevFields ?? {}
      let fields = solFields?.[responseId] || []
      fields = [...fields, { type: "description", content: "" }]
      solFields[responseId] = fields
      return solFields
    })
  }

  const handleRemoveSolutionFields: Function = (responseId: number, id: number) => {
    setSolutionFields((prevFields: any) => {
      let solFields = prevFields ?? {}
      let fields = solFields?.[responseId] || []
      solFields[responseId] = fields.filter((_: any, index: any) => index !== id)
      return solFields
    })
  }

  const handleQuestionFieldChange: Function = (e: any, id: number, name: string) => {
    if (name === "type") {
      setQuestionFields(
        questionFields?.map((questionField, index) =>
          id === index ? { type: e.target.value, content: "" } : questionField
        )
      )
    } else if (name === "content") {
      setQuestionFields(
        questionFields?.map((questionField: any, index: number) =>
          id === index ? { ...questionField, content: e.target.value, order: id } : questionField
        )
      )
    } else if (name === "image") {
      const file = e.target.files[0]
      const filename = file.name

      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"]

      if (!allowedTypes.includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .png, .jpg and .jpeg is alllowed")
      } else {
        /**
         * Image handling logic goes here
         */
        readImageAsDataURL(file, filename)
          .then((data) => {
            setQuestionFields(
              questionFields.map((questionField, index) =>
                id === index ? { ...questionField, content: data, file, order: id } : questionField
              )
            )
          })
          .catch((error) => {
            changeToastVisibility(true)
            changeToastDetails(STR_FAILURE, genError(error, "Something went wrong!"))
          })
      }
    } else if (name === "document") {
      /**
       * File will be .doc, .xls, .pdf
       */
      const file = e.target.files[0]
      const filename = file.name

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]

      if (!allowedTypes.includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .doc, .xls, .xlsx and .pdf is alllowed")
      } else {
        /**
         * File handling logic goes here
         */

        setQuestionFields(
          questionFields.map((questionField, index) =>
            id === index
              ? {
                  ...questionField,
                  content: filename,
                  file,
                  filename,
                  order: id,
                }
              : questionField
          )
        )
      }
    }
  }

  const handleSolutionFieldChange: Function = async (e: any, responseId: number, id: number, name: string) => {
    if (name === "type") {
      setSolutionFields((prevFields: any) => {
        const newFields = { ...prevFields }
        const fields = newFields?.[responseId] || []
        newFields[responseId] = fields.map((field: any, index: number) =>
          index === id ? { ...field, type: e.target.value, content: "" } : field
        )
        return newFields
      })
    } else if (name === "content") {
      setSolutionFields((prevFields: any) => {
        const newFields = { ...prevFields }
        const fields = newFields?.[responseId] || []
        newFields[responseId] = fields.map((field: any, index: number) =>
          index === id ? { ...field, content: e.target.value } : field
        )
        return newFields
      })
    } else if (name === "image") {
      const file = e.target.files[0]
      const filename = file.name

      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"]

      if (!allowedTypes.includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .png, .jpg and .jpeg is alllowed")
      } else {
        /**
         * Image handling logic goes here
         */
        const imgData = await readImageAsDataURL(file, filename)

        setSolutionFields((prevFields: any) => {
          const newFields = { ...prevFields }
          const fields = newFields?.[responseId] || []
          newFields[responseId] = fields.map((field: any, index: number) =>
            index === id ? { ...field, content: imgData, filename, file } : field
          )
          return newFields
        })
      }
    } else if (name === "document") {
      /**
       * File will be .doc, .xls, .pdf
       */
      const file = e.target.files[0]
      const filename = file.name

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]
      if (!allowedTypes.includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .doc, .xls, .xlsx and .pdf is allowed")
      } else {
        /**
         * File handling logic goes here
         */
        setSolutionFields((prevFields: any) => {
          const newFields = { ...prevFields }
          const fields = newFields?.[responseId] || []
          newFields[responseId] = fields.map((field: any, index: number) =>
            index === id ? { ...field, content: filename, filename, file } : field
          )
          return newFields
        })
      }
    }
  }

  /** ********************************************************************
   * Handle Question Responses
   *  This is for when question type is Case Study
   */
  const addQuestionResponse: Function = (e: any) => {
    setQuestionResponse([
      ...questionResponse,
      {
        type: "",
        subTitle: "",
        content: "",
        coins: 0,
        order: questionResponse.length,
      },
    ])
  }

  const removeQuestionResponse: Function = (id: number) => {
    const updatedResponse = questionResponse.filter((_, index) => id !== index)

    // Reassign the order field
    const reorderedResponse = updatedResponse.map((item, index) => ({
      ...item,
      order: index,
    }))
    setQuestionResponse(reorderedResponse)
  }

  const handleQuestionResponse: Function = (e: any, id: number, name: any) => {
    if (name === "type") {
      /* when type changes from MCQ to Subjective
            or vise-versa, clear all the options */
      resetQuestionResponseOptions(id)
    }
    setQuestionResponse(
      questionResponse?.map((content, index) => (id === index ? { ...content, [name]: e.target.value } : content))
    )
  }

  const addQuestionResponseFields: Function = (questionResponseId: number) => {
    const key = questionResponseId
    setQuestionResponseFields((prevFields) => ({
      ...prevFields,
      [key]: prevFields?.[key]
        ? [...prevFields[key], { type: "image", content: "" }]
        : [{ type: "image", content: "" }],
    }))
  }

  const removeQuestionResponseFields: Function = (questionResponseId: number, fieldId: number) => {
    const key = questionResponseId
    setQuestionResponseFields((prevFields) => {
      if (!prevFields || !prevFields[key]) {
        return prevFields
      }

      const updatedArray = prevFields[key].filter((_, index) => index !== fieldId)

      if (updatedArray.length > 0) {
        return { ...prevFields, [key]: updatedArray }
      }

      delete prevFields[key]
      return { ...prevFields }
    })
  }

  const handleQuestionResponseFieldsChange: Function = (
    e: any,
    questionResponseId: number,
    fieldId: number,
    name: string
  ) => {
    const key = questionResponseId
    if (name === "type") {
      setQuestionResponseFields({
        ...questionResponseFields!,
        [key]: questionResponseFields?.[key]
          ? questionResponseFields[key]?.map((content, index) =>
              index === fieldId ? { ...content, type: e.target.value, content: "" } : content
            )
          : [],
      })
    } else if (name === "content") {
      setQuestionResponseFields({
        ...questionResponseFields!,
        [key]: questionResponseFields?.[key]
          ? questionResponseFields[key]?.map((content, index) =>
              index === fieldId ? { ...content, content: e.target.value } : content
            )
          : [],
      })
    } else if (name === "image") {
      const file = e.target.files[0]
      const filename = file.name

      if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .png, .jpg and .jpeg is alllowed")
      } else {
        /**
         * Image uploading login goes here
         */
        readImageAsDataURL(file, filename)
          .then((data) => {
            setQuestionResponseFields({
              ...questionResponseFields!,
              [key]: questionResponseFields?.[key]
                ? questionResponseFields[key]?.map((content, index) =>
                    index === fieldId ? { ...content, content: data, file } : content
                  )
                : [],
            })
          })
          .catch((error) => {
            changeToastVisibility(true)
            changeToastDetails(STR_FAILURE, genError(error, "Failed to load the image"))
          })
      }
    } else if (name === "document") {
      const file = e.target.files[0]
      const documentUrl = URL.createObjectURL(file)
      window.open(documentUrl, "_blank")
    }
  }

  const addQuestionResponseOptions: Function = (questionResponseId: number) => {
    const key = questionResponseId
    setQuestionResponseOptions((prevOptions) => {
      const options: Field[][] = prevOptions?.[key] ? Object.values(prevOptions[key]) : []
      const keys: string[] = prevOptions?.[key] ? Object.keys(prevOptions[key]) : []
      const len = options?.[0]?.length || 1

      const option: Field[] = []
      for (let index = 0; index < len; index++) {
        option.push({ type: "text", content: "" })
      }

      let allOptions = { ...prevOptions?.[key], [keys.length]: option }
      return { ...prevOptions, [key]: allOptions }
    })
  }

  const removeQuestionResponseOptions: Function = (questionResponseId: number, optionId: number) => {
    const key = questionResponseId
    setQuestionResponseOptions((prevOptions) => {
      if (!prevOptions || !prevOptions[key]) {
        return prevOptions
      }
      const options: { [key: number]: Field[] } = prevOptions[key]
      delete options[optionId]
      return { ...prevOptions, [key]: options }
    })
  }

  const addQuestionResponseOptionField: Function = (questionResponseId: number) => {
    const key = questionResponseId
    setQuestionResponseOptions((prevOptions) => {
      if (!prevOptions || !prevOptions[key]) {
        return prevOptions
      }

      const options: { [key: number]: Field[] } = prevOptions[key]
      const keys: string[] = Object.keys(options)
      const values: Field[][] = Object.values(options)

      for (let index = 0; index < keys.length; index++) {
        let _key = parseInt(keys[index], 10)
        options[_key] = [...values[index], { type: "text", content: "" }]
      }

      return { ...prevOptions, [key]: options }
    })
  }

  const removeQuestionResponseOptionField: Function = (questionResponseId: number, fieldId: number) => {
    const key = questionResponseId
    setQuestionResponseOptions((prevOptions) => {
      if (!prevOptions || !prevOptions[key]) {
        return prevOptions
      }

      let isAbortDeletion = false
      const options: { [key: number]: Field[] } = prevOptions[key]
      const keys: string[] = Object.keys(options)
      const values: Field[][] = Object.values(options)

      for (let index = 0; index < keys.length; index++) {
        let _key = parseInt(keys[index], 10)
        if (values[index].length === 1) {
          isAbortDeletion = true
        } else {
          options[_key] = values[index].filter((_, index) => index !== fieldId)
        }
      }

      if (isAbortDeletion) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "You cannot delete a single field.")
      }

      return { ...prevOptions, [key]: options }
    })
  }

  const handleQuestionResponseOptionField: Function = (
    e: any,
    questionResponseId: number,
    optionId: number,
    fieldId: number,
    name: string
  ) => {
    const key = questionResponseId
    setQuestionResponseOptions((prevOptions) => {
      if (!prevOptions || !prevOptions[key]) {
        return prevOptions
      }

      let options = prevOptions[key]
      let fields = options[optionId]

      if (name === "type") {
        fields = fields.map((field, index) => (index === fieldId ? { ...field, type: e.target.value } : field))
      }

      if (name === "content") {
        fields = fields.map((field, index) => (index === fieldId ? { ...field, content: e.target.value } : field))
      }

      if (name === "image") {
        const file = e.target.files[0]
        const filename = file.name

        if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, "Only .png, .jpg and .jpeg is alllowed")
        } else {
          /**
           * Image uploading login goes here
           */
          readImageAsDataURL(file, filename)
            .then((data) => {
              fields = fields.map((field, index) => (index === fieldId ? { ...field, content: data, file } : field))

              options[optionId] = fields
              setQuestionResponseOptions({
                ...questionResponseOptions!,
                [questionResponseId]: options,
              })
              return
            })
            .catch((error) => {
              changeToastVisibility(true)
              changeToastDetails(STR_FAILURE, "Failed to load the image")
            })
        }
      }
      if (name === "document") {
        /**
         * File will be .doc, .xls, .pdf
         */
        const file: File | undefined = (e.target as HTMLInputElement)?.files?.[0]
        if (!file) return // Handle case when file is not selected

        const filename: string = file.name

        const allowedTypes: string[] = [
          "application/pdf",
          "application/msword",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]

        if (!allowedTypes.includes(file.type)) {
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, "Only .doc, .xls, .xlsx and .pdf are allowed")
        } else {
          /**
           * File handling logic goes here
           */
          readDocument(file)
            .then((data: any) => {
              fields = fields.map((field: any, index: number) =>
                index === fieldId ? { ...field, content: data, file } : field
              )

              options[optionId] = fields
              setQuestionResponseOptions({
                ...(questionResponseOptions || {}),
                [questionResponseId]: options,
              })
            })
            .catch((error: any) => {
              console.error(error)
              changeToastVisibility(true)
              changeToastDetails(STR_FAILURE, "Failed to load the document")
            })
        }
      }

      options[optionId] = fields
      return { ...prevOptions, [questionResponseId]: options }
    })
  }

  /**
   * Handle Question Solution
   */
  const handleSubjectiveQuestionSolution: Function = (e: any) => {
    setSubjectiveSolution(e.target.value)
  }

  const handleCaseStudyQuestionSolution: Function = (e: any, responseId: number, optionId: number) => {
    const key = responseId
    const data = e.target.value

    if (questionResponse[key].type === "MCQ") {
      setCaseStudySolution((prevData: any) => {
        let solutions = (prevData?.[key] as { [key: number]: string }) || {}
        solutions[optionId] = data
        return { ...prevData, [key]: solutions }
      })
    } else {
      setCaseStudySolution({
        ...caseStudySolution,
        [key]: data,
      })
    }
  }

  /**
     * @description If the content type is image, 
    then upload the image and replace the conent with image URL
     * @returns {Array<Field>}
     */
  async function processQuestionFields(fields: Array<Field>): Promise<Array<Field>> {
    const filterFields: Array<Field> = []
    await Promise.all(
      fields.map(async (question: any) => {
        if (question.type === "image" && question.file) {
          const file = question.file
          const formData = new FormData()
          formData.append("img", file)

          const response = await uploadImageUseCase.invoke(auth, formData)
          if (!response?.success) {
            throw new Error("Error in uploading an image")
          }

          question.content = response.data
          question.filename = question.file.name
          delete question["file"]
          filterFields.push(question)
        } else if (question.type === "document" && question.file) {
          const file = question.file
          const formData = new FormData()
          formData.append("doc", file)

          const response = await uploadDocumentUseCase.invoke(auth, formData)
          if (!response?.success) {
            throw new Error("Error in uploading document")
          }

          question.content = response.data
          question.filename = question.file.name
          delete question["file"]
          filterFields.push(question)
        } else {
          filterFields.push(question)
        }
      })
    )

    return filterFields
  }

  /**
   * @description Preprare all the responses
   * @returns {Array<Response>}
   */
  async function segregateQuestionResponse(): Promise<{
    responses: Array<Response>
    totalCoins: number
  }> {
    let totalCoins: number = 0 // Variable to store the total coins

    const responses: Array<Response> = []

    await Promise.all(
      questionResponse.map(async (response: any, index: number) => {
        const data: Response = {
          questionType: response?.type || "",
          subTitle: response?.subTitle || "",
          content: response?.content || "",
          id: response?.id || "",
          coins: parseInt(response?.coins) || 0,
          order: response?.order,
          fields:
            (await processQuestionFields(questionResponseFields?.[index] ? questionResponseFields[index] : [])) || [],
        }

        totalCoins += data.coins // Add coins from current response to total

        if (response.type === "MCQ") {
          if (questionResponseOptions?.[index]) {
            let _keys = Object.keys(questionResponseOptions?.[index])
            let values = Object.values(questionResponseOptions?.[index])

            let fields = await Promise.all(values.map(async (option) => await processQuestionFields(option)))

            let keys = _keys.map((_) => parseInt(_))

            const options: { [key: number]: Field[] } = {}
            for (let index = 0; index < fields.length; index++) {
              options[keys[index]] = fields[index]
            }

            data["options"] = options
          }
          data["solution"] = caseStudySolution?.[index]
          data["solutionDescription"] = await processQuestionFields(solutionFields?.[index] ?? [])
        } else if (response.type === "Subjective") {
          data["solution"] = caseStudySolution?.[index]
        }

        responses[index] = data
      })
    )

    return { responses, totalCoins }
  }

  /**
   * @description Preprare the question
   * @returns {Question}
   */
  async function segregateQuestion(): Promise<Question> {
    const question: Question = {
      questionType: questionDetails?.type || "",
      status: questionDetails?.status || "",
      course: questionDetails?.course || "",
      module: questionDetails?.module || "",
      topic: questionDetails?.topic || "",
      otherTopic: questionDetails?.otherTopic || "",
      questionTitle: questionDetails?.title || "",
      difficultyLevel: questionDetails?.difficultyLevel || "",
      coins: questionDetails?.coins || 0,
      fields: await processQuestionFields(questionFields),
    }

    if (questionDetails?.type === "Subjective") {
      question["solution"] = subjectiveSolution
    } else if (questionDetails?.type === "Case Study") {
      // solution will be added to each question response individually
      let { responses, totalCoins } = await segregateQuestionResponse()
      question["response"] = responses
      if (totalCoins.toString() !== questionDetails.coins.toString()) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Coins mismatch with the Total coins")
        throw new Error("Coins mismatch with the total coins of the responses")
      }
    }

    if (question.topic === "Others" && question.otherTopic === "") {
      throw new Error("Other topic must have a value when topic is 'others'")
    }

    Object.entries(question).forEach(([key, value]) => {
      if (
        key === "otherTopic" ||
        key === "fields" ||
        key === "solution" ||
        key === "response" ||
        (op === "edit" && key === "topic" && value !== "")
      ) {
        return question
      }

      if (
        (key === "coins" ||
          key === "questionType" ||
          key === "course" ||
          key === "difficultyLevel" ||
          key === "module" ||
          key === "questionTitle" ||
          key === "status" ||
          key === "topic") &&
        value !== ""
      ) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, `Changes Saved Partially!`)
      } else {
        throw new Error("All fields are required")
      }
    })
    return question
  }

  const handleQuestionSaveChanges: Function = async () => {
    try {
      setLoading(true)
      const question: Question = await segregateQuestion()
      let response

      if (op === "edit") {
        response = await updateSubCaseQuestionUseCase.invoke(auth, {
          ...question,
          id,
        })
      } else {
        // in case of question duplicate and question add
        response = await createSubCaseQuestionUseCase.invoke(auth, question)
      }

      setLoading(false)

      if (!response.success) {
        throw new Error("Error in creating question, Try again!")
      }

      if (op !== "edit") {
        resetAllValues()
      }

      if (op === "duplicate") {
        changeToastDetails(STR_SUCCESS, `Question duplicated successfully!`)
      } else if (op === "edit") {
        changeToastDetails(STR_SUCCESS, `Question edited successfully!`)
      } else {
        changeToastDetails(STR_SUCCESS, `Question added successfully!`)
      }
      changeToastVisibility(true)
    } catch (error: any) {
      setLoading(false)
      changeToastDetails(STR_FAILURE, genError({ error: error.message }))
      changeToastVisibility(true)
    }
  }

  return {
    TABS,
    allCourses,
    allModules,
    allTopics,
    fetchAllModules,
    fetchAllTopics,
    GoBack,
    pageLoading,
    loading,
    selectedTopic,
    toast,
    changeToastVisibility,
    questionFields,
    solutionFields,
    questionDetails,
    questionResponse,
    questionResponseFields,
    handleQuestionFields,
    handleAddSolutionFields,
    handleRemoveSolutionFields,
    handleQuestionFieldChange,
    handleQuestionDetails,
    addQuestionResponse,
    removeQuestionResponse,
    handleQuestionResponse,
    addQuestionResponseFields,
    removeQuestionResponseFields,
    handleQuestionResponseFieldsChange,
    questionResponseOptions,
    addQuestionResponseOptions,
    removeQuestionResponseOptions,
    addQuestionResponseOptionField,
    removeQuestionResponseOptionField,
    handleQuestionResponseOptionField,
    subjectiveSolution,
    caseStudySolution,
    setCaseStudySolution,
    handleSubjectiveQuestionSolution,
    handleCaseStudyQuestionSolution,
    handleSolutionFieldChange,
    handleQuestionSaveChanges,
    getAllQuestionsUseCase,
  }
}
