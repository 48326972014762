import { useAuth } from "core/context/auth"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import GetAllInstructorDetails from "domain/useCase/Admin/GenerateInvoice/GetAllInstructorDetails"
import GetInstructorDetails from "domain/useCase/Admin/GenerateInvoice/GetInstructorDetails"

import FinaliseInvoiceOfAMentor from "domain/useCase/Admin/GenerateInvoice/FinaliseInvoiceOfAMentor"
import updateInstructorDetails from "domain/useCase/Admin/GenerateInvoice/UpdateInstructorDetails"
import React, { useState } from "react"
import { Auth } from "domain/model/Auth"
import GetAllSessionOfMonthYear from "domain/useCase/Admin/GenerateInvoice/GetAllSessionOfMonthYear"
import UpdateInstructorDetails from "domain/useCase/Admin/GenerateInvoice/UpdateInstructorDetails"
import UpdateInstructorCompensationDetails from "domain/useCase/Admin/GenerateInvoice/UpdateInstructorCompensationDetails"
import GetInstructorAverageRating from "domain/useCase/Admin/GenerateInvoice/GetInstructorAverageRating"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { genError } from "core/utils/string"
import GetInstructorIncentiveDetails from "domain/useCase/Admin/GenerateInvoice/GetInstructorIncentiveDetails"
import UpdateInstructorIncentiveDetails from "domain/useCase/Admin/GenerateInvoice/UpdateInstructorIncentiveDetails"
import ConfirmInvoiceOfMentor from "domain/useCase/Admin/GenerateInvoice/ConfirmInvoiceOfMentor"
import { GetAllModules } from "domain/useCase/Admin/CreateClass/GetAllModules"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { GetAllInstructors } from "domain/useCase/Admin/CreateClass/GetAllInstructors"

export default function GenerateInvoiceViewModel() {
  const { auth } = useAuth()
  const [instructor_id, setInstructorId] = React.useState<string>("")
  const [yearMonth, setYearMonth] = React.useState<string>("")
  const [time_given, setTimeGiven] = React.useState<string>("")
  const [year, setYear] = React.useState<string>("")
  const [month, setMonth] = React.useState<string>("")
  const [sessionName, setSessionName] = React.useState("")
  const [compensationAmount, setCompensationAmount] = React.useState<string>("")
  const [selectModuleId, setSelectModuleId] = React.useState("")
  const [moduleId, setModuleId] = React.useState([])
  const [allInstructorDetails, setAllInstructorDetails] = React.useState<any>("")
  const [instructorDetails, setInstructorDetails] = React.useState<any>("")
  const [instructorList, setInstructorList] = React.useState<any>([])
  const [updatedInstructorDetails, setUpdatedInstructorDetails] = React.useState<any>()
  const [finalInvoice, setFinalInvoice] = React.useState<any>()
  const [confirmStatus, setConfirmStatus] = React.useState<any>()
  const [Loading, setLoading] = React.useState<boolean>(false)
  const [incentiveLoading, setIncentiveLoading] = React.useState<boolean>(false)
  const [updateClassBool, setUpdateClassBool] = React.useState(false)
  const [updatedInstructorCompensationDetails, setUpdatedInstructorCompensationDetails] = React.useState<any>({})
  const [updatedInstructorIncentiveDetails, setUpdatedInstructorIncentiveDetails] = React.useState<any>({})
  const [instructorIncentiveDetails, setInstructorIncentiveDetails] = React.useState<any>({})
  const [getBatchList, setBatchList] = React.useState<any>()
  const [batch, setBatch] = React.useState("")
  const [allSessionMonthYear, setAllSessionMonthYear] = React.useState<any>({})
  const [originalData, setOriginalData] = React.useState<any>()
  const [sessionDate, setSessionDate] = React.useState("")
  const [instructorAverageRating, setInstructorAverageRating] = React.useState<any>()
  const [allOriginalInstructor, setOriginalInstructor] = React.useState<any>()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  function handleSearchByInstructor(e: any) {
    if (e.target.value) setInstructorId(e.target.value)
    else setInstructorId("")
  }
  function handleSearchByCompensationAmount(e: any) {
    if (e.target.value) setCompensationAmount(e.target.value)
    else setCompensationAmount("")
  }

  function handleSearchByYear_month(e: any) {
    if (e.target.value) setYearMonth(e.target.value)
    else setYearMonth("")
  }

  function handleChangeModuleId(e: any) {
    setSelectModuleId(e.target.value)
  }

  function handleSessionsDate(e: any) {
    setSessionDate(e.target.value as string)
  }

  function handleSearchByBatch(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }

  function handleSessionName(e: any) {
    setSessionName(e.target.value)
  }

  const getAllModuleIdUseCase = new GetAllModules(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const GetAllInstructorDetailsUseCase = new GetAllInstructorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetInstructorAverageRatingUseCase = new GetInstructorAverageRating(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const UpdateInstructorDetailsUseCase = new UpdateInstructorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetInstructorDetailsUseCase = new GetInstructorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const UpdateInstructorCompensationDetailsUseCase = new UpdateInstructorCompensationDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetAllSessionOfMOnthYearUseCase = new GetAllSessionOfMonthYear(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const FinaliseInvoiceOfAMentorUseCase = new FinaliseInvoiceOfAMentor(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetInstructorIncentiveDetailsUseCase = new GetInstructorIncentiveDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const UpdateInstructorIncentiveDetailsUseCase = new UpdateInstructorIncentiveDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const ConfirmInvoiceOfMentorUseCase = new ConfirmInvoiceOfMentor(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllQuestonsUseCase = new GetAllInstructors(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const fetchAllInstructorDetails = async () => {
    const response = await GetAllInstructorDetailsUseCase.invoke(auth)

    if (!response?.success) {
      return
    }
    setAllInstructorDetails(response?.data)
    setOriginalInstructor(response?.data)
  }

  const fetchInstructorAverageRating = async (auth: Auth, instructor_id: string) => {
    const response = await GetInstructorAverageRatingUseCase.invoke(auth, instructor_id)

    if (!response?.success) {
      return
    }
    setInstructorAverageRating(response?.data)
  }
  const fetchInstructorDetails = async (auth: Auth, instructor_id: string) => {
    const response = await GetInstructorDetailsUseCase.invoke(auth, instructor_id)
    if (!response?.success) {
      return
    }

    setInstructorDetails(response?.data)
  }

  const fetchUpdatedInstructorDetails = async (auth: Auth, instructor_id: string, time_given: string) => {
    const response = await UpdateInstructorDetailsUseCase.invoke(auth, instructor_id, time_given)

    if (!response?.success) {
      return
    }

    setUpdatedInstructorDetails(response?.data)
    return response?.data
  }

  const fetchUpdatedInstructorCompensationDetails = async (
    auth: Auth,
    instructor_id: string,
    compensationAmount: number,
    yearMonth: string
  ) => {
    const response = await UpdateInstructorCompensationDetailsUseCase.invoke(
      auth,
      instructor_id,
      compensationAmount,
      yearMonth
    )
    if (!response?.success) {
      return
    }

    setUpdatedInstructorCompensationDetails(response?.data)
  }

  const fetchAllSessionMonthyear = async (auth: Auth, instructor_id: string, year: string, month: string) => {
    const response = await GetAllSessionOfMOnthYearUseCase.invoke(auth, instructor_id, year, month)
    if (!response?.success) {
      return
    }

    setAllSessionMonthYear(response?.data)
    setOriginalData(response?.data)
  }

  const fetchInvoiceOfMentor = async (auth: Auth, instructor_id: string, yearMonth: string) => {
    const response = await FinaliseInvoiceOfAMentorUseCase.invoke(auth, instructor_id, yearMonth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      changeToastVisibility(true)
      return "Invoice Not generated"
    }

    changeToastDetails(STR_SUCCESS, ":)")
    changeToastVisibility(true)
    setFinalInvoice(response?.data)
    return response?.data
  }

  const fetchUpdatedInstructorIncentiveDetails = async (
    auth: Auth,
    instructor_id: string,
    incentiveAmount: number,
    incentiveYearMonth: string
  ) => {
    const response = await UpdateInstructorIncentiveDetailsUseCase.invoke(
      auth,
      instructor_id,
      incentiveAmount,
      incentiveYearMonth
    )
    if (!response?.success) {
      return
    }

    setUpdatedInstructorIncentiveDetails(response?.data)
  }

  const fetchInstructorIncentiveDetails = async (auth: Auth, instructor_id: string) => {
    const response = await GetInstructorIncentiveDetailsUseCase.invoke(auth, instructor_id)
    if (!response?.success) {
      return
    }

    setInstructorIncentiveDetails(response?.data)
  }

  const fetchConfirmedInvoiceOfMentor = async (auth: Auth, instructor_id: string, yearMonth: string) => {
    const response = await ConfirmInvoiceOfMentorUseCase.invoke(auth, instructor_id, yearMonth)
    if (!response?.success) {
      return
    }
    setConfirmStatus(response?.data)
    return response?.data
  }

  async function getAllModules() {
    const response = await getAllModuleIdUseCase.invoke(auth)

    if (response.success) setModuleId(response.data)
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }

  async function getInstructorsList() {
    const response = await getAllQuestonsUseCase.invoke(auth)

    if (response.success) {
      setInstructorList(response.data)
    }
  }
  return {
    auth,
    instructor_id,
    yearMonth,
    compensationAmount,
    time_given,
    year,
    month,
    batch,
    getBatchList,
    Loading,
    toast,
    instructorAverageRating,
    allInstructorDetails,
    instructorDetails,
    instructorIncentiveDetails,
    updatedInstructorDetails,
    updatedInstructorCompensationDetails,
    updatedInstructorIncentiveDetails,
    allSessionMonthYear,
    finalInvoice,
    incentiveLoading,
    confirmStatus,
    moduleId,
    selectModuleId,
    sessionName,
    sessionDate,
    originalData,
    instructorList,
    allOriginalInstructor,
    updateClassBool,
    setUpdateClassBool,
    setAllInstructorDetails,
    getInstructorsList,
    getBatches,
    setBatch,
    setSessionDate,
    setSessionName,
    handleSessionName,
    handleSessionsDate,
    getAllModules,
    handleChangeModuleId,
    changeToastDetails,
    changeToastVisibility,
    setFinalInvoice,
    setLoading,
    setIncentiveLoading,
    handleSearchByBatch,
    handleSearchByInstructor,
    handleSearchByYear_month,
    setYearMonth,
    setCompensationAmount,
    fetchInstructorAverageRating,
    handleSearchByCompensationAmount,
    fetchAllInstructorDetails,
    fetchInvoiceOfMentor,
    fetchInstructorDetails,
    fetchInstructorIncentiveDetails,
    fetchUpdatedInstructorDetails,
    fetchUpdatedInstructorIncentiveDetails,
    fetchUpdatedInstructorCompensationDetails,
    fetchConfirmedInvoiceOfMentor,
    fetchAllSessionMonthyear,
    setAllSessionMonthYear,
  }
}
