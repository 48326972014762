import { cn } from "core/lib/utils"

const NavBarIconHolder = ({ active, Icon, isHovered }: any) => {
  return (
    <div
      className={cn(
        "flex h-6 w-6 rounded border border-transparent",
        active ? "bg-white" : isHovered ? "border-gray-500" : "border-v2-gray-600"
      )}
    >
      <Icon
        className={cn("w-4 h-4 m-auto", active ? "text-[#001018]" : isHovered ? "text-white" : "text-v2-gray-400")}
      />
    </div>
  )
}

export { NavBarIconHolder }
