import React from "react"

export default function ProductAnalysis(data: any) {
  return (
    <div className="mx-4 my-8 w-80 rounded-lg border border-gray-300 p-4">
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Start Date</div>
        <div>{data.productAnalysis.start_date}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">End Date</div>
        <div>{data.productAnalysis.end_date}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Total Classes</div>
        <div>{data.productAnalysis.total_classes}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Avg Class Rating</div>
        <div>{data.productAnalysis.avg_class_rating}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Total Mentor Sessions</div>
        <div>{data.productAnalysis.total_session}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Avg Mentor Session Rating</div>
        <div>{data.productAnalysis.avg_session_rating}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Total Problems</div>
        <div>{data.productAnalysis.problem_solved}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Total Leetcode Problem</div>
        <div>{data.productAnalysis.total_leetcode_count}</div>
      </div>
      <div className="mb-2 flex justify-between">
        <div className="font-bold">Total Platform Problems</div>
        <div>{data.productAnalysis.total_platform_count}</div>
      </div>
    </div>
  )
}
