import useToast from "core/hooks/useToast"
import { useAuth } from "core/context/auth"
import { GetAllSSMHistorys } from "domain/useCase/Student/StudentProgress/GetAllSSMHistorys"
import { SSMHistoryRepositoryImpl } from "data/repository/Student/SSMHistoryRepositoryImpl"
import SSMHistoryDataSourceImpl from "data/API/Student/SSMHistoryAPIDataSourceImpls"
import { useState } from "react"

export default function ScheduleViewModel() {
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [meeting, setMeeting] = useState("")
  const [meetingDate, setMeetingDate] = useState("")
  const [meetingDuration, setMeetingDuration] = useState("")
  const [meetingSsmRemark, setMeetingSsmRemark] = useState("")
  const [rating, setRating] = useState(0)
  const [meetingAgenda, setMeetingAgenda] = useState("")
  const [feedback, setFeedback] = useState("")
  const [status, setStatus] = useState("")
  const [currentDate, setCurrentDate] = useState("")
  const [problemSolvingMilestoneDueDate, setProblemSolvingMilestoneDueDate] = useState("")
  const [developmentMilestoneDueDate, setDevelopmentMilestoneDueDate] = useState("")
  const [problemSolvingMilestone, setProblemSolvingMilestone] = useState("")
  const [developmentMilestone, setDevelopmentMilestone] = useState("")
  const tableHeaders: string[] = ["Sr. No.", "Date", "Duration", "Status", "Session Detail"]
  const [isSessionDetailOpen, setIsSessionDetailOpen] = useState<boolean>(false)
  const [scheduledCnt, setScheduledCnt] = useState(0)
  const [history, setHistory] = useState<any>([])
  const addHyphens = (dateString: string): string => {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const day = dateString.slice(6, 8)
    return `${year}-${month}-${day}`
  }
  const getAllSSMHistorysUseCase = new GetAllSSMHistorys(new SSMHistoryRepositoryImpl(new SSMHistoryDataSourceImpl()))
  function handleSessionDetail() {
    setIsSessionDetailOpen(!isSessionDetailOpen)
  }
  function handleUpdateDetailButton(
    meeting: string,
    meetingDate: string,
    meetingSsmRemark: string,
    meetingAgenda: string,
    meetingDuration: string,
    status: string,
    rating: number,
    feedback: string,
    psmdd: string,
    dmdd: string,
    psm: string,
    dm: string
  ) {
    setMeeting(meeting)
    setMeetingSsmRemark(meetingSsmRemark)
    setMeetingAgenda(meetingAgenda)
    setMeetingDuration(meetingDuration)
    setMeetingDate(addHyphens(meetingDate))
    setStatus(status)
    setRating(rating)
    setFeedback(feedback)
    setProblemSolvingMilestoneDueDate(psmdd)
    setDevelopmentMilestoneDueDate(dmdd)
    setProblemSolvingMilestone(psm)
    setDevelopmentMilestone(dm)
  }
  function getCurrentDate() {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 7)
    return currentDate.toISOString().slice(0, 10).replace(/-/g, "")
  }
  async function fetchStudentProgressHistory() {
    const response = await getAllSSMHistorysUseCase.invoke(auth)

    if (response.data) {
      const rawData = response.data
      let currentLargestDate: string | null = null
      let currentSecondLargestDate: string | null = null
      setCurrentDate(getCurrentDate())
      const scheduledCount = Object.values(rawData).filter(
        (entry: any) => entry.status === "Scheduled" && getCurrentDate() <= entry.meeting_date
      ).length

      const sortedDataArray = Object.entries(rawData)
        .sort((a, b) => +b[0] - +a[0])
        .map(([key, value]) => ({ [key]: value }))

      setScheduledCnt(scheduledCount)
      if (response?.success) {
        setHistory(sortedDataArray)
      }
    } else {
      setHistory([])
    }
  }
  function formatDate(dateString: string, status: string): string {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const day = dateString.slice(6, 8)

    const currentDate = new Date(`${year}-${month}-${day}`)
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    }

    if (status === "Scheduled") {
      const futureDate = new Date(currentDate)
      futureDate.setDate(currentDate.getDate() + 7)

      const formattedCurrentDate = currentDate.toLocaleDateString("en-US", options)
      const formattedFutureDate = futureDate.toLocaleDateString("en-US", options)

      return `${formattedCurrentDate} - ${formattedFutureDate}`
    } else if (status != "Scheduled") {
      const formattedDate = currentDate.toLocaleDateString("en-US", options)
      const day = currentDate.getDate()
      const daySuffix =
        day === 1 || day === 21 || day === 31
          ? "st"
          : day === 2 || day === 22
            ? "nd"
            : day === 3 || day === 23
              ? "rd"
              : "th"
      return `${day}${daySuffix} ${currentDate.toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      })}`
    } else {
      return "Invalid status"
    }
  }
  function removeHypens(dateString: string) {
    return dateString.replace(/-/g, "")
  }
  return {
    tableHeaders,
    toast,
    changeToastVisibility,
    fetchStudentProgressHistory,
    history,
    isSessionDetailOpen,
    setIsSessionDetailOpen,
    handleSessionDetail,
    handleUpdateDetailButton,
    meeting,
    meetingAgenda,
    meetingDate,
    meetingDuration,
    meetingSsmRemark,
    feedback,
    rating,
    status,
    problemSolvingMilestone,
    problemSolvingMilestoneDueDate,
    developmentMilestone,
    developmentMilestoneDueDate,
    formatDate,
    removeHypens,
    scheduledCnt,
    currentDate,
  }
}
