import { API_UPDATE_WALKTHROUGH_DATE, API_V4_ADMIN } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { UpdateWalkThroughDataSource } from "data/dataSource/Admin/UpdateWalkThroughDataSource"

export default class UpdateWalkThroughAPIDataSourceImpl implements UpdateWalkThroughDataSource {
  async updateWalkThroughDate(auth: any, student_id: string, new_walkthrough_date: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_WALKTHROUGH_DATE), {
        id_token: auth.id_token,
        student_id,
        new_walkthrough_date,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
