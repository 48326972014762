import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface UpdateStoreItemUseCase {
  invoke(auth: Auth, item: any, item_id: any): Promise<any>
}

export class UpdateStoreItem implements UpdateStoreItemUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, item: any, item_id: any) {
    return this.repository.updateStoreItem(auth, item, item_id)
  }
}
