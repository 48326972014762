import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "core/lib/utils"
import { IconHolder } from "./IconHolder"

const buttonVariants = cva(
  "inline-flex justify-center items-center gap-10 font-sans font-medium text-sm rounded disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        default: "bg-v2-accent-400 text-v2-white hover:bg-v2-accent-400",
        primary: "bg-v2-accent-400 text-v2-white hover:bg-v2-accent-200 active:bg-v2-gray-600 disabled:bg-v2-gray-400",
        secondary:
          "bg-white text-v2-gray-500 hover:border-v2-white hover:bg-v2-gray-200 hover:text-v2-gray-500 focus:bg-v2-accent-200 focus:text-v2-white disabled:bg-v2-white disabled:text-v2-gray-400",
        ternary:
          "bg-v2-white text-v2-gray-500 hover:bg-v2-gray-200 focus:bg-v2-accent-200 focus:text-white disabled:bg-v2-white disabled:text-v2-gray-400",
        cancel: "bg-v2-white text-failure hover:bg-v2-red-light disabled:bg-v2-white disabled:text-v2-gray-400",
        icon: "bg-v2-white shadow-sm text-v2-gray-500 hover:text-v2-gray-600 hover:border-v2-white hover:bg-v2-gray-200 focus:bg-v2-accent-200 focus:text-white disabled:text-v2-gray-400",
      },
      size: {
        sm: "h-8 px-2 py-1",
        md: "h-10 px-4 py-2",
        lg: "h-12 px-6 py-3",
      },
      border: {
        none: "border-none",
        thin: "border border border-solid border-v2-gray-400",
        medium: "border border border-solid border-v2-gray-400",
        bold: "border border-3 border-solid border-v2-gray-400",
      },
      shadow: {
        sm: "shadow-sm",
        md: "shadow-md",
        lg: "shadow-lg",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  icon?: React.ReactNode
  iconPosition?: "left" | "right"
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, border, shadow, asChild = false, icon, iconPosition = "left", children, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp className={cn(buttonVariants({ variant, size, border, shadow, className }))} ref={ref} {...props}>
        {icon ? (
          iconPosition === "left" ? (
            <div className="w-full flex gap-2 items-center justify-center">
              <IconHolder className="w-5 h-5 flex items-center">{icon}</IconHolder>
              {children}
            </div>
          ) : (
            <div className="w-full flex gap-2 items-center justify-center">
              {children}
              <IconHolder className="w-5 h-5">{icon}</IconHolder>
            </div>
          )
        ) : (
          children
        )}
      </Comp>
    )
  }
)

Button.displayName = "Button"
export { Button, buttonVariants }
