import { Auth } from "domain/model/Auth"
import { TStudentEMI } from "domain/model/Payment"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface CreateStudentEMIUseCase {
  invoke(auth: Auth, student_payment_emi: TStudentEMI): Promise<any>
}

export class CreateStudentEMI implements CreateStudentEMIUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, student_payment_emi: TStudentEMI): Promise<any> {
    return await this.repository.createStudentEMI(auth, student_payment_emi)
  }
}
