import TableButton from "core/components/v2/TableButton"
import { STR_PLAY_VIDEO } from "core/constants/strings"
import { useNavigate } from "react-router-dom"

export default function HomeVideoCard({ data, index, type, selected }: any) {
  const minutes = Math.floor(data?.video_len / 60)
  const extraSeconds = data?.video_len % 60

  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/home/${type}/${data?.video_id}?h=${data?.video_secret || "default"}`)
  }

  return (
    <div
      className={`flex h-[134px] min-h-[134px] w-[205px] min-w-[205px] flex-col gap-2 rounded-lg border-[0.5px] bg-white p-3 shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.0.05)]`}
    >
      <div className="flex justify-between">
        <h3 className="w-4/5 text-sm font-semibold text-[#333]" title={data?.name}>
          {data?.name.slice(0, 20)}...
        </h3>
        <input type="checkbox" className="rounded border-[#D3D3D3] checked:bg-[#00C22B]" />
      </div>
      <p className="text-[11px] font-normal leading-[14.3px] text-[#646464]">
        Duration: {minutes}:{extraSeconds < 10 ? "0" + extraSeconds : extraSeconds} mins
      </p>
      <div className="flex-grow"></div> {/* This div will take up remaining space */}
      <div className="mt-auto flex">
        <TableButton outlined onClick={handleClick} className={selected && "border-[#07B42D] text-[#07B42D]"}>
          {selected ? "Selected" : "Play Video"}
        </TableButton>
      </div>
    </div>
  )
}
