import Button from "core/components/Button"
import { CrossIcon } from "core/constants/svgs"

const actions: any = {
  add: "Add Store Item",
  update: "Update Store Item",
  delete: "Delete Store Item",
}

export default function StoreModal({ type, item, open, loading, onClose, onChange, onSubmit, onClick }: any) {
  if (!open) return null

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
        <div className="no-scrollbar h-screen overflow-y-auto py-5">
          <div className="flex w-[700px] transform flex-col rounded-lg bg-new-solid-white font-medium text-new-neutral">
            <div className="flex items-center justify-between border-b p-8">
              <h3 className="text-lg font-semibold leading-[22px] text-new-accent">{actions[type]}</h3>
              <button type="button" onClick={onClose} className="flex items-center gap-1.5">
                <CrossIcon className="h-6 w-6 text-new-neutral" />
                <span className="text-new-neutral">Close</span>
              </button>
            </div>
            <div className="p-8">
              {type === "delete" ? (
                <div className="flex flex-col gap-8">
                  <p className="text-new-neutral">Are you sure you want to delete this item?</p>
                  <div className="flex gap-4">
                    <Button danger onClick={onClose}>
                      Cancel
                    </Button>
                    <Button isLoading={loading} onClick={onClick}>
                      Delete Item
                    </Button>
                  </div>
                </div>
              ) : (
                <form className="flex flex-col gap-8" onSubmit={(e: any) => onSubmit(e)}>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <label htmlFor="name">Name</label>
                      <input
                        required
                        type="text"
                        id="name"
                        name="name"
                        value={item?.name}
                        onChange={(e: any) => onChange(e)}
                        className="rounded-md border border-new-neutral p-2"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="image_url">Image URL</label>
                      <input
                        type="text"
                        id="image_url"
                        name="image_url"
                        value={item?.image_url}
                        onChange={(e: any) => onChange(e)}
                        className="rounded-md border border-new-neutral p-2"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="coins">Coins</label>
                      <input
                        required
                        type="number"
                        id="coins"
                        name="coins"
                        value={item?.coins}
                        onChange={(e: any) => onChange(e)}
                        className="rounded-md border border-new-neutral p-2"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="quantity">Quantity</label>
                      <input
                        required
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={item?.quantity}
                        onChange={(e: any) => onChange(e)}
                        className="rounded-md border border-new-neutral p-2"
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <Button onClick={onClose} danger type="button">
                      Cancel
                    </Button>
                    <Button isLoading={loading}>{type === "add" ? "Add" : "Update"} Item</Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
