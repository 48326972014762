import SubmissionDataSource from "data/dataSource/Student/SubmissionDataSource"
import { Auth } from "domain/model/Auth"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_V4_STUDENT, API_SUBMIT_ASSIGNMENT_QUESTION, API_V4_GET_STUDENT_SUBMISSION } from "core/constants/strings"

export default class SubmissionAPIDataSourceImpl implements SubmissionDataSource {
  async submitAssignmentQuestion(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_ASSIGNMENT_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSubmission(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_STUDENT_SUBMISSION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
