import { Button } from "core/components/v2/Button"
import Progress from "core/components/Progress"
import { STR_VIEW_MODULE } from "core/constants/strings"
import { cn } from "core/lib/utils"
import { getNumericFieldValue } from "core/utils/others"

export default function CourseCard({
  index,
  active,
  course,
  onClick,
  color,
  masterClass,
  loading,
  projectModule,
}: any) {
  const MODULES_CLASSES: any = {
    // SDE
    "01_beginner_course": 24,
    "10_DSA": 64,
    "20_cs_fundamentals": 18,
    "30_system_design": 18,
    "40_project": 28,
    ev_dsa: 22,

    // DSML
    "01_data_fundamentals": 30,
    "02_product_analytics": 6,
    "03_python": 24,
    "03_advanced_python": 24,
    "04_maths_for_datascience": 15,
    "04_advanced_maths_for_datascience": 15,
    "05_introduction_to_mldl": 6,
    "06_mldl_specialisation": 36,
    "07_advanced_dsa": 15,
    // Misc
    master_class: 79,
    project_module: 10,
  }

  const recorded = course?.recorded_lectures
  const classes_done = recorded?.length
  const module_classes = getNumericFieldValue(MODULES_CLASSES, course?.id)
  const total_classes = Math.max(module_classes, classes_done)
  const percent_module_done = Math.floor((classes_done / total_classes) * 100)
  const progress = isFinite(percent_module_done) ? percent_module_done : 0

  const classes_completed = recorded?.filter((l: any) => l?.is_completed)?.length

  return (
    <div
      className={cn(
        "relative flex min-h-[189px] w-full max-w-[170px] shrink-0 flex-col gap-2 rounded-lg border-2 p-4 before:absolute",
        loading ? "border border-new-neutral-light bg-new-solid-white" : color.bg,
        active ? `${color.border} shadow-[4px_4px_0px_0px_rgba(87,126,154,0.40)]` : "border-transparent",
        active && "before:right-4 before:top-4 before:h-1.5 before:w-1.5 before:rounded-full"
      )}
    >
      <h3
        className={cn("w-2/3 text-sm line-clamp-3", loading ? "text-new-neutral-light" : "text-[#333]")}
        title={course?.name}
      >
        {course?.name}
      </h3>
      {masterClass ? (
        <h5 className={cn("mb-auto", loading ? "text-new-neutral-light" : "text-new-neutral-dark")}>
          This module contains all the extra chapters specifically designed for advanced learners.
        </h5>
      ) : projectModule ? (
        <h5 className={cn("mb-auto", loading ? "text-new-neutral-light" : "text-new-neutral-dark")}>
          This module contains information regarding all the projects for the course.
        </h5>
      ) : (
        <div className="space-y-4">
          <div className="text-[10px]">
            <h5>{progress}% Module Done</h5>
            <h5>{classes_completed} Classes Completed</h5>
          </div>
          <Progress progress={progress} />
        </div>
      )}
      <div className="mt-auto">
        <Button
          disabled={loading}
          border={"thin"}
          variant="secondary"
          className="w-full"
          onClick={() => onClick(course, index)}
        >
          {STR_VIEW_MODULE}
        </Button>
      </div>
    </div>
  )
}
