import Select from "core/components/new/Select"
import columnTitle from "core/utils/columnTitle"
import React, { Fragment } from "react"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { AddIcon } from "core/constants/svgs"
import Fields from "./Fields"

export default function Solution({
  questionDetails,
  questionResponse,
  questionResponseOptions,
  subjectiveSolution,
  caseStudySolution,
  handleSubjectiveQuestionSolution,
  handleCaseStudyQuestionSolution,
  handleQuestionFieldChange,
  handleSolutionFieldChange,
  handleAddSolutionFields,
  handleRemoveSolutionFields,
  solutionFields,
}: any) {
  const [activeTab, setActivetab] = React.useState<number>(0)

  return (
    <div className="w-full flex flex-col gap-4 animate-[pulse-animation_1s_linear_10ms]">
      <div className="w-full flex items-start gap-4 overflow-auto">
        {questionDetails?.type === "Case Study" &&
          questionResponse?.map((_: any, index: any) => (
            <div
              className={cn(
                "py-2 px-4 flex flex-col content-center items-center bg-[#E5E5E5] cursor-pointer rounded-sm",
                activeTab === index && "border border-solid border-new-accent"
              )}
              onClick={() => setActivetab(index)}
            >
              <p>{index + 1}</p>
            </div>
          ))}
      </div>
      {questionDetails?.type === "Case Study" && !questionResponse[activeTab] ? (
        <h3>Please add the response first.</h3>
      ) : questionResponse[activeTab]?.type === "" ? (
        <h3>Please select the Question Type in response tab first.</h3>
      ) : questionDetails?.type === "Case Study" && questionResponse[activeTab]?.type === "MCQ" ? (
        <MCQOptions
          activeTab={activeTab}
          options={questionResponseOptions?.[activeTab]}
          solution={caseStudySolution?.[activeTab]}
          solutionFields={solutionFields}
          handleSolution={handleCaseStudyQuestionSolution}
          handleSolutionFieldChange={handleSolutionFieldChange}
          caseStudySolution={caseStudySolution}
          handleAddSolutionFields={handleAddSolutionFields}
          handleRemoveSolutionFields={handleRemoveSolutionFields}
          handleQuestionFieldChange={handleQuestionFieldChange}
        />
      ) : (
        <FinalSolution
          activeTab={activeTab}
          questionDetails={questionDetails}
          subjectiveSolution={subjectiveSolution}
          caseStudySolution={caseStudySolution}
          handleSubjectiveSolution={handleSubjectiveQuestionSolution}
          handleCaseStudySolution={handleCaseStudyQuestionSolution}
        />
      )}
    </div>
  )
}

function FinalSolution({
  activeTab,
  questionDetails,
  subjectiveSolution,
  caseStudySolution,
  handleSubjectiveSolution,
  handleCaseStudySolution,
}: any) {
  return (
    <Fragment>
      <h3>Final Solution {questionDetails?.type !== "Subjective" && `Question ${activeTab + 1}`}</h3>
      <div className="w-full flex flex-col items-start gap-4 animate-[pulse-animation_1s_linear_10ms]">
        {questionDetails?.type === "Subjective" ? (
          <textarea
            onChange={(e) => {
              handleSubjectiveSolution(e, activeTab)
            }}
            value={subjectiveSolution}
            placeholder="Write your solution here!"
            className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent cursor-pointer overflow-auto text-new-netural"
          >
            {subjectiveSolution}
          </textarea>
        ) : (
          <textarea
            onChange={(e) => {
              handleCaseStudySolution(e, activeTab)
            }}
            value={caseStudySolution?.[activeTab] || ""}
            placeholder="Write your solution here!"
            className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent cursor-pointer overflow-auto text-new-netural"
          />
        )}
      </div>
    </Fragment>
  )
}

function MCQOptions({
  activeTab,
  options,
  solution,
  solutionFields,
  handleSolution,
  handleAddSolutionFields,
  handleRemoveSolutionFields,
  handleSolutionFieldChange,
}: any) {
  return (
    <Fragment>
      <h3>Final Solution Question {activeTab + 1}</h3>
      <div className="w-full flex py-4 gap-6 items-start content-start flex-wrap justify-between animate-[pulse-animation_1s_linear_10ms]">
        {!isEmpty(options) &&
          Object.entries(options)?.map((option: any) => (
            <div key={option[0]} className="w-[48%] flex flex-col gap-2">
              <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark">
                Option {columnTitle(parseInt(option[0], 10) + 1)}
              </label>
              <Select
                name="questionType"
                id="questionType"
                value={solution?.[parseInt(option[0])] || "false"}
                onChange={(e) => {
                  handleSolution(e, activeTab, parseInt(option[0]))
                }}
                className={solution?.[parseInt(option[0])] === "true" ? "text-success" : "text-failure"}
              >
                <option value={"true"} className="text-success">
                  True
                </option>
                <option value={"false"} className="text-failure">
                  False
                </option>
              </Select>
            </div>
          ))}
        <Fields
          responseId={activeTab}
          isResponseField={true}
          fields={solutionFields?.[activeTab]}
          fieldTypes={["description", "image", "document", "code"]}
          onFieldChange={handleSolutionFieldChange}
          onDeleteField={handleRemoveSolutionFields}
        />
        <button
          className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
          onClick={() => handleAddSolutionFields(activeTab)}
        >
          <AddIcon className="h-4 w-4" />
          <p>Add Solution Fields</p>
        </button>
      </div>
    </Fragment>
  )
}
