import { Auth } from "domain/model/Auth"
import { CreateNewProject } from "domain/model/ProjectSection"
import { ProjectSectionRepository } from "domain/repository/Admin/ProjectSectionRepository"

export interface DeleteProjectUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class DeleteProject implements DeleteProjectUseCase {
  private repository: ProjectSectionRepository

  constructor(repository: ProjectSectionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.deleteProject(auth, data)
  }
}
