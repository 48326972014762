import { Auth } from "domain/model/Auth"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface getJobsUseCase {
  invoke(request: Auth): Promise<any>
}

export class GetAllJobsList implements getJobsUseCase {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: Auth): Promise<any> {
    return await this.repository.getAllJobs(request)
  }
}
