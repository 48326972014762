import { studentDetails } from "domain/model/Placement"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"
import { request } from "http"

export interface uploadStudentPictureUseCase {
  invoke(repository: studentDetails): Promise<any>
}

export class UploadStudentPicture implements uploadStudentPictureUseCase {
  private repository: AddPlacementRepository
  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }
  async invoke(request: studentDetails): Promise<any> {
    return await this.repository.uploadStudentPicture(request)
  }
}
