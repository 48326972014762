import { isEmpty } from "core/utils/misc"
import Options from "./Options"

export default function QuestionView({ question, optionSelected, handleOptionChange }: any) {
  const fields = question?.question || []

  return (
    <div className="flex flex-col gap-4">
      <div className="border-b border-new-neutral-light pb-2 text-2xl font-semibold">MCQ Question</div>
      <div className="flex flex-col gap-2">
        <h2>{question?.name}</h2>
        <p>
          <strong>Max Marks: </strong> 30
        </p>
      </div>
      <div className="flex flex-col gap-2 p-2 pl-0">
        <p>
          <strong className="text-lg">Problem Statement:</strong>
        </p>
        {fields?.map((field: any, index: number) => {
          if (field.type === "text") {
            return (
              <div
                key={index}
                className="text-justify whitespace-pre-line break-words"
                dangerouslySetInnerHTML={{ __html: field?.content }}
              />
            )
          } else if (field.type === "code") {
            return <pre className="p-2 bg-new-editor-dark-500 rounded-sm">{field?.content}</pre>
          } else if (field.type === "image") {
            return (
              <img
                src={field?.content}
                alt="img"
                title={field?.filename}
                onClick={() => window.open(field?.content)}
                className="cursor-pointer rounded-sm"
              />
            )
          }
          return <></>
        })}
      </div>
      {!isEmpty(question?.options) && (
        <Options options={question?.options} optionSelected={optionSelected} handleOptionChange={handleOptionChange} />
      )}
    </div>
  )
}
