import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { ReferAndEarnAPIDataSourceImpl } from "data/API/Student/ReferAndEarnAPIDataSourceImpl"
import { ReferAndEarnRepositoryImpl } from "data/repository/Student/ReferAndEarnRepositoryImpl"
import { set } from "date-fns"
import { GetReferralData } from "domain/useCase/Student/ReferAndEarn/GetReferralData"
import { MyReferredCandidates } from "domain/useCase/Student/ReferAndEarn/MyReferredCandidates"
import { ReferCandidates } from "domain/useCase/Student/ReferAndEarn/ReferCandidate"
import React, { ChangeEvent, FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SDE_FORM_LINK, DSML_FORM_LINK } from "core/constants/strings"
import useLocalStorage from "core/hooks/useLocalStorage"
import { PostReferralFormData } from "domain/useCase/Student/ReferAndEarn/PostReferralFormData"
import { GetReferrerStudentData } from "domain/useCase/Student/ReferAndEarn/GetReferrerStudentData"
import { EditReferralFormData } from "domain/useCase/Student/ReferAndEarn/EditPostRederralFormData"
import { DeleteReferralFormData } from "domain/useCase/Student/ReferAndEarn/DeleteReferralFormData"
import { PostBankFormData } from "domain/useCase/Student/ReferAndEarn/PostBankFormData"

export default function ReferViewModel() {
  const [myreferredCandidates, setMyReferredCandidates] = React.useState<any>([])
  const [student] = useLocalStorage<any>("student", {} as any)

  const [myReferralCode, setMyReferralCode] = React.useState<any>("")
  const [candiDate, setCandiDate] = React.useState<any>("")

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()
  const [ReferredByActive, setReferredByActive] = React.useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  const [courseOptions, setCourseOptions] = useState<String[]>(["SDE", "DSML"])
  const [relationOptions, setRelationOptions] = useState<String[]>(["Friend", "Relative", "Other"])
  // const handleChange = (value: string) => {
  //   const selectedValue = value === "SDE" ? SDE_FORM_LINK : DSML_FORM_LINK
  //   setFormValue(value)
  //   setFormLink(selectedValue)
  // }

  const [formData, setFormData] = useState({
    yourName: student.name ? student.name : "",
    yourEmail: student.email ? student.email : "",
    yourNumber: student?.phone ? student?.phone : "",
    referName: "",
    referEmail: "",
    referNumber: "",
    course: "",
    relation: "",
    agreedToTerms: false,
  })

  const [editReferDetails, setEditReferDetails] = useState<any>({
    yourName: student.name,
    yourEmail: student.email,
    yourNumber: student?.phone ? student?.phone : "",
    referName: "",
    referEmail: "",
    referNumber: "",
    course: "",
    relation: "",
    agreedToTerms: false,
  })

  const [bankDetails, setBankDetails] = useState<any>({
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    accountNumber2: "",
    iffciCode: "",
    agreedToTerms: false,
  })
  const [editBankDetails, setEditBankDetails] = useState<any>({
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    accountNumber2: "",
    iffciCode: "",
    agreedToTerms: false,
  })

  const getReferredCandidatesUseCase = new GetReferralData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )

  const getReferrerStudentUseCase = new GetReferrerStudentData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )

  const postReferralFormDataUseCase = new PostReferralFormData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )
  const editReferralFormDataUseCase = new EditReferralFormData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )
  const deleteReferralFormDataUseCase = new DeleteReferralFormData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )

  const postBankFormDataUseCase = new PostBankFormData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )

  const toggleCheckbox = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      agreedToTerms: !prevFormData.agreedToTerms,
    }))
  }
  const cancelForm = () => {
    setIsModalOpen(false)
  }
  const handleChange2 = (e: any) => {
    const { name, value, type } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  function handlePhoneNumber(e: any) {
    setFormData((prev) => ({
      ...prev,
      referNumber: e,
    }))
  }

  const handleRealationOptions = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      relation: value,
    }))
  }
  const handleCourseOptions = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      course: value,
    }))
  }

  const handleUserPhoneNumber = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      yourNumber: value,
    }))
  }

  const handleRefferedPhoneNumber = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      referNumber: value,
    }))
  }

  const handleCopyToClipboard = (email: string) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Email copied to clipboard")
      })
      .catch(() => {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Failed to copy to clipboard")
      })
  }
  const handleLinkCopyToClipboard = (link: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Link copied to clipboard")
      })
      .catch(() => {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Failed to copy to clipboard")
      })
  }

  const handleBankSubmit = async (e: any, data: any) => {
    e.preventDefault()
    if (bankDetails?.accountNumber !== bankDetails?.accountNumber2) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Account Number does not match!")
      return
    }
    const updatedFormData = {
      referEmail: data?.email,
      details: data?.details,
      ...bankDetails,
    }

    setLoading(true)
    const postRequestResponse = await postBankFormData(updatedFormData)
    setLoading(false)
    if (postRequestResponse?.message) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, postRequestResponse?.message)
    } else if (!postRequestResponse?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, `Error`)
    } else {
      if (postRequestResponse?.success) {
        setMyReferredCandidates(postRequestResponse?.data.referredStudentList)
        setCandiDate(postRequestResponse?.data.referrerDetails)
      }
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `Bank Details Added Successfully!`)
      setBankDetails({
        bankName: "",
        accountHolderName: "",
        accountNumber: "",
        accountNumber2: "",
        iffciCode: "",
        agreedToTerms: false,
      })
    }
  }
  const handleEditBankSubmit = async (e: any, data: any) => {
    e.preventDefault()
    if (editBankDetails?.accountNumber !== editBankDetails?.accountNumber2) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Account Number does not match!")
      return
    }
    const updatedFormData = {
      referEmail: data?.email,
      details: data?.details,
      ...editBankDetails,
    }

    setLoading(true)
    const postRequestResponse = await postBankFormData(updatedFormData)
    setLoading(false)
    if (postRequestResponse?.message) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, postRequestResponse?.message)
    } else if (!postRequestResponse?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, `Error`)
    } else {
      if (postRequestResponse?.success) {
        setMyReferredCandidates(postRequestResponse?.data.referredStudentList)
        setCandiDate(postRequestResponse?.data.referrerDetails)
      }
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `Bank Details Added Successfully!`)
      setBankDetails({
        bankName: "",
        accountHolderName: "",
        accountNumber: "",
        accountNumber2: "",
        iffciCode: "",
        agreedToTerms: false,
      })
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const updatedFormData = { ...formData }
    setLoading(true)

    const postRequestResponse = await postReferralFormData(updatedFormData)
    if (postRequestResponse?.message) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, postRequestResponse?.message)
    } else if (postRequestResponse?.data?.success === false) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, `This user has already been referred!`)
    } else {
      if (postRequestResponse.success) {
        setMyReferredCandidates(postRequestResponse.data.referredStudentList)
        setCandiDate(postRequestResponse.data.referrerDetails)
      }
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `User referred Successfully`)
      setIsModalOpen(false)
      setFormData({
        yourName: student.name,
        yourEmail: student.email,
        yourNumber: "",
        referName: "",
        referEmail: "",
        referNumber: "",
        course: "",
        relation: "",
        agreedToTerms: false,
      })
    }
  }
  const handleSubmitLink = async (e: FormEvent) => {
    e.preventDefault()
    const updatedFormData = { ...formData }
    setLoading(true)
    const postRequestResponse = await postReferralFormData(updatedFormData)
    if (postRequestResponse?.message) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, postRequestResponse?.message)
    } else if (!postRequestResponse?.data?.success) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, `This user has already been referred!`)
    } else {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `User referred Successfully`)
      setIsModalOpen(false)
      setFormData({
        yourName: student.name ? student.name : "",
        yourEmail: student.email ? student.email : "",
        yourNumber: student?.phone ? student?.phone : "",
        referName: "",
        referEmail: "",
        referNumber: "",
        course: "",
        relation: "",
        agreedToTerms: false,
      })
    }
  }
  const handleEditSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const updatedFormData = { ...editReferDetails }
    setLoading(true)
    const postRequestResponse = await editReferralFormData(updatedFormData)
    if (postRequestResponse?.message) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, postRequestResponse?.message)
    } else {
      if (postRequestResponse.success) {
        setMyReferredCandidates(postRequestResponse.data.referredStudentList)
        setCandiDate(postRequestResponse.data.referrerDetails)
      }
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `User referred Successfully`)
      setEditReferDetails({
        yourName: student.name,
        yourEmail: student.email,
        yourNumber: "",
        referName: "",
        referEmail: "",
        referNumber: "",
        courseType: "",
        relation: "",
        agreedToTerms: false,
      })
    }
  }
  const handleDeleteSubmit = async (email: string) => {
    setLoading(true)

    const deleteRequestResponse = await deleteReferralFormData(email)
    if (deleteRequestResponse?.message) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, deleteRequestResponse?.message)
    } else {
      if (deleteRequestResponse?.success) {
        setMyReferredCandidates(deleteRequestResponse?.data.referredStudentList)
        setCandiDate(deleteRequestResponse?.data.referrerDetails)
      }
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `User Deleted Successfully`)
    }
  }

  async function getReferralData() {
    setFetchLoading(true)
    const response = await getReferredCandidatesUseCase.invoke(auth)
    if (response.success) {
      setMyReferredCandidates(response.data.referredStudentList)
      setMyReferralCode(response.data.referralCode)
      setCandiDate(response.data.referrerDetails)
    }
    setFetchLoading(false)
  }
  async function getReferrerData(email: string) {
    const response = await getReferrerStudentUseCase.invoke(email)
    if (response.success) {
      setFormData((prev) => ({
        ...prev,
        yourName: response?.data?.name,
        yourEmail: response?.data?.email,
      }))
    }
  }

  async function postReferralFormData(formData: any): Promise<any> {
    const response = await postReferralFormDataUseCase.invoke(formData, auth)
    return response
  }
  async function postBankFormData(formData: any): Promise<any> {
    const response = await postBankFormDataUseCase.invoke(formData, auth)
    return response
  }
  async function editReferralFormData(formData: any): Promise<any> {
    const response = await editReferralFormDataUseCase.invoke(formData, auth)
    return response
  }

  async function deleteReferralFormData(email: string): Promise<any> {
    const response = await deleteReferralFormDataUseCase.invoke(email, auth)
    return response
  }

  function handleCopy(text: string, msg: string) {
    navigator.clipboard.writeText(text)
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, `${msg} copied to clipboard`)
  }
  function handleViewReward(candidate: any) {
    if (candidate.studentEnrolled && candidate.studentFinalized) {
      window.location.href =
        "https://docs.google.com/forms/d/1u3XqwC6jtnZp_E_6k10cJbZX0OVS4B41kBE0nmehQ3Y/viewform?edit_requested=true#responses"
    }
  }

  return {
    student,
    myreferredCandidates,
    toast,
    candiDate,
    myReferralCode,
    ReferredByActive,
    getReferrerData,
    setReferredByActive,
    handleViewReward,
    setMyReferralCode,
    setCandiDate,
    setMyReferredCandidates,
    changeToastVisibility,
    getReferralData,
    postReferralFormData,
    handleCopy,
    editReferDetails,
    setEditReferDetails,
    bankDetails,
    editBankDetails,
    setEditBankDetails,
    setBankDetails,
    courseOptions,
    setCourseOptions,
    relationOptions,
    isModalOpen,
    setIsModalOpen,
    formData,
    handleCourseOptions,
    handleRealationOptions,
    setFormData,
    handlePhoneNumber,
    handleSubmit,
    handleSubmitLink,
    handleEditSubmit,
    handleDeleteSubmit,
    handleBankSubmit,
    handleEditBankSubmit,
    handleRefferedPhoneNumber,
    handleUserPhoneNumber,
    handleChange2,
    cancelForm,
    toggleCheckbox,
    loading,
    fetchLoading,
    setLoading,
    handleCopyToClipboard,
    handleLinkCopyToClipboard,
  }
}
