import McqRepository from "domain/repository/Admin/McqRepository"
import { Auth } from "domain/model/Auth"

export interface GetMcqQuestionUseCase {
  invoke(auth: Auth, id: string): Promise<any>
}

export class GetMcqQuestion implements GetMcqQuestionUseCase {
  private repository: McqRepository

  constructor(repository: McqRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string): Promise<any> {
    return await this.repository.get_mcq_question(auth, id)
  }
}
