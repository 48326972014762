import React from "react"

const LineFull = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="327" height="476" viewBox="0 0 327 476" fill="none">
      <path
        d="M163.039 0H326.078L163.039 476H9.76234e-05L163.039 0Z"
        fill="url(#paint0_linear_742_22431)"
        fillOpacity="0.66"
      />

      <defs>
        <linearGradient
          id="paint0_linear_742_22431"
          x1="257.194"
          y1="-61.4194"
          x2="130.516"
          y2="319.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.52" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LineFull
