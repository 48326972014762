import React from "react"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import Button from "core/components/new/Button"

export default function PaymentDetails(props: any) {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[#999999] bg-opacity-50 z-50">
      <div
        className={`relative flex ${
          props.editable ? "w-[824px] h-[100vh]" : "w-[824px] h-[441px]"
        } bg-[#FFF] p-4 rounded-lg`}
      >
        <div className="w-1/3 space-y-2">
          <h3 className="text-lg text-[#312E81]">{`#Inst_Id ${props.currentData["id"]}`}</h3>
          <p className="text-sm capitalize">{props.currentData["payment_type"]}</p>
          <button
            className="bg-white text-[#312E81] px-3 py-1 rounded-md outline outline-1"
            onClick={props.handleUpdateClick}
          >
            Update
          </button>
        </div>
        {/* right divide this into component */}
        <div className="flex flex-col gap-4 w-2/3 p-3 border-l-2 overflow-auto h-full custom-scrollbar-2">
          {/* top div */}
          <div className="flex justify-between">
            <div className="space-y-2">
              <p className="text-[#414141] font-bold">
                {props.getDate(props.currentData["updated_at"] || props.currentData["date_time"])}
              </p>
              <p className="capitalize">{props.currentData["payment_type"]}</p>
            </div>
            <div className="absolute top-5 right-20">
              <button className="fixed" onClick={props.onClose}>
                <CloseSharpIcon className="hover:text-red-500" />
              </button>
            </div>
          </div>
          {/* bottom */}
          <div className="space-y-2 pb-2">
            {Object.entries(props.Keys).map(([key, label]: [any, any], index) => {
              if (props.currentData["status"] === "refund" && key === "due_date") {
                return null // Skip rendering "due_date" when status is "refund"
              }
              return (
                <div key={index} className="flex items-center">
                  <p className="w-1/3 text-[#414141]">
                    {key === "date_time" && props.currentData["status"] === "paid"
                      ? "Paid Date"
                      : key === "date_time" && props.currentData["status"] === "refund"
                        ? "Refund Date"
                        : label}
                  </p>
                  {props.editable ? (
                    <input
                      type="text"
                      name={key}
                      className={`w-2/3 bg-white ${props.disabledKeys.includes(key) ? "disabled bg-gray-100" : "cursor-pointer"} ${props.currentData[key] === "paid" ? "text-green-500 capitalize" : ""} ${props.currentData[key] === "refund" ? "text-[#DC2626] capitalize" : ""}`}
                      defaultValue={props.currentData[key]}
                      onChange={props.handleInputChange}
                      disabled={props.disabledKeys.includes(key)}
                    />
                  ) : (
                    <p
                      className={`w-2/3 font-semibold ${props.currentData[key] === "paid" ? "text-green-500 capitalize" : ""} ${props.currentData[key] === "refund" ? "text-[#DC2626] capitalize" : ""}`}
                    >
                      {props.currentData[key]}
                    </p>
                  )}
                </div>
              )
            })}

            {!props.editable && Object.keys(props.currentData.previous_record).length > 0 && (
              <div>
                {Object.entries(props.currentData.previous_record)
                  .reverse()
                  .map(([date, record]: [any, any], index) => (
                    <div key={index} className="space-y-2 border-t py-4">
                      <p className="text-[#414141] font-bold">{props.getDate(date.split(" ")[0])}</p>

                      {Object.entries(props.Keys).map(([key, label]: [any, any], idx) => (
                        <div key={idx} className="flex items-center">
                          <p className="w-1/3 text-[#414141]">{label}</p>
                          <p className="w-2/3 font-semibold">{record[key]}</p>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            )}
            {props.editable && (
              <div className="flex space-x-2">
                <Button outlined failure onClick={props.handleChange}>
                  Discard Changes
                </Button>
                <Button
                  className="bg-gradient-to-br from-[#06A1F9] to-[#4032EB] text-[#FFF]"
                  onClick={props.handleSaveChanges}
                >
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
