import { useAuth } from "core/context/auth"
import { pathList, redirectPath } from "core/lib/utils"
import { useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

export default function ProtectedRoute() {
  const { pathname } = useLocation()

  const { auth, user, loading } = useAuth()

  useEffect(() => {
    if (auth && "role" in auth && ["admin", "mentor"].includes(auth.role)) {
      const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
      if (launcherFrame) {
        launcherFrame.style.display = "none"
      }
    }
  }, [auth])

  if (!loading && !user) {
    return <Navigate to="/login" />
  }

  const paths = pathList(pathname)

  if (paths.length === 0) {
    return <Navigate to={redirectPath(auth.role)} />
  }

  const rootPath = paths[0]

  const studentRedirect = auth.role === "user" && (rootPath === "admin" || (rootPath === "mentor" && paths.length > 1))
  const adminRedirect = auth.role === "admin" && rootPath !== "admin"
  const mentorRedirect = auth.role === "mentor" && rootPath !== "mentor"

  if (studentRedirect || adminRedirect || mentorRedirect) {
    return <Navigate to={redirectPath(auth.role)} />
  }

  return <Outlet />
}
