import DashboardLayoutV2 from "core/layouts/DashboardLayoutv2"
import RemainderCard from "../Home/components/RemainderCard"
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "core/components/v2/Table"
import { isEmpty } from "core/utils/misc"
import OnboardingViewModel from "./OnboardingViewModel"
import { useEffect, useState } from "react"
import { Button } from "core/components/v2/Button"
import StudentFeedbackModal from "./components/StudentFeedbackModal"
import MentorFeedbackModal from "./components/MentorFeedbackModal"
import Toast from "core/components/Toast"
import { AwardIcon } from "core/constants/svgs"
import AwardEmpty from "assets/svgs/AwardEmpty"
import OnboardingTable from "./components/OnboardingTable"

export default function OnboardingView() {
  const {
    auth,
    refreshed,
    toast,
    changeToastVisibility,
    loading,
    onboardingDetails,
    fetchOnboardingMeetingDetails,
    updateOnboardingMeetingDetails,
  } = OnboardingViewModel()
  const [studentFeedbackModal, setStudentFeedbackModal] = useState(false)
  const [studentFeedbackDetails, setStudentFeedbackDetails] = useState({
    message: "",
    star: 0,
  })
  const [mentorFeedbackModal, setMentorFeedbackModal] = useState(false)
  useEffect(() => {
    if (refreshed) {
      fetchOnboardingMeetingDetails()
    }
  }, [refreshed])

  useEffect(() => {
    setStudentFeedbackModal(false)
    setStudentFeedbackDetails({
      message: "",
      star: 0,
    })
  }, [onboardingDetails])
  const tableData = {
    header: ["Subject", "Description", ""],
    rows: [
      [
        <p className="text-[#646464] text-[12px] font-[400]">Meeting Status</p>,
        <p className="text-[#333] text-[12px] font-[400]">
          {onboardingDetails.duration ? "Attended" : "Not Attended"}
        </p>,
        "",
      ],
      [
        <p className="text-[#646464] text-[12px] font-[400]">Onboarding Date</p>,
        <p className="text-[#333] text-[12px] font-[400]">{formatTimestamp(onboardingDetails?.start_time)}</p>,
        "",
      ],
      [
        <p className="text-[#646464] text-[12px] font-[400]">Student's Rating</p>,
        <div>
          {onboardingDetails?.student_feedback?.star ? (
            <div className="flex gap-[8px] items-center">
              <p className="text-[#333] text-[12px] font-[400]">{onboardingDetails?.student_feedback?.star}/5</p>

              {Array(5)
                .fill(0)
                .map((_, index) => {
                  return index < onboardingDetails?.student_feedback.star ? (
                    <AwardIcon className="size-[16px]" key={index} />
                  ) : (
                    <AwardEmpty className="size-[16px]" key={index} />
                  )
                })}
            </div>
          ) : (
            <p className="text-[#333] text-[12px] font-[400]">Not Rated</p>
          )}
        </div>,
        "",
      ],
      [
        <p className="text-[#646464] text-[12px] font-[400]">Manager Name</p>,
        <p className="text-[#333] text-[12px] font-[400]">{onboardingDetails?.manager_name}</p>,
        "",
      ],
      [
        <p className="text-[#646464] text-[12px] font-[400]">Manager's Feedback</p>,
        <p className="text-[#333] text-[12px] font-[400]">--</p>,
        <div className="flex justify-end mx-[10px]">
          <Button
            onClick={() => setMentorFeedbackModal(true)}
            variant={"secondary"}
            border={"thin"}
            size="sm"
            className=" w-[107px] h-[25px]"
          >
            View
          </Button>
        </div>,
      ],
      [
        <p className="text-[#646464] text-[12px] font-[400]">Student's Feedback</p>,
        <p className="text-[#333] text-[12px] font-[400]">--</p>,
        !onboardingDetails?.hasOwnProperty("student_feedback") && (
          <div className="flex justify-end mx-[10px]">
            <Button
              onClick={() => setStudentFeedbackModal(true)}
              variant={"secondary"}
              border={"thin"}
              size="sm"
              className="w-[107px] h-[25px]"
            >
              Feedback
            </Button>
          </div>
        ),
      ],
    ],
  }

  function formatTimestamp(seconds: number) {
    const date = new Date(seconds * 1000)
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "long" })
    const year = date.getFullYear()
    let hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, "0")

    const ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12
    const getOrdinalSuffix = (day: any) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }
    return `${day}${getOrdinalSuffix(day)} ${month}, ${year} ${hours}:${minutes} ${ampm}`
  }

  return (
    <DashboardLayoutV2>
      <div className="w-11/12 mx-auto mt-[24px]">
        {onboardingDetails?.start_time + 45 * 60 > Math.floor(Date.now() / 1000) && (
          <RemainderCard
            selectedTag={"Onboarding"}
            data={{
              Onboarding: { ...onboardingDetails, name: "Portal Onboarding Meet" },
            }}
            loading={false}
            className="h-[30px] font-bold"
            color={"#FFBEBE"}
            handleRemainderCardClick={(tag: string, id?: string, link?: string) => {
              const room_id = id
              if (isEmpty(room_id) || isEmpty(link)) {
                return
              }
              window.open(link)
            }}
          />
        )}

        <StudentFeedbackModal
          loading={loading}
          formatTimestamp={formatTimestamp}
          onboardingDetails={onboardingDetails}
          studentFeedbackDetails={studentFeedbackDetails}
          setStudentFeedbackDetails={setStudentFeedbackDetails}
          updateOnboardingMeetingDetails={updateOnboardingMeetingDetails}
          isOpen={studentFeedbackModal}
          onClose={() => setStudentFeedbackModal(false)}
        />
        <MentorFeedbackModal
          formatTimestamp={formatTimestamp}
          onboardingDetails={onboardingDetails}
          isOpen={mentorFeedbackModal}
          onClose={() => setMentorFeedbackModal(false)}
        />

        <OnboardingTable tableHeaders={tableData.header} tableData={tableData} />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutV2>
  )
}
