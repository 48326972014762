import { MentorSessionCoupon } from "domain/model/RedeemCoins"
import { RedeemCoinsRepository } from "domain/repository/Student/RedeemCoinsRepository"

export interface BookMentorSessionCouponUseCase {
  invoke(mentorSessionCoupon: MentorSessionCoupon): Promise<any>
}

export default class BookMentorSessionCoupon implements BookMentorSessionCouponUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(mentorSessionCoupon: MentorSessionCoupon) {
    return this.repository.bookMentorSessionCoupon(mentorSessionCoupon)
  }
}
