import * as React from "react"
import { cn } from "core/lib/utils"

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "flex min-h-[90px] min-w-[400px] w-full rounded border border-v2-gray-400 bg-v2-white px-3 py-2 text-sm ring-offset-v2-white placeholder:text-muted-foreground focus-visible:ring-1 focus-visible:ring-v2-gray-500 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Textarea.displayName = "Textarea"

export { Textarea }
