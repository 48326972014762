import { Auth } from "domain/model/Auth"
import { LeaderboardRepository } from "domain/repository/Student/LeaderboardRespository"
import { LeaderboardDataSource } from "data/dataSource/Student/LeaderboardDataSource"

export class LeaderboardRepositoryImpl implements LeaderboardRepository {
  private dataSource: LeaderboardDataSource

  constructor(dataSource: LeaderboardDataSource) {
    this.dataSource = dataSource
  }

  async getLeaderboard(auth: Auth): Promise<any> {
    return this.dataSource.getLeaderboard(auth)
  }
}
