import HomeRepository from "domain/repository/Admin/HomeRepository"
import { Auth } from "domain/model/Auth"
import { FilterData } from "domain/model/FilterData"

export interface GetFilteredDataUseCase {
  invoke(auth: Auth, data: FilterData): Promise<any>
}

export default class GetFilteredData implements GetFilteredDataUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: FilterData) {
    return await this.repository.getFilteredData(auth, data)
  }
}
