import { Auth } from "domain/model/Auth"
import { AvailabilityRepository } from "domain/repository/Mentor/AvailabilityRepository"

export interface BlockGoalSettingDatesUseCase {
  invoke(auth: Auth, date: any): Promise<any>
}

export class BlockGoalSettingDates implements BlockGoalSettingDatesUseCase {
  private repository: AvailabilityRepository

  constructor(repository: AvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, date: any) {
    return await this.repository.blockGoalSettingDates(auth, date)
  }
}
