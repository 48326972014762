import React from "react"

export default function Feedback8(props: any) {
  const { selectedColor, onHover, onLeave, onClick, index, isSelected } = props

  return (
    <svg
      className={props.className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onLeave()}
      onClick={() => onClick(index)}
    >
      <rect width="64" height="64" rx="8" fill={isSelected ? selectedColor : "#D3D3D3"} />
      <circle cx="23.0859" cy="23.4043" r="3.40426" fill="white" />
      <circle cx="41.1289" cy="23.4043" r="3.40426" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1236 38.8428C19.4363 38.1588 20.2442 37.8578 20.9282 38.1705L25.0552 40.0571C29.6117 42.1401 34.8632 42.0647 39.358 39.8519L42.7393 38.1872C43.414 37.8551 44.2303 38.1328 44.5624 38.8075C44.8946 39.4822 44.6169 40.2984 43.9422 40.6306L40.5609 42.2952C35.3322 44.8694 29.2233 44.957 23.9229 42.534L19.7959 40.6473C19.1119 40.3347 18.8109 39.5267 19.1236 38.8428Z"
        fill="white"
      />
    </svg>
  )
}
