import Model from "core/components/Model"
import Button from "core/components/new/Button"
import Avatar from "react-avatar"
import { Link } from "react-router-dom"
import MyMenteeDetialsPageViewModel from "../MyMenteeDetialsPageViewModel"
import Toast from "core/components/Toast"
import React from "react"

export default function MyMenteeCard({
  menteeName,
  menteebatch,
  menteeProfilePic,
  menteeEmail,
  menteeLeetcodeCount,
  menteePhone,
  isButtonEnabled,
  isNewMentorshipFlow,
}: any) {
  const { toast, changeToastVisibility, extraSessionModel, enableExtraMentorSession, confirmEnableExtraSession } =
    MyMenteeDetialsPageViewModel()

  return (
    <div className="flex gap-4 rounded p-8 shadow-[0px_4px_16px_rgba(134,134,134,0.2)]">
      <div className="aspect-square h-[145px] shrink-0 overflow-hidden rounded bg-new-solid-white shadow-[0px_4px_10px_rgba(232,108,108,0.15)]">
        <Avatar
          className="object-cover object-top"
          src={menteeProfilePic}
          alt={menteeName}
          name={menteeName}
          size="145"
        />
      </div>
      <div className="flex w-full flex-col justify-between">
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-2xl font-bold leading-[29px] text-new-accent">{menteeName}</h2>
            <p className="mt-2 items-center gap-4 font-medium  leading-[20px] text-new-neutral-dark">
              <p className="pb-2">Email : {menteeEmail}</p>
              <p className="pb-2">Leetcode Count : {menteeLeetcodeCount}</p>
              <p className="pb-2">Contact No : {menteePhone}</p>
            </p>
          </div>
        </div>
        <div className="flex items-end justify-between font-medium leading-[20px] text-new-neutral-dark">
          Batch : {menteebatch}
          <div className="flex gap-x-1">
            <Link to={`/mentor/mentees/${menteeEmail}`}>
              {isButtonEnabled && (
                <Button outlined className="w-fit">
                  View Profile
                </Button>
              )}
            </Link>
            {!isNewMentorshipFlow ? (
              <Button outlined className="w-fit" onClick={confirmEnableExtraSession}>
                Enable Extra Session
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Model isOpen={extraSessionModel} onClose={confirmEnableExtraSession}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Enable Extra Session</h3>
            <p>Are You Sure You Want To Enable Extra Session For {menteeName}</p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={confirmEnableExtraSession}>
              No
            </Button>
            <Button outlined onClick={() => enableExtraMentorSession(menteeEmail)}>
              Yes
            </Button>
          </div>
        </div>
      </Model>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
