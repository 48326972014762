import { cn } from "core/lib/utils"

export default function StandaredSelect({
  disabled,
  id,
  className,
  value,
  onChange,
  placeholder,
  options,
  required = false,
}: any) {
  return (
    <select
      disabled={disabled}
      id={id}
      className={cn(
        "cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-4 text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent block w-full",
        className
      )}
      value={value}
      onChange={onChange}
      required={required}
    >
      {placeholder && (
        <option value="" disabled={required}>
          {placeholder}
        </option>
      )}
      {options?.map((option: string, i: number) => (
        <option key={i} className="bg-neutral-50 text-neutral-500" value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}
