import BgPattern from "assets/svgs/BgPattern"
import Badge from "core/components/Badge"
import ButtonGroup from "core/components/ButtonGroup"
import DashboardLayout from "core/layouts/DashboardLayout"
import ContestCard from "./components/ContestCard"
import useContestViewModel from "./ViewModel"
import React from "react"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import ContestRegisteredCard from "./components/ContestRegisteredCard"
import ContestPopup from "./components/ContestPopup"
import { isEmpty } from "core/utils/misc"

export default function ContestView() {
  const {
    toast,
    activeTab,
    pastContests,
    liveContests,
    instantContests,
    openPopup,
    popupData,
    popupDataLoading,
    registeredLiveContests,
    activePopupContestId,
    refreshed,
    setActivePopupContestId,
    redirectToContest,
    onOpenPopup,
    onClosePopup,
    handleChangeButtonType,
    handleRedirectToContest,
    changeToastVisibility,
    fetchLiveContests,
    fetchInstantContests,
    fetchStudentPastContests,
    handleRegisteredContests,
    navigate,
  } = useContestViewModel()

  React.useEffect(() => {
    if (refreshed) {
      fetchLiveContests()
      fetchInstantContests()
      fetchStudentPastContests()
    }
  }, [refreshed])

  React.useEffect(() => {
    if (!isEmpty(liveContests)) {
      handleRegisteredContests(liveContests?.upcoming)
    }
  }, [liveContests])

  React.useEffect(() => {
    navigate("/home")
  }, [])

  return (
    <DashboardLayout>
      <ContestPopup
        data={popupData}
        loading={popupDataLoading}
        open={openPopup}
        onClose={onClosePopup}
        onClick={() => redirectToContest(activePopupContestId)}
      />
      <div className="space-y-6">
        <div className="relative flex h-[300px] bg-new-radial-gradient-contest">
          <div>
            <div className="absolute top-[145px] left-[78px]">
              <BgPattern />
            </div>
            <div className="absolute top-[40px] left-[247px]">
              <BgPattern />
            </div>
            <div className="absolute top-[40px] right-[48px]">
              <BgPattern />
            </div>
            <div className="absolute top-[145px] right-[218px]">
              <BgPattern />
            </div>
          </div>
          <div className="m-auto space-y-4">
            <div className="flex items-center justify-center gap-4">
              <Badge type="bronze" className="w-[75px]" />
              <Badge type="gold" className="w-[93px]" />
              <Badge type="silver" className="w-[75px]" />
            </div>
            <div className="flex flex-col justify-center gap-1 text-center text-new-solid-white">
              <h1 className="font-normal">
                <strong>Bosscoder</strong> Contest
              </h1>
              <p>Contest every week. Compete and see your ranking!</p>
            </div>
          </div>
        </div>
        <div className="space-y-6 px-6 pb-6">
          {!isEmpty(registeredLiveContests) &&
            registeredLiveContests
              ?.filter((_: any, i: number) => i < 1)
              ?.map((contest: any, i: number) => (
                <ContestRegisteredCard data={contest} key={i} onClick={() => onOpenPopup(contest?.id)} />
              ))}
          <ButtonGroup
            buttons={["Live & Instant Contest", "Past Participated Contest"]}
            active={activeTab}
            onChange={handleChangeButtonType}
          />
          {isEmpty(liveContests) && isEmpty(instantContests) && isEmpty(pastContests) ? (
            <Loader />
          ) : activeTab === 0 ? (
            <>
              {liveContests?.upcoming?.length > 0 && (
                <div className="flex gap-6 overflow-y-auto p-1.5">
                  {liveContests?.upcoming?.map((contest: any, index: number) => (
                    <ContestCard
                      key={index}
                      data={contest}
                      type="live"
                      onClick={() => {
                        handleRedirectToContest(contest?.id)
                        setActivePopupContestId(contest?.id)
                      }}
                    />
                  ))}
                </div>
              )}
              {instantContests?.length > 0 && (
                <div className="space-y-6">
                  <div className="space-y-2">
                    <h2 className="text-new-accent">Instant Contest</h2>
                    <p>You can start this instant contest</p>
                  </div>
                  <div className="flex gap-6 overflow-y-auto p-1.5">
                    {instantContests?.map((contest: any, index: number) => (
                      <ContestCard
                        key={index}
                        data={contest}
                        type="instant"
                        onClick={() => handleRedirectToContest(contest?.id)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {liveContests?.past?.length > 0 && (
                <div className="space-y-6">
                  <div className="space-y-2">
                    <h2 className="text-new-accent">Past Contest</h2>
                    <p>All earlier contest held in recent times.</p>
                  </div>
                  <div className="flex gap-6 overflow-y-auto p-1.5">
                    {liveContests?.past?.map((contest: any, index: number) => (
                      <ContestCard
                        key={index}
                        data={contest}
                        type="past"
                        onClick={() => handleRedirectToContest(contest?.id)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {pastContests?.length > 0 ? (
                <div className="flex gap-6 overflow-y-auto p-1.5">
                  {pastContests?.map((contest: any, index: number) => (
                    <ContestCard
                      key={index}
                      data={contest}
                      type="participated"
                      onClick={() => handleRedirectToContest(contest?.id)}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-2 py-5">
                  <h4 className="text-new-accent">No Past Contest</h4>
                  <p>You have not participated in any contest yet.</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
