import {
  API_V4_ADMIN,
  API_V4_GET_ALL_INVOICES,
  API_V4_UPDATE_INVOICE_DETAILS,
  API_V4_FILTERED_SEARCH_INVOICES,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { InvoiceSearch, PaymentInvoice } from "domain/model/PaymentInvoice"
import PaymentInvoiceDataSource from "data/dataSource/Admin/PaymentInvoiceDataSource"

export class PaymentInvoiceAPIDataSourceImpl implements PaymentInvoiceDataSource {
  async getInvoices(InvoiceFetchDetails: InvoiceSearch): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_INVOICES), InvoiceFetchDetails)
      return response
    } catch (error) {
      return error
    }
  }

  async updateInvoice(auth: Auth, updateInvoice: PaymentInvoice, oldInvoice: PaymentInvoice): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_UPDATE_INVOICE_DETAILS), {
        id_token: auth.id_token,
        updateInvoice,
        oldInvoice,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getInvoiceByFilterSearch(InvoiceFilterSearch: InvoiceSearch): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_FILTERED_SEARCH_INVOICES), InvoiceFilterSearch)
      return response
    } catch (error) {
      return error
    }
  }
}
