import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface SubmitFeedbackUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}
export interface SubmitRecordingFeedbackUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class SubmitFeedback implements SubmitFeedbackUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.submitFeedback(auth, data)
  }
}
export class SubmitRecordingFeedback implements SubmitRecordingFeedbackUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.submitRecordingFeedback(auth, data)
  }
}
