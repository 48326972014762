import React from "react"

const NotificationAlert = ({ color }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M13.3334 9.16664C13.3334 12.5 11 14.1666 8.22669 15.1333C8.08146 15.1825 7.92371 15.1802 7.78002 15.1266C5.00002 14.1666 2.66669 12.5 2.66669 9.16664V4.49997C2.66669 4.32316 2.73693 4.15359 2.86195 4.02857C2.98697 3.90355 3.15654 3.83331 3.33335 3.83331C4.66669 3.83331 6.33335 3.03331 7.49335 2.01997C7.63459 1.89931 7.81426 1.83301 8.00002 1.83301C8.18578 1.83301 8.36545 1.89931 8.50669 2.01997C9.67335 3.03997 11.3334 3.83331 12.6667 3.83331C12.8435 3.83331 13.0131 3.90355 13.1381 4.02857C13.2631 4.15359 13.3334 4.32316 13.3334 4.49997V9.16664Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 5.83325V8.49992" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11.1667H8.00667" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default NotificationAlert
