import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"

export default function Table({ data, outlined, empty = "No data available" }: any) {
  return (
    <div
      className={cn(
        "relative overflow-x-auto rounded-sm",
        outlined ? "border border-new-neutral-light" : "shadow-table"
      )}
    >
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-editor-dark-500 text-new-solid-white">
          <tr>
            {data?.header?.map((title: any, i: any) => (
              <th
                key={i}
                className={cn(
                  "px-6 py-2 text-sm font-semibold leading-[17px]",
                  i > 0 && i >= data?.header?.length - 2 && "text-right"
                )}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmpty(data?.rows) ? (
            data?.rows?.map((row: any, i: any) => (
              <tr className={cn("group relative bg-new-solid-white text-left")} key={i}>
                {row.map((cell: any, j: any) => (
                  <td
                    key={j}
                    className={cn(
                      "whitespace-nowrap px-6 py-4 font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark",
                      j > 0 && j >= row.length - 2 && "text-right"
                    )}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-new-misc-gray">
              <td
                colSpan={data?.header?.length}
                className="py-2 text-center font-medium leading-6 text-new-neutral-light"
              >
                {empty}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
