import { ScheduledClass } from "domain/model/ScheduledClass"
import { ScheduledClassRepository } from "domain/repository/Admin/ScheduledClassRepository"

export interface ScheduledClassUseCase {
  invoke(ScheduledClass: ScheduledClass): Promise<void>
}

export class ScheduleClasses implements ScheduledClassUseCase {
  private repository: ScheduledClassRepository

  constructor(repository: ScheduledClassRepository) {
    this.repository = repository
  }
  async invoke(ScheduledClass: ScheduledClass) {
    return await this.repository.scheduleClass(ScheduledClass)
  }
}
