import { Questions } from "domain/model/Questions"
import { QuestionsRepository } from "domain/repository/Admin/QuestionsRepository"

export interface FetchQuestionsUseCase {
  invoke(id_token: any): Promise<Questions>
}

export default class FetchQuestions implements FetchQuestionsUseCase {
  private quesitonsRepository: QuestionsRepository

  constructor(quesitonsRepository: QuestionsRepository) {
    this.quesitonsRepository = quesitonsRepository
  }

  async invoke(id_token: any) {
    return this.quesitonsRepository.fetchQuestions(id_token)
  }
}
