import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import AdminQuestionChamberDataSource from "data/dataSource/Admin/QuestionChamberDataSource"
import { postAPI, putAPI } from "core/utils/axios"
import {
  API_CREATE_SUB_CASE_QUESTION,
  API_UPDATE_SUB_CASE_QUESTION,
  API_DELETE_SUB_CASE_QUESTION,
  GET_CASESTUDY_SUBJECTIVE_QUESTION,
  GET_QUESTION,
  GET_COUNT_SUB_CASE_QUESTIONS,
  GET_FILTERED_SUB_CASE_QUESTIONS,
  API_V4_ADMIN,
} from "core/constants/strings"

export default class AdminQuestionChamberDataSourceImpl implements AdminQuestionChamberDataSource {
  async create_sub_case_question(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_SUB_CASE_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async update_sub_case_question(auth: Auth, data: any): Promise<any> {
    try {
      const response = await putAPI(pn(API_V4_ADMIN, API_UPDATE_SUB_CASE_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async delete_sub_case_question(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_SUB_CASE_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async get_all_questions(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, GET_CASESTUDY_SUBJECTIVE_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async get_question(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, GET_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async get_sub_case_counts(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, GET_COUNT_SUB_CASE_QUESTIONS), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async get_filtered_questions(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, GET_FILTERED_SUB_CASE_QUESTIONS), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
