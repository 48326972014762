import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import Select from "core/components/new/Select"
import { STR_DESCRIPTION } from "core/constants/strings"
import { ThumbDownIcon, ThumbUpIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { capitalize } from "core/utils/string"
import { useEffect } from "react"
import useViewModel from "./ViewModel"
import Toast from "core/components/Toast"
import getOrdinal from "core/utils/ordinal"
import { CrossIcon } from "lucide-react"
import Modal from "core/components/Modal"
import CreateCompany from "./components/CreateCompany"

export default function QuestionView() {
  const {
    id,
    tabs,
    activeTab,
    question,
    pageLoading,
    refreshed,
    optionsDifficulty,
    toast,
    isNewQuestion,
    tags,
    loading,
    company,
    companyTags,
    companysTagsList,
    optionsSolutionHeading,
    handleTabChange,
    navigate,
    changeToastVisibility,
    fetchQuestion,
    handleTagsChange,
    handleQuestionChange,
    handleAddInput,
    handleNthInputChange,
    handleRemoveLastInput,
    createQuestion,
    updateQuestion,
    handleAddSolution,
    handleRemoveLastSolution,
    handleNthSolutionChange,
    fetchAllCompanies,
    handleCompany,
    handleRemoveCompany,
    fetchAllCourses,
    allCourses,
    allModules,
    allTopics,
    requiredFields,
    checkAllRequiredFields,
    selectedModules,
    selectedTopics,
    addModule,
    removeModule,
    addTopic,
    removeTopic,
    currentSelectedModule,
    currentSelectedTopic,
  } = useViewModel()

  useEffect(() => {
    fetchAllCourses()
    if (refreshed && id) {
      fetchAllCompanies()
      fetchQuestion(id)
    }
  }, [refreshed, id])

  useEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  useEffect(() => {
    checkAllRequiredFields()
  }, [question, selectedModules, selectedTopics])

  return (
    <div className="flex h-screen">
      <div className="editor relative flex h-screen w-full flex-1 flex-col overflow-y-scroll bg-new-editor-dark-700">
        <div className="no-scrollbar sticky top-0 z-20 flex shrink-0 overflow-x-auto">
          {tabs.map((tab, i) => (
            <button
              key={i}
              className={cn(
                "flex-1 px-4 py-2 text-sm font-medium leading-[20px]",
                tabs[activeTab] === tab
                  ? "bg-new-editor-dark-700 text-new-solid-white"
                  : "bg-new-editor-dark-500 text-new-neutral-light"
              )}
              onClick={() => handleTabChange(i)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="space-y-8 p-8">
          <ConditionalLoader isLoading={pageLoading} loader={<Loader dark />}>
            <div className="flex justify-between">
              <div className="space-y-6">
                <div className="flex items-center gap-2 text-new-neutral-light">
                  <h3 className="text-new-solid-white">
                    {isNewQuestion ? "#" : question?.question_number + 1}. {question?.problem_name}
                  </h3>
                </div>
                <div className="item-center flex flex-wrap gap-4">
                  {question?.tags?.map((tag: string, i: number) => (
                    <h5 key={i} className="rounded-sm bg-new-editor-dark-500 px-3 py-1 text-new-neutral-light">
                      {capitalize(tag)}
                    </h5>
                  ))}
                </div>
              </div>
              <div className="flex items-center gap-6 text-new-neutral">
                <button>
                  <ThumbUpIcon className="h-4 w-4" />
                </button>
                <button>
                  <ThumbDownIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            {tabs[activeTab] === STR_DESCRIPTION ? (
              <div className="space-y-6 text-new-neutral-light">
                <div
                  className="text-new-neutral-light"
                  dangerouslySetInnerHTML={{
                    __html: question?.question_details,
                  }}
                />
                {question?.input &&
                  question?.input?.map((input: any, i: number) => (
                    <div
                      key={i}
                      className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                      dangerouslySetInnerHTML={{
                        __html: input.value,
                      }}
                    />
                  ))}
                {question?.constraints && (
                  <div
                    className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                    dangerouslySetInnerHTML={{
                      __html: question?.constraints,
                    }}
                  />
                )}
              </div>
            ) : (
              question?.solution && (
                <div className="space-y-6 text-new-solid-white">
                  {question?.solution?.map((solution: any, i: number) => (
                    <div key={i} className="space-y-2">
                      <h4>{solution?.heading}</h4>
                      <div className="overflow-hidden rounded border border-new-editor-dark-500 bg-new-editor-dark-600">
                        <pre className="editor overflow-auto p-4 text-new-editor-dark-50" key={i}>
                          <code>{solution?.description}</code>
                        </pre>
                      </div>
                    </div>
                  ))}
                </div>
              )
            )}
          </ConditionalLoader>
        </div>
      </div>
      <div className="h-screen w-full flex-1 space-y-8 overflow-y-auto p-6">
        <ConditionalLoader isLoading={isNewQuestion === null} loader={<Loader />}>
          <div className="flex items-start justify-between">
            <div>
              <h2 className="text-new-accent">{isNewQuestion ? "Create" : "Update"} Question</h2>
              <p>{isNewQuestion ? "Create" : "Update"} a question and submit to save the changes</p>
            </div>
            <Button failure outlined className="w-fit" onClick={() => navigate("/admin/questions")}>
              Back
            </Button>
          </div>
          <div className="space-y-4">
            <h3 className="text-new-accent">Metadata</h3>
            <div className="grid grid-cols-3 gap-4">
              <Input name="topic" value={question?.topic} onChange={handleQuestionChange} placeholder="Question ID" />
              <Input
                name="problem_name"
                value={question?.problem_name}
                onChange={handleQuestionChange}
                placeholder="Question Name"
              />
              <Select
                name="course"
                value={question?.course || ""}
                onChange={handleQuestionChange}
                options={allCourses}
                defaultOption="Course"
              />
              <Select
                name="module"
                value={currentSelectedModule || ""}
                onChange={addModule}
                options={allModules}
                defaultOption="Module"
              />
              <Select
                name="course_topic"
                value={currentSelectedTopic || ""}
                onChange={addTopic}
                options={allTopics}
                defaultOption="Topic"
              />
              <Input value={tags} onChange={handleTagsChange} placeholder="Topics (comma separated)" />
              <Input
                name="leetcode_link"
                value={question?.leetcode_link}
                onChange={handleQuestionChange}
                placeholder="Leetcode Link"
              />
              <Select
                name="company_tags"
                value={question?.company_tags}
                onChange={handleCompany}
                options={companysTagsList}
                defaultOption="Company Tags"
              />
              <Select
                name="difficulty_level"
                value={question?.difficulty_level}
                onChange={handleQuestionChange}
                options={optionsDifficulty}
                defaultOption="Difficulty Level"
              />
              <div className="flex gap-x-1">
                {companyTags?.map((company: any, i: number) => (
                  <div
                    key={i}
                    className="flex items-center gap-2 rounded-sm border border-new-neutral-light py-1 px-2 text-new-neutral-dark"
                  >
                    <p>{company}</p>
                    <span className="ml-4 cursor-pointer" onClick={() => handleRemoveCompany(i)}>
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="space-y-2">
            {selectedModules?.map((mod, i) => (
              <div key={i} className="flex gap-2 text-new-editor-dark-150">
                <div className="border border-new-neutral-light rounded-sm py-1 px-2">
                  {mod}
                  <span className="ml-4 cursor-pointer" onClick={() => removeModule(i)}>
                    X
                  </span>
                </div>
                {selectedTopics?.[mod]?.map((top: string, i: number) => (
                  <div key={i} className="border border-new-neutral-light rounded-sm py-1 px-2">
                    {top}
                    <span className="ml-4 cursor-pointer" onClick={() => removeTopic(i)}>
                      X
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="space-y-4">
            <h3 className="text-new-accent">Description</h3>
            <textarea
              className="min-h-[300px] w-full rounded-sm border border-new-neutral bg-new-solid-white p-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              name="question_details"
              value={question?.question_details}
              onChange={handleQuestionChange}
              placeholder="Write question description"
            />
          </div>
          <div className="space-y-4">
            <h3 className="text-new-accent">Inputs</h3>
            <div className="space-y-2">
              {question?.input?.map((input, i) => (
                <textarea
                  key={i}
                  className="min-h-[100px] w-full rounded-sm border border-new-neutral bg-new-solid-white p-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                  value={input.value}
                  onChange={(e) => handleNthInputChange(e, i)}
                  placeholder={`${getOrdinal(i + 1)} input value`}
                />
              ))}
            </div>
            <div className="flex gap-4">
              <Button outlined className="w-fit" onClick={handleAddInput}>
                Add inputs
              </Button>
              <Button
                outlined
                failure
                className="w-fit"
                onClick={handleRemoveLastInput}
                disabled={question?.input?.length === 1}
              >
                Remove last input
              </Button>
            </div>
          </div>
          <div className="space-y-4">
            <h3 className="text-new-accent">Constraints</h3>
            <textarea
              className="min-h-[200px] w-full rounded-sm border border-new-neutral bg-new-solid-white p-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              name="constraints"
              value={question?.constraints}
              onChange={handleQuestionChange}
              placeholder="Constraints of the question"
            />
          </div>
          <div className="space-y-4">
            <h3 className="text-new-accent">Solutions</h3>
            <div className="space-y-4">
              {question?.solution?.map((solution, i) => (
                <div key={i} className="flex flex-col gap-4">
                  <Select
                    name="heading"
                    value={solution?.heading}
                    onChange={(e) => handleNthSolutionChange(e, i)}
                    options={optionsSolutionHeading}
                    defaultOption="Select code language"
                  />
                  <textarea
                    name="description"
                    className="min-h-[200px] w-full rounded-sm border border-new-neutral bg-new-solid-white p-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                    value={solution?.description}
                    onChange={(e) => handleNthSolutionChange(e, i)}
                    placeholder={`${getOrdinal(i + 1)} solution`}
                  />
                </div>
              ))}
            </div>
            <div className="flex gap-4">
              <Button outlined className="w-fit" onClick={handleAddSolution}>
                Add another solution
              </Button>
              <Button
                outlined
                failure
                className="w-fit"
                onClick={handleRemoveLastSolution}
                disabled={question?.solution?.length === 1}
              >
                Remove last solution
              </Button>
            </div>
          </div>
          <Button
            loading={loading}
            disabled={loading || !requiredFields}
            onClick={isNewQuestion ? createQuestion : updateQuestion}
          >
            {isNewQuestion ? "Create" : "Update"} Question
          </Button>
        </ConditionalLoader>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
