import React from "react"

export default function More({ className, ...props }: any) {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.667C4.9 14.667 4 15.567 4 16.667C4 17.767 4.9 18.667 6 18.667C7.1 18.667 8 17.767 8 16.667C8 15.567 7.1 14.667 6 14.667ZM26 14.667C24.9 14.667 24 15.567 24 16.667C24 17.767 24.9 18.667 26 18.667C27.1 18.667 28 17.767 28 16.667C28 15.567 27.1 14.667 26 14.667ZM14 16.667C14 15.567 14.9 14.667 16 14.667C17.1 14.667 18 15.567 18 16.667C18 17.767 17.1 18.667 16 18.667C14.9 18.667 14 17.767 14 16.667Z"
        className={props.pathClassName}
      />
    </svg>
  )
}
