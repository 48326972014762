import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface UpdateMentorUseCase {
  invoke(auth: Auth, mentor_id: any, reason: any): Promise<any>
}

export class UpdateMentor implements UpdateMentorUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: any, reason: any): Promise<any> {
    return await this.repository.updateMentor(auth, mentor_id, reason)
  }
}
