import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface StudentBatchPauseUseCase {
  invoke(auth: Auth, reason: any): Promise<any>
}

export class StudentBatchPause implements StudentBatchPauseUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, reason: any) {
    return this.repository.studentBatchPause(auth, reason)
  }
}
