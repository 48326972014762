import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import Input from "core/components/new/Input"
import useDocumentatioModel from "./DocumentationViewModel"
import Loader from "core/components/Loader"
import { AddIcon } from "core/constants/svgs"
import Card from "./components/Card"

const DocumentationView = () => {
  const {
    searchFile,
    documentationList,
    loading,
    fetchDocumentationFiles,
    redirectToDocmentationFile,
    handleSearchDocumentation,
    handleSearchDocumentationFileChange,
  } = useDocumentatioModel()

  React.useEffect(() => {
    fetchDocumentationFiles()
  }, [])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-1">
          <h2 className="text-new-accent">Code Base Documentation.</h2>
          <p className="">You have access to all Files related to Documentation.</p>
        </div>
        <button
          className="inline-flex w-full items-center justify-center gap-4 rounded-sm py-12 text-new-neutral shadow-[0px_0px_7px_0px_rgba(0,0,0,0.10)]"
          onClick={() => redirectToDocmentationFile("add")}
        >
          <AddIcon className="h-6 w-6" />
          <h3>Add Files to Document</h3>
        </button>

        {loading ? (
          <Loader dark />
        ) : (
          <>
            <Input
              value={searchFile}
              onChange={handleSearchDocumentationFileChange}
              id="searchFile"
              type="text"
              placeholder="Type your keyword feature here!"
              containerClass="max-w-[400px]"
            />
            {documentationList?.length > 0 &&
              documentationList
                ?.filter(handleSearchDocumentation())
                ?.map((file, i) => <Card key={i} data={file} onEdit={() => redirectToDocmentationFile(file?.id)} />)}
          </>
        )}
      </div>
    </DashboardLayout>
  )
}

export default DocumentationView
