import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface UpdateDoubtStatusUseCase {
  invoke(auth: Auth, like: any, problem_id: any): Promise<any>
}

export class UpdateDoubtStatus implements UpdateDoubtStatusUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, like: any, problem_id: any) {
    return this.repository.updateDoubtStatus(auth, like, problem_id)
  }
}
