import { Auth } from "domain/model/Auth"
import { RedeemCoinsRepository } from "domain/repository/Admin/RedeemCoinsRepository"

export interface GetAllCouponsUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllCoupons implements GetAllCouponsUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getAllCoupons(auth)
  }
}
