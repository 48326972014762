import { Auth } from "domain/model/Auth"
import HomeDataSource from "data/dataSource/Student/HomeDataSource"
import pn from "core/utils/pn"
import { postAPI, Server } from "core/utils/axios"
import {
  API_SURVEY,
  API_TOGGLE_CHECKLIST_STATUS,
  API_TOGGLE_HOMEPAGE_LIST_STATUS,
  API_V4_PRE_HOMEPAGE,
  API_V4_STUDENT,
  STUDENT,
  ONBOARDING_TEAM,
  HOME,
  MEET_AND_GREET,
  PREREQUISITE,
  CLASS_REMAINDER,
  MENTOR_SESSION_REMAINDER,
  CONTEST_REMAINDER,
  ROADMAP,
  PROGRESS_MEET,
  ONBOARDING_MEET,
  PLACEMENT_COHORT,
  RIGHT_PANEL,
  TOAST,
  API_TAKE_PLEDGE,
} from "core/constants/strings"

const server = new Server()
export default class HomeAPIDataSourceImpl implements HomeDataSource {
  async getHomeDetails(auth: Auth) {
    const response = await postAPI(pn(API_V4_STUDENT, API_V4_PRE_HOMEPAGE), {
      id_token: auth.id_token,
    })
    return response
  }

  async takeSurvey(auth: Auth, data: any) {
    const response = await postAPI(pn(API_V4_STUDENT, API_SURVEY), {
      id_token: auth.id_token,
      ...data,
    })
    return response
  }

  async toggleChecklistStatus(auth: Auth, data: any) {
    const response = await postAPI(pn(API_V4_STUDENT, API_TOGGLE_CHECKLIST_STATUS), {
      id_token: auth.id_token,
      ...data,
    })
    return response
  }

  async toggleHomepageListStatus(auth: Auth, data: any) {
    const response = await postAPI(pn(API_V4_STUDENT, API_TOGGLE_HOMEPAGE_LIST_STATUS), {
      id_token: auth.id_token,
      ...data,
    })
    return response
  }

  async getOnboardingTeamDetails(auth: Auth, data: any) {
    const response = await server.get(`${pn(STUDENT, HOME, ONBOARDING_TEAM)}?team=onboarding`, {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getMeetAndGreetDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, MEET_AND_GREET), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }
  async getPrerequisiteDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, PREREQUISITE), {
      Authorization: `Bearer ${auth.id_token}`,
    })

    return response
  }

  async getRemainderDetails(auth: Auth, type: string) {
    let response: any
    if (type === "class") {
      response = await server.get(`${pn(STUDENT, HOME, CLASS_REMAINDER)}?isUpcomingClass=true`, {
        Authorization: `Bearer ${auth.id_token}`,
      })
    } else if (type === "assignment") {
      response = await server.get(`${pn(STUDENT, HOME, CLASS_REMAINDER)}?isAssignmentRemainder=true`, {
        Authorization: `Bearer ${auth.id_token}`,
      })
    }

    return response
  }

  async getMentorSessionRemainder(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, MENTOR_SESSION_REMAINDER), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getContestRemainder(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, CONTEST_REMAINDER), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getRoadmapDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, ROADMAP), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getProgressMeetDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, PROGRESS_MEET), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getOnboardingMeetingDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, "onboarding", "data"), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async updateOnboardingMeetingDetails(auth: Auth, data: any) {
    const response = await server.post(pn(STUDENT, "onboarding", "feedback"), data, {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getPlacementCohortDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, PLACEMENT_COHORT), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getRightPanelDetails(auth: Auth) {
    const response = await server.get(pn(STUDENT, HOME, RIGHT_PANEL), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async getNotficationToastDetails(auth: Auth): Promise<any> {
    const response = await server.get(pn(STUDENT, HOME, TOAST), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }
  async createPledgeResponse(auth: Auth, email: string): Promise<any> {
    const response = await server.post(
      pn(STUDENT, HOME, API_TAKE_PLEDGE),
      { email },
      {
        Authorization: `Bearer ${auth.id_token}`,
      }
    )
    return response
  }
}
