import DocumentationFileRepository from "domain/repository/Admin/DocumentationFileRepository"
import { Auth } from "domain/model/Auth"

export interface UpdateDocumentationFileUseCase {
  invoke(auth: Auth, id: string, name: string, feature: string, subFeature: string): Promise<any>
}

export class UpdateDocumentationFile implements UpdateDocumentationFileUseCase {
  private repository: DocumentationFileRepository

  constructor(repository: DocumentationFileRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string, name: string, feature: string, subFeature: string): Promise<any> {
    return await this.repository.update_documentation_files(auth, id, name, feature, subFeature)
  }
}
