import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface EditMentorCompensationUseCase {
  invoke(auth: Auth, instructor_id: string, index: number, amount: number): Promise<any>
}

export default class EditMentorCompensation implements EditMentorCompensationUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string, index: number, amount: number) {
    return await this.repository.editMentorCompensation(auth, instructor_id, index, amount)
  }
}
