import McqRepository from "domain/repository/Admin/McqRepository"
import { Auth } from "domain/model/Auth"

export interface GetMcqQuestionsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetMcqQuestions implements GetMcqQuestionsUseCase {
  private repository: McqRepository

  constructor(repository: McqRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.get_mcq_questions(auth)
  }
}
