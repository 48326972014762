import React from "react"
import Popup from "core/components/PremiumPopup"
import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import PlacementCard from "./components/PlacementCard"
import PlacementCard2 from "./components/PlacementCard2"
import usePlacementViewModel from "./PlacementViewModel"
import { STR_PLACEMENT, STR_PLACEMENT_SUBTITLE, STR_PLACEMENT2 } from "core/constants/strings"
import PlacementProcess from "./components/PlacementProcess"
import PartnerCompanyList from "./components/PartnerCompanyList"
import Footer from "./components/Footer"
import JoinTelegramComponent from "./components/JoinTelegramComponent"
import PlacementUpdate from "./components/PlacementUpdate"
import DashboardLayoutV2 from "core/layouts/DashboardLayoutv2"
import PlacementPolicyCard from "./components/PlacementPolicyCard"

export default function PlacementView() {
  const {
    toast,
    allPlacementsList,
    newPlacementList,
    // jobStatusError,
    // tabs1Data,
    // tabs2Data,
    // applicationStatus,
    // activeButtonType,
    // jobDetails,
    // showDescription,
    isPremiumPopupVisible,
    student,
    containerRef,
    studentFourMonth,
    getRecentPlacements,
    getAllJobsList,
    getStudentJobs,
    getNewPlacement,
    // getJobDetails,
    // getApplicationStatus,
    // changeActiveButtonType,
    // applyForJob,
    changeToastVisibility,
    handleClosePopup,
  } = usePlacementViewModel()

  React.useEffect(() => {
    getRecentPlacements()
    getAllJobsList()
    getStudentJobs()
    getNewPlacement()
  }, [])

  const backgroundColors = ["bg-[#E0E7FF]", "bg-[#BBF7D0]", "bg-[#FEF3C7]", "bg-[#FEE2E2]"]
  const backgroundColors2 = ["#FFDA8C", "#F0F477", "#EDC8FB", "#97FDB3", "#FFBEBE", "#99D9FA"]
  const isDSML = student?.batch?.includes("DSML") || student?.tracks.includes("data_engineer")
  const isEvolve = student?.batch?.includes("EV")
  return studentFourMonth ? (
    isDSML ? (
      <DashboardLayoutV2>
        <JoinTelegramComponent />
        <PlacementUpdate />
        <PlacementProcess isEvolve={isEvolve} />
        <div className="flex flex-col p-8 gap-6 rounded">
          <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
        </div>
        <PartnerCompanyList />
        <Footer />
      </DashboardLayoutV2>
    ) : isEvolve ? (
      <DashboardLayoutV2>
        <JoinTelegramComponent />
        <PlacementUpdate />
        <PlacementProcess isEvolve={isEvolve} />
        <div className="flex flex-col p-8 gap-6 rounded">
          <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
        </div>
        <PartnerCompanyList />
        <Footer />
      </DashboardLayoutV2>
    ) : (
      <DashboardLayoutV2 isLoading={!allPlacementsList || allPlacementsList.length === 0}>
        {isPremiumPopupVisible && <Popup onClose={handleClosePopup} />}
        <JoinTelegramComponent />
        <div className="space-y-6 p-6 text-new-neutral-dark">
          <div className="space-y-6">
            <PlacementUpdate />
            <PlacementProcess isEvolve={isEvolve} />
            {newPlacementList.length > 0 && (
              <>
                <div className=" relative flex space-y-2">
                  <div>
                    <h2 className="text-new-accent">{STR_PLACEMENT}</h2>
                    <p>{STR_PLACEMENT_SUBTITLE}</p>
                  </div>
                </div>
                <div className="overflow-x gap-4 shadow rounded pb-6">
                  <div
                    ref={containerRef}
                    id="placementContainer"
                    className="flex overflow-x-scroll mx-10 custom-scrollbar-2"
                  >
                    {newPlacementList?.map((data: any, index: any) => (
                      <PlacementCard2
                        key={index}
                        bg={backgroundColors2[index % 6]}
                        name={data.name}
                        student_pic={data.profile_picture}
                        company_logo={data.company_logo}
                        yoe={data.yoe}
                        position={data.position}
                        testimonial={data.testimonial}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col p-8 gap-6 rounded">
              <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
            </div>
            <div>
              <h2 className="text-new-accent">{STR_PLACEMENT2}</h2>
              {/* <p>{STR_PLACEMENT_SUBTITLE2}</p> */}
            </div>
            <div className="overflow-x gap-2 rounded pb-6 shadow">
              <div
                ref={containerRef}
                id="placementContainer"
                className="custom-scrollbar-2 mx-6 flex overflow-x-scroll"
              >
                {allPlacementsList?.map((data: any, index: any) => (
                  <PlacementCard
                    key={index}
                    bg={backgroundColors[index % 4]}
                    name={data.name}
                    student_pic={data.student_pic}
                    company_name={data.company_name}
                  />
                ))}
              </div>
            </div>
            <PartnerCompanyList />
            <Footer />
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </DashboardLayoutV2>
    )
  ) : isDSML ? (
    <DashboardLayoutV2>
      <JoinTelegramComponent />
      <PlacementProcess isEvolve={isEvolve} />
      <div className="flex flex-col p-8 gap-6 rounded">
        <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
      </div>
      <PlacementUpdate />
      <PartnerCompanyList />
      <Footer />
    </DashboardLayoutV2>
  ) : isEvolve ? (
    <DashboardLayoutV2>
      <JoinTelegramComponent />
      <PlacementProcess isEvolve={isEvolve} />
      <div className="flex flex-col p-8 gap-6 rounded">
        <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
      </div>

      <PlacementUpdate />
      <PartnerCompanyList />
      <Footer />
    </DashboardLayoutV2>
  ) : (
    <DashboardLayoutV2 isLoading={!allPlacementsList || allPlacementsList.length === 0}>
      {isPremiumPopupVisible && <Popup onClose={handleClosePopup} />}
      <JoinTelegramComponent />
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-6">
          <PlacementProcess isEvolve={isEvolve} />
          <div className="flex flex-col p-8 gap-6 rounded">
            <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
          </div>
          <PlacementUpdate />
          {newPlacementList.length > 0 && (
            <>
              {" "}
              <div className=" relative flex space-y-2">
                <div>
                  <h2 className="text-new-accent">{STR_PLACEMENT}</h2>
                  <p>{STR_PLACEMENT_SUBTITLE}</p>
                </div>
              </div>
              <div className="overflow-x gap-4 shadow rounded pb-6">
                <div
                  ref={containerRef}
                  id="placementContainer"
                  className="custom-scrollbar-2 mx-10 flex overflow-x-scroll"
                >
                  {newPlacementList?.map((data: any, index: any) => (
                    <PlacementCard2
                      key={index}
                      bg={backgroundColors2[index % 6]}
                      name={data.name}
                      student_pic={data.profile_picture}
                      company_logo={data.company_logo}
                      yoe={data.yoe}
                      position={data.position}
                      testimonial={data.testimonial}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          <div>
            <h2 className="text-new-accent">{STR_PLACEMENT2}</h2>
          </div>
          <div className="overflow-x gap-2 shadow rounded pb-6">
            <div ref={containerRef} id="placementContainer" className="flex overflow-x-scroll mx-6 custom-scrollbar-2">
              {allPlacementsList?.map((data: any, index: any) => (
                <PlacementCard
                  key={index}
                  bg={backgroundColors[index % 4]}
                  name={data.name}
                  student_pic={data.student_pic}
                  company_name={data.company_name}
                />
              ))}
            </div>
          </div>
          <PartnerCompanyList />
          <Footer />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutV2>
  )
}
