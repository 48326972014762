import { Auth } from "domain/model/Auth"
import { GetAllActiveStudentsOfBatchRepository } from "domain/repository/Admin/GetAllActiveStudentsOfBatchRepository"

export interface GetAllActiveStudentInBatchUseCase {
  invoke(auth: Auth, batch: string): Promise<any>
}

export class GetAllActiveStudentInBatch implements GetAllActiveStudentInBatchUseCase {
  private repository: GetAllActiveStudentsOfBatchRepository

  constructor(repository: GetAllActiveStudentsOfBatchRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, batch: string) {
    return await this.repository.getAllStudentOfBatchList(auth, batch)
  }
}
