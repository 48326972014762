import { GetDailyReport } from "domain/model/DailyReport"
import DailyReportRepository from "domain/repository/Admin/DailyReportRepository"
export interface getDailyReportUseCase {
  invoke(details: GetDailyReport): Promise<any>
}

export class GetDailyReportData implements getDailyReportUseCase {
  private repository: DailyReportRepository

  constructor(repository: DailyReportRepository) {
    this.repository = repository
  }

  async invoke(details: GetDailyReport): Promise<any> {
    return await this.repository.getDailyReport(details)
  }
}
