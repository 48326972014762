import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface getPlacementCompanyUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}

export class GetPlacementCompany implements getPlacementCompanyUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string): Promise<any> {
    return await this.repository.getPlacementCompany(auth, email)
  }
}
