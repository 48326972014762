import React from "react"
import { format } from "date-fns"
import { MentorGlobeIllustration } from "core/constants/svgs"
import Button from "core/components/new/Button"

export default function MentorshipCard({ session, mentor, onBooking }: any) {
  const [isBookingLoading, setIsBookingLoading] = React.useState(false)

  async function handleBookSession() {
    setIsBookingLoading(true)
    await onBooking(session?.date, session?.start, session?.end)
    setIsBookingLoading(false)
  }

  return (
    <div className="flex items-center gap-2 rounded-sm text-new-accent">
      <div className="h-[65px] w-1.5 bg-new-accent" />
      <div className="flex h-[115px] w-full rounded-sm bg-new-solid-blue-light">
        <div className="flex h-full items-center justify-center rounded border border-new-accent-light bg-new-solid-white px-4">
          <MentorGlobeIllustration />
        </div>
        <div className="flex w-full flex-col justify-between p-4">
          <div className="flex justify-between">
            <h4>Mentorship Session</h4>
            <div className="flex items-center gap-2 rounded-sm bg-new-solid-white px-2 py-1 shadow-[2px_2px_#312E81]">
              <h4>{format(new Date(session?.start_timestamp), "dd")}</h4>
              <p className="text-xs leading-[19px]">{format(new Date(session?.start_timestamp), "MMM")}</p>
            </div>
          </div>
          <div className="flex items-end justify-between gap-4">
            <div className="">
              <h5>
                {format(new Date(session?.start_timestamp), "h:mm a")} to{" "}
                {format(new Date(session?.end_timestamp), "h:mm a")}
              </h5>
              <h5>By {mentor?.name || mentor}</h5>
            </div>
            <Button
              outlined
              className="w-fit"
              onClick={handleBookSession}
              disabled={isBookingLoading}
              loading={isBookingLoading}
            >
              Book
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
