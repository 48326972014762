import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface GetOnboardingMeetingDetailsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetOnboardingMeetingDetails implements GetOnboardingMeetingDetailsUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getOnboardingMeetingDetails(auth)
  }
}
