import { format } from "date-fns"
import { cn } from "core/lib/utils"
import { ChevronLeftIcon, ChevronRightIcon } from "core/constants/svgs"

export default function NewMentorCalendar({
  header,
  loading,
  days,
  activeDate,
  setActiveDate,
  onClick,
  onPrevMonth,
  onNextMonth,
  disabled = false,
}: any) {
  return (
    <div className="space-y-4 text-center">
      <div className="flex justify-center items-center">
        <button
          className={cn(
            "flex size-[24px] items-center justify-center rounded-[4px] border-[0.5px] bg-new-solid-white",
            disabled
              ? "border-new-neutral-light text-new-neutral-light"
              : "border-[#E3E3E3] text-new-accent hover:bg-[#E3E3E3] hover:text-new-solid-white"
          )}
          onClick={() => onPrevMonth()}
          disabled={disabled}
        >
          <ChevronLeftIcon className="size-[18px]" fillColor="#0C0C0C" />
        </button>

        <h3
          className={cn("w-[156px]", disabled ? "text-new-neutral-light" : "text-[#0C0C0C]", "text-[14px] font-[500]")}
        >
          {format(new Date(activeDate), "MMMM yyyy")}
        </h3>

        <button
          className={cn(
            "flex size-[24px] items-center justify-center rounded-[4px] bg-new-solid-white border-[0.5px]",
            disabled
              ? "border-new-neutral-light text-new-neutral-light"
              : "border-[#E3E3E3] text-new-accent hover:bg-[#E3E3E3] hover:text-new-solid-white"
          )}
          onClick={() => onNextMonth()}
          disabled={disabled}
        >
          <ChevronRightIcon className="size-[18px]" fillColor="#0C0C0C" />
        </button>
      </div>
      <div className=" grid grid-cols-7 gap-[4px]">
        {header.map((day: string, i: number) => (
          <p
            key={i}
            className={cn(
              "flex size-[28px] items-center justify-center text-[12px] font-[500]",
              disabled ? "text-new-neutral-light" : "text-[#4F609B]"
            )}
          >
            {day}
          </p>
        ))}
        {days.map((day: any, i: number) => (
          <button
            key={i}
            type="button"
            className={cn(
              day.date === new Date().toISOString().split("T")[0] &&
                !loading &&
                "border-[0.5px] border-[#2C4C9F] rounded-[4px]",
              "relative flex size-[28px] select-none items-center justify-center rounded-[4px] focus:z-10",
              !disabled && "disabled:hover:bg-new-solid-white disabled:hover:text-new-neutral-light",
              day.sessions?.length > 0 && !disabled && "bg-[#EDFFF2]",
              day.isActiveDate ? "bg-[#162456] text-white" : "",
              day.isActiveDate
                ? disabled
                  ? "bg-new-neutral-light text-new-solid-white"
                  : ""
                : day.isCurrentMonth
                  ? disabled
                    ? "bg-new-solid-white text-new-neutral-light"
                    : ""
                  : disabled
                    ? "text-new-neutral-light"
                    : "text-new-neutral-light hover:bg-new-solid-blue-light"
            )}
            onClick={() => {
              onClick(day.date)
              setActiveDate(new Date(day.date))
            }}
            disabled={!day.isCurrentMonth || disabled}
          >
            <p className="text-[12px] font-[500] ">{day.date.split("-").at(-1)}</p>
          </button>
        ))}
      </div>
    </div>
  )
}
