import { Tooltip } from "@mui/material"
import Button from "core/components/new/Button"
import { CoinIcon } from "core/constants/svgs"
import useLocalStorage from "core/hooks/useLocalStorage"
import { useNavigate } from "react-router-dom"

export default function StoreItem({ item }: any) {
  const navigate = useNavigate()
  const [student] = useLocalStorage<any>("student", {} as any)

  return (
    <div className="flex flex-col overflow-hidden rounded shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
      <div className="h-[188px] w-full bg-new-solid-white py-4">
        {item?.image_url && (
          <img className="h-full w-full object-contain mix-blend-multiply" src={item?.image_url} alt="store item" />
        )}
      </div>
      <div className="flex flex-1 flex-col justify-between gap-7 p-6">
        <div className="flex justify-between gap-4 font-medium">
          <Tooltip title={item?.name}>
            <h4 className="line-clamp-2">{item?.name}</h4>
          </Tooltip>
        </div>
        <div className="mt-auto flex w-full flex-1 items-end justify-between self-end">
          <div className="flex items-center gap-2">
            <span>
              <CoinIcon className="h-6 w-6" />
            </span>
            <h3 className="text-[#FBBF24]">{item?.coins}</h3>
          </div>
          <Button
            disabled={true || item?.quantity === 0 || item?.coins > student?.metrics[0]?.current}
            onClick={() => navigate(item?.item_id)}
            className="w-fit"
            outlined
          >
            Purchase
          </Button>
        </div>
      </div>
    </div>
  )
}
