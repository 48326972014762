import StandaredSelect from "core/components/StandaredSelect"
import React from "react"

export default function EligibilityCandidatesTable({
  tableHeaders,
  jobApplications,
  jobStatus,
  selectJobStates,
  onChangeJobStatus,
}: any) {
  return (
    <div className="px-20">
      <div className="relative overflow-x-auto shadow-md rounded-md">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 " aria-label="simple table">
          <thead className="text-[16px] text-white bg-gradient-to-r from-[#06A1F9] to-[#2246C3] h-[40px]">
            <tr>
              {tableHeaders?.map((title: any, index: any) => (
                <th key={index} className="px-4 py-4 text=[16px] font-medium  ">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {jobApplications?.map((data: any, index: any) => (
              <TableRow
                data={data}
                key={index}
                index={index}
                jobStatus={jobStatus}
                selectJobStates={selectJobStates}
                onChangeJobStatus={onChangeJobStatus}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export function TableRow({ data, index, selectJobStates, onChangeJobStatus, jobStatus }: any) {
  return (
    <tr>
      <td className="px-4 py-4 text=[16px] font-medium whitespace-nowrap">{data.student_name}</td>
      <td className="px-4 py-4 text=[16px] font-medium whitespace-nowrap">{data.student_email}</td>
      <td className="px-4 py-4 text=[16px] font-medium whitespace-nowrap">{jobStatus[parseInt(data.status) - 1]}</td>
      <td className="px-4 py-4 text=[16px] font-medium whitespace-nowrap" key={index}>
        <StandaredSelect
          options={jobStatus}
          className="w-fit"
          value={selectJobStates[index].value}
          onChange={(e: any) => onChangeJobStatus(e, index, data.student_email)}
          key={index}
        />
      </td>
    </tr>
  )
}
