import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface ToggleLeetCodeProblemCompleteUseCase {
  invoke(auth: Auth, problem_id: string, status: boolean, coins: number): Promise<void>
}

export class ToggleLeetCodeProblemComplete implements ToggleLeetCodeProblemCompleteUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, problem_id: string, status: boolean, coins: number) {
    return this.repository.toggleLeetCodeProblemComplete(auth, problem_id, status, coins)
  }
}
