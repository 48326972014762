import Loader from "core/components/Loader"
import { AddIcon, CrossIcon } from "core/constants/svgs"
import DashboardLayout from "core/layouts/DashboardLayout"
import Toast from "core/components/Toast"
import ButtonGroup from "core/components/ButtonGroup"
import StoreViewModel from "./StoreViewModel"
import React from "react"
import Model from "core/components/Model"
import CreateCoupon from "./components/CreateCoupon"
import CouponCard from "./components/CouponCard"
import EditCoupon from "./components/EditCoupon"
import Button from "core/components/new/Button"
import UsedCouponCard from "./components/UsedCouponCard"
import ConfirmPopUp from "./components/ConfirmPopUp"
import StandaredSelect from "core/components/StandaredSelect"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty, removeAtIndex } from "core/utils/misc"

export default function StoreView() {
  const {
    auth,
    toast,
    activeButtonType,
    loading,
    addCouponModal,
    reload,
    couponId,
    couponType,
    amount,
    purchaseDate,
    expiryDate,
    createCouponLoader,
    editModel,
    deleteModel,
    editModalData,
    deleteModalData,
    deleteCouponLoader,
    referenceId,
    activeFilter,
    coupons,
    batch,
    selectBatch,
    currentSelectBatch,
    saveBatchLoader,
    getBatches,
    setSelectBatch,
    setCurrentSelectBatch,
    handleBatchChange,
    handleActiveFilter,
    DeleteCouponFunc,
    AddNewCoupon,
    UpdateCoupon,
    handleDeleteModel,
    handleEditModel,
    handleReferenceId,
    handleChangeCouponId,
    handleCouponType,
    handleAmount,
    handleChangePurchaseDate,
    handleChangeExpiryDate,
    handleAddCouponModal,
    fetchAllCoupons,
    updateBatchRedeemCoins,
    fetchBatchesAllowedRedeemCoins,
    changeToastVisibility,
    changeActiveButtonType,
  } = StoreViewModel()

  React.useEffect(() => {
    fetchAllCoupons()
    getBatches()
    fetchBatchesAllowedRedeemCoins()
  }, [auth, reload])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="flex justify-between">
          <div className="space-y-1">
            <h2 className="text-new-accent">All Coupons</h2>
            <p className="">Create, update and manage all the coupons here.</p>
          </div>
          <div className="">
            <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
              <ConditionalLoader
                isLoading={isEmpty(selectBatch)}
                loader={<em className="text-xs text-new-neutral-light">No batches selected</em>}
              >
                {selectBatch.map((batch: any, i: number) => (
                  <li key={i} className="inline-flex items-center gap-2">
                    {batch}
                    <button
                      className="rounded bg-new-solid-black p-1 text-new-solid-white"
                      onClick={() => {
                        setSelectBatch(removeAtIndex(selectBatch, i))
                        setCurrentSelectBatch("")
                      }}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </button>
                  </li>
                ))}
              </ConditionalLoader>
            </ul>
            <div className="flex items-center gap-x-1">
              <StandaredSelect
                required
                options={batch}
                className="h-fit w-[270px]"
                placeholder="Select Coupon Applicable Batch "
                onChange={handleBatchChange}
                value={currentSelectBatch}
              />
              <Button
                loading={saveBatchLoader}
                disabled={selectBatch.length > 0 ? false : true}
                className="h-2 w-4"
                onClick={updateBatchRedeemCoins}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <button
          className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-gray-400 py-8 text-new-neutral shadow-[0px_0px_7px_0px_rgba(0,0,0,0.10)]"
          onClick={handleAddCouponModal}
        >
          <AddIcon className="h-6 w-6" />
          <h3>Add A New Coupon</h3>
        </button>
        {loading ? (
          <Loader dark />
        ) : (
          <>
            <div className="mt-10 px-5">
              <ButtonGroup
                buttons={["Available Coupons", "Utilized Coupons"]}
                active={activeButtonType}
                onChange={changeActiveButtonType}
              />
            </div>
            <div className=""></div>
          </>
        )}
        <div className="flex flex-row gap-4">
          <Button
            onClick={() => handleActiveFilter("All")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "All" ? true : false}
          >
            All
          </Button>
          <Button
            onClick={() => handleActiveFilter("50 Rupees")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "50 Rupees" ? true : false}
          >
            50 Rupees
          </Button>
          <Button
            onClick={() => handleActiveFilter("75 Rupees")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "75 Rupees" ? true : false}
          >
            75 Rupees
          </Button>
          <Button
            onClick={() => handleActiveFilter("100 Rupees")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "100 Rupees" ? true : false}
          >
            100 Rupees
          </Button>
          <Button
            onClick={() => handleActiveFilter("200 Rupees")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "200 Rupees" ? true : false}
          >
            200 Rupees
          </Button>
          <Button
            onClick={() => handleActiveFilter("500 Rupees")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "500 Rupees" ? true : false}
          >
            500 Rupees
          </Button>
        </div>

        {activeButtonType === 0 && coupons && coupons?.unused?.length > 0 ? (
          coupons?.unused?.map((data: any, i: number) => (
            <CouponCard key={i} handleDeleteModel={handleDeleteModel} handleEditModel={handleEditModel} data={data} />
          ))
        ) : activeButtonType === 1 && coupons && coupons.used.length !== 0 ? (
          coupons?.used?.map((data: any, i: number) => <UsedCouponCard data={data} />)
        ) : (
          <div>No Data Found</div>
        )}
        <Model width="max-w-[500px]" isOpen={addCouponModal} onClose={handleAddCouponModal}>
          <CreateCoupon
            couponId={couponId}
            couponType={couponType}
            amount={amount}
            purchaseDate={purchaseDate}
            expiryDate={expiryDate}
            referenceId={referenceId}
            handleReferenceId={handleReferenceId}
            createCouponLoader={createCouponLoader}
            AddNewCoupon={AddNewCoupon}
            handleChangeCouponId={handleChangeCouponId}
            handleCouponType={handleCouponType}
            handleAmount={handleAmount}
            handleChangePurchaseDate={handleChangePurchaseDate}
            handleChangeExpiryDate={handleChangeExpiryDate}
          />
        </Model>
        <Model width="max-w-[500px]" isOpen={editModel} onClose={() => handleEditModel({})}>
          {editModel && <EditCoupon UpdateCoupon={UpdateCoupon} data={editModalData} />}
        </Model>
        {deleteModel && (
          <ConfirmPopUp
            loading={deleteCouponLoader}
            id={deleteModalData.id}
            handleDeleteModel={handleDeleteModel}
            DeleteCouponFunc={DeleteCouponFunc}
            heading="Are you sure you want to delete this coupon"
          />
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
