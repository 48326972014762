import bannerImage from "assets/images/masters_banner.png"
import Pattern from "assets/svgs/Pattern"
import Flag from "core/components/Flag"
import { CrossIcon } from "core/constants/svgs"

export default function RegisteredModal({ show, onRedirect, onClose }: any) {
  if (!show) return null

  return (
    <>
      <div className="fixed inset-0 z-20">
        <div className="flex min-h-screen items-center justify-center">
          <div onClick={onClose} className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-white/80 backdrop-blur-md" />
          </div>
          <div className="z-10 w-full max-w-[862px]">
            <div className="relative flex h-[300px] w-full justify-between overflow-hidden rounded bg-gradient-to-r from-[#091D50] to-[#1648C8]">
              <div className="z-10 h-full w-full max-w-[530px] self-end overflow-hidden">
                <img src={bannerImage} alt="banner hero" className="h-full w-full object-cover object-right" />
              </div>
              <div className="z-10 m-6 flex h-auto w-full max-w-[243px] flex-col">
                <div className="space-y-4 text-new-solid-white">
                  <Flag text="Registered!" bg="#00C22B" color="#fff" />
                  <div className="space-y-1">
                    <h2>Registration Successful!</h2>
                    <p className="line-clamp-3">Our team will connect with you shortly.</p>
                  </div>
                </div>
                <button
                  className="mt-auto rounded-sm bg-new-solid-white px-16 py-4 text-base font-medium text-new-accent hover:opacity-90"
                  onClick={onRedirect}
                >
                  Continue
                </button>
              </div>
              <button className="ml-3 mr-8 mt-8 self-start" onClick={onClose}>
                <CrossIcon className="h-6 w-6 text-new-solid-white" />
              </button>
              <div className="absolute -top-6 -left-6 text-new-solid-white">
                <Pattern />
              </div>
              <div className="absolute bottom-[calc(50%+30px)] right-[calc(50%+70px)] text-new-solid-white">
                <Pattern />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
