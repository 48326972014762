import { createCompany } from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface CreateCompanyUseCase {
  invoke(request: createCompany): Promise<any>
}

export class CreateCompany implements CreateCompanyUseCase {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: createCompany): Promise<any> {
    return await this.repository.createCompany(request)
  }
}
