import { Button } from "core/components/v2/Button"
import celebrate from "assets/images/payment-method/celebrate.gif"
import { Link } from "react-router-dom"

export default function PopUpCardModel({
  card_key,
  isOpen,
  onClose,
  children,
  width,
  height,
  img,
  heading,
  details,
  size,
}: any) {
  if (!isOpen) return null
  return (
    <div className="relative z-50">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0"></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div
          className={`bg-white relative w-[274px] ${size == "lg" ? "h-[390px]" : "h-[347px]"} shadow-[inset_-1px_1px_3px_rgba(34,41,48,0.15)] rounded-[8px]`}
        >
          <img alt="" src={celebrate} className="absolute bottom-[4px] left-0 right-0 mx-auto w-[270px]" />
          <button
            onClick={onClose}
            className="absolute z-10 top-[24px] right-[24px] rounded-[1.5px] font-[300] text-[24px] size-[24px] hover:bg-[#efebeb]"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" rx="2" fill="white" fillOpacity="0.26" />
                <path d="M18 6L6 18" stroke="#646464" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="#646464" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </button>
          <div className="absolute top-0 left-0 right-0 m-auto">
            <img alt="" src={img} className="w-[175px] h-[150px] mx-auto mt-[50px]" />

            <div className="flex flex-col items-center">
              <p className="mt-[35px] text-yellow-400 font-[600] text-[18px] ">{heading}</p>

              <p
                className={`mt-[10px] ${size == "lg" ? "w-[250px]" : "w-[200px]"} text-[#333] font-[400] text-[12px] text-center`}
              >
                {details}
              </p>

              {size == "lg" && (
                <Link to="/mentor">
                  <Button variant={"secondary"} border={"thin"} className="mt-[10px] focus:bg-v2-gray-600">
                    {card_key == "card2" && "Book Now"}
                    {card_key == "card3" && "Select Mentor"}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
