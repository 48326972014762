import React from "react"

const AwardEmpty = ({ className }: any) => {
  return (
    <svg className={className} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.07203 1.31085C6.40793 0.474381 7.59207 0.47438 7.92797 1.31085L9.09368 4.2137C9.23672 4.56991 9.57103 4.8128 9.954 4.83876L13.075 5.05038C13.9743 5.11136 14.3403 6.23755 13.6485 6.81549L11.248 8.82118C10.9534 9.06729 10.8257 9.46029 10.9194 9.83255L11.6825 12.8662C11.9024 13.7403 10.9445 14.4364 10.181 13.9571L7.53171 12.2938C7.20661 12.0897 6.79339 12.0897 6.46829 12.2938L3.81896 13.9571C3.05555 14.4364 2.09756 13.7403 2.31747 12.8662L3.08065 9.83255C3.1743 9.46029 3.0466 9.06729 2.75204 8.82118L0.351482 6.81549C-0.340248 6.23755 0.0256703 5.11136 0.925 5.05038L4.046 4.83876C4.42897 4.8128 4.76328 4.56991 4.90632 4.2137L6.07203 1.31085Z"
        fill="#E3E3E3"
      />
    </svg>
  )
}

export default AwardEmpty
