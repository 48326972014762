import Loader from "core/components/Loader"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import React from "react"

export default function Table({
  isLoading,
  data,
  outlined,
  empty = "No data available",
  gradient = true,
  isMenuOpen,
  menuActiveRow,
}: any) {
  return (
    <div
      className={cn(
        "relative overflow-x-auto rounded-[4px]",
        outlined ? "border border-new-neutral-light" : "shadow-table"
      )}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <table className="w-full text-left text-sm text-new-neutral-dark">
          <thead className="bg-new-editor-dark-500 text-new-solid-white whitespace-nowrap">
            <tr>
              {data?.header?.map((title: any, i: any) => (
                <th
                  key={i}
                  className={cn(
                    "px-6 py-2 text-sm font-semibold leading-[17px]",
                    i > 0 && i === data?.header?.length - 1 && "text-right"
                  )}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data?.rows) &&
              data?.rows?.map((row: any, i: any) => (
                <React.Fragment key={i}>
                  <tr
                    className={cn("group relative bg-new-solid-white text-left", gradient && "even:bg-new-misc-gray")}
                  >
                    {row.map((cell: any, j: any) => (
                      <td
                        key={j}
                        className={cn(
                          "whitespace-nowrap px-6 py-4 font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark group-hover:bg-new-misc-gray",
                          j > 0 && j === row.length - 1 && "text-right"
                        )}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            {isEmpty(data?.rows) && (
              <tr className="h-[50px] w-full bg-new-misc-gray">
                <td
                  colSpan={data?.header?.length}
                  className="py-2 text-center font-medium leading-6 text-new-neutral-light"
                >
                  {empty}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}
