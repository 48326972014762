import { Auth } from "domain/model/Auth"
import {
  STR_GET_UPCOMING_CLASSES,
  API_V4_STUDENT,
  API_GET_NEXT_SCHEDULE_CLASS_TIMESTAMP,
  API_V4_GET_ALL_SSM_HISTORY,
} from "core/constants/strings"
import SessionDataSource from "data/dataSource/Student/SessionDataSource"
// import { format } from 'date-fns';
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import SSMHistoryDataSource from "data/dataSource/Student/SSMHistoryDataSource"

// export default class SessionAPIDataSourceImpl implements SessionDataSource {

//   async getNextClassTimestamp(auth: Auth): Promise<any> {
//     const response = await postAPI(
//       pn(API_V4_STUDENT, API_GET_NEXT_SCHEDULE_CLASS_TIMESTAMP),
//       {
//         id_token: auth.id_token,
//       }
//     );

//     return response;
//   }
// }
export default class SSMHistoryDataSourceImpl implements SSMHistoryDataSource {
  async getAllSSMHistorys(auth: Auth): Promise<any> {
    const response = await postAPI(API_V4_GET_ALL_SSM_HISTORY, {
      id_token: auth.id_token,
    })
    return response
  }
}
