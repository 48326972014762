import React, { useState, useEffect } from "react"
import MyBarChart from "./BarChart.jsx"
import "./Chart.css"
import Card from "../../layout/Card"

interface WeeklyReportProps {
  weekData: any
  getWeeklyReport: (batch: any, month: any, year: any) => Promise<void>
  selectedBatch: string
  selectedYear: number
  selectedMonth: number
  selectedFieldType: string
}

interface Field {
  [key: string]: {
    [key: string]: string
  }
}

const fieldData: Field = {
  "Classes Schedule": {
    "Number of class schedule": "totalClassSchedule",
    "Attendance Percentage": "classAttendancePercentage",
    "Overall Rating": "classRating",
  },
  "Mentor Sessions": {
    "Schedule Session": "scheduleMentorSession",
    "Scheduled Taken": "mentorSessionTaken",
    "Cancelled By Mentee": "cancelledSessionByMentee",
    "Cancelled By Mentor": "cancelledSessionByMentor",
    "Average Rating": "mentorSessionRating",
  },
  "Weekly Test": {
    "Scheduled Test": "scheduleTest",
    "Attendance Percentage": "contestAttendencePercentage",
    "Avg. Students score": "avgContestScore",
  },
  "Problem Solving skills": {
    "TPS less than 100": "lessThan100",
    "TPS Range 100 - 200": "inBetween100To200",
    "TPS Range 200 - 300": "inBetween200To300",
    "More than 300": "moreThan300",
  },
  "Placement Readiness": {
    "Placement ready students": "placementReadyStudent",
    "Avg. Placement readiness score": "avgPlacementReadinessScore",
  },
}

export default function WeeklyReport({
  weekData,
  getWeeklyReport,
  selectedBatch,
  selectedYear,
  selectedMonth,
  selectedFieldType,
}: WeeklyReportProps) {
  // Utility function to get the first key from an object
  const getFirstKey = (obj: { [key: string]: any }): string => {
    const keys = Object.keys(obj)
    return keys.length > 0 ? keys[0] : ""
  }

  // Determine a safe initial field type and its first key
  const initialFieldType =
    selectedFieldType && fieldData[selectedFieldType] ? selectedFieldType : Object.keys(fieldData)[0]
  const initialSelectedField = getFirstKey(fieldData[initialFieldType])

  const [selectedField, setSelectedField] = useState<string>(initialSelectedField)

  useEffect(() => {
    getWeeklyReport(selectedBatch, selectedMonth, selectedYear)
  }, [selectedBatch, selectedMonth, selectedYear])

  useEffect(() => {
    const newSelectedField = getFirstKey(fieldData[selectedFieldType] || {})
    setSelectedField(newSelectedField)
  }, [selectedFieldType])

  const handleSelectField = (text: string) => {
    setSelectedField(text)
  }

  const cardData = {
    data: [fieldData[selectedFieldType]?.[selectedField]],
    month: selectedMonth,
    weekData,
  }

  const getAllKeys = (fieldData: Field, fieldType: string): string[] => {
    return Object.keys(fieldData[fieldType] || {})
  }

  return (
    <div className="rounded-lg flex bg-[#FFF] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
      <div className="w-[50%]">
        <MyBarChart data={weekData} targetedData={fieldData[selectedFieldType][selectedField]} />
      </div>
      <Card
        field={getAllKeys(fieldData, selectedFieldType)}
        handleSelectField={handleSelectField}
        cardData={cardData}
        disabled={true}
        weekData={weekData}
      />
    </div>
  )
}
