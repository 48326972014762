import { EditCoupon } from "domain/model/RedeemCoins"
import { RedeemCoinsRepository } from "domain/repository/Admin/RedeemCoinsRepository"

export interface EditCouponUseCase {
  invoke(editCoupon: EditCoupon): Promise<any>
}

export default class EditCoins implements EditCouponUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(editCoupon: EditCoupon) {
    return this.repository.editCoupon(editCoupon)
  }
}
