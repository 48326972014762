// import { getStudentDetails } from "domain/model/StudentDashboard"
import { Auth } from "domain/model/Auth"
import { approveData } from "domain/model/ContestApprove"
import ContestApproveRepository from "domain/repository/Admin/ContestApproveRepository"

export interface ApproveToDisapproveUseCase {
  invoke(auth: Auth, data: approveData): Promise<any>
}
export class ApproveToDisapproveRequests implements ApproveToDisapproveUseCase {
  private repository: ContestApproveRepository

  constructor(repository: ContestApproveRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: approveData): Promise<any> {
    return await this.repository.approveToDisapprove(auth, data)
  }
}
