import { Batch } from "domain/model/Batch"
import { EndBatchParams } from "domain/model/EndBatch"
import { BatchRepository } from "domain/repository/Admin/BatchRepository"

export interface EndBatchUseCase {
  invoke(params: EndBatchParams): Promise<any>
}

export class EndBatch implements EndBatchUseCase {
  private repository: BatchRepository

  constructor(repository: BatchRepository) {
    this.repository = repository
  }

  async invoke(params: EndBatchParams) {
    return await this.repository.endBatch(params)
  }
}
