import CheckBox from "assets/svgs/CheckBox"
import CheckBoxCircle from "assets/svgs/CheckBoxCircle"
import { isEmpty } from "core/utils/misc"
import React from "react"

export default function DetailsSession(props: any) {
  const mentor_feedback_to_student = props.data?.mentor_feedback?.feedback
  const student_feedback_to_mentor = props?.data?.student_review?.comment
  let rating_for_mentor = props?.data?.data?.student_review?.rating?.toString()
  if (isEmpty(rating_for_mentor)) {
    rating_for_mentor = "0"
  }

  const action_items = props?.data?.mentor_feedback?.action_items
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <h3 className="">Feedback of Mentor to Student</h3>
        <div className="flex flex-col gap-y-4">
          <div className="flex gap-6">
            <p>Action Items</p>
            <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
              {action_items}
            </p>
          </div>
          <div className="flex gap-6">
            <p>Feedback</p>
            <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
              {mentor_feedback_to_student}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <h3 className="">Feedback of Student to Mentor</h3>
        <div className="flex flex-col gap-y-4">
          <div className="flex gap-6">
            <p>Feedback</p>
            <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
              {student_feedback_to_mentor}
            </p>
          </div>
          <div className="flex gap-6">
            <div className="flex gap-x-10">
              <p>Rating</p>
              <div className="flex gap-x-[24px]">
                <div className="flex ">
                  {Array(parseInt(rating_for_mentor))
                    .fill(0)
                    .map((_, i) => (
                      <CheckBox key={i} className={"w-[24px]"} />
                    ))}
                  {Array(5 - parseInt(rating_for_mentor))
                    .fill(0)
                    .map((_) => (
                      <CheckBoxCircle />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className=""></p>
      </div>
    </div>
  )
}
