import React, { useState, useEffect } from "react"
import useToast from "core/hooks/useToast"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import { STR_FAILURE } from "core/constants/strings"
import { useNavigate } from "react-router-dom"
import { AddPlacementAPIDataSourceImpl } from "data/API/Admin/AddPlacementAPIDataSourceImpl"
import { AddPlacementRepositoryImpl } from "data/repository/Admin/AddPlacementRepositoryImpl"
import { GetNewPlacement } from "domain/useCase/Admin/Placement/GetNewPlacement"
import { DeletePlacement } from "domain/useCase/Admin/Placement/DeletePlacement"

export default function PlacementViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [newPlacementList, setNewPlacementList] = useState<any>([])
  const [filteredPlacementList, setFilteredPlacementList] = useState<any>([])
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [placementId, setPlacementId] = useState<string>("")
  const [searchPlacement, setSearchPlacement] = useState<string>("")

  const GetNewPlacementUsecase = new GetNewPlacement(
    new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl())
  )
  const DeletePlacementUsecase = new DeletePlacement(
    new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl())
  )

  async function getNewPlacement() {
    const response = await GetNewPlacementUsecase.invoke(auth)
    if (response?.success) {
      setNewPlacementList(response?.data)
      setFilteredPlacementList(response?.data) // Initially set filtered list to complete list
    }
  }

  const redirectToPlacement = (id: string) => {
    navigate(`/admin/placement/${id}`)
  }

  const handleSearchPlacementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPlacement(e.target.value)
    filterPlacements(e.target.value)
  }

  const filterPlacements = (searchTerm: string) => {
    const filteredList = newPlacementList.filter((placement: any) => {
      return searchTerm === "" || placement?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
    })
    setFilteredPlacementList(filteredList)
  }

  const handleDeletePlacement = async (id: string) => {
    setPlacementId(id)
    await deletePlacement(id)
  }

  async function deletePlacement(id: string) {
    setLoading(true)
    const response = await DeletePlacementUsecase.invoke({
      id_token: auth.id_token,
      placement_id: id,
    })
    setLoading(false)

    if (response?.success) {
      const updatedList = newPlacementList.filter((item: any) => item.id !== id)
      setNewPlacementList(updatedList)
      filterPlacements(searchPlacement)
      changeToastDetails("Success", "Placement deleted successfully.")
      changeToastVisibility(true)
    } else {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to delete placement"))
      changeToastVisibility(true)
    }
  }

  return {
    toast,
    searchPlacement,
    filteredPlacementList,
    handleDeletePlacement,
    handleSearchPlacementChange,
    redirectToPlacement,
    setNewPlacementList,
    changeToastDetails,
    changeToastVisibility,
    getNewPlacement,
  }
}
