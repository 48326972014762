import { useEffect, useState } from "react"

export default function PartnerCompanyList() {
  const [companyImages, setCompanyImages] = useState<string[]>([])

  useEffect(() => {
    const fetchCompanyImages = async () => {
      const images = []
      for (let i = 1; i <= 15; i++) {
        const image = await import(`../assets/company${i}.png`)
        images.push(image.default)
      }
      setCompanyImages(images)
    }

    fetchCompanyImages()
  }, [])

  return (
    <div className="w-full bg-white px-10 py-[48px]">
      <div className="flex justify-center items-center text-[#312E81] text-4xl leading-normal text-center font-bold">
        Partnered Companies
      </div>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-5 gap-4 w-[80%]">
          {companyImages.map((image, index) => (
            <div className="flex items-center justify-center px-2 py-2">
              <img key={index + 1} src={image} alt={`Company ${index + 1}`} className="w-[100px] h-auto" />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
