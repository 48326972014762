import React from "react"
import ButtonGroup from "core/components/ButtonGroup"
import DashboardLayout from "core/layouts/DashboardLayout"
import useContestViewModel from "./ContestViewModel"
import ContestCard from "./components/ContestCard"
import Input from "core/components/new/Input"
import Loader from "core/components/Loader"
import { AddIcon } from "core/constants/svgs"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import ConfirmationPopUp from "./components/ConfirmationPopUp"
import Spinner from "core/components/Spinner"

export default function ContestView() {
  const {
    TABS,
    activeTab,
    loading,
    contests,
    searchContest,
    fetchContests,
    handleSearchContestChange,
    handleSearchContest,
    handleTabChange,
    redirectToContest,
    handleDeleteContest,
  } = useContestViewModel()

  React.useEffect(() => {
    fetchContests()
  }, [])
  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [contest_id, setContestId] = React.useState<any>("")
  return (
    <DashboardLayout>
      {loading ? (
        <div className="flex justify-center items-center" style={{ width: "100%", height: "100vh" }}>
          <Spinner small />
        </div>
      ) : (
        <>
          {isOpen && (
            <ConfirmationPopUp
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              contest_id={contest_id}
              handleDeleteContest={handleDeleteContest}
              heading="Are you sure you want to delete this contest?"
            />
          )}
          <div className="space-y-6 p-6 text-new-neutral-dark">
            <div className="space-y-1">
              <h2 className="text-new-accent">Contests</h2>
              <p className="">Manage all the active and past Contests from here</p>
            </div>
            <button
              className="inline-flex w-full items-center justify-center gap-4 rounded-sm py-12 text-new-neutral shadow-[0px_0px_7px_0px_rgba(0,0,0,0.10)]"
              onClick={() => redirectToContest("add")}
            >
              <AddIcon className="h-6 w-6" />
              <h3>Create Contest</h3>
            </button>

            <ButtonGroup
              buttons={["Live Contests", "Instant Contests", "Past Contests"]}
              active={activeTab}
              onChange={handleTabChange}
            />

            <ConditionalLoader isLoading={loading} loader={<Loader dark />}>
              <Input
                value={searchContest}
                onChange={handleSearchContestChange}
                id="contest_name"
                label="Contest Name"
                type="text"
                placeholder="Type your keyword here!"
                containerClass="max-w-[400px]"
              />
              {!isEmpty(contests[TABS[activeTab]]) &&
              !isEmpty(contests[TABS[activeTab]]?.filter(handleSearchContest())) ? (
                contests[TABS[activeTab]]?.filter(handleSearchContest())?.map((contest: any, i: number) => (
                  <ContestCard
                    key={i}
                    data={contest}
                    onView={() => redirectToContest(contest?.id + "/view")} //newly added
                    onEdit={() => redirectToContest(contest?.id)}
                    // onDelete={() => handleDeleteContest(contest?.id)}
                    onDelete={() => {
                      setIsOpen(true)
                      setContestId(contest?.id)
                    }}
                    onDuplicate={() => redirectToContest(contest?.id + "/duplicate")}
                  />
                ))
              ) : (
                <div className="flex h-[100px] items-center justify-center gap-4">
                  <h3 className="text-new-neutral-light">No Contests Found!</h3>
                </div>
              )}
            </ConditionalLoader>
          </div>
        </>
      )}
    </DashboardLayout>
  )
}
