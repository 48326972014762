import React from "react"
import Cross from "assets/svgs/Cross"
import Button from "core/components/Button"
import ButtonNew from "core/components/new/Button"
import useStudentProfileViewModel from "../StudentProfileViewModel"
import Toast from "core/components/Toast"
import StandaredSelect from "core/components/StandaredSelect"

export default function TransactionDetailUpdatePopup(props: any) {
  const { getPaymentMethods, paymentMethodsList } = useStudentProfileViewModel()

  React.useEffect(() => {
    getPaymentMethods()
  }, [])

  function handleCross() {
    props.setUpdateTransactionPopup(false)
  }
  function formatPaidAt(timestamp: any): string {
    const date = new Date(parseInt(timestamp) * 1000)
    const day = date.getDate()
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return `${day} ${monthNames[monthIndex]}, ${year}`
  }

  // Function to handle discarding changes
  function handleDiscardChanges() {
    // Reset input fields to original values
    let payment_id = ""
    if (props.instalmentPayInfo?.paymentInfo?.status === "refund") {
      payment_id = props.instalmentPayInfo?.paymentInfo?.refund_id
    } else if (props.instalmentPayInfo?.paymentInfo?.status === "paid") {
      payment_id = props.instalmentPayInfo?.paymentInfo?.payment_id
    } else {
      payment_id = props.instalmentPayInfo?.paymentInfo?.payment_link_id
    }

    props.setUpdateTransactionDetailValues({
      payment_link_id: payment_id,
      invoice_number: props.instalmentPayInfo?.paymentInfo?.invoice_number,
      status: props.instalmentPayInfo?.paymentInfo?.status,
      payment_method: props.instalmentPayInfo?.paymentInfo?.payment_method,
      paid_at: props.instalmentPayInfo?.paymentInfo?.paid_at,
    })
  }
  function formatDueDateWithOutGMT(dueTime: string): string {
    const dueDateParts = dueTime.split(" ")
    return dueDateParts.slice(1, 4).join(" ")
  }

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto">
          <div className="m-auto w-[633px] transform space-y-8 rounded border border-new-accent bg-new-solid-white  font-medium">
            <div className="flex w-[633px] justify-between">
              <div className="flex w-[162px] justify-between">
                <div className="w-all flex flex-col  items-center justify-between pl-5">
                  <div className="flex flex-col pt-[24px]">
                    <div className="w-[127px]  text-[18px] font-bold text-[#312E81]">
                      {`Instalment ${props?.instalmentPayInfo?.index}`}
                    </div>
                    <p className="text-[14px] text-[#ADADAD]">
                      Sr. No. :{" "}
                      <span className="text-[14px] font-semibold text-[#414141]">{`00${props?.instalmentPayInfo?.index}`}</span>
                    </p>
                  </div>
                </div>
                <div className="ml-[16px] h-full border-[0.063px] border-[#F5F5F5]"></div>
              </div>

              <div className="flex w-[471px] p-[24px] gap-[10px] pr-0">
                <div className="flex  flex-col">
                  <div className="font-montserrat text-base-600 flex justify-start font-semibold   text-[#414141]">
                    Transaction Detail
                  </div>
                  <div className="w-all mt-3 flex flex-col gap-[12px]">
                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Amount ( Rs. ) :</span>
                      <span className="h-[17px] w-[177px] text-left text-[14px] font-semibold text-[#6B6B6B]">{`${props.instalmentPayInfo.paymentInfo.amount}.00 /-`}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Due date :</span>
                      <span className="w-[177px] text-left text-[14px] font-semibold text-[#6B6B6B]">{`${formatDueDateWithOutGMT(
                        `${props.instalmentPayInfo.paymentInfo.due_time}`
                      )}`}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Reference No. :</span>
                      <input
                        type="text"
                        className="h-[35px] w-[177px] py-[8px] px-[16px] text-[14px] font-semibold  text-[#6B6B6B] "
                        value={props.updateTransactionDetailValues?.invoice_number}
                        // onChange={(e) =>
                        //   props.setUpdateTransactionDetailValues(
                        //     (prevState: any) => ({
                        //       ...prevState,
                        //       invoice_number: e.target.value,
                        //     })
                        //   )
                        // }
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Payment Status:</span>
                      <select
                        className="h-[35px] w-[177px] py-[8px] px-[16px] text-[14px]"
                        value={props.updateTransactionDetailValues.status}
                        onChange={(e) =>
                          props.setUpdateTransactionDetailValues((prevState: any) => ({
                            ...prevState,
                            status: e.target.value,
                          }))
                        }
                      >
                        <option className="text-[14px] text-[#00C22B]" value="paid">
                          Paid
                        </option>
                        <option className="text-[14px] text-[#DC2626]" value="refund">
                          Refund
                        </option>
                        <option className="text-[14px]" value="created">
                          Created
                        </option>
                      </select>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Payment ID. :</span>
                      <input
                        type="text"
                        className="h-[35px] w-[177px] py-[8px] px-[16px] text-[14px] font-semibold  text-[#6B6B6B] "
                        placeholder="Enter payment ID"
                        value={props.updateTransactionDetailValues?.payment_id}
                        onChange={(e) =>
                          props.setUpdateTransactionDetailValues((prevState: any) => ({
                            ...prevState,
                            payment_id: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Payment method :</span>
                      <StandaredSelect
                        value={props.updateTransactionDetailValues.payment_method}
                        className="h-[35px] w-[177px] py-[8px] px-[16px] text-[14px]"
                        onChange={(e: any) =>
                          props.setUpdateTransactionDetailValues((prevState: any) => ({
                            ...prevState,
                            payment_method: e.target.value,
                          }))
                        }
                        placeholder="Select the payment method"
                        options={paymentMethodsList}
                      />
                      {/* <select
                        value={
                          props.updateTransactionDetailValues.payment_method
                        }
                        className="h-[35px] w-[177px] py-[8px] px-[16px] text-[14px]"
                        onChange={(e) =>
                          props.setUpdateTransactionDetailValues(
                            (prevState: any) => ({
                              ...prevState,
                              payment_method: e.target.value,
                            })
                          )
                        }
                      >
                        {paymentMethodsList.map((method, index) => (
                          <option key={index} value={method}>
                            {method}
                          </option>
                        ))}
                      </select> */}
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Received On :</span>
                      <input
                        type="date"
                        value={props.payReciveDate}
                        className="h-[33px] w-[177px] py-[8px] px-[16px] text-[14px] "
                        onChange={(e) => {
                          const date = e.target.value
                          const timestamp = new Date(date).getTime() / 1000 // Convert date string to Unix timestamp
                          props.setUpdateTransactionDetailValues((prevState: any) => ({
                            ...prevState,
                            paid_at: timestamp,
                          }))
                          props.setPayReciveDate(date)
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-6 flex justify-end">
                    <ButtonNew
                      className="mr-2 h-[49px] w-[188px] rounded text-[14px]"
                      onClick={() => props.submitUpdateTransactionDetail(props.updateTransactionDetailValues)}
                    >
                      Save Changes
                    </ButtonNew>
                    <Button
                      className="h-[49px] w-[188px] text-[14px] border-red-500 bg-none text-red-500 hover:bg-red-500 hover:text-white"
                      onClick={handleDiscardChanges}
                    >
                      Discard Changes
                    </Button>
                  </div>
                </div>

                <button
                  className="text-500 h-[24px] w-[24px] text-[#312E81] hover:text-gray-600 focus:outline-none"
                  onClick={() => handleCross()}
                >
                  {/* Icon for closing */}
                  <Cross />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
