import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetFeedbackResponseSubmissionUseCase {
  invoke(auth: Auth, session_id: string, student_email: string): Promise<any>
}

export default class GetFeedbackResponseSubmission implements GetFeedbackResponseSubmissionUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string, student_email: string) {
    return await this.repository.getFeedbackResponseSubmission(auth, session_id, student_email)
  }
}
