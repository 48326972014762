export default function PlayCircle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 14.6668C4.31792 14.6668 1.33325 11.6822 1.33325 8.00016C1.33325 4.31816 4.31792 1.3335 7.99992 1.3335C11.6819 1.3335 14.6666 4.31816 14.6666 8.00016C14.6666 11.6822 11.6819 14.6668 7.99992 14.6668ZM7.99992 13.3335C10.9454 13.3335 13.3333 10.9457 13.3333 8.00016C13.3333 5.05464 10.9454 2.66683 7.99992 2.66683C5.0544 2.66683 2.66659 5.05464 2.66659 8.00016C2.66659 10.9457 5.0544 13.3335 7.99992 13.3335ZM10.3339 7.77815L7.08125 5.61015C6.9995 5.55561 6.89438 5.55045 6.80768 5.59672C6.72099 5.643 6.66677 5.73321 6.66658 5.83148V10.1688C6.66693 10.2669 6.72105 10.3568 6.80752 10.403C6.89399 10.4493 6.99886 10.4443 7.08059 10.3902L10.3339 8.22215C10.4082 8.17271 10.4528 8.08939 10.4528 8.00015C10.4528 7.91091 10.4082 7.82759 10.3339 7.77815Z"
        fill="currentColor"
      />
    </svg>
  )
}
