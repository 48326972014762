import { useAuth } from "core/context/auth"
import { StoreAPIDataSourceImpl } from "data/API/Admin/StoreAPIDataSourceImpl"
import { StoreRepositoryImpl } from "data/repository/Admin/StoreRepositoryImpl"
import { GetMentorSessionCoupon } from "domain/useCase/Admin/Store/GetMentorSessionCoupon"
import { GetOrderHistory } from "domain/useCase/Admin/Store/GetOrderHistory"
import React from "react"
import { useLocation } from "react-router-dom"

export default function TransactionViewModel() {
  const location = useLocation()
  const path = location.pathname
  const startIndex = path.indexOf("transaction-history/") + "transaction-history/".length
  const email = path.substring(startIndex)
  const GetOrderHistoryUseCase = new GetOrderHistory(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))
  const GetMentorSessionCouponUseCase = new GetMentorSessionCoupon(
    new StoreRepositoryImpl(new StoreAPIDataSourceImpl())
  )
  const { auth } = useAuth()
  const [copyColor, setCopyColor] = React.useState(false)

  const [transactionDetails, setTransactionDetails] = React.useState<any | null>(null)
  const [couponTransactionDetails, setCouponTransactionDetails] = React.useState<any | null>(null)
  const [clickedCopyIndex, setClickedCopyIndex] = React.useState(null)

  const handleCopyCoupon = (couponCode: any, index: any) => {
    navigator.clipboard.writeText(couponCode)
    setClickedCopyIndex(index)
  }
  const getTransactionDetails = async () => {
    try {
      const response = await GetOrderHistoryUseCase.invoke(auth, email)
      if (response.success) {
        const transactionDetails = response.data
        setTransactionDetails(transactionDetails)
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error)
    }
  }

  const { studentData } = location.state
  const tabs = ["Amazon Coupons", "Mentor Sessions"]
  const [activeTab, setActiveTab] = React.useState(0)
  const handleTabChange = (i: number) => {
    setActiveTab(i)
  }
  function formatDateFromTimestamp(timestamp: any) {
    const milliseconds = timestamp * 1000

    const date = new Date(milliseconds)

    const formattedDate = date.toLocaleDateString()

    return formattedDate
  }
  const getCouponTransactionDetails = async () => {
    try {
      const response = await GetMentorSessionCouponUseCase.invoke(auth, email)
      if (response.success) {
        const couponTransactionDetails = response.data
        setCouponTransactionDetails(couponTransactionDetails)
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error)
    }
  }

  const mapCoinToValue = (coins: any) => {
    switch (coins) {
      case 50:
        return 10000
      case 75:
        return 15000
      case 100:
        return 19000
      case 200:
        return 35000
      case 500:
        return 90000
      case 1000:
        return 100000
      default:
        return 0
    }
  }
  return {
    clickedCopyIndex,
    location,
    path,
    startIndex,
    email,
    GetOrderHistoryUseCase,
    auth,
    copyColor,
    transactionDetails,
    couponTransactionDetails,
    studentData,
    tabs,
    activeTab,
    handleTabChange,
    formatDateFromTimestamp,
    getCouponTransactionDetails,
    setClickedCopyIndex,
    handleCopyCoupon,
    setCopyColor,
    getTransactionDetails,
    mapCoinToValue,
  }
}
