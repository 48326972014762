import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import StandaredSelect from "core/components/StandaredSelect"
import useUpdateBatchViewModel from "./UpdateBatchViewModel"
import Input from "core/components/Input"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import { STR_TEXT_FIELD } from "core/constants/strings"
import ConfirmationPopUp from "./Component/ConfirmationPopUp"

export default function UpdateBatchView() {
  const {
    selectBatch,
    batchList,
    email,
    toast,
    loading,
    fromBatch,
    toBatch,
    batchShifting,
    isOpen,
    selectBatch2,
    emailList,
    setIsOpen,
    shiftBatch,
    handleFromBatchChange,
    handleToBatchChange,
    handleUpdateBatchBunch,
    handleBatchChange2,
    handleBatchChange,
    handleEmailChange,
    getBatches,
    handleEmailChangeBunch,
    updateStudentBatch,
    changeToastVisibility,
  } = useUpdateBatchViewModel()

  React.useEffect(() => {
    getBatches()
  }, [])

  const handleUpdateStudentBatch = (previousBatchData: boolean) => {
    updateStudentBatch(previousBatchData)
  }

  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUpdateStudentBatch={handleUpdateStudentBatch}
          heading="Would you like to keep the previous batches' record intact?"
        />
      )}
      <div className="p-6">
        <div className="w-full">
          <h1 className="text-2xl font-bold leading-[29px] text-new-accent">Update Batch</h1>
          <p className="font-medium">Update Batch for students</p>
        </div>
        <div className="flex justify-center py-12">
          <div className="round-2 flex w-full max-w-xl flex-col items-center p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
            <StandaredSelect
              required
              className="mb-4"
              options={batchList}
              placeholder="Select Batch"
              onChange={handleBatchChange}
              value={selectBatch}
            />
            <Input type={STR_TEXT_FIELD} placeholder="Email" onChange={handleEmailChange} value={email} className="" />
            <Button
              className="mt-4"
              onClick={() => (selectBatch === "ALL" ? updateStudentBatch(false) : setIsOpen(!isOpen))}
            >
              {loading ? "Updating..." : "Update"}
            </Button>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-2xl font-bold leading-[29px] text-new-accent">Shift Batch</h1>
          <p className="font-medium">Shift whole batch of students</p>
        </div>
        <div className="flex justify-center py-12">
          <div className="round-2 flex w-full max-w-xl flex-col items-center space-y-4 p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
            <div className="flex w-full flex-col gap-1">
              <h4>From</h4>
              <StandaredSelect
                required
                options={batchList}
                placeholder="Select Batch"
                onChange={handleFromBatchChange}
                value={fromBatch}
              />
            </div>
            <div className="flex w-full flex-col gap-1">
              <h4>To</h4>
              <StandaredSelect
                required
                options={batchList}
                placeholder="Select Batch"
                onChange={handleToBatchChange}
                value={toBatch}
              />
            </div>
            <Button loading={batchShifting} onClick={shiftBatch}>
              Update
            </Button>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-2xl font-bold leading-[29px] text-new-accent">Update Batch</h1>
          <p className="font-medium">Update Batch for a Group of Students</p>
        </div>
        <div className="flex justify-center py-12">
          <div className="round-2 flex w-full max-w-xl flex-col items-center p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
            <StandaredSelect
              required
              className="mb-4"
              options={batchList}
              placeholder="Select Batch"
              onChange={handleBatchChange2}
              value={selectBatch2}
            />
            <Input
              type="textarea"
              placeholder="Email's Of Students"
              onChange={handleEmailChangeBunch}
              value={emailList}
              className=""
            />
            <Button className="mt-4" onClick={() => handleUpdateBatchBunch()}>
              {loading ? "Updating..." : "Update"}
            </Button>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
