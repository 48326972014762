export default function ArrowGoBack({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.828 7.00078L8.364 9.53678L6.95 10.9508L2 6.00078L6.95 1.05078L8.364 2.46478L5.828 5.00078H13C17.4183 5.00078 21 8.5825 21 13.0008C21 17.4191 17.4183 21.0008 13 21.0008H4V19.0008H13C16.3137 19.0008 19 16.3145 19 13.0008C19 9.68707 16.3137 7.00078 13 7.00078H5.828Z"
        fill="currentColor"
      />
    </svg>
  )
}
