import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Student/ProfileRepository"

export interface UpdateProfileUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UpdateProfile implements UpdateProfileUseCase {
  private repository: ProfileRepository

  constructor(repository: ProfileRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.updateProfile(auth, data)
  }
}
