import { CheckBlankIcon, CheckBoxIcon, CrossIcon } from "core/constants/svgs"
import OrderedItemCard from "./OrderedItemCard"
import Loader from "core/components/Loader"

const statuses = ["Order Placed", "Dispatched ", "Delivered"]

export default function OrderStatusModal({ status, item, show, onHide }: any) {
  const completedUpto = +status

  return show ? (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onHide} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
        <div className="no-scrollbar h-screen overflow-y-auto py-5">
          <div className="flex w-[700px] transform flex-col bg-new-solid-white font-medium text-new-neutral">
            <div className="flex items-start justify-between p-8">
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold leading-[22px] text-new-accent">Order Status</h3>
                <p className="">Your current order status as per 3rd party delivery system.</p>
              </div>
              <button type="button" onClick={onHide} className="flex items-center gap-1.5">
                <CrossIcon className="h-6 w-6" pathClassName="fill-new-neutral" />
                <span className="text-new-neutral">Close</span>
              </button>
            </div>
            <OrderedItemCard item={item} />
            {status ? (
              <div className="p-8">
                <div className="flex flex-col items-start gap-3 rounded border border-new-neutral p-6">
                  {statuses.map((status, index) => (
                    <div key={index} className="flex items-center justify-center gap-2">
                      {index < completedUpto ? (
                        <CheckBoxIcon className="h-4 w-4 text-[#22C55E]" />
                      ) : (
                        <CheckBlankIcon className="h-4 w-4" />
                      )}
                      <span className="text-sm leading-[17px]">{status}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
