import React from "react"

const SearchItemLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 16" fill="none">
      <rect y="0.5" width="16" height="16" rx="2" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 14.4953V5.83325L9.998 1.83325H2.668C2.29933 1.83325 2 2.13659 2 2.49459V14.5053C2.00037 14.8706 2.29665 15.1666 2.662 15.1666H13.338C13.5148 15.1654 13.6839 15.0939 13.808 14.968C13.9322 14.8421 14.0012 14.6721 14 14.4953ZM9.33333 6.49992H12.6667V13.8333H3.33333V3.16659H9.33333V6.49992Z"
        fill="#D3D3D3"
      />
    </svg>
  )
}

export default SearchItemLogo
