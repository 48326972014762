import Button from "core/components/new/Button"
// import DashboardLayout from "core/layouts/DashboardLayout"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import useStoreViewModel from "./StoreViewModel"
import Toast from "core/components/Toast"
import { CoinIcon, CrossIcon } from "core/constants/svgs"
import StandaredSelect from "core/components/StandaredSelect"
import amazon_vocher from "assets/images/amazon_voucher.png"
import { Tooltip } from "@mui/material"
import extra_mentor_session from "assets/images/extra_mentor_session.png"
import ConfirmCouponModal from "./components/ConfirmCouponModal"
import ConfirmMentorSessionModal from "./components/ConfirmMentorSessionModal"

export default function StoreView() {
  const {
    toast,
    student,
    allCoupons,
    amount,
    redeemModal,
    amountToCoinsMap,
    mentorSessionRedeemModal,
    redeemCouponLoader,
    mentorCouponLoader,
    RedeemMentorCoupon,
    RedeemCoupon,
    handleMentorSessionModal,
    handleRedeemCoinsModal,
    handleAmountChange,
    handleRedirectToHistory,
    changeToastVisibility,
  } = useStoreViewModel()

  return (
    <DashboardLayoutv2>
      <div className="px-[24px] pt-[24px]">
        <div className="space-y-6 text-new-neutral-dark">
          <div className="flex justify-between gap-8">
            <div className="space-y-1">
              <h2 className="text-new-accent">Redeem Coins</h2>
              <p>Redeem coins for Amazon Gift Cards or an additional Mentor Session.</p>
            </div>
            <Button outlined onClick={handleRedirectToHistory} className="h-fit w-fit">
              Order History
            </Button>
          </div>
          <div className="">
            <div className="flex gap-x-[32px]">
              <div className="flex h-[340px] w-[413px] flex-col overflow-hidden rounded-md  border-[1px] border-[#DEDEDE] shadow-[-1px_1px_5px_rgba(34,41,48,0.15)]">
                <div className="h-[187px] w-[413px]">
                  <img className="h-full w-full" src={amazon_vocher} alt="" />
                </div>
                <div className="flex flex-col gap-y-[15px] p-[24px]">
                  <div className="flex items-center justify-between">
                    <div className="flex h-[56px] flex-col">
                      <h4 className="text-lg line-clamp-2">Amazon</h4>
                      <h4 className="text-lg line-clamp-2">Gift Card</h4>
                    </div>
                    <div className="flex h-[56px] items-center gap-2">
                      <span>
                        <CoinIcon className="h-6 w-6" />
                      </span>
                      <h3 className="text-[#FBBF24]">{amountToCoinsMap[amount]}</h3>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="">
                      <StandaredSelect
                        required
                        name="Amount"
                        id="Amount"
                        options={allCoupons}
                        placeholder="Select Amount"
                        onChange={handleAmountChange}
                        value={amount}
                        className="h-[33px] w-[189px]"
                      />
                    </div>
                    <div className="">
                      {amount == "" && (
                        <Tooltip title="Select Amount">
                          <span className="">
                            {" "}
                            <Button
                              outlined
                              disabled={amount == "" ? true : false}
                              onClick={handleRedeemCoinsModal}
                              className="h-[33px] w-[125px]"
                            >
                              Redeem
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      {amount != "" && (
                        <Button
                          outlined
                          disabled={amount == "" ? true : false}
                          onClick={handleRedeemCoinsModal}
                          className="h-[33px] w-[125px]"
                        >
                          Redeem
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-[340px] w-[413px] flex-col overflow-hidden  rounded-md border-[1px]  border-[#DEDEDE] shadow-[-1px_1px_5px_rgba(34,41,48,0.15)]">
                <div className="h-[187px] w-[413px]">
                  <img className="h-full w-full" src={extra_mentor_session} alt="" />
                </div>
                <div className="flex flex-col gap-y-[15px] p-[24px]">
                  <div className="flex items-center justify-between">
                    <div className="flex h-[56px] flex-col">
                      <h4 className="text-lg line-clamp-2">Additional Mentor</h4>
                      <h4 className="text-lg line-clamp-2">Session</h4>
                    </div>
                    <div className="flex items-center gap-2">
                      <span>
                        <CoinIcon className="h-6 w-6" />
                      </span>
                      <h3 className="text-[#FBBF24]">100000</h3>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div className="">
                      <div className="">
                        {student?.points < 100000 && (
                          <Tooltip title="You do not have sufficient coins">
                            <span className="">
                              <Button
                                outlined
                                disabled={student?.points < 100000 ? true : false}
                                onClick={handleMentorSessionModal}
                                className="h-[33px]"
                              >
                                Book Mentor Session
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                        {student?.points >= 100000 && (
                          <Button outlined onClick={handleMentorSessionModal} className="h-[33px]">
                            Book Mentor Session
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {redeemModal && (
                <ConfirmCouponModal
                  handleRedeemCoinsModal={handleRedeemCoinsModal}
                  amount={amount}
                  coins={amountToCoinsMap[amount]}
                  RedeemCoupon={RedeemCoupon}
                  loader={redeemCouponLoader}
                />
              )}
              {mentorSessionRedeemModal && (
                <ConfirmMentorSessionModal
                  handleMentorSessionModal={handleMentorSessionModal}
                  RedeemMentorCoupon={RedeemMentorCoupon}
                  loader={mentorCouponLoader}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
