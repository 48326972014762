import { Auth } from "domain/model/Auth"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface FinalizeMentorSessionInvoicingUseCase {
  invoke(auth: Auth, mentor_id: string, year_month: string): Promise<any>
}

export default class FinalizeMentorSessionInvoicing implements FinalizeMentorSessionInvoicingUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, year_month: string) {
    return await this.repository.finalizeMentorSessionInvoicing(auth, mentor_id, year_month)
  }
}
