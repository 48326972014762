import { CreateModuleClass } from "domain/model/ModuleClass"
import { ModuleClassRepository } from "domain/repository/Admin/ModuleClass"

export interface CreateModuleClassUseCase {
  invoke(createModuleClass: CreateModuleClass): Promise<any>
}

export class CreateModuleClasses implements CreateModuleClassUseCase {
  private repository: ModuleClassRepository

  constructor(repository: ModuleClassRepository) {
    this.repository = repository
  }

  async invoke(createModuleClass: CreateModuleClass) {
    return await this.repository.createModuleClass(createModuleClass)
  }
}
