import { saveResponse } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface UpdateStudentProfileUseCase {
  invoke(details: saveResponse): Promise<any>
}

export default class UpdateStudentProfile implements UpdateStudentProfileUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: saveResponse): Promise<any> {
    return await this.repository.updateStudentProfile(details)
  }
}
