import { postAPI } from "core/utils/axios"
import { StoreDataSource } from "data/dataSource/Admin/StoreDataSource"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import {
  API_ADD_STORE_ITEM,
  API_DELETE_STORE_ITEM,
  API_GET_STORE_ITEMS,
  API_GET_STORE_ORDERS,
  API_GET_STORE_ORDER_STATUS,
  API_UPDATE_STORE_ITEM,
  API_UPDATE_STORE_ORDER_STATUS,
  API_V3_ADMIN,
  API_V4_ADMIN,
  API_V4_GET_NEXT_30_STUDENTS_BY_COINS,
  API_V4_GET_ORDER_HISTORY,
  API_v4_GET_TOP_100_STUDENTS_BY_COINS,
  API_V4_GET_MENTOR_SESSION_COUPON,
} from "core/constants/strings"
import { getStudentDetails, loadNextData } from "domain/model/StudentDashboard"

export class StoreAPIDataSourceImpl implements StoreDataSource {
  async addStoreItem(auth: Auth, item: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_ADD_STORE_ITEM), {
        id_token: auth.id_token,
        ...item,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateStoreItem(auth: Auth, item: any, item_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_STORE_ITEM), {
        id_token: auth.id_token,
        ...item,
        item_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteStoreItem(auth: Auth, item_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_STORE_ITEM), {
        id_token: auth.id_token,
        item_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStoreItems(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_STORE_ITEMS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStoreOrders(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_STORE_ORDERS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStoreOrderStatus(auth: Auth, order_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_STORE_ORDER_STATUS), {
        id_token: auth.id_token,
        order_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateStoreOrderStatus(auth: Auth, order_id: any, status: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_STORE_ORDER_STATUS), {
        id_token: auth.id_token,
        order_id,
        status,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getTop100StudentsByCoins(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_v4_GET_TOP_100_STUDENTS_BY_COINS), {
        id_token: auth.id_token,
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getNext30StudentsByCoins(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_NEXT_30_STUDENTS_BY_COINS), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getOrderHistory(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ORDER_HISTORY), {
        id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getMentorSessionCoupon(auth: Auth, data: any): Promise<any> {
    const id_token = auth?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_MENTOR_SESSION_COUPON), {
        id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
