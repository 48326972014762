import Button from "core/components/Button"
import Input from "core/components/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useHomeAssessmentViewModel from "./ViewModel"
import Toast from "core/components/Toast"
import { STR_SUBMIT } from "core/constants/strings"
import { StarIcon } from "core/constants/svgs"

export default function HomeAssessmentView() {
  const {
    toast,
    questions,
    languages,
    assessmentData,
    handleRating,
    handlePreferedLanguage,
    handleNumberOfCodingProblems,
    handleQuestionStatus,
    handleSubmit,
    changeToastVisibility,
  } = useHomeAssessmentViewModel()

  return (
    <DashboardLayout>
      <div className="p-6">
        <div className="rounded-lg shadow">
          <div className="w-full p-8">
            <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">User Survey</h1>
            <p className="font-medium">Please fill this small survey in order to proceed with your contest test.</p>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col pb-8 font-medium text-new-neutral-dark">
            <div className="flex w-full items-center justify-between px-8">
              <label htmlFor="pref_language" className="after-star">
                Preferred coding language
              </label>
              <select
                id="pref_language"
                className="h-fit rounded border border-new-accent bg-gray-50 px-4 py-2 font-medium leading-[19px] text-new-accent"
                onChange={(e) => handlePreferedLanguage(e.target.value)}
              >
                {languages.map((language: any, index: number) => (
                  <option key={index} value={language.value}>
                    {language.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-8 px-8 py-6">
              {questions.map((question: any, index: number) => (
                <div key={index} className="inline-flex items-center justify-between gap-10">
                  <span className="after-star">{question.question}</span>
                  {question.type === "rating" ? (
                    <div className="flex items-center gap-5">
                      <p>Rate</p>
                      <div className="flex gap-4">
                        {Array(5)
                          .fill(0)
                          .map((_, i) => (
                            <button type="button" key={i} onClick={() => handleRating(i + 1)}>
                              <StarIcon
                                className="h-6 w-6"
                                pathClassName={
                                  assessmentData.self_rating !== 0 && assessmentData.self_rating >= i + 1
                                    ? "fill-[#FBBF24]"
                                    : "fill-border"
                                }
                              />
                            </button>
                          ))}
                      </div>
                    </div>
                  ) : question.type === "radio" ? (
                    <div key={question.name} className="flex items-center gap-4">
                      {question.options.map((option: any, index: number) => (
                        <div className="flex items-center" key={index}>
                          <input
                            id={question.name + option.value}
                            name={question.name}
                            type="radio"
                            value={option.value}
                            onChange={(e) => handleQuestionStatus(question.name, e.target.value)}
                            className="h-4 w-4 cursor-pointer border-gray-300 text-new-accent"
                          />
                          <label
                            htmlFor={question.name + option.value}
                            className="ml-3 block text-sm font-medium leading-[19px]"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Input
                      className="w-fit font-normal"
                      type="number"
                      placeholder="Type your answer here!"
                      value={assessmentData.num_coding_problems}
                      onChange={(e) => handleNumberOfCodingProblems(e.target.value)}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="mt-8 flex justify-end px-8">
              <Button className="w-1/2">{STR_SUBMIT}</Button>
            </div>
          </form>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
