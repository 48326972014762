export default function Discount() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5 0.249999C22.425 1.325 17.1625 5.25 14.3625 10.8C13.5375 12.4125 13.1 13.625 12.675 15.45C12.3375 16.85 12.325 17.1 12.325 19.75C12.325 22.2125 12.35 22.7125 12.6 23.75C13.85 29.1 16.6375 33.25 20.9375 36.1375C23.525 37.8875 26.325 38.95 29.5375 39.375C31.1375 39.5875 31.8125 39.55 32.1874 39.2125C32.8374 38.65 32.5374 37.45 31.725 37.325C31.5 37.2875 30.725 37.2 30.0125 37.125C22.0125 36.25 15.775 30.1375 14.6125 22.0125C14.425 20.6875 14.5375 17.525 14.8125 16.25C16.375 9.1 22.0125 3.6625 29.1125 2.45C30.65 2.1875 33.3499 2.1875 34.8874 2.45C42.0374 3.6625 47.6624 9.1375 49.2124 16.375C49.5374 17.8875 49.5249 21.625 49.1999 23.125C48.9124 24.45 48.2374 26.4125 47.6874 27.4875C46.0624 30.7125 43.1999 33.6375 40.1374 35.2125C39.0249 35.8 38.5249 36 36.9624 36.5625C35.9499 36.925 35.9874 36.5875 36.0249 44.075L36.0624 50.65L36.4124 50.95C36.7124 51.2125 36.8874 51.25 37.6874 51.25C38.6124 51.25 38.9999 51.4125 38.9999 51.8C38.9999 52.0125 32.4749 61.5125 32.2249 61.675C32.0624 61.775 31.9375 61.775 31.7875 61.675C31.525 61.5125 25 52.0125 25 51.8C25 51.4125 25.3875 51.25 26.3125 51.25C27.1125 51.25 27.2875 51.2125 27.5875 50.95L27.9375 50.65V46.8125V42.975L27.5875 42.675C27.1125 42.275 26.4625 42.275 26.0625 42.6875L25.75 42.9875V45.975V48.9625L25.15 49.05C24.8125 49.0875 24.2875 49.2875 23.975 49.4875C22.95 50.1375 22.4625 51.6875 22.925 52.8125C23.15 53.35 29.4 62.5375 29.925 63.1C30.5125 63.7375 31.125 64 31.9999 64C32.8749 64 33.4874 63.7375 34.0749 63.1C34.5999 62.5375 40.8374 53.3625 41.0624 52.8125C41.5374 51.6875 41.0624 50.1375 40.0249 49.4875C39.7124 49.2875 39.1874 49.0875 38.8499 49.05L38.2499 48.9625V43.75V38.5375L39.0374 38.25C44.3499 36.325 49.0249 31.45 50.8124 25.95C51.5249 23.7125 51.6749 22.6625 51.6749 19.6875C51.6749 17.1125 51.6624 16.85 51.3249 15.45C50.8999 13.625 50.4624 12.4125 49.6374 10.8C46.8124 5.2 41.5374 1.2875 35.3999 0.237499C33.4999 -0.075001 30.3625 -0.075001 28.5 0.249999Z"
        fill="#FBBF24"
      />
      <path
        d="M30.2501 5.63749C28.1376 5.88749 25.6626 6.86249 23.8126 8.13749C22.7501 8.87499 21.0251 10.6125 20.2876 11.6875C17.0376 16.4875 17.0001 22.725 20.1876 27.625C21.0376 28.9375 23.0626 30.9 24.4251 31.7375C25.6376 32.4875 27.0876 33.1125 28.5876 33.525C29.4251 33.7625 29.8876 33.8 32.0001 33.8C34.1001 33.8 34.5626 33.7625 35.3751 33.5375C37.8126 32.8625 39.8626 31.7625 41.5126 30.2625C42.6126 29.2625 43.7251 27.9 43.8251 27.425C43.9251 26.8875 43.6876 26.3875 43.2251 26.1875C42.5376 25.9125 42.1751 26.075 41.4501 26.9875C39.7501 29.1125 37.5501 30.575 34.9751 31.275C34.1001 31.525 33.6251 31.5625 31.9376 31.5625C30.2251 31.5625 29.8001 31.525 28.9501 31.275C24.8001 30.075 21.6876 26.975 20.5001 22.8625C20.1626 21.7 20.0126 18.8875 20.2376 17.7125C20.5501 16.1125 21.2876 14.3 22.1626 13C22.7251 12.175 24.2001 10.675 25.0751 10.05C26.0251 9.37499 27.9251 8.46249 28.9376 8.19999C31.7376 7.48749 34.3626 7.72499 36.9876 8.94999C38.4501 9.63749 39.4501 10.35 40.5751 11.5125C41.5751 12.5625 42.0376 13.2125 42.6251 14.375C43.5626 16.225 43.8751 17.65 43.8751 19.9875C43.8751 21.525 43.9001 21.75 44.1251 22.025C44.6001 22.6375 45.6001 22.525 45.9501 21.8375C46.2501 21.275 46.2126 18.3125 45.9001 16.8125C45.4751 14.825 44.4626 12.6375 43.2501 11.05C42.5501 10.1625 40.9626 8.64999 40.0501 8.03749C38.5001 6.98749 36.6501 6.19999 34.7876 5.81249C33.6126 5.57499 31.4376 5.48749 30.2501 5.63749Z"
        fill="#FBBF24"
      />
      <path
        d="M31.1876 10.5625C30.9126 10.825 30.8751 10.975 30.8751 11.7875V12.725L30.4751 12.8C29.6876 12.95 28.5001 13.6125 27.8751 14.25C26.5126 15.65 26.3626 17.9375 27.5501 19.325C28.3126 20.2125 29.2001 20.5875 32.2876 21.3125C33.8751 21.675 34.5501 22 34.8126 22.5C35.3376 23.5 34.6751 24.5625 33.2126 25.05C31.9126 25.4875 30.5126 25.2 29.5251 24.3125C29.0126 23.85 28.8126 23.75 28.4126 23.75C27.5876 23.75 27.0876 24.5 27.4126 25.275C27.7501 26.075 29.2626 27.1 30.4126 27.3125L30.8751 27.4V28.3375C30.8751 29.15 30.9126 29.3 31.1876 29.5625C31.4126 29.8 31.6251 29.875 32.0001 29.875C32.3751 29.875 32.5876 29.8 32.8126 29.5625C33.0876 29.3 33.1251 29.15 33.1251 28.3375V27.4L33.5876 27.3125C34.8501 27.075 36.4001 25.9 36.9126 24.775C37.3251 23.875 37.3001 22.2875 36.8501 21.425C36.2126 20.2 35.2501 19.6625 32.6626 19.0625C31.6626 18.825 30.6376 18.575 30.3876 18.4875C28.9001 18.0125 28.5501 16.7625 29.6126 15.75C30.9001 14.525 33.4001 14.65 34.6751 15.9875C34.9751 16.3 35.1501 16.375 35.5626 16.375C36.2626 16.375 36.6251 16 36.6251 15.275C36.6251 14.8125 36.5501 14.675 36.0626 14.175C35.4876 13.6 34.2626 12.9375 33.5376 12.8L33.1251 12.725V11.7875C33.1251 10.975 33.0876 10.825 32.8126 10.5625C32.3876 10.125 31.6126 10.125 31.1876 10.5625Z"
        fill="#FBBF24"
      />
      <path
        d="M7.78753 26.925L7.43753 27.225L7.40003 35.7875L7.36253 44.35L7.01253 44.5C6.53753 44.6875 5.92503 45.3375 5.70003 45.9C5.45003 46.4875 5.45003 47.375 5.67503 47.9375C5.88753 48.425 9.98753 54.5 10.4875 55.05C11.2625 55.9 12.6 56.1125 13.7125 55.5625C14.4 55.2125 14.5375 55.0375 18.0125 49.9C19.3 48 19.3 48 19.35 47.125C19.3875 46.425 19.35 46.15 19.1375 45.7C18.8625 45.1375 18.2875 44.6125 17.7875 44.45L17.5 44.3625V40.925V37.475L17.1375 37.1125C16.75 36.725 16.3 36.65 15.8375 36.9C15.2625 37.2 15.2625 37.225 15.25 41.6V45.775L15.6125 46.1375C15.8625 46.375 16.1125 46.5 16.35 46.5C16.9 46.5 17.125 46.6125 17.125 46.9C17.125 47.2125 12.875 53.4625 12.6 53.5625C12.475 53.6125 12.325 53.6125 12.2625 53.5625C12.125 53.4875 7.88753 47.3 7.78753 47.05C7.68753 46.775 7.98753 46.5 8.40003 46.5C9.00003 46.5 9.47503 46.175 9.62503 45.65C9.72503 45.325 9.75003 42.4375 9.72503 36.2125L9.68753 27.225L9.33753 26.925C8.87503 26.525 8.25003 26.525 7.78753 26.925Z"
        fill="#FBBF24"
      />
      <path
        d="M54.6625 26.925L54.3125 27.225L54.275 36.2125C54.25 42.4375 54.275 45.325 54.375 45.65C54.525 46.175 55 46.5 55.6 46.5C56.0125 46.5 56.3125 46.775 56.2125 47.05C56.1125 47.3 51.875 53.4875 51.7375 53.5625C51.675 53.6125 51.525 53.6125 51.4 53.5625C51.125 53.4625 46.875 47.2125 46.875 46.9C46.875 46.6125 47.1 46.5 47.65 46.5C47.8875 46.5 48.1375 46.375 48.3875 46.1375L48.75 45.775V41.6C48.7375 37.225 48.7375 37.2 48.1625 36.9C47.7 36.65 47.25 36.725 46.8625 37.1125L46.5 37.475V40.9L46.4875 44.3125L45.85 44.6875C44.9875 45.1875 44.625 45.8625 44.625 46.9375C44.625 47.925 44.65 47.975 47.4125 51.9875C49.5125 55.05 49.675 55.25 50.275 55.55C51.4 56.1125 52.7375 55.9 53.5125 55.05C54.0125 54.5 58.1125 48.425 58.325 47.9375C58.55 47.375 58.55 46.4875 58.3 45.9C58.075 45.3375 57.4625 44.6875 56.9875 44.5L56.6375 44.35L56.6 35.7875L56.5625 27.225L56.2125 26.925C55.75 26.525 55.125 26.525 54.6625 26.925Z"
        fill="#FBBF24"
      />
    </svg>
  )
}
