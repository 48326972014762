import React, { useState } from "react"
import Feedback1 from "assets/svgs/feedback/Feedback1"
import Feedback2 from "assets/svgs/feedback/Feedback2"
import Feedback3 from "assets/svgs/feedback/Feedback3"
import Feedback4 from "assets/svgs/feedback/Feedback4"
import Feedback5 from "assets/svgs/feedback/Feedback5"
import Feedback6 from "assets/svgs/feedback/Feedback6"
import Feedback7 from "assets/svgs/feedback/Feedback7"
import Feedback8 from "assets/svgs/feedback/Feedback8"
import Feedback9 from "assets/svgs/feedback/Feedback9"
import Feedback10 from "assets/svgs/feedback/Feedback10"

interface FeedbackCardProps {
  rating: number
  response1: string
  response2: string
  name: string
  email: string
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ rating, response1, response2, name, email }) => {
  const [activeTab, setActiveTab] = useState("tab1")

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  const colorMap = [
    "#E02626",
    "#E05326",
    "#E09626",
    "#E09626",
    "#E0CE26",
    "#DCE026",
    "#B0E026",
    "#8EE026",
    "#62E026",
    "#0DD746",
  ]

  const emojis = [
    <Feedback1
      isSelected={rating === 1}
      selectedColor={colorMap[0]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback2
      isSelected={rating === 2}
      selectedColor={colorMap[1]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback3
      isSelected={rating === 3}
      selectedColor={colorMap[2]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback4
      isSelected={rating === 4}
      selectedColor={colorMap[3]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback5
      isSelected={rating === 5}
      selectedColor={colorMap[4]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback6
      isSelected={rating === 6}
      selectedColor={colorMap[5]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback7
      isSelected={rating === 7}
      selectedColor={colorMap[6]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback8
      isSelected={rating === 8}
      selectedColor={colorMap[7]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback9
      isSelected={rating === 9}
      selectedColor={colorMap[8]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
    <Feedback10
      isSelected={rating === 10}
      selectedColor={colorMap[9]}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
    />,
  ]
  function handleHover() {}
  function handleLeave() {}
  function handleClick() {}

  return (
    <div className="bg-white shadow-md rounded-lg p-[24px] h-[325px] w-[760px]">
      <h3
        className="text-2xl text-gray-600 leading-normal"
        style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "600" }}
      >
        Monthly Feedback
      </h3>
      <p className="text-sm text-gray-600 pb-[24px]">User's Experience</p>
      <div className="flex flex-col">
        <div className="flex bg-gray-200 w-[219.5px] pt-[4px] pb-[4px] pl-[6px] pr-[6px] h-[43px] mb-[20px] rounded-[4px]">
          <button
            className={`rounded-[4px] mr-[1px] ${activeTab === "tab2" ? "text-gray-500" : "bg-white text-[#333]"} hover:bg-white duration-1000 hover:text-[#333]`}
            onClick={() => handleTabClick("tab1")}
          >
            <div className="pt-[9px] pb-[9px] pr-[16px] pl-[16px] rounded-[2px] text-[14px] w-[103px] h-[35px]">
              NPS Score
            </div>
          </button>
          <button
            className={`rounded-[4px] ml-[1px] ${activeTab === "tab1" ? "text-gray-500" : "bg-white text-[#333]"} hover:bg-white duration-1000 hover:text-[#333]`}
            onClick={() => handleTabClick("tab2")}
          >
            <div className="pt-[9px] pb-[9px] pr-[16px] pl-[16px] text-[14px] w-[103px] h-[35px]">Feedback</div>
          </button>
        </div>

        <div className="rounded-lg" style={{ minHeight: "200px" }}>
          {activeTab === "tab1" ? (
            <>
              <p className="text-[15px] text-gray-600 mb-[16px]">
                How likely is it that you will recommend Bosscoder Academy to your friends?{" "}
                <sup className="text-red-600">*</sup>
              </p>
              <div className="flex items-center">
                <div className="flex items-center space-x-2 w-[712px]">
                  {emojis.map((emoji, index) => (
                    <div key={index} className={`rounded`}>
                      {emoji}
                      <span
                        className="ml-5 text-gray-400 font-semibold"
                        style={{ color: rating === index + 1 ? colorMap[index] : "gray" }}
                      >
                        {index < 9 ? `0${index + 1}` : index + 1}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between">
                <div className="">
                  <p className="h-[17px] w-[260px] mb-[4px]">
                    What do you like about the program?<sup className="text-red-600">*</sup>
                  </p>
                  <textarea
                    value={response1}
                    className="rounded h-[125px] w-[342px] bg-[#F9F9F9] border-none focus:outline-none p-[10px] resize-none overflow-auto"
                    readOnly
                  />
                </div>
                <div className="">
                  <p className="h-[17px] w-[171px] mb-[4px]">
                    What can be improved?<sup className="text-red-600">*</sup>
                  </p>
                  <textarea
                    value={response2}
                    className="rounded h-[125px] w-[342px] bg-[#F9F9F9] border-none focus:outline-none p-[10px] resize-none overflow-auto"
                    readOnly
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedbackCard
