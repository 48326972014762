import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface togglePermanentDisableStudent {
  invoke(auth: Auth, id: any): Promise<any>
}

export class TogglePermanentDisableStudent implements togglePermanentDisableStudent {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, id: any): Promise<any> {
    return await this.repository.togglePermanentDisableStudent(auth, id)
  }
}
