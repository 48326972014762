import BatchDataSource from "data/dataSource/Admin/BatchDataSource"
import { Auth } from "domain/model/Auth"
import { Batch } from "domain/model/Batch"
import { BatchRepository } from "domain/repository/Admin/BatchRepository"
import { EndBatchParams } from "domain/model/EndBatch"
export class BatchRepositoryImpl implements BatchRepository {
  private Datasource: BatchDataSource

  constructor(Datasource: BatchDataSource) {
    this.Datasource = Datasource
  }

  async createBatch(createBatch: Batch): Promise<any> {
    return await this.Datasource.createBatch(createBatch)
  }

  async getBatchDetails(auth: Auth, batch_id: string): Promise<any> {
    return await this.Datasource.getBatchDetails(auth, batch_id)
  }

  async updateBatchDetails(updateBatch: Batch): Promise<any> {
    return await this.Datasource.updateBatchDetails(updateBatch)
  }
  async endBatch(params: EndBatchParams): Promise<any> {
    return await this.Datasource.endBatch(params)
  }
  async getAllActiveBatches(auth: Auth): Promise<any> {
    return await this.Datasource.getAllActiveBatches(auth)
  }
  async getAllMentorshipTemplates(auth: Auth): Promise<any> {
    return await this.Datasource.getAllMentorshipTemplates(auth)
  }
}
