export default function ViewPlacementPopUp({
  open,
  bg,
  yoe,
  student_pic,
  name,
  position,
  company_logo,
  testimonial,
  onClose,
}: any) {
  if (!open) return null

  return (
    <div
      className="fixed mt-[-24px] inset-0 z-20 transition-all ease-linear duration-500"
      style={{ background: "rgba(0 0 0 / .5)", marginTop: "0" }}
      onClick={() => onClose()}
    >
      <div className="flex min-h-screen items-center justify-center">
        <div className="flex shrink-0 flex-col items-center gap-2 w-[336px] h-[454px] relative bg-white rounded-md border border-zinc-400 overflow-hidden mr-[16px] my-[6px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="336"
            height="74"
            viewBox="0 0 336 74"
            fill={bg}
            className="rounded relative"
          >
            <path d="M0 0H336L47.8481 37L0 74V0Z" />
          </svg>
          <div className="absolute w-[64px] h-[64px] left-[137px] top-[32px] justify-center items-center inline-flex">
            <img className="object-fit rounded-full" src={student_pic} alt="Student" />
          </div>
          <div className="absolute left-[286px] top-[24px] text-neutral-700 font-Montserrat font-bold text-right flex flex-col">
            <span className="text-lg ">{yoe}+ </span>
            <span className="text-xs">YOE</span>
          </div>
          <div className="absolute left-[31px] top-[120px] w-[273px] h-12 text-center text-neutral-700 font-Montserrat">
            <div className="top-0 font-size:18px line-height:normal font-semibold ">{name}</div>
            <div className="top-[26px]  font-size:14px line-height:22.4px font-normal leading-snug">{position}</div>
          </div>
          <div className="absolute left-[40px] top-[192px] w-[257px] h-[71px] justify-center items-center inline-flex ">
            <img src={company_logo} alt="Company Logo" />
          </div>
          <div className="absolute left-[24px] top-[287px] bottom-[24px] w-72 h-[143px]">
            <div className="absolute left-0 top-0 w-72 h-[143px] text-center">
              <span className="text-neutral-700 text-sm font-normal font-Montserrat leading-snug line-clamp-6">
                {testimonial}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
