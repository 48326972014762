import React, { useState } from "react"
import IconButton from "core/components/IconButton"
import Toggle from "core/components/Toggle"
import { Link } from "react-router-dom"
import Avatar from "react-avatar"
import CopyIcon from "assets/svgs/Copy"
import { STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"

export default function MentorDashboardTable(props: any) {
  const [showFullEmail, setShowFullEmail] = useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const copyEmail = (email: string) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Email Copied to clipboard") // Optional: to notify the user that the email is copied
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err)
      })
  }

  return (
    <div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="relative mx-[24px] overflow-x-auto rounded-md shadow-md">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
          <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
            <tr>
              {props.tableHeaders?.map((title: any, index: any) => (
                <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.mentorData
              ?.filter((data: any) => {
                // Apply filtering logic based on email and course
                const emailMatches = props.searchingforEmail
                  ? data.email?.toLowerCase().includes(props.searchByEmail?.toLowerCase())
                  : true // If searchByEmail is empty, don't filter by email
                const courseMatches = props.searchingByCourse
                  ? data.course_type?.toLowerCase().includes(props.searchByCourse?.toLowerCase())
                  : true // If searchByCourse is empty, don't filter by course

                return emailMatches && courseMatches // Return true only if both filters match
              })
              .map((data: any) => (
                <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={data.email}>
                  <td className="px-6 py-4 ">
                    <Avatar name={data.photo} src={data.photo} round size="60" />
                  </td>
                  <td className="px-6 py-4">
                    <p>{data.name}</p>
                    <p>{data.phone}</p>
                    <p>{data.course_type}</p>
                    <p
                      className="max-w-xs cursor-pointer overflow-hidden font-medium"
                      id="email"
                      onMouseEnter={() => setShowFullEmail(true)}
                      onMouseLeave={() => setShowFullEmail(false)}
                    >
                      <button onClick={() => copyEmail(data.email)}>
                        <CopyIcon />
                      </button>
                    </p>
                  </td>
                  <td className="pl-10">{data.mentees?.length}</td>
                  <td className="px-6 py-4 text-[16px] font-medium">
                    <p>{data.bandwidth}</p>
                  </td>
                  <td className="px-6 py-4 text-[16px]">{data.current_company}</td>
                  <td className="px-6 py-4 text-[16px]">{data.experience}</td>
                  <td className={`px-6 py-4 text-[16px] font-medium`}>
                    <Toggle
                      checked={data.is_instructor}
                      onChange={() => {
                        props.handleInstructor(data.email)
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 text-[16px]">
                    <Toggle
                      checked={data.disabled}
                      status={true}
                      onChange={() => {
                        props.handleDisableMentor(data.email)
                      }}
                      className={`${
                        data.disabled
                          ? "peer-checked:bg-red-600 peer-checked:after:translate-x-full"
                          : "dark:bg-gray-700"
                      }`}
                    />
                  </td>
                  <td className="px-6 py-4 text-[16px]">
                    {" "}
                    <Toggle
                      checked={data?.is_mentor_locked}
                      onChange={() => {
                        props.handleLockMentor(data.email)
                      }}
                      className={`${
                        data.is_mentor_locked
                          ? "peer-checked:bg-red-600 peer-checked:after:translate-x-full"
                          : "dark:bg-gray-700"
                      }`}
                    />
                  </td>
                  <td className="px-6 py-4 text-[16px]">
                    {" "}
                    <Toggle
                      checked={data?.goal_setting_mentor}
                      onChange={() => {
                        props.handleDisableGoalSettingMentor(data.email)
                      }}
                    />
                  </td>

                  <td className="px-6 py-4 text-[16px]">
                    <Link to={`/admin/mentor-profile/edit/${data.email}`}>
                      <IconButton
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit"
                          >
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                          </svg>
                        }
                      />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
