import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface GetRightPanelDetailsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetRightPanelDetails implements GetRightPanelDetailsUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getRightPanelDetails(auth)
  }
}
