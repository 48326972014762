import React from "react"

export default function Feedback3(props: any) {
  const { selectedColor, onHover, onLeave, onClick, index, isSelected } = props

  return (
    <svg
      className={props.className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onLeave()}
      onClick={() => onClick(index)}
      fill={isSelected ? selectedColor : "#ADADAD"}
    >
      <rect width="64" height="64" rx="8" fill={isSelected ? selectedColor : "#D3D3D3"} />
      <circle cx="22.7441" cy="24.4043" r="3.40426" fill="white" />
      <circle cx="40.7871" cy="24.4043" r="3.40426" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.579 42.1104C44.2664 42.7943 43.4584 43.0953 42.7745 42.7826L38.6475 40.896C34.091 38.813 28.8395 38.8884 24.3446 41.1013L20.9633 42.7659C20.2886 43.0981 19.4724 42.8204 19.1402 42.1457C18.808 41.4709 19.0857 40.6547 19.7604 40.3225L23.1417 38.6579C28.3705 36.0838 34.4793 35.9961 39.7798 38.4192L43.9067 40.3058C44.5907 40.6185 44.8917 41.4264 44.579 42.1104Z"
        fill="white"
      />
    </svg>
  )
}
