import Button from "core/components/new/Button"
import { StarIcon } from "core/constants/svgs"
import Select from "core/components/new/Select"
function UpdateDetails(props: any) {
  return (
    <>
      {
        props.isDetailUpdateOpen && (
          <div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.85)]">
                <div className={"relative mx-auto w-full max-w-4xl  shadow-lg"}>
                  <div className="w-full p-6 ">
                    <div className="flex flex-col items-start gap-4 rounded-[4px]  border-[0.5px] border-solid border-[#312E81] bg-new-solid-white p-6">
                      <div className="flex w-full">
                        <div className="font-montserrat flex w-full text-[18px] font-semibold text-[#312E81]">
                          Progress Meeting Details
                        </div>
                        <div className=" flex justify-end">
                          <button
                            className="text-gray-500 hover:text-gray-600 focus:outline-none"
                            onClick={props.handleIsDetailUpdateopen}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M10.586 12L5.63599 7.05001L7.04999 5.63601L12 10.586L16.95 5.63601L18.364 7.05001L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12Z"
                                fill="#6B6B6B"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className=" flex flex-row flex-wrap space-x-4">
                        <div className="">
                          <div className="font-montserrat  text-[14px] font-semibold text-[#414141]">Meeting Topic</div>
                          <input
                            className="mt-2 h-[33px] w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                            type="text"
                            placeholder="Type here"
                            value={props.updatedMeeting}
                            onChange={props.handleUpdatedMeeting}
                          ></input>
                        </div>
                        <div>
                          <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">Date</div>
                          <input
                            className="h-[33px] w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                            type="date"
                            placeholder="Type here"
                            value={props.meetingUpdatedDate}
                            onChange={props.handleMeetingUpdatedDate}
                          ></input>
                        </div>
                        <div className="">
                          <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">Status</div>
                          <Select
                            required
                            className="h-[33px] w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 "
                            options={props.statusOption}
                            value={props.updatedStatus}
                            onChange={props.handleUpdatedStatus}
                            defaultOption="Select Status"
                          />
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                            Duration (In Mins)
                          </div>
                          <input
                            className="h-[33px] w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                            type="number"
                            placeholder="Type here"
                            value={props.meetingUpdatedDuration}
                            onChange={props.handleMeetingUpdatedDuration}
                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement)?.blur()}
                          ></input>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Meeting Agenda
                        </div>
                        <textarea
                          value={props.meetingUpdatedAgenda}
                          onChange={props.handleMeetingUpdatedAgenda}
                          className="h-[113px] w-full rounded-sm border border-border bg-transparent p-4"
                          placeholder="Type here"
                        />
                      </div>
                      <div className="w-full">
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">SSM Remarks</div>
                        <textarea
                          value={props.meetingUpdatedSsmRemark}
                          onChange={props.handleMeetingUpdatedSsmRemark}
                          className="h-[113px] w-full rounded-sm border border-border bg-transparent p-4"
                          placeholder="Type here"
                        />
                      </div>
                      <div className=" h-0 w-full border-t-[1px] border-dashed border-[#ADADAD]"></div>
                      <div className=" flex items-center justify-center gap-6">
                        <p className="font-montserratflex h-full  text-[14px] font-semibold text-[#414141]">
                          Rate Student
                        </p>
                        <div className="flex gap-4">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <button type="button" key={i} onClick={() => props.handleUpdatedRating(i + 1)}>
                                <StarIcon
                                  className="h-6 w-6"
                                  pathClassName={
                                    props.updatedRating !== "-1" && props.updatedRating >= i + 1
                                      ? "fill-[#FBBF24]"
                                      : "fill-border"
                                  }
                                />
                              </button>
                            ))}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Student Feedback
                        </div>
                        <textarea
                          value={props.updatedFeedback}
                          onChange={props.handleUpdatedFeedback}
                          className="h-[113px] w-full rounded-sm border border-border bg-transparent p-4"
                          placeholder="Type here"
                        />
                      </div>
                      <div className=" h-0 w-full border-t-[1px] border-dashed border-[#ADADAD]"></div>

                      <div>
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Problem Solving Milestone Due Date
                        </div>
                        <input
                          className="h-[33px] w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                          type="date"
                          placeholder="Type here"
                          value={props.updatedProblemSolvingMilestoneDueDate}
                          onChange={props.handleUpdatedProblemSolvingMilestoneDate}
                        ></input>
                      </div>
                      <div className="w-full">
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Problem Solving Milestone
                        </div>
                        <textarea
                          value={props.updatedProblemSolvingMilestone}
                          onChange={props.handleUpdatedProblemSolvingMilestone}
                          className="h-[113px] w-full rounded-sm border border-border bg-transparent p-4"
                          placeholder="Type here"
                        />
                      </div>
                      <div>
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Development Milestone Due Date
                        </div>
                        <input
                          className="h-[33px] w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                          type="date"
                          placeholder="Type here"
                          value={props.updatedDevelopmentMilestoneDueDate}
                          onChange={props.handleUpdatedDevelopmentMilestoneDate}
                        ></input>
                      </div>
                      <div className="w-full">
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Development Milestone
                        </div>
                        <textarea
                          value={props.updatedDevelopmentMilestone}
                          onChange={props.handleUpdatedDevelopmentMilestone}
                          className="h-[113px] w-full rounded-sm border border-border bg-transparent p-4"
                          placeholder="Type here"
                        />
                      </div>

                      <div className="flex w-full ">
                        <div className="font-montserrat pt-0 ">
                          <div className="text-[18px] font-semibold text-[#414141]">Meeting Data</div>
                          <div className="text-[14px] leading-6  text-[#6B6B6B]">Save your progress meeting data.</div>
                        </div>
                        <div className="ml-auto flex space-x-4">
                          <Button
                            onClick={props.handleDiscard}
                            outlined
                            className="h-[52px] w-[178px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                          >
                            Discard Changes
                          </Button>
                          <Button
                            className="font-montserrat h-[52px] w-[178px] text-[16px] font-medium  "
                            onClick={() => {
                              props.handleMeetingDetails(props.email)
                            }}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        // </Model>
      }
    </>
  )
}

export default UpdateDetails
