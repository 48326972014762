import React from "react"

export default function Store({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9936 8.665V13.3305C13.9936 13.5073 13.9234 13.6768 13.7984 13.8018C13.6734 13.9268 13.5038 13.997 13.3271 13.997H2.66308C2.48631 13.997 2.31678 13.9268 2.19179 13.8018C2.0668 13.6768 1.99658 13.5073 1.99658 13.3305V8.665H1.33008V7.332L1.99658 3.9995H13.9936L14.6601 7.332V8.665H13.9936ZM3.32958 8.665V12.664H12.6606V8.665H3.32958ZM2.68974 7.332H13.3004L12.9005 5.3325H3.08964L2.68974 7.332ZM3.99608 9.3315H9.32808V11.331H3.99608V9.3315ZM1.99658 2H13.9936V3.333H1.99658V2Z"
        fill="currentColor"
      />
    </svg>
  )
}
