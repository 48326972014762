import { Auth } from "domain/model/Auth"
import { GroupMentorSession } from "domain/model/GroupMentorSession"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface DeleteGroupMentorSessionUseCase {
  invoke(auth: Auth, session_id: string): Promise<any>
}

export default class DeleteGroupMentorSession implements DeleteGroupMentorSessionUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string) {
    return await this.repository.deleteGroupMentorSession(auth, session_id)
  }
}
