import React from "react"

// Small functional component to represent the vertical divider
const VerticalDivider = () => <div className="h-11 border border-[#ADADAD]" />

const Report = ({ data }: any) => {
  if (!data) {
    return (
      <div>
        <h2>DATA N/A</h2>
        <p>Please select any of the parameters from the bar graph.</p>
      </div>
    )
  }

  if (!data.present) {
    return <div>Data has not yet been collected for this week.</div>
  }

  return (
    <div className="bg-[#FBFBFB] flex justify-between p-3 px-6 mx-3 rounded-xl items-center mt-2">
      <div className="text-left" style={{ width: "187px" }}>
        <p className="font-[600] text-12">Class Attendance</p>
        <div className="flex justify-between">
          <div className=" font-[400] text-10 ">
            <p>Classes scheduled</p>
            <p>Classes Attended</p>
          </div>
          <div>
            <p>:</p>
            <p>:</p>
          </div>
          <div>
            <p>{data.classAttendancetotal}</p>
            <p>{data.classAttendance}</p>
          </div>
        </div>
      </div>

      <VerticalDivider />

      <div className="text-left">
        <p className="font-bold text-12">Class Assignment</p>
        <div className=" flex gap-4 text-10">
          <div>
            <p>Total Problems</p>
            <p>Problems solved</p>
          </div>
          <div>
            <p>:</p> <p>:</p>
          </div>
          <div>
            <p>{data.totalAssignments}</p>
            <p>{data.assignmentProblemsSolved}</p>
          </div>
        </div>
      </div>

      {/* <div> */}
      <VerticalDivider />
      {/* </div> */}

      <div className="text-left mt-0">
        <p className="font-bold text-10">Class Feedback</p>
        <div className="flex gap-4">
          <div>
            <p>Maximum</p>
            <p>Student Rating</p>
          </div>
          <div>
            <p>:</p> <p>:</p>
          </div>
          <div>
            <p>5</p>
            <p>{data?.avg_rating == 0 ? "NR" : data?.avg_rating}</p>
          </div>
        </div>
      </div>

      <VerticalDivider />

      <div className="text-left">
        <p className="font-bold text-12">Weekly Test</p>
        <div className=" flex gap-4 text-10">
          <div>
            <p>Total Marks</p>
            <p>Marks Obtained</p>
          </div>
          <div>
            <p>:</p> <p>:</p>
          </div>
          <div>
            <p>{data.waScoretotal}</p>
            <p>{data.waScore}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
