import MonthlyReportRepositry from "domain/repository/Admin/MonthlyReport"
import GetMonthlyReport from "domain/model/GetMonthlyReport"

export interface getMonthlyReportUseCase {
  invoke(details: GetMonthlyReport): Promise<any>
}

export class GetMonthlyReportData implements getMonthlyReportUseCase {
  private repository: MonthlyReportRepositry

  constructor(repository: MonthlyReportRepositry) {
    this.repository = repository
  }

  async invoke(details: GetMonthlyReport): Promise<any> {
    return await this.repository.getMonthlyReport(details)
  }
}
