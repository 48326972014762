import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface GetRemainderDetailsUseCase {
  invoke(auth: Auth, type: string): Promise<any>
}

export class GetRemainderDetails implements GetRemainderDetailsUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, type: string) {
    return this.repository.getRemainderDetails(auth, type)
  }
}
