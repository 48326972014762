import { Auth } from "domain/model/Auth"
import { AvailabilityRepository } from "domain/repository/Mentor/AvailabilityRepository"

export interface GetAvailabilityUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAvailability implements GetAvailabilityUseCase {
  private repository: AvailabilityRepository

  constructor(repository: AvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAvailability(auth)
  }
}
