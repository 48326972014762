import { useEffect, useState } from "react"
import { GetOverallReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetOverallReportData"
import { OverallReportRepositoryImpl } from "data/repository/Admin/OverallReportRepositoryImpl"
import { OverallReportAPIDataSourceImpl } from "data/API/Admin/OverallReportDataSourceImpl"
import useToast from "core/hooks/useToast"
import { STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import ProgressRoadmapViewModel from "../../../BatchProgressRoadmapViewModel"
const OverallReportViewModel = () => {
  const { id } = ProgressRoadmapViewModel()
  const [studentData, setStudentData] = useState<any>()
  const [labelData, setLabelData] = useState<any>()
  const [currentLabel, setCurrentLabel] = useState<number>()
  const [label, setLabel] = useState<any>()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth, refreshed } = useAuth()
  const [showContent, setShowContent] = useState(false)
  const [currContent, setCurrContent] = useState(null)

  const getOverallReportUseCase = new GetOverallReportData(
    new OverallReportRepositoryImpl(new OverallReportAPIDataSourceImpl())
  )

  const getOverallReport = async () => {
    const response = await getOverallReportUseCase.invoke({
      id_token: auth.id_token,
      email: id,
    })
    setLabelData(response.data.overall_report)
    setCurrentLabel(0)
    setStudentData(response.data)
  }
  return {
    currentLabel,
    studentData,
    labelData,
    label,
    setCurrentLabel,
    getOverallReport,
    showContent,
    setShowContent,
    currContent,
    setLabel,
    setCurrContent,
  }
}

export default OverallReportViewModel
