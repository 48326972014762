import { Auth } from "domain/model/Auth"
import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"

export interface getReferralDataUseCase {
  invoke(request: Auth): Promise<any>
}
export class GetReferralData implements getReferralDataUseCase {
  private repository: ReferAndEarnRepository

  constructor(repository: ReferAndEarnRepository) {
    this.repository = repository
  }

  async invoke(request: Auth): Promise<any> {
    return await this.repository.getReferralData(request)
  }
}
