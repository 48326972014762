import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface UpdateAllClassDetailsUseCase {
  invoke(
    auth: Auth,
    session_id: string,
    status: string,
    actual_duration: number,
    considered_duration: number
  ): Promise<any>
}

export default class UpdateAllClassDetails implements UpdateAllClassDetailsUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string, status: string, actual_duration: number, considered_duration: number) {
    return await this.repository.updateAllClassDetails(auth, session_id, status, actual_duration, considered_duration)
  }
}
