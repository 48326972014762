import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { JobDashboardAPIDataSourceImpl } from "data/API/Admin/JobDashboardAPIDataSourceImpl"
import { JobDashboardRepositoryImpl } from "data/repository/Admin/JobDashboardRepositoryImpl"
import { format } from "date-fns"
import { DeleteJobs } from "domain/useCase/Admin/JobDashboard/DeleteJob"
import { GetAllJobsList } from "domain/useCase/Admin/JobDashboard/GetAllJobs"
import { GetCompanies } from "domain/useCase/Admin/JobDashboard/GetCompaniesList"
import { GetJobDetails } from "domain/useCase/Admin/JobDashboard/GetJobDetails"
import { UpdateJobs } from "domain/useCase/Admin/JobDashboard/UpdateJobs"
import React from "react"

export default function JobDashboardViewModel() {
  const [companiesList, setCompaniesList] = React.useState<any>([])
  const [jobList, setJobList] = React.useState<any>([])

  const [selectCompany, setSelectCompany] = React.useState("")

  const [selectValues, setSelectValues] = React.useState({
    expiredDate: "",
    jobDescription: "",
    job_link: "",
    companyLogo: "",
    problemSolved: "",
    experience: "",
    location: "",
    role: "",
    CTC: "",
  })
  const [jobType, selectJobType] = React.useState<any>("")
  const [status, setStatus] = React.useState<any>("")
  const [othersCriateria, setOthersCriateria] = React.useState<any>({})
  const [selectOthersCriateria, setSelectOthersCriateria] = React.useState("")
  const [source, setSource] = React.useState("")
  const [isOpen, setIsOpen] = React.useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [jobId, setJobId] = React.useState("")
  const { auth } = useAuth()

  const GetCompaniesListUseCase = new GetCompanies(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))
  const GetAllJobsUseCase = new GetAllJobsList(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  const GetJobDetailsUseCase = new GetJobDetails(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  const updateJobDetailsUseCase = new UpdateJobs(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  const deleteJobsUseCase = new DeleteJobs(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  async function getCompaniesList() {
    const response = await GetCompaniesListUseCase.invoke(auth)

    if (response.success) {
      setCompaniesList(response.data)
    }
  }

  async function getAllJobsList() {
    const response = await GetAllJobsUseCase.invoke(auth)

    if (response.success) {
      setJobList(response.data)
    }
  }

  async function getJobDetails(job_id: string) {
    const response = await GetJobDetailsUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
    })
    const data = response.data
    if (response.success) {
      setSelectCompany(data.company_key)
      setJobId(data.job_id)
      setSelectValues({
        expiredDate: format(data.end_date * 1000, "yyyy-MM-dd'T'HH:mm"),
        jobDescription: data.job_description,
        job_link: data.job_link,
        companyLogo: data.job_pic_url,
        problemSolved: data.system_criteria.problem_solved,
        experience: data.system_criteria.experience,
        role: data.role,
        CTC: data.CTC ? data.CTC : "",
        location: data.location ? data.location : "",
      })
      selectJobType(data.job_type ? data.job_type : "")
      setStatus(data.status)
      setSource(data.source ? data.source : "")
      setOthersCriateria(data.others_criateria ? data.others_criateria : {})
    } else {
    }
  }
  const system_criteria = {
    problem_solved: parseInt(selectValues.problemSolved),
    experience: parseInt(selectValues.experience),
  }
  async function updateJobDetails(e: any) {
    e.preventDefault()
    const response = await updateJobDetailsUseCase.invoke({
      id_token: auth.id_token,
      company_key: selectCompany,
      date_created: Math.floor(Date.now() / 1000),
      end_date: Date.parse(selectValues.expiredDate) / 1000,
      job_description: selectValues.jobDescription,
      job_link: selectValues.job_link,
      job_pic_url: selectValues.companyLogo,
      job_type: jobType,
      system_criteria: system_criteria,
      other_criteria: othersCriateria,
      status: status,
      source: source,
      role: selectValues.role,
      CTC: selectValues.CTC,
      job_id: jobId,
      location: selectValues.location,
    })
    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response.data)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.data)
    }
  }

  async function deleteJob(job_id: string) {
    const filteredJobList = jobList.filter((job: any) => job.job_id !== job_id)
    setJobList(filteredJobList)
    await deleteJobsUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
    })
    getAllJobsList()
  }

  const handleOpenModel = () => {
    setIsOpen(true)
  }

  const handleCloseModel = () => {
    setIsOpen(false)
  }

  function onCompanyChange(e: any) {
    if (e.target.value === "others") {
      handleOpenModel()
      return
    }
    setSelectCompany(e.target.value)
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setSelectValues((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const onChangeJobType = (e: any) => {
    selectJobType(e.target.value)
  }

  const onChangeSource = (e: any) => {
    setSource(e.target.value)
  }

  const onChangeStatus = (e: any) => {
    setStatus(e.target.value)
  }

  const onChangeOthersCriateria = (e: any) => {
    setSelectOthersCriateria(e.target.value)
  }
  function handleOthersCriateria(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (selectOthersCriateria.length > 0) {
      setOthersCriateria((prevState: any) => ({
        ...prevState,
        [selectOthersCriateria]: false,
      }))
    }
    setSelectOthersCriateria("")
  }

  function removeOthersCriateria(key: string) {
    setOthersCriateria((prevState: any) => {
      const newState = { ...prevState }
      delete newState[key]
      return newState
    })
  }

  React.useEffect(() => {
    getCompaniesList()
    getAllJobsList()
  }, [])

  return {
    toast,
    companiesList,
    jobList,
    status,
    jobType,
    selectOthersCriateria,
    othersCriateria,
    selectValues,
    handleChange,
    selectCompany,
    isOpen,
    source,
    onCompanyChange,
    handleCloseModel,
    handleOpenModel,
    onChangeJobType,
    onChangeOthersCriateria,
    onChangeStatus,
    getCompaniesList,
    handleOthersCriateria,
    removeOthersCriateria,
    getJobDetails,
    onChangeSource,
    changeToastVisibility,
    updateJobDetails,
    deleteJob,
  }
}
