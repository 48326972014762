export default function MinusIcon({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      //   fill="none"
    >
      <path d="M5.08594 11H19.0859V13H5.08594V11Z" fill="#ADADAD" />
    </svg>
  )
}
