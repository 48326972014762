import { cn } from "core/lib/utils"
import React from "react"

const LeaderBoardBG2 = ({ className }: any) => {
  return (
    <svg className={cn(className)} viewBox="0 0 747 293" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1439_58740)">
        <rect width="747" height="293" rx="18" fill="#202020" />
        <path d="M0 -5V303.5" stroke="#5A5A5A" />
        <g opacity="0.25">
          <path d="M51 -5V303.5" stroke="#414141" />
          <path d="M153 -5V303.5" stroke="#414141" />
          <path d="M255 -5V303.5" stroke="#414141" />
          <path d="M357 -5V303.5" stroke="#414141" />
          <path d="M459 -5V303.5" stroke="#414141" />
          <path d="M561 -5V303.5" stroke="#414141" />
          <path d="M663 -5V303.5" stroke="#414141" />
          <path d="M102 -5V303.5" stroke="#414141" />
          <path d="M204 -5V303.5" stroke="#414141" />
          <path d="M306 -5V303.5" stroke="#414141" />
          <path d="M408 -5V303.5" stroke="#414141" />
          <path d="M510 -5V303.5" stroke="#414141" />
          <path d="M612 -5V303.5" stroke="#414141" />
          <path d="M714 -5V303.5" stroke="#414141" />
          <path d="M772 19L-16 19" stroke="#414141" />
          <path d="M772 121L-16 121" stroke="#414141" />
          <path d="M772 223L-16 223" stroke="#414141" />
          <path d="M772 70L-16 70" stroke="#414141" />
          <path d="M772 172L-16 172" stroke="#414141" />
          <path d="M772 274L-16 274" stroke="#414141" />
        </g>
        <path d="M51 66V74" stroke="#484848" stroke-width="2" />
        <path d="M55 70L47 70" stroke="#484848" stroke-width="2" />
        <path d="M51 15V23" stroke="#484848" stroke-width="2" />
        <path d="M55 19L47 19" stroke="#484848" stroke-width="2" />
        <path d="M51 117V125" stroke="#484848" stroke-width="2" />
        <path d="M55 121L47 121" stroke="#484848" stroke-width="2" />
        <path d="M51 168V176" stroke="#484848" stroke-width="2" />
        <path d="M55 172L47 172" stroke="#484848" stroke-width="2" />
        <path d="M51 219V227" stroke="#484848" stroke-width="2" />
        <path d="M55 223L47 223" stroke="#484848" stroke-width="2" />
        <path d="M51 270V278" stroke="#484848" stroke-width="2" />
        <path d="M55 274L47 274" stroke="#484848" stroke-width="2" />
        <path d="M102 66V74" stroke="#484848" stroke-width="2" />
        <path d="M106 70L98 70" stroke="#484848" stroke-width="2" />
        <path d="M102 15V23" stroke="#484848" stroke-width="2" />
        <path d="M106 19L98 19" stroke="#484848" stroke-width="2" />
        <path d="M102 117V125" stroke="#484848" stroke-width="2" />
        <path d="M106 121L98 121" stroke="#484848" stroke-width="2" />
        <path d="M102 168V176" stroke="#484848" stroke-width="2" />
        <path d="M106 172L98 172" stroke="#484848" stroke-width="2" />
        <path d="M102 219V227" stroke="#484848" stroke-width="2" />
        <path d="M106 223L98 223" stroke="#484848" stroke-width="2" />
        <path d="M102 270V278" stroke="#484848" stroke-width="2" />
        <path d="M106 274L98 274" stroke="#484848" stroke-width="2" />
        <path d="M153 66V74" stroke="#484848" stroke-width="2" />
        <path d="M157 70L149 70" stroke="#484848" stroke-width="2" />
        <path d="M153 15V23" stroke="#484848" stroke-width="2" />
        <path d="M157 19L149 19" stroke="#484848" stroke-width="2" />
        <path d="M153 117V125" stroke="#484848" stroke-width="2" />
        <path d="M157 121L149 121" stroke="#484848" stroke-width="2" />
        <path d="M153 168V176" stroke="#484848" stroke-width="2" />
        <path d="M157 172L149 172" stroke="#484848" stroke-width="2" />
        <path d="M153 219V227" stroke="#484848" stroke-width="2" />
        <path d="M157 223L149 223" stroke="#484848" stroke-width="2" />
        <path d="M153 270V278" stroke="#484848" stroke-width="2" />
        <path d="M157 274L149 274" stroke="#484848" stroke-width="2" />
        <path d="M204 66V74" stroke="#484848" stroke-width="2" />
        <path d="M208 70L200 70" stroke="#484848" stroke-width="2" />
        <path d="M204 15V23" stroke="#484848" stroke-width="2" />
        <path d="M208 19L200 19" stroke="#484848" stroke-width="2" />
        <path d="M204 117V125" stroke="#484848" stroke-width="2" />
        <path d="M208 121L200 121" stroke="#484848" stroke-width="2" />
        <path d="M204 168V176" stroke="#484848" stroke-width="2" />
        <path d="M208 172L200 172" stroke="#484848" stroke-width="2" />
        <path d="M204 219V227" stroke="#484848" stroke-width="2" />
        <path d="M208 223L200 223" stroke="#484848" stroke-width="2" />
        <path d="M204 270V278" stroke="#484848" stroke-width="2" />
        <path d="M208 274L200 274" stroke="#484848" stroke-width="2" />
        <path d="M255 66V74" stroke="#484848" stroke-width="2" />
        <path d="M259 70L251 70" stroke="#484848" stroke-width="2" />
        <path d="M255 15V23" stroke="#484848" stroke-width="2" />
        <path d="M259 19L251 19" stroke="#484848" stroke-width="2" />
        <path d="M255 117V125" stroke="#484848" stroke-width="2" />
        <path d="M259 121L251 121" stroke="#484848" stroke-width="2" />
        <path d="M255 168V176" stroke="#484848" stroke-width="2" />
        <path d="M259 172L251 172" stroke="#484848" stroke-width="2" />
        <path d="M255 219V227" stroke="#484848" stroke-width="2" />
        <path d="M259 223L251 223" stroke="#484848" stroke-width="2" />
        <path d="M255 270V278" stroke="#484848" stroke-width="2" />
        <path d="M259 274L251 274" stroke="#484848" stroke-width="2" />
        <path d="M306 66V74" stroke="#484848" stroke-width="2" />
        <path d="M310 70L302 70" stroke="#484848" stroke-width="2" />
        <path d="M306 15V23" stroke="#484848" stroke-width="2" />
        <path d="M310 19L302 19" stroke="#484848" stroke-width="2" />
        <path d="M306 117V125" stroke="#484848" stroke-width="2" />
        <path d="M310 121L302 121" stroke="#484848" stroke-width="2" />
        <path d="M306 168V176" stroke="#484848" stroke-width="2" />
        <path d="M310 172L302 172" stroke="#484848" stroke-width="2" />
        <path d="M306 219V227" stroke="#484848" stroke-width="2" />
        <path d="M310 223L302 223" stroke="#484848" stroke-width="2" />
        <path d="M306 270V278" stroke="#484848" stroke-width="2" />
        <path d="M310 274L302 274" stroke="#484848" stroke-width="2" />
        <path d="M357 66V74" stroke="#484848" stroke-width="2" />
        <path d="M361 70L353 70" stroke="#484848" stroke-width="2" />
        <path d="M357 15V23" stroke="#484848" stroke-width="2" />
        <path d="M361 19L353 19" stroke="#484848" stroke-width="2" />
        <path d="M357 117V125" stroke="#484848" stroke-width="2" />
        <path d="M361 121L353 121" stroke="#484848" stroke-width="2" />
        <path d="M357 168V176" stroke="#484848" stroke-width="2" />
        <path d="M361 172L353 172" stroke="#484848" stroke-width="2" />
        <path d="M357 219V227" stroke="#484848" stroke-width="2" />
        <path d="M361 223L353 223" stroke="#484848" stroke-width="2" />
        <path d="M357 270V278" stroke="#484848" stroke-width="2" />
        <path d="M361 274L353 274" stroke="#484848" stroke-width="2" />
        <path d="M408 66V74" stroke="#484848" stroke-width="2" />
        <path d="M412 70L404 70" stroke="#484848" stroke-width="2" />
        <path d="M408 15V23" stroke="#484848" stroke-width="2" />
        <path d="M412 19L404 19" stroke="#484848" stroke-width="2" />
        <path d="M408 117V125" stroke="#484848" stroke-width="2" />
        <path d="M412 121L404 121" stroke="#484848" stroke-width="2" />
        <path d="M408 168V176" stroke="#484848" stroke-width="2" />
        <path d="M412 172L404 172" stroke="#484848" stroke-width="2" />
        <path d="M408 219V227" stroke="#484848" stroke-width="2" />
        <path d="M412 223L404 223" stroke="#484848" stroke-width="2" />
        <path d="M408 270V278" stroke="#484848" stroke-width="2" />
        <path d="M412 274L404 274" stroke="#484848" stroke-width="2" />
        <path d="M459 66V74" stroke="#484848" stroke-width="2" />
        <path d="M463 70L455 70" stroke="#484848" stroke-width="2" />
        <path d="M459 15V23" stroke="#484848" stroke-width="2" />
        <path d="M463 19L455 19" stroke="#484848" stroke-width="2" />
        <path d="M459 117V125" stroke="#484848" stroke-width="2" />
        <path d="M463 121L455 121" stroke="#484848" stroke-width="2" />
        <path d="M459 168V176" stroke="#484848" stroke-width="2" />
        <path d="M463 172L455 172" stroke="#484848" stroke-width="2" />
        <path d="M459 219V227" stroke="#484848" stroke-width="2" />
        <path d="M463 223L455 223" stroke="#484848" stroke-width="2" />
        <path d="M459 270V278" stroke="#484848" stroke-width="2" />
        <path d="M463 274L455 274" stroke="#484848" stroke-width="2" />
        <path d="M510 66V74" stroke="#484848" stroke-width="2" />
        <path d="M514 70L506 70" stroke="#484848" stroke-width="2" />
        <path d="M510 15V23" stroke="#484848" stroke-width="2" />
        <path d="M514 19L506 19" stroke="#484848" stroke-width="2" />
        <path d="M510 117V125" stroke="#484848" stroke-width="2" />
        <path d="M514 121L506 121" stroke="#484848" stroke-width="2" />
        <path d="M510 168V176" stroke="#484848" stroke-width="2" />
        <path d="M514 172L506 172" stroke="#484848" stroke-width="2" />
        <path d="M510 219V227" stroke="#484848" stroke-width="2" />
        <path d="M514 223L506 223" stroke="#484848" stroke-width="2" />
        <path d="M510 270V278" stroke="#484848" stroke-width="2" />
        <path d="M514 274L506 274" stroke="#484848" stroke-width="2" />
        <path d="M561 66V74" stroke="#484848" stroke-width="2" />
        <path d="M565 70L557 70" stroke="#484848" stroke-width="2" />
        <path d="M561 15V23" stroke="#484848" stroke-width="2" />
        <path d="M565 19L557 19" stroke="#484848" stroke-width="2" />
        <path d="M561 117V125" stroke="#484848" stroke-width="2" />
        <path d="M565 121L557 121" stroke="#484848" stroke-width="2" />
        <path d="M561 168V176" stroke="#484848" stroke-width="2" />
        <path d="M565 172L557 172" stroke="#484848" stroke-width="2" />
        <path d="M561 219V227" stroke="#484848" stroke-width="2" />
        <path d="M565 223L557 223" stroke="#484848" stroke-width="2" />
        <path d="M561 270V278" stroke="#484848" stroke-width="2" />
        <path d="M565 274L557 274" stroke="#484848" stroke-width="2" />
        <path d="M612 66V74" stroke="#484848" stroke-width="2" />
        <path d="M616 70L608 70" stroke="#484848" stroke-width="2" />
        <path d="M612 15V23" stroke="#484848" stroke-width="2" />
        <path d="M616 19L608 19" stroke="#484848" stroke-width="2" />
        <path d="M612 117V125" stroke="#484848" stroke-width="2" />
        <path d="M616 121L608 121" stroke="#484848" stroke-width="2" />
        <path d="M612 168V176" stroke="#484848" stroke-width="2" />
        <path d="M616 172L608 172" stroke="#484848" stroke-width="2" />
        <path d="M612 219V227" stroke="#484848" stroke-width="2" />
        <path d="M616 223L608 223" stroke="#484848" stroke-width="2" />
        <path d="M612 270V278" stroke="#484848" stroke-width="2" />
        <path d="M616 274L608 274" stroke="#484848" stroke-width="2" />
        <path d="M663 66V74" stroke="#484848" stroke-width="2" />
        <path d="M667 70L659 70" stroke="#484848" stroke-width="2" />
        <path d="M663 15V23" stroke="#484848" stroke-width="2" />
        <path d="M667 19L659 19" stroke="#484848" stroke-width="2" />
        <path d="M663 117V125" stroke="#484848" stroke-width="2" />
        <path d="M667 121L659 121" stroke="#484848" stroke-width="2" />
        <path d="M663 168V176" stroke="#484848" stroke-width="2" />
        <path d="M667 172L659 172" stroke="#484848" stroke-width="2" />
        <path d="M663 219V227" stroke="#484848" stroke-width="2" />
        <path d="M667 223L659 223" stroke="#484848" stroke-width="2" />
        <path d="M663 270V278" stroke="#484848" stroke-width="2" />
        <path d="M667 274L659 274" stroke="#484848" stroke-width="2" />
        <path d="M714 66V74" stroke="#484848" stroke-width="2" />
        <path d="M718 70L710 70" stroke="#484848" stroke-width="2" />
        <path d="M714 15V23" stroke="#484848" stroke-width="2" />
        <path d="M718 19L710 19" stroke="#484848" stroke-width="2" />
        <path d="M714 117V125" stroke="#484848" stroke-width="2" />
        <path d="M718 121L710 121" stroke="#484848" stroke-width="2" />
        <path d="M714 168V176" stroke="#484848" stroke-width="2" />
        <path d="M718 172L710 172" stroke="#484848" stroke-width="2" />
        <path d="M714 219V227" stroke="#484848" stroke-width="2" />
        <path d="M718 223L710 223" stroke="#484848" stroke-width="2" />
        <path d="M714 270V278" stroke="#484848" stroke-width="2" />
        <path d="M718 274L710 274" stroke="#484848" stroke-width="2" />
      </g>
      <defs>
        <clipPath id="clip0_1439_58740">
          <rect width="747" height="293" rx="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LeaderBoardBG2
