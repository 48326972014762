export default function Calendar(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0002 2.66634H11.3335V1.33301H10.0002V2.66634H6.00016V1.33301H4.66683V2.66634H2.00016C1.63197 2.66634 1.3335 2.96482 1.3335 3.33301V13.9997C1.3335 14.3679 1.63197 14.6663 2.00016 14.6663H14.0002C14.3684 14.6663 14.6668 14.3679 14.6668 13.9997V3.33301C14.6668 2.96482 14.3684 2.66634 14.0002 2.66634ZM5.3335 8.66634V7.33301H4.00016V8.66634H5.3335ZM5.3335 9.99967V11.333H4.00016V9.99967H5.3335ZM8.66683 7.33301H7.3335V8.66634H8.66683V7.33301ZM7.3335 9.99967H8.66683V11.333H7.3335V9.99967ZM12.0002 8.66634V7.33301H10.6668V8.66634H12.0002ZM12.0002 9.99967V11.333H10.6668V9.99967H12.0002ZM6.00016 3.99967H10.0002V5.33301H11.3335V3.99967H13.3335V13.3263H2.66683V3.99967H4.66683V5.33301H6.00016V3.99967Z"
        fill="currentColor"
      />
    </svg>
  )
}
