import { Auth } from "domain/model/Auth"

import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface GetPaymentMethodsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetPaymentMethods implements GetPaymentMethodsUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getPaymentMethods(auth)
  }
}
