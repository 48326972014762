import React, { useEffect, useState } from "react"
import DailyReportMainBody from "./DailyReportMainBody"
import axios from "axios"
import { useAuth } from "core/context/auth"

export default function DailyReport(props: any) {
  return (
    <>
      <div className=" rounded border-[0.5px] border-[#ADADAD] ">
        {/* <Test /> */}
        <div className="w-full border-b-[0.5px] border-b-[#ADADAD] text-center text-[14px] font-semibold leading-6 text-[#6B6B6B]">
          Daily Report
        </div>
        <div className="flex ">
          <div className="w-[54px] border-r-[0.5px] border-[#ADADAD]"></div>
          <div className=" flex-1 ">
            <DailyReportMainBody
              id={props.id}
              joiningDate={props.joiningDate}
              formatDateString={props.formatDateString}
              getDayOfWeek={props.getDayOfWeek}
              getPreviousDate={props.getPreviousDate}
              daily={props.daily}
              dataLoading={props.dataLoading}
              fetchthing={props.fetchthing}
              todayDate={props.todayDate}
              ind={props.ind}
              setInd={props.setInd}
              handleIncInd={props.handleIncInd}
              handleDecInd={props.handleDecInd}
              student={props.student}
            />
          </div>
          <div className="flex-1  border-[#ADADAD]"></div>
        </div>
      </div>
    </>
  )
}
