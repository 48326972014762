import Avatar from "react-avatar"
import DonutChart from "core/components/DonutChart"
import { STR_WELCOME_BACK } from "core/constants/strings"
import { ProfileMetric } from "domain/model/Profile"
import { Tooltip } from "@mui/material"
import { useApp } from "core/context/app"
import { isEmpty } from "core/utils/misc"

export default function ProfileBar() {
  const { student } = useApp()

  return (
    <aside className="flex h-full w-full flex-col bg-new-solid-white shadow-card">
      <div className="flex flex-col items-center gap-2 border-b border-new-neutral-light px-6 py-6">
        <div className="flex h-16 w-16 items-center justify-center rounded-sm bg-new-solid-white">
          <Avatar className="rounded-sm object-cover" src={student?.photo} name={student?.name} size="60" />
        </div>
        <div className="flex flex-col items-center">
          <span className="text-center text-xs leading-[19px] text-new-neutral-light">{STR_WELCOME_BACK}</span>
          <h3 className="text-center text-new-accent">{student?.name}</h3>
        </div>
        <div className="w-full rounded-sm bg-new-solid-white px-4 py-2 text-center text-sm font-medium text-new-neutral-dark shadow-[0px_0px_10px_0px_rgba(141,141,141,0.20)]">
          Batch {student?.batch}
        </div>
      </div>
      {student?.batch != "Accelerator" && (
        <div className="flex flex-col gap-6 p-6">
          <div className="flex w-full flex-col gap-4">
            {!isEmpty(student) &&
              student?.metrics?.map((item: ProfileMetric) => (
                <div key={item.title} className="flex items-center gap-1">
                  <span>
                    <DonutChart current={item.current} total={item.total} />
                  </span>
                  <Tooltip title={item?.info}>
                    <div className="flex flex-col gap-1">
                      <h4 className="text-new-accent">{item.title}</h4>
                      <span className="text-xs leading-[19px]">
                        {item.current}/{item.total}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              ))}
          </div>
        </div>
      )}
    </aside>
  )
}
