import Button from "core/components/Button"
import Model from "core/components/Model"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import { useNavigate } from "react-router-dom"
import JobDashboardTable from "./components/JobDashboardTable"
import useJobDashboardViewModel from "./JobDashboardViewModel"
import UpdateCompanyDetails from "./components/UpdateCompanyDetails"
import Toast from "core/components/Toast"
export default function JobDashboardView() {
  const {
    toast,
    companiesList,
    jobList,
    source,
    status,
    jobType,
    selectOthersCriateria,
    othersCriateria,
    selectValues,
    handleChange,
    selectCompany,
    onCompanyChange,
    onChangeJobType,
    onChangeOthersCriateria,
    onChangeStatus,
    handleOthersCriateria,
    removeOthersCriateria,
    getJobDetails,
    onChangeSource,
    changeToastVisibility,
    updateJobDetails,
    deleteJob,
  } = useJobDashboardViewModel()
  const tableHeader = [
    "Company Name",
    "Created / Expired Date",
    "Update Job",
    "View Candidates",
    "Eligible Candidate",
    "Status",
    "Delete Job",
  ]
  const [isOpen, setIsOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("create-job")
  }

  const handleOpenModel = () => {
    setIsOpen(true)
  }

  const handleCloseModel = () => {
    setIsOpen(false)
  }
  return (
    <DashboardLayout isLoading={!jobList || jobList?.length === 0}>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="p-6">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Job Dashboard</h1>
          <p className="font-medium">Overall Job realted information</p>
        </div>
        <Button className="my-5 w-fit" onClick={handleClick}>
          Create Job
        </Button>
        <JobDashboardTable
          tableHeaders={tableHeader}
          jobList={jobList}
          handleOpenModel={handleOpenModel}
          getJobDetails={getJobDetails}
          deleteJob={deleteJob}
        />

        <Model isOpen={isOpen} onClose={handleCloseModel}>
          <UpdateCompanyDetails
            companiesList={companiesList}
            jobType={jobType}
            selectOthersCriateria={selectOthersCriateria}
            othersCriateria={othersCriateria}
            selectValues={selectValues}
            handleChange={handleChange}
            selectCompany={selectCompany}
            onCompanyChange={onCompanyChange}
            onChangeJobType={onChangeJobType}
            onChangeOthersCriateria={onChangeOthersCriateria}
            onChangeStatus={onChangeStatus}
            handleOthersCriateria={handleOthersCriateria}
            removeOthersCriateria={removeOthersCriateria}
            status={status}
            source={source}
            onChangeSource={onChangeSource}
            updateJobDetails={updateJobDetails}
          />
        </Model>
      </div>
    </DashboardLayout>
  )
}
