import ProjectDataSource from "data/dataSource/Admin/ProjectDataSource"
import { CreateNewProject } from "domain/model/ProjectSection"
import { Auth } from "domain/model/Auth"
export class ProjectSectionRepositoryImpl implements ProjectSectionRepositoryImpl {
  private dataSource: ProjectDataSource
  constructor(dataSource: ProjectDataSource) {
    this.dataSource = dataSource
  }
  async createProject(auth: Auth, data: any): Promise<any> {
    return this.dataSource.createProject(auth, data)
  }
  async getProject(auth: Auth): Promise<any> {
    return this.dataSource.getProject(auth)
  }
  async getProjectDetails(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getProjectDetails(auth, data)
  }
  async editProject(auth: Auth, data: any): Promise<any> {
    return this.dataSource.editProject(auth, data)
  }
  async toggleProjectStatus(auth: Auth, data: any): Promise<any> {
    return this.dataSource.toggleProjectStatus(auth, data)
  }

  async deleteProject(auth: Auth, data: any): Promise<any> {
    return this.dataSource.deleteProject(auth, data)
  }
}
