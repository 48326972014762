export default function ProgressManager(props: any) {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.6667 2.66797C12.1167 2.67604 12.9019 2.74034 13.4142 3.2526C14 3.83838 14 4.7812 14 6.6668V10.6668C14 12.5524 14 13.4953 13.4142 14.081C12.8284 14.6668 11.8856 14.6668 10 14.6668H6C4.11438 14.6668 3.17157 14.6668 2.58579 14.081C2 13.4953 2 12.5524 2 10.6668V6.6668C2 4.7812 2 3.83838 2.58579 3.2526C3.09804 2.74034 3.88331 2.67604 5.33333 2.66797"
          stroke="currentColor"
        />
        <path d="M7 9.33398H11.3333" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.66699 9.33398H5.00033" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.66699 7H5.00033" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.66699 11.666H5.00033" stroke="currentColor" strokeLinecap="round" />
        <path d="M7 7H11.3333" stroke="currentColor" strokeLinecap="round" />
        <path d="M7 11.666H11.3333" stroke="currentColor" strokeLinecap="round" />
        <path
          d="M5.33301 2.33398C5.33301 1.7817 5.78072 1.33398 6.33301 1.33398H9.66634C10.2186 1.33398 10.6663 1.7817 10.6663 2.33398V3.00065C10.6663 3.55294 10.2186 4.00065 9.66634 4.00065H6.33301C5.78072 4.00065 5.33301 3.55294 5.33301 3.00065V2.33398Z"
          stroke="currentColor"
        />
      </svg>
    </>
  )
}
