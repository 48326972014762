import { newCompanyDetials } from "domain/model/Placement"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"
import { request } from "http"

export interface addNewCompanyUseCase {
  invoke(repository: newCompanyDetials): Promise<any>
}

export class AddCompany implements addNewCompanyUseCase {
  private repository: AddPlacementRepository
  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }
  async invoke(request: newCompanyDetials): Promise<any> {
    return await this.repository.addNewPlacement(request)
  }
}
