import { Auth } from "domain/model/Auth"
import { ModuleClassRepository } from "domain/repository/Admin/ModuleClass"

export interface GetModuleClassUseCase {
  invoke(auth: Auth, instructor_id: string): Promise<any>
}

export class GetModuleClasses implements GetModuleClassUseCase {
  private repository: ModuleClassRepository

  constructor(repository: ModuleClassRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string) {
    return await this.repository.getModuleClass(auth, instructor_id)
  }
}
