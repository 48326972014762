import AwardEmpty from "assets/svgs/AwardEmpty"
import { Button } from "core/components/v2/Button"
import { AwardIcon, CrossIcon } from "core/constants/svgs"
import { CustomScroll } from "react-custom-scroll"

export default function MentorFeedbackModal({ isOpen, onClose, formatTimestamp, onboardingDetails }: any) {
  if (!isOpen) return null
  return (
    <div className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-[2px] z-0"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative bg-white  p-[24px] w-[450px] h-[550px] rounded-[8px] border-[0.5px] border-[#DEDEDE] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.2)]">
          <div className="flex justify-between">
            <p className="text-[16px] font-[600] text-[#03249A]">Onboarding Session Detail</p>
            <button onClick={onClose}>
              <CrossIcon className="h-6 w-6 hover:bg-[#dadada] rounded" />
            </button>
          </div>
          <p className="text-[14px] font-[500]">{formatTimestamp(onboardingDetails?.start_time)}</p>
          <p className="text-[14px] font-[600] mt-[16px] text-[#333]">Student's Feedback</p>
          <div className="flex gap-[8px] mt-[4px]">
            {onboardingDetails?.hasOwnProperty("student_feedback") ? (
              Array(5)
                .fill(0)
                .map((_, index) => {
                  return index < onboardingDetails?.student_feedback.star ? (
                    <AwardIcon className="size-[16px]" key={index} />
                  ) : (
                    <AwardEmpty className="size-[16px]" key={index} />
                  )
                })
            ) : (
              <>
                <AwardEmpty className="size-[16px]" />
                <AwardEmpty className="size-[16px]" />
                <AwardEmpty className="size-[16px]" />
                <AwardEmpty className="size-[16px]" />
                <AwardEmpty className="size-[16px]" />
              </>
            )}
          </div>
          <div className="bg-[#F9F9F9] w-[402px] h-[104px] overflow-y-auto text-[14px] text-[#646464] p-[10px] my-[20px] rounded-[4px]">
            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
              {onboardingDetails?.hasOwnProperty("student_feedback")
                ? onboardingDetails?.student_feedback?.message
                : ""}
            </CustomScroll>
          </div>

          <p className="text-[14px] font-[600] mt-[16px] mb-[8px] text-[#333]">Manager's Feedback</p>
          <p className="text-[10px] font-[600] text-[#646464]">Objective</p>

          <div className="bg-[#F9F9F9] w-[402px] h-[39px] text-[14px] text-[#646464] p-[10px] my-[4px] rounded-[4px]">
            {onboardingDetails?.manager_feedback?.objective ? onboardingDetails?.manager_feedback?.objective : "-"}
          </div>

          <p className="text-[10px] mt-[16px] font-[600] text-[#646464]">Next Work Action</p>

          <div className="bg-[#F9F9F9] w-[402px] h-[39px] text-[14px] text-[#646464] p-[10px] my-[4px] rounded-[4px]">
            {onboardingDetails?.manager_feedback?.action ? onboardingDetails?.manager_feedback?.action : "-"}
          </div>
        </div>
      </div>
    </div>
  )
}
