import { API_ADMIN, API_V4_ADMIN, API_V4_SSM_CALL_HISTORY, API_REMOVE_MENTOR } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"

import { SSMCallHistory } from "domain/model/SSMCallHistory"
import { SSMCallHistoryDataSource } from "data/dataSource/Admin/SSMCallHistoryDataSource"
import { Auth } from "domain/model/Auth"

export class SSMCallHistoryAPIDataSourceImpl implements SSMCallHistoryDataSource {
  async getSSMCallHistory(data: SSMCallHistory): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_SSM_CALL_HISTORY), data)
      return response
    } catch (error) {
      return error
    }
  }
}
