export default function LeftArrow({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M5.21801 7.33312L13.3327 7.33312L13.3327 8.66646L5.21801 8.66645L8.79401 12.2425L7.85135 13.1851L2.66601 7.99979L7.85135 2.81445L8.79402 3.75712L5.21801 7.33312Z"
        fill="white"
      />
    </svg>
  )
}
