import QuestionChamberRespository from "domain/repository/Admin/QuestionChamberRepository"
import { Auth } from "domain/model/Auth"

export interface GetAllQuestionsUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetAllQuestions implements GetAllQuestionsUseCase {
  private repository: QuestionChamberRespository

  constructor(repository: QuestionChamberRespository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.get_all_questions(auth, data)
  }
}
