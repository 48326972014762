export const RemoveKeyAccelerator = [
  "Bosscoder Community",
  "Class Attendance",
  "Class Feedback",
  "Class Schedule",
  "Course Pause",
  "Goal Setting Mentor Session",
  "Leaderboard",
  "Mentor Change",
  "Mentor Session",
  "Placement",
  "Placement Cohort Test",
  "Progress Meet",
  "Redeem Coins",
  "Resume Course",
  "Student Progress Meeting",
  "Tests",
  "Upcoming Classes",
]

export const RemoveKeyKCE = [
  "Batch Change",
  "Bosscoder Community",
  "Course Pause",
  "Fee Payment",
  "Fee Payment Methods",
  "Goal Setting Mentor Session",
  "Mentor Change",
  "Placement",
  "Placement Cohort Test",
  "Progress Meet",
  "Refer and Earn",
  "Resume Course",
  "Student Progress Meeting",
  "Mentor Session",
]
// "Upcoming Classes"
