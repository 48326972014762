import { Auth } from "domain/model/Auth"
import { OnboardingData, ManagerFeedback } from "domain/model/OnboardingMeet"
import { CreateOnboardingMeetRepository } from "domain/repository/Admin/CreateOnboardingMeetRepository"

export interface PostOnboardingFeedbackUseCase {
  invoke(postOnboardingFeedback: ManagerFeedback, auth: Auth): Promise<any>
}
export class PostOnboardingFeedback implements PostOnboardingFeedbackUseCase {
  private repository: CreateOnboardingMeetRepository

  constructor(repository: CreateOnboardingMeetRepository) {
    this.repository = repository
  }

  async invoke(postOnboardingFeedback: ManagerFeedback, auth: Auth) {
    return await this.repository.postOnboardingFeedback(postOnboardingFeedback, auth)
  }
}
