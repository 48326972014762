import { cn } from "core/lib/utils"

export default function Star({ className, pathClassName }: any) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 18.26L4.94704 22.208L6.52204 14.28L0.587036 8.792L8.61404 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26Z"
        className={cn("fill-[#FBBF24]", pathClassName)}
      />
    </svg>
  )
}
