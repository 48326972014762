import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface GetAllQuestionsUseCase {
  invoke(auth: Auth, class_name_id: any): Promise<any>
}

export class GetAllQuestions implements GetAllQuestionsUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, class_name_id: any) {
    return this.repository.getAllQuestions(auth, class_name_id)
  }
}
