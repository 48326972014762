import React from "react"

const Information = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33301 7.99967C1.33301 11.6817 4.31767 14.6663 7.99967 14.6663C11.6817 14.6663 14.6663 11.6817 14.6663 7.99967C14.6663 4.31767 11.6817 1.33301 7.99967 1.33301C4.31767 1.33301 1.33301 4.31767 1.33301 7.99967ZM13.333 7.99967C13.333 10.9452 10.9452 13.333 7.99967 13.333C5.05416 13.333 2.66634 10.9452 2.66634 7.99967C2.66634 5.05416 5.05416 2.66634 7.99967 2.66634C10.9452 2.66634 13.333 5.05416 13.333 7.99967ZM8.66634 4.66634V5.99967H7.33301V4.66634H8.66634ZM8.66634 11.333V7.33301H7.33301V11.333H8.66634Z"
        fill="#646464"
      />
    </svg>
  )
}

export default Information
