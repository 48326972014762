import React from "react"
import { CrossIcon } from "core/constants/svgs"

function SubmitConfirmationPopup({ message, confirmAction, cancelAction, isQuestionSubmitted = false }: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
          <div className="m-auto w-[350px] transform rounded border border-new-neutral-light bg-new-editor-dark-500 p-4 font-medium">
            <div className="relative flex">
              <p className="text-md w-[90%] font-semibold text-new-neutral-light">{message}</p>
              <button onClick={cancelAction} className="absolute right-0 mt-[3px]">
                <CrossIcon className="h-6 w-6 text-new-neutral-light" />
              </button>
            </div>
            <div className="mt-3 flex gap-x-1">
              <button
                disabled={isQuestionSubmitted}
                onClick={confirmAction}
                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border border-[#4474f8] text-[#4c65e2]  hover:bg-blue-200"
              >
                Confirm
              </button>
              <button
                onClick={cancelAction}
                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border border-[#f84444] text-[#EF4444] hover:bg-red-200"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmitConfirmationPopup
