import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface DislikeQuestionUseCase {
  invoke(auth: Auth, topic: string, dislike: boolean): Promise<any>
}

export class DislikeQuestion implements DislikeQuestionUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, topic: string, dislike: boolean) {
    return this.repository.dislikeQuestion(auth, topic, dislike)
  }
}
