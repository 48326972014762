import Button from "core/components/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/Input"
import StandardSelect from "core/components/StandaredSelect"
import { STR_DATE_AND_TIME_FIELD, STR_NUMBER_FILED, STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"

export default function UpdateCompanyDetails({
  companiesList,
  status,
  jobType,
  source,
  selectOthersCriateria,
  othersCriateria,
  selectValues,
  handleChange,
  selectCompany,
  onCompanyChange,
  onChangeJobType,
  onChangeOthersCriateria,
  onChangeStatus,
  handleOthersCriateria,
  removeOthersCriateria,
  onChangeSource,
  updateJobDetails,
}: any) {
  const job_type = ["Full Time", "Part Time", "Internship"]
  const source_type = ["internal", "external"]
  const job_status = ["active", "expired"]
  return (
    <>
      <div>
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Update Job</h1>
          <p className="font-medium">Update the jobs details </p>
        </div>
        <div className="flex justify-center py-[50px]">
          <div className="round-2 flex w-3/4  flex-col items-center p-6">
            <form
              className="mb-4 w-full"
              id="main-form"
              // onSubmit={updateJobDetails}
            >
              <select
                className="para dark:focus:[] mb-4 w-full cursor-pointer appearance-none rounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-500 outline-none dark:text-white dark:focus:ring-blue-500 "
                onChange={onCompanyChange}
                value={selectCompany}
                style={{ color: "rgb(64, 64, 64,1) " }}
              >
                {!selectCompany && (
                  <option value="" className="text-neutral-500">
                    Select Company
                  </option>
                )}
                {companiesList?.map((header: any, index: any) => (
                  <option className=" bg-neutral-50 text-neutral-500" value={header} key={index}>
                    {header}
                  </option>
                ))}
                <option value="others">others</option>
              </select>

              <Input
                type={STR_DATE_AND_TIME_FIELD}
                value={selectValues.expiredDate}
                onChange={handleChange}
                placeholder="Last Date to Apply"
                className="mb-4"
                name="expiredDate"
              />

              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.role}
                onChange={handleChange}
                placeholder="Role"
                className="mb-4"
                name="role"
              />
              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.CTC}
                onChange={handleChange}
                placeholder="CTC"
                className="mb-4"
                name="CTC"
              />
              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.job_link}
                onChange={handleChange}
                placeholder="Job link"
                className="mb-4"
                name="job_link"
              />
              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.location}
                onChange={handleChange}
                placeholder="Location"
                className="mb-4"
                name="location"
              />
              <StandardSelect
                className="mb-4"
                options={job_type}
                placeholder="Job Type"
                name="jobType"
                onChange={onChangeJobType}
                value={jobType}
              />
              <StandardSelect
                className="mb-4"
                options={source_type}
                placeholder="Source"
                name="Source"
                onChange={onChangeSource}
                value={source}
              />
              <ul className="mb-4 flex w-full list-disc flex-col border pl-8">
                {Object.keys(othersCriateria)?.map((key) => (
                  <li key={key}>
                    {key}
                    <IconButton
                      className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
                      onClick={() => removeOthersCriateria(key)}
                      text="X"
                    >
                      X
                    </IconButton>
                  </li>
                ))}
              </ul>
              <form className="flex w-full justify-between gap-4" id="form-2">
                <Input
                  type={STR_TEXT_FIELD}
                  value={selectOthersCriateria}
                  onChange={onChangeOthersCriateria}
                  placeholder="Other Criateria"
                  className="mb-4"
                />

                <Button form="form-2" onClick={handleOthersCriateria} className="ml-auto h-fit w-fit">
                  +
                </Button>
              </form>
              <Input
                type={STR_NUMBER_FILED}
                value={selectValues.problemSolved}
                onChange={handleChange}
                placeholder="Problem Solve"
                className="mb-4"
                name="problemSolved"
              />
              <Input
                type={STR_NUMBER_FILED}
                value={selectValues.experience}
                onChange={handleChange}
                placeholder="Experience"
                className="mb-4"
                name="experience"
              />
              <StandardSelect
                className=""
                options={job_status}
                placeholder="Status"
                onChange={onChangeStatus}
                value={status}
                name="status"
              />
            </form>
            <textarea
              className="para dark:focus:[] mb-4 w-full appearance-none rounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-500 outline-none dark:text-white dark:focus:ring-blue-500"
              rows={4}
              placeholder="Job Description"
              value={selectValues.jobDescription}
              onChange={handleChange}
              name="jobDescription"
              style={{ color: "rgb(64, 64, 64,1) " }}
            ></textarea>
            <Button onClick={updateJobDetails} form="main-form">
              Update
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
