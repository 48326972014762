import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface RemoveCompensationUseCase {
  invoke(auth: Auth, instructor_id: string, index: number): Promise<any>
}

export default class RemoveCompensation implements RemoveCompensationUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string, index: number) {
    return await this.repository.removeCompensation(auth, instructor_id, index)
  }
}
