import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface GetAllMentorsListUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllMentorsList implements GetAllMentorsListUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllMentorsList(auth)
  }
}
