import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI, postAPIForFiles } from "core/utils/axios"
import {
  API_GET_ALL_CLASSES,
  API_GET_CLASS_ALL_RATING,
  API_GET_CLASS_AVERAGE_RATING,
  API_GET_UPCOMING_CLASSES,
  API_UPDATE_ALL_CLASS_DETAILS,
  API_V4_DUPLICATE_CLASS,
  API_V4_ADMIN,
  API_V4_GET_STUDENT_LIST_OF_BATCH,
  API_V4_GET_BATCH_ASSIGMENT,
  API_V4_GET_STUDENT_ASSIGNMENT_DETAILS,
  API_V4_CREATE_FEEDBACK_RESPONSE,
  API_V4_GET_FEEDBACK_RESPONSE,
  API_V4_GET_SSM_SUBMISSION_ENABLE_STATUS,
  API_V4_GET_FEEDBACK_RESPONSE_SUBMISSION,
  API_V4_DELETE_FEEDBACK_FILE,
  API_V4_GET_CLASS_REMINDER_DATA,
} from "core/constants/strings"
import GetAllClassesDataSource from "data/dataSource/Admin/GetAllClassesDataSource"
import { DuplicateClass } from "domain/model/AllClasses"
import { FeedbackResponse } from "domain/model/FeedbackResponse"

export default class GetAllClassesAPIDataSourceImpl implements GetAllClassesDataSource {
  async getAllClasses(auth: Auth, batch: string, month: string, instructor: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_CLASSES), {
        id_token: auth?.id_token,
        batch,
        month,
        instructor,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getClassAverageRating(auth: Auth, class_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_CLASS_AVERAGE_RATING), {
        id_token: auth?.id_token,
        class_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getClassAllRating(auth: Auth, class_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_CLASS_ALL_RATING), {
        id_token: auth?.id_token,
        class_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateAllClassDetails(
    auth: Auth,
    session_id: string,
    status: string,
    actual_duration: number,
    considered_duration: number
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_ALL_CLASS_DETAILS), {
        id_token: auth?.id_token,
        session_id,
        status,
        actual_duration,
        considered_duration,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getUpcomingClasses(auth: Auth, batch: string, month: string, instructor: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_UPCOMING_CLASSES), {
        id_token: auth?.id_token,
        batch,
        month,
        instructor,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getDuplicateClass(duplicateClass: DuplicateClass): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_DUPLICATE_CLASS), duplicateClass)
    return response
  }

  async getStudentListofBatch(auth: Auth, batch: string, page: number, pageLimit: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_STUDENT_LIST_OF_BATCH), {
        id_token: auth?.id_token,
        batch,
        page,
        pageLimit,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getBatchAssignment(auth: Auth, session_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_BATCH_ASSIGMENT), {
        id_token: auth?.id_token,
        session_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSubmission(auth: Auth, session_id: string, student_email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_STUDENT_ASSIGNMENT_DETAILS), {
        id_token: auth?.id_token,
        session_id,
        student_email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createFeedbackResponse(
    auth: Auth,
    session_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any> {
    try {
      // Append additional fields directly to the existing formData
      formData.append("id_token", auth?.id_token)
      formData.append("session_id", session_id)
      formData.append("student_email", student_email)
      formData.append("feedbackResponse", JSON.stringify(feedbackResponse))

      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_V4_CREATE_FEEDBACK_RESPONSE), formData)

      return response // Assuming response structure is { data: any }
    } catch (error) {
      console.error("Error creating feedback response:", error)
      throw new Error("An error occurred while creating feedback response")
    }
  }

  async getFeedbackResponse(auth: Auth, session_id: string, student_email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_FEEDBACK_RESPONSE), {
        id_token: auth?.id_token,
        session_id,
        student_email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSSMAndSubmissionButtonStatus(auth: Auth, session_id: string, student_email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_SSM_SUBMISSION_ENABLE_STATUS), {
        id_token: auth?.id_token,
        session_id,
        student_email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getFeedbackResponseSubmission(auth: Auth, session_id: string, student_email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_FEEDBACK_RESPONSE_SUBMISSION), {
        id_token: auth?.id_token,
        session_id,
        student_email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteFeedbackFile(
    auth: Auth,
    session_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_DELETE_FEEDBACK_FILE), {
        id_token: auth?.id_token,
        session_id,
        student_email,
        fileurl,
        questionName,
        type,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getClassReminderData(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CLASS_REMINDER_DATA), {
        id_token: auth?.id_token,
        id: id,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
