import React from "react"
import { useNavigate } from "react-router-dom"

export default function InvoicingMainPageViewModel() {
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  // const navigate = useNavigate();
  function changeActiveButtonType(index: number) {
    setActiveButtonType((prevIndex) => (prevIndex === index ? prevIndex : index))
  }
  return {
    activeButtonType,
    changeActiveButtonType,
  }
}
