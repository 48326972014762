import React from "react"

function Ellipse123() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
        <circle cx="2" cy="2" r="2" fill="white" />
      </svg>
    </div>
  )
}

export default Ellipse123
