import { AddPlacementDataSource } from "data/dataSource/Admin/AddPlacementDataSource"
import { Auth } from "domain/model/Auth"
import {
  companiesList,
  companyDetails,
  newCompanyDetials,
  placementDetail,
  studentDetails,
} from "domain/model/Placement"
export class AddPlacementRepositoryImpl implements AddPlacementRepositoryImpl {
  private dataSource: AddPlacementDataSource
  constructor(dataSource: AddPlacementDataSource) {
    this.dataSource = dataSource
  }
  getCompaniesList(request: companiesList): Promise<any> {
    return this.dataSource.getCompaniesList(request)
  }
  uploadCompanyLogo(request: companyDetails): Promise<any> {
    return this.dataSource.uploadCompanyLogo(request)
  }
  uploadStudentPicture(request: studentDetails): Promise<any> {
    return this.dataSource.uploadStudentPicture(request)
  }
  addNewPlacement(request: newCompanyDetials): Promise<any> {
    return this.dataSource.addNewPlacement(request)
  }
  deletePlacement(request: placementDetail): Promise<any> {
    return this.dataSource.deletePlacement(request)
  }
  getNewPlacement(auth: Auth): Promise<any> {
    return this.dataSource.getNewPlacement(auth)
  }
  getStudentPlacement(request: placementDetail): Promise<any> {
    return this.dataSource.getStudentPlacement(request)
  }
}
