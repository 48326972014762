import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface updateMilestoneSessionMentorUseCase {
  invoke(auth: Auth, student_id: any): Promise<any>
}

export class updateMilestoneSessionMentor implements updateMilestoneSessionMentorUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_id: any): Promise<any> {
    return this.repository.updateMilestoneSessionMentor(auth, student_id)
  }
}
