import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface UpdateBatchDataUseCase {
  invoke(auth: Auth, batch: string): Promise<any>
}

export class UpdateBatchData implements UpdateBatchDataUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch: string) {
    return this.repository.updateBatchData(auth, batch)
  }
}
