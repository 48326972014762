export default function NoEye({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9211 12.8646C10.7486 13.6084 9.38827 14.0022 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 7.99998C1.0738 6.44713 1.85486 5.02858 3.01378 3.95598L0.927776 1.87198L1.87111 0.92865L15.0704 14.1286L14.1271 15.0713L11.9204 12.8646H11.9211ZM3.95644 4.89998C3.05045 5.72371 2.41932 6.80584 2.14844 7.99998C2.35664 8.91095 2.77462 9.76075 3.36911 10.4817C3.9636 11.2027 4.71819 11.7749 5.57281 12.1529C6.42743 12.5308 7.35847 12.704 8.29184 12.6587C9.2252 12.6134 10.1351 12.3509 10.9491 11.892L9.59711 10.54C9.02157 10.9025 8.33991 11.0587 7.66393 10.9829C6.98796 10.9072 6.3578 10.6039 5.87682 10.1229C5.39584 9.64196 5.09259 9.0118 5.01682 8.33582C4.94104 7.65985 5.09723 6.97819 5.45978 6.40265L3.95644 4.89998ZM8.60911 9.55198L6.44778 7.39065C6.32915 7.69261 6.30123 8.02262 6.36745 8.34021C6.43366 8.65781 6.59112 8.94917 6.82052 9.17857C7.04992 9.40797 7.34129 9.56543 7.65888 9.63165C7.97647 9.69786 8.30649 9.66994 8.60844 9.55132L8.60911 9.55198ZM13.8711 11.0613L12.9171 10.108C13.3628 9.47285 13.68 8.75677 13.8511 7.99998C13.6699 7.20647 13.3293 6.45808 12.8499 5.80031C12.3705 5.14254 11.7623 4.58913 11.0624 4.17372C10.3624 3.7583 9.58531 3.48956 8.77826 3.38383C7.97123 3.27809 7.15111 3.33757 6.36778 3.55865L5.31578 2.50665C6.14711 2.17998 7.05311 1.99998 7.99978 1.99998C11.5944 1.99998 14.5851 4.58665 15.2124 7.99998C15.0082 9.11045 14.549 10.1584 13.8711 11.0613ZM7.81511 5.00532C8.23945 4.97909 8.66452 5.04336 9.06214 5.19386C9.45976 5.34436 9.82085 5.57766 10.1215 5.87828C10.4221 6.17891 10.6554 6.54 10.8059 6.93762C10.9564 7.33524 11.0207 7.76031 10.9944 8.18465L7.81444 5.00532H7.81511Z"
        fill="currentColor"
      />
    </svg>
  )
}
