import Button from "core/components/new/Button"
import Select from "core/components/new/Select"
import { capitalize } from "core/utils/string"
import { Fragment } from "react"

export default function Fields({
  fields,
  fieldTypes,
  onFieldChange,
  onDeleteField,
  isResponseField = false,
  isOptionField = false,
  responseId = undefined,
  optionId = undefined,
}: any) {
  return (
    <Fragment>
      {fields?.map((field: any, fieldId: number) => (
        <div key={fieldId} className="w-full flex flex-col items-start gap-6">
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-row justify-between">
              <Select
                className="basis-2/4 border border-new-neutral text-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent cursor-pointer"
                value={field.type || "text"}
                onChange={(e) => {
                  isResponseField
                    ? isOptionField
                      ? onFieldChange(e, responseId, optionId, fieldId, "type")
                      : onFieldChange(e, responseId, fieldId, "type")
                    : onFieldChange(e, fieldId, "type")
                }}
              >
                {fieldTypes.map((type: string, idx: number) => (
                  <option key={idx} value={type}>
                    {capitalize(type)}
                  </option>
                ))}
              </Select>
              <Button
                className="basis-1/4"
                outlined
                failure
                onClick={(e) => {
                  isResponseField ? onDeleteField(responseId, fieldId) : onDeleteField("Remove", fieldId)
                }}
              >
                Delete Field
              </Button>
            </div>
            {FieldView(field, fieldId, onFieldChange, isResponseField, isOptionField, responseId, optionId)}
            <div className="w-full h-px border border-dashed border-gray-400"></div>
          </div>
        </div>
      ))}
    </Fragment>
  )
}

function FieldView(
  field: any,
  fieldId: number,
  onFieldChange: Function,
  isResponseField?: boolean,
  isOptionField?: boolean,
  responseId?: number,
  optionId?: number
) {
  if (field.type === "text" || field.type === "description") {
    return (
      <textarea
        onChange={(e) => {
          isResponseField
            ? isOptionField
              ? onFieldChange(e, responseId, optionId, fieldId, "content")
              : onFieldChange(e, responseId, fieldId, "content")
            : onFieldChange(e, fieldId, "content")
        }}
        value={field.content}
        className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent overflow-auto text-new-netural"
      >
        {field.content}
      </textarea>
    )
  } else if (field.type === "code") {
    return (
      <textarea
        onChange={(e) => {
          isResponseField
            ? isOptionField
              ? onFieldChange(e, responseId, optionId, fieldId, "content")
              : onFieldChange(e, responseId, fieldId, "content")
            : onFieldChange(e, fieldId, "content")
        }}
        value={field.content}
        className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent cursor-pointer overflow-auto text-new-netural"
      >
        {field.content}
      </textarea>
    )
  } else if (field.type === "image") {
    return (
      <div className="flex flex-row items-start gap-4 text-new-netural">
        <div className="basis-2/5 h-[48px] border border-solid border-new-accent relative overflow-hidden inline-block rounded-sm shadow-sm">
          <div className="flex items-center justify-center h-full w-full text-new-neutral">
            <p className="p-0 text-[1rem]">Upload Image +</p>
          </div>
          <input
            className="opacity-0 absolute top-0 left-0 cursor-pointer w-full h-full"
            type="file"
            name="img"
            id="img"
            accept="image/*"
            onChange={(e) => {
              isResponseField
                ? isOptionField
                  ? onFieldChange(e, responseId, optionId, fieldId, "image")
                  : onFieldChange(e, responseId, fieldId, "image")
                : onFieldChange(e, fieldId, "image")
            }}
          />
        </div>
        <div className="w-full border border-solid border-new-neutral py-3 p-4 rounded-sm">
          <input
            type="text"
            placeholder="img.png"
            disabled
            value={field?.filename}
            className="p-0 m-0 w-full border-none"
          />
        </div>
      </div>
    )
  } else if (field.type === "document") {
    return (
      <div className="flex flex-row items-start gap-4 text-new-netural">
        <div className="basis-2/5 h-[48px] border border-solid border-new-accent relative overflow-hidden inline-block rounded-sm shadow-sm">
          <div className="flex items-center justify-center h-full w-full text-new-neutral">
            <p className="p-0 text-[1rem]">Upload Doc +</p>
          </div>
          <input
            className="opacity-0 absolute top-0 left-0 cursor-pointer w-full h-full"
            type="file"
            name="doc"
            id="file"
            accept=".pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => {
              isResponseField
                ? isOptionField
                  ? onFieldChange(e, responseId, optionId, fieldId, "document")
                  : onFieldChange(e, responseId, fieldId, "document")
                : onFieldChange(e, fieldId, "document")
            }}
          />
        </div>
        <div className="w-full border border-solid border-new-neutral py-3 p-4 rounded-sm">
          <input
            type="text"
            placeholder="mydoc.pdf"
            disabled
            value={field?.filename}
            className="p-0 m-0 w-full border-none"
          />
        </div>
      </div>
    )
  } else {
    return <div className="w-full p-2">No Field is selected!</div>
  }
}
