import { jobDetails } from "domain/model/Placement"
import { PlacementRepository } from "domain/repository/Student/PlacementRepository"

export interface ApplyForJobUseCase {
  invoke(request: jobDetails): Promise<any>
}

export class ApplyJobs implements ApplyForJobUseCase {
  private repository: PlacementRepository

  constructor(repository: PlacementRepository) {
    this.repository = repository
  }

  async invoke(request: jobDetails): Promise<any> {
    return await this.repository.applyForJob(request)
  }
}
