import { useEffect, useState } from "react"

export default function Options({
  options,
  multiple = false,
  responseId,
  optionSelected = null,
  handleOptionChange,
}: any) {
  const [currentOption, setCurrentOption] = useState<number>(-1)

  useEffect(() => {
    setCurrentOption(optionSelected)
  }, [optionSelected])

  return (
    <div className="flex flex-col gap-4 border border-new-neutral-light p-4 rounded-sm bg-new-editor-dark-600">
      {Object.values(options).map((option: any, index: number) => {
        return (
          <div key={index} className="w-full flex gap-2 justify-between">
            <div className="flex gap-2 mr-4">
              <p>{index + 1}.</p>
              <div>
                {option?.map((field: any, index: number) => {
                  if (field?.type === "text") {
                    return (
                      <p key={index} className="text-justify">
                        {field.content}
                      </p>
                    )
                  } else if (field.type === "image") {
                    return (
                      <img
                        key={index}
                        src={field?.content}
                        alt="img"
                        title={field?.filename}
                        onClick={() => window.open(field?.content)}
                        className="cursor-pointer rounded-sm"
                      />
                    )
                  } else if (field.type === "document") {
                    return (
                      <div
                        key={index}
                        title="Click to open the document"
                        className="p-2 text-pretty text-sm cursor-pointer text-blue-500 bg-new-editor-dark-600 rounded-sm"
                        onClick={() => window.open(field?.content)}
                      >
                        {"📄" + field?.filename}
                      </div>
                    )
                  } else if (field.type === "code") {
                    return (
                      <pre key={index} className="p-2 bg-new-editor-dark-600 rounded-sm">
                        {field.content}
                      </pre>
                    )
                  }
                  return <p className="italic">Empty option</p>
                })}
              </div>
            </div>
            {multiple ? (
              <input
                type="checkbox"
                name="answer"
                className="bg-opacity-0 px-2 py-2 border-white bg-new-editor-dark-300"
                checked={optionSelected?.[responseId]?.[index]}
                value={index}
                onChange={(e) => handleOptionChange(e, responseId)}
              />
            ) : (
              <input
                type="checkbox"
                name="answer"
                className="bg-opacity-0 px-2 py-2 border-white bg-new-editor-dark-300"
                checked={currentOption === index ? true : false}
                value={index}
                onChange={(e) => {
                  handleOptionChange(e)
                  setCurrentOption(index)
                }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}
