import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface GetAllInstructorDetailsUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllInstructorDetails implements GetAllInstructorDetailsUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllInstructorDetails(auth)
  }
}
