import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface GetQuestionListUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetQuestionList implements GetQuestionListUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getQuestionList(auth)
  }
}
