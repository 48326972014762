import React, { useEffect } from "react"
import Button from "core/components/new/Button"
import Fields from "./Fields"
import { AddIcon, StarFilled } from "core/constants/svgs"
import { set } from "date-fns"
import { cn } from "core/lib/utils"

export default function SubmissionTab({
  TABS,
  tab,
  activeTab,
  allQuestions,
  handleTabChange,
  selectedQuestion,
  questionSize,
  handleSelectedQuestion,
  handleOpenSubmissionTab,
  handleFeedbackChange,
  feedbackResponses,
  questionName,
  questionFields,
  handleQuestionFieldChange,
  handleQuestionFields,
  addQuestion,
  handleAddQuestion,
  handleOpenViewQuestion,
  openViewQuestion,
  handleRatingChange,
  handleSaveChanges,
  session_id,
  email_id,
  handleDiscardChanges,
  fileResponse,
  handleDeleteFile,
}: any) {
  const [rating, setRating] = React.useState(feedbackResponses?.[questionName]?.rating || 0)
  React.useEffect(() => {
    const newRating =
      feedbackResponses && feedbackResponses[questionName]?.rating ? feedbackResponses[questionName]?.rating : 0

    setRating(newRating)
  }, [activeTab, selectedQuestion, feedbackResponses, questionName])

  const isDocumentQuestion =
    TABS[activeTab] === "Coding" &&
    allQuestions[TABS[activeTab]]?.[selectedQuestion]?.problem_name === "Document question"

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[#999999] bg-opacity-50 z-50">
      <div className="flex w-[1152px] h-screen justify-center">
        {/* Left Part */}
        <div className="w-1/2 h-full bg-white p-6 rounded-lg space-y-4">
          <div className="flex justify-between items-baseline">
            <h3>Submission Assignment</h3>
            <Button outlined failure className="w-fit" onClick={handleOpenSubmissionTab}>
              Go Back
            </Button>
          </div>
          <div className="flex">
            {TABS.map((item: any, index: number) => {
              return (
                <div key={index} className="w-full">
                  <button
                    onClick={() => handleTabChange(index)}
                    className={`px-4 py-2 border border-[#ADADAD] w-full ${tab === index ? "bg-[#312E81] text-[#FFF]" : "bg-[#FFF] text-[#ADADAD]"}`}
                  >
                    {item}
                  </button>
                </div>
              )
            })}
          </div>

          {questionSize > 0 ? (
            <div>
              <div className="flex justify-between py-2">
                <h3>Question</h3>
                <button className="underline" onClick={handleOpenViewQuestion}>
                  View Question
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                {activeTab === 3
                  ? allQuestions[TABS[activeTab]]?.[0]?.["response"]?.map((question: any, i: number) => (
                      <button
                        key={i}
                        className={`flex items-center justify-center w-[40px] h-[40px] px-3 py-2  text-new-neutral-light border border-new-editor-dark-500 rounded-md ${selectedQuestion === i && "text-[#00C22B] outline outline-[#00C22B]"}`}
                        onClick={() => handleSelectedQuestion(i)}
                      >
                        {i + 1}
                      </button>
                    ))
                  : allQuestions[TABS[activeTab]]?.map((question: any, i: number) => {
                      return (
                        <button
                          key={i}
                          className={` flex items-center justify-center w-[40px] h-[40px] px-3 py-2  text-new-neutral-light border border-new-editor-dark-500 rounded-md ${selectedQuestion === i && "text-[#00C22B] border-collapse outline outline-[#00C22B]"} ${isDocumentQuestion && "cursor-not-allowed opacity-50"}`}
                          onClick={() => question.problem_name !== "Document question" && handleSelectedQuestion(i)}
                        >
                          {i + 1}
                        </button>
                      )
                    })}
              </div>
              {/* Scrollable container */}
              <div className="h-96 overflow-y-auto my-4 custom-scrollbar-2 pr-2">
                <div className="space-y-6">
                  <div className="space-y-2">
                    <h3>Response Rating</h3>
                    <div className="flex">
                      {[...Array(5)].map((_, index) => {
                        const currentRating = index + 1
                        return (
                          <button
                            key={index}
                            className={`${isDocumentQuestion && "cursor-not-allowed opacity-50"}`}
                            onClick={(e) => {
                              if (!isDocumentQuestion) {
                                setRating(currentRating)
                                handleRatingChange(questionName, currentRating)
                              }
                            }}
                          >
                            <StarFilled className={cn(currentRating <= rating ? "text-[#ffc107]" : "text-[#e4e5e9]")} />
                          </button>
                        )
                      })}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <h3>Question Marks</h3>
                    <input
                      type="text"
                      name="marks"
                      className={`border border-[#ADADAD] rounded-md w-full ${
                        isDocumentQuestion && "cursor-not-allowed opacity-50"
                      }`}
                      disabled={isDocumentQuestion}
                      value={feedbackResponses?.[questionName]?.marks || ""}
                      onChange={(e) => {
                        const newMarks = parseInt(e.target.value)

                        // Check if the input is empty or not a number
                        if (e.target.value === "" || (!isNaN(newMarks) && newMarks >= -1)) {
                          let maxMark = 0
                          if (activeTab === 0 || activeTab === 1 || activeTab === 2) {
                            maxMark = allQuestions[TABS[activeTab]]?.[selectedQuestion]?.maxMarks
                          } else {
                            maxMark = allQuestions[TABS[activeTab]]?.[0]?.response?.[selectedQuestion]?.maxMarks
                          }

                          if (e.target.value === "" || newMarks <= maxMark) {
                            handleFeedbackChange(questionName, TABS[activeTab], e)
                          } else {
                            alert("Marks cannot exceed max mark for the question and must be greater than zero.")
                          }
                        } else {
                          alert("Please enter a valid number.")
                        }
                      }}
                    />
                  </div>

                  <div className="space-y-2">
                    <h3>Response Feedback</h3>
                    <textarea
                      className={`border border-[#ADADAD] rounded-md w-full h-32 resize-none overflow-auto ${
                        isDocumentQuestion && "cursor-not-allowed opacity-50"
                      }`}
                      name="feedback_response"
                      disabled={isDocumentQuestion}
                      value={feedbackResponses?.[questionName]?.feedback_response || ""}
                      placeholder="Your Feedback Response"
                      onChange={(e) => !isDocumentQuestion && handleFeedbackChange(questionName, TABS[activeTab], e)}
                    />
                  </div>
                  {fileResponse[TABS[activeTab]]?.[questionName]?.length > 0 && (
                    <div className="flex  space-y-4 flex-col w-full">
                      {fileResponse[TABS[activeTab]][questionName].map((response: any, index: number) => (
                        <div key={index} className="border w-full p-2 rounded">
                          <div className="flex w-full justify-between">
                            <h4 className="text-base py-2 px-4 border font-semibold capitalize rounded">
                              {response.type}
                            </h4>
                            <Button
                              className="basis-1/4 rounded"
                              outlined
                              failure
                              onClick={() =>
                                handleDeleteFile(
                                  questionName,
                                  response.File,
                                  activeTab,
                                  response.type,
                                  session_id,
                                  email_id
                                )
                              }
                            >
                              Delete File
                            </Button>
                          </div>
                          <div className="mt-4 w-full">
                            {response.type === "image" && (
                              <img
                                src={response.File}
                                alt={response.content}
                                className="w-full h-[350px] object-contain rounded"
                              />
                            )}
                            {response.type === "document" && (
                              <div className="flex justify-center w-full border py-2 px-4 rounded">
                                <a
                                  href={response.File}
                                  className="text-blue-500 hover:underline text-center"
                                  target="_blank"
                                >
                                  Open Document
                                </a>
                              </div>
                            )}
                            {response.type === "code" && (
                              <pre className="bg-[#F5F5F5] p-2 overflow-x-auto rounded">{response.content}</pre>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {addQuestion && questionFields?.[TABS[activeTab]] && (
                    <Fields
                      fields={questionFields[TABS[activeTab]]}
                      questionType={TABS[activeTab]}
                      selectedQuestion={
                        TABS[activeTab] === "Coding"
                          ? allQuestions[TABS[activeTab]][selectedQuestion].topic
                          : TABS[activeTab] === "Case Study"
                            ? allQuestions[TABS[activeTab]][0].id +
                              "_" +
                              allQuestions[TABS[activeTab]][0]["response"][selectedQuestion].id
                            : allQuestions[TABS[activeTab]][selectedQuestion].id
                      }
                      fieldTypes={["image", "document", "code"]}
                      onFieldChange={handleQuestionFieldChange}
                      onDeleteField={handleQuestionFields}
                    />
                  )}
                  <button
                    className={`inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral`}
                    onClick={(e) => {
                      handleAddQuestion()
                      handleQuestionFields(
                        "Add",
                        TABS[activeTab],
                        TABS[activeTab] === "Coding"
                          ? allQuestions[TABS[activeTab]][selectedQuestion].topic
                          : TABS[activeTab] === "Case Study"
                            ? allQuestions[TABS[activeTab]][0].id +
                              "_" +
                              allQuestions[TABS[activeTab]][0]["response"][selectedQuestion].id
                            : allQuestions[TABS[activeTab]][selectedQuestion].id
                      )
                    }}
                  >
                    <AddIcon className="h-4 w-4" />
                    <p>Add Feedback File</p>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="flex w-full h-full text-new-accent justify-center items-center">No Data Available</h1>
          )}
          <div className="flex p-4 justify-center items-center gap-14 bg-[#F5F5F5] rounded-sm">
            <div className="w-5/12">
              {" "}
              <h3>Save Current Changes :</h3>
            </div>
            <div className="flex justify-between gap-4 w-7/12">
              {" "}
              <Button className="py-4 px-8 rounded" outlined failure onClick={() => handleDiscardChanges()}>
                Discard Changes
              </Button>
              <Button className="rounded" onClick={() => handleSaveChanges(session_id, email_id)}>
                {" "}
                Save Changes
              </Button>
            </div>
          </div>
        </div>

        {/* Right Part */}
        {openViewQuestion && (
          <div className="w-1/2 bg-new-editor-dark-700 p-4 space-y-2 overflow-y-auto">
            <div className="flex p-3 bg-[#303030] text-new-neutral-light border-new-editor-dark-500 rounded">
              {activeTab === 0 && (
                <h3 className="text-new-neutral-light p-2">
                  {selectedQuestion + 1}. {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["problem_name"]}
                </h3>
              )}
              {activeTab === 1 && (
                <h3>
                  {selectedQuestion + 1}. {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["name"]}
                </h3>
              )}
              {activeTab === 2 && (
                <h3>
                  {selectedQuestion + 1}. {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.questionTitle}
                </h3>
              )}
              {activeTab === 3 && (
                <h3>{allQuestions[TABS[activeTab]]?.[0]?.["response"]?.[selectedQuestion]?.subTitle}</h3>
              )}
            </div>
            <div className="p-4 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
              <h4 className="py-2 text-new-neutral-light text-lg">Problem Statement</h4>
              {activeTab === 0 && (
                <div
                  className="text-new-neutral-light"
                  dangerouslySetInnerHTML={{
                    __html: allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["question_details"],
                  }}
                ></div>
              )}

              {activeTab === 1 && (
                <>
                  <p className="text-new-neutral-light">
                    {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.question?.[0]?.content}
                  </p>

                  {Object.entries(allQuestions[TABS[activeTab]]?.[selectedQuestion]?.options).map(
                    ([key, option]: [string, any]) => (
                      <div key={key} className="flex items-baseline py-2">
                        <span className="text-new-neutral-light mr-2">{String.fromCharCode(64 + parseInt(key))}.</span>
                        {option[0]?.type === "code" ? (
                          <pre
                            className="text-new-neutral-light"
                            dangerouslySetInnerHTML={{
                              __html: option[0]?.content,
                            }}
                            key={`${key}-option`}
                          ></pre>
                        ) : (
                          <p className="text-new-neutral-light">{option[0]?.content}</p>
                        )}
                      </div>
                    )
                  )}
                </>
              )}

              {activeTab === 2 && (
                <div>
                  {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.question.map((item: any, index: number) => (
                    <div key={index}>
                      {item.type === "text" ? (
                        <p className="text-new-neutral-light">{item.content}</p>
                      ) : item.type === "image" ? (
                        <img src={item.content[0]} alt="Question" />
                      ) : (
                        <pre
                          className="text-new-neutral-light"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></pre>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {activeTab === 3 && (
                <div>
                  {allQuestions[TABS[activeTab]]?.[0]?.["response"]?.map((question: any, idx: any) =>
                    idx === selectedQuestion ? (
                      <div key={idx} className="mb-4">
                        <h3 className="mb-2">{question.content}</h3>
                        {question.questionType === "MCQ" && (
                          <div>
                            {Object.keys(question.options).map((key) => (
                              <div key={key} className="flex items-baseline py-2">
                                <span className="text-new-neutral-light mr-2">
                                  {String.fromCharCode(65 + parseInt(key))}.{" "}
                                </span>
                                {question.options[key][0].type === "code" && (
                                  <pre className="bg-gray-200 rounded-md">{question.options[key][0].content}</pre>
                                )}
                                {question.options[key][0].type === "image" && (
                                  <img
                                    src={question.options[key][0].content}
                                    alt={`Option ${String.fromCharCode(65 + parseInt(key))}`}
                                    className="max-w-full h-auto rounded-md"
                                  />
                                )}
                                {question.options[key][0].type === "text" && (
                                  <p className="text-new-neutral-light">{question.options[key][0].content}</p>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : null
                  )}
                </div>
              )}
            </div>
            <div className="p-4 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
              Student Response
              {activeTab === 0 &&
                (allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["user_answer"]?.length > 0 ? (
                  <pre
                    className="bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto h-full"
                    dangerouslySetInnerHTML={{
                      __html: allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["user_answer"],
                    }}
                  ></pre>
                ) : (
                  <p className="flex bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto h-full items-center justify-center">
                    No Response
                  </p>
                ))}
              {activeTab === 2 && (
                <div className="flex flex-wrap bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto h-full">
                  <p>{allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["user_answer"]["answer"]}</p>
                </div>
              )}
              {activeTab === 1 &&
                (() => {
                  const usersolution = allQuestions[TABS[activeTab]]?.[selectedQuestion]?.user_answer?.answer
                  return (
                    <div className="flex flex-wrap bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto h-full">
                      <span className="text-new-neutral-light mr-2">
                        {String.fromCharCode(64 + parseInt(usersolution))}.
                      </span>
                      <p className="text-new-neutral-light">
                        {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.options?.[usersolution]?.[0]?.content}
                      </p>
                    </div>
                  )
                })()}
              {activeTab === 3 && (
                <div className="flex flex-wrap bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2">
                  {typeof allQuestions[TABS[activeTab]]?.[0]?.["user_answer"]?.["answer"]?.[selectedQuestion] ===
                  "string" ? (
                    <p className="text-new-neutral-light">
                      {allQuestions[TABS[activeTab]]?.[0]?.["user_answer"]?.["answer"]?.[selectedQuestion]}
                    </p>
                  ) : (
                    Object.entries(
                      allQuestions[TABS[activeTab]]?.[0]?.["user_answer"]?.["answer"]?.[selectedQuestion] || {}
                    ).map(([key, usersolution]: [string, any], idx: number) => (
                      <div key={idx} className=" flex mb-2">
                        <span className="text-new-neutral-light mr-2">{String.fromCharCode(64 + parseInt(key))}.</span>
                        {usersolution === true && (
                          <p className="flex flex-col-reverse text-new-neutral-light">
                            {
                              allQuestions[TABS[activeTab]]?.[0]?.["response"]?.[selectedQuestion]?.["options"]?.[
                                key
                              ]?.[0]?.["content"]
                            }
                          </p>
                        )}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
