import Input from "core/components/Input"
import { Button } from "core/components/v2/Button"
import StandardSelect from "core/components/v2/StandardSelect"
import useReferViewModel from "./ReferViewModel"
import { useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import LineBottom from "core/components/payment-method/LineBottom"
import LineBottomRot from "assets/svgs/LineBottomRot"
import BosscoderReferLogo from "assets/svgs/BosscoderReferLogo"
import Toast from "core/components/Toast"
import Spinner from "core/components/Spinner"
import BosscoderLogoDoted from "assets/svgs/BosscoderLogoDoted"
import PhoneInput from "react-phone-number-input"

const ReferLinkView = () => {
  const {
    formData,
    getReferrerData,
    handleChange2,
    handlePhoneNumber,
    handleRealationOptions,
    handleCourseOptions,
    handleSubmitLink,
    toast,
    loading,
    changeToastVisibility,
    courseOptions,
  } = useReferViewModel()
  const { email } = useParams()
  useEffect(() => {
    console.log(email)
    getReferrerData(email || "")
  }, [])
  return (
    <div className="flex justify-center items-center min-h-screen ">
      <div className="flex justify-between items-center w-full h-[100vh] bg-[#fff]">
        <div className="relative w-1/3 h-full bg-[#333]">
          <div className="absolute top-[36px] left-[36px]">
            <BosscoderReferLogo />
          </div>
          <div className="absolute top-[20%] -right-[50%] min-[1920px]:-right-[40%]">
            <BosscoderLogoDoted />
          </div>
          <div className="absolute bottom-0">
            <LineBottomRot />
          </div>
          <div className="absolute bottom-0 -left-[80px]">
            <LineBottomRot />
          </div>
        </div>
        <div className="relative overflow-hidden flex justify-center py-[40px] items-center w-2/3 h-full bg-white rounded-[16px]">
          <div className="absolute top-0 right-0">
            <LoginPageGradient className={"#C9E5F5"} />
          </div>

          <div className="absolute top-0 -right-[60px]">
            <LoginPageGradient className={"#C9E5F5"} />
          </div>

          <div className="absolute bottom-0 left-0 scale-y-[-1] scale-x-[-1] w-[42%]">
            <div className="absolute">
              <LoginPageGradient className={"#C9E5F5"} />
            </div>
            <div className="absolute left-[60px]">
              <LoginPageGradient className={"#C9E5F5"} />
            </div>
          </div>
          <div className="relative z-10 max-2xl:overflow-y-scroll w-[25vw] max-2xl:h-[90vh] h-[660px] bg-white p-[24px] rounded-[8px] border-[1px] border-[#D3D3D3]">
            {formData?.yourName ? (
              <>
                <p className="text-[14px] font-[600]">Congratulations!</p>
                <p className="text-[12px] font-[500] mb-[16px]">
                  {formData?.yourName} has referred you to Bosscoder Academy!{" "}
                </p>
              </>
            ) : (
              <div className="mb-[16px]">
                <p className="text-[14px] font-[600]">Welcome To Booscoder Academy</p>
              </div>
            )}
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Name
              <span className="text-red-600">*</span>
            </div>

            <Input
              placeholder="Type here"
              type="text"
              name="referName"
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              value={formData.referName}
              onChange={handleChange2}
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Email
              <span className="text-red-600">*</span>
            </div>
            <Input
              placeholder="Type here"
              type="text"
              name="referEmail"
              value={formData.referEmail}
              onChange={handleChange2}
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[3px]">
              Phone Number
              <span className="text-red-600">*</span>
            </div>
            <PhoneInput
              defaultCountry="IN"
              value={formData.referNumber}
              onChange={handlePhoneNumber}
              placeholder="Phone Number"
              limitMaxLength
              className="mb-[16px]"
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Name of the Referrer
              <span className="text-red-600">*</span>
            </div>
            <Input
              placeholder="Type here"
              type="text"
              name="yourName"
              value={formData.yourName}
              onChange={handleChange2}
              className="font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            />

            <div className="my-[16px] flex flex-col justify-between gap-0 font-semibold">
              <div className="text-new-solid-black text-[14px] font-normal">
                Relationship<span className="text-red-600">*</span>
              </div>
              <StandardSelect
                dimensions="w-full"
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="relation"
                value={formData.relation}
                onChange={handleRealationOptions}
                options={["Friend", "Relative", "Other"]}
                required
              ></StandardSelect>
            </div>
            <div className="my-[20px] flex flex-col justify-between gap-0 font-semibold">
              <div className="text-new-solid-black text-[14px] font-normal">
                Course Type<span className="text-red-600">*</span>
              </div>
              <StandardSelect
                dimensions="w-full"
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="course"
                value={formData.course}
                onChange={handleCourseOptions}
                options={courseOptions}
                required
              ></StandardSelect>
            </div>

            <div className="flex flex-row justify-between my-[16px]">
              <label className="flex items-start">
                <span className="font-['Inter'] w-[259px] max-2xl:w-[200px] text-[10px] text-[#E00000] font-[500]">
                  ** You will receive a call from our career expert within 24 hours.
                </span>
              </label>
            </div>
            <Button
              onClick={handleSubmitLink}
              className="w-full h-[35px]"
              size="sm"
              border={"thin"}
              variant={"primary"}
              disabled={
                loading ||
                !formData.referName ||
                !formData.referEmail ||
                !formData.referNumber ||
                !formData.relation ||
                !formData.course
              }
            >
              {loading ? <Spinner small={true} /> : "Submit"}
            </Button>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}

export default ReferLinkView
