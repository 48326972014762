import React, { Component } from "react"
import GenerateInvoiceViewModel from "./GenerateMentorInvoiceViewModel"
import DashboardLayout from "core/layouts/DashboardLayout"
import { isEmpty } from "core/utils/misc"
import InstructorSheet from "./components/MentorSheet"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import MentorsViewModel from "../MentorSessions/MentorsViewModel"
import Button from "core/components/new/Button"
import StandaredSelect from "core/components/StandaredSelect"

export default function GenerateMentorInvoiceView() {
  const { allMentorsList, fetchAllMentorList } = GenerateInvoiceViewModel()
  const {
    auth,
    mentorEmail,
    mentorList,
    toast,
    loading,
    handleSearchByMentor,
    handleSearch,
    fetchMentorList,
    changeToastVisibility,
  } = MentorsViewModel()

  React.useEffect(() => {
    fetchMentorList("")
    fetchAllMentorList()
  }, [auth])

  const [isDuplicateOpen, setIsDuplicateOpen] = React.useState(false)
  const handleDuplicateClose = () => {
    setIsDuplicateOpen(false)
  }
  if (isEmpty(mentorList)) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <div className="space-y-6 p-6">
        <div className="flex items-end gap-4">
          <select className="h-9 w-[209px]" onChange={handleSearchByMentor} value={mentorEmail}>
            <option value="">Mentor</option>
            {allMentorsList?.map((instructor: any, i: number) => (
              <option value={instructor?.email} key={i}>
                {instructor?.name}
              </option>
            ))}
          </select>
        </div>
        {!isEmpty(mentorList) ? (
          <div className="mx-4 flex flex-col space-y-6">
            <div className="space-y-6">
              {!isEmpty(mentorList?.all_mentors) ? (
                mentorList?.all_mentors?.map((data: any, i: number) => <InstructorSheet key={i} data={data} />)
              ) : (
                <div>
                  <h4>No Data Found</h4>
                </div>
              )}{" "}
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
