import ButtonGroup from "core/components/ButtonGroup"
import Toast from "core/components/Toast"
import { STR_CREATE_TEST, STR_SCHEDULE_TEST } from "core/constants/strings"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import CreateTest from "./components/CreateTest"
import ScheduleTest from "./components/ScheduleTest"
import useTestDashboardViewModel from "./TestDashboardViewModel"

export default function TestDashboardView() {
  const tableHeaders = [
    "Name",
    "Date",
    "Time",
    // 'Update Test',
    "Delete Test",
  ]

  const {
    toast,
    batchList,
    activeButtonType,
    createTest,
    selectBatch,
    scheduleTest,
    batch,
    weeklyTest,
    scheduleWeeklyTests,
    isOpen,
    loading,
    open,
    refreshed,
    changeToastVisibility,
    changeActiveButtonType,
    handleCreateTest,
    handleScheduleTest,
    setSelectBatch,
    handleAddBatch,
    handleBatchChange,
    removeItem,
    getScheduleWeeklyTests,
    getAllTest,
    createWeeklyTests,
    getBatches,
    scheduleTests,
    onChangeTestId,
    handleOpenModel,
    handleCloseModel,
    updateScheduleTest,
    deleteScheduleTest,
    deleteWeeklyTests,
    openDeleteModel,
    closeDeleteModel,
  } = useTestDashboardViewModel()

  React.useEffect(() => {
    if (refreshed) {
      getScheduleWeeklyTests()
      getAllTest()
      getBatches()
    }
  }, [refreshed])

  return (
    <DashboardLayout isLoading={loading}>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="p-6">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Test Series Dashboard</h1>
          <p className="font-medium">Handle all data of test Series</p>
        </div>

        <div className="mt-10">
          <ButtonGroup
            buttons={[STR_CREATE_TEST, STR_SCHEDULE_TEST]}
            active={activeButtonType}
            onChange={changeActiveButtonType}
          />
          {activeButtonType === 0 ? (
            <CreateTest
              createTest={createTest}
              handleChange={handleCreateTest}
              createWeeklyTests={createWeeklyTests}
              tableHeaders={tableHeaders}
              weeklyTest={weeklyTest}
              deleteWeeklyTests={deleteWeeklyTests}
            />
          ) : (
            <ScheduleTest
              selectBatch={selectBatch}
              handleAddBatch={handleAddBatch}
              handleScheduleTest={handleScheduleTest}
              scheduleTest={scheduleTest}
              batch={batch}
              batchList={batchList}
              handleBatchChange={handleBatchChange}
              removeItem={removeItem}
              setSelectBatch={setSelectBatch}
              scheduleTests={scheduleTests}
              weeklyTest={weeklyTest}
              onChangeTestId={onChangeTestId}
              scheduleWeeklyTests={scheduleWeeklyTests}
              handleOpenModel={handleOpenModel}
              handleCloseModel={handleCloseModel}
              isOpen={isOpen}
              updateScheduleTest={updateScheduleTest}
              deleteScheduleTest={deleteScheduleTest}
              openDeleteModel={openDeleteModel}
              closeDeleteModel={closeDeleteModel}
              open={open}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
