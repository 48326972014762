import { AvailabilityDataSource } from "data/dataSource/Mentor/AvailabilityDataSource"
import { Auth } from "domain/model/Auth"
import { AvailabilityRepository } from "domain/repository/Mentor/AvailabilityRepository"

export class AvailabilityRepositoryImpl implements AvailabilityRepository {
  private dataSource: AvailabilityDataSource

  constructor(dataSource: AvailabilityDataSource) {
    this.dataSource = dataSource
  }

  async getAvailability(auth: Auth) {
    return await this.dataSource.getAvailability(auth)
  }

  async updateAvailability(auth: Auth, data: any) {
    return await this.dataSource.updateAvailability(auth, data)
  }

  async blockDates(auth: Auth, date: any) {
    return await this.dataSource.blockDates(auth, date)
  }

  async getGoalSettingAvailability(auth: Auth) {
    return await this.dataSource.getGoalSettingAvailability(auth)
  }

  async updateGoalSettingAvailability(auth: Auth, data: any) {
    return await this.dataSource.updateGoalSettingAvailability(auth, data)
  }

  async blockGoalSettingDates(auth: Auth, data: any) {
    return await this.dataSource.blockGoalSettingDates(auth, data)
  }
}
