import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface DeleteContestFeedbackFileUseCase {
  invoke(
    auth: Auth,
    contest_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any>
}

export default class DeleteContestFeedbackFile implements DeleteContestFeedbackFileUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    contest_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ) {
    return await this.repository.deleteContestFeedbackFile(auth, contest_id, student_email, fileurl, questionName, type)
  }
}
