import React from "react"

function MentorSession() {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.70796 2.17745C7.73673 2.12518 7.77901 2.0816 7.83037 2.05125C7.88173 2.02089 7.9403 2.00488 7.99996 2.00488C8.05962 2.00488 8.11818 2.02089 8.16955 2.05125C8.22091 2.0816 8.26318 2.12518 8.29196 2.17745L10.26 5.91345C10.3069 5.99995 10.3724 6.075 10.4518 6.13319C10.5311 6.19138 10.6224 6.23128 10.719 6.25002C10.8156 6.26876 10.9152 6.26587 11.0106 6.24156C11.106 6.21726 11.1948 6.17214 11.2706 6.10945L14.122 3.66678C14.1767 3.62226 14.2441 3.59626 14.3146 3.59251C14.3851 3.58877 14.4549 3.60748 14.514 3.64594C14.5732 3.68441 14.6186 3.74065 14.6437 3.80657C14.6689 3.87249 14.6725 3.94469 14.654 4.01278L12.7646 10.8434C12.7261 10.9832 12.643 11.1066 12.528 11.1949C12.413 11.2832 12.2723 11.3316 12.1273 11.3328H3.87329C3.72818 11.3318 3.58736 11.2834 3.47222 11.1951C3.35707 11.1068 3.27389 10.9833 3.23529 10.8434L1.34662 4.01345C1.32812 3.94536 1.3317 3.87316 1.35685 3.80724C1.382 3.74132 1.42741 3.68508 1.48656 3.64661C1.5457 3.60814 1.61553 3.58944 1.68598 3.59318C1.75644 3.59692 1.82389 3.62293 1.87862 3.66745L4.72929 6.11011C4.80516 6.17281 4.89396 6.21793 4.98933 6.24223C5.0847 6.26654 5.18427 6.26942 5.28089 6.25069C5.37751 6.23195 5.46878 6.19205 5.54815 6.13386C5.62752 6.07567 5.69303 6.00062 5.73996 5.91411L7.70796 2.17745Z"
          stroke="#1C4974"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M3.33301 14H12.6663" stroke="#1C4974" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  )
}

export default MentorSession
