import { DeleteSSMCall } from "domain/model/DeleteSSMCall"
import { DeleteSSMCallRepository } from "domain/repository/Admin/DeleteSSMCallRepository"

export interface DeleteSSMCallUseCase {
  invoke(deleteSSMCall: DeleteSSMCall): Promise<any>
}

export class DeleteSSMCalls implements DeleteSSMCallUseCase {
  private repository: DeleteSSMCallRepository

  constructor(repository: DeleteSSMCallRepository) {
    this.repository = repository
  }

  async invoke(deleteSSMCall: DeleteSSMCall) {
    return await this.repository.deleteSSMCall(deleteSSMCall)
  }
}
