import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Student/SessionRepository"

export interface GetAllSessionsUseCase {
  invoke(auth: Auth, timestamp: number): Promise<any>
}

export class GetAllSessions implements GetAllSessionsUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, timestamp: number) {
    return this.repository.getAllSessions(auth, timestamp)
  }
}
