import React, { useState, useEffect } from "react"
import { AddIcon } from "core/constants/svgs"
import DashboardLayout from "core/layouts/DashboardLayout"
import PlacementCard from "./components/PlacementCard"
import usePlacementViewModelAdmin from "./PlacementViewModel"
import Input from "core/components/new/Input"
import ConfirmationPopUp from "./components/ConfirmationPopUp"
import ViewPlacementPopUp from "./components/ViewPlacementPopup"
import Toast from "core/components/Toast"

export default function PlacementView() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [placement_id, setPlacementId] = useState<string>("")
  const [placementIdForPopup, setPlacementIdForPopup] = useState<string | null>(null)

  const {
    toast,
    searchPlacement,
    filteredPlacementList,
    handleDeletePlacement,
    changeToastVisibility,
    handleSearchPlacementChange,
    redirectToPlacement,
    getNewPlacement,
  } = usePlacementViewModelAdmin()

  const togglePopupOpen = (placementId: string) => {
    setPlacementIdForPopup(placementIdForPopup === placementId ? null : placementId)
  }
  useEffect(() => {
    getNewPlacement()
  }, [])
  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          placement_id={placement_id}
          handleDeletePlacement={handleDeletePlacement}
          heading="Are you sure you want to delete this placement?"
        />
      )}
      <Toast data={toast} onclick={() => changeToastVisibility(false)} />
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-1">
          <h2 className="text-new-accent">Placement</h2>
          <p>Add Recent Placement of students from here</p>
        </div>
        <button
          onClick={() => redirectToPlacement("add")}
          className="inline-flex w-full items-center justify-center gap-4 rounded-sm py-12 text-new-neutral shadow-[0px_0px_7px_0px_rgba(0,0,0,0.10)]"
        >
          <AddIcon className="h-6 w-3" />
          <h3>Add Placement</h3>
        </button>
        <Input
          value={searchPlacement}
          onChange={handleSearchPlacementChange}
          id="student_name"
          label="Student Name"
          type="text"
          placeholder="Type your keyword here!"
          containerClass="max-w-[400px]"
        />
        {filteredPlacementList.length > 0 ? (
          filteredPlacementList.map((data: any, index: number) => (
            <React.Fragment key={index}>
              <PlacementCard
                data={data}
                onEdit={() => redirectToPlacement("edit/" + data?.id)}
                onDelete={() => {
                  setIsOpen(true)
                  setPlacementId(data?.id)
                }}
                onView={() => togglePopupOpen(data.id)}
              />
              {placementIdForPopup === data.id && (
                <ViewPlacementPopUp
                  open={true}
                  bg="fff"
                  name={data.name}
                  student_pic={data.profile_picture}
                  company_logo={data.company_logo}
                  yoe={data.yoe}
                  position={data.position}
                  testimonial={data.testimonial}
                  onClose={() => togglePopupOpen(data.id)}
                />
              )}
            </React.Fragment>
          ))
        ) : (
          <div className="flex h-[100px] items-center justify-center gap-4">
            <h3 className="text-new-neutral-light">No Placements Found!</h3>
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}
