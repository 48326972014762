import { enableInstructors } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface EnableInstructorsUseCase {
  invoke(enabled: enableInstructors): Promise<any>
}

export class EnableInstructors implements EnableInstructorsUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(enabled: enableInstructors): Promise<any> {
    return await this.repository.enableInstructors(enabled)
  }
}
