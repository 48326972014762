import { cn } from "core/lib/utils"

export default function TabGroup({ buttons, active, onChange, ...props }: any) {
  return (
    <div className="inline-flex rounded-[4px] gap-[10px] ">
      {buttons.map((button: any, i: number) => (
        <button
          {...props}
          id={button}
          key={i}
          className={cn(
            "flex rounded-[4px] py-[6px] px-[16px] text-[14px] font-[500] border-[1px]",
            active === i
              ? i === 0
                ? "bg-[#001665] text-[#fff] border-[#001665] shadow-[-1px_1px_2px_0px_rgba(34,41,48,0.05)]"
                : "bg-[#DC2626] text-[#fff] border-[#DC2626]"
              : i === 0
                ? "hover:border-[#001665] hover:text-[#fff] hover:bg-[#001665]"
                : "text-[#DC2626] border-[#DC2626] hover:border-[#DC2626] hover:text-[#fff] hover:bg-[#DC2626]"
          )}
          onClick={() => onChange(i)}
        >
          {button}
        </button>
      ))}
    </div>
  )
}
