import {
  API_V4_CREATE_COMPANY,
  API_V4_DELETE_JOB,
  API_V4_GET_JOB_APPLICATIONS,
  API_V4_SET_APPLICATION_STATUS,
  API_V4_ADMIN,
  STR_CREATE_JOB,
  STR_GET_ALL_COMPANIES,
  STR_GET_ALL_JOBS,
  STR_GET_JOB_DETAILS,
  STR_UPDATE_JOB,
  STR_UPDATE_JOB_STATUS,
} from "core/constants/strings"
import { postAPI, putAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import {
  createCompany,
  CreateJob,
  deleteJob,
  jobApplicationStatus,
  jobDetails,
  UpdateJob,
  UpdateJobStatus,
} from "domain/model/JobDashboard"
import { JobDashboardDataSource } from "data/dataSource/Admin/JobDashboardDataSource"

export class JobDashboardAPIDataSourceImpl implements JobDashboardDataSource {
  async getCompaniesList(request: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_ALL_COMPANIES), request)

    return response
  }

  async createJob(request: CreateJob): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_CREATE_JOB), request)
    return response
  }

  async updateJob(request: UpdateJob): Promise<any> {
    const response = await putAPI(pn(API_V4_ADMIN, STR_UPDATE_JOB), request)

    return response
  }

  async updateJobStatus(request: UpdateJobStatus): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_UPDATE_JOB_STATUS), request)

    return response
  }

  async getAllJobs(request: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_ALL_JOBS), request)

    return response
  }

  async getApplicationStatus(request: jobApplicationStatus): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_CREATE_COMPANY), request)

    return response
  }
  async getJobDetails(request: jobDetails): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_JOB_DETAILS), request)

    return response
  }

  async setApplicationStatus(request: jobApplicationStatus): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_SET_APPLICATION_STATUS), request)
    return response
  }

  async createCompany(request: createCompany): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_CREATE_COMPANY), request)
    return response
  }
  async deleteJob(request: deleteJob): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_DELETE_JOB), request)
    return response
  }

  async getJobApplications(request: deleteJob): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_JOB_APPLICATIONS), request)
    return response
  }
}
