import React, { useState, useEffect } from "react"
import CertificateGradient from "core/components/new/CertificateGradient"
import { Button } from "core/components/v2/Button"
import lockImage from "assets/images/lock.png"
import CertificateImage from "assets/images/JohnDoe.jpg"
import celebrationImage from "assets/images/celebration.png"

interface CourseCompletionCardProps {
  courseCompleted: boolean
  course_name: string
  certificate_url: string
}

const CourseCompletionCard: React.FC<CourseCompletionCardProps> = ({
  courseCompleted,
  certificate_url,
  course_name,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState("")

  const cardStyles = courseCompleted ? "bg-[#162456] border-[#2C689F]" : "bg-[#333] border-[#D3D3D3]"

  const getTitle = () => {
    switch (course_name) {
      case "Accelerator":
      case "Evolve":
      case "Transformer":
        return "SDE"
      case "Data_Scientist":
      case "Data-(Scientist+Analyst)":
        return "Data Scientist"
      case "Data_Analyst":
        return "Data Analyst"
      default:
        return "Professional"
    }
  }

  const shareOnLinkedIn = () => {
    const addcertificate_url = `https://www.linkedin.com/profile/add?startTask=CourseCompletedByBosscoder&organizationName=${encodeURIComponent(
      "Bosscoder Academy"
    )}&issueYear=${new Date().getFullYear()}&issueMonth=${
      new Date().getMonth() + 1
    }&certUrl=${encodeURIComponent(certificate_url)}`

    setRedirectUrl(addcertificate_url)
    setShowModal(true)

    // Copy message to clipboard
    const message =
      "I'm thrilled to announce that I've successfully completed my certification from Bosscoder Academy! 🎉 It's been an incredible journey of learning and growth, and I'm excited to apply these new skills in my career. A huge thanks to the amazing team at Bosscoder Academy for providing such a valuable experience. Looking forward to the opportunities ahead! 🚀 #BosscoderAcademy #Certified #NeverStopLearning"
    navigator.clipboard.writeText(message)
  }

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        try {
          window.open(redirectUrl, "_blank")
        } catch (e) {
          console.warn("Pop-ups blocked. Please use the link provided.")
        }
        setShowModal(false)
      }, 12000)

      return () => clearTimeout(timer)
    }
  }, [showModal, redirectUrl])

  const animationStyle = {
    animation: "slide 3s linear infinite",
  }

  return (
    <div
      className={`relative w-full overflow-hidden rounded-lg border-[0.5px] ${cardStyles} px-6 py-4 shadow-[-1px_1px_3px_0_rgba(34,41,48,0.15)]`}
    >
      <div className="flex items-start justify-between">
        <div>
          <div className="font-semibold">
            <p className="text-normal text-[14px] font-semibold leading-[16.94px] text-white">
              Course Completion Certificate
            </p>
          </div>
          {courseCompleted ? (
            <>
              <p className="text-normal font-normal text-[12px] text-white">
                Congratulations! You are now a Certified {getTitle()}.
              </p>
              <p className="text-normal font-normal text-[12px] text-white">Share your Certificate with your peers.</p>
            </>
          ) : (
            <>
              <p className="text-normal font-normal text-[12px] text-white">
                Certificate will be issued after the course completion!
              </p>
              <p className="text-normal font-normal text-[12px] text-white">
                Complete the milestones to earn your certificate!
              </p>
            </>
          )}
          <div className="mt-2 font-semibold">
            <Button
              variant={"icon"}
              size={"md"}
              disabled={!courseCompleted}
              onClick={shareOnLinkedIn}
              className="py-[9px] px-[16px] text-center"
            >
              {courseCompleted ? (
                "Share on LinkedIn"
              ) : (
                <span className="flex items-center w-120px h-17px">
                  <img src={lockImage} alt="Lock" className="w-16px h-16px mr-2" />
                  Share on LinkedIn
                </span>
              )}
            </Button>
          </div>
        </div>
        <img
          src={CertificateImage}
          alt="John Doe"
          className="w-[134px] h-[103px] flex-shrink-0 rounded-[4.12px] border-[0.162px] border-[rgba(14,45,126,0.50)] bg-white ml-4"
        />
      </div>

      <div className="absolute top-0 left-[420px]">
        <CertificateGradient className={courseCompleted ? "bg-blue-200" : "bg-gray-500"} />
      </div>
      <div className="absolute top-0 left-[495px]">
        <CertificateGradient className={courseCompleted ? "bg-blue-200" : "bg-gray-500"} />
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-sm w-full">
            <img src={celebrationImage} alt="Celebration" className="w-3/4 mx-auto object-cover opacity-100" />
            <div className="relative z-20 ">
              <h2 className="text-lg font-semibold text-center text-black">Congratulations from Bosscoder Team</h2>
              <p className="text-sm mb-2 text-center text-black">
                We have also created and copied your post dynamically. You can Paste (Ctrl+V) our Content during Post
                Creation.
              </p>
              <div className="flex justify-center items-center">
                <div className="w-6 h-6 border-4 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
              </div>
              <p className="text-sm mt-2 text-center text-blue-500" style={animationStyle}>
                Referring you to LinkedIn in 10 seconds...
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CourseCompletionCard
