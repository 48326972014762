import { ProfileDataSource } from "data/dataSource/Mentor/ProfileDataSource"
import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Mentor/ProfileRepository"

export class ProfileRepositoryImpl implements ProfileRepository {
  private dataSource: ProfileDataSource

  constructor(dataSource: ProfileDataSource) {
    this.dataSource = dataSource
  }

  async fetchMentorProfile(auth: Auth) {
    return await this.dataSource.fetchMentorProfile(auth)
  }
}
