import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"
import { Auth } from "domain/model/Auth"

export interface postBankFormDataUseCase {
  invoke(request: any, auth: Auth): Promise<any>
}

export class PostBankFormData implements postBankFormDataUseCase {
  private repository: ReferAndEarnRepository

  constructor(repository: ReferAndEarnRepository) {
    this.repository = repository
  }

  async invoke(request: any, auth: Auth): Promise<any> {
    return await this.repository.postBankFormData(request, auth)
  }
}
