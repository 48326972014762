"use client"

import * as React from "react"
import { cn } from "core/lib/utils"
import { Button } from "core/components/v2/Button"
import { Popover, PopoverContent, PopoverTrigger } from "core/components/v2/ui/PopOver"
import OnboardingTime from "./OnboardingTime"
import Clock from "assets/svgs/Clock"

export function TimePickerWithProps({ setStartTime, time, setTime, className }: any) {
  const [open, setOpen] = React.useState<boolean>(false)

  const handleTimeSelection = (selectedTime: string) => {
    setTime(selectedTime)
    setStartTime(selectedTime)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"ternary"}
          className={cn(
            "w-full justify-between text-left font-normal bg-white border-[1px] rounded-md ",
            !time && "text-muted-foreground",
            className
          )}
          onClick={() => setOpen(true)}
        >
          {time ? time : <span>Select time</span>}
          <Clock className="mr-2 w-4 h-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-[999] bg-white">
        <OnboardingTime setStartTime={handleTimeSelection} />
      </PopoverContent>
    </Popover>
  )
}
