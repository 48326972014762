import { format } from "date-fns"
import { cn } from "core/lib/utils"
import { ChevronLeftIcon, ChevronRightIcon } from "core/constants/svgs"

export default function MentorCalendar({
  header,
  days,
  activeDate,
  setActiveDate,
  onClick,
  onPrevMonth,
  onNextMonth,
  disabled = false,
}: any) {
  return (
    <div className="w-full space-y-8 text-center">
      <div className="flex justify-between">
        <h3 className={cn(disabled ? "text-new-neutral-light" : "text-new-accent")}>
          {format(new Date(activeDate), "do MMMM, yyyy")}
        </h3>
        <div className="flex items-center gap-4">
          <button
            className={cn(
              "flex h-8 w-8 items-center justify-center rounded-sm border bg-new-solid-white",
              disabled
                ? "border-new-neutral-light text-new-neutral-light"
                : "border-new-accent text-new-accent hover:bg-new-accent hover:text-new-solid-white"
            )}
            onClick={() => onPrevMonth()}
            disabled={disabled}
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </button>
          <button
            className={cn(
              "flex h-8 w-8 items-center justify-center rounded-sm border bg-new-solid-white",
              disabled
                ? "border-new-neutral-light text-new-neutral-light"
                : "border-new-accent text-new-accent hover:bg-new-accent hover:text-new-solid-white"
            )}
            onClick={() => onNextMonth()}
            disabled={disabled}
          >
            <ChevronRightIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="isolate grid grid-cols-[repeat(7,minmax(40px,1fr))] gap-2">
        {header.map((day: string, i: number) => (
          <h4
            key={i}
            className={cn(
              "flex h-8 w-full items-center justify-center",
              disabled ? "text-new-neutral-light" : "text-new-neutral-dark"
            )}
          >
            {day}
          </h4>
        ))}
        {days.map((day: any, i: number) => (
          <button
            key={i}
            type="button"
            className={cn(
              "relative flex h-8 w-full select-none items-center justify-center rounded-sm focus:z-10",
              !disabled && "disabled:hover:bg-new-solid-white disabled:hover:text-new-neutral-light",
              day.sessions?.length > 0 &&
                !disabled &&
                "before:absolute before:top-1 before:right-1 before:h-1 before:w-1 before:rounded-full before:bg-new-accent",
              day.isActiveDate
                ? "bg-new-accent text-white"
                : day.sessions?.length > 0 && "bg-new-solid-green-light text-new-accent",
              day.isActiveDate
                ? disabled
                  ? "bg-new-neutral-light text-new-solid-white"
                  : ""
                : day.isCurrentMonth
                  ? disabled
                    ? "bg-new-solid-white text-new-neutral-light"
                    : "bg-new-solid-white text-new-accent hover:bg-new-solid-blue-light"
                  : disabled
                    ? "text-new-neutral-light"
                    : "text-new-neutral-light hover:bg-new-solid-blue-light"
            )}
            onClick={() => {
              onClick(day.date)
              setActiveDate(new Date(day.date))
            }}
            disabled={!day.isCurrentMonth || disabled}
          >
            <h4>{day.date.split("-").at(-1)}</h4>
          </button>
        ))}
      </div>
    </div>
  )
}
