import NewMentorSessionsDataSource from "data/dataSource/Admin/NewMentorSessionsDataSource"
import { Auth } from "domain/model/Auth"

export class NewMentorSessionsRepositoryImpl implements NewMentorSessionsRepositoryImpl {
  private dataSource: NewMentorSessionsDataSource

  constructor(dataSource: NewMentorSessionsDataSource) {
    this.dataSource = dataSource
  }

  async getBatchTemplateGeneric(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getBatchTemplateGeneric(auth, data)
  }
  async updateBatchTemplateGeneric(auth: Auth, data: any): Promise<any> {
    return this.dataSource.updateBatchTemplateGeneric(auth, data)
  }
  async editMentorTemplateForStudent(auth: Auth, template: any): Promise<any> {
    return this.dataSource.editMentorTemplateForStudent(auth, template)
  }
  async getMentorNameFromId(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorNameFromId(auth, data)
  }
}
