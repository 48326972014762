import { API_V4_ADMIN, API_V4_CREATE_ONBOARDING_MANAGER } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { CreateOnboardingManager } from "domain/model/CreateOnboardingManager"
import CreateOnboardingManagerDataSource from "data/dataSource/Admin/CreateOnboardingManagerDataSource"
import { RequestQuote } from "@mui/icons-material"

export class CreateOnboardingManagerAPIDataSourceImpl implements CreateOnboardingManagerDataSource {
  async createOnboardingManager(createOnboardingManager: CreateOnboardingManager): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_CREATE_ONBOARDING_MANAGER), createOnboardingManager)
      return response
    } catch (error) {
      return error
    }
  }
}
