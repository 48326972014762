import { Auth } from "domain/model/Auth"
import { OnboardingData, ManagerFeedback } from "domain/model/OnboardingMeet"
import { CreateOnboardingMeetRepository } from "domain/repository/Admin/CreateOnboardingMeetRepository"

export interface CreateOnboardingMeetUseCase {
  invoke(createOnboardingMeet: OnboardingData, auth: Auth): Promise<any>
}
export class CreateOnboardingMeet implements CreateOnboardingMeetUseCase {
  private repository: CreateOnboardingMeetRepository

  constructor(repository: CreateOnboardingMeetRepository) {
    this.repository = repository
  }

  async invoke(createOnboardingMeet: OnboardingData, auth: Auth) {
    return await this.repository.createOnboardingMeet(createOnboardingMeet, auth)
  }
}
