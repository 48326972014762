import ButtonGroup from "core/components/ButtonGroup"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import InvoicingMainPageViewModel from "./InvoicingMainPageViewModel"
import GenerateInvoiceView from "../Invoice/GenerateInvoiceView"
import GenerateMentorInvoiceView from "../MentorInvoicing/GenerateMentorInvoiceView"

export default function InvoicingMainPage() {
  const { activeButtonType, changeActiveButtonType } = InvoicingMainPageViewModel()
  return (
    <DashboardLayout>
      <div className="mt-10 px-5">
        <ButtonGroup
          buttons={["Instructor Invoice", "Mentor Invoice"]}
          active={activeButtonType}
          onChange={(index: number) => changeActiveButtonType(index)}
        />
        {activeButtonType == 0 ? (
          <div className="">
            <GenerateInvoiceView />
          </div>
        ) : (
          <div>
            <GenerateMentorInvoiceView />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}
