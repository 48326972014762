import React from "react"

const CertificateGradient = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="289" height="151" viewBox="0 0 289 151" fill="none">
      <path d="M140.5 0H288.5L148 151H0L140.5 0Z" fill="url(#paint0_linear_3088_9317)" fillOpacity="0.3" />
      <defs>
        <linearGradient
          id="paint0_linear_3088_9317"
          x1="276.5"
          y1="-111.5"
          x2="89"
          y2="151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CertificateGradient
