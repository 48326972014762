import { mentorFeedback } from "domain/model/MyMentee"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export interface AddMentorFeedbackUseCase {
  invoke(feedback: mentorFeedback): Promise<any>
}

export class addMentorFeedback implements AddMentorFeedbackUseCase {
  private repository: MyMenteeRepository

  constructor(repository: MyMenteeRepository) {
    this.repository = repository
  }

  async invoke(feedback: mentorFeedback) {
    return await this.repository.addMentorFeedback(feedback)
  }
}
