import { Auth } from "domain/model/Auth"

import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface UploadPaymentDocumentUseCase {
  invoke(auth: Auth, student_email: string, file: File, filename: string, id: number): Promise<any>
}

export class UploadPaymentDocument implements UploadPaymentDocumentUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_email: string, file: File, filename: string, id: number) {
    return await this.repository.uploadPaymentDocument(auth, student_email, file, filename, id)
  }
}
