export default function BgPattern() {
  return (
    <svg width="90" height="100" viewBox="0 0 90 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.1875 47.1827L35.1874 47.1827L34.0933 45.9006C34.0933 45.9006 34.0933 45.9006 34.0933 45.9006C33.8343 45.5971 33.378 45.5732 33.0866 45.8226L33.0866 45.8226C33.0588 45.8464 33.0331 45.8722 33.0093 45.8998L33.0086 45.9006L31.9145 47.1825C31.9144 47.1825 31.9144 47.1826 31.9143 47.1826C31.884 47.218 31.8369 47.2405 31.7848 47.2405H29.4509C29.3792 47.2405 29.3304 47.2028 29.3073 47.1555C29.2846 47.1091 29.2872 47.0568 29.3268 47.0104L29.3268 47.0103L32.0487 43.821L32.0488 43.821L33.0082 42.6967C33.0082 42.6966 33.0082 42.6966 33.0083 42.6966C33.1438 42.5379 33.3365 42.4537 33.5352 42.4476L33.5547 42.4473L33.5691 42.4474L33.5691 42.4474L33.5729 42.4475C33.7722 42.4534 33.9653 42.5376 34.1009 42.6966L34.101 42.6967L34.1229 42.7224V42.7226L34.1828 42.7927L35.0604 43.821L35.0604 43.821L35.6676 44.5327L35.7425 44.6204H35.7427L37.7823 47.0105L37.7824 47.0106C37.822 47.057 37.8246 47.1093 37.8019 47.1556C37.7788 47.2028 37.73 47.2405 37.6583 47.2405H35.3169C35.2646 47.2405 35.2174 47.2178 35.1875 47.1827Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M45.9941 53.6241L45.9941 53.6241L44.9 52.342C44.9 52.342 44.9 52.342 44.8999 52.342C44.6409 52.0385 44.1846 52.0147 43.8933 52.264L43.8932 52.264C43.8654 52.2878 43.8397 52.3136 43.8159 52.3412L43.8153 52.342L42.7211 53.6239C42.721 53.6239 42.721 53.624 42.721 53.624C42.6906 53.6594 42.6435 53.682 42.5915 53.682H40.2575C40.1858 53.682 40.137 53.6442 40.1139 53.5969C40.0912 53.5505 40.0938 53.4982 40.1334 53.4518L40.1335 53.4518L42.8554 50.2625L42.8554 50.2624L43.8149 49.1381C43.8149 49.1381 43.8149 49.138 43.8149 49.138C43.9504 48.9793 44.1431 48.8951 44.3419 48.889L44.3613 48.8887L44.3757 48.8888L44.3757 48.8888L44.3796 48.889C44.5788 48.8948 44.7719 48.979 44.9075 49.138L44.9076 49.1381L44.9296 49.1638V49.164L44.9894 49.2341L45.8671 50.2624L45.8671 50.2625L46.4743 50.9741L46.5491 51.0619H46.5493L48.589 53.4519L48.5891 53.452C48.6287 53.4984 48.6312 53.5507 48.6086 53.597C48.5855 53.6442 48.5367 53.682 48.465 53.682H46.1235C46.0712 53.682 46.0241 53.6592 45.9941 53.6241Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M57.8515 60.2852L57.8515 60.2852L56.7574 59.0031C56.7574 59.0031 56.7574 59.0031 56.7574 59.0031C56.4984 58.6996 56.0421 58.6758 55.7507 58.9251L55.7506 58.9252C55.7228 58.949 55.6971 58.9747 55.6733 59.0024L55.6727 59.0031L54.5785 60.285C54.5785 60.285 54.5784 60.2851 54.5784 60.2852C54.548 60.3206 54.5009 60.3431 54.4489 60.3431H52.1149C52.0433 60.3431 51.9944 60.3054 51.9713 60.2581C51.9486 60.2117 51.9512 60.1593 51.9908 60.113L51.9909 60.1129L54.7128 56.9236L54.7128 56.9236L55.6723 55.7992C55.6723 55.7992 55.6723 55.7992 55.6723 55.7992C55.8079 55.6404 56.0005 55.5563 56.1993 55.5501L56.2188 55.5498L56.2331 55.5499L56.2331 55.55L56.237 55.5501C56.4362 55.5559 56.6293 55.6401 56.7649 55.7991L56.765 55.7992L56.787 55.8249V55.8251L56.8468 55.8952L57.7245 56.9236L57.7245 56.9236L58.3317 57.6353L58.4066 57.723H58.4068L60.4464 60.1131L60.4465 60.1132C60.4861 60.1595 60.4887 60.2118 60.466 60.2581C60.4429 60.3053 60.3941 60.3431 60.3224 60.3431H57.981C57.9286 60.3431 57.8815 60.3204 57.8515 60.2852Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M59.4277 48.9923L59.4277 48.9922L58.3336 47.7102C58.3335 47.7102 58.3335 47.7102 58.3335 47.7102C58.0745 47.4066 57.6182 47.3828 57.3269 47.6322L57.3268 47.6322C57.299 47.656 57.2733 47.6817 57.2495 47.7094L57.2489 47.7101L56.1547 48.992C56.1546 48.9921 56.1546 48.9921 56.1546 48.9922C56.1242 49.0276 56.0771 49.0501 56.0251 49.0501H53.6911C53.6194 49.0501 53.5706 49.0124 53.5475 48.9651C53.5248 48.9187 53.5274 48.8664 53.567 48.82L53.5671 48.8199L56.289 45.6306L56.289 45.6306L57.2485 44.5062C57.2485 44.5062 57.2485 44.5062 57.2485 44.5062C57.384 44.3474 57.5767 44.2633 57.7755 44.2572L57.7949 44.2568L57.8093 44.257L57.8093 44.257L57.8132 44.2571C58.0124 44.263 58.2055 44.3471 58.3411 44.5061L58.3412 44.5062L58.3632 44.532V44.5321L58.423 44.6023L59.3006 45.6306L59.3007 45.6306L59.9079 46.3423L59.9827 46.43H59.9829L62.0226 48.8201L62.0227 48.8202C62.0623 48.8666 62.0648 48.9189 62.0422 48.9652C62.0191 49.0124 61.9703 49.0501 61.8986 49.0501H59.5571C59.5048 49.0501 59.4577 49.0274 59.4277 48.9923Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M48.3965 40.8126L48.3964 40.8125L47.3023 39.5305C47.3023 39.5305 47.3023 39.5305 47.3023 39.5305C47.0433 39.2269 46.587 39.2031 46.2956 39.4525L46.2956 39.4525C46.2678 39.4763 46.2421 39.502 46.2182 39.5297L46.2176 39.5305L45.1234 40.8123C45.1234 40.8124 45.1233 40.8124 45.1233 40.8125C45.0929 40.8479 45.0459 40.8704 44.9938 40.8704H42.6598C42.5882 40.8704 42.5394 40.8327 42.5162 40.7854C42.4935 40.739 42.4961 40.6867 42.5357 40.6403L42.5358 40.6402L45.2577 37.4509L45.2577 37.4509L46.2172 36.3265C46.2172 36.3265 46.2172 36.3265 46.2172 36.3265C46.3528 36.1677 46.5455 36.0836 46.7442 36.0775L46.7637 36.0771L46.7781 36.0773L46.7781 36.0773L46.7819 36.0774C46.9811 36.0833 47.1743 36.1674 47.3098 36.3264L47.3099 36.3266L47.3319 36.3523V36.3524L47.3917 36.4226L48.2694 37.4509L48.2694 37.4509L48.8766 38.1626L48.9515 38.2503H48.9517L50.9913 40.6404L50.9914 40.6405C51.031 40.6869 51.0336 40.7392 51.0109 40.7855C50.9878 40.8327 50.939 40.8704 50.8673 40.8704H48.5259C48.4736 40.8704 48.4264 40.8477 48.3965 40.8126Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M74.3613 41.6095L74.3613 41.6094L73.2671 40.3274C73.2671 40.3274 73.2671 40.3274 73.2671 40.3274C73.0081 40.0238 72.5518 40 72.2605 40.2493L72.2604 40.2494C72.2326 40.2732 72.2069 40.2989 72.1831 40.3266L72.1825 40.3273L71.0883 41.6092C71.0882 41.6093 71.0882 41.6093 71.0881 41.6094C71.0578 41.6448 71.0107 41.6673 70.9586 41.6673H68.6247C68.553 41.6673 68.5042 41.6296 68.4811 41.5823C68.4584 41.5359 68.461 41.4835 68.5006 41.4372L68.5007 41.4371L71.2226 38.2478L71.2226 38.2478L72.1821 37.1234C72.1821 37.1234 72.1821 37.1234 72.1821 37.1234C72.3176 36.9646 72.5103 36.8805 72.7091 36.8743L72.7285 36.874L72.7429 36.8742L72.7429 36.8742L72.7468 36.8743C72.946 36.8802 73.1391 36.9643 73.2747 37.1233L73.2748 37.1234L73.2967 37.1492V37.1493L73.3566 37.2194L74.2342 38.2478L74.2343 38.2478L74.8415 38.9595L74.9163 39.0472H74.9165L76.9562 41.4373L76.9563 41.4374C76.9959 41.4838 76.9984 41.5361 76.9758 41.5824C76.9527 41.6296 76.9039 41.6673 76.8321 41.6673H74.4907C74.4384 41.6673 74.3913 41.6446 74.3613 41.6095Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M72.4101 53.6241L72.4101 53.6241L71.316 52.342C71.316 52.342 71.316 52.342 71.316 52.342C71.057 52.0385 70.6007 52.0147 70.3093 52.264L70.3092 52.264C70.2814 52.2878 70.2557 52.3136 70.2319 52.3412L70.2313 52.342L69.1371 53.6239C69.1371 53.6239 69.137 53.624 69.137 53.624C69.1066 53.6594 69.0595 53.682 69.0075 53.682H66.6735C66.6019 53.682 66.553 53.6442 66.5299 53.5969C66.5072 53.5505 66.5098 53.4982 66.5494 53.4518L66.5495 53.4518L69.2714 50.2625L69.2714 50.2624L70.2309 49.1381C70.2309 49.1381 70.2309 49.138 70.2309 49.138C70.3664 48.9793 70.5591 48.8951 70.7579 48.889L70.7773 48.8887L70.7917 48.8888L70.7917 48.8888L70.7956 48.889C70.9948 48.8948 71.1879 48.979 71.3235 49.138L71.3236 49.1381L71.3456 49.1638V49.164L71.4054 49.2341L72.2831 50.2624L72.2831 50.2625L72.8903 50.9741L72.9651 51.0619H72.9654L75.005 53.4519L75.0051 53.452C75.0447 53.4984 75.0473 53.5507 75.0246 53.597C75.0015 53.6442 74.9527 53.682 74.881 53.682H72.5396C72.4872 53.682 72.4401 53.6592 72.4101 53.6241Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M86.4433 53.6241L86.4433 53.6241L85.3492 52.342C85.3492 52.342 85.3492 52.342 85.3492 52.342C85.0902 52.0385 84.6339 52.0147 84.3425 52.264L84.3424 52.264C84.3146 52.2878 84.2889 52.3136 84.2651 52.3412L84.2645 52.342L83.1703 53.6239C83.1703 53.6239 83.1702 53.624 83.1702 53.624C83.1398 53.6594 83.0927 53.682 83.0407 53.682H80.7067C80.6351 53.682 80.5862 53.6442 80.5631 53.5969C80.5404 53.5505 80.543 53.4982 80.5826 53.4518L80.5827 53.4518L83.3046 50.2625L83.3046 50.2624L84.2641 49.1381C84.2641 49.1381 84.2641 49.138 84.2641 49.138C84.3996 48.9793 84.5923 48.8951 84.7911 48.889L84.8105 48.8887L84.8249 48.8888L84.8249 48.8888L84.8288 48.889C85.028 48.8948 85.2211 48.979 85.3567 49.138L85.3568 49.1381L85.3788 49.1638V49.164L85.4386 49.2341L86.3163 50.2624L86.3163 50.2625L86.9235 50.9741L86.9983 51.0619H86.9986L89.0382 53.4519L89.0383 53.452C89.0779 53.4984 89.0805 53.5507 89.0578 53.597C89.0347 53.6442 88.9859 53.682 88.9142 53.682H86.5728C86.5204 53.682 86.4733 53.6592 86.4433 53.6241Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M71.3613 64.1212L71.3613 64.1211L70.2671 62.8391C70.2671 62.8391 70.2671 62.8391 70.2671 62.8391C70.0081 62.5355 69.5518 62.5117 69.2605 62.7611L69.2604 62.7611C69.2326 62.7849 69.2069 62.8106 69.1831 62.8383L69.1825 62.8391L68.0883 64.1209C68.0882 64.121 68.0882 64.121 68.0881 64.1211C68.0578 64.1565 68.0107 64.179 67.9586 64.179H65.6247C65.553 64.179 65.5042 64.1413 65.4811 64.094C65.4584 64.0476 65.461 63.9953 65.5006 63.9489L65.5007 63.9488L68.2226 60.7595L68.2226 60.7595L69.1821 59.6351C69.1821 59.6351 69.1821 59.6351 69.1821 59.6351C69.3176 59.4763 69.5103 59.3922 69.7091 59.3861L69.7285 59.3857L69.7429 59.3859L69.7429 59.3859L69.7468 59.386C69.946 59.3919 70.1391 59.476 70.2747 59.635L70.2748 59.6352L70.2967 59.6609V59.661L70.3566 59.7312L71.2342 60.7595L71.2343 60.7595L71.8415 61.4712L71.9163 61.5589H71.9165L73.9562 63.949L73.9563 63.9491C73.9959 63.9955 73.9984 64.0478 73.9758 64.0941C73.9527 64.1413 73.9039 64.179 73.8321 64.179H71.4907C71.4384 64.179 71.3913 64.1563 71.3613 64.1212Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M44.3437 63.7598L44.3437 63.7598L43.2496 62.4778C43.2496 62.4778 43.2496 62.4777 43.2496 62.4777C42.9905 62.1742 42.5343 62.1504 42.2429 62.3997L42.2428 62.3998C42.215 62.4236 42.1893 62.4493 42.1655 62.477L42.1649 62.4777L41.0707 63.7596C41.0707 63.7597 41.0706 63.7597 41.0706 63.7598C41.0402 63.7952 40.9931 63.8177 40.9411 63.8177H38.6071C38.5354 63.8177 38.4866 63.78 38.4635 63.7327C38.4408 63.6863 38.4434 63.6339 38.483 63.5876L38.4831 63.5875L41.205 60.3982L41.205 60.3982L42.1645 59.2738C42.1645 59.2738 42.1645 59.2738 42.1645 59.2738C42.3 59.115 42.4927 59.0309 42.6915 59.0247L42.7109 59.0244L42.7253 59.0246L42.7253 59.0246L42.7292 59.0247C42.9284 59.0306 43.1215 59.1147 43.2571 59.2737L43.2572 59.2738L43.2792 59.2995V59.2997L43.339 59.3698L44.2167 60.3982L44.2167 60.3982L44.8239 61.1099L44.8987 61.1976H44.899L46.9386 63.5877L46.9387 63.5878C46.9783 63.6341 46.9808 63.6864 46.9582 63.7327C46.9351 63.78 46.8863 63.8177 46.8146 63.8177H44.4732C44.4208 63.8177 44.3737 63.795 44.3437 63.7598Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M14.0996 82.5108L14.0995 82.5108L13.0054 81.2287C13.0054 81.2287 13.0054 81.2287 13.0054 81.2287C12.7464 80.9252 12.2901 80.9014 11.9987 81.1507L11.9987 81.1508C11.9709 81.1746 11.9452 81.2003 11.9214 81.228L11.9207 81.2287L10.8266 82.5106C10.8265 82.5106 10.8265 82.5107 10.8264 82.5107C10.7961 82.5461 10.749 82.5687 10.6969 82.5687H8.36297C8.29131 82.5687 8.24249 82.5309 8.21936 82.4837C8.19667 82.4373 8.19927 82.3849 8.23886 82.3386L8.23893 82.3385L10.9609 79.1492L10.9609 79.1492L11.9203 78.0248C11.9204 78.0248 11.9204 78.0248 11.9204 78.0248C12.0559 77.866 12.2486 77.7819 12.4473 77.7757L12.4668 77.7754L12.4812 77.7755L12.4812 77.7756L12.485 77.7757C12.6843 77.7815 12.8774 77.8657 13.013 78.0247L13.0131 78.0248L13.035 78.0505V78.0507L13.0949 78.1208L13.9725 79.1491L13.9725 79.1492L14.5797 79.8608L14.6546 79.9486H14.6548L16.6945 82.3387L16.6945 82.3388C16.7341 82.3851 16.7367 82.4374 16.7141 82.4837C16.6909 82.5309 16.6422 82.5687 16.5704 82.5687H14.229C14.1767 82.5687 14.1295 82.546 14.0996 82.5108Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M24.9062 88.9522L24.9062 88.9522L23.8121 87.6701C23.8121 87.6701 23.8121 87.6701 23.8121 87.6701C23.553 87.3666 23.0968 87.3428 22.8054 87.5921L22.8053 87.5922C22.7775 87.616 22.7518 87.6417 22.728 87.6694L22.7274 87.6701L21.6332 88.952C21.6332 88.952 21.6331 88.9521 21.6331 88.9521C21.6027 88.9876 21.5556 89.0101 21.5036 89.0101H19.1696C19.0979 89.0101 19.0491 88.9724 19.026 88.9251C19.0033 88.8787 19.0059 88.8263 19.0455 88.78L19.0456 88.7799L21.7675 85.5906L21.7675 85.5906L22.727 84.4662C22.727 84.4662 22.727 84.4662 22.727 84.4662C22.8625 84.3074 23.0552 84.2233 23.254 84.2171L23.2734 84.2168L23.2878 84.2169L23.2878 84.217L23.2917 84.2171C23.4909 84.2229 23.684 84.3071 23.8196 84.4661L23.8197 84.4662L23.8417 84.4919V84.4921L23.9015 84.5622L24.7792 85.5906L24.7792 85.5906L25.3864 86.3022L25.4612 86.39H25.4615L27.5011 88.7801L27.5012 88.7802C27.5408 88.8265 27.5433 88.8788 27.5207 88.9251C27.4976 88.9723 27.4488 89.0101 27.3771 89.0101H25.0357C24.9833 89.0101 24.9362 88.9874 24.9062 88.9522Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M36.7636 95.6134L36.7636 95.6133L35.6695 94.3313C35.6695 94.3313 35.6695 94.3313 35.6695 94.3313C35.4105 94.0277 34.9542 94.0039 34.6628 94.2532L34.6627 94.2533C34.6349 94.2771 34.6092 94.3028 34.5854 94.3305L34.5848 94.3312L33.4906 95.6131C33.4906 95.6132 33.4905 95.6132 33.4905 95.6133C33.4601 95.6487 33.413 95.6712 33.361 95.6712H31.027C30.9554 95.6712 30.9066 95.6335 30.8834 95.5862C30.8607 95.5398 30.8633 95.4874 30.9029 95.4411L30.903 95.441L33.6249 92.2517L33.6249 92.2517L34.5844 91.1273C34.5844 91.1273 34.5844 91.1273 34.5844 91.1273C34.72 90.9685 34.9127 90.8844 35.1114 90.8782L35.1309 90.8779L35.1453 90.8781L35.1452 90.8781L35.1491 90.8782C35.3483 90.8841 35.5415 90.9682 35.677 91.1272L35.6771 91.1273L35.6991 91.1531V91.1532L35.7589 91.2233L36.6366 92.2517L36.6366 92.2517L37.2438 92.9634L37.3187 93.0511H37.3189L39.3585 95.4412L39.3586 95.4413C39.3982 95.4877 39.4008 95.54 39.3781 95.5863C39.355 95.6335 39.3062 95.6712 39.2345 95.6712H36.8931C36.8408 95.6712 36.7936 95.6485 36.7636 95.6134Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M38.3398 84.3204L38.3398 84.3203L37.2457 83.0383C37.2457 83.0383 37.2457 83.0383 37.2456 83.0383C36.9866 82.7348 36.5303 82.7109 36.239 82.9603L36.2389 82.9603C36.2111 82.9841 36.1854 83.0099 36.1616 83.0375L36.161 83.0383L35.0668 84.3202C35.0668 84.3202 35.0667 84.3203 35.0667 84.3203C35.0363 84.3557 34.9892 84.3782 34.9372 84.3782H32.6032C32.5315 84.3782 32.4827 84.3405 32.4596 84.2932C32.4369 84.2468 32.4395 84.1945 32.4791 84.1481L32.4792 84.148L35.2011 80.9587L35.2011 80.9587L36.1606 79.8344C36.1606 79.8343 36.1606 79.8343 36.1606 79.8343C36.2961 79.6755 36.4888 79.5914 36.6876 79.5853L36.707 79.585L36.7214 79.5851L36.7214 79.5851L36.7253 79.5852C36.9245 79.5911 37.1176 79.6752 37.2532 79.8342L37.2533 79.8344L37.2753 79.8601V79.8603L37.3351 79.9304L38.2128 80.9587L38.2128 80.9587L38.82 81.6704L38.8948 81.7581H38.895L40.9347 84.1482L40.9348 84.1483C40.9744 84.1947 40.9769 84.247 40.9543 84.2933C40.9312 84.3405 40.8824 84.3782 40.8107 84.3782H38.4692C38.4169 84.3782 38.3698 84.3555 38.3398 84.3204Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M27.3086 76.1407L27.3085 76.1407L26.2144 74.8586C26.2144 74.8586 26.2144 74.8586 26.2144 74.8586C25.9554 74.5551 25.4991 74.5313 25.2077 74.7806L25.2077 74.7806C25.1799 74.8044 25.1542 74.8302 25.1303 74.8579L25.1297 74.8586L24.0355 76.1405C24.0355 76.1405 24.0355 76.1406 24.0354 76.1406C24.0051 76.176 23.958 76.1986 23.9059 76.1986H21.5719C21.5003 76.1986 21.4515 76.1608 21.4283 76.1135C21.4057 76.0671 21.4083 76.0148 21.4478 75.9684L21.4479 75.9684L24.1698 72.7791L24.1698 72.779L25.1293 71.6547C25.1293 71.6547 25.1293 71.6546 25.1293 71.6546C25.2649 71.4959 25.4576 71.4118 25.6563 71.4056L25.6758 71.4053L25.6902 71.4054L25.6902 71.4054L25.694 71.4056C25.8932 71.4114 26.0864 71.4956 26.2219 71.6546L26.2221 71.6547L26.244 71.6804V71.6806L26.3039 71.7507L27.1815 72.779L27.1815 72.7791L27.7887 73.4907L27.8636 73.5785H27.8638L29.9034 75.9685L29.9035 75.9686C29.9431 76.015 29.9457 76.0673 29.923 76.1136C29.8999 76.1608 29.8511 76.1986 29.7794 76.1986H27.438C27.3857 76.1986 27.3385 76.1758 27.3086 76.1407Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M53.2734 76.9366L53.2734 76.9366L52.1793 75.6545C52.1793 75.6545 52.1792 75.6545 52.1792 75.6545C51.9202 75.351 51.4639 75.3272 51.1726 75.5765L51.1725 75.5765C51.1447 75.6003 51.119 75.6261 51.0952 75.6537L51.0946 75.6545L50.0004 76.9364C50.0003 76.9364 50.0003 76.9365 50.0003 76.9365C49.9699 76.9719 49.9228 76.9945 49.8708 76.9945H47.5368C47.4651 76.9945 47.4163 76.9567 47.3932 76.9094C47.3705 76.863 47.3731 76.8107 47.4127 76.7643L47.4128 76.7643L50.1347 73.575L50.1347 73.5749L51.0942 72.4506C51.0942 72.4506 51.0942 72.4505 51.0942 72.4505C51.2297 72.2918 51.4224 72.2076 51.6212 72.2015L51.6406 72.2012L51.655 72.2013L51.655 72.2013L51.6589 72.2015C51.8581 72.2073 52.0512 72.2915 52.1868 72.4505L52.1869 72.4506L52.2089 72.4763V72.4765L52.2687 72.5466L53.1463 73.5749L53.1464 73.575L53.7536 74.2866L53.8284 74.3744H53.8286L55.8683 76.7644L55.8684 76.7645C55.908 76.8109 55.9105 76.8632 55.8879 76.9095C55.8648 76.9567 55.816 76.9945 55.7443 76.9945H53.4028C53.3505 76.9945 53.3034 76.9717 53.2734 76.9366Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M51.3222 88.9522L51.3222 88.9522L50.2281 87.6701C50.2281 87.6701 50.2281 87.6701 50.2281 87.6701C49.9691 87.3666 49.5128 87.3428 49.2214 87.5921L49.2213 87.5922C49.1935 87.616 49.1678 87.6417 49.144 87.6694L49.1434 87.6701L48.0492 88.952C48.0492 88.952 48.0491 88.9521 48.0491 88.9521C48.0187 88.9876 47.9716 89.0101 47.9196 89.0101H45.5856C45.514 89.0101 45.4652 88.9724 45.442 88.9251C45.4193 88.8787 45.4219 88.8263 45.4615 88.78L45.4616 88.7799L48.1835 85.5906L48.1835 85.5906L49.143 84.4662C49.143 84.4662 49.143 84.4662 49.143 84.4662C49.2786 84.3074 49.4712 84.2233 49.67 84.2171L49.6895 84.2168L49.7038 84.2169L49.7038 84.217L49.7077 84.2171C49.9069 84.2229 50.1001 84.3071 50.2356 84.4661L50.2357 84.4662L50.2577 84.4919V84.4921L50.3175 84.5622L51.1952 85.5906L51.1952 85.5906L51.8024 86.3022L51.8773 86.39H51.8775L53.9171 88.7801L53.9172 88.7802C53.9568 88.8265 53.9594 88.8788 53.9367 88.9251C53.9136 88.9723 53.8648 89.0101 53.7931 89.0101H51.4517C51.3994 89.0101 51.3522 88.9874 51.3222 88.9522Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M65.3554 88.9522L65.3554 88.9522L64.2613 87.6701C64.2613 87.6701 64.2613 87.6701 64.2613 87.6701C64.0023 87.3666 63.546 87.3428 63.2546 87.5921L63.2545 87.5922C63.2267 87.616 63.201 87.6417 63.1772 87.6694L63.1766 87.6701L62.0824 88.952C62.0824 88.952 62.0823 88.9521 62.0823 88.9521C62.0519 88.9876 62.0048 89.0101 61.9528 89.0101H59.6188C59.5472 89.0101 59.4984 88.9724 59.4752 88.9251C59.4525 88.8787 59.4551 88.8263 59.4947 88.78L59.4948 88.7799L62.2167 85.5906L62.2167 85.5906L63.1762 84.4662C63.1762 84.4662 63.1762 84.4662 63.1762 84.4662C63.3118 84.3074 63.5044 84.2233 63.7032 84.2171L63.7227 84.2168L63.737 84.2169L63.737 84.217L63.7409 84.2171C63.9401 84.2229 64.1333 84.3071 64.2688 84.4661L64.2689 84.4662L64.2909 84.4919V84.4921L64.3507 84.5622L65.2284 85.5906L65.2284 85.5906L65.8356 86.3022L65.9105 86.39H65.9107L67.9503 88.7801L67.9504 88.7802C67.99 88.8265 67.9926 88.8788 67.9699 88.9251C67.9468 88.9723 67.898 89.0101 67.8263 89.0101H65.4849C65.4326 89.0101 65.3854 88.9874 65.3554 88.9522Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M50.2695 99.4503L50.2695 99.4502L49.1753 98.1682C49.1753 98.1682 49.1753 98.1682 49.1753 98.1682C48.9163 97.8646 48.46 97.8408 48.1687 98.0902L48.1686 98.0902C48.1408 98.114 48.1151 98.1397 48.0913 98.1674L48.0907 98.1682L46.9965 99.45C46.9964 99.4501 46.9964 99.4501 46.9964 99.4502C46.966 99.4856 46.9189 99.5081 46.8668 99.5081H44.5329C44.4612 99.5081 44.4124 99.4704 44.3893 99.4231C44.3666 99.3767 44.3692 99.3244 44.4088 99.278L44.4089 99.2779L47.1308 96.0886L47.1308 96.0886L48.0903 94.9642C48.0903 94.9642 48.0903 94.9642 48.0903 94.9642C48.2258 94.8054 48.4185 94.7213 48.6173 94.7152L48.6367 94.7148L48.6511 94.715L48.6511 94.715L48.655 94.7151C48.8542 94.721 49.0473 94.8051 49.1829 94.9641L49.183 94.9643L49.2049 94.99V94.9901L49.2648 95.0603L50.1424 96.0886L50.1425 96.0886L50.7497 96.8003L50.8245 96.888H50.8247L52.8644 99.2781L52.8645 99.2782C52.9041 99.3246 52.9066 99.3769 52.884 99.4232C52.8609 99.4704 52.8121 99.5081 52.7404 99.5081H50.3989C50.3466 99.5081 50.2995 99.4854 50.2695 99.4503Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M23.2558 99.088L23.2558 99.0879L22.1617 97.8059C22.1617 97.8059 22.1617 97.8059 22.1617 97.8059C21.9027 97.5023 21.4464 97.4785 21.155 97.7279L21.1549 97.7279C21.1271 97.7517 21.1014 97.7774 21.0776 97.8051L21.077 97.8058L19.9828 99.0877C19.9828 99.0878 19.9827 99.0878 19.9827 99.0879C19.9523 99.1233 19.9052 99.1458 19.8532 99.1458H17.5192C17.4476 99.1458 17.3987 99.1081 17.3756 99.0608C17.3529 99.0144 17.3555 98.9621 17.3951 98.9157L17.3952 98.9156L20.1171 95.7263L20.1171 95.7263L21.0766 94.6019C21.0766 94.6019 21.0766 94.6019 21.0766 94.6019C21.2121 94.4431 21.4048 94.359 21.6036 94.3529L21.623 94.3525L21.6374 94.3527L21.6374 94.3527L21.6413 94.3528C21.8405 94.3587 22.0336 94.4428 22.1692 94.6018L22.1693 94.602L22.1913 94.6277V94.6278L22.2511 94.698L23.1288 95.7263L23.1288 95.7263L23.736 96.438L23.8108 96.5257H23.8111L25.8507 98.9158L25.8508 98.9159C25.8904 98.9623 25.893 99.0146 25.8703 99.0609C25.8472 99.1081 25.7984 99.1458 25.7267 99.1458H23.3853C23.3329 99.1458 23.2858 99.1231 23.2558 99.088Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M6.1445 11.8546L6.14446 11.8545L5.05035 10.5725C5.05035 10.5725 5.05034 10.5725 5.05034 10.5725C4.79133 10.2689 4.33504 10.2451 4.04365 10.4945L4.0436 10.4945C4.0158 10.5183 3.9901 10.544 3.96629 10.5717L3.96566 10.5724L2.87148 11.8543C2.87144 11.8544 2.8714 11.8544 2.87135 11.8545C2.84099 11.8899 2.7939 11.9124 2.74185 11.9124H0.407887C0.336228 11.9124 0.287416 11.8747 0.264282 11.8274C0.241589 11.781 0.244191 11.7287 0.283784 11.6823L0.283856 11.6822L3.00577 8.49292L3.00578 8.49291L3.96527 7.36853C3.96527 7.36852 3.96528 7.36852 3.96528 7.36851C4.10082 7.20973 4.29351 7.12562 4.49227 7.11946L4.51172 7.11914L4.52611 7.11928L4.52611 7.11931L4.52997 7.11942C4.72918 7.12528 4.92232 7.20943 5.05789 7.36843L5.05799 7.36855L5.07995 7.39427V7.39444L5.13979 7.46456L6.01744 8.49289L6.01747 8.49292L6.62466 9.20459L6.69952 9.29232H6.69974L8.73938 11.6824L8.73945 11.6825C8.77906 11.7289 8.78163 11.7812 8.75897 11.8275C8.73587 11.8747 8.68707 11.9124 8.61535 11.9124H6.27393C6.22162 11.9124 6.17447 11.8897 6.1445 11.8546Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M16.9511 18.296L16.9511 18.2959L15.857 17.0139C15.857 17.0139 15.857 17.0139 15.857 17.0139C15.598 16.7103 15.1417 16.6865 14.8503 16.9359L14.8502 16.9359C14.8224 16.9597 14.7967 16.9854 14.7729 17.0131L14.7723 17.0139L13.6781 18.2957C13.6781 18.2958 13.678 18.2958 13.678 18.2959C13.6476 18.3313 13.6005 18.3538 13.5485 18.3538H11.2145C11.1429 18.3538 11.0941 18.3161 11.0709 18.2688C11.0482 18.2224 11.0508 18.1701 11.0904 18.1237L11.0905 18.1236L13.8124 14.9343L13.8124 14.9343L14.7719 13.8099C14.7719 13.8099 14.7719 13.8099 14.7719 13.8099C14.9075 13.6511 15.1002 13.567 15.2989 13.5609L15.3184 13.5605L15.3328 13.5607L15.3327 13.5607L15.3366 13.5608C15.5358 13.5667 15.729 13.6508 15.8645 13.8098L15.8646 13.81L15.8866 13.8357V13.8358L15.9464 13.906L16.8241 14.9343L16.8241 14.9343L17.4313 15.646L17.5062 15.7337H17.5064L19.546 18.1238L19.5461 18.1239C19.5857 18.1703 19.5883 18.2226 19.5656 18.2689C19.5425 18.3161 19.4937 18.3538 19.422 18.3538H17.0806C17.0283 18.3538 16.9811 18.3311 16.9511 18.296Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M28.8086 24.9571L28.8085 24.9571L27.7144 23.675C27.7144 23.675 27.7144 23.675 27.7144 23.675C27.4554 23.3715 26.9991 23.3477 26.7077 23.597L26.7077 23.597C26.6799 23.6208 26.6542 23.6466 26.6303 23.6743L26.6297 23.675L25.5355 24.9569C25.5355 24.9569 25.5355 24.957 25.5354 24.957C25.5051 24.9924 25.458 25.015 25.4059 25.015H23.0719C23.0003 25.015 22.9515 24.9772 22.9283 24.9299C22.9057 24.8836 22.9083 24.8312 22.9478 24.7848L22.9479 24.7848L25.6698 21.5955L25.6698 21.5954L26.6293 20.4711C26.6293 20.4711 26.6293 20.4711 26.6293 20.471C26.7649 20.3123 26.9576 20.2282 27.1563 20.222L27.1758 20.2217L27.1902 20.2218L27.1902 20.2218L27.194 20.222C27.3932 20.2278 27.5864 20.312 27.7219 20.471L27.7221 20.4711L27.744 20.4968V20.497L27.8039 20.5671L28.6815 21.5954L28.6815 21.5955L29.2887 22.3071L29.3636 22.3949H29.3638L31.4034 24.7849L31.4035 24.785C31.4431 24.8314 31.4457 24.8837 31.423 24.93C31.3999 24.9772 31.3511 25.015 31.2794 25.015H28.938C28.8857 25.015 28.8385 24.9922 28.8086 24.9571Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M30.3847 13.6641L30.3847 13.6641L29.2906 12.3821C29.2906 12.382 29.2906 12.382 29.2906 12.382C29.0316 12.0785 28.5753 12.0547 28.2839 12.304L28.2838 12.3041C28.256 12.3279 28.2303 12.3536 28.2065 12.3813L28.2059 12.382L27.1117 13.6639C27.1117 13.664 27.1116 13.664 27.1116 13.6641C27.0812 13.6995 27.0341 13.722 26.9821 13.722H24.6481C24.5765 13.722 24.5277 13.6843 24.5045 13.637C24.4818 13.5906 24.4844 13.5382 24.524 13.4919L24.5241 13.4918L27.246 10.3025L27.246 10.3025L28.2055 9.1781C28.2055 9.17809 28.2055 9.17809 28.2055 9.17808C28.3411 9.0193 28.5337 8.93519 28.7325 8.92903L28.752 8.92871L28.7663 8.92885L28.7663 8.92888L28.7702 8.92899C28.9694 8.93485 29.1626 9.019 29.2981 9.178L29.2982 9.17812L29.3202 9.20384V9.20401L29.38 9.27413L30.2577 10.3025L30.2577 10.3025L30.8649 11.0142L30.9398 11.1019H30.94L32.9796 13.492L32.9797 13.4921C33.0193 13.5384 33.0219 13.5907 32.9992 13.637C32.9761 13.6843 32.9273 13.722 32.8556 13.722H30.5142C30.4619 13.722 30.4147 13.6993 30.3847 13.6641Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M19.3535 5.48446L19.3534 5.48441L18.2593 4.20237C18.2593 4.20236 18.2593 4.20236 18.2593 4.20235C18.0003 3.89882 17.544 3.875 17.2526 4.12434L17.2526 4.12439C17.2248 4.14819 17.1991 4.17392 17.1753 4.2016L17.1746 4.20233L16.0805 5.48422C16.0804 5.48427 16.0804 5.48432 16.0803 5.48437C16.05 5.51978 16.0029 5.5423 15.9508 5.5423H13.6169C13.5452 5.5423 13.4964 5.50458 13.4733 5.45729C13.4506 5.4109 13.4532 5.35854 13.4928 5.31219L13.4928 5.31211L16.2148 2.1228L16.2148 2.12279L17.1743 0.998413C17.1743 0.998406 17.1743 0.998399 17.1743 0.998392C17.3098 0.83961 17.5025 0.755501 17.7013 0.749341L17.7207 0.749023L17.7351 0.749161L17.7351 0.749191L17.739 0.749304C17.9382 0.755163 18.1313 0.839308 18.2669 0.998311L18.267 0.998437L18.2889 1.02415V1.02432L18.3488 1.09444L19.2264 2.12278L19.2265 2.12281L19.8336 2.83447L19.9085 2.9222H19.9087L21.9484 5.31229L21.9484 5.31239C21.988 5.35876 21.9906 5.41105 21.968 5.45735C21.9449 5.50457 21.8961 5.5423 21.8243 5.5423H19.4829C19.4306 5.5423 19.3835 5.51959 19.3535 5.48446Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M45.3183 6.28035L45.3183 6.28031L44.2242 4.99826C44.2242 4.99826 44.2242 4.99825 44.2242 4.99825C43.9652 4.69472 43.5089 4.6709 43.2175 4.92024L43.2174 4.92029C43.1896 4.94408 43.1639 4.96982 43.1401 4.9975L43.1395 4.99823L42.0453 6.28011C42.0453 6.28017 42.0452 6.28022 42.0452 6.28027C42.0148 6.31568 41.9677 6.3382 41.9157 6.3382H39.5817C39.5101 6.3382 39.4612 6.30048 39.4381 6.25319C39.4154 6.20679 39.418 6.15444 39.4576 6.10809L39.4577 6.10801L42.1796 2.9187L42.1796 2.91869L43.1391 1.79431C43.1391 1.7943 43.1391 1.7943 43.1391 1.79429C43.2746 1.63551 43.4673 1.5514 43.6661 1.54524L43.6855 1.54492L43.6999 1.54506L43.6999 1.54509L43.7038 1.5452C43.903 1.55106 44.0961 1.63521 44.2317 1.79421L44.2318 1.79434L44.2538 1.82005V1.82022L44.3136 1.89034L45.1913 2.91868L45.1913 2.9187L45.7985 3.63037L45.8733 3.7181H45.8736L47.9132 6.10819L47.9133 6.10828C47.9529 6.15465 47.9555 6.20695 47.9328 6.25325C47.9097 6.30047 47.8609 6.3382 47.7892 6.3382H45.4478C45.3954 6.3382 45.3483 6.31549 45.3183 6.28035Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M43.3672 18.296L43.3671 18.2959L42.273 17.0139C42.273 17.0139 42.273 17.0139 42.273 17.0139C42.014 16.7103 41.5577 16.6865 41.2663 16.9359L41.2663 16.9359C41.2385 16.9597 41.2128 16.9854 41.1889 17.0131L41.1883 17.0139L40.0941 18.2957C40.0941 18.2958 40.0941 18.2958 40.094 18.2959C40.0636 18.3313 40.0166 18.3538 39.9645 18.3538H37.6305C37.5589 18.3538 37.5101 18.3161 37.4869 18.2688C37.4642 18.2224 37.4668 18.1701 37.5064 18.1237L37.5065 18.1236L40.2284 14.9343L40.2284 14.9343L41.1879 13.8099C41.1879 13.8099 41.1879 13.8099 41.1879 13.8099C41.3235 13.6511 41.5162 13.567 41.7149 13.5609L41.7344 13.5605L41.7488 13.5607L41.7488 13.5607L41.7526 13.5608C41.9518 13.5667 42.145 13.6508 42.2805 13.8098L42.2806 13.81L42.3026 13.8357V13.8358L42.3624 13.906L43.2401 14.9343L43.2401 14.9343L43.8473 15.646L43.9222 15.7337H43.9224L45.962 18.1238L45.9621 18.1239C46.0017 18.1703 46.0043 18.2226 45.9816 18.2689C45.9585 18.3161 45.9097 18.3538 45.838 18.3538H43.4966C43.4443 18.3538 43.3971 18.3311 43.3672 18.296Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M57.4004 18.296L57.4003 18.2959L56.3062 17.0139C56.3062 17.0139 56.3062 17.0139 56.3062 17.0139C56.0472 16.7103 55.5909 16.6865 55.2995 16.9359L55.2995 16.9359C55.2717 16.9597 55.246 16.9854 55.2221 17.0131L55.2215 17.0139L54.1273 18.2957C54.1273 18.2958 54.1273 18.2958 54.1272 18.2959C54.0968 18.3313 54.0498 18.3538 53.9977 18.3538H51.6637C51.5921 18.3538 51.5433 18.3161 51.5201 18.2688C51.4974 18.2224 51.5001 18.1701 51.5396 18.1237L51.5397 18.1236L54.2616 14.9343L54.2616 14.9343L55.2211 13.8099C55.2211 13.8099 55.2211 13.8099 55.2211 13.8099C55.3567 13.6511 55.5494 13.567 55.7481 13.5609L55.7676 13.5605L55.782 13.5607L55.782 13.5607L55.7858 13.5608C55.985 13.5667 56.1782 13.6508 56.3137 13.8098L56.3139 13.81L56.3358 13.8357V13.8358L56.3956 13.906L57.2733 14.9343L57.2733 14.9343L57.8805 15.646L57.9554 15.7337H57.9556L59.9952 18.1238L59.9953 18.1239C60.0349 18.1703 60.0375 18.2226 60.0148 18.2689C59.9917 18.3161 59.9429 18.3538 59.8712 18.3538H57.5298C57.4775 18.3538 57.4303 18.3311 57.4004 18.296Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M42.3183 28.794L42.3183 28.794L41.2242 27.5119C41.2242 27.5119 41.2242 27.5119 41.2242 27.5119C40.9652 27.2084 40.5089 27.1846 40.2175 27.4339L40.2174 27.434C40.1896 27.4578 40.1639 27.4835 40.1401 27.5112L40.1395 27.5119L39.0453 28.7938C39.0453 28.7938 39.0452 28.7939 39.0452 28.7939C39.0148 28.8294 38.9677 28.8519 38.9157 28.8519H36.5817C36.5101 28.8519 36.4612 28.8142 36.4381 28.7669C36.4154 28.7205 36.418 28.6681 36.4576 28.6218L36.4577 28.6217L39.1796 25.4324L39.1796 25.4324L40.1391 24.308C40.1391 24.308 40.1391 24.308 40.1391 24.308C40.2746 24.1492 40.4673 24.0651 40.6661 24.0589L40.6855 24.0586L40.6999 24.0587L40.6999 24.0588L40.7038 24.0589C40.903 24.0647 41.0961 24.1489 41.2317 24.3079L41.2318 24.308L41.2538 24.3337V24.3339L41.3136 24.404L42.1913 25.4323L42.1913 25.4324L42.7985 26.144L42.8733 26.2318H42.8736L44.9132 28.6219L44.9133 28.622C44.9529 28.6683 44.9555 28.7206 44.9328 28.7669C44.9097 28.8141 44.8609 28.8519 44.7892 28.8519H42.4478C42.3954 28.8519 42.3483 28.8292 42.3183 28.794Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
      <path
        d="M15.3008 28.4317L15.3007 28.4317L14.2066 27.1496C14.2066 27.1496 14.2066 27.1496 14.2066 27.1496C13.9476 26.8461 13.4913 26.8223 13.1999 27.0716L13.1998 27.0717C13.1721 27.0955 13.1464 27.1212 13.1225 27.1489L13.1219 27.1496L12.0277 28.4315C12.0277 28.4315 12.0276 28.4316 12.0276 28.4316C11.9972 28.467 11.9502 28.4896 11.8981 28.4896H9.56414C9.49248 28.4896 9.44367 28.4518 9.42053 28.4046C9.39784 28.3582 9.40044 28.3058 9.44003 28.2595L9.44011 28.2594L12.162 25.0701L12.162 25.0701L13.1215 23.9457C13.1215 23.9457 13.1215 23.9457 13.1215 23.9457C13.2571 23.7869 13.4498 23.7028 13.6485 23.6966L13.668 23.6963L13.6824 23.6964L13.6824 23.6965L13.6862 23.6966C13.8854 23.7024 14.0786 23.7866 14.2141 23.9456L14.2142 23.9457L14.2362 23.9714V23.9716L14.296 24.0417L15.1737 25.07L15.1737 25.0701L15.7809 25.7817L15.8558 25.8695H15.856L17.8956 28.2596L17.8957 28.2597C17.9353 28.306 17.9379 28.3583 17.9152 28.4046C17.8921 28.4518 17.8433 28.4896 17.7716 28.4896H15.4302C15.3779 28.4896 15.3307 28.4669 15.3008 28.4317Z"
        stroke="#A7A5C7"
        strokeWidth="0.5"
      />
    </svg>
  )
}
