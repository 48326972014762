function DownloadIcon({ className }: any) {
  return (
    <svg
      stroke="currentColor"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0002 4L11.0002 16.172L5.63617 10.808L4.22217 12.222L10.0046 18.0044L4.49121 18.0044L4.49121 20.001L19.4912 20.001L19.4912 18.0044L13.9958 18.0044L19.7782 12.222L18.3642 10.808L13.0002 16.172L13.0002 4L11.0002 4Z"
      />
    </svg>
  )
}

export default DownloadIcon
