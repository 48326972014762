import { Auth } from "domain/model/Auth"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export interface AddMentorFeedbackNewUseCase {
  invoke(auth: Auth, rating: any, feedback: any, action_items: any, id: any): Promise<any>
}

export class AddMentorFeedbackNew implements AddMentorFeedbackNewUseCase {
  private repository: MyMenteeRepository

  constructor(repository: MyMenteeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, feedback: any, rating: any, action_items: any, id: any) {
    return await this.repository.addMentorFeedbackNew(auth, rating, feedback, action_items, id)
  }
}
