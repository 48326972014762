import NotificationAlert from "assets/svgs/HomePage/NotificationAlert"

const NotificationToast = ({ type, icon, time, description, color }: any) => {
  const timeLeftToStart = time - Math.floor(Date.now() / 1000)
  function formatTime() {
    const hours = Math.floor(timeLeftToStart / (60 * 60))
    const minutes = Math.floor((timeLeftToStart / 60) % 60)

    if (hours > 24)
      return Math.floor(hours / 24) === 1 ? Math.floor(hours / 24) + " day" : Math.floor(hours / 24) + " days"

    const formattedHours = hours < 10 ? "0" + hours : hours
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes

    return `${formattedHours} hr : ${formattedMinutes} min`
  }
  return (
    <div className="flex w-full rounded-lg border-[0.5px] border-[#d3d3d3] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
      <div className="h-full w-[8px] rounded-tl-lg rounded-bl-lg" style={{ backgroundColor: color }}></div>
      <div className="w-full p-4">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            {/* {icon} */}
            <NotificationAlert color={color} />
            <p className="text-base font-medium" style={{ color: color }}>
              {type}
            </p>
          </div>
          {timeLeftToStart > 0 && <p className="text-xs font-semibold text-[#dc2626]">{formatTime()}</p>}
        </div>
        <p className="text-sm font-normal">
          {description}
          {/* Complete your onboarding meeting for better understanding of your
          portal. */}
        </p>
      </div>
    </div>
  )
}

export default NotificationToast
