import { cn } from "core/lib/utils"

const IconHolder = ({ border, children, ...props }: any) => {
  return (
    <div
      className={cn(
        "w-8 h-8 bg-v2-white rounded flex-shrink-0 hover:bg-v2-gray-200 flex items-center justify-center",
        border ? "border border-solid border-v1-gray-200" : ""
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export { IconHolder }
