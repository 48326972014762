import React from "react"

export default function LockIcon(props: any) {
  return (
    <div>
      <svg
        className={props?.className}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5.3335V6.00016H2.66667C2.29848 6.00016 2 6.29864 2 6.66683V14.6668C2 15.035 2.29848 15.3335 2.66667 15.3335H13.3333C13.7015 15.3335 14 15.035 14 14.6668V6.66683C14 6.29864 13.7015 6.00016 13.3333 6.00016H12V5.3335C12 3.12436 10.2091 1.3335 8 1.3335C5.79086 1.3335 4 3.12436 4 5.3335ZM3.33333 14.0002V7.3335H12.6667V14.0002H3.33333ZM7.33335 11.1548C6.81073 10.8531 6.55593 10.2379 6.71212 9.65502C6.86831 9.07211 7.39655 8.66678 8.00002 8.66678C8.6035 8.66678 9.13173 9.07211 9.28792 9.65502C9.44411 10.2379 9.18931 10.8531 8.66669 11.1548V12.6668H7.33335V11.1548ZM10.6667 5.3335V6.00016H5.33333V5.3335C5.33333 3.86074 6.52724 2.66683 8 2.66683C9.47276 2.66683 10.6667 3.86074 10.6667 5.3335Z"
          fill="#333333"
        />
      </svg>
    </div>
  )
}
