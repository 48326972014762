import { cn } from "core/lib/utils"

export default function ButtonGroup({ buttons, active, onChange, ...props }: any) {
  return (
    <div className="flex w-full gap-1 rounded bg-[#ebebeb] p-1.5">
      {buttons.map((button: any, i: number) => (
        <button
          {...props}
          id={button}
          key={i}
          className={cn(
            "w-fit min-w-[150px] select-none rounded-sm py-2 px-4 text-sm font-medium leading-[22px]",
            active === i ? "bg-white text-[#333]" : "bg-[#ebebeb] text-[#646464]"
          )}
          onClick={() => onChange(i)}
        >
          {button}
        </button>
      ))}
    </div>
  )
}
