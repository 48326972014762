import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetStudentListofBatchUseCase {
  invoke(auth: Auth, batch: string, page: number, pageLimit: number): Promise<any>
}

export default class GetStudentListofBatch implements GetStudentListofBatchUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch: string, page: number, pageLimit: number) {
    return await this.repository.getStudentListofBatch(auth, batch, page, pageLimit)
  }
}
