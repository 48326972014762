import { API_V4_ADMIN, API_V4_GET_WEEKLY_REPORT } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import GetWeeklyReport from "domain/model/GetWeeklyReport"
import WeeklyReportDataSource from "data/dataSource/Admin/WeeklyReportDataSource"

export class WeeklyReportAPIDataSourceImpl implements WeeklyReportDataSource {
  async getWeeklyReport(getWeeklyReport: GetWeeklyReport): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_WEEKLY_REPORT), getWeeklyReport)
      return response
    } catch (error) {
      return error
    }
  }
}
