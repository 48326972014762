import React from "react"
import { useParams } from "react-router-dom"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import ConfirmationPopUp from "../Components/ConfirmationPopup"
import useViewModel from "./ViewModel"
import QuestionView from "../Components/QuestionView"
import SolutionView from "../Components/SolutionView"
import TabsGroup from "../Components/TabsGroup"
import SolutionCard from "../Components/SolutionCard"
import SubmitButton from "../Components/SubmitButton"

export default function SubjectiveView() {
  const {
    toast,
    changeToastVisibility,
    pageLoading,
    loading,
    question,
    fetchQuestion,
    TABS,
    activeTab,
    viewSolution,
    isSolutionViewed,
    confirmViewSoluton,
    denyViewSolution,
    handelTabChange,
    solution,
    handleSolutionChange,
    handleSolutionSubmit,
    fetchSubmission,
  } = useViewModel()

  const { id } = useParams()
  React.useEffect(() => {
    fetchQuestion(id!)
  }, [])

  React.useEffect(() => {
    fetchSubmission(id!)
  }, [question])

  React.useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  return (
    <div className="flex w-full h-screen bg-new-editor-dark-700">
      {!isSolutionViewed && viewSolution && (
        <ConfirmationPopUp confirmViewSoluton={confirmViewSoluton} denyViewSolution={denyViewSolution} />
      )}
      <ConditionalLoader isLoading={pageLoading} loader={<Loader />}>
        <div className="flex flex-1 flex-col border-r-2 border-new-neutral-light overflow-y-auto text-white gap-4 p-8">
          <TabsGroup TABS={TABS} activeTab={activeTab} handelTabChange={handelTabChange} />
          {activeTab === 0 ? <QuestionView question={question} /> : <SolutionView question={question} />}
        </div>
        <div className="relative flex-1 flex flex-col p-8 gap-6 text-white">
          <SolutionCard
            loading={loading}
            solution={solution}
            handleSolutionChange={handleSolutionChange}
            handleSolutionSubmit={handleSolutionSubmit}
          />
          <SubmitButton loading={loading} handleSolutionSubmit={handleSolutionSubmit} id={id} />
        </div>
      </ConditionalLoader>
      <Toast data={toast} onClick={changeToastVisibility} />
    </div>
  )
}
