import React from "react"
import { Toast } from "core/constants/types"

export default function useToast() {
  const [toast, setToast] = React.useState<Toast>({})

  function changeToastVisibility(visible: boolean) {
    setToast((toast) => ({ ...toast, visible }))
  }

  function changeToastDetails(status: string, message: string) {
    const details = {
      status,
      message,
    }

    setToast((toast) => ({
      ...toast,
      details,
    }))
  }

  return {
    toast,
    changeToastDetails,
    changeToastVisibility,
  }
}
