import WeeklyReportDataSource from "data/dataSource/Admin/WeeklyReportDataSource"
import GetWeeklyReport from "domain/model/GetWeeklyReport"
import WeeklyReportRepositry from "domain/repository/Admin/WeeklyReport"

export class WeeklyReportRepositoryImpl implements WeeklyReportRepositry {
  private Datasource: WeeklyReportDataSource

  constructor(Datasource: WeeklyReportDataSource) {
    this.Datasource = Datasource
  }

  async getWeeklyReport(weeklyReport: GetWeeklyReport): Promise<any> {
    return await this.Datasource.getWeeklyReport(weeklyReport)
  }
}
