import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Student/StoreRepository"

export interface UpdateStudentAddressUseCase {
  invoke(auth: Auth, address: any): Promise<any>
}

export class UpdateStudentAddress implements UpdateStudentAddressUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, address: any) {
    return this.repository.updateStudentAddress(auth, address)
  }
}
