import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import useCreateBatchViewModel from "./CreateBatchViewModel"
import { CrossIcon } from "core/constants/svgs"
import {
  STR_DATE_AND_TIME_FIELD,
  STR_TEXT_FIELD,
  STR_EMAIL_ID,
  STR_DATE_FIELD,
  STR_MONTH_AND_YEAR_FIELD,
  STR_EMAIL_FIELD,
} from "core/constants/strings"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import React from "react"

export default function CreateBatchView() {
  const {
    toast,
    _selectTrack,
    _currentSelectTrack,
    _batchName,
    _meetingId,
    _batchStartDate,
    _modules,
    _showModules,
    _currentSelectModule,
    _selectModule,
    _googleGroupEmail,
    _payable_start_date,
    track,
    loading,
    refreshed,
    _oneShotDiscount,
    _offerDiscount,
    allMentorshipTemplates,
    selectedMentorshiptemplate,
    setSelectedMentorshipTemplate,
    handleMentorshipTemplateChange,
    _setSelectTrack,
    _setSelectModule,
    _setShowModules,
    _setCurrentSelectModule,
    _setCurrentSelectTrack,
    handleBatchName,
    handleMeetingId,
    handleBatchStartDate,
    handleTrackChange,
    handleModuleChange,
    handleGoogleGroupEmail,
    handlePayable_start_date,
    handleOneShotDiscount,
    handleOfferDiscount,
    changeToastVisibility,
    // getBatches,
    getAllTracks,
    // getInstructorsList,
    createBatches,
    getAllMentorshipTemplates,
  } = useCreateBatchViewModel()

  React.useEffect(() => {
    if (refreshed) {
      // getBatches();
      getAllTracks()
      getAllMentorshipTemplates()
      // getInstructorsList();
    }
  }, [refreshed])

  React.useEffect(() => {
    if (!_selectTrack || _selectTrack.length === 0 || !_modules) {
      return
    }
    _setShowModules([])
    _setShowModules((prev: any) => {
      let updatedShowModules = [...prev]
      _selectTrack.forEach((tr: any) => {
        if (_modules[tr]) {
          _modules[tr].forEach((val: any) => {
            if (val) {
              val.forEach((vl: any) => {
                updatedShowModules = [...updatedShowModules, vl]
              })
            }
          })
        }
      })

      return updatedShowModules
    })
  }, [_selectTrack, _modules])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">Add a new batch</h2>
          <p>Adding a new batch</p>
        </div>
        <div className="round-2 mx-auto flex w-full max-w-[600px] flex-col gap-4 p-6 shadow">
          <Input
            type={STR_TEXT_FIELD}
            placeholder="Batch Name"
            onChange={handleBatchName}
            value={_batchName}
            containerClass="w-full"
          />
          <Input
            type={STR_TEXT_FIELD}
            value={_batchStartDate}
            onChange={handleBatchStartDate}
            placeholder="Batch Start Date"
            onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
            onFocus={(e) => (e.target.type = STR_DATE_FIELD)}
          />
          <div className="flex items-center">
            <div className="w-60">Meeting Id</div>
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Meeting Id"
              onChange={handleMeetingId}
              value={_meetingId}
              containerClass="w-full"
            />
          </div>

          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(_selectTrack)}
              loader={<em className="text-xs text-new-neutral-light">No track selected</em>}
            >
              {_selectTrack.map((track: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {track}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => {
                      _setSelectTrack(removeAtIndex(_selectTrack, i))
                      const flatModules = _modules[track].flat()

                      _setSelectModule((prevSelectModule: any) =>
                        prevSelectModule.filter((mod: any) => !flatModules.includes(mod))
                      )
                      _setCurrentSelectModule("")
                      _setCurrentSelectTrack("")
                    }}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <StandaredSelect
            required
            options={track}
            placeholder="Select Tracks"
            onChange={handleTrackChange}
            value={_currentSelectTrack}
          />
          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(_selectModule)}
              loader={<em className="text-xs text-new-neutral-light">No module selected</em>}
            >
              {_selectModule.map((mod: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {mod}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => {
                      _setSelectModule(removeAtIndex(_selectModule, i))
                      _setCurrentSelectModule("")
                    }}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <StandaredSelect
            required
            options={_showModules}
            placeholder="Select Module"
            onChange={handleModuleChange}
            value={_currentSelectModule}
          />
          <StandaredSelect
            required
            options={allMentorshipTemplates}
            placeholder="Select mentorship template to inherit"
            onChange={handleMentorshipTemplateChange}
            value={selectedMentorshiptemplate}
          />
          <div className="flex items-center">
            <div className="w-60">Google Group Email</div>
            <Input
              type={STR_EMAIL_FIELD}
              placeholder="Google Group Email"
              onChange={handleGoogleGroupEmail}
              value={_googleGroupEmail}
              containerClass="w-full"
            />
          </div>
          <Input
            type={STR_TEXT_FIELD}
            value={_payable_start_date}
            onChange={handlePayable_start_date}
            placeholder="EMI Payable Start Month"
            onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
            onFocus={(e) => (e.target.type = STR_MONTH_AND_YEAR_FIELD)}
          />
          <Input
            type={STR_TEXT_FIELD}
            placeholder="Offer discount in percent"
            onChange={handleOfferDiscount}
            value={_offerDiscount}
            containerClass="w-full"
            min="1"
            max="100"
          />
          <Input
            type={STR_TEXT_FIELD}
            placeholder="One shot payment discount in percent"
            onChange={handleOneShotDiscount}
            value={_oneShotDiscount}
            containerClass="w-full"
          />
          <Button disabled={loading} loading={loading} onClick={createBatches}>
            Add a new Batch
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
