import React, { useEffect } from "react"
import Button from "core/components/new/Button"
import { STR_SUBMIT } from "core/constants/strings"
import { StarIcon, CrossIcon } from "core/constants/svgs"

const questions = [
  "Was the content of the lecture clear and easy to understand?",
  "Did the lecture cover the necessary topics adequately?",
  "How engaging was the speaker's presentation style?",
  "Were the key points and concepts explained clearly?",
  "How satisfied are you with the recorded lecture?",
]

export default function CourseSessionFeedback({
  onSubmit,
  isRecordedFeedbackVisible,
  setIsRecordedFeedbackVisible,
  feedbackData,
}: any) {
  const [ratings, setRatings] = React.useState<number[]>([0, 0, 0, 0, 0])
  const [comment, setComment] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    // If feedbackData is provided, set the initial state based on the feedback data
    if (feedbackData) {
      const newRatings = questions.map((_, index) => feedbackData[index + 6] || 0)
      setRatings(newRatings)
      setComment(feedbackData.recorded_class_suggestion || "")
    }
  }, [feedbackData])

  const handleRatings = (rating: number, index: number) => {
    const newRatings = [...ratings]
    newRatings[index] = rating
    setRatings(newRatings)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)

    await onSubmit(ratings, comment)

    setLoading(false)
  }

  const handleClose = () => {
    if (isRecordedFeedbackVisible) {
      setIsRecordedFeedbackVisible(false)
    }
  }

  return (
    <div className={`space-y-4 ${isRecordedFeedbackVisible ? "" : "hidden"}`}>
      <div className="flex justify-end">
        <button onClick={handleClose}>
          <CrossIcon className="h-6 w-6 text-new-neutral-dark cursor-pointer" />
        </button>
      </div>

      <div className="space-y-2">
        <h2 className="text-new-accent">User Feedback Survey</h2>
        <p className="font-medium">Please share the feedback for this recorded lecture.</p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4 font-medium text-new-neutral-dark">
        <div className="flex flex-col gap-4 px-4">
          {questions.map((question, index) => (
            <div key={index} className="flex items-center justify-between">
              <p className="after-star">{question}</p>
              <div className="flex gap-4">
                {Array(5)
                  .fill(0)
                  .map((_, i) => (
                    <button key={i} type="button" onClick={() => handleRatings(i + 1, index)}>
                      <StarIcon
                        className="h-6 w-6"
                        pathClassName={
                          ratings[index] !== -1 && ratings[index] >= i + 1 ? "fill-[#FBBF24]" : "fill-border"
                        }
                      />
                    </button>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <p>Any other feedback you want to add?</p>
          <div className="w-full">
            <textarea
              value={comment}
              onChange={(e) => {
                if (e.target.value.length <= 400) setComment(e.target.value)
              }}
              className="h-32 w-full rounded border border-new-neutral-light bg-transparent p-2"
              placeholder="Type your suggestion here..."
            />
            <p className="text-new-neutral-light text-xs">{400 - comment.length} Characters left</p>
          </div>
        </div>
        <div className="flex justify-end">
          <Button outlined className="w-[100px]" disabled={loading} loading={loading}>
            {STR_SUBMIT}
          </Button>
        </div>
      </form>
    </div>
  )
}
