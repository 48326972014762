import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface UpdateStoreOrderStatusUseCase {
  invoke(auth: Auth, order_id: any, status: any): Promise<any>
}

export class UpdateStoreOrderStatus implements UpdateStoreOrderStatusUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, order_id: any, status: any) {
    return this.repository.updateStoreOrderStatus(auth, order_id, status)
  }
}
