import { Auth } from "domain/model/Auth"
import MastersRepository from "domain/repository/Student/MastersRepository"

export interface CheckBatchForMastersUseCase {
  invoke(auth: Auth): Promise<any>
}

export class CheckBatchForMasters implements CheckBatchForMastersUseCase {
  private repository: MastersRepository

  constructor(repository: MastersRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.checkBatchForMasters(auth)
  }
}
