import { Auth } from "domain/model/Auth"
import DocumenationFileDataSource from "data/dataSource/Admin/DocumentationFileDataSource"
import DocumentationFileRepository from "domain/repository/Admin/DocumentationFileRepository"

export class DocumentationFilesRepositoryImpl implements DocumentationFileRepository {
  private dataSource: DocumenationFileDataSource

  constructor(dataSource: DocumenationFileDataSource) {
    this.dataSource = dataSource
  }

  async get_documentation_files(auth: Auth): Promise<any> {
    return this.dataSource.get_document_files(auth)
  }

  async create_documentation_file(
    auth: Auth,
    file: File,
    name: string,
    feature: string,
    subFeature: string
  ): Promise<any> {
    return this.dataSource.create_document_file(auth, file, name, feature, subFeature)
  }

  async get_documentation_file(auth: Auth, id: string): Promise<any> {
    return this.dataSource.get_document_file(auth, id)
  }

  async update_documentation_files(
    auth: Auth,
    id: string,
    name: string,
    feature: string,
    subFeature: string
  ): Promise<any> {
    return this.dataSource.update_document_file(auth, id, name, feature, subFeature)
  }
  async delete_documentation_file(auth: Auth, id: string): Promise<any> {
    return this.dataSource.delete_document_file(auth, id)
  }
}
