import Button from "core/components/new/Button"
import { EditIcon, DeleteIcon, EyeIcon } from "core/constants/svgs"

export default function PlacementCard({ data, onEdit, onDelete, onView }: any) {
  return (
    <div className="flex justify-between gap-6 p-6 shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
      <div className="flex flex-col">
        <h3 className="text-new-accent">{data?.name}</h3>
        <p>
          {data.yoe} YOE | {data.position} | {data.company_name}
        </p>
      </div>
      <div className="flex">
        <Button outlined className="mr-2 h-fit w-fit" onClick={onEdit}>
          <EditIcon className="w-5" />
        </Button>
        <Button
          outlined
          className="mr-2 cursor-pointer h-fit w-fit border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
          onClick={onDelete}
        >
          <DeleteIcon className="w-5" />
        </Button>
        <Button outlined className="mr-2 h-fit w-fit" onClick={onView}>
          <EyeIcon className="w-5" />
        </Button>
      </div>
    </div>
  )
}
