export default function Trapezium() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="41" viewBox="0 0 33 41" fill="none">
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M33 0H0V39.012C0 39.8338 0.936264 40.3048 1.5961 39.8149L27.7493 20.3981C31.0526 17.9457 33 14.0744 33 9.96027V0Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
