import UpdateBatchDataSouce from "data/dataSource/Admin/UpdateBatchDataSource"
import { updateBatch, updateBatchBunch } from "domain/model/UpdateBatch"
import { UpdateBatchRepository } from "domain/repository/Admin/UpdateBatchRepository"

export class UpdateBatchRepositoryImpl implements UpdateBatchRepository {
  private dataSource: UpdateBatchDataSouce

  constructor(dataSource: UpdateBatchDataSouce) {
    this.dataSource = dataSource
  }

  async updateBatch(batch: updateBatch) {
    return await this.dataSource.updateBatch(batch)
  }

  async shiftBatch(auth: any, data: any) {
    return await this.dataSource.shiftBatch(auth, data)
  }

  async updateBatchBunch(batch: updateBatchBunch) {
    return await this.dataSource.updateBatchBunch(batch)
  }
}
