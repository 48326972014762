import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import delay from "core/utils/delay"
import HomeAPIDataSourceImpl from "data/API/Student/HomeAPIDataSourceImpl"
import { HomeRepositoryImpl } from "data/repository/Student/HomeRepositoryImpl"
import { TakeSurvey } from "domain/useCase/Student/Home/TakeSurvey"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function HomeAssessmentViewModel() {
  const questions = [
    {
      name: "self_rating",
      question: "How would you rate yourself in Data Structures & Algorithms?",
      type: "rating",
    },
    {
      name: "num_coding_problems",
      question: "How many coding problems have you solved in total on all platforms (like Leetcode, Hacker Rank etc.)?",
      type: "text",
    },
    {
      name: "bool_easy_problems",
      question: "Can you solve easy problems using if else, for loop?",
      type: "radio",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    {
      name: "max_element",
      question:
        "If you have to write it immediately, can you write the code for finding maximum element in an integer array?",
      type: "radio",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    {
      name: "theoretical_knowledge",
      question: "Do you have theoretical knowledge about arrays, linked list, stacks, queues?",
      type: "radio",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    {
      name: "time_complexity",
      question: "What is the worst case time complexity for inserting an element in an array?",
      type: "radio",
      options: [
        {
          label: "I havn't heard about time complexity.",
          value: "I havn't heard about time complexity.",
        },
        { label: "O(1)", value: "O(1)" },
        { label: "O(n)", value: "O(n)" },
      ],
    },
  ]

  const languages = [
    { label: "C++", value: "cpp" },
    { label: "Java", value: "java" },
    { label: "Python", value: "python" },
    { label: "C", value: "c" },
    { label: "C#", value: "c#" },
    { label: "JavaScript", value: "javascript" },
    { label: "Other", value: "other" },
  ]

  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const { auth } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    const is_survey_done = JSON.parse(localStorage.getItem("home-details") || "{}").is_survey_done
    if (is_survey_done) {
      navigate("/home", { replace: true })
    }
  }, [navigate])

  const [assessmentData, setAssessmentData] = React.useState<any>({
    pref_language: "cpp",
    bool_easy_problems: null,
    num_coding_problems: "",
    self_rating: 0,
    max_element: null,
    theoretical_knowledge: null,
    time_complexity: null,
  })

  const takeSurveyUseCase = new TakeSurvey(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  function handleRating(rating: number) {
    setAssessmentData({
      ...assessmentData,
      self_rating: rating,
    })
  }

  function handlePreferedLanguage(language: string) {
    setAssessmentData({
      ...assessmentData,
      pref_language: language,
    })
  }

  function handleNumberOfCodingProblems(nums: string) {
    setAssessmentData({
      ...assessmentData,
      num_coding_problems: nums,
    })
  }

  function handleQuestionStatus(name: string, value: any) {
    setAssessmentData({
      ...assessmentData,
      [name]: value,
    })
  }

  async function handleSubmit(e: any) {
    e.preventDefault()
    if (
      assessmentData.self_rating === 0 ||
      assessmentData.self_rating === null ||
      assessmentData.bool_easy_problems === null ||
      assessmentData.max_element === null ||
      assessmentData.theoretical_knowledge === null ||
      assessmentData.time_complexity === null ||
      assessmentData.num_coding_problems === ""
    ) {
      changeToastVisibility(true)
      changeToastDetails("failure", "Please answer all the questions")
      return
    }

    await takeSurveyUseCase.invoke(auth, assessmentData)
    changeToastVisibility(true)
    changeToastDetails("success", "Survey submitted successfully")
    await delay(2000)
    navigate("/home", { replace: true })
  }

  return {
    toast,
    questions,
    languages,
    assessmentData,
    handleRating,
    handlePreferedLanguage,
    handleNumberOfCodingProblems,
    handleQuestionStatus,
    handleSubmit,
    changeToastVisibility,
  }
}
