export default function JoinTelegramComponent() {
  return (
    <>
      <div className="w-full h-12 relative justify-between items-center bg-[#fbfbfb] shadow-inherit">
        <div className="w-8 h-8 left-[24px] top-[8px] absolute">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" width="32" height="32">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#37BBFE" stopOpacity="1" />
                <stop offset="100%" stopColor="#007DBB" stopOpacity="1" />
              </linearGradient>
            </defs>
            <path
              d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"
              fill="url(#grad1)"
            />
          </svg>
        </div>
        <div className="left-[64px] top-[14px] absolute text-neutral-700 text-sm font-['Inter'] leading-snug items-center">
          Join our Telegram Channel for the latest Placement updates.
        </div>

        <div className="px-2 py-1 right-[18px] top-[10px] absolute bg-[#fbfbfb] rounded-sm justify-start items-start inline-flex">
          <a
            href="https://t.me/+_4mRmaIE8lhiNDc1"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#2779d9] text-sm font-semibold font-['Inter'] underline"
          >
            Click to join
          </a>
        </div>
      </div>
      <div className="h-[1px] bg-slate-300 w-full my-[0.5px]"></div>
    </>
  )
}
