import { Auth } from "domain/model/Auth"
import { TPaymentDocumentItem } from "domain/model/Payment"

import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface UpdatePaymentDocumentUseCase {
  invoke(auth: Auth, student_email: string, document: TPaymentDocumentItem, id: number): Promise<any>
}

export class UpdatePaymentDocument implements UpdatePaymentDocumentUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_email: string, document: TPaymentDocumentItem, id: number) {
    return await this.repository.updatePaymentDocument(auth, student_email, document, id)
  }
}
