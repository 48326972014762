import React, { useState } from "react"
import ProgressRoadmapViewModel from "./ProgressRoadmapViewModel"

import RightBar from "./components/DailyReport/RightBar"
import Header from "./components/DailyReport/Header"
// import ProgressRoadmapLayout from "./layout/ProgressRoadmapLayout";
import DashboardLayout from "core/layouts/DashboardLayout"
// import { act } from "react-dom/test-utils";
import DailyReport from "./components/DailyReport/DailyReport"
import MonthlyReport from "./components/MonthlyReport/MonthlyReportView"
import OverallReport from "./components/OverallReport/OverallReportView"
import WeeklyReport from "./components/WeeklyReport/WeeklyReportView"
export default function ProgressRoadmapView() {
  const [active, setActive] = useState(3)
  const {
    refreshed,
    id,
    student,
    joiningDate,
    getUserData,
    navigate,
    formatDateString,
    getDayOfWeek,
    getPreviousDate,
    daily,
    dataLoading,
    getDailyReportData,
    todayDate,
    ind,
    setInd,
    handleIncInd,
    handleDecInd,
  } = ProgressRoadmapViewModel()
  React.useEffect(() => {
    if (refreshed) {
      getUserData(id)
    }
  }, [refreshed, navigate, id])
  return (
    <div className="">
      <DashboardLayout
        rightBar={<RightBar student={student} />}
        header={<Header points={student.points || student.total_points} />}
      >
        <div className="font-montserrat flex-row space-y-6 p-6 overflow-hidden">
          <div>
            <div className="mb-[2px] text-[24px] font-bold text-[#312E81]">Progress Roadmap</div>
            {/* <div>{joiningDate}</div> */}
            <div className="text-[14px]  leading-6 text-[#737373]">Monitor the student's progress here</div>
          </div>
          <div className="flex space-x-4">
            <button
              className={`py2 h-[35px] rounded-sm border-[0.5px] border-[#6B6B6B] px-4 text-[12px] font-medium text-[6B6B6B] hover:border-[#312E81] hover:bg-[#312E81] hover:text-white ${
                active === 1 && "border-[#312E81] bg-[#312E81] text-white "
              } transition duration-150 ease-in-out`}
              onClick={() => setActive(1)}
            >
              Overall Report
            </button>
            <button
              className={`py2 h-[35px] rounded-sm border-[0.5px] border-[#6B6B6B] px-4 text-[12px] font-medium text-[6B6B6B] hover:border-[#312E81] hover:bg-[#312E81] hover:text-white ${
                active === 2 && "border-[#312E81] bg-[#312E81] text-white "
              } transition duration-150 ease-in-out`}
              onClick={() => setActive(2)}
            >
              Daily Report
            </button>
            <button
              className={`py2 h-[35px] rounded-sm border-[0.5px] border-[#6B6B6B] px-4 text-[12px] font-medium text-[6B6B6B] hover:border-[#312E81] hover:bg-[#312E81] hover:text-white ${
                active === 3 && "border-[#312E81] bg-[#312E81] text-white "
              } transition duration-150 ease-in-out`}
              onClick={() => setActive(3)}
            >
              Weekly Report
            </button>
            <button
              className={`py2 h-[35px] rounded-sm border-[0.5px] border-[#6B6B6B] px-4 text-[12px] font-medium text-[6B6B6B] hover:border-[#312E81] hover:bg-[#312E81] hover:text-white ${
                active === 4 && "border-[#312E81] bg-[#312E81] text-white "
              } transition duration-150 ease-in-out`}
              onClick={() => setActive(4)}
            >
              Monthly Report
            </button>
          </div>
          {active === 1 && (
            <div>
              <OverallReport />
            </div>
          )}
          {active === 2 && (
            <>
              <DailyReport
                id={id}
                joiningDate={joiningDate}
                formatDateString={formatDateString}
                getDayOfWeek={getDayOfWeek}
                getPreviousDate={getPreviousDate}
                daily={daily}
                dataLoading={dataLoading}
                fetchthing={getDailyReportData}
                todayDate={todayDate}
                ind={ind}
                setInd={setInd}
                handleIncInd={handleIncInd}
                handleDecInd={handleDecInd}
                student={student}
              />
            </>
          )}
          {active === 3 && (
            <div>
              <WeeklyReport />
            </div>
          )}
          {active === 4 && (
            <div>
              <MonthlyReport />
            </div>
          )}
        </div>
      </DashboardLayout>
    </div>
  )
}
