import { API_SHIFT_BATCH, API_UPDATE_BATCH_BUNCH, API_V3_CHANGE_BATCH, API_V4_ADMIN } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { updateBatch, updateBatchBunch } from "domain/model/UpdateBatch"
import UpdateBatchDataSouce from "data/dataSource/Admin/UpdateBatchDataSource"

export default class UpdateBatchAPIDataSourceImpl implements UpdateBatchDataSouce {
  async updateBatch(batch: updateBatch): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V3_CHANGE_BATCH), batch)
    return response
  }

  async shiftBatch(auth: any, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_SHIFT_BATCH), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async updateBatchBunch(batch: updateBatchBunch): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_BATCH_BUNCH), batch)
    return response
  }
}
