import { RedeemCoinsDataSource } from "data/dataSource/Student/RedeemCoinsDataSource"
import { Auth } from "domain/model/Auth"
import { MentorSessionCoupon, RedeemStoreCoins } from "domain/model/RedeemCoins"
import { RedeemCoinsRepository } from "domain/repository/Student/RedeemCoinsRepository"

export class RedeemCoinsRepositoryImpl implements RedeemCoinsRepository {
  private dataSource: RedeemCoinsDataSource

  constructor(dataSource: RedeemCoinsDataSource) {
    this.dataSource = dataSource
  }

  async redeemCoins(redeemStoreCoins: RedeemStoreCoins): Promise<any> {
    return await this.dataSource.redeemCoins(redeemStoreCoins)
  }

  async getOrderHistory(auth: Auth, email: string): Promise<any> {
    return await this.dataSource.getOrderHistory(auth, email)
  }

  async bookMentorSessionCoupon(mentorSessionCoupon: MentorSessionCoupon): Promise<any> {
    return await this.dataSource.bookMentorSessionCoupon(mentorSessionCoupon)
  }

  async getExtraSession(auth: Auth): Promise<any> {
    return await this.dataSource.getExtraSession(auth)
  }
}
