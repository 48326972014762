import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import { CreateProjectCard } from "./components/CreateProjectCard"
import ProjectsSectionViewModel from "./CreateProjectsSectionViewModel"

export default function EditProjectView() {
  const { auth, projectId, getProjectDatabyId, handleClick } = ProjectsSectionViewModel()
  React.useEffect(() => {
    getProjectDatabyId()
  }, [auth, projectId])

  return (
    <DashboardLayout>
      <div className="px-6 py-6">
        <div className="flex flex-row justify-between">
          <div className="font-bold text-indigo-900 text-2xl">Edit Project</div>
          <div>
            <Button onClick={() => handleClick()} outlined failure>
              Go Back
            </Button>
          </div>
        </div>
        <CreateProjectCard />
      </div>
    </DashboardLayout>
  )
}
