import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import useEditBatchViewModel from "./EditBatchViewModel"
import { CrossIcon } from "core/constants/svgs"
import {
  STR_DATE_AND_TIME_FIELD,
  STR_TEXT_FIELD,
  STR_EMAIL_ID,
  STR_DATE_FIELD,
  STR_MONTH_AND_YEAR_FIELD,
  STR_EMAIL_FIELD,
} from "core/constants/strings"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import React from "react"

export default function EditBatchView() {
  const {
    toast,
    _selectTrack,
    _currentSelectTrack,
    _batchName,
    _meetingId,
    _batchStartDate,
    _modules,
    _showModules,
    _currentSelectModule,
    _selectModule,
    _googleGroupEmail,
    _payable_start_date,
    track,
    loading,
    refreshed,
    _setSelectTrack,
    _setSelectModule,
    _setShowModules,
    _setCurrentSelectModule,
    _setCurrentSelectTrack,
    handleBatchName,
    handleMeetingId,
    handleBatchStartDate,
    handleTrackChange,
    handleModuleChange,
    handleGoogleGroupEmail,
    handlePayable_start_date,
    changeToastVisibility,
    getBatches,
    getAllTracks,
    // getInstructorsList,
    selectBatch,
    setSelectBatch,
    setCurrentSelectBatch,
    batch,
    handleBatchChange,
    currentSelectBatch,
    handleCancel,
    handleEdit,
    handleSaveChanges,
    editMode,
    getBatchDetail,
  } = useEditBatchViewModel()

  React.useEffect(() => {
    if (refreshed) {
      getBatches()
      getAllTracks()
      // getInstructorsList();
    }
  }, [refreshed])

  React.useEffect(() => {
    if (!_selectTrack || _selectTrack.length === 0 || !_modules) {
      return
    }
    _setShowModules([])
    _setShowModules((prev: any) => {
      let updatedShowModules = [...prev]
      _selectTrack.forEach((tr: any) => {
        if (_modules[tr]) {
          _modules[tr].forEach((val: any) => {
            if (val) {
              val.forEach((vl: any) => {
                updatedShowModules = [...updatedShowModules, vl]
              })
            }
          })
        }
      })

      return updatedShowModules
    })
  }, [_selectTrack, _modules])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">Edit Batch Details</h2>
          <p>Edit Batch Details</p>
        </div>
        <div className="round-2 mx-auto flex w-full max-w-[600px] flex-col gap-4 p-6 shadow">
          <StandaredSelect
            required
            options={batch}
            placeholder="Select Batch"
            onChange={handleBatchChange}
            value={currentSelectBatch}
          />
          <Input
            type={STR_TEXT_FIELD}
            value={_batchStartDate}
            onChange={handleBatchStartDate}
            placeholder="Batch Start Date"
            onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
            onFocus={(e) => (e.target.type = STR_DATE_FIELD)}
            disabled={editMode ? false : true}
          />
          <div className="flex items-center">
            <div className="w-60">Meeting Id</div>
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Meeting Id"
              onChange={handleMeetingId}
              value={_meetingId}
              containerClass="w-full"
              disabled={editMode ? false : true}
            />
          </div>

          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(_selectTrack)}
              loader={<em className="text-xs text-new-neutral-light">No track selected</em>}
            >
              {_selectTrack.map((track: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {track}
                  {editMode && (
                    <button
                      className="rounded bg-new-solid-black p-1 text-new-solid-white"
                      onClick={() => {
                        _setSelectTrack(removeAtIndex(_selectTrack, i))
                        const flatModules = _modules[track].flat()

                        _setSelectModule((prevSelectModule: any) =>
                          prevSelectModule.filter((mod: any) => !flatModules.includes(mod))
                        )
                        _setCurrentSelectModule("")
                        _setCurrentSelectTrack("")
                      }}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </button>
                  )}
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          {editMode && (
            <StandaredSelect
              required
              options={track}
              placeholder="Select Tracks"
              onChange={handleTrackChange}
              value={_currentSelectTrack}
            />
          )}
          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(_selectModule)}
              loader={<em className="text-xs text-new-neutral-light">No module selected</em>}
            >
              {_selectModule.map((mod: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {mod}
                  {editMode && (
                    <button
                      className="rounded bg-new-solid-black p-1 text-new-solid-white"
                      onClick={() => {
                        _setSelectModule(removeAtIndex(_selectModule, i))
                        _setCurrentSelectModule("")
                      }}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </button>
                  )}
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          {editMode && (
            <StandaredSelect
              required
              options={_showModules}
              placeholder="Select Module"
              onChange={handleModuleChange}
              value={_currentSelectModule}
            />
          )}
          <div className="flex items-center">
            <div className="w-60">Google Group Email</div>
            <Input
              type={STR_EMAIL_FIELD}
              placeholder="Google Group Email"
              onChange={handleGoogleGroupEmail}
              value={_googleGroupEmail}
              containerClass="w-full"
              disabled={editMode ? false : true}
            />
          </div>
          <Input
            type={STR_TEXT_FIELD}
            value={_payable_start_date}
            onChange={handlePayable_start_date}
            placeholder="EMI Payable Start Month"
            onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
            onFocus={(e) => (e.target.type = STR_MONTH_AND_YEAR_FIELD)}
            disabled={editMode ? false : true}
          />
          <div className={`${editMode ? "flex" : ""}`}>
            <Button
              className={`w-full ${editMode ? "w-1/2 mr-2" : ""}`}
              onClick={editMode ? handleSaveChanges : handleEdit}
            >
              {editMode ? "Save Changes" : "Edit Batch Details"}
            </Button>
            {editMode && (
              <Button className="w-1/2 bg-red-500" onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
