import React from "react"
import Avatar from "react-avatar"
import { Link } from "react-router-dom"
import DashboardLayout from "core/layouts/DashboardLayout"
import bg from "assets/images/background.png"
import Button from "core/components/new/Button"
import useProfileEditViewModel from "./ProfileEditViewModel"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import ResumeDropdown from "presentation/Student/Profile/components/ResumeDropdown"
import { isEmpty } from "core/utils/misc"

export default function ProfileEditView() {
  const {
    toast,
    resumes,
    SKILLS_LIST,
    profileImage,
    profileData,
    profileDataLoading,
    handleUpdateProfileData,
    handleSubmitProfile,
    changeToastVisibility,
    uploadProfilePicture,
    handleSkillChange,
    handleRedirectToResumePage,
    fetchStudentProfileDetails,
    fetchResumes,
  } = useProfileEditViewModel()

  React.useEffect(() => {
    fetchStudentProfileDetails()
    fetchResumes()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-2">
          <h2 className="text-new-accent">Complete your user profile!</h2>
          <p className="text-new-neutral-dark">
            You need to complete your user profile in order to start with the placement cell.
          </p>
        </div>
        <div className="rounded p-8 shadow">
          {profileDataLoading ? (
            <Loader />
          ) : (
            <>
              <div className="relative">
                <img src={bg} alt="background" className="h-[340px] rounded-lg" />
                <div className="absolute top-full left-8 -translate-y-1/2 overflow-hidden rounded-lg">
                  <Avatar className="object-cover" name={profileData.name} src={profileImage} size="160" />
                  <div className="absolute top-0 hover:before:absolute hover:before:top-0 hover:before:left-0 hover:before:flex hover:before:h-[160px] hover:before:w-[160px] hover:before:items-center hover:before:justify-center hover:before:bg-[#000000a0] hover:before:font-medium hover:before:text-white hover:before:content-['Upload']">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={uploadProfilePicture}
                      className=" absolute top-0 left-0 h-[160px] w-[160px] cursor-pointer bg-green-600 opacity-0"
                    />
                  </div>
                </div>
              </div>
              <form className="mt-[100px] space-y-8" onSubmit={handleSubmitProfile}>
                <h2 className="ml-[38px] text-2xl font-bold leading-[29px] text-new-accent">User Profile</h2>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <label htmlFor="fullname" className="after-star block flex-1 text-sm font-medium text-gray-700">
                      Full name
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="text"
                        id="fullname"
                        name="name"
                        value={profileData.name}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="resume" className="flex flex-1 flex-col text-sm font-medium text-gray-700">
                      Your resume
                    </label>
                    <div className="flex w-full flex-1 items-center gap-4">
                      {/* <div className="shrink-0 flex flex-col self-start">
                    {profileImage?.name && (
                      <div className="text-xs text-[#A3A3A3] font-medium">
                        {profileImage && profileImage?.name}
                      </div>
                    )}
                    {profileImage && profileImage?.size && (
                      <div className="text-xs text-new-neutral font-medium">
                        {(profileImage?.size / 1000).toFixed(0)} KB
                      </div>
                    )}
                  </div> */}
                      {/* <input
                      type="file"
                      name="resume"
                      className="absolute inset-0 opacity-0"
                      onChange={uploadResume}
                    /> */}
                      {/* <Tooltip title="Upload resume will be available soon!">
                        <span> */}
                      <div className="flex w-full items-center justify-between gap-4">
                        <Button outlined type="button" className="w-fit" onClick={handleRedirectToResumePage}>
                          Update
                        </Button>
                        {resumes?.length > 0 ? (
                          <a
                            className="text-sm font-medium leading-[17px] text-new-accent underline"
                            href={resumes?.at(-1)?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Resume(Latest)
                          </a>
                        ) : (
                          <p className="text-new-neutral-light">No resume</p>
                        )}
                        {resumes?.length > 1 && (
                          <div className="ml-auto">
                            <ResumeDropdown resumes={resumes} />
                          </div>
                        )}
                      </div>

                      {/* </span>
                      </Tooltip> */}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="gender" className="after-star flex-1 text-sm font-medium text-gray-700">
                      Gender
                    </label>
                    <div className="flex-1">
                      <select
                        required
                        id="gender"
                        name="gender"
                        value={profileData.gender}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      >
                        <option value="">Select gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="email" className="flex-1 text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <div className="flex-1">
                      {/* <input
                        required
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleUpdateProfileData}
                        placeholder="Type your email here!"
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      /> */}
                      <div className="flex h-9 w-full items-center rounded-md border border-gray-300 px-3 py-1.5 text-sm">
                        {profileData.email}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="batch" className="flex-1 text-sm font-medium text-gray-700">
                      Batch
                    </label>
                    <div className="flex-1">
                      {/* <input
                        required
                        type="text"
                        id="batch"
                        name="batch"
                        onChange={handleUpdateProfileData}
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      /> */}
                      <div className="w-full rounded-md border border-gray-300 px-3 py-1.5 text-sm">
                        {profileData.batch}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="linkedin" className="after-star flex-1 text-sm font-medium text-gray-700">
                      LinkedIn Profile
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="text"
                        id="linkedin"
                        name="linkedin_profile"
                        value={profileData.linkedin_profile}
                        onChange={handleUpdateProfileData}
                        placeholder="Type your link here!"
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="leetcode" className="flex-1 text-sm font-medium text-gray-700">
                      LeetCode Profile
                    </label>
                    <div className="flex-1">
                      {/* <input
                        required
                        type="text"
                        id="leetcode"
                        name="leetcode"
                        onChange={handleUpdateProfileData}
                        placeholder="Type your link here!"
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      /> */}
                      <div className="flex h-9 w-full items-center rounded-md border border-gray-300 px-3 py-1.5 text-sm">
                        {profileData.leetcode || "Not found"}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="years-of-experience"
                      className="after-star flex-1 text-sm font-medium text-gray-700"
                    >
                      Years of experience
                    </label>
                    <div className="flex-1">
                      <select
                        required
                        id="years-of-experience"
                        name="experience"
                        value={profileData.experience}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      >
                        <option value="">Select your experience</option>
                        {Array.from(Array(21).keys()).map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="position" className="after-star flex-1 text-sm font-medium text-gray-700">
                      Current / Last position
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="text"
                        id="position"
                        name="current_position"
                        placeholder="Type here"
                        value={profileData.current_position}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="current_ctc" className="after-star flex-1 text-sm font-medium text-gray-700">
                      Current CTC
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="number"
                        id="current_ctc"
                        name="current_ctc"
                        placeholder="LPA"
                        min={0}
                        max={100}
                        value={profileData.current_ctc}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="after-star flex-1 text-sm font-medium text-gray-700">Skills and Tech Stacks</div>
                    <div className="grid flex-1 grid-flow-col grid-rows-[repeat(9,1fr)] gap-1">
                      {SKILLS_LIST.map((skill, skillIndex) => (
                        <label key={skillIndex} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            checked={!isEmpty(profileData.skills) && profileData.skills?.includes(skill)}
                            name="skills"
                            className="form-checkbox rounded text-primary-600"
                            onChange={() => handleSkillChange(skillIndex)}
                          />
                          <span className="text-sm font-medium text-new-neutral">{skill}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="dream_company" className="after-star flex-1 text-sm font-medium text-gray-700">
                      Dream Company
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="text"
                        id="dream_company"
                        name="dream_company"
                        value={profileData.dream_company}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="preferred_role" className="after-star flex-1 text-sm font-medium text-gray-700">
                      Any preferred role
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="text"
                        id="preferred_role"
                        name="preferred_role"
                        value={profileData.preferred_role}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="college" className="after-star flex-1 text-sm font-medium text-gray-700">
                      College
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="text"
                        id="college"
                        name="college"
                        value={profileData.college}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="year_of_passing" className="after-star flex-1 text-sm font-medium text-gray-700">
                      Year of passing
                    </label>
                    <div className="flex-1">
                      <input
                        required
                        type="number"
                        id="year_of_passing"
                        name="year_of_passing"
                        min={1990}
                        max={2100}
                        value={profileData.year_of_passing}
                        onChange={handleUpdateProfileData}
                        className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center gap-4">
                  <Link to={"/placement"}>
                    <Button type="button" outlined className="w-40">
                      Cancel
                    </Button>
                  </Link>
                  <Button small className="w-40">
                    Save Profile
                  </Button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
