import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import StandaredSelect from "core/components/StandaredSelect"
import MentorsViewModel from "./MentorsViewModel"
import MentorCard from "./components/MentorCard"

export default function MentorsView() {
  const {
    auth,
    mentorEmail,
    allMentorsList,
    mentorList,
    toast,
    loading,
    handleSearchByMentor,
    handleSearch,
    fetchMentorList,
    fetchAllMentorList,
    changeToastVisibility,
  } = MentorsViewModel()

  React.useEffect(() => {
    fetchMentorList("")
    fetchAllMentorList()
  }, [auth])

  if (isEmpty(mentorList)) {
    return (
      <DashboardLayout>
        <Loader />
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="flex items-end gap-4">
          <select className="h-9 w-[209px]" onChange={handleSearchByMentor} value={mentorEmail}>
            <option value="">Mentor</option>
            {allMentorsList?.map((instructor: any, i: number) => (
              <option value={instructor?.email} key={i}>
                {instructor?.name}
              </option>
            ))}
          </select>
        </div>
        {!isEmpty(mentorList) ? (
          <div className="mx-4 flex flex-col space-y-6">
            <div className="space-y-6">
              {!isEmpty(mentorList?.all_mentors) ? (
                mentorList?.all_mentors?.map((data: any, i: number) => <MentorCard key={i} data={data} />)
              ) : (
                <div>
                  <h4>No Data Found</h4>
                </div>
              )}{" "}
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
