import Button from "core/components/new/Button"
import Avatar from "react-avatar"
import useTimer from "core/hooks/useTimer"
import { Tooltip } from "@mui/material"

export default function ContestRegisteredCard({ data, onClick }: any) {
  const [timer] = useTimer(parseInt(data?.start_time) * 1000)
  const minsLeft = (parseInt(data?.start_time) * 1000 - Date.now()) / 1000 / 60

  return (
    <div className="flex gap-4 rounded bg-new-solid-red-light p-8">
      <Avatar
        className="shrink-0 rounded-sm object-cover shadow-[0px_4px_8px_rgba(232,108,108,0.15)]"
        src={""}
        name={data?.name}
        size="120"
      />
      <div className="space-y-2">
        <h2 className="text-new-accent">{data?.name ?? "hello name"}</h2>
        <p className="text-new-neutral-dark line-clamp-2">Description</p>
      </div>
      <div className="flex flex-1 flex-col items-end justify-between">
        <h3 className="whitespace-nowrap text-new-failure">{timer}</h3>
        <Tooltip title={minsLeft > 30 ? "Contest has not started yet" : ""} placement="top">
          <span>
            <Button className="w-fit whitespace-nowrap disabled:opacity-50" onClick={onClick} disabled={minsLeft > 30}>
              Start Exam
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}
