import { placementDetail } from "domain/model/Placement"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"

export interface DeletePlacementUsecase {
  invoke(request: placementDetail): Promise<any>
}

export class DeletePlacement implements DeletePlacementUsecase {
  private repository: AddPlacementRepository

  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }

  async invoke(request: placementDetail): Promise<any> {
    return await this.repository.deletePlacement(request)
  }
}
