export const isExternalProblem = (url: string) => {
  const internalURLs = ["platform.bosscoderacademy.com", "student.bosscoderacademy.com"]

  const isInternal = internalURLs.reduce((acc, internal) => {
    return acc || url.includes(internal)
  }, false)

  return !isInternal
}

export function getNumericFieldValue(obj: any, field: string) {
  if (obj && obj.hasOwnProperty(field)) {
    const value = obj[field]
    if (typeof value === "number") {
      return value
    } else if (typeof value === "string") {
      const numericValue = parseFloat(value)
      if (!isNaN(numericValue)) {
        return numericValue
      }
    }
  }
  return 0 // Return 0 when the field is not present
}
