import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface GetInstructorDetailsUseCase {
  invoke(auth: Auth, instructor_id: string): Promise<any>
}

export default class GetInstructorDetails implements GetInstructorDetailsUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string) {
    return await this.repository.getInstructorDetails(auth, instructor_id)
  }
}
