import { UpdateSSMCallDetail } from "domain/model/UpdateSSMCallDetail"
import { UpdateSSMCallDetailRepository } from "domain/repository/Admin/UpdateSSMCallDetailRepository"

export interface UpdateSSMCallDetailUseCase {
  invoke(updateSSMCallDetail: UpdateSSMCallDetail): Promise<any>
}

export class UpdateSSMCallDetails implements UpdateSSMCallDetailUseCase {
  private repository: UpdateSSMCallDetailRepository

  constructor(repository: UpdateSSMCallDetailRepository) {
    this.repository = repository
  }

  async invoke(updateSSMCallDetail: UpdateSSMCallDetail) {
    return await this.repository.updateSSMCallDetail(updateSSMCallDetail)
  }
}
