import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface ImportTemplateCodeUseCase {
  invoke(topic: string, auth: Auth): Promise<any>
}

export class ImportTemplateCode implements ImportTemplateCodeUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(topic: string, auth: Auth) {
    return this.repository.importTemplateCode(topic, auth)
  }
}
