import { Auth } from "domain/model/Auth"
import { GroupMentorSession } from "domain/model/GroupMentorSession"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface GetGroupMentorSessionUseCase {
  invoke(auth: Auth, mentor_id: string, batch: string, month: string): Promise<any>
}

export default class GetGroupMentorSession implements GetGroupMentorSessionUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, batch: string, month: string) {
    return await this.repository.getGroupMentorSession(auth, mentor_id, batch, month)
  }
}
