import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Student/StoreRepository"

export interface OrderHistoryUseCase {
  invoke(auth: Auth): Promise<any>
}

export class OrderHistory implements OrderHistoryUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.orderHistory(auth)
  }
}
