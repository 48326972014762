import { Auth } from "domain/model/Auth"
import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface UpdateTransactionDetailAdminUseCase {
  invoke(auth: Auth, paymentData: any): Promise<any>
}

export class UpdateTransactionDetailAdmin implements UpdateTransactionDetailAdminUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, paymentData: any) {
    return await this.repository.updateTransactionDetailAdmin(auth, paymentData)
  }
}
