import { STR_CANCELLED, STR_FAILURE, STR_PAST, STR_SUCCESS, STR_UPCOMING } from "core/constants/strings"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import MyMenteeAPIDataSourceImpl from "data/API/Mentor/MyMenteeAPIDataSourceImpl"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"
import { MyMenteeRepositoryImpl } from "data/repository/Mentor/MyMenteeRepositoryImpl"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import { Auth } from "domain/model/Auth"
import { AddMentorFeedbackNew } from "domain/useCase/Mentor/MyMentee/AddMentorFeedbackNew"
import { CancelMentorSessionByMentor } from "domain/useCase/Mentor/MyMentee/CancelMentorSessionByMentor"
import { CancelMentorSessionByMentorOld } from "domain/useCase/Mentor/MyMentee/CancelMentorSessionByMentorOld"
import { MarkMenteeAbsent } from "domain/useCase/Mentor/MyMentee/MarkMenteeAbsent"
import { MarkTakenElsewhere } from "domain/useCase/Mentor/MyMentee/MarkTakenElsewhere"
import CancelSession from "domain/useCase/Mentor/Session/CancelSession"
import GetMentorSessions from "domain/useCase/Mentor/Session/GetMentorSessions"
import WriteStudentFeedback from "domain/useCase/Mentor/Session/WriteStudentFeedback"
import { CancelMentorSession } from "domain/useCase/Student/Mentor/CancelMentorSession"
import React from "react"

export default function AllSessionViewModel() {
  const TABS = [STR_UPCOMING, STR_PAST, STR_CANCELLED]
  const MENTOR_SESSION_TYPES = ["Mentor Sessions", "Goal Setting Sessions"]

  const { auth, refreshed } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { mentor } = useApp()

  const [activeTab, setActiveTab] = React.useState(0)
  const [pastGoalSettingSesions, setPastGoalSettingSessions] = React.useState<any>([])
  const [sessionActiveTab, setSessionActiveTab] = React.useState(0)
  const [upcomingSessions, setUpcomingSessions] = React.useState<any>([])
  const [upcomingGoalSettingSessions, setUpcomingGoalSettingSessions] = React.useState<any>([])
  const [pastSessions, setPastSessions] = React.useState<any>([])
  const [cancelledSessions, setCancelledSessions] = React.useState<any>([])
  const [loading, setLoading] = React.useState(true)
  const [modalLoading, setModalLoading] = React.useState(false)
  const [reviewIndex, setReviewIndex] = React.useState(-1)
  const [feedbackIndex, setFeedbackIndex] = React.useState<any>(-1)
  const [cancelIndex, setCancelIndex] = React.useState(-1)
  const [openModal, setOpenModal] = React.useState(false)
  const [mentorFeedback, setMentorFeedback] = React.useState("")
  const [mentorActionItems, setMentorActionItems] = React.useState("")
  const [isCancellationInProgress, setIsCancellationInProgress] = React.useState(false)
  const [isGoalSettingMentor, setIsGoalSettingMentor] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [indexToBeMarkedAbsent, setIndexToBeMarkedAbsent] = React.useState("")
  const [indexToBeMarkedTakenElsewhere, setIndexToBeMarkedTakenElsewhere] = React.useState("")
  const [cancelReason, setCancelReason] = React.useState("")
  const [isCancelModal, setIsCancelModal] = React.useState<boolean>(false)

  const getMentorSessionsUseCase = new GetMentorSessions(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))
  const cancelSessionUseCase = new CancelSession(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))
  const cancelMentorSessionByMentorOldUseCase = new CancelMentorSessionByMentorOld(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const writeStudentFeedbackUseCase = new WriteStudentFeedback(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )
  const addMentorFeedbackNewUseCase = new AddMentorFeedbackNew(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const cancelMentorSessionByMentorUseCase = new CancelMentorSessionByMentor(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const markMenteeAbsentUseCase = new MarkMenteeAbsent(new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl()))
  const markTakenElsewhereUseCase = new MarkTakenElsewhere(new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl()))

  const handleTabChange = (i: number) => {
    setActiveTab(i)
  }
  const handleSessionTabChange = (i: number) => {
    setSessionActiveTab(i)
  }
  const formatTime = (time: number | undefined) => {
    if (!time && time !== 0) return "" // handle undefined or null cases
    const hours = Math.floor(time) // Get the integer part (hours)
    const minutes = Math.round((time - hours) * 60) // Get the decimal part, convert to minutes

    // Format the time to ensure two digits for minutes
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`
  }
  const fetchMentorSessions = async () => {
    setLoading(true)

    const response = await getMentorSessionsUseCase.invoke(auth)

    const sessions = response?.response

    setCancelledSessions(sessions?.cancelled_sessions)

    const upcoming = sessions?.mentor_sessions?.filter((s: any) => !s?.done && !s?.id?.includes("msid1"))

    setUpcomingSessions(upcoming)
    const upcomingGoalSetting = sessions?.mentor_sessions?.filter((s: any) => !s?.done && s?.id?.includes("msid1"))
    const pastGoalSetting = sessions?.mentor_sessions?.filter((s: any) => s?.done && s?.id?.includes("msid1"))

    setUpcomingGoalSettingSessions(upcomingGoalSetting)
    setPastGoalSettingSessions(pastGoalSetting)

    const past = sessions?.mentor_sessions?.filter((s: any) => s?.done)
    setPastSessions(past)

    setLoading(false)
  }

  async function cancelMentorSessionByMentor(session_id: any, type: any, student_id: any) {
    setIsCancellationInProgress(true)
    setOpenModal(false)
    const response = await cancelMentorSessionByMentorUseCase.invoke(auth, session_id, type, student_id, cancelReason)
    setIsCancellationInProgress(false)
    changeToastDetails(STR_SUCCESS, "Your mentor session has been cancelled succesfully!")
    changeToastVisibility(true)
    setIsCancelModal(false)
  }

  async function markMenteeAbsent(session_id: any) {
    const response = await markMenteeAbsentUseCase.invoke(auth, session_id)
    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Mentee Marked absent successfully")
      changeToastVisibility(true)
      window.location.reload()
    } else {
      changeToastDetails(STR_FAILURE, "Unable to mark mentee absent at the moment. Retry later.")
      changeToastVisibility(true)
    }
  }

  async function markTakenElsewhere(session_id: any) {
    const response = await markTakenElsewhereUseCase.invoke(auth, session_id)
    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Taken Elsewhere maked successfully")
      changeToastVisibility(true)
      window.location.reload()
    } else {
      changeToastDetails(STR_FAILURE, "Unable to mark taken elsewhere at the moment. Retry later.")
      changeToastVisibility(true)
    }
  }

  async function cancelMentorSessionByMentorOld(date: any, start: any, end: any) {
    setIsCancellationInProgress(true)
    const response = await cancelMentorSessionByMentorOldUseCase.invoke(auth, date, start, end)
    setOpenModal(false)
    setIsCancellationInProgress(false)
  }

  const handleReviewIndexChange = (index: number) => {
    setReviewIndex(index)
    handleOpenModal()
  }

  const handleFeedbackIndexChange = (index: number) => {
    setFeedbackIndex(index)
    handleOpenModal()
  }

  const handleCancelIndexChange = (index: number) => {
    setCancelIndex(index)
  }

  const handleCloseModal = () => {
    setReviewIndex(-1)
    setFeedbackIndex(-1)

    setMentorFeedback("")
    setMentorActionItems("")

    setOpenModal(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCancelSession = async () => {
    setModalLoading(true)

    const { date, start, end } = upcomingSessions[cancelIndex]
    const response = await cancelSessionUseCase.invoke(auth, date, start, end)
    if (!response?.success) {
      if (response?.error === "Cannot cancel less than 2 hours prior to session") {
        changeToastDetails(STR_FAILURE, "Cannot cancel less than 2 hours prior to session")
      } else {
        changeToastDetails(STR_FAILURE, "Unknown error occurred")
      }
      changeToastVisibility(true)
      setModalLoading(false)
      handleCloseModal()

      return
    }

    const newUpcomingSessions = upcomingSessions?.filter((_: any, i: number) => i !== cancelIndex)
    setUpcomingSessions(newUpcomingSessions)

    setModalLoading(false)

    handleCloseModal()

    changeToastDetails(STR_SUCCESS, "Session cancelled successfully")
    changeToastVisibility(true)
  }

  const handleCancelSessionOld = async () => {
    setModalLoading(true)

    const { date, start, end } = upcomingSessions[cancelIndex]
    const response = await cancelMentorSessionByMentorOldUseCase.invoke(auth, date, start, end)

    if (!response?.success) {
      if (response?.error === "Cannot cancel less than 2 hours prior to session") {
        changeToastDetails(STR_FAILURE, "Cannot cancel less than 2 hours prior to session")
      } else {
        changeToastDetails(STR_FAILURE, "Unknown error occurred")
      }
      changeToastVisibility(true)

      handleCloseModal()

      return
    }

    const newUpcomingSessions = upcomingSessions?.filter((_: any, i: number) => i !== cancelIndex)
    setUpcomingSessions(newUpcomingSessions)

    setModalLoading(false)

    handleCloseModal()

    changeToastDetails(STR_SUCCESS, "Session cancelled successfully")
    changeToastVisibility(true)
  }

  const handleFeedbackChange = (e: any) => {
    setMentorFeedback(e.target.value)
  }

  const handleActionItemsChange = (e: any) => {
    setMentorActionItems(e.target.value)
  }

  const handleSubmitFeedback = async () => {
    setModalLoading(true)

    const response = await writeStudentFeedbackUseCase.invoke(feedbackIndex, mentorFeedback, mentorActionItems)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Unknown error occurred")
      changeToastVisibility(true)

      handleCloseModal()

      return
    }

    const newPastSessions = pastSessions.map((s: any, i: number) => ({
      ...s,
      mentor_feedback:
        i === feedbackIndex
          ? {
              feedback: mentorFeedback,
              action_items: mentorActionItems,
            }
          : s?.mentor_feedback,
    }))
    setPastSessions(newPastSessions)

    setModalLoading(false)

    handleCloseModal()

    changeToastDetails(STR_SUCCESS, "Feedback submitted successfully")
    changeToastVisibility(true)
  }

  const handleNewSubmitFeedback = async (room_id: string, rating: any, comment: any) => {
    setModalLoading(true)

    const response = await writeStudentFeedbackUseCase.invoke(room_id, rating, comment)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Unknown error occurred")
      changeToastVisibility(true)

      handleCloseModal()

      return
    }

    const newPastSessions = pastSessions.map((s: any, i: number) => ({
      ...s,
      mentor_feedback:
        i === feedbackIndex
          ? {
              feedback: mentorFeedback,
              action_items: mentorActionItems,
            }
          : s?.mentor_feedback,
    }))
    setPastSessions(newPastSessions)

    setModalLoading(false)

    handleCloseModal()

    changeToastDetails(STR_SUCCESS, "Feedback submitted successfully")
    changeToastVisibility(true)
  }

  return {
    TABS,
    activeTab,
    upcomingSessions,
    pastSessions,
    cancelledSessions,
    refreshed,
    loading,
    reviewIndex,
    feedbackIndex,
    cancelIndex,
    openModal,
    setOpenModal,
    toast,
    modalLoading,
    mentorFeedback,
    mentorActionItems,
    isCancellationInProgress,
    MENTOR_SESSION_TYPES,
    sessionActiveTab,
    isGoalSettingMentor,
    mentor,
    isOpen,
    setIsOpen,
    markMenteeAbsent,
    markTakenElsewhere,
    setIsGoalSettingMentor,
    handleSessionTabChange,
    cancelMentorSessionByMentor,
    handleTabChange,
    fetchMentorSessions,
    formatTime,
    handleReviewIndexChange,
    handleFeedbackIndexChange,
    handleCancelIndexChange,
    handleCloseModal,
    handleOpenModal,
    handleCancelSession,
    changeToastVisibility,
    handleFeedbackChange,
    handleActionItemsChange,
    setMentorFeedback,
    setMentorActionItems,
    handleSubmitFeedback,
    handleCancelSessionOld,
    cancelMentorSessionByMentorOld,
    upcomingGoalSettingSessions,
    pastGoalSettingSesions,
    indexToBeMarkedAbsent,
    indexToBeMarkedTakenElsewhere,
    setIndexToBeMarkedAbsent,
    setIndexToBeMarkedTakenElsewhere,
    handleNewSubmitFeedback,
    cancelReason,
    setCancelReason,
    isCancelModal,
    setIsCancelModal,
  }
}
