import { pdf } from "@react-pdf/renderer"
import useToast from "core/hooks/useToast"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import { UploadResume } from "domain/useCase/Student/Profile/UploadResume"
import React from "react"
import { useNavigate } from "react-router-dom"
import ResumeTemplate from "./components/ResumeTemplate"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import delay from "core/utils/delay"
import { useAuth } from "core/context/auth"

export default function ResumeCreateViewModel() {
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()

  const { auth } = useAuth()

  const [resumeData, setResumeData] = React.useState({
    name: "",
    email: "",
    mobile: "",
    linkedin: "",
    educations: [
      {
        institution: "",
        degree: "",
        place: "",
        yearStart: "",
        yearEnd: "",
        gpa: "",
      },
    ],
    experiences: [
      {
        company: "",
        designation: "",
        place: "",
        start: "",
        end: "",
        description: "",
      },
    ],
    projects: [
      {
        title: "",
        description: "",
      },
    ],
    skills: {
      languages: [],
      technologies: [],
    },
  })
  const [language, setLanguage] = React.useState("")
  const [technology, setTechnology] = React.useState("")
  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const uploadResumeUseCase = new UploadResume(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const handleTextInputChange = (e: any) => {
    const { name, value } = e.target
    setResumeData((prev) => ({ ...prev, [name]: value }))
  }

  const handleEducationChange = (e: any, index: number) => {
    const { name, value } = e.target
    setResumeData((prev) => ({
      ...prev,
      educations: prev.educations.map((education, i) => (i === index ? { ...education, [name]: value } : education)),
    }))
  }

  const handleExperienceChange = (e: any, index: number) => {
    const { name, value } = e.target
    setResumeData((prev) => ({
      ...prev,
      experiences: prev.experiences.map((experience, i) =>
        i === index ? { ...experience, [name]: value } : experience
      ),
    }))
  }

  const handleProjectChange = (e: any, index: number) => {
    const { name, value } = e.target
    setResumeData((prev) => ({
      ...prev,
      projects: prev.projects.map((project, i) => (i === index ? { ...project, [name]: value } : project)),
    }))
  }

  const handleAddMoreExperience = () => {
    setResumeData((prev) => ({
      ...prev,
      experiences: [
        ...prev.experiences,
        {
          company: "",
          designation: "",
          place: "",
          description: "",
          start: "",
          end: "",
        },
      ],
    }))
  }

  const handleAddMoreEducation = () => {
    setResumeData((prev: any) => ({
      ...prev,
      educations: [
        ...prev.educations,
        {
          institution: "",
          degree: "",
          place: "",
          year: { start: "", end: "" },
          gpa: "",
        },
      ],
    }))
  }

  const handleRemoveEducation = (index: number) => {
    setResumeData((prev) => ({
      ...prev,
      educations: prev.educations.filter((_, i) => i !== index),
    }))
  }

  const handleRemoveExperience = (index: number) => {
    setResumeData((prev) => ({
      ...prev,
      experiences: prev.experiences.filter((_, i) => i !== index),
    }))
  }

  const handleAddMoreProject = () => {
    setResumeData((prev) => ({
      ...prev,
      projects: [
        ...prev.projects,
        {
          title: "",
          description: "",
        },
      ],
    }))
  }

  const handleRemoveProject = (index: number) => {
    setResumeData((prev) => ({
      ...prev,
      projects: prev.projects.filter((_, i) => i !== index),
    }))
  }

  const handleAddSkill = async (e: any, skillType: string) => {
    e.preventDefault()

    if (skillType === "languages") {
      if (language.trim() === "") {
        return
      }

      setResumeData((prev: any) => ({
        ...prev,
        skills: {
          ...prev.skills,
          languages: [...prev.skills.languages, language.trim()],
        },
      }))

      setLanguage("")
    } else {
      if (technology.trim() === "") {
        return
      }

      setResumeData((prev: any) => ({
        ...prev,
        skills: {
          ...prev.skills,
          technologies: [...prev.skills.technologies, technology],
        },
      }))

      setTechnology("")
    }
  }

  const handleRemoveSkill = (index: number, skillType: string) => {
    setResumeData((prev: any) => ({
      ...prev,
      skills: {
        ...prev.skills,
        [skillType]: prev.skills[skillType].filter((_: any, i: number) => i !== index),
      },
    }))
  }

  const handleSubmitResumeDocument = async () => {
    if (
      resumeData.name.trim() === "" ||
      resumeData.email.trim() === "" ||
      resumeData.mobile.trim() === "" ||
      resumeData.linkedin.trim() === "" ||
      resumeData.educations.some(
        (education: any) =>
          education.institution.trim() === "" ||
          education.degree.trim() === "" ||
          education.place.trim() === "" ||
          education.yearStart.trim() === "" ||
          education.yearEnd.trim() === "" ||
          education.gpa.trim() === ""
      ) ||
      resumeData.experiences.some(
        (experience: any) =>
          experience.company.trim() === "" ||
          experience.designation.trim() === "" ||
          experience.place.trim() === "" ||
          experience.start.trim() === "" ||
          experience.end.trim() === "" ||
          experience.description.trim() === ""
      ) ||
      resumeData.projects.some((project: any) => project.title.trim() === "" || project.description.trim() === "") ||
      resumeData.skills.languages.length === 0 ||
      resumeData.skills.technologies.length === 0
    ) {
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      changeToastVisibility(true)
      return
    }

    setIsUploading(true)
    const blob = await pdf(<ResumeTemplate data={resumeData} />).toBlob()
    const response = await uploadResumeUseCase.invoke(auth, blob, "Resume.pdf")
    setIsUploading(false)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, response?.error || "Something went wrong!")
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Resume uploaded successfully")
      changeToastVisibility(true)
      await delay(2000)
      navigate("/placement/edit")
    }
  }

  const handleCancelResumeUpdate = () => {
    navigate("/placement/edit")
  }

  return {
    toast,
    resumeData,
    language,
    technology,
    isUploading,
    setLanguage,
    setTechnology,
    handleTextInputChange,
    changeToastVisibility,
    handleAddMoreEducation,
    handleRemoveEducation,
    handleAddMoreExperience,
    handleRemoveExperience,
    handleAddMoreProject,
    handleRemoveProject,
    handleAddSkill,
    handleRemoveSkill,
    handleEducationChange,
    handleExperienceChange,
    handleProjectChange,
    handleSubmitResumeDocument,
    handleCancelResumeUpdate,
  }
}
