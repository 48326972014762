import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { cn } from "core/lib/utils"

const TooltipProvider = TooltipPrimitive.Provider
const BaseTooltip = TooltipPrimitive.Root
const TooltipTrigger = TooltipPrimitive.Trigger
const TooltipContent = TooltipPrimitive.Content

interface TooltipProps extends React.ComponentPropsWithoutRef<typeof TooltipContent> {
  info: string
}

const Tooltip = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, TooltipProps>(
  ({ className, sideOffset = 4, info, children, ...props }, ref) => (
    <TooltipProvider>
      <BaseTooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent
          ref={ref}
          sideOffset={sideOffset}
          className={cn(
            "z-50 overflow-hidden rounded-md border bg-v2-gray-600 text-v2-white px-3 py-1.5 max-w-[200px] text-sm shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className
          )}
          {...props}
        >
          <p>{info}</p>
        </TooltipContent>
      </BaseTooltip>
    </TooltipProvider>
  )
)
Tooltip.displayName = TooltipPrimitive.Content.displayName

export { Tooltip }
