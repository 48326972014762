import { postAPI, postAPIForFiles } from "core/utils/axios"
import {
  API_GET_FEE_AFTER_DISCOUNT,
  API_CREATE_PAYMENT_STUDENT,
  API_V4_STUDENT,
  GET_STUDENT_PAYMENT_DETAILS,
  GET_STUDENT_PAYMENT_DETAILS_ALL,
  CREATE_STUDENT_EMI,
  DELETE_PAYMENT_DOCUMENT,
  GET_PAYMENT_DOCUMENTS,
  GET_STUDENT_EMI,
  UPLOAD_PAYMENT_DOCUMENT,
  API_V4_ADMIN,
  API_GET_WALK_THROUGH_DAY,
  API_CREATE_PAYMENT_ADMIN,
  API_PAYMENT_DETAIL_ADMIN,
  API_UPDATE_TRANSACTION_ADMIN,
  API_GET_PAYMENT_METHODS,
} from "core/constants/strings"
import pn from "core/utils/pn"
import PaymentDataSource from "data/dataSource/Student/PaymentDataSource"
import { Auth } from "domain/model/Auth"
import { TStudentEMI } from "domain/model/Payment"
import { Server } from "core/utils/axios"
const server = new Server()

export class PaymentAPIDataSourceImpl implements PaymentDataSource {
  async getMethodDiscount(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_WALK_THROUGH_DAY), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getPaymentMethod(auth: Auth): Promise<any> {
    try {
      const respone = await postAPI(pn(API_V4_STUDENT, API_GET_PAYMENT_METHODS), {
        id_token: auth.id_token,
      })
      return respone
    } catch (error) {
      return error
    }
  }
  async getFeeAfterDiscount(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_FEE_AFTER_DISCOUNT), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createPayment(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_PAYMENT_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getPaymentDetailAdmin(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_PAYMENT_DETAIL_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async updateTransactionDetailAdmin(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_TRANSACTION_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getPaymentDocuments(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, GET_PAYMENT_DOCUMENTS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async uploadPaymentDocument(auth: Auth, file: File, filename: string, documentId: number): Promise<any> {
    try {
      let data = new FormData()
      data.append("id_token", auth.id_token)
      data.append("file", file)
      data.append("filename", filename)
      data.append("documentId", documentId.toString())
      const response = await postAPIForFiles(pn(API_V4_STUDENT, UPLOAD_PAYMENT_DOCUMENT), data)
      return response
    } catch (error) {
      return error
    }
  }

  async deletePaymentDocument(auth: Auth, documentId: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, DELETE_PAYMENT_DOCUMENT), {
        id_token: auth.id_token,
        documentId: documentId,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentEMI(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, GET_STUDENT_EMI), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createStudentEMI(auth: Auth, student_payment_emi: TStudentEMI): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, CREATE_STUDENT_EMI), {
        id_token: auth.id_token,
        student_payment_emi: student_payment_emi,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentPaymentDetails(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, GET_STUDENT_PAYMENT_DETAILS), {
      id_token: auth.id_token,
    })
    return response
  }

  async getStudentPaymentDetailsAll(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, GET_STUDENT_PAYMENT_DETAILS_ALL), {
      id_token: auth.id_token,
    })
    return response
  }

  async createPaymentOneShot(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_CREATE_PAYMENT_STUDENT), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async scratchCardDetails(auth: Auth, data: any) {
    try {
      const response = await server.post(pn("student", "offer", "scratch_card"), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async fetchScratchCardDetails(auth: Auth) {
    try {
      const response = await server.get(pn("student", "offer", "fetch_scratch_cards"), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
