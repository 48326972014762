import getTimeLeft from "core/utils/timeLeft"
import React from "react"

export default function useTimer(timestamp: any, interval = 1000, deps: any[] = []) {
  const [timer, setTimer] = React.useState<any>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  React.useEffect(() => {
    if (timestamp) setTimer(getTimeLeft(timestamp))

    const timeInterval = setInterval(() => {
      if (timestamp) setTimer(getTimeLeft(timestamp))
    }, interval)

    return () => clearInterval(timeInterval)
  }, deps)

  const { days, hours, minutes, seconds } = timer
  const formattedTimerParts = []

  if (days > 0) {
    formattedTimerParts.push(`${days} Days`)
  }

  if (hours > 0) {
    formattedTimerParts.push(`${hours} Hrs`)
  }

  if (minutes > 0) {
    formattedTimerParts.push(`${minutes} Min`)
  }

  formattedTimerParts.push(`${seconds} Sec`)

  const formattedTimerString = formattedTimerParts.join(" : ")

  return [formattedTimerString, timer]
}

export function contestTimerFormatter(timer: any) {
  const { days, hours, minutes, seconds } = timer

  const daysStr = days < 10 ? `0${days}` : days.toString()
  const hoursStr = hours < 10 ? `0${hours}` : hours.toString()
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString()
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds.toString()

  const formattedTimerParts = []

  if (days > 0) {
    formattedTimerParts.push(`${daysStr}:`)
  }

  if (hours > 0) {
    formattedTimerParts.push(`${hoursStr}:`)
  }

  formattedTimerParts.push(`${minutesStr}:${secondsStr}`)

  return formattedTimerParts.join("")
}
