import {
  API_V4_ADMIN,
  API_V4_GET_BATCH_TEMPLATE_GENERIC,
  API_V4_UPDATE_BATCH_TEMPLATE_GENERIC,
  STR_EDIT_MENTOR_TEMPLATE_FOR_STUDENT,
  STR_GET_MENTOR_NAME_FROM_ID,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import NewMentorSessionsDataSource from "data/dataSource/Admin/NewMentorSessionsDataSource"
import { Auth } from "domain/model/Auth"
// import NewMentorSessionsDataSource from "data/dataSource/Admin/NewMentorSessionsDataSource";

export default class NewMentorSessionsAPIDataSourceImpl implements NewMentorSessionsDataSource {
  async getBatchTemplateGeneric(auth: Auth, data: any): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_BATCH_TEMPLATE_GENERIC), {
      id_token: auth?.id_token,
      data,
    })
    return response
  }
  async updateBatchTemplateGeneric(auth: Auth, data: any): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_UPDATE_BATCH_TEMPLATE_GENERIC), {
      id_token: auth?.id_token,
      data,
    })
    return response
  }
  async editMentorTemplateForStudent(auth: Auth, template: any): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_EDIT_MENTOR_TEMPLATE_FOR_STUDENT), {
      id_token: auth?.id_token,
      template,
    })
    return response
  }
  async getMentorNameFromId(auth: Auth, data: any): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_MENTOR_NAME_FROM_ID), {
      id_token: auth?.id_token,
      data,
    })
    return response
  }
}
