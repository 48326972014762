import { updateBatchBunch } from "domain/model/UpdateBatch"
import { UpdateBatchRepository } from "domain/repository/Admin/UpdateBatchRepository"

export interface UpdateBatchUseCase {
  invoke(batch: updateBatchBunch): Promise<any>
}
export class UpdateBatchBunch implements UpdateBatchUseCase {
  private repository: UpdateBatchRepository

  constructor(repository: UpdateBatchRepository) {
    this.repository = repository
  }

  async invoke(batch: updateBatchBunch): Promise<any> {
    return await this.repository.updateBatchBunch(batch)
  }
}
