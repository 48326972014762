import { jobApplicationStatus } from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface setApplicationStatusUseCase {
  invoke(request: jobApplicationStatus): Promise<any>
}

export class SetApplicationStatus implements setApplicationStatusUseCase {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: jobApplicationStatus): Promise<any> {
    return await this.repository.setApplicationStatus(request)
  }
}
