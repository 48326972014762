import React from "react"
import { Tooltip } from "@mui/material"
import { CoinIcon, DiscordIcon } from "core/constants/svgs"
import { STR_DISCORD_LINK } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import { isEmpty } from "core/utils/misc"
import { cn } from "core/lib/utils"

export default function Header() {
  const { auth } = useAuth()
  const { student } = useApp()
  const [communityLinkVisible, setCommunityLinkVisible] = React.useState(false)

  const points = student?.points
  const isDSML = student?.batch?.includes("DSML")
  const isKCE = student?.batch?.includes("KCE")
  const isAccelerator = student?.batch?.includes("Accelerator")
  const isNewBatch = student?.batch_start_timestamp

  React.useEffect(() => {
    if (!isEmpty(auth) && auth.role === "admin") {
      setCommunityLinkVisible(true)
    } else if (!isEmpty(student)) {
      setCommunityLinkVisible(!(isDSML || isKCE || isNewBatch > 1707676200)) // 12/02/24
    }
  }, [auth, student])

  const community = {
    link: STR_DISCORD_LINK,
    icon: <DiscordIcon className="h-8 w-8" />,
    text: "Join Discord Here",
  }

  return (
    <header className="flex w-full items-center gap-2 border-b border-border bg-new-solid-white px-6 py-2">
      {communityLinkVisible && (
        <a
          className={cn("flex items-center gap-3 underline underline-offset-2 text-[#9D21C9]")}
          href={community.link}
          target="_blank"
          rel="noreferrer"
        >
          {community.icon}
          <span className="text-sm font-medium leading-[19px]">{community.text}</span>
        </a>
      )}
      {auth?.role === "user" && !isAccelerator && (
        <Tooltip title={`${points} Coins`}>
          <div className="ml-auto flex items-center gap-4">
            <span>
              <CoinIcon className="h-8 w-8" />
            </span>
            <span className="text-2xl font-bold text-[#FBBF24]">{points}</span>
          </div>
        </Tooltip>
      )}
    </header>
  )
}
