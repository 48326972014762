import { cn } from "core/lib/utils"

export default function TabGroup({ buttons, active, onChange, ...props }: any) {
  return (
    <div className="inline-flex rounded-[4px] gap-[2px] px-[6px] py-[4px] bg-[#EBEBEB]">
      {buttons.map((button: any, i: number) => (
        <button
          {...props}
          id={button}
          key={i}
          className={cn(
            "flex rounded-[4px] py-[9px] px-[16px] text-[14px] font-[500]",
            active === i
              ? "bg-[#fff] text-[#333] shadow-[-1px_1px_2px_0px_rgba(34,41,48,0.05)]"
              : "text-[#646464] hover:bg-[#fff]"
          )}
          onClick={() => onChange(i)}
        >
          {button}
        </button>
      ))}
    </div>
  )
}
