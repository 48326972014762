import React from "react"

export default function ThumbUp(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.73865 5.33862H14.0063C14.36 5.33862 14.6992 5.47912 14.9493 5.72923C15.1994 5.97933 15.3399 6.31855 15.3399 6.67225V8.07524C15.3401 8.24952 15.3061 8.42214 15.2399 8.58335L13.1761 13.5945C13.1258 13.7167 13.0402 13.8211 12.9304 13.8946C12.8206 13.9681 12.6914 14.0073 12.5593 14.0073H1.33674C1.15989 14.0073 0.990281 13.937 0.865228 13.8119C0.740176 13.6869 0.669922 13.5173 0.669922 13.3404V6.67225C0.669922 6.4954 0.740176 6.32579 0.865228 6.20074C0.990281 6.07569 1.15989 6.00543 1.33674 6.00543H3.6586C3.76536 6.00546 3.87056 5.97986 3.96536 5.93077C4.06016 5.88169 4.14179 5.81056 4.20339 5.72337L7.83955 0.570866C7.88552 0.505727 7.9533 0.459215 8.03062 0.439763C8.10793 0.420312 8.18966 0.429209 8.26098 0.464842L9.47059 1.06965C9.811 1.23979 10.0829 1.52129 10.2413 1.86737C10.3996 2.21345 10.4347 2.60328 10.3408 2.97208L9.73865 5.33862ZM4.67083 7.06434V12.6736H12.1125L14.0063 8.07524V6.67225H9.73865C9.53553 6.67222 9.33511 6.6258 9.15267 6.53653C8.97023 6.44725 8.81059 6.31749 8.68593 6.15713C8.56127 5.99677 8.47488 5.81005 8.43336 5.61123C8.39184 5.4124 8.39629 5.20672 8.44635 5.00987L9.04849 2.644C9.06734 2.57021 9.06035 2.49218 9.02868 2.42291C8.99702 2.35363 8.94259 2.29729 8.87445 2.26325L8.43369 2.0432L5.29297 6.49221C5.12627 6.72826 4.91289 6.92164 4.67083 7.06434ZM3.33719 7.33907H2.00356V12.6736H3.33719V7.33907Z"
        fill="currentColor"
      />
    </svg>
  )
}
