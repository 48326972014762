import { updateBatch } from "domain/model/UpdateBatch"
import { UpdateBatchRepository } from "domain/repository/Admin/UpdateBatchRepository"

export interface UpdateBatchUseCase {
  invoke(batch: updateBatch): Promise<any>
}

export class UpdateBatches implements UpdateBatchUseCase {
  private repository: UpdateBatchRepository

  constructor(repository: UpdateBatchRepository) {
    this.repository = repository
  }

  async invoke(batch: updateBatch): Promise<any> {
    return await this.repository.updateBatch(batch)
  }
}
