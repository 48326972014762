import visa from "../assets/logo/Visa.png"
import Samsung from "../assets/logo/Samsung.png"
import JPMorgan from "../assets/logo/JP C&C.png"
import CommerceIQ from "../assets/logo/CIQ.png"
import Bewakoof from "../assets/logo/Bewakoof.png"
import Thoughtworks from "../assets/logo/Thoughtworks.png"
import Deloitte from "../assets/logo/Deloitte.png"
import Borderfree from "../assets/logo/borderfree technologies.png"
import Byjus from "../assets/logo/Byjus.png"
import Pathor from "../assets/logo/Path-or.png"
// import Visioapps from "../assets/logo/Visioapps.png";
import Navis from "../assets/logo/Navis.png"
import Synopsys from "../assets/logo/Synopsis.png"
import Cisco from "../assets/logo/Cisco.png"
import Ltimindtree from "../assets/logo/LTIM.png"

import HitachiV from "../assets/logo/Hitachi Vantara.png"
import Bajaj from "../assets/logo/BajajFinserv.png"
import Redhat from "../assets/logo/Redhat.png"
import Juniper from "../assets/logo/juniper.png"
import Pysphere from "../assets/logo/Pysphere.png"
import Skypoint from "../assets/logo/skypoint.png"
import Telstra from "../assets/logo/Telstra.png"
import Gainsight from "../assets/logo/Gainsight.png"
import Nykaa from "../assets/logo/Nykaa.png"
import Siemens from "../assets/logo/siemens.png"
import Oneture from "../assets/logo/Oneture.png"
import Upswing from "../assets/logo/Upswing.png"
import Principal from "../assets/logo/Principal.png"
import Persistent from "../assets/logo/Persistent.png"
import Olam from "../assets/logo/Olam.png"
import Acies from "../assets/logo/Acies.png"
import Miramed from "../assets/logo/MiraMed.png"
import Netenrich from "../assets/logo/Neterich.png"
import Electronic_Arts from "../assets/logo/Electronic Arts.png"
import Numerator from "../assets/logo/Numerator.png"
import Akidata from "../assets/logo/Akridata.png"
import Ibaset from "../assets/logo/IbaseT.png"
import Mphasis from "../assets/logo/Mphasis.png"
import Freecharge from "../assets/logo/Freecharge.png"
import Sprinklr from "../assets/logo/Sprinklr.png"
import Hcl from "../assets/logo/HCL Software.png"
import Dassault_System from "../assets/logo/Dassault Systemes.png"
import Yokogawa from "../assets/logo/Yokogawa.png"
import Senasys from "../assets/logo/Senasys.png"
import Ust from "../assets/logo/UST.png"
import Oracle from "../assets/logo/oracle.png"
import Tekion from "../assets/logo/Tekion.png"
import Spurtreetechnologies from "../assets/logo/spurtree.png"
import HDFC from "../assets/logo/HDFC BANK.png"
import National_Instaruments from "../assets/logo/National Instaruments.png"
import Nagarro from "../assets/logo/Nagarro.png"
import Publisis_Sapient from "../assets/logo/Publicis Sapient.png"
import ZS_Associates from "../assets/logo/ZS Associates.png"
import Lenskart from "../assets/logo/Lenskart.png"
import Wells_Fargo from "../assets/logo/Wells fargo.png"
import Justransform from "../assets/logo/Justransform.png"
import ServiceNow from "../assets/logo/Service now.png"
import Amazon from "../assets/logo/Amazon.png"
import Hashedin from "../assets/logo/Hashedin.png"
import Hyland from "../assets/logo/Hyland.png"
import Google from "../assets/logo/Google.png"
import Technex from "../assets/logo/Technex.png"
import Pure_Software from "../assets/logo/puresoftware.png"
import Volkswagen from "../assets/logo/volkswagen.png"
import Jubilant_Foodworks from "../assets/logo/Jubilant.png"
import Optym from "../assets/logo/optym.png"
import Thirdeye_Data from "../assets/logo/Thirdeye.png"
import Planys_Technologies from "../assets/logo/Planys.png"
import Ather_Energy from "../assets/logo/Ather.png"
import Rewemax from "../assets/logo/ReveMAX.png"
import Arcesium from "../assets/logo/Arcesium.png"
import Barclays from "../assets/logo/Barclays.png"
import Clevertap from "../assets/logo/clevertap.png"
import Akridata from "../assets/logo/Akridata.png"
import Gmp from "../assets/logo/GMP.png"
import Analog_Devices from "../assets/logo/Analog Devices.png"
import Atlassian from "../assets/logo/Atlassian.png"
import Xoriant from "../assets/logo/xoriant.png"
import Kpmg from "../assets/logo/KPMG.png"
import Amdocs from "../assets/logo/amdocs.png"
import Mobiquity from "../assets/logo/mobiquity.png"
import Gojek from "../assets/logo/Gojek.png"
import Cleartrip from "../assets/logo/Cleartrip.png"
import Sharechat from "../assets/logo/Sharechat.png"
import Dell from "../assets/logo/DELL.png"
import Campgemini from "../assets/logo/Capgemini.png"
import Benison from "../assets/logo/Benison.png"
import Paysafe from "../assets/logo/paysafe.png"
import Kafqa from "../assets/logo/Kafqa.png"
import Meta from "../assets/logo/Meta.png"
import State_Street from "../assets/logo/State Stree.png"
import Virtusa from "../assets/logo/Virtusa.png"
import Rabobank from "../assets/logo/Rabobank.png"
import Caastle from "../assets/logo/Caastle.png"
import IBM from "../assets/logo/IBM.png"
import Wisetech from "../assets/logo/Wisetech Global.png"
import Epam from "../assets/logo/Epam.png"
import Exitbrief from "../assets/logo/ExitBrief.png"
import Visioapps from "../assets/logo/Visioapps Technology Pvt. Ltd..png"
import SmartMile from "../assets/logo/SmartMile Global.png"
import Samsung_Research from "../assets/logo/Samsung Research.png"
import LAndT from "../assets/logo/L&T Tech.png"
import LeenaAI from "../assets/logo/Leena AI.png"
import Livespace from "../assets/logo/Livespace.png"
import Thoughtspot from "../assets/logo/Thoughtspot.png"
import MMT from "../assets/logo/Makemytrip.png"
import Celigo from "../assets/logo/Celigo.png"
import Charge_Point from "../assets/logo/Charge point.png"
import NWM from "../assets/logo/Nationwithnamo.png"
import Deeptek from "../assets/logo/Deeptek.png"
import Eighttech from "../assets/logo/Eighttech Projects.png"
import Capexmove from "../assets/logo/capexmove.png"
import Airbus from "../assets/logo/Airbus.png"
import CGI from "../assets/logo/CGI.png"
import NextGen from "../assets/logo/NextGen.png"
import Hitachi from "../assets/logo/Hitachi.png"
import Fis from "../assets/logo/FIS.png"
import KaviramAI from "../assets/logo/Kaviram AI.png"
import FlipKart from "../assets/logo/Flipkart.png"
import Mavenir from "../assets/logo/Mavenir.png"
import Bristlecone from "../assets/logo/Bristlecone.png"
import BombayPlay from "../assets/logo/Bombay Play.png"
import Gupshup from "../assets/logo/Gupshup.png"
import Groupon from "../assets/logo/Groupon.png"
import LTI from "../assets/logo/LTI.png"
import Optum from "../assets/logo/Optum.png"
import Iqvia from "../assets/logo/IQVIA.png"
import Microsoft from "../assets/logo/Microsoft.png"
import Bel from "../assets/logo/Bharat Electronics.png"
import Scrum_master from "../assets/logo/Scrum Master.png"
import Apisero from "../assets/logo/Apisero.png"
import HclT from "../assets/logo/HCL TECH.png"
import Cashfree from "../assets/logo/Cashfree.png"
import Philips from "../assets/logo/Philips.png"
import Recro from "../assets/logo/Recro.png"
import LSE from "../assets/logo/London Stock Exchange.png"
import Hubilo from "../assets/logo/Hubilo.png"
import Smartcoin from "../assets/logo/Smartcoin.png"
import GoldmanSachs from "../assets/logo/Goldman Sachs.png"
import BlogVault from "../assets/logo/Blogvault.png"
import BtyeDance from "../assets/logo/Byte Dance.png"
import Kreditbee from "../assets/logo/KreditBee.png"
import Myntra from "../assets/logo/Myntra.png"
import Walmart from "../assets/logo/Walmart.png"
import Opentext from "../assets/logo/Opentext.png"
import Appdynamics from "../assets/logo/Appdynamics.png"
import Webbo from "../assets/logo/webbo.png"

export const companyLogos: any = {
  "Gowebbo & Co.": Webbo,
  Hubilo: Hubilo,
  "Smartcoin Financials Pvt Ltd": Smartcoin,
  Blogvault: BlogVault,
  "Goldman Sachs": GoldmanSachs,
  Kreditbee: Kreditbee,
  Bytedance: BtyeDance,
  Myntra: Myntra,
  Appdynamics: Appdynamics,
  Walmart: Walmart,
  Opentext: Opentext,
  "London Stock Exchange Group": LSE,
  Recro: Recro,
  Philips: Philips,
  Cashfree: Cashfree,
  Hcl: HclT,
  Apisero: Apisero,
  "Scrum Master": Scrum_master,
  Bel: Bel,
  Microsoft: Microsoft,
  LTI: LTI,
  Iqvia: Iqvia,
  Optum: Optum,
  Groupon: Groupon,
  Manvenir: Mavenir,
  Bristlecone: Bristlecone,
  Flipkart: FlipKart,
  "Bombay Play": BombayPlay,
  Gupshup: Gupshup,
  "Kaviram Ai": KaviramAI,
  Fis: Fis,
  Hitachi: Hitachi,
  Deeptek: Deeptek,
  "Eighttech Projects": Eighttech,
  Airbus: Airbus,
  "Nextgen Healthcare India": NextGen,
  Cgi: CGI,
  Capexmove: Capexmove,
  "Nation With Namo": NWM,
  Chargepoint: Charge_Point,
  Celigo: Celigo,
  "Make My Trip": MMT,
  Thoughtspot: Thoughtspot,
  Livespace: Livespace,
  "Leena AI": LeenaAI,
  "L&T": LAndT,
  "Smartmile Global Technologies": SmartMile,
  "Samsung Research Institute Banglore": Samsung_Research,
  "Visioapps Technology Pvt. Ltd.": Visioapps,
  Exitbrief: Exitbrief,
  "Epam Systems": Epam,
  "Wisetech Global": Wisetech,
  Visa: visa,
  "JP Morgan Chase And Co": JPMorgan,
  CommerceIQ: CommerceIQ,
  Samsung: Samsung,
  "Bewakoof.Com": Bewakoof,
  Thoughtworks: Thoughtworks,
  Deloitte: Deloitte,
  "Borderfree Technologies": Borderfree,
  "Byju'S": Byjus,
  "Pathor Platforms": Pathor,
  Navis: Navis,
  Synopsys: Synopsys,
  Cisco: Cisco,
  LTIMindtree: Ltimindtree,
  "Hitachi Vantara": HitachiV,
  "Bajaj Finserv": Bajaj,
  Redhat: Redhat,
  "Juniper Networks": Juniper,
  "Pysphere Technologies Llp": Pysphere,
  "Skypoint Cloud": Skypoint,
  Telstra: Telstra,
  Gainsight: Gainsight,
  Nykaa: Nykaa,
  Siemens: Siemens,
  "Oneture Technologies": Oneture,
  Upswing: Upswing,
  "Principal Global Services": Principal,
  "Persistent Systems": Persistent,
  "Olam Information Service Pvt. Ltd": Olam,
  Acies: Acies,
  Miramed: Miramed,
  Netenrich: Netenrich,
  "Electronic Arts": Electronic_Arts,
  Numerator: Numerator,
  Akidata: Akidata,
  Ibaset: Ibaset,
  Mphasis: Mphasis,
  Freecharge: Freecharge,
  Sprinklr: Sprinklr,
  "Hcl Software": Hcl,
  "Dassault Systems": Dassault_System,
  Yokogawa: Yokogawa,
  Senasys: Senasys,
  Ust: Ust,
  Oracle: Oracle,
  Tekion: Tekion,
  "Spurtree Technologies": Spurtreetechnologies,
  HDFC: HDFC,
  "National Instaruments": National_Instaruments,
  Nagarro: Nagarro,
  "Publisis Sapient": Publisis_Sapient,
  "ZS Associates": ZS_Associates,
  Lenskart: Lenskart,
  "Wells Fargo": Wells_Fargo,
  Justransform: Justransform,
  ServiceNow: ServiceNow,
  Amazon: Amazon,
  Hashedin: Hashedin,
  Hyland: Hyland,
  Google: Google,
  Technex: Technex,
  "Pure Software": Pure_Software,
  "Volkswagen Group Technology Solutions": Volkswagen,
  "Jubilant Foodworks": Jubilant_Foodworks,
  Optym: Optym,
  "Thirdeye Data": Thirdeye_Data,
  "Planys Technologies": Planys_Technologies,
  "Ather Energy": Ather_Energy,
  Rewemax: Rewemax,
  Arcesium: Arcesium,
  Barclays: Barclays,
  Clevertap: Clevertap,
  Akridata: Akridata,
  Gmp: Gmp,
  "Analog Devices": Analog_Devices,
  Atlassian: Atlassian,
  Xoriant: Xoriant,
  "Kpmg Global Services": Kpmg,
  Amdocs: Amdocs,
  "Mobiquity Inc": Mobiquity,
  "Gojek Tech": Gojek,
  Cleartrip: Cleartrip,
  Sharechat: Sharechat,
  Dell: Dell,
  "Campgemini Engineering": Campgemini,
  "Benison Technologies": Benison,
  Paysafe: Paysafe,
  Kafqa: Kafqa,
  Meta: Meta,
  "State Street": State_Street,
  Virtusa: Virtusa,
  Rabobank: Rabobank,
  Caastle: Caastle,
  IBM: IBM,
}
