import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface MarkAttendanceUseCase {
  invoke(auth: Auth, session_id: string): Promise<any>
}

export class MarkAttendance implements MarkAttendanceUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string): Promise<any> {
    return this.repository.markAttendance(auth, session_id)
  }
}
