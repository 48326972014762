import { cn } from "core/lib/utils"
import React from "react"

const Stage1 = ({ className }: any) => {
  return (
    <svg className={cn(className)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2314_62116)">
        <rect width="32" height="32" rx="4" fill="#333333" />
        <path
          d="M25.0787 5.90909V39H18.0824V12.5499H17.8885L10.3105 17.3002V11.0957L18.5025 5.90909H25.0787Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_62116">
          <rect width="32" height="32" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Stage1
