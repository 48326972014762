import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import delay from "core/utils/delay"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import { UploadResume } from "domain/useCase/Student/Profile/UploadResume"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function ResumeViewModel() {
  const [uploadedResume, setUploadedResume] = React.useState<any>(null)
  const [resumeURL, setResumeURL] = React.useState<any>(null)
  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()

  const uploadResumeUseCase = new UploadResume(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const handleSubmitResume = async (e: any) => {
    e.preventDefault()

    if (!uploadedResume) {
      changeToastDetails(STR_FAILURE, "Please select a file to upload")
      changeToastVisibility(true)
      return
    }

    await handleUploadResume()
  }

  const handleCancel = () => {
    navigate("/placement/edit")
  }

  const handleChangeResume = (e: any) => {
    setUploadedResume(e.target.files[0])
    setResumeURL(URL.createObjectURL(e.target.files[0]))
  }

  const handleUploadResume = async () => {
    setIsUploading(true)
    const response = await uploadResumeUseCase.invoke(auth, uploadedResume, uploadedResume?.name)
    setIsUploading(false)

    if (!response?.success) {
      changeToastDetails(
        STR_FAILURE,
        (typeof response?.data === "string" && response?.data) ||
          (typeof response?.data === "string" && response?.error) ||
          "Error in uploading resume!"
      )
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Resume uploaded successfully!")
    changeToastVisibility(true)

    await delay(1000)
    navigate("/placement/edit")
  }

  return {
    toast,
    uploadedResume,
    resumeURL,
    isUploading,
    handleSubmitResume,
    handleCancel,
    handleChangeResume,
    changeToastVisibility,
  }
}
