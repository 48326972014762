export default function CheckBoxTick({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 2H2.66667C2.29848 2 2 2.29848 2 2.66667V13.3333C2 13.7015 2.29848 14 2.66667 14H13.3333C13.7015 14 14 13.7015 14 13.3333V2.66667C14 2.29848 13.7015 2 13.3333 2ZM12.0487 5.9527L7.33533 10.6667L4.50667 7.83804L5.44933 6.89537L7.33533 8.78137L11.106 5.01004L12.0487 5.9527Z"
        fill="#00C22B"
      />
    </svg>
  )
}
