export default function listenForOutsideClicks(listening: any, setListening: any, menuRef: any, setIsOpen: any) {
  return () => {
    if (listening) return
    if (!menuRef.current) return
    setListening(true)
    ;[`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        const cur = menuRef.current
        const node = evt.target
        if (cur && !cur.contains(node)) setIsOpen(false)
      })
    })
  }
}
