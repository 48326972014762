import { STR_DISCORD_LINK } from "core/constants/strings"

export default function HomeToggleCheck({
  data,
  communityVisible,
  index,
  id,
  onChange,
  student,
  withoutHeader,
  isChecked,
}: any) {
  const isNewBatch = student?.batch_start_timestamp > 1707676200 // 12/02/24

  const community = {
    link: STR_DISCORD_LINK,
    text: isNewBatch ? "Join WhatsApp Community" : "Join Discord",
  }

  // used in project section
  if (withoutHeader) {
    return (
      <div>
        <label className="relative inline-flex cursor-pointer items-center">
          <input
            type="checkbox"
            checked={isChecked ?? data?.status}
            className="peer sr-only"
            onChange={() => onChange(id, !(isChecked ?? data?.status))}
          />
          <div className="peer h-8 w-[60px] rounded-full border border-new-accent bg-new-solid-white after:absolute after:top-1 after:left-1 after:h-6 after:w-6 after:rounded-full after:bg-new-accent after:transition-all after:content-[''] peer-checked:bg-new-accent peer-checked:after:translate-x-7 peer-checked:after:bg-new-solid-white peer-focus:outline-none"></div>
        </label>
      </div>
    )
  }

  return (
    <label className="flex w-40 cursor-pointer flex-col gap-4 rounded-sm bg-new-solid-white p-4">
      <div className="h-16">
        <h4 className="text-new-neutral-light">{("00" + (index + 1)).slice(-2)}.</h4>
        <h4 className="text-new-accent" title={data?.name}>
          {data?.name === "Join discord" ? community.text : data?.name}
        </h4>
        {!isNewBatch && communityVisible && data?.name === "Join discord" && (
          <a
            href={community.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-medium text-[#7C3794] underline"
          >
            Click here to join
          </a>
        )}
      </div>
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          checked={isChecked ?? data?.status}
          className="peer sr-only"
          onChange={() => onChange(id, !(isChecked ?? data?.status))}
        />
        <div className="peer h-8 w-[60px] rounded-full border border-new-accent bg-new-solid-white after:absolute after:top-1 after:left-1 after:h-6 after:w-6 after:rounded-full after:bg-new-accent after:transition-all after:content-[''] peer-checked:bg-new-accent peer-checked:after:translate-x-7 peer-checked:after:bg-new-solid-white peer-focus:outline-none"></div>
      </label>
    </label>
  )
}
