import styled from "@emotion/styled"
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import NewMentorSessionsAPIDataSourceImpl from "data/API/Admin/NewMentorSessionsAPIDataSourceImpl"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { MentorAPIDataSourceImpl } from "data/API/Student/MentorAPIDataSourceImpl"
import { NewMentorSessionsRepositoryImpl } from "data/repository/Admin/NewMentorSessionsRepositoryImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { MentorRepositoryImpl } from "data/repository/Student/MentorRepositoryImpl"
import { Auth } from "domain/model/Auth"
import { EditMentorTemplateForStudent } from "domain/useCase/Admin/NewMentorSessions/EditMentorTemplateForStudent"
import { GetMentorNameFromId } from "domain/useCase/Admin/NewMentorSessions/GetMentorNameFromId"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import { GetMentorSessionsByStudent } from "domain/useCase/Student/Mentor/GetMentorSessionsByStudent"
import React from "react"
// import { Theme } from '@mui/material/styles/createTheme';

export default function MentorshipStudentEditViewModel() {
  function formatDate(inputDate: any) {
    // Regular expression to match the date in "YYYY-MM-DD" format
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    // Check if the inputDate matches the expected format
    if (!dateRegex.test(inputDate)) return inputDate

    // Parse the inputDate string into a Date object
    const dateObject = new Date(inputDate)

    // Get the day of the month
    const dayOfMonth = dateObject.getDate()
    // Get the month name
    const monthName = dateObject.toLocaleString("default", { month: "short" })

    // Function to get the ordinal suffix for a number
    const getOrdinal = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"]
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }

    // Get the day with ordinal suffix
    const dayWithOrdinal = getOrdinal(dayOfMonth)

    // Construct the formatted date string
    const formattedDate = `${dayWithOrdinal} ${monthName}`

    return formattedDate
  }
  function getStatusColorClass(status: any) {
    switch (status) {
      case "Pending":
        return "text-yellow-500"
      case "not_booked":
        return "text-#ADADAD"
      case "Completed":
        return "text-green-500"
      case "":
        return "text-#ADADAD"
      case "Booked":
        return "text-yellow-500"
      default:
        return "text-red-500"
    }
  }

  const [reviewIndex, setReviewIndex] = React.useState<number>(-1)
  const [feedbackIndex, setFeedbackIndex] = React.useState<number>(-1)
  const [cancelSessionIndex, setCancelSessionIndex] = React.useState<number>(-1)
  const [rescheduleSessionIndex, setRescheduleSessionIndex] = React.useState<number>(-1)
  const [confirmSessionIndex, setConfirmSessionIndex] = React.useState<number>(-1)
  const [rating, setRating] = React.useState<number>(0)

  const [isMenuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [menuActiveRow, setMenuActiveRow] = React.useState<number>(-1)
  const [name, setName] = React.useState("")
  const [value, setValue] = React.useState("")
  const [comment, setComment] = React.useState<string>("")

  function closeSelectedFeedbackDialog(type: string) {
    if (type === "review") setReviewIndex(-1)
    else if (type === "feedback") setFeedbackIndex(-1)
    else if (type === "cancel") setCancelSessionIndex(-1)
    else if (type === "reschedule") setRescheduleSessionIndex(-1)
    else if (type === "confirm") setConfirmSessionIndex(-1)
    else setFeedbackIndex(-1)
    setRating(0)
    setComment("")
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = event.target.value
    const updatedItems = [...items]
    updatedItems[index].name = newValue // Assuming the name property exists in each item

    setItems(updatedItems)
  }
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#414141",
      color: "#FFFFFF",
      // boxShadow: theme.shadows[1],
      fontSize: "10px",
      maxWidth: "184px",
      textWrap: "wrap",
      padding: "8px 8px 8px 8px",
    },
  }))
  const getMentorSessionsByStudentUseCase = new GetMentorSessionsByStudent(
    new MentorRepositoryImpl(new MentorAPIDataSourceImpl())
  )
  const getMentorNameFromIdUseCase = new GetMentorNameFromId(
    new NewMentorSessionsRepositoryImpl(new NewMentorSessionsAPIDataSourceImpl())
  )
  const editMentorTemplateForStudentUseCase = new EditMentorTemplateForStudent(
    new NewMentorSessionsRepositoryImpl(new NewMentorSessionsAPIDataSourceImpl())
  )
  const { auth, refreshed } = useAuth()
  const [tableDataNew, setTableDataNew] = React.useState<any>({
    header: [],
    rows: [],
  })
  const [mentorName, setMentorName] = React.useState("")
  const [milestoneMentorName, setMilestoneMentorName] = React.useState("")
  const [student, setStudent] = React.useState<any>({})
  const [items, setItems] = React.useState<any>([])
  const [editModes, setEditModes] = React.useState(new Array(items.length).fill(false))
  const [endDateEditModes, setEndDateEditModes] = React.useState(new Array(items.length).fill(false))
  const [currentItemIndex, setCurrentItemIndex] = React.useState<number | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  async function editMentorTemplateForStudent(auth: Auth, template: any) {
    setIsLoading(true)

    const response = await editMentorTemplateForStudentUseCase.invoke(auth, template)
    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Changes saved")
      changeToastVisibility(true)
    }
    setIsLoading(false)
  }
  async function handleEdit(template: any) {
    await editMentorTemplateForStudent(auth, template)
  }
  const generateUniqueId = (studentEmail: any, sessionId: any) => {
    return `${studentEmail}${sessionId}${Date.now()}`
  }
  const addNewMentorSession = () => {
    const newItem = {
      name: "",
      agenda: "",
      session_date: "",
      session_start_time: "",
      session_end_time: "",
      mentor_name: "",
      session_status: "",
      duration: "",
      startDate: "",
      endDate: "",
      student_review: "",
      mentor_feedback: "",
    }
    setItems([...items, newItem])
    setEditModes([...editModes, false])
    setEndDateEditModes([...endDateEditModes, false])
  }
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const toggleEditMode = (i: any) => {
    const updatedEditModes = [...editModes]
    updatedEditModes[i] = !updatedEditModes[i]
    setEditModes(updatedEditModes)
  }
  const toggleEndDateEditMode = (i: any) => {
    const updatedEditModes = [...endDateEditModes]
    updatedEditModes[i] = !updatedEditModes[i]
    setEndDateEditModes(updatedEditModes)
  }
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [agenda, setAgenda] = React.useState("")
  const [modalAgenda, setModalAgenda] = React.useState("")
  const deleteMentorSession = (index: number) => {
    const updatedItems = items.filter((_: any, i: any) => i !== index)
    setItems(updatedItems)

    const updatedEditModes = editModes.filter((_, i) => i !== index)
    setEditModes(updatedEditModes)

    const updatedEndDateEditModes = endDateEditModes.filter((_, i) => i !== index)
    setEndDateEditModes(updatedEndDateEditModes)
  }
  const handleStartDateChange = (index: any, date: any) => {
    const updatedItems = [...items]
    updatedItems[index] = {
      ...updatedItems[index],
      startDate: date, // Update startDate for the item
    }

    setItems(updatedItems)
  }
  const toggleModal = (item?: any, index?: any) => {
    if (isModalOpen) {
      // Closing the modal, reset state
      setIsModalOpen(false)
      setAgenda("")
      setModalAgenda("")
      setCurrentItemIndex(null)
    } else {
      // Opening the modal, set state based on the item
      setIsModalOpen(true)
      setAgenda(item?.agenda || "")
      setModalAgenda(item?.agenda || "")
      setCurrentItemIndex(index ?? null)
    }
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
    setAgenda("")
    setModalAgenda("")
    setCurrentItemIndex(null)
  }
  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [indexToBeDeleted, setIndexToBeDeleted] = React.useState(-1)

  const handleModalAgendaChange = (e: any) => {
    const newAgenda = e.target.value
    setModalAgenda(newAgenda)

    if (currentItemIndex !== null) {
      const updatedItems = [...items]
      updatedItems[currentItemIndex] = {
        ...updatedItems[currentItemIndex],
        agenda: newAgenda,
      }
      setItems(updatedItems)
    }
  }
  const addNewMentorSessionAt = (index: number, studentEmail: string, sessionId: any) => {
    // You can use any logic to generate the session ID
    const uniqueId = generateUniqueId(studentEmail, sessionId)
    const newItem = {
      id: uniqueId,
      name: "",
      agenda: "",
      session_date: "",
      session_start_time: "",
      session_end_time: "",
      mentor_id: sessionId === "msid1" ? student?.milestone_session_mentor : student?.mentor_id,
      mentor_name: sessionId === "msid1" ? milestoneMentorName : mentorName,
      session_status: "not_booked",
      duration: "",
      startDate: "2024-01-01",
      endDate: "2029-01-01",
      student_review: {},
      mentor_feedback: {},
      student_id: studentEmail,
      session_id: sessionId,
    }

    const updatedItems = [...items.slice(0, index + 1), newItem, ...items.slice(index + 1)]
    setItems(updatedItems)

    const updatedEditModes = [...editModes.slice(0, index + 1), false, ...editModes.slice(index + 1)]
    setEditModes(updatedEditModes)
    const updatedEndDateEditModes = [
      ...endDateEditModes.slice(0, index + 1),
      false,
      ...endDateEditModes.slice(index + 1),
    ]
    setEndDateEditModes(updatedEndDateEditModes)
  }

  const handleEndDateChange = (index: any, date: any) => {
    const updatedItems = [...items]
    const startDate = new Date(updatedItems[index].startDate)
    const endDate = new Date(date)

    if (endDate <= startDate) {
      changeToastDetails(STR_FAILURE, "End date must be greater than start date")
      changeToastVisibility(true)
      return
    }

    updatedItems[index] = {
      ...updatedItems[index],
      endDate: date, // Update endDate for the item
    }

    setItems(updatedItems)
  }
  const getNextSessionId = (sessionId: any) => {
    if (!sessionId) return null

    const prefix = sessionId.match(/^[a-zA-Z]+/)[0] // Extract the alphabetic prefix
    const number = parseInt(sessionId.match(/\d+$/)[0], 10) // Extract and parse the numeric part

    return `${prefix}${number + 1}` // Increment the number and return the new session_id
  }
  async function getMentorSessionsByStudent(data: any) {
    const response = await getMentorSessionsByStudentUseCase.invoke(auth, data)
    setItems(response.data)
  }
  async function getMentorNameFromId(data: any) {
    const response = await getMentorNameFromIdUseCase.invoke(auth, data)
    setMentorName(response?.data["name"])
  }
  async function getMilestoneMentorNameFromId(data: any) {
    const response = await getMentorNameFromIdUseCase.invoke(auth, data)
    setMilestoneMentorName(response?.data["name"])
  }
  const getUserDataUseCase = new GetAllStudentData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const getUserData = async (email: string) => {
    const response = await getUserDataUseCase.invoke({
      admin_uid: auth.local_id,
      email: email,
    })

    if (!response) {
      return
    }

    const userData = response?.response
    setStudent(userData?.student)
  }
  return {
    reviewIndex,
    setReviewIndex,
    feedbackIndex,
    setFeedbackIndex,
    cancelSessionIndex,
    setCancelSessionIndex,
    rescheduleSessionIndex,
    setRescheduleSessionIndex,
    confirmSessionIndex,
    setConfirmSessionIndex,
    rating,
    setRating,
    isMenuOpen,
    setMenuOpen,
    menuActiveRow,
    setMenuActiveRow,
    name,
    setName,
    value,
    setValue,
    comment,
    setComment,
    LightTooltip,
    getMentorSessionsByStudentUseCase,
    getMentorNameFromIdUseCase,
    auth,
    refreshed,
    tableDataNew,
    setTableDataNew,
    mentorName,
    setMentorName,
    milestoneMentorName,
    setMilestoneMentorName,
    student,
    setStudent,
    items,
    setItems,
    editModes,
    setEditModes,
    endDateEditModes,
    isLoading,
    setEndDateEditModes,
    currentItemIndex,
    setCurrentItemIndex,
    editMentorTemplateForStudentUseCase,
    toast,
    changeToastVisibility,
    changeToastDetails,
    isModalOpen,
    setIsModalOpen,
    agenda,
    setAgenda,
    modalAgenda,
    setModalAgenda,
    isOpen,
    setIsOpen,
    indexToBeDeleted,
    setIndexToBeDeleted,
    getUserDataUseCase,
    handleChange,
    toggleModal,
    formatDate,
    toggleEditMode,
    handleStartDateChange,
    handleEndDateChange,
    getStatusColorClass,
    addNewMentorSessionAt,
    getNextSessionId,
    getMentorNameFromId,
    getMilestoneMentorNameFromId,
    getMentorSessionsByStudent,
    getUserData,
    deleteMentorSession,
    handleEdit,
    handleModalClose,
    handleModalAgendaChange,
    closeSelectedFeedbackDialog,
    toggleEndDateEditMode,
  }
}
