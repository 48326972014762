import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface DeleteStoreItemUseCase {
  invoke(auth: Auth, item_id: any): Promise<any>
}

export class DeleteStoreItem implements DeleteStoreItemUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, item_id: any) {
    return this.repository.deleteStoreItem(auth, item_id)
  }
}
