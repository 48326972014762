import React, { useEffect, useState } from "react"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import { useNavigate, useParams } from "react-router-dom"
import { STR_FAILURE } from "core/constants/strings"
import { GetDailyReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetDailyReportData"
import { DailyReportRepositoryImpl } from "data/repository/Admin/DailyReportRepositoryImpl"
import { DailyReportAPIDataSourceImpl } from "data/API/Admin/DailyReportDataSourceImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { GetBatchWeeklyReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetBatchWeeklyReportData"
import { GetBatchMonthlyReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetBatchMonthlyReportData"
import { BatchWeeklyReportRepositoryImpl } from "data/repository/Admin/BatchWeeklyReportRepositoryImpl"
import { BatchMonthlyReportRepositoryImpl } from "data/repository/Admin/BatchMonthlyReportRepositoryImpl"
import { BatchWeeklyReportAPIDataSourceImpl } from "data/API/Admin/BatchWeeklyReportAPIDataSourceImpl"
import { BatchMonthlyReportAPIDataSourceImpl } from "data/API/Admin/BatchMonthlyReportAPIDataSourceImpl"

const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

export default function ProgressRoadmapViewModel() {
  const [student, setStudent] = useState<any>({})
  const [batchList, setBatchList] = useState<any>([])
  const navigate = useNavigate()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth, refreshed } = useAuth()
  const { id }: any = useParams()
  const [weekData, setWeeksData] = useState<any>()

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const [active, setActive] = useState(3)
  const [selectedBatch, setSelectedBatch] = useState<string>("B23A")
  const [selectedField, setSelectedField] = useState<string>("Classes Schedule")
  const [month, setMonth] = useState<number>(currentMonth + 1)
  const [year, setYear] = useState<number>(currentYear)

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }

  const getUserDataUseCase = new GetAllStudentData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const getDailyReportDataUseCase = new GetDailyReportData(
    new DailyReportRepositoryImpl(new DailyReportAPIDataSourceImpl())
  )

  const batchWeeklyReportUseCase = new GetBatchWeeklyReportData(
    new BatchWeeklyReportRepositoryImpl(new BatchWeeklyReportAPIDataSourceImpl())
  )

  const batchMonthlyReportUseCase = new GetBatchMonthlyReportData(
    new BatchMonthlyReportRepositoryImpl(new BatchMonthlyReportAPIDataSourceImpl())
  )

  const getWeeklyReport = async (batch: any, month: any, year: any) => {
    const response = await batchWeeklyReportUseCase.invoke({
      id_token: auth.id_token,
      batch: batch,
      month: month,
      year: year,
    })
    setWeeksData(response.data)
  }

  const getUserData = async (email: string) => {
    const response = await getUserDataUseCase.invoke({
      admin_uid: auth.local_id,
      email: email,
    })

    if (!response) {
      changeToastDetails(STR_FAILURE, "Error while fetching user data")
      changeToastVisibility(true)
      return
    }

    const userData = response?.response
    setStudent(userData?.student)
  }

  const handleOptionClick = (text: string) => {
    setSelectedField(text)
  }

  const handleSelectBatch = (text: string) => {
    setSelectedBatch(text)
  }

  const handleSelectMonth = (text: string) => {
    const index = months.findIndex((month) => month === text)
    setMonth(index + 1)
  }

  const handleSelectYear = (text: number) => {
    setYear(text)
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const years = Array.from({ length: currentYear - 2020 + 1 }, (_, i) => 2020 + i)

  return {
    getUserData,
    refreshed,
    id,
    student,
    navigate,
    batchList,
    getBatches,
    weekData,
    getWeeklyReport,
    selectedField,
    years,
    months,
    handleOptionClick,
    handleSelectBatch,
    handleSelectMonth,
    handleSelectYear,
    active,
    setActive,
    selectedBatch,
    setSelectedBatch,
    month,
    setMonth,
    year,
    setYear,
    setSelectedField,
    currentDate,
    currentMonth,
    currentYear,
  }
}
