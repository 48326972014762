import { CreateClass } from "domain/model/CreateClass"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface CreateClassUseCase {
  invoke(createClass: CreateClass): Promise<any>
}

export class CreateClasses implements CreateClassUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }

  async invoke(createClass: CreateClass) {
    return await this.repository.createClass(createClass)
  }
}
