import { SSMCallHistory } from "domain/model/SSMCallHistory"
import SSMCallHistoryRepository from "domain/repository/Admin/SSMCallHistoryRepository"

export interface SSMCallHistoryUseCase {
  invoke(details: SSMCallHistory): Promise<any>
}
export class SSMCallHistorys implements SSMCallHistoryUseCase {
  private repository: SSMCallHistoryRepository
  constructor(repository: SSMCallHistoryRepository) {
    this.repository = repository
  }
  async invoke(details: SSMCallHistory): Promise<any> {
    return await this.repository.SSMCallHistory(details)
  }
}
