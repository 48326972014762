import React from "react"

const QuestionContent = ({ question }: { question: any }) => {
  return (
    <div>
      {question?.map((item: any, index: number) => (
        <div key={index} className="my-2">
          {item.type === "text" ? (
            <pre className="text-new-neutral-light whitespace-pre-wrap">{item.content}</pre>
          ) : item.type === "image" ? (
            <img src={item.content} alt="Question" />
          ) : (
            <pre className="text-new-neutral-light" dangerouslySetInnerHTML={{ __html: item.content }}></pre>
          )}
        </div>
      ))}
    </div>
  )
}

const Subjective = ({ questions, selectedQuestion, activeTabContent }: any) => {
  return (
    <>
      <div className="flex flex-col p-3 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 space-y-1 rounded">
        <h3>
          {selectedQuestion + 1}. {questions?.questionTitle}
        </h3>
        <div className="flex text-base space-x-2">
          <>
            <p className="font-semibold">Max Marks : </p>
            <p>{questions?.["maxMarks"]}</p>
          </>
          <div className="flex justify-between space-x-1">
            <p className="font-semibold">Difficulty Level : </p>
            <p
              className={`${
                questions?.["difficultyLevel"] === "easy"
                  ? "text-emerald-500"
                  : questions?.["difficultyLevel"] === "medium"
                    ? "text-orange-500"
                    : "text-red-500"
              } capitalize`}
            >
              {questions?.["difficultyLevel"]}
            </p>
          </div>
        </div>
      </div>
      <div>
        {activeTabContent === 0 ? (
          <div className="p-4 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
            <h4 className="py-2 text-new-neutral-light">Question</h4>
            <QuestionContent question={questions?.question} />
          </div>
        ) : (
          <div className="flex items-baseline flex-col p-3 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
            <h4 className="py-2 text-new-neutral-light">Solution : </h4>
            <p className="text-new-neutral-light">{questions?.solution}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Subjective
