import {
  API_GET_RESUME,
  API_V4_STUDENT,
  API_STUDENT,
  STR_GET_STUDENT_DETAILS,
  STR_GET_STUDENT_PROFILE_DETAILS,
  STR_PERCENT_COMPLETED_PROFILE,
  STR_UPDATE_PROFILE,
  STR_UPLOAD_PROFILE_PICTURE,
  STR_UPLOAD_RESUME,
  PLACEMENT_READINESS_SCORE,
  DETAILS,
  STUDENT,
} from "core/constants/strings"
import { postAPI, postAPIForFiles, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import ProfileDataSource from "data/dataSource/Student/ProfileDataSource"
import { getTotal } from "core/utils/misc"

const server = new Server()
export class ProfileAPIDataSourceImpl implements ProfileDataSource {
  async getProfileDetails(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(STUDENT, DETAILS), {
        Authorization: `Bearer ${auth.id_token}`,
      })

      const student = (response as unknown as any)?.data

      const data: any = {
        name: student?.name,
        email: student?.email,
        batch: student?.batch,
        photo: student?.photo,
        referralCode: student?.referral_code,
        points: student?.points,
        is_batch_paused: student?.is_batch_paused,
        batch_paused_date: student?.batch_paused_date,
        is_payment_done: student?.is_payment_done,
        new_updates: student?.new_updates,
        metrics: [
          {
            title: "Problem Solved",
            current: student?.total_count,
            total: getTotal(student?.total_count, 200),
            info: "Total (Leetcode + Platform) problems solved",
          },
          {
            title: "All Streak",
            current: student?.total_streaks,
            total: getTotal(student?.total_streaks, 10),
            info: "Combined streaks of Leetcode and Platform",
          },
        ],
        tracks: student?.tracks,
        is_masters_registered: student?.is_masters_registered,
        onboardingManagerId: student?.onboardingManagerId,
        payable_start_month: student?.payable_start_date,
        mentor_id: student?.mentor_id,
        milestone_session_mentor_id: student?.milestone_session_mentor_id,
        PAYMENT_OPTION: student?.PAYMENT_OPTION,
        batch_start_timestamp: student?.batch_start_timestamp,
        batch_v4: student?.batch_v4,
        isICP: student?.isICP,
        course_completed: student?.course_completed,
        course_name: student?.course_name,
        certificate_url: student?.certificate_url,
        batch_start_date: student?.batch_start_date,
        batch_end_timestamp: student?.batch_end_timestamp,
        instalmentsLength: student?.instalmentsLength,
        payment_method: student?.payment_method,
        redeem_coins: student?.redeem_coins,
        proctoring_status: student?.proctoring_status,
        show_pledge: student?.show_pledge,
      }

      if (student.hasOwnProperty("walk_through_date")) {
        data.walk_through_date = student.walk_through_date
      }

      if (student.hasOwnProperty("program_type")) {
        data.program_type = student.program_type
      }

      return { data, success: true }
    } catch (error) {
      return error
    }
  }

  async updateProfile(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_UPDATE_PROFILE), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async uploadProfilePicture(auth: Auth, file: File): Promise<any> {
    try {
      const data = new FormData()
      data.append("id_token", auth.id_token)
      data.append("image", file)

      const response = await postAPIForFiles(pn(API_V4_STUDENT, STR_UPLOAD_PROFILE_PICTURE), data)
      return response
    } catch (error) {
      return error
    }
  }

  async uploadResume(auth: Auth, blob: any, filename: string): Promise<any> {
    try {
      const data = new FormData()
      data.append("id_token", auth.id_token)
      data.append("resume", blob, filename)

      const response = await postAPIForFiles(pn(API_V4_STUDENT, STR_UPLOAD_RESUME), data)

      return response
    } catch (error) {
      return error
    }
  }

  async percentCompletedProfile(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_PERCENT_COMPLETED_PROFILE), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentProfileDetails(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_STUDENT_PROFILE_DETAILS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getResume(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_RESUME), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentPlacementScore(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(API_STUDENT, PLACEMENT_READINESS_SCORE), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
