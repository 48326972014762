export default function IssueResolvedPage(props: any) {
  return (
    <div className="custom-shadow border-1 flex h-[482px] w-full border">
      <div className="m-auto flex h-[70px] w-[450px] flex-col gap-y-4">
        <div className="m-auto text-2xl font-bold text-[#312E81]">{props.h1}</div>
        <div className="m-auto text-base font-semibold text-[#00C22B]">{props.h2}</div>
      </div>
    </div>
  )
}
