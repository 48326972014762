import { Auth } from "domain/model/Auth"
import { RedeemCoinsRepository } from "domain/repository/Admin/RedeemCoinsRepository"

export interface AllowRedeemCoinsUseCase {
  invoke(auth: Auth, batches: Array<string>): Promise<any>
}

export default class AllowRedeemCoins implements AllowRedeemCoinsUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batches: Array<string>) {
    return this.repository.allowRedeemCoins(auth, batches)
  }
}
