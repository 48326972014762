import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface AddStoreItemUseCase {
  invoke(auth: Auth, item: any): Promise<any>
}

export class AddStoreItem implements AddStoreItemUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, user: any) {
    return this.repository.addStoreItem(auth, user)
  }
}
