import { CrossIcon } from "core/constants/svgs"

export default function SheetsModal(props: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setIsOpen(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
          <div className="m-auto w-[650px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            {/* content goes here */}
            <div className="relative flex gap-x-4">
              <div className="text-lg font-semibold text-[#312E81]">{props.task}</div>
              <div className="my-auto text-sm font-semibold">{props.email}</div>
              <button onClick={() => props.setIsOpen(false)} className="absolute right-0 mt-[3px]">
                <CrossIcon className="h-6 w-6 text-[#312E81]" />
              </button>
            </div>
            <div className="border-1 mt-1 min-h-[200px] border border-[#312E81] p-2 text-sm font-normal">
              {props.reason}
            </div>

            {/* ends here */}
          </div>
        </div>
      </div>
    </div>
  )
}
