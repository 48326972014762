import Modal from "core/components/Modal"
import Model from "core/components/Model"
import Button from "core/components/new/Button"
import React from "react"
import EditModuleClass from "./EditModuleClass"
import ModuleClassViewModel from "../ModuleClassViewModel"
import { useAuth } from "core/context/auth"
import { useParams } from "react-router-dom"
import { UpdateModuleClasses } from "domain/useCase/Admin/ModuleClasses/UpdateModuleClass"
import ModuleClassAPIDataSourceImpl from "data/API/Admin/ModuleClassAPIDataSourceImpl"
import { ModuleClassRepositoryImpl } from "data/repository/Admin/ModuleClassRepositoryImpl"
import { DeleteModuleClasses } from "domain/useCase/Admin/ModuleClasses/DeleteModuleClass"
import { Auth } from "domain/model/Auth"
import { UpdateModuleClass } from "domain/model/ModuleClass"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { genError } from "core/utils/string"

export default function ModuleClassTable(props: any) {
  const { fetchModuleClasses } = ModuleClassViewModel()
  const { auth } = useAuth()
  const { id } = useParams()
  const [deleteLoader, setDeleteLoader] = React.useState(false)
  const [isEditModalOpen, setEditModalOpen] = React.useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [editData, setEditData] = React.useState<any>()
  const [deleteId, setDeleteId] = React.useState("")
  const ModulesSessions = props.ModulesSessions?.module_sessions
  const formatDateString = (dateString: string): string => {
    const dateObject = new Date(`${dateString}-01`)
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
    }).format(dateObject)

    return formattedDate
  }

  const deleteModuleClassesUseCase = new DeleteModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )

  const fetchDeleteModuleClass = async (auth: Auth, module_id: string) => {
    const response = await deleteModuleClassesUseCase.invoke(auth, module_id)
    if (!response?.success) {
      return
    }
    props.changeToastDetails(STR_SUCCESS, "Module Deleted!")
    props.changeToastVisibility(true)
    props.setReloadData(!props.reloadData)
  }

  function handleEditModal(data: any) {
    setEditData(data)
    setEditModalOpen(!isEditModalOpen)
  }

  function handleDeleteModal(delId: string) {
    setDeleteId(delId)
    setDeleteModalOpen(!isDeleteModalOpen)
  }

  async function DeleteModule() {
    setDeleteLoader(true)
    await fetchDeleteModuleClass(auth, deleteId as string)
    setDeleteLoader(false)
    await fetchModuleClasses(auth, id as string)
    setDeleteModalOpen(!isDeleteModalOpen)
  }

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.ModuleClassHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {ModulesSessions && (
          <tbody>
            {ModulesSessions?.map((data: any, i: number) => {
              return (
                <tr key={i}>
                  <td className="px-6 py-4 ">{i + 1}</td>
                  <td className="px-6 py-4 text-[16px] font-medium text-new-accent">{data.module}</td>
                  <td className="px-6 py-4 text-[16px] font-medium">{data.batch}</td>
                  <td className="px-6 py-4 text-[16px]">{formatDateString(data.start_date)}</td>
                  <td className="px-6 py-4 text-[16px] font-medium">{data.end_date}</td>
                  <td className="px-6 py-4 text-[16px] font-medium">{data.amount}</td>
                  <td className="px-6 py-4 text-[16px] font-medium">{data.status}</td>
                  <td className="px-6 py-4 text-[16px] font-medium">
                    <Button onClick={() => handleEditModal(data)}>Edit</Button>
                  </td>
                  <td className="px-6 py-4 text-[16px] font-medium">
                    <Button onClick={() => handleDeleteModal(data.id as string)}>Delete</Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      <Model isOpen={isEditModalOpen} onClose={handleEditModal} width="max-w-6xl">
        <EditModuleClass
          changeToastDetails={props.changeToastDetails}
          changeToastVisibility={props.changeToastVisibility}
          reloadData={props.reloadData}
          setReloadData={props.setReloadData}
          clasDetails={editData}
        />
      </Model>
      <Model isOpen={isDeleteModalOpen} onClose={() => handleDeleteModal(deleteId)}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Delete Module</h3>
            <p>Are you sure you want to Delete Module Class</p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={() => handleDeleteModal(deleteId)}>
              No
            </Button>
            <Button outlined loading={deleteLoader} onClick={DeleteModule}>
              Yes
            </Button>
          </div>
        </div>
      </Model>
    </div>
  )
}
