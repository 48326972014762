import { Auth } from "domain/model/Auth"
import NotificationDataSource from "data/dataSource/Student/NotificationDataSource"
import pn from "core/utils/pn"
import { Server } from "core/utils/axios"
import { STUDENT, NOTIFICATION, NOTIFICATION_READ } from "core/constants/strings"

const server = new Server()
export default class NotificationAPIDataSourceImpl implements NotificationDataSource {
  async getNotificationDetails(auth: Auth): Promise<any> {
    const response = await server.get(pn(STUDENT, NOTIFICATION), {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }

  async updateNotificationRead(auth: Auth, type: any): Promise<any> {
    const response = await server.post(pn(STUDENT, NOTIFICATION, NOTIFICATION_READ), type, {
      Authorization: `Bearer ${auth.id_token}`,
    })
    return response
  }
}
