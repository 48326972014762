import React from "react"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import ClassAPIDataSourceImpl from "data/API/Mentor/ClassAPIDataSourceImpl"
import { ClassRepositoryImpl } from "data/repository/Mentor/ClassRepositoryImpl"
import { GetAllClasses } from "domain/useCase/Mentor/Class/GetAllClasses"
import { ClassReminderScheduling } from "domain/useCase/Mentor/Class/ClassReminderScheduling"

export default function LiveClassViewModel() {
  const { auth, refreshed } = useAuth()
  const { mentor } = useApp()
  const { toast, changeToastVisibility } = useToast()
  const [sessions, setSessions] = React.useState<any[]>([])

  const getAllClassesUseCase = new GetAllClasses(new ClassRepositoryImpl(new ClassAPIDataSourceImpl()))
  const classReminderSchedulingUseCase = new ClassReminderScheduling(
    new ClassRepositoryImpl(new ClassAPIDataSourceImpl())
  )

  async function fetchAllClasses() {
    const response = await getAllClassesUseCase.invoke(auth)
    setSessions(response?.data)
  }

  async function classReminderScheduling(sessionId: any) {
    await classReminderSchedulingUseCase.invoke(auth, sessionId)
  }

  return {
    toast,
    mentor,
    sessions,
    refreshed,
    fetchAllClasses,
    changeToastVisibility,
    classReminderScheduling,
  }
}
