import { Auth } from "domain/model/Auth"
import { GetAllActiveStudentsOfBatchRepository } from "domain/repository/Admin/GetAllActiveStudentsOfBatchRepository"
import BatchStudentsDataSource from "data/dataSource/Admin/BatchStudentsDataSource"

export class GetAllActiveStudentsOfBatchRepositoryImpl implements GetAllActiveStudentsOfBatchRepository {
  private dataSource: BatchStudentsDataSource

  constructor(dataSource: BatchStudentsDataSource) {
    this.dataSource = dataSource
  }
  async getAllStudentOfBatchList(auth: Auth, batch: string): Promise<any> {
    return this.dataSource.getAllStudentOfBatchList(auth, batch)
  }
}
