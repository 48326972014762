import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface GetStudentsByBatchUseCase {
  invoke(data: any): Promise<any>
}

export class GetStudentsByBatch implements GetStudentsByBatchUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(data: any) {
    return this.repository.getStudentsByBatch(data)
  }
}
