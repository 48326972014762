export default function ThumbDownFilled(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87117 15.1287L11.1378 10.862C11.2629 10.737 11.3331 10.5675 11.3332 10.3907V2.66667C11.3332 2.29848 11.0347 2 10.6665 2H3.44651C3.1764 2.00027 2.93315 2.16349 2.83051 2.41333L0.766506 7.42267C0.7003 7.58384 0.666327 7.75643 0.666505 7.93067V9.33333C0.666505 10.0697 1.26346 10.6667 1.99984 10.6667H6.26651L5.49784 13.6867C5.39812 14.078 5.54355 14.4909 5.86651 14.7333L6.43517 15.16C6.56802 15.2596 6.75393 15.2462 6.87117 15.1287ZM12.6665 10H14.6665C15.0347 10 15.3332 9.70152 15.3332 9.33333V2.66667C15.3332 2.29848 15.0347 2 14.6665 2H12.6665V10Z"
        fill="currentColor"
      />
    </svg>
  )
}
