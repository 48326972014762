import { MentorsDetails } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface GetAllMentorsInfoUseCase {
  invoke(details: MentorsDetails): Promise<any>
}

export class GetMentorsDetails implements GetAllMentorsInfoUseCase {
  private repository: MentorDashboardRepository
  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: MentorsDetails): Promise<any> {
    return await this.repository.getAllMentorsDetails(details)
  }
}
