import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"
import { Auth } from "domain/model/Auth"

export interface getbatchHistoryDataUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}

export class GetBatchHistoryData implements getbatchHistoryDataUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string): Promise<any> {
    return await this.repository.getBatchHistoryData(auth, email)
  }
}
