import { Auth } from "domain/model/Auth"
import { TResponse } from "domain/model/Questions"
import { QuestionsRepository } from "domain/repository/Admin/QuestionsRepository"

export interface GetQuestionByTopicUseCase {
  invoke(auth: Auth, topic: string): Promise<TResponse>
}

export default class GetQuestionByTopic implements GetQuestionByTopicUseCase {
  private repository: QuestionsRepository

  constructor(repository: QuestionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, topic: string) {
    return this.repository.getQuestionByTopic(auth, topic)
  }
}
