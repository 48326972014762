import React from "react"

const BosscoderReferLogo = () => {
  return (
    <svg width="180" height="38" viewBox="0 0 180 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer 5">
        <g id="Group 41504">
          <path id="Vector" d="M26.445 5.37242L25.0146 3.61279L26.445 5.37242Z" fill="white" />
          <path
            id="Vector_2"
            d="M26.4451 5.37008C25.555 5.37175 24.6762 5.57194 23.8711 5.95642C23.066 6.3409 22.3545 6.90023 21.7871 7.59464L21.0335 8.52006L20.3062 9.40988C20.2634 9.44808 20.2237 9.48974 20.1876 9.53446L17.6081 12.72C17.5166 12.832 17.4018 12.9222 17.2719 12.9841C17.142 13.046 17.0002 13.0781 16.8566 13.0782H11.3637C11.1822 13.0779 11.0046 13.0254 10.8515 12.9269C10.6983 12.8284 10.576 12.6879 10.4986 12.5217C10.4213 12.3556 10.3921 12.1706 10.4146 11.9883C10.437 11.806 10.5101 11.6339 10.6254 11.4921L17.0302 3.61046L19.2889 0.831985C19.4931 0.580281 19.7482 0.375789 20.037 0.232276C20.3258 0.0887629 20.6416 0.00956026 20.9632 0L21.0928 0C21.414 0.0097312 21.7295 0.0890214 22.0179 0.232533C22.3063 0.376045 22.561 0.580449 22.7649 0.831985L22.9582 1.07001L25.0236 3.61046L26.4451 5.37008Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M30.6835 13.0782H25.1708C25.0272 13.0781 24.8854 13.046 24.7555 12.9841C24.6256 12.9222 24.5108 12.832 24.4193 12.72L21.842 9.55225C21.7506 9.43961 21.6377 9.34677 21.5101 9.27927C21.3824 9.21177 21.2427 9.17099 21.0991 9.15936C20.9556 9.14773 20.8112 9.1655 20.6746 9.2116C20.538 9.2577 20.412 9.33119 20.304 9.42768C20.2612 9.46588 20.2215 9.50754 20.1854 9.55225L17.6081 12.72C17.5166 12.832 17.4018 12.9222 17.2719 12.9841C17.142 13.046 17.0002 13.0781 16.8566 13.0782H11.3637C11.1822 13.0779 11.0046 13.0254 10.8515 12.9269C10.6983 12.8284 10.576 12.6879 10.4986 12.5217C10.4213 12.3556 10.3921 12.1706 10.4146 11.9883C10.437 11.806 10.5101 11.6339 10.6254 11.4921L17.0302 3.61046L19.2889 0.831985C19.4931 0.580281 19.7482 0.375789 20.037 0.232276C20.3258 0.0887629 20.6416 0.00956026 20.9632 0L21.0928 0C21.414 0.0097312 21.7295 0.0890214 22.0179 0.232533C22.3063 0.376045 22.561 0.580449 22.7649 0.831985L22.9582 1.07001L25.0236 3.61046L26.4539 5.37008L31.4196 11.4921C31.5347 11.6338 31.6078 11.8057 31.6303 11.9877C31.6529 12.1698 31.6239 12.3546 31.5468 12.5207C31.4697 12.6868 31.3477 12.8274 31.1949 12.926C31.0421 13.0247 30.8648 13.0775 30.6835 13.0782Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M42.0048 18.4065L37.45 33.1487C37.3256 33.5498 37.0781 33.9003 36.7433 34.1492C36.4086 34.398 36.0042 34.5323 35.589 34.5324H29.7511C29.317 34.5319 28.9007 34.3575 28.5931 34.0474C29.0593 34.0472 29.5132 33.8959 29.8884 33.6157C30.2636 33.3355 30.5404 32.9411 30.6783 32.4902L31.0276 31.3624L31.1946 30.824L32.1592 27.6985C33.0595 24.7847 34.9473 22.2865 37.4895 20.6445L39.6318 19.2608C39.6743 19.2334 39.7093 19.1956 39.7335 19.1509C39.7577 19.1061 39.7703 19.056 39.7702 19.005C39.7714 18.9521 39.7589 18.9 39.7339 18.8536C39.709 18.8072 39.6725 18.7682 39.628 18.7405C39.5836 18.7129 39.5328 18.6975 39.4806 18.6958C39.4285 18.6942 39.3768 18.7065 39.3308 18.7313L33.6642 21.779C33.1417 22.0593 32.5594 22.2059 31.968 22.2061H28.3273C27.9797 22.2061 27.6384 22.1115 27.3393 21.9323C27.0401 21.7531 26.794 21.4957 26.6267 21.1872L25.0227 18.2442C24.9704 18.1479 24.9437 18.0395 24.9454 17.9297C24.9471 17.8198 24.977 17.7123 25.0322 17.6178C25.0875 17.5232 25.1661 17.4449 25.2604 17.3905C25.3547 17.3361 25.4613 17.3075 25.5698 17.3076H41.2006C41.3317 17.3083 41.4609 17.3399 41.5778 17.3999C41.6948 17.4599 41.7963 17.5466 41.8743 17.6533C41.9524 17.76 42.0048 17.8836 42.0274 18.0143C42.0501 18.1451 42.0423 18.2794 42.0048 18.4065Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M17.0223 18.2458L15.4183 21.2045C15.2508 21.5128 15.0047 21.77 14.7056 21.9492C14.4064 22.1284 14.0653 22.2231 13.7177 22.2233H10.0748C9.48339 22.2234 8.90099 22.0767 8.37854 21.7962L2.70981 18.7485C2.6407 18.7133 2.56082 18.7063 2.48676 18.7291C2.4127 18.7519 2.35016 18.8026 2.31212 18.8709C2.28625 18.917 2.27262 18.9691 2.27257 19.0222C2.27483 19.0722 2.28924 19.1209 2.31454 19.164C2.33983 19.2071 2.37522 19.2432 2.41758 19.2691L4.98609 20.9286C7.25001 22.3943 8.93121 24.6211 9.73421 27.2174L10.8504 30.8323L11.0174 31.3707L11.3645 32.4985C11.5042 32.9483 11.7815 33.3415 12.1563 33.6214C12.531 33.9013 12.9839 34.0534 13.4496 34.0557C13.1449 34.3614 12.7339 34.5339 12.3049 34.5362H6.45601C6.04047 34.536 5.63587 34.4014 5.30113 34.1521C4.96639 33.9029 4.719 33.5519 4.59499 33.1503L0.0402285 18.4082C-0.000334406 18.2802 -0.0104034 18.1443 0.0108446 18.0116C0.0320926 17.8789 0.0840545 17.7531 0.162483 17.6447C0.240912 17.5362 0.343581 17.448 0.462098 17.3874C0.580616 17.3268 0.711619 17.2954 0.844398 17.2959H16.4796C16.5896 17.2953 16.6979 17.3241 16.7934 17.3795C16.8889 17.4349 16.9682 17.5149 17.0233 17.6113C17.0784 17.7078 17.1073 17.8173 17.1072 17.9287C17.107 18.0401 17.0777 18.1495 17.0223 18.2458Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M24.5522 25.0047C23.3367 25.0041 22.1688 25.4829 21.296 26.3394L21.0258 26.6041L19.6723 27.9388L16.8753 30.684L14.4584 33.0487L13.4411 34.0475C12.9754 34.0452 12.5224 33.8931 12.1477 33.6132C11.7729 33.3333 11.4956 32.9401 11.3559 32.4903L11.0176 31.3669L10.8506 30.8286C11.0855 30.822 11.3172 30.7714 11.5339 30.6795C11.7504 30.5869 11.9479 30.4541 12.1162 30.288L14.0936 28.3482L17.7981 24.711C18.2174 24.2997 18.7786 24.0698 19.3625 24.0703H22.9703C23.3798 24.0678 23.774 24.2275 24.0689 24.5153L24.5522 25.0047Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M31.2009 30.8286L31.0339 31.3669L30.6846 32.4947C30.5467 32.9456 30.2699 33.34 29.8947 33.6202C29.5195 33.9004 29.0657 34.0517 28.5994 34.0519L27.5844 33.0531L25.1674 30.6884L22.3836 27.9544C22.0185 27.5987 21.5314 27.3999 21.0247 27.3999C20.5179 27.3999 20.0308 27.5987 19.6657 27.9544L16.8753 30.684L14.4584 33.0487L13.4411 34.0475C12.9754 34.0452 12.5224 33.8931 12.1477 33.6132C11.7729 33.3333 11.4956 32.9401 11.3559 32.4903L11.0176 31.3669L10.8506 30.8286C11.0855 30.822 11.3172 30.7714 11.5339 30.6795C11.7504 30.5869 11.9479 30.4541 12.1162 30.288L14.0936 28.3482L17.7981 24.711C18.2174 24.2997 18.7786 24.0698 19.3625 24.0703H22.9703C23.3798 24.0678 23.774 24.2275 24.0689 24.5153L24.5676 25.0047L27.9689 28.3415L29.9463 30.2813C30.1138 30.4484 30.3115 30.5813 30.5286 30.6728C30.7453 30.7648 30.977 30.8153 31.2119 30.8219L31.2009 30.8286Z"
            fill="white"
          />
        </g>
        <path
          id="Vector_8"
          d="M68.8648 9.01998C68.5272 8.54498 68.0585 8.17478 67.514 7.95302C67.4136 7.90874 67.3106 7.8703 67.2055 7.83791C67.6474 7.61346 68.0245 7.28302 68.302 6.8772C68.6119 6.40264 68.7688 5.84713 68.7523 5.2834C68.7624 4.85403 68.665 4.4288 68.4687 4.04512C68.2724 3.66144 67.9832 3.33107 67.6266 3.08307C66.8761 2.52524 65.7541 2.24707 64.2607 2.24854H58.4678V13.9231H64.6074C66.1684 13.9231 67.3504 13.642 68.1534 13.0797C68.5392 12.8215 68.852 12.4712 69.0623 12.0615C69.2726 11.6518 69.3736 11.1963 69.3556 10.7377C69.3769 10.129 69.2054 9.5288 68.8648 9.01998ZM61.1965 4.28285H63.9297C64.4622 4.25177 64.993 4.36672 65.4629 4.61489C65.6396 4.72379 65.7832 4.87745 65.8787 5.05973C65.9742 5.24201 66.018 5.4462 66.0055 5.65086C66.0185 5.85524 65.976 6.05934 65.8822 6.24218C65.7884 6.42503 65.6468 6.58003 65.4719 6.69126C65.1162 6.91262 64.6052 7.03437 63.9387 7.03437H61.1965V4.28285ZM66.0325 11.5391C65.6588 11.7604 65.132 11.8888 64.4205 11.8888H61.1965V9.01112H64.4295C65.132 9.01112 65.6678 9.12844 66.0416 9.36309C66.228 9.48051 66.3787 9.64545 66.4774 9.84034C66.5761 10.0352 66.6192 10.2528 66.6022 10.4699C66.612 10.6817 66.5644 10.8923 66.4644 11.0802C66.3643 11.268 66.2153 11.4264 66.0325 11.5391Z"
          fill="white"
        />
        <path
          id="Vector_9"
          d="M84.4772 5.66684C84.162 4.94256 83.6984 4.28997 83.1151 3.74985C82.5194 3.19974 81.8183 2.77159 81.0528 2.49031C80.2261 2.18845 79.35 2.03838 78.4682 2.04759C77.5846 2.03518 76.7064 2.18533 75.879 2.49031C75.1135 2.77791 74.4129 3.21035 73.8167 3.76313C73.2343 4.30189 72.77 4.95197 72.4524 5.67348C72.1221 6.43512 71.9572 7.25619 71.9683 8.0841C71.959 8.90899 72.1238 9.72682 72.4524 10.4859C72.769 11.208 73.2334 11.8582 73.8167 12.3962C74.4159 12.9474 75.1187 13.3782 75.8858 13.6646C77.5545 14.2579 79.3818 14.2579 81.0505 13.6646C81.8154 13.3889 82.5171 12.9668 83.1151 12.4228C83.7009 11.8809 84.1655 11.225 84.4794 10.4969C84.8076 9.73464 84.9723 8.91402 84.9635 8.08632C84.9732 7.25545 84.8076 6.43163 84.4772 5.66684ZM81.8993 9.596C81.7186 10.038 81.4508 10.4405 81.1113 10.7803C80.7756 11.1167 80.3743 11.3832 79.9316 11.5639C79.4666 11.7509 78.9682 11.8443 78.4659 11.8384C77.96 11.8438 77.458 11.7505 76.989 11.5639C76.5447 11.3859 76.143 11.1191 75.8092 10.7803C75.4747 10.4383 75.2102 10.0362 75.0302 9.596C74.8374 9.11557 74.7418 8.60275 74.7488 8.08632C74.7416 7.56986 74.8372 7.05702 75.0302 6.57663C75.2102 6.1364 75.4747 5.73434 75.8092 5.39235C76.143 5.05357 76.5447 4.78672 76.989 4.60873C77.458 4.42216 77.96 4.32886 78.4659 4.33425C78.9682 4.32838 79.4666 4.42172 79.9316 4.60873C80.3743 4.78943 80.7756 5.05593 81.1113 5.39235C81.4508 5.73217 81.7186 6.13465 81.8993 6.57663C82.0923 7.05702 82.1879 7.56986 82.1807 8.08632C82.1877 8.60275 82.0921 9.11557 81.8993 9.596Z"
          fill="white"
        />
        <path
          id="Vector_10"
          d="M96.8618 8.9936C96.6167 8.59658 96.2767 8.26449 95.8712 8.02625C95.448 7.78168 94.9988 7.58348 94.5316 7.43522C94.043 7.28026 93.5522 7.14966 93.0547 7.04341C92.5571 6.93715 92.1046 6.83312 91.7038 6.72686C91.3491 6.64522 91.0146 6.4947 90.7199 6.28414C90.5998 6.19851 90.5026 6.08548 90.4367 5.95477C90.3709 5.82406 90.3382 5.67957 90.3417 5.53373C90.3397 5.29393 90.4189 5.0603 90.5668 4.86964C90.7548 4.64551 91.0052 4.48012 91.2873 4.39372C91.7102 4.2633 92.1524 4.20417 92.5954 4.21884C93.1455 4.22153 93.6929 4.29597 94.2231 4.4402C94.7995 4.59333 95.3528 4.82033 95.8689 5.11535L96.7335 3.08105C96.1425 2.72772 95.498 2.46918 94.8243 2.31514C94.1003 2.13789 93.3572 2.04794 92.6111 2.04729C91.6564 2.01761 90.7059 2.1858 89.8216 2.54092C89.151 2.81159 88.5757 3.26948 88.1668 3.85802C87.8052 4.40038 87.6149 5.03578 87.6197 5.68425C87.5999 6.21562 87.7258 6.74244 87.9845 7.20943C88.2256 7.61145 88.5645 7.94838 88.9706 8.19006C89.3934 8.44507 89.8473 8.64663 90.3214 8.78995C90.8122 8.93752 91.3008 9.06517 91.7871 9.1729C92.2734 9.28063 92.7237 9.39205 93.138 9.50716C93.5001 9.59709 93.8426 9.75084 94.1488 9.96095C94.2682 10.0455 94.3646 10.1575 94.4298 10.2871C94.4949 10.4168 94.5268 10.5602 94.5226 10.7047C94.5258 10.9398 94.4417 11.168 94.2862 11.3467C94.0849 11.5568 93.8298 11.7097 93.5477 11.7894C93.1215 11.9146 92.6775 11.9714 92.2329 11.9576C91.5064 11.957 90.7848 11.8404 90.0963 11.6123C89.4529 11.4107 88.8452 11.112 88.2952 10.7269L87.3721 12.7722C88.009 13.2225 88.7249 13.5534 89.4839 13.7484C90.3746 14.0002 91.2971 14.1262 92.2239 14.1225C93.1823 14.153 94.1363 13.9824 95.0224 13.6223C95.6954 13.3484 96.2749 12.8915 96.693 12.3052C97.0568 11.7727 97.249 11.1448 97.2446 10.5033C97.2638 9.97469 97.1312 9.45145 96.8618 8.9936Z"
          fill="white"
        />
        <path
          id="Vector_11"
          d="M108.956 8.99435C108.71 8.59805 108.371 8.26616 107.966 8.027C107.539 7.78141 107.086 7.58319 106.615 7.43597C106.129 7.28101 105.637 7.15041 105.14 7.04416C104.643 6.9379 104.19 6.83386 103.789 6.72761C103.435 6.64597 103.1 6.49545 102.806 6.28489C102.687 6.19841 102.591 6.08504 102.526 5.9544C102.462 5.82376 102.43 5.67971 102.434 5.53447C102.432 5.29471 102.511 5.06113 102.659 4.87039C102.847 4.64626 103.098 4.48087 103.38 4.39447C103.803 4.26388 104.245 4.20475 104.688 4.21959C105.238 4.22228 105.785 4.29672 106.316 4.44095C106.892 4.59408 107.445 4.82108 107.961 5.1161L108.826 3.08179C108.235 2.7277 107.591 2.46841 106.917 2.31367C106.193 2.13642 105.45 2.04648 104.704 2.04582C103.749 2.01614 102.798 2.18433 101.914 2.53946C101.243 2.81012 100.668 3.26801 100.259 3.85656C99.8992 4.39941 99.7104 5.03477 99.7166 5.68279C99.6968 6.21415 99.8227 6.74098 100.081 7.20796C100.323 7.61106 100.663 7.9488 101.07 8.19081C101.493 8.44582 101.946 8.64737 102.421 8.7907C102.911 8.93827 103.4 9.06592 103.886 9.17365C104.373 9.28138 104.823 9.3928 105.237 9.50791C105.592 9.59644 105.928 9.74634 106.23 9.95063C106.349 10.0352 106.446 10.1472 106.511 10.2768C106.576 10.4065 106.608 10.5498 106.604 10.6944C106.607 10.9299 106.522 11.1585 106.365 11.3363C106.165 11.5469 105.911 11.6999 105.629 11.7791C105.203 11.9043 104.759 11.9611 104.314 11.9473C103.593 11.9457 102.877 11.8299 102.193 11.6042C101.55 11.4031 100.942 11.1044 100.392 10.7188L99.46 12.7531C100.097 13.2111 100.816 13.5489 101.579 13.7492C102.469 14.0009 103.392 14.1269 104.319 14.1233C105.277 14.1537 106.231 13.9832 107.117 13.623C107.788 13.3485 108.366 12.8916 108.783 12.3059C109.147 11.7734 109.339 11.1455 109.335 10.504C109.354 9.97597 109.223 9.45305 108.956 8.99435Z"
          fill="white"
        />
        <path
          id="Vector_12"
          d="M119.965 11.4798C119.454 11.7206 118.894 11.8433 118.328 11.8385C117.806 11.8445 117.288 11.7513 116.802 11.564C116.351 11.3882 115.941 11.1238 115.597 10.787C115.255 10.4503 114.987 10.0479 114.809 9.60492C114.617 9.12129 114.521 8.60566 114.528 8.08638C114.521 7.5671 114.617 7.05147 114.809 6.56784C114.986 6.12388 115.254 5.72067 115.597 5.38353C115.94 5.0464 116.35 4.78263 116.802 4.6088C117.288 4.42148 117.806 4.32828 118.328 4.33431C118.894 4.32863 119.453 4.44817 119.965 4.68406C120.486 4.92903 120.946 5.28165 121.316 5.71782L123.097 4.11738C122.536 3.4386 121.814 2.90635 120.994 2.56785C119.281 1.89565 117.376 1.86572 115.642 2.48373C114.879 2.76457 114.181 3.19284 113.589 3.74328C113.011 4.28587 112.552 4.93802 112.238 5.66026C111.91 6.42569 111.746 7.24928 111.756 8.07974C111.746 8.90951 111.91 9.73243 112.238 10.497C112.554 11.2258 113.018 11.8835 113.601 12.43C114.185 12.9766 114.875 13.4004 115.631 13.6757C116.447 13.9759 117.313 14.126 118.184 14.1185C119.15 14.1331 120.109 13.9515 121.001 13.585C121.819 13.2444 122.541 12.7125 123.103 12.0355L121.323 10.435C120.95 10.8734 120.487 11.2294 119.965 11.4798Z"
          fill="white"
        />
        <path
          id="Vector_13"
          d="M137.709 5.66686C137.393 4.94239 136.929 4.28978 136.344 3.74987C135.748 3.2007 135.047 2.77267 134.282 2.49033C133.455 2.1882 132.578 2.03813 131.695 2.04761C130.812 2.035 129.935 2.18517 129.108 2.49033C128.344 2.77819 127.644 3.21061 127.048 3.76316C126.465 4.30141 126 4.95158 125.682 5.6735C125.352 6.43541 125.188 7.25629 125.198 8.08412C125.189 8.90891 125.354 9.72652 125.682 10.4859C125.998 11.2085 126.464 11.8589 127.048 12.3962C127.647 12.9479 128.35 13.3788 129.117 13.6646C130.786 14.2579 132.613 14.2579 134.282 13.6646C135.047 13.3823 135.748 12.9543 136.344 12.4051C136.93 11.8632 137.395 11.2073 137.709 10.4792C138.036 9.71663 138.201 8.89624 138.193 8.06863C138.2 7.24397 138.035 6.42664 137.709 5.66686ZM135.124 9.59602C134.943 10.0381 134.674 10.4406 134.334 10.7803C133.999 11.1167 133.599 11.3832 133.156 11.5639C132.691 11.7507 132.192 11.844 131.689 11.8384C131.183 11.8438 130.682 11.7505 130.214 11.5639C129.774 11.3846 129.376 11.1178 129.045 10.7803C128.71 10.4384 128.445 10.0364 128.264 9.59602C128.072 9.11544 127.977 8.60263 127.985 8.08634C127.977 7.57003 128.072 7.05719 128.264 6.57665C128.445 6.1363 128.71 5.73424 129.045 5.39237C129.379 5.05359 129.781 4.78674 130.225 4.60875C130.693 4.42213 131.195 4.32882 131.7 4.33427C132.203 4.32864 132.702 4.42197 133.168 4.60875C133.61 4.7895 134.01 5.05601 134.345 5.39237C134.681 5.73309 134.946 6.13549 135.124 6.57665C135.316 7.05719 135.411 7.57003 135.403 8.08634C135.411 8.60263 135.316 9.11544 135.124 9.59602Z"
          fill="white"
        />
        <path
          id="Vector_14"
          d="M152.645 5.00909C152.086 4.13084 151.286 3.42568 150.337 2.97478C149.275 2.47298 148.107 2.22425 146.929 2.24872H141.525V13.9233H146.929C148.107 13.9478 149.275 13.699 150.337 13.1972C151.286 12.7457 152.086 12.0407 152.645 11.1629C153.214 10.2358 153.502 9.16845 153.476 8.08601C153.503 7.00352 153.214 5.93605 152.645 5.00909ZM150.209 10.0118C149.897 10.5593 149.427 11.0037 148.858 11.2891C148.218 11.5967 147.511 11.7485 146.798 11.7318H144.299V4.44904H146.794C147.507 4.43234 148.213 4.58417 148.854 4.89177C149.419 5.17338 149.889 5.61113 150.205 6.15131C150.539 6.74206 150.706 7.41016 150.689 8.08601C150.705 8.75837 150.54 9.42296 150.209 10.0118Z"
          fill="white"
        />
        <path
          id="Vector_15"
          d="M166.011 11.7538V13.9209H156.816V2.24854H165.791V4.41566H159.55V6.93475H165.061V9.05096H159.55V11.7538H166.011Z"
          fill="white"
        />
        <path
          id="Vector_16"
          d="M177.343 10.1702H177.361C178.086 9.87703 178.706 9.37757 179.142 8.73577C179.573 8.05974 179.791 7.27344 179.77 6.47567C179.789 5.68095 179.571 4.89809 179.142 4.22443C178.707 3.57676 178.087 3.06939 177.361 2.76566C176.491 2.40639 175.554 2.23284 174.612 2.25653H169.472V13.9245H172.236V10.655H174.731L177.036 13.9245H179.988L177.343 10.1702ZM176.359 7.96986C175.93 8.32551 175.296 8.50334 174.459 8.50334H172.236V4.45243H174.459C175.296 4.45243 175.929 4.62509 176.359 4.97484C176.575 5.15891 176.745 5.38938 176.857 5.64846C176.968 5.90753 177.017 6.18828 177 6.46903C177.017 6.75074 176.968 7.03242 176.856 7.29255C176.745 7.55267 176.575 7.78435 176.359 7.96986Z"
          fill="white"
        />
        <path
          id="Vector_17"
          d="M56.918 34.4662L61.7248 23.8408H64.2013L69.0531 34.4662H66.4235L62.452 25.076H63.4561L59.4756 34.4662H56.918ZM59.345 32.1861L59.9777 30.3178H65.5724L66.2298 32.1861H59.345Z"
          fill="white"
        />
        <path
          id="Vector_18"
          d="M75.8213 34.5881C74.9927 34.6035 74.1731 34.418 73.4348 34.048C72.9292 33.7779 72.4843 33.4104 72.1265 32.9673C71.7688 32.5242 71.5057 32.0148 71.3528 31.4694C71.2 30.924 71.1607 30.3539 71.2372 29.7932C71.3137 29.2325 71.5044 28.6928 71.798 28.2063C72.1978 27.5743 72.7631 27.0598 73.4348 26.7165C74.1724 26.3418 74.9934 26.1539 75.8235 26.1698C76.623 26.1509 77.4137 26.3371 78.1177 26.7099C78.7513 27.0459 79.2498 27.5837 79.5316 28.2351L77.6787 29.24C77.4871 28.8919 77.2041 28.6007 76.8592 28.3966C76.5385 28.2187 76.176 28.1263 75.8078 28.1288C75.4251 28.125 75.0477 28.2172 74.7113 28.3966C74.3799 28.5738 74.106 28.8393 73.9211 29.1626C73.716 29.5351 73.615 29.9544 73.6284 30.3778C73.6153 30.804 73.7162 31.2261 73.9211 31.602C74.1044 31.9287 74.3785 32.1974 74.7113 32.3767C75.0479 32.5554 75.4253 32.6468 75.8078 32.6424C76.1758 32.6456 76.5383 32.554 76.8592 32.3767C77.2071 32.1695 77.4906 31.8724 77.6787 31.5178L79.5316 32.5206C79.2451 33.1678 78.7477 33.7028 78.1177 34.0414C77.4144 34.4188 76.6223 34.6073 75.8213 34.5881Z"
          fill="white"
        />
        <path
          id="Vector_19"
          d="M85.3671 34.5876C84.7981 34.6024 84.2332 34.4904 83.7145 34.26C83.2802 34.0672 82.9079 33.7609 82.6383 33.3745C82.3866 33.0007 82.2548 32.5611 82.2601 32.1128C82.2436 31.6445 82.3825 31.1837 82.6559 30.8C82.9292 30.4162 83.3221 30.1303 83.7753 29.9855C84.441 29.7605 85.1431 29.6577 85.8466 29.6822H88.0418V31.048H86.0943C85.5269 31.048 85.1374 31.141 84.9258 31.3225C84.8255 31.4068 84.7453 31.5118 84.6908 31.63C84.6363 31.7482 84.6089 31.8767 84.6106 32.0065C84.6057 32.1469 84.6362 32.2862 84.6995 32.4122C84.7627 32.5381 84.8567 32.6467 84.9731 32.7281C85.2625 32.9186 85.6069 33.0118 85.9547 32.9938C86.3411 33.0003 86.7225 32.9064 87.0601 32.7215C87.3822 32.5336 87.6226 32.2358 87.7356 31.8847L88.107 32.9916C87.9422 33.485 87.6016 33.9032 87.1479 34.1692C86.6046 34.4666 85.9885 34.6113 85.3671 34.5876ZM87.9022 34.4658V32.8698L87.7468 32.5201V29.6623C87.7584 29.443 87.7224 29.2239 87.6412 29.0193C87.5601 28.8148 87.4357 28.6295 87.2763 28.4758C86.9611 28.1924 86.4793 28.0508 85.8309 28.0508C85.3872 28.0508 84.9464 28.1203 84.525 28.2566C84.1273 28.3782 83.7535 28.5653 83.4196 28.81L82.5708 27.1543C83.0656 26.8116 83.6205 26.5618 84.2076 26.4171C84.843 26.2512 85.4976 26.1671 86.1551 26.167C87.4219 26.167 88.4057 26.4621 89.1067 27.0524C89.8076 27.6427 90.1581 28.5599 90.1581 29.804V34.4658H87.9022Z"
          fill="white"
        />
        <path
          id="Vector_20"
          d="M97.8448 34.5868C97.104 34.5976 96.3734 34.4167 95.7262 34.0622C95.1035 33.7179 94.5899 33.2106 94.2425 32.5968C93.8892 31.9106 93.7051 31.1523 93.7051 30.3832C93.7051 29.6141 93.8892 28.8558 94.2425 28.1696C94.5893 27.5553 95.103 27.0479 95.7262 26.7042C96.3734 26.3497 97.104 26.1688 97.8448 26.1796C98.4885 26.1658 99.124 26.3234 99.6842 26.6356C100.228 26.9597 100.655 27.4436 100.904 28.0191C101.218 28.7694 101.366 29.5769 101.337 30.3876C101.363 31.1975 101.221 32.0041 100.92 32.7584C100.685 33.3371 100.264 33.8249 99.7225 34.1486C99.1477 34.4571 98.4993 34.6085 97.8448 34.5868ZM98.2455 32.6411C98.6239 32.6471 98.9973 32.5555 99.3285 32.3754C99.654 32.1918 99.9195 31.9207 100.094 31.594C100.291 31.2181 100.39 30.7995 100.38 30.3766C100.392 29.9541 100.294 29.5356 100.094 29.1613C99.9164 28.8408 99.6513 28.5755 99.3285 28.3954C98.9975 28.2146 98.6241 28.1222 98.2455 28.1275C97.8728 28.1233 97.5055 28.2157 97.1806 28.3954C96.8574 28.5749 96.5921 28.8404 96.4151 29.1613C96.2141 29.5351 96.1156 29.9539 96.1292 30.3766C96.1183 30.7996 96.2167 31.2185 96.4151 31.594C96.589 31.9212 96.8546 32.1924 97.1806 32.3754C97.5057 32.5544 97.873 32.646 98.2455 32.6411ZM100.452 34.4651V32.7938L100.499 30.3588L100.344 27.9416V23.189H102.755V34.4651H100.452Z"
          fill="white"
        />
        <path
          id="Vector_21"
          d="M110.966 34.5874C110.103 34.6069 109.248 34.4192 108.476 34.0406C107.787 33.7023 107.21 33.178 106.814 32.5292C106.417 31.8804 106.216 31.1339 106.236 30.3771C106.223 29.6166 106.418 28.8666 106.801 28.2056C107.172 27.5704 107.718 27.0516 108.377 26.7092C109.078 26.3425 109.861 26.1567 110.655 26.169C111.422 26.1581 112.18 26.3354 112.859 26.6848C113.503 27.0221 114.041 27.5267 114.413 28.1436C114.81 28.8331 115.007 29.6161 114.985 30.4081C114.985 30.4989 114.985 30.6029 114.969 30.7202C114.953 30.8375 114.949 30.9416 114.937 31.0456H108.183V29.6621H113.67L112.726 30.0716C112.746 29.6878 112.661 29.3059 112.481 28.9648C112.312 28.6579 112.061 28.4028 111.754 28.2277C111.424 28.0453 111.05 27.9535 110.671 27.9621C110.29 27.9544 109.914 28.046 109.581 28.2277C109.27 28.4025 109.016 28.6604 108.847 28.9715C108.665 29.3218 108.575 29.7115 108.586 30.1048V30.4701C108.575 30.8854 108.682 31.2955 108.895 31.6544C109.096 31.9859 109.391 32.2527 109.743 32.4225C110.15 32.6089 110.594 32.6999 111.042 32.6881C111.435 32.6948 111.826 32.6279 112.193 32.4911C112.536 32.3536 112.849 32.1521 113.114 31.8979L114.397 33.2659C114.003 33.6991 113.51 34.0344 112.958 34.2443C112.323 34.4832 111.646 34.5996 110.966 34.5874Z"
          fill="white"
        />
        <path
          id="Vector_22"
          d="M129.11 26.1692C129.718 26.1571 130.32 26.29 130.864 26.5566C131.379 26.8192 131.797 27.2332 132.061 27.7409C132.373 28.3778 132.521 29.08 132.494 29.7863V34.4658H130.082V30.1493C130.082 29.4926 129.947 29.0063 129.675 28.6905C129.531 28.5324 129.352 28.4081 129.153 28.3269C128.953 28.2456 128.738 28.2095 128.522 28.2212C128.188 28.2143 127.857 28.2953 127.565 28.4559C127.282 28.617 127.058 28.8617 126.924 29.1554C126.759 29.5369 126.682 29.9497 126.699 30.364V34.4658H124.287V30.1493C124.287 29.4852 124.152 29.0049 123.878 28.6905C123.729 28.5302 123.545 28.4048 123.341 28.3236C123.136 28.2424 122.916 28.2074 122.696 28.2212C122.366 28.2158 122.04 28.2967 121.752 28.4559C121.469 28.6192 121.243 28.8631 121.104 29.1554C120.936 29.5362 120.859 29.9496 120.879 30.364V34.4658H118.463V26.291H120.766V28.5046L120.334 27.8671C120.593 27.3276 121.021 26.8834 121.554 26.5987C122.106 26.3025 122.726 26.1501 123.355 26.1559C124.068 26.1422 124.769 26.3405 125.366 26.7248C125.963 27.1365 126.373 27.7606 126.509 28.4647L125.658 28.2434C125.935 27.618 126.399 27.0906 126.989 26.7315C127.626 26.3463 128.362 26.1509 129.11 26.1692Z"
          fill="white"
        />
        <path
          id="Vector_23"
          d="M137.443 37.5362C137.012 37.5349 136.585 37.4677 136.175 37.3369C135.791 37.2264 135.434 37.0404 135.126 36.7902L136.009 35.0879C136.193 35.2605 136.41 35.3952 136.648 35.4841C136.883 35.5737 137.132 35.6202 137.384 35.6214C137.669 35.6347 137.95 35.5511 138.179 35.3845C138.421 35.1696 138.609 34.9021 138.728 34.6031L139.161 33.6004L139.345 33.3414L142.344 26.291H144.647L140.89 34.975C140.681 35.5421 140.369 36.0672 139.969 36.5245C139.657 36.8725 139.265 37.1413 138.825 37.3082C138.382 37.4639 137.914 37.5411 137.443 37.5362ZM138.863 34.7846L135.171 26.291H137.648L140.538 33.1532L138.863 34.7846Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default BosscoderReferLogo
