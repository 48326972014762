import React from "react"
import Line from "../../../../core/components/payment-method/Line"
import { Link } from "react-router-dom"

const OfferAd = ({
  height,
  width,
}: {
  height: number
  width: number
  cut_gap: number
  transparent_color: string
  img_size: number
}) => {
  const cardHeight = height
  const cardWidth = width

  return (
    <div
      className={`relative mx-auto bg-[#333] overflow-hidden h-[${cardHeight}px] w-[${cardWidth}px] rounded-xl mb-[10px]`}
    >
      {/* Main content */}
      <div className={`relative h-[${cardHeight}px] w-[${cardWidth}px] bg-[#333] px-[20px] py-[10px]`}>
        <div className="absolute top-0 -right-20">
          <Line />
        </div>
        <div className="absolute top-0 -right-10">
          <Line />
        </div>
        <p className="text-[16px] text-[#E3E3E3]">Off Upto</p>
        <p style={{ textShadow: "-1px 1px 0px #FFF" }} className="mt-[12px] font-[800] text-[28px] text-[#EBD617]">
          10,000
          <span className="ml-[2px] text-[14px] text-[#FFF] font-[500] font-[Inter] size[21px]">Rs</span>
        </p>
        <p className="mt-[10px] text-[15px] text-[#E3E3E3] font-[400]">Offer for Limited Period</p>

        <Link to="/payment">
          <button className="relative z-10 mt-[10px] font-[400] px-[15px] text-[#FFF] py-[5px] rounded-[4px] border-[0.5px] border-[#FFF] hover:bg-[#474747]">
            View Offer
          </button>
        </Link>
      </div>
    </div>
  )
}

export default OfferAd
