import PaymentDataSource from "data/dataSource/Student/PaymentDataSource"
import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export class PaymentRepositoryImpl implements PaymentRepository {
  private dataSource: PaymentDataSource

  constructor(dataSource: PaymentDataSource) {
    this.dataSource = dataSource
  }
  async getMethodDiscount(auth: Auth): Promise<any> {
    return this.dataSource.getMethodDiscount(auth)
  }
  async getPaymentMethod(auth: Auth): Promise<any> {
    return this.dataSource.getPaymentMethod(auth)
  }

  async getPaymentDocuments(auth: Auth): Promise<any> {
    return this.dataSource.getPaymentDocuments(auth)
  }

  async uploadPaymentDocument(auth: Auth, file: File, filename: string, documentId: number): Promise<any> {
    return this.dataSource.uploadPaymentDocument(auth, file, filename, documentId)
  }

  async deletePaymentDocument(auth: Auth, documentId: number): Promise<any> {
    return this.dataSource.deletePaymentDocument(auth, documentId)
  }

  async getStudentEMI(auth: Auth): Promise<any> {
    return this.dataSource.getStudentEMI(auth)
  }

  async createStudentEMI(auth: Auth, student_payment_emi: any): Promise<any> {
    return this.dataSource.createStudentEMI(auth, student_payment_emi)
  }

  async getFeeAfterDiscount(auth: Auth): Promise<any> {
    return this.dataSource.getFeeAfterDiscount(auth)
  }

  getStudentPaymentDetails(auth: Auth) {
    return this.dataSource.getStudentPaymentDetails(auth)
  }

  getStudentPaymentDetailsAll(auth: Auth) {
    return this.dataSource.getStudentPaymentDetailsAll(auth)
  }

  async createPaymentOneShot(auth: Auth): Promise<any> {
    return await this.dataSource.createPaymentOneShot(auth)
  }

  async scratchCardDetails(auth: Auth, data: any): Promise<any> {
    return await this.dataSource.scratchCardDetails(auth, data)
  }
  async fetchScratchCardDetails(auth: Auth): Promise<any> {
    return await this.dataSource.fetchScratchCardDetails(auth)
  }
}
