import { jobDetails } from "domain/model/Placement"
import { PlacementRepository } from "domain/repository/Student/PlacementRepository"

export interface GetJobsDetailsUseCase {
  invoke(request: jobDetails): Promise<any>
}

export class JobDetails implements GetJobsDetailsUseCase {
  private repository: PlacementRepository

  constructor(repository: PlacementRepository) {
    this.repository = repository
  }

  async invoke(request: jobDetails): Promise<any> {
    return await this.repository.getJobDetails(request)
  }
}
