import BatchWeeklyReportDataSource from "data/dataSource/Admin/BatchWeeklyReportDataSource"
import GetBatchWeeklyReport from "domain/model/GetBatchWeeklyReport"
import BatchWeeklyReportRepositry from "domain/repository/Admin/BatchWeeklyReport"

export class BatchWeeklyReportRepositoryImpl implements BatchWeeklyReportRepositry {
  private Datasource: BatchWeeklyReportDataSource

  constructor(Datasource: BatchWeeklyReportDataSource) {
    this.Datasource = Datasource
  }

  async getBatchWeeklyReport(weeklyReport: GetBatchWeeklyReport): Promise<any> {
    return await this.Datasource.getBatchWeeklyReport(weeklyReport)
  }
}
