import { Auth } from "domain/model/Auth"
import ContestResultRepository from "domain/repository/Student/ContestResultRepository"

export interface ContestResultUseCase {
  invoke(auth: Auth, contestId: any): Promise<any>
}

export default class ContestResult implements ContestResultUseCase {
  private repository: ContestResultRepository
  constructor(repository: ContestResultRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contestId: any): Promise<any> {
    return await this.repository.contestResult(auth, contestId)
  }
}
