import { JobDashboardDataSource } from "data/dataSource/Admin/JobDashboardDataSource"
import { Auth } from "domain/model/Auth"
import {
  createCompany,
  CreateJob,
  deleteJob,
  jobApplicationStatus,
  jobDetails,
  UpdateJob,
  UpdateJobStatus,
} from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export class JobDashboardRepositoryImpl implements JobDashboardRepository {
  private dataSource: JobDashboardDataSource

  constructor(dataSource: JobDashboardDataSource) {
    this.dataSource = dataSource
  }

  getCompaniesList(request: Auth): Promise<any> {
    return this.dataSource.getCompaniesList(request)
  }

  updateJob(request: UpdateJob): Promise<any> {
    return this.dataSource.updateJob(request)
  }

  updateJobStatus(request: UpdateJobStatus): Promise<any> {
    return this.dataSource.updateJobStatus(request)
  }

  createJob(request: CreateJob): Promise<any> {
    return this.dataSource.createJob(request)
  }

  getAllJobs(request: Auth): Promise<any> {
    return this.dataSource.getAllJobs(request)
  }

  createCompany(request: createCompany): Promise<any> {
    return this.dataSource.createCompany(request)
  }

  getApplicationStatus(request: jobApplicationStatus): Promise<any> {
    return this.dataSource.getApplicationStatus(request)
  }

  getJobDetails(request: jobDetails): Promise<any> {
    return this.dataSource.getJobDetails(request)
  }
  setApplicationStatus(request: jobApplicationStatus): Promise<any> {
    return this.dataSource.setApplicationStatus(request)
  }

  deleteJob(request: deleteJob): Promise<any> {
    return this.dataSource.deleteJob(request)
  }
  getJobApplications(request: deleteJob): Promise<any> {
    return this.dataSource.getJobApplications(request)
  }
}
