import { Tooltip } from "@mui/material"
import Loader from "core/components/Loader"
import Button from "core/components/new/Button"
import { CrossIcon } from "core/constants/svgs"
import React from "react"

export default function ContestModal({ loading, data, open, onClose, onClick }: any) {
  const [enterContestDisabled, setEnterContestDisabled] = React.useState(true)

  React.useEffect(() => {
    if (open) {
      const checkContestStatus = () => {
        const currTimestamp = Math.floor(Date.now() / 1000)
        const startTimestamp = parseInt(data?.start_time)
        const duration = parseInt(data?.duration)
        const endTimestamp = startTimestamp + duration * 60

        const isContestRunning =
          (currTimestamp >= startTimestamp && currTimestamp <= endTimestamp) || currTimestamp > endTimestamp

        const isLiveContest = data?.type === "live"

        if (isLiveContest) {
          setEnterContestDisabled(!isContestRunning)
        } else {
          setEnterContestDisabled(!(currTimestamp >= startTimestamp))
        }
      }

      checkContestStatus()

      const interval = setInterval(checkContestStatus, 1000)

      return () => clearInterval(interval)
    }
  }, [open, data])

  if (!open) return null

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          {loading ? (
            <Loader />
          ) : (
            <div className="m-auto flex w-[680px] transform flex-col gap-4 rounded-lg bg-new-solid-white p-6 text-new-neutral">
              <div className="flex justify-between">
                <h3 className="text-new-neutral-dark">Exam Guidelines</h3>
                <button type="button" onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
              {/* {data?.mcq_lis?.length > 0 && (
                <div className="space-y-[1ch]">
                 
                  <ul className="list-disc pl-8 text-new-accent">
                    <li>{data?.mcq_lis?.length} Questions</li>
                    <li>
                      {data?.mcq_lis?.reduce((total: number, mcq: any) => total + mcq?.score, 0)}{" "}
                      Marks
                    </li>
                  </ul>
                </div>
              )}
              {data?.question_lis?.length > 0 && (
                <div className="space-y-[1ch]">
                  <h4>Coding Problem Details:</h4>
                 
                  <ul className="list-disc pl-8 text-new-accent">
                    <li>{data?.question_lis?.length} Questions</li>
                    <li>
                      {data?.question_lis?.reduce(
                        (total: number, question: any) => total + question?.score,
                        0
                      )}{" "}
                      Marks
                    </li>
                  </ul>
                </div>
              )}
              {data?.subjective_lis?.length > 0 && (
                <div className="space-y-[1ch]">
                  <p>Subjective Questions:</p>
                  <ul className="list-disc pl-8 text-new-accent">
                    <li>{data?.subjective_lis?.length} Questions</li>
                    <li>
                      {data?.subjective_lis?.reduce((total: number, q: any) => total + q?.score, 0)}{" "}
                      Marks
                    </li>
                  </ul>
                </div>
              )}
              {data?.casestudy_lis?.length > 0 && (
                <div className="space-y-[1ch]">
                  <p>Casestudy Questions:</p>
                  <ul className="list-disc pl-8 text-new-accent">
                    <li>{data?.casestudy_lis?.length} Questions</li>
                    <li>
                      {data?.casestudy_lis?.reduce((total: number, q: any) => total + q?.score, 0)}{" "}
                      Marks
                    </li>
                  </ul>
                </div>
              )} */}
              <ol className="list-decimal m-4">
                <li>
                  <strong>No Tab/Window Switching:</strong> Switching between tabs or windows during the exam is
                  prohibited. Doing so will result in the automatic submission of your exam.
                </li>
                <li>
                  <strong>Window Management:</strong> Do not minimize or restore the exam window, as this action will
                  also lead to the automatic submission of your exam.
                </li>
                <li>
                  <strong>No Developer Tools:</strong> Attempting to open developer tools (inspect element) will cause
                  your exam to end automatically.
                </li>
                <li>
                  <strong>Disable Notifications:</strong> Please disable desktop notifications before starting the exam.
                  Any notifications that appear during the exam will be considered a violation of the guidelines and
                  will result in the automatic submission of your exam.
                </li>
                <li>
                  <strong>Stable Internet Connection:</strong> Ensure a stable internet connection to complete the exam
                  smoothly and without interruptions.
                </li>
              </ol>

              <div className="flex justify-end">
                <Tooltip title={enterContestDisabled ? "Exam has not started yet" : ""} placement="top">
                  <span>
                    <Button onClick={onClick} disabled={enterContestDisabled} className="w-fit">
                      Start Exam
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
