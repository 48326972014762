import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import NewMentorSessionsAPIDataSourceImpl from "data/API/Admin/NewMentorSessionsAPIDataSourceImpl"
import { NewMentorSessionsRepositoryImpl } from "data/repository/Admin/NewMentorSessionsRepositoryImpl"
import { GetBatchTemplateGeneric } from "domain/useCase/Admin/NewMentorSessions/GetBatchTemplateGeneric"
import { UpdateBatchTemplateGeneric } from "domain/useCase/Admin/NewMentorSessions/UpdateBatchTemplateGeneric"
import React from "react"

export default function MentorshipEditViewModel() {
  const [mentorshipType, setMentorshipType] = React.useState("")
  const [studentId, setStudentId] = React.useState("")
  const [mentorshipData, setMentorshipData] = React.useState<{ msid: string; name: string; agenda: string }[]>([])
  const [items, setItems] = React.useState<{ msid: string; name: string; agenda: string }[]>([])
  const [nonGenericItems, setNonGenericItems] = React.useState<
    {
      msid: string
      name: string
      agenda: string
      startDate: any
      endDate: any
    }[]
  >([])
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [indexToBeDeleted, setIndexToBeDeleted] = React.useState(-1)
  const [timeValue, setTimeValue] = React.useState<any>()
  const [startDate, setStartDate] = React.useState<any>()
  const [endDate, setEndDate] = React.useState<any>()
  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const genericBatchTypes = ["SDE_BEG_DEFAULT", "SDE_ADV_DEFAULT", "DSML_BEG_DEFAULT", "DSML_ADV_DEFAULT", "EVOLVE"]

  function extractMentorshipData() {
    const currentPath = window.location.pathname
    const pathSegments = currentPath.split("/")
    const mentorshipIndex = pathSegments.indexOf("mentorship")
    let mentorshipType = ""
    let studentId = ""

    if (mentorshipIndex !== -1) {
      const sdeIndex = mentorshipIndex + 1
      const studentIndex = mentorshipIndex + 2
      mentorshipType = decodeURIComponent(pathSegments[sdeIndex])
      studentId = decodeURIComponent(pathSegments[studentIndex])
      setMentorshipType(mentorshipType)
      setStudentId(studentId.toLowerCase())
    }
  }
  const { auth } = useAuth()

  const GetBatchTemplateGenericUseCase = new GetBatchTemplateGeneric(
    new NewMentorSessionsRepositoryImpl(new NewMentorSessionsAPIDataSourceImpl())
  )
  const UpdateBatchTemplateGenericUseCase = new UpdateBatchTemplateGeneric(
    new NewMentorSessionsRepositoryImpl(new NewMentorSessionsAPIDataSourceImpl())
  )
  async function updateBatchtemplateGeneric() {
    const data = {
      student_type: studentId,
      batch_name: mentorshipType,
      batch_template: items,
    }
    setIsLoading(true)

    const response = await UpdateBatchTemplateGenericUseCase.invoke(auth, data)
    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Changes saved")
      changeToastVisibility(true)
    }
    setIsLoading(false)
  }
  async function updateBatchtemplate() {
    const data = {
      student_type: studentId,
      batch_name: mentorshipType,
      batch_template: nonGenericItems,
    }
    setIsLoading(true)
    const response = await UpdateBatchTemplateGenericUseCase.invoke(auth, data)
    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Changes saved")
      changeToastVisibility(true)
    }
    setIsLoading(false)
  }
  async function getBatchtemplateGeneric() {
    const data = {
      student_type: studentId,
      batch_name: mentorshipType,
    }

    const response = await GetBatchTemplateGenericUseCase.invoke(auth, data)
    if (response?.success) {
      if (typeof response.data === "object" && response.data !== null) {
        const mentorshipArray: {
          msid: string
          name: string
          agenda: string
        }[] = Object.entries(response.data).map(([msid, details]) => ({
          msid,
          ...(details as { name: string; agenda: string }),
        }))
        setMentorshipData(mentorshipArray)
        setItems(mentorshipArray)
      } else {
      }
    }
  }
  async function getBatchtemplateNonGeneric() {
    const data = {
      student_type: studentId,
      batch_name: mentorshipType,
    }

    const response = await GetBatchTemplateGenericUseCase.invoke(auth, data)
    if (response?.success) {
      if (typeof response.data === "object" && response.data !== null) {
        const mentorshipArrayNonGeneric: {
          msid: string
          name: string
          agenda: string
          startDate: any
          endDate: any
        }[] = Object.entries(response.data).map(([msid, details]) => ({
          msid,
          ...(details as {
            name: string
            agenda: string
            startDate: any
            endDate: any
          }),
        }))
        setMentorshipData(mentorshipArrayNonGeneric)
        setNonGenericItems(mentorshipArrayNonGeneric)
      } else {
      }
    }
  }

  const handleDelete = (index: number) => {
    const updatedItems = [...items]
    const updatedItemsNonGeneric = [...nonGenericItems]
    updatedItems.splice(index, 1)
    updatedItemsNonGeneric.splice(index, 1)
    setItems(updatedItems)
    setNonGenericItems(updatedItemsNonGeneric)
  }
  const editType = genericBatchTypes.includes(mentorshipType) ? "generic" : "non-generic"
  const handleAddField = () => {
    setItems([...items, { msid: "", name: "", agenda: "" }])
    setNonGenericItems([...nonGenericItems, { msid: "", name: "", agenda: "", startDate: "", endDate: "" }])
  }

  const handleInputChange = (index: number, field: keyof (typeof items)[0], value: any) => {
    const updatedItems = [...items]
    const updatedItemsNonGeneric = [...nonGenericItems]
    updatedItemsNonGeneric[index][field] = value
    updatedItems[index][field] = value
    setItems(updatedItems)
    setNonGenericItems(updatedItemsNonGeneric)
  }
  const handleGenericInputChange = (index: number, field: keyof (typeof nonGenericItems)[0], value: any) => {
    const updatedItemsNonGeneric = [...nonGenericItems]
    if (field === "endDate") {
      const startDate = new Date(updatedItemsNonGeneric[index].startDate)
      const endDate = new Date(value)

      if (endDate <= startDate) {
        changeToastDetails(STR_FAILURE, "End date must be greater than start date")
        changeToastVisibility(true)
        return
      }
    }

    if (field === "startDate") {
      const endDate = new Date(updatedItemsNonGeneric[index].endDate)
      const startDate = new Date(value)

      if (endDate <= startDate) {
        changeToastDetails(STR_FAILURE, "End date must be greater than start date")
        changeToastVisibility(true)
        return
      }
    }
    updatedItemsNonGeneric[index][field] = value
    setNonGenericItems(updatedItemsNonGeneric)
  }
  function startDateChange(e: any) {
    const time = e.target.value as string
    const timestamp = new Date(time).getTime() / 1000
    setStartDate(time)
  }
  function endDateChange(e: any) {
    const time = e.target.value as string
    const timestamp = new Date(time).getTime() / 1000
    setEndDate(time)
  }

  return {
    STR_FAILURE,
    STR_SUCCESS,
    useAuth,
    useToast,
    mentorshipType,
    setMentorshipType,
    studentId,
    setStudentId,
    mentorshipData,
    setMentorshipData,
    items,
    setItems,
    nonGenericItems,
    setNonGenericItems,
    indexToBeDeleted,
    setIndexToBeDeleted,
    timeValue,
    setTimeValue,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isOpen,
    setIsOpen,
    isLoading,
    setIsLoading,
    extractMentorshipData,
    updateBatchtemplateGeneric,
    updateBatchtemplate,
    getBatchtemplateGeneric,
    getBatchtemplateNonGeneric,
    handleDelete,
    handleAddField,
    handleInputChange,
    handleGenericInputChange,
    startDateChange,
    endDateChange,
    editType,
    toast,
    changeToastVisibility,
  }
}
