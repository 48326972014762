import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface GetCoursesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetCourses implements GetCoursesUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getCourses(auth)
  }
}
