import { CancelScheduledClass } from "domain/model/ScheduledClass"
import { ScheduledClassRepository } from "domain/repository/Admin/ScheduledClassRepository"

export interface CancelSchduledClassUseCase {
  invoke(cancelScheduledClass: CancelScheduledClass): Promise<void>
}

export class CancelSchduledClass implements CancelSchduledClassUseCase {
  private repository: ScheduledClassRepository

  constructor(repository: ScheduledClassRepository) {
    this.repository = repository
  }
  async invoke(cancelScheduledClass: CancelScheduledClass) {
    return await this.repository.cancelScheduledClass(cancelScheduledClass)
  }
}
