import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useStudentDashboardViewModel from "../StudentDashboard/StudentDashboardViewModel"
import { useEffect } from "react"
import Toast from "core/components/Toast"
import Skeleton from "core/components/Skeleton"
import StandaredSelect from "core/components/StandaredSelect"
import useCreateClassViewModel from "../CreateClass/CreateClassViewModel"
import useContestProctoringViewModel from "./ContestProctoringViewModel"
import DashboardTable from "./components/ContestProctoringDashboard"

export default function ContestProctoringView() {
  const {
    students,
    mailSendLoading,
    nextStudentsLoading,
    searchingforEmail,
    searchByEmail,
    searchByBatch,
    searchByName,
    toast,
    refreshed,
    emailInputRef,
    fetchStudentsByBatch,
    fetchStudentsByName,
    fetchStudentByEmail,
    changeToastVisibility,
    handleSearchByEmail,
    handleSearchByBatch,
    handleSearchByName,
    loadNextData,
    getTop100Students,
  } = useStudentDashboardViewModel()
  const { tableHeaders, proctoringStatusToggle } = useContestProctoringViewModel()
  const { batch, getBatches, getAllTracks, getInstructorsList } = useCreateClassViewModel()

  useEffect(() => {
    if (refreshed) {
      getTop100Students()
      emailInputRef.current?.focus()
    }
  }, [refreshed])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByEmail) {
        fetchStudentByEmail(searchByEmail)
      } else {
        getTop100Students()
      }
    }, 800)
    return () => clearTimeout(getData)
  }, [searchByEmail])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByName) fetchStudentsByName(searchByName)
      else getTop100Students()
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByName])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByBatch) fetchStudentsByBatch(searchByBatch)
      else getTop100Students()
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByBatch])

  useEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })
  useEffect(() => {
    if (refreshed) {
      getBatches()
      getAllTracks()
      getInstructorsList()
    }
  }, [refreshed])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Proctoring Support</h2>
          <p>Control students' proctoring settings here</p>
        </div>

        <div className="flex w-full flex-wrap gap-4">
          <Input
            id="search-email"
            ref={emailInputRef}
            type="email"
            placeholder="Search with email"
            value={searchByEmail}
            onChange={handleSearchByEmail}
            containerClass="w-60"
            search
          />

          <Input
            id="search-name"
            placeholder="Search with name"
            value={searchByName}
            onChange={handleSearchByName}
            containerClass="w-60"
            search
          />
          <div className="w-60">
            <StandaredSelect
              id="search-batch"
              containerClass="w-60"
              required
              options={batch}
              placeholder="Select Batch"
              onChange={handleSearchByBatch}
              value={searchByBatch}
            />
          </div>
        </div>
        <DashboardTable
          tableHeaders={tableHeaders}
          students={students}
          searchByEmail={searchByEmail}
          searchingforEmail={searchingforEmail}
          mailSendLoading={mailSendLoading}
          paidToUnpaid={proctoringStatusToggle}
        />
        <Skeleton loading={nextStudentsLoading} exhausted={false} handler={loadNextData} />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
