import React, { useRef, useState, useEffect } from "react"
import card from "assets/images/payment-method/card.png"
import PopUpCardModel from "./PopUpCardModel"
import { useAuth } from "core/context/auth"

import { PaymentAPIDataSourceImpl } from "data/API/Student/PaymentAPIDataSourceImpl"
import { PaymentRepositoryImpl } from "data/repository/Student/PaymentRepositoryImpl"
import { ScratchCardDetails } from "domain/useCase/Student/Payment/ScratchCardDetails"
import Spinner from "core/components/Spinner"

const ScratchCard = ({
  src,
  scratched,
  img,
  heading,
  details,
  size,
  card_key,
  amount,
  setScratchCardDetails,
  scratch_card,
}: any) => {
  const scratchCardUseCase = new ScratchCardDetails(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [isScratching, setIsScratching] = useState(false)
  const [isScratched, setIsScratched] = useState(false)

  useEffect(() => {
    const resizeCanvas = () => {
      if (canvasRef.current) {
        const canvas = canvasRef.current
        const ctx = canvas.getContext("2d")

        if (ctx) {
          const scale = window.devicePixelRatio
          const width = window.innerWidth <= 1280 ? 125 : 154 // Use 125px if the screen is max-xl, otherwise 154px
          const height = 176 // Height remains constant

          canvas.width = width * scale
          canvas.height = height * scale
          canvas.style.width = `${width}px`
          canvas.style.height = `${height}px`
          ctx.scale(scale, scale)

          const img = new Image()
          img.src = scratch_card
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width / scale, canvas.height / scale)
          }
        }
      }
    }

    resizeCanvas()

    window.addEventListener("resize", resizeCanvas)

    return () => window.removeEventListener("resize", resizeCanvas)
  }, [])

  const handleScratchStart = () => {
    setIsScratching(true)
  }

  const handleScratchMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isScratching || !canvasRef.current) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")

    if (ctx) {
      const rect = canvas.getBoundingClientRect()
      const x = (e.clientX - rect.left) * window.devicePixelRatio
      const y = (e.clientY - rect.top) * window.devicePixelRatio
      ctx.globalCompositeOperation = "destination-out"
      ctx.beginPath()

      // Reduce brush size here
      ctx.arc(x, y, 10 * window.devicePixelRatio, 0, Math.PI * 2, true)

      ctx.fill()
    }
  }

  const handleScratchEnd = () => {
    setIsScratching(false)

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d")
      if (ctx) {
        const imageData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)
        const pixels = imageData.data
        let scratchedPixels = 0

        for (let i = 0; i < pixels.length; i += 4) {
          if (pixels[i + 3] === 0) {
            scratchedPixels++
          }
        }

        if (scratchedPixels / (pixels.length / 4) > 0.3) {
          setIsScratched(true)
        }
      }
    }
  }

  const [model, setModel] = useState(false)
  const { auth } = useAuth()

  useEffect(() => {
    const handleScratch = async () => {
      if (isScratched) {
        try {
          const response = await scratchCardUseCase.invoke(auth, { card_key })
          if (!response?.success) {
            return
          }
          setScratchCardDetails(response?.data)
        } catch (err) {
          // Handle the error if needed
          console.error("Error scratching the card:", err)
        }
        setModel(true)
      }
    }

    handleScratch()
  }, [isScratched])

  return (
    <div>
      <PopUpCardModel
        isOpen={model}
        card_key={card_key}
        onClose={() => setModel(false)}
        img={img}
        heading={heading}
        details={details}
        size={size}
      />
      {scratched ? (
        <div>
          <div className="relative w-[154px] max-xl:w-[125px] h-[176px]">
            {card_key === "card1" && amount && (
              <p className="absolute bottom-[46px] left-[50px] max-xl:left-[40px] z-10 text-[12px] text-[#108730] font-[500]">
                Rs {amount} Off
              </p>
            )}

            <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500`}>
              <img alt="" src={src} className="w-[154px] max-xl:w-[125px] h-[176px]" />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="relative w-[154px] max-xl:w-[125px] h-[176px] hover:scale-110">
            <div
              className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 
            ${isScratched ? "opacity-100" : "opacity-80"}`}
            >
              <img alt="" src={src} className="w-[154px] max-xl:w-[125px] h-[176px]" />
            </div>

            {!isScratched && (
              <canvas
                ref={canvasRef}
                width={154}
                height={176}
                className="absolute inset-0 cursor-pointer select-none outline-none focus:outline-none  max-xl:w-[125px]"
                onMouseDown={handleScratchStart}
                onMouseMove={handleScratchMove}
                onMouseUp={handleScratchEnd}
                onMouseLeave={handleScratchEnd}
                style={{ WebkitTapHighlightColor: "transparent" }}
              ></canvas>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ScratchCard
