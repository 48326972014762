import { Fragment } from "react"

export default function SolutionCard({ solution, handleSolutionChange }: any) {
  return (
    <Fragment>
      <p className="text-white">Write your solution here:</p>
      <textarea
        maxLength={5000}
        className="bg-new-editor-dark-500 h-80 max-h-96 focus:border-none focus:ring-0 rounded-sm"
        value={solution}
        onChange={(e) => handleSolutionChange(e)}
      />
    </Fragment>
  )
}
