import React from "react"

export default function Coin({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_1668_16358)" />
      <path
        d="M20.7224 20.2444L20.6543 20.4582L20.5118 20.9062C20.4556 21.0852 20.3427 21.2419 20.1897 21.3531C20.0368 21.4644 19.8517 21.5245 19.6616 21.5246V21.5246L19.2477 21.1279L18.2621 20.1887L17.127 19.1029C16.9781 18.9616 16.7795 18.8827 16.5729 18.8827C16.3663 18.8827 16.1677 18.9616 16.0188 19.1029L14.8809 20.187L13.8954 21.1261L13.4806 21.5228C13.2907 21.5219 13.106 21.4615 12.9532 21.3504C12.8004 21.2392 12.6873 21.083 12.6304 20.9044L12.4924 20.4582L12.4243 20.2444C12.5201 20.2418 12.6146 20.2217 12.7029 20.1852C12.7912 20.1484 12.8717 20.0957 12.9404 20.0297L13.7467 19.2593L15.2572 17.8147C15.4282 17.6514 15.657 17.5601 15.8951 17.5603H17.3662C17.5332 17.5593 17.694 17.6227 17.8142 17.737L18.0176 17.9314L19.4044 19.2566L20.2108 20.0271C20.2791 20.0934 20.3597 20.1462 20.4482 20.1826C20.5366 20.2191 20.631 20.2391 20.7268 20.2417L20.7224 20.2444Z"
        fill="url(#paint1_linear_1668_16358)"
      />
      <path
        d="M18.0113 17.9314C17.5157 17.9311 17.0394 18.1213 16.6835 18.4615V18.4615L16.5733 18.5666L16.0214 19.0967L14.8809 20.187L13.8954 21.1261L13.4806 21.5228C13.2907 21.5219 13.106 21.4615 12.9532 21.3504C12.8004 21.2392 12.6873 21.083 12.6304 20.9044L12.4924 20.4582L12.4243 20.2444C12.5201 20.2418 12.6146 20.2217 12.7029 20.1852C12.7912 20.1484 12.8717 20.0957 12.9404 20.0297L13.7467 19.2593L15.2572 17.8147C15.4282 17.6514 15.657 17.5601 15.8951 17.5603H17.3662C17.5332 17.5593 17.694 17.6227 17.8142 17.737L18.0113 17.9314Z"
        fill="url(#paint2_linear_1668_16358)"
      />
      <path
        d="M14.941 15.2433L14.287 16.4184C14.2187 16.5409 14.1183 16.643 13.9963 16.7142C13.8744 16.7854 13.7352 16.823 13.5935 16.8231H12.1081C11.8669 16.8231 11.6295 16.7649 11.4164 16.6534L9.10495 15.443C9.07677 15.429 9.0442 15.4262 9.014 15.4353C8.9838 15.4443 8.9583 15.4645 8.94279 15.4916C8.93224 15.5099 8.92668 15.5306 8.92666 15.5517C8.92758 15.5716 8.93346 15.5909 8.94377 15.608C8.95409 15.6251 8.96852 15.6395 8.98579 15.6498L10.0331 16.3089C10.9563 16.891 11.6418 17.7754 11.9692 18.8066L12.4243 20.2423L12.4924 20.4561L12.634 20.904C12.691 21.0827 12.804 21.2388 12.9568 21.35C13.1096 21.4612 13.2943 21.5216 13.4842 21.5225C13.3599 21.6439 13.1924 21.7124 13.0174 21.7133H10.6325C10.4631 21.7132 10.2981 21.6598 10.1616 21.5608C10.0251 21.4618 9.92422 21.3224 9.87365 21.1629L8.0164 15.3078C7.99986 15.257 7.99576 15.203 8.00442 15.1503C8.01309 15.0976 8.03427 15.0477 8.06625 15.0046C8.09823 14.9615 8.1401 14.9265 8.18842 14.9024C8.23675 14.8783 8.29017 14.8659 8.34431 14.8661H14.7197C14.7646 14.8658 14.8087 14.8773 14.8477 14.8993C14.8866 14.9213 14.9189 14.953 14.9414 14.9914C14.9639 15.0297 14.9757 15.0731 14.9756 15.1174C14.9755 15.1617 14.9636 15.2051 14.941 15.2433V15.2433Z"
        fill="url(#paint3_linear_1668_16358)"
      />
      <path
        d="M25.1285 15.3115L23.2713 21.1665C23.2205 21.3258 23.1196 21.465 22.9831 21.5639C22.8466 21.6627 22.6817 21.716 22.5124 21.7161H20.132C19.955 21.7159 19.7852 21.6466 19.6598 21.5235C19.8499 21.5234 20.035 21.4633 20.188 21.352C20.3409 21.2407 20.4538 21.0841 20.51 20.905L20.6525 20.4571L20.7206 20.2432L21.1139 19.0019C21.481 17.8447 22.2508 16.8524 23.2874 16.2003L24.1609 15.6507C24.1782 15.6399 24.1925 15.6248 24.2024 15.6071C24.2122 15.5893 24.2174 15.5694 24.2174 15.5491C24.2178 15.5281 24.2127 15.5074 24.2026 15.489C24.1924 15.4706 24.1775 15.4551 24.1594 15.4441C24.1413 15.4331 24.1205 15.427 24.0993 15.4264C24.078 15.4257 24.0569 15.4306 24.0382 15.4405L21.7276 16.6509C21.5145 16.7622 21.2771 16.8204 21.0359 16.8205H19.5514C19.4096 16.8205 19.2705 16.7829 19.1485 16.7118C19.0265 16.6406 18.9262 16.5384 18.8579 16.4159L18.2039 15.247C18.1826 15.2087 18.1717 15.1657 18.1724 15.1221C18.1731 15.0784 18.1853 15.0357 18.2078 14.9982C18.2303 14.9606 18.2624 14.9295 18.3008 14.9079C18.3393 14.8863 18.3828 14.875 18.427 14.875H24.8006C24.8541 14.8753 24.9067 14.8878 24.9544 14.9116C25.0021 14.9355 25.0435 14.9699 25.0753 15.0123C25.1072 15.0547 25.1285 15.1038 25.1378 15.1557C25.147 15.2076 25.1438 15.2609 25.1285 15.3115V15.3115Z"
        fill="url(#paint4_linear_1668_16358)"
      />
      <path
        d="M20.5117 13.1942H18.2639C18.2053 13.1942 18.1475 13.1814 18.0946 13.1568C18.0416 13.1322 17.9948 13.0964 17.9575 13.0519L16.9065 11.7938C16.8693 11.7491 16.8232 11.7122 16.7712 11.6854C16.7191 11.6586 16.6622 11.6424 16.6036 11.6378C16.5451 11.6332 16.4862 11.6402 16.4305 11.6585C16.3748 11.6768 16.3234 11.706 16.2794 11.7443C16.2619 11.7595 16.2458 11.7761 16.231 11.7938L15.1801 13.0519C15.1428 13.0964 15.096 13.1322 15.043 13.1568C14.9901 13.1814 14.9322 13.1942 14.8737 13.1942H12.6339C12.5599 13.1941 12.4875 13.1732 12.425 13.1341C12.3626 13.095 12.3127 13.0392 12.2812 12.9732C12.2496 12.9072 12.2377 12.8337 12.2469 12.7613C12.256 12.6889 12.2859 12.6206 12.3329 12.5642L14.9445 9.43395L15.8655 8.33044C15.9487 8.23047 16.0528 8.14925 16.1705 8.09225C16.2883 8.03525 16.4171 8.0038 16.5482 8H16.601C16.732 8.00386 16.8606 8.03536 16.9782 8.09235C17.0959 8.14935 17.1997 8.23053 17.2828 8.33044L17.3617 8.42497L18.2038 9.43395L18.7871 10.1328L20.8119 12.5642C20.8588 12.6205 20.8886 12.6888 20.8978 12.7611C20.907 12.8334 20.8952 12.9068 20.8638 12.9728C20.8323 13.0388 20.7826 13.0946 20.7203 13.1338C20.658 13.173 20.5856 13.1939 20.5117 13.1942V13.1942Z"
        fill="url(#paint5_linear_1668_16358)"
      />
      <path
        d="M18.7835 10.1328C18.4205 10.1335 18.0622 10.213 17.7339 10.3657C17.4056 10.5184 17.1155 10.7405 16.8842 11.0163L16.5768 11.3839L16.2803 11.7373C16.2628 11.7524 16.2467 11.769 16.2319 11.7868L15.1801 13.0519C15.1428 13.0964 15.096 13.1322 15.043 13.1568C14.9901 13.1814 14.9322 13.1942 14.8737 13.1942H12.6339C12.5599 13.1941 12.4875 13.1732 12.425 13.1341C12.3626 13.095 12.3127 13.0392 12.2812 12.9732C12.2496 12.9072 12.2377 12.8337 12.2469 12.7613C12.256 12.6889 12.2859 12.6206 12.3329 12.5642L14.9445 9.43395L15.8655 8.33044C15.9487 8.23047 16.0528 8.14925 16.1705 8.09225C16.2883 8.03525 16.4171 8.0038 16.5482 8H16.601C16.732 8.00386 16.8606 8.03536 16.9782 8.09235C17.0959 8.14935 17.1997 8.23053 17.2828 8.33044L17.3617 8.42497L18.2038 9.43395L18.7835 10.1328Z"
        fill="url(#paint6_linear_1668_16358)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 29.7143C23.5742 29.7143 29.7143 23.5742 29.7143 16C29.7143 8.42581 23.5742 2.28571 16 2.28571C8.42581 2.28571 2.28571 8.42581 2.28571 16C2.28571 23.5742 8.42581 29.7143 16 29.7143ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="url(#paint7_linear_1668_16358)"
      />
      <circle cx="15.9999" cy="16" r="13.6143" stroke="url(#paint8_linear_1668_16358)" strokeWidth="0.2" />
      <defs>
        <linearGradient id="paint0_linear_1668_16358" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5861B" />
          <stop offset="1" stopColor="#604603" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1668_16358"
          x1="16.5756"
          y1="17.5603"
          x2="16.5756"
          y2="21.5246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0AB00" />
          <stop offset="1" stopColor="#FFEC97" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1668_16358"
          x1="15.2178"
          y1="17.5603"
          x2="15.2178"
          y2="21.5228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0AB00" />
          <stop offset="1" stopColor="#FFEC97" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1668_16358"
          x1="11.4878"
          y1="14.8661"
          x2="11.4878"
          y2="21.7133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0AB00" />
          <stop offset="1" stopColor="#FFEC97" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1668_16358"
          x1="21.6576"
          y1="14.875"
          x2="21.6576"
          y2="21.7161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0AB00" />
          <stop offset="1" stopColor="#FFEC97" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1668_16358"
          x1="16.5724"
          y1="8"
          x2="16.5724"
          y2="13.1942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0AB00" />
          <stop offset="1" stopColor="#FFEC97" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1668_16358"
          x1="15.5137"
          y1="8"
          x2="15.5137"
          y2="13.1942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0AB00" />
          <stop offset="1" stopColor="#FFEC97" />
        </linearGradient>
        <linearGradient id="paint7_linear_1668_16358" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD232" />
          <stop offset="0.255208" stopColor="#FFDC66" />
          <stop offset="0.541667" stopColor="#FFF1CF" />
          <stop offset="0.765625" stopColor="#E0AE4A" />
          <stop offset="1" stopColor="#98650C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1668_16358"
          x1="15.9999"
          y1="2.28571"
          x2="15.9999"
          y2="29.7143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A4D00" />
          <stop offset="1" stopColor="#FFCE20" />
        </linearGradient>
      </defs>
    </svg>
  )
}
