function CopyIcon({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.20542C0 2.83417 0.283279 2.5332 0.632727 2.5332H7.44C7.78945 2.5332 8.07273 2.83416 8.07273 3.20542V11.8283C8.07273 12.1995 7.78945 12.5005 7.44 12.5005H0.632727C0.283281 12.5005 0 12.1996 0 11.8283V3.20542ZM1.09091 3.69219V11.3415H6.98182V3.69219H1.09091Z"
        fill="#ADADAD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55851 0.5C2.20906 0.5 1.92578 0.800964 1.92578 1.17221V2.97374H3.0167V1.65899H8.90767V9.30831H7.43504V10.4673H9.36586C9.71531 10.4673 9.99859 10.1663 9.99859 9.79509V1.17221C9.99859 0.800961 9.7153 0.5 9.36586 0.5H2.55851Z"
        fill="#ADADAD"
      />
    </svg>
  )
}

export default CopyIcon
