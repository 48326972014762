import { scheduleTest } from "domain/model/TestSeriesDashboard"
import { TestSeriesDashboardRepository } from "domain/repository/Admin/TestSeriesDashboardRepository"

export interface scheduleWeeklyTestUseCase {
  invoke(test: scheduleTest): Promise<any>
}

export class scheduleWeeklyTest implements scheduleWeeklyTestUseCase {
  private repository: TestSeriesDashboardRepository
  constructor(repository: TestSeriesDashboardRepository) {
    this.repository = repository
  }

  async invoke(test: scheduleTest): Promise<any> {
    return await this.repository.scheduleWeeklyTest(test)
  }
}
