export default function Pattern() {
  return (
    <svg width="128" height="87" viewBox="0 0 128 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Pattern ">
        <g id="Group 40942">
          <g id="Group 407">
            <path
              id="Vector"
              d="M50.3527 65.6393L50.3527 65.6393C50.4134 65.7131 50.504 65.7559 50.5995 65.7559H53.8215C54.0838 65.7559 54.2276 65.4509 54.0609 65.2486L54.0609 65.2485L51.2261 61.8048H51.2259L51.1508 61.7136L50.3153 60.6984L50.3153 60.6984L49.1075 59.2313L49.0505 59.1621V59.1618L48.995 59.0943L48.9948 59.0942C48.7887 58.8435 48.4977 58.714 48.2022 58.705L48.1982 58.7049L48.1982 58.7048C48.193 58.7046 48.1873 58.7045 48.1746 58.7045L50.3527 65.6393ZM50.3527 65.6393L48.8471 63.8103C48.8471 63.8103 48.8471 63.8103 48.8471 63.8103C48.5258 63.42 47.9527 63.387 47.5882 63.7104L47.5882 63.7104C47.5538 63.741 47.5219 63.774 47.4924 63.8096L47.4918 63.8103L45.9861 65.6391C45.986 65.6391 45.986 65.6392 45.9859 65.6393C45.9859 65.6393 45.9859 65.6393 45.9859 65.6393C45.9248 65.7133 45.8343 65.7559 45.7391 65.7559H42.5274C42.2652 65.7559 42.1212 65.4507 42.2879 65.2483L42.288 65.2482L46.0336 60.6984L46.0336 60.6984L47.3539 59.0943C47.354 59.0943 47.354 59.0943 47.354 59.0943C47.5603 58.8438 47.8509 58.7142 48.1461 58.705L50.3527 65.6393Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_2"
              d="M65.224 74.8299L65.2241 74.83C65.2848 74.9037 65.3754 74.9465 65.4709 74.9465H68.6929C68.9551 74.9465 69.099 74.6415 68.9323 74.4392L68.9322 74.4391L66.0975 70.9954H66.0972L66.0222 70.9043L65.1866 69.889L65.1866 69.889L63.9789 68.4219L63.9219 68.3527V68.3525L63.8663 68.285L63.8662 68.2849C63.66 68.0342 63.3691 67.9046 63.0735 67.8956L63.0695 67.8955L63.0695 67.8954C63.0643 67.8952 63.0587 67.8952 63.0459 67.8952L65.224 74.8299ZM65.224 74.8299L63.7184 73.001C63.7184 73.0009 63.7184 73.0009 63.7184 73.0009C63.3971 72.6106 62.8241 72.5776 62.4596 72.901L62.4595 72.901C62.4251 72.9316 62.3933 72.9646 62.3638 73.0002L62.3632 73.0009L60.8574 74.8297C60.8574 74.8298 60.8573 74.8298 60.8573 74.8299C60.8573 74.8299 60.8573 74.8299 60.8573 74.8299C60.7961 74.9039 60.7057 74.9465 60.6104 74.9465H57.3987C57.1365 74.9465 56.9925 74.6413 57.1593 74.4389L57.1593 74.4388L60.9049 69.889L60.9049 69.889L62.2253 68.285C62.2253 68.2849 62.2253 68.2849 62.2253 68.2849C62.4316 68.0344 62.7222 67.9049 63.0175 67.8956L65.224 74.8299Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_3"
              d="M81.5387 84.3309L81.5388 84.331C81.5995 84.4047 81.6901 84.4475 81.7856 84.4475H85.0076C85.2698 84.4475 85.4137 84.1425 85.247 83.9402L85.2469 83.9401L82.4122 80.4964H82.4119L82.3369 80.4053L81.5013 79.39L81.5013 79.39L80.2936 77.923L80.2366 77.8537V77.8535L80.181 77.786L80.1809 77.7859C79.9747 77.5352 79.6838 77.4056 79.3882 77.3966L79.3842 77.3965L79.3842 77.3965C79.379 77.3962 79.3734 77.3962 79.3606 77.3962L81.5387 84.3309ZM81.5387 84.3309L80.0331 82.502C80.0331 82.502 80.0331 82.502 80.0331 82.502C79.7118 82.1116 79.1388 82.0787 78.7743 82.402L78.7742 82.402C78.7398 82.4326 78.708 82.4656 78.6785 82.5012L78.6779 82.5019L77.1721 84.3307C77.1721 84.3308 77.172 84.3308 77.172 84.3309C77.172 84.3309 77.172 84.3309 77.172 84.3309C77.1108 84.4049 77.0204 84.4475 76.9251 84.4475H73.7134C73.4512 84.4475 73.3072 84.1423 73.474 83.9399L73.474 83.9399L77.2196 79.39L77.2196 79.39L78.54 77.786C78.54 77.786 78.54 77.786 78.54 77.7859C78.7463 77.5354 79.0369 77.4059 79.3322 77.3966L81.5387 84.3309Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_4"
              d="M83.7087 68.2214L83.7087 68.2214C83.7694 68.2952 83.86 68.338 83.9555 68.338H87.1775C87.4397 68.338 87.5836 68.033 87.4169 67.8307L87.4168 67.8306L84.5821 64.3869H84.5818L84.5068 64.2957L83.6712 63.2805L83.6712 63.2805L82.4635 61.8134L82.4065 61.7442V61.7439L82.3509 61.6764L82.3508 61.6763C82.1446 61.4256 81.8537 61.2961 81.5581 61.2871L81.5541 61.287L81.5541 61.2869C81.5489 61.2867 81.5433 61.2866 81.5305 61.2866L83.7087 68.2214ZM83.7087 68.2214L82.2031 66.3924C82.2031 66.3924 82.2031 66.3924 82.2031 66.3924C81.8817 66.0021 81.3087 65.9691 80.9442 66.2925L80.9441 66.2925C80.9097 66.3231 80.8779 66.3561 80.8484 66.3917L80.8478 66.3924L79.342 68.2212C79.342 68.2212 79.3419 68.2213 79.3419 68.2214C79.3419 68.2214 79.3419 68.2214 79.3419 68.2214C79.2807 68.2954 79.1903 68.338 79.095 68.338H75.8833C75.6212 68.338 75.4771 68.0328 75.6439 67.8304L75.644 67.8303L79.3896 63.2805L79.3896 63.2805L80.7099 61.6764C80.7099 61.6764 80.7099 61.6764 80.7099 61.6764C80.9162 61.4259 81.2069 61.2963 81.5021 61.2871L83.7087 68.2214Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_5"
              d="M68.5291 56.552L68.5291 56.552C68.5898 56.6258 68.6804 56.6686 68.7759 56.6686H71.9979C72.2602 56.6686 72.404 56.3636 72.2373 56.1613L72.2373 56.1612L69.3273 52.6264L69.3272 52.6263L68.4917 51.6111L68.4917 51.611L67.2839 50.1438L67.2839 50.1437L67.1713 50.007L67.1712 50.0069C66.9651 49.7562 66.6741 49.6267 66.3786 49.6177L66.3746 49.6175L66.3746 49.6175C66.3694 49.6173 66.3637 49.6172 66.351 49.6172L68.5291 56.552ZM68.5291 56.552L67.0235 54.723C66.7022 54.3327 66.1291 54.2997 65.7646 54.6231L65.7646 54.6231C65.7301 54.6537 65.6983 54.6867 65.6688 54.7223L65.6682 54.723L64.1625 56.5518C64.1013 56.6259 64.0108 56.6686 63.9155 56.6686H60.7037C60.4416 56.6686 60.2976 56.3634 60.4643 56.161L60.4644 56.1609L64.21 51.6111L64.21 51.6111L65.5303 50.007C65.5303 50.007 65.5304 50.007 65.5304 50.007C65.7367 49.7565 66.0273 49.6269 66.3225 49.6177L68.5291 56.552Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_6"
              d="M104.258 57.688L104.258 57.688C104.319 57.7618 104.409 57.8046 104.505 57.8046H107.727C107.989 57.8046 108.133 57.4996 107.966 57.2973L107.966 57.2972L105.132 53.8535H105.131L105.056 53.7624L104.221 52.7471L104.221 52.7471L103.013 51.28L102.956 51.2108V51.2106L102.9 51.1431L102.9 51.1429C102.694 50.8922 102.403 50.7627 102.108 50.7537L102.104 50.7536L102.104 50.7535C102.098 50.7533 102.093 50.7533 102.08 50.7533L104.258 57.688ZM104.258 57.688L102.753 55.859C102.752 55.859 102.752 55.859 102.752 55.859C102.431 55.4687 101.858 55.4357 101.494 55.7591L101.494 55.7591C101.459 55.7897 101.427 55.8227 101.398 55.8583L101.397 55.859L99.8915 57.6878C99.8914 57.6879 99.8914 57.6879 99.8913 57.688C99.8913 57.688 99.8913 57.688 99.8913 57.688C99.8302 57.762 99.7397 57.8046 99.6445 57.8046H96.4327C96.1706 57.8046 96.0266 57.4994 96.1933 57.297L96.1934 57.2969L99.939 52.7471L99.939 52.7471L101.259 51.143C101.259 51.143 101.259 51.143 101.259 51.143C101.466 50.8925 101.756 50.7629 102.052 50.7537L104.258 57.688Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_7"
              d="M101.572 74.8299L101.572 74.83C101.633 74.9037 101.723 74.9465 101.819 74.9465H105.041C105.303 74.9465 105.447 74.6415 105.28 74.4392L105.28 74.4391L102.445 70.9954H102.445L102.37 70.9043L101.535 69.889L101.535 69.889L100.327 68.4219L100.27 68.3527V68.3525L100.214 68.285L100.214 68.2849C100.008 68.0342 99.717 67.9046 99.4214 67.8956L99.4174 67.8955L99.4174 67.8954C99.4122 67.8952 99.4066 67.8952 99.3938 67.8952L101.572 74.8299ZM101.572 74.8299L100.066 73.001C100.066 73.0009 100.066 73.0009 100.066 73.0009C99.745 72.6106 99.172 72.5776 98.8075 72.901L98.8074 72.901C98.773 72.9316 98.7412 72.9646 98.7117 73.0002L98.7111 73.0009L97.2053 74.8297C97.2053 74.8298 97.2052 74.8298 97.2052 74.8299C97.2052 74.8299 97.2052 74.8299 97.2052 74.8299C97.144 74.9039 97.0536 74.9465 96.9583 74.9465H93.7466C93.4844 74.9465 93.3404 74.6413 93.5072 74.4389L93.5072 74.4388L97.2528 69.889L97.2528 69.889L98.5732 68.285C98.5732 68.2849 98.5732 68.2849 98.5732 68.2849C98.7795 68.0344 99.0701 67.9049 99.3654 67.8956L101.572 74.8299Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_8"
              d="M120.883 74.8299L120.884 74.83C120.944 74.9037 121.035 74.9465 121.13 74.9465H124.352C124.615 74.9465 124.758 74.6415 124.592 74.4392L124.592 74.4391L121.757 70.9954H121.757L121.682 70.9043L120.846 69.889L120.846 69.889L119.638 68.4219L119.581 68.3527V68.3525L119.526 68.285L119.526 68.2849C119.319 68.0342 119.028 67.9046 118.733 67.8956L118.729 67.8955L118.729 67.8954C118.724 67.8952 118.718 67.8952 118.705 67.8952L120.883 74.8299ZM120.883 74.8299L119.378 73.001C119.378 73.0009 119.378 73.0009 119.378 73.0009C119.057 72.6106 118.483 72.5776 118.119 72.901L118.119 72.901C118.085 72.9316 118.053 72.9646 118.023 73.0002L118.023 73.0009L116.517 74.8297C116.517 74.8298 116.517 74.8298 116.517 74.8299C116.517 74.8299 116.517 74.8299 116.517 74.8299C116.456 74.9039 116.365 74.9465 116.27 74.9465H113.058C112.796 74.9465 112.652 74.6413 112.819 74.4389L112.819 74.4388L116.564 69.889L116.564 69.889L117.885 68.285C117.885 68.2849 117.885 68.2849 117.885 68.2849C118.091 68.0344 118.382 67.9049 118.677 67.8956L120.883 74.8299Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
          </g>
          <g id="Group 406">
            <path
              id="Vector_9"
              d="M10.3866 19.2723L10.3867 19.2724C10.4474 19.3462 10.538 19.3889 10.6335 19.3889H13.8555C14.1177 19.3889 14.2616 19.0839 14.0949 18.8816L14.0948 18.8815L11.2601 15.4378H11.2598L11.1848 15.3467L10.3492 14.3314L10.3492 14.3314L9.14146 12.8644L9.08447 12.7951V12.7949L9.02889 12.7274L9.02878 12.7273C8.82261 12.4766 8.53167 12.347 8.23612 12.338L8.23212 12.3379L8.23212 12.3379C8.22692 12.3376 8.22127 12.3376 8.20852 12.3376L10.3866 19.2723ZM10.3866 19.2723L8.88104 17.4434C8.88104 17.4434 8.88103 17.4434 8.88103 17.4434C8.55973 17.053 7.98666 17.0201 7.62218 17.3434L7.62213 17.3435C7.58769 17.374 7.55587 17.407 7.52636 17.4426L7.52575 17.4433L6.02001 19.2721C6.01997 19.2722 6.01992 19.2723 6.01988 19.2723C6.01987 19.2723 6.01987 19.2723 6.01986 19.2723C5.95871 19.3464 5.86826 19.3889 5.77302 19.3889H2.56129C2.29915 19.3889 2.1551 19.0838 2.32187 18.8814L2.32194 18.8813L6.06753 14.3314L6.06754 14.3314L7.38788 12.7274C7.38789 12.7274 7.3879 12.7274 7.3879 12.7274C7.5942 12.4768 7.88483 12.3473 8.18006 12.3381L10.3866 19.2723Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_10"
              d="M25.258 28.4627L25.258 28.4627C25.3187 28.5365 25.4093 28.5793 25.5048 28.5793H28.7268C28.9891 28.5793 29.1329 28.2743 28.9662 28.072L28.9661 28.0719L26.1314 24.6282H26.1311L26.0561 24.537L25.2206 23.5218L25.2205 23.5217L24.0128 22.0547L23.9558 21.9855V21.9852L23.9002 21.9177L23.9001 21.9176C23.6939 21.6669 23.403 21.5374 23.1075 21.5284L23.1035 21.5282L23.1035 21.5282C23.0983 21.528 23.0926 21.5279 23.0799 21.5279L25.258 28.4627ZM25.258 28.4627L23.7524 26.6337C23.7524 26.6337 23.7524 26.6337 23.7524 26.6337C23.4311 26.2433 22.858 26.2104 22.4935 26.5337L22.4935 26.5338C22.459 26.5644 22.4272 26.5974 22.3977 26.6329L22.3971 26.6337L20.8913 28.4625C20.8913 28.4625 20.8913 28.4626 20.8912 28.4626C20.8912 28.4626 20.8912 28.4627 20.8912 28.4627C20.83 28.5367 20.7396 28.5793 20.6444 28.5793H17.4326C17.1705 28.5793 17.0264 28.2741 17.1932 28.0717L17.1933 28.0716L20.9389 23.5218L20.9389 23.5218L22.2592 21.9177C22.2592 21.9177 22.2592 21.9177 22.2592 21.9177C22.4655 21.6671 22.7562 21.5376 23.0514 21.5284L25.258 28.4627Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_11"
              d="M41.5728 37.9637L41.5728 37.9637C41.6335 38.0375 41.7241 38.0803 41.8196 38.0803H45.0416C45.3039 38.0803 45.4477 37.7753 45.281 37.573L45.281 37.5729L42.371 34.0381L42.3709 34.038L41.5354 33.0228L41.5354 33.0227L40.3276 31.5555L40.3276 31.5554L40.215 31.4187L40.2149 31.4186C40.0088 31.1679 39.7178 31.0384 39.4223 31.0294L39.4183 31.0292L39.4183 31.0292C39.4131 31.029 39.4074 31.0289 39.3947 31.0289L41.5728 37.9637ZM41.5728 37.9637L40.0672 36.1347C40.0672 36.1347 40.0672 36.1347 40.0672 36.1347C39.7459 35.7443 39.1728 35.7114 38.8083 36.0347L38.8083 36.0348C38.7739 36.0654 38.742 36.0984 38.7125 36.134L38.7119 36.1347L37.2062 37.9635C37.2061 37.9635 37.2061 37.9636 37.206 37.9637C37.206 37.9637 37.206 37.9637 37.206 37.9637C37.1449 38.0377 37.0544 38.0803 36.9592 38.0803H33.7474C33.4853 38.0803 33.3413 37.7751 33.508 37.5727L33.5081 37.5726L37.2537 33.0228L37.2537 33.0228L38.574 31.4187C38.574 31.4187 38.5741 31.4187 38.5741 31.4187C38.7804 31.1681 39.071 31.0386 39.3662 31.0294L41.5728 37.9637Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_12"
              d="M43.7426 21.8538L43.7426 21.8539C43.8033 21.9277 43.8939 21.9705 43.9894 21.9705H47.2114C47.4737 21.9705 47.6175 21.6655 47.4508 21.4631L47.4508 21.463L44.616 18.0193H44.6157L44.5407 17.9282L43.7052 16.9129L43.7052 16.9129L42.4974 15.4459L42.4404 15.3767V15.3764L42.3848 15.3089L42.3847 15.3088C42.1786 15.0581 41.8876 14.9285 41.5921 14.9195L41.5881 14.9194L41.5881 14.9194C41.5829 14.9191 41.5772 14.9191 41.5645 14.9191L43.7426 21.8538ZM43.7426 21.8538L42.237 20.0249C42.237 20.0249 42.237 20.0249 42.237 20.0249C41.9157 19.6345 41.3426 19.6016 40.9781 19.9249L40.9781 19.925C40.9436 19.9555 40.9118 19.9886 40.8823 20.0241L40.8817 20.0249L39.376 21.8537C39.3759 21.8537 39.3759 21.8538 39.3758 21.8538C39.3758 21.8538 39.3758 21.8538 39.3758 21.8538C39.3147 21.9279 39.2242 21.9705 39.129 21.9705H35.9172C35.6551 21.9705 35.5111 21.6653 35.6778 21.4629L35.6779 21.4628L39.4235 16.9129L39.4235 16.9129L40.7438 15.3089C40.7438 15.3089 40.7439 15.3089 40.7439 15.3089C40.9502 15.0583 41.2408 14.9288 41.536 14.9196L43.7426 21.8538Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_13"
              d="M28.563 10.1847L28.5631 10.1848C28.6238 10.2586 28.7144 10.3013 28.8099 10.3013H32.0319C32.2941 10.3013 32.438 9.99634 32.2713 9.79403L32.2712 9.79394L29.4365 6.35023H29.4362L29.3612 6.2591L28.5256 5.24383L28.5256 5.2438L27.3178 3.77677L27.2609 3.70754V3.70728L27.2053 3.6398L27.2052 3.63967C26.999 3.38898 26.7081 3.25943 26.4125 3.25042L26.4085 3.2503L26.4085 3.25027C26.4033 3.25003 26.3977 3.24998 26.3849 3.24998L28.563 10.1847ZM28.563 10.1847L27.0574 8.35578C27.0574 8.35577 27.0574 8.35576 27.0574 8.35576C26.7361 7.9654 26.1631 7.93246 25.7986 8.2558L25.7985 8.25585C25.7641 8.28642 25.7323 8.31944 25.7028 8.355L25.7021 8.35574L24.1964 10.1845C24.1964 10.1846 24.1963 10.1846 24.1963 10.1847C24.1963 10.1847 24.1963 10.1847 24.1963 10.1847C24.1351 10.2587 24.0446 10.3013 23.9494 10.3013H20.7377C20.4755 10.3013 20.3315 9.99615 20.4983 9.79375L20.4983 9.79367L24.2439 5.24383L24.2439 5.24382L25.5643 3.63977C25.5643 3.63977 25.5643 3.63976 25.5643 3.63975C25.7706 3.3892 26.0612 3.25968 26.3564 3.25045L28.563 10.1847Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_14"
              d="M64.292 11.3208L64.2921 11.3208C64.3528 11.3946 64.4434 11.4374 64.5389 11.4374H67.7609C68.0231 11.4374 68.167 11.1324 68.0003 10.9301L68.0002 10.93L65.1655 7.48626H65.1652L65.0902 7.39513L64.2546 6.37986L64.2546 6.37983L63.0469 4.9128L62.9899 4.84357V4.84332L62.9343 4.77583L62.9342 4.7757C62.728 4.52501 62.4371 4.39547 62.1415 4.38646L62.1375 4.38633L62.1375 4.3863C62.1323 4.38606 62.1267 4.38602 62.1139 4.38602L64.292 11.3208ZM64.292 11.3208L62.7864 9.49181C62.7864 9.4918 62.7864 9.4918 62.7864 9.49179C62.4651 9.10144 61.8921 9.0685 61.5276 9.39184L61.5275 9.39188C61.4931 9.42245 61.4613 9.45547 61.4318 9.49104L61.4312 9.49177L59.9254 11.3206C59.9254 11.3206 59.9253 11.3207 59.9253 11.3207C59.9253 11.3207 59.9253 11.3207 59.9253 11.3208C59.8641 11.3948 59.7737 11.4374 59.6784 11.4374H56.4667C56.2045 11.4374 56.0605 11.1322 56.2273 10.9298L56.2273 10.9297L59.9729 6.37986L59.9729 6.37985L61.2933 4.77581C61.2933 4.7758 61.2933 4.77579 61.2933 4.77578C61.4996 4.52523 61.7902 4.39572 62.0855 4.38648L64.292 11.3208Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_15"
              d="M61.6083 28.4627L61.6084 28.4627C61.6691 28.5365 61.7597 28.5793 61.8552 28.5793H65.0771C65.3394 28.5793 65.4833 28.2743 65.3166 28.072L65.3165 28.0719L62.4818 24.6282H62.4815L62.4065 24.537L61.5709 23.5218L61.5709 23.5217L60.3631 22.0547L60.3061 21.9855V21.9852L60.2506 21.9177L60.2505 21.9176C60.0443 21.6669 59.7533 21.5374 59.4578 21.5284L59.4538 21.5282L59.4538 21.5282C59.4486 21.528 59.4429 21.5279 59.4302 21.5279L61.6083 28.4627ZM61.6083 28.4627L60.1027 26.6337C60.1027 26.6337 60.1027 26.6337 60.1027 26.6337C59.7814 26.2433 59.2083 26.2104 58.8439 26.5337L58.8438 26.5338C58.8094 26.5644 58.7776 26.5974 58.748 26.6329L58.7474 26.6337L57.2417 28.4625C57.2416 28.4625 57.2416 28.4626 57.2416 28.4626C57.2416 28.4626 57.2415 28.4627 57.2415 28.4627C57.1804 28.5367 57.0899 28.5793 56.9947 28.5793H53.783C53.5208 28.5793 53.3768 28.2741 53.5436 28.0717L53.5436 28.0716L57.2892 23.5218L57.2892 23.5218L58.6096 21.9177C58.6096 21.9177 58.6096 21.9177 58.6096 21.9177C58.8159 21.6671 59.1065 21.5376 59.4017 21.5284L61.6083 28.4627Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_16"
              d="M80.9174 28.4627L80.9174 28.4627C80.9781 28.5365 81.0687 28.5793 81.1642 28.5793H84.3862C84.6485 28.5793 84.7923 28.2743 84.6256 28.072L84.6256 28.0719L81.7908 24.6282H81.7905L81.7155 24.537L80.88 23.5218L80.88 23.5217L79.6722 22.0547L79.6152 21.9855V21.9852L79.5597 21.9177L79.5595 21.9176C79.3534 21.6669 79.0624 21.5374 78.7669 21.5284L78.7629 21.5282L78.7629 21.5282C78.7577 21.528 78.752 21.5279 78.7393 21.5279L80.9174 28.4627ZM80.9174 28.4627L79.4118 26.6337C79.4118 26.6337 79.4118 26.6337 79.4118 26.6337C79.0905 26.2433 78.5174 26.2104 78.1529 26.5337L78.1529 26.5338C78.1185 26.5644 78.0866 26.5974 78.0571 26.6329L78.0565 26.6337L76.5508 28.4625C76.5507 28.4625 76.5507 28.4626 76.5506 28.4626C76.5506 28.4626 76.5506 28.4627 76.5506 28.4627C76.4895 28.5367 76.399 28.5793 76.3038 28.5793H73.092C72.8299 28.5793 72.6859 28.2741 72.8526 28.0717L72.8527 28.0716L76.5983 23.5218L76.5983 23.5218L77.9186 21.9177C77.9187 21.9177 77.9187 21.9177 77.9187 21.9177C78.125 21.6671 78.4156 21.5376 78.7108 21.5284L80.9174 28.4627Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_17"
              d="M60.1625 43.4374L60.1626 43.4374C60.2233 43.5112 60.3139 43.554 60.4093 43.554H63.6313C63.8936 43.554 64.0375 43.249 63.8708 43.0467L63.8707 43.0466L61.036 39.6029H61.0357L60.9607 39.5118L60.1251 38.4965L60.1251 38.4965L58.9173 37.0294L58.8603 36.9602V36.9599L58.8048 36.8925L58.8047 36.8923C58.5985 36.6416 58.3075 36.5121 58.012 36.5031L58.008 36.503L58.008 36.5029C58.0028 36.5027 57.9971 36.5026 57.9844 36.5026L60.1625 43.4374ZM60.1625 43.4374L58.6569 41.6084C58.6569 41.6084 58.6569 41.6084 58.6569 41.6084C58.3356 41.2181 57.7625 41.1851 57.3981 41.5085L57.398 41.5085C57.3636 41.5391 57.3318 41.5721 57.3022 41.6077L57.3016 41.6084L55.7959 43.4372C55.7958 43.4373 55.7958 43.4373 55.7958 43.4374C55.7958 43.4374 55.7957 43.4374 55.7957 43.4374C55.7346 43.5114 55.6441 43.554 55.5489 43.554H52.3372C52.075 43.554 51.931 43.2488 52.0978 43.0464L52.0978 43.0463L55.8434 38.4965L55.8434 38.4965L57.1638 36.8924C57.1638 36.8924 57.1638 36.8924 57.1638 36.8924C57.3701 36.6419 57.6607 36.5123 57.9559 36.5031L60.1625 43.4374Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
            <path
              id="Vector_18"
              d="M22.9854 42.9206L22.9854 42.9207C23.0461 42.9944 23.1367 43.0372 23.2322 43.0372H26.4542C26.7165 43.0372 26.8603 42.7322 26.6936 42.5299L26.6936 42.5298L23.8588 39.0861H23.8585L23.7835 38.995L22.948 37.9797L22.948 37.9797L21.7402 36.5126L21.6832 36.4434V36.4432L21.6276 36.3757L21.6275 36.3755C21.4214 36.1248 21.1304 35.9953 20.8349 35.9863L20.8309 35.9862L20.8309 35.9861C20.8257 35.9859 20.82 35.9859 20.8073 35.9859L22.9854 42.9206ZM22.9854 42.9206L21.4798 41.0916C21.4798 41.0916 21.4798 41.0916 21.4798 41.0916C21.1585 40.7013 20.5854 40.6683 20.2209 40.9917L20.2209 40.9917C20.1864 41.0223 20.1546 41.0553 20.1251 41.0909L20.1245 41.0916L18.6188 42.9204C18.6187 42.9205 18.6187 42.9205 18.6186 42.9206C18.6186 42.9206 18.6186 42.9206 18.6186 42.9206C18.5575 42.9946 18.467 43.0372 18.3718 43.0372H15.16C14.8979 43.0372 14.7539 42.732 14.9206 42.5296L14.9207 42.5295L18.6663 37.9797L18.6663 37.9797L19.9866 36.3756C19.9866 36.3756 19.9867 36.3756 19.9867 36.3756C20.193 36.1251 20.4836 35.9956 20.7788 35.9863L22.9854 42.9206Z"
              stroke="currentColor"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
