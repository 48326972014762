const colors: any = {
  red: {
    bg: "bg-new-solid-red-light hover:shadow-[4px_4px] hover:shadow-new-solid-red-dark before:bg-new-solid-red-dark",
    border: "border-new-solid-red-dark",
  },
  green: {
    bg: "bg-new-solid-green-light hover:shadow-[4px_4px] hover:shadow-new-solid-green-dark before:bg-new-solid-green-dark",
    border: "border-new-solid-green-dark",
  },
  blue: {
    bg: "bg-new-solid-blue-light hover:shadow-[4px_4px] hover:shadow-new-solid-blue-dark before:bg-new-solid-blue-dark",
    border: "border-new-solid-blue-dark",
  },
  yellow: {
    bg: "bg-new-solid-yellow-light hover:shadow-[4px_4px] hover:shadow-new-solid-yellow-dark before:bg-new-solid-yellow-dark",
    border: "border-new-solid-yellow-dark",
  },
  violet: {
    bg: "bg-new-solid-violet-light hover:shadow-[4px_4px] hover:shadow-new-solid-violet-dark before:bg-new-solid-violet-dark",
    border: "border-new-solid-violet-dark",
  },
  purple: {
    bg: "bg-new-solid-purple-light hover:shadow-[4px_4px] hover:shadow-new-solid-purple-dark before:bg-new-solid-purple-dark",
    border: "border-new-solid-purple-dark",
  },
  grey: {
    bg: "bg-new-misc-gray hover:shadow-[4px_4px] hover:shadow-new-misc-grey before:bg-new-misc-grey",
    border: "border-new-misc-grey",
  },
  white: {
    bg: "border-[#312E81] bg-new-solid-white-light hover:shadow-[4px_4px] hover:shadow-[#312E81] before:bg-new-solid-white-dark",
    border: "border-[#312E81]",
  },
}

export default colors
