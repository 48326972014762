import CompanyDataSource from "data/dataSource/Admin/CompanyDataSource"
import { Auth } from "domain/model/Auth"
import { Company, EditCompany } from "domain/model/Questions"
import CompanyRepository from "domain/repository/Admin/CompanyRepository"

export class CompanyRepositoryImpl implements CompanyRepository {
  private dataSource: CompanyDataSource

  constructor(dataSource: CompanyDataSource) {
    this.dataSource = dataSource
  }

  createCompany(company: Company) {
    return this.dataSource.createCompany(company)
  }

  deleteCompany(auth: Auth, name: string) {
    return this.dataSource.deleteCompany(auth, name)
  }

  updateCompany(editCompany: EditCompany) {
    return this.dataSource.updateCompany(editCompany)
  }

  getCompany(auth: Auth) {
    return this.dataSource.getCompany(auth)
  }
}
