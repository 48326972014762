import {
  API_V4_ADMIN,
  STR_CREATE_WEEKLY_TEST,
  STR_DELETE_SCHEDULE_WEEKLY_TEST,
  STR_DELETE_WEEKLY_TEST,
  STR_GET_ALL_SCHEDULE_TEST,
  STR_GET_WEEKLY_TEST,
  STR_SCHEDULE_WEEKLY_TEST,
  STR_UPDATE_SCHEDULE_WEEKLY_TEST,
  STR_UPDATE_WEEKLY_TEST,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import {
  CreateTest,
  deleteScheduleTest,
  scheduleTest,
  updateScheduleTest,
  updateTest,
} from "domain/model/TestSeriesDashboard"
import TestSeriesDataSource from "data/dataSource/Admin/TestSeriesDashboardDataSource"

export class TestSeriesDashboardAPIDataSourceImpl implements TestSeriesDataSource {
  async getAllWeeklyTest(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_WEEKLY_TEST), {
      id_token: auth.id_token,
    })
    return response
  }

  async getAllWeeklyScheduleTest(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_ALL_SCHEDULE_TEST), {
      id_token: auth.id_token,
    })
    return response
  }

  async createWeeklyTest(request: CreateTest): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_CREATE_WEEKLY_TEST), request)
    return response
  }

  async updateWeeklyTest(request: updateTest): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_UPDATE_WEEKLY_TEST), request)
    return response
  }
  async deleteWeeklyTest(request: updateTest): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_DELETE_WEEKLY_TEST), request)
    return response
  }
  async scheduleWeeklyTest(request: scheduleTest): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_SCHEDULE_WEEKLY_TEST), request)
    return response
  }

  async updateScheduledTest(request: updateScheduleTest): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_UPDATE_SCHEDULE_WEEKLY_TEST), request)
    return response
  }
  async deleteScheduleTest(request: deleteScheduleTest): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_DELETE_SCHEDULE_WEEKLY_TEST), request)
    return response
  }
}
