import { Auth } from "domain/model/Auth"
import AttendenceRepository from "domain/repository/Student/AttendenceRepository"

export interface IsAttendedUseCase {
  invoke(auth: Auth, contestId: any): Promise<any>
}

export default class IsAttended implements IsAttendedUseCase {
  private repository: AttendenceRepository
  constructor(repository: AttendenceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contestId: any): Promise<any> {
    return await this.repository.isStudentAttended(auth, contestId)
  }
}
