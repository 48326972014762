import useToast from "core/hooks/useToast"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import GetSubCaseQuestionDetails from "domain/useCase/User/Contest/GetSubCaseQuestionDetails"
import { useAuth } from "core/context/auth"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import SubmissionAPIDataSourceImpl from "data/API/Student/SubmissionAPIDataSourceImpl"
import { SubmissionRepositoryImpl } from "data/repository/Student/SubmissionRepositoryImpl"
import SubmitAssignmentQuestion from "domain/useCase/Student/Assignment/SubmitAssignmentQuestion"
import GetSubmission from "domain/useCase/Student/Assignment/GetSubmission"
import { isEmpty } from "core/utils/misc"
import delay from "core/utils/delay"

export default function ViewModel() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [question, setQuestion] = useState<any>({})
  const [optionSelected, setOptionSelected] = useState<{ [key: number]: {} }>({})
  const [solution, setSolution] = useState<{ [key: number]: string }>({})

  const TABS: string[] = ["Description", "Solution"]
  const [activeTab, setActiveTab] = useState<number>(0)
  const [viewSolution, setViewSolution] = useState<boolean[]>([])
  const [isSolutionViewed, setIsSolutionViewed] = useState<boolean[]>([])
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState<boolean>(false)
  const [submitConfirmationPopup, setSubmitConfirmationPopup] = useState<boolean>(false)

  const getSubCaseQuestionDetailsUseCase = new GetSubCaseQuestionDetails(new ContestAPIDataSourceImpl())

  const submitCaseStudyQuestionUseCase = new SubmitAssignmentQuestion(
    new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl())
  )

  const getSubmissionUseCase = new GetSubmission(new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl()))

  async function fetchQuestion(id: string) {
    setPageLoading(true)
    const response = await getSubCaseQuestionDetailsUseCase.invoke(auth, id)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Failed to load the question")
      changeToastVisibility(true)
      return
    }
    const temp = response.data.response
    temp.sort((a: any, b: any) => a.order - b.order)
    response.data.response = temp
    setQuestion(response.data)
    const len = response.data?.response?.length
    const _data = []
    for (let index = 0; index < len; index++) {
      _data[index] = false
    }
    setViewSolution(_data)
    setIsSolutionViewed(_data)
    setPageLoading(false)
  }

  async function fetchSubmission(id: string) {
    setPageLoading(true)
    const response = await getSubmissionUseCase.invoke(auth, { question_id: id })
    setPageLoading(false)

    if (!response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Failed to load the submission")
    } else {
      const answer = response?.data?.answer || {}
      const isSolutionViewed = response?.data?.is_solution_viewed || []
      const solutions: any = {}
      const options: any = {}

      for (let index = 0; index < question?.response?.length; index++) {
        const res = question.response[index]
        if (res.questionType === "MCQ") {
          options[res.id] = answer[res.id]
        } else {
          solutions[res.id] = answer[res.id]
        }
      }
      setSolution(solutions)
      setOptionSelected(options)
      setViewSolution(isSolutionViewed)
      setIsSolutionViewed(isSolutionViewed)
      // setIsQuestionSubmitted(true);
    }
  }

  function confirmViewSoluton(responseId: number) {
    setActiveTab(1)
    setViewSolution((viewSolution) => {
      const newViewSolution = [...viewSolution]
      newViewSolution[responseId] = true
      return newViewSolution
    })
    setIsSolutionViewed((isSolutionViewed) => {
      const newIsSolutionViewed = [...isSolutionViewed]
      newIsSolutionViewed[responseId] = true
      return newIsSolutionViewed
    })
  }

  function denyViewSolution(responseId: number) {
    setActiveTab(0)
    setViewSolution((viewSolution) => {
      const newViewSolution = [...viewSolution]
      newViewSolution[responseId] = false
      return newViewSolution
    })
  }

  function handelTabChange(index: number, responseId: number) {
    if (index === 0) {
      setActiveTab(0)
    } else {
      if (isSolutionViewed[responseId]) {
        setActiveTab(1)
      } else {
        setViewSolution((viewSolution) => {
          const newViewSolution = [...viewSolution]
          newViewSolution[responseId] = true
          return newViewSolution
        })
      }
    }
  }

  function handleOptionChange(e: any, responseId: any) {
    const newOptions = { ...optionSelected }
    let options = newOptions?.[responseId] || {}
    options = { ...options, [e.target.value]: e.target.checked }
    newOptions[responseId] = options
    setOptionSelected(newOptions)
  }

  function handleSolutionChange(e: any, responseId: any) {
    setSolution({
      ...solution,
      [responseId]: e.target.value,
    })
  }

  const handleSubmitBtnClick = () => {
    setSubmitConfirmationPopup(true)
  }
  const handleCancelBtnClick = () => {
    setSubmitConfirmationPopup(false)
  }

  async function handleSolutionSubmit(id: string) {
    const answer = {
      ...optionSelected,
      ...solution,
    }

    // Remove the requirement to check if all questions are answered
    const isAnswerEmpty = isEmpty(answer)

    if (isAnswerEmpty) {
      changeToastDetails(STR_FAILURE, "Please answer at least one question before submitting.")
      changeToastVisibility(true)
      return
    }

    setLoading(true)
    const response = await submitCaseStudyQuestionUseCase.invoke(auth, {
      question_id: id,
      question_type: "casestudy",
      answer: answer,
      is_solution_viewed: isSolutionViewed,
    })

    if (!response?.success) {
      const err = response?.details?.error_name ?? response?.error ?? "Error in submitting the question"
      changeToastDetails(STR_FAILURE, err)
      changeToastVisibility(true)
      setLoading(false)
      return
    }

    changeToastDetails(STR_SUCCESS, "Question submitted")
    changeToastVisibility(true)
    setLoading(false)
    setSubmitConfirmationPopup(false)
    await delay(2000)
    navigate(-1)
    return
  }

  return {
    toast,
    changeToastVisibility,
    pageLoading,
    loading,
    question,
    isQuestionSubmitted,
    fetchQuestion,
    fetchSubmission,
    TABS,
    activeTab,
    setActiveTab,
    viewSolution,
    setViewSolution,
    isSolutionViewed,
    setIsSolutionViewed,
    confirmViewSoluton,
    optionSelected,
    denyViewSolution,
    handelTabChange,
    handleOptionChange,
    handleSolutionSubmit,
    solution,
    handleSolutionChange,
    handleSubmitBtnClick,
    submitConfirmationPopup,
    handleCancelBtnClick,
  }
}
