import { Button } from "core/components/v2/Button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "core/components/v2/ui/dropdown-menu"

export default function PlacementTable(props: any) {
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {!props.placementDetails?.length && !props.loading && <div className="m-2">No data found</div>}
        <tbody>
          {props.placementDetails?.map((data: any, index: any) => {
            const formattedDate = new Date(data.company_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            const date = new Date(data.company_date)
            const stateDate = date.toISOString().split("T")[0]
            return (
              <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                <td className="px-6 py-4 ">
                  <p>{data.company_name}</p>
                </td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.designation}</td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{formattedDate}</td>
                <td className="relative px-6 py-4 text-[16px] font-medium text-new-accent ">
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <button className="rotate-90">...</button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white w-[304px]">
                      <DropdownMenuItem>
                        <Button
                          onClick={() => {
                            props?.setEditPlacementModel(true)
                            props?.setEditCompanyDetails({ ...data, company_date: stateDate })
                          }}
                          variant={"ternary"}
                          className="w-full flex justify-start"
                        >
                          <span>Update Company</span>
                        </Button>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Button
                          onClick={() => {
                            props?.setDeletePlacementModel(true)
                            props?.setDeleteCompanyDetails({ id: data?.id })
                          }}
                          variant={"cancel"}
                          className="w-full flex justify-start"
                        >
                          <span>Delete Company</span>
                        </Button>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
