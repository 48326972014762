import React from "react"

function Expand(props: any) {
  return (
    <svg
      className={`${props.classname}`}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.66536 12.6673H3.33203V9.33398M9.33203 3.33398H12.6654V6.66732"
        stroke="#6B6B6B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Expand
