import DashboardLayout from "core/layouts/DashboardLayout"
import useResetPasswordViewModel from "./ViewModel"
import { Input } from "core/components/v2/Input"
import Toast from "core/components/Toast"
import { Button } from "core/components/v2/Button"
import { useEffect } from "react"
import Spinner from "core/components/Spinner"

export default function ResetPasswordView() {
  const {
    newPassword,
    toast,
    oldPassword,
    student,
    isLoading,
    isSubmitting,
    handleChangeNewPassword,
    handleResetPassword,
    changeToastVisibility,
    fetchUser,
    setOldPassword,
  } = useResetPasswordViewModel()

  useEffect(() => {
    if (!student || !("password" in student)) {
      fetchUser()
    }
  }, [])

  useEffect(() => {
    if (student && "password" in student) {
      setOldPassword(student.password)
    }
  }, [student])

  return (
    <DashboardLayout isLoading={isLoading}>
      <div className="space-y-4 p-4">
        <h2 className="text-v2-accent-300">Reset Student Password</h2>
        <form className="flex gap-4 w-fit flex-col" onSubmit={handleResetPassword}>
          <Input id="old-password" placeholder="Existing password" label="Old Password" value={oldPassword} />
          <Input
            id="reset-password"
            placeholder="Type new password here"
            label="New Password"
            value={newPassword}
            onChange={handleChangeNewPassword}
          />
          <Button disabled={isSubmitting} variant="primary" {...(isSubmitting && { icon: <Spinner small /> })}>
            Change Password
          </Button>
        </form>
        <ul className="list-disc pl-4">
          <li>The password must be at least 6 characters long.</li>
          <li>It can contain only letters, numbers, periods (.), and underscores (_).</li>
          <li>It must not start with a period or an underscore.</li>
        </ul>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
