export default function More({ className }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3C11.675 3 11 3.675 11 4.5C11 5.325 11.675 6 12.5 6C13.325 6 14 5.325 14 4.5C14 3.675 13.325 3 12.5 3ZM12.5 18C11.675 18 11 18.675 11 19.5C11 20.325 11.675 21 12.5 21C13.325 21 14 20.325 14 19.5C14 18.675 13.325 18 12.5 18ZM11 12C11 11.175 11.675 10.5 12.5 10.5C13.325 10.5 14 11.175 14 12C14 12.825 13.325 13.5 12.5 13.5C11.675 13.5 11 12.825 11 12Z"
        fill="#ADADAD"
      />
    </svg>
  )
}
