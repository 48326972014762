import Button from "core/components/new/Button"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import Input from "core/components/new/Input"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"

export default function RefundForm(props: any) {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[#999999] bg-opacity-50 z-50">
      <div className="relative flex flex-col w-[560px] h-[100vh] bg-[#FFF] p-4 rounded-lg">
        <div className="flex items-center justify-center">
          <h3 className="text-lg font-semibold mb-4 text-new-accent">Refund Information</h3>
          <div className="absolute top-2 right-10">
            <button className="fixed" onClick={props.refundFormClose}>
              <CloseSharpIcon className="hover:text-red-500" />
            </button>
          </div>
        </div>
        <div className="w-full space-y-2 pb-2 overflow-auto custom-scrollbar-2">
          {Object.entries(props.Keys).map(([key, label]: [any, any], index) => (
            <div key={index} className="flex items-center px-4">
              <p className="w-1/3 text-[#414141]">{label}</p>
              {key === "date_time" ? (
                <div className="w-2/3 bg-white cursor-pointer">
                  <Input
                    type={STR_TEXT_FIELD}
                    name={key}
                    value={props.currentData[key]}
                    onChange={props.handleInputChange}
                    onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
                    onFocus={(e) => (e.target.type = STR_DATE_AND_TIME_FIELD)}
                  />
                </div>
              ) : (
                <input
                  type="text"
                  name={key}
                  className={`w-2/3 bg-white ${key === "status" && "text-[#DC2626]"} ${props.disabledKeys.includes(key) ? "bg-gray-100" : "cursor-pointer"}`}
                  defaultValue={`${key === "status" ? "Refund" : props.currentData[key] === undefined ? "" : props.currentData[key]}`}
                  onChange={props.handleInputChange}
                  disabled={props.disabledKeys.includes(key)}
                />
              )}
            </div>
          ))}
        </div>
        <div className="flex space-x-2 mt-2">
          <Button outlined failure onClick={props.handleDiscardBtnClick}>
            Discard Changes
          </Button>
          <Button
            className="bg-gradient-to-br from-[#06A1F9] to-[#4032EB] text-[#FFF]"
            onClick={props.handleSaveChanges}
          >
            Save Changes
          </Button>
        </div>
      </div>
      {props.showPopup && (
        <>
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 z-40"></div>
          <div className="fixed inset-0 flex justify-center items-center z-50">
            <div className="relative flex flex-col w-96 bg-white p-4 rounded-lg">
              <div>
                <h3 className="text-center p-3">Refund Confirmation</h3>
                <div className="absolute top-2 right-10">
                  <button className="fixed" onClick={props.popUpClose}>
                    <CloseSharpIcon className="hover:text-red-500" />
                  </button>
                </div>
              </div>
              <div className="text-sm text-center p-3">
                {"You are about to save changes for Refund "}
                <span className="font-semibold">{`Refund ID: ${props.currentData["refNo"]}`}</span>
              </div>
              <div className="flex flex-col items-center justify-center space-y-2">
                <Button className="w-60" onClick={props.confirmClick} success>
                  Confirm
                </Button>
                <Button className="w-60" onClick={props.cancelClick} failure>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
