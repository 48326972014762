import React, { useState, useEffect } from "react"
import useMonthlyReportVewModel from "./MonthlyReportViewModel"
import MyBarChart from "./BarChart.jsx"
import Report from "./Report"
import "./Chart.css"
import arrow from "assets/svgs/table_icon.svg"

export default function MonthlyReport() {
  const { selectedMonth, handleMonthSelect, handleNext, handlePrevious, monthBatch } = useMonthlyReportVewModel()

  return (
    <div className="text-center items-center border border-[#D3D3D3]" style={{ borderRadius: "4px" }}>
      <h1 className="font-semibold my-2 text-xl">Monthly Data</h1>
      <div className="flex justify-between items-stretch w-full">
        <div
          className="border border-[#D3D3D3] border-l-0 border-b-0 cursor-pointer w-1/12 p-5 flex justify-center items-center"
          onClick={handlePrevious}
        >
          <div className="h-full w-full">
            <img className="w-[85%]" src={arrow} alt="" />
          </div>
        </div>
        <div className="flex flex-grow justify-between">
          {monthBatch?.map((month: any, index: number) => {
            return (
              <div
                onClick={() => handleMonthSelect(index)}
                key={index}
                className={`border-y border-[#D3D3D3] flex items-center justify-center flex-grow p-5 text-center cursor-pointer ${
                  index === selectedMonth ? "bg-[#312E81] text-white" : "hover:bg-blue-100"
                }`}
              >
                <h2 className="font-semibold text-base my-auto"> {month.month}</h2>
                {/* <h4 className="font-semibold"> Month </h4> */}
              </div>
            )
          })}
        </div>

        <div
          className="border border-[#D3D3D3] cursor-pointer border-r-0 border-b-0 w-1/12 p-5 flex justify-center items-center"
          onClick={handleNext}
        >
          <div className="h-full w-full">
            <img className="w-[85%]" src={arrow} alt="" style={{ transform: "rotate(180deg)" }} />
          </div>
        </div>
      </div>
      <div className="flex justify-between ">
        <div className="border border-[#D3D3D3] w-1/12 border-b-0 border-l-0 p-5 flex justify-center items-center text-center">
          <p className="-rotate-90  whitespace-nowrap ">This week task list</p>
        </div>

        <div className="flex-col w-5/6">
          <div className="chart-container">
            <MyBarChart data={monthBatch} selectedMonth={selectedMonth} />
            <div className="chart-fade -mt-8"></div>{" "}
          </div>
          <div className="fade-content flex justify-around items-center">
            <div className="flex gap-3 items-center">
              <div
                style={{
                  backgroundColor: "#66EB45",
                  height: "10px",
                  width: "10px",
                }}
              ></div>
              <p>Class Attendance</p>
            </div>
            <div className="flex gap-3 items-center">
              <div
                style={{
                  backgroundColor: "#F8A934",
                  height: "10px",
                  width: "10px",
                }}
              ></div>
              <p>Assignment Problems</p>
            </div>
            <div className="flex gap-3 items-center">
              <div
                style={{
                  backgroundColor: "#FAF12D",
                  height: "10px",
                  width: "10px",
                }}
              ></div>

              <p>Class Feedback</p>
            </div>
            <div className="flex gap-3 items-center">
              <div
                style={{
                  backgroundColor: "#BB3CE8",
                  height: "10px",
                  width: "10px",
                }}
              ></div>
              <p>Weekly Test Score</p>
            </div>
          </div>

          <div
            className="flex flex-grow justify-between h-[100px] mt-3 border border-[#D3D3D3]"
            style={{
              borderBottom: "none",
              borderWidth: "0.5px",
              borderLeft: "none",
              borderRight: "none",
            }}
          >
            {monthBatch?.map((item: any) => (
              <div className="flex w-full">
                <div className="w-[4%] bg-[#5641A9]"></div>
                <div className="w-full text-left pl-2 my-auto">
                  <div className="text-[#5641A9] font-semibold text-xl">{item && item["ssmRating"]}</div>
                  <div className="text-[#6B6B6B] text-sm">Student Success</div>
                  <div className="text-[#6B6B6B] text-sm">Manager Meet</div>
                </div>
              </div>
            ))}
          </div>

          <div
            className="flex flex-grow justify-between h-[100px] border border-[#D3D3D3]"
            style={{
              borderWidth: "0.5px",
              borderLeft: "none",
              borderRight: "none",
            }}
          >
            {monthBatch?.map((item: any) => (
              <div className="flex w-full">
                <div className="w-[4%] bg-[#44BC66]"></div>
                <div className="w-full text-left pl-2 my-auto">
                  <div className="text-[#44BC66] font-semibold text-xl">{item && item["mentorRating"]}</div>
                  <div className="text-[#6B6B6B] text-sm">Mentor</div>
                  <div className="text-[#6B6B6B] text-sm">Session</div>
                </div>
              </div>
            ))}
          </div>

          <div className="my-7">
            <Report data={monthBatch && monthBatch[selectedMonth]} />
          </div>
          <div>{/* EXTENDED VERSION */}</div>
        </div>
        <div className="border border-[#D3D3D3] border-r-0 border-b-0 w-1/12 flex justify-center items-center"></div>
      </div>
    </div>
  )
}
