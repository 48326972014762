import React from "react"

export default function (props: any) {
  return (
    <div>
      <svg
        className={props?.className}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5.63548V5.66667H2.66667C2.29848 5.66667 2 5.96514 2 6.33333V14.3333C2 14.7015 2.29848 15 2.66667 15H13.3333C13.7015 15 14 14.7015 14 14.3333V6.33333C14 5.96514 13.7015 5.66667 13.3333 5.66667H12V5C12 2.79086 10.2091 1 8 1C7.33359 1 6.70525 1.16297 6.15264 1.45122L7.05986 2.50378C7.35224 2.39361 7.66908 2.33333 8 2.33333C9.47276 2.33333 10.6667 3.52724 10.6667 5V5.66667H5.33333V5.63548H4ZM3.33333 7V13.6667H12.6667V7H3.33333ZM6.71212 9.32152C6.55593 9.90444 6.81073 10.5196 7.33335 10.8213V12.3333H8.66669V10.8213C9.18931 10.5196 9.44411 9.90444 9.28792 9.32152C9.13173 8.73861 8.6035 8.33328 8.00002 8.33328C7.39655 8.33328 6.86831 8.73861 6.71212 9.32152Z"
          fill="#FBBF24"
        />
      </svg>
    </div>
  )
}
