import { useNavigate } from "react-router-dom"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import McqAPIDataSourceImpl from "data/API/Admin/McqAPIDataSourceImpl"
import { McqRepositoryImpl } from "data/repository/Admin/McqRepositoryImpl"
import { GetMcqQuestions } from "domain/useCase/Admin/Mcq/GetMcqQuestions"
import { DeleteMcqQuestion } from "domain/useCase/Admin/Mcq/DeleteMcqQuestion"
import React from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import { isEmpty } from "core/utils/misc"

export default function McqsViewModel() {
  const [mcqQuestionsLocally, setMcqQuestionsLocally] = useLocalStorage<any>("mcqs", [])
  const [mcqQuestions, setMcqQuestions] = React.useState<any[]>(() => mcqQuestionsLocally)
  const [searchQuestion, setSearchQuestion] = React.useState<any>("")
  const [loading, setLoading] = React.useState<boolean>(false)

  const navigate = useNavigate()
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const getMcqQuestionsUseCase = new GetMcqQuestions(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const deleteMcqQuestionUseCase = new DeleteMcqQuestion(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const fetchMcqQuestions = async () => {
    if (isEmpty(mcqQuestions)) setLoading(true)

    const response = await getMcqQuestionsUseCase.invoke(auth)

    if (isEmpty(mcqQuestions)) setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.error, "Couldn't fetch mcq questions!"))
      changeToastVisibility(true)
      return
    }

    setMcqQuestions(response?.data)
    setMcqQuestionsLocally(response?.data)
  }

  const handleSearchQuestionChange = (e: any) => {
    setSearchQuestion(e.target.value)
  }

  const handleSearchQuestion = () => {
    return function (question: any) {
      return searchQuestion === "" || question?.name?.toLowerCase()?.includes(searchQuestion?.toLowerCase())
    }
  }

  const redirectToMcq = (id: string) => {
    navigate(`/admin/mcqs/${id}`)
  }

  const deleteMcqQuestion = async (id: string) => {
    const response = await deleteMcqQuestionUseCase.invoke(auth, id)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.error, "Couldn't delete mcq questions!"))
      changeToastVisibility(true)
      return
    } else {
      setMcqQuestions(mcqQuestions.filter((mcq: any) => mcq.id !== id))
      changeToastDetails(STR_SUCCESS, genError(response?.error, "Successfully deleted mcq questions!"))
      changeToastVisibility(true)
      return
    }
  }

  const copyQuestionToClipboard = (id: any) => {
    if (id) {
      try {
        const origin = "https://platform.bosscoderacademy.com"
        changeToastDetails(STR_SUCCESS, `Question URL Copied To Clipboard!`)
        changeToastVisibility(true)
        const str = `${origin}/mcqs/${id}`
        navigator.clipboard.writeText(str)
      } catch (error) {
        changeToastDetails(STR_FAILURE, genError(error, "Unable to copy text to clipboard"))
        changeToastVisibility(true)
      }
    }
  }

  return {
    toast,
    searchQuestion,
    mcqQuestions,
    loading,
    changeToastVisibility,
    fetchMcqQuestions,
    redirectToMcq,
    handleSearchQuestion,
    handleSearchQuestionChange,
    deleteMcqQuestion,
    copyQuestionToClipboard,
  }
}
