import React, { useEffect, useState } from "react"
import { GetWeeklyReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetWeeklyReportData"
import { WeeklyReportRepositoryImpl } from "data/repository/Admin/WeeklyReportRepositoryImpl"
import { WeeklyReportAPIDataSourceImpl } from "data/API/Admin/WeeklyReportDataSourceImpl"
import { useAuth } from "core/context/auth"
import ProgressRoadmapViewModel from "../../ProgressRoadmapViewModel"

interface WeekData {
  week: number
  classAttendance: number
  assignmentProblems: number
  avgRating: number
  waScore: number
  data: string
  present: boolean
}

const WeeklyReportViewModel = () => {
  const [weekData, setWeeksData] = useState<any>()
  const [selectedWeek, setSelectedWeek] = useState<number>(0)
  const [selectedWeekData, setSelectedWeekData] = useState<any>()
  const [monthIndex, setMonthIndex] = useState<number>(0)
  const { auth } = useAuth()
  const { id } = ProgressRoadmapViewModel()
  const handleWeekSelect = (index: number): void => {
    setSelectedWeek(index)
    setSelectedWeekData(weekData[monthIndex]?.weeks[index])
  }
  const [months, setMonths] = useState<number>(0)
  useEffect(() => {}, [weekData, monthIndex, months])

  useEffect(() => {
    getWeeklyReport()
  }, [])

  const studentWeeklyReportUseCase = new GetWeeklyReportData(
    new WeeklyReportRepositoryImpl(new WeeklyReportAPIDataSourceImpl())
  )

  const getWeeklyReport = async () => {
    const response = await studentWeeklyReportUseCase.invoke({
      id_token: auth.id_token,
      email: id,
    })
    setMonths(response.data.length)
    setWeeksData(response.data)
  }

  const handleNext = (): void => {
    setMonthIndex((current) => {
      if (current - 1 >= 0) {
        return current - 1
      } else {
        return current
      }
    })
  }

  const handlePrevious = (): void => {
    setMonthIndex((current) => {
      if (current + 1 < months) {
        return current + 1
      } else {
        return current
      }
    })
  }

  return {
    weekData,
    selectedWeek,
    selectedWeekData,
    setSelectedWeek,
    handleWeekSelect,
    handleNext,
    handlePrevious,
    monthIndex,
    months,
  }
}

export default WeeklyReportViewModel
