import { Auth } from "domain/model/Auth"
import { NotificationRepository } from "domain/repository/Student/NotificationRepository"

export interface GetNotificationDetailsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetNotificationDetails implements GetNotificationDetailsUseCase {
  private repository: NotificationRepository

  constructor(repository: NotificationRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getNotificationDetails(auth)
  }
}
