export default function Discord({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" fill="white" />
      <path
        d="M7.0502 22.3111C14.0094 25.1205 19.9317 25.6677 26.6429 21.2948C26.5937 21.3712 25.2662 23.4056 21.7751 24.4482C22.5126 25.4909 23.5206 26.6861 23.5206 26.6861C25.7579 26.6861 27.9459 26.0249 29.8389 24.7534C31.314 23.7361 32.1498 21.9814 31.9777 20.1504C31.6827 17.0224 30.9697 13.9707 29.8635 11.0463C28.4621 7.23168 25.0941 4.58685 21.1851 4.20536C20.8409 4.17991 20.5951 4.17991 20.4476 4.17991L20.0542 4.58677C24.5532 5.90919 26.7905 7.96906 26.8396 8.04539C19.9313 4.43419 11.7692 4.38338 4.81165 7.89281C4.81165 7.89281 7.02429 5.62947 11.9166 4.43419L11.6216 4.12903C11.1053 4.12903 10.6136 4.17991 10.0974 4.25617C6.48339 4.89193 3.45951 7.46044 2.15649 10.9953C1.02558 14.047 0.288053 17.2513 0.0176403 20.5064C-0.129864 22.2357 0.656851 23.9396 2.03358 24.9314C3.85283 26.1775 6.01628 26.8387 8.20432 26.8387C8.20432 26.8387 9.08935 25.6434 9.97445 24.5754C6.65549 23.5581 5.30335 21.5237 5.27876 21.4473L5.90337 21.7773C6.27676 21.9746 6.65958 22.1534 7.0502 22.3111V22.3111ZM10.9087 20.0996C9.31064 20.0487 8.05682 18.65 8.10601 16.9715C8.1552 15.3949 9.38443 14.1233 10.9087 14.0724C12.5067 14.1233 13.7605 15.522 13.7113 17.2005C13.6375 18.7772 12.4329 20.0488 10.9087 20.0996V20.0996ZM20.9392 20.0996C19.3412 20.0487 18.0873 18.65 18.1365 16.9715C18.1857 15.3949 19.415 14.1233 20.9392 14.0724C22.5372 14.1233 23.791 15.522 23.7419 17.2005C23.6927 18.7772 22.4634 20.0488 20.9392 20.0996V20.0996Z"
        fill="currentColor"
      />
    </svg>
  )
}
