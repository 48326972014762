import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import UpdateBatchAPIDataSourceImpl from "data/API/Admin/UpdateBatchAPIDataSource"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { UpdateBatchRepositoryImpl } from "data/repository/Admin/UpdateBatchRepositoryImpl"
import { set } from "date-fns"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { ShiftBatch } from "domain/useCase/Admin/UpdateBatch/ShiftBatch"
import { UpdateBatches } from "domain/useCase/Admin/UpdateBatch/UpdateBatch"
import { UpdateBatchBunch } from "domain/useCase/Admin/UpdateBatch/UpdateBatchBunch"
import React from "react"

export default function useUpdateBatchViewModel() {
  const [selectBatch, setSelectBatch] = React.useState<any>("")
  const [selectBatch2, setSelectBatch2] = React.useState<any>("")
  const [batchList, setBatchList] = React.useState<any>([])
  const [email, setEmail] = React.useState<any>("")
  const [emailList, setEmailList] = React.useState<any>([])
  const [loading, setLoading] = React.useState<any>(false)
  const [fromBatch, setFromBatch] = React.useState<any>("")
  const [toBatch, setToBatch] = React.useState<any>("")
  const [batchShifting, setBatchShifting] = React.useState<any>(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const updateStudentBatchUseCase = new UpdateBatches(new UpdateBatchRepositoryImpl(new UpdateBatchAPIDataSourceImpl()))

  const shiftBatchUseCase = new ShiftBatch(new UpdateBatchRepositoryImpl(new UpdateBatchAPIDataSourceImpl()))

  const updateBatchBunchUseCase = new UpdateBatchBunch(
    new UpdateBatchRepositoryImpl(new UpdateBatchAPIDataSourceImpl())
  )

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }

  async function updateStudentBatch(previousBatchData: boolean) {
    setLoading(true)
    const response = await updateStudentBatchUseCase.invoke({
      id_token: auth.id_token,
      student_email: email?.trim()?.toLowerCase(),
      batch: selectBatch,
      previous: previousBatchData,
    })
    if (response.success) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, STR_SUCCESS)
    } else {
      setLoading(false)
      changeToastVisibility(true)
      if (response.error) {
        changeToastDetails(STR_FAILURE, response.error)
      } else {
        changeToastDetails(STR_FAILURE, response.data)
      }
    }

    return response.data
  }

  async function handleUpdateBatchBunch() {
    setLoading(true)
    let emails = emailList
      .split(" ")
      .map((email: string) => email.trim().toLowerCase())
      .filter((email: string) => email !== "")
    const response = await updateBatchBunchUseCase.invoke({
      id_token: auth.id_token,
      emails,
      batch: selectBatch2,
    })
    if (response.success) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, STR_SUCCESS)
    } else {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }

    return response.data
  }
  const shiftBatch = async () => {
    if (!fromBatch || !toBatch) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please select batch")
      return
    }

    if (fromBatch === toBatch) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please select different batch")
      return
    }

    setBatchShifting(true)
    const response = await shiftBatchUseCase.invoke(auth, {
      from: fromBatch,
      to: toBatch,
    })
    setBatchShifting(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, STR_SUCCESS)
  }

  function handleBatchChange(e: any) {
    setSelectBatch(e.target.value)
  }
  function handleBatchChange2(e: any) {
    setSelectBatch2(e.target.value)
  }

  function handleEmailChange(e: any) {
    setEmail(e.target.value)
  }
  function handleEmailChangeBunch(e: any) {
    setEmailList(e.target.value)
  }
  function handleFromBatchChange(e: any) {
    setFromBatch(e.target.value)
  }

  function handleToBatchChange(e: any) {
    setToBatch(e.target.value)
  }

  return {
    toast,
    loading,
    selectBatch,
    selectBatch2,
    batchList,
    email,
    fromBatch,
    toBatch,
    batchShifting,
    isOpen,
    emailList,
    setIsOpen,
    shiftBatch,
    handleFromBatchChange,
    handleToBatchChange,
    handleUpdateBatchBunch,
    handleBatchChange2,
    handleEmailChangeBunch,
    handleBatchChange,
    handleEmailChange,
    getBatches,
    updateStudentBatch,
    changeToastDetails,
    changeToastVisibility,
  }
}
