import React from "react"

export default function Menu({ className, ...props }: any) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20C11 20.825 11.675 21.5 12.5 21.5C13.325 21.5 14 20.825 14 20C14 19.175 13.325 18.5 12.5 18.5C11.675 18.5 11 19.175 11 20ZM11 5C11 5.825 11.675 6.5 12.5 6.5C13.325 6.5 14 5.825 14 5C14 4.175 13.325 3.5 12.5 3.5C11.675 3.5 11 4.175 11 5ZM12.5 14C11.675 14 11 13.325 11 12.5C11 11.675 11.675 11 12.5 11C13.325 11 14 11.675 14 12.5C14 13.325 13.325 14 12.5 14Z"
        fill="#6B6B6B"
        className={props.pathClassName}
      />
    </svg>
  )
}
