import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { ProfileDataSource } from "data/dataSource/Mentor/ProfileDataSource"
import { API_FETCH_MENTOR_PROFILE, API_MENTORS } from "core/constants/strings"

export default class ProfileAPIDataSourceImpl implements ProfileDataSource {
  async fetchMentorProfile(auth: Auth) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_FETCH_MENTOR_PROFILE), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
