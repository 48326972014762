import React from "react"

export default function Cart({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66666 1.33203C2.97092 1.33203 3.23303 1.53729 3.31039 1.82519L3.3258 1.89882L3.64399 3.9967L14 3.99658C14.3997 3.99658 14.7031 4.34348 14.6634 4.72982L14.6509 4.80753L13.3205 10.8093C13.2584 11.0891 13.0259 11.2949 12.7468 11.3273L12.6696 11.3317H3.97981C3.67554 11.3317 3.41344 11.1265 3.33607 10.8385L3.32066 10.7649L2.09399 2.66536H0.666656V1.33203H2.66666ZM3.99999 13.9987C3.99999 14.7351 4.59694 15.332 5.33332 15.332C6.0697 15.332 6.66666 14.7351 6.66666 13.9987C6.66666 13.2623 6.0697 12.6654 5.33332 12.6654C4.59694 12.6654 3.99999 13.2623 3.99999 13.9987ZM9.99999 13.9987C9.99999 14.7351 10.5969 15.332 11.3333 15.332C12.0697 15.332 12.6667 14.7351 12.6667 13.9987C12.6667 13.2623 12.0697 12.6654 11.3333 12.6654C10.5969 12.6654 9.99999 13.2623 9.99999 13.9987ZM3.84598 5.33003L4.55331 9.9987H12.1353L13.17 5.33003H3.84598Z"
        fill="currentColor"
      />
    </svg>
  )
}
