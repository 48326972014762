import { Auth } from "domain/model/Auth"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface UploadMentorImageUsecase {
  invoke(auth: Auth, profile_picture: File): Promise<any>
}

export class UploadMentorImage implements UploadMentorImageUsecase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, profile_picture: File): Promise<any> {
    return await this.repository.uploadMentorImage(auth, profile_picture)
  }
}
