import Button from "core/components/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"
import Toast from "core/components/Toast"
import useUpdateClassFormViewModel from "./UpdateClassFormViewModel"
import { format } from "date-fns"
import { CrossIcon } from "core/constants/svgs"

export default function UpdateClassForm(props: any) {
  const {
    selectBatch,
    currentSelectAssignmentsQuestions,
    currentSelectBatch,
    setcurrentSelectBatch,
    chapterNames,
    setChapterNames,
    classLink,
    setClassLink,
    selectInstructor,
    setSelectInstructor,
    selectModuleId,
    setSelectModuleId,
    notes_link,
    setNotesLink,
    selectAssigmentsQuestions,
    setSelectAssigmentsQuestions,
    selectHomeworkQuestions,
    setSelectHomeworkQuestions,
    currentSelectHomeworkQuestions,
    selectPracticalQuestions,
    setSelectPracticalQuestions,
    currentSelectPracticalQuestions,
    selectWarmupQuestions,
    setselectWarmupQuestions,
    currentSelectWarmupQuestions,
    session_timestamp,
    setSessionTimestamp,
    selectTrack,
    setSelectTrack,
    videoId,
    setVideoId,
    notesSummaryLink,
    setNotesSummaryLink,
    assignmentSolutionActivationTime,
    setAssignmentSolutionActivationTime,
    assignmentSolutionLink,
    setAssignmentSolutionLink,
    videoSecret,
    setVideoSecret,
    loading,
    toast,
    compensationStatus,
    assignmentCoins,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    setMasterClassType,
    handleMasterClassType,
    handleNewDuration,
    handleSessionDuration,
    handleOtherSessionDuration,
    setAssignmentCoins,
    handleChangeAssignmentCoins,
    handleCompensationStatus,
    handleRemoveBatch,
    setSessionId,
    setSelectBatch,
    handleAssignmentChange,
    handleAddAssignmentQuestions,
    handleSelectHomeworkChange,
    handleAddHomeworkQuestions,
    handlePracticalChange,
    handleAddPracticalQuestions,
    handleWarmupChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeSessionTimestamp,
    handleChangeTrack,
    handleChangeVideoId,
    handleChangeVideoSecreat,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    removeAssignmentQuestion,
    removeHomeworkQuestion,
    removePracticeQuestion,
    removeWarmupQestions,
    handleAddWarmupQuestions,
    handleChangeAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    updateClass,
    setPreviousTimestamp,
    changeToastVisibility,
  } = useUpdateClassFormViewModel()

  const compensationStatusArray = ["Hourly Class", "Module Based Class"]
  const DurationArray = [1, 1.5, 2, 2.5, 3, "other"]
  const masterClassTypeArray = ["Software Engineering", "Data Scientist", "Both"]

  React.useEffect(() => {
    setcurrentSelectBatch(props.currentSelectBatch)
    setSelectBatch(props.currentSelectBatch)
    setChapterNames(props.chapterNames)
    setClassLink(props.classLink)
    setSelectInstructor(props.selectInstructor)
    setSelectModuleId(props.selectModuleId)
    setNotesLink(props.notes_link)
    setSelectAssigmentsQuestions(props.selectAssigmentsQuestions)
    setSelectHomeworkQuestions(props.selectHomeworkQuestions)
    setSelectPracticalQuestions(props.selectPracticalQuestions)
    setselectWarmupQuestions(props.selectWarmupQuestions)
    setSessionTimestamp(format(props.session_timestamp * 1000, "yyyy-MM-dd'T'HH:mm"))
    handleNewDuration(props.session_duration.toString(), DurationArray)
    setSelectTrack(props.selectTrack)
    setVideoId(props.videoId)
    setVideoSecret(props.videoSecret)
    setNotesSummaryLink(props.notesSummaryLink)
    setMasterClassType(props.master_class_type)
    setAssignmentSolutionLink(props.assignmentSolutionLink)
    setAssignmentSolutionActivationTime(props.assignmentSolutionActivationTime)
    setSessionId(props.session_id)
    setPreviousTimestamp(props.session_timestamp)
    setAssignmentCoins(props.assignmentCoins)
  }, [
    props.currentSelectBatch,
    props.chapterNames,
    props.classLink,
    props.selectInstructor,
    props.selectModuleId,
    props.notes_link,
    props.selectAssigmentsQuestions,
    props.selectHomeworkQuestions,
    props.selectPracticalQuestions,
    props.selectWarmupQuestions,
    props.session_timestamp,
    props.selectTrack,
    props.videoId,
    props.videoSecret,
    props.notesSummaryLink,
    props.session_id,
    props.assignmentSolutionLink,
    props.assignmentSolutionActivationTime,
    props.assignmentCoins,
  ])

  return (
    <div className="space-y-4">
      <p className="text-xl font-medium">Edit - {props.chapterNames}</p>
      <ul className="flex w-full flex-col border p-2">
        {selectBatch?.map((batch: any) => (
          <li key={batch} className="flex items-center gap-1 p-0.5">
            {batch}
            <button
              className="rounded-sm bg-new-solid-black text-new-solid-white"
              onClick={() => handleRemoveBatch(batch)}
            >
              <CrossIcon className="h-4 w-4" />
            </button>
          </li>
        ))}
        {selectBatch?.length === 0 && <li className="flex items-center gap-1 p-0.5">No Batch Selected</li>}
      </ul>
      <StandaredSelect
        required
        options={props.batch}
        placeholder="Select Batch"
        onChange={handleBatchChange}
        value={currentSelectBatch}
      />
      <Input type={STR_TEXT_FIELD} value={chapterNames} onChange={handleChangeChapterName} placeholder="Class Name" />
      <Input type={STR_TEXT_FIELD} value={classLink} onChange={handleChangeClassLink} placeholder="Class Link" />
      <StandaredSelect
        required
        options={props.instructor?.map((c: any, index: any) => c.email)}
        placeholder="Instructor"
        onChange={handleChangeInstructor}
        value={selectInstructor}
      />
      <StandaredSelect
        required
        options={compensationStatusArray}
        value={compensationStatus}
        onChange={handleCompensationStatus}
        placeholder="Compensation Status"
      />
      <StandaredSelect
        required
        options={props.moduleId}
        placeholder="Module ID"
        onChange={handleChangeModuleId}
        value={selectModuleId}
      />
      <ul className=" flex w-full flex-col border p-2">
        {selectAssigmentsQuestions?.map((assigment: any, index: any) => (
          <li key={index}>
            {assigment}{" "}
            <IconButton
              className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
              onClick={() => removeAssignmentQuestion(index)}
              text="X"
            >
              X
            </IconButton>
          </li>
        ))}
        {selectAssigmentsQuestions?.length === 0 && (
          <li className="flex items-center gap-1 p-0.5">No Assignment Questions Selected</li>
        )}
      </ul>
      <form className=" flex w-full justify-between gap-4" onSubmit={handleAddAssignmentQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Assignments"
          onChange={handleAssignmentChange}
          value={currentSelectAssignmentsQuestions}
        />
        <Button className="ml-auto w-fit">+</Button>
      </form>
      <ul className=" flex w-full flex-col border p-2">
        {selectHomeworkQuestions?.map((homework: any, index: any) => (
          <li>
            {homework}
            <IconButton
              className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
              onClick={() => removeHomeworkQuestion(index)}
              text="X"
            >
              X
            </IconButton>
          </li>
        ))}
        {selectHomeworkQuestions?.length === 0 && (
          <li className="flex items-center gap-1 p-0.5">No Homework Questions Selected</li>
        )}
      </ul>
      <form className=" flex w-full justify-between gap-4" onSubmit={handleAddHomeworkQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Homework"
          onChange={handleSelectHomeworkChange}
          value={currentSelectHomeworkQuestions}
        />
        <Button className="ml-auto w-fit">+</Button>
      </form>
      <ul className=" flex w-full flex-col border p-2">
        {selectPracticalQuestions?.map((practical: any, index: any) => (
          <li>
            {practical}
            <IconButton
              className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
              onClick={() => removePracticeQuestion(index)}
              text="X"
            >
              X
            </IconButton>
          </li>
        ))}
        {selectPracticalQuestions?.length === 0 && (
          <li className="flex items-center gap-1 p-0.5">No Practice Questions Selected</li>
        )}
      </ul>
      <form className=" flex w-full justify-between gap-4" onSubmit={handleAddPracticalQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Practice Questions"
          onChange={handlePracticalChange}
          value={currentSelectPracticalQuestions}
        />
        <Button className="ml-auto w-fit">+</Button>
      </form>
      <ul className=" flex w-full flex-col border p-2">
        {selectWarmupQuestions?.map((questions: any, index: any) => (
          <li>
            {questions}
            <IconButton
              className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
              onClick={() => removeWarmupQestions(index)}
              text="X"
            >
              X
            </IconButton>
          </li>
        ))}
        {selectWarmupQuestions?.length === 0 && (
          <li className="flex items-center gap-1 p-0.5">No Warmup Questions Selected</li>
        )}
      </ul>
      <form className=" flex w-full justify-between gap-4" onSubmit={handleAddWarmupQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Warmup Questions"
          onChange={handleWarmupChange}
          value={currentSelectWarmupQuestions}
        />
        <Button className="ml-auto w-fit">+</Button>
      </form>
      <Input
        type={STR_DATE_AND_TIME_FIELD}
        value={session_timestamp}
        onChange={handleChangeSessionTimestamp}
        placeholder="Date && Time"
      />
      <StandaredSelect
        required
        options={DurationArray}
        value={sessionDuration}
        onChange={handleSessionDuration}
        placeholder="Session Duration (in hrs)"
      />
      {sessionDuration == "other" && (
        <Input
          required
          type="number"
          value={otherSessionDuration}
          onChange={handleOtherSessionDuration}
          placeholder="Session Duration (in hrs)"
        />
      )}
      <StandaredSelect
        required
        options={props.track}
        placeholder="Track"
        onChange={handleChangeTrack}
        value={selectTrack}
      />
      {selectTrack === "master_class" && (
        <StandaredSelect
          required
          options={masterClassTypeArray}
          placeholder="Master Class Type"
          onChange={handleMasterClassType}
          value={masterClassType}
        />
      )}
      <Input type={STR_TEXT_FIELD} value={videoId} onChange={handleChangeVideoId} placeholder="Video ID" />
      <Input type={STR_TEXT_FIELD} value={videoSecret} onChange={handleChangeVideoSecreat} placeholder="Video Secret" />
      <Input type={STR_TEXT_FIELD} value={notes_link} onChange={handleChangeNotesLink} placeholder="Notes Link" />
      <Input
        type={STR_TEXT_FIELD}
        value={notesSummaryLink}
        onChange={handleChangeNotesSummaryLink}
        placeholder="Notes Summary Link"
      />
      <Input
        type={STR_TEXT_FIELD}
        value={assignmentSolutionLink}
        onChange={handleChangeAssignmentSolutionLink}
        placeholder="Assignment Solution Link"
      />
      <Input
        type="number"
        max="100"
        min="0"
        placeholder="Assignment Solution Activation Time (in Days)"
        value={assignmentSolutionActivationTime}
        onChange={handleChangeAssignmentSolutionActivationTime}
      />
      <Input
        type="number"
        max="100"
        min="0"
        placeholder="Assignment Coins"
        value={assignmentCoins}
        onChange={handleChangeAssignmentCoins}
      />
      <Button onClick={updateClass}>{loading ? "Updating..." : "Update Class"}</Button>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
