import { Auth } from "domain/model/Auth"
import { RedeemCoinsRepository } from "domain/repository/Student/RedeemCoinsRepository"

export interface GetOrderHistoryUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}
export default class OrderHistory implements GetOrderHistoryUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string) {
    return this.repository.getOrderHistory(auth, email)
  }
}
