import AwardEmpty from "assets/svgs/AwardEmpty"
import Loader from "core/components/Loader"
import { Button } from "core/components/v2/Button"
import { AwardIcon, CrossIcon } from "core/constants/svgs"
import { useState } from "react"

export default function StudentFeedbackModal({
  isOpen,
  onClose,
  loading,
  formatTimestamp,
  onboardingDetails,
  studentFeedbackDetails,
  setStudentFeedbackDetails,
  updateOnboardingMeetingDetails,
}: any) {
  if (!isOpen) return null

  const [rating, setRating] = useState(0)

  const handleClick = (index: number) => {
    setRating(index + 1)
    setStudentFeedbackDetails({
      ...studentFeedbackDetails,
      star: index + 1,
    })
  }
  return (
    <div className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-[2px] z-0"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative bg-white  p-[24px] w-[450px] h-[550px] rounded-[8px] border-[0.5px] border-[#DEDEDE] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.2)]">
          <div className="flex justify-between">
            <p className="text-[16px] font-[600] text-[#03249A]">Onboarding Session Detail</p>
            <button onClick={onClose}>
              <CrossIcon className="h-6 w-6 hover:bg-[#dadada] rounded" />
            </button>
          </div>
          <p className="text-[14px] font-[500]">{formatTimestamp(onboardingDetails?.start_time)}</p>
          <p className="text-[14px] font-[600] mt-[16px] text-[#333]">Student Feedback</p>
          <div className="flex gap-[8px] mt-[8px]">
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} onClick={() => handleClick(index)} className="cursor-pointer">
                {index < rating ? <AwardIcon className="size-[16px]" /> : <AwardEmpty className="size-[16px]" />}
              </div>
            ))}
          </div>
          <textarea
            value={studentFeedbackDetails?.message}
            onChange={(e: any) => {
              setStudentFeedbackDetails({
                ...studentFeedbackDetails,
                message: e.target.value,
              })
            }}
            placeholder="Type your message here!"
            className="mb-[16px] w-[401px] h-[318px] border-[0.5px] border-[#D3D3D3] focus:border-new-accent py-[12px] my-[20px] rounded-[4px]"
          />

          <div className="absolute bottom-0 left-0 bg-[#F9F9F9] w-full rounded-b-[4px] py-[12px] px-[24px] flex justify-end gap-[16px]">
            <Button variant={"cancel"} border={"thin"} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateOnboardingMeetingDetails(studentFeedbackDetails)
              }}
              variant={"primary"}
              border={"thin"}
            >
              {loading ? <Loader small={true} /> : "Save Changes"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
