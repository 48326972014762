import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { JobDashboardAPIDataSourceImpl } from "data/API/Admin/JobDashboardAPIDataSourceImpl"
import { JobDashboardRepositoryImpl } from "data/repository/Admin/JobDashboardRepositoryImpl"
import { CreateCompany } from "domain/useCase/Admin/JobDashboard/CreateCompany"
import { CreateJobs } from "domain/useCase/Admin/JobDashboard/CreateJob"
import { GetCompanies } from "domain/useCase/Admin/JobDashboard/GetCompaniesList"
import React from "react"
import useToast from "core/hooks/useToast"
import { useNavigate } from "react-router-dom"

export default function CreateJobViewModel() {
  const [companiesList, setCompaniesList] = React.useState<any>([])
  const [selectCompany, setSelectCompany] = React.useState("")

  const [selectValues, setSelectValues] = React.useState({
    expiredDate: "",
    jobDescription: "",
    job_link: "",
    companyLogo: "",
    problemSolved: "",
    experience: "",
    location: "",
    role: "",
    CTC: "",
  })
  const [jobType, selectJobType] = React.useState<any>("")
  const [status, setStatus] = React.useState<any>("")
  const [othersCriteria, setOthersCriteria] = React.useState<any>({})
  const [selectOthersCriteria, setSelectOthersCriteria] = React.useState("")
  const [contactList, setContactList] = React.useState<any>([])
  const [selectContactList, setSelectContactList] = React.useState("")
  const [source, setSource] = React.useState("")
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)

  const navigate = useNavigate()
  const handleOpenModel = () => {
    setIsOpen(true)
  }

  const handleCloseModel = () => {
    setIsOpen(false)
  }
  const GetCompaniesListUseCase = new GetCompanies(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  const createJobUseCase = new CreateJobs(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  const createCompanyUseCase = new CreateCompany(new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl()))

  function onCompanyChange(e: any) {
    if (e.target.value === "others") {
      handleOpenModel()
      return
    }
    setSelectCompany(e.target.value)
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setSelectValues((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const onChangeJobType = (e: any) => {
    selectJobType(e.target.value)
  }

  const onChangeStatus = (e: any) => {
    setStatus(e.target.value)
  }
  const onChangeSource = (e: any) => {
    setSource(e.target.value)
  }

  const onChangeContactList = (e: any) => {
    setSelectContactList(e.target.value)
  }
  const addContact = (e: any) => {
    e.preventDefault()
    if (selectContactList.length > 0) {
      setContactList([...contactList, selectContactList])
    }
    setSelectContactList("")
  }
  const onChangeOthersCriteria = (e: any) => {
    setSelectOthersCriteria(e.target.value)
  }
  function removeItem(array: any[], index: number) {
    return array.filter((item, i) => i !== index)
  }
  function handleOthersCriteria(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (selectOthersCriteria.length > 0) {
      setOthersCriteria((prevState: any) => ({
        ...prevState,
        [selectOthersCriteria]: false,
      }))
    }
    setSelectOthersCriteria("")
  }

  function removeOthersCriteria(key: string) {
    setOthersCriteria((prevState: any) => {
      const { [key]: _, ...rest } = prevState
      return rest
    })
  }

  async function getCompaniesList() {
    const response = await GetCompaniesListUseCase.invoke(auth)

    if (response.success) {
      setCompaniesList(response.data)
    }
  }

  const system_criteria = {
    problem_solved: parseInt(selectValues.problemSolved),
    experience: parseInt(selectValues.experience),
  }

  async function createJob(e: any) {
    e.preventDefault()
    e.stopPropagation()
    const response = await createJobUseCase.invoke({
      id_token: auth.id_token,
      company_key: selectCompany,
      date_created: Math.floor(Date.now() / 1000),
      end_date: Date.parse(selectValues.expiredDate) / 1000,
      job_description: selectValues.jobDescription,
      job_link: selectValues.job_link,
      job_pic_url: selectValues.companyLogo,
      job_type: jobType,
      system_criteria: system_criteria,
      other_criteria: othersCriteria,
      status: status,
      source: source,
      role: selectValues.role,
      CTC: selectValues.CTC,
      location: selectValues.location,
    })

    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response.data)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.data)
    }
    navigate(0)
  }
  async function createCompany(e: any) {
    e.preventDefault()
    const response = await createCompanyUseCase.invoke({
      id_token: auth.id_token,
      location: selectValues.location,
      name: selectCompany,
      logo: selectValues.companyLogo,
      contact_list: contactList,
    })
    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response.data)
      setIsOpen(false)
      getCompaniesList()
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.data)
      setIsOpen(false)
    }
  }

  return {
    toast,
    selectContactList,
    contactList,
    jobType,
    selectOthersCriteria,
    othersCriteria,
    status,
    source,
    selectValues,
    companiesList,
    selectCompany,
    isOpen,
    setContactList,
    onChangeContactList,
    getCompaniesList,
    handleChange,
    onCompanyChange,
    handleCloseModel,
    handleOpenModel,
    onChangeJobType,
    onChangeOthersCriteria,
    onChangeStatus,
    handleOthersCriteria,
    removeOthersCriteria,
    createJob,
    createCompany,
    onChangeSource,
    changeToastVisibility,
    addContact,
    removeItem,
  }
}
