import { classTemplate } from "domain/model/CreateClass"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface getClassTemplateUseCase {
  invoke(request: classTemplate): Promise<any>
}

export class ClassTemplate implements getClassTemplateUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }

  async invoke(request: classTemplate): Promise<any> {
    return await this.repository.getClassTemplate(request)
  }
}
