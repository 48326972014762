import { Auth } from "domain/model/Auth"
import { CreateNewProject } from "domain/model/ProjectSection"
import { ProjectSectionRepository } from "domain/repository/Admin/ProjectSectionRepository"

export interface GetProjectUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetProject implements GetProjectUseCase {
  private repository: ProjectSectionRepository

  constructor(repository: ProjectSectionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getProject(auth)
  }
}
