import { Auth } from "domain/model/Auth"
import { NewMentorSessionsRepository } from "domain/repository/Admin/NewMentorSessionsRepository"

export interface GetBatchTemplateGenericUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetBatchTemplateGeneric implements GetBatchTemplateGenericUseCase {
  private repository: NewMentorSessionsRepository

  constructor(repository: NewMentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.getBatchTemplateGeneric(auth, data)
  }
}
