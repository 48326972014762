import React from "react"
import useStoreViewModel from "../StoreViewModel"
import DashboardLayoutV2 from "core/layouts/DashboardLayoutv2"
import { AddIcon, CartIcon, CoinIcon, CrossIcon, MoreIcon } from "core/constants/svgs"
import { useNavigate, useParams } from "react-router-dom"
import Button from "core/components/new/Button"
import Loader from "core/components/Loader"
import { convertToAddress } from "core/utils/misc"
import AddressModal from "./AddressModal"
import { AddStudentAddress } from "domain/useCase/Student/Store/AddStudentAddress"
import { StoreRepositoryImpl } from "data/repository/Student/StoreRepositoryImpl"
import { StoreAPIDataSourceImpl } from "data/API/Student/StoreAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import Toast from "core/components/Toast"
import { UpdateStudentAddress } from "domain/useCase/Student/Store/UpdateStudentAddress"
import { DeleteStudentAddress } from "domain/useCase/Student/Store/DeleteStudentAddress"
import { PlaceOrder } from "domain/useCase/Student/Store/PlaceOrder"
import OrderConfirmedModal from "./OrderConfirmedModal"

export type Address = {
  flat: ""
  block: ""
  area: ""
  nearby_landmark: ""
  city: ""
  state: ""
  pincode: ""
  country: ""
  id?: ""
} | null

export default function ConfirmOrder() {
  const { auth, storeItems, userDetails, fetchStoreItems, handleGoBack, fetchStudentAllAddresses } = useStoreViewModel()

  const { item_id }: any = useParams()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const navigate = useNavigate()

  const [newAddress, setNewAddress] = React.useState<Address>({} as Address)
  const [selectedItem, setSelectedItem] = React.useState<any>(null)
  const [selectedAddress, setSelectedAddress] = React.useState<Address>(null)
  const [showMenu, setShowMenu] = React.useState(true)
  const [showAddressModal, setShowAddressModal] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)
  const [orderConfirmedModalOpen, setOrderConfirmedModalOpen] = React.useState(false)
  const [isConfirming, setIsConfirming] = React.useState(false)

  const addStudentAddressUseCase = new AddStudentAddress(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const updateStudentAddressUseCase = new UpdateStudentAddress(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const deleteStudentAddressUseCase = new DeleteStudentAddress(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const placeOrderUseCase = new PlaceOrder(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const getSelectedItemDetails = () => {
    const item = storeItems.find((item: any) => item.item_id === item_id)
    setSelectedItem(item)
  }

  const handleSelectAddress = (address: any) => {
    setSelectedAddress(address)
  }

  const handleChangeAddressForm = (e: any) => {
    const { name, value } = e.target
    if (!isEditing) setNewAddress((prev: any) => ({ ...prev, [name]: value }))
    else setSelectedAddress((prev: any) => ({ ...prev, [name]: value }))
  }

  const handleAddStudentAddress = async () => {
    const response = await addStudentAddressUseCase.invoke(auth, newAddress)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Error in adding address")
    } else {
      changeToastDetails(STR_SUCCESS, "Address added")
    }
    changeToastVisibility(true)

    setShowAddressModal(false)

    fetchStudentAllAddresses()
    setNewAddress({} as Address)
  }

  const handleDeleteAddress = async () => {
    const address_id = selectedAddress?.id
    const response = await deleteStudentAddressUseCase.invoke(auth, address_id)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Error in deleting address")
    } else {
      changeToastDetails(STR_SUCCESS, "Address deleted")
    }
    changeToastVisibility(true)

    fetchStudentAllAddresses()
    setSelectedAddress(null)
  }

  const handleUpdateAddress = async () => {
    const address = {
      flat: selectedAddress?.flat,
      block: selectedAddress?.block,
      area: selectedAddress?.area,
      nearby_landmark: selectedAddress?.nearby_landmark,
      city: selectedAddress?.city,
      state: selectedAddress?.state,
      pincode: selectedAddress?.pincode,
      country: selectedAddress?.country,
      address_id: selectedAddress?.id,
    }

    const response = await updateStudentAddressUseCase.invoke(auth, address)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Error in updating address")
    } else {
      changeToastDetails(STR_SUCCESS, "Address updated")
    }
    changeToastVisibility(true)

    setShowAddressModal(false)

    fetchStudentAllAddresses()
    setSelectedAddress(null)
  }

  const handleConfirmOrder = async () => {
    setIsConfirming(true)
    const response = await placeOrderUseCase.invoke(auth, item_id, selectedAddress?.id)
    setIsConfirming(false)

    if (!response?.success || !response?.data) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    setOrderConfirmedModalOpen(true)
  }

  const onConfirmOrderModalClose = () => {
    setOrderConfirmedModalOpen(false)
    navigate(-1)
  }

  React.useEffect(() => {
    fetchStoreItems()
    getSelectedItemDetails()
    fetchStudentAllAddresses()
  }, [])

  return (
    <>
      <AddressModal
        show={showAddressModal}
        onHide={() => setShowAddressModal(false)}
        editing={isEditing}
        address={isEditing ? selectedAddress : newAddress}
        onChange={handleChangeAddressForm}
        onSubmit={isEditing ? handleUpdateAddress : handleAddStudentAddress}
      />
      <OrderConfirmedModal
        item={{
          product: selectedItem,
          address: selectedAddress,
          timestamp: Date.now() / 1000,
        }}
        show={orderConfirmedModalOpen}
        onHide={onConfirmOrderModalClose}
      />
      <DashboardLayoutV2>
        <div className="space-y-6 p-6">
          <div className="flex justify-between">
            <div className="space-y-1">
              <h2 className="text-new-accent">Confirm Order</h2>
              <p>Finalize your purchase with a simple click - confirm your order now!</p>
            </div>
            <Button failure outlined className="h-fit w-fit" onClick={handleGoBack}>
              Back
            </Button>
          </div>
          <div className="flex gap-4 overflow-hidden rounded-sm border border-new-neutral-light p-8">
            <div className="h-[120px] w-[120px] rounded-sm bg-new-solid-white p-1">
              {selectedItem?.image_url && (
                <img
                  className="h-full w-full object-contain mix-blend-multiply"
                  src={selectedItem?.image_url}
                  alt="store item"
                />
              )}
            </div>
            <div className="flex flex-col gap-2 font-medium">
              <h4 className="text-new-accent">{selectedItem?.name}</h4>
              <span className="text-[10px] font-medium">Date of Purchase: 18th January, 2023</span>
              <div className="mt-auto flex items-center gap-2">
                <span>
                  <CoinIcon className="h-6 w-6" />
                </span>
                <span className="text-xl font-bold text-[#FBBF24]">{selectedItem?.coins}</span>
              </div>
            </div>
          </div>
          {Object.keys(userDetails).length > 0 ? (
            <>
              <div className="flex justify-between">
                <div className="space-y-2 text-sm text-new-neutral-light">
                  <div className="flex items-baseline gap-1">
                    <span>Name:</span>
                    <span className="text-new-neutral-dark">{userDetails?.name}</span>
                  </div>
                  <div className="flex items-baseline gap-1">
                    <span>Contact Number:</span>
                    <span className="text-new-neutral-dark">{userDetails?.phone}</span>
                  </div>
                  <div className="flex flex-col">
                    <span>Delivery Address:</span>
                    <span className="max-w-[400px] text-new-neutral-dark">
                      {selectedAddress ? convertToAddress(selectedAddress) : "NOT SELECTED"}
                    </span>
                  </div>
                </div>
                {showMenu && selectedAddress && (
                  <div className="w-[200px] space-y-2 rounded border p-4">
                    <div className="flex justify-between text-xs">
                      <span className="text-new-neutral-light">Menu</span>
                      <button onClick={() => setShowMenu(false)} className="text-new-neutral">
                        <CrossIcon className="h-4 w-4" pathClassName="fill-new-neutral" />
                      </button>
                    </div>
                    <Button
                      onClick={() => {
                        setShowAddressModal(true)
                        setIsEditing(true)
                      }}
                      outlined
                    >
                      Edit Address
                    </Button>
                    <Button outlined failure onClick={handleDeleteAddress}>
                      Delete Address
                    </Button>
                  </div>
                )}
              </div>
              <div className="overflow-hidden rounded-md border border-new-neutral-light">
                <div className="flex h-[200px] w-full overflow-x-auto">
                  <button
                    onClick={() => {
                      setShowAddressModal(true)
                      setIsEditing(false)
                    }}
                    className="flex h-full w-full min-w-[200px] flex-col items-center justify-center gap-2 border-r border-new-neutral-light"
                  >
                    <span className="">
                      <AddIcon className="h-6 w-6" />
                    </span>
                    <span className="">Add address</span>
                  </button>
                  {userDetails?.addresses?.map((address: any, index: number) => (
                    <div
                      key={address?.id}
                      className="flex h-full w-full min-w-[210px] flex-col gap-4 border-r border-new-neutral-light p-4 text-xs"
                    >
                      <div className="flex justify-between text-new-neutral-light">
                        <span className="">Address {index + 1}:</span>
                        <button className="">
                          <MoreIcon className="h-4 w-4" pathClassName="fill-new-neutral-light" />
                        </button>
                      </div>
                      <div className="text-new-neutral-dark">{convertToAddress(address)}</div>
                      <Button
                        outlined
                        onClick={() => handleSelectAddress(address)}
                        className="mt-auto text-base leading-[20px]"
                        selected={selectedAddress?.id === address?.id}
                      >
                        {selectedAddress?.id === address?.id ? "Selected" : "Select"}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
          <div className="flex items-center justify-between p-4 shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
            <div className="flex items-center gap-2 text-new-neutral">
              <span className="">
                <CartIcon className="h-4 w-4" />
              </span>
              <span className="text-sm">Product in cart :</span>
              <span className="text-lg font-semibold text-new-accent">1 QTY.</span>
            </div>
            <Button
              outlined
              disabled={!selectedAddress}
              onClick={() => handleConfirmOrder()}
              className="w-fit"
              loading={isConfirming}
            >
              Confirm Order
            </Button>
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </DashboardLayoutV2>
    </>
  )
}
