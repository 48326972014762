import { RedeemCoinsDataSource } from "data/dataSource/Admin/RedeemCoinsDataSource"
import { Auth } from "domain/model/Auth"
import { CreateCoupon, EditCoupon } from "domain/model/RedeemCoins"
import { RedeemCoinsRepository } from "domain/repository/Admin/RedeemCoinsRepository"

export class RedeemCoinsRepositoryImpl implements RedeemCoinsRepository {
  private dataSource: RedeemCoinsDataSource

  constructor(dataSource: RedeemCoinsDataSource) {
    this.dataSource = dataSource
  }

  async createCoupon(createCoupon: CreateCoupon): Promise<any> {
    return await this.dataSource.createCoupon(createCoupon)
  }

  async editCoupon(editCoupon: EditCoupon): Promise<any> {
    return await this.dataSource.editCoupon(editCoupon)
  }

  async deleteCoupon(auth: Auth, id: string): Promise<any> {
    return await this.dataSource.deleteCoupon(auth, id)
  }

  async getAllCoupons(auth: Auth): Promise<any> {
    return await this.dataSource.getAllCoupons(auth)
  }

  async allowRedeemCoins(auth: Auth, batches: Array<string>): Promise<any> {
    return await this.dataSource.allowRedeemCoins(auth, batches)
  }

  async getBatchesAllowedRedeemCoins(auth: Auth): Promise<any> {
    return await this.dataSource.getBatchesAllowedRedeemCoins(auth)
  }
}
