import Flag from "core/components/Flag"
import { ChevronDownIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import React from "react"

export default function Disclosure({ label, children }: any) {
  const [open, setOpen] = React.useState(false)

  return (
    <div className="rounded border border-new-neutral px-6 py-4">
      <div className="flex cursor-pointer items-center gap-4" onClick={() => setOpen(!open)}>
        <h4 className="text-new-accent">{label}</h4>
        <span className="ml-auto text-new-neutral-dark">
          <ChevronDownIcon className={cn("h-4 w-4", open && "rotate-180")} />
        </span>
      </div>
      <div className={cn("w-full", open ? "mt-4" : "hidden")}>{children}</div>
    </div>
  )
}
