import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface UpdateOnboardingMeetingDetailsUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UpdateOnboardingMeetingDetails implements UpdateOnboardingMeetingDetailsUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.updateOnboardingMeetingDetails(auth, data)
  }
}
