import { cn } from "core/lib/utils"

export default function DividerButton({ className, children, ...props }: any) {
  return (
    <button
      className={cn(
        "bg-new-solid-white rounded-sm px-4 py-2 text-sm font-semibold leading-4 text-new-accent before:absolute before:top-1/2 before:left-0 before:h-px before:w-full before:bg-new-editor-dark-50 before:-translate-y-1/2 before:-z-10",
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}
