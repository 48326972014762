import Feedback1 from "assets/svgs/feedback/Feedback1"
import Feedback2 from "assets/svgs/feedback/Feedback2"
import Feedback3 from "assets/svgs/feedback/Feedback3"
import Feedback4 from "assets/svgs/feedback/Feedback4"
import Feedback5 from "assets/svgs/feedback/Feedback5"
import Feedback6 from "assets/svgs/feedback/Feedback6"
import Feedback7 from "assets/svgs/feedback/Feedback7"
import Feedback8 from "assets/svgs/feedback/Feedback8"
import Feedback9 from "assets/svgs/feedback/Feedback9"
import Feedback10 from "assets/svgs/feedback/Feedback10"
import Toast from "core/components/Toast"
// import Button from "core/components/new/Button"
import { STR_FAILURE, STR_SUBMIT, STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import React, { useEffect } from "react"
import MonthlyFeedbackAPIDataSourceImpl from "data/API/Student/MonthlyFeedbackAPIDataSourceImpl"
import { SubmitMonthlyFeedback } from "domain/useCase/Student/MonthlyFeedback/SubmitMonthlyFeedback"
import { MonthlyFeedbackRepositoryImpl } from "data/repository/Student/MonthlyFeedbackRepositoryImpl"
import { useAuth } from "core/context/auth"
import { CrossIcon } from "core/constants/svgs"
import { useApp } from "core/context/app"
import { Button } from "core/components/v2/Button"
import { Loader } from "lucide-react"

export default function FeedbackModal({ status, onSubmit, setMonthlyFeedbackStatus }: any) {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { auth } = useAuth()
  const { student } = useApp()

  const [formVisible, setFormVisible] = React.useState(false)
  const [selectedEmojiIndex, setSelectedEmojiIndex] = React.useState<number | null>(null)
  const [response1, setResponse1] = React.useState("")
  const [response2, setResponse2] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [hoveredEmojiIndex, setHoveredEmojiIndex] = React.useState<number | null>(null)
  const [secretClose, setSecretClose] = React.useState<boolean>(false)

  const handleHover = (index: number) => {
    setHoveredEmojiIndex(index)
  }

  const handleLeave = () => {
    setHoveredEmojiIndex(null)
  }

  const submitMonthlyFeedbackUseCase = new SubmitMonthlyFeedback(
    new MonthlyFeedbackRepositoryImpl(new MonthlyFeedbackAPIDataSourceImpl())
  )

  async function handleSubmitFeedback(e: any) {
    e.preventDefault()

    // if (response1.length === 0 || response2.length === 0) {
    //   changeToastDetails(STR_FAILURE, "Kindly provide your feedback to help us improve")
    //   changeToastVisibility(true)
    //   return
    // }
    const lis = {
      batch: student?.batch,
      1: selectedEmojiIndex,
      2: response1,
      3: response2,
    }
    const data: any = {
      lis,
      name: student?.name,
    }

    setLoading(true)

    const response = await submitMonthlyFeedbackUseCase.invoke(auth, data)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Please submit the monthly feedback")
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Feedback Submitted Successfully!")
      changeToastVisibility(true)
    }

    onSubmit(response?.data)
  }

  const handleResponse1Change = (e: any) => {
    setResponse1(e.target.value)
  }

  const handleResponse2Change = (e: any) => {
    setResponse2(e.target.value)
  }

  const handleClick = (i: number) => {
    setSelectedEmojiIndex(i)
  }

  const handleNextClick = () => {
    if (!selectedEmojiIndex) {
      changeToastDetails(STR_FAILURE, "Please select the emotion")
      changeToastVisibility(true)
      return
    }

    setFormVisible(true)
  }

  const handlePrevClick = () => {
    setFormVisible(false)
  }

  const emojis = [
    <Feedback1
      selectedColor="#E02626"
      index={0}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 0 || hoveredEmojiIndex === 0}
    />,
    <Feedback2
      selectedColor="#E05326"
      index={1}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 1 || hoveredEmojiIndex === 1}
    />,
    <Feedback3
      selectedColor="#E09626"
      index={2}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 2 || hoveredEmojiIndex === 2}
    />,
    <Feedback4
      selectedColor="#E09626"
      index={3}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 3 || hoveredEmojiIndex === 3}
    />,
    <Feedback5
      selectedColor="#E0CE26"
      index={4}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 4 || hoveredEmojiIndex === 4}
    />,
    <Feedback6
      selectedColor="#DCE026"
      index={5}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 5 || hoveredEmojiIndex === 5}
    />,
    <Feedback7
      selectedColor="#B0E026"
      index={6}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 6 || hoveredEmojiIndex === 6}
    />,
    <Feedback8
      selectedColor="#8EE026"
      index={7}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 7 || hoveredEmojiIndex === 7}
    />,
    <Feedback9
      selectedColor="#62E026"
      index={8}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 8 || hoveredEmojiIndex === 8}
    />,
    <Feedback10
      selectedColor="#0DD746"
      index={9}
      onHover={handleHover}
      onLeave={handleLeave}
      onClick={handleClick}
      isSelected={selectedEmojiIndex === 9 || hoveredEmojiIndex === 9}
    />,
  ]

  const handleSecretCloseModal = () => {
    setMonthlyFeedbackStatus(false)
  }

  if (!status) return null

  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-sm bg-opacity-50"></div>
        </div>
        <div className="transform relative bg-new-solid-white rounded-lg h-[330px] w-[760px]">
          <button
            onClick={handleSecretCloseModal}
            className="cursor-default opacity-0 absolute bottom-0 text-new-solid-white left-0"
          >
            <CrossIcon className="w-4 h-4" />
          </button>
          {!formVisible ? (
            <div className="rounded-lg shadow h-[330px] pt-[24px] pl-[24px] pr-[24px] pb-[32px]">
              <div className="">
                <h1
                  className="text-2xl text-gray-600 leading-normal"
                  style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "600" }}
                >
                  Monthly Feedback
                </h1>

                <p className="text-sm font-normal leading-normal" style={{ fontFamily: "Inter" }}>
                  Choose an emotion that best reflects your experience with Bosscoder thus far.
                </p>
              </div>
              <div className="flex flex-col pb-8 font-medium text-new-neutral-dark h-[215px]">
                <div className="flex flex-col gap-4 py-6 w-[712px] h-[127px]">
                  <p className="text-sm font-medium text-gray-600 h-5 w-[711px]">
                    How likely is that you will recommend Bosscoder Academy to your friends?{" "}
                    <sup className="text-red-600">*</sup>
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div className="flex gap-2 w-[700px]">
                        {emojis.map((emoji, i) => {
                          const isHoveredOrSelected = selectedEmojiIndex === i || hoveredEmojiIndex === i
                          return (
                            <button onClick={() => handleClick(i)} className="flex flex-col items-center gap-2" key={i}>
                              <span>{emoji}</span>
                              <span
                                className="font-semibold text-[#D3D3D3]"
                                style={{ color: isHoveredOrSelected ? emoji.props.selectedColor : "gray" }}
                              >
                                {i < 9 ? `0${i + 1}` : i + 1}
                              </span>
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex justify-end mt-11">
                  <Button
                    className="w-32 rounded-[4px]"
                    onClick={handleNextClick}
                    disabled={selectedEmojiIndex === null}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col content-start pt-[24px] pl-[24px] pr-[24px] pb-[32px] ">
              <h1
                className="text-2xl text-gray-600 leading-normal"
                style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "600" }}
              >
                Monthly Feedback
              </h1>
              <p className="font-sans text-sm font-normal leading-5 text-[#6B6B6B] ">
                Kindly provide your feedback to assist us in enhancing our services.
              </p>
              <div className="mt-8 flex w-full">
                <div className="w-[50%] pr-4">
                  <div
                    className="text-sm font-medium text-gray-600 pb-[4px]"
                    style={{ fontFamily: "Inter", fontSize: "14px" }}
                  >
                    What do you like about the program? <span className="text-red-600">*</span>
                  </div>

                  <div className="text-primary-color-neutral text-xs">
                    <textarea
                      className="flex h-[115px] w-[342px] flex-shrink-0 resize-none content-start gap-0 rounded-lg bg-white p-4 border border-[#D3D3D3] focus:border-gray-400"
                      placeholder="Type your message here!"
                      onChange={handleResponse1Change}
                      value={response1}
                      required
                    />
                  </div>
                </div>
                <div className="w-[50%] pl-4">
                  <div
                    className="text-sm font-medium text-gray-600 pb-[4px]"
                    style={{ fontFamily: "Inter", fontSize: "14px" }}
                  >
                    What can be improved? <span className="text-red-600">*</span>
                  </div>
                  <div className="text-primary-color-neutral text-xs">
                    <textarea
                      className="flex h-[115px] w-[342px] flex-shrink-0 resize-none content-start gap-0 rounded-lg bg-white p-4 border border-[#D3D3D3] focus:border-gray-400"
                      placeholder="Type your message here!"
                      onChange={handleResponse2Change}
                      value={response2}
                      required
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="flex justify-end gap-4 px-8 pr-0">
                <Button
                  className="w-32 rounded-[4px] bg-[#FFF] text-gray-500 border border-gray-400 hover:bg-gray-400 hover:text-black "
                  onClick={handlePrevClick}
                >
                  Previous
                </Button>

                <Button className="w-32 rounded-[4px] bg-[#162456]" onClick={handleSubmitFeedback} disabled={loading}>
                  {!loading ? "Submit" : <Loader />}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
