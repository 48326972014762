export default function BarChart({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3335 8.66667H5.3335V14H1.3335V8.66667ZM10.6668 5.33333H14.6668V14H10.6668V5.33333ZM6.00016 2H10.0002V14H6.00016V2ZM2.66683 10V12.6667H4.00016V10H2.66683ZM7.3335 3.33333V12.6667H8.66683V3.33333H7.3335ZM12.0002 6.66667V12.6667H13.3335V6.66667H12.0002Z"
        fill="currentColor"
      />
    </svg>
  )
}
