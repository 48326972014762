import { API_V4_GET_MY_REFERRED_CANDIDATES, API_V4_REFER_CANDIDATE, API_V4_STUDENT } from "core/constants/strings"
import { Server, postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { referCandidate } from "domain/model/ReferandEarn"
import { ReferAndEarnDataSource } from "data/dataSource/Student/ReferAndEarnAPIDataSourceImpl"
import { ReferringDetails } from "domain/model/ReferringDetails"

const server = new Server()

export class ReferAndEarnAPIDataSourceImpl implements ReferAndEarnDataSource {
  async myReferredCandidates(request: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_MY_REFERRED_CANDIDATES), request)

    return response
  }
  async referCandidates(request: referCandidate): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, API_V4_REFER_CANDIDATE), request)
    return response
  }
  async getReferralData(request: Auth): Promise<any> {
    try {
      const response = await server.get(pn("student", "referral", "refer"), {
        Authorization: `Bearer ${request.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getReferrerStudentData(email: string): Promise<any> {
    try {
      const response = await server.get(pn("student", "referral", "get_referrer", email))
      return response
    } catch (error) {
      return error
    }
  }
  async deleteReferralFormData(email: string, auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn("student", "referral", "delete_referral", email), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async postReferralFormData(request: ReferringDetails, auth: Auth): Promise<any> {
    try {
      const response = await server.post(pn("student", "referral", "add_referral"), request, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async postBankFormData(request: ReferringDetails, auth: Auth): Promise<any> {
    try {
      const response = await server.post(pn("student", "referral", "add_bank"), request, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      console.log("this is the response 4:", response)
      return response
    } catch (error) {
      console.log("this is error 4", error)
      return error
    }
  }
  async editReferralFormData(request: ReferringDetails, auth: Auth): Promise<any> {
    try {
      const response = await server.post(pn("student", "referral", "edit_referral"), request, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      console.log("this is the response 4:", response)
      return response
    } catch (error) {
      console.log("this is error 4", error)
      return error
    }
  }
}
