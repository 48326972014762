import { useEffect } from "react"
import Spinner from "./Spinner"

export default function PageLoader() {
  return (
    <div className="h-screen w-full flex items-center justify-center">
      <Spinner />
    </div>
  )
}
