import { API_V4_ADMIN, API_V4_GET_DAILY_REPORT } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { GetDailyReport } from "domain/model/DailyReport"
import DailyReportDataSource from "data/dataSource/Admin/DailyReportDataSource"
import { RequestQuote } from "@mui/icons-material"

export class DailyReportAPIDataSourceImpl implements DailyReportDataSource {
  async getDailyReport(getDailyReport: GetDailyReport): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_DAILY_REPORT), getDailyReport)
      return response
    } catch (error) {
      return error
    }
  }
}
