import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import ModuleClassesDataSource from "data/dataSource/Admin/ModuleClassesDataSource"
import { CreateModuleClass, UpdateModuleClass } from "domain/model/ModuleClass"
import {
  API_CREATE_MODULE_CLASS,
  API_DELETE_MODULE_CLASS,
  API_GET_MODULES_CLASSES,
  API_UPDATE_MODULE_CLASS,
  API_V4_ADMIN,
} from "core/constants/strings"

export default class ModuleClassAPIDataSourceImpl implements ModuleClassesDataSource {
  async getModuleClass(auth: Auth, instructor_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MODULES_CLASSES), {
        id_token: auth?.id_token,
        instructor_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createModuleClass(createModuleClass: CreateModuleClass): Promise<any> {
    const id_token = createModuleClass["auth"]?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_MODULE_CLASS), {
        id_token,
        ...createModuleClass,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateModuleClass(updateModuleClass: UpdateModuleClass): Promise<any> {
    const id_token = updateModuleClass["auth"]?.id_token

    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_MODULE_CLASS), {
        id_token,
        ...updateModuleClass,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteModuleClass(auth: Auth, session_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_MODULE_CLASS), {
        id_token: auth?.id_token,
        session_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
