import React from "react"
import useToast from "core/hooks/useToast"
import useLocalStorage from "core/hooks/useLocalStorage"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useParams, useNavigate } from "react-router-dom"
import EditorAPIDataSourceImpl from "data/API/Student/EditorAPIDataSourceImpl"
import { EditorRepositoryImpl } from "data/repository/Student/EditorRepositoryImpl"
import { ImportTemplateCode } from "domain/useCase/Student/Editor/ImportTemplateCode"
import { GetQuestion } from "domain/useCase/Student/Editor/GetQuestion"
import { GetQuestionWithAnswer } from "domain/useCase/Student/Editor/GetQuestionWithAnswer"
import { GetSubCaseStudyQuestion } from "domain/useCase/Student/Editor/GetCaseStudySubjectiveQuestion"
import { GetSubmissions } from "domain/useCase/Student/Editor/GetSubmissions"
import { RunCode } from "domain/useCase/Student/Editor/RunCode"
import { CreateSubmission } from "domain/useCase/Student/Editor/CreateSubmission"
import { GetSubmission } from "domain/useCase/Student/Editor/GetSubmission"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import GetContestDetails from "domain/useCase/User/Contest/GetContestDetails"
import GetMcqQuestionDetails from "domain/useCase/User/Contest/GetMcqQuestionDetails"
import GetCodingQuestionDetails from "domain/useCase/User/Contest/GetCodingQuestionDetails"
import SubmitCodingQuestion from "domain/useCase/User/Contest/SubmitCodingQuestion"
import SubmitMcqQuestion from "domain/useCase/User/Contest/SubmitMcqQuestion"
import SubmitSubjectiveQuestions from "domain/useCase/User/Contest/SubmitSubjectiveQuestions"
import SubmitCasestudyQuestions from "domain/useCase/User/Contest/SubmitCasestudyQuetions"
import SubmitBatchMcqQuestion from "domain/useCase/User/Contest/SubmitBatchMcqQuestion"
import GetStudentContestDetails from "domain/useCase/User/Contest/GetStudentContestDetails"
import SubmitContest from "domain/useCase/User/Contest/SubmitContest"
import delay from "core/utils/delay"
import { useAuth } from "core/context/auth"
import { isEmpty } from "core/utils/misc"
import { genError } from "core/utils/string"
import MarkSubmitProctored from "domain/useCase/User/Contest/MarkSubmitProctored"
import getAllQuestions from "domain/useCase/User/Contest/GetAllQuestions"
import getAllMcqQuestions from "domain/useCase/User/Contest/GetAllMcqQuestions"

export default function EditorViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { contest_id } = useParams()
  const navigate = useNavigate()

  const { auth, refreshed } = useAuth()
  const [problemTemplates, setProblemTemplates] = useLocalStorage<any>("problems", {})
  const [language, setLanguage] = useLocalStorage<string>("language", "cpp")

  const [code, setCode] = React.useState<any>("")
  const [templates, setTemplates] = React.useState<any>({})
  const [question, setQuestion] = React.useState<any>({})
  const [questionWithAnswer, setQuestionWithAnswer] = React.useState<any>({})
  const [sizes, setSizes] = React.useState(["40%", "60%"])
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [isTemplateLoaded, setIsTemplateLoaded] = React.useState(false)
  const [isQuestionLoaded, setIsQuestionLoaded] = React.useState(false)
  const [submissions, setSubmissions] = React.useState<any>([])
  const [isCodeRunning, setIsCodeRunning] = React.useState(false)
  const [isCodeSubmitting, setIsCodeSubmitting] = React.useState(false)
  const [runCodeDetails, setRunCodeDetails] = React.useState<any>({})
  const [submitCodeDetails, setSubmitCodeDetails] = React.useState<any>({})
  const [isAllSubmissionsLoading, setIsAllSubmissionsLoading] = React.useState<boolean>(false)
  const [recEndTimestamp, setRecTimestamp] = React.useState<number>(0)
  const [contestData, setContestData] = React.useState<any>({})
  const [endTimestamp, setEndTimestamp] = React.useState<number>(0)
  const [codingProblems, setCodingProblems] = React.useState<any[]>([])
  const [mcqQuestions, setMcqQuestions] = React.useState<any[]>([])
  const [activeMcqQuestionIndex, setActiveMcqQuestionIndex] = React.useState<any>(0)
  const [activeMcqQuestionDetails, setActiveMcqQuestionDetails] = React.useState<any>({})
  const [activeCodingTabIndex, setActiveCodingTabIndex] = React.useState<any>(0)
  const [activeCodingProblemIndex, setActiveCodingProblemIndex] = React.useState<any>(0)
  const [, setActiveCodingProblemDetails] = React.useState<any>({})
  const [isFetchingActiveMcqQuestion, setIsFetchingActiveMcqQuestion] = React.useState<boolean>(false)
  const [showRunCodeDetails, setShowRunCodeDetails] = React.useState<boolean>(false)
  const [resetingTemplate, setResetingTemplate] = React.useState<boolean>(false)
  const [isMcqSubmittingWithId, setIsMcqSubmittingWithId] = React.useState<any>(-1)
  const [batchMcqSolution, setBatchMcqSolution] = React.useState<any>({})
  const [isAllMcqSubmitting, setIsAllMcqSubmitting] = React.useState<boolean>(false)
  const [exitPopupOpen, setExitPopupOpen] = React.useState<boolean>(false)
  const [recordedContest, setRecordedContest] = React.useState<boolean>(false)
  const [studentContestDetails, setStudentContestDetails] = React.useState<any>({})
  const [codingProblemSolution, setCodingProblemSolution] = React.useState<any>([])
  const [subjectiveQuestions, setSubjectiveQuestions] = React.useState<any[]>([])
  const [submitCodingQuestion, setSubmitCodingQuestion] = React.useState<boolean>(false)
  const [casestudyQuestions, setCasestudyQuestions] = React.useState<any[]>([])
  const [subjectiveQuestion, setSubjectiveQuestion] = React.useState<any>("")
  const [casestudyQuestion, setCasestudyQuestion] = React.useState<any>("")
  const [activeSubjectiveQuestionIndex, setActiveSubjectiveQuestionIndex] = React.useState<number>(0)
  const [activeCasestudyQuestionIndex, setActiveCasestudyQuestionIndex] = React.useState<number>(0)
  const [submittedDueToProctoring, setSubmiitedDueToProctoring] = React.useState(false)
  const [activeCasestudyResponseIndex, setActiveCasestudyResponseIndex] = React.useState<number>(0)
  const [subjectiveQuestionSolutions, setSubjectiveQuestionSolutions] = React.useState<any[]>([])
  const [casestudyQuestionSolutions, setCasestudyQuestionSolutions] = React.useState<any[]>([])
  const [isFetchingSubjectiveQuestion, setIsFetchingSubjectiveQuestion] = React.useState<boolean>(false)
  const [isFetchingCasestudyQuestion, setIsFetchingCasestudyQuestion] = React.useState<boolean>(false)
  const [isSubmittingSubjectiveQuestion, setIsSubmittingSubjectiveQuestion] = React.useState<boolean>(false)
  const [isSubmittingCasestudyQuestion, setIsSubmittingCasestudyQuestion] = React.useState<boolean>(false)
  const [isPast, setIsPast] = React.useState<boolean>(false)
  const [reattemptedContest, setReattemptedContest] = React.useState<boolean>(false)
  const [isApproved, setIsApproved] = React.useState<boolean>(false)
  const [isContestSubmitting, setIsContestSubmitting] = React.useState<boolean>(false)
  const [id, setId] = React.useState<any>(null)
  const [blurScreen, setBlurScreen] = React.useState(true)
  const [flag, setFlag] = React.useState<number>(-1)
  const [questionsLocally, setQuestionsLocally] = useLocalStorage<any>("coding_questions", [])
  const [mcqQuestionsLocally, setMcqQuestionsLocally] = useLocalStorage<any>("mcq_questions", [])

  const getContestDetailsUseCase = new GetContestDetails(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const importTemplateCodeUseCase = new ImportTemplateCode(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getQuestionUseCase = new GetQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getQuestionWithAnswerUseCase = new GetQuestionWithAnswer(
    new EditorRepositoryImpl(new EditorAPIDataSourceImpl())
  )

  const getSubCaseStudyUseCase = new GetSubCaseStudyQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getSubmissionsUseCase = new GetSubmissions(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const runCodeUseCase = new RunCode(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const createSubmissionUseCase = new CreateSubmission(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getSubmissionUseCase = new GetSubmission(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getMcqQuestionDetailsUseCase = new GetMcqQuestionDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const getCodingQuestionDetailsUseCase = new GetCodingQuestionDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const getAllQuestionsUseCase = new getAllQuestions(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const getAllMcqQuestionsUseCase = new getAllMcqQuestions(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const submitCodingQuestionUseCase = new SubmitCodingQuestion(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const submitMcqQuestionUseCase = new SubmitMcqQuestion(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const submitSubjectiveQuestionsUseCase = new SubmitSubjectiveQuestions(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const submitCasestudyQuestionsUseCase = new SubmitCasestudyQuestions(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const submitBatchMcqQuestionUseCase = new SubmitBatchMcqQuestion(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const getStudentContestDetailsUseCase = new GetStudentContestDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const submitContestUseCase = new SubmitContest(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const markSubmitProctoredUseCase = new MarkSubmitProctored(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const fetchDefaultTemplateCode = async (type = "") => {
    if (type === "reset") setResetingTemplate(true)

    const response = await importTemplateCodeUseCase.invoke(id.trim(), auth)

    if (type === "reset") setResetingTemplate(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching template code"))
      return null
    }

    setCode(response?.data[language])
    setTemplates(response?.data)
    setProblemTemplates({
      ...problemTemplates,
      ...(!!id ? { [id]: response?.data } : {}),
    })

    return response
  }

  const handleActiveTabChange = () => {
    setActiveCasestudyQuestionIndex((prev: any) => (typeof prev === "number" ? prev + 1 : 0))
  }

  const fetchTemplateCode = async () => {
    if (problemTemplates.hasOwnProperty(id) && Object.keys(problemTemplates[id]).length > 0) {
      setCode(problemTemplates[id][language])
      setTemplates(problemTemplates[id])
      setIsTemplateLoaded(true)
      return
    }

    setIsTemplateLoaded(false)
    const response = await fetchDefaultTemplateCode()
    setIsTemplateLoaded(true)

    setCode(response?.data[language])
    setTemplates(response?.data)
    setProblemTemplates({
      ...problemTemplates,
      ...(!!id ? { [id]: response?.data } : {}),
    })
  }

  const fetchQuestion = async () => {
    setIsQuestionLoaded(false)
    // const response = await getQuestionUseCase.invoke(auth, id.trim(), true)

    setIsQuestionLoaded(true)

    // if (!response?.success) {
    //   changeToastVisibility(true)
    //   changeToastDetails(STR_FAILURE, genError(response, "Error in fetching question"))
    //   return
    // }
  }

  const fetchQuestionWithAnswer = async () => {
    setIsQuestionLoaded(false)
    const response = await getQuestionWithAnswerUseCase.invoke(auth, id.trim(), true)

    setIsQuestionLoaded(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching question"))
      return
    }

    setQuestionWithAnswer(response?.data)
  }

  const fetchSubmissions = async (topic: any) => {
    setIsAllSubmissionsLoading(true)

    const response = await getSubmissionsUseCase.invoke(auth, topic)

    setIsAllSubmissionsLoading(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching submissions"))
      return
    }
    const contestStartTime = new Date(contestData?.start_time * 1000)
    const contestEndTime = new Date(contestStartTime.getTime() + contestData?.duration * 60 * 1000)
    if (reattemptedContest) {
      setSubmissions(response?.data?.filter((submission: any) => submission?.total_testcase !== -1)?.reverse())
    } else {
      setSubmissions(
        response?.data
          ?.filter((submission: any) => {
            const submissionTime = new Date(submission?.time)
            return (
              submission?.total_testcase !== -1 && submissionTime > contestStartTime && submissionTime <= contestEndTime
            )
          })
          ?.reverse()
      )

      setFlag((f) => f + 1)
    }
    setFlag((f) => f + 1)
  }

  const fetchStudentContestDetails = async () => {
    // const response = await getStudentContestDetailsUseCase.invoke(auth, (contest_id as string) + "recorded")

    // if (!response?.success) {
    //   changeToastVisibility(true)
    //   changeToastDetails(STR_FAILURE, genError(response, "Error in fetching student contest details"))
    //   return
    // }
    let retryCount = 0 // Counter for retry attempts
    const MAX_RETRY = 1 // Maximum retry attempts
    const makeApiCall = async (): Promise<void> => {
      // Perform the API call
      const response = await getStudentContestDetailsUseCase.invoke(auth, (contest_id as string) + "recorded")

      // If the call is unsuccessful, retry once and show toast only on the second failure
      if (!response?.success) {
        if (retryCount < MAX_RETRY) {
          retryCount++ // Increment retry counter
          return await makeApiCall() // Retry the API call
        } else {
          // If the retry also fails, show the toast and return
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, genError(response, "Error in fetching student contest details"))
          return
        }
      }

      if (response?.data?.["rec_contest_start_timestamp"]) {
        setReattemptedContest(true)
      }
      if (response?.data?.submitted === true && !response?.data?.["rec_contest_start_timestamp"]) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "This contest has already been submitted")
        await delay(2000)
        navigate("/weekly-test-series")
      }
      const recorded_contest_check = "rec_contest_start_timestamp" in response?.data
      const batchMcqStatus: any = {}
      for (const mcqKey of Object.keys(response?.data?.mcq_question_status)) {
        if (recorded_contest_check) {
          batchMcqStatus[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.rec_user_solution
        } else {
          batchMcqStatus[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.user_solution
        }
      }

      const codingProblemSolutionData: any = {}
      for (const problemKey of Object.keys(response?.data?.coding_question_status)) {
        if (recorded_contest_check) {
          codingProblemSolutionData[problemKey] = response?.data?.coding_question_status[problemKey]?.rec_status
        } else {
          codingProblemSolutionData[problemKey] = response?.data?.coding_question_status[problemKey]?.status
        }
      }
      const subjectiveProblemSolutionData: any = {}
      const _subjectiveProblemSolutionData: any = []

      for (const problemKey of Object.keys(response?.data?.subjective_question_status)) {
        if (recorded_contest_check) {
          subjectiveProblemSolutionData[problemKey] =
            response?.data?.subjective_question_status?.[problemKey]?.rec_user_solution
        } else {
          subjectiveProblemSolutionData[problemKey] =
            response?.data?.subjective_question_status[problemKey]?.user_solution
        }
      }

      for (let index = 0; index < subjectiveQuestions.length; index++) {
        const key = subjectiveQuestions[index]?.key
        _subjectiveProblemSolutionData[index] = subjectiveProblemSolutionData[key]
      }

      const casestudyProblemSolutionData: any = {}
      const _casestudyProblemSolutionData: any = []

      for (const problemKey of Object.keys(response?.data?.casestudy_question_status)) {
        if (recorded_contest_check) {
          casestudyProblemSolutionData[problemKey] =
            response?.data?.casestudy_question_status[problemKey]?.rec_user_solution
        } else {
          casestudyProblemSolutionData[problemKey] =
            response?.data?.casestudy_question_status[problemKey]?.user_solution
        }
      }

      for (let index = 0; index < casestudyQuestions.length; index++) {
        const key = casestudyQuestions[index]?.key
        _casestudyProblemSolutionData[index] = casestudyProblemSolutionData[key]
      }
      if (recorded_contest_check) {
        const endTimestamp =
          (parseInt(response?.data?.rec_contest_start_timestamp) + parseInt(response?.data?.duration) * 60) * 1000
        setEndTimestamp(endTimestamp)
        setRecTimestamp(endTimestamp)
      }

      setBatchMcqSolution(batchMcqStatus)
      setCodingProblemSolution(codingProblemSolutionData)
      setSubjectiveQuestionSolutions(_subjectiveProblemSolutionData)
      setCasestudyQuestionSolutions(_casestudyProblemSolutionData)
      setStudentContestDetails(response?.data)
    }
    await makeApiCall()
  }

  const fetchContestDetails = async () => {
    const response = await getContestDetailsUseCase.invoke(auth, contest_id as string)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching contest details"))
      return
    }

    const contest = response?.data
    setContestData(contest)
    let codingQuestions = contest?.question_lis || []
    let subjectiveQuestions = contest?.subjective_lis || []
    let casestudyQuestions = contest?.casestudy_lis || []
    let mcqQuestions = contest?.mcq_lis || []
    setCodingProblems(codingQuestions)
    setSubjectiveQuestions(subjectiveQuestions)
    setCasestudyQuestions(casestudyQuestions)
    setMcqQuestions(mcqQuestions)

    setId(contest?.question_lis?.[activeCodingProblemIndex]?.key)

    const currentTimestamp = Date.now()
    const endTimestamp = (parseInt(contest?.start_time) + parseInt(contest?.duration) * 60) * 1000
    if (endTimestamp >= currentTimestamp) {
      setEndTimestamp(endTimestamp)
    }
    if (currentTimestamp > endTimestamp) {
      setRecordedContest(true)
    }

    const startTimestamp = parseInt(contest?.start_time) * 1000
    const duration = parseInt(contest?.duration) * 60
    const endTimestamps = startTimestamp + duration * 1000
    setIsPast(currentTimestamp > endTimestamps)
  }
  const fetchAllMcqQuestions = async () => {
    // setIsFetchingActiveMcqQuestion(true)
    const response = await getAllMcqQuestionsUseCase.invoke(auth, contest_id)
    // setIsFetchingActiveMcqQuestion(false)
    console.log(response?.data, "ye hai response")
    setMcqQuestionsLocally(response?.data)
    setActiveMcqQuestionDetails(response?.data?.[0])

    // if (!response?.success) {
    //   changeToastVisibility(true)
    //   changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
    //   return
    // }

    // setActiveMcqQuestionDetails(response?.data)
  }
  const fetchMcqQuestionDetails = async () => {
    setIsFetchingActiveMcqQuestion(true)
    const response = await getMcqQuestionDetailsUseCase.invoke(auth, mcqQuestions[activeMcqQuestionIndex]?.key)
    setIsFetchingActiveMcqQuestion(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
      return
    }

    setActiveMcqQuestionDetails(response?.data)
  }

  const fetchAllQuestions = async () => {
    // setIsFetchingActiveMcqQuestion(true)
    const response = await getAllQuestionsUseCase.invoke(auth, contest_id)
    // setIsFetchingActiveMcqQuestion(false)
    console.log(response?.data, "ye hai response")
    setQuestionsLocally(response?.data)
    setQuestion(response?.data?.[0])

    // if (!response?.success) {
    //   changeToastVisibility(true)
    //   changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
    //   return
    // }

    // setActiveMcqQuestionDetails(response?.data)
  }

  const fetchCodingProblemDetails = async () => {
    const response = await getCodingQuestionDetailsUseCase.invoke(auth, codingProblems[activeCodingProblemIndex]?.key)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
      return
    }

    setActiveCodingProblemDetails(response?.data)
  }

  const fetchSubjectiveProblemDetails = async () => {
    let id = subjectiveQuestions?.[activeSubjectiveQuestionIndex]?.key
    setIsFetchingSubjectiveQuestion(true)
    const response = await getSubCaseStudyUseCase.invoke(auth, id)
    setIsFetchingSubjectiveQuestion(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching Subjective question details"))
      return
    }

    setSubjectiveQuestion(response?.data)
  }

  const fetchCasestudyProblemDetails = async () => {
    let id = casestudyQuestions?.[activeCasestudyQuestionIndex]?.key
    setIsFetchingCasestudyQuestion(true)
    const response = await getSubCaseStudyUseCase.invoke(auth, id)
    setIsFetchingCasestudyQuestion(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching Casestudy question details"))
      return
    }

    setCasestudyQuestion(response?.data)
  }

  const fetchProblemData = () => {
    fetchQuestion()
    fetchTemplateCode()
  }

  const checkForContest = async () => {
    const currentTimestamp = Date.now()
    const startTimestamp = parseInt(contestData?.start_time) * 1000
    if (
      "rec_contest_end_timestamp" in contestData &&
      currentTimestamp > contestData["rec_contest_end_timestamp"] * 1000
    ) {
      changeToastVisibility(true)
      changeToastDetails(
        STR_FAILURE,
        currentTimestamp < contestData["rec_contest_end_timestamp"]
          ? "Contest has not started yet"
          : "Contest has ended"
      )

      await delay(2000)
      navigate(`/contests/${contest_id}`)

      return
    } else if (
      "rec_contest_start_timestamp" in contestData &&
      currentTimestamp >= contestData["rec_contest_start_timestamp"]
    ) {
      setBlurScreen(false)
    } else if (
      (currentTimestamp < startTimestamp || (endTimestamp < currentTimestamp && contestData?.type === "live")) &&
      !("rec_contest_start_timestamp" in contestData)
    ) {
      changeToastVisibility(true)
      changeToastDetails(
        STR_FAILURE,
        currentTimestamp < startTimestamp ? "Contest has not started yet" : "Contest has ended"
      )

      await delay(2000)
      navigate(`/contests/${contest_id}`)

      return
    }

    setBlurScreen(false)
  }

  const handleGetSubmission = async (token: string, callback?: any) => {
    const response = await getSubmissionUseCase.invoke(auth, token)

    if (
      response?.description !== "In Queue" &&
      response?.description !== "Processing" &&
      typeof callback === "function"
    ) {
      callback()

      const allTCPassed =
        !isEmpty(response?.stdout) &&
        response?.stdout?.length > 0 &&
        response?.stdout?.every((item: any) => item?.result === 1)

      const status = allTCPassed ? "AC" : "WA"

      setCodingProblemSolution((prev: any) => ({
        ...prev,
        [id as string]: status,
      }))

      setIsCodeRunning(false)
      setIsCodeSubmitting(false)
    }

    return response
  }

  const handleRunCode = async (language: string, code: string, code_folder: string) => {
    setIsCodeRunning(true)

    const response = await runCodeUseCase.invoke(auth, {
      language,
      code,
      code_folder,
    })
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error running code. Please try again after 2-3 seconds.")
      changeToastVisibility(true)
      setIsCodeRunning(false)
      return
    }

    let submissionResponse = await handleGetSubmission(response?.data?.token)

    if (!submissionResponse?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
      changeToastVisibility(true)
      setIsCodeRunning(false)
      return
    }

    setRunCodeDetails(submissionResponse?.data)

    handleOpenRunCodeDetails()

    setTimeout(async () => {
      const interval = setInterval(async () => {
        submissionResponse = await handleGetSubmission(response?.data?.token, () => clearInterval(interval))

        if (!submissionResponse?.success) {
          clearInterval(interval)
          changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
          changeToastVisibility(true)
          setIsCodeRunning(false)
          return
        }

        setRunCodeDetails(submissionResponse?.data)
      }, 2000)
    }, 2000)
  }

  const handleSubmitCode = async (language: string, code: string, code_folder: string) => {
    setIsCodeSubmitting(true)
    handleCodingTabChange(1)
    setSubmitCodeDetails({})

    const response = await createSubmissionUseCase.invoke(auth, {
      language,
      code,
      code_folder,
    })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Unstable Internet Connection. Please refresh and try again after 2-3 seconds")
      changeToastVisibility(true)
      setIsCodeSubmitting(false)
      return
    }

    let submissionResponse = await handleGetSubmission(response?.data?.token)

    if (!submissionResponse?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
      changeToastVisibility(true)
      setIsCodeSubmitting(false)
      return
    }

    setSubmitCodeDetails(submissionResponse?.data)

    setTimeout(() => {
      const interval = setInterval(async () => {
        submissionResponse = await handleGetSubmission(response?.data?.token, () => clearInterval(interval))

        if (!submissionResponse?.success) {
          clearInterval(interval)
          changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
          changeToastVisibility(true)
          setIsCodeSubmitting(false)
          return
        }

        setSubmitCodeDetails(submissionResponse?.data)
      }, 2000)
    }, 2000)
  }

  const handleSubmitCodingQuestion = async () => {
    const response = await submitCodingQuestionUseCase.invoke(auth, contest_id as string, id, "")

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in submitting coding question"))
      return
    }
    setSubmitCodingQuestion(!submitCodingQuestion)
  }

  const handleSubmitMcqQuestion = async (option: any) => {
    setIsMcqSubmittingWithId(option)

    const response = await submitMcqQuestionUseCase.invoke(
      auth,
      contest_id as string,
      mcqQuestions[activeMcqQuestionIndex]?.key,
      String(option + 1)
    )

    setIsMcqSubmittingWithId(-1)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in submitting mcq question"))
      return
    }

    handleChangeBatchMcqSolution(mcqQuestions[activeMcqQuestionIndex]?.key, option)
  }

  const handleSubmitBatchMcqQuestion = async () => {
    setIsAllMcqSubmitting(true)

    const response = await submitBatchMcqQuestionUseCase.invoke(auth, contest_id as string, batchMcqSolution)

    setIsAllMcqSubmitting(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in submitting all mcq questions"))
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "All MCQ questions submitted successfully")
  }

  const handleSubmitContest = async (dueToProctoring?: any) => {
    setIsContestSubmitting(true)

    const response = await submitContestUseCase.invoke(auth, contest_id as string)

    setIsContestSubmitting(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in submitting contest"))
      return
    }
    if (dueToProctoring === true) {
      setSubmiitedDueToProctoring(true)
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Contest submitted successfully")

    await delay(2000)
    window.location.href = `/weekly-test-series`
  }

  const markSubmitProctored = async () => {
    const response = await markSubmitProctoredUseCase.invoke(auth, contest_id as any)

    await delay(2000)
  }

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index)
  }

  const handleCodingTabChange = (index: number) => {
    setActiveCodingTabIndex(index)
  }

  const handleCaseStudyTabChange = (index: number) => {
    setActiveCasestudyQuestionIndex(index)
  }

  const handleCodingProblemChange = (index: number) => {
    setActiveCodingProblemIndex(index)
    // setQuestion(questionsLocally?.[index])
    setActiveCodingTabIndex(0)
    setId(codingProblems[index]?.key)
  }

  const handleSizeChange = (newSizes: string[]) => {
    setSizes(newSizes)
  }

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value)
  }

  const handleCodeChange = (code: string | undefined) => {
    setCode(code)
    setTemplates((templates: any) => ({
      ...templates,
      [language]: code,
    }))
    setProblemTemplates((problemTemplates: any) => ({
      ...problemTemplates,
      ...(!!id
        ? {
            [id]: {
              ...problemTemplates[id],
              [language]: code,
            },
          }
        : {}),
    }))
  }

  const handleActiveMcqQuestion = (index: number) => {
    setActiveMcqQuestionIndex(index)
  }

  const handleNextMcqQuestion = () => {
    if (activeMcqQuestionIndex + 1 < mcqQuestions.length) {
      setActiveMcqQuestionIndex(activeMcqQuestionIndex + 1)
    }
  }

  const handlePreviousMcqQuestion = () => {
    if (activeMcqQuestionIndex - 1 >= 0) {
      setActiveMcqQuestionIndex(activeMcqQuestionIndex - 1)
    }
  }

  const handleNextCodingProblem = () => {
    if (activeCodingProblemIndex + 1 < codingProblems.length) {
      setActiveCodingProblemIndex(activeCodingProblemIndex + 1)
      setId(codingProblems[activeCodingProblemIndex + 1]?.key)
    }
  }

  const handlePreviousCodingProblem = () => {
    if (activeCodingProblemIndex - 1 >= 0) {
      setActiveCodingProblemIndex(activeCodingProblemIndex - 1)
      setId(codingProblems[activeCodingProblemIndex - 1]?.key)
    }
  }

  const handleOpenRunCodeDetails = () => {
    setShowRunCodeDetails(true)
  }

  const handleCloseRunCodeDetails = () => {
    setShowRunCodeDetails(false)
  }

  const handleChangeBatchMcqSolution = (key: number, option: any) => {
    setBatchMcqSolution((batchMcqSolution: any) => ({
      ...batchMcqSolution,
      [key]: String(option + 1),
    }))
  }

  const handleClosePopup = () => {
    setExitPopupOpen(false)
  }

  const handleOpenPopup = () => {
    setExitPopupOpen(true)
  }

  const handleSubjectiveProblemChange = (id: any) => {
    setActiveSubjectiveQuestionIndex(parseInt(id))
  }

  const handleCasestudyProblemChange = (id: any) => {
    setActiveCasestudyQuestionIndex(parseInt(id))
    setActiveCasestudyResponseIndex(0)
  }

  const handleCasestudyProblemResponseChange = (id: any) => {
    setActiveCasestudyResponseIndex(parseInt(id))
  }

  const handleSubjectiveQuestionSolutionChange = (e: any) => {
    setSubjectiveQuestionSolutions((prevSolutions: any[]) => {
      const newSolution = [...prevSolutions]
      newSolution[activeSubjectiveQuestionIndex] = e.target.value
      return newSolution
    })
  }

  const handleCasestudyQuestionSolutionChange = (e: any, type: string, questionId: string) => {
    if (type === "mcq") {
      const optionId = parseInt(e.target.value)
      setCasestudyQuestionSolutions((prevSolutions: any[]) => {
        const newSolution = [...prevSolutions]
        newSolution[activeCasestudyQuestionIndex] = {
          ...newSolution[activeCasestudyQuestionIndex],
          [questionId]: {
            ...newSolution[activeCasestudyQuestionIndex]?.[questionId],
            [optionId]: e.target.checked,
          },
        }
        return newSolution
      })
    } else {
      setCasestudyQuestionSolutions((prevSolutions: any[]) => {
        const newSolution = [...prevSolutions]
        newSolution[activeCasestudyQuestionIndex] = {
          ...newSolution[activeCasestudyQuestionIndex],
          [questionId]: e.target.value,
        }
        return newSolution
      })
    }
  }

  const handleSubmitBatchSubjectiveQuestion = async () => {
    const solObj: any = {}

    for (let index = 0; index < subjectiveQuestions.length; index++) {
      solObj[subjectiveQuestions[index]?.key] = subjectiveQuestionSolutions?.[index] || ""
    }

    setIsSubmittingSubjectiveQuestion(true)
    const response = await submitSubjectiveQuestionsUseCase.invoke(auth, contest_id as string, solObj)
    setIsSubmittingSubjectiveQuestion(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in submitting Subjective question"))
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Subjective Questions submitted successfully")
  }

  const handleSubmitBatchCasestudyQuestion = async () => {
    const solObj: any = {}

    for (let index = 0; index < casestudyQuestions.length; index++) {
      solObj[casestudyQuestions[index]?.key] = casestudyQuestionSolutions?.[index] || ""
    }

    setIsSubmittingCasestudyQuestion(true)
    const response = await submitCasestudyQuestionsUseCase.invoke(auth, contest_id as string, solObj)
    setIsSubmittingCasestudyQuestion(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in submitting Casestudy question"))
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Casestudy Questions submitted successfully")
  }

  return {
    id,
    code,
    toast,
    sizes,
    templates,
    question,
    questionWithAnswer,
    language,
    isPast,
    isApproved,
    submissions,
    isCodeRunning,
    activeTabIndex,
    runCodeDetails,
    submitCodeDetails,
    isTemplateLoaded,
    isQuestionLoaded,
    isCodeSubmitting,
    isAllSubmissionsLoading,
    contestData,
    codingProblems,
    mcqQuestions,
    activeMcqQuestionIndex,
    activeMcqQuestionDetails,
    activeCodingTabIndex,
    isFetchingActiveMcqQuestion,
    showRunCodeDetails,
    resetingTemplate,
    isMcqSubmittingWithId,
    batchMcqSolution,
    isAllMcqSubmitting,
    exitPopupOpen,
    studentContestDetails,
    codingProblemSolution,
    isContestSubmitting,
    refreshed,
    blurScreen,
    endTimestamp,
    flag,
    activeCodingProblemIndex,
    activeSubjectiveQuestionIndex,
    activeCasestudyQuestionIndex,
    activeCasestudyResponseIndex,
    subjectiveQuestions,
    casestudyQuestions,
    subjectiveQuestion,
    casestudyQuestion,
    isFetchingSubjectiveQuestion,
    isFetchingCasestudyQuestion,
    subjectiveQuestionSolutions,
    casestudyQuestionSolutions,
    isSubmittingSubjectiveQuestion,
    isSubmittingCasestudyQuestion,
    recordedContest,
    recEndTimestamp,
    submitCodingQuestion,
    fetchAllQuestions,
    changeToastDetails,
    checkForContest,
    handleSubmitContest,
    handleOpenPopup,
    fetchStudentContestDetails,
    handleClosePopup,
    handleSubmitBatchMcqQuestion,
    handleSubmitMcqQuestion,
    fetchDefaultTemplateCode,
    handleOpenRunCodeDetails,
    handleCloseRunCodeDetails,
    handleNextMcqQuestion,
    handlePreviousMcqQuestion,
    handleNextCodingProblem,
    handlePreviousCodingProblem,
    fetchCodingProblemDetails,
    handleCodingProblemChange,
    handleCodingTabChange,
    handleCaseStudyTabChange,
    fetchMcqQuestionDetails,
    fetchAllMcqQuestions,
    handleActiveMcqQuestion,
    fetchContestDetails,
    handleLanguageChange,
    handleTabChange,
    handleSizeChange,
    handleActiveTabChange,
    handleCodeChange,
    fetchProblemData,
    fetchQuestionWithAnswer,
    handleRunCode,
    handleSubmitCode,
    fetchSubmissions,
    changeToastVisibility,
    handleSubmitCodingQuestion,
    setActiveTabIndex,
    setActiveSubjectiveQuestionIndex,
    setActiveCasestudyQuestionIndex,
    handleCasestudyProblemResponseChange,
    fetchSubjectiveProblemDetails,
    fetchCasestudyProblemDetails,
    handleSubjectiveProblemChange,
    handleCasestudyProblemChange,
    handleSubjectiveQuestionSolutionChange,
    handleCasestudyQuestionSolutionChange,
    handleSubmitBatchSubjectiveQuestion,
    handleSubmitBatchCasestudyQuestion,
    markSubmitProctored,
    setQuestion,
    questionsLocally,
    setIsCodeRunning,
    setIsCodeSubmitting,
    reattemptedContest,
    STR_FAILURE,
    submittedDueToProctoring,
    setMcqQuestions,
    mcqQuestionsLocally,
    setActiveMcqQuestionDetails,
  }
}
