import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetDoubtRaisedStatsUseCase {
  invoke(data: any): Promise<any>
}

export class GetDoubtRaisedStats implements GetDoubtRaisedStatsUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(data: any) {
    return this.repository.getDoubtRaisedStats(data)
  }
}
