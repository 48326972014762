import { deleteJob } from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface DeleteJobUseCase {
  invoke(request: deleteJob): Promise<any>
}

export class DeleteJobs implements DeleteJobUseCase {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: deleteJob): Promise<any> {
    return await this.repository.deleteJob(request)
  }
}
