import Button from "core/components/Button"
import Input from "core/components/Input"
import { STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"
import ViewWeeklyTestTable from "./ViewWeeklyTestTable"

export default function CreateTest(props: any) {
  return (
    <div>
      <div className="flex justify-center py-[50px]">
        <div className="round-2 flex w-2/4 flex-col items-center p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
          <form className="mb-4 w-full" id="main-form" onSubmit={props.createWeeklyTests}>
            <Input
              name="testName"
              type={STR_TEXT_FIELD}
              value={props.createTest.testName}
              onChange={props.handleChange}
              placeholder="Test Name"
              className="mb-4"
              required
            />
            <Input
              name="testUrl"
              type={STR_TEXT_FIELD}
              value={props.createTest.testUrl}
              onChange={props.handleChange}
              placeholder="Test Url"
              className="mb-4"
              required
            />
            <Button>Create Test</Button>
          </form>
        </div>
      </div>

      <ViewWeeklyTestTable
        tableHeaders={props.tableHeaders}
        weeklyTest={props.weeklyTest}
        deleteWeeklyTests={props.deleteWeeklyTests}
      />
    </div>
  )
}
