import BatchMonthlyReportDataSource from "data/dataSource/Admin/BatchMonthlyReportDataSource"
import GetBatchMonthlyReport from "domain/model/GetBatchMonthlyReport"
import BatchMonthlyReportRepositry from "domain/repository/Admin/BatchMonthlyReport"

export class BatchMonthlyReportRepositoryImpl implements BatchMonthlyReportRepositry {
  private Datasource: BatchMonthlyReportDataSource

  constructor(Datasource: BatchMonthlyReportDataSource) {
    this.Datasource = Datasource
  }

  async getBatchMonthlyReport(MonthlyReport: GetBatchMonthlyReport): Promise<any> {
    return await this.Datasource.getBatchMonthlyReport(MonthlyReport)
  }
}
