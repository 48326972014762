import Button from "core/components/new/Button"
import { STR_PLACEHOLDER_IMAGE } from "core/constants/strings"
import { cn } from "core/lib/utils"
import { format } from "date-fns"

export default function ContestCard({ data, type, onClick }: any) {
  return (
    <div className="w-[265px] shrink-0 overflow-hidden rounded-sm bg-new-solid-white shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
      <div className="h-32 w-full">
        <img src={data?.image ?? STR_PLACEHOLDER_IMAGE} alt="contest banner" className="h-full w-full object-cover" />
      </div>
      <div className="flex w-full flex-col gap-4 px-4 pt-4 pb-6 text-new-neutral-dark">
        <div className="space-y-1">
          {data?.start_time ? (
            <h4 className={cn(type === "live" ? "text-new-success" : "text-new-neutral")}>
              {format(new Date(data?.start_time * 1000), "do MMM yyyy")}
            </h4>
          ) : (
            <h4 className="text-new-neutral">Start Now</h4>
          )}
          <h3 className="h-11 line-clamp-2" title={data?.name}>
            {data?.name}
          </h3>
          {data?.start_time && <h5>{format(new Date(data?.start_time * 1000), "hh:mm a O")}</h5>}
        </div>
        <Button onClick={onClick} success={data?.is_registered}>
          {type === "participated" ? "View Result" : data?.is_registered ? "Contest Registered" : "Enter Contest"}
        </Button>
      </div>
    </div>
  )
}
