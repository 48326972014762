import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface GetPaymentDocumentsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetPaymentDocuments implements GetPaymentDocumentsUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getPaymentDocuments(auth)
  }
}
