import Select from "core/components/new/Select"
import Input from "core/components/new/Input"
import { AddIcon } from "core/constants/svgs"
import Fields from "./Fields"

export default function Question({
  allCourses,
  allModules,
  allTopics,
  questionFields,
  questionDetails,
  handleQuestionFields,
  handleQuestionFieldChange,
  handleQuestionDetails,
}: any) {
  return (
    <div className="w-full flex flex-col items-start gap-4 animate-[pulse-animation_1s_linear_10ms]">
      <h3>Question</h3>
      <div className="w-full flex py-8 px-4 flex-col items-start gap-6 border rounded-sm border-new-neutral">
        <div className="w-full flex flex-col gap-2">
          <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">
            Question Type
          </label>
          <Select
            name="questionType"
            id="questionType"
            defaultOption="Select Question Type"
            value={questionDetails?.type || ""}
            onChange={(e) => handleQuestionDetails(e, "type")}
          >
            <option value={"Subjective"}>Subjective</option>
            <option value={"Case Study"}>Case Study</option>
          </Select>
        </div>
        <div className="w-full flex flex-col gap-2">
          <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">Status</label>
          <Select
            name="status"
            id="status"
            defaultOption="Select Status"
            value={questionDetails?.status || ""}
            onChange={(e) => handleQuestionDetails(e, "status")}
          >
            <option value={"active"}>Active</option>
            <option value={"inactive"}>Inactive</option>
          </Select>
        </div>
        <div className="w-full flex flex-col gap-2">
          <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">Course</label>
          <Select
            name="module"
            id="module"
            value={questionDetails?.course || ""}
            onChange={(e) => handleQuestionDetails(e, "course")}
            defaultOption="Select Course"
          >
            {allCourses?.map((course: any, index: number) => (
              <option key={index} value={course}>
                {course}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full flex flex-col gap-2">
          <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">Module</label>
          <Select
            name="module"
            id="module"
            value={questionDetails?.module || ""}
            onChange={(e) => handleQuestionDetails(e, "module")}
            defaultOption="Select Module"
          >
            {allModules?.map((module: any, index: number) => (
              <option key={index} value={module}>
                {module}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full flex flex-col gap-2">
          <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">Topic</label>
          <Select
            name="topic"
            id="topic"
            value={questionDetails?.topic || ""}
            onChange={(e) => handleQuestionDetails(e, "topic")}
          >
            <option value="">Select a Topic</option>
            {allTopics?.map((data: any, index: number) => (
              <option key={index} value={data.topic}>
                {data.topic}
              </option>
            ))}
          </Select>
        </div>

        {questionDetails?.topic === "Others" && (
          <div className="w-full">
            <Input
              type="text"
              name="otherTopic"
              id="otherTopic"
              placeholder="Enter Other Topic"
              value={questionDetails?.otherTopic || ""}
              onChange={(e) => handleQuestionDetails(e, "otherTopic")}
            />
          </div>
        )}
      </div>
      <div className="w-full h-px border border-dashed border-new-neutral"></div>
      <div className="w-full flex flex-col py-8 px-4 items-start gap-6 border rounded-sm border-new-neutral">
        <div className="w-full">
          <Input
            label="Question Title"
            type="text"
            required
            placeholder="What will be the output of the following code snippet?"
            name="questionTitle"
            value={questionDetails?.title}
            onChange={(e) => handleQuestionDetails(e, "title")}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">
            Diffculty Level
          </label>
          <Select
            value={questionDetails?.difficultyLevel || ""}
            defaultOption="Select Level"
            onChange={(e) => handleQuestionDetails(e, "difficultyLevel")}
          >
            <option value={"easy"}>Easy</option>
            <option value={"medium"}>Medium</option>
            <option value={"hard"}>Hard</option>
          </Select>
        </div>
        <div className="w-full">
          <Input
            label="Coins"
            type="text"
            placeholder="Number of coins"
            value={questionDetails?.coins}
            onChange={(e) => handleQuestionDetails(e, "coins")}
          />
        </div>
      </div>
      <div className="w-full h-px border border-dashed border-new-neutral"></div>
      {questionDetails?.type === "Subjective" ? (
        <Fields
          fields={questionFields}
          fieldTypes={["text", "image", "code"]}
          onFieldChange={handleQuestionFieldChange}
          onDeleteField={handleQuestionFields}
        />
      ) : (
        <Fields
          fields={questionFields}
          fieldTypes={["description", "image", "document", "code"]}
          onFieldChange={handleQuestionFieldChange}
          onDeleteField={handleQuestionFields}
        />
      )}
      {questionDetails?.type && (
        <button
          className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
          onClick={(e) => handleQuestionFields("Add", -1)}
        >
          <AddIcon className="h-4 w-4" />
          <p>Add Question Fields</p>
        </button>
      )}
    </div>
  )
}
