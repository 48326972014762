export default function BlankCircle({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33334 8.00065C1.33334 11.6827 4.31801 14.6673 8.00001 14.6673C11.682 14.6673 14.6667 11.6827 14.6667 8.00065C14.6667 4.31865 11.682 1.33398 8.00001 1.33398C4.31801 1.33398 1.33334 4.31865 1.33334 8.00065ZM13.3333 8.00065C13.3333 10.9462 10.9455 13.334 8.00001 13.334C5.05449 13.334 2.66668 10.9462 2.66668 8.00065C2.66668 5.05513 5.05449 2.66732 8.00001 2.66732C10.9455 2.66732 13.3333 5.05513 13.3333 8.00065Z"
        fill="#ADADAD"
      />
    </svg>
  )
}
