import { Auth } from "domain/model/Auth"
import { NotificationRepository } from "domain/repository/Student/NotificationRepository"

export interface UpdateNotificationReadUseCase {
  invoke(auth: Auth, type: any): Promise<any>
}

export class UpdateNotificationRead implements UpdateNotificationReadUseCase {
  private repository: NotificationRepository

  constructor(repository: NotificationRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, type: any) {
    return this.repository.updateNotificationRead(auth, type)
  }
}
