import Button from "core/components/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/Input"
import Model from "core/components/Model"
import StandaredSelect from "core/components/StandaredSelect"
import { STR_DATE_AND_TIME_FIELD } from "core/constants/strings"
import UpdateScheduleTest from "./UpdateSchdeuleTest"
import ViewScheduleTestTable from "./ViewScheduleTestTable"

export default function ScheduleTest({
  selectBatch,
  handleAddBatch,
  handleScheduleTest,
  scheduleTest,
  batch,
  handleBatchChange,
  removeItem,
  setSelectBatch,
  batchList,
  scheduleTests,
  weeklyTest,
  onChangeTestId,
  scheduleWeeklyTests,
  handleOpenModel,
  handleCloseModel,
  isOpen,
  updateScheduleTest,
  deleteScheduleTest,
  openDeleteModel,
  closeDeleteModel,
  open,
}: any) {
  const tableHeaders = ["Name", "batch", "Date", "Time", "url", "Update Test", "Delete Test"]
  return (
    <div>
      <div className="flex justify-center py-[50px]">
        <div className="flex flex-col items-center shadow-[0px_4px_16px_rgba(32,76,188,0.2)] p-6 w-2/4 round-2">
          <form
            className="mb-4 w-full"
            id="main-form"
            // onSubmit={createJob}
          >
            <select
              className="mb-4 w-full para text-neutral-500 px-4 py-2 bg-neutral-50 rounded border border-neutral-300 outline-none dark:text-white dark:focus:ring-blue-500 dark:focus:[] appearance-none cursor-pointer "
              onChange={onChangeTestId}
              value={scheduleTest?.testId}
              style={{ color: "#A7A7A7" }}
            >
              {!scheduleTest?.testId && (
                <option value="" className="text-neutral-500">
                  Test Id
                </option>
              )}
              {weeklyTest?.map((header: any) => (
                <option className=" text-neutral-500 bg-neutral-50" value={header.id}>
                  {header.name}
                </option>
              ))}
            </select>

            <ul className="list-disc pl-8 flex flex-col w-full mb-4 border">
              {selectBatch.map((batches: any, index: any) => (
                <li key={index}>
                  {batches}
                  <IconButton
                    className="bg-black text-xs text-white w-[20px] h-[20px] inline-flex justify-center items-center rounded-full m-2"
                    onClick={() => setSelectBatch(removeItem(selectBatch, index))}
                    text="X"
                  >
                    X
                  </IconButton>
                </li>
              ))}
            </ul>
            <form className="mb-4 flex justify-between gap-4 w-full">
              <StandaredSelect
                required
                options={batchList}
                placeholder="Select Batch"
                onChange={handleBatchChange}
                value={batch}
              />

              <Button className="h-fit w-fit" onClick={handleAddBatch}>
                +
              </Button>
            </form>
            <Input
              name="timestamp"
              type={STR_DATE_AND_TIME_FIELD}
              value={scheduleTest.timestamp}
              onChange={handleScheduleTest}
              placeholder="Test Url"
              className="mb-4"
            />
            <Button onClick={scheduleTests}>Schedule Test</Button>
          </form>
        </div>
      </div>
      <Model isOpen={isOpen} onClose={handleCloseModel}>
        <UpdateScheduleTest
          selectBatch={selectBatch}
          handleAddBatch={handleAddBatch}
          handleScheduleTest={handleScheduleTest}
          scheduleTest={scheduleTest}
          batch={batch}
          handleBatchChange={handleBatchChange}
          removeItem={removeItem}
          setSelectBatch={setSelectBatch}
          batchList={batchList}
          scheduleTests={scheduleTests}
          weeklyTest={weeklyTest}
          onChangeTestId={onChangeTestId}
          scheduleWeeklyTests={scheduleWeeklyTests}
          updateScheduleTest={updateScheduleTest}
        />
      </Model>
      <ViewScheduleTestTable
        tableHeaders={tableHeaders}
        weeklyTest={scheduleWeeklyTests}
        handleOpenModel={handleOpenModel}
        deleteScheduleTest={deleteScheduleTest}
        scheduleWeeklyTests={scheduleWeeklyTests}
        openDeleteModel={openDeleteModel}
        closeDeleteModel={closeDeleteModel}
        open={open}
      />
    </div>
  )
}
