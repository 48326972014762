import Toast from "core/components/Toast"
import SubmissionViewModel from "./SubmissionViewModel"
import Button from "core/components/new/Button"
import { useEffect, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"
import Coding from "./components/Coding"
import Mcqs from "./components/Mcqs"
import Subjective from "./components/Subjective"
import CaseStudy from "./components/CaseStudy"
import SubmissionTab from "./components/SubmissonTab"
import React from "react"
import { cn } from "core/lib/utils"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "core/components/v2/ui/Resizable"

export default function SubmissionView() {
  const {
    TABS,
    BUTTON,
    tab,
    toast,
    activeTab,
    activeTabContent,
    allQuestions,
    selectedQuestion,
    questionSize,
    openSubmissionTab,
    feedbackResponses,
    questionName,
    handleQuestionFieldChange,
    handleQuestionFields,
    questionFields,
    addQuestion,
    openViewQuestion,
    fileResponse,
    selectedSubQuestion,
    selectedSubquestionId,
    isSubmittingFeedback,
    handleTabChange,
    changeToastVisibility,
    handleTabContentChange,
    fetchSubmissionDetails,
    handleSelectedQuestion,
    handlePreviousBtnClick,
    handleNextBtnClick,
    handleOpenSubmissionTab,
    handleFeedbackChange,
    setQuestionName,
    handleAddQuestion,
    handleOpenViewQuestion,
    handleRatingChange,
    handleSaveChanges,
    handleDiscardChanges,
    fetchFeedbackResponseSubmission,
    handleDeleteFile,
    handleCaseStudySelectedQuestion,
    handleCaseStudyFeedbackChange,
    handleCaseStudyRatingChange,
  } = SubmissionViewModel()

  let student_email = ""
  let contest_id = ""
  const path = useLocation().pathname

  useEffect(() => {
    student_email = path.split("/")[5]
    contest_id = path.split("/")[3]

    const fetchDetails = async () => {
      await fetchSubmissionDetails(student_email, contest_id)
      await fetchFeedbackResponseSubmission(student_email, contest_id)
    }

    if (student_email && contest_id) {
      fetchDetails()
    }
  }, [student_email, contest_id])

  React.useEffect(() => {
    if (activeTab === 3 && allQuestions[TABS[activeTab]]?.length > 0) {
      const firstQuestion = allQuestions[TABS[activeTab]][selectedQuestion]
      const firstSubQuestion = firstQuestion.response[0]
      handleCaseStudySelectedQuestion(selectedQuestion, 0, firstSubQuestion.id)
    }
  }, [activeTab, allQuestions, selectedQuestion])

  const escapeHtml = (text: any) => {
    return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
  }

  const getQuestionName = () => {
    if (activeTab === 0) {
      return allQuestions[TABS[activeTab]]?.[selectedQuestion]?.id
    } else if (activeTab === 1) {
      return allQuestions[TABS[activeTab]]?.[selectedQuestion]?.id
    } else if (activeTab === 2) {
      return allQuestions[TABS[activeTab]]?.[selectedQuestion]?.id
    } else {
      return (
        allQuestions[TABS[activeTab]]?.[selectedQuestion]?.id +
        "_" +
        allQuestions[TABS[activeTab]]?.[selectedQuestion]?.response?.[selectedSubQuestion]?.id
      )
    }
  }

  useEffect(() => {
    const questionName = getQuestionName()
    if (TABS[activeTab] === "Case Study") {
      setQuestionName(questionName.toLowerCase())
    } else {
      setQuestionName(questionName)
    }
  }, [activeTab, selectedQuestion, allQuestions])

  useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  return (
    <div className="h-screen">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel defaultSize={40} minSize={20}>
          <div className="editor relative flex h-full w-full flex-col overflow-y-scroll bg-new-editor-dark-700">
            <div className="no-scrollbar sticky top-0 z-20 flex shrink-0 overflow-x-auto">
              {TABS.map((tab, i) => (
                <button
                  key={i}
                  className={cn(
                    "flex-1 px-4 py-2 text-sm font-medium leading-[20px]",
                    TABS[activeTab] === tab
                      ? "bg-new-editor-dark-700 text-new-solid-white"
                      : "bg-new-editor-dark-500 text-new-neutral-light"
                  )}
                  onClick={() => handleTabChange(i)}
                >
                  {tab}
                </button>
              ))}
            </div>
            {questionSize > 0 ? (
              <div className="flex flex-col space-y-6 p-6">
                <div className="flex space-x-4 h-full w-full">
                  {BUTTON.map((item, i) => (
                    <button
                      key={i}
                      className={cn(
                        "flex-1 px-4 py-2 text-sm font-medium justify-center items-center leading-[20px] rounded",
                        BUTTON[activeTabContent] === item
                          ? "bg-new-editor-dark-700 text-new-solid-white backdrop-blur-sm border border-new-editor-dark-500"
                          : "bg-new-editor-dark-500 text-new-neutral-light"
                      )}
                      onClick={() => handleTabContentChange(i)}
                    >
                      {item}
                    </button>
                  ))}
                </div>
                <div className="space-y-2">
                  {activeTab === 3 ? (
                    <h3 className="flex text-base space-x-2 text-new-neutral-light">Questions</h3>
                  ) : (
                    <h3></h3>
                  )}
                  <div className="flex gap-2">
                    {activeTab === 3
                      ? allQuestions[TABS[activeTab]]?.map((mainQuestion: any, mainIdx: number) => (
                          <button
                            key={mainIdx}
                            className={`flex items-center justify-center w-[40px] h-[40px] px-3 py-2 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded ${
                              selectedQuestion === mainIdx && "text-[#00C22B] outline outline-[#00C22B]"
                            }`}
                            onClick={() => handleCaseStudySelectedQuestion(mainIdx, 0, mainQuestion.id)}
                          >
                            {mainIdx + 1}
                          </button>
                        ))
                      : allQuestions[TABS[activeTab]]?.map((question: any, i: number) => (
                          <button
                            key={i}
                            className={`flex items-center justify-center w-[40px] h-[40px] px-3 py-2 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded ${
                              selectedQuestion === i && "text-[#00C22B] outline outline-[#00C22B]"
                            }`}
                            onClick={() => handleCaseStudySelectedQuestion(i, 0, question.id)}
                          >
                            {i + 1}
                          </button>
                        ))}
                  </div>
                  {activeTab === 3 && selectedQuestion !== null && (
                    <div className="space-y-2">
                      <h3 className="flex text-base space-x-2 text-new-neutral-light">Subquestions</h3>
                      <div className="flex gap-2">
                        {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.response?.map(
                          (subQuestion: any, subIdx: number) => (
                            <button
                              key={`${selectedQuestion}-${subIdx}`}
                              className={`flex items-center justify-center w-[40px] h-[40px] px-3 py-2 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded ${
                                selectedSubQuestion === subIdx && "text-[#00C22B] outline outline-[#00C22B]"
                              }`}
                              onClick={() => handleCaseStudySelectedQuestion(selectedQuestion, subIdx, subQuestion.id)}
                            >
                              {subIdx + 1}
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {activeTab === 0 && (
                  <Coding
                    questions={allQuestions[TABS[activeTab]]?.[selectedQuestion]}
                    selectedQuestion={selectedQuestion}
                    TABS={TABS}
                    activeTabContent={activeTabContent}
                    activeTab={activeTab}
                  />
                )}
                {activeTab === 1 && questionSize > 0 && (
                  <Mcqs
                    questions={allQuestions[TABS[activeTab]]?.[selectedQuestion]}
                    selectedQuestion={selectedQuestion}
                    activeTabContent={activeTabContent}
                  />
                )}
                {activeTab === 2 && questionSize > 0 && (
                  <Subjective
                    questions={allQuestions[TABS[activeTab]]?.[selectedQuestion]}
                    selectedQuestion={selectedQuestion}
                    activeTabContent={activeTabContent}
                  />
                )}
                {activeTab === 3 && questionSize > 0 && (
                  <>
                    <div className="space-y-2">
                      <div className="flex flex-col p-2 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 space-y-1 rounded">
                        <h3 className="text-lg font-semibold">
                          {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.questionTitle}
                        </h3>
                        <div className="flex text-base space-x-2">
                          <>
                            <p className="font-semibold">Max Marks : </p>
                            <p>
                              {
                                allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["response"]?.[
                                  selectedSubQuestion
                                ]?.["maxMarks"]
                              }
                            </p>
                          </>
                          <div className="flex justify-between space-x-1">
                            <p className="font-semibold">Difficulty Level : </p>
                            <p
                              className={`${
                                allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["difficultyLevel"] === "easy"
                                  ? "text-emerald-500"
                                  : allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["difficultyLevel"] === "medium"
                                    ? "text-orange-500"
                                    : "text-red-500"
                              } capitalize`}
                            >
                              {allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["difficultyLevel"]}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
                        {activeTabContent === 0 &&
                          allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["response"]?.[selectedSubQuestion]?.fields
                            .slice()
                            .sort((a: any, b: any) => (a.type === "document" ? -1 : 1))
                            .map((field: any, idx: number) => (
                              <div key={idx} className="p-2">
                                {field.type === "document" && (
                                  <div className="flex justify-between">
                                    <h3 className="py-2 text-new-neutral-light">Problem Statement</h3>
                                    <button
                                      onClick={() => window.open(field.content, "_blank")}
                                      className="text-blue-500 underline cursor-pointer"
                                    >
                                      Open File
                                    </button>
                                  </div>
                                )}
                                {field.type === "text" && <p className="text-new-neutral-light">{field.content}</p>}
                                {field.type === "image" && (
                                  <img src={field.content} alt={field.filename} className="max-w-full max-h-[400px]" />
                                )}
                              </div>
                            ))}
                      </div>
                    </div>
                    <CaseStudy
                      questions={allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["response"]}
                      selectedQuestion={selectedSubQuestion}
                      activeTabContent={activeTabContent}
                    />
                  </>
                )}
              </div>
            ) : (
              <h1 className="flex w-full h-full text-new-accent justify-center items-center">No Data Available</h1>
            )}
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={60} minSize={20}>
          <div className="editor relative flex h-[100vh] w-full flex-col overflow-y-scroll bg-new-editor-dark-700 p-2">
            <div className="flex flex-col h-full">
              <div className="w-full h-full">
                <div className=" flex flex-col space-y-2 text-white p-1 h-full">
                  <h4 className="text-sm font-semibold">Student Response</h4>
                  {activeTab === 0 &&
                    (allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["user_answer"]?.length > 0 ? (
                      <pre
                        className="bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto flex-grow rounded"
                        dangerouslySetInnerHTML={{
                          __html: escapeHtml(allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["user_answer"]),
                        }}
                      ></pre>
                    ) : (
                      <p className="flex bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto flex-grow items-center justify-center rounded">
                        No Response
                      </p>
                    ))}

                  {activeTab === 2 && (
                    <div className="flex flex-grow flex-wrap bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto rounded">
                      <p>{allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["user_answer"]}</p>
                    </div>
                  )}
                  {activeTab === 1 &&
                    (() => {
                      const usersolution = allQuestions[TABS[activeTab]]?.[selectedQuestion]?.user_answer?.answer

                      const isValidSolution =
                        usersolution !== null && usersolution !== undefined && !isNaN(usersolution)

                      return (
                        <div className="flex flex-grow bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto rounded">
                          {isValidSolution ? (
                            <>
                              <span className="text-new-neutral-light mr-2">
                                {String.fromCharCode(64 + parseInt(usersolution + 1)) + "."}
                              </span>
                              <span className="text-new-neutral-light flex-wrap">
                                {
                                  allQuestions[TABS[activeTab]]?.[selectedQuestion]?.options?.[
                                    parseInt(usersolution + 1)
                                  ]?.[0]?.content
                                }
                              </span>
                            </>
                          ) : (
                            <p className="flex bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 overflow-y-auto flex-grow items-center justify-center rounded">
                              No Response
                            </p>
                          )}
                        </div>
                      )
                    })()}

                  {activeTab === 3 && (
                    <div className="flex flex-col flex-wrap bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto py-4 px-2 flex-grow rounded">
                      {typeof allQuestions[TABS[activeTab]]?.[selectedQuestion]?.user_answer?.answer?.[
                        selectedSubquestionId
                      ] === "string" ? (
                        <p className="text-new-neutral-light">
                          {
                            allQuestions[TABS[activeTab]]?.[selectedQuestion]?.user_answer?.answer?.[
                              selectedSubquestionId
                            ]
                          }
                        </p>
                      ) : (
                        Object.entries(
                          allQuestions[TABS[activeTab]]?.[selectedQuestion]?.user_answer?.answer?.[
                            selectedSubquestionId
                          ] || {}
                        ).map(
                          ([key, usersolution], idx) =>
                            usersolution === true && (
                              <div key={idx} className="flex mb-2">
                                <span className="text-new-neutral-light mr-2">
                                  {String.fromCharCode(64 + parseInt(key))}.
                                </span>
                                <p className="flex flex-col-reverse text-new-neutral-light">
                                  {
                                    // allQuestions[TABS[activeTab]]?.[
                                    //   selectedQuestion
                                    // ]?.response?.[selectedSubquestionId]
                                    //   ?.options?.[parseInt(key)]?.[
                                    //   selectedQuestion
                                    // ]?.content

                                    // allQuestions[TABS[activeTab]]?.[
                                    //   selectedQuestion
                                    // ]?.response?.[selectedSubQuestion]
                                    //   ?.options?.[parseInt(key) - 1]?.[
                                    //   selectedQuestion
                                    // ]?.content
                                    allQuestions[TABS[activeTab]]?.[selectedQuestion]?.response?.[selectedSubQuestion]
                                      ?.options[parseInt(key) - 1][0]?.content
                                  }
                                </p>
                              </div>
                            )
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full h-[10vh]">
                {/* <div className="flex flex-col h-full m-2"> */}
                {/* <div className="text-white flex-grow">
                    <h4 className="mb-2 text-sm font-semibold">
                      Provide Your Feedback
                    </h4>
                    <textarea
                      className={`w-full h-36 p-2 mb-4 bg-gray-800 text-gray-300 border border-gray-600 rounded-md resize-none focus:outline-none focus:border-blue-500 ${isDocumentQuestion && "opacity-50"}`}
                      placeholder="Your Feedback Response"
                      name="feedback_response"
                      disabled={isDocumentQuestion}
                      value={
                        feedbackResponses[questionName]?.feedback_response || ""
                      }
                      onChange={(e) =>
                        handleFeedbackChange(questionName, TABS[activeTab], e)
                      }
                    />
                  </div> */}
                {/* </div>  */}
              </div>
            </div>
            <div className="flex justify-between border-t border-new-neutral-light px-6 py-4 h-[10%]">
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 text-sm font-medium text-new-neutral-light bg-new-editor-dark-500 hover:bg-new-editor-dark-700 rounded-md"
                  onClick={handlePreviousBtnClick}
                >
                  Previous
                </button>
                <button
                  className="px-4 py-2 text-sm font-medium text-new-neutral-light bg-new-editor-dark-500 hover:bg-new-editor-dark-700 rounded-md"
                  onClick={handleNextBtnClick}
                >
                  Next
                </button>
              </div>
              <div>
                <Button className="bg-blue-500 hover:bg-blue-600" onClick={handleOpenSubmissionTab}>
                  Submit Feedback
                </Button>
              </div>
            </div>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
      {/* inset view of submission */}
      {openSubmissionTab && (
        <SubmissionTab
          allQuestions={allQuestions}
          tab={tab}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          selectedQuestion={selectedQuestion}
          questionSize={questionSize}
          TABS={TABS}
          handleOpenSubmissionTab={handleOpenSubmissionTab}
          handleSelectedQuestion={handleSelectedQuestion}
          handleFeedbackChange={handleFeedbackChange}
          feedbackResponses={feedbackResponses}
          questionName={questionName}
          handleQuestionFieldChange={handleQuestionFieldChange}
          handleQuestionFields={handleQuestionFields}
          questionFields={questionFields}
          addQuestion={addQuestion}
          handleAddQuestion={handleAddQuestion}
          handleOpenViewQuestion={handleOpenViewQuestion}
          openViewQuestion={openViewQuestion}
          handleRatingChange={handleRatingChange}
          handleSaveChanges={handleSaveChanges}
          email_id={student_email}
          contest_id={contest_id}
          handleDiscardChanges={handleDiscardChanges}
          fileResponse={fileResponse}
          handleDeleteFile={handleDeleteFile}
          handleCaseStudySelectedQuestion={handleCaseStudySelectedQuestion}
          selectedSubQuestion={selectedSubQuestion}
          handleCaseStudyFeedbackChange={handleCaseStudyFeedbackChange}
          selectedSubquestionId={selectedSubquestionId}
          handleCaseStudyRatingChange={handleCaseStudyRatingChange}
          isSubmittingFeedback={isSubmittingFeedback}
        />
      )}

      <Toast data={toast} onClick={() => changeToastVisibility(false)} className="absolute bottom-0 left-0" />
    </div>
  )
}
