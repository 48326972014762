import BatchMonthlyReportRepositry from "domain/repository/Admin/BatchMonthlyReport"
import GetBatchMonthlyReport from "domain/model/GetBatchMonthlyReport"

export interface getBatchMonthlyReportUseCase {
  invoke(details: GetBatchMonthlyReport): Promise<any>
}

export class GetBatchMonthlyReportData implements getBatchMonthlyReportUseCase {
  private repository: BatchMonthlyReportRepositry

  constructor(repository: BatchMonthlyReportRepositry) {
    this.repository = repository
  }

  async invoke(details: GetBatchMonthlyReport): Promise<any> {
    return await this.repository.getBatchMonthlyReport(details)
  }
}
