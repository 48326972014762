import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { useState } from "react"

export default function OperationalIssueTable({ data, outlined, OperationalIssue, futureBatchEligiblity }: any) {
  const [infoHover, setInfoHover] = useState<any>(false)
  const [index, setIndex] = useState<any>()
  const [rowIndex, setRowIndex] = useState<any>()
  const [hoverContent, setHoverContent] = useState<any>("")

  return (
    <div
      className={cn(
        "relative overflow-x-auto rounded-sm",
        outlined ? "border border-new-neutral-light" : "shadow-[0px_4px_16px_rgba(134,134,134,0.2)]"
      )}
    >
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-editor-dark-500 text-new-solid-white">
          <tr>
            {data?.header?.map((title: any, i: any) => (
              <th
                key={i}
                className={cn(
                  "px-6 py-2 text-sm font-semibold leading-[17px]",
                  i > 0 && i === data.header.length - 1 && "text-right"
                )}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmpty(data?.rows) ? (
            data?.rows?.map((row: any, ind: any) => (
              <tr className="group relative bg-new-solid-white even:bg-new-misc-gray" key={ind}>
                {Object.keys(row).map((key: any, i: any) => (
                  <td
                    key={key}
                    className={cn(
                      "relative whitespace-nowrap px-6 py-4 font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark",
                      i > 0 && i === Object.keys(row).length - 1 && "text-right",
                      futureBatchEligiblity &&
                        futureBatchEligiblity[ind]["is_batch_open_to_choose"] === false &&
                        "opacity-50"
                    )}
                    onMouseEnter={() => {
                      setIndex(i)
                      setRowIndex(ind)
                      setInfoHover(true)
                      setHoverContent(row[key])
                    }}
                    onMouseLeave={() => setInfoHover(false)}
                  >
                    {OperationalIssue ? (row[key].length > 30 ? row[key].slice(0, 30) + "..." : row[key]) : row[key]}
                  </td>
                ))}
                {ind == rowIndex &&
                  index === 2 &&
                  futureBatchEligiblity &&
                  !futureBatchEligiblity[rowIndex]["is_batch_open_to_choose"] &&
                  infoHover && (
                    <td className="absolute right-[45px] top-[-28px] z-50 flex items-center justify-center rounded-sm bg-[#312E81] py-1 px-2 opacity-100">
                      <div className="text-[12px] font-medium text-white">
                        Selection for this batch is only possible once it commences
                      </div>
                    </td>
                  )}
                {ind == rowIndex && hoverContent.length > 30 && index === 1 && OperationalIssue && infoHover && (
                  <td className="absolute left-[250px] top-[-28px] z-50 flex items-center justify-center rounded-sm bg-[#312E81] py-1 px-2 opacity-100">
                    <div className="text-[12px] font-medium text-white">{data?.rows[rowIndex]["status"]}</div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-new-misc-gray">
              <td
                colSpan={data?.header?.length}
                className="py-2 text-center font-medium leading-6 text-new-neutral-light"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
