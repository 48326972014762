import React from "react"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import LinkedInIcon from "assets/svgs/LinkedInIcon"
import { cn } from "core/lib/utils"

const OnboardingManagerModal = ({ onboardingTeam, handleCrossBtnClick }: any) => {
  return (
    <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-[#0c0c0c] bg-opacity-80">
      <div className="relative h-[440px] w-[770px] space-y-4 rounded-lg bg-white p-6">
        <div>
          <p className="text-base font-semibold text-[#333]">Onboarding Team Members </p>
          <p className="text-sm font-normal text-[#646464]">
            You can access all your onboarding team members from here.
          </p>
          <div className="absolute top-5 right-12">
            <button className="fixed rounded hover:bg-[#e3e3e3]" onClick={handleCrossBtnClick}>
              <CloseSharpIcon />
            </button>
          </div>
        </div>
        <div className="flex h-4/5 flex-col">
          <div className="gray-scrollbar grid flex-grow grid-cols-3 gap-4 overflow-y-auto pr-1">
            {onboardingTeam.map((member: any, idx: number) => (
              <div key={member.id} className="flex gap-2 rounded border border-[#D3D3D3] p-4">
                <div className="relative h-[64px] w-[64px]">
                  <img
                    className="h-full w-full rounded-md object-cover"
                    src={member.profile_pic}
                    alt="Onboarding Manager"
                  />
                  <a
                    className="absolute bottom-0 right-0 h-[16px] w-[16px] rounded border border-white bg-[#0077B5]"
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon className="text-white" />
                  </a>
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    <p className="text-sm font-semibold">{member?.name.split(" ")[0]}</p>
                    <p className={cn(member.isLead || idx < 2 ? "text-green-500" : "text-[#646464]", "text-[10px]")}>
                      {member.designation}
                    </p>
                  </div>
                  <div className="flex items-end">
                    <p className="text-[10px] text-[#646464]">{member.phone}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingManagerModal
