import StarFilled from "assets/svgs/StarFilled"
import { cn } from "core/lib/utils"
import React, { useState } from "react"

interface StarRatingProps {
  starRating: number
}

const StarRating: React.FC<StarRatingProps> = ({ starRating }) => {
  const rating = starRating as number
  return (
    <div className="flex gap-1">
      {[...Array(5)].map((_, i) => {
        const ratingValue = i + 1

        return (
          <label key={i}>
            <StarFilled className={cn("h-5", ratingValue <= rating ? "text-[#FBBF24]" : "text-[#D3D3D3]")} />
          </label>
        )
      })}
    </div>
  )
}

export default StarRating
