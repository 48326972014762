import React, { useEffect, useState } from "react"
import { GetMonthlyReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetMonthlyReportData"
import { MonthlyReportRepositoryImpl } from "data/repository/Admin/MonthlyReportRepositoryImpl"
import { MonthlyReportAPIDataSourceImpl } from "data/API/Admin/MonthlyReportDataSourceImpl"
import { useAuth } from "core/context/auth"
import ProgressRoadmapViewModel from "../../ProgressRoadmapViewModel"

const MonthlyReportViewModel = () => {
  const [MonthsData, setMonthsData] = useState<any>()
  const [selectedMonth, setSelectedMonth] = useState<number>(0)
  const [selectedMonthData, setSelectedMonthData] = useState<any>()
  const [totalBatch, setTotalBatch] = useState<number>(0)
  const [currMonthBatch, setCurrMonthBatch] = useState<number>(0)
  const [monthBatch, setMonthBatch] = useState<any>()
  const { auth } = useAuth()
  const { id } = ProgressRoadmapViewModel()

  const handleMonthSelect = (index: number): void => {
    setSelectedMonth(index)
    setCurrMonthBatch(MonthsData.data.data[currMonthBatch][index])
  }

  const studentMonthlyReportUseCase = new GetMonthlyReportData(
    new MonthlyReportRepositoryImpl(new MonthlyReportAPIDataSourceImpl())
  )

  const handleNext = (): void => {
    if (currMonthBatch - 1 >= 0) {
      setCurrMonthBatch(currMonthBatch - 1)
      setMonthBatch(MonthsData[currMonthBatch - 1])
    }
  }

  const handlePrevious = (): void => {
    if (currMonthBatch + 1 < totalBatch) {
      setCurrMonthBatch(currMonthBatch + 1)
      setMonthBatch(MonthsData[currMonthBatch + 1])
    }
  }

  const getMonthlyReport = async () => {
    const response = await studentMonthlyReportUseCase.invoke({
      id_token: auth.id_token,
      email: id,
    })
    setTotalBatch(response.data.data.length)
    setMonthsData(response.data.data)
    setMonthBatch(response.data.data[currMonthBatch])
  }

  useEffect(() => {
    getMonthlyReport()
  }, [])

  return {
    MonthsData,
    selectedMonth,
    setSelectedMonthData,
    setSelectedMonth,
    getMonthlyReport,
    handleMonthSelect,
    selectedMonthData,
    handleNext,
    handlePrevious,
    monthBatch,
  }
}

export default MonthlyReportViewModel
