import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface SubmitBatchMcqQuestionUseCase {
  invoke(auth: Auth, contest_id: string, batch_mcq_obj: string): Promise<any>
}

export default class SubmitBatchMcqQuestion implements SubmitBatchMcqQuestionUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contest_id: string, batch_mcq_obj: string) {
    return this.repository.submitBatchMcqQuestion(auth, contest_id, batch_mcq_obj)
  }
}
