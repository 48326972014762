import { DoubtSupportDataSource } from "data/dataSource/Student/DoubtSupportDataSource"
import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export class DoubtSupportRepositoryImpl implements DoubtSupportRepository {
  private dataSource: DoubtSupportDataSource

  constructor(dataSource: DoubtSupportDataSource) {
    this.dataSource = dataSource
  }

  async getAllClassTopics(auth: Auth): Promise<any> {
    return this.dataSource.getAllClassTopics(auth)
  }

  async getAllQuestions(auth: Auth, class_name_id: any): Promise<any> {
    return this.dataSource.getAllQuestions(auth, class_name_id)
  }

  async getDoubtQuerySolution(auth: Auth, problem_id: any): Promise<any> {
    return this.dataSource.getDoubtQuerySolution(auth, problem_id)
  }

  async updateDoubtStatus(auth: Auth, like: any, problem_id: any): Promise<any> {
    return this.dataSource.updateDoubtStatus(auth, like, problem_id)
  }

  async getStudentDoubtHistory(auth: Auth): Promise<any> {
    return this.dataSource.getStudentDoubtHistory(auth)
  }

  async searchStudentDoubt(auth: Auth, problem_id: any): Promise<any> {
    return this.dataSource.searchStudentDoubt(auth, problem_id)
  }

  async getStudentDoubtPending(auth: Auth): Promise<any> {
    return this.dataSource.getStudentDoubtPending(auth)
  }

  async getFutureBatches(auth: Auth): Promise<any> {
    return this.dataSource.getFutureBatches(auth)
  }

  async studentBatchShift(
    auth: Auth,
    batch: any,
    reason: any,
    is_batch_resume: any,
    discord_username: any
  ): Promise<any> {
    return this.dataSource.studentBatchShift(auth, batch, reason, is_batch_resume, discord_username)
  }

  async isEligibleForBatchChange(auth: Auth): Promise<any> {
    return this.dataSource.isEligibleForBatchChange(auth)
  }

  async studentBatchPause(auth: Auth, reason: any): Promise<any> {
    return this.dataSource.studentBatchPause(auth, reason)
  }

  async getEligibleMentors(auth: Auth): Promise<any> {
    return this.dataSource.getEligibleMentors(auth)
  }

  async updateMentor(auth: Auth, mentor_id: any, reason: any): Promise<any> {
    return this.dataSource.updateMentor(auth, mentor_id, reason)
  }
}
