import Cross from "assets/svgs/Cross"
import ReferralModal from "./ReferralModal"
import ReferralTableTabGroup from "./ReferralTableTabGroup"
import { useEffect, useState } from "react"
import StandardSelect from "core/components/v2/StandardSelect"
import { CustomScroll } from "react-custom-scroll"

const ReferralRewardsModal = ({ isOpen, onClose, student }: any) => {
  const activeTableButtons = ["Referrer", "Referee"]
  const [activeTableButtonType, setActiveTableButtonType] = useState<string>("Referrer")
  const [courseType, setCourseType] = useState<string>("")
  const [referralCourseType, setReferralCourseType] = useState<string>("")
  const [refereeCouses, setRefereeCourses] = useState<any>({
    Accelerator: "3000",
    Transformer: "5000",
    Evolve: "6000",
    "Data Science": "5000",
    "Data Analytics": "4000",
  })
  const [courses, setCourses] = useState<any>({
    Accelerator: [
      { amount: "3000" },
      { amount: "3500" },
      { amount: "4000", extra: "Goodies worth 1K" },
      { amount: "4000" },
      { amount: "4000" },
      { amount: "4000", extra: "Zomato Voucher 1000" },
      { amount: "4000" },
      { amount: "4000" },
      { amount: "4000" },
      { amount: "Remaining fees refund", extra: "Remaining fees refund" },
    ],
    Transformer: [
      { amount: "5000" },
      { amount: "5500" },
      { amount: "6000", extra: "Zomato voucher / 1 Month LinkedIn premium" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "10000" },
      { amount: "10000" },
      { amount: "Remaining fees refund", extra: "Remaining fees refund" },
    ],
    Evolve: [
      { amount: "6000" },
      { amount: "7000" },
      { amount: "7500", extra: "Zomato Vouchers / Personalised goodie" },
      { amount: "7500" },
      { amount: "7500" },
      { amount: "7500", extra: "3000 worth of Amazon coupons and goodies" },
      { amount: "7500" },
      { amount: "7500" },
      { amount: "7500" },
      { amount: "10000" },
      { amount: "10000" },
      { amount: "Remaining fees refund", extra: "Remaining fees refund" },
    ],
    "Data Science": [
      { amount: "5000" },
      { amount: "5500" },
      { amount: "6000", extra: "Zomato voucher / 1 Month LinkedIn premium" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "6000", extra: "3000 worth of Amazon coupons and goodies" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "6000" },
      { amount: "10000" },
      { amount: "10000" },
      { amount: "Remaining fees refund", extra: "Remaining fees refund" },
    ],
    "Data Analytics": [
      { amount: "4000" },
      { amount: "4500" },
      { amount: "5000", extra: "Zomato voucher / 1 Month LinkedIn premium" },
      { amount: "5000" },
      { amount: "5000" },
      { amount: "5000", extra: "Goodies worth 1K" },
      { amount: "5000" },
      { amount: "5000" },
      { amount: "5000" },
      { amount: "10000" },
      { amount: "10000" },
      { amount: "Remaining fees refund", extra: "Remaining fees refund" },
    ],
  })

  useEffect(() => {
    if (student?.program_type) {
      setReferralCourseType(student?.program_type)
      setCourseType(student?.program_type)
    } else {
      const batch = student?.batch
      let program_type = "Evolve"

      if (batch?.startsWith("B")) {
        program_type = "Transformer"
      } else if (batch?.startsWith("AC") || batch === "Accelerator") {
        program_type = "Accelerator"
      } else if (batch === "DSML") {
        program_type = "Data Analytics"
      } else if (batch?.startsWith("EV")) {
        program_type = "Evolve"
      }
      // else if (batch?.startsWith("DE")) {
      //   program_type = "Data Engineering";
      // }

      setReferralCourseType(program_type)
      setCourseType(program_type)
    }
  }, [])

  function changeActiveTableButtonType(index: number) {
    const tabType = ["Referrer", "Referee"][index]
    setActiveTableButtonType(tabType)
  }
  return (
    <div>
      <ReferralModal isOpen={isOpen}>
        <div className="w-[621px] h-[500px] bg-white rounded-[8px] p-[24px]">
          <div className="flex justify-between">
            <p className="text-[16px] font-[600] text-[#333]">Referral rewards</p>
            <button className="hover:bg-[#D3D3D3] duration-500 rounded-sm" onClick={onClose}>
              <Cross />
            </button>
          </div>
          <p className="text-[#646464] text-[14px] font-[400]">List of all your referrals rewards.</p>

          <div className="my-[16px] flex justify-between">
            <ReferralTableTabGroup
              buttons={activeTableButtons}
              active={activeTableButtons.indexOf(activeTableButtonType)}
              onChange={changeActiveTableButtonType}
            />
            <StandardSelect
              dimensions="w-[300px]"
              className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              placeholder={"Select course Type"}
              name="relation"
              value={activeTableButtonType === "Referrer" ? referralCourseType : courseType}
              onChange={(course: string) => setCourseType(course)}
              options={
                activeTableButtonType === "Referrer"
                  ? [`${referralCourseType}`]
                  : ["Accelerator", "Transformer", "Evolve", "Data Science", "Data Analytics"]
              }
              required
            ></StandardSelect>
          </div>

          <div className=" bg-white rounded-[4px] h-[321px]">
            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
              <div className="p-[24px] bg-[#F9F9F9]  border-[#D3D3D3] border-[0.5px] rounded-[6px] shadow-md">
                {activeTableButtonType === "Referrer" && (
                  <>
                    <div className="flex justify-between items-center p-[6px]">
                      <p className="text-[12px] font-[600] w-[136px] text-center text-[#0C0C0C]">Referral No.</p>
                      <p className="text-[12px] font-[600] w-[178px] text-center text-[#0C0C0C]">₹ Amount </p>
                      <p className="text-[12px] font-[600] w-[178px] text-center text-[#0C0C0C]">Extra Benefits</p>
                    </div>

                    <hr className="my-[16px]" />

                    {courses[referralCourseType]?.map((data: any, index: number) => (
                      <>
                        <div className="my-[16px] flex justify-between items-center">
                          <div className="text-[12px] font-[600] w-[136px] py-[3px] rounded-[4px] bg-[#EBF8FF] text-center">
                            {index + 1}
                          </div>
                          <p className="text-[12px] font-[600] w-[178px] py-[3px] rounded-[4px] bg-[#EBF8FF] text-center">
                            ₹ {data.amount}{" "}
                          </p>
                          <p
                            className={`text-[12px] font-[600] w-[178px] py-[3px] rounded-[4px] ${data?.extra ? "bg-[#FEFFE4]" : "bg-[#EBF8FF]"} text-center`}
                          >
                            {data?.extra ? data?.extra : "-"}
                          </p>
                        </div>
                      </>
                    ))}
                  </>
                )}

                {activeTableButtonType === "Referee" && (
                  <>
                    <div className="flex justify-between items-center p-[6px]">
                      <p className="text-[12px] font-[600] w-[136px] text-center text-[#0C0C0C]">Referral No.</p>
                      <p className="text-[12px] font-[600] w-[178px] text-center text-[#0C0C0C]">₹ Cashback </p>
                      <p className="text-[12px] font-[600] w-[178px] text-center text-[#0C0C0C]">Extra Benefits</p>
                    </div>

                    <hr className="my-[16px]" />
                    <div className="flex justify-between items-center">
                      <div className="text-[12px] font-[600] w-[136px] py-[3px] rounded-[4px] bg-[#EBF8FF] text-center">
                        01
                      </div>
                      <p className="text-[12px] font-[600] w-[178px] py-[3px] rounded-[4px] bg-[#EBF8FF] text-center">
                        ₹ {refereeCouses[courseType]}{" "}
                      </p>
                      <p className="text-[12px] font-[600] w-[178px] py-[3px] rounded-[4px] bg-[#EBF8FF] text-center">
                        -
                      </p>
                    </div>
                  </>
                )}
              </div>
            </CustomScroll>
          </div>
        </div>
      </ReferralModal>
    </div>
  )
}

export default ReferralRewardsModal
