const LoginPageGradient = ({ className }: any) => {
  return (
    <svg width="365" height="301" viewBox="0 0 365 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{ mixBlendMode: "multiply" }}>
        <path d="M152.673 -3H365L212.928 301H0.601562L152.673 -3Z" fill="url(#paint0_linear_984_38089)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_984_38089"
          x1="347.784"
          y1="-224.542"
          x2="-46.0305"
          y2="173.547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={className} />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LoginPageGradient
