import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface FinaliseInvoiceOfAMentorUseCase {
  invoke(auth: Auth, instructor_id: string, year_month: string): Promise<any>
}

export default class FinaliseInvoiceOfAMentor implements FinaliseInvoiceOfAMentorUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string, year_month: string) {
    return await this.repository.finaliseInvoiceOfAMentor(auth, instructor_id, year_month)
  }
}
