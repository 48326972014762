import DocumentationFileRepository from "domain/repository/Admin/DocumentationFileRepository"
import { Auth } from "domain/model/Auth"

export interface DeleteDocumentationFileUseCase {
  invoke(auth: Auth, id: string): Promise<any>
}

export class DeleteDocumentationFile implements DeleteDocumentationFileUseCase {
  private repository: DocumentationFileRepository

  constructor(repository: DocumentationFileRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string): Promise<any> {
    return await this.repository.delete_documentation_file(auth, id)
  }
}
