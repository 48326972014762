import DividerButton from "./DividerButton"

type TProps = {
  loading: boolean
  exhausted: boolean
  handler: () => void
}

export default function Skeleton({ loading, exhausted, handler }: TProps) {
  if (loading) {
    return (
      <div className="space-y-2">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="w-full h-8 rounded bg-new-editor-dark-50" />
        ))}
      </div>
    )
  }

  if (!exhausted) {
    return (
      <div className="flex justify-center relative">
        <DividerButton onClick={handler}>Load More</DividerButton>
      </div>
    )
  }

  return null
}
