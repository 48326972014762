import React, { forwardRef } from "react"
import { cn } from "core/lib/utils"
import { EyeIcon, NoEyeIcon, SearchIcon } from "core/constants/svgs"

type TProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  label?: string
  search?: boolean
  type?: "text" | "password" | "email" | "number" | "checkbox" | "radio" | "date" | "datetime-local" | "file"
  containerClass?: string
}

const Input = forwardRef<any, TProps>(
  ({ label, search, type = "text", className, containerClass, ...props }: TProps, ref) => {
    const [visible, setVisible] = React.useState(false)

    return (
      <div className={cn("flex flex-col gap-2", containerClass)}>
        {label && (
          <label
            htmlFor={props.id}
            className={cn(
              "w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark",
              props.required && "after-star",
              containerClass
            )}
          >
            {label}
          </label>
        )}
        <div className="relative">
          <input
            onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement)?.blur()}
            className={cn(
              "w-full rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent",
              className,
              (type === "password" || search) && "pr-10"
            )}
            ref={ref}
            type={visible ? "text" : type}
            {...props}
          />
          {type === "password" && (
            <div
              className={cn(
                "absolute top-1/2 right-0 flex w-10 -translate-y-1/2 cursor-pointer justify-center p-2 text-new-neutral",
                containerClass
              )}
              onClick={() => setVisible(!visible)}
            >
              {visible ? <NoEyeIcon className="h-4 w-4" /> : <EyeIcon className="h-4 w-4" />}
            </div>
          )}
          {search && (
            <label
              className="absolute top-1/2 right-0 flex w-10 -translate-y-1/2 cursor-pointer justify-center p-2 text-new-neutral"
              htmlFor={props.id}
            >
              <SearchIcon className="h-4 w-4" />
            </label>
          )}
        </div>
      </div>
    )
  }
)

export default Input
