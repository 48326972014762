import OverAllReportRepositry from "domain/repository/Admin/OverallReport"
import GetOverAllReport from "domain/model/GetOverAllReport"
export interface getOverallReportUseCase {
  invoke(details: GetOverAllReport): Promise<any>
}

export class GetOverallReportData implements getOverallReportUseCase {
  private repository: OverAllReportRepositry

  constructor(repository: OverAllReportRepositry) {
    this.repository = repository
  }

  async invoke(details: GetOverAllReport): Promise<any> {
    return await this.repository.getOverallReport(details)
  }
}
