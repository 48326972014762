import { format } from "date-fns"
import { STR_CANCEL, STR_SUBMIT } from "core/constants/strings"
import { CrossIcon, StarIcon } from "core/constants/svgs"
import getOrdinal from "core/utils/ordinal"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import React from "react"

export default function MentorModal({
  sessions,
  index,
  onClose,
  rating,
  setRating,
  comment,
  setComment,
  actionItems,
  setActionItems,
  onSubmit,
  type,
  openedFrom,
  newMentorshipFlow,
}: any) {
  const isReview = type === "review"
  const isReviewDone = sessions?.student_review !== undefined && !isEmpty(sessions?.student_review)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = React.useState(false)
  const isFeedbackDone =
    sessions?.mentor_feedback?.action_items !== undefined && !isEmpty(sessions?.mentor_feedback?.action_items)
  if (openedFrom === "admin") {
    newMentorshipFlow = true
  }
  return newMentorshipFlow ? (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="max-w-xxl m-auto transform space-y-8 rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="flex items-start justify-between gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex gap-1">
                  <h3 className="text-new-accent">{isReview ? "Mentor Session" : "Mentor Feedback"} - </h3>
                  <h4 className="text-neutral flex items-center">
                    {" "}
                    #{getOrdinal(index + 1)} {sessions?.name}
                  </h4>
                </div>
                <p>{isReview ? "Student Review" : "Mentor"}</p>
              </div>
              <button onClick={onClose} className="p-2">
                <CrossIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="flex flex-col gap-4 m-0">
              <div className="flex items-center gap-6">
                {openedFrom === "student" ? <h4>Rate your mentor session </h4> : <h4>Mentor Session Rating </h4>}
                <div className="flex gap-4">
                  {Array(5)
                    .fill(0)
                    .map((_, i) =>
                      !isEmpty(sessions?.[index]?.student_review) && isReview ? (
                        <span key={i}>
                          <StarIcon
                            className="h-6 w-6"
                            pathClassName={
                              sessions?.[index]?.student_review?.rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"
                            }
                          />
                        </span>
                      ) : isReview ? (
                        <button
                          type="button"
                          key={i}
                          onClick={() => setRating(i + 1)}
                          disabled={isReviewDone || openedFrom === "mentor" || openedFrom === "admin"}
                        >
                          <StarIcon
                            className="h-6 w-6"
                            pathClassName={rating !== -1 && rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"}
                          />
                        </button>
                      ) : (
                        <button
                          type="button"
                          key={i}
                          onClick={() => setRating(i + 1)}
                          disabled={isFeedbackDone || openedFrom === "admin"}
                        >
                          <StarIcon
                            className="h-6 w-6"
                            pathClassName={rating !== -1 && rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"}
                          />
                        </button>
                      )
                    )}
                </div>
              </div>
              <h4 className="text-neutral-dark">
                Feedback? <span className="text-red-500">*</span>
              </h4>
              <div>
                {isReview ? (
                  <div className="w-[716px]">
                    <div className="flex flex-col gap-4">
                      <div className="w-[716px] flex flex-col gap-4">
                        <textarea
                          value={comment}
                          onChange={(e) => {
                            if (e.target.value.length <= 400) setComment(e.target.value)
                          }}
                          className="h-32 w-full rounded-sm border border-border bg-transparent p-4 focus:border-black"
                          placeholder="Type your feedback here..."
                          disabled={isReviewDone || openedFrom === "mentor" || openedFrom === "admin"}
                        />
                        <p>{400 - comment?.length || 0} Characters left</p>
                      </div>
                      {/* <p className="bg-neutral-dark h-fit whitespace-pre-wrap rounded-sm border border-border bg-[#F7F7F7] p-4">
                      {sessions?.student_review?.comment}
                    </p> */}
                    </div>
                  </div>
                ) : isFeedbackDone && !isReview ? (
                  <div className="w-[716px]">
                    <div className="flex flex-col gap-4">
                      <p className="bg-neutral-dark h-fit whitespace-pre-wrap rounded-sm border border-border bg-[#F7F7F7] p-4">
                        {sessions?.mentor_feedback?.feedback}
                      </p>
                      <h4 className="text-neutral-dark">
                        Action Items <span className="text-red-500">*</span>
                      </h4>
                      <p className="bg-neutral-dark h-fit whitespace-pre-wrap rounded-sm border border-border bg-[#F7F7F7] p-4">
                        {sessions?.mentor_feedback?.action_items}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="w-[716px] flex flex-col gap-4">
                    <textarea
                      value={comment}
                      onChange={(e) => {
                        if (e.target.value.length <= 400) setComment(e.target.value)
                      }}
                      className="h-32 w-full rounded-sm border border-border bg-transparent p-4"
                      placeholder="Type your feedback here..."
                      disabled={isFeedbackDone || openedFrom === "admin"}
                    />
                    <p>{400 - comment?.length || 0} Characters left</p>
                    <h4 className="text-neutral-dark">
                      Action Items <span className="text-red-500">*</span>
                    </h4>
                    <textarea
                      value={actionItems}
                      onChange={(e) => {
                        if (e.target.value.length <= 400) setActionItems(e.target.value)
                      }}
                      className="h-32 w-full rounded-sm border border-border bg-transparent p-4"
                      placeholder="Type your action items here..."
                      disabled={isFeedbackDone || openedFrom === "admin"}
                    />
                    <p>{400 - (actionItems?.length || 0)} Characters left</p>
                  </div>
                )}
              </div>
              {((!isReviewDone && isReview) || (!isReview && !isFeedbackDone && openedFrom !== "admin")) && (
                <div className="justify flex gap-4">
                  <Button
                    className="w-56"
                    onClick={() => {
                      onSubmit()
                      onClose()
                    }}
                  >
                    {STR_SUBMIT}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="m-auto min-w-[500px] transform space-y-8 rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="flex items-start justify-between gap-4">
              <div className="flex flex-col gap-2 text-new-neutral">
                <div className="flex gap-4 text-new-accent">
                  <p>{isReview ? "My Review" : "Mentor Feedback"}</p>
                  <p>{getOrdinal(index + 1)} Session</p>
                </div>
                {!newMentorshipFlow ? (
                  <p>
                    {/* {isReview ? "Your" : "Mentor"} feedback for the mentor session
                  on {format(new Date(sessions?.[index]?.date), "dd-MM-yyyy")}{" "}
                  from{" "}
                  {format(
                    new Date(Number(sessions?.[index]?.start_timestamp) * 1000),
                    "h:mm a"
                  )}{" "}
                  to{" "}
                  {format(
                    new Date(Number(sessions?.[index]?.end_timestamp) * 1000),
                    "h:mm a"
                  )}
                  . */}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
              <button onClick={onClose} className="p-2">
                <CrossIcon className="h-6 w-6" />
              </button>
            </div>
            {isReview ? (
              <div className="flex flex-col gap-6">
                <div className="flex items-center gap-6">
                  <p>Rate</p>
                  <div className="flex gap-4">
                    {Array(5)
                      .fill(0)
                      .map((_, i) =>
                        !isEmpty(sessions?.[index]?.student_review) ? (
                          <span key={i}>
                            <StarIcon
                              className="h-6 w-6"
                              pathClassName={
                                sessions?.[index]?.student_review?.rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"
                              }
                            />
                          </span>
                        ) : (
                          <button type="button" key={i} onClick={() => setRating(i + 1)}>
                            <StarIcon
                              className="h-6 w-6"
                              pathClassName={rating !== -1 && rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"}
                            />
                          </button>
                        )
                      )}
                  </div>
                </div>
                <div className="flex gap-6">
                  <p>Feedback</p>
                  {!isEmpty(sessions?.[index]?.student_review) ? (
                    <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
                      {sessions?.[index]?.student_review?.comment}
                    </p>
                  ) : (
                    <div className="w-full">
                      <textarea
                        value={comment}
                        onChange={(e) => {
                          if (e.target.value.length <= 400) setComment(e.target.value)
                        }}
                        className="h-32 w-full focus:border-black rounded-sm border border-border bg-transparent p-4"
                        placeholder="Write your feedback here..."
                      />
                      <p>{400 - comment?.length || 0} Characters left</p>
                    </div>
                  )}
                </div>
                {isEmpty(sessions?.[index]?.student_review) && (
                  <div className="flex justify-end gap-4">
                    <Button small failure className="w-fit" type="button" onClick={onClose}>
                      {STR_CANCEL}
                    </Button>
                    <Button small className="w-fit" onClick={onSubmit}>
                      {STR_SUBMIT}
                    </Button>
                  </div>
                )}
              </div>
            ) : !isEmpty(sessions?.[index]?.mentor_feedback) ? (
              <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                  <p>Actions</p>
                  <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
                    {sessions?.[index]?.mentor_feedback?.action_items}
                  </p>
                </div>
                <div className="flex gap-6">
                  <p>Feedback</p>
                  <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
                    {sessions?.[index]?.mentor_feedback?.feedback}
                  </p>
                </div>
              </div>
            ) : (
              <p className="text-center">No mentor feedback is provided by mentor yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
