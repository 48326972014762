import { sendStates } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface updateDisableStateUseCase {
  invoke(details: sendStates): Promise<any>
}

export class UpdateState implements updateDisableStateUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }
  async invoke(details: sendStates): Promise<any> {
    return await this.repository.updateDisableState(details)
  }
}
