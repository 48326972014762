import GetAllClassesDataSource from "data/dataSource/Admin/GetAllClassesDataSource"
import ModuleClassesDataSource from "data/dataSource/Admin/ModuleClassesDataSource"
import ModuleClassDataSource from "data/dataSource/Admin/ModuleClassesDataSource"
import { Auth } from "domain/model/Auth"
import { CreateModuleClass, UpdateModuleClass } from "domain/model/ModuleClass"

export class ModuleClassRepositoryImpl implements ModuleClassRepositoryImpl {
  private dataSource: ModuleClassesDataSource

  constructor(dataSource: ModuleClassesDataSource) {
    this.dataSource = dataSource
  }

  async getModuleClass(auth: Auth, instructor_id: string): Promise<any> {
    return this.dataSource.getModuleClass(auth, instructor_id)
  }

  async createModuleClass(createModuleClass: CreateModuleClass): Promise<any> {
    return this.dataSource.createModuleClass(createModuleClass)
  }

  async deleteModuleClass(auth: Auth, module_id: string): Promise<any> {
    return this.dataSource.deleteModuleClass(auth, module_id)
  }

  async updateModuleClass(updateModuleClass: UpdateModuleClass): Promise<any> {
    return this.dataSource.updateModuleClass(updateModuleClass)
  }
}
