import React from "react"

export default function Feedback6(props: any) {
  const { selectedColor, onHover, onLeave, onClick, index, isSelected } = props

  return (
    <svg
      className={props.className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onLeave()}
      onClick={() => onClick(index)}
      fill={isSelected ? selectedColor : "#ADADAD"}
    >
      <rect width="64" height="64" rx="8" fill={isSelected ? selectedColor : "#D3D3D3"} />
      <circle cx="23.4043" cy="25.4043" r="3.40426" fill="white" />
      <circle cx="41.4472" cy="25.4043" r="3.40426" fill="white" />
      <path d="M22.3833 41.8086H42.4684" stroke="white" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}
