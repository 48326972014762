import DocumentationFileRepositry from "domain/repository/Admin/DocumentationFileRepository"
import { Auth } from "domain/model/Auth"

export interface GetDocumentationFileUseCase {
  invoke(auth: Auth, id: string): Promise<any>
}

export class GetDocumentationFile implements GetDocumentationFileUseCase {
  private repository: DocumentationFileRepositry

  constructor(repository: DocumentationFileRepositry) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string): Promise<any> {
    return await this.repository.get_documentation_file(auth, id)
  }
}
