import { STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import MyMenteeAPIDataSourceImpl from "data/API/Mentor/MyMenteeAPIDataSourceImpl"
import { MyMenteeRepositoryImpl } from "data/repository/Mentor/MyMenteeRepositoryImpl"
import { GetMentorMentee } from "domain/useCase/Mentor/MyMentee/GetMentorMentee"
import React from "react"

export default function MyMenteesViewModel() {
  const [searchByEmail, setSearchByEmail] = React.useState("")
  const [searchByBatch, setSearchByBatch] = React.useState("")
  const [allMenteeList, setAllMenteeList] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false)
  const [newMentorshipFlow, setNewMentorshipFlow] = React.useState(false)
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getMentorMenteeUseCase = new GetMentorMentee(new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl()))

  async function getMentorMentee() {
    setLoading(true)
    const response = await getMentorMenteeUseCase.invoke(auth)

    if (response.success) {
      setAllMenteeList(response.data)
      setLoading(false)
    } else {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }
  function handleSearchByBatch(e: any) {
    if (e.target.value) setSearchByBatch(e.target.value)
    else setSearchByBatch("")
  }
  function searchingForBatch(batch: string | null | undefined) {
    if (batch == "Choose Below") {
      batch = ""
    }
    return function (obj: any) {
      return !batch || obj.batch.toLowerCase().includes(batch.toLowerCase())
    }
  }
  function searchingForEmail(id: string | null | undefined) {
    return function (obj: any) {
      return !id || obj.id.toLowerCase().includes(id.toLowerCase())
    }
  }

  return {
    newMentorshipFlow,
    toast,
    searchByBatch,
    allMenteeList,
    searchByEmail,
    loading,
    setNewMentorshipFlow,
    changeToastVisibility,
    getMentorMentee,
    searchingForBatch,
    searchingForEmail,
    handleSearchByBatch,
    handleSearchByEmail,
  }
}
