import { CreateJob } from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface CreateJobUseCase {
  invoke(request: CreateJob): Promise<any>
}

export class CreateJobs implements CreateJobUseCase {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: CreateJob): Promise<any> {
    return await this.repository.createJob(request)
  }
}
