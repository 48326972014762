import { cn } from "core/lib/utils"

export default function Progress({ bg, width, height, progress }: any) {
  return (
    <div
      className={cn(
        "mt-2 h-[3px] w-[88px] rounded-[42px]",
        width ?? "w-[88px]",
        height ?? "h-[3px]",
        bg ?? "bg-new-solid-white"
      )}
    >
      <div
        className="h-full bg-[#333] rounded-[42px]"
        style={{
          width: `${progress}%`,
        }}
      ></div>
    </div>
  )
}
