import Button from "core/components/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { STR_DATE_AND_TIME_FIELD } from "core/constants/strings"
import React from "react"

export default function UpdateScheduleTest({
  selectBatch,
  handleAddBatch,
  handleScheduleTest,
  scheduleTest,
  batch,
  handleBatchChange,
  removeItem,
  setSelectBatch,
  batchList,
  scheduleTests,
  weeklyTest,
  onChangeTestId,
  updateScheduleTest,
}: any) {
  return (
    <div>
      <div className="">
        <div className="w-full p-4">
          <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Update Test</h1>
          <p className="pb-3 font-medium">Update the test here</p>
        </div>
        <div className="">
          <form
            className="mb-4 w-full"
            id="main-form"
            // onSubmit={createJob}
          >
            <select
              className="para dark:focus:[] mb-4 w-full cursor-pointer appearance-none rounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-500 outline-none dark:text-white dark:focus:ring-blue-500 "
              onChange={onChangeTestId}
              value={scheduleTest?.testId}
              style={{ color: "#A7A7A7" }}
              required
            >
              {!scheduleTest?.testId && (
                <option value="" className="text-neutral-500">
                  Test Id
                </option>
              )}
              {weeklyTest?.map((header: any) => (
                <option className=" bg-neutral-50 text-neutral-500" value={header.id}>
                  {header.name}
                </option>
              ))}
            </select>

            <ul className="mb-4 flex w-full list-disc flex-col border pl-8">
              {selectBatch.map((batches: any, index: any) => (
                <li key={index}>
                  {batches}
                  <IconButton
                    className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
                    onClick={() => setSelectBatch(removeItem(selectBatch, index))}
                    text="X"
                  >
                    X
                  </IconButton>
                </li>
              ))}
            </ul>
            <div className="mb-4 flex w-full justify-between gap-4">
              <StandaredSelect
                required
                options={batchList}
                placeholder="Select Batch"
                onChange={handleBatchChange}
                value={batch}
              />

              <Button className="h-fit w-fit" onClick={handleAddBatch}>
                +
              </Button>
            </div>
            <Input
              name="timestamp"
              type={STR_DATE_AND_TIME_FIELD}
              value={scheduleTest.timestamp}
              onChange={handleScheduleTest}
              placeholder="Test Url"
              className="mb-4"
            />
            <Button onClick={updateScheduleTest}>Update Test</Button>
          </form>
        </div>
      </div>
    </div>
  )
}
