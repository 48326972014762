import { cn } from "core/lib/utils"

export default function Button({ children, className, ...props }: any) {
  return (
    <button
      className={cn(
        "inline-flex select-none items-center justify-center gap-2 rounded-sm px-4 py-2 text-sm font-medium leading-[17px] disabled:opacity-80",
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}
