import React from "react"
import { GetAllSessions } from "domain/useCase/Student/Session/GetAllSessions"
import { SessionRepositoryImpl } from "data/repository/Student/SessionRepositoryImpl"
import SessionAPIDataSourceImpl from "data/API/Student/SessionAPIDataSourceImpl"
import { Session } from "domain/model/Session"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"

export default function ScheduleViewModel() {
  const { auth } = useAuth()
  const { student } = useApp()
  const [sessionInWeekLocally, setWeeklySessionsLocally] = useLocalStorage<any>("session-in-week", [] as Session[])
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [weeklySessions, setWeeklySessions] = React.useState<Session[]>(() => sessionInWeekLocally)
  const [epochTimestamp] = React.useState(Math.floor(new Date().getTime() / 1000))
  const [maxSessions, setMaxSessions] = React.useState(0)

  const getAllSessionsUseCase = new GetAllSessions(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))

  async function fetchSessions() {
    const response = await getAllSessionsUseCase.invoke(auth, epochTimestamp)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Failed to fetch sessions")
      changeToastVisibility(true)
      return
    }

    setWeeklySessions(response?.data)
    setWeeklySessionsLocally(response?.data)
    let max = 0
    response?.data?.forEach((item: any) => (max = Math.max(max, item?.sessions?.length)))
    setMaxSessions(max)
  }

  async function getLocalSessions() {
    if (weeklySessions.length > 0) {
      let max = 0
      weeklySessions.forEach((item: any) => (max = Math.max(max, item?.sessions?.length)))
      setMaxSessions(max)
    }
  }

  async function fetchSessionsDetails() {
    getLocalSessions()
    await fetchSessions()
  }

  return {
    toast,
    student,
    weeklySessions,
    epochTimestamp,
    maxSessions,
    fetchSessionsDetails,
    changeToastVisibility,
  }
}
