interface Mapping {
  [key: string]: string[]
}

const searchMapping: Mapping = {
  // "Onboarding Meeting": [
  //   "onboarding",
  //   "portal walkthrough",
  //   "onboarding meeting",
  // ],
  "Onboarding Team": ["onboaring person", "onboarding Manager", "onboarding team"],
  "Fee Payment": [
    "fee payment",
    "payment",
    "fee",
    "course fee",
    "Seat Booking Fee",
    "Fees",
    "Course Fees",
    "Installment",
    "Next Installment",
  ],
  "Bosscoder Community": [
    "Community",
    "Bosscoder Community",
    "Whatsapp",
    "Whatsapp community",
    "Join Whatsaap",
    "Join community",
  ],
  "Course Prerequisite": ["Prerequisite videos", "Prerequisite", "Course prerequisite"],
  "Goal Setting Mentor Session": [
    "Goal setting session",
    "Milestone session",
    "Join Goal setting session",
    "Book Goal setting session",
    "Join milestone session",
    "Book  milestone session",
  ],
  // "Course Orientation Session": [
  //   "Orientation",
  //   "Orientation class",
  //   "Orientation session",
  // ],
  "Mentor Session": [
    "Mentor session",
    "Book mentor session",
    "Join mentor session",
    "Upcoming mentor session",
    "Next mentor session",
    "Last mentor session",
    "Previous mentor session",
    "Mock Interview",
    "Book Mock Interview",
    "Mentor",
    "Join Mock Interview",
    "Select mentor",
    "Mentor selection",
    "My mentor",
  ],
  "Mentor Change": ["Mentor change", "Change mentor", "New mentor"],
  "Placement Cohort Test": ["Placement cohort", "Placement test", "Placement contest"],
  "Student Progress Meeting": [
    "progress meeting",
    "SSM meet",
    "SSM meeting",
    "SSM call",
    "Student success meeting",
    "student progress meeting",
    "Batch manager meeting",
    "batch manager call",
    "Batch manager meeting",
  ],
  // "Batch Start Date": [
  //   "Batch start",
  //   "Batch start date",
  //   "Batch begin",
  //   "start batch",
  //   "start batch date",
  // ],
  "Course Curriculum": ["Curriculum", "Modules"],
  // "Master Classes": ["Masterclass", "Masterclasses"],
  "Upcoming Classes": ["Upcoming class", "next class", "next session", "Upcoming session"],
  "Past Classes": ["Last session", "Last class", "Past class", "previous session", "past session"],
  "Class Attendance": ["Attendance"],
  "Class Feedback": [
    "Feedback",
    "Class feedback",
    "last class feedback",
    "last session feedback",
    "Previous class feedback",
    "previous session feedback",
  ],
  "Past Class Recordings": [
    "Class recording",
    "past recording",
    "past class recording",
    "past sessions recording",
    "video recording",
    "recorded video",
    "recorded sessions",
    "recorded class",
  ],
  "Class Assignments": [
    "Assignment",
    "Assignments",
    "Last Assignment",
    "Past Assignment",
    "Class Assignment",
    "Homework",
    "Assignment problems",
    "Assignment questions",
    "Homework problems",
    "Homework questions",
  ],
  "Classes Schedule": [
    "Class schedule",
    "classes schedule",
    "weekly schedule",
    "next week schedule",
    "week schedule",
    "Schedule",
  ],
  Exam: [
    "Weekly Test",
    "Weekly contest",
    "weekly assessment",
    "test",
    "Exam",
    "assessment",
    "contest",
    "upcoming test",
    "upcoming contest",
    "previous contest",
    "past contest",
    "past test",
    "past assessment",
    "previous assessment",
    "last assessment",
    "last contest",
    "next contest",
    "next test",
    "next assessment",
  ],
  Leaderboard: [
    "leaderboard",
    "rank",
    "my rank",
    "my position",
    "progress meeting",
    "my progress",
    "last week progress",
    "past week progress",
    "previous week progress",
    "Total problems solved",
    "last week problems solved",
  ],
  Placement: [
    "Placement",
    "Partner companies",
    "Placement process",
    "Recent placements",
    "past placements",
    "previous placements",
    "Bosscoder placements",
  ],
  "Redeem Coins": [
    "Store",
    "Redeem coins",
    "Additional mentor session",
    "voucher",
    "coupons",
    "Amazon coupon",
    "amazon voucher",
  ],
  "Fee Payment Methods": ["Payment methods", "fee payment methods", "fee methods", "methods of payment"],
  "Refer and Earn": [
    "refer and earn",
    "referral",
    "referred",
    "my referral",
    "cashback",
    "referral money",
    "referral cashback",
    "referral amount",
  ],
  "Doubt Support": ["Doubt support", "Help", "help desk"],
  "Batch Change": ["batch change", "change batch", "batch shift", "shift batch", "new batch"],
  "Course Pause": ["course pause", "pause course", "course break"],
  "Resume Course": ["resume course", "rejoin course", "recontinue"],
}

export default searchMapping
