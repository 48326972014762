import { CreateOnboardingManager } from "domain/model/CreateOnboardingManager"
import { CreateOnboardingManagerRepository } from "domain/repository/Admin/CreateOnboardingManagerRepository"

export interface CreateOnboardingManagerUseCase {
  invoke(createOnboardingManager: CreateOnboardingManager): Promise<any>
}

export class CreateOnboardingManagers implements CreateOnboardingManagerUseCase {
  private repository: CreateOnboardingManagerRepository

  constructor(repository: CreateOnboardingManagerRepository) {
    this.repository = repository
  }

  async invoke(createOnboardingManager: CreateOnboardingManager) {
    return await this.repository.createOnboardingManager(createOnboardingManager)
  }
}
