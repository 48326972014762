import { ReferringDetails } from "domain/model/ReferringDetails"
import { referCandidate } from "domain/model/ReferandEarn"
import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"
import { Auth } from "domain/model/Auth"

export interface editReferralFormDataUseCase {
  invoke(request: ReferringDetails, auth: Auth): Promise<any>
}

export class EditReferralFormData implements editReferralFormDataUseCase {
  private repository: ReferAndEarnRepository

  constructor(repository: ReferAndEarnRepository) {
    this.repository = repository
  }

  async invoke(request: ReferringDetails, auth: Auth): Promise<any> {
    return await this.repository.editReferralFormData(request, auth)
  }
}
