import React from "react"

export default function ConfirmPaymentPopup({ onCancel, onConfirm, months }: any) {
  return (
    <React.Fragment>
      <div className="fixed inset-0 bg-black opacity-80 z-10"></div>
      <div className="rounded flex flex-col items-center fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 h-[340px] w-[390px] bg-white p-6 text-black">
        <div className="text-center text-[#414141] font-semibold text-lg">Payment Option</div>

        <button onClick={onCancel} className="absolute top-4 right-4 p-2 z-21">
          <svg className="w-6 h-6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z"
              fill="#414141"
            />
          </svg>
        </button>
        <div className="my-4 text-center text-sm text-[#414141] leading-[22.4px]">
          You have selected <span className="font-semibold">{months} Month EMI Payment</span> as your preferred payment
          option.
        </div>
        <div className="text-[#DC2626] text-sm text-center pb-4">
          You cannot change payment method after document submission. Please select payment method carefully.
        </div>
        <button
          className="w-[192px] h-[52px] font-semibold rounded-sm bg-new-gradient text-white hover:bg-none hover:bg-[#312E81]"
          onClick={onConfirm}
        >
          Continue
        </button>
        <button
          className="mt-2 w-[192px] h-[52px] font-semibold rounded-sm border-[2px] text-[#DC2626] border-[#DC2626] hover:text-white hover:bg-[#DC2626]"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  )
}
