import { useEffect, useState } from "react"

export default function useMobileWarning() {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    async function showMobileWarning() {
      if (window.innerWidth < 500) {
        setIsMobile(true)
        return
      }

      setIsMobile(false)
    }

    showMobileWarning()

    window.addEventListener("resize", showMobileWarning)

    return () => window.removeEventListener("resize", showMobileWarning)
  }, [])

  return {
    isMobile,
  }
}
