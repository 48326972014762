import { ChevronDownIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import React from "react"

export default function SubmissionDisclosure({ label, children }: any) {
  const [open, setOpen] = React.useState(false)

  return (
    <div className="space-y-2">
      <div
        className={cn("flex cursor-pointer items-center gap-4", open && "border-b border-new-editor-dark-50 pb-2")}
        onClick={() => setOpen(!open)}
      >
        <h4 className="text-new-failure">{label}</h4>
        <span className="ml-auto text-new-editor-dark-50">
          <ChevronDownIcon className={cn("h-4 w-4", open && "rotate-180")} />
        </span>
      </div>
      <div className={cn("w-full", !open && "hidden")}>{children}</div>
    </div>
  )
}
