import Modal from "core/components/Modal"
import Button from "core/components/new/Button"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import { Auth } from "domain/model/Auth"
import RemoveIncentive from "domain/useCase/Admin/GenerateInvoice/RemoveIncentive"
import React from "react"
import { useParams } from "react-router-dom"

export default function IncentiveTable(props: any) {
  const { id } = useParams()
  const { auth } = useAuth()
  const incentive = props.incentive?.incentive
  const [yearMonth, setYearMonth] = React.useState("")
  const [confirmModal, setConfirmModal] = React.useState(false)
  const convertToMonthYear = (dateString: string) => {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const date = new Date(`${year}-${month}-01`)
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    })
  }

  const RemoveIncentiveUseCase = new RemoveIncentive(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  function handleOpenModal(key: string) {
    setYearMonth(key)
    setConfirmModal(true)
  }
  function handleCloseModal() {
    setConfirmModal(false)
  }
  async function handleIncentiveDelete(auth: Auth, yearMonth: string) {
    const response = await RemoveIncentiveUseCase.invoke(auth, id as string, yearMonth)

    if (!response?.success) {
      props.changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      props.changeToastVisibility(true)
      return
    }
    props.changeToastDetails(STR_SUCCESS, "Incentive Deleted!")
    props.changeToastVisibility(true)
    props.setReloadData(!props.reloadData)
  }

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.IncentiveTableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {incentive && (
          <tbody>
            {Object.keys(incentive)?.map((key, i) => {
              const data = incentive[key]
              return (
                <tr key={key}>
                  <td className="px-6 py-4 ">{i + 1}</td>
                  <td className="px-6 py-4 text-[16px] font-medium text-new-accent">{data.amount}</td>
                  <td className="px-6 py-4 text-[16px]">{convertToMonthYear(key.toString())}</td>
                  <td>
                    <Button className="h-[10px] w-[120px]" onClick={() => handleOpenModal(key.toString())}>
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      <Modal open={confirmModal} onClose={handleCloseModal}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Delete</h3>
            <p>Are you sure you want to Delete Incentive For {convertToMonthYear(yearMonth)}</p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={handleCloseModal}>
              No
            </Button>
            <Button outlined onClick={() => handleIncentiveDelete(auth, yearMonth)}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
