import { Response } from "core/constants/types"
import { node_api } from "core/utils/axios"

export function getUser(id_token: string) {
  return node_api.get("/user", {
    headers: {
      Authorization: `Bearer ${id_token}`,
    },
  })
}

export function resetPassword(
  id_token: string,
  payload: { email?: string; new_password: string; old_password?: string }
) {
  return node_api.post<Response>("/reset_password", payload, {
    headers: {
      Authorization: `Bearer ${id_token}`,
    },
  }) as unknown as Promise<Response>
}
