import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface deleteStudentCommentUseCase {
  invoke(auth: Auth, comment_details: any): Promise<any>
}

export class DeleteStudentComment implements deleteStudentCommentUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, comment_details: any): Promise<any> {
    return await this.repository.deleteStudentComment(auth, comment_details)
  }
}
