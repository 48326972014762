import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import Select from "core/components/new/Select"
import { AddIcon } from "core/constants/svgs"
import { Fragment, useState } from "react"
import { cn } from "core/lib/utils"
import Accordion from "./Accordion"
import { isEmpty } from "core/utils/misc"
import Options from "./Options"
import Fields from "./Fields"

export default function Response({
  questionResponse,
  addQuestionResponse,
  removeQuestionResponse,
  handleQuestionResponse,
  questionResponseFields,
  addQuestionResponseFields,
  removeQuestionResponseFields,
  handleQuestionResponseFieldsChange,
  questionResponseOptions,
  addQuestionResponseOptions,
  removeQuestionResponseOptions,
  addQuestionResponseOptionField,
  removeQuestionResponseOptionField,
  handleQuestionResponseOptionField,
  activeResponseTab,
  setActiveResponseTab,
}: any) {
  const [isOptionFieldAdded, setIsOptionFieldAdded] = useState<boolean>(false)
  const [isResponseFieldAdded, setIsResponseFieldAdded] = useState<boolean>(false)

  return (
    <div className="w-full flex flex-col items-start content-start gap-6 animate-[pulse-animation_1s_linear_10ms]">
      <div className="w-full flex items-start gap-4 overflow-auto">
        {questionResponse?.map((_: any, index: any) => (
          <div
            className={cn(
              "py-2 px-4 flex flex-col content-center items-center bg-[#E5E5E5] cursor-pointer rounded-sm",
              index === activeResponseTab && "border border-solid border-new-accent"
            )}
            onClick={(e) => {
              setActiveResponseTab(index)
            }}
          >
            <p>{index + 1}</p>
          </div>
        ))}
        <div
          onClick={() => {
            addQuestionResponse()
          }}
          className="py-2 px-4 flex flex-col content-center items-center bg-[#E5E5E5] cursor-pointer rounded-sm"
        >
          <p>+</p>
        </div>
      </div>
      {questionResponse.length === 0 && <h3>Click the + button to add the responses.</h3>}
      {!isEmpty(questionResponse) && (
        <div
          key={activeResponseTab}
          className="w-full flex flex-col items-start content-start gap-6 animate-[pulse-animation_1s_linear_10ms]"
        >
          <div className="relative w-full flex flex-col py-8 px-6 gap-6 items-start content-start border border-solid border-new-neutral rounded-sm">
            <Accordion text={"Question #" + (activeResponseTab + 1).toString()} deps={isResponseFieldAdded}>
              <div className="w-full flex flex-col item-start content-start gap-6">
                <div className="w-full flex flex-col gap-2">
                  <label className="w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark after-star">
                    Question Type
                  </label>
                  <Select
                    name="questionType"
                    id="questionType"
                    defaultOption="Select Question Type"
                    value={questionResponse?.[activeResponseTab].type || ""}
                    onChange={(e) => handleQuestionResponse(e, activeResponseTab, "type")}
                  >
                    <option value={"MCQ"}>MCQ</option>
                    <option value={"Subjective"}>Subjective</option>
                  </Select>
                </div>
                <div className="w-full flex items-start justify-between">
                  <div className="basis-full">
                    <Input
                      placeholder="Coins to be awarded"
                      value={questionResponse?.[activeResponseTab].coins || ""}
                      onChange={(e) => handleQuestionResponse(e, activeResponseTab, "coins")}
                    />
                  </div>
                </div>
                <div className="w-full flex items-start justify-between">
                  <div className="basis-1/2">
                    <Input
                      placeholder="Sub Question Title"
                      value={questionResponse?.[activeResponseTab].subTitle || ""}
                      onChange={(e) => handleQuestionResponse(e, activeResponseTab, "subTitle")}
                    />
                  </div>
                  <div>
                    <Button
                      outlined
                      failure
                      onClick={() => {
                        removeQuestionResponse(activeResponseTab)
                        activeResponseTab !== 0 && setActiveResponseTab(activeResponseTab - 1)
                      }}
                    >
                      Delete Field
                    </Button>
                  </div>
                </div>
                <textarea
                  onChange={(e) => handleQuestionResponse(e, activeResponseTab, "content")}
                  value={questionResponse?.[activeResponseTab].content || ""}
                  placeholder="Add your question text here!"
                  className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent overflow-auto text-new-netural"
                >
                  {questionResponse?.[activeResponseTab].content}
                </textarea>
                <Fields
                  fields={questionResponseFields?.[activeResponseTab]}
                  fieldTypes={["image", "code"]}
                  onFieldChange={handleQuestionResponseFieldsChange}
                  onDeleteField={removeQuestionResponseFields}
                  isResponseField={true}
                  isOptionField={false}
                  responseId={activeResponseTab}
                />
              </div>
            </Accordion>
            <div className="w-full h-px border border-dashed border-new-neutral"></div>
            <button
              className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
              onClick={() => {
                addQuestionResponseFields(activeResponseTab)
                setIsResponseFieldAdded(!isResponseFieldAdded)
              }}
            >
              <AddIcon className="h-4 w-4" />
              <p>Add Question Data Fields</p>
            </button>
          </div>
          {/* *********************** Question response options *******************/}
          {questionResponse?.[activeResponseTab]?.type === "MCQ" && (
            <Fragment>
              <button
                className="w-full py-2 px-4 border border-solid border-new-neutral rounded-sm"
                onClick={() => addQuestionResponseOptions(activeResponseTab)}
              >
                <div className="flex py-2 px-4 items-start gap-4 cursor-pointer">
                  <p className="text-black font-semibold">Add Options</p>
                  <AddIcon className="h-5 w-5" />
                </div>
              </button>
              {questionResponseOptions?.[activeResponseTab] && (
                <Fragment>
                  <div>
                    <h4>Data Types</h4>
                  </div>
                  <button
                    className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
                    onClick={() => {
                      addQuestionResponseOptionField(activeResponseTab)
                      setIsOptionFieldAdded(!isOptionFieldAdded)
                    }}
                  >
                    <AddIcon className="h-4 w-4" />
                    <p>Add Fields</p>
                  </button>
                </Fragment>
              )}
              <Options
                isOptionFieldAdded={isOptionFieldAdded}
                activeResponseTab={activeResponseTab}
                options={questionResponseOptions?.[activeResponseTab]}
                removeOption={removeQuestionResponseOptions}
                onOptionFieldChange={handleQuestionResponseOptionField}
                removeOptionField={removeQuestionResponseOptionField}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  )
}
