import { ChevronDownIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { Fragment, useRef, RefObject, useState, useLayoutEffect } from "react"

export default function Accordion({ text, children, deps }: any) {
  const divRef: RefObject<HTMLDivElement> = useRef(null)
  const [isOpen, setIsOpen] = useState<boolean>(true)

  useLayoutEffect(() => {
    if (divRef.current !== null) {
      const h = (10 + divRef.current.scrollHeight).toString()
      divRef.current.style.maxHeight = h + "px"
    }
  }, [deps])

  function toggleOpen() {
    if (divRef.current !== null) {
      if (isOpen === false) {
        const h = (10 + divRef.current.scrollHeight).toString()
        divRef.current.style.maxHeight = h + "px"
      } else {
        divRef.current.style.maxHeight = "0px"
      }
    }
    setIsOpen(!isOpen)
  }

  return (
    <Fragment>
      <div
        className="w-full flex py-2 px-4 items-start justify-between bg-[#E5E5E5] rounded-sm cursor-pointer"
        onClick={() => toggleOpen()}
      >
        <p className="font-medium">{text}</p>
        <ChevronDownIcon
          className={cn("w-5 h-5 transition-all duration-300 ease-linear", !isOpen ? "rotate-0" : "rotate-180")}
        />
      </div>
      <div ref={divRef} className="w-full overflow-hidden transition-all duration-300 ease-in-out">
        {children}
      </div>
    </Fragment>
  )
}
