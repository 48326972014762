import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface GetAllTempMentorsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllTempMentors implements GetAllTempMentorsUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getAllTempMentors(auth)
  }
}
