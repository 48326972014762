import React, { useEffect, useState } from "react"
import { GetMonthlyFeedback } from "domain/useCase/Student/MonthlyFeedback/GetMonthlyFeedback"
import { MonthlyFeedbackRepositoryImpl } from "data/repository/Student/MonthlyFeedbackRepositoryImpl"
import { format } from "date-fns"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import MonthlyFeedbackAPIDataSourceImpl from "data/API/Student/MonthlyFeedbackAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { GetMonthlyFeedbackArchived } from "domain/useCase/Student/MonthlyFeedback/GetMonthlyFeedbackArchived"
import Papa from "papaparse"
import { saveAs } from "file-saver"

export default function FeedbackViewModel() {
  const { auth } = useAuth()
  const [monthlyFeedback, setMonthlyFeedback] = useState<any[]>([])
  const [allMonthFeedback, setAllMonthFeedback] = useState<any[]>([])
  const [data, setData] = useState({
    header: [],
    rows: [],
  })
  const [searchByEmail, setSearchByEmail] = useState("")
  const [studentBatch, setStudentBatch] = useState("")
  const [batch, setBatch] = useState<any>([])
  const [range, setRange] = useState({
    start_index: 0,
    end_index: 100,
  })
  const [average, setAverage] = useState<number>(0)
  const [median, setMedian] = useState<number>(0)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [studentsByEmail, setStudentsByEmail] = useState([])
  const [studentsByBatch, setStudentsByBatch] = useState([])
  const [stduentByDateRange, setStudentsByDateRange] = useState([])
  const [first100, setfirst100] = useState<any>([])
  const [firstFiltered100, setfirstFiltered100] = useState<any>([])
  const [baseData, setBaseData] = useState<any[]>([])
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)
  const [studentByRatingRange, setStudentsByRatingRange] = useState([])
  const [minRating, setMinRating] = React.useState(0)
  const [maxRating, setMaxRating] = React.useState(0)
  const [filteredMedian, setFilteredMedian] = React.useState(0)
  const [filteredAverage, setFiltereedAverage] = React.useState(0)
  const [npsScore, setNpsScore] = React.useState(0)
  const [promoters, setPromoters] = React.useState(0)
  const [detractors, setDetractors] = React.useState(0)
  const [totalreponses, setTotalreponses] = React.useState(0)

  const GetMonthlyFeedbackUseCase = new GetMonthlyFeedback(
    new MonthlyFeedbackRepositoryImpl(new MonthlyFeedbackAPIDataSourceImpl())
  )

  const GetMonthlyFeedbackArchivedUseCase = new GetMonthlyFeedbackArchived(
    new MonthlyFeedbackRepositoryImpl(new MonthlyFeedbackAPIDataSourceImpl())
  )
  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)
    if (response.success) {
      setBatch(response.data)
    }
  }

  function handleBatchChange(e: any) {
    if (e.target.value === "Select Batch") {
      setStudentBatch("")
    } else setStudentBatch(e.target.value)
  }

  async function fetchAllFeedback() {
    // const range = {
    //   start_index: 0,
    //   end_index: 100,
    // };
    try {
      const response = await GetMonthlyFeedbackUseCase.invoke(auth, range)
      if (!response?.success) {
        console.error(response?.error || "Failed to fetch feedback")
        return
      }

      const feedbackList = response?.data?.feedback_list || []
      setMonthlyFeedback(feedbackList)
      setAllMonthFeedback(feedbackList)
      setfirst100(feedbackList)
      setfirstFiltered100(feedbackList.slice(0, 100))
      setAverage(response?.data?.average_rating || 0)
      setMedian(response?.data?.median_rating || 0)
    } catch (error) {
      console.error("Error fetching feedback:", error)
    }
  }

  async function fetchAfter3months() {
    const response = await GetMonthlyFeedbackArchivedUseCase.invoke(auth, range)
    if (!response?.success) {
      console.error(response?.error || "Failed to fetch feedback")
      return
    }

    const feedbackList = response?.data?.feedback_list || []
    setAllMonthFeedback([...allMonthFeedback, ...feedbackList])
  }

  interface Feedback {
    "1": number
    "2": string
    "3": string
    email: string
    submit_timestamp: number
  }

  const [curIdx, setCurIdx] = useState(100)
  function appendNext100() {
    if (searchByEmail || studentBatch || (toDate && fromDate) || (minRating > 0 && maxRating > 0)) {
      const next100: any = first100.slice(curIdx, curIdx + 100)
      setfirstFiltered100([...firstFiltered100, ...next100])

      setCurIdx(curIdx + 100)
    } else {
      const next100: any = allMonthFeedback.slice(curIdx, curIdx + 100)
      setfirstFiltered100([...firstFiltered100, ...next100])

      setCurIdx(curIdx + 100)
    }
  }

  function HandleTableData() {
    const header: any = ["Name", "Email", "Batch", "Rate", "Reviews", "Suggestion", "Month", "Year"]
    let rows: any = []

    rows = monthlyFeedback?.map((f: any) => [
      f["name"],
      f["email"],
      f["batch"],
      <div>{f["1"]}</div>,
      <div className="h-auto w-[200px] whitespace-normal">{f["2"]}</div>,
      <div className="h-auto w-[200px] whitespace-normal">{f["3"]}</div>,
      format(parseInt(f["submit_timestamp"]) * 1000, "MMMM"),
      format(parseInt(f["submit_timestamp"]) * 1000, "y"),
    ])

    setData({ header, rows })
  }

  function handleChange(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }

  function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const dateValue = e.target.value
    const timestamp = dateValue ? new Date(dateValue).getTime() / 1000 : null

    if (e.target.id === "fromDate") {
      setFromDate(timestamp)
    } else if (e.target.id === "toDate") {
      setToDate(timestamp)
    }
  }

  useEffect(() => {
    let temp: any = []

    temp = allMonthFeedback.filter((feedback: any) => {
      let check: any = true
      check =
        check &&
        (!searchByEmail || feedback?.email === searchByEmail) &&
        (!studentBatch || feedback?.batch === studentBatch) &&
        (!(minRating && maxRating) || (feedback?.[1] >= minRating && feedback?.[1] <= maxRating)) &&
        (!(fromDate && toDate) || (fromDate <= feedback?.submit_timestamp && feedback?.submit_timestamp <= toDate))

      return check
    })
    if (searchByEmail || studentBatch || (toDate && fromDate) || (minRating >= 0 && maxRating >= 0)) {
      setfirst100(temp)
      setfirstFiltered100(temp.slice(0, 100))
      setCurIdx(100)
    } else {
      setfirst100(allMonthFeedback)
      setfirstFiltered100(allMonthFeedback.slice(0, 100))
      setCurIdx(100)
    }
    const values = temp.map((f: any) => f?.[1])
    const totalResponses = values.length
    setTotalreponses(totalResponses)
    const promoters = values.filter((value: any) => value > 8).length
    setPromoters(promoters)
    const detractors = values.filter((value: any) => value <= 6).length
    setDetractors(detractors)
    const npsScore = ((promoters - detractors) / totalResponses) * 100
    setNpsScore(npsScore)
  }, [searchByEmail, studentBatch, toDate, fromDate, minRating, maxRating])

  const DownloadCSV = () => {
    const csvData = first100.map((feedback: any) => {
      return {
        Name: feedback.name || "N/A",
        Email: feedback.email || "N/A",
        Batch: feedback.batch || "N/A",
        Rating: feedback["1"] || "N/A",
        Review: feedback["2"] || "N/A",
        Suggestion: feedback["3"] || "N/A",
        Month: format(parseInt(feedback.submit_timestamp) * 1000, "MMMM"),
        Year: format(parseInt(feedback.submit_timestamp) * 1000, "yyyy"),
      }
    })
    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    saveAs(blob, "NPS Data.csv")
  }

  return {
    monthlyFeedback,
    // fetchStudentsByDateRange,
    fetchAllFeedback,
    setMonthlyFeedback,
    HandleTableData,
    data,
    fromDate,
    DownloadCSV,
    toDate,
    studentsByEmail,
    studentsByBatch,
    studentByRatingRange,
    // filterByDateRange,
    // filterByEmail,
    searchByEmail,
    handleChange,
    handleDateChange,
    studentBatch,
    stduentByDateRange,
    // filterByBatch,
    // downloadMonthlyFeedback,
    // handleMonthChange,
    // filterByMonth,
    allMonthFeedback,
    handleBatchChange,
    batch,
    getBatches,
    // handleNext,
    // fetchStudentByEmail,
    // fetchStudentsByBatch,
    // fetchStudentsByRatingRange,
    appendNext100,
    setMinRating,
    setMaxRating,
    curIdx,
    first100,
    firstFiltered100,
    filteredAverage,
    filteredMedian,
    setSearchByEmail,
    fetchAfter3months,
    setFromDate,
    totalreponses,
    setToDate,
    setfirstFiltered100,
    setFilteredMedian,
    setFiltereedAverage,
    setStudentBatch,
    setfirst100,
    average,
    promoters,
    detractors,
    npsScore,
    median,
  }
}
