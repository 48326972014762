import Button from "core/components/new/Button"
import { CopyIcon, DeleteIcon, EditIcon } from "core/constants/svgs"

export default function McqCard({ data, onEdit, onDelete, copyQuestionToClipboard }: any) {
  return (
    <div className="flex justify-between gap-6 p-6 shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <h3 className="text-new-accent">{data?.name}</h3>
          <button className="ml-2" onClick={() => copyQuestionToClipboard(data?.id)}>
            <CopyIcon className="w-5 h-5 text-gray-400" />
          </button>
        </div>
        <p className="">
          {`${data?.tags?.join(" | ")}${data?.tags?.length > 0 ? " | " : ""}`}
          {data?.difficulty}
        </p>
      </div>
      <div className="flex gap-2">
        <Button outlined className="h-fit w-fit" onClick={onEdit}>
          <EditIcon className="w-5" />
        </Button>
        <Button outlined className="h-fit w-fit text-red-500 border-red-500 hover:bg-red-500" onClick={onDelete}>
          <DeleteIcon className="w-5" />
        </Button>
      </div>
    </div>
  )
}
