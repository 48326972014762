import React from "react"

const RedirectArrow = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M9.72669 10.6693L3.98802 4.93131L4.93069 3.98865L10.6687 9.72598V4.66931H12.002V12.0026H4.66869V10.6693H9.72669Z"
        fill="#646464"
      />
    </svg>
  )
}

export default RedirectArrow
