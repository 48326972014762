import StandaredSelect from "core/components/StandaredSelect"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"
import StoreViewModel from "../StoreViewModel"
import useToast from "core/hooks/useToast"

export default function CreateCoupon({
  couponId,
  couponType,
  amount,
  purchaseDate,
  expiryDate,
  referenceId,
  handleReferenceId,
  createCouponLoader,
  AddNewCoupon,
  handleChangeCouponId,
  handleCouponType,
  handleAmount,
  handleChangePurchaseDate,
  handleChangeExpiryDate,
}: any) {
  const COUPONARRAY = ["Amazon Coupon", "Mentor Session"]
  const AMOUNTARRAY = ["Amount", 50, 75, 100, 200, 500]

  return (
    <div className="space-y-4">
      <p className="text-xl font-medium">Add Coupon</p>
      <Input type={STR_TEXT_FIELD} value={referenceId} onChange={handleReferenceId} placeholder="Reference ID" />
      <Input type={STR_TEXT_FIELD} value={couponId} onChange={handleChangeCouponId} placeholder="Coupon Code" />
      <StandaredSelect required options={AMOUNTARRAY} value={amount} onChange={handleAmount} placeholder="Amount" />
      <Input
        type="text"
        value={purchaseDate}
        onChange={handleChangePurchaseDate}
        onFocus={(e) => (e.target.type = "date")}
        onBlur={(e) => (e.target.type = "text")}
        placeholder="Purchase Date"
      />
      <Input
        type="text"
        value={expiryDate}
        onChange={handleChangeExpiryDate}
        onFocus={(e) => (e.target.type = "date")}
        onBlur={(e) => (e.target.type = "text")}
        placeholder="Expiry Date"
      />
      <Button
        disabled={amount && purchaseDate && expiryDate && couponId && referenceId ? false : true}
        loading={createCouponLoader}
        onClick={AddNewCoupon}
      >
        Add Coupon
      </Button>
    </div>
  )
}
