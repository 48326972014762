import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Student/ProfileRepository"

export interface UploadProfilePictureUseCase {
  invoke(auth: Auth, file: File): Promise<any>
}

export class UploadProfilePicture implements UploadProfilePictureUseCase {
  private repository: ProfileRepository

  constructor(repository: ProfileRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, file: File) {
    return await this.repository.uploadProfilePicture(auth, file)
  }
}
