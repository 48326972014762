import { resetPassword } from "core/api/auth"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import { ChangeEvent, FormEvent, useState } from "react"
import { useParams } from "react-router-dom"

export default function ResetPasswordViewModel() {
  const [student, setStudent] = useLocalStorage<any>("student", {})
  const [newPassword, setNewPassword] = useState<string>("")
  const [oldPassword, setOldPassword] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { id } = useParams()
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const getUserDataUseCase = new GetAllStudentData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const fetchUser = async () => {
    setIsLoading(true)

    const response = await getUserDataUseCase.invoke({
      admin_uid: auth.local_id,
      email: id as string,
    })

    if (!response) {
      changeToastDetails(STR_FAILURE, "Error while fetching user data")
      changeToastVisibility(true)
      setIsLoading(false)
      return
    }

    const user = response?.response
    const student = user?.student
    const password = student?.password

    setStudent(student)
    setOldPassword(password)
    setIsLoading(false)
  }

  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
  }

  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault()

    if (!newPassword) return

    const email = id!

    try {
      setIsSubmitting(true)

      const response = await resetPassword(auth.id_token, { email, new_password: newPassword })

      if (!response.success) {
        changeToastDetails(STR_FAILURE, response.error)
      } else {
        changeToastDetails(STR_SUCCESS, response.data)
        setStudent((student: any) => ({ ...student, password: newPassword }))
      }
    } catch (e) {
      changeToastDetails(STR_FAILURE, "Unknown error occurred")
    }

    changeToastVisibility(true)
    setIsSubmitting(false)
  }

  return {
    newPassword,
    toast,
    oldPassword,
    student,
    isLoading,
    isSubmitting,
    handleChangeNewPassword,
    handleResetPassword,
    changeToastVisibility,
    fetchUser,
    setOldPassword,
  }
}
