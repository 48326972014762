import HomeRepository from "domain/repository/Admin/HomeRepository"
import { Auth } from "domain/model/Auth"

export interface GetAllMentorsUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllMentors implements GetAllMentorsUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllMentors(auth)
  }
}
