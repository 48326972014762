export default function Flag({ text, bg, color }: any) {
  const width = text.length * 7 + 36

  return (
    <svg
      height="24"
      width={width + 12}
      viewBox={`0 0 ${width + 12} 24`}
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={`M 0 0 H ${width} L ${width + 12} 12 L ${width} 24 H 0 L 12 12 L 0 0 Z`} fill={bg ?? "#DC2626"} />
      <text x={24} y={16} className="text-xs font-semibold text-new-solid-white" fill={color ?? "currentColor"}>
        {text}
      </text>
    </svg>
  )
}
