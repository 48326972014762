import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface GetMentorSessionsByStudentUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetMentorSessionsByStudent implements GetMentorSessionsByStudentUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.getMentorSessionsByStudent(auth, data)
  }
}
