import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useMonthlySessionsViewModel from "./MonthlySessionsViewModel"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Table from "core/components/new/Table"
import { isEmpty } from "core/utils/misc"

export default function MonthlySessionsView() {
  const {
    fromDate,
    toDate,
    toast,
    sessionTableData,
    loading,
    handleFromDateChange,
    handleToDateChange,
    handleFetchMonthlyMentorSession,
    changeToastVisibility,
  } = useMonthlySessionsViewModel()

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <h2 className="text-new-accent">Monthly Sessions</h2>
        <form className="flex items-end gap-6" onSubmit={handleFetchMonthlyMentorSession}>
          <Input
            id="fromDate"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            label="From Date"
            containerClass="w-full max-w-xs"
          />
          <Input
            id="toDate"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            label="To Date"
            containerClass="w-full max-w-xs"
          />
          <Button small className="h-fit w-full max-w-[160px]" loading={loading} disabled={loading}>
            View Sessions
          </Button>
        </form>

        {!isEmpty(sessionTableData?.header) && <Table data={sessionTableData} />}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
