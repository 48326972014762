import React from "react"

const LinearGradientBottomLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="248" height="162" viewBox="0 0 248 162" fill="none">
      <path d="M81.4552 162H195.185L113.73 0H0L81.4552 162Z" fill="url(#paint0_linear_2891_69654)" fill-opacity="0.2" />

      <path
        d="M134.27 162H248L166.544 0H52.8145L134.27 162Z"
        fill="url(#paint1_linear_2891_69654)"
        fill-opacity="0.2"
      />

      <defs>
        <linearGradient
          id="paint0_linear_2891_69654"
          x1="185.964"
          y1="280.059"
          x2="66.7368"
          y2="26.8516"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2891_69654"
          x1="238.778"
          y1="280.059"
          x2="186.5"
          y2="73"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LinearGradientBottomLeft
