import { useLocation, useNavigate, useParams } from "react-router-dom"
import DashboardLayout from "core/layouts/DashboardLayout"
import useLocalStorage from "core/hooks/useLocalStorage"
import HomeVideoCard from "./Cards/HomeVideoCard"
import TableButton from "core/components/v2/TableButton"
import ArrowBack from "core/components/v2/ArrowBack"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function HomeVideoSection({ type }: any) {
  const [mappingDetails] = useLocalStorage<any>(type, {})
  const { search } = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()

  const videoIndex = mappingDetails?.findIndex((item: any) => item?.video_id === id)
  const minutes = Math.floor(mappingDetails[videoIndex]?.video_len / 60)
  const extraSeconds = mappingDetails[videoIndex]?.video_len % 60

  return (
    <DashboardLayoutv2>
      <div className="flex w-full flex-col space-y-3 p-6">
        <div className="flex justify-between">
          <div className="flex flex-col gap-y-1">
            <h3 className="text-sm font-semibold text-[#333]">{mappingDetails[videoIndex]?.name}</h3>
            <p className="text-sm font-normal text-[#646464]">
              Duration: {minutes}:{extraSeconds < 10 ? "0" + extraSeconds : extraSeconds} Mins
            </p>
          </div>
          <TableButton
            className="flex h-fit w-fit items-center justify-center gap-1"
            outlined
            onClick={() => navigate("/home")}
          >
            <ArrowBack />
            <p className="text-sm font-medium">Go Back</p>
          </TableButton>
        </div>
        <div className="flex w-full justify-between gap-x-6">
          <div className="h-fit w-4/5 max-w-[745px] rounded-lg">
            {typeof id !== "undefined" && typeof search !== "undefined" ? (
              <iframe
                title="Video"
                className="aspect-video h-full w-full rounded-lg"
                src={`https://player.vimeo.com/video/${id}${search}`}
                allow="autoplay; fullscreen; picture-in-picture"
              ></iframe>
            ) : (
              <div className="grid aspect-video h-full w-full place-items-center font-medium">
                <span>Video not available!</span>
              </div>
            )}
          </div>
          <div className="space-y-4 rounded-lg bg-[#e3e3e3] pb-2 pr-1 shadow-[-1px_1px_3px_0pxr_rgba(132,136,139,0.05)]">
            <p className="px-4 pt-4 text-start text-sm font-semibold capitalize text-[#333]">
              {type === "meet_and_greet" ? "Meet And Greet" : type}
            </p>
            <div className="gray-scrollbar flex h-[60vh] w-[225px] max-w-[225px] flex-col items-center gap-y-4 overflow-y-auto px-2 pr-1">
              {mappingDetails?.map((item: any, index: number) => (
                <HomeVideoCard key={index} data={item} index={index} type={type} selected={index === videoIndex} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayoutv2>
  )
}
