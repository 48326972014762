import DashboardLayout from "core/layouts/DashboardLayout"
import useViewModel from "./ViewModel"
import useSubCaseViewModel from "./Questions/SubCaseQuestionViewModel"
import Input from "core/components/new/Input"
import { useState, useEffect } from "react"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import Select from "core/components/new/Select"
import { cn } from "core/lib/utils"
import ButtonGroup from "core/components/ButtonGroup"
import SearchIcon from "assets/svgs/SearchIcon"
import Accordion from "./Components/Accordion"
import CopyIcon from "assets/svgs/Copy"
import { DeleteIcon, EditIcon } from "core/constants/svgs"
import LeftArrow from "assets/svgs/LeftArrow"
import RightArrow from "assets/svgs/RightArrow"
import ConfirmationPopUp from "./Components/ConfirmPopup"
import { isEmpty } from "core/utils/misc"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { genError } from "core/utils/string"

export default function QuestionsChamberView() {
  const {
    pageLoading,
    toast,
    changeToastVisibility,
    AddQuestion,
    editQuestion,
    duplicateQuestion,
    onDeleteQuestion,
    allQuestions,
    filterQuestions,
    clearFilters,
    filters,
    handelFiltersChange,
    currentPage,
    setCurrentPage,
    totalPages,
    loadQuestionsData,
  } = useViewModel()

  const { allCourses, allModules, allTopics, fetchAllModules, fetchAllTopics } = useSubCaseViewModel()

  const TABS = ["Active Questions", "Inactive Questions"]
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [isConfrimationPopUpOpen, setIsConfrimationPopUpOpen] = useState<boolean>(false)
  const [selectQuestion, setSelectQuestion] = useState<any>({
    id: "",
    questionType: "",
  })
  const [questionTab, setQuestionTab] = useState<"Case Study" | "Subjective">("Case Study")

  useEffect(() => {
    loadQuestionsData(filters)
  }, [currentPage])

  return (
    <DashboardLayout>
      {isConfrimationPopUpOpen && (
        <ConfirmationPopUp
          isOpen={isConfrimationPopUpOpen}
          setIsOpen={setIsConfrimationPopUpOpen}
          id={selectQuestion.id}
          questionType={selectQuestion.questionType}
          deleteQuestion={onDeleteQuestion}
          heading="Are you sure you want to delete this question?"
        />
      )}
      <div className="w-full inline-flex flex-col justify-center items-center gap-6 p-6">
        <div className="w-full flex flex-col items-start gap-4">
          <div>
            <h2 className="text-new-accent">Subjective/Case Studies</h2>
            <p>View, Edit and manage all subjective / Case Studies question here.</p>
          </div>
          <div className="w-full flex flex-wrap p-6 items-end content-end gap-6 border-solid border-2 border-gray-200 rounded-sm">
            <Accordion text="Filters">
              <QuestionFilter
                allCourses={allCourses}
                allModules={allModules}
                allTopics={allTopics}
                filters={filters}
                fetchAllModules={fetchAllModules}
                fetchAllTopics={fetchAllTopics}
                handelFiltersChange={handelFiltersChange}
                filterQuestions={filterQuestions}
                clearFilters={clearFilters}
              />
            </Accordion>
          </div>
          <div
            onClick={() => AddQuestion()}
            className="w-full p-6 text-center border-solid border-2 border-gray-200 rounded-sm cursor-pointer"
          >
            <p className="font-semibold text-lg">+ Add More Question</p>
          </div>
        </div>
        <div className="w-full">
          <ButtonGroup buttons={TABS} active={currentTab} onChange={setCurrentTab} />
        </div>
        <div className="w-full flex flex-row items-start content-start gap-4">
          {/* <button
            className={`text-sm py-2 px-4 border border-solid border-new-neutral rounded-sm ${questionTab === "all" ? "bg-new-accent text-white" : ""}`}
            onClick={() => setQuestionTab("all")}
          >
            All Problems
          </button> */}
          <button
            className={`text-sm py-2 px-4 border border-solid border-new-neutral rounded-sm ${questionTab === "Case Study" ? "bg-new-accent text-white" : ""}`}
            onClick={() => setQuestionTab("Case Study")}
          >
            Case Studies
          </button>
          <button
            className={`text-sm py-2 px-4 border border-solid border-new-neutral rounded-sm ${questionTab === "Subjective" ? "bg-new-accent text-white" : ""}`}
            onClick={() => setQuestionTab("Subjective")}
          >
            Subjective Questions
          </button>
        </div>
        <ConditionalLoader isLoading={pageLoading} loader={<Loader />}>
          {isEmpty(allQuestions?.[currentTab]?.filter((question: any) => question.questionType === questionTab)) ? (
            <h2 className="p-5">No Question Found</h2>
          ) : (
            allQuestions?.[currentTab]
              ?.filter((question: any) => question.questionType === questionTab)
              ?.map((question: any, index: number) => (
                <QuestionViewCard
                  key={index}
                  index={index}
                  currentPage={currentPage}
                  question={question}
                  duplicateQuestion={duplicateQuestion}
                  editQuestion={editQuestion}
                  setSelectQuestion={setSelectQuestion}
                  setIsConfrimationPopUpOpen={setIsConfrimationPopUpOpen}
                />
              ))
          )}
        </ConditionalLoader>
        {isEmpty(allQuestions?.[currentTab]?.filter((question: any) => question.questionType === questionTab)) ? (
          <div></div>
        ) : (
          <div className="flex items-start justify-center gap-4">
            <div
              className="flex w-8 h-8 p-2 content-center items-center flex-shrink-0 bg-new-accent rounded-sm mr-4 cursor-pointer"
              onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
            >
              <LeftArrow />
            </div>
            {currentTab === 0
              ? totalPages["active"]?.map((num: number) => (
                  <div
                    key={num}
                    className={cn(
                      "flex w-10 h-8 py-2 px-0 justify-center items-center flex-shrink-0 bg-[#F9F9F9] cursor-pointer rounded-sm",
                      currentPage === num ? "text-black" : "text-gray-300"
                    )}
                    onClick={() => setCurrentPage(num)}
                  >
                    {num}
                  </div>
                ))
              : totalPages["inactive"]?.map((num: number) => (
                  <div
                    key={num}
                    className={cn(
                      "flex w-10 h-8 py-2 px-0 justify-center items-center flex-shrink-0 bg-[#F9F9F9] cursor-pointer rounded-sm",
                      currentPage === num ? "text-black" : "text-gray-300"
                    )}
                    onClick={() => setCurrentPage(num)}
                  >
                    {num}
                  </div>
                ))}
            <div
              className="flex w-8 h-8 p-2 content-center items-center flex-shrink-0 bg-new-accent rounded-sm ml-4 cursor-pointer"
              onClick={() =>
                (currentPage < totalPages["active"].length || currentPage < totalPages["inactive"].length) &&
                setCurrentPage(currentPage + 1)
              }
            >
              <RightArrow />
            </div>
          </div>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}

function QuestionFilter({
  allCourses,
  allModules,
  allTopics,
  filters,
  fetchAllModules,
  fetchAllTopics,
  handelFiltersChange,
  filterQuestions,
  clearFilters,
}: any) {
  return (
    <div className="w-full">
      <div className="w-full inline-flex items-start gap-6">
        <div className="basis-1/5 flex flex-col items-start justify-center gap-2">
          <label className="text-md font-semibold text-[#414141]">Course</label>
          <Select
            className="w-full"
            defaultOption={"All Courses"}
            value={filters?.course || ""}
            onChange={(e) => {
              fetchAllModules(e.target.value)
              handelFiltersChange(e, "course")
            }}
          >
            {allCourses?.map((course: any, index: number) => (
              <option key={index} value={course}>
                {course}
              </option>
            ))}
          </Select>
        </div>
        <div className="basis-1/5 flex flex-col items-start justify-center gap-2">
          <label className="text-md font-semibold text-[#414141]">Module</label>
          <Select
            className="w-full"
            defaultOption={"All Module"}
            value={filters?.module || ""}
            onChange={(e) => {
              fetchAllTopics(e.target.value)
              handelFiltersChange(e, "module")
            }}
          >
            {allModules?.map((module: any, index: number) => (
              <option key={index} value={module}>
                {module}
              </option>
            ))}
          </Select>
        </div>
        <div className="basis-1/5 flex flex-col items-start justify-center gap-2">
          <label className="text-md font-semibold text-[#414141]">Topic</label>
          <Select
            className="w-full"
            defaultOption={"Topic"}
            value={filters?.topic || ""}
            onChange={(e) => {
              handelFiltersChange(e, "topic")
            }}
          >
            {allTopics?.map((data: any, index: number) => (
              <option key={index} value={data.topic}>
                {data.topic}
              </option>
            ))}
          </Select>
        </div>
        <div className="basis-1/5 flex flex-col items-start justify-center gap-2">
          <label className="text-md font-semibold text-[#414141]">Problem Level</label>
          <Select
            className="w-full"
            defaultOption={"Problem Level"}
            value={filters?.difficultyLevel || ""}
            onChange={(e) => handelFiltersChange(e, "difficultyLevel")}
          >
            <option value={"easy"}>Easy</option>
            <option value={"medium"}>Medium</option>
            <option value={"hard"}>Hard</option>
          </Select>
        </div>
      </div>
      <div className="w-full flex items-start content-start py-6 gap-6">
        <div className="basis-1/3 relative">
          <Input
            label={"Question Title"}
            placeholder="Type your keyword here!"
            value={filters?.questionTitle || ""}
            onChange={(e) => handelFiltersChange(e, "questionTitle")}
          />
          <div className="absolute bottom-0 right-0 -translate-y-2 -translate-x-2">
            <SearchIcon className="w-5 h-5" />
          </div>
        </div>
        <Button outlined className="basis-1/5 mt-[30px]" onClick={() => filterQuestions()}>
          Search
        </Button>
        <Button outlined failure className="basis-1/5 mt-[30px]" onClick={() => clearFilters()}>
          Clear Filters
        </Button>
      </div>
    </div>
  )
}

function QuestionViewCard({
  index,
  currentPage,
  question,
  duplicateQuestion,
  editQuestion,
  setSelectQuestion,
  setIsConfrimationPopUpOpen,
}: any) {
  const origin = "https://platform.bosscoderacademy.com"
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const copyQuestionToClipboard = (questionType: any, questionId: any) => {
    if (questionType && questionId) {
      questionType = questionType.replaceAll(" ", "")
      questionType = questionType.toLocaleLowerCase()
      try {
        changeToastDetails(STR_SUCCESS, `Question URL Copied To Clipboard!`)
        changeToastVisibility(true)
        const str = `${origin}/${questionType}/${questionId}`
        navigator.clipboard.writeText(str)
      } catch (error) {
        changeToastDetails(STR_FAILURE, genError(error, "Unable to copy text to clipboard"))
        changeToastVisibility(true)
        console.error("Unable to copy text to clipboard:", error)
      }
    }
  }
  return (
    <div
      key={index}
      className="w-full flex flex-col items-start content-start gap-4 p-6 border border-solid border-new-neutral rounded-sm"
    >
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="w-full flex justify-between">
        <p className="font-semibold">#Question {index + 1 + (currentPage - 1) * 10}</p>
        <p className="font-semibold text-yellow-300">{question?.coins} Coins</p>
      </div>
      <div className="w-full flex justify-between">
        <div className="basis-1/2">
          <p className="text-lg font-semibold text-new-accent">
            {question?.questionTitle}
            <button className="ml-2" onClick={() => copyQuestionToClipboard(question?.questionType, question.id)}>
              <CopyIcon className="w-5 h-5 text-gray-400" />
            </button>
          </p>
          <p>
            {question?.course} |{question?.module} |
            {question?.topic === "Others" ? question?.otherTopic : question?.topic} |
            <span className="text-success">
              {question?.difficultyLevel
                ? question.difficultyLevel.charAt(0).toUpperCase() + question.difficultyLevel.slice(1)
                : "Easy"}
            </span>
          </p>
        </div>
        <div className="basis-1/3 flex gap-4 justify-end">
          <Button outlined className="w-fit h-fit" onClick={(e) => duplicateQuestion(question?.id)}>
            <CopyIcon className="w-4 h-4" />
          </Button>
          <Button outlined className="w-fit h-fit" onClick={(e) => editQuestion(question?.id)}>
            <EditIcon className="w-4 h-4" />
          </Button>
          <Button
            outlined
            failure
            className="w-fit h-fit"
            onClick={(e) => {
              setSelectQuestion({
                id: question?.id,
                questionType: question?.questionType,
              })
              setIsConfrimationPopUpOpen(true)
            }}
          >
            <DeleteIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}

// {
//   questionTab === "all" ? (
//     allQuestions?.[currentTab]?.map((question: any, index: number) => (
//       <QuestionViewCard
//         key={index}
//         index={index}
//         currentPage={currentPage}
//         question={question}
//         duplicateQuestion={duplicateQuestion}
//         editQuestion={editQuestion}
//         setSelectQuestion={setSelectQuestion}
//         setIsConfrimationPopUpOpen={setIsConfrimationPopUpOpen}
//       />
//     ))
//   ) :
// }
