import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import { CreateProjectCard } from "./components/CreateProjectCard"
import ProjectsSectionViewModel from "./CreateProjectsSectionViewModel"
export default function CreateNewProjectView() {
  const { handleClick } = ProjectsSectionViewModel()
  return (
    <DashboardLayout>
      <div className="px-6 py-6">
        <div className="flex flex-row justify-between m-[20px]">
          <div className="font-bold text-indigo-900 text-2xl">New Project</div>
          <div>
            <Button onClick={() => handleClick()} outlined failure>
              Go Back
            </Button>
          </div>
        </div>
        <CreateProjectCard />
      </div>
    </DashboardLayout>
  )
}
