import React from "react"
import { circumference, dasharray } from "core/utils/donut"

export default function DonutChart({ current, total }: any) {
  const fraction = current / total

  return (
    <div className="relative h-12 w-12">
      <svg className="h-full w-full -scale-100" viewBox="0 0 120 120">
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="#4032EB"></stop>
            <stop offset="100%" stopColor="#06A1F9"></stop>
          </linearGradient>
        </defs>
        <circle className="fill-transparent stroke-new-accent" r="48" cx="60" cy="60" strokeWidth="2" />
        <circle
          className="fill-transparent"
          r="48"
          cx="60"
          cy="60"
          stroke="url(#gradient)"
          strokeWidth="12"
          strokeDasharray={dasharray(circumference(48), fraction)}
          strokeDashoffset={circumference(48) / 2}
          strokeLinecap="round"
        />
      </svg>
      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <span className="whitespace-nowrap text-xs font-medium text-new-accent">
          <span className="font-semibold">{current}</span>
        </span>
      </span>
    </div>
  )
}
