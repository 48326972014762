import { Auth } from "domain/model/Auth"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"

export interface GetNewPlacementUsecase {
  invoke(auth: Auth): Promise<any>
}

export class GetNewPlacement implements GetNewPlacementUsecase {
  private repository: AddPlacementRepository

  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getNewPlacement(auth)
  }
}
