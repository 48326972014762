import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetClassAllRatingUseCase {
  invoke(auth: Auth, class_id: string): Promise<any>
}

export default class GetClassAllRating implements GetClassAllRatingUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, class_id: string) {
    return await this.repository.getClassAllRating(auth, class_id)
  }
}
