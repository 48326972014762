import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Student/StoreRepository"

export interface DeleteStudentAddressUseCase {
  invoke(auth: Auth, address_id: any): Promise<any>
}

export class DeleteStudentAddress implements DeleteStudentAddressUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, address_id: any) {
    return this.repository.deleteStudentAddress(auth, address_id)
  }
}
