import { useEffect, useState } from "react"
import StandardSelectWithoutPlaceholder from "../StandardSelectWithoutPlaceholder"
import { cn } from "core/lib/utils"

const OnboardingTime = ({ setStartTime }: any) => {
  const [selectedHour, setSelectedHour] = useState<string>("")
  const [selectedMinute, setSelectedMinute] = useState<string>("")
  const [selectedPeriod, setSelectedPeriod] = useState<string>("")

  const hours: any = ["11", "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10"]
  const minutes: any = ["00", "15", "30", "45"]
  const periods: any = ["AM", "PM"]

  useEffect(() => {
    if (selectedHour && selectedMinute && selectedPeriod) {
      setStartTime(`${selectedHour}:${selectedMinute} ${selectedPeriod}`)
    }
  }, [selectedHour, selectedMinute, selectedPeriod, setStartTime])

  return (
    <div className={cn("w-[301px] h-[148px] bg-white text-[#646464] flex flex-col items-center border rounded shadow")}>
      <div className="flex justify-between w-full relative mr-1">
        <StandardSelectWithoutPlaceholder
          id="hour-select"
          value={selectedHour}
          onChange={setSelectedHour}
          placeholder="Hour"
          options={hours}
          className="w-1/3 "
        />
        <StandardSelectWithoutPlaceholder
          id="minute-select"
          value={selectedMinute}
          onChange={setSelectedMinute}
          placeholder="Minute"
          options={minutes}
          className="w-1/3"
        />
        <div className="w-1/3">
          <StandardSelectWithoutPlaceholder
            id="period-select"
            value={selectedPeriod}
            onChange={setSelectedPeriod}
            placeholder="AM/PM"
            options={periods}
            className="w-full h-[74px]"
          />
        </div>
      </div>
    </div>
  )
}
export default OnboardingTime
