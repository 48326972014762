import { cn } from "core/lib/utils"
import icon1 from "assets/images/payment-method/icon1.svg"
import icon2 from "assets/images/payment-method/icon2.svg"
import icon3 from "assets/images/payment-method/icon3.svg"
import icon4 from "assets/images/payment-method/icon4.svg"
import icon5 from "assets/images/payment-method/icon5.svg"

export default function EarlyPaymentModel({ isOpen, onClose, children, width, height, offerDate }: any) {
  if (!isOpen) return null
  return (
    <div className="relative z-30">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0"></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="bg-white w-[549px] h-[499px] px-[32px] py-[24px] rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="flex justify-between">
            <div>
              <p className="text-[18px] font-[600] font-[Inter] mb-[5px]">
                Additional benefit if you pay by <span className="text-[#00C22B]">{offerDate}</span>
              </p>
              <p className="text-[#646464] text-[14px] font-[500] font-[Inter]">
                Unlock additional advantages by paying fees Immediately.
              </p>
            </div>

            <button onClick={onClose} className="rounded-[1.5px] font-[300] text-[24px] size-[24px] hover:bg-[#efebeb]">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect width="24" height="24" rx="2" fill="white" fillOpacity="0.26" />
                  <path
                    d="M18 6L6 18"
                    stroke="#646464"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#646464"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div className="mt-[10px] grid grid-cols-3 gap-[16px]">
            <div className="hover:bg-[#F5F5F5] w-[155px] h-[188px] p-[12px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] rounded-[8px]">
              <img alt="" src={icon1} className="size-[40px]" />
              <p className="mt-[8px] ml-[4px] text-[#333] text-[14px] font-[600] font-[Inter]">Upto 2000 Rs Off</p>
              <p className="mt-[8px] text-[12px] font-[400] text-[#333] font-[Inter] ml-[4px] leading-4">
                Receive an extra Discount of upto Rs 2000.
              </p>
            </div>

            <div className="hover:bg-[#F5F5F5] w-[155px] h-[188px] p-[12px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] rounded-[8px]">
              <img alt="" src={icon2} className="size-[40px]" />
              <p className="mt-[8px] ml-[4px] text-[#333] text-[14px] font-[600] font-[Inter]">
                Priority Mentor Allotment
              </p>
              <p className="mt-[8px] text-[12px] font-[400] text-[#333] font-[Inter] ml-[4px] leading-4">
                Priority Mentor Allotment — Limited Mentors Availability.
              </p>
            </div>

            <div className="hover:bg-[#F5F5F5] w-[155px] h-[188px] p-[12px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] rounded-[8px]">
              <img alt="" src={icon3} className="size-[40px]" />
              <p className="mt-[8px] ml-[4px] text-[#333] text-[14px] font-[600] font-[Inter]">Goal Setting Session</p>
              <p className="mt-[8px] text-[12px] font-[400] text-[#333] font-[Inter] ml-[4px] leading-4">
                <span className="text-green-500 font-[600]">Recommended: </span>Book a Goal Setting Session worth Rs.
                3,000 with our Expert Mentor.
              </p>
            </div>

            <div className="hover:bg-[#F5F5F5] w-[155px] h-[188px] p-[12px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] rounded-[8px]">
              <img alt="" src={icon4} className="size-[40px]" />
              <p className="mt-[8px] ml-[4px] text-[#333] text-[14px] font-[600] font-[Inter]">
                Additional Mentor Session
              </p>
              <p className="mt-[8px] text-[12px] font-[400] text-[#333] font-[Inter] ml-[4px] leading-4">
                Get an additional mentor session with your assigned mentor.
              </p>
            </div>

            <div className="hover:bg-[#F5F5F5] w-[155px] h-[188px] p-[12px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] rounded-[8px]">
              <img alt="" src={icon5} className="size-[40px]" />
              <p className="mt-[8px] ml-[4px] text-[#333] text-[14px] font-[600] font-[Inter]">Exclusive Goodies</p>
              <p className="mt-[8px] text-[12px] font-[400] text-[#333] font-[Inter] ml-[4px] leading-4">
                Don’t miss out on the chance for exclusive treats.
              </p>
            </div>

            <div className="bg-[#F9F9F9] w-[155px] h-[188px] p-[12px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] rounded-[8px]">
              <p className=" ml-[4px] text-[#DC2626] text-[14px] font-[600] font-[Inter]">Note**</p>
              <p className="mt-[8px] text-[12px] font-[400] text-[#333] font-[Inter] ml-[4px] leading-4">
                All these benefits are available on first come, first serve basis.
              </p>

              <p className="mt-[8px] text-[12px] font-[500] text-[#333] font-[Inter] ml-[4px] leading-4">
                Class access will be provided only after the payment is completed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
