import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface UpdateInstructorCompensationDetailsUseCase {
  invoke(auth: Auth, instructor_id: string, compensation_amount: number, year_month: string): Promise<any>
}

export default class UpdateInstructorCompensationDetails implements UpdateInstructorCompensationDetailsUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string, compensation_amount: number, year_month: string) {
    return await this.repository.updateInstructorCompensationDetails(
      auth,
      instructor_id,
      compensation_amount,
      year_month
    )
  }
}
