import { DuplicateClass } from "domain/model/AllClasses"
import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetDuplicateClassUseCase {
  invoke(duplicateClass: DuplicateClass): Promise<any>
}

export default class GetDuplicateClass implements GetDuplicateClassUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(duplicateClass: DuplicateClass) {
    return await this.repository.getDuplicateClass(duplicateClass)
  }
}
