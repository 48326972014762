import { OnboardingManagerName } from "domain/model/OnboardingManagerName"
import { Auth } from "domain/model/Auth"
import { OnboardingManagerNameRepository } from "domain/repository/Admin/OnboardingManagerNameRepository"

export interface OnboardingManagerNameUseCase {
  invoke(auth: Auth, onboardingManagerName: OnboardingManagerName): Promise<any>
}

export class OnboardingManagerNames implements OnboardingManagerNameUseCase {
  private repository: OnboardingManagerNameRepository

  constructor(repository: OnboardingManagerNameRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, onboardingManagerName: OnboardingManagerName) {
    return await this.repository.onboardingManagerName(auth, onboardingManagerName)
  }
}
