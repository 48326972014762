import {
  API_GET_MODULE_BASED_TRACK,
  API_V4_ADMIN,
  API_V4_CREATE_CLASS,
  API_V4_GET_ALL_BATCHES,
  API_V4_GET_ALL_ACTIVE_BATCHES,
  API_V4_GET_ALL_INSTRUCTORS,
  API_V4_GET_ALL_MODULES,
  API_V4_GET_ALL_TRACKS,
  API_V4_GET_CLASS_TEMPLATE,
  API_V4_GET_MODULE_CLASSES,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { classTemplate, CreateClass, ModuleClasses } from "domain/model/CreateClass"
import CreateClassDataSource from "data/dataSource/Admin/CreateClassDataSource"
import { RequestQuote } from "@mui/icons-material"

export class CreateClassAPIDataSourceImpl implements CreateClassDataSource {
  async getAllBatches(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_BATCHES), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllInstructors(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_INSTRUCTORS), {
      id_token: auth.id_token,
    })
    return response
  }

  async getAllModules(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_MODULES), {
      id_token: auth.id_token,
    })
    return response
  }

  async getAllTracks(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_TRACKS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createClass(createClass: CreateClass): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_CREATE_CLASS), createClass)
      return response
    } catch (error) {
      return error
    }
  }

  async getModuleClasses(request: ModuleClasses): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_MODULE_CLASSES), {
      id_token: request.id_token,
      module: request.module,
    })
    return response
  }
  async getClassTemplate(request: classTemplate): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CLASS_TEMPLATE), {
      id_token: request.id_token,
      module: request.module,
      class_id: request.class_id,
    })
    return response
  }

  async getModulesBasedOnTrack(auth: Auth, track: string): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_GET_MODULE_BASED_TRACK), {
      id_token: auth.id_token,
      track: track,
    })
    return response
  }
}
