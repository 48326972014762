import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Student/StoreRepository"

export interface PlaceOrderUseCase {
  invoke(auth: Auth, product_id: any, address_id: any): Promise<any>
}

export class PlaceOrder implements PlaceOrderUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, product_id: any, address_id: any) {
    return this.repository.placeOrder(auth, product_id, address_id)
  }
}
