import { Auth } from "domain/model/Auth"
import McqDataSource from "data/dataSource/Admin/McqDataSource"
import McqRepository from "domain/repository/Admin/McqRepository"

export class McqRepositoryImpl implements McqRepository {
  private dataSource: McqDataSource

  constructor(dataSource: McqDataSource) {
    this.dataSource = dataSource
  }

  async get_mcq_questions(auth: Auth): Promise<any> {
    return this.dataSource.get_mcq_questions(auth)
  }

  async create_mcq_question(auth: Auth, data: any): Promise<any> {
    return this.dataSource.create_mcq_question(auth, data)
  }

  async get_mcq_question(auth: Auth, id: string): Promise<any> {
    return this.dataSource.get_mcq_question(auth, id)
  }

  async update_mcq_question(auth: Auth, id: string, data: any): Promise<any> {
    return this.dataSource.update_mcq_question(auth, id, data)
  }

  async delete_mcq_question(auth: Auth, id: string): Promise<any> {
    return this.dataSource.delete_mcq_question(auth, id)
  }
}
