import React from "react"
// import { MoreIcon } from 'core/constants/svgs';
import Button from "core/components/new/Button"
import { STR_SSM_MEETING_SESSION } from "core/constants/strings"
import { format } from "date-fns"
import Avatar from "react-avatar"
import { cn } from "core/lib/utils"
import useTimer from "core/hooks/useTimer"
import SSMProgressBannerSvg from "assets/svgs/SSMProgress/SSMProgressBannerSvg"

export default function ProgressMeetingCard(props: any) {
  return (
    <div className="font-montserrat flex gap-4 rounded bg-new-solid-red-light p-8">
      {/* <Avatar src={mentor?.photo} name={mentor?.name} size="120" /> */}
      {/* <div className=""> */}
      <SSMProgressBannerSvg />
      {/* </div> */}
      <div className="space-y-2">
        <h2 className="text-new-accent">{STR_SSM_MEETING_SESSION}</h2>
        <p className="text-new-neutral-dark">{props.formatDate(props.data.meeting_date, "Scheduled")}</p>
        <p className="text-[14px] font-semibold text-new-neutral-dark ">
          You will receive a call between the mentioned date range.
        </p>
      </div>
      <div className="flex flex-1 flex-col items-end justify-between">
        <h3 className="text-new-failure">
          Approx Duration:{" "}
          {props.data.meeting_duration >= 60
            ? `${Math.floor(props.data.meeting_duration / 60)} hr ${
                props.data.meeting_duration % 60 === 0 ? "" : (props.data.meeting_duration % 60) + " Mins"
              }`
            : `${props.data.meeting_duration} Mins`}
        </h3>
      </div>
    </div>
  )
}
