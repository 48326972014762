import { cn } from "core/lib/utils"

export default function ProcessCard({ index, bg, fontcolor, bordercolor, title, description, boldText }: any) {
  return (
    <div
      className={cn(`relative rounded bg-white w-full h-[160px] border-[0.8px] border-opacity-30`, bordercolor)}
      key={index}
    >
      <div
        className={cn(
          "absolute top-0 right-0 flex rounded-bl-lg items-center justify-center h-[32px] w-[32px] text-[24px]",
          bg,
          fontcolor
        )}
      >
        {index}
      </div>
      {/* <div className="text-[#414141] font-semibold text-lg leading-normal h-[44px] w-[75%] mt-4 ml-4">
        {title}
      </div> */}
      <div className="flex items-center w-3/4 mt-4 ml-4">
        <div className="text-[#414141] font-semibold text-lg leading-normal">{title}</div>
      </div>

      <div className="font-normal w-[75%] h-auto text-[12px] leading-[130%] mt-[8px] ml-4">
        {description}
        {boldText.length > 0 && boldText.map((sentence: string) => <div className="font-normal">{sentence}</div>)}
      </div>
    </div>
  )
}
