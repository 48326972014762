import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import { Auth } from "domain/model/Auth"
import { GetBatchTemplateGeneric } from "domain/useCase/Admin/NewMentorSessions/GetBatchTemplateGeneric"
import { NewMentorSessionsRepositoryImpl } from "data/repository/Admin/NewMentorSessionsRepositoryImpl"
import NewMentorSessionsAPIDataSourceImpl from "data/API/Admin/NewMentorSessionsAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import Input from "core/components/Input"
import Button from "core/components/new/Button"
import { UpdateBatchTemplateGeneric } from "domain/useCase/Admin/NewMentorSessions/UpdateBatchTemplateGeneric"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import ConfirmationPopUpMentorRemoval from "./components/ChangesConfirmationPopup"
import Loader from "core/components/Loader"
import Vector from "assets/svgs/Vector"
import useMentorshipEditViewModel from "./MentorshipEditViewModel"

export default function MentorshipEditView() {
  const {
    mentorshipType,
    studentId,
    items,
    nonGenericItems,
    indexToBeDeleted,
    setIndexToBeDeleted,
    isOpen,
    setIsOpen,
    isLoading,
    extractMentorshipData,
    updateBatchtemplateGeneric,
    updateBatchtemplate,
    getBatchtemplateGeneric,
    getBatchtemplateNonGeneric,
    handleDelete,
    handleAddField,
    handleInputChange,
    handleGenericInputChange,
    editType,
    toast,
    changeToastVisibility,
  } = useMentorshipEditViewModel()
  React.useEffect(() => {
    extractMentorshipData()
  }, [])
  React.useEffect(() => {
    extractMentorshipData()
  }, [])
  React.useEffect(() => {
    if (mentorshipType && studentId) {
      getBatchtemplateGeneric()
      getBatchtemplateNonGeneric()
    }
  }, [mentorshipType, studentId])
  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUpMentorRemoval
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          // email={student?.email}
          updateStudentsMentor={() => handleDelete(indexToBeDeleted!)}
          heading="Are you sure you want to delete this session?"
        />
      )}
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">Edit Mentorship Flow Template</h2>
          <p className="">You have access to edit Mentorship Flow Templates here.</p>
        </div>
        <div className="flex items-center flex-col gap-4">
          {items.map((item: any, index: any) => (
            <div className="flex flex-col gap-4 max-w-[700px]" key={index}>
              <div className="flex flex-row gap-4">
                <Input
                  className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                  readOnly
                  value="Mentor Session Index"
                />
                <Input
                  readOnly
                  value={`msid${index + 1}`}
                  onChange={(e) => handleInputChange(index, "msid", e.target.value)}
                />
              </div>
              <div className="flex flex-row gap-4">
                <Input
                  className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                  readOnly
                  value="Session Name"
                />
                <Input value={item.name} onChange={(e) => handleInputChange(index, "name", e.target.value)} />
              </div>
              <div className="flex flex-row gap-4">
                <Input
                  className="w-1/2 !bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                  readOnly
                  value="Session Agenda"
                />
                <textarea
                  className="w-1/2 ounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-500 outline-none"
                  value={item.agenda}
                  onChange={(e) => handleInputChange(index, "agenda", e.target.value)}
                />
              </div>
              {editType === "non-generic" && (
                <>
                  <div className="flex flex-row gap-4">
                    <Input
                      className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                      readOnly
                      value="Start Date"
                    />
                    <Input
                      type="date"
                      placeholder="Start date"
                      value={nonGenericItems[index]?.startDate || ""}
                      onChange={(e) => handleGenericInputChange(index, "startDate", e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row gap-4">
                    <Input
                      className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                      readOnly
                      value="End Date"
                    />
                    <Input
                      type="date"
                      placeholder="End date"
                      value={nonGenericItems[index]?.endDate || ""}
                      onChange={(e) => handleGenericInputChange(index, "endDate", e.target.value)}
                    />
                  </div>
                </>
              )}
              <div className="flex flex-col items-center">
                <Button
                  className="max-w-[600px]"
                  failure
                  outlined
                  onClick={() => {
                    setIsOpen(!isOpen)
                    setIndexToBeDeleted(index)
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}

          <Button className="max-w-[580px]" success outlined onClick={handleAddField}>
            Add Field
          </Button>
          <Button
            className="max-w-[580px] h-[54px]"
            onClick={() => {
              if (editType === "generic") {
                updateBatchtemplateGeneric()
              } else {
                updateBatchtemplate()
              }
            }}
          >
            {isLoading ? <Loader height="54 px" /> : "Save Changes"}
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
