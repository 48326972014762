import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface TakeSurveyUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class TakeSurvey implements TakeSurveyUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.takeSurvey(auth, data)
  }
}
