import { cn } from "core/lib/utils"

const Stage3 = ({ className }: any) => {
  return (
    <svg className={cn(className)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2314_62140)">
        <rect width="32" height="32" rx="4" fill="#333333" />
        <path
          d="M21.5578 35.4113C19.3642 35.4113 17.4106 35.0343 15.6969 34.2802C13.993 33.5164 12.6466 32.4686 11.6575 31.1368C10.6783 29.7953 10.1739 28.2481 10.1446 26.4952H16.5489C16.588 27.2296 16.828 27.8759 17.2686 28.4341C17.7191 28.9825 18.3164 29.4085 19.0607 29.712C19.8049 30.0156 20.6422 30.1674 21.5724 30.1674C22.5419 30.1674 23.3987 29.996 24.143 29.6533C24.8872 29.3105 25.4699 28.8356 25.8909 28.2285C26.312 27.6213 26.5226 26.9212 26.5226 26.128C26.5226 25.325 26.2973 24.615 25.8469 23.9981C25.4062 23.3714 24.7697 22.8817 23.9373 22.5292C23.1148 22.1767 22.1355 22.0004 20.9996 22.0004H18.194V17.3294H20.9996C21.9592 17.3294 22.8063 17.1629 23.5407 16.83C24.285 16.497 24.8627 16.0368 25.274 15.4492C25.6853 14.8519 25.8909 14.1566 25.8909 13.3634C25.8909 12.6094 25.7098 11.9484 25.3475 11.3804C24.9949 10.8027 24.4955 10.3522 23.8492 10.0291C23.2127 9.7059 22.4685 9.54432 21.6165 9.54432C20.7548 9.54432 19.9665 9.701 19.2516 10.0144C18.5368 10.3179 17.9639 10.7537 17.533 11.3217C17.1022 11.8896 16.872 12.5555 16.8427 13.3193H10.7468C10.7762 11.5861 11.2707 10.0584 12.2304 8.73644C13.19 7.41445 14.4827 6.38134 16.1082 5.63711C17.7436 4.88308 19.5895 4.50607 21.6459 4.50607C23.7219 4.50607 25.5384 4.88308 27.0954 5.63711C28.6524 6.39113 29.8618 7.40955 30.7236 8.69237C31.5951 9.9654 32.026 11.3951 32.0162 12.9815C32.026 14.6658 31.5021 16.071 30.4445 17.1972C29.3967 18.3233 28.0306 19.0382 26.3463 19.3417V19.5768C28.5594 19.8608 30.2437 20.6295 31.3992 21.8829C32.5646 23.1266 33.1423 24.6836 33.1325 26.5539C33.1423 28.2676 32.6478 29.7904 31.649 31.1222C30.6599 32.4539 29.2939 33.5017 27.5508 34.2656C25.8077 35.0294 23.81 35.4113 21.5578 35.4113Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_62140">
          <rect width="32" height="32" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Stage3
