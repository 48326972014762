import { Auth } from "domain/model/Auth"
import { PlacementRepository } from "domain/repository/Student/PlacementRepository"

export interface GetStudentJobsuseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetStudentJobs implements GetStudentJobsuseCase {
  private repository: PlacementRepository

  constructor(repository: PlacementRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getStudentsJob(auth)
  }
}
