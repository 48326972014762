import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import SearchInput from "core/components/SearchInput"
import Button from "core/components/new/Button"
import ProjectsCard from "./components/ProjectsCard"
import { Link } from "react-router-dom"
import ProjectsSectionViewModel from "./CreateProjectsSectionViewModel"
import Loader from "core/components/Loader"

export default function ProjectsSectionView() {
  const {
    auth,
    clicked,
    activeFilter,
    visibleProjects,
    isLoading,
    handleSearchChange,
    handleActiveFilter,
    setClicked,
    getProjects,
  } = ProjectsSectionViewModel()

  React.useEffect(() => {
    getProjects()
  }, [auth, clicked])

  return (
    <DashboardLayout>
      <div
        className="flex flex-col gap-4 py-6
       px-6"
      >
        <div className="flex flex-col">
          <div className="text-indigo-900 font-bold text-2xl leading-normal">Course Projects</div>
          <div className="text-gray-700 text-sm font-normal leading-relaxed">
            Create, view, and manage all the projects here.
          </div>
        </div>
        <Link
          to={"/admin/project-section/create-new-project"}
          className="flex justify-center items-center text-lg font-semibold w-full h-[80px] bg-gray-200"
        >
          + Create Project
        </Link>
        <div className="text-lg font-semibold text-neutral-700">Project Name</div>
        <div className="flex flex-row gap-4">
          <SearchInput className="w-96 border-[#6B6B6B]" placeholder="Search Projects!" onChange={handleSearchChange} />
          {/* <Button
            outlined
            className="max-w-[150px]"
            onClick={handleSearchChange}
          >
            Search
          </Button> */}
        </div>

        <div className="flex flex-row gap-4">
          <Button
            onClick={() => handleActiveFilter("all")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "all" ? true : false}
          >
            All Projects
          </Button>
          <Button
            onClick={() => handleActiveFilter("active")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "active" ? true : false}
          >
            Active
          </Button>
          <Button
            onClick={() => handleActiveFilter("inactive")}
            outlined
            className="max-w-[150px]"
            selected={activeFilter === "inactive" ? true : false}
          >
            Inactive
          </Button>
        </div>
        <div>
          {isLoading ? (
            <Loader dark={true} small={false} xs={false} height="100px" />
          ) : (
            <div>
              {visibleProjects.map((project: any, index: any) => (
                <ProjectsCard project={project} index={index + 1} setClicked={setClicked} clicked={clicked} />
              ))}
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
