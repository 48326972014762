import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"
import PaymentAdminDataSource from "data/dataSource/Admin/PaymentAdmin"
import { Auth } from "domain/model/Auth"
import { TPaymentDocumentItem } from "domain/model/Payment"

export default class PaymentAdminRepositoryImpl implements PaymentAdminRepository {
  private dataSource: PaymentAdminDataSource

  constructor(dataSource: PaymentAdminDataSource) {
    this.dataSource = dataSource
  }
  async updatePaymentOptionsMenu(auth: Auth, student_email: string, option: boolean): Promise<any> {
    return await this.dataSource.updatePaymentOptionsMenu(auth, student_email, option)
  }
  async documentVerified(auth: Auth, student_email: string, status: boolean): Promise<any> {
    return await this.dataSource.documentVerified(auth, student_email, status)
  }

  async createPayment(auth: Auth, paymentData: any): Promise<any> {
    return await this.dataSource.createPayment(auth, paymentData)
  }

  async getPaymentDetailAdmin(auth: Auth, paymentData: any): Promise<any> {
    return this.dataSource.getPaymentDetailAdmin(auth, paymentData)
  }

  async getStudentEMI(auth: Auth, student_email: string): Promise<any> {
    return this.dataSource.getStudentEMI(auth, student_email)
  }

  async getPaymentDocuments(auth: Auth, student_email: string): Promise<any> {
    return this.dataSource.getPaymentDocuments(auth, student_email)
  }

  async updatePaymentDocument(
    auth: Auth,
    student_email: string,
    document: TPaymentDocumentItem,
    id: number
  ): Promise<any> {
    return this.dataSource.updatePaymentDocument(auth, student_email, document, id)
  }

  async uploadPaymentDocument(
    auth: Auth,
    student_email: string,
    file: File,
    filename: string,
    id: number
  ): Promise<any> {
    return this.dataSource.uploadPaymentDocument(auth, student_email, file, filename, id)
  }
  async getPaymentMethods(auth: Auth): Promise<any> {
    return this.dataSource.getPaymentMethods(auth)
  }
  async updateTransactionDetailAdmin(auth: Auth, paymentData: any): Promise<any> {
    return this.dataSource.updateTransactionDetailAdmin(auth, paymentData)
  }
}
