import Input, { InputProps } from "core/components/Input"
import { SearchIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"

export default function SearchInput({ className, value, onChange, placeholder }: InputProps) {
  return (
    <div className="relative w-fit">
      <label
        htmlFor="search-input"
        className="absolute right-0 rounded-r top-1/2 -translate-y-1/2 flex cursor-pointer px-4 py-2 items-center text-sm text-gray-600"
      >
        <SearchIcon className="h-3" />
      </label>
      <Input
        id="search-input"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={cn(className, "pr-12")}
      />
    </div>
  )
}
