// Import necessary dependencies
import React, { useEffect } from "react"
import Button from "core/components/Button"
// import Button from 'core/components/new/Button';
import DashboardLayout from "core/layouts/DashboardLayout"

import Toast from "core/components/Toast"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import userPaymentBreakdownViewModel from "./CCAvenueBreakdownViewModel"

import PlusIcon from "assets/svgs/payments/PlusIcon"
import MinusIcon from "assets/svgs/payments/MinusIcon"
import AdminTableCCAvenue from "core/components/new/AdminTableCCAvenue"

export default function CCAvenue() {
  const {
    studentGetPayDetail,
    toast,
    paymentBreakData,
    disableSaveChanges,
    changeToastVisibility,
    setInitailInstallmentAuto,
    handleDiscardPaymentBreakdown,
    submitPaymentBreakdown,
    submitCCAvenuePaymentBreakdown,
    calculateOfferDiscount,
    calculateOneshotDiscount,
    handleInstallmentNumberChange,
    paymentBreakdownTableData,
    installmentNumber,
    editablePaymentBreakValues,
    payableAmountBreakdown,
    createPaymentBreakdownTable,
    handlePaymentBreakdownFieldChange,
    getPaymentBreakDownDetailAdmin,
    getPayableAmount,
    setInitailInstallmentAutoCCAv,
  } = userPaymentBreakdownViewModel()

  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    getPaymentBreakDownDetailAdmin(id)
  }, [])

  useEffect(() => {
    // getPayableAmount();
    createPaymentBreakdownTable()
  }, [editablePaymentBreakValues])

  useEffect(() => {
    getPayableAmount()
  }, [studentGetPayDetail, editablePaymentBreakValues])

  useEffect(() => {
    //  createPaymentBreakdownTable();
  }, [paymentBreakdownTableData])
  // const
  const goBack = () => {
    navigate(-1)
  }
  return (
    <DashboardLayout>
      <div className="flex w-full flex-col items-center justify-center p-6">
        {/* Title and Subtitle */}
        <div className="flex w-full justify-between pb-[24px]">
          <div className="flex flex-col">
            <div className="text-2xl font-bold text-[#312E81]">Fee Payment Detail</div>
          </div>

          <Button
            className="h-[33px] w-[91px] rounded-[1px] border-[#DC2626] p-[1rem] pl-[0.5rem] pr-[0.5rem] text-[14px] text-[#DC2626]"
            onClick={() => goBack()}
          >
            Go Back
          </Button>
        </div>

        {/* Fee Payment Breakdown */}
        <div className="w-full rounded border-[0.5px] border-[#ADADAD] p-[24px]">
          <div className="w-full rounded border-[0.5px] border-[#ADADAD] p-[24px]">
            <div className="mb-5 flex pb-3 text-[18px] font-semibold text-[#312E81]">Fee Payment Breakdown</div>
            <div className="mb-4 flex items-center  justify-between gap-[30vw]">
              <div className="font-sans text-base font-semibold leading-normal  text-[#414141]">
                Total Course Fees :
              </div>
              <input
                type="text"
                value={(editablePaymentBreakValues?.course_fees).toString()}
                onChange={(e) => handlePaymentBreakdownFieldChange("course_fees", e.target.value)}
                className="border-300 h-[40px] w-[163px] appearance-none rounded-[2px] border-[1px] border-[#414141] p-2 px-[16px] py-[8px] text-center text-[14px] font-[500]"
                placeholder="Amount"
              />
            </div>

            <div className="mb-4 flex items-center  justify-between gap-[30vw]">
              <div className="font-sans text-base font-semibold leading-normal  text-[#414141]">
                Seat Booking Amount
              </div>
              <input
                type="text"
                value={editablePaymentBreakValues?.seat_booking_amount}
                onChange={(e) => handlePaymentBreakdownFieldChange("seat_booking_amount", e.target.value)}
                className="border-300 [#414141] h-[40px]  w-[163px] appearance-none rounded-[2px] rounded-[1px] border-[1px] p-2 px-[16px] py-[8px] text-center text-[14px] font-[500]"
                placeholder="Amount"
              />
            </div>

            <div className="mb-4 flex items-center justify-between gap-[30vw]">
              <div className="font-sans text-base font-semibold leading-normal text-[#DC2626]">Standard Discount :</div>
              <input
                type="text"
                value={editablePaymentBreakValues?.standard_discount}
                onChange={(e) => handlePaymentBreakdownFieldChange("standard_discount", e.target.value)}
                className="border-300 h-[40px] w-[163px] appearance-none rounded-[2px] border border-[#DC2626] p-2 px-[16px] py-[8px] text-center text-[14px] font-[500] text-[#DC2626]"
                placeholder="Amount"
              />
            </div>

            <div className="mb-4 flex items-center justify-between gap-[30vw]">
              <div className="font-sans text-base font-semibold leading-normal  text-[#DC2626]">
                {`Offer Discount ( ${studentGetPayDetail?.offer_discount_in_percent}% ) :`}
              </div>
              <input
                type="text"
                value={`${calculateOfferDiscount()} Rs`}
                className="h-[40px] w-40 appearance-none  rounded-[2px] border-none bg-[#F5F5F5] p-2 text-center text-[14px] font-[500]  text-[#DC2626] outline-none"
                placeholder="Amount"
              />
            </div>

            <div className="mb-4 flex items-center justify-between gap-[30vw]">
              <div className="font-sans text-base font-semibold leading-normal text-[#DC2626]">
                {`One Shot Payment Discount ( ${studentGetPayDetail?.one_shot_discount}% ) :`}
              </div>
              <input
                type="text"
                value={`${calculateOneshotDiscount()} Rs`}
                className="h-[40px] w-40 appearance-none  rounded-[2px] border-none bg-[#F5F5F5] p-2 text-center text-[14px] font-[500] text-[#DC2626] focus:outline-none"
                placeholder="Amount"
              />
            </div>

            <hr className="my-3 border-t-2 border-dotted border-gray-300" />

            {/* Payable Amount */}
            <div className="flex items-center justify-between bg-[#F5F5F5] p-[16px]">
              <div className="font-montserrat  text-base-600 font-semibold  text-[#414141]">Total amount payable :</div>
              <div className="border-300 h-[40px] w-[163px] appearance-none rounded-[2px] border-[1px] border-[#414141] p-2 px-[16px] py-[8px] text-center text-[14px] font-[500]">
                {payableAmountBreakdown}
              </div>
            </div>
          </div>

          <div className="mt-3 w-full rounded border-[0.5px] border-[#ADADAD] p-8">
            <div className="flex pb-3 font-semibold text-[#312E81]">Fees Payment Installment</div>

            <div className="flex w-full flex-col justify-between">
              <div className="flex w-full items-center justify-between">
                <div className="font-montserrat  flex text-[0.875rem] text-base font-semibold  text-[#414141]">
                  Number of installments
                </div>
                <div className="flex w-[163px] justify-center ">
                  <div
                    className="flex  cursor-pointer items-center "
                    onClick={() =>
                      handleInstallmentNumberChange(
                        "number_of_installments",
                        installmentNumber.number_of_installments + 1
                      )
                    }
                  >
                    <PlusIcon />
                  </div>
                  <input
                    type="text"
                    value={installmentNumber?.number_of_installments}
                    onChange={(e) => handleInstallmentNumberChange("number_of_installments", e.target.value)}
                    className="border-300 ml-[28px] mr-[28px] h-[40px] w-[58px] appearance-none rounded-[2px] border border-[#414141] p-2 py-[8px] text-center"
                    placeholder="Amount"
                  />
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={() => {
                      if (installmentNumber.number_of_installments > 1) {
                        handleInstallmentNumberChange(
                          "number_of_installments",
                          installmentNumber.number_of_installments - 1
                        )
                      }
                    }}
                  >
                    <MinusIcon />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex w-full items-center justify-between">
                <div className="font-montserrat  flex text-[0.875rem] text-base font-semibold  text-[#414141]">
                  Instalment Start Date :{" "}
                </div>
                <div className="flex items-center">
                  <input
                    type="date"
                    value={installmentNumber?.start_date}
                    onChange={(e) => handleInstallmentNumberChange("start_date", e.target.value)}
                    className="border-300 h-[40px] w-[163px] appearance-none rounded-[2px] border-[1px] border-[#414141] p-2 text-center"
                    placeholder="Select Date"
                  />
                </div>
              </div>

              <hr className="my-3 border-t-2 border-dotted border-gray-300" />
              <div className="flex items-center justify-between bg-[#f5f5f5] py-[12px] px-[16px]">
                <div className="font-montserrat flex w-[189px] text-[14px] text-base font-semibold leading-normal  text-[#414141]">
                  Breakdown the number of installments
                </div>
                <Button
                  className="border-1px text-500 h-[41px] w-[142px] rounded-[2px] border-[#312E81] text-[14px] text-[#312E81]"
                  onClick={() => {
                    setInitailInstallmentAutoCCAv()
                  }}
                >
                  Apply Now
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-3 flex w-full justify-end text-[0.5rem]">**Amount must be in natural number</div>
          <div className="mt-5">
            <AdminTableCCAvenue
              data={paymentBreakdownTableData}
              outlined={true} // Set to true if you want an outlined table
              headerWidths={["[50px]", "[150px]", "[200px]", "[200px]", "full"]}
              rowWidths={["[50px]", "[150px]", "[200px]", "full", "full"]}
              paddingInfo={["[24px]", "[10px]", "[24px]", "[16px]", "[16px]"]} //top bottom px
            />
          </div>
          <hr className="border-300 my-3 border-t-2 border-[#F5F5F5]" />
          <div className="ml-auto mt-5 flex items-center justify-between space-x-4">
            {/* <Button
              onClick={handleDiscardPaymentBreakdown}
              outlined
              className="h-[52px] w-[188px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
            >
              Discard Changes
            </Button> */}

            <div className="font-sans text-base font-semibold leading-normal  text-[#414141]">
              Save Fees Payment Detail :
            </div>
            {disableSaveChanges ? (
              <Button
                className="font-montserrat h-[52px] w-[178px] cursor-not-allowed rounded-[2px] bg-gradient-to-br from-blue-500 to-blue-900 text-base font-medium text-white opacity-50"
                disabled
              >
                Save Changes
              </Button>
            ) : (
              <Button
                className="font-montserrat h-[52px] w-[178px] rounded-[2px] bg-gradient-to-br from-blue-500 to-blue-900 text-base font-medium text-white"
                onClick={submitCCAvenuePaymentBreakdown}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
