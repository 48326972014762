import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetBatchYearDataUseCase {
  invoke(auth: Auth, batch: string, month: any, year: string): Promise<any>
}

export class GetBatchYearData implements GetBatchYearDataUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch: string, month: any, year: string) {
    return this.repository.getBatchYearData(auth, batch, month, year)
  }
}
