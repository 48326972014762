import React from "react"
import { Bar } from "react-chartjs-2"
// import "chart.js/auto";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { AspectRatio } from "@mui/icons-material"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels)

const MyBarChart = ({ data, selectedWeek }) => {
  const labels = data?.map((item) => `Week ${item.week}`)
  const datasetColors = ["#66EB45", "#F8A934", "#FAF12D", "#BB3CE8"] // Define specific colors for each dataset
  const options = {
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const firstElement = elements[0]
        const datasetIndex = firstElement.datasetIndex
        const dataIndex = firstElement.index
        const label = labels[dataIndex]
        const datasetLabel = chartData.datasets[datasetIndex].label
        // You can do more here, like setting state or calling a function
      }
    },

    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y1: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
      annotation: {
        annotations: data
          ?.map((weekData, index) => {
            return !weekData.present
              ? {
                  type: "label",
                  content: ["Data Not Yet", "collected"],
                  xValue: index,
                  yValue: 50,
                  color: "gray",
                }
              : null
          })
          .filter((annotation) => annotation !== null),
      },
      datalabels: {
        color: function (context) {
          return datasetColors[context.datasetIndex]
        },
        align: "end",
        anchor: "end",
        formatter: function (value, context) {
          if (context.dataIndex === selectedWeek) {
            return value
          } else {
            return null
          }
        },
      },
    },
    responsive: true,
    AspectRatio: true,
  }

  const chartData = {
    labels: data?.map((item) => `Week ${item.week}`),
    datasets: [
      {
        label: "Class Attendance",
        data: data?.map((item) => item.classAttendance),
        backgroundColor: "#66EB45",
        yAxisID: "y",
        borderRadius: 4,
        minBarLength: 10,
      },
      {
        label: "Assignment Problems",
        data: data?.map((item) => item.assignmentProblems),
        backgroundColor: "#F8A934",
        yAxisID: "y",
        borderRadius: 4,
        minBarLength: 10,
      },
      {
        label: "Average Rating",
        data: data?.map((item) => item.avgRating),
        backgroundColor: "#FAF12D",
        yAxisID: "y",
        borderRadius: 4,
        minBarLength: 10,
      },
      {
        label: "W.A Score",
        data: data?.map((item) => item.contestScore),
        backgroundColor: "#BB3CE8",
        yAxisID: "y",
        borderRadius: 4,
        minBarLength: 10,
      },
    ],
  }

  return <Bar data={chartData} options={options} height={75} />
}

export default MyBarChart
