import Button from "core/components/Button"
import { CoinIcon } from "core/constants/svgs"
import { convertToAddress } from "core/utils/misc"
import { format } from "date-fns"

export default function OrderStatusCard({ item, onShow }: any) {
  return (
    <div className="flex h-[162px] overflow-hidden rounded shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
      <div className="h-full w-full max-w-[240px] bg-gray-400">
        {item?.product?.image_url && (
          <img className="h-full w-full object-cover" src={item?.product?.image_url} alt="store item" />
        )}
      </div>
      <div className="flex w-full justify-between p-6">
        <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
          <div className="flex justify-between">
            <div className="space-y-2">
              <h3 className="text-base">{item?.product?.name}</h3>
              <div className="flex gap-1">
                <span className="text-[#A3A3A3]">Date of Purchase:</span>
                <span className="">{format(item?.timestamp * 1000, "do MMM, yyyy")}</span>
              </div>
            </div>
            <div className="flex items-center gap-2 self-start">
              <span>
                <CoinIcon className="h-6 w-6" />
              </span>
              <span className="text-xl font-bold text-[#FBBF24]">{item?.product?.coins}</span>
            </div>
          </div>
          <div className="flex items-end justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-[#A3A3A3]">Delivery Address:</span>
              <span className="w-full max-w-[330px]">{item?.address && convertToAddress(item?.address)}</span>
            </div>
            <Button className="w-fit" onClick={onShow}>
              Order Status
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
