// import pn from "core/utils/pn"
// import { postAPI } from "core/utils/axios"
// import {
//   API_SEND_EMAIL,
//   API_V4_ADMIN,
// } from "core/constants/strings"
// import SendEmailDataSource from "data/dataSource/Admin/SendEmailDataSource"
// import { Auth } from "domain/model/Auth"

// export default class SendEmailAPIDataSourceImpl implements SendEmailDataSource {
//   async sendEmail(auth: Auth, data: FormData): Promise<any> {
//     try {
//       const response = await postAPI(pn(API_V4_ADMIN, API_SEND_EMAIL), {
//         id_token: auth.id_token,
//         ...data,
//       });
//       return response
//     } catch (error) {
//       return error
//     }
//   }
// }
import axios from "axios"
import pn from "core/utils/pn"
import { flask_api, postAPI } from "core/utils/axios"
import {
  API_SEND_EMAIL_TO_ALL,
  API_V4_ADMIN,
  API_SEND_EMAIL_TO_PROGRAMME,
  API_SEND_EMAIL_TO_BATCH,
  API_SEND_EMAIL_TO_GROUP_OF_STUDENTS,
} from "core/constants/strings"
import SendEmailDataSource from "data/dataSource/Admin/SendEmailDataSource"
import { Auth } from "domain/model/Auth"

export default class SendEmailAPIDataSourceImpl implements SendEmailDataSource {
  async sendEmail(auth: Auth, data: FormData): Promise<any> {
    try {
      const response = await flask_api.post(pn(API_V4_ADMIN, API_SEND_EMAIL_TO_ALL), data, {
        headers: {
          Authorization: `Bearer ${auth.id_token}`, // Add the token here if required
          "Content-Type": "multipart/form-data", // Optional, axios usually handles this automatically
        },
      })
      return response
    } catch (error) {
      console.error("Error sending email:", error)
      return error
    }
  }
  async sendEmailtoprogramme(auth: Auth, data: FormData): Promise<any> {
    try {
      const response = await flask_api.post(pn(API_V4_ADMIN, API_SEND_EMAIL_TO_PROGRAMME), data, {
        headers: {
          Authorization: `Bearer ${auth.id_token}`, // Add the token here if required
          "Content-Type": "multipart/form-data", // Optional, axios usually handles this automatically
        },
      })
      return response
    } catch (error) {
      console.error("Error sending email:", error)
      return error
    }
  }
  async sendEmailtobatch(auth: Auth, data: FormData): Promise<any> {
    try {
      const response = await flask_api.post(pn(API_V4_ADMIN, API_SEND_EMAIL_TO_BATCH), data, {
        headers: {
          Authorization: `Bearer ${auth.id_token}`, // Add the token here if required
          "Content-Type": "multipart/form-data", // Optional, axios usually handles this automatically
        },
      })
      return response
    } catch (error) {
      console.error("Error sending email:", error)
      return error
    }
  }
  async sendEmailToGroupOfStudents(auth: Auth, data: FormData): Promise<any> {
    try {
      const response = await flask_api.post(pn(API_V4_ADMIN, API_SEND_EMAIL_TO_GROUP_OF_STUDENTS), data, {
        headers: {
          Authorization: `Bearer ${auth.id_token}`, // Add the token here if required
          "Content-Type": "multipart/form-data", // Optional, axios usually handles this automatically
        },
      })
      return response
    } catch (error) {
      console.error("Error sending email:", error)
      return error
    }
  }
}
