import { deleteStudent } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface deleteStudentUseCase {
  invoke(student: deleteStudent): Promise<any>
}

export class DeleteStudent implements deleteStudentUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(student: deleteStudent): Promise<any> {
    return await this.repository.deleteStudent(student)
  }
}
