"use client"

import * as React from "react"
import { format, addDays, startOfDay } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "core/lib/utils"
import { Button } from "core/components/v2/Button"
import { Calendar } from "core/components/v2/ui/Calender"
import { Popover, PopoverContent, PopoverTrigger } from "core/components/v2/ui/PopOver"

interface DatePickerProps {
  className?: string
  date?: string
  setDate?: (date: string | undefined) => void
}

export function DatePickerWithProps({ className, date, setDate }: DatePickerProps) {
  const [open, setOpen] = React.useState(false)
  const today = addDays(new Date(), 0)
  const handleSelectDate = (selectedDate: Date | undefined) => {
    if (setDate) {
      const formattedDate = selectedDate ? format(selectedDate, "dd/MM/yyyy") : undefined
      setDate(formattedDate)
    }
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"ternary"}
          className={cn(
            "w-full justify-between text-left font-normal bg-white border-[1px] rounded-md ",
            !date && "text-muted-foreground",
            className
          )}
          onClick={() => setOpen(!open)}
        >
          {date ? date : <span>Select date</span>}
          <CalendarIcon className="mr-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-[999] bg-white">
        <Calendar
          mode="single"
          selected={date ? new Date(date.split("/").reverse().join("-")) : undefined}
          onSelect={handleSelectDate}
          fromDate={today}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
