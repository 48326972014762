import ClassSchedule from "assets/svgs/HomePage/ClassSchedule"
import Curriculum from "assets/svgs/HomePage/Curriculum"
import MentorSession from "assets/svgs/HomePage/MentorSession"
import ProgressMeeting from "assets/svgs/HomePage/ProgressMeeting"
import StudentHelp from "assets/svgs/HomePage/StudentHelp"
import WeeklyTest from "assets/svgs/HomePage/WeeklyTest"
import Placement from "assets/svgs/HomePage/Placement"
import React, { ReactElement } from "react"
import {
  BookOpenCheckIcon,
  BookTextIcon,
  BriefcaseBusinessIcon,
  CalendarDaysIcon,
  CircleDotDashedIcon,
  FileVideoIcon,
  MessagesSquareIcon,
} from "lucide-react"

const ShortcutTab = ({ data, isVisited, onClick }: any) => {
  const icon: { [key: string]: ReactElement } = {
    "Class Schedule": <FileVideoIcon size={16} />,
    "Detailed Curriculum": <BookTextIcon size={16} />,
    "Mentor Sessions": <CalendarDaysIcon size={16} />,
    "Progress Meeting": <CircleDotDashedIcon size={16} />,
    "Exam Schedule": <BookOpenCheckIcon size={16} />,
    "Placement Support": <BriefcaseBusinessIcon size={16} />,
    "Doubt Support": <MessagesSquareIcon size={16} />,
  }

  return (
    <div
      className="relative h-[68px] w-[100px] flex-shrink-0 cursor-pointer rounded bg-white px-4 py-3 shadow-[-1px_1px_3px_0px_rgba(31,41,48,0.15)] transition-all duration-300 ease-in-out hover:scale-105"
      onClick={() => onClick(data)}
    >
      {!isVisited && <div className="z-8 absolute top-2 right-2 h-1 w-1 rounded-full bg-[#DC2626]"></div>}
      <div className="shortcut-tab flex flex-col items-center justify-center space-y-1">
        {icon[data]}
        <p className="font-inter shortcut-tab w-full text-center">{data}</p>
      </div>
    </div>
  )
}

export default ShortcutTab
