import StarRating from "core/components/StarRating"
import Avatar from "react-avatar"
import { LinkedInIcon } from "core/constants/svgs"
import Alumni from "assets/svgs/Alumni"
import { useState } from "react"
import { cn } from "core/lib/utils"

export default function CurrentMentorCard(props: any) {
  const [isHovered, setIsHovered] = useState(false)
  const [infoHover, setInfoHover] = useState(false)

  return (
    <div
      className={cn(
        "h-[100px] rounded-sm bg-new-solid-white shadow-[0px_3px_7px_rgba(0,0,0,0.1)]",
        props.state === "Selected" ? "sticky bottom-0 bg-[#F5F5F5] pt-[2px]" : "relative"
      )}
    >
      <>
        {props.details["is_alumni"] && <Alumni className="absolute top-2" setIsHovered={setIsHovered} />}
        {isHovered && (
          <div className="absolute left-[80px] top-[-16px] flex h-[28px] w-[110px] items-center justify-center bg-black">
            <h5 className="text-white">Bosscoder Alumni</h5>
          </div>
        )}
        <div className="mx-6 my-4 flex gap-4">
          <Avatar
            className="shrink-0 rounded-sm object-cover"
            src={props.details["photo"]}
            name={props.details["name"]}
            size="67"
          />
          <div className="flex w-full flex-1 justify-between">
            <div className="flex flex-col gap-1">
              <h3 className="text-new-neutral-dark">{props.details["name"]}</h3>
              <h5 className="mb-auto flex gap-1 text-new-neutral-dark">
                <span>{props.details["current_company"]}</span>
                <span>|</span>
                <span>{props.details["experience"]}yrs</span>
              </h5>
              <StarRating rating={Math.round(props.details["rating"] * 10) / 10} total={5} />
            </div>
            <div className="flex flex-col items-end justify-between">
              <a href={props.details["linkedin"]} target="_blank" rel="noreferrer">
                <LinkedInIcon className="h-5 w-5" />
              </a>
              {props.state === "Current" ? (
                <div className="text-sm font-semibold	text-new-success	">Current Mentor</div>
              ) : props.state === "Previous" ? (
                <div className="text-sm font-semibold	text-[#DC2626]">Previous Mentor</div>
              ) : (
                props.state === "Select" && (
                  <div onMouseEnter={() => setInfoHover(true)} onMouseLeave={() => setInfoHover(false)}>
                    <button
                      onClick={() => {
                        props.setSelectedMentor(props.details)
                        props.setOpenMentorToSelect(false)
                      }}
                      disabled={props.details["bandwidth"] >= 1000 ? true : false}
                      className={cn(
                        "border-1 relative h-[33px] w-[132px] border border-[#312E81] text-sm font-medium text-[#312E81]",
                        props.details["bandwidth"] >= 1000 && "cursor-not-allowed opacity-60"
                      )}
                    >
                      Select Mentor
                      {infoHover && props.details["bandwidth"] >= 1000 && (
                        <div className="absolute left-[-128px] top-[-29px] flex h-[28px] w-[125px] items-center justify-center rounded-sm bg-black">
                          <h5 className="font-semibold text-white">Currently Unavailable</h5>
                        </div>
                      )}
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  )
}
