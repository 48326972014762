import Button from "core/components/new/Button"

export default function HistoryTable(props: any) {
  return (
    <div className="font-montserrat w-full overflow-x-auto rounded-[4px] border border-[#ADADAD]">
      <table className="w-full ">
        <thead className="h-[33px] bg-[#303030]">
          <tr className="">
            {props.tableHeaders?.map((title: any, index: any) => (
              <th
                key={index}
                className={`${
                  title === "Update Detail" || title === "Session Detail" ? "w-[157px] text-center" : "text-left"
                } 
                p-0 text-[14px] font-semibold text-[#FFF] ${title === "Sr. No." && " px-[24px]"}`}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.historys?.map((historyObject: any, index: any, array: any[]) => {
            const [key, data]: any = Object.entries(historyObject)[0]
            const rowNumber = array.length - index

            return (
              <tr className="h-[69px] bg-new-solid-white hover:bg-[#F5F5F5]" key={index}>
                <td className="font-montserrat leading-160 w-[94px] pl-[24px]  text-left text-[14px] font-normal text-[#414141]">
                  {String(rowNumber).padStart(2, "0")}
                </td>
                <td className="font-montserrat leading-160 w-[200px] text-left text-[14px] font-normal text-[#414141]">
                  {props.formatDate(
                    data.meeting_date,
                    data.status == "Scheduled" && data.meeting_date >= props.currentDate ? data.status : "Completed"
                  )}
                </td>
                <td className="font-montserrat leading-160 w-[94px] max-w-[94px] text-left text-[14px] font-normal text-[#414141]">
                  {data.status === "Completed"
                    ? data.meeting_duration > 60
                      ? `${Math.floor(data.meeting_duration / 60)} hr ${data.meeting_duration % 60} min`
                      : `${data.meeting_duration} min`
                    : "N/A"}
                </td>

                <td
                  className={`font-montserrat leading-160 w-auto text-[14px] font-medium ${
                    data.status === "Scheduled" && props.currentDate <= data.meeting_date
                      ? "text-yellow-500"
                      : data.status === "Student Absent" || data.status === "SSM Absent"
                        ? "text-red-500"
                        : data.status === "Completed"
                          ? "text-[#00C22B]"
                          : "text-gray-500"
                  } w-[120px] `}
                >
                  {data.status == "Scheduled"
                    ? data.meeting_date >= props.currentDate
                      ? "Scheduled"
                      : "N/A"
                    : data.status}
                </td>
                <td className="">
                  <div className="flex items-center justify-center">
                    <Button
                      onClick={() => {
                        props.setIsSessionDetailOpen(true)
                        props.handleUpdateDetailButton(
                          data.meeting_topic,
                          data.meeting_date,
                          data.meeting_ssmRemark,
                          data.meeting_agenda,
                          data.meeting_duration,
                          data.status || "",
                          data.rate || 0,
                          data.feedback || "",
                          data.problem_solving_milestone_due_date || "",
                          data.development_milestone_due_date || "",
                          data.problem_solving_milestone || "",
                          data.development_milestone || ""
                        )
                      }}
                      outlined
                      className={`font-montserrat h-[33px] w-[136px]  border-[0.5px] border-solid  px-4 py-2 text-[14px] font-semibold leading-normal ${
                        data.status === "Scheduled" && props.currentDate > data.meeting_date
                          ? "cursor-not-allowed border-[#BDBDBD] bg-[#F7F7F7] text-[#BDBDBD]"
                          : "bg-bg-White border-[#312E81] text-[#312E81]  active:bg-[#F7F7F7] active:text-[#312E81]"
                      }`}
                      disabled={data.status === "Scheduled" && props.currentDate > data.meeting_date}
                    >
                      Session Detail
                    </Button>
                  </div>
                </td>
              </tr>
            )
          })}
          {(props.historys.length == 1 || props.historys.length == 2) && (
            <div className={`${props.historys.length == 1 ? "h-[138px]" : "h-[69px]"}`}></div>
          )}
          {props.historys.length == 0 && (
            <tr>
              <td
                colSpan={props?.tableHeaders?.length}
                className="items-center py-2 text-center text-sm font-medium leading-6 text-gray-500"
              >
                No meetings have been planned thus far.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!props.historys && (
        <div className="font-montserrat flex h-[207px] items-center justify-center text-center text-[14px] leading-6 text-[#414141]">
          You have no meeting history with your student success manager!
        </div>
      )}
    </div>
  )
}
