import { StoreRepository } from "domain/repository/Admin/StoreRepository"
import { Auth } from "domain/model/Auth"

export interface GetMentorSessionCouponUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetMentorSessionCoupon implements GetMentorSessionCouponUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return this.repository.getMentorSessionCoupon(auth, data)
  }
}
