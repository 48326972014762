import Button from "core/components/new/Button"
import { format } from "date-fns"
import React from "react"
import UpdateClass from "./UpdateClass"
import GetClassAverageRating from "domain/useCase/Admin/AllClasses/GetClassAverageRating"
import GetAllClassesAPIDataSourceImpl from "data/API/Admin/GetAllClassesAPIDataSourceImpl"
import { GetAllClassesRepositoryImpl } from "data/repository/Admin/GetAllClassesRepositoryImpl"
import { useAuth } from "core/context/auth"
import Loader from "core/components/Loader"
import { isEmpty } from "core/utils/misc"
import Spinner from "core/components/Spinner"
import DetailedRatingcard from "./DetailedRatingcard"
import GetAllClassesViewModel from "../GetAllClassesViewModel"
import Dashboard from "assets/svgs/Dashboard"
import { useNavigate } from "react-router-dom"
import EditScheduledClassModel from "./EditScheduledClassModel"
import BatchAssessmentView from "../BatchAssignment/BatchAssessmentView"
import Toast from "core/components/Toast"

export default function NewClassCard({
  data,
  changeToastVisibility,
  changeToastDetails,
  fetchAllClassesDetails,
  loading,
  getBatchList,
  instructorList,
  updateClassBool,
  upcomingClassBool,
  duplicateClassBool,
  setUpcomingClassBool,
  setUpdateClassBool,
  setDulplicateClassBool,
}: any) {
  const {
    toast,
    isLoading,
    setIsLoading,
    ClassAllRating,
    handleSearch,
    isModalOpen,
    fetchAllClassRatingDetails,
    setIsModalOpen,
    toggleModal,
    batch,
    getClassReminderData,
  } = GetAllClassesViewModel()
  const [showDetailedRating, setShowDetailedRating] = React.useState(false)
  const [ClassAverageRating, setClassAverageRating] = React.useState<any>({})
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [class_id, setClassId] = React.useState("")
  const [classbatchlist, setClassBatchList] = React.useState<any>([])

  const convertToMinutes = (timeString: string) => {
    // Extract hours and minutes from the time string
    const [hours, minutes] = timeString.split(/\D+/).map(Number)

    // Calculate total minutes
    const totalMinutes = hours * 60 + minutes

    return totalMinutes
  }

  const GetClassAverageRatingUseCase = new GetClassAverageRating(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )
  const redirectToDetailedRating = (id: string) => {
    navigate(`/admin/all-classes/${id}`)
  }
  const fetchClassAverageRatingDetails = async () => {
    const response = await GetClassAverageRatingUseCase.invoke(auth, data.session_id)

    if (!response?.success) {
      return
    }
    setClassAverageRating(response?.data)
  }

  const handleBatchAssignment = (session_id: string) => {
    navigate(`/admin/all-classes/${session_id}/submission`)
  }

  React.useEffect(() => {
    fetchClassAverageRatingDetails()
  }, [auth, data.session_id])

  React.useEffect(() => {
    // Update classBatchList whenever data changes
    if (data && data.batches && data.batches.length > 0) {
      setClassBatchList(data.batches)
    }
  }, [data])

  return (
    <div className="p-6 text-new-neutral-dark shadow rounded-[4px]">
      <div className="space-y-4">
        <div>
          <div className="flex justify-between">
            <h3>{data.chapter_name}</h3>

            {data.hasOwnProperty("class_strength") && data.hasOwnProperty("students_present") && (
              <div>
                Attendance: {data?.students_present}/{data?.class_strength}{" "}
                <span className="text-slate-500">
                  ({((data?.students_present / data?.class_strength) * 100).toFixed(2)}%)
                </span>
              </div>
            )}
            {!isEmpty(ClassAverageRating) ? (
              ClassAverageRating.avg != 0 ? (
                <div className="flex">
                  {" "}
                  <h3 className="text-new-success">
                    {ClassAverageRating.avg}/5 Rating
                    <span className="text-slate-500 px-1">({ClassAverageRating.number_of_students})</span>
                  </h3>
                </div>
              ) : (
                <div className="">Not Rated</div>
              )
            ) : (
              <Spinner small />
            )}
          </div>
          <div className="flex pt-1 flex-wrap">
            {!isEmpty(data?.batches) ? (
              data.batches.map((item: string, index: number) =>
                index === 0 ? <p className="">{item}</p> : <p className="flex flex-wrap">{` | ${item} `}</p>
              )
            ) : (
              <p className="italic">No Batch Found</p>
            )}
          </div>

          <div className="flex justify-between lg:flex-row flex-col space-y-2">
            <p className="flex flex-col-reverse font-bold">
              Instructor: {data.instructor_name != "" ? data.instructor_name : "No Instructor Found"}
            </p>
            <div className="flex items-baseline flex-wrap space-y-1">
              <Button
                outlined
                className={`w-[200px] h-[30px] mx-1 ${isLoading ? "py-2" : "p-0"}`}
                onClick={() => getClassReminderData(data?.session_id)}
              >
                {isLoading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  "Reminder Report"
                )}
              </Button>
              <Button
                outlined
                className="w-[200px] h-[30px] mx-1"
                onClick={() => handleBatchAssignment(data.session_id)}
              >
                Batch Assignments
              </Button>
              <Button
                outlined
                className="w-[200px] h-[30px] mx-1 "
                onClick={() => redirectToDetailedRating(data.session_id)}
              >
                Detailed Rating
              </Button>
              <Button outlined className="w-[200px] h-[30px] mx-1" onClick={toggleModal}>
                Update Information
              </Button>
            </div>
          </div>
        </div>
        <div className="flex bg-gray-100 gap-4 font-semibold p-4 flex-wrap rounded-[4px]">
          <p>{data.module_id}</p>
          <p className="">
            <strong>AD : </strong>
            {data.actual_duration ? data.actual_duration : "0 min"}
          </p>
          <p className="">
            <strong>CD : </strong>
            {data.considered_duration ? data.considered_duration : "0 min"}
          </p>
          {data?.video_id ? (
            <p style={{ color: "#00AB66" }}>Rec-Uploaded</p>
          ) : (
            <p style={{ color: "#EF0107" }}>Rec-Not Uploaded</p>
          )}
          <p className="">{format(parseInt(data.session_timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}</p>
        </div>
      </div>
      <EditScheduledClassModel isOpen={isModalOpen} onClose={toggleModal}>
        <UpdateClass
          updateClassBool={updateClassBool}
          setUpdateClassBool={setUpdateClassBool}
          batch={getBatchList}
          instructor={instructorList}
          session_id={data.session_id}
          currentSelectBatch={data.batches}
          chapterNames={data.chapter_name}
          classLink={data.class_link}
          selectInstructor={data.instructor}
          selectModuleId={data.module_id}
          notes_link={data.notes_link}
          selectAssigmentsQuestions={data.problems.assignments}
          selectHomeworkQuestions={data.problems.homework}
          selectPracticalQuestions={data.problems.practice}
          selectWarmupQuestions={data.problems.warmup}
          session_timestamp={data.session_timestamp}
          selectTrack={data.track}
          videoId={data.video_id}
          videoSecret={data.video_secret}
          notesLink={data.notes_link}
          assignmentSolutionLink={data.assignment_solution_link}
          assignmentSolutionActivationTime={data.assignment_solution_activation_time}
          assignmentCoins={data.assignment_points !== 0 && data.assignment_points}
          actual_duration={data.actual_duration ? convertToMinutes(data.actual_duration) : 90}
          considered_duration={data.considered_duration ? convertToMinutes(data.considered_duration) : 90}
          status={data.status}
          notesSummaryLink={data.notes_summary_link}
          changeToastVisibility={changeToastVisibility}
          changeToastDetails={changeToastDetails}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          instructor_name={data.instructor_name}
          rating={ClassAverageRating.avg}
          class_name={data.chapter_name}
          data={data}
          loading={loading}
          fetchAllClassesDetails={fetchAllClassesDetails}
          // session_date={format(
          //   parseInt(data.session_timestamp) * 1000,
          //   'do MMMM, yyyy | hh:mm a'
          // )}
        />
      </EditScheduledClassModel>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
