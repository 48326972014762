import Button from "core/components/new/Button"
import DashboardLayout from "core/layouts/DashboardLayout"
import useSheetsViewModel from "./SheetsViewModel"
import React from "react"
import Toast from "core/components/Toast"
import StandaredSelect from "core/components/StandaredSelect"
import Input from "core/components/new/Input"
import { CrossIcon } from "core/constants/svgs"
import ButtonGroup from "core/components/ButtonGroup"

export default function SheetsView() {
  const {
    refreshed,
    toast,
    allBatches,
    batch,
    fromDate,
    toDate,
    fromDate2,
    toDate2,
    isLoading,
    isLoading2,
    isLoading4,
    year,
    month,
    monthList,
    allBatches2,
    batch2,
    isLoading3,
    dataSetButton,
    blurSelect,
    dataButtonLoading,
    batch3,
    handleAllotMentors,
    handleBatchChange3,
    submitForm,
    setBatchData,
    handleFormSubmitDoubtSupport,
    handleFromDateChange2,
    handleToDateChange2,
    handleBatchChange2,
    fetchAllBatches2,
    handleAddMonth,
    handleRemoveMonth,
    handleFromDateChange,
    handleFormSubmitMonthYear,
    handleMonthChange,
    handleYearChange,
    handleToDateChange,
    handleFormSubmit,
    handleBatchChange,
    handleFormSubmitMentorList,
    fetchAllBatches,
    changeToastVisibility,
    selectedBatch,
    handleSelectedBatchChange,
    allotMentorsLoading,
  } = useSheetsViewModel()

  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  React.useEffect(() => {
    if (refreshed) {
      fetchAllBatches()
      fetchAllBatches2()
    }
  }, [refreshed])

  return (
    <>
      <div className="space-y-6 p-6">
        <div className="">
          <h2 className="text-new-accent">Analytics</h2>
          <p>Download various sheets</p>
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h3>Get Student Details Of Particular Batch</h3>
            <div className="max-w-md space-x-4">
              <form className="space-y-4" onSubmit={handleFormSubmit}>
                <div className="space-y-2 text-new-neutral-dark">
                  <label
                    htmlFor="batch"
                    className="after-star w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark"
                  >
                    Batch
                  </label>
                  <StandaredSelect
                    required
                    name="batch"
                    id="batch"
                    options={allBatches}
                    placeholder="Select Batch"
                    onChange={handleBatchChange}
                    value={batch}
                  />
                </div>
                <Input
                  required
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  value={fromDate}
                  placeholder="Choose Date"
                  label="From Date"
                  onChange={handleFromDateChange}
                />
                <Input
                  required
                  type="date"
                  name="toDate"
                  id="toDate"
                  value={toDate}
                  placeholder="Choose Date"
                  label="To Date"
                  onChange={handleToDateChange}
                />
                <Button loading={isLoading} disabled={isLoading}>
                  Download
                </Button>
              </form>
            </div>
          </div>
          <div className="space-y-4">
            <h3>Get Batch Details</h3>
            <div className="max-w-md space-x-4">
              <form className="space-y-4" onSubmit={handleFormSubmitMonthYear}>
                <div className="flex flex-col gap-y-2">
                  <div className={`space-y-2 text-new-neutral-dark ${blurSelect ? "blur" : ""}`}>
                    <label
                      htmlFor="batch"
                      className="after-star w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark"
                    >
                      Batch
                    </label>
                    <StandaredSelect
                      required
                      name="batch"
                      id="batch"
                      options={allBatches2}
                      placeholder="Select Batch"
                      onChange={handleBatchChange2}
                      value={batch2}
                    />
                  </div>
                  {batch2 != "" && (
                    <div className="flex justify-end">
                      <Button
                        onClick={setBatchData}
                        disabled={dataButtonLoading}
                        className="h-[20px] w-[150px]"
                        loading={dataButtonLoading}
                      >
                        Set Data
                      </Button>
                    </div>
                  )}
                </div>
                {!blurSelect && (
                  <Input
                    required
                    type="number"
                    id="year"
                    name="year"
                    min="2022"
                    max="2030"
                    value={year}
                    placeholder="Year"
                    label="Year"
                    onChange={handleYearChange}
                  />
                )}
                {!blurSelect && (
                  <div className="flex flex-col gap-y-1">
                    <div className="flex justify-between">
                      {" "}
                      <select value={month} onChange={handleMonthChange} className="w-fit py-1">
                        <option value="">Select Month</option>
                        {MONTHS.map((item: string, index: number) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <Button type="button" className="w-fit" onClick={handleAddMonth}>
                        Add Month
                      </Button>
                    </div>
                    <div className="flex gap-x-1">
                      {monthList.map((month: any, i: number) => (
                        <div
                          key={i}
                          className="flex w-fit items-center gap-2 rounded-sm border border-new-neutral-light py-1 px-2 text-new-neutral-dark"
                        >
                          <p>{month}</p>
                          <button
                            type="button"
                            className="inline-flex h-4 w-4 items-center justify-center"
                            onClick={() => handleRemoveMonth(i)}
                          >
                            <CrossIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!blurSelect && (
                  <Button onClick={submitForm} loading={isLoading2} disabled={isLoading2}>
                    Download
                  </Button>
                )}
              </form>
            </div>
          </div>
          <div className="space-y-4">
            <h3>Get Mentors List of a Batch</h3>
            <div className="max-w-md space-x-4">
              <form className="space-y-4" onSubmit={handleFormSubmitMentorList}>
                <div className="space-y-2 text-new-neutral-dark">
                  <label
                    htmlFor="batch"
                    className="after-star w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark"
                  >
                    Batch
                  </label>
                  <StandaredSelect
                    required
                    name="batch3"
                    id="batch3"
                    options={allBatches}
                    placeholder="Select Batch"
                    onChange={handleBatchChange3}
                    value={batch3}
                  />
                </div>
                <Button loading={isLoading4} disabled={isLoading4}>
                  Download
                </Button>
              </form>
            </div>
          </div>

          <div className="space-y-4">
            <h3>Allot mentors to all students in a batch</h3>
            <div className="max-w-md space-x-4">
              <form className="space-y-4" onSubmit={(e) => handleAllotMentors(e, selectedBatch)}>
                <div className="space-y-2 text-new-neutral-dark">
                  <label
                    htmlFor="batch"
                    className="after-star w-fit text-lg font-semibold leading-[22px] text-new-neutral-dark"
                  >
                    Batch
                  </label>
                  <StandaredSelect
                    required
                    options={allBatches}
                    placeholder="Select Batch"
                    onChange={handleSelectedBatchChange}
                    value={selectedBatch}
                  />
                </div>
                <Button loading={allotMentorsLoading} disabled={allotMentorsLoading}>
                  Allot Mentors
                </Button>
              </form>
            </div>
          </div>

          <div className="space-y-4">
            <h3>Get Doubt Support Analytics</h3>
            <div className="max-w-md space-x-4">
              <form className="space-y-4" onSubmit={handleFormSubmitDoubtSupport}>
                <Input
                  required
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  value={fromDate2}
                  placeholder="Choose Date"
                  label="From Date"
                  onChange={handleFromDateChange2}
                />
                <Input
                  required
                  type="date"
                  name="toDate"
                  id="toDate"
                  value={toDate2}
                  placeholder="Choose Date"
                  label="To Date"
                  onChange={handleToDateChange2}
                />
                <Button onClick={submitForm} loading={isLoading3} disabled={isLoading3}>
                  Download
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </>
  )
}
