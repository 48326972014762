import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import { ChangeEvent, useState } from "react"

export default function ContestConfirmationPopup({ loading, onSubmit, isAlertPopup, onClose }: any) {
  const [leetcodeId, setLeetcodeId] = useState(isAlertPopup ? "xyz" : "")

  function handleSubmit(e: any) {
    e.preventDefault()

    if (leetcodeId.length > 0) {
      onSubmit(leetcodeId)
    }
  }

  const handleLeetcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLeetcodeId(e.target.value.trim().split(" ")[0])
  }

  const handleNoClick = (e: any) => {
    e.preventDefault()
    // You can add any other logic if needed when "No" is pressed.
    onClose()
    console.log("No clicked, closing or doing nothing")
  }

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
        <form
          className="flex w-[600px] transform flex-col gap-4 rounded-lg bg-new-solid-white p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          onSubmit={handleSubmit}
        >
          {!isAlertPopup ? (
            <h3 className="text-lg font-bold text-new-neutral-dark">Enter LeetCode Username</h3>
          ) : (
            <h3 className="text-lg font-bold text-red-700">Warning</h3>
          )}
          {isAlertPopup ? (
            <p className="text-sm">
              You have already attempted this exam in the past, if you wish to reattempt it, your latest score of the
              exam would only be considered and your past attempt would be nullified. Are you sure you want to continue?
            </p>
          ) : (
            <p className="text-sm">
              To continue to this website, please enter your LeetCode username here. Please note that if your leetcode
              profile page is "https://leetcode.com/username/", then you should write the username as "username" in the
              input field.
            </p>
          )}

          {!isAlertPopup ? (
            <Input
              type="text"
              value={leetcodeId}
              onChange={handleLeetcodeChange}
              className="text-black"
              containerClass="w-full"
              placeholder="LeetCode Username"
            />
          ) : (
            <></>
          )}
          <div className="flex justify-around gap-4">
            <Button small className="w-fit" loading={loading}>
              {isAlertPopup ? "Yes" : "Submit"}
            </Button>
            <Button
              failure
              small
              className="w-fit"
              loading={loading}
              onClick={handleNoClick} // Prevent form submission
            >
              {isAlertPopup ? "No" : "Cancel"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
