import { deleteTest } from "domain/model/TestSeriesDashboard"
import { TestSeriesDashboardRepository } from "domain/repository/Admin/TestSeriesDashboardRepository"

export interface deleteWeeklyTestUseCase {
  invoke(test: deleteTest): Promise<any>
}

export class deleteWeeklyTest implements deleteWeeklyTestUseCase {
  private repository: TestSeriesDashboardRepository
  constructor(repository: TestSeriesDashboardRepository) {
    this.repository = repository
  }

  async invoke(test: deleteTest): Promise<any> {
    return await this.repository.deleteWeeklyTest(test)
  }
}
