import { Auth } from "domain/model/Auth"
import AdminQuestionChamberDataSource from "data/dataSource/Admin/QuestionChamberDataSource"
import QuestionChamberRespository from "domain/repository/Admin/QuestionChamberRepository"

export class QuestionChamberRepositoryImpl implements QuestionChamberRespository {
  private dataSource: AdminQuestionChamberDataSource

  constructor(dataSource: AdminQuestionChamberDataSource) {
    this.dataSource = dataSource
  }

  async create_sub_case_question(auth: Auth, data: any): Promise<any> {
    return this.dataSource.create_sub_case_question(auth, data)
  }

  async update_sub_case_question(auth: Auth, data: any): Promise<any> {
    return this.dataSource.update_sub_case_question(auth, data)
  }

  async delete_sub_case_question(auth: Auth, data: any): Promise<any> {
    return this.dataSource.delete_sub_case_question(auth, data)
  }

  async get_all_questions(auth: Auth, data: any): Promise<any> {
    return this.dataSource.get_all_questions(auth, data)
  }

  async get_question(auth: Auth, data: any): Promise<any> {
    return this.dataSource.get_question(auth, data)
  }

  async get_sub_case_counts(auth: Auth, data: any): Promise<any> {
    return this.dataSource.get_sub_case_counts(auth, data)
  }

  async get_filtered_questions(auth: Auth, data: any): Promise<any> {
    return this.dataSource.get_filtered_questions(auth, data)
  }
}
