import ChevronDown from "assets/svgs/ChevronDown"
import Button from "core/components/new/Button"
import { useEffect, useState } from "react"
import HistoryTable from "./components/HistoryTable"
import Toast from "core/components/Toast"
import UpdateDetails from "./components/UpdateDetails"
import DetailSession from "./components/DetailSession"
import StudentProfileViewModel from "./StudentProfileViewModel"
import { format } from "date-fns"

function SsmCallView({ email, student }: any) {
  useEffect(() => {
    if (email) {
      getHistory(email)
    }
  }, [email])
  const [collapse, setCollapse] = useState(false)
  const handleCollapse = () => {
    setCollapse(!collapse)
  }
  const tableHeaders: string[] = ["Sr. No.", "Date", "Duration", "Status", "Update Detail", "Session Detail", ""]
  const {
    meeting,
    meetingDate,
    meetingDuration,
    meetingSsmRemark,
    meetingAgenda,
    history,
    toast,
    scheduledCnt,
    // setEmail,
    handleMeeting,
    handleMeetingDate,
    handleMeetingDuration,
    handleMeetingSsmRemark,
    handleMeetingAgenda,
    handleCreateMeeting,
    handleDiscard,
    getHistory,
    changeToastVisibility,
    handleUpdateMeeting,
    handleUpdateButton,
    handleMeetingUpdatedDate,
    updatedMeeting,
    handleUpdatedMeeting,
    meetingUpdatedSsmRemark,
    meetingUpdatedAgenda,
    handleMeetingUpdatedSsmRemark,
    handleMeetingUpdatedAgenda,
    meetingUpdatedDuration,
    handleMeetingUpdatedDuration,
    meetingUpdatedDate,
    formatDate,
    isCreateGroupSessionOpen,
    setIsCreateGroupSessionOpen,
    handleCreateGroupSession,
    handleUpdateDetailButton,
    statusOption,
    updatedStatus,
    handleUpdatedStatus,
    updatedRating,
    handleUpdatedRating,
    updatedFeedback,
    handleUpdatedFeedback,
    updatedProblemSolvingMilestoneDueDate,
    handleUpdatedProblemSolvingMilestoneDate,
    updatedDevelopmentMilestoneDueDate,
    handleUpdatedDevelopmentMilestoneDate,
    updatedProblemSolvingMilestone,
    handleUpdatedProblemSolvingMilestone,
    updatedDevelopmentMilestone,
    handleUpdatedDevelopmentMilestone,
    handleMeetingDetails,
    isDetailUpdateOpen,
    setIsDetailUpdateOpen,
    handleIsDetailUpdateopen,
    handleIsSessionDetailopen,
    isSessionDetailOpen,
    setIsSessionDetailOpen,
    removeHypens,
    handleDeleteMeeting,
    handleIsDeleteSSMPopUp,
    isDeleteSSMPopUp,
    setIsDeleteSSMPopUp,
    menuVisible,
    currentDate,
    toggleMenu,
    setMenuVisible,
  } = StudentProfileViewModel()
  return (
    <>
      <div
        className={`m-[24px] flex flex-col items-start gap-4 rounded-[4px] border border-[#ADADAD] ${
          !collapse && "border-opacity-50"
        } px-6 py-4`}
      >
        <div className="w-full ">
          <div className="align-items flex w-full cursor-pointer gap-1 text-[18px]" onClick={handleCollapse}>
            <div className="font-montserrat  font-semibold text-[#312E81]">Student Progress Meeting -</div>
            <div
              className={
                scheduledCnt === 0
                  ? "font-montserrat font-semibold text-red-500"
                  : "font-montserrat font-semibold text-[#00C22B]"
              }
            >
              {scheduledCnt === 0 ? "Inactive" : "Active"}
            </div>
            <div className="ml-auto mr-2 flex cursor-pointer items-center">
              {collapse ? (
                <ChevronDown className=" h-6 w-6 rotate-180 transform" />
              ) : (
                <ChevronDown className=" h-6 w-6 " />
              )}
            </div>
          </div>
          <div className={collapse ? "block" : "hidden"}>
            <div className="relative">
              {student?.batch_v4?.slice(-1)[0]?.batch_pause_reason && (
                <div className="absolute bottom-1/2 w-full text-center z-10 animate-bounce font-semibold text-red-700">
                  You cannot schedule a meeting with this student as they have paused the course on the{" "}
                  {format(new Date(student?.batch_v4?.slice(-1)[0]?.timestamp_end * 1000), "do MMMM, yyyy")}.
                </div>
              )}
              <div
                className={`mt-4 flex flex-col items-start gap-4 rounded-[4px] border border-[#ADADAD]  p-6 ${student?.batch_v4?.slice(-1)[0]?.batch_pause_reason && "opacity-20"}`}
              >
                <div className={`font-montserrat text-[18px] font-semibold text-[#312E81]`}>Create New Meeting</div>
                <div className=" flex flex-row flex-wrap">
                  <div className="mr-4">
                    <div className="font-montserrat  text-[14px] font-semibold text-[#414141]">Meeting Topic</div>
                    <input
                      className="mt-2 w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      type="text"
                      placeholder="Type here"
                      value={meeting}
                      onChange={handleMeeting}
                      disabled={student?.batch_v4?.slice(-1)[0]?.batch_pause_reason}
                    ></input>
                  </div>
                  <div className="mr-4 ">
                    <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">Date</div>
                    <input
                      className="w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-[#6B6B6B] outline-none placeholder:text-[#6B6B6B] focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      type="date"
                      placeholder="Type here"
                      value={meetingDate}
                      onChange={handleMeetingDate}
                      disabled={student?.batch_v4?.slice(-1)[0]?.batch_pause_reason}
                    ></input>
                  </div>
                  <div className="">
                    <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                      Duration (In Mins)
                    </div>
                    <input
                      className="w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      type="number"
                      placeholder="Type here"
                      value={meetingDuration}
                      onChange={handleMeetingDuration}
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement)?.blur()}
                      disabled={student?.batch_v4?.slice(-1)[0]?.batch_pause_reason}
                    ></input>
                  </div>
                </div>
                <div className="w-full">
                  <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">Meeting Agenda</div>
                  <textarea
                    value={meetingAgenda}
                    onChange={handleMeetingAgenda}
                    className="h-[65px] w-full  rounded-sm border-[#6B6B6B] border-opacity-50 bg-transparent p-4"
                    placeholder="Type here"
                    disabled={student?.batch_v4?.slice(-1)[0]?.batch_pause_reason}
                  />
                </div>
                <div className="flex w-full items-center justify-between">
                  <div className="font-montserrat  ">
                    <div className="text-[18px] font-semibold text-[#414141]">New Meeting</div>
                    <div className="text-[14px] leading-6 text-[#6B6B6B]">Save your New Meeting data</div>
                  </div>
                  <div className="ml-auto flex space-x-4">
                    <Button
                      onClick={handleDiscard}
                      outlined
                      className="h-[52px] w-[188px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                      disabled={student?.batch_v4?.slice(-1)[0]?.batch_pause_reason}
                    >
                      Discard Changes
                    </Button>
                    <Button
                      className="font-montserrat h-[52px] w-[188px] text-[16px] font-medium  "
                      onClick={() => {
                        handleCreateMeeting(email)
                      }}
                      disabled={
                        !meeting ||
                        !meetingDate ||
                        !meetingDuration ||
                        !meetingAgenda ||
                        student?.batch_v4?.slice(-1)[0]?.batch_pause_reason
                      }
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {history.length != 0 && (
              <div className="mt-4">
                <div className="font-montserrat text-[18px] font-semibold leading-normal text-[#312E81]">
                  Meeting History :
                </div>
                <div className="font-montserrat leading-160 mb-4 text-[14px] font-normal text-[#737373]">
                  Track each meeting data with ease.
                </div>

                <HistoryTable
                  scheduledCnt={scheduledCnt}
                  handleUpdateButton={handleUpdateButton}
                  handleUpdateMeeting={handleUpdateMeeting}
                  email={email}
                  tableHeaders={tableHeaders}
                  historys={history}
                  handleMeetingUpdatedDate={handleMeetingUpdatedDate}
                  updatedMeeting={updatedMeeting}
                  currentDate={currentDate}
                  handleUpdatedMeeting={handleUpdatedMeeting}
                  meetingUpdatedSsmRemark={meetingUpdatedSsmRemark}
                  meetingUpdatedAgenda={meetingUpdatedAgenda}
                  handleMeetingUpdatedSsmRemark={handleMeetingUpdatedSsmRemark}
                  handleMeetingUpdatedAgenda={handleMeetingUpdatedAgenda}
                  meetingUpdatedDuration={meetingUpdatedDuration}
                  handleMeetingUpdatedDuration={handleMeetingUpdatedDuration}
                  meetingUpdatedDate={meetingUpdatedDate}
                  formatDate={formatDate}
                  toast={toast}
                  handleDiscard={handleDiscard}
                  changeToastVisibility={changeToastVisibility}
                  isCreateGroupSessionOpen={isCreateGroupSessionOpen}
                  setIsCreateGroupSessionOpen={setIsCreateGroupSessionOpen}
                  handleCreateGroupSession={handleCreateGroupSession}
                  handleUpdateDetailButton={handleUpdateDetailButton}
                  setIsDetailUpdateOpen={setIsDetailUpdateOpen}
                  setIsSessionDetailOpen={setIsSessionDetailOpen}
                  handleDeleteMeeting={handleDeleteMeeting}
                  handleIsDeleteSSMPopUp={handleIsDeleteSSMPopUp}
                  isDeleteSSMPopUp={isDeleteSSMPopUp}
                  setIsDeleteSSMPopUp={setIsDeleteSSMPopUp}
                  menuVisible={menuVisible}
                  toggleMenu={toggleMenu}
                  setMenuVisible={setMenuVisible}
                />
              </div>
            )}
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </div>
      <UpdateDetails
        email={email}
        handleMeetingUpdatedDate={handleMeetingUpdatedDate}
        updatedMeeting={updatedMeeting}
        handleUpdatedMeeting={handleUpdatedMeeting}
        meetingUpdatedSsmRemark={meetingUpdatedSsmRemark}
        handleMeetingUpdatedSsmRemark={handleMeetingUpdatedSsmRemark}
        meetingUpdatedAgenda={meetingUpdatedAgenda}
        handleMeetingUpdatedAgenda={handleMeetingUpdatedAgenda}
        meetingUpdatedDuration={meetingUpdatedDuration}
        handleMeetingUpdatedDuration={handleMeetingUpdatedDuration}
        meetingUpdatedDate={meetingUpdatedDate}
        formatDate={formatDate}
        toast={toast}
        changeToastVisibility={changeToastVisibility}
        isDetailUpdateOpen={isDetailUpdateOpen}
        setIsDetailUpdateOpen={setIsDetailUpdateOpen}
        handleIsDetailUpdateopen={handleIsDetailUpdateopen}
        // handleUpdateDetailButton={handleUpdateDetailButton}
        statusOption={statusOption}
        updatedStatus={updatedStatus}
        handleDiscard={handleDiscard}
        // setUpdatedStatus={setUpdatedStatus}
        handleUpdatedStatus={handleUpdatedStatus}
        updatedRating={updatedRating}
        handleUpdatedRating={handleUpdatedRating}
        updatedFeedback={updatedFeedback}
        handleUpdatedFeedback={handleUpdatedFeedback}
        updatedProblemSolvingMilestoneDueDate={updatedProblemSolvingMilestoneDueDate}
        handleUpdatedProblemSolvingMilestoneDate={handleUpdatedProblemSolvingMilestoneDate}
        updatedDevelopmentMilestoneDueDate={updatedDevelopmentMilestoneDueDate}
        handleUpdatedDevelopmentMilestoneDate={handleUpdatedDevelopmentMilestoneDate}
        updatedProblemSolvingMilestone={updatedProblemSolvingMilestone}
        handleUpdatedProblemSolvingMilestone={handleUpdatedProblemSolvingMilestone}
        updatedDevelopmentMilestone={updatedDevelopmentMilestone}
        handleUpdatedDevelopmentMilestone={handleUpdatedDevelopmentMilestone}
        handleMeetingDetails={handleMeetingDetails}
      />
      <DetailSession
        handleIsSessionDetailopen={handleIsSessionDetailopen}
        isSessionDetailOpen={isSessionDetailOpen}
        status={updatedStatus}
        date={meetingUpdatedDate}
        problemSolvingMSDate={updatedProblemSolvingMilestoneDueDate}
        problemSolvingMS={updatedProblemSolvingMilestone}
        developmentMSDate={updatedDevelopmentMilestoneDueDate}
        developmentMS={updatedDevelopmentMilestone}
        studentRating={updatedRating}
        ssmRemark={meetingUpdatedSsmRemark}
        meetingAgenda={meetingUpdatedAgenda}
        studentFeedback={updatedFeedback}
        formatDate={formatDate}
        removeHypens={removeHypens}
      />
      <div className="mb-56"></div>
    </>
  )
}

export default SsmCallView
