import { ChevronDownIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import React from "react"

export default function CourseDisclosure({ name, children, isOpen = false, className }: any) {
  const [open, setOpen] = React.useState(isOpen)

  return (
    <div className={cn("w-[calc(100%-32px)] rounded-sm border border-new-neutral-light px-6", className)}>
      <div
        className={cn(
          "flex w-full cursor-pointer items-center justify-between py-4 text-new-neutral-dark",
          open && "border-b border-dashed border-new-neutral-light"
        )}
        onClick={() => setOpen(!open)}
      >
        <h4>{name}</h4>
        <span className="ml-auto">
          <ChevronDownIcon className={cn("h-4 w-4", open && "rotate-180")} />
        </span>
      </div>
      <div className={cn("w-full overflow-x-auto py-4", !open && "hidden")}>{children}</div>
    </div>
  )
}
