import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Check, Circle } from "lucide-react"
import { cn } from "core/lib/utils"
import { cva } from "class-variance-authority"

const typeVariants = cva("", {
  variants: {
    type: {
      checkbox: "h-3 w-3",
      radio: "h-2 w-2 fill-v2-gray-500",
    },
  },
  defaultVariants: {
    type: "checkbox",
  },
})

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  buttonType: "checkbox" | "radio"
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ buttonType, className, ...props }, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        "peer h-4 w-4 shrink-0 rounded border-2 border-v2-gray-400 data-[state=checked]:border-v2-gray-600 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
        {buttonType === "checkbox" ? (
          <Check className={cn(typeVariants({ type: buttonType }))} />
        ) : (
          <Circle className={cn(typeVariants({ type: buttonType }))} />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
