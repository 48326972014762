import {
  Questions,
  TCreateQuestion,
  TCreateResponse,
  TResponse,
  TUpdateQuestion,
  TUpdateResponse,
} from "domain/model/Questions"
import { QuestionsRepository } from "domain/repository/Admin/QuestionsRepository"
import QuestionsDataSource from "data/dataSource/Admin/QuestionsDataSource"
import { Auth } from "domain/model/Auth"

export default class QuestionsRepositoryImpl implements QuestionsRepository {
  private dataSource: QuestionsDataSource

  constructor(dataSource: QuestionsDataSource) {
    this.dataSource = dataSource
  }

  async fetchQuestions(id_token: any): Promise<Questions> {
    return this.dataSource.fetchQuestions(id_token)
  }

  async getNext100Questions(id_token: any, problem_name: any): Promise<Questions> {
    return this.dataSource.getNext100Questions(id_token, problem_name)
  }

  async getQuestionByTopic(auth: Auth, topic: string): Promise<TResponse> {
    return this.dataSource.getQuestionByTopic(auth, topic)
  }

  async createQuestion(auth: Auth, data: TCreateQuestion): Promise<TCreateResponse> {
    return this.dataSource.createQuestion(auth, data)
  }

  async updateQuestion(auth: Auth, data: TUpdateQuestion): Promise<TUpdateResponse> {
    return this.dataSource.updateQuestion(auth, data)
  }
}
