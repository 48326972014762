import { mentorProfile } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface GetMentorprofileUseCase {
  invoke(profile: mentorProfile): Promise<any>
}

export class GetMentorprofile implements GetMentorprofileUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(profile: mentorProfile): Promise<any> {
    return await this.repository.getMentorProfile(profile)
  }
}
