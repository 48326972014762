import Modal from "core/components/Modal"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import { Auth } from "domain/model/Auth"
import EditMentorCompensation from "domain/useCase/Admin/GenerateInvoice/EditMentorCompensation"
import RemoveMentorCompensation from "domain/useCase/Admin/GenerateInvoice/RemoveMentorCompensation"
import React from "react"
import { useParams } from "react-router-dom"

export default function CompensationTable(props: any) {
  const [deleteMentorCompensation, setDeleteMentorCompensation] = React.useState<any>()
  const { auth } = useAuth()
  const { id } = useParams()
  const [deleteId, setDeleteId] = React.useState<any>()
  const [editModal, setEditModal] = React.useState(false)
  const [editYearMonth, setEditYearMonth] = React.useState<string>("")
  const handleOpenModal = (id: number) => {
    setConfirmModal(true)
    setDeleteId(id)
  }
  const handleCloseModal = () => {
    setConfirmModal(false)
  }

  const [confirmModal, setConfirmModal] = React.useState<boolean>(false)
  const RemoveCompensationUseCase = new RemoveMentorCompensation(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )
  const EditMentorCompensationUseCase = new EditMentorCompensation(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )
  const [loading, setLoading] = React.useState(false)
  const [amount, setAmount] = React.useState<number>(0)
  const [editId, setEditId] = React.useState<any>()
  async function handleMentorCompensationDelete(auth: Auth, index: number) {
    setLoading(true)
    const response = await RemoveCompensationUseCase.invoke(auth, id as string, index)
    setLoading(false)
    if (!response?.success) {
      props.changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      props.changeToastVisibility(true)
      return
    }
    props.changeToastDetails(STR_SUCCESS, "Compensation Deleted!")
    props.changeToastVisibility(true)
    props.setReloadData(!props.reloadData)
    handleCloseModal()
  }

  async function handleCompensationEdit(auth: Auth, index: number) {
    setLoading(true)
    const response = await EditMentorCompensationUseCase.invoke(auth, id as string, index, amount)
    setLoading(false)

    if (!response?.success) {
      props.changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      props.changeToastVisibility(true)
      return
    }
    props.changeToastDetails(STR_SUCCESS, "Compensation Amount Updated!")
    props.changeToastVisibility(true)
    props.setReloadData(!props.reloadData)
    handleCloseEditModal()
  }

  const convertToMonthYear = (dateString: string) => {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const date = new Date(`${year}-${month}-01`)
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    })
  }

  const handleOpenEditModal = (id: number, start_time: string, amount: number) => {
    setEditModal(true)
    setEditId(id)
    setEditYearMonth(start_time)
    setAmount(amount)
  }
  const handleCloseEditModal = () => {
    setEditModal(false)
  }

  function handleAmount(e: any) {
    setAmount(e.target.value)
  }

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeader?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.Compensation?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={data.email}>
              <td className="px-6 py-4 ">
                <p>{data.id + 1}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.amount}</td>
              <td className="px-6 py-4 text-[16px]"> {convertToMonthYear(data.start_time.toString())}</td>
              <td>
                <Button
                  loading={loading}
                  className="h-[10px] w-[120px]"
                  onClick={() =>
                    handleOpenEditModal(data.id, convertToMonthYear(data.start_time.toString()), data.amount)
                  }
                >
                  Edit
                </Button>
              </td>
              <td>
                <Button className="h-[10px] w-[120px]" loading={loading} onClick={() => handleOpenModal(data.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal open={confirmModal} onClose={handleCloseModal}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Delete</h3>
            <p>Are you sure you want to Delete Compensation </p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={handleCloseModal}>
              No
            </Button>
            <Button outlined onClick={() => handleMentorCompensationDelete(auth, deleteId)}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={editModal} onClose={handleCloseEditModal} width={"max-w-1xl"}>
        <div className="flex flex-col gap-y-4">
          <div className="">
            <h3 className="">Edit Amount For {editYearMonth}</h3>
          </div>
          <div className="flex gap-x-6">
            <Input
              id="yearMonth"
              type="text"
              placeholder="Enter New Amount"
              value={amount}
              onBlur={(e) => (e.target.type = "text")}
              onFocus={(e) => (e.target.type = "number")}
              onChange={handleAmount}
            />
            <Button
              loading={loading}
              outlined
              className="ml-auto w-fit"
              onClick={() => handleCompensationEdit(auth, editId)}
            >
              Edit Amount
            </Button>
          </div>
          <div className=""></div>
        </div>
      </Modal>
    </div>
  )
}
