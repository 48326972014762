import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import OrderStatusCard from "./OrderStatusCard"
import useStoreViewModel from "../StoreViewModel"
import React from "react"
import Button from "core/components/new/Button"
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "core/components/Loader"
import ButtonGroup from "core/components/ButtonGroup"
import MentorSessionCouponCard from "./MentorSessionCouponCard"
import { useAuth } from "core/context/auth"

export default function OrderHistory() {
  const {
    orderHistory,
    activeButtonType,
    allMentorCoupons,
    fetchOrderHistory,
    FetchMentorSessionCoupon,
    changeActiveButtonType,
  } = useStoreViewModel()
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    fetchOrderHistory()
    FetchMentorSessionCoupon()
    if (location.state) {
      let tab = parseInt(location.state?.tab)
      if (tab === 0 || tab === 1) {
        changeActiveButtonType(tab)
      }
    }
  }, [auth, location])

  return (
    <>
      <DashboardLayoutv2>
        <div className="divide-y divide-new-neutral-light text-new-neutral-dark">
          {
            <div className="space-y-8 p-6">
              <div className="flex items-center justify-between">
                <div className="space-y-2">
                  <h2 className="text-new-accent">Past Orders</h2>
                  <p>You can view the history of your previous orders here.</p>
                </div>
                <Button outlined failure className="h-fit w-fit" onClick={() => navigate("/redeem-coins")}>
                  Back
                </Button>
              </div>
              <div className="mt-10 px-5">
                <ButtonGroup
                  buttons={["Amazon Coupons", "Extra Mentor Session"]}
                  active={activeButtonType}
                  onChange={changeActiveButtonType}
                />
              </div>
              {activeButtonType == 0 ? (
                <div className="flex flex-col gap-6">
                  {orderHistory && orderHistory.length != 0 ? (
                    orderHistory.map((data: any, i: number) => <OrderStatusCard order={data} />)
                  ) : orderHistory && orderHistory.length == 0 ? (
                    <div className="text-lg text-black">No Orders Placed</div>
                  ) : (
                    <Loader />
                  )}
                </div>
              ) : (
                activeButtonType == 1 && (
                  <div className="flex flex-col gap-6">
                    {allMentorCoupons && allMentorCoupons.length != 0 ? (
                      allMentorCoupons.map((data: any, i: number) => <MentorSessionCouponCard order={data} />)
                    ) : allMentorCoupons && allMentorCoupons.length == 0 ? (
                      <div className="text-lg text-black">No Mentor Session</div>
                    ) : (
                      <Loader />
                    )}
                  </div>
                )
              )}
            </div>
          }
        </div>
      </DashboardLayoutv2>
    </>
  )
}
