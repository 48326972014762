import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface AddSessionReviewUseCase {
  invoke(auth: Auth, rating: number, comment: string, session_id: string): Promise<any>
}

export class AddSessionReview implements AddSessionReviewUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, rating: number, comment: string, session_id: string): Promise<any> {
    return this.repository.addSessionReview(auth, rating, comment, session_id)
  }
}
