import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface UpdateInstructorIncentiveDetailsUseCase {
  invoke(auth: Auth, instructor_id: string, incentive_amount: number, incentive_year_month: string): Promise<any>
}

export default class UpdateInstructorIncentiveDetails implements UpdateInstructorIncentiveDetailsUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string, incentive_amount: number, incentive_year_month: string) {
    return await this.repository.updateInstructorIncentiveDetails(
      auth,
      instructor_id,
      incentive_amount,
      incentive_year_month
    )
  }
}
