import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface UpdateMentorDetailsUseCase {
  invoke(auth: Auth, instructor_id: string, time_given: string): Promise<any>
}

export default class UpdateMentorDetails implements UpdateMentorDetailsUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string, time_given: string) {
    return await this.repository.updateMentorDetails(auth, instructor_id, time_given)
  }
}
