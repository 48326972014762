import { RedeemStoreCoins } from "domain/model/RedeemCoins"
import { RedeemCoinsRepository } from "domain/repository/Student/RedeemCoinsRepository"

export interface RedeemCoinsUseCase {
  invoke(redeemStoreCoins: RedeemStoreCoins): Promise<any>
}

export default class RedeemCoins implements RedeemCoinsUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(redeemStoreCoins: RedeemStoreCoins) {
    return this.repository.redeemCoins(redeemStoreCoins)
  }
}
