import { Auth } from "domain/model/Auth"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface MentorSessionInvoicingUseCase {
  invoke(auth: Auth, mentor_id: string, year_month: string): Promise<any>
}

export default class MentorSessionInvoicing implements MentorSessionInvoicingUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, year_month: string) {
    return await this.repository.mentorSessionInvoicing(auth, mentor_id, year_month)
  }
}
