import { API_V4_ADMIN, API_V4_UPDATE_SSM_CALL_DETAIL } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { UpdateSSMCallDetail } from "domain/model/UpdateSSMCallDetail"
import UpdateSSMCallDetailDataSource from "data/dataSource/Admin/UpdateSSMCallDetailDataSource"
import { RequestQuote } from "@mui/icons-material"

export class UpdateSSMCallDetailAPIDataSourceImpl implements UpdateSSMCallDetailDataSource {
  async updateSSMCallDetail(updateSSMCallDetail: UpdateSSMCallDetail): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_UPDATE_SSM_CALL_DETAIL), updateSSMCallDetail)
      return response
    } catch (error) {
      return error
    }
  }
}
