import React from "react"
import { Menu, Transition } from "@headlessui/react"
import Button from "core/components/new/Button"
import { cn } from "core/lib/utils"

export default function ResumeDropdown({ resumes }: any) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button>
            <Button outlined type="button" className={cn(open && "bg-new-accent text-new-solid-white")}>
              All Resumes
            </Button>
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="no-scrollbar absolute right-0 mt-2 max-h-[300px] w-48 origin-top-right divide-y divide-new-neutral-light overflow-y-auto rounded-md border border-new-neutral-light bg-new-solid-white">
              {resumes?.map((resume: any, index: number) => (
                <div className="p-1" key={index}>
                  <Menu.Item>
                    <a
                      href={resume?.url}
                      className="w-full rounded-md px-2 py-2 text-sm hover:text-new-accent hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {resume?.filename}
                    </a>
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
