import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface SearchStudentDoubtUseCase {
  invoke(auth: Auth, problem_id: any): Promise<any>
}

export class SearchStudentDoubt implements SearchStudentDoubtUseCase {
  private repository: DoubtSupportRepository
  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, problem_id: any) {
    return this.repository.searchStudentDoubt(auth, problem_id)
  }
}
