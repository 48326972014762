import { Auth } from "domain/model/Auth"
import { InvoiceSearch } from "domain/model/PaymentInvoice"
import { PaymentInvoiceRepository } from "domain/repository/Admin/PaymentInvoiceRepository"

export interface GetInvoicesUseCase {
  invoke(InvoiceFetchDetails: InvoiceSearch): Promise<any>
}

export class GetInvoices implements GetInvoicesUseCase {
  private repository: PaymentInvoiceRepository

  constructor(repository: PaymentInvoiceRepository) {
    this.repository = repository
  }
  async invoke(InvoiceFetchDetails: InvoiceSearch) {
    return await this.repository.getInvoices(InvoiceFetchDetails)
  }
}
