import { cn } from "core/lib/utils"

export default function SilverMedel({ className }: any) {
  return (
    <svg className={cn(className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 144" fill="none">
      <path
        d="M65.0031 0L95.9646 17.7522L127 36L127 71.9744V108L95.9646 126.248L65.0031 144L33.5 126L2 108L2 72L2 36L33.3215 17.7522L65.0031 0Z"
        fill="#8697A7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65 144L33.5 126L2 108L2 64L33 38L64 66L95 49L88 61L95 96L65 144Z"
        fill="#545D62"
      />
      <path
        d="M33.0006 36.0034L35.0006 36.0035L64 52L93.0006 36.0037L95.0006 36.0039L95 38L64 55L33.0006 38L33.0006 36.0034Z"
        fill="#DCFCFF"
      />
      <path d="M33 49.0023V38L64 55.0023V66.0023L33 49.0023Z" fill="#668A95" />
      <path d="M95 49.0023V38L64 55.0023V66.0023L95 49.0023Z" fill="#E5FDFF" />
      <path d="M95 38L64 66L95 49V38Z" fill="#BBDEE2" />
      <path d="M33 38L64 66L33 49V38Z" fill="#4E7072" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36 58.0019L64 77.0136L92 58.0019L95 58V94.0136L64 112.014L33 94.0136L33 58L36 58.0019Z"
        fill="#DCFCFF"
      />
      <path d="M64 79L33 58L33 96L64 114L64 79Z" fill="#668A95" />
      <path d="M64 79L95 58V96L64 114L64 79Z" fill="#E5FDFF" />
      <path d="M64 114L33 58L33 96L64 114Z" fill="#4E7072" />
      <path d="M64 114L95 58V96L64 114Z" fill="#BBDEE2" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65 9L37.291 24.6983L10.0004 41L10.0004 103.507L37.2911 119.302L64.9961 134.996L91.9471 119.33L119 104.01L119 40.5765L91.9471 24.6705L65 9ZM95.9646 17.7522L65.0031 0L33.3215 17.7522L2 36L2 108L18 117L33.5 126L65.0031 144L95.9646 126.248L127 108L127 36L95.9646 17.7522Z"
        fill="#758481"
      />
      <path d="M68.002 10.7526L70.9996 3.44141L119.164 31.3945L113 37.0156L68.002 10.7526Z" fill="#E9FEFF" />
      <path d="M65 144V135L10 103.5L10 41L2 36L2 108L65 144Z" fill="#3B4144" />
    </svg>
  )
}
