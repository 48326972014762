import { CoinIcon, CopyIcon } from "core/constants/svgs"
import DashboardLayout from "core/layouts/DashboardLayout"
import { format } from "date-fns"
import AmazonCouponStoreCard from "assets/images/amazon voucher.png"
import React from "react"
import Button from "core/components/new/Button"
import CopyButton from "core/components/Button"
import TransactionViewModel from "./TransactionViewModel"
import ButtonGroup from "core/components/ButtonGroup"
import extra_mentor_session from "assets/images/extra_mentor_session.png"

export default function CoinsTracker() {
  const {
    clickedCopyIndex,
    transactionDetails,
    couponTransactionDetails,
    studentData,
    tabs,
    activeTab,
    handleTabChange,
    formatDateFromTimestamp,
    handleCopyCoupon,
    getTransactionDetails,
    mapCoinToValue,
    getCouponTransactionDetails,
  } = TransactionViewModel()

  React.useEffect(() => {
    getTransactionDetails()
    getCouponTransactionDetails()
  }, [])

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 p-6">
        <div className="flex flex-row gap-[10px] h-full overflow-hidden rounded px-[32px] py-[24px] shadow-[0px_1px_17px_rgba(0,0,0,0.1)] justify-between">
          <div className="flex flex-col gap-[12px]">
            <div className="flex flex-row items-center gap-[4px]">
              <h3 className="text-[#312E81]">Student Name:</h3>
              <div>{studentData.name}</div>
            </div>
            <div className="flex flex-row items-center gap-[4px]">
              <h3 className="text-[#312E81]">Student Email:</h3>
              <div>{studentData.email}</div>
            </div>
          </div>
          <div className="flex flex-col gap-[12px]">
            <div className="flex flex-row items-center gap-[4px]">
              <h3 className="text-[#312E81]">Batch:</h3>
              <div>{studentData.batch}</div>
            </div>
            <div className="flex flex-row items-center gap-[4px]">
              <h3 className="text-[#312E81]">Coins:</h3>
              <div>{studentData.points}</div>
            </div>
          </div>
        </div>
        <ButtonGroup buttons={tabs} active={activeTab} onChange={handleTabChange} />

        {activeTab === 0
          ? transactionDetails
              ?.sort((a: any, b: any) => b.order_date - a.order_date)
              .map((transaction: any, index: any) => (
                <div className="flex h-[162px] overflow-hidden rounded px-[32px] py-[24px] shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
                  <div className="h-full w-full max-w-[240px] bg-gray-400">
                    <img className="h-full" src={AmazonCouponStoreCard}></img>
                  </div>
                  <div className="flex w-full justify-between pl-6">
                    <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
                      <div className="flex justify-between">
                        <div className="space-y-[4px]">
                          <h6>Order ID : {transaction.order_id}</h6>
                          <h3 className="text-[#312E81]">Amazon Coupon ({transaction.amount} INR) </h3>
                          <span>{transaction.reference_id}</span>
                          <div className="flex">
                            <span className="text-[#A3A3A3]">Date of Order / Expiry :</span>
                            <span className="">
                              {format(transaction.order_date * 1000, "do MMM, yyyy")} -{" "}
                              {format(transaction.expiry_date * 1000, "do MMM, yyyy")}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 self-start">
                          <span>
                            <CoinIcon className="h-6 w-6" />
                          </span>
                          <span className="text-xl font-bold text-[#FBBF24]">{mapCoinToValue(transaction.amount)}</span>
                        </div>
                      </div>
                      <div className="flex items-end justify-between">
                        <div className="flex flex-col gap-y-[2px]">
                          <div className="text-[#A3A3A3]">Coupon Code</div>
                          <span className="flex w-full max-w-[330px] items-center gap-x-[8px] font-medium text-[#525252]">
                            <h4 className="">{transaction.coupon_id}</h4>
                            <CopyButton
                              outlined
                              onClick={() => handleCopyCoupon(transaction.coupon_id, index)}
                              className={
                                clickedCopyIndex === index ? "border-0 px-0 py-0 text-blue-500" : "border-0 px-0 py-0"
                              }
                            >
                              <CopyIcon stroke={clickedCopyIndex === index ? "#00FF00" : "#0000FF"}></CopyIcon>
                            </CopyButton>
                          </span>
                        </div>
                        <Button
                          success={true}
                          disabled={true}
                          className="w-fit bg-green-50 px-[36px] py-[8px] text-[#00C22B]"
                        >
                          Redeem Successful
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          : couponTransactionDetails
              ?.sort((a: any, b: any) => b.order_date - a.order_date)
              .map((transaction: any, index: any) => (
                <div className="flex h-[162px] overflow-hidden rounded px-[32px] py-[24px] shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
                  <div className="h-full w-full max-w-[240px] bg-gray-400">
                    <img className="h-full" src={extra_mentor_session}></img>
                  </div>
                  <div className="flex w-full justify-between pl-6">
                    <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
                      <div className="flex justify-between">
                        <div className="space-y-[4px]">
                          <h6>Order ID : {transaction.order_id}</h6>
                          <h3 className="text-[#312E81]">Mentor Session</h3>
                          <span>{transaction.reference_id}</span>
                          <div className="flex">
                            <span className="text-[#A3A3A3]">Date of Order: </span>
                            <span className="">{formatDateFromTimestamp(transaction.order_date)}</span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 self-start">
                          <span>
                            <CoinIcon className="h-6 w-6" />
                          </span>
                          <span className="text-xl font-bold text-[#FBBF24]">100000</span>
                        </div>
                      </div>
                      <div className="flex items-end justify-between">
                        <Button
                          success={true}
                          disabled={true}
                          className="w-fit bg-green-50 px-[36px] py-[8px] text-[#00C22B]"
                        >
                          Redeem Successful
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
      </div>
    </DashboardLayout>
  )
}
