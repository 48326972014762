import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface PaymentOneShotUseCase {
  invoke(auth: Auth): Promise<any>
}

export class PaymentOneShot implements PaymentOneShotUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.createPaymentOneShot(auth)
  }
}
