import { cn } from "core/lib/utils"
import React from "react"

export default function ReferralModal({ isOpen, children, width, height }: any) {
  if (!isOpen) return null
  return (
    <div className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-[10px] z-0"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative">{children}</div>
      </div>
    </div>
  )
}
