import { updateScheduleTest } from "domain/model/TestSeriesDashboard"
import { TestSeriesDashboardRepository } from "domain/repository/Admin/TestSeriesDashboardRepository"

export interface updateScheduleTestUseCase {
  invoke(test: updateScheduleTest): Promise<any>
}

export class updateScheduleTests implements updateScheduleTestUseCase {
  private repository: TestSeriesDashboardRepository
  constructor(repository: TestSeriesDashboardRepository) {
    this.repository = repository
  }

  async invoke(test: updateScheduleTest): Promise<any> {
    return await this.repository.updateScheduledTest(test)
  }
}
