import { cn } from "core/lib/utils"

export default function Gold({ className }: any) {
  return (
    <svg
      className={cn(className ?? "h-full w-32")}
      viewBox="0 0 128 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_814_24094)">
        <path
          d="M65.3966 144.002L4.1126 109.76L2.73999 38.8668L62.6258 2.18817L123.884 36.4026L125.256 107.322L65.3966 144.002Z"
          fill="#C49A44"
        />
        <path
          d="M2.29676 110.812L48.8953 136.856C49.7607 135.945 50.6524 135.034 51.4936 134.043L5.93269 108.585L5.30859 76.368C4.05482 77.0292 2.85298 77.7184 1.67607 78.4616L2.29676 110.812Z"
          fill="#65401D"
        />
        <path
          d="M62.5836 -0.0545673L0.87945 37.7508L1.68138 78.456C2.85829 77.7128 4.08711 76.9977 5.31389 76.3625L4.61607 39.8218L62.658 4.24257L69.6376 8.13166C70.3743 7.11029 71.1102 6.11558 71.8725 5.12155L62.5836 -0.0545673Z"
          fill="#AD8557"
        />
        <path
          d="M127.124 108.277L125.701 35.2207L123.6 34.0457C122.603 34.927 121.529 35.7529 120.482 36.5795L122.065 37.4475L123.387 106.206L65.318 141.759L51.4884 134.037C50.6472 135.029 49.7555 135.94 48.8901 136.851L65.4181 146.083L127.124 108.277Z"
          fill="#AD8557"
        />
        <path
          d="M120.486 36.5466C121.533 35.7201 122.607 34.8942 123.603 34.0131L71.8693 5.11641C71.1071 6.11036 70.3711 7.10499 69.6345 8.12628L120.486 36.5466Z"
          fill="#FDF7C3"
        />
        <path
          d="M35.6249 60.8789L5.49594 85.4956L5.98281 108.664L65.4019 141.683L97.4967 100.898L65.2125 118.202L34.3808 99.2625L35.6249 60.8789Z"
          fill="#8F5C2F"
        />
        <path
          d="M97.5053 100.901L65.2188 118.193L34.3763 99.2505L35.5912 61.6768L66.1098 83.0108L98.1873 63.5796L97.5053 100.901Z"
          fill="#AC7848"
        />
        <path d="M97.5049 100.897L65.2098 118.192L66.1011 83.004L98.1871 63.5693L97.5049 100.897Z" fill="#E5C767" />
        <path d="M98.1876 63.5786L65.219 118.192L35.5913 61.6758L66.11 83.0098L98.1876 63.5786Z" fill="#FDF0CD" />
        <path d="M66.119 83.0137L65.2277 118.202L35.5918 61.6758L66.119 83.0137Z" fill="#DA9A42" />
        <path
          d="M35.6051 61.6765L35.6255 60.877L66.1474 81.6702L98.2143 62.5023L98.1871 63.5683L66.1134 83.0027L35.6051 61.6765Z"
          fill="#FDF7C3"
        />
        <path d="M66.602 64.0803L66.3108 75.5422L35.2017 56.8603L35.5063 44.8652L66.602 64.0803Z" fill="#E09E7E" />
        <path d="M66.5943 64.0721L98.6243 46.5022L98.3197 58.4973L66.3032 75.5341L66.5943 64.0721Z" fill="#E5C767" />
        <path d="M66.602 64.0803L66.3108 75.5422L35.2017 56.8603L35.5063 44.8652L66.602 64.0803Z" fill="#AC7848" />
        <path d="M98.6247 46.5052L66.3061 75.537L35.5085 44.8652L66.5981 64.076L98.6247 46.5052Z" fill="#FDF0CD" />
        <path d="M66.6008 64.0793L66.3087 75.5422L35.5088 44.8652L66.6008 64.0793Z" fill="#DA9A42" />
        <path
          d="M35.5107 44.8648L35.5378 43.7988L66.6263 63.0059L98.6522 45.4377L98.6251 46.5036L66.5991 64.0718L35.5107 44.8648Z"
          fill="#FDF7C3"
        />
        <path
          d="M98.318 58.4982L94.7427 64.5409L66.1481 81.6727L35.6282 60.8756L5.52797 85.4375L5.15462 69.1558L35.5382 43.801L35.2044 56.8633L66.3048 75.5414L98.318 58.4982Z"
          fill="#8F5C2F"
        />
        <path d="M63.825 61.3875L75.8146 27.2995L55.165 28.2389L38.8454 45.7525L63.825 61.3875Z" fill="#8F5C2F" />
        <path d="M66.1001 41.3156L75.8163 27.2977L65.6541 22.5712L55.1639 28.2402L66.1001 41.3156Z" fill="#FDF0CD" />
        <path
          d="M55.904 27.0052L55.1636 28.2365L65.6529 22.5688L75.8144 27.2943L74.9663 26.1355L65.5453 20.1787L55.904 27.0052Z"
          fill="#F8D781"
        />
        <path d="M66.1565 33.728L55.1646 28.2383L66.1014 41.3175L75.8142 27.2989L66.1565 33.728Z" fill="#AC7848" />
      </g>
      <defs>
        <clipPath id="clip0_814_24094">
          <rect width="128" height="146" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
