import { Auth } from "domain/model/Auth"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export interface CancelMentorSessionByMentorOldUseCase {
  invoke(auth: Auth, date: any, start: any, end: any): Promise<any>
}

export class CancelMentorSessionByMentorOld implements CancelMentorSessionByMentorOldUseCase {
  private repository: MyMenteeRepository

  constructor(repository: MyMenteeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, date: any, start: any, end: any) {
    return await this.repository.cancelMentorSessionByMentorOld(auth, date, start, end)
  }
}
