import { deleteScheduleTest } from "domain/model/TestSeriesDashboard"
import { TestSeriesDashboardRepository } from "domain/repository/Admin/TestSeriesDashboardRepository"

export interface deleteScheduleWeeklyTestUseCase {
  invoke(test: deleteScheduleTest): Promise<any>
}

export class deleteScheduleWeeklyTest implements deleteScheduleWeeklyTestUseCase {
  private repository: TestSeriesDashboardRepository
  constructor(repository: TestSeriesDashboardRepository) {
    this.repository = repository
  }

  async invoke(test: deleteScheduleTest): Promise<any> {
    return await this.repository.deleteScheduleTest(test)
  }
}
