import SendEmailRepository from "domain/repository/Admin/SendEmailRepository"
export interface SendEmailUseCase {
  invoke(auth: any, data: FormData): Promise<any>
}

export class SendEmail implements SendEmailUseCase {
  private readonly repository: SendEmailRepository

  constructor(repository: SendEmailRepository) {
    this.repository = repository
  }

  async invoke(auth: any, data: FormData): Promise<any> {
    return await this.repository.sendEmail(auth, data)
  }
}
