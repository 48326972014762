import { useState } from "react"
import { STR_FAILURE } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import { useParams } from "react-router-dom"
import useLocalStorage from "core/hooks/useLocalStorage"
import { EditorRepositoryImpl } from "data/repository/Student/EditorRepositoryImpl"
import EditorAPIDataSourceImpl from "data/API/Student/EditorAPIDataSourceImpl"
import { ImportTemplateCode } from "domain/useCase/Student/Editor/ImportTemplateCode"
import { GetQuestion } from "domain/useCase/Student/Editor/GetQuestion"
import { GetSubmissions } from "domain/useCase/Student/Editor/GetSubmissions"
import { LikeQuestion } from "domain/useCase/Student/Editor/LikeQuestion"
import { DislikeQuestion } from "domain/useCase/Student/Editor/DislikeQuestion"
import { RunCode } from "domain/useCase/Student/Editor/RunCode"
import { CreateSubmission } from "domain/useCase/Student/Editor/CreateSubmission"
import { GetSubmission } from "domain/useCase/Student/Editor/GetSubmission"
import { genError } from "core/utils/string"
import { useAuth } from "core/context/auth"

export default function EditorViewModel() {
  const GET_SUBMISSION_DELAY = 2000

  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { auth, refreshed } = useAuth()

  const { id } = useParams()

  const [problemTemplates, setProblemTemplates] = useLocalStorage<any>("problems", {})
  const [language, setLanguage] = useLocalStorage<string>("language", "cpp")

  const [code, setCode] = useState<string>("")
  const [templates, setTemplates] = useState<any>({})
  const [question, setQuestion] = useState<any>({})
  const [activeTab, setActiveTab] = useState(0)
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false)
  const [isQuestionLoaded, setIsQuestionLoaded] = useState(false)
  const [submissions, setSubmissions] = useState<any>([])
  const [isCodeRunning, setIsCodeRunning] = useState(false)
  const [isCodeSubmitting, setIsCodeSubmitting] = useState<boolean | null>(null)
  const [runCodeDetails, setRunCodeDetails] = useState<any>({})
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isAllSubmissionsLoaded, setIsAllSubmissionsLoaded] = useState<boolean>(false)
  const [submitCodeDetails, setSubmitCodeDetails] = useState<any>({})
  const [showRunCodeDetails, setShowRunCodeDetails] = useState<boolean>(false)
  const [resetingTemplate, setResetingTemplate] = useState<boolean>(false)

  const handleTabChange = (index: number) => {
    setActiveTab(index)
  }

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value)
  }

  const handleCodeChange = (code: string | undefined, lang = language) => {
    if (typeof lang !== "string") return

    setCode(code ?? "")
    setTemplates((t: any) => ({ ...t, [lang]: code }))
    setProblemTemplates((t: any) => ({
      ...t,
      ...(id && { [id]: { ...t[id], [lang]: code } }),
    }))
  }

  const handleDrawer = (open: boolean) => {
    setIsDrawerOpen(open)
  }

  const importTemplateCodeUseCase = new ImportTemplateCode(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getQuestionUseCase = new GetQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getSubmissionsUseCase = new GetSubmissions(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const likeQuestionUseCase = new LikeQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const dislikeQuestionUseCase = new DislikeQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const runCodeUseCase = new RunCode(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const createSubmissionUseCase = new CreateSubmission(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getSubmissionUseCase = new GetSubmission(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const fetchDefaultTemplateCode = async (type = "") => {
    if (type === "reset") setResetingTemplate(true)

    const response = await importTemplateCodeUseCase.invoke(id as string, auth)

    if (type === "reset") setResetingTemplate(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching template code"))
      return null
    }

    setCode(response?.data[language])
    setTemplates(response?.data)
    setProblemTemplates((t: any) => ({
      ...t,
      ...(id && { [id]: response?.data }),
    }))

    return response
  }

  const fetchTemplateCode = async () => {
    if (!id) return

    if (id in problemTemplates && Object.keys(problemTemplates[id]).length > 0) {
      setCode(problemTemplates[id][language])
      setTemplates(problemTemplates[id])
      setIsTemplateLoaded(true)
      return
    }

    setIsTemplateLoaded(false)
    const response = await fetchDefaultTemplateCode()
    setIsTemplateLoaded(true)

    setTemplates(response?.data)
  }

  const fetchQuestion = async () => {
    setIsQuestionLoaded(false)

    const response = await getQuestionUseCase.invoke(auth, id as string)

    setIsQuestionLoaded(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching question"))
      setIsQuestionLoaded(true)
      return
    }

    setQuestion(response?.data)
  }

  const fetchSubmissions = async (topic?: string) => {
    setIsAllSubmissionsLoaded(false)

    const response = await getSubmissionsUseCase.invoke(auth, id as string)

    setIsAllSubmissionsLoaded(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching submissions"))
      return
    }

    const submissions = response?.data?.reverse()
    setSubmissions(submissions?.filter((s: any) => s?.total_testcase !== -1))

    // fetch last submitted code
    if (!topic) {
      let accepted_found = false
      for (let i = 0; i < submissions?.length; ++i) {
        let s = submissions[i]
        if (s?.total_testcase > 0 && s?.score === s?.total_testcase) {
          handleCodeChange(s?.code, s?.language)
          setLanguage(s?.language)
          accepted_found = true
          break
        }
      }
      if (!accepted_found && submissions?.length > 0) {
        const s = submissions[0]
        handleCodeChange(s?.code, s?.language)
        setLanguage(s?.language)
      }
    }
  }

  const handleLikeProblem = async () => {
    setQuestion((question: any) => ({
      ...question,
      liked: !question?.liked,
      likes: question?.liked ? question?.likes - 1 : question?.likes + 1,
      dislikes: !question?.liked && question?.disliked ? question?.dislikes - 1 : question?.dislikes,
      disliked: false,
    }))

    const response = await likeQuestionUseCase.invoke(auth, id as string, !question?.liked)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error in liking the problem")
      changeToastVisibility(true)
    }
  }

  const handleDislikeProblem = async () => {
    setQuestion((question: any) => ({
      ...question,
      disliked: !question?.disliked,
      dislikes: question?.disliked ? question?.dislikes - 1 : question?.dislikes + 1,
      likes: !question?.disliked && question?.liked ? question?.likes - 1 : question?.likes,
      liked: false,
    }))

    const response = await dislikeQuestionUseCase.invoke(auth, id as string, !question?.disliked)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error in disliking the problem")
      changeToastVisibility(true)
    }
  }

  const handleGetSubmission = async (token: string, callback?: any) => {
    const response = await getSubmissionUseCase.invoke(auth, token)

    if (
      response?.description !== "In Queue" &&
      response?.description !== "Processing" &&
      typeof callback === "function"
    ) {
      callback()
      setIsCodeRunning(false)
      setIsCodeSubmitting(false)
    }

    return response
  }

  const handleRunCode = async (language: string, code: string, code_folder: string) => {
    setIsCodeRunning(true)

    const response = await runCodeUseCase.invoke(auth, {
      language,
      code,
      code_folder,
    })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error in running code"))
      changeToastVisibility(true)
      setIsCodeRunning(false)
      return
    }

    let submissionResponse = await handleGetSubmission(response?.data?.token)

    if (!submissionResponse?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
      changeToastVisibility(true)
      return
    }

    setRunCodeDetails(submissionResponse?.data)

    handleOpenRunCodeDetails()

    setTimeout(async () => {
      const interval = setInterval(async () => {
        submissionResponse = await handleGetSubmission(response?.data?.token, () => clearInterval(interval))

        if (!submissionResponse?.success) {
          clearInterval(interval)
          changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
          changeToastVisibility(true)
          setIsCodeRunning(false)
          return
        }

        setRunCodeDetails(submissionResponse?.data)
      }, GET_SUBMISSION_DELAY)
    }, GET_SUBMISSION_DELAY)
  }

  const handleSubmitCode = async (language: string, code: string, code_folder: string) => {
    setIsCodeSubmitting(true)
    setActiveTab(1)
    setSubmitCodeDetails({})

    const response = await createSubmissionUseCase.invoke(auth, {
      language,
      code,
      code_folder,
    })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error in creating submission"))
      changeToastVisibility(true)
      setIsCodeSubmitting(false)
      return
    }

    let submissionResponse = await handleGetSubmission(response?.data?.token)

    if (!submissionResponse?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
      changeToastVisibility(true)
      setIsCodeSubmitting(false)
      return
    }

    setSubmitCodeDetails(submissionResponse?.data)

    setTimeout(() => {
      const interval = setInterval(async () => {
        submissionResponse = await handleGetSubmission(response?.data?.token, () => clearInterval(interval))

        if (!submissionResponse?.success) {
          clearInterval(interval)
          changeToastDetails(STR_FAILURE, genError(response, "Error in getting submission result"))
          changeToastVisibility(true)
          setIsCodeSubmitting(false)
          return
        }

        setSubmitCodeDetails(submissionResponse?.data)
      }, GET_SUBMISSION_DELAY)
    }, GET_SUBMISSION_DELAY)
  }

  const handleOpenRunCodeDetails = () => {
    setShowRunCodeDetails(true)
  }

  const handleCloseRunCodeDetails = () => {
    setShowRunCodeDetails(false)
  }

  const fetchProblemData = async () => {
    await fetchQuestion()
    await fetchTemplateCode()
    await fetchSubmissions()
  }

  return {
    toast,
    topic: id,
    templates,
    question,
    activeTab,
    language,
    code,
    runCodeDetails,
    submissions,
    isTemplateLoaded,
    isQuestionLoaded,
    isCodeRunning,
    isCodeSubmitting,
    isDrawerOpen,
    isAllSubmissionsLoaded,
    submitCodeDetails,
    showRunCodeDetails,
    resetingTemplate,
    refreshed,
    fetchDefaultTemplateCode,
    handleCloseRunCodeDetails,
    handleLanguageChange,
    handleTabChange,
    handleCodeChange,
    fetchProblemData,
    handleLikeProblem,
    handleDislikeProblem,
    handleRunCode,
    handleSubmitCode,
    fetchSubmissions,
    changeToastVisibility,
    handleDrawer,
  }
}
