import MonthlyFeedbackRepository from "domain/repository/Student/MonthlyFeedbackRepository"

export interface GetMonthlyFeedbackUseCase {
  invoke(auth: any, data: any): Promise<any>
}

export class GetMonthlyFeedback implements GetMonthlyFeedbackUseCase {
  private readonly repository: MonthlyFeedbackRepository

  constructor(repository: MonthlyFeedbackRepository) {
    this.repository = repository
  }

  async invoke(auth: any, data: any): Promise<any> {
    return await this.repository.getMonthlyFeedback(auth, data)
  }
}
