import { Auth } from "domain/model/Auth"

import { EndBatchParams } from "domain/model/EndBatch"
import { BatchRepository } from "domain/repository/Admin/BatchRepository"

export interface GetAllActiveBatchesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllActiveBatches implements GetAllActiveBatchesUseCase {
  private repository: BatchRepository

  constructor(repository: BatchRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllActiveBatches(auth)
  }
}
