import React from "react"

export default function RemoveFilePopup({ onCancel, onConfirm }: any) {
  return (
    <React.Fragment>
      <div className="fixed inset-0 bg-black opacity-80 z-10"></div>
      <div className="flex flex-col rounded items-center fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 h-[258px] w-[346px] bg-white p-6 text-black">
        <div className="text-center text-[#DC2626] font-semibold text-lg">Delete Document</div>

        <button onClick={onCancel} className="absolute top-4 right-4 p-2">
          <svg className="w-6 h-6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z"
              fill="#414141"
            />
          </svg>
        </button>
        <div className="my-[16px] w-[60%] text-center text-sm text-[#414141] leading-[22.4px]">
          You are about to delete uploaded document!!
        </div>
        <button
          className="w-[192px] h-[52px] font-semibold rounded-sm bg-[#DC2626] text-white text-base hover:bg-[#841919]"
          onClick={onConfirm}
        >
          Delete
        </button>
        <button
          className="mt-2 w-[192px] h-[52px] font-semibold rounded-sm border-[#312E81] border-[2px] text-[#312E81] hover:bg-[#312E81] hover:text-white"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  )
}
