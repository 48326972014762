import CopyButton from "core/components/Button"
import RedeemButton from "core/components/new/Button"
import { CoinIcon, CopyIcon } from "core/constants/svgs"
import { format } from "date-fns"
import React from "react"
import amazon_vocher from "assets/images/amazon_voucher.png"
import extra_mentor_session from "assets/images/extra_mentor_session.png"

export default function OrderStatusCard(order: any) {
  const [copyColor, setCopyColor] = React.useState(false)
  function handleCopyCoupon() {
    setCopyColor(true)
    navigator.clipboard.writeText(order?.order?.coupon_id)
  }
  const amountToCoinsMap: { [key: number]: string } = {
    50: "10000",
    75: "15000",
    100: "19000",
    200: "35000",
    500: "90000",
  }
  // const expiry = format(order.order_date * 1000, "do MMM, yyyy");
  return (
    <div className="flex h-[162px] overflow-hidden rounded px-[32px] py-[24px] shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
      <div className="h-full w-full max-w-[240px] bg-gray-400">
        <img className="h-full" src={amazon_vocher} alt="" />
      </div>
      <div className="flex w-full justify-between pl-6">
        <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
          <div className="flex justify-between">
            <div className="space-y-[4px]">
              <h6>Order ID : #{order?.order?.order_id}</h6>
              <h3 className="text-[#312E81]">Amazon Coupon ({order?.order?.amount} Rupees)</h3>
              <div className="flex">
                <span className="text-[#A3A3A3]">Date of Order / Expiry :</span>
                <span className="">
                  {format(order?.order?.order_date * 1000, "do MMM, yyyy")} -{" "}
                  {format(order?.order?.expiry_date * 1000, "do MMM, yyyy")}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 self-start">
              <span>
                <CoinIcon className="h-6 w-6" />
              </span>
              <span className="text-xl font-bold text-[#FBBF24]">{amountToCoinsMap[order?.order?.amount]}</span>
            </div>
          </div>
          <div className="flex items-end justify-between">
            <div className="flex flex-col gap-y-[2px]">
              <div className="text-[#A3A3A3]">Coupon Code</div>
              <span className="flex w-full max-w-[330px] items-center gap-x-[8px] font-medium text-[#525252]">
                <h4 className="">{order?.order?.coupon_id}</h4>
                <CopyButton
                  outlined
                  onClick={handleCopyCoupon}
                  className={copyColor ? "border-0 px-0 py-0 text-blue-500" : "border-0 px-0 py-0"}
                >
                  <CopyIcon stroke={copyColor ? "#00FF00" : "#0000FF"}></CopyIcon>
                </CopyButton>
              </span>
            </div>
            <RedeemButton
              success={true}
              disabled={true}
              className="w-fit bg-green-50 px-[36px] py-[8px] text-[#00C22B]"
            >
              Redeem Successful
            </RedeemButton>
          </div>
        </div>
      </div>
    </div>
  )
}
