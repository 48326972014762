import { ContestApproveDataSource } from "data/dataSource/Admin/ContestApproveDataSource"
import { Auth } from "domain/model/Auth"
import ReferandEarnRepository from "domain/repository/Admin/ReferandEarnRepository"

export class ReferandEarnRepositoryImpl implements ReferandEarnRepository {
  private dataSource: ReferandEarnRepository

  constructor(dataSource: ReferandEarnRepository) {
    this.dataSource = dataSource
  }

  getTop100ReferandEarnRequests(auth: Auth) {
    return this.dataSource.getTop100ReferandEarnRequests(auth)
  }
  updateReferandEarnRequests(auth: Auth, data: any) {
    return this.dataSource.updateReferandEarnRequests(auth, data)
  }
  getFilterReferandEarnRequests(auth: Auth, data: any) {
    return this.dataSource.getFilterReferandEarnRequests(auth, data)
  }
}
