import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import {
  API_GET_ALL_CLASS_TOPICS,
  API_GET_ALL_QUESTIONS,
  API_GET_DOUBT_QUERY_SOLUTION,
  API_UPDATE_DOUBT_STATUS,
  API_GET_STUDENT_DOUBT_HISTORY,
  API_V4_STUDENT,
  API_SEARCH_STUDENT_DOUBT,
  API_GET_STUDENT_DOUBT_PENDING,
  API_GET_FUTURE_BATCHES,
  API_STUDENT_BATCH_SHIFT,
  API_IS_ELIGIBLE_FOR_BATCH_CHANGE,
  API_STUDENT_BATCH_PAUSE,
  STR_GET_ELIGIBLE_MENTORS,
  STR_UPDATE_MENTOR,
} from "core/constants/strings"
import { Auth } from "domain/model/Auth"
import { DoubtSupportDataSource } from "data/dataSource/Student/DoubtSupportDataSource"

export default class DoubtSupportAPIDataSourceImpl implements DoubtSupportDataSource {
  async getAllClassTopics(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_ALL_CLASS_TOPICS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllQuestions(auth: Auth, class_name_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_ALL_QUESTIONS), {
        id_token: auth.id_token,
        class_name_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getDoubtQuerySolution(auth: Auth, problem_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_DOUBT_QUERY_SOLUTION), {
        id_token: auth.id_token,
        problem_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateDoubtStatus(auth: Auth, like: any, problem_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_UPDATE_DOUBT_STATUS), {
        id_token: auth.id_token,
        like,
        problem_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentDoubtHistory(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_DOUBT_HISTORY), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async searchStudentDoubt(auth: Auth, problem_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SEARCH_STUDENT_DOUBT), {
        id_token: auth.id_token,
        problem_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentDoubtPending(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_DOUBT_PENDING), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getFutureBatches(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_FUTURE_BATCHES), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async studentBatchShift(
    auth: Auth,
    batch: any,
    reason: any,
    is_batch_resume: any,
    discord_username: any
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_STUDENT_BATCH_SHIFT), {
        id_token: auth.id_token,
        batch,
        reason,
        is_batch_resume,
        discord_username,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async isEligibleForBatchChange(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_IS_ELIGIBLE_FOR_BATCH_CHANGE), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async studentBatchPause(auth: Auth, reason: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_STUDENT_BATCH_PAUSE), {
        id_token: auth.id_token,
        reason,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getEligibleMentors(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_ELIGIBLE_MENTORS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateMentor(auth: Auth, mentor_id: any, reason: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_UPDATE_MENTOR), {
        id_token: auth.id_token,
        mentor_id,
        reason,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
