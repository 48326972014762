import { deleteJob } from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface GetJobsApplications {
  invoke(request: deleteJob): Promise<any>
}

export class JobApplications implements GetJobsApplications {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: deleteJob): Promise<any> {
    return await this.repository.getJobApplications(request)
  }
}
