import { STR_FAILURE } from "core/constants/strings"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import HomeAPIDataSourceImpl from "data/API/Student/HomeAPIDataSourceImpl"
import { HomeRepositoryImpl } from "data/repository/Student/HomeRepositoryImpl"
import { GetOnboardingMeetingDetails } from "domain/useCase/Student/Home/GetOnboardingMeetingDetails"
import { UpdateOnboardingMeetingDetails } from "domain/useCase/Student/Home/UpdateOnboardingMeetingDetails"
import { useState } from "react"

export default function OnboardingViewModel() {
  const { auth, refreshed } = useAuth()
  const { setRemainder } = useApp()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [onboardingDetails, setOnboardingDetails] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  const GetOnboardingMeetingDetailsUseCase = new GetOnboardingMeetingDetails(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )
  const UpdateOnboardingMeetingDetailsUseCase = new UpdateOnboardingMeetingDetails(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )

  const fetchOnboardingMeetingDetails = async () => {
    const response = await GetOnboardingMeetingDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Onboarding Meeting"))
      changeToastVisibility(true)
      return
    }
    setRemainder({
      "Onboarding Meet": response?.data,
    })
    setOnboardingDetails(response?.data)
  }
  const updateOnboardingMeetingDetails = async (data: any) => {
    if (!data.star) {
      changeToastDetails(STR_FAILURE, "Set the stats")
      changeToastVisibility(true)
      return
    }
    if (!data.message) {
      changeToastDetails(STR_FAILURE, "Message is Required")
      changeToastVisibility(true)
      return
    }

    setLoading(true)
    const response = await UpdateOnboardingMeetingDetailsUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Onboarding Meeting"))
      changeToastVisibility(true)
      setLoading(false)
      return
    }
    setOnboardingDetails(response?.data)
    setLoading(false)
  }

  return {
    auth,
    refreshed,
    toast,
    changeToastVisibility,
    loading,
    onboardingDetails,
    fetchOnboardingMeetingDetails,
    updateOnboardingMeetingDetails,
  }
}
