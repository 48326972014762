import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI, postAPIForFiles } from "core/utils/axios"

import {
  API_V4_ADMIN,
  GET_STUDENT_CCAV_PAYMENT_DETAILS,
  API_GET_STUDENT_EMI,
  STR_FAILURE,
  API_CREATE_CCAV_PAYMENT_ADMIN,
} from "core/constants/strings"
import { genError } from "core/utils/string"
import CCAvPaymentAdminDataSource from "data/dataSource/Admin/CCAvenue"

export default class CCAvPaymentAdminAPIDataSourceImpl implements CCAvPaymentAdminDataSource {
  //changepaymentoptions
  async createCCAvPayment(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_CCAV_PAYMENT_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getCCAvPaymentDetailAdmin(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, GET_STUDENT_CCAV_PAYMENT_DETAILS), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentEMI(auth: Auth, student_email: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_STUDENT_EMI), {
        id_token: auth?.id_token,
        student_email,
      })
      return response
    } catch (error) {
      changeToastDetails(STR_FAILURE, genError(error))
      changeToastVisibility(true)
      return error
    }
  }

  async updateTransactionDetailAdmin(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, "update_ccAv_transaction_details"), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
function changeToastDetails(STR_SUCCESS: any, arg1: string) {
  throw new Error("Function not implemented.")
}

function changeToastVisibility(arg0: boolean) {
  throw new Error("Function not implemented.")
}
