import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface DeleteFeedbackFileUseCase {
  invoke(
    auth: Auth,
    session_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any>
}

export default class DeleteFeedbackFile implements DeleteFeedbackFileUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    session_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ) {
    return await this.repository.deleteFeedbackFile(auth, session_id, student_email, fileurl, questionName, type)
  }
}
