import Model from "core/components/Model"
import Button from "core/components/new/Button"
import React from "react"
import UpdateGroupSessionForm from "./UpdateGroupSessionForm"
import { isEmpty } from "core/utils/misc"
import MentorsViewModel from "presentation/Admin/MentorSessions/MentorsViewModel"
import DeleteGroupMentorSession from "domain/useCase/Admin/MentorSessions/DeleteGroupMentorSession"
import MentorSessionsAPIDataSourceImpl from "data/API/Admin/MentorSessionsAPIDataSourceImpl"
import { MentorSessionsRepositoryImpl } from "data/repository/Admin/MentorSessionsRepositoryImpl"
import { useAuth } from "core/context/auth"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function GroupMentorSessionCard({
  data,
  reloadData,
  setReloadData,
  changeToastVisibility,
  changeToastDetails,
}: any) {
  const DeleteGroupMentorSessionUseCase = new DeleteGroupMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )
  const { auth } = useAuth()
  const [isUpdateGroupSessionOpen, setIsUpdateGroupSessionOpen] = React.useState<boolean>(false)
  const [deleteLoader, setDeleteLoader] = React.useState(false)
  const [isDeleteGroupSessionOpen, setIsDeleteGroupSessionOpen] = React.useState<boolean>(false)

  function handleCloseUpdateSession() {
    setIsUpdateGroupSessionOpen(!isUpdateGroupSessionOpen)
  }
  function handleCloseDeleteSession() {
    setIsDeleteGroupSessionOpen(!isDeleteGroupSessionOpen)
  }
  const deleteGroupMentorSession = async (session_id: string) => {
    const response = await DeleteGroupMentorSessionUseCase.invoke(auth, session_id)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response.error)
      changeToastVisibility(true)
      return
    }
    handleCloseDeleteSession()
    changeToastDetails(STR_SUCCESS, "Group Session Deleted")
    changeToastVisibility(true)

    setReloadData(!reloadData)
  }
  async function handleDeleteSession() {
    setDeleteLoader(!deleteLoader)
    await deleteGroupMentorSession(data.id as string)
    setDeleteLoader(!deleteLoader)
  }
  function formatDuration(durationInMinutes: any) {
    const duration = parseInt(durationInMinutes, 10) // Parse string to integer
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60

    if (hours > 0 && minutes > 0) {
      return `${hours} hrs ${minutes} min`
    } else if (hours > 0) {
      return `${hours} hrs`
    } else if (minutes > 0) {
      return `${minutes} min`
    } else {
      return "No duration Found"
    }
  }

  return (
    <>
      <div className="mb-6 flex items-center justify-between  gap-8 rounded-lg p-8 shadow">
        <div className="flex flex-1 flex-col">
          {!isEmpty(data?.mentor_id) ? (
            <h3 className="">{data.mentor_id != "" ? data.mentor_id : "No Mentor Found"}</h3>
          ) : (
            <h3 className="italic">No Mentor Found</h3>
          )}
          <div className="flex gap-x-2">
            <div className="flex gap-x-2">
              <p className="">
                {new Date(data.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <p className="">| {data.start_time} PM</p>
            </div>
            {!isEmpty(data?.batches) ? (
              data.batches.map((item: "string", ky: number) => <p className="">{item}</p>)
            ) : (
              <p className="italic">No Batch Found</p>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-x-2">
            <h4 className="">Duration</h4>
            {!isEmpty(data?.duration) ? (
              <h4 className="">{data.duration != "" ? formatDuration(data.duration) : "No duration Found"}</h4>
            ) : (
              <p className="italic">No duration Found</p>
            )}
          </div>
        </div>
        <Button outlined className="w-fit" onClick={handleCloseDeleteSession}>
          Delete Session
        </Button>
        <Button outlined className="w-fit" onClick={handleCloseUpdateSession}>
          Edit Session
        </Button>
      </div>
      <Model isOpen={isUpdateGroupSessionOpen} onClose={handleCloseUpdateSession}>
        <UpdateGroupSessionForm
          reloadData={reloadData}
          setReloadData={setReloadData}
          changeToastDetails={changeToastDetails}
          changeToastVisibility={changeToastVisibility}
          data={data}
        />
      </Model>
      <Model isOpen={isDeleteGroupSessionOpen} onClose={handleCloseDeleteSession}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Delete Session</h3>
            <p>Are you sure you want to delete this session </p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={handleCloseDeleteSession}>
              No
            </Button>
            <Button outlined loading={deleteLoader} onClick={handleDeleteSession}>
              Yes
            </Button>
          </div>
        </div>
      </Model>
    </>
  )
}
