import { Auth } from "domain/model/Auth"
import { TUpdateQuestion, TUpdateResponse } from "domain/model/Questions"
import { QuestionsRepository } from "domain/repository/Admin/QuestionsRepository"

export interface UpdateQuestionUseCase {
  invoke(auth: Auth, data: TUpdateQuestion): Promise<TUpdateResponse>
}

export default class UpdateQuestion implements UpdateQuestionUseCase {
  private repository: QuestionsRepository

  constructor(repository: QuestionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: TUpdateQuestion) {
    return this.repository.updateQuestion(auth, data)
  }
}
