import { Auth } from "domain/model/Auth"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface getAllTracksUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllTracks implements getAllTracksUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllTracks(auth)
  }
}
