import React, { useEffect, useRef, useState } from "react"
import FeedbackViewModel from "./FeedbackViewModel"
import DashboardLayout from "core/layouts/DashboardLayout"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import Loader from "core/components/Loader"
import StandaredSelect from "core/components/StandaredSelect"
import FeedbackCard from "./FeedbackCard"
import CopyIcon from "assets/svgs/Copy"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"

export default function FeedbackTable() {
  const {
    monthlyFeedback,
    fetchAllFeedback,
    HandleTableData,
    searchByEmail,
    first100,
    setfirst100,
    npsScore,
    DownloadCSV,
    firstFiltered100,
    totalreponses,
    handleChange,
    appendNext100,
    fromDate,
    toDate,
    handleDateChange,
    batch,
    getBatches,
    handleBatchChange,
    fetchAfter3months,
    median,
    average,
    curIdx,
    setMinRating,
    setMaxRating,
    setfirstFiltered100,
    setSearchByEmail,
    setFromDate,
    setToDate,
    setStudentBatch,
    studentBatch,
    promoters,
    detractors,
    filteredAverage,
    setFilteredMedian,
    setFiltereedAverage,
    filteredMedian,
  } = FeedbackViewModel()
  interface Feedback {
    name: string
    "1": number
    "2": string
    "3": string
    email: string
    submit_timestamp: number
  }

  const [isPopupOpen, setIsPopupOpen] = React.useState(false)
  const [selectedFeedback, setSelectedFeedback] = React.useState<Feedback[]>([])
  const [noResultsMessage, setNoResultsMessage] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(true)
  const [selectedTab, setSelectedTab] = React.useState<string>("All Students")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [isButtonVisible, setButtonVisible] = useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetchAllFeedback()
      setLoading(false)
    }
    fetchData()
  }, [])

  const fetchNext3MonthsData = async () => {
    setLoading(true)
    await fetchAfter3months()
    setLoading(false)
    setButtonVisible(false)
  }

  React.useEffect(() => {
    HandleTableData()
  }, [monthlyFeedback])

  React.useEffect(() => {
    getBatches()
  }, [])
  const tabs = ["All Students", "Less Than or Equal to 6", "7-8 Rating", "9-10 Rating"]

  const [selectedFeedbackIndex, setSelectedFeedbackIndex] = React.useState<number | null>(null)

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab)
    if (tab === "Less Than or Equal to 6") {
      setMinRating(1)
      setMaxRating(6)
    } else if (tab === "7-8 Rating") {
      setMinRating(7)
      setMaxRating(8)
    } else if (tab === "9-10 Rating") {
      setMinRating(9)
      setMaxRating(10)
    } else if (tab === "All Students") {
      setMinRating(1)
      setMaxRating(10)
    }
  }

  const handleViewFeedback = (email: string, feedback: Feedback) => {
    setIsPopupOpen(true)
    setSelectedFeedback([feedback])
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const copyToClipboard = (email: string) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        changeToastDetails(STR_SUCCESS, `Copied ${email} to clipboard`)
        changeToastVisibility(true)
        // Hide the toast after a few seconds if needed
        setTimeout(() => changeToastVisibility(false), 3000)
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err)
      })
  }

  function clearFilters() {
    setfirstFiltered100(first100.slice(0, 100))
    setSearchByEmail("")
    setFromDate(null)
    setToDate(null)
    setMinRating(0)
    setStudentBatch("")
    setMaxRating(10)
    setSelectedTab("All Students")
    setFilteredMedian(0), setFiltereedAverage(0)
  }

  const loaderRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting && firstFiltered100.length > 0) {
        appendNext100()
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [first100, firstFiltered100])

  return (
    <DashboardLayout>
      {toast && (
        <Toast
          data={toast}
          onClick={() => changeToastVisibility(false)}
          className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50"
        />
      )}
      <div className="flex flex-col gap-6 p-8">
        <div className="flex items-center gap-6">
          {/* <h3>Average: {Math.round(average * 100) / 100}</h3> */}
          {/* <h3>Average: {isNaN(filteredAverage) ? "" : Math.round(filteredAverage * 100) / 100}</h3>
          <h3 className="">Median: {isNaN(filteredMedian) ? "" : filteredMedian}</h3> */}
          <h3 className="">Promoters: {isNaN(promoters) ? "" : promoters}</h3>
          <h3 className="">Detractors: {isNaN(detractors) ? "" : detractors}</h3>
          <h3 className="">Total Responses: {isNaN(totalreponses) ? "" : totalreponses}</h3>

          <h3 className="">NPS: {isNaN(npsScore) ? "" : Math.round(npsScore * 100) / 100}</h3>
        </div>
      </div>
      <div className="flex">
        <div className="w-4/5 p-4 m-2.5 border border-gray-300 rounded-md">
          <div className="mb-4">
            <h2 className="text-2xl font-bold text-gray-600">NPS Score</h2>
            <p className="text-[#737373]">Track students' feedback and NPS Score here.</p>
          </div>
          <div className="flex items-end mb-3">
            <Input
              id="email"
              type="text"
              className="w-[300px]"
              placeholder="Email Id"
              value={searchByEmail}
              onChange={handleChange}
            />
            <div className="flex flex-col gap-2 ml-2">
              <StandaredSelect
                id="batch"
                className="w-[300px]"
                required
                options={batch}
                placeholder="Select Batch"
                onChange={handleBatchChange}
                value={studentBatch}
              />
            </div>
            <button
              className="border-[1px] px-[12px] rounded-md p-1 ml-2 hover:bg-new-accent hover:text-white hover:cursor-pointer"
              onClick={clearFilters}
              id="button"
            >
              Clear All Filters
            </button>
          </div>
          <div className="flex space-x-2 mb-5">
            <div className="space-y-2">
              <label>From Date</label>
              <Input
                type="date"
                placeholder="From Date"
                className="w-[300px]"
                onChange={handleDateChange}
                id="fromDate"
                value={fromDate !== null ? new Date(fromDate * 1000).toISOString().split("T")[0] : ""}
              />
            </div>
            <div className="space-y-2">
              <label>To Date</label>
              <Input
                type="date"
                placeholder="To Date"
                className="w-[300px]"
                onChange={handleDateChange}
                id="toDate"
                value={toDate !== null ? new Date(toDate * 1000).toISOString().split("T")[0] : ""}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`border border-gray-300 p-2 rounded-md transition-colors hover:cursor-pointer
        ${selectedTab === tab ? "bg-[#162456] text-white" : "hover:bg-[#162456] hover:text-white"}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
            {isButtonVisible && (
              <button
                onClick={fetchNext3MonthsData}
                className="border-[1px] px-[12px] pt-0 pb-0 rounded-md ml-2 hover:bg-new-accent hover:text-white hover:cursor-pointer"
              >
                Fetch Archived Data
              </button>
            )}
            <button
              onClick={DownloadCSV}
              className="border border-gray-300 p-2 rounded-md transition-colors hover:cursor-pointer"
            >
              Download CSV
            </button>
          </div>

          <div className="flex mb-4"></div>
          <table className="w-full border border-gray-300 rounded-md">
            <thead className="bg-gray-800 w-full">
              <tr className="bg-gray-800 ">
                <th className="px-4 py-2 text-left text-white font-normal tracking-wider rounded-tl-md">
                  Student Name
                </th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider"></th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider">Rating</th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider">Batch</th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider">Submitted On</th>
                <th className="px-4 py-2 text-left text-white font-normal tracking-wider rounded-tr-md"></th>
              </tr>
            </thead>
            {loading && (
              <tr>
                <td colSpan={4} className="px-4 py-2 text-center m-auto">
                  <div className="m-auto">
                    <Box sx={{ display: "flex", marginLeft: "25vw" }}>
                      <CircularProgress />
                    </Box>
                  </div>
                </td>
              </tr>
            )}
            <tbody>
              {monthlyFeedback?.length > 0 ? (
                firstFiltered100?.map((f: any, index: number) => (
                  <tr key={index} className="hello p-1 rounded-md hover:bg-gray-200 duration-500 ">
                    <td className="pl-4 py-2">
                      <div className="flex items-center">
                        <span>{f["name"]}</span>
                      </div>
                    </td>
                    <td className="relative group py-2 ">
                      <button onClick={() => copyToClipboard(f.email)} className="relative z-10">
                        <CopyIcon />
                      </button>
                      {/* <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        Copy Email Address
                        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-4px] w-2 h-2 bg-gray-800 rotate-45"></div>
                      </div> */}
                    </td>
                    <td className="px-4 py-2 relative group">
                      <span
                        className={`font-bold ${
                          f["1"] >= 9
                            ? "text-green-500"
                            : f["1"] <= 8 && f["1"] >= 7
                              ? "text-yellow-500"
                              : f["1"] <= 6 && f["1"] >= 0
                                ? "text-red-500"
                                : "text-red-500"
                        }`}
                      >
                        {f["1"]}/10
                      </span>
                    </td>
                    <td className="px-4 py-2 ">{f["batch"]}</td>
                    <td className="px-4 py-2 ">
                      {new Date(f["submit_timestamp"] * 1000)
                        .toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })
                        .replace(",", "")}
                    </td>
                    <td className="px-1 py-2 relative group items-center pl-10 ">
                      <button
                        className="bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 hover:text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:border-gray-600"
                        type="button"
                        onClick={() => handleViewFeedback(f.email, f)}
                      >
                        View Feedback
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="px-4 py-2 text-center">
                    {noResultsMessage ? <>{noResultsMessage}</> : <></>}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* {curIdx < first100.length &&
            <p
              onClick={appendNext100}
              className="text-blue-500 text-center mt-5 m-auto font-bold hover:cursor-pointer text-[15px] flex items-center justify-center"
            >
              <span className="flex-grow border-t border-gray-300 mr-3"></span>
              Load more
              <span className="flex-grow border-t border-gray-300 ml-3"></span>
            </p>
          } */}
        </div>
        <div className="w-1/5 p-4 border border-gray-300 m-2.5 rounded-md h-[100vh]">
          <div className="flex justify-center mb-4">
            <img src="https://source.unsplash.com/random/200x200" alt="Profile Picture" className="rounded-full" />
          </div>
          <div className="text-center">
            <h4 className="text-l font-bold">
              {" "}
              {selectedFeedback?.map((feedback: any, index: number) => (
                <div key={index}>
                  <div className="flex-wrap font-montserrat text-[18px] font-semibold">{feedback.name}</div>

                  <div className="border-[1px] w-1/2 rounded-lg m-auto p-2 mt-3 text-gray-600">
                    Batch: {feedback.batch}
                  </div>
                </div>
              ))}
            </h4>
          </div>
        </div>
      </div>
      {isPopupOpen && selectedFeedback && (
        <div
          className="fixed inset-0 bg-[rgba(12,12,12,0.85)] flex items-center justify-center"
          style={{ zIndex: 1000 }}
        >
          <div className="rounded-lg shadow-lg relative">
            <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={closePopup}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="popup-content flex flex-col space-y-6">
              {selectedFeedback?.map((feedback: any, index: number) => (
                <FeedbackCard
                  key={index}
                  rating={feedback["1"]}
                  response1={feedback["2"]}
                  response2={feedback["3"]}
                  name={feedback.name}
                  email={feedback.email}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {curIdx < first100.length && <div ref={loaderRef}></div>}
    </DashboardLayout>
  )
}
