import React from "react"

export default function Home(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6665 13.7652C2.6665 14.1334 2.96498 14.4319 3.33317 14.4319H12.6665C13.0347 14.4319 13.3332 14.1334 13.3332 13.7652V8.32287C13.3332 8.0149 13.5828 7.76525 13.8908 7.76525C14.4005 7.76525 14.643 7.13786 14.2659 6.79501L8.4485 1.50658C8.19416 1.27515 7.80552 1.27515 7.55117 1.50658L1.73379 6.79501C1.35664 7.13786 1.59919 7.76525 2.10888 7.76525C2.41685 7.76525 2.6665 8.0149 2.6665 8.32287V13.7652ZM11.6725 6.23902C11.881 6.42854 11.9998 6.69723 11.9998 6.97899V12.0986C11.9998 12.6509 11.5521 13.0986 10.9998 13.0986H4.99984C4.44755 13.0986 3.99984 12.6509 3.99984 12.0986V6.97899C3.99984 6.69723 4.1187 6.42854 4.3272 6.23902L7.3272 3.51202C7.70861 3.16532 8.29106 3.16532 8.67247 3.51202L11.6725 6.23902Z"
        fill="currentColor"
      />
    </svg>
  )
}
