import { ClassDataSource } from "data/dataSource/Mentor/ClassDataSource"
import { Auth } from "domain/model/Auth"
import { ClassRepository } from "domain/repository/Mentor/ClassRepository"

export class ClassRepositoryImpl implements ClassRepository {
  private dataSource: ClassDataSource

  constructor(dataSource: ClassDataSource) {
    this.dataSource = dataSource
  }

  async getAllClasses(auth: Auth) {
    return await this.dataSource.getAllClasses(auth)
  }

  async joinClass(auth: Auth, class_id: string) {
    return await this.dataSource.joinClass(auth, class_id)
  }

  async classReminderScheduling(auth: Auth, id: any) {
    return await this.dataSource.classReminderScheduling(auth, id)
  }
}
