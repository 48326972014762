import React from "react"

export default function AvailibilityTable(props: any) {
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 " aria-label="simple table">
        <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
          <tr>
            <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Day</th>
            <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Start</th>
            <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">End</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(props.availability?.sunday) &&
            props.availability?.sunday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Sunday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
          {Array.isArray(props.availability?.monday) &&
            props.availability?.monday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Monday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
          {Array.isArray(props.availability?.tuesday) &&
            props.availability?.tuesday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Tuesday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
          {Array.isArray(props.availability?.wednesday) &&
            props.availability?.wednesday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Wednesday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
          {Array.isArray(props.availability?.thursday) &&
            props.availability?.thursday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Thursday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
          {Array.isArray(props.availability?.friday) &&
            props.availability?.friday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Friday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
          {Array.isArray(props.availability?.saturday) &&
            props.availability?.saturday?.map((row: any) => (
              <tr key={row.start + row.end}>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Saturday</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.start}:00</td>
                <td className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">{row.end}:00</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
