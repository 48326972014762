import Button from "core/components/editor/Button"
import Button2 from "core/components/new/Button"
import { CrossIcon } from "core/constants/svgs"

export default function ConfirmExitPopup({ data, loading, open, onClose, onClick }: any) {
  if (!open) return null

  return (
    <div className="fixed inset-0 z-30">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="m-auto flex w-[660px] transform flex-col items-center gap-4 rounded-lg bg-new-editor-dark-600 p-6 text-center text-new-editor-dark-50">
            <div className="relative flex w-full justify-center">
              <h3 className="text-new-solid-white">Exit</h3>
              <button className="absolute top-0 right-0" onClick={onClose}>
                <CrossIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="flex flex-col items-center gap-[1ch]">
              <p>
                By choosing to end the exam now, you will permanently halt the competition, and the opportunity for
                further engagement will be lost. We want to ensure that you are fully aware of the consequences before
                making a final decision.
              </p>
              <ul className="flex flex-col items-center gap-1">
                <li className="flex items-center gap-1">
                  Coding Problem Solved :
                  <h4 className="text-new-solid-white">
                    {data?.coding?.done}/{data?.coding?.total}
                  </h4>
                </li>
                <li className="flex items-center gap-1">
                  MCQ Problem Solved :
                  <h4 className="text-new-solid-white">
                    {data?.mcq?.done}/{data?.mcq?.total}
                  </h4>
                </li>
                <li className="flex items-center gap-1">
                  Subjective Problem Solved :
                  <h4 className="text-new-solid-white">
                    {data?.subjective?.done}/{data?.subjective?.total}
                  </h4>
                </li>
                <li className="flex items-center gap-1">
                  Casestudy Problem Solved :
                  <h4 className="text-new-solid-white">
                    {data?.casestudy?.done}/{data?.casestudy?.total}
                  </h4>
                </li>
              </ul>
            </div>
            <Button2 loading={loading} className="w-fit" onClick={onClick}>
              Submit & End Exam
            </Button2>
            <Button className="w-[120px] bg-new-misc-red text-new-solid-white" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
