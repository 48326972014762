import DeleteSSMCallDataSource from "data/dataSource/Admin/DeleteSSMCallDataSource"
import { Auth } from "domain/model/Auth"
import { DeleteSSMCall } from "domain/model/DeleteSSMCall"
import { DeleteSSMCallRepository } from "domain/repository/Admin/DeleteSSMCallRepository"

export class DeleteSSMCallRepositoryImpl implements DeleteSSMCallRepository {
  private Datasource: DeleteSSMCallDataSource

  constructor(Datasource: DeleteSSMCallDataSource) {
    this.Datasource = Datasource
  }

  async deleteSSMCall(deleteSSMCall: DeleteSSMCall): Promise<any> {
    return await this.Datasource.deleteSSMCall(deleteSSMCall)
  }
}
