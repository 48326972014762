import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { ScheduleClassAPIDataSource } from "data/API/Admin/ScheduledClassAPIDataSource"
import { ScheduleClassRepositoryImpl } from "data/repository/Admin/ScheduledClassRepositoryImpl"
import { UpdateScheduledClass } from "domain/useCase/Admin/ScheduledClasses/UpdateScheduledClass"
import React from "react"

export default function UpdateClassFormViewModel() {
  const [selectBatch, setSelectBatch] = React.useState<any>([])
  const [currentSelectBatch, setcurrentSelectBatch] = React.useState<any>(null)
  const [chapterNames, setChapterNames] = React.useState<any>(null)
  const [classLink, setClassLink] = React.useState<any>(null)
  const [selectInstructor, setSelectInstructor] = React.useState<any>(null)
  const [selectModuleId, setSelectModuleId] = React.useState<any>(null)
  const [notes_link, setNotesLink] = React.useState<any>(null)
  const [sessionDuration, setSessionDuration] = React.useState<string>()
  const [otherSessionDuration, setOtherSessionDuration] = React.useState<any>()
  const [masterClassType, setMasterClassType] = React.useState("Software Engineering")
  const [assignmentSolutionLink, setAssignmentSolutionLink] = React.useState<any>("")

  const [assignmentCoins, setAssignmentCoins] = React.useState<string>("")
  const [assignmentSolutionActivationTime, setAssignmentSolutionActivationTime] = React.useState<any>("")

  const [selectAssigmentsQuestions, setSelectAssigmentsQuestions] = React.useState<any>(null)
  const [currentSelectAssignmentsQuestions, setCurrentSelectAssignmentsQuestions] = React.useState<any>("")
  const [selectHomeworkQuestions, setSelectHomeworkQuestions] = React.useState<any>(null)
  const [currentSelectHomeworkQuestions, setCurrentSelectHomeworkQuestions] = React.useState<any>("")

  const [selectPracticalQuestions, setSelectPracticalQuestions] = React.useState<any>(null)

  const [currentSelectPracticalQuestions, setCurrentSelectPracticalQuestions] = React.useState<any>("")
  const [selectWarmupQuestions, setselectWarmupQuestions] = React.useState<any>(null)

  const [currentSelectWarmupQuestions, setCurrentSelectWarmupQuestions] = React.useState<any>("")

  const [session_timestamp, setSessionTimestamp] = React.useState<any>(null)
  const [selectTrack, setSelectTrack] = React.useState<any>(null)
  const [videoId, setVideoId] = React.useState<any>(null)
  const [videoSecret, setVideoSecret] = React.useState<any>(null)
  const [notesSummaryLink, setNotesSummaryLink] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<any>(false)
  const [sessionId, setSessionId] = React.useState<any>(null)
  const [previousTimestamp, setPreviousTimestamp] = React.useState<any>(null)
  const [compensationStatus, setCompensationStatus] = React.useState("")
  const UpdateScheduledClassUseCase = new UpdateScheduledClass(
    new ScheduleClassRepositoryImpl(new ScheduleClassAPIDataSource())
  )
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const problems = {
    assignments: selectAssigmentsQuestions,
    homework: selectHomeworkQuestions,
    practice: selectPracticalQuestions,
    warmup: selectWarmupQuestions,
  }

  async function updateClass() {
    setLoading(true)
    const response = await UpdateScheduledClassUseCase.invoke({
      id_token: auth.id_token?.trim(),
      batches: selectBatch,
      session_id: sessionId?.trim(),
      chapter_name: chapterNames?.trim(),
      class_link: classLink?.trim(),
      instructor: selectInstructor?.trim(),
      module_id: selectModuleId?.trim(),
      problems: problems,
      session_timestamp: session_timestamp === null ? previousTimestamp : Date.parse(session_timestamp) / 1000,
      session_duration: Number(sessionDuration == "other" ? otherSessionDuration : sessionDuration),
      track: selectTrack?.trim(),
      master_class_type: selectTrack.trim() == "master_class" ? masterClassType : "",
      video_id: videoId?.trim(),
      video_secret: videoSecret?.trim(),
      notes_link: notes_link?.trim(),
      notes_summary_link: notesSummaryLink?.trim(),
      compensation_status: compensationStatus,
      assignment_solution_activation_time: assignmentSolutionActivationTime?.trim(),
      assignment_solution_link: assignmentSolutionLink?.trim(),
      assignment_points: assignmentCoins === "" ? 0 : parseInt(assignmentCoins),
    })

    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response.data)
      setLoading(false)
    } else {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
      setLoading(false)
    }
  }

  function handleAssignmentChange(e: any) {
    setCurrentSelectAssignmentsQuestions(e.target.value)
  }

  function handleAddAssignmentQuestions(e: any) {
    e.preventDefault()
    if (currentSelectAssignmentsQuestions.length > 0) {
      setSelectAssigmentsQuestions([...selectAssigmentsQuestions, currentSelectAssignmentsQuestions])
    }
    setCurrentSelectAssignmentsQuestions("")
  }

  function handleSelectHomeworkChange(e: any) {
    setCurrentSelectHomeworkQuestions(e.target.value)
  }

  function handleSessionDuration(e: any) {
    setSessionDuration(e.target.value)
  }

  function handleMasterClassType(e: any) {
    setMasterClassType(e.target.value)
  }

  function handleOtherSessionDuration(e: any) {
    setOtherSessionDuration(e.target.value)
  }

  function handleNewDuration(duration: any, durationArray: any[]) {
    const durationNumber = parseFloat(duration)

    if (!isNaN(durationNumber) && durationArray.includes(durationNumber)) {
      setSessionDuration(durationNumber.toString())
    } else {
      setSessionDuration("other")
      setOtherSessionDuration(duration)
    }
  }

  function handleAddHomeworkQuestions(e: any) {
    e.preventDefault()
    if (currentSelectHomeworkQuestions.length > 0) {
      setSelectHomeworkQuestions([...selectHomeworkQuestions, currentSelectHomeworkQuestions])
    }
    setCurrentSelectHomeworkQuestions("")
  }

  function handlePracticalChange(e: any) {
    setCurrentSelectPracticalQuestions(e.target.value)
  }

  function handleAddPracticalQuestions(e: any) {
    e.preventDefault()
    if (currentSelectPracticalQuestions.length > 0) {
      setSelectPracticalQuestions([...selectPracticalQuestions, currentSelectPracticalQuestions])
    }
    setCurrentSelectPracticalQuestions("")
  }

  function handleWarmupChange(e: any) {
    setCurrentSelectWarmupQuestions(e.target.value)
  }

  function handleAddWarmupQuestions(e: any) {
    e.preventDefault()
    if (currentSelectWarmupQuestions.length > 0) {
      setselectWarmupQuestions([...selectWarmupQuestions, currentSelectWarmupQuestions])
    }
    setCurrentSelectWarmupQuestions("")
  }

  function handleBatchChange(e: any) {
    const selectedValue = e.target.value

    setcurrentSelectBatch(selectedValue)

    if (selectBatch.includes(selectedValue)) {
      const updatedSelectBatch = selectBatch.filter((value: any) => value !== selectedValue)
      setSelectBatch(updatedSelectBatch)
    } else {
      setSelectBatch([...selectBatch, selectedValue])
    }
  }
  function handleRemoveBatch(batch: any) {
    const newSelectBatch = selectBatch.filter((item: any) => item !== batch)
    setSelectBatch(newSelectBatch)
  }
  function handleChangeChapterName(e: any) {
    setChapterNames(e.target.value)
  }
  function handleChangeClassLink(e: any) {
    setClassLink(e.target.value)
  }
  function handleChangeInstructor(e: any) {
    setSelectInstructor(e.target.value)
  }
  function handleChangeModuleId(e: any) {
    setSelectModuleId(e.target.value)
  }
  function handleCompensationStatus(e: any) {
    setCompensationStatus(e.target.value)
  }
  function handleChangeSessionTimestamp(e: any) {
    setSessionTimestamp(e.target.value)
  }
  function handleChangeTrack(e: any) {
    setSelectTrack(e.target.value)
  }
  function handleChangeVideoId(e: any) {
    setVideoId(e.target.value)
  }

  function handleChangeVideoSecreat(e: any) {
    setVideoSecret(e.target.value)
  }

  function handleChangeNotesLink(e: any) {
    setNotesLink(e.target.value)
  }
  function handleChangeNotesSummaryLink(e: any) {
    setNotesSummaryLink(e.target.value)
  }
  function handleChangeAssignmentSolutionLink(e: any) {
    setAssignmentSolutionLink(e.target.value)
  }

  function handleChangeAssignmentSolutionActivationTime(e: any) {
    setAssignmentSolutionActivationTime(e.target.value)
  }

  function handleChangeAssignmentCoins(e: any) {
    setAssignmentCoins(e.target.value)
  }

  function removeAssignmentQuestion(index: number) {
    setSelectAssigmentsQuestions((prevState: any[]) => prevState.filter((_, i) => i !== index))
  }
  function removeHomeworkQuestion(index: number) {
    setSelectHomeworkQuestions((prevState: any[]) => prevState.filter((_, i) => i !== index))
  }
  function removePracticeQuestion(index: number) {
    setSelectPracticalQuestions((prevState: any[]) => prevState.filter((_, i) => i !== index))
  }
  function removeWarmupQestions(index: number) {
    setselectWarmupQuestions((prevState: any[]) => prevState.filter((_, i) => i !== index))
  }

  return {
    selectBatch,
    setSelectBatch,
    currentSelectAssignmentsQuestions,
    setCurrentSelectAssignmentsQuestions,
    currentSelectBatch,
    setcurrentSelectBatch,
    chapterNames,
    setChapterNames,
    classLink,
    setClassLink,
    selectInstructor,
    setSelectInstructor,
    selectModuleId,
    setSelectModuleId,
    notes_link,
    setNotesLink,
    selectAssigmentsQuestions,
    setSelectAssigmentsQuestions,
    currentSelectHomeworkQuestions,
    selectHomeworkQuestions,
    setSelectHomeworkQuestions,
    selectPracticalQuestions,
    setSelectPracticalQuestions,
    currentSelectPracticalQuestions,
    setCurrentSelectPracticalQuestions,
    selectWarmupQuestions,
    setselectWarmupQuestions,
    currentSelectWarmupQuestions,
    setCurrentSelectWarmupQuestions,
    session_timestamp,
    setSessionTimestamp,
    selectTrack,
    setSelectTrack,
    videoId,
    setVideoId,
    notesSummaryLink,
    setNotesSummaryLink,
    videoSecret,
    setVideoSecret,
    loading,
    toast,
    compensationStatus,
    assignmentCoins,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    setMasterClassType,
    handleMasterClassType,
    handleNewDuration,
    setSessionDuration,
    setOtherSessionDuration,
    handleSessionDuration,
    handleOtherSessionDuration,
    handleChangeAssignmentCoins,
    setAssignmentCoins,
    handleCompensationStatus,
    handleRemoveBatch,
    setSessionId,
    setPreviousTimestamp,
    handleAssignmentChange,
    handleAddAssignmentQuestions,
    handleSelectHomeworkChange,
    handleAddHomeworkQuestions,
    handlePracticalChange,
    handleAddPracticalQuestions,
    handleWarmupChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeSessionTimestamp,
    handleAddWarmupQuestions,
    handleChangeTrack,
    handleChangeVideoId,
    handleChangeVideoSecreat,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    removeAssignmentQuestion,
    removeHomeworkQuestion,
    removePracticeQuestion,
    removeWarmupQestions,
    updateClass,
    changeToastVisibility,
    changeToastDetails,
    handleChangeAssignmentSolutionActivationTime,
    assignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    assignmentSolutionLink,
    setAssignmentSolutionActivationTime,
    setAssignmentSolutionLink,
  }
}
