import Button from "core/components/Button"
import { isEmpty } from "core/utils/misc"
import React from "react"
import { useNavigate } from "react-router-dom"
import Spinner from "core/components/Spinner"
import MentorSessionsViewModel from "../MentorsViewModel"
import Avatar from "react-avatar"

export default function MentorCard({ data }: any) {
  const navigate = useNavigate()
  const { mentorRating, fetchMentorRating } = MentorSessionsViewModel()
  React.useEffect(() => {
    fetchMentorRating(data.email)
  }, [data.email])
  const viewMentorSessions = (id: string) => {
    navigate(`/admin/mentor-sessions/${id}`)
  }
  return (
    <div className="">
      <div className="mx-2rem my-3rem flex justify-between px-6 py-4 text-new-neutral-dark shadow">
        <div className="flex items-center gap-x-4">
          <div className="">
            <Avatar name={data.photo} src={data.photo} round size="60" />
          </div>
          <div className="flex items-end gap-x-6">
            <div className="space-y-1">
              <h4 className="mb-1">{data.name}</h4>
              <p className="">
                {data.current_company} | {data.experience} years
              </p>

              <p className="">
                {data.email} | +91 {data.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-x-12">
          <div className="flex flex-col gap-y-5">
            {!isEmpty(mentorRating) ? (
              mentorRating.rating != 0 ? (
                <div className="">
                  {" "}
                  <h3 className="text-new-success">{mentorRating.rating}/5</h3>
                  <p className="">Rating</p>
                </div>
              ) : (
                <div className="">Not Rated</div>
              )
            ) : (
              <Spinner small />
            )}

            <Button outlined className="w-fit" onClick={() => viewMentorSessions(data.email)}>
              View Mentor Sessions
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
