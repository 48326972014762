import { API_CHECK_BATCH_FOR_MASTERS, API_REGISTER_MASTERS_PROGRAM, API_V4_STUDENT } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import MastersDataSource from "data/dataSource/Student/MastersDataSource"
import { Auth } from "domain/model/Auth"

export default class MastersAPIDataSourceImpl implements MastersDataSource {
  async checkBatchForMasters(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_CHECK_BATCH_FOR_MASTERS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async registerMastersProgram(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_REGISTER_MASTERS_PROGRAM), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
