import { Auth } from "domain/model/Auth"
import { FeedbackResponse } from "domain/model/FeedbackResponse"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface CreateContestFeedbackResponseUseCase {
  invoke(
    auth: Auth,
    contest_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any>
}

export default class CreateContestFeedbackResponse implements CreateContestFeedbackResponseUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    contest_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ) {
    return await this.repository.createContestFeedbackResponse(
      auth,
      contest_id,
      student_email,
      formData,
      feedbackResponse
    )
  }
}
