import { companyDetails } from "domain/model/Placement"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"

export interface UploadCompanyLogoUseCase {
  invoke(request: companyDetails): Promise<any>
}

export class UploadCompanyLogo implements UploadCompanyLogoUseCase {
  private repository: AddPlacementRepository

  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }

  async invoke(request: companyDetails): Promise<any> {
    return await this.repository.uploadCompanyLogo(request)
  }
}
