export default function ChevronUp({ className, pathClassName }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M7.99952 7.21932L4.69952 10.5193L3.75686 9.57665L7.99952 5.33398L12.2422 9.57665L11.2995 10.5193L7.99952 7.21932Z"
        fill="currentColor"
      />
    </svg>
  )
}
