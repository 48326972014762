import React from "react"

function Cut() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.586 12L5.63599 7.05001L7.04999 5.63601L12 10.586L16.95 5.63601L18.364 7.05001L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12Z"
        fill="#6B6B6B"
      />
    </svg>
  )
}

export default Cut
