import { CreateCoupon } from "domain/model/RedeemCoins"
import { RedeemCoinsRepository } from "domain/repository/Admin/RedeemCoinsRepository"

export interface CreateCouponUseCase {
  invoke(createCoupon: CreateCoupon): Promise<any>
}

export default class CreateCoupons implements CreateCouponUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(createCoupon: CreateCoupon) {
    return this.repository.createCoupon(createCoupon)
  }
}
