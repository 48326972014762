import Toggle from "core/components/Toggle"
import { capitalize } from "core/utils/string"

export default function DashboardTable(props: any) {
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.students?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={data.email}>
              <td className="px-6 py-4 ">
                <p>{data.name}</p>
                <p className="font-medium">{data.email}</p>
                <p> {data.phone}</p>
                <p className="font-medium">{capitalize(data.course_type)}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.batch}</td>
              <td className="px-6 py-4">
                <Toggle checked={data.proctoring_status === true} onChange={() => props.paidToUnpaid(data.email)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
