import { STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { StoreAPIDataSourceImpl } from "data/API/Student/StoreAPIDataSourceImpl"
import { StoreRepositoryImpl } from "data/repository/Student/StoreRepositoryImpl"
import { GetStoreItems } from "domain/useCase/Student/Store/GetStoreItems"
import { GetStudentAllAddresses } from "domain/useCase/Student/Store/GetStudentAllAddresses"
import { OrderHistory } from "domain/useCase/Student/Store/OrderHistory"
import { OrderStatus } from "domain/useCase/Student/Store/OrderStatus"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function StoreViewModel() {
  const { auth } = useAuth()
  const [storeItemsLocally, setStoreItemsLocally] = useLocalStorage<any>("store-items", [])
  const [userDetailsLocally, setUserDetailsLocally] = useLocalStorage<any>("user-details", {})
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()

  const [storeItems, setStoreItems] = React.useState(() => storeItemsLocally)
  const [userDetails, setUserDetails] = React.useState<any>(() => userDetailsLocally)
  const [orderHistory, setOrderHistory] = React.useState<any>([])
  const [orderStatus, setOrderStatus] = React.useState<number>(0)

  const getStoreItemsUseCase = new GetStoreItems(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const getStudentAllAddressesUseCase = new GetStudentAllAddresses(
    new StoreRepositoryImpl(new StoreAPIDataSourceImpl())
  )

  const orderHistoryUseCase = new OrderHistory(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const orderStatusUseCase = new OrderStatus(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const fetchStoreItems = async () => {
    const response = await getStoreItemsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    setStoreItems(response?.data)
    setStoreItemsLocally(response?.data)
  }

  const fetchStudentAllAddresses = async () => {
    const response = await getStudentAllAddressesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    setUserDetails(response?.data)
    setUserDetailsLocally(response?.data)
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleRedirectToHistory = () => {
    navigate("history")
  }

  const fetchOrderHistory = async () => {
    const response = await orderHistoryUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    setOrderHistory(response?.data)
  }

  const fetchOrderStatus = async (orderId: string) => {
    const response = await orderStatusUseCase.invoke(auth, orderId)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    setOrderStatus(Number(response?.data?.status))
  }

  return {
    auth,
    toast,
    storeItems,
    userDetails,
    orderHistory,
    orderStatus,
    fetchStoreItems,
    changeToastVisibility,
    handleGoBack,
    fetchStudentAllAddresses,
    handleRedirectToHistory,
    fetchOrderHistory,
    fetchOrderStatus,
  }
}
