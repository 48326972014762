import { placementDetail } from "domain/model/Placement"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"

export interface GetStudentPlacementUsecase {
  invoke(request: placementDetail): Promise<any>
}

export class GetStudentPlacement implements GetStudentPlacementUsecase {
  private repository: AddPlacementRepository

  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }

  async invoke(request: placementDetail): Promise<any> {
    return await this.repository.getStudentPlacement(request)
  }
}
