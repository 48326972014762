import React from "react"

const Mcqs = ({ questions, selectedQuestion, activeTabContent }: any) => {
  return (
    <>
      <div className="flex flex-col p-3 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
        <h3 className="text-lg font-semibold">
          {selectedQuestion + 1}. {questions?.["name"]}
        </h3>
        <div className="flex text-base space-x-2">
          <>
            <p className="font-semibold">Max Marks : </p>
            <p>{questions?.["maxMarks"]}</p>
          </>
          <div className="flex justify-between space-x-1">
            <p className="font-semibold">Difficulty Level : </p>
            <p
              className={`${
                questions?.["difficulty"] === "Easy"
                  ? "text-emerald-500"
                  : questions?.["difficulty"] === "Medium"
                    ? "text-orange-500"
                    : "text-red-500"
              } capitalize`}
            >
              {questions?.["difficulty"]}
            </p>
          </div>
        </div>
      </div>
      <div>
        {activeTabContent === 0 ? (
          <div className="p-4 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
            <h4 className="py-2 text-new-neutral-light">Question</h4>
            <pre className="text-new-neutral-light flex-wrap whitespace-pre-wrap">
              {/* {" "} */}
              {questions?.question?.[0]?.content}
            </pre>
            {Object.entries(questions.options).map(([key, option]: [string, any]) => (
              <div key={key} className="flex items-baseline py-2">
                <span className="text-new-neutral-light mr-2">{String.fromCharCode(64 + parseInt(key))}.</span>
                {option[0]?.type === "code" ? (
                  <pre
                    className="text-new-neutral-light"
                    dangerouslySetInnerHTML={{
                      __html: option[0]?.content,
                    }}
                    key={`${key}-option`}
                  ></pre>
                ) : (
                  <p className="text-new-neutral-light">{option[0]?.content}</p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="p-3 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
            <h4 className="py-2 text-new-neutral-light">Solution</h4>
            <div className="flex items-baseline">
              <span className="text-new-neutral-light mr-2">
                {String.fromCharCode(64 + parseInt(questions?.solution))}.
              </span>
              <p className="text-new-neutral-light">{questions?.options?.[questions?.solution]?.[0]?.content}</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Mcqs
