import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, User } from "firebase/auth"

import { Auth } from "domain/model/Auth"
import { Login } from "domain/useCase/Admin/Auth/Login"
import { AuthRepositoryImpl } from "data/repository/Common/AuthRepositoryImpl"
import AuthAPIDataSourceImpl from "data/API/Common/AuthAPIDataSourceImpl"
import PageLoader from "core/components/PageLoader"
// import TokenRefresh from "domain/useCase/Common/TokenRefresh"
import { getUser } from "core/api/auth"
import { auth as firebaseAuth } from "core/configs/firebase"

type AuthContextType = {
  auth: Auth
  refreshed: boolean
  loading: boolean
  user: User | null
  login: (email: string, password: string) => Promise<Auth>
  logout: () => Promise<void>
  setRefreshed: Dispatch<SetStateAction<any>>
}

export const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<Auth>({} as Auth)
  const [loading, setLoading] = useState<boolean>(true)
  const [refreshed, setRefreshed] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)

  const loginUseCase = new Login(new AuthRepositoryImpl(new AuthAPIDataSourceImpl()))
  // const tokenRefreshUseCase = new TokenRefresh(new AuthRepositoryImpl(new AuthAPIDataSourceImpl()))

  // useEffect(() => {
  //   const checkAuthState = async () => {
  //     let localAuth = localStorage.getItem("auth")
  //     if (typeof localAuth === "string" && localAuth !== "undefined") {
  //       try {
  //         const parsedAuth = JSON.parse(localAuth)
  //         const response = await tokenRefreshUseCase.invoke(parsedAuth?.refresh_token)
  //         const { id_token } = response?.data as { id_token: string }
  //         setAuth({ ...parsedAuth, id_token })
  //         setRefreshed(true)
  //         if (!user) {
  //           setUser(response?.data)
  //         }
  //       } catch (e: any) {
  //         console.error("ERROR (token_refresh):", e)
  //         setError(e.response.data)
  //       }
  //     }
  //     setLoading(false)
  //   }

  //   checkAuthState()

  //   const intervalId = setInterval(
  //     () => {
  //       checkAuthState()
  //     },
  //     1 * 60 * 60 * 1000 // every 1hr
  //   )

  //   return () => clearInterval(intervalId)
  // }, [])

  /* SDK */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const refresh_token = user.refreshToken
        const local_id = user.uid
        const id_token = await user.getIdToken()
        const tokens = { refresh_token, local_id, id_token }
        const { data: userData } = await getUser(id_token)
        const updatedAuth = { ...auth, ...userData, ...tokens }
        setAuth(updatedAuth)
        setRefreshed(true)
      }
      setLoading(false)
      setUser(user)
    })

    return () => unsubscribe()
  }, [])

  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password) /* SDK */
      const { data: authData } = await loginUseCase.invoke({ email, password })
      setAuth(authData)
      setRefreshed(true)
      return authData
    } catch (e: unknown) {
      if (e instanceof Error) {
        throw new Error(e.message)
      } else {
        throw new Error("Unknown error occurred during login")
      }
    }
  }

  const logout = async () => {
    await signOut(firebaseAuth) /* SDK */
    setAuth({} as Auth)
    setRefreshed(false)
    setUser(null)
    localStorage.clear()
  }

  const authContextValue = {
    auth,
    refreshed,
    loading,
    user,
    login,
    logout,
    setRefreshed,
  }

  return <AuthContext.Provider value={authContextValue}>{loading ? <PageLoader /> : children}</AuthContext.Provider>
}
