import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface MarkAttendanceManuallyUseCase {
  invoke(auth: Auth, session_id: string, status: boolean): Promise<any>
}

export class MarkAttendanceManually implements MarkAttendanceManuallyUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string, status: boolean): Promise<any> {
    return this.repository.markAttendanceManually(auth, session_id, status)
  }
}
