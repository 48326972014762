import MonthlyFeedbackRepository from "domain/repository/Student/MonthlyFeedbackRepository"

export interface CheckMonthlyFeedbackUseCase {
  invoke(auth: any): Promise<any>
}

export class CheckMonthlyFeedback implements CheckMonthlyFeedbackUseCase {
  private readonly repository: MonthlyFeedbackRepository

  constructor(repository: MonthlyFeedbackRepository) {
    this.repository = repository
  }

  async invoke(auth: any): Promise<any> {
    return await this.repository.checkMonthlyFeedback(auth)
  }
}
