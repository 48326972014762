import React, { useState } from "react"
import DetailedDailyReport from "./DetailedDailyReport"
import Expand from "assets/svgs/Expand"

export default function DailyReportMainBody(props: any) {
  const [isOpen, setIsOpen] = useState(false)
  const handleToggleDetails = () => {
    setIsOpen(false)
  }
  React.useEffect(() => {
    props.fetchthing(props.todayDate)
  }, [])
  React.useEffect(() => {
    if (!props.dataLoading) {
      const dashboardDiv = document.querySelector("#dashboard") as HTMLElement
      const handleScroll = () => {
        const scrollTop = dashboardDiv.scrollTop
        const scrollHeight = dashboardDiv.scrollHeight
        const clientHeight = dashboardDiv.clientHeight

        if (scrollHeight <= Math.ceil(clientHeight) + Math.ceil(scrollTop)) {
          if (!props.dataLoading) props.fetchthing(props.todayDate)
        }
      }

      dashboardDiv.addEventListener("scroll", handleScroll)

      return () => {
        dashboardDiv.removeEventListener("scroll", handleScroll)
      }
    }
  }, [props.daily, props.dataLoading])

  return (
    <>
      <div className="flex flex-col font-montserrat">
        {props.daily.map((mapItem: any, index: number) => (
          <div key={index}>
            {Object.keys(mapItem).map((key: string, innerIndex: number) => {
              const item = mapItem[key]
              return (
                <div className="flex flex-1 " key={key}>
                  <div
                    className={`w-[104px] group relative ${
                      key === new Date(Date.now()).toISOString().slice(0, 10).replace(/-/g, "")
                        ? " bg-[#312E81] text-white"
                        : "text-[#6B6B6B]"
                    } flex-row border-r-[0.5px]  border-r-[#ADADAD]`}
                  >
                    <div className="p-8 pt-[19px] pb-[21px] text-center  cursor-pointer">
                      <div className="text-[18px] font-semibold">{key.slice(-2)}</div>
                      <div className="text-[12px]">{props.getDayOfWeek(key)}</div>
                    </div>
                    <div className="text-white whitespace-nowrap bg-[#414141] rounded-[2px] p-2 text-[10px] font-medium absolute hidden group-hover:block top-[50px] left-[75px] transition-all duration-300">
                      {props.formatDateString(key)}
                    </div>
                  </div>
                  <div
                    className="w-[153px] border-b-[0.5px] cursor-pointer border-r-[0.5px] border-[#ADADAD] border-opacity-20 p-[10px]"
                    onClick={() => {
                      setIsOpen(true)
                      props.setInd(index)
                    }}
                  >
                    {item?.attendance && (
                      <div
                        className={`group h-full w-full ${
                          item?.attendance?.attendance === "True"
                            ? "rounded-sm border-[0.2px] border-opacity-20 border-[#44BC66] bg-[#EDFFF2]"
                            : item?.attendance?.attendance === "False"
                              ? "rounded-sm border-[0.2px] border-opacity-20 border-[#AB3535] bg-[#FFEDED]"
                              : " rounded-sm border-[0.2px] border-opacity-20 border-[#ADADAD] bg-[#FAFAFA]"
                        }`}
                      >
                        <div
                          className={`ml-[6px] mt-1 mb-1 text-[14px] font-semibold  ${
                            item?.attendance?.attendance === "True"
                              ? " text-[#00C22B] "
                              : item?.attendance?.attendance === "False"
                                ? " text-[#DC2626]"
                                : null
                          }`}
                        >
                          {item?.attendance?.attendance == "True"
                            ? "Present"
                            : item?.attendance?.attendance == "False"
                              ? "Absent"
                              : ""}
                        </div>
                        <div className="flex">
                          <div className="text ml-[6px] h-6 flex-1 p-0 text-[10px] font-medium leading-tight text-[#6B6B6B]">
                            Class <br />
                            Attendence
                          </div>
                          <div className="mr-2 mt-auto h-4 w-4">
                            <Expand
                              classname={`hidden ${
                                item?.attendence !== "NA" && "group-hover:block group-hover:cursor-pointer"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="w-[153px] border-b-[0.5px] cursor-pointer border-r-[0.5px] border-[#ADADAD] border-opacity-20 p-[10px]"
                    onClick={() => {
                      setIsOpen(true)
                      props.setInd(index)
                    }}
                  >
                    {item?.problem_solving_track && (
                      <div
                        className={`group h-full w-full ${
                          item?.problem_solving_track !== 0
                            ? "rounded-sm border-[0.2px] border-opacity-20 border-[#7C3794] bg-[#FAEDFF]"
                            : "rounded-sm border-[0.2px] border-opacity-20 border-[#AB3535] bg-[#FFEDED]"
                        }`}
                      >
                        <div
                          className={`ml-[6px] mt-1 mb-1 text-[14px] font-semibold  ${
                            item?.problem_solving_track?.leetcode_count + item?.problem_solving_track?.platform_count >=
                            250
                              ? " text-[#7C3794] "
                              : null
                          }`}
                        >
                          {item?.problem_solving_track?.leetcode_count
                            ? item?.problem_solving_track?.leetcode_count
                            : "N/A"}{" "}
                          |{" "}
                          {item?.problem_solving_track?.platform_count
                            ? item?.problem_solving_track?.platform_count
                            : "N/A"}
                        </div>
                        <div className="flex">
                          <div className="text ml-[6px] h-6 flex-1 p-0 text-[10px] font-medium leading-tight text-[#6B6B6B]">
                            Problems <br /> Solved
                          </div>
                          <div className="mr-2 mt-auto h-4 w-4">
                            <Expand
                              classname={`hidden ${
                                item?.attendance?.solved !== "NA" && "group-hover:block group-hover:cursor-pointer"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="w-[153px] border-b-[0.5px] cursor-pointer border-r-[0.5px] border-[#ADADAD] border-opacity-20 p-[10px]"
                    onClick={() => {
                      setIsOpen(true)
                      props.setInd(index)
                    }}
                  >
                    {item?.weekly_assessment && (
                      <div
                        className={`group h-full w-full ${
                          item?.weekly_assessment?.score_got !== 0
                            ? "rounded-sm border-[0.2px] border-opacity-20 border-[#FBBF24] bg-[#FEFFE4]"
                            : "rounded-sm border-[0.2px] border-opacity-20 border-[#AB3535] bg-[#FFEDED]"
                        }`}
                      >
                        <div
                          className={`ml-[6px] mt-1 mb-1 text-[14px] font-semibold  ${
                            item?.weekly_assessment?.score_got !== 0 ? " text-[#5641A9] " : " text-[#DC2626]"
                          }`}
                        >
                          {item?.weekly_assessment?.score_got} / {item?.weekly_assessment?.total_score}
                        </div>
                        <div className="flex">
                          <div className="text ml-[6px] h-6 flex-1 p-0 text-[10px] font-medium leading-tight text-[#6B6B6B]">
                            Contest
                          </div>
                          <div className="mr-2 mt-auto h-4 w-4">
                            <Expand
                              classname={`hidden ${
                                item?.weekly_assessment?.score_got !== "NA" &&
                                "group-hover:block group-hover:cursor-pointer"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="flex w-[153px] border-b-[0.5px] cursor-pointer  border-[#ADADAD] border-opacity-20  "
                    onClick={() => {
                      setIsOpen(true)
                      props.setInd(index)
                    }}
                  >
                    {(item?.ssm || item?.mentorSession) && (
                      <>
                        <div
                          className={`h-full w-[10px] ${
                            item?.mentorSession && item?.ssm
                              ? "bg-red-500"
                              : item?.ssm
                                ? "bg-[#5641A9]"
                                : item?.mentorSession
                                  ? "bg-[#44BC66]"
                                  : null
                          }`}
                        ></div>
                        <div className={`group h-full w-full py-[10px] pr-[10px] `}>
                          <div
                            className={`ml-[6px] mt-1 mb-1 text-[14px] font-semibold  ${
                              item?.mentorSession && item?.ssm
                                ? "text-red-500"
                                : item?.ssm
                                  ? "text-[#5641A9]"
                                  : item?.mentorSession
                                    ? "text-[#44BC66]"
                                    : null
                            }`}
                          >
                            <div className="flex gap-1">
                              {item?.ssm?.rate && <div>{item?.ssm?.rate}</div>}
                              {item?.ssm?.rate && item?.mentorSession?.rate && <div className="font-normal">|</div>}
                              {item?.mentorSession && <div>{item?.mentorSession?.rate}</div>}
                            </div>
                          </div>
                          <div className="flex">
                            {item?.ssm && item?.mentorSession ? (
                              <>
                                <div className="text ml-[6px] h-6 flex-1 p-0 text-[10px] font-medium leading-tight text-[#6B6B6B]">
                                  Session <br />
                                  SSM + Mentor
                                </div>
                              </>
                            ) : item?.ssm ? (
                              <div className="text ml-[6px] h-6 flex-1 p-0 text-[10px] font-medium leading-tight text-[#6B6B6B]">
                                Student Progress <br />
                                Meeting
                              </div>
                            ) : item?.mentorSession ? (
                              <div className="text ml-[6px] h-6 flex-1 p-0 text-[10px] font-medium leading-tight text-[#6B6B6B]">
                                Mentor
                                <br />
                                Session
                              </div>
                            ) : null}

                            <div
                              className="mr-2 mt-auto h-4 w-4"
                              onClick={() => {
                                setIsOpen(true)
                                props.setInd(index)
                              }}
                            >
                              <Expand
                                classname={`hidden ${
                                  item?.weekly_assessment?.score_got !== "NA" &&
                                  "group-hover:block group-hover:cursor-pointer"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        ))}
        <DetailedDailyReport
          isOpen={isOpen}
          handleToggleDetails={handleToggleDetails}
          fetchthing={props.fetchthing}
          ind={props.ind}
          handleIncInd={props.handleIncInd}
          handleDecInd={props.handleDecInd}
          daily={props.daily}
          joiningDate={props.joiningDate}
          student={props.student}
        />
        {!props.dataLoading ? null : (
          <div className="flex flex-1 ">
            <div className="w-[104px] p-[10px] h-[85px] border-r-[0.5px] border-r-[#ADADAD]">
              <div className=" bg-gray-300 rounded-sm h-full w-full animate-pulse duration-75"></div>
            </div>
            <div className="w-[153px] p-[10px] h-[85px]">
              <div className=" bg-gray-300 rounded-sm h-full w-full animate-pulse duration-75"></div>
            </div>
            <div className="w-[153px] p-[10px] h-[85px]">
              <div className=" bg-gray-300 rounded-sm h-full w-full animate-pulse duration-75"></div>
            </div>
            <div className="w-[153px] p-[10px] h-[85px]">
              <div className=" bg-gray-300 rounded-sm h-full w-full animate-pulse duration-75"></div>
            </div>
            <div className="w-[153px] p-[10px] h-[85px]">
              <div className=" bg-gray-300 rounded-sm h-full w-full animate-pulse duration-75"></div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
