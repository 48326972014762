import AssessmentDataSource from "data/dataSource/Student/AssessmentDataSource"
import ContestRepository from "domain/repository/Student/ContestRepository"
import { Auth } from "domain/model/Auth"

export default class ContestRepositoryImpl implements ContestRepository {
  private dataSource: AssessmentDataSource

  constructor(dataSource: AssessmentDataSource) {
    this.dataSource = dataSource
  }

  async getAllContest(auth: Auth): Promise<any> {
    return await this.dataSource.getAllContest(auth)
  }

  async isStudentAttended(auth: Auth, contestId: any): Promise<any> {
    return await this.dataSource.isStudentAttended(auth, contestId)
  }

  async contestResult(auth: Auth, contestId: any): Promise<any> {
    return await this.dataSource.contestResult(auth, contestId)
  }
}
