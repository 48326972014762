import { Auth } from "domain/model/Auth"
import { mentorFeedback } from "domain/model/MyMentee"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export interface AllowExtraMentorSessionUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}

export class allowExtraMentorSession implements AllowExtraMentorSessionUseCase {
  private repository: MyMenteeRepository

  constructor(repository: MyMenteeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string) {
    return await this.repository.allowExtraMentorSession(auth, email)
  }
}
