import Loader from "core/components/Loader"
import Spinner from "core/components/Spinner"
import Button from "core/components/editor/Button"

export default function SubmitButton({ loading, id, handleSolutionSubmit, isQuestionSubmitted = false }: any) {
  return (
    <div className="w-full absolute bottom-0 left-0 flex justify-end bg-new-editor-dark-500 p-4 pr-10 items-center">
      <Button
        disabled={isQuestionSubmitted}
        className="bg-new-gradient text-lg px-4 w-[100px] h-10"
        onClick={() => handleSolutionSubmit(id)}
      >
        {loading ? <Spinner small /> : "Submit"}
      </Button>
    </div>
  )
}
