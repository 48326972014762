import { API_V4_ADMIN, API_V4_GET_MONTHLY_REPORT } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import GetMontlyReport from "domain/model/GetMonthlyReport"
import MonthlyReportDataSource from "data/dataSource/Admin/MonthlyReportDataSource"

export class MonthlyReportAPIDataSourceImpl implements MonthlyReportDataSource {
  async getMonthlyReport(getMontlyReport: GetMontlyReport): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_MONTHLY_REPORT), getMontlyReport)
      return response
    } catch (error) {
      return error
    }
  }
}
