export default function BinActive({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M11.3333 4.00065H14.6666V5.33398H13.3333V14.0007C13.3333 14.1775 13.2631 14.347 13.1381 14.4721C13.013 14.5971 12.8435 14.6673 12.6666 14.6673H3.33331C3.1565 14.6673 2.98693 14.5971 2.86191 14.4721C2.73688 14.347 2.66665 14.1775 2.66665 14.0007V5.33398H1.33331V4.00065H4.66665V2.00065C4.66665 1.82384 4.73688 1.65427 4.86191 1.52925C4.98693 1.40422 5.1565 1.33398 5.33331 1.33398H10.6666C10.8435 1.33398 11.013 1.40422 11.1381 1.52925C11.2631 1.65427 11.3333 1.82384 11.3333 2.00065V4.00065ZM12 5.33398H3.99998V13.334H12V5.33398ZM5.99998 2.66732V4.00065H9.99998V2.66732H5.99998Z"
        fill="#DC2626"
      />
    </svg>
  )
}
