import {
  API_CREATE_QUESTION,
  API_GET_NEXT_100_QUESTIONS,
  API_GET_QUESTION_BY_TOPIC,
  API_ALL_QUESTIONS,
  API_UPDATE_QUESTION,
  API_V4_ADMIN,
} from "core/constants/strings"
import QuestionsDataSource from "data/dataSource/Admin/QuestionsDataSource"
import {
  Questions,
  TCreateQuestion,
  TCreateResponse,
  TResponse,
  TUpdateQuestion,
  TUpdateResponse,
} from "domain/model/Questions"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { Auth } from "domain/model/Auth"

export default class QuestionsAPIDataSourceImpl implements QuestionsDataSource {
  async fetchQuestions(id_token: any): Promise<Questions> {
    const response = await postAPI(pn(API_V4_ADMIN, API_ALL_QUESTIONS), {
      id_token: id_token,
    })
    return response as unknown as Questions
  }

  async getNext100Questions(id_token: any, problem_name: any): Promise<Questions> {
    const response = await postAPI(pn(API_V4_ADMIN, API_GET_NEXT_100_QUESTIONS), {
      id_token: id_token,
      problem_name: problem_name,
    })
    return response as unknown as Questions
  }

  async getQuestionByTopic(auth: Auth, topic: string): Promise<TResponse> {
    const response = await postAPI(pn(API_V4_ADMIN, API_GET_QUESTION_BY_TOPIC), {
      id_token: auth.id_token,
      topic,
    })
    return response as unknown as TResponse
  }

  async createQuestion(auth: Auth, data: TCreateQuestion) {
    const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_QUESTION), {
      id_token: auth.id_token,
      ...data,
    })
    return response as unknown as TCreateResponse
  }

  async updateQuestion(auth: Auth, data: TUpdateQuestion) {
    const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_QUESTION), {
      id_token: auth.id_token,
      ...data,
    })
    return response as unknown as TUpdateResponse
  }
}
