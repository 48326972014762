import { Auth } from "domain/model/Auth"
import { ClassRepository } from "domain/repository/Mentor/ClassRepository"

export interface ClassReminderSchedulingUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export class ClassReminderScheduling implements ClassReminderSchedulingUseCase {
  private repository: ClassRepository

  constructor(repository: ClassRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: any) {
    return await this.repository.classReminderScheduling(auth, id)
  }
}
