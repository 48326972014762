import { format } from "date-fns"
import TableButton from "core/components/v2/TableButton"

export default function CourseSidebar({ lectures, courseName, activeIndex, onClick }: any) {
  return (
    <div className="space-y-2 rounded-lg bg-[#e3e3e3] pb-2 pr-1 shadow-[-1px_1px_3px_0pxr_rgba(132,136,139,0.05)]">
      <p className="px-4 pt-4 text-start text-sm font-semibold capitalize text-[#333]">{courseName}</p>
      <p className="px-4"> {lectures?.length} Video Lectures</p>
      <div className="gray-scrollbar flex h-[calc(100vh-300px)] w-full flex-col items-center gap-y-2 overflow-y-auto px-2 pr-1">
        {lectures?.map((item: any, idx: number) => (
          <div
            className={`flex h-[134px] min-h-[134px] w-[calc(100%-32px)] min-w-[205px] flex-col gap-2 rounded-lg border-[0.5px] bg-white p-3 shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.0.05)]`}
          >
            <div className="flex justify-between">
              <h3 className="w-4/5 text-sm font-semibold text-[#333]" title={item?.chapter_name}>
                {item?.chapter_name.slice(0, 20)}...
              </h3>
              <input
                type="checkbox"
                className="rounded border-[#D3D3D3] checked:bg-v2-success"
                checked={item?.is_completed}
              />
            </div>
            <p> Date: {format(item?.timestamp * 1000, "do MMM yyyy")}</p>
            <div className="flex-grow"></div>
            <div className="mt-auto flex">
              <TableButton
                outlined
                onClick={() => onClick(item, idx)}
                className={`${activeIndex === idx && "border-[#07B42D] text-[#07B42D]"}`}
              >
                {activeIndex === idx ? "Selected" : "Play Video"}
              </TableButton>
            </div>
          </div>
        ))}
      </div>
    </div>

    // <div className="shrink-1 flex h-full w-[222px] flex-col rounded shadow-card">
    //   <div className="space-y-1 border-b border-new-neutral-light p-6">
    //     <h3 className="text-new-accent">{courseName}</h3>
    //     <p className="text-xs leading-[19px] text-new-neutral">
    //       {lectures?.length} Video Lectures
    //     </p>
    //   </div>
    //   <div className="gray-scrollbar flex h-[60vh] flex-col gap-4 overflow-y-auto px-6 py-4">
    //     {lectures?.map((lecture: any, i: number) => (
    //       <button
    //         key={i}
    //         className={cn(
    //           "relative block cursor-pointer rounded-sm border-2 px-4 py-2 hover:border-new-accent",
    //           activeIndex === i &&
    //             "before:absolute before:top-2 before:right-4 before:h-1.5 before:w-1.5 before:rounded-full before:bg-new-accent",
    //           activeIndex === i
    //             ? "border-new-accent"
    //             : "border-new-neutral-light"
    //         )}
    //         onClick={() => onClick(lecture, i)}
    //       >
    //         <div className="flex gap-1">
    //           <span
    //             className={cn(
    //               lecture?.is_completed
    //                 ? "text-new-success"
    //                 : "text-new-neutral-light"
    //             )}
    //           >
    //             {lecture?.is_completed ? (
    //               <CheckSuccessIcon className="h-4 w-4" />
    //             ) : (
    //               <CheckBlankIcon className="h-4 w-4" />
    //             )}
    //           </span>
    //           <h4 className="text-new-neutral-light">
    //             {format(lecture?.timestamp * 1000, "dd/MM/yyyy")}
    //           </h4>
    //         </div>
    //         <h4 className="text-left text-new-accent">
    //           {lecture?.chapter_name}
    //         </h4>
    //       </button>
    //     ))}
    //   </div>
    // </div>
  )
}
