import ArrowGoBack from "assets/svgs/ArrowGoBack"
import LeftArrow from "assets/svgs/LeftArrow"
import RightArrow from "assets/svgs/RightArrow"
import React, { useRef } from "react"

function Pagination({ currentPage, totalPages, onPageChange }: any) {
  const paginationRef = useRef(null)

  const handlePageClick = (page: any) => {
    if (page !== currentPage) {
      onPageChange(page)
    }
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  return (
    <div className="flex items-center justify-center">
      <button
        className="p-2 bg-new-accent border focus:outline-none"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <LeftArrow />
      </button>
      <div className="flex overflow-x-auto space-x-2 mx-2" ref={paginationRef}>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            className={`px-4 py-2 border-none focus:outline-none  ${
              currentPage === page ? "bg-[#F9F9F9] text-[#414141]" : "text-[#414141]"
            }`}
            onClick={() => handlePageClick(page)}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        className="p-2 border bg-new-accent focus:outline-none"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        <RightArrow />
      </button>
    </div>
  )
}

export default Pagination
