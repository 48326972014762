import { Auth } from "domain/model/Auth"
import { AvailabilityRepository } from "domain/repository/Mentor/AvailabilityRepository"

export interface UpdateAvailabilityUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UpdateAvailability implements UpdateAvailabilityUseCase {
  private repository: AvailabilityRepository

  constructor(repository: AvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.updateAvailability(auth, data)
  }
}
