import Model from "core/components/Model"
import React from "react"

function DetailSession(props: any) {
  return (
    <>
      <div>
        {props.isSessionDetailOpen && (
          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.85)]">
              <div className={"relative mx-auto  shadow-lg"}>
                <div className="relative">
                  <div className="font-montserrat my-6 flex h-full flex-row rounded-sm bg-new-solid-white">
                    <div className="ml-6 flex flex-col items-center border-r-[1px]  border-[#] py-6 pr-6 ">
                      <div className="">
                        <div className="text-[18px] font-semibold text-[#312E81]">
                          {props.formatDate(props.removeHypens(props.date))}
                        </div>
                        <div className="text-[12px] font-normal leading-[140%] text-[#414141]">SSM Meet Report</div>
                      </div>
                    </div>
                    <div className="mx-6 mt-6">
                      <div className="mb-4 flex w-full">
                        <div className=" text-[16px] font-semibold leading-normal text-[#414141]">
                          Student Success Manager Meeting
                        </div>
                        <div className=" ml-auto flex justify-end ">
                          <button
                            className="text-gray-500 hover:text-gray-600 focus:outline-none"
                            onClick={props.handleIsSessionDetailopen}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M10.586 12L5.63599 7.05001L7.04999 5.63601L12 10.586L16.95 5.63601L18.364 7.05001L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12Z"
                                fill="#6B6B6B"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="justify-between space-y-2 pb-6">
                        <div className="flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">• Status:</div>
                          <div
                            className={`text-[14px] font-semibold leading-normal ${
                              props.status === "Scheduled"
                                ? "text-yellow-500"
                                : props.status === "N/A"
                                  ? "text-gray-500"
                                  : props.status === "Student Absent" || props.status === "SSM Absent"
                                    ? "text-red-500"
                                    : props.status === "Completed"
                                      ? "text-[#00C22B]"
                                      : "text-black-500"
                            }`}
                          >
                            {props.status}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">• Date :</div>
                          <div className="text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                            {props.formatDate(props.removeHypens(props.date))}
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48  text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Meeting Agenda : <br /> <br />
                          </div>
                          <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                            <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                              <div className="break-words">{props.meetingAgenda}</div>
                            </pre>
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Student Rating <br />
                            <div className="flex text-[#ADADAD]">
                              <div>&nbsp;(Given by SSM)</div>
                              <div className="text-[#414141]">:</div>
                            </div>
                          </div>
                          <div className=" text-[14px] font-semibold leading-normal text-[#FBBF24]">
                            {props.studentRating ? props.studentRating + "/5" : "N/A"}
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48  text-[12px] font-normal leading-[140%] text-[#414141]">
                            • SSM Remark :<br /> <br />
                          </div>
                          <div className="p-1/4 items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                            <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal">
                              <div className="break-words">{props.ssmRemark ? props.ssmRemark : "-"}</div>
                            </pre>
                          </div>
                        </div>
                        <div className=" flex">
                          <div className=" w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Student's Feedback : <br /> <br />
                          </div>
                          <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                            <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal ">
                              <div className="break-words">{props.studentFeedback ? props.studentFeedback : "-"}</div>
                            </pre>
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Problem Solving <br /> &nbsp; Milestone Due date :
                          </div>
                          <div className=" text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                            {props.problemSolvingMSDate
                              ? props.problemSolvingMSDate &&
                                props.formatDate(props.removeHypens(props.problemSolvingMSDate))
                              : "N/A"}
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Problem Solving <br />
                            &nbsp; Milestone : <br />
                          </div>
                          <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                            <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                              <div className="break-words">{props.problemSolvingMS ? props.problemSolvingMS : "-"}</div>
                            </pre>
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Development Milestone <br />
                            &nbsp; Due date:
                          </div>
                          <div className="text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                            {props.developmentMSDate
                              ? props.formatDate(props.removeHypens(props.developmentMSDate))
                              : "N/A"}
                          </div>
                        </div>
                        <div className=" flex">
                          <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                            • Development Milestone : <br />
                            <br />
                          </div>
                          <div className="p-1/4   items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                            <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                              <div className="break-words">{props.developmentMS ? props.developmentMS : "-"}</div>
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* </Model> */}
    </>
  )
}

export default DetailSession
