import { Auth } from "domain/model/Auth"
import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"

export interface getReferrerStudentDataUseCase {
  invoke(email: string): Promise<any>
}
export class GetReferrerStudentData implements getReferrerStudentDataUseCase {
  private repository: ReferAndEarnRepository

  constructor(repository: ReferAndEarnRepository) {
    this.repository = repository
  }

  async invoke(email: string): Promise<any> {
    return await this.repository.getReferrerStudentData(email)
  }
}
