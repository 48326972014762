import CreateOnboardingManagerDataSource from "data/dataSource/Admin/CreateOnboardingManagerDataSource"
import { Auth } from "domain/model/Auth"
import { CreateOnboardingManager } from "domain/model/CreateOnboardingManager"
import { CreateOnboardingManagerRepository } from "domain/repository/Admin/CreateOnboardingManagerRepository"

export class CreateOnboardingManagerRepositoryImpl implements CreateOnboardingManagerRepository {
  private Datasource: CreateOnboardingManagerDataSource

  constructor(Datasource: CreateOnboardingManagerDataSource) {
    this.Datasource = Datasource
  }

  async createOnboardingManager(createOnboardingManager: CreateOnboardingManager): Promise<any> {
    return await this.Datasource.createOnboardingManager(createOnboardingManager)
  }
}
