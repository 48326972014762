import { cn } from "core/lib/utils"

export default function TabsGroup({ TABS, activeTab, handelTabChange }: any) {
  return (
    <div className="-m-8 mb-0 flex divide-x bg-new-editor-dark-500">
      {TABS.map((btn: any, index: number) => (
        <button
          key={index}
          className={cn(
            "w-full flex-1 select-none rounded-sm py-2 text-lg leading-[22px]",
            activeTab === index ? "bg-new-editor-dark-700 text-white" : "text-new-neutral-light"
          )}
          onClick={() => handelTabChange(index)}
        >
          {btn}
        </button>
      ))}
    </div>
  )
}
