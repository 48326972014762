import Flag from "core/components/Flag"
import HomePageGradient from "core/components/new/HomePageGradient"
import { Button } from "core/components/v2/Button"
import React from "react"

const NewMentorBeginSelectionCard = ({ onClick }: any) => {
  return (
    <div className="relative px-[24px] py-[16px] h-[115px] my-[24px] w-full border-[#D3D3D3] border-[0.5px] rounded-[8px]">
      <div className="absolute top-0 left-[200px]">
        <HomePageGradient className={"#FFBEBE"} />
      </div>
      <div className="absolute top-0 left-[280px]">
        <HomePageGradient className={"#FFBEBE"} />
      </div>
      <div className="flex justify-between">
        <Flag text="Mandatory" />
        {/* <p className="text-[#DC2626] text-[14px] font-[600]">1 min</p> */}
      </div>
      <p className="text-[14px] font-[600] text-[#333] my-[2px]">Complete mentor selection process.</p>

      <div className="flex justify-between items-center">
        <p className="w-[313px] text-[#646464] text-[12px] font-[400] leading-4">
          You have not selected you mentor yet, complete the remaining steps to book amentor session.
        </p>
        <Button onClick={onClick} size={"sm"} variant={"primary"} className="w-[142px] h-[35px] z-20">
          Continue{" "}
        </Button>
      </div>
    </div>
  )
}

export default NewMentorBeginSelectionCard
