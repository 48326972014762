import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import ContestSubmissionViewModel from "./ContestSubmissionViewModel"
import ContestSubmissionCard from "./components/ContestSubmissionCard"
import Toast from "core/components/Toast"
import Pagination from "./components/Pagination"
import React from "react"
import { useAuth } from "core/context/auth"
import { format } from "date-fns"
import SSMFeedbackModal from "./components/FeedbackModal"

const ContestSubmissionView = () => {
  const {
    selectedContest,
    date,
    fetchContestDetails,
    fetchAllStudentList,
    studentData,
    toast,
    changeToastVisibility,
    handleNavigateClick,
    contestData,
    page,
    handlePageChange,
    pageLimit,
    totalDataList,
    loading,
    openFeedbackModal,
    feedbackResponse,
    handleSelectedQuestionBtnClick,
    handleCloseBtnClick,
    getDate,
    selectedQuestion,
    handleSubmissionClick,
    handleCopyToClipboard,
    handleSSMFeedback,
    fetchContestSSMAndSubmissionStatus,
    buttonStatus,
    student_email,
    contest_timestamp,
    order,
    handleQuestionTypeClick,
    handleMainQuestionClick,
    selectedQuestionType,
    selectedMainQuestion,
    // setSelectedQuestion,
  } = ContestSubmissionViewModel()

  const [contestId, setContestId] = React.useState("")

  const auth = useAuth()

  React.useEffect(() => {
    const url = window.location.href
    const parts = url.split("/")
    const id = parts[parts.length - 2]
    setContestId(id)
  }, [auth])

  React.useEffect(() => {
    if (contestId) {
      fetchContestDetails()
    }
  }, [contestId])

  React.useEffect(() => {
    if (contestId) {
      fetchAllStudentList(contestId, page, pageLimit)
    }
  }, [contestId, page, pageLimit])
  const skeletonItems = Array.from({ length: 10 }, (_, index) => index)
  return (
    <DashboardLayout>
      <div className="p-4 space-y-6">
        <div className="flex justify-between">
          <div className="space-y-1">
            <h2>Contest Submissions</h2>
            <div>
              <p>{selectedContest} - All Submissions</p>
              <p className="text-[#ADADAD]">
                {date && <div>{format(parseInt(date) * 1000, "do MMMM, yyyy | hh:mm a")}</div>}
              </p>
            </div>
          </div>
          <Button outlined failure className="w-[160px] h-[40px] px-4 py-2" onClick={handleNavigateClick}>
            Go Back
          </Button>
        </div>
        {/* Removed batch selection buttons */}
        {loading ? (
          skeletonItems.map((index) => (
            <div key={index} className="p-6 text-new-neutral-dark shadow animate-pulse">
              <div className="flex">
                <div className="h-6 w-6 bg-gray-200 rounded-full mr-2"></div>
                <div className="h-4 bg-gray-200 w-32 rounded-lg"></div>
              </div>
              <div className="h-4 bg-gray-200 w-16 rounded-lg mt-2"></div>
              <div className="flex justify-between mt-4">
                <div className="h-4 bg-gray-200 w-32 rounded-lg"></div>
                <div className="flex space-x-4">
                  <div className="h-8 bg-gray-200 w-20 rounded-lg"></div>
                  <div className="h-8 bg-gray-200 w-20 rounded-lg"></div>
                </div>
              </div>
            </div>
          ))
        ) : studentData?.length > 0 ? (
          studentData.map((data: any, index: any) => (
            <ContestSubmissionCard
              key={index}
              studentData={data}
              contestData={contestData}
              handleSubmissionClick={handleSubmissionClick}
              handleSSMFeedback={handleSSMFeedback}
              handleCopyToClipboard={handleCopyToClipboard}
              buttonStatus={buttonStatus[index]} //check this
              fetchContestSSMAndSubmissionStatus={fetchContestSSMAndSubmissionStatus}
              index={index}
            />
          ))
        ) : (
          <p className="text-new-neutral-dark text-center py-6">No submissions found</p>
        )}
        {studentData?.length > 0 && !loading && (
          <div className="p-4 sticky top-0 bg-white z-10">
            <Pagination
              currentPage={page}
              totalPages={Math.ceil(totalDataList / pageLimit)}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        {openFeedbackModal && (
          <SSMFeedbackModal
            feedback_response={feedbackResponse}
            onClose={handleCloseBtnClick}
            selectedQuestion={selectedQuestion}
            handleSelectedQuestionBtnClick={handleSelectedQuestionBtnClick}
            getDate={getDate}
            contest_timestamp={contest_timestamp}
            handleSubmissionClick={handleSubmissionClick}
            student_email={student_email}
            order={order}
            handleQuestionTypeClick={handleQuestionTypeClick}
            handleMainQuestionClick={handleMainQuestionClick}
            selectedQuestionType={selectedQuestionType}
            selectedMainQuestion={selectedMainQuestion}
            // setSelectedQuestion={setSelectedQuestion}
          />
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}

export default ContestSubmissionView
