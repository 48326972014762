import { Auth } from "domain/model/Auth"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface getAllTracksUseCase {
  invoke(auth: Auth, track: string): Promise<any>
}

export class GetModuleBasedTrack implements getAllTracksUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, track: string) {
    return await this.repository.getModulesBasedOnTrack(auth, track)
  }
}
