import pn from "core/utils/pn"
import {
  API_BLOCK_DATES,
  API_GET_AVAILABILITY,
  API_MENTORS,
  API_UPDATE_AVAILABILITY,
  API_V4_MENTOR,
  API_UPDATE_GOAL_SETTING_AVAILABILITY,
  API_GET_GOAL_SETTING_AVAILABILITY,
  API_BLOCK_GOAL_SETTING_DATES,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import { AvailabilityDataSource } from "data/dataSource/Mentor/AvailabilityDataSource"
import { Auth } from "domain/model/Auth"

export default class AvailabilityAPIDataSourceImpl implements AvailabilityDataSource {
  async getAvailability(auth: Auth) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_GET_AVAILABILITY), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateAvailability(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_UPDATE_AVAILABILITY), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async blockDates(auth: Auth, date: any) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_BLOCK_DATES), {
        id_token: auth.id_token,
        date,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getGoalSettingAvailability(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, API_GET_GOAL_SETTING_AVAILABILITY), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateGoalSettingAvailability(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, API_UPDATE_GOAL_SETTING_AVAILABILITY), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async blockGoalSettingDates(auth: Auth, date: any) {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, API_BLOCK_GOAL_SETTING_DATES), {
        id_token: auth.id_token,
        date,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
