import React from "react"
import { GetStudentPaymentDetails } from "domain/useCase/Student/Payment/GetStudentPaymentDetails"
import { GetStudentPaymentDetailsAll } from "domain/useCase/Student/Payment/GetStudentDetailsAll"
import { PaymentRepositoryImpl } from "data/repository/Student/PaymentRepositoryImpl"
import { PaymentOneShot } from "domain/useCase/Student/Payment/CreatePaymentOneShot"
import { PaymentAPIDataSourceImpl } from "data/API/Student/PaymentAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { GetFeeAfterDiscount } from "domain/useCase/Student/Payment/GetFeeAfterDiscount"
import { GetPaymentDocuments } from "domain/useCase/Student/Payment/GetPaymentDocuments"
import { UploadPaymentDocument } from "domain/useCase/Student/Payment/UploadPaymentDocument"
import { DeletePaymentDocument } from "domain/useCase/Student/Payment/DeletePaymentDocument"
import { GetStudentEMI } from "domain/useCase/Student/Payment/GetStudentEmi"
import { CreateStudentEMI } from "domain/useCase/Student/Payment/CreateStudentEMI"
import { TPaymentDocumentItem, TStudentEMI } from "domain/model/Payment"
import Button from "core/components/Button"
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import InvoiceTemplate from "./components/InvoiceTemplate"
import { capitalize } from "../../../../src/core/utils/string"
import { GetPaymentMethod } from "domain/useCase/Student/Payment/GetPaymentMethod"
import { useApp } from "core/context/app"
import useToast from "core/hooks/useToast"
export default function PaymentOptionsViewModel() {
  const { student } = useApp()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const interestRate =
    student?.batch[0] === "B"
      ? [0, 1.9, 8.9, 11.9]
      : student?.batch[0] === "D"
        ? [0, 0, 8.9, 11.9, 14.9, 16.9]
        : [0, 1.9, 8.9, 11.9, 14.9, 16.9]
  const [activePaymentButtonType, setActivePaymentButtonType] = React.useState(0)
  const [selectedEMIOption, setSelectedEMIOption] = React.useState(0)
  const [activeInstallment, setActiveInstallment] = React.useState({
    number: 0,
    due_time: "",
  })
  const [paymentOptionDiscount, setPaymentOptionDiscount] = React.useState<any>({})
  const [paymentDocuments, setPaymentDocuments] = React.useState<TPaymentDocumentItem[]>([])
  const [studentEMI, setStudentEMI] = React.useState<TStudentEMI>({
    duration_index: -1,
    is_submitted: false,
    progress: [],
  })
  const { auth, refreshed } = useAuth()
  const [paymentMethod, setPaymentMethod] = React.useState<any>("")
  const [payData, setPayData] = React.useState<any>([])
  const [payDataAll, setPayDataAll] = React.useState<any>({})
  const [openPopUp, setOpenPopUp] = React.useState<any>(false)
  const [isCompletelyPaid, setIsCompletelyPaid] = React.useState<any>(false)
  const [showPayNow, setShowPayNow] = React.useState<any>(false)
  const [disableInitiateNow, setDisableInitiateNow] = React.useState<any>(false)
  const [paymentLink, setPaymentLink] = React.useState<any>("")
  const [studentDataForOneShot, setStudentDataForOneShot] = React.useState<any>({
    student_email: "",
    instalments: [],
    course_fees: "",
    standard_discount: "",
    seat_booking_amount: "",
  })
  const [emiAmount, setEmiAmount] = React.useState<number>(0)
  const [emiInterest, setEmiInterest] = React.useState<number>(0)
  const [studentTransactionTableData, setStudentTransactionTableData] = React.useState<any>({
    header: [],
    rows: [],
  })

  const getPaymentMethodUseCase = new GetPaymentMethod(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const getPaymentDocumentsUseCase = new GetPaymentDocuments(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const uploadPaymentDocumentUseCase = new UploadPaymentDocument(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )
  const deletePaymentDocumentUseCase = new DeletePaymentDocument(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )
  const getStudentEMIUseCase = new GetStudentEMI(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const createStudentEMIUseCase = new CreateStudentEMI(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const getStudentPaymentUseCase = new GetStudentPaymentDetails(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )
  const getStudentPaymentAllUseCase = new GetStudentPaymentDetailsAll(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )
  const getFeeAfterDiscountUseCase = new GetFeeAfterDiscount(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const createPaymentOneShotUseCase = new PaymentOneShot(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  function changeActivePaymentButtonType(index: number) {
    if (activePaymentButtonType !== index) setActivePaymentButtonType(index)
  }
  function handleEMIOption(index: number) {
    setSelectedEMIOption(index)
  }
  function handleDirectPayment() {}
  function handleEMIPayment() {}
  function handlePopUp() {
    setOpenPopUp(!openPopUp)
  }
  const fetchPaymentMethod = async () => {
    try {
      const response = await getPaymentMethodUseCase.invoke(auth)
      setPaymentMethod(response.data.payment_method)
    } catch (err) {}
  }

  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp * 1000)
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions
    return date.toLocaleDateString("en-US", options) // Format like: 12 Feb, 2023
  }

  async function getStudentPaymentDetails() {
    const response = await getStudentPaymentUseCase.invoke(auth)
    if (response?.success) {
      setPayData(response?.data)
      for (let i = 0; i < response?.data.length; i++) {
        if (response?.data[i].status === "created") {
          setPaymentLink(response?.data[i].link)
          break
        }
      }
    }
  }

  const calculateOneshotDiscount = () => {
    const discountAmount =
      (payDataAll?.fees - payDataAll?.standard_discount - payDataAll?.seat_booking_amount) *
      (payDataAll?.one_shot_discount / 100)

    return Math.max(0, Math.round(discountAmount)) // Ensure non-negative integer
  }
  const calculateAmountPaid = (): number => {
    const totalAmount = payData.reduce((curr: any, sum: number) => {
      if (curr.status === "paid") sum += curr.amount
      return sum
    }, 0)
    return totalAmount
  }
  async function getStudentPaymentDetailsAll() {
    const response = await getStudentPaymentAllUseCase.invoke(auth)
    if (response?.success) {
      setPayDataAll(response?.data)
      const header = ["No.", "Fee type", "Reference no.", "Received on", "Amount", "Status", "Download Receipts"]

      const instalments = response?.data?.instalments
      let rownumber = 1
      const rows = response?.data?.instalments
        .filter((payment: any) => payment.status === "paid")
        // .filter((payment: any) => payment.status === 'paid' || 'created')
        .map((payment: any, index: any) => {
          const dueDateParts = payment.due_time.split(" ")
          const formattedDueDate = dueDateParts.slice(0, 4).join(" ")
          const feeType = payment.payment_type === "seat_booking" ? "Seat booking" : `Installment ${rownumber}`

          if (payment.payment_type !== "seat_booking") rownumber++

          payment.amount =
            studentEMI.duration_index !== -1 && payment.payment_type !== "seat_booking" ? emiAmount : payment.amount

          const paymentDescription = response?.data?.instalments.length > 2 ? "Bosscoder Direct" : "Direct to Bosscoder"
          return {
            "No.": index + 1,
            "Fee Type": feeType,
            "Reference No.": payment.invoice_number,
            "Received on": formatDate(payment.paid_at),
            Amount: `${payment.amount} Rs`,
            Status: (
              <span className={`p-4 ${payment.status === "paid" ? "text-500 text-[#00C22B]" : ""}`}>
                {capitalize(payment.status)}
              </span>
            ),
            "Download Receipts": (
              <span
                className={`p-4 ${payment.status === "paid" ? "text-500-14px border-[#312E81] text-[#312E81]" : ""}`}
              >
                <PDFDownloadLink
                  className="font-medium text-new-accent"
                  document={
                    <InvoiceTemplate
                      data={{
                        payment: payment,
                        index: rownumber,
                        payment_type: payment.payment_type,
                        description: paymentDescription,
                      }}
                    />
                  }
                  fileName="Invoice.pdf"
                >
                  {(loading) =>
                    loading ? (
                      <Button className="border-[#312E81] text-[#312E81]">Download</Button>
                    ) : (
                      <Button className="border-[#312E81] text-[#312E81]">Download</Button>
                    )
                  }
                </PDFDownloadLink>
              </span>
            ),
          }
        })

      setStudentTransactionTableData({ header, rows })
      if (instalments) {
        let number_of_paid = 0
        for (let index = 0; index < instalments.length; index++) {
          const item = instalments[index]
          if (item.status === "paid") {
            number_of_paid++
          }
        }
        for (let index = 0; index < instalments.length; index++) {
          const item = instalments[index]
          if (item.status === "created") {
            setActiveInstallment({
              due_time: item.due_time,
              number: number_of_paid + 1,
            })
            break
          }
        }
      }

      let paidNo = 0
      response?.data?.instalments?.map((item: any) => {
        if (item.status === "paid") {
          paidNo++
        }
      })
      if (paidNo === response?.data?.instalments?.length && paidNo !== 1) setIsCompletelyPaid(true)
    }
  }
  const handleGeneratePaylink = async () => {
    setDisableInitiateNow(true)
    const response = await createPaymentOneShotUseCase.invoke(auth)
    setPaymentLink(response?.data?.paymentLink)
    if (response?.data?.paymentLink) {
      setShowPayNow(true)
      setDisableInitiateNow(false)
    }
  }

  const uploadPaymentDocument = async (file: File, filename: string, documentId: number) => {
    const response = await uploadPaymentDocumentUseCase.invoke(auth, file, filename, documentId)
    if (!response?.success) {
      return
    }
  }

  const deletePaymentDocument = async (docuemntId: number) => {
    const response = await deletePaymentDocumentUseCase.invoke(auth, docuemntId)
    if (!response?.success) {
      return
    }
  }

  const getPaymentDocuments = async () => {
    const response = await getPaymentDocumentsUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
    setPaymentDocuments(response?.data?.documents)
  }

  const getStudentEMI = async () => {
    const response = await getStudentEMIUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
    setStudentEMI(response?.data)
  }

  const createStudentEMI = async (studentEMI: TStudentEMI) => {
    const response = await createStudentEMIUseCase.invoke(auth, studentEMI)

    if (!response?.success) {
      return
    }
    setStudentEMI(response?.data)
  }

  const CreatePaymentOneShot = async () => {
    // const response = await createPaymentOneShotUseCase.invoke(auth);
    // if (!response?.success) {
    //   changeToastVisibility(true);
    //   return;
    // }
    // changeToastVisibility(true);
    // changeToastDetails(STR_SUCCESS, response?.data);
    // setPaymentDetails({
    //   student_email: '',
    //   instalments: [{ amount: 0, due_time: 0 }],
    //   course_fees: 0,
    //   discount: 0,
    //   seat_booking_amount: 0,
    // });
  }
  function formatDueDateWithOutGMT(dueTime: string): string {
    const dueDateParts = dueTime.split(" ")
    return dueDateParts.slice(0, 4).join(" ")
  }
  const formatDateTotext = (dateString: string): string => {
    const [day, month, year] = dateString.split("-").map(Number)
    const date = new Date(year, month - 1, day) // Month in JavaScript starts from 0
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    }

    return new Intl.DateTimeFormat("en-US", options).format(date)
  }

  const fetchPaymentOptionDetails = async () => {
    const response = await getFeeAfterDiscountUseCase.invoke(auth)

    if (!response?.success) {
      return
    }
    setPaymentOptionDiscount(response?.data)
    const amount = response?.data?.fees_after_discount
    if (studentEMI.duration_index !== -1) {
      setEmiAmount(Math.ceil(amount + (amount * interestRate[studentEMI?.duration_index]) / 100))
    }
  }
  return {
    student,
    emiAmount,
    activePaymentButtonType,
    refreshed,
    paymentMethod,
    paymentOptionDiscount,
    payData,
    payDataAll,
    paymentLink,
    selectedEMIOption,
    openPopUp,
    paymentDocuments,
    studentEMI,
    calculateAmountPaid,
    setStudentEMI,
    getStudentEMI,
    createStudentEMI,
    uploadPaymentDocument,
    deletePaymentDocument,
    setPaymentDocuments,
    getPaymentDocuments,
    fetchPaymentMethod,
    studentDataForOneShot,
    isCompletelyPaid,
    showPayNow,
    disableInitiateNow,
    activeInstallment,
    handleGeneratePaylink,
    changeActivePaymentButtonType,
    CreatePaymentOneShot,
    handleDirectPayment,
    handleEMIPayment,
    getStudentPaymentDetails,
    getStudentPaymentDetailsAll,
    fetchPaymentOptionDetails,
    handleEMIOption,
    handlePopUp,
    setStudentDataForOneShot,
    formatDateTotext,
    formatDueDateWithOutGMT,
    setStudentTransactionTableData,
    studentTransactionTableData,
    calculateOneshotDiscount,
    setEmiInterest,
    emiInterest,
    toast,
    changeToastDetails,
    changeToastVisibility,
  }
}
