import { ContestApproveDataSource } from "data/dataSource/Admin/ContestApproveDataSource"
import { Auth } from "domain/model/Auth"
import { approveData, date, email } from "domain/model/ContestApprove"
import ContestApproveRepository from "domain/repository/Admin/ContestApproveRepository"

export class ContestApproveRepositoryImpl implements ContestApproveRepository {
  private dataSource: ContestApproveDataSource

  constructor(dataSource: ContestApproveDataSource) {
    this.dataSource = dataSource
  }

  getTop100ApproveRequests(auth: Auth) {
    return this.dataSource.getTop100ApproveRequests(auth)
  }
  approveToDisapprove(auth: Auth, data: approveData) {
    return this.dataSource.approveToDisapprove(auth, data)
  }
  getApprovalByEmail(auth: Auth, data: email) {
    return this.dataSource.getApprovalByEmail(auth, data)
  }
  getApprovalByDate(auth: Auth, data: date) {
    return this.dataSource.getApprovalByDate(auth, data)
  }
}
