import React from "react"
import { Select, SelectItem } from "core/components/v2/DropdownInput"
import { Button } from "core/components/v2/Button"
import { Headings } from "core/components/v2/Headings"

interface CardProps {
  field: any
  handleSelectField: any
  disabled: any
  cardData: any
  weekData: any
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

function Card({ field, handleSelectField, disabled, cardData, weekData }: CardProps) {
  return (
    <div className="w-[50%] rounded-[8px] border-[0.5px] px-3 py-4 border-[#D3D3D3] bg-[#FFF] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]">
      <Headings variant="h3" fontStyle="semiBold">
        {months[cardData.month - 1]}
      </Headings>

      <div className="py-5">
        <Select placeholder={field[0]} onChange={handleSelectField} defaultValue={field[0]}>
          {field.map((val: any) => (
            <SelectItem value={val}>{val}</SelectItem>
          ))}
        </Select>
      </div>

      <div className="grid grid-cols-3 gap-x-3 gap-y-2">
        {weekData?.map((week: any, index: any) => (
          <div
            key={index}
            className="rounded-[8px] border-[0.5px] px-3 pt-4 border-[#D3D3D3] bg-[#FFF] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]"
          >
            <div>
              <Headings variant="h2" fontStyle="medium">
                {week[cardData.data]}
              </Headings>
              <div>
                Week {week.week} . {months[cardData.month - 1]}
              </div>
              <div className="flex flex-col gap-2 py-4 pb-2">
                {disabled ? (
                  <Button variant="secondary" border="thin">
                    View
                  </Button>
                ) : (
                  <Button variant="ternary" border="thin">
                    View
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Card
