import { UpdateModuleClass } from "domain/model/ModuleClass"
import { ModuleClassRepository } from "domain/repository/Admin/ModuleClass"

export interface UpdateModuleClassUseCase {
  invoke(UpdateModuleClass: UpdateModuleClass): Promise<any>
}

export class UpdateModuleClasses implements UpdateModuleClassUseCase {
  private repository: ModuleClassRepository

  constructor(repository: ModuleClassRepository) {
    this.repository = repository
  }

  async invoke(UpdateModuleClass: UpdateModuleClass) {
    return await this.repository.updateModuleClass(UpdateModuleClass)
  }
}
