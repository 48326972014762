import React from "react"

const Line = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="327" height="476" viewBox="0 0 327 476" fill="none">
      <path
        d="M163.458 0H0.418701L163.458 476H326.497L163.458 0Z"
        fill="url(#paint0_linear_723_48718)"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_723_48718"
          x1="69.3035"
          y1="-61.4194"
          x2="195.981"
          y2="319.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.52" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Line
