import React from "react"

function Alert() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0932 13.1025L7.99227 2.53504C7.87786 2.3369 7.66644 2.21484 7.43764 2.21484C7.20884 2.21484 6.99742 2.3369 6.88301 2.53504L0.782093 13.1025C0.667689 13.3006 0.667688 13.5447 0.782089 13.7429C0.89649 13.9411 1.10791 14.0631 1.33672 14.0631H13.5386C13.7674 14.0631 13.9788 13.9411 14.0932 13.7429C14.2076 13.5447 14.2076 13.3006 14.0932 13.1025ZM12.4293 12.7823H2.44595L7.43762 4.13619L12.4293 12.7823ZM6.79713 10.8609H8.07803V12.1418H6.79713V10.8609ZM8.07803 6.37839H6.79713V9.58064H8.07803V6.37839Z"
          fill="#DC2626"
        />
      </svg>
    </>
  )
}

export default Alert
