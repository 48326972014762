import React from "react"
import Avatar from "react-avatar"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { GetLeaderboard } from "domain/useCase/Student/Leaderboard/GetLeaderboard"
import { LeaderboardAPIDataSource } from "data/API/Student/LeaderboardAPIDataSource"
import { LeaderboardRepositoryImpl } from "data/repository/Student/LeaderboardRepositoryImpl"
import { AwardIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { STR_FAILURE } from "core/constants/strings"
import { genError } from "core/utils/string"
import { getNumericFieldValue } from "core/utils/others"
import { TTableData } from "core/constants/types"
import { useApp } from "core/context/app"
import AwardEmpty from "assets/svgs/AwardEmpty"

export default function LeaderboardViewModel() {
  const { auth, refreshed } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { student } = useApp()

  const [leaderboardList, setLeaderboardList] = useLocalStorage<any>("leaderboard-list", [])
  const [leaderboardUser, setLeaderboardUser] = useLocalStorage<any>("leaderboard-user", [])
  const [tableData, setTableData] = React.useState<TTableData>({
    header: ["Rank", "Name", "", "Batch", "Last 7 days Problems Solved"],
  } as TTableData)

  const getLeaderboardUseCase = new GetLeaderboard(new LeaderboardRepositoryImpl(new LeaderboardAPIDataSource()))

  async function fetchLeaderboard() {
    const response = await getLeaderboardUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch leaderboard data"))
      changeToastVisibility(true)
      return
    }

    setLeaderboardList(response?.data?.leaderboard)
    if (response?.data?.cur_user?.user && response?.data?.cur_user?.rank != -1)
      setLeaderboardUser(response?.data?.cur_user)
  }

  async function changeTableData() {
    const rows = await Promise.all(
      leaderboardList.map(async (item: any, colIndex: number) => {
        const last_week_count = getNumericFieldValue(item, "last_week_count")
        const last_week_platform_count = getNumericFieldValue(item, "last_week_platform_count")
        // const latestContestScore =
        //   item.latest_contest_score === "N/A" || item.latest_contest_score === "Ab"
        //     ? item.latest_contest_score
        //     : getNumericFieldValue(item, "latest_contest_score")

        const total_score = getNumericFieldValue(item, "total_score")

        const total_last_week_progress = last_week_count + last_week_platform_count

        const isRanked = colIndex < 3

        const code = [
          isRanked ? (
            <h4 className="text-[12px] font-[400]">{String(colIndex + 1).padStart(2, "0")}</h4>
          ) : (
            <h4 className="text-[12px] font-[400]">{String(colIndex + 1).padStart(2, "0")}</h4>
          ),
          <div className="flex items-center gap-2">
            <Avatar
              className="shrink-0 rounded-[2px] object-cover"
              src={item?.profile_pic}
              name={item?.name}
              size="24"
            />
            {isRanked ? <h4>{item?.name}</h4> : item?.name}
          </div>,
          <div className="flex gap-[5px]">
            {isRanked ? (
              <>
                {[...Array(3 - colIndex)].map((_, index) => (
                  <AwardIcon key={`left-${index}`} className="h-4 w-4" />
                ))}
                {[...Array(colIndex)].map((_, index) => (
                  <AwardEmpty key={`right-${index}`} className="h-4 w-4" />
                ))}
              </>
            ) : (
              <>
                {[...Array(3)].map((_, index) => (
                  <AwardEmpty key={`right-${index}`} className="h-4 w-4" />
                ))}
              </>
            )}
          </div>,
          <p className="text-left text-[12px] font-[400]">{item?.batch}</p>,
          <p className="text-left text-[14px] font-[600]">{total_last_week_progress}</p>,
          // <p className="text-left text-[12px] font-[400]">{latestContestScore}</p>,
          // <p className="text-left text-[14px] font-[600]">{total_score}</p>,
        ]

        return { code, email: item?.email }
      })
    )

    const item = leaderboardUser?.user
    const colIndex = leaderboardUser?.rank
    const last_week_count = getNumericFieldValue(item, "last_week_count")
    const last_week_platform_count = getNumericFieldValue(item, "last_week_platform_count")
    // const latestContestScore =
    //   item?.latest_contest_score === "N/A" || item?.latest_contest_score === "Ab"
    //     ? item?.latest_contest_score
    //     : getNumericFieldValue(item, "latest_contest_score")

    const total_score = getNumericFieldValue(item, "total_score")

    const total_last_week_progress = last_week_count + last_week_platform_count

    const isRanked = colIndex < 3

    const leaderboardUserCode = [
      isRanked ? (
        <h4 className="text-[12px] font-[400]">{String(colIndex + 1).padStart(2, "0")}</h4>
      ) : (
        <h4 className="text-[12px] font-[400]">{String(colIndex + 1).padStart(2, "0")}</h4>
      ),
      <div className="flex items-center gap-2">
        <Avatar className="shrink-0 rounded-[2px] object-cover" src={item?.profile_pic} name={item?.name} size="24" />
        {isRanked ? <h4>{item?.name}</h4> : item?.name}
      </div>,
      <div className="flex gap-[5px]">
        {isRanked ? (
          <>
            {[...Array(3 - colIndex)].map((_, index) => (
              <AwardIcon key={`left-${index}`} className="h-4 w-4" />
            ))}
            {[...Array(colIndex)].map((_, index) => (
              <AwardEmpty key={`right-${index}`} className="h-4 w-4" />
            ))}
          </>
        ) : (
          <>
            {[...Array(3)].map((_, index) => (
              <AwardEmpty key={`right-${index}`} className="h-4 w-4" />
            ))}
          </>
        )}
      </div>,
      <p className="text-left text-[12px] font-[400]">{item?.batch}</p>,
      <p className="text-left text-[12px] font-[400]">{total_last_week_progress}</p>,
      // <p className="text-left text-[12px] font-[400]">{latestContestScore}</p>,
      <p className="text-left text-[14px] font-[600]">{total_score}</p>,
    ]

    const lbUser = { leaderboardUserCode, email: item?.email }

    setTableData((data: TTableData) => ({ ...data, rows, lbUser }))
  }

  return {
    toast,
    leaderboardList,
    tableData,
    refreshed,
    student,
    fetchLeaderboard,
    changeTableData,
  }
}
