import { API_V4_NEW_UPDATES, API_V4_STUDENT } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import NewUpdatesDataSource from "data/dataSource/Student/NewUpdatesDataSource"
import { NewUpdates } from "domain/model/NewUpdates"

export class NewUpdatesAPIDataSourceImpl implements NewUpdatesDataSource {
  async newUpdates(newUpdates: NewUpdates): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_NEW_UPDATES), newUpdates)
      return response
    } catch (error) {
      return error
    }
  }
}
