import { ReferringDetails } from "domain/model/ReferringDetails"
import { referCandidate } from "domain/model/ReferandEarn"
import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"
import { Auth } from "domain/model/Auth"

export interface postReferralFormDataUseCase {
  invoke(request: ReferringDetails, auth: Auth): Promise<any>
}

export class PostReferralFormData implements postReferralFormDataUseCase {
  private repository: ReferAndEarnRepository

  constructor(repository: ReferAndEarnRepository) {
    this.repository = repository
  }

  async invoke(request: ReferringDetails, auth: Auth): Promise<any> {
    return await this.repository.postReferralFormData(request, auth)
  }
}
