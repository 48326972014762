import DashboardLayout from "core/layouts/DashboardLayout"
import useMcqsViewModel from "./McqsViewModel"
import Input from "core/components/new/Input"
import { AddIcon } from "core/constants/svgs"
import McqCard from "./components/McqCard"
import React from "react"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import ConfirmationPopUp from "./components/ConfirmationPopUp"

export default function McqsView() {
  const {
    searchQuestion,
    mcqQuestions,
    loading,
    toast,
    changeToastVisibility,
    fetchMcqQuestions,
    redirectToMcq,
    deleteMcqQuestion,
    handleSearchQuestion,
    handleSearchQuestionChange,
    copyQuestionToClipboard,
  } = useMcqsViewModel()

  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [mcqQuestionId, setMcqQuestionId] = React.useState<any>(null)

  React.useEffect(() => {
    fetchMcqQuestions()
  }, [])

  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={mcqQuestionId}
          deleteMcqQuestion={deleteMcqQuestion}
          heading="Are you sure you want to delete this MCQ question?"
        />
      )}
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-1">
          <h2 className="text-new-accent">Multiple Choice Questions Chamber</h2>
          <p className="">You have access to all MCQ database.</p>
        </div>
        <button
          className="inline-flex w-full items-center justify-center gap-4 rounded-sm py-12 text-new-neutral shadow-[0px_0px_7px_0px_rgba(0,0,0,0.10)]"
          onClick={() => redirectToMcq("add")}
        >
          <AddIcon className="h-6 w-6" />
          <h3>Add More Questions</h3>
        </button>
        {loading ? (
          <Loader dark />
        ) : (
          <>
            <Input
              value={searchQuestion}
              onChange={handleSearchQuestionChange}
              id="searchQuestion"
              label="Question Name"
              type="text"
              placeholder="Type your keyword here!"
              containerClass="max-w-[400px]"
            />
            {mcqQuestions?.length > 0 &&
              mcqQuestions?.filter(handleSearchQuestion())?.map((mcq, i) => (
                <McqCard
                  key={i}
                  data={mcq}
                  copyQuestionToClipboard={copyQuestionToClipboard}
                  onEdit={() => redirectToMcq(mcq?.id)}
                  onDelete={() => {
                    setMcqQuestionId(mcq?.id)
                    setIsOpen(true)
                  }}
                />
              ))}
          </>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
