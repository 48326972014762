import { loadNextData } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface loadNextDataUseCase {
  invoke(details: loadNextData): Promise<any>
}

export class loadNext100Students implements loadNextDataUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: loadNextData): Promise<any> {
    return this.repository.loadNextData(details)
  }
}
