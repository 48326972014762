import { cn } from "core/lib/utils"
import { useState } from "react"

export default function StandardSelectWithoutPlaceholder({
  id,
  className,
  value,
  onChange,
  placeholder,
  options,
  required = false,
}: any) {
  const [isOpen, setIsOpen] = useState(true)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectOption = (option: string) => {
    onChange(option)
  }

  return (
    <div
      className={cn(
        "relative w-full h-[148px] rounded-[4px] overflow-y-auto scroll-p-0 scroll-m-0 scrollbar-hide",
        className
      )}
    >
      {isOpen && (
        <ul className={cn("relative z-10 w-full bg-white p-1 rounded-[4px] max-h-full ")}>
          {options?.map((option: string, i: number) => (
            <li
              key={i}
              className={`px-4 py-[9px] h-[35px] text-sm font-normal w-[95px] leading-4 cursor-pointer  rounded-[4px] font-inter",
                ${value === option ? "bg-[#001664] text-white" : "text-v2-gray-500 hover:bg-gray-100"}`}
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
