import { useRef, useState } from "react"
import { GetOnboardingTeamDetails } from "domain/useCase/Student/Home/GetOnboardingTeamDetails"
import { HomeRepositoryImpl } from "data/repository/Student/HomeRepositoryImpl"
import HomeAPIDataSourceImpl from "data/API/Student/HomeAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED } from "core/constants/strings"
import { GetMeetAndGreetDetails } from "domain/useCase/Student/Home/GetMeetAndGreetDetails"
import { GetPrerequisiteDetails } from "domain/useCase/Student/Home/GetPrerequisiteDetails"
import { GetRemainderDetails } from "domain/useCase/Student/Home/GetRemainderDetails"
import { GetMentorSessionRemainder } from "domain/useCase/Student/Home/GetMentorSessionRemainder"
import { GetContestRemainder } from "domain/useCase/Student/Home/GetContestRemainder"
import { GetRoadmapDetails } from "domain/useCase/Student/Home/GetRoadmapDetails"
import { GetProgressMeetDetails } from "domain/useCase/Student/Home/GetProgressMeetDetails"
import { useNavigate } from "react-router-dom"
import { GetPlacementCohortDetails } from "domain/useCase/Student/Home/GetPlacementCohortDetails"
import { GetOnboardingMeetingDetails } from "domain/useCase/Student/Home/GetOnboardingMeetingDetails"
import { useApp } from "core/context/app"
import NotificationAPIDataSourceImpl from "data/API/Student/NotificationAPIDataSourceImpl"
import { NotificationRepositoryImpl } from "data/repository/Student/NotificationRepository"
import { UpdateNotificationRead } from "domain/useCase/Student/Notification/UpdateNoticationRead"
import { PaymentAPIDataSourceImpl } from "data/API/Student/PaymentAPIDataSourceImpl"
import { PaymentRepositoryImpl } from "data/repository/Student/PaymentRepositoryImpl"
import { FetchScratchCardDetails } from "domain/useCase/Student/Payment/FetchScratchCardDetails"
import { isEmpty } from "core/utils/misc"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"
import { MarkAttendance } from "domain/useCase/Student/Course/MarkAttendance"
import delay from "core/utils/delay"

interface TeamMember {
  designation: string
  email: string
  isDisabled: boolean
  isLead: boolean
  linkedin: string
  name: string
  phone: string
  profile_pic: string
}

const NewViewModel = () => {
  const { auth, user, refreshed } = useAuth()
  const {
    notificationDetails,
    remainder,
    notificationToastDetails,
    student,
    setNotificationDetails,
    setNotificationBubble,
    setRemainder,
    setNotificationToastDetails,
  } = useApp()

  const navigate = useNavigate()

  const [selectedTag, setSelectedTag] = useState("")
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [onboardingTeam, setOnboardingTeam] = useState<TeamMember[]>([])
  const [viewOnboardingManager, setViewOnboardingManager] = useState(false)
  const [viewPrerequisite, setViewPrerequisite] = useState(false)
  const [viewMeetAndGreet, setViewMeetAndGreet] = useState(false)
  const [MeetAndGreetDetails, setMeetAndGreetDetails] = useState<any>([])
  const [prerequisiteDetails, setPrerequisiteDetails] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [roadmapDetails, setRoadmapDetails] = useState<any>([])
  const [scrollPosition, setScrollPosition] = useState(0)
  const [shortcutTabNotification, setShortcutTabNotification] = useState<any>()

  const roadmapRef = useRef<HTMLDivElement>(null)
  const isKCE = student?.batch?.includes("KCE") || false
  const isAccelerator = student?.batch?.includes("Accelerator") || false
  const isEV = student?.batch?.includes("EV") || false
  const isAC = student?.batch?.includes("AC") || false
  const isDSML = student?.batch?.includes("DSML") || false

  const [scratchCardDetails, setScratchCardDetails] = useState<any>({})

  const GetOnboardingTeamDetailsUsecase = new GetOnboardingTeamDetails(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )
  const GetMeetAndGreetDetailsUseCase = new GetMeetAndGreetDetails(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const GetPrerequisiteDetailsUseCase = new GetPrerequisiteDetails(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const GetRemainderDetailsUseCase = new GetRemainderDetails(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const GetMentorSessionRemainderUseCase = new GetMentorSessionRemainder(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )
  const GetContestRemainderUseCase = new GetContestRemainder(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const GetRoadmapDetailsUseCase = new GetRoadmapDetails(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const GetProgressMeetDetailsUseCase = new GetProgressMeetDetails(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const markAttendanceUseCase = new MarkAttendance(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const GetPlacementCohortDetailsUseCase = new GetPlacementCohortDetails(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )
  const GetOnboardingMeetingDetailsUseCase = new GetOnboardingMeetingDetails(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )
  const UpdateNotificationReadUseCase = new UpdateNotificationRead(
    new NotificationRepositoryImpl(new NotificationAPIDataSourceImpl())
  )
  const fetchScratchCardDetailsUseCase = new FetchScratchCardDetails(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )

  const colorCode: { [key: string]: string } = {
    "Onboarding Meet": "#FFCCCC",
    Assignment: "#CCFFD1",
    Class: "#FFF1CC",
    Test: "#C3F1EE",
    "Placement Cohort": "#C3C5F1",
    "Progress Meet": "#F1C3E7",
    Mentorship: "#E5F1C3",
  }

  const sectionButton = [
    "Onboarding Meet",
    "Class",
    "Assignment",
    "Mentorship",
    "Placement Cohort",
    "Exam",
    "Progress Meet",
  ]
  const shortcutTab = [
    !isAccelerator && "Class Schedule",
    "Detailed Curriculum",
    "Mentor Sessions",
    !isKCE && !isAccelerator && "Progress Meeting",
    !isAccelerator && "Exam Schedule",
    !isKCE && !isAccelerator && !isAC && "Placement Support",
    !isKCE && "Doubt Support",
  ]

  const notificationMapping: any = {
    "Class Schedule": "schedule",
    "Detailed Curriculum": "class",
    "Mentor Sessions": "mentor",
    "Progress Meeting": "progress_meet",
    "Exam Schedule": "contest",
    "Doubt Support": "",
  }

  const roadMap = [
    "Course Enrollment",
    "Onboarding Meeting",
    "Course Payment",
    "Batch Started",
    !isDSML && "Goal Setting Session",
    !isAccelerator && "Orientation",
    !isAccelerator && !isAC && "Placement Ready",
    !isAccelerator && !isAC && "Offer Received",
  ].filter(Boolean)

  const Onboarding: any = [
    ["Know your On-boarding team", "Click here to connect with your Onboarding Team.", "details", "Onboarding Team"],
    !isKCE && [
      "Onboarding Meeting",
      "Complete your onboarding meeting to understand various features of our portal.",
      "details",
      "Onboarding Meeting",
    ],
    !isKCE && [
      "Course Payment",
      roadmapDetails["Course Payment"]
        ? "Your Course fee payment has been completed"
        : "Complete your Course fee payment to Enjoy full access to all features.",
      roadmapDetails["Course Payment"] ? "details" : "payment",
      "Course Payment",
    ],
    !isKCE &&
      !isAccelerator && [
        "Join Bosscoder community",
        "Connect with your Onboarding Manager for Bosscoder Community access.",
        "",
        "BC Community",
      ],
    !(isKCE || isAccelerator || isEV) && [
      "Meet and Greet",
      "Click here to know more about Bosscoder Academy and our course features.",
      "details",
      "Meet and Greet",
    ],
    ["Prerequisite", "Click here to view Course Prerequisite content.", "details", "Course Prerequisite"],
    !isKCE &&
      !isAccelerator &&
      !isDSML && [
        "Goal Setting Session",
        "Book a goal-setting session with our expert mentor for better guidance",
        "book",
        "GS Session",
      ],
    !isAccelerator && [
      "Attend Orientation",
      "Attend the Course Orientation for a comprehensive understanding of the Course Curriculum.",
      "join",
      "Orientation",
    ],
  ].filter(Boolean)

  const handleSelectedTagBtnClick = (tag: string) => {
    // const idx:any = sectionButton.indexOf(tag)
    setSelectedTag(tag)
  }

  const handleOnboardingModalClose = () => {
    setViewOnboardingManager(false)
  }
  const handlePreRequisiteModalClose = () => {
    setViewPrerequisite(false)
  }
  const handleMeetAndGreetModalClose = () => {
    setViewMeetAndGreet(false)
  }

  const handleViewDetailsClick = (index: string) => {
    const step = index.split(" ")[1]
    switch (step) {
      case "2":
        navigate("/onboarding")
        // window.location.href = "/onboarding"
        // isKCE && setViewPrerequisite(true)
        break
      case "4":
        ;(isKCE || isAccelerator) && setViewPrerequisite(true)
        break
      case "3":
        if (isKCE) {
          navigate("/course")
          break
        }
        navigate("/transaction")
        break
      case "5":
        if (isAccelerator) {
          navigate("/course")
          break
        }
        if (isEV) {
          setViewPrerequisite(true)
          break
        }
        setViewMeetAndGreet(true)
        break
      case "6":
        if (isEV) {
          navigate("/mentor")
          break
        }
        setViewPrerequisite(true)
        break
      case "1":
        setViewOnboardingManager(true)
        break
      case "7":
        if (isEV || isDSML) {
          navigate("/course")
          break
        }
        navigate("/mentor")
        break
      case "8":
        navigate("/course")
        break
      default:
        break
    }
  }

  const handleScroll = (scrollOffset: number) => {
    if (roadmapRef.current) {
      const newScrollPosition = scrollPosition + scrollOffset
      roadmapRef.current.scrollLeft = newScrollPosition
      setScrollPosition(newScrollPosition)
    }
  }

  const fetchScratchCardDetails = async () => {
    const response = await fetchScratchCardDetailsUseCase.invoke(auth)
    if (!response?.success) {
      setLoading(false)
      return
    }
    setScratchCardDetails(response?.data)
    // setPaymentDiscount(response?.data)
  }

  const getOnboardingTeamDetails = async () => {
    const response = await GetOnboardingTeamDetailsUsecase.invoke(auth, {
      team: "onboarding",
    })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch courses"))
      changeToastVisibility(true)
      return
    }
    let data = Object.values(response.data)
    const onboardingManager = student?.onboardingManagerId

    let lead = data.filter((item: any) => item.isLead)
    let team = data.filter((item: any) => !item.isLead)

    lead.sort((a: any, b: any) => a.name.localeCompare(b.name))

    // Sort the team array so the person whose onboardingManagerId matches comes first, then alphabetically
    team.sort((a: any, b: any) => {
      if (a.email === onboardingManager) return -1
      if (b.email === onboardingManager) return 1
      return a.name.localeCompare(b.name)
    })

    // Replace the phone number of the rest of the team members with "+91 xxxxxxxxxxxx"
    team = team.map((item: any) => {
      if (item.email !== onboardingManager) {
        return { ...item, phone: "+91 xxxxxxxxxxxx" }
      }
      return item
    })
    const sortedData: any = [...lead, ...team]
    setOnboardingTeam(sortedData)
  }

  const fetchMeetAndGreetDetails = async () => {
    const response = await GetMeetAndGreetDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Meet and Greet"))
      changeToastVisibility(true)
      return
    }
    let data = response?.data
    data.sort((a: any, b: any) => a.serial - b.serial)
    if (isAC) {
      data = data.filter((item: any) => item.serial !== 5 && item.serial !== 9)
    }

    localStorage.setItem("meet_and_greet", JSON.stringify(data))
    setMeetAndGreetDetails(data)
  }

  const fetchPrerequisiteDetails = async () => {
    const response = await GetPrerequisiteDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Prerequisite"))
      changeToastVisibility(true)
      return
    }
    const data = response?.data
    data.sort((a: any, b: any) => a.serial - b.serial)
    localStorage.setItem("prerequisite", JSON.stringify(data))
    setPrerequisiteDetails(data)
  }

  const fetchClassRemainder = async (type: string) => {
    const response = await GetRemainderDetailsUseCase.invoke(auth, type)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch class details"))
      changeToastVisibility(true)
      return
    }
    return response?.data
  }

  const fetchMentorSessionRemainder = async () => {
    const response = await GetMentorSessionRemainderUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch mentor session details"))
      changeToastVisibility(true)
      return
    }
    return response?.data
  }
  const fetchContestRemainder = async () => {
    const response = await GetContestRemainderUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch contest details"))
      changeToastVisibility(true)
      return
    }
    return response?.data
  }

  const fetchProgressMeetDetails = async () => {
    const response = await GetProgressMeetDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Progress Meet"))
      changeToastVisibility(true)
      return
    }
    return response?.data
  }

  const fetchPlacementCohortDetails = async () => {
    const response = await GetPlacementCohortDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Placement Cohort"))
      changeToastVisibility(true)
      return
    }
    return response?.data
  }

  const fetchOnboardingMeetingDetails = async () => {
    const response = await GetOnboardingMeetingDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch details of Onboarding Meeting"))
      changeToastVisibility(true)
      return
    }
    return response?.data
  }

  const fetchRemainder = async () => {
    setLoading(true)
    const remainder: any = {}
    const sortedRemainder: any = {}

    const onboardingMeeting = await fetchOnboardingMeetingDetails()
    const expired = onboardingMeeting?.start_time + 45 * 60 < Math.floor(Date.now() / 1000)
    if (onboardingMeeting) {
      if (expired) {
        sortedRemainder["Onboarding Meet"] = {
          name: "Onboarding Meeting",
          ...onboardingMeeting,
          expired: true,
        }
      } else {
        sortedRemainder["Onboarding Meet"] = {
          ...onboardingMeeting,
          name: "Onboarding Meeting",
        }
      }
    }

    if (onboardingMeeting) {
      remainder["Onboarding Meet"] = sortedRemainder["Onboarding Meet"]
      setRemainder(sortedRemainder)
    } else {
      setRemainder({})
    }

    const upcomingClass = await fetchClassRemainder("class")
    remainder["Class"] = upcomingClass
    const assignment = await fetchClassRemainder("assignment")
    remainder["Assignment"] = assignment
    const mentorSession = await fetchMentorSessionRemainder()
    remainder["Mentorship"] = mentorSession
    const contest = await fetchContestRemainder()
    remainder["Exam"] = contest
    const progressMeet = await fetchProgressMeetDetails()
    remainder["Progress Meet"] = progressMeet
    const placementCohort = await fetchPlacementCohortDetails()
    remainder["Placement Cohort"] = placementCohort
    // const onboardingMeeting = await fetchOnboardingMeetingDetails()

    // const sortedRemainder: any = {}

    sectionButton.forEach((button) => {
      if (remainder[button] && Object.keys(remainder[button]).length > 0) {
        sortedRemainder[button] = remainder[button]
      }
    })

    // const expired = remainder["Onboarding Meet"]?.start_time + 45 * 60 < Math.floor(Date.now() / 1000)
    // if (sortedRemainder["Onboarding Meet"]) {
    //   if (expired) {
    //     sortedRemainder["Onboarding Meet"] = {
    //       name: "Onboarding Meeting",
    //     }
    //   } else {
    //     sortedRemainder["Onboarding Meet"] = {
    //       ...sortedRemainder["Onboarding Meet"],
    //       name: "Onboarding Meeting",
    //     }
    //   }
    // }

    setRemainder(sortedRemainder)
    if (Object.keys(sortedRemainder)[0] === "Onboarding Meet" && expired)
      setSelectedTag(Object.keys(sortedRemainder)[1])
    else setSelectedTag(Object.keys(sortedRemainder)[0])
    setLoading(false)
  }

  const fetchRoadmapDetails = async () => {
    const response = await GetRoadmapDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch"))
      changeToastVisibility(true)
      return
    }
    setRoadmapDetails(response?.data)
  }

  const navigateTo = (type: string) => {
    const typeToPathMap: { [key: string]: string } = {
      "Class Schedule": "schedule",
      "Detailed Curriculum": "course",
      "Mentor Sessions": isKCE ? "doubt-support" : "mentor",
      "Progress Meeting": "progress-meeting",
      "Exam Schedule": "weekly-test-series",
      "Placement Support": "placement",
      "Doubt Support": "doubt-support",
    }

    const typeToKeyMap: { [key: string]: string } = {
      "Class Schedule": "schedule",
      "Detailed Curriculum": "class",
      "Mentor Sessions": isKCE ? "doubt-support" : "mentor",
      "Progress Meeting": "progress_meet",
      "Exam Schedule": "contest",
      "Placement Support": "placement",
    }

    const path = typeToPathMap[type]
    const key = typeToKeyMap[type]

    if (!path && !key) {
      return
    }

    const index = notificationDetails.findIndex((item: any) => item.type === key)

    if (index === -1 || notificationDetails[index].isRead) {
      navigate(`/${path}`)
      return
    }

    notificationDetails[index].isRead = true
    const reqObj = { ...notificationDetails[index] }
    delete reqObj.type
    UpdateNotificationReadUseCase.invoke(auth, { [key]: reqObj })

    const isAllRead = notificationDetails.every((notification: any) => notification.isRead)
    setNotificationBubble(!isAllRead)
    setNotificationDetails([...notificationDetails])
    setTimeout(() => navigate(`/${path}`), 5000)
  }

  const handleRemainderCardClick = async (tag: string, id?: string, link?: string) => {
    if (tag === "Class") {
      if (isEmpty(id) || isEmpty(link)) {
        changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
        changeToastVisibility(true)
        return
      }
      window.open(link)

      const response = await markAttendanceUseCase.invoke(auth, id as string)

      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response, "Failed to mark attendance"))
        changeToastVisibility(true)

        await delay(2000)
        changeToastDetails(STR_FAILURE, "Refreshing...")
        await delay(2000)

        navigate(0)
        return
      }
    }
    if (tag === "Assignment") {
      navigate(`/course/?tab=Past Classes`)
    }
    if (tag === "Mentorship") {
      navigate(`/mentor/${id}`)
    }
    if (tag === "Exam" || tag === "Placement Cohort") {
      navigate(`/weekly-test-series`)
    }
    if (tag === "Progress Meet") {
      navigate(`/progress-meeting`)
    }
    if (tag === "Onboarding Meet" || tag === "Onboarding") {
      const room_id = id

      if (isEmpty(room_id) || isEmpty(link)) {
        changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
        changeToastVisibility(true)
        return
      }

      window.open(link)
    }
  }

  return {
    auth,
    sectionButton,
    selectedTag,
    shortcutTab,
    Onboarding,
    onboardingTeam,
    viewOnboardingManager,
    viewPrerequisite,
    viewMeetAndGreet,
    prerequisiteDetails,
    MeetAndGreetDetails,
    remainder,
    roadMap,
    roadmapRef,
    roadmapDetails,
    scrollPosition,
    shortcutTabNotification,
    notificationMapping,
    loading,
    notificationDetails,
    colorCode,
    notificationToastDetails,
    scratchCardDetails,
    refreshed,
    user,
    fetchScratchCardDetails,
    setNotificationToastDetails,
    navigateTo,
    setShortcutTabNotification,
    handleScroll,
    setSelectedTag,
    handleRemainderCardClick,
    handleViewDetailsClick,
    fetchMeetAndGreetDetails,
    fetchRemainder,
    fetchRoadmapDetails,
    fetchPrerequisiteDetails,
    handleMeetAndGreetModalClose,
    handleSelectedTagBtnClick,
    getOnboardingTeamDetails,
    handleOnboardingModalClose,
    handlePreRequisiteModalClose,
  }
}

export default NewViewModel
