import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import EligibilityCandidatesTable from "./components/EligibilityCandidatesTable"
import viewEligibleCandidatesViewModel from "./EligibleCandidateViewModel"

export default function EligibleCandidateView() {
  const {
    toast,
    loading,
    jobApplications,
    jobStatus,
    selectJobStates,
    changeToastVisibility,
    onChangeJobStatus,
    getJobApplications,
    setJobApplicationStatus,
  } = viewEligibleCandidatesViewModel()
  const tableHeaders = ["Student Name", "Email", "Status", "Update Status"]

  React.useEffect(() => {
    const job_id = window.location.pathname.split("/")[4]
    getJobApplications(job_id)
  }, [])

  return (
    <DashboardLayout isLoading={loading}>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="p-6">
        <div className="mb-8">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Eligible Candidates</h1>
            <p className="font-medium">Get list of all eligible candidates for job</p>
          </div>
        </div>
        {jobApplications?.length > 0 ? (
          <EligibilityCandidatesTable
            tableHeaders={tableHeaders}
            jobApplications={jobApplications}
            jobStatus={jobStatus}
            selectJobStates={selectJobStates}
            onChangeJobStatus={onChangeJobStatus}
            setJobApplicationStatus={setJobApplicationStatus}
          />
        ) : (
          <div className="flex items-center justify-center text-[18px] font-medium text-[#DC2626]">
            No one Apply for this job yet!
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}
