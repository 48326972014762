import { API_V4_ADMIN, API_V4_DELETE_SSM_CALL } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { DeleteSSMCall } from "domain/model/DeleteSSMCall"
import DeleteSSMCallDataSource from "data/dataSource/Admin/DeleteSSMCallDataSource"
import { RequestQuote } from "@mui/icons-material"

export class DeleteSSMCallAPIDataSourceImpl implements DeleteSSMCallDataSource {
  async deleteSSMCall(deleteSSMCall: DeleteSSMCall): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_DELETE_SSM_CALL), deleteSSMCall)
      return response
    } catch (error) {
      return error
    }
  }
}
