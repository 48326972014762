import { Auth } from "domain/model/Auth"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface GetMentorRatingUseCase {
  invoke(auth: Auth, mentor_id: string): Promise<any>
}

export default class GetMentorRating implements GetMentorRatingUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string) {
    return await this.repository.getMentorRating(auth, mentor_id)
  }
}
