import { Auth } from "domain/model/Auth"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface DeleteMentorImageUsecase {
  invoke(auth: Auth, imageUrl: string): Promise<any>
}

export class DeleteMentorImage implements DeleteMentorImageUsecase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, imageUrl: string): Promise<any> {
    return await this.repository.deleteMentorImage(auth, imageUrl)
  }
}
