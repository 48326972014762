import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface getContestStudentList {
  invoke(
    auth: Auth,
    contest_id: string,
    // batch: string,
    page: number,
    pageLimit: number
  ): Promise<any>
}

export default class GetContestStudentList implements getContestStudentList {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    contest_id: string,
    // batch: string,
    page: number,
    pageLimit: number
  ) {
    return await this.repository.getContestStudentList(
      auth,
      contest_id,
      // batch,
      page,
      pageLimit
    )
  }
}
