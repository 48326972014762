import { Auth } from "domain/model/Auth"
import { Company } from "domain/model/Questions"
import CompanyRepository from "domain/repository/Admin/CompanyRepository"

export interface CreateCompanyUseCase {
  invoke(company: Company): Promise<any>
}

export class CreateCompany implements CreateCompanyUseCase {
  private repository: CompanyRepository

  constructor(repository: CompanyRepository) {
    this.repository = repository
  }

  async invoke(company: Company) {
    return await this.repository.createCompany(company)
  }
}
