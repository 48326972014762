"use client"

import * as React from "react"
import { Check, ChevronsDown, ChevronsUpDown } from "lucide-react"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./Command"
import { Popover, PopoverContent, PopoverTrigger } from "./PopOver"
import { cn } from "core/lib/utils"
import { Button } from "core/components/v2/Button"
import ChevronDown from "assets/svgs/ChevronDown"

const frameworks = [
  {
    value: "next.js",
    label: "Next.js",
  },
  {
    value: "sveltekit",
    label: "SvelteKit",
  },
  {
    value: "nuxt.js",
    label: "Nuxt.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
]

export default function Combobox({ className, defaultOption, options, Initialvalue, value, setValue }: any) {
  const [open, setOpen] = React.useState(false)
  // const [value, setValue] = React.useState(Initialvalue)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="default"
          role="combobox"
          // aria-expanded={open}
          className={cn("h-[40px] w-[301px] border-[1px] rounded-[4px] justify-between text-sm font-normal", className)}
        >
          {value ? options.find((option: any) => option.value === value)?.label : defaultOption}
          <ChevronDown className="mr-2 h-4 w-4 shrink-0 opacity-100" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("w-[301px] p-0 m-0 h-[164px] justify-start bg-white")}>
        <Command className="m-0">
          <div className=" bg-white border-0 text-v2-gray-500 hover:bg-v2-gray-200 focus:bg-transparent focus:text-inherit outline-auto focus:border-[#D3D3D3] focus:ring-0">
            <CommandInput className="border-0 focus:ring-0 px-0" placeholder="Search" />
          </div>
          <CommandList className="overflow-y-auto scroll-p-0 scroll-m-0 scrollbar-hide">
            <CommandEmpty>No framework found.</CommandEmpty>
            <CommandGroup>
              {options.map((framework: any) => (
                <CommandItem
                  key={framework.value}
                  value={framework.value}
                  className="w-[292px] px-4 py-[9px] hover:border-v2-white border-[#D3D3D3] bg-v2-white text-v2-gray-500 hover:bg-v2-gray-200 focus:bg-transparent focus:text-inherit outline-auto focus:border-[#D3D3D3] focus:ring-0"
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue)
                    setOpen(false)
                  }}
                >
                  {framework.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
