import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { useState, useEffect } from "react"

export default function QuestionView({
  questions,
  question,
  isPast,
  response,
  activeResposeTab,
  activeQuestionIndex,
  handleQuestionProblemChange,
  isFetchingQuestion,
}: any) {
  const [showSolution, setShowSolution] = useState<boolean>(false)

  function openUrl(url: any) {
    if (url) {
      window.open(url, "_blank")
    } else {
      console.error("URL is not provided:", url)
    }
  }
  const [currentResponse, setCurrentResponse] = useState<any>({})
  useEffect(() => {
    const sortedResponse = response?.sort((a: any, b: any) => a.order - b.order)
    if (sortedResponse) {
      setCurrentResponse(sortedResponse[activeResposeTab])
    }
  }, [activeResposeTab, response])

  const handleToggleSolution = () => {
    setShowSolution((prev) => !prev)
  }

  return (
    <div className="flex flex-1 flex-col overflow-y-auto text-white gap-4">
      {isEmpty(questions) ? (
        <></>
      ) : (
        <div className="flex gap-4">
          {questions?.map((_: any, i: number) => (
            <button
              key={i}
              className={cn(
                "flex w-[56px] items-center justify-center rounded-sm border bg-new-editor-dark-500 px-3 py-2",
                false
                  ? "border-new-success text-new-success"
                  : activeQuestionIndex === i
                    ? "border-new-neutral-light text-new-editor-dark-50"
                    : "border-transparent text-new-editor-dark-50"
              )}
              onClick={() => handleQuestionProblemChange(i)}
            >
              <h4>{i + 1}</h4>
            </button>
          ))}
        </div>
      )}
      <ConditionalLoader isLoading={isFetchingQuestion} loader={<Loader />}>
        <div className="flex flex-col gap-2">
          <h2>{question?.questionTitle}</h2>
          <div className="flex gap-4">
            <p>
              <strong>Topic: </strong> {question?.topic}
            </p>
            <p>
              <strong>Difficulty: </strong>
              <span
                className={
                  question?.difficultyLevel === "easy"
                    ? "text-success"
                    : question?.difficultyLevel === "medium"
                      ? "text-orange-400"
                      : "text-failure"
                }
              >
                {question?.difficultyLevel?.toUpperCase()}
              </span>
            </p>
            <p>
              <strong>Max Marks: </strong> {questions?.[activeQuestionIndex]?.score}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 border-t border-new-neutral-light pt-4">
          <p>
            <strong className="text-lg">Problem Statement:</strong>
          </p>
          <div className="flex flex-col gap-4">
            {question?.fields?.map((field: any, index: number) => {
              if (field.type === "text") {
                return (
                  <div
                    key={index}
                    className="whitespace-pre-line break-words text-justify"
                    dangerouslySetInnerHTML={{ __html: field?.content }}
                  />
                )
              } else if (field.type === "image") {
                return (
                  <img
                    src={field?.content}
                    alt="img"
                    title={field?.filename}
                    onClick={() => openUrl(field?.content)}
                    className="cursor-pointer rounded-sm"
                  />
                )
              } else if (field.type === "document") {
                return (
                  <div
                    title="Click to open the document"
                    className="p-2 text-pretty text-sm cursor-pointer text-blue-500 bg-new-editor-dark-600 rounded-sm"
                    onClick={() => openUrl(field?.content)}
                  >
                    {"📄" + field?.filename}
                  </div>
                )
              } else if (field.type === "code") {
                return (
                  <pre className="p-2 bg-new-editor-dark-600 rounded-sm" key={index}>
                    {field.content}
                  </pre>
                )
              } else {
                return <></>
              }
            })}
          </div>
        </div>
        {isPast && (
          <div className="flex flex-col gap-4 border-t border-new-neutral-light pt-4">
            <button onClick={handleToggleSolution} className="bg-blue-500 text-white p-2 rounded-sm hover:bg-blue-600">
              {showSolution ? "Hide Solution" : "Show Solution"}
            </button>
            {showSolution && question.questionType === "Subjective" && (
              <div className="p-4 bg-new-editor-dark-500 rounded-sm">
                <pre className="whitespace-pre-wrap break-words text-justify">{question.solution}</pre>
              </div>
            )}
            {showSolution && question.questionType === "Case Study" && isPast && currentResponse?.solution && (
              <div className="mt-4">
                <h4>Solution:</h4>
                <pre className="whitespace-pre-wrap break-words text-justify">
                  {typeof currentResponse.solution === "object"
                    ? Object.values(currentResponse.solution).join(", ")
                    : currentResponse.solution}
                </pre>
              </div>
            )}
          </div>
        )}
      </ConditionalLoader>
    </div>
  )
}
