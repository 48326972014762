import { Auth } from "domain/model/Auth"
import ReferandEarnRepository from "domain/repository/Admin/ReferandEarnRepository"

export interface updateReferandEarnUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}
export class UpdateReferandEarnRequests implements updateReferandEarnUseCase {
  private repository: ReferandEarnRepository

  constructor(repository: ReferandEarnRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.updateReferandEarnRequests(auth, data)
  }
}
