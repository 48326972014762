import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface GetMentorSessionDetailsUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetMentorSessionDetails implements GetMentorSessionDetailsUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.getMentorSessionDetails(auth, data)
  }
}
