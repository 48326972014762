import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface GetStudentEMIUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetStudentEMI implements GetStudentEMIUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getStudentEMI(auth)
  }
}
