import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface GetFutureBatchesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetFutureBatches implements GetFutureBatchesUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getFutureBatches(auth)
  }
}
