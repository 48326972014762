import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface SubmitMcqQuestionUseCase {
  invoke(auth: Auth, contest_id: string, mcq_question_key: string, solution: string): Promise<any>
}

export default class SubmitMcqQuestion implements SubmitMcqQuestionUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contest_id: string, mcq_question_key: string, solution: string) {
    return this.repository.submitMcqQuestion(auth, contest_id, mcq_question_key, solution)
  }
}
