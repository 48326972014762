import { Page, Text, View, Document, StyleSheet, Link } from "@react-pdf/renderer"
import React from "react"

export default function ResumeTemplate({ data }: any) {
  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      height: "100%",
      fontFamily: "Times-Roman",
      padding: 24,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    headerAlignRight: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    h1: {
      fontSize: 16,
      fontFamily: "Times-Bold",
    },
    link: {
      color: "black",
      textDecoration: "none",
      fontSize: 10,
    },
    text: {
      fontSize: 10,
    },
    singleColumn: {
      display: "flex",
      flexDirection: "column",
      gap: 12,
    },
    mainTitle: {
      fontSize: 14,
      lineHeight: 0.9,
    },
    mainTitle2: {
      fontSize: 10,
      lineHeight: 0.9,
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
    },
    line: {
      width: "100%",
      height: 1,
      backgroundColor: "black",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
    },
    contents: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    projects: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
    },
    contentHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 8,
    },
    contentColumn1: {
      display: "flex",
      flexDirection: "column",
      gap: 3,
      flex: 1,
    },
    contentColumn2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      alignSelf: "flex-start",
    },
    contentTitle: {
      fontSize: 12,
      fontFamily: "Times-Bold",
    },
    contentSubtitle: {
      fontSize: 10,
      fontFamily: "Times-Italic",
    },
    contentListBullet: {
      marginRight: 2,
      fontSize: 16,
    },
    contentMain: {
      fontSize: 10,
      display: "flex",
      flexDirection: "column",
    },
    contentMainListBullet: {
      marginLeft: 24,
      marginRight: 2,
      fontSize: 16,
    },
    contentMainListBullet2: {
      marginLeft: 2,
      marginRight: 2,
      fontSize: 16,
    },
    contentMainText: {
      flex: 1,
    },
    bold: {
      fontFamily: "Times-Bold",
    },
    contentMainItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 8,
    },
  })

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.singleColumn}>
          <View style={styles.header}>
            <View>
              <Text style={styles.h1}>{data.name || "Your name"}</Text>
              <Link src={data.linkedin} style={styles.link}>
                LinkedIn
              </Link>
            </View>
            <View style={styles.headerAlignRight}>
              <Link src={`mailto:${data.email || "your@mail.com"}`} style={styles.link}>
                Email : {data.email || "your@email.com"}
              </Link>
              <Text style={styles.text}>Mobile : {data.mobile || "9988776655"}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.titleWrapper}>
              <Text style={styles.mainTitle}>E</Text>
              <Text style={styles.mainTitle2}>DUCATION</Text>
            </View>
            <View style={styles.line}></View>
            <View style={styles.contents}>
              {data.educations.map((item: any, index: any) => (
                <View key={index} style={styles.content}>
                  <View style={styles.contentHeader}>
                    <Text style={styles.contentListBullet}>•</Text>
                    <View style={styles.contentColumn1}>
                      <Text style={styles.contentTitle}>{item.institution}</Text>
                      <Text style={styles.contentSubtitle}>
                        {item.degree}
                        {item.gpa && `; GPA: ${item.gpa}`}
                      </Text>
                    </View>
                    <View style={styles.contentColumn2}>
                      <Text style={styles.text}>{item.place}</Text>
                      <Text style={styles.contentSubtitle}>
                        {item.yearStart} - {item.yearEnd}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.titleWrapper}>
              <Text style={styles.mainTitle}>E</Text>
              <Text style={styles.mainTitle2}>XPERIENCE</Text>
            </View>
            <View style={styles.line}></View>
            <View style={styles.contents}>
              {data.experiences.map((item: any, index: any) => (
                <View key={index} style={styles.content}>
                  <View style={styles.contentHeader}>
                    <Text style={styles.contentListBullet}>•</Text>
                    <View style={styles.contentColumn1}>
                      <Text style={styles.contentTitle}>{item.company}</Text>
                      <Text style={styles.contentSubtitle}>{item.designation}</Text>
                    </View>
                    <View style={styles.contentColumn2}>
                      <Text style={styles.text}>{item.place}</Text>
                      <Text style={styles.contentSubtitle}>
                        {item.start} - {item.end}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.contentMain}>
                    {item.description.split("\n").map((item: any, index: any) => (
                      <View key={index} style={styles.contentMainItem}>
                        <Text style={styles.contentMainListBullet}>•</Text>
                        <Text style={styles.contentMainText}>{item}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.titleWrapper}>
              <Text style={styles.mainTitle}>P</Text>
              <Text style={styles.mainTitle2}>ROJECTS</Text>
            </View>
            <View style={styles.line}></View>
            <View style={styles.projects}>
              {data.projects.map((item: any, index: any) => (
                <View key={index} style={styles.content}>
                  <View style={styles.contentMain}>
                    <View style={styles.contentMainItem}>
                      <Text style={styles.contentMainListBullet2}>•</Text>
                      {(item.title || item.description) && (
                        <Text style={styles.contentMainText}>
                          <Text style={styles.bold}>{item.title}</Text>:{item.description}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.titleWrapper}>
              <Text style={styles.mainTitle}>P</Text>
              <Text style={styles.mainTitle2}>
                ROGRAMMING <Text style={styles.mainTitle}>S</Text>KILLS
              </Text>
            </View>
            <View style={styles.line}></View>
            <View style={styles.projects}>
              <View style={styles.content}>
                <View style={styles.contentMain}>
                  <View style={styles.contentMainItem}>
                    <Text style={styles.contentMainListBullet2}>•</Text>
                    <Text style={styles.contentMainText}>
                      <Text style={styles.bold}>Languages</Text>: {data.skills.languages.join(", ")}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.content}>
                <View style={styles.contentMain}>
                  <View style={styles.contentMainItem}>
                    <Text style={styles.contentMainListBullet2}>•</Text>
                    <Text style={styles.contentMainText}>
                      <Text style={styles.bold}>Technologies</Text>: {data.skills.technologies.join(", ")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
