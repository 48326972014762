import RedirectArrow from "assets/svgs/HomePage/RedirectArrow"
import { format } from "date-fns"
import React from "react"

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return `${day}th`
  switch (day % 10) {
    case 1:
      return `${day}st`
    case 2:
      return `${day}nd`
    case 3:
      return `${day}rd`
    default:
      return `${day}th`
  }
}

const formatCustomDate = (date: Date): string => {
  const day = format(date, "d")
  const ordinalDay = getOrdinalSuffix(parseInt(day))
  const monthYear = format(date, "MMMM, yyyy")
  const time = format(date, "h:mm a")
  return `${ordinalDay} ${monthYear} | ${time}`
}

const NotificationCard: any = ({ data, onClick, index }: any) => {
  const notificationList: { [key: string]: string } = {
    class: "Class",
    contest: "Exam",
    progress_meet: "Progress Meeting",
    schedule: "Class Schedule",
    mentor: "Mentor",
    leaderboard: "Leaderboard",
  }

  const notificationDescription = (data: any) => {
    switch (data?.type) {
      case "class":
        if (data?.isCancelled) {
          return `The upcoming session ${data?.name} has been canceled`
        }
        // ${data?.name}
        return `Timing for the upcoming session has been updated. Click here to view details`
      case "contest":
        if (data?.isCancelled) {
          return `The upcoming contest ${data.name} has been canceled`
        }
        return `An Upcoming Exam has been scheduled for your batch on ${formatCustomDate(new Date(data.session_timestamp * 1000))}. Click here to view details`
      case "schedule":
        return `Your class schedule has been updated. Click here to view details`
      case "progress_meet":
        return `Your progress meeting has been scheduled. Click here to view details`
      case "mentor":
        if (data?.isCancelled) {
          return `Your mentor has cancelled your upcoming mentor session scheduled on ${formatCustomDate(new Date(data.session_timestamp * 1000))}`
        }
        return `Your mentor session has been scheduled with ${data?.name}. Click here to view details`
      case "leaderboard":
        return `Congratulations ${data.name}! ${data.difference === null || data.difference <= 0 ? "Keep trying to achieve milestones in the leaderboard" : `You have moved ${data.difference} places up on the Bosscoder Leaderboard.`}`

      default:
        return ""
    }
  }

  return (
    <div
      className={`flex flex-col gap-2 rounded p-2 ${data?.isCancelled ? "bg-red-100" : !data?.isRead ? "bg-[#EDFFF2] hover:bg-[#d2f1db]" : "bg-[#f9f9f9]"}`}
    >
      <h3 className="text-xs font-semibold">{notificationList[data?.type]}</h3>
      <p className="text-xs font-normal text-[#646464]">{notificationDescription(data)}</p>
      <div className="flex justify-end">
        <div className="group h-7 w-7 cursor-pointer rounded bg-white p-1.5" onClick={() => onClick(index)}>
          <div className="duration-300 group-hover:-rotate-90">
            <RedirectArrow />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationCard
