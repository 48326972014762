export default function MainLogoBlack(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 129 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3628 7.57819L21.4102 6.40625L22.3628 7.57819Z" fill="#333333" />
      <path
        d="M22.3632 7.57657C21.7703 7.57768 21.185 7.71101 20.6488 7.96708C20.1126 8.22315 19.6387 8.59568 19.2609 9.05817L18.7589 9.67451L18.2745 10.2672C18.246 10.2926 18.2196 10.3203 18.1955 10.3501L16.4775 12.4718C16.4166 12.5464 16.3401 12.6064 16.2536 12.6477C16.1671 12.6889 16.0727 12.7103 15.9771 12.7103H12.3186C12.1978 12.7101 12.0795 12.6752 11.9775 12.6096C11.8755 12.544 11.794 12.4504 11.7425 12.3397C11.691 12.229 11.6716 12.1058 11.6865 11.9844C11.7015 11.863 11.7502 11.7484 11.827 11.6539L16.0927 6.40463L17.597 4.55412C17.733 4.38648 17.9029 4.25028 18.0952 4.1547C18.2876 4.05912 18.4979 4.00637 18.7121 4H18.7984C19.0124 4.00648 19.2225 4.05929 19.4146 4.15487C19.6066 4.25045 19.7763 4.38659 19.9121 4.55412L20.0408 4.71265L21.4164 6.40463L22.3632 7.57657Z"
        fill="#333333"
      />
      <path
        d="M25.186 12.7103H21.5144C21.4188 12.7103 21.3244 12.6889 21.2379 12.6477C21.1514 12.6064 21.0749 12.5464 21.014 12.4718L19.2974 10.362C19.2365 10.287 19.1614 10.2251 19.0763 10.1802C18.9913 10.1352 18.8983 10.108 18.8027 10.1003C18.7071 10.0926 18.6109 10.1044 18.5199 10.1351C18.4289 10.1658 18.345 10.2147 18.2731 10.279C18.2446 10.3044 18.2181 10.3322 18.1941 10.362L16.4775 12.4718C16.4166 12.5464 16.3401 12.6064 16.2536 12.6477C16.1671 12.6889 16.0727 12.7103 15.9771 12.7103H12.3186C12.1978 12.7101 12.0795 12.6752 11.9775 12.6096C11.8755 12.544 11.794 12.4504 11.7425 12.3397C11.691 12.229 11.6716 12.1058 11.6865 11.9844C11.7015 11.863 11.7502 11.7484 11.827 11.6539L16.0927 6.40463L17.597 4.55412C17.733 4.38648 17.9029 4.25028 18.0952 4.1547C18.2876 4.05912 18.4979 4.00637 18.7121 4H18.7984C19.0124 4.00648 19.2225 4.05929 19.4146 4.15487C19.6066 4.25045 19.7763 4.38659 19.9121 4.55412L20.0408 4.71265L21.4164 6.40463L22.369 7.57657L25.6762 11.6539C25.7529 11.7483 25.8016 11.8628 25.8166 11.9841C25.8316 12.1053 25.8123 12.2284 25.761 12.339C25.7097 12.4497 25.6284 12.5433 25.5266 12.609C25.4248 12.6747 25.3067 12.7098 25.186 12.7103Z"
        fill="#333333"
      />
      <path
        d="M32.7262 16.2593L29.6926 26.0778C29.6098 26.345 29.4449 26.5784 29.222 26.7441C28.999 26.9099 28.7297 26.9993 28.4531 26.9993H24.565C24.2759 26.999 23.9986 26.8829 23.7938 26.6764C24.1043 26.6762 24.4066 26.5754 24.6565 26.3888C24.9063 26.2022 25.0907 25.9395 25.1825 25.6392L25.4152 24.8881L25.5264 24.5295L26.1688 22.4479C26.7685 20.5072 28.0258 18.8434 29.7189 17.7497L31.1457 16.8282C31.174 16.81 31.1973 16.7848 31.2134 16.755C31.2296 16.7252 31.238 16.6918 31.2379 16.6578C31.2387 16.6226 31.2304 16.5879 31.2137 16.557C31.1971 16.5261 31.1728 16.5001 31.1432 16.4817C31.1136 16.4633 31.0798 16.453 31.045 16.4519C31.0103 16.4509 30.9759 16.459 30.9452 16.4756L27.1712 18.5054C26.8232 18.692 26.4354 18.7897 26.0415 18.7898H23.6167C23.3852 18.7898 23.1579 18.7268 22.9587 18.6075C22.7594 18.4881 22.5955 18.3167 22.4841 18.1112L21.4158 16.1511C21.3809 16.087 21.3632 16.0148 21.3643 15.9417C21.3654 15.8685 21.3854 15.7969 21.4221 15.7339C21.4589 15.6709 21.5113 15.6188 21.5741 15.5825C21.6369 15.5463 21.7079 15.5273 21.7802 15.5273H32.1906C32.2779 15.5278 32.3639 15.5488 32.4418 15.5888C32.5197 15.6287 32.5873 15.6865 32.6393 15.7576C32.6913 15.8286 32.7262 15.9109 32.7413 15.998C32.7564 16.0851 32.7512 16.1745 32.7262 16.2593Z"
        fill="#333333"
      />
      <path
        d="M16.0871 16.1522L15.0189 18.1227C14.9073 18.3281 14.7434 18.4994 14.5442 18.6187C14.3449 18.7381 14.1177 18.8011 13.8862 18.8013H11.46C11.0661 18.8013 10.6782 18.7037 10.3303 18.5168L6.55478 16.487C6.50875 16.4635 6.45555 16.4589 6.40623 16.474C6.3569 16.4892 6.31525 16.5231 6.28991 16.5685C6.27268 16.5992 6.26361 16.6339 6.26357 16.6693C6.26508 16.7026 6.27468 16.735 6.29152 16.7637C6.30837 16.7924 6.33194 16.8165 6.36015 16.8337L8.07083 17.939C9.57864 18.9152 10.6983 20.3982 11.2332 22.1275L11.9766 24.5351L12.0878 24.8936L12.319 25.6448C12.412 25.9443 12.5967 26.2062 12.8463 26.3926C13.0959 26.579 13.3975 26.6803 13.7077 26.6819C13.5047 26.8855 13.231 27.0004 12.9453 27.0019H9.04982C8.77306 27.0017 8.50359 26.9121 8.28065 26.7461C8.05771 26.5801 7.89294 26.3463 7.81035 26.0789L4.77679 16.2603C4.74978 16.1751 4.74307 16.0846 4.75722 15.9962C4.77137 15.9078 4.80598 15.8241 4.85822 15.7518C4.91045 15.6796 4.97883 15.6209 5.05777 15.5805C5.1367 15.5401 5.22395 15.5192 5.31238 15.5195H15.7257C15.799 15.5191 15.8711 15.5383 15.9347 15.5752C15.9983 15.6121 16.0511 15.6654 16.0878 15.7296C16.1245 15.7939 16.1438 15.8668 16.1437 15.941C16.1436 16.0152 16.1241 16.0881 16.0871 16.1522Z"
        fill="#333333"
      />
      <path
        d="M21.1021 20.6535C20.2926 20.6532 19.5147 20.972 18.9334 21.5425L18.7534 21.7188L17.852 22.6078L15.9891 24.4361L14.3794 26.011L13.7019 26.6762C13.3917 26.6747 13.0901 26.5734 12.8404 26.387C12.5908 26.2006 12.4062 25.9387 12.3131 25.6391L12.0878 24.8909L11.9766 24.5324C12.133 24.528 12.2873 24.4943 12.4317 24.4331C12.5759 24.3714 12.7074 24.283 12.8195 24.1723L14.1365 22.8804L16.6037 20.458C16.883 20.184 17.2568 20.0309 17.6456 20.0313H20.0485C20.3212 20.0296 20.5838 20.1359 20.7802 20.3276L21.1021 20.6535Z"
        fill="#333333"
      />
      <path
        d="M25.5303 24.5324L25.419 24.8909L25.1864 25.6421C25.0945 25.9424 24.9102 26.205 24.6603 26.3917C24.4104 26.5783 24.1081 26.679 23.7976 26.6792L23.1216 26.014L21.5119 24.439L19.6578 22.6181C19.4146 22.3812 19.0902 22.2488 18.7527 22.2488C18.4152 22.2488 18.0908 22.3812 17.8476 22.6181L15.9891 24.4361L14.3794 26.011L13.7019 26.6762C13.3917 26.6747 13.0901 26.5734 12.8404 26.387C12.5908 26.2006 12.4062 25.9387 12.3131 25.6391L12.0878 24.8909L11.9766 24.5324C12.133 24.528 12.2873 24.4943 12.4317 24.4331C12.5759 24.3714 12.7074 24.283 12.8195 24.1723L14.1365 22.8804L16.6037 20.458C16.883 20.184 17.2568 20.0309 17.6456 20.0313H20.0485C20.3212 20.0296 20.5838 20.1359 20.7802 20.3276L21.1124 20.6535L23.3776 22.8759L24.6947 24.1679C24.8062 24.2792 24.9379 24.3677 25.0825 24.4287C25.2268 24.4899 25.3811 24.5235 25.5376 24.5279L25.5303 24.5324Z"
        fill="#333333"
      />
      <path
        d="M50.2401 10.007C50.0152 9.69062 49.703 9.44406 49.3404 9.29636C49.2735 9.26687 49.2049 9.24127 49.1349 9.2197C49.4292 9.07021 49.6804 8.85013 49.8652 8.57985C50.0716 8.26378 50.1761 7.8938 50.1651 7.51835C50.1718 7.23238 50.1069 6.94917 49.9762 6.69363C49.8455 6.43809 49.6529 6.21806 49.4153 6.05289C48.9155 5.68136 48.1683 5.49609 47.1736 5.49707H43.3154V13.2726H47.4045C48.4442 13.2726 49.2314 13.0853 49.7662 12.7108C50.0232 12.5389 50.2315 12.3055 50.3716 12.0327C50.5117 11.7598 50.5789 11.4564 50.567 11.151C50.5811 10.7456 50.4669 10.3459 50.2401 10.007ZM45.1328 6.85196H46.9532C47.3078 6.83126 47.6614 6.90782 47.9743 7.07311C48.092 7.14564 48.1877 7.24798 48.2512 7.36938C48.3148 7.49078 48.344 7.62677 48.3357 7.76308C48.3444 7.8992 48.316 8.03513 48.2536 8.15691C48.1911 8.27869 48.0968 8.38193 47.9803 8.456C47.7434 8.60344 47.403 8.68452 46.9592 8.68452H45.1328V6.85196ZM48.3537 11.6847C48.1048 11.8322 47.7539 11.9177 47.2801 11.9177H45.1328V10.0011H47.2861C47.7539 10.0011 48.1108 10.0792 48.3597 10.2355C48.4839 10.3137 48.5842 10.4235 48.65 10.5534C48.7157 10.6832 48.7444 10.8281 48.7331 10.9726C48.7396 11.1137 48.708 11.254 48.6413 11.3791C48.5747 11.5042 48.4754 11.6097 48.3537 11.6847Z"
        fill="#333333"
      />
      <path
        d="M60.6381 7.77424C60.4282 7.29186 60.1194 6.85723 59.731 6.4975C59.3342 6.13111 58.8673 5.84596 58.3574 5.65862C57.8068 5.45757 57.2233 5.35763 56.636 5.36375C56.0475 5.35549 55.4627 5.45549 54.9116 5.65862C54.4018 5.85016 53.9351 6.13817 53.5381 6.50634C53.1502 6.86516 52.841 7.29813 52.6294 7.77867C52.4094 8.28593 52.2996 8.83278 52.307 9.38419C52.3008 9.93358 52.4105 10.4783 52.6294 10.9838C52.8403 11.4647 53.1496 11.8978 53.5381 12.2561C53.9371 12.6232 54.4052 12.9102 54.9161 13.1009C56.0275 13.496 57.2445 13.496 58.3559 13.1009C58.8654 12.9173 59.3327 12.6361 59.731 12.2738C60.1211 11.9129 60.4306 11.4761 60.6396 10.9912C60.8582 10.4835 60.9679 9.93693 60.962 9.38566C60.9685 8.83229 60.8582 8.28361 60.6381 7.77424ZM58.9212 10.3911C58.8009 10.6855 58.6225 10.9536 58.3964 11.1799C58.1728 11.404 57.9056 11.5814 57.6107 11.7018C57.301 11.8264 56.9691 11.8885 56.6345 11.8846C56.2976 11.8882 55.9632 11.8261 55.6509 11.7018C55.355 11.5833 55.0874 11.4055 54.8651 11.1799C54.6423 10.9521 54.4662 10.6843 54.3463 10.3911C54.2179 10.0712 54.1542 9.72962 54.1589 9.38566C54.1541 9.04169 54.2178 8.70013 54.3463 8.38018C54.4662 8.08698 54.6423 7.8192 54.8651 7.59143C55.0874 7.3658 55.355 7.18807 55.6509 7.06953C55.9632 6.94526 56.2976 6.88313 56.6345 6.88671C56.9691 6.8828 57.301 6.94497 57.6107 7.06953C57.9056 7.18987 58.1728 7.36737 58.3964 7.59143C58.6225 7.81776 58.8009 8.08582 58.9212 8.38018C59.0498 8.70013 59.1134 9.04169 59.1087 9.38566C59.1133 9.72962 59.0497 10.0712 58.9212 10.3911Z"
        fill="#333333"
      />
      <path
        d="M68.8867 9.98993C68.7235 9.7255 68.497 9.50433 68.227 9.34565C67.9451 9.18276 67.6459 9.05076 67.3348 8.95201C67.0094 8.84881 66.6825 8.76183 66.3511 8.69106C66.0197 8.6203 65.7183 8.551 65.4514 8.48024C65.2152 8.42586 64.9924 8.32561 64.7961 8.18538C64.7161 8.12835 64.6514 8.05307 64.6075 7.96601C64.5637 7.87895 64.5419 7.78272 64.5442 7.68559C64.5429 7.52588 64.5957 7.37027 64.6942 7.24329C64.8194 7.09402 64.9861 6.98387 65.174 6.92632C65.4557 6.83946 65.7502 6.80008 66.0452 6.80985C66.4116 6.81164 66.7762 6.86122 67.1293 6.95728C67.5132 7.05927 67.8817 7.21045 68.2255 7.40694L68.8013 6.05206C68.4076 5.81674 67.9784 5.64454 67.5297 5.54195C67.0476 5.4239 66.5526 5.36399 66.0557 5.36356C65.4198 5.34379 64.7868 5.4558 64.1978 5.69233C63.7512 5.87259 63.3681 6.17755 63.0957 6.56954C62.8549 6.93076 62.7281 7.35394 62.7314 7.78584C62.7181 8.13974 62.802 8.49061 62.9743 8.80164C63.1349 9.06939 63.3606 9.29379 63.631 9.45475C63.9127 9.6246 64.2149 9.75883 64.5307 9.85429C64.8576 9.95258 65.183 10.0376 65.5069 10.1093C65.8308 10.1811 66.1307 10.2553 66.4066 10.332C66.6478 10.3919 66.8759 10.4943 67.0799 10.6342C67.1593 10.6905 67.2236 10.7651 67.267 10.8514C67.3104 10.9378 67.3316 11.0333 67.3288 11.1296C67.3309 11.2862 67.2749 11.4381 67.1713 11.5571C67.0373 11.697 66.8674 11.7989 66.6795 11.852C66.3956 11.9354 66.0999 11.9732 65.8038 11.964C65.3199 11.9636 64.8393 11.8859 64.3808 11.734C63.9522 11.5998 63.5475 11.4008 63.1812 11.1443L62.5664 12.5066C62.9906 12.8065 63.4674 13.0269 63.9729 13.1567C64.5661 13.3244 65.1806 13.4083 65.7978 13.4059C66.4361 13.4262 67.0715 13.3126 67.6617 13.0727C68.1099 12.8903 68.4959 12.586 68.7743 12.1955C69.0166 11.8408 69.1446 11.4226 69.1417 10.9954C69.1545 10.6433 69.0662 10.2949 68.8867 9.98993Z"
        fill="#333333"
      />
      <path
        d="M76.942 9.99042C76.7782 9.72648 76.5518 9.50543 76.2823 9.34615C75.9981 9.18259 75.6964 9.05056 75.3826 8.95251C75.0587 8.84931 74.7313 8.76233 74.4004 8.69156C74.069 8.62079 73.7676 8.5515 73.5007 8.48073C73.2645 8.42636 73.0417 8.32611 72.8454 8.18587C72.7663 8.12828 72.7026 8.05277 72.6595 7.96576C72.6164 7.87875 72.5953 7.78281 72.598 7.68608C72.5968 7.52639 72.6495 7.37082 72.748 7.24379C72.8732 7.09452 73.0399 6.98436 73.2278 6.92682C73.5094 6.83985 73.804 6.80046 74.099 6.81035C74.4654 6.81214 74.83 6.86171 75.1831 6.95778C75.567 7.05976 75.9355 7.21095 76.2793 7.40744L76.8551 6.05255C76.4615 5.81672 76.0323 5.64403 75.5835 5.54097C75.1013 5.42292 74.6064 5.36301 74.1095 5.36258C73.4736 5.34281 72.8406 5.45483 72.2516 5.69135C71.805 5.87162 71.4218 6.17658 71.1495 6.56856C70.9097 6.93011 70.784 7.35327 70.7881 7.78486C70.7749 8.13876 70.8588 8.48964 71.031 8.80066C71.1919 9.06913 71.4181 9.29407 71.6893 9.45525C71.9709 9.62509 72.2732 9.75933 72.589 9.85479C72.9159 9.95307 73.2413 10.0381 73.5652 10.1098C73.8891 10.1816 74.189 10.2558 74.4649 10.3325C74.7013 10.3914 74.9252 10.4913 75.1261 10.6273C75.2056 10.6836 75.2699 10.7582 75.3133 10.8446C75.3567 10.9309 75.3779 11.0264 75.3751 11.1227C75.3773 11.2796 75.3207 11.4318 75.2161 11.5502C75.0829 11.6905 74.9134 11.7924 74.7258 11.8451C74.4419 11.9285 74.1462 11.9663 73.8501 11.9571C73.3698 11.9561 72.8929 11.8789 72.4376 11.7286C72.0089 11.5947 71.6042 11.3957 71.238 11.1389L70.6172 12.4938C71.0417 12.7989 71.5202 13.0238 72.0282 13.1572C72.6214 13.3249 73.2359 13.4088 73.8531 13.4064C74.4914 13.4267 75.1268 13.3131 75.7169 13.0732C76.1641 12.8903 76.5489 12.5861 76.8266 12.196C77.0689 11.8413 77.1969 11.4231 77.1939 10.9959C77.207 10.6442 77.1197 10.2959 76.942 9.99042Z"
        fill="#333333"
      />
      <path
        d="M84.2741 11.6458C83.934 11.8062 83.5611 11.8879 83.184 11.8846C82.8363 11.8887 82.491 11.8266 82.1673 11.7018C81.8669 11.5848 81.5939 11.4087 81.3651 11.1844C81.1371 10.9601 80.9584 10.6921 80.8402 10.3971C80.7121 10.075 80.6484 9.73155 80.6528 9.3857C80.6484 9.03985 80.7121 8.69643 80.8402 8.37433C80.958 8.07864 81.1367 7.81009 81.365 7.58555C81.5934 7.36101 81.8666 7.18534 82.1673 7.06957C82.491 6.94481 82.8363 6.88273 83.184 6.88675C83.5605 6.88297 83.9331 6.96258 84.2741 7.11969C84.6209 7.28284 84.9278 7.5177 85.1738 7.80819L86.3599 6.74227C85.9865 6.29019 85.5054 5.93571 84.9593 5.71026C83.8184 5.26256 82.55 5.24262 81.3951 5.65423C80.8868 5.84127 80.4218 6.12651 80.0275 6.49311C79.6429 6.85449 79.3368 7.28883 79.1278 7.76986C78.9095 8.27965 78.8003 8.82818 78.8069 9.38128C78.8001 9.93392 78.9093 10.482 79.1278 10.9912C79.3384 11.4766 79.6473 11.9146 80.0359 12.2787C80.4244 12.6427 80.8843 12.925 81.3876 13.1083C81.9312 13.3082 82.5076 13.4082 83.088 13.4032C83.7315 13.4129 84.3701 13.2919 84.9638 13.0479C85.5091 12.8211 85.9899 12.4668 86.3644 12.0159L85.1783 10.9499C84.9301 11.2419 84.6219 11.479 84.2741 11.6458Z"
        fill="#333333"
      />
      <path
        d="M96.0917 7.77426C95.8814 7.29174 95.5721 6.8571 95.1831 6.49751C94.7858 6.13175 94.319 5.84667 93.8095 5.65863C93.2584 5.45741 92.6744 5.35746 92.0866 5.36377C91.4986 5.35537 90.9142 5.45539 90.3637 5.65863C89.8544 5.85035 89.3883 6.13835 88.9917 6.50636C88.603 6.86484 88.2932 7.29787 88.0815 7.77868C87.8621 8.28612 87.7523 8.83285 87.7591 9.3842C87.7536 9.93352 87.8633 10.4781 88.0815 10.9838C88.2924 11.4651 88.6023 11.8983 88.9917 12.2561C89.3905 12.6236 89.8586 12.9106 90.3697 13.1009C91.4811 13.4961 92.6981 13.4961 93.8095 13.1009C94.319 12.9129 94.7858 12.6278 95.1831 12.262C95.5732 11.9011 95.8827 11.4643 96.0917 10.9794C96.3096 10.4715 96.4193 9.92508 96.4141 9.37388C96.4187 8.82464 96.309 8.28029 96.0917 7.77426ZM94.3703 10.3911C94.2494 10.6856 94.0706 10.9536 93.844 11.1799C93.6209 11.4039 93.3542 11.5814 93.0598 11.7018C92.7495 11.8262 92.4171 11.8884 92.0821 11.8846C91.7456 11.8882 91.4118 11.8261 91.0999 11.7018C90.8068 11.5824 90.5418 11.4047 90.3217 11.1799C90.0984 10.9522 89.9218 10.6844 89.8014 10.3911C89.6735 10.0711 89.6103 9.72953 89.6155 9.38567C89.6102 9.0418 89.6734 8.70024 89.8014 8.3802C89.9218 8.08691 90.0984 7.81913 90.3217 7.59144C90.544 7.36581 90.8116 7.18808 91.1074 7.06954C91.4193 6.94524 91.7531 6.8831 92.0896 6.88673C92.4246 6.88298 92.757 6.94514 93.0673 7.06954C93.3617 7.18992 93.6284 7.36742 93.8515 7.59144C94.0754 7.81837 94.2517 8.08637 94.3703 8.3802C94.4984 8.70024 94.5615 9.0418 94.5563 9.38567C94.5614 9.72953 94.4982 10.0711 94.3703 10.3911Z"
        fill="#333333"
      />
      <path
        d="M106.04 7.33565C105.667 6.75072 105.135 6.28107 104.503 5.98076C103.795 5.64655 103.017 5.48089 102.233 5.49719H98.6338V13.2727H102.233C103.017 13.289 103.795 13.1233 104.503 12.7891C105.134 12.4884 105.667 12.0188 106.04 11.4342C106.419 10.8167 106.611 10.1059 106.593 9.38493C106.611 8.66398 106.419 7.95302 106.04 7.33565ZM104.417 10.6676C104.209 11.0322 103.896 11.3282 103.518 11.5183C103.091 11.7231 102.62 11.8242 102.146 11.8131H100.481V6.96265H102.143C102.617 6.95152 103.088 7.05265 103.515 7.25751C103.891 7.44507 104.204 7.73662 104.414 8.09639C104.637 8.48984 104.748 8.9348 104.737 9.38493C104.748 9.83274 104.637 10.2754 104.417 10.6676Z"
        fill="#333333"
      />
      <path
        d="M114.941 11.8277V13.2711H108.817V5.49707H114.794V6.94042H110.638V8.61817H114.309V10.0276H110.638V11.8277H114.941Z"
        fill="#333333"
      />
      <path
        d="M122.488 10.773H122.5C122.984 10.5778 123.397 10.2451 123.686 9.81769C123.974 9.36744 124.119 8.84375 124.105 8.31243C124.118 7.78312 123.972 7.26173 123.686 6.81306C123.397 6.38169 122.984 6.04378 122.5 5.84149C121.921 5.60221 121.297 5.48662 120.669 5.5024H117.246V13.2735H119.087V11.0959H120.749L122.284 13.2735H124.25L122.488 10.773ZM121.833 9.30758C121.547 9.54445 121.125 9.66289 120.567 9.66289H119.087V6.96491H120.567C121.125 6.96491 121.547 7.07991 121.833 7.31285C121.977 7.43544 122.09 7.58893 122.165 7.76148C122.239 7.93403 122.271 8.12102 122.26 8.308C122.271 8.49562 122.239 8.68323 122.165 8.85648C122.09 9.02973 121.977 9.18403 121.833 9.30758Z"
        fill="#333333"
      />
      <path
        d="M42.2832 26.9556L45.4846 19.8789H47.134L50.3654 26.9556H48.614L45.9689 20.7016H46.6377L43.9866 26.9556H42.2832ZM43.8997 25.437L44.321 24.1927H48.0472L48.4851 25.437H43.8997Z"
        fill="#333333"
      />
      <path
        d="M54.8733 27.0364C54.3215 27.0466 53.7756 26.9231 53.2838 26.6766C52.9471 26.4968 52.6508 26.252 52.4125 25.9569C52.1743 25.6618 51.999 25.3225 51.8972 24.9592C51.7955 24.596 51.7693 24.2163 51.8202 23.8429C51.8712 23.4694 51.9982 23.11 52.1937 22.7859C52.46 22.365 52.8365 22.0223 53.2838 21.7937C53.7751 21.5442 54.3219 21.419 54.8748 21.4296C55.4073 21.417 55.9338 21.541 56.4028 21.7893C56.8247 22.0131 57.1568 22.3713 57.3445 22.8051L56.1104 23.4744C55.9828 23.2426 55.7943 23.0486 55.5646 22.9127C55.351 22.7942 55.1096 22.7327 54.8643 22.7343C54.6094 22.7318 54.3581 22.7932 54.1341 22.9127C53.9133 23.0307 53.7309 23.2075 53.6077 23.4228C53.4712 23.671 53.4039 23.9502 53.4128 24.2322C53.4041 24.5161 53.4713 24.7972 53.6077 25.0475C53.7298 25.2652 53.9124 25.4441 54.1341 25.5635C54.3582 25.6825 54.6096 25.7434 54.8643 25.7404C55.1094 25.7426 55.3509 25.6816 55.5646 25.5635C55.7963 25.4255 55.9851 25.2277 56.1104 24.9915L57.3445 25.6594C57.1536 26.0904 56.8223 26.4467 56.4028 26.6722C55.9343 26.9236 55.4068 27.0492 54.8733 27.0364Z"
        fill="#333333"
      />
      <path
        d="M61.2313 27.036C60.8524 27.0458 60.4761 26.9713 60.1307 26.8178C59.8414 26.6894 59.5935 26.4854 59.4139 26.2281C59.2462 25.9791 59.1585 25.6863 59.162 25.3877C59.151 25.0759 59.2435 24.769 59.4256 24.5134C59.6076 24.2578 59.8693 24.0674 60.1712 23.9709C60.6145 23.8211 61.0822 23.7526 61.5507 23.7689H63.0127V24.6786H61.7156C61.3378 24.6786 61.0784 24.7405 60.9374 24.8614C60.8706 24.9176 60.8172 24.9875 60.7809 25.0662C60.7446 25.1449 60.7264 25.2305 60.7275 25.317C60.7242 25.4104 60.7445 25.5033 60.7867 25.5871C60.8288 25.671 60.8914 25.7433 60.9689 25.7976C61.1617 25.9244 61.391 25.9865 61.6227 25.9745C61.88 25.9788 62.134 25.9163 62.3589 25.7932C62.5734 25.668 62.7335 25.4696 62.8088 25.2359L63.0562 25.973C62.9464 26.3016 62.7195 26.5802 62.4174 26.7573C62.0555 26.9554 61.6452 27.0518 61.2313 27.036ZM62.9197 26.9549V25.8919L62.8163 25.659V23.7557C62.8239 23.6096 62.8 23.4637 62.7459 23.3274C62.6919 23.1912 62.6091 23.0678 62.5029 22.9654C62.2929 22.7767 61.972 22.6824 61.5402 22.6824C61.2447 22.6824 60.9512 22.7287 60.6705 22.8195C60.4056 22.9004 60.1566 23.0251 59.9342 23.1881L59.3689 22.0853C59.6985 21.8571 60.0681 21.6907 60.4591 21.5943C60.8823 21.4838 61.3183 21.4278 61.7561 21.4277C62.5998 21.4277 63.2551 21.6243 63.7219 22.0175C64.1888 22.4106 64.4222 23.0215 64.4222 23.85V26.9549H62.9197Z"
        fill="#333333"
      />
      <path
        d="M69.5413 27.0355C69.0479 27.0427 68.5613 26.9222 68.1303 26.6861C67.7155 26.4568 67.3735 26.119 67.1421 25.7101C66.9068 25.2531 66.7842 24.7481 66.7842 24.2358C66.7842 23.7236 66.9068 23.2185 67.1421 22.7615C67.3731 22.3524 67.7152 22.0145 68.1303 21.7855C68.5613 21.5495 69.0479 21.429 69.5413 21.4361C69.97 21.427 70.3933 21.5319 70.7664 21.7398C71.1287 21.9557 71.413 22.278 71.5791 22.6613C71.7881 23.161 71.8862 23.6988 71.867 24.2388C71.8843 24.7782 71.79 25.3154 71.5896 25.8178C71.4327 26.2032 71.1527 26.5281 70.7919 26.7436C70.4091 26.9492 69.9772 27.05 69.5413 27.0355ZM69.8082 25.7396C70.0602 25.7436 70.3089 25.6826 70.5295 25.5627C70.7462 25.4404 70.9231 25.2599 71.0393 25.0423C71.1707 24.7919 71.2362 24.5131 71.2297 24.2314C71.2378 23.95 71.1723 23.6713 71.0393 23.422C70.921 23.2086 70.7445 23.0319 70.5295 22.9119C70.309 22.7915 70.0603 22.73 69.8082 22.7335C69.56 22.7307 69.3153 22.7923 69.0989 22.9119C68.8837 23.0315 68.707 23.2083 68.5891 23.422C68.4552 23.671 68.3896 23.9499 68.3987 24.2314C68.3914 24.5132 68.457 24.7922 68.5891 25.0423C68.7049 25.2602 68.8818 25.4408 69.0989 25.5627C69.3155 25.6819 69.5601 25.7429 69.8082 25.7396ZM71.2777 26.9544V25.8413L71.3092 24.2196L71.2057 22.6097V19.4443H72.8117V26.9544H71.2777Z"
        fill="#333333"
      />
      <path
        d="M78.2802 27.0359C77.7055 27.0489 77.1362 26.9239 76.6217 26.6717C76.163 26.4464 75.779 26.0972 75.5148 25.6651C75.2505 25.233 75.117 24.7358 75.1297 24.2318C75.1213 23.7252 75.2513 23.2258 75.5061 22.7855C75.7533 22.3625 76.1172 22.0169 76.5558 21.7888C77.0225 21.5446 77.5446 21.4209 78.0732 21.4291C78.584 21.4218 79.0886 21.5399 79.5412 21.7726C79.9701 21.9972 80.328 22.3333 80.5759 22.7442C80.8402 23.2034 80.9719 23.7249 80.9567 24.2524C80.9567 24.3128 80.9568 24.3821 80.9463 24.4603C80.9358 24.5384 80.9328 24.6077 80.9253 24.677H76.4268V23.7555H80.081L79.4528 24.0283C79.4656 23.7727 79.4092 23.5183 79.2893 23.2911C79.177 23.0867 79.0094 22.9168 78.805 22.8002C78.5851 22.6787 78.3359 22.6176 78.0837 22.6233C77.8302 22.6182 77.5797 22.6792 77.358 22.8002C77.1507 22.9166 76.9813 23.0884 76.8691 23.2956C76.7479 23.5289 76.6881 23.7885 76.6952 24.0504V24.2937C76.6877 24.5703 76.7589 24.8434 76.9006 25.0824C77.0347 25.3032 77.2311 25.4809 77.4659 25.594C77.7365 25.7182 78.0327 25.7788 78.3311 25.7709C78.5927 25.7754 78.8528 25.7308 79.0974 25.6397C79.3258 25.5481 79.5341 25.4139 79.7107 25.2446L80.5654 26.1557C80.3028 26.4443 79.9745 26.6676 79.6072 26.8074C79.1838 26.9665 78.7333 27.044 78.2802 27.0359Z"
        fill="#333333"
      />
      <path
        d="M90.3645 21.4292C90.7694 21.4211 91.1702 21.5097 91.5326 21.6872C91.8755 21.8621 92.1544 22.1378 92.3303 22.476C92.5378 22.9002 92.6367 23.3679 92.6182 23.8382V26.9549H91.0123V24.08C91.0123 23.6426 90.9218 23.3188 90.7409 23.1084C90.6449 23.0031 90.5261 22.9203 90.3932 22.8662C90.2603 22.8121 90.1168 22.7881 89.9731 22.7959C89.7504 22.7913 89.5304 22.8453 89.3359 22.9522C89.1472 23.0595 88.9977 23.2225 88.9085 23.4181C88.7985 23.6722 88.7473 23.9471 88.7586 24.223V26.9549H87.1526V24.08C87.1526 23.6377 87.0626 23.3178 86.8797 23.1084C86.7807 23.0017 86.6585 22.9182 86.5223 22.8641C86.386 22.81 86.2391 22.7867 86.0925 22.7959C85.8726 22.7922 85.6558 22.8462 85.4642 22.9522C85.2756 23.061 85.1251 23.2234 85.0323 23.4181C84.9208 23.6717 84.8695 23.947 84.8824 24.223V26.9549H83.2734V21.5103H84.8074V22.9846L84.5195 22.56C84.6924 22.2007 84.977 21.9049 85.3322 21.7152C85.7 21.518 86.1129 21.4165 86.5318 21.4204C87.0068 21.4112 87.4735 21.5433 87.8709 21.7993C88.2684 22.0734 88.5416 22.4891 88.6326 22.9581L88.0658 22.8106C88.2501 22.3942 88.5593 22.0429 88.952 21.8037C89.3761 21.5471 89.8667 21.417 90.3645 21.4292Z"
        fill="#333333"
      />
      <path
        d="M95.9141 29.0002C95.6274 28.9994 95.3425 28.9546 95.0699 28.8675C94.8143 28.7939 94.5765 28.67 94.3711 28.5034L94.9589 27.3696C95.0816 27.4846 95.2264 27.5743 95.3847 27.6335C95.5411 27.6932 95.7073 27.7242 95.8751 27.7249C96.0647 27.7338 96.2517 27.6781 96.4044 27.5672C96.5659 27.4241 96.6911 27.2459 96.7703 27.0468L97.0582 26.3789L97.1811 26.2064L99.1785 21.5107H100.712L98.2098 27.2944C98.0707 27.6722 97.8629 28.0219 97.5965 28.3265C97.389 28.5582 97.1277 28.7373 96.8348 28.8484C96.5394 28.9521 96.2277 29.0035 95.9141 29.0002ZM96.8602 27.1677L94.4011 21.5107H96.0505L97.9759 26.0811L96.8602 27.1677Z"
        fill="#333333"
      />
    </svg>
  )
}
