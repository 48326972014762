import { STR_ADD, STR_SUCCESS, STR_FAILURE } from "core/constants/strings"
import Toast from "core/components/Toast"
import React from "react"
import useDocumentationEditViewModel from "../DocumentationFileAddViewModel"
import ConfirmationPopUp from "./ConfirmationPopUp"
import Button from "core/components/new/Button"

export default function Card({ data, onEdit }: any) {
  const { deleteDocumentationFile, toast, changeToastVisibility, changeToastDetails } = useDocumentationEditViewModel()
  const [isOpen, setIsOpen] = React.useState<any>(false)

  const copyLinkToClipboard = () => {
    if (data?.link && typeof data.link === "string") {
      navigator.clipboard.writeText(data.link).catch((err) => {
        console.error("Failed to copy link to clipboard:", err)
      })
    }
    changeToastDetails(STR_SUCCESS, "Link copied to clipboard")
    changeToastVisibility(true)
  }
  return (
    <div className="flex justify-between gap-6 p-6 shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
      {isOpen && (
        <ConfirmationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={data.id}
          deleteDocumentationFile={deleteDocumentationFile}
          heading="Are you sure you want to remove the file?"
        />
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />

      <div className="flex flex-col">
        <h3 className="text-new-accent">{data?.name}</h3>
        <span className="flex items-center">
          <p className="text-green-400 font-bold">{`${data?.feature} `} &nbsp;</p> |{" "}
          <p className="text-blue-700 font-semibold"> &nbsp; {data?.subFeature}</p>
        </span>
      </div>

      <div className="flex justify-between gap-6 ">
        <Button outlined className="mr-2 h-fit w-fit" onClick={copyLinkToClipboard}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 43 43" id="copy">
            <path fill="none" d="M0 0h48v48H0z"></path>
            <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z"></path>
          </svg>
        </Button>

        <Button outlined className="mr-2 h-fit w-fit" onClick={onEdit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-edit"
          >
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
          </svg>
        </Button>

        <Button
          outlined
          className="h-fit w-fit border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
          onClick={async () => {
            setIsOpen(true)
          }}
        >
          <svg
            className="w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            ></path>
          </svg>
        </Button>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
