import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface getStudentCommentUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}

export class GetStudentComment implements getStudentCommentUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string): Promise<any> {
    return await this.repository.getStudentComment(auth, email)
  }
}
