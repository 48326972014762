import { Auth } from "domain/model/Auth"
import { BatchRepository } from "domain/repository/Admin/BatchRepository"

export interface GetBatchDetailsUseCase {
  invoke(auth: Auth, batch_id: string): Promise<any>
}

export class getBatchDetails implements GetBatchDetailsUseCase {
  private repository: BatchRepository

  constructor(repository: BatchRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, batch_id: string) {
    return await this.repository.getBatchDetails(auth, batch_id)
  }
}
