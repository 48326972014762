import React from "react"
import Button from "core/components/Button"
import EditScheduledClassModel from "./EditScheduledClassModel"
import UpdateClassForm from "./UpdateClassForm"

export default function ScheduledClassCard(props: any) {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className="mb-6 flex items-center justify-between  gap-8 rounded-lg p-8 shadow">
        <div className="flex flex-1 flex-col">
          <h5 className="text-xl font-bold">{props.chapter_name}</h5>
          <p className="text-base text-[14px] font-normal">Zoom Link : {props.oldClassData.class_link}</p>
          <p className="text-base text-[14px] font-normal">Instructor : {props.oldClassData.instructor}</p>
          <p className="text-base text-[14px] font-normal">Date : {`${props.formattedDate} ${props.formattedTime}`}</p>
          <p className="text-base text-[14px] font-normal">Class Link : {props.oldClassData.class_link}</p>
        </div>
        <Button outlined className="w-fit" onClick={() => props.deleteScheduleClass(props.session_id)}>
          {props.cancelLoading ? "Cancelling" : "Cancel Session"}
        </Button>
        <Button outlined className="w-fit" onClick={handleClick}>
          Edit class
        </Button>
      </div>
      <EditScheduledClassModel isOpen={isOpen} onClose={handleClose}>
        <UpdateClassForm
          batch={props.getBatchList}
          instructor={props.instructorList}
          moduleId={props.getModulesList}
          track={props.getTracksList}
          session_id={props.oldClassData.session_id}
          currentSelectBatch={props.oldClassData.batches}
          chapterNames={props.oldClassData.chapter_name}
          classLink={props.oldClassData.class_link}
          session_duration={props.oldClassData.session_duration}
          selectInstructor={props.oldClassData.instructor}
          selectModuleId={props.oldClassData.module_id}
          notes_link={props.oldClassData.notes_link}
          selectAssigmentsQuestions={props.oldClassData.problems.assignments}
          selectHomeworkQuestions={props.oldClassData.problems.homework}
          selectPracticalQuestions={props.oldClassData.problems.practice}
          selectWarmupQuestions={props.oldClassData.problems.warmup}
          session_timestamp={props.oldClassData.session_timestamp}
          master_class_type={props.oldClassData.master_class_type}
          selectTrack={props.oldClassData.track}
          videoId={props.oldClassData.video_id}
          videoSecret={props.oldClassData.video_secret}
          notesLink={props.oldClassData.notes_link}
          notesSummaryLink={props.oldClassData.notes_summary_link}
          assignmentSolutionLink={props.oldClassData.assignment_solution_link}
          assignmentSolutionActivationTime={props.oldClassData.assignment_solution_activation_time}
          assignmentCoins={props.oldClassData?.assignment_points}
        />
      </EditScheduledClassModel>
    </>
  )
}
