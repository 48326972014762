import React from "react"
import { Tooltip } from "@mui/material"
import { CoinIcon, DiscordIcon } from "core/constants/svgs"
import { STR_DISCORD_LINK } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import { isEmpty } from "core/utils/misc"

type Props = {
  points: string
}
export default function Header({ points }: Props) {
  return (
    <header className="flex w-full items-center gap-2 border-b border-border bg-new-solid-white px-6 py-2">
      <a
        className="flex items-center gap-6 text-[#9D21C9] underline underline-offset-2"
        href={STR_DISCORD_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <DiscordIcon className="h-8 w-8" />
        <span className="text-sm font-medium leading-[19px]">Join Discord Here</span>
      </a>
      <Tooltip title={`${points} Coins`}>
        <div className="ml-auto flex items-center gap-4">
          <span>
            <CoinIcon className="h-8 w-8" />
          </span>
          <span className="text-2xl font-bold text-[#FBBF24]">{points}</span>
        </div>
      </Tooltip>
    </header>
  )
}
