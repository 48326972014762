import DashboardLayout from "core/layouts/DashboardLayout"
import useHomeViewModel from "./HomeViewModel"
import Toast from "core/components/Toast"
import Avatar from "react-avatar"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import { STR_TEXT_FIELD } from "core/constants/strings"

export default function HomeView() {
  const { toast, mentor, referralCode, changeToastVisibility, handleCopyClick } = useHomeViewModel()

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="divide-y divide-new-neutral-light rounded-sm bg-new-solid-white shadow">
          <div className="flex gap-6 p-6">
            <Avatar src={mentor?.mentor?.photo} alt="profile pic" className="shadow" name={mentor?.mentor?.name} />
            <div className="flex flex-col gap-1">
              <h2 className="mb-1">{mentor?.mentor?.name}</h2>
              <p>
                Email: <strong>{mentor?.mentor?.email}</strong>
              </p>
              {!isEmpty(mentor?.mentor?.linkedin_profile) && (
                <p>
                  LinkedIn:{" "}
                  <a href={mentor?.mentor?.linkedin_profile}>
                    <strong>{mentor?.mentor?.linkedin_profile}</strong>
                  </a>
                </p>
              )}

              <p>
                Current Company: <strong>{mentor?.mentor?.current_company}</strong>
              </p>
              <p>
                Experience: <strong>{mentor?.experience}</strong>{" "}
                <strong>{mentor?.experience > 1 ? "years" : "year"}</strong>
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 p-6">
            <p>
              Total mentees: <strong>{mentor?.mentor?.total_mentees}</strong>
            </p>
            {mentor?.mentor?.is_mentor == true && (
              <p>
                Average Mentor Sessions Rating:{" "}
                {mentor?.rating != 0 && <strong>{Math.round(parseFloat(mentor?.rating) * 10) / 10}</strong>}
                {mentor?.rating == 0 && <strong>Not Rated</strong>}
              </p>
            )}
            {mentor?.mentor?.is_instructor == true && (
              <p>
                Average Class Rating:{" "}
                {mentor?.instructor_rating != 0 && (
                  <strong>{Math.round(parseFloat(mentor?.instructor_rating) * 10) / 10}</strong>
                )}
                {mentor?.instructor_rating == 0 && <strong>Not Rated</strong>}
              </p>
            )}
            {!isEmpty(referralCode) && (
              <div className="flex items-center gap-1">
                <p>Referral code:</p>
                <div className="flex gap-2">
                  <Input type={STR_TEXT_FIELD} placeholder="Your referral code" value={referralCode} readOnly />
                  <Button small className="h-fit w-fit rounded" onClick={handleCopyClick}>
                    Copy Code
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
