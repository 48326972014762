import {
  API_ADMIN,
  API_CONTEST_APPROVE,
  API_GET_TOP_100_APPROVE_REQUESTS,
  API_APPROVE_STUDENT,
  API_GET_APPROVAL_BY_DATE,
  API_GET_APPROVAL_BY_EMAIL,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { ContestApproveDataSource } from "data/dataSource/Admin/ContestApproveDataSource"
import { Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { approveData, date, email } from "domain/model/ContestApprove"
const server = new Server()

export class ContestApproveAPIDataSourceImpl implements ContestApproveDataSource {
  async getTop100ApproveRequests(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(API_ADMIN, API_CONTEST_APPROVE, API_GET_TOP_100_APPROVE_REQUESTS), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async approveToDisapprove(auth: Auth, data: approveData): Promise<any> {
    try {
      const response = await server.post(pn(API_ADMIN, API_CONTEST_APPROVE, API_APPROVE_STUDENT), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getApprovalByEmail(auth: Auth, data: email): Promise<any> {
    try {
      const response = await server.post(
        //todo: add variables with name
        pn(API_ADMIN, API_CONTEST_APPROVE, API_GET_APPROVAL_BY_EMAIL),
        data,
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getApprovalByDate(auth: Auth, data: date): Promise<any> {
    try {
      const response = await server.post(
        //todo: add variables with name
        pn(API_ADMIN, API_CONTEST_APPROVE, API_GET_APPROVAL_BY_DATE),
        data,
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
