import React from "react"
import adobe from "assets/images/companies/adobe.png"
import bytedance from "assets/images/companies/bytedance.png"
import dream11 from "assets/images/companies/dream11.png"
import google from "assets/images/companies/google.png"
import meta from "assets/images/companies/meta.png"
import oracle from "assets/images/companies/oracle.png"
import service_now from "assets/images/companies/service_now.png"
import twitter from "assets/images/companies/twitter.png"
import amazon from "assets/images/companies/amazon.png"
import cleartrip from "assets/images/companies/cleartrip.png"
import espressif from "assets/images/companies/espressif.png"
import hyland from "assets/images/companies/hyland.png"
import microsoft from "assets/images/companies/microsoft.png"
import paypal from "assets/images/companies/paypal.png"
import smart_coin from "assets/images/companies/smart_coin.png"
import zomato from "assets/images/companies/zomato.png"
import arcesium from "assets/images/companies/arcesium.png"
import curefit from "assets/images/companies/curefit.png"
import flipkart from "assets/images/companies/flipkart.png"
import intuit from "assets/images/companies/intuit.png"
import mindtickle from "assets/images/companies/mindtickle.png"
import payu from "assets/images/companies/payu.png"
import swiggy from "assets/images/companies/swiggy.png"
import barlays from "assets/images/companies/barlays.png"
import direct1 from "assets/images/companies/direct1.png"
import goldman_sachs from "assets/images/companies/goldman_sachs.png"
import jp_morgan from "assets/images/companies/jp_morgan.png"
import myntra from "assets/images/companies/myntra.png"
import samsung from "assets/images/companies/samsung.png"
import tata_1mg from "assets/images/companies/tata_1mg.png"
import Clock from "assets/svgs/masters/Clock"
import Discount from "assets/svgs/masters/Discount"
import Future from "assets/svgs/masters/Future"
import Identity from "assets/svgs/masters/Identity"
import Recruitment from "assets/svgs/masters/Recruitment"
import MastersAPIDataSourceImpl from "data/API/Student/MastersAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { MastersRepositoryImpl } from "data/repository/Student/MastersRepositoryImpl"
import { CheckBatchForMasters } from "domain/useCase/Student/Masters/CheckBatchForMasters"
import { STR_FAILURE } from "core/constants/strings"
import { genError } from "core/utils/string"
import { useAuth } from "core/context/auth"
import { useNavigate } from "react-router-dom"
import { RegisterMastersProgram } from "domain/useCase/Student/Masters/RegisterMastersProgram"
import { useApp } from "core/context/app"

export default function MastersProgramViewModel() {
  const DATA = {
    name: "Masters of Science in Computer Science",
    description:
      "This is a Masters program accredited within the European Qualifications Framework. It is taught by the best industry experts to prepare you for the best tech jobs.",
    reasons: [
      {
        title: "Get Upskilling Upgrade",
        description: "Complete the program without leaving current course. It gets upgraded to Masters.",
        image: <Future />,
        // bg: 'bg-new-solid-blue-light',
        color: "#99D9FA",
        // gradient: 'from-new-solid-blue-light to-new-solid-blue-dark',
      },
      {
        title: "No separate cost for this program",
        description:
          "Paid fees for current program? It will be adjusted when you opt for Masters. Just pay the remaining amount.",
        image: <Discount />,
        // bg: 'bg-new-solid-yellow-light',
        color: "#F0F477",
        // gradient: 'from-new-solid-yellow-light to-new-solid-yellow-dark',
      },
      {
        title: "Get better placement opportunity",
        description: "Some top roles want master degrees, get that covered with Bosscoder Masters Program.",
        image: <Recruitment className="text-new-solid-green-dark" />,
        // bg: 'bg-new-solid-green-light',
        color: "#97FDB3",
        // gradient: 'from-new-solid-green-light to-new-solid-green-dark',
      },
      {
        title: "Get Worldwide Recognition",
        description: "Learn with European Credit System, which has acceptance around the globe.",
        image: <Identity />,
        // bg: 'bg-new-solid-red-light',
        color: "#FFBEBE",
        // gradient: 'from-new-solid-red-light to-new-solid-red-dark',
      },
      {
        title: "Same Degree, Less Time",
        description: "Complete Masters a year before any offline master's degree and get better benefits with it.",
        image: <Clock />,
        // bg: 'bg-new-solid-purple-light',
        color: "#EDC8FB",
        // gradient: 'from-new-solid-purple-light to-new-solid-purple-dark',
      },
    ],
    curriculum: [
      {
        name: "MANDATORY MODULES",
        modules: [
          {
            topic: "Programming Language Fundamentals",
            credits: 10,
          },
          {
            topic: "Data Structures and Algorithms",
            credits: 20,
          },
          {
            topic: "SQL",
            credits: 5,
          },
          {
            topic: "LLD",
            credits: 5,
          },
          {
            topic: "HLD",
            credits: 5,
          },
          {
            topic: "Project (Full Stack Engineering )",
            credits: 15,
          },
        ],
      },
      {
        name: "ELECTIVES",
        modules: [
          {
            topic: "Machine Learning 1 - Python Libraries",
            credits: 5,
          },
          {
            topic: "Machine Learning 2 - Probability & Statistics",
            credits: 5,
          },
          {
            topic: "Machine Learning 3 - ML Fundamentals",
            credits: 5,
          },
          {
            topic: "Machine Learning 4 - ML Project",
            credits: 5,
          },
        ],
      },
      {
        name: "MASTER'S PROJECT",
        modules: [
          {
            topic: "Master's Project",
            credits: 10,
          },
        ],
      },
    ],
    companies: [
      {
        name: "Microsoft",
        image: microsoft,
      },
      {
        name: "Google",
        image: google,
      },
      {
        name: "Meta",
        image: meta,
      },
      {
        name: "Amazon",
        image: amazon,
      },
      {
        name: "Swiggy",
        image: swiggy,
      },
      {
        name: "Arcesium",
        image: arcesium,
      },
      {
        name: "Adobe",
        image: adobe,
      },
      {
        name: "Twitter",
        image: twitter,
      },
      {
        name: "Directi",
        image: direct1,
      },
      {
        name: "Myntra",
        image: myntra,
      },
      {
        name: "Goldman Sachs",
        image: goldman_sachs,
      },
      {
        name: "ByteDance",
        image: bytedance,
      },
      {
        name: "Paypal",
        image: paypal,
      },
      {
        name: "Samsung",
        image: samsung,
      },
      {
        name: "JP Morgan",
        image: jp_morgan,
      },
      {
        name: "Oracle",
        image: oracle,
      },
      {
        name: "Intuit",
        image: intuit,
      },
      {
        name: "ServiceNow",
        image: service_now,
      },
      {
        name: "Mindtickle",
        image: mindtickle,
      },
      {
        name: "Flipkart",
        image: flipkart,
      },
      {
        name: "Dream11",
        image: dream11,
      },
      {
        name: "Zomato",
        image: zomato,
      },
      {
        name: "CureFit",
        image: curefit,
      },
      {
        name: "Cleartrip",
        image: cleartrip,
      },
      {
        name: "Tata 1mg",
        image: tata_1mg,
      },
      {
        name: "Hyland",
        image: hyland,
      },
      {
        name: "Espressif",
        image: espressif,
      },
      {
        name: "Barclays",
        image: barlays,
      },
      {
        name: "PayU",
        image: payu,
      },
      {
        name: "SmartCoin",
        image: smart_coin,
      },
    ],
    faqs: [
      {
        question: "What is Bosscoder Masters Program?",
        answer:
          "Bosscoder Masters Program is a unique masters program aimed at providing the best teaching and learning practices and help learners become better equipped to address the growing demand for education and jobs.",
      },
      {
        question: "Why Bosscoder Master's Program?",
        answer:
          "Bosscoder created this Master's Program to re-invent higher education in the field of Computer Science to make graduates industry-ready by matching the skill demands of the industry. Designed by industry experts, the program does not compromise on the academic standards for which we approached the European Union which follows the European Credit Transfer and Accumulation System (ECTS) for accreditation.",
      },
      {
        question: "Who is eligible for Bosscoder Master's Program in Computer Science?",
        answer: "Working professionals with an Undergraduate Degree are eligible to join Bosscoder Masters Program.",
      },
      {
        question: "When are the live classes held?",
        answer:
          "Timings of classes will be same as your current course. Since our learners are working professionals, classes will be held in the late evening or night on weekdays and any suitable time on the weekends.",
      },
      {
        question: "What if I miss a lecture?",
        answer:
          "No problem, all our live sessions are recorded and you will have access to these recordings after the lecture is completed.",
      },
      {
        question: "What is the admission process?",
        answer:
          "Interested candidates are first required to register for the program, submit their documents and pay the remaining fee. Post verification of documents, you'll receive a confirmation over email with your Learning Management System credentials and batch details.",
      },
      {
        question: "Is there a certificate provided at the end of the program?",
        answer: "Yes, once you complete the course you will be awarded a Master's Degree Certificate.",
      },
      {
        question: "Is this comparable to offline masters programs?",
        answer:
          "Yes, and this is a better choice if you want the same degree, with more relevance from the comfort of your home and with less fees.",
      },
    ],
    completion_benefits: [
      "ECTS is accepted across 60+ of countries, across thousands of Educational Institutions.",
      "Transferable Credits if you need it for higher education.",
      "ECE.org evaluated credits making it equivalent to a master's degree from the United States. To Know More: <a href='https://legal.woolf.university/accreditation' target='_blank' rel='noreferrer'>https://legal.woolf.university/accreditation</a>.",
    ],
    fees: 150000,
  }

  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const { student } = useApp()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [registeredModalOpen, setRegisteredModalOpen] = React.useState(false)
  const [isRegistering, setIsRegistering] = React.useState(false)
  const [registered, setRegistered] = React.useState(() => Boolean(student?.is_masters_registered))

  const checkBatchForMastersUseCase = new CheckBatchForMasters(
    new MastersRepositoryImpl(new MastersAPIDataSourceImpl())
  )

  const registerMastersProgramUseCase = new RegisterMastersProgram(
    new MastersRepositoryImpl(new MastersAPIDataSourceImpl())
  )

  async function fetchMastersStatus() {
    setLoading(true)

    const response = await checkBatchForMastersUseCase.invoke(auth)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch masters status"))
      changeToastVisibility(true)
      return
    }

    if (!response?.data) {
      navigate("/home")
    }
  }

  const onRegister = async () => {
    setIsRegistering(true)

    const response = await registerMastersProgramUseCase.invoke(auth)

    setIsRegistering(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to register for masters"))
      changeToastVisibility(true)
      return
    }

    setRegistered(true)
    onOpenRegisteredModal()
  }

  const onOpenRegisteredModal = () => {
    setRegisteredModalOpen(true)
  }

  const onCloseRegisteredModal = () => {
    setRegisteredModalOpen(false)
  }

  const onRedirect = () => {
    onCloseRegisteredModal()
  }

  return {
    DATA,
    toast,
    loading,
    registeredModalOpen,
    registered,
    isRegistering,
    student,
    fetchMastersStatus,
    changeToastVisibility,
    onRegister,
    onRedirect,
    onCloseRegisteredModal,
  }
}
