import { CreateTest } from "domain/model/TestSeriesDashboard"
import { TestSeriesDashboardRepository } from "domain/repository/Admin/TestSeriesDashboardRepository"

export interface createWeeklyTestUseCase {
  invoke(test: CreateTest): Promise<any>
}

export class createWeeklyTest implements createWeeklyTestUseCase {
  private repository: TestSeriesDashboardRepository
  constructor(repository: TestSeriesDashboardRepository) {
    this.repository = repository
  }

  async invoke(test: CreateTest): Promise<any> {
    return await this.repository.createWeeklyTest(test)
  }
}
