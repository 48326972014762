export default function CheckboxFilled({ className }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 17 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.366 2H2.69938C2.33119 2 2.03271 2.29848 2.03271 2.66667V13.3333C2.03271 13.7015 2.33119 14 2.69938 14H13.366C13.7342 14 14.0327 13.7015 14.0327 13.3333V2.66667C14.0327 2.29848 13.7342 2 13.366 2ZM12.0814 5.9527L7.36805 10.6667L4.53938 7.83804L5.48205 6.89537L7.36805 8.78137L11.1387 5.01004L12.0814 5.9527Z"
        fill="#00C22B"
      />
    </svg>
  )
}
