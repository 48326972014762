import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface SetLeetCodeIdUseCase {
  invoke(auth: Auth, leetcodeId: string): Promise<boolean>
}

export class SetLeetCodeId implements SetLeetCodeIdUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, leetcodeId: string) {
    return this.repository.setLeetCodeId(auth, leetcodeId)
  }
}
