import StandaredSelect from "core/components/StandaredSelect"
import AddPlacementFormViewModel from "./AddPlacementFormViewModel"
import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import React from "react"
export default function AddPlacementForm() {
  const {
    toast,
    loading,
    changeToastVisibility,
    name,
    yoe,
    testimonial,
    position,
    selectedCompany,
    companiesList,
    isEditMode,
    profilePicPrev,
    handleSubmit,
    setName,
    setTestimonial,
    setYOE,
    setPosition,
    handleCompanyChange,
    setCompanyName,
    handleFileChange,
    handleProfilePictureChange,
    goBack,
    getCompaniesList,
    fetchPlacmentDetials,
    id,
  } = AddPlacementFormViewModel()

  React.useEffect(() => {
    getCompaniesList()
    if (isEditMode) {
      fetchPlacmentDetials(id)
    }
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="flex justify-between gap-6 items-center">
          <h2 className="text-new-accent text-2xl font-bold">{isEditMode ? "Update" : "Add"} Placement</h2>
          <Button className="h-fit w-fit" failure outlined onClick={() => goBack()}>
            Go Back
          </Button>
        </div>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto mt-8 space-y-4">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
          />
          <input
            type="number"
            placeholder="YOE"
            value={yoe || ""}
            onChange={(e) => setYOE(parseInt(e.target.value))}
            required
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
          />
          <input
            type="text"
            placeholder="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            required
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
          />
          <textarea
            placeholder="Review"
            value={testimonial}
            onChange={(e) => setTestimonial(e.target.value)}
            required
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2 h-24"
          />
          <div className="flex items-center gap-4">
            <div className="relative basis-2/5 h-12 border border-new-accent rounded-md shadow-sm flex items-center justify-center">
              <span className="text-new-neutral cursor-pointer">Upload Pic</span>
              <input
                type="file"
                onChange={handleProfilePictureChange}
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Upload Student Image"
              disabled
              value={profilePicPrev.split("/").pop()}
              className="w-full border border-new-neutral py-2 px-4 rounded-md"
            />
          </div>
          <StandaredSelect
            id="companyLogo"
            className="w-full"
            value={selectedCompany}
            onChange={handleCompanyChange}
            placeholder="Select Company"
            options={[...companiesList, "Other"]}
            required
          />
          {selectedCompany === "Other" && (
            <>
              <div className="flex items-center gap-4">
                <div className="relative basis-2/5 h-12 border border-new-accent rounded-md shadow-sm flex items-center justify-center">
                  <span className="text-new-neutral cursor-pointer">Upload Pic</span>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    required
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Upload Company Logo"
                  disabled
                  className="w-full border border-new-neutral py-2 px-4 rounded-md"
                />
              </div>
              <input
                type="text"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
                required
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm p-2"
              />
            </>
          )}
          <button
            type="submit"
            className="mt-4 w-full h-10 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? <Loader small /> : isEditMode ? "Update" : "Submit"}
          </button>
        </form>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
