import React, { useEffect, useState } from "react"
import { differenceInMinutes, format } from "date-fns"
import { MentorGlobeIllustration } from "core/constants/svgs"
import StandardSelect from "core/components/v2/StandardSelect"
import Select from "core/components/new/Select"
import { Button } from "core/components/v2/Button"
import Spinner from "core/components/Spinner"
export default function NewMentorshipCard({
  index,
  mentorSessions,
  session,
  mentor,
  isTemp,
  mentorSessionDetails,
  setBookingDetails,
  setConfirmSessionIndex,
  setConfirmModal,
}: any) {
  const [isBookingLoading, setIsBookingLoading] = React.useState(false)
  const startTimestamp = new Date(session?.start_timestamp)
  const endTimestamp = new Date(session?.end_timestamp)
  if (mentorSessionDetails?.status === "booked") {
  }
  const durationInMinutes = differenceInMinutes(endTimestamp, startTimestamp)

  const [options, setOptions] = useState([])
  const [selectedSession, setSelectedSession] = useState(null)
  const [selectedValue, setSelectedValue] = useState("")

  useEffect(() => {
    const opt: any = [
      //   {
      //     label: "HH: MM",
      //     value: -1,
      //   },
    ]

    const sessions = mentorSessions.map((session: any, index: number) => {
      return {
        ...session,
        index,
      }
    })
    const sortedSessions = sessions.sort(
      (a: any, b: any) => new Date(a.start_timestamp).getTime() - new Date(b.start_timestamp).getTime()
    )

    sortedSessions.map((session: any) => {
      opt.push({
        label: format(new Date(session?.start_timestamp), "h:mm a"),
        value: session.index,
      })
    })

    setOptions(opt)
  }, [mentorSessions])
  return (
    <div className="">
      <h4 className="text-[#333] text-[18px] font-[600] my-[6px]">Mentorship Session</h4>
      <Select
        className="w-full my-[10px]"
        options={options}
        defaultOption="HH:MM"
        value={selectedValue}
        onChange={(event) => {
          const selectedIndex = event.target.value
          const sessionIndex = Number(selectedIndex)
          console.log("Selected session index:", sessionIndex)

          setSelectedSession(mentorSessions[sessionIndex])
          setSelectedValue(selectedIndex)
        }}
      />
      <div className="absolute w-full px-[16px] py-[16px] left-0 bottom-0 flex items-center justify-between gap-8">
        <div>
          <h5 className="text-[#646464] text-[12px] font-[500]">{mentor?.name || mentor}</h5>
          <h5 className="text-[#646464] text-[12px] font-[500]">{durationInMinutes} Min</h5>
        </div>
        <Button
          onClick={() => {
            setBookingDetails(selectedSession)
            console.log(mentor?.email)
            setConfirmModal(isTemp ? mentor?.email : true)
            // setConfirmSessionIndex(index)
          }}
          variant={"primary"}
          size="sm"
          disabled={isBookingLoading || mentorSessionDetails?.status === "booked" || !selectedSession}
          //   loading={isBookingLoading}
        >
          {isBookingLoading ? <Spinner small={true} /> : "Book Session"}
        </Button>
      </div>
    </div>
  )
}
