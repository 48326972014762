import { updateMentorProfile } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface updateMentorProfileUseCase {
  invoke(id: updateMentorProfile): Promise<any>
}

export class UpdateMentorProfile implements updateMentorProfileUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(id: updateMentorProfile): Promise<any> {
    return await this.repository.updateMentorProfiles(id)
  }
}
