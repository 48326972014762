import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface UnregisterContestUseCase {
  invoke(auth: Auth, contest_id: string): Promise<any>
}

export default class UnregisterContest implements UnregisterContestUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contest_id: string) {
    return this.repository.unregisterContest(auth, contest_id)
  }
}
