import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import ProjectSectionDataSourceImpl from "data/API/Admin/ProjectSectionDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { ProjectSectionRepositoryImpl } from "data/repository/Admin/ProjectSectionRepositoryImpl"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateProject } from "domain/useCase/Admin/ProjectSection/CreateProject"
import { DeleteProject } from "domain/useCase/Admin/ProjectSection/DeleteProject"
import { EditProject } from "domain/useCase/Admin/ProjectSection/EditProject"
import { GetProject } from "domain/useCase/Admin/ProjectSection/GetProject"
import { GetProjectDetails } from "domain/useCase/Admin/ProjectSection/GetProjectDetails"
import { ToggleProjectStatus } from "domain/useCase/Admin/ProjectSection/ToggleProjectStatus"
import React, { ChangeEvent } from "react"
import { useLocation, useParams } from "react-router-dom"

export default function ProjectsSectionViewModel() {
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()

  const location = useLocation()
  const { projectId } = useParams()

  const path = location.pathname
  const isCreatingNewProject = path.endsWith("create-new-project")
  const isEditingProject = path.includes("/edit-project/")

  const [projectDetails, setProjectDetails] = React.useState<any | null>(null)
  const [batch, setBatch] = React.useState([])
  const [links, setLinks] = React.useState([""])
  const [customTechStack, setCustomTechStack] = React.useState("")
  const [customSystemRequirement, setCustomSystemRequirement] = React.useState("")
  const [customIndustry, setCustomIndustry] = React.useState("")
  const [selectedRequirement, setSelectedRequirement] = React.useState("")
  const [projectTitle, setProjectTitle] = React.useState("")
  const [projectLevel, setProjectLevel] = React.useState("")
  const [techStack, setTechStack] = React.useState("")
  const [industry, setIndustry] = React.useState("")
  const [tags, setTags] = React.useState<string[]>([])
  const [inputValue, setInputValue] = React.useState<string>("")
  const [courseType, setCourseType] = React.useState("")
  const [problemStatement, setProblemStatement] = React.useState("")
  const [projectActive, setProjectActive] = React.useState(false)
  const [isOtherSelected, setIsOtherSelected] = React.useState(false)
  const [selectedBatch, setSelectedBatch] = React.useState("")
  const [dataScienceType, setDataScienceType] = React.useState("")
  const [activeFilter, setActiveFilter] = React.useState("all")
  const [searchQuery, setSearchQuery] = React.useState("")
  const [clicked, setClicked] = React.useState(false)
  const [projects, setProjects] = React.useState<any>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [isOtherRequirementSelected, setIsOtherRequirementSelected] = React.useState(false)
  const [isOtherIndustrySelected, setIsOtherIndustrySelected] = React.useState(false)
  const [formState, setFormState] = React.useState({
    projectTitle: projectTitle,
    courseType: courseType,
    projectLevel: projectLevel,
    systemRequirement: selectedRequirement,
    techStack: techStack,
    industry: industry,
    batch: tags,
    problemStatement: problemStatement,
    referenceUrls: links,
    projectId: projectId,
    isActive: projectActive,
    dataScienceType: dataScienceType,
  })

  const ToggleProjectStatusUseCase = new ToggleProjectStatus(
    new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl())
  )
  const CreateProjectUseCase = new CreateProject(new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl()))
  const EditProjectUseCase = new EditProject(new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl()))
  const DeleteProjectUseCase = new DeleteProject(new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl()))
  const GetProjectDetailsUseCase = new GetProjectDetails(
    new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl())
  )

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
  }

  const getColorClass = (level: any) => {
    switch (level) {
      case "Easy":
        return "text-green-600"
      case "Medium":
        return "text-yellow-600"
      case "Hard":
        return "text-red-600"
      default:
        return "text-gray-800"
    }
  }

  const handleProjectStatusChange = async (is_active: boolean) => {
    const data = { projectId: projectId, isActive: projectActive }
    const response = await ToggleProjectStatusUseCase.invoke(auth, data)
    if (response.success) {
      setProjectActive(is_active)
    }
  }

  const toggleProjectActive = () => {
    setProjectActive(!projectActive)
  }

  const toggleProjectActiveEdit = () => {
    handleProjectStatusChange(!projectActive)
    setProjectActive(!projectActive)
  }

  const getProjectDatabyId = async () => {
    const response = await GetProjectDetailsUseCase.invoke(auth, projectId)

    if (response.success) {
      setProjectDetails(response.data)
    }
    if (predefinedRequirements.includes(response.data?.system_requirement)) {
      setSelectedRequirement(response.data?.system_requirement)
      setIsOtherRequirementSelected(false)
    } else {
      setSelectedRequirement("others")
      setIsOtherRequirementSelected(true)
      setCustomSystemRequirement(response.data?.system_requirement)
    }
    if (predefinedTechStacks.includes(response.data?.tech_stack)) {
      setTechStack(response.data?.tech_stack)
      setIsOtherSelected(false)
    } else {
      setTechStack("others")
      setIsOtherSelected(true)
      setCustomTechStack(response.data?.tech_stack)
    }
    if (predefinedIndustries.includes(response.data.industry)) {
      setIndustry(response.data.industry)
      setIsOtherIndustrySelected(false)
    } else {
      setIndustry("others")
      setIsOtherIndustrySelected(true)
      setCustomIndustry(response.data.industry)
    }
    setProjectTitle(response.data.project_title)
    setProjectLevel(response.data.project_level)
    setTechStack(response.data?.tech_stack)
    setIndustry(response.data.industry)
    setProblemStatement(response.data.problem_statement)
    setCourseType(response.data.course_type)
    setTechStack(response.data?.tech_stack)
    setCourseType(response.data.course_type)
    setTags(response.data.batch)
    setLinks(response.data.reference_url)
    setProjectActive(response.data.project_active ?? projectActive)
    setSelectedRequirement(response.data?.system_requirement)
    setDataScienceType(response.data.data_science_or_data_analytics ?? dataScienceType)
    setFormState({
      projectTitle: response.data.project_title,
      courseType: response.data.course_type,
      projectLevel: response.data.project_level,
      systemRequirement: response.data?.system_requirement,
      techStack: response.data?.tech_stack,
      industry: response.data.industry,
      batch: response.data.batch,
      problemStatement: response.data.problem_statement,
      referenceUrls: response.data.reference_url,
      projectId: projectId,
      isActive: response.data.is_active ?? projectActive,
      dataScienceType: response.data.data_science_or_data_analytics ?? dataScienceType,
    })
  }

  const addLink = () => {
    const newLinks = [...links, ""]
    setLinks(newLinks)
    const nonEmptyLinks = newLinks.filter((link) => link.trim() !== "")
    setFormState({ ...formState, referenceUrls: nonEmptyLinks })
  }

  const handleClick = () => {
    window.history.back()
  }

  const deleteLastLink = () => {
    if (links.length > 1) {
      const newLinks = links.slice(0, -1)
      setLinks(newLinks)
      const nonEmptyLinks = newLinks.filter((link) => link.trim() !== "")
      setFormState({ ...formState, referenceUrls: nonEmptyLinks })
    }
  }

  const handleChange = (index: any, event: any) => {
    const newLinks = [...links]
    newLinks[index] = event.target.value
    setLinks(newLinks)
    const nonEmptyLinks = newLinks.filter((link) => link.trim() !== "")
    setFormState({ ...formState, referenceUrls: nonEmptyLinks })
  }

  const handleKeyPress = (index: any, event: any) => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (links[index].trim() !== "") {
        addLink()
      }
    }
  }

  const handleSystemRequirementChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setSelectedRequirement(value)

    if (value === "others") {
      setIsOtherRequirementSelected(true)
      setCustomSystemRequirement("")
      setFormState({ ...formState, systemRequirement: "" })
    } else {
      setIsOtherRequirementSelected(false)
      setFormState({ ...formState, systemRequirement: value })
    }
  }

  const handleOtherSystemRequirementInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCustomSystemRequirement(value)
    setFormState({ ...formState, systemRequirement: value })
  }

  const handleProjectTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectTitle(event.target.value)
    setFormState({ ...formState, projectTitle: event.target.value })
  }

  const handleProjectLevelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProjectLevel(event.target.value)
    setFormState({ ...formState, projectLevel: event.target.value })
  }

  const handleIndustryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setIndustry(value)

    if (value === "others") {
      setIsOtherIndustrySelected(true)
      setCustomIndustry("")

      setFormState({ ...formState, industry: "" })
    } else {
      setIsOtherIndustrySelected(false)
      setFormState({ ...formState, industry: value })
    }
  }

  const handleOtherIndustryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCustomIndustry(value)

    setFormState({ ...formState, industry: value })
  }

  const handleTechStackChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setTechStack(value)

    if (value === "others") {
      setIsOtherSelected(true)

      setCustomTechStack("")
      setFormState({ ...formState, techStack: "" })
    } else {
      setIsOtherSelected(false)
      setFormState({ ...formState, techStack: value })
    }
  }

  const handleOtherTechStackInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCustomTechStack(value)

    setFormState({ ...formState, techStack: value })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async () => {
    const response = await CreateProjectUseCase.invoke(auth, formState)

    if (response.success) {
      changeToastDetails("success", "Project Created successfully!")
      changeToastVisibility(true)
      setTimeout(() => {
        changeToastVisibility(false)
      }, 5000)
    }
  }

  const goback = () => {
    window.history.back()
  }

  const handleEditSubmit = async () => {
    const response = await EditProjectUseCase.invoke(auth, formState)
    if (response.success) {
      changeToastDetails("success", "Project Edited successfully!")
      changeToastVisibility(true)
      setTimeout(() => {
        changeToastVisibility(false)
      }, 5000)
    }
  }

  const handleDelete = async () => {
    const response = await DeleteProjectUseCase.invoke(auth, projectId)

    if (response.success) {
      changeToastDetails("success", "Project Deleted successfully!")
      changeToastVisibility(true)
      setTimeout(() => {
        changeToastVisibility(false)
      }, 5000)
    }
  }

  const addTag = (tag: any) => {
    if (!tags.includes(tag)) {
      const newTags = [...tags, tag]
      setTags(newTags)
      setFormState({ ...formState, batch: newTags })
      setInputValue("")
    }
  }
  const addTags = (tags: any[]) => {
    const uniqueTags = Array.from(new Set(tags))
    setTags(uniqueTags)
    setFormState({ ...formState, batch: uniqueTags })
    setInputValue("")
  }

  const handleInputKeyDown = (event: any) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault()
      addTag(inputValue.trim())
    }
  }

  const removeTag = (indexToRemove: number) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove)
    setTags(newTags)
    setFormState({ ...formState, batch: newTags })
  }

  const handleBatchChange = (event: any) => {
    const batchValue = event.target.value
    setSelectedBatch(batchValue)
    if (batchValue && batchValue !== "Select Here") {
      addTag(batchValue)
    }
  }

  const handleBatchChangeCollective = (batchValue: any) => {
    addTag(batchValue)
  }

  const handleCourseTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCourseType(e.target.value)
    setFormState({ ...formState, courseType: e.target.value })
  }

  const handleDataScienceTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDataScienceType(event.target.value)
    setFormState({ ...formState, dataScienceType: event.target.value })
  }

  const handleProblemStatementChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setProblemStatement(e.target.value)
    setFormState({ ...formState, problemStatement: e.target.value })
  }

  const getProjectUseCase = new GetProject(new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl()))
  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getProjects = async () => {
    setIsLoading(true)
    const response = await getProjectUseCase.invoke(auth)

    if (response.success) {
      setProjects(response.data)
    }

    setIsLoading(false)
  }

  const filteredProjects = projects.filter((project: any) =>
    project.project_title.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleActiveFilter = (filter: string) => {
    setActiveFilter(filter)
    setClicked(!clicked)
  }

  const visibleProjects = filteredProjects.filter((project: any) => {
    if (activeFilter === "all") return true
    return activeFilter === "active" ? project.is_active : !project.is_active
  })

  const requirements = [
    "On device",
    "Imbalance Data",
    "Time Series Analysis",
    "Image",
    "Youtube",
    "Text+Speech",
    "QA",
    "others",
  ]
  const predefinedRequirements = requirements.map((req: any) => req.value)
  const techStacks = ["Supervised", "Unsupervised", "CV", "NLP", "others"]
  const predefinedTechStacks = techStacks.map((req: any) => req.value)

  const courses = ["SDE", "DSML", "EVOLVE"]

  const industries = [
    "Healthcare",
    "Finance",
    "Marketing-Ads",
    "Media",
    "EdTech",
    "Social Media",
    "Disaster Management",
    "Agriculture",
    "others",
  ]
  const predefinedIndustries = industries.map((req: any) => req.value)

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatch(response.data)
    }
  }

  const batches = ["b20", "b21", "b22"]

  const projectsLevelList = ["Easy", "Medium", "Hard"]

  const dataScienceTypeOptions = ["Data Science", "Data Analytics"]

  const onClickHandler = isCreatingNewProject ? handleSubmit : handleEditSubmit

  return {
    auth,
    projectDetails,
    links,
    selectedRequirement,
    projectTitle,
    projectLevel,
    techStack,
    industry,
    tags,
    inputValue,
    courseType,
    problemStatement,
    projectActive,
    formState,
    requirements,
    techStacks,
    courses,
    projects,
    industries,
    isCreatingNewProject,
    isEditingProject,
    projectId,
    clicked,
    activeFilter,
    visibleProjects,
    projectsLevelList,
    isOtherSelected,
    isOtherRequirementSelected,
    isOtherIndustrySelected,
    selectedBatch,
    batches,
    isLoading,
    customTechStack,
    customSystemRequirement,
    customIndustry,
    batch,
    toast,
    dataScienceType,
    dataScienceTypeOptions,
    addTags,
    getBatches,
    setClicked,
    goback,
    handleActiveFilter,
    handleSearchChange,
    toggleProjectActive,
    getProjectDatabyId,
    addLink,
    addTag,
    setTags,
    getProjects,
    deleteLastLink,
    handleChange,
    handleClick,
    handleSystemRequirementChange,
    handleProjectTitleChange,
    handleProjectLevelChange,
    handleIndustryChange,
    handleTechStackChange,
    handleInputChange,
    handleInputKeyDown,
    removeTag,
    handleCourseTypeChange,
    handleProblemStatementChange,
    handleSubmit,
    handleEditSubmit,
    handleDelete,
    onClickHandler,
    toggleProjectActiveEdit,
    handleOtherTechStackInput,
    handleOtherSystemRequirementInput,
    handleOtherIndustryInput,
    handleBatchChange,
    handleBatchChangeCollective,
    setSelectedBatch,
    getColorClass,
    handleKeyPress,
    changeToastVisibility,
    changeToastDetails,
    handleDataScienceTypeChange,
  }
}
