import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Input from "core/components/new/Input"
import PhoneInput from "react-phone-number-input"
import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import CreateOnboardingManagerViewModel from "./CreateOnboardingMangerViewModel"
import { CrossIcon } from "core/constants/svgs"
import {
  STR_DATE_AND_TIME_FIELD,
  STR_TEXT_FIELD,
  STR_EMAIL_ID,
  STR_DATE_FIELD,
  STR_MONTH_AND_YEAR_FIELD,
  STR_EMAIL_FIELD,
} from "core/constants/strings"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import React from "react"

export default function CreateOnboardingManagerView() {
  const {
    toast,
    email,
    phone,
    linkedin,
    profile_pic,
    isLead,
    isDisabled,
    name,
    loading,
    refreshed,
    imgSrc,
    handlePhoneChange,
    handleNameChange,
    handleEmailChange,
    handleLinkedinChange,
    handleProfile_picChange,
    handleIsLeadChange,
    handleIsDisabledChange,
    changeToastVisibility,
    // getBatches,
    // getAllTracks,
    // getInstructorsList,
    createOnboardingManagers,
  } = CreateOnboardingManagerViewModel()

  // React.useEffect(() => {
  //     if (refreshed) {
  //         // getBatches();
  //         // getAllTracks();
  //         // getInstructorsList();
  //     }
  // }, [refreshed]);

  // React.useEffect(() => {
  //     if (!_selectTrack || _selectTrack.length === 0 || !_modules) {
  //         return;
  //     }
  //     _setShowModules([]);
  //     _setShowModules((prev: any) => {
  //         let updatedShowModules = [...prev];
  //         _selectTrack.forEach((tr: any) => {
  //             if (_modules[tr]) {
  //                 _modules[tr].forEach((val: any) => {
  //                     if (val) {
  //                         val.forEach((vl: any) => {
  //                             updatedShowModules = [...updatedShowModules, vl];
  //                         });
  //                     }
  //                 });
  //             }
  //         });

  //         return updatedShowModules;
  //     });
  // }, [_selectTrack, _modules]);

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">Add a new Onboarding Manager</h2>
          <p>Adding a new Onboarding Manager</p>
        </div>
        <div className="round-2 mx-auto flex w-full max-w-[600px] flex-col gap-4 p-6 shadow">
          <Input
            type={STR_EMAIL_FIELD}
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
            containerClass="w-full"
            required
          />

          <Input
            type={STR_TEXT_FIELD}
            placeholder="Name"
            onChange={handleNameChange}
            value={name}
            containerClass="w-full"
            required
          />

          <Input
            type={STR_TEXT_FIELD}
            placeholder="LinkedIn Profile"
            onChange={handleLinkedinChange}
            value={linkedin}
            containerClass="w-full"
            required
          />

          <PhoneInput
            defaultCountry="IN"
            value={phone}
            onChange={handlePhoneChange}
            placeholder="Phone Number"
            limitMaxLength
            required
          />

          {/* <label htmlFor="profile-pic" className="text-sm">Profile Picture</label> */}
          <h4 className="text-sm">Profile Picture</h4>
          <div className="flex gap-3">
            {imgSrc && (
              <div className="w-20 rounded-xl">
                <img src={imgSrc} alt="Profile Picture" width={100} height={100} className="w-full rounded-lg" />
              </div>
            )}
            <input
              type="file"
              name="img"
              accept="image/*"
              className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              onChange={handleProfile_picChange}
            />
          </div>

          <div className="mb-4 flex flex-col py-1 text-[20px]">
            <div>
              <div className="flex flex-col">
                <h4 className="text-sm mb-1">IsLead</h4>
                <select
                  className="w-full mb-4 rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none"
                  value={isLead ? "true" : "false"}
                  onChange={handleIsLeadChange}
                  id="isLead"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>

              <div className="flex flex-col">
                <h4 className="text-sm mb-1">IsDisabled</h4>
                <select
                  className="w-full mb-4 rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none"
                  value={isDisabled ? "true" : "false"}
                  onChange={handleIsDisabledChange}
                  id="isDisabled"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
          </div>

          <Button disabled={loading} loading={loading} onClick={createOnboardingManagers}>
            Add a new Onboarding Manager
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
