import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface FetchLeetCodeDetailsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class FetchLeetCodeDetails implements FetchLeetCodeDetailsUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.fetchLeetCodeDetails(auth)
  }
}
