import React from "react"

const checkboxSuccess = ({ className }: any) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 2H13.3333C13.7015 2 14 2.29848 14 2.66667V13.3333C14 13.7015 13.7015 14 13.3333 14H2.66667C2.29848 14 2 13.7015 2 13.3333V2.66667C2 2.29848 2.29848 2 2.66667 2ZM3.33333 3.33333V12.6667H12.6667V3.33333H3.33333ZM4.50667 7.83804L7.33533 10.6667L12.0493 5.9527L11.106 5.01004L7.33533 8.78137L5.44933 6.89537L4.50667 7.83804Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default checkboxSuccess
