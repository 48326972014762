import SendEmailRepository from "domain/repository/Admin/SendEmailRepository"
export interface SendEmailToGroupOfStudentsUseCase {
  invoke(auth: any, data: FormData): Promise<any>
}

export class SendEmailToGroupOfStudents implements SendEmailToGroupOfStudentsUseCase {
  private readonly repository: SendEmailRepository

  constructor(repository: SendEmailRepository) {
    this.repository = repository
  }

  async invoke(auth: any, data: FormData): Promise<any> {
    return await this.repository.sendEmailToGroupOfStudents(auth, data)
  }
}
