import React from "react"
import { Bar } from "react-chartjs-2"
import "chart.js/auto"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { AspectRatio } from "@mui/icons-material"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels)

const MyBarChart = ({ data, targetedData }) => {
  const labels = data?.map((item) => `Week ${item.week}`)
  const datasetColors = ["#66EB45", "#F8A934", "#FAF12D", "#BB3CE8"]
  const options = {
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const firstElement = elements[0]
        const datasetIndex = firstElement.datasetIndex
        const dataIndex = firstElement.index
        const label = labels[dataIndex]
        const datasetLabel = chartData.datasets[datasetIndex].label
      }
    },

    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
        border: {
          display: false,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
      y1: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          display: false,
        },
      },
    },
    layout: {
      height: 40,
      padding: {
        top: 30,
        right: 5,
        left: 50,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
      datalabels: {
        color: function (context) {
          return datasetColors[context.datasetIndex]
        },
        align: "end",
        anchor: "end",
      },
    },
    responsive: true,
    AspectRatio: true,
    maintainAspectRatio: false,
  }

  const chartData = {
    labels: data?.map((item) => `Week ${item.week}`),
    datasets: [
      {
        label: "Class Attendance",
        data: data?.map((item) => item[targetedData]),
        backgroundColor: "#162456",
        yAxisID: "y",
        borderRadius: 4,
        minBarLength: 0,
        barThickness: 34,
      },
    ],
  }

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default MyBarChart
