import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import UpdateWalkThroughAPIDataSourceImpl from "data/API/Admin/UpdateWalkThroughAPIDataSourceImpl"
import { UpdateWalkThroughRepositoryImpl } from "data/repository/Admin/UpdateWalkThorughRepositoryImpl"
import { UpdateWalkThroughDate } from "domain/useCase/Admin/UpdateWalkThrough/UpdateWalkThroughDate"

import React from "react"

export default function UpdateWalkThroughViewModel() {
  const [WalkThrough, setWalkThrough] = React.useState<any>()
  const { auth } = useAuth()
  const [student_id, setStudentId] = React.useState<string>("")
  const [new_walkthrough_date, setNewWalkThroughDate] = React.useState<any>()
  const [timeValue, setTimeValue] = React.useState<any>()
  const UpdateWalkThroughDateUseCase = new UpdateWalkThroughDate(
    new UpdateWalkThroughRepositoryImpl(new UpdateWalkThroughAPIDataSourceImpl())
  )
  const [loading, setLoading] = React.useState<boolean>(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  function handleStudentChange(e: any) {
    const trimmedValue = e.target.value.trim()
    setStudentId(trimmedValue)
  }
  const fetchWalkThroughDate = async () => {
    const response = await UpdateWalkThroughDateUseCase.invoke(auth, student_id, new_walkthrough_date as string)

    if (!response?.success) {
      return "error"
    }
    setWalkThrough(response?.data)
    return response.data
  }
  function walkThroughDateChange(e: any) {
    const time = e.target.value as string
    const timestamp = new Date(time).getTime() / 1000
    setTimeValue(e.target.value)
    setNewWalkThroughDate(timestamp)
  }

  return {
    student_id,
    WalkThrough,
    loading,
    toast,
    new_walkthrough_date,
    timeValue,
    setLoading,
    changeToastDetails,
    changeToastVisibility,
    setTimeValue,
    handleStudentChange,
    setNewWalkThroughDate,
    fetchWalkThroughDate,
    walkThroughDateChange,
    setStudentId,
    setWalkThrough,
  }
}
