import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface WriteStudentFeedbackUseCase {
  invoke(room_id: string, feedback: string, action_items: string): Promise<any>
}

export default class WriteStudentFeedback implements WriteStudentFeedbackUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(room_id: string, feedback: string, action_items: string): Promise<any> {
    return await this.repository.writeStudentFeedback(room_id, feedback, action_items)
  }
}
