import { ChevronLeftIcon, ChevronRightIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { format } from "date-fns"
import { useEffect, useRef } from "react"

export default function ScheduleCalendar({ weeklySessions, maxSessions }: any) {
  const divRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const currentDate = new Date()
    const dayOfMonth = currentDate.getDate()
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate()

    const scrollPercentage = dayOfMonth < 7 ? 0 : ((dayOfMonth - 1) / daysInMonth) * 100

    if (divRef.current) {
      const scrollWidth = divRef.current.scrollWidth
      const scrollPosition = (scrollWidth * scrollPercentage) / 100
      divRef.current.scrollTo({ left: scrollPosition, behavior: "smooth" })
    }
  }, [])

  return (
    <div className="shadow-[-1px_1px_3px_0px_rgba(132,_136,_139,_0.15)] rounded-[8px] border-[0.5px] border-[#ADADAD] text-new-neutral">
      <h4 className="flex rounded-t-[8px] bg-[#F9F9F9] justify-center py-[13px] text-[14px] font-[Inter] font-[400]">
        Weekly Class Schedule
      </h4>

      <div className="group relative overflow-hidden scrollbar-container h-[380px] mb-[6px] px-[8px]">
        <div ref={divRef} className="flex overflow-hidden scrollbar-hidden scrollbar-visible ">
          {weeklySessions?.map(({ timestamp, sessions }: any, i: number) => {
            const is_done = timestamp < new Date(new Date().toDateString()).getTime()
            return (
              <div key={i} className="w-full h-full">
                <div
                  className={cn(
                    "flex my-[8px] h-[81px] w-full min-w-[128px] flex-1 shrink-0 flex-col items-center justify-center border-new-neutral-light",
                    timestamp === new Date().setHours(0, 0, 0, 0)
                      ? "bg-[#000F43] rounded-[4px] text-new-solid-white"
                      : "text-[#646464]"
                  )}
                >
                  <h3 className="text-[18px] font-[500]">
                    {new Date(timestamp).getDate()} {format(timestamp, "MMM")}
                  </h3>
                  <p className="text-[#D3D3D3] text-[12px] font-[500]">{format(timestamp, "EEE")}</p>
                </div>
                {Array(1)
                  .fill(0)
                  .map((_, j) => (
                    <div
                      key={j}
                      className={cn(
                        "w-11/12 rounded-[4px] my-[16px] min-w-[100px] max-w-[200px] flex-1 mx-[6px] border-[0.5px] border-[#d3d3d3] shadow-[-1px_1px_3px_0px_rgba(132,_136,_139,_0.05)]",
                        j < maxSessions ? "h-[250px]" : "h-[250px]",
                        sessions.length === 0 && "bg-[#F9F9F9] border-0"
                      )}
                    >
                      {j < sessions.length && (
                        <>
                          <div
                            className={cn(
                              "w-full h-[12px] rounded-t-[3px]",
                              is_done ? "bg-new-neutral-light" : "bg-new-accent"
                            )}
                          />
                          <div className="h-full px-[6px] py-[16px]">
                            <h4 className="text-[12px] font-[600] mb-[10px]" title={sessions[j]?.module_name}>
                              {sessions[j]?.module_name}:
                            </h4>
                            <ul className="list-inside">
                              <li
                                className="relative w-fit text-[10px] leading-4 font-[400] pl-3 before:content-['\2022'] before:absolute before:left-0 before:text-black"
                                title={sessions[j]?.chapter_name}
                              >
                                {sessions[j]?.chapter_name}
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
