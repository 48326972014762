import { Auth } from "domain/model/Auth"
import { TCreateQuestion, TCreateResponse } from "domain/model/Questions"
import { QuestionsRepository } from "domain/repository/Admin/QuestionsRepository"

export interface CreateQuestionUseCase {
  invoke(auth: Auth, data: TCreateQuestion): Promise<TCreateResponse>
}

export default class CreateQuestion implements CreateQuestionUseCase {
  private repository: QuestionsRepository

  constructor(repository: QuestionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: TCreateQuestion) {
    return this.repository.createQuestion(auth, data)
  }
}
