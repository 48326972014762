export default function CrossCircle({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33334 8.00065C1.33334 11.6827 4.318 14.6673 8 14.6673C11.682 14.6673 14.6667 11.6827 14.6667 8.00065C14.6667 4.31865 11.682 1.33398 8 1.33398C4.318 1.33398 1.33334 4.31865 1.33334 8.00065ZM6.11467 5.17196L8.00001 7.05796L9.88534 5.17196L10.8287 6.11529L8.94267 8.00062L10.8287 9.88596L9.88534 10.8293L8.00001 8.94329L6.11467 10.8293L5.17134 9.88596L7.05734 8.00062L5.17134 6.11529L6.11467 5.17196Z"
        fill="#DC2626"
      />
    </svg>
  )
}
