import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetClassAverageRatingUseCase {
  invoke(auth: Auth, batch: string, month: string, instructor: string): Promise<any>
}

export default class GetClassAverageRating implements GetClassAverageRatingUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, class_id: string) {
    return await this.repository.getClassAverageRating(auth, class_id)
  }
}
