export default function OnboardingTable(props: any) {
  return (
    <div className="relative overflow-x-auto rounded-[8px] border-[0.5px] my-[15px]">
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-[16px] py-[8px] text-[12px] font-[500]">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {props?.tableData?.rows?.map((row: any, i: any) => (
            <tr className="bg-new-solid-white hover:bg-[#F5F5F5]" key={i}>
              {row.map((cell: any, j: any) => (
                <td className="px-[16px] py-[8px] ">{cell}</td>
              ))}
            </tr>
          ))}
          <tr className="bg-[#F5F5F5]">
            <td className="py-[10px]"></td>
            <td className="py-[10px]"></td>
            <td className="py-[10px]"></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
