import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import DeleteSSMPopUp from "./DeleteSSMPopUp"

export default function HistoryTable(props: any) {
  return (
    <div className="font-montserrat w-full overflow-x-auto rounded-[4px] border border-[#ADADAD]">
      <table className="w-full ">
        <thead className="h-[33px] bg-[#303030]">
          <tr className="">
            {props.tableHeaders?.map((title: any, index: any) => (
              <th
                key={index}
                className={`${
                  title === "Update Detail" || title === "Session Detail" ? "w-[157px] text-center" : "text-left"
                } 
                p-0 text-[14px] font-semibold text-[#FFF] ${title === "Sr. No." && " px-[24px]"}`}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.historys?.map((historyObject: any, index: any, array: any[]) => {
            const [key, data]: any = Object.entries(historyObject)[0]
            const rowNumber = array.length - index

            return (
              <tr className="h-[69px] bg-new-solid-white hover:bg-[#F5F5F5]" key={index}>
                <td className="font-montserrat leading-160 w-[94px] pl-[24px]  text-left text-[14px] font-normal text-[#414141]">
                  {String(rowNumber).padStart(2, "0")}
                </td>
                <td className="font-montserrat leading-160 w-[200px] text-left text-[14px] font-normal text-[#414141]">
                  {props.formatDate(
                    data.meeting_date,
                    data.status == "Scheduled" && data.meeting_date >= props.currentDate ? data.status : "Completed"
                  )}
                </td>
                <td className="font-montserrat leading-160 w-[94px] max-w-[94px] text-left text-[14px] font-normal text-[#414141]">
                  {data.meeting_duration > 60
                    ? `${Math.floor(data.meeting_duration / 60)} hr ${data.meeting_duration % 60} min`
                    : `${data.meeting_duration} min`}
                </td>

                <td
                  className={`font-montserrat leading-160 w-auto text-[14px] font-medium ${
                    data.status === "Scheduled" && props.currentDate <= data.meeting_date
                      ? "text-yellow-500"
                      : data.status === "Student Absent" || data.status === "SSM Absent"
                        ? "text-red-500"
                        : data.status === "Completed"
                          ? "text-[#00C22B]"
                          : "text-gray-500"
                  } w-[120px] `}
                >
                  {data.status == "Scheduled"
                    ? data.meeting_date >= props.currentDate
                      ? "Scheduled"
                      : "N/A"
                    : data.status}
                </td>

                <td className="">
                  <div className="flex  justify-center">
                    <Button
                      onClick={() => {
                        props.setIsDetailUpdateOpen(true)
                        props.handleUpdateDetailButton(
                          data.meeting_topic,
                          data.meeting_date,
                          data.meeting_ssmRemark,
                          data.meeting_agenda,
                          data.meeting_duration,
                          data.status == "Scheduled" ? "Completed" : data.status,
                          data.rate || 0,
                          data.feedback || "",
                          data.problem_solving_milestone_due_date || "",
                          data.development_milestone_due_date || "",
                          data.problem_solving_milestone || "",
                          data.development_milestone || ""
                        )
                        props.setMenuVisible(true)
                      }}
                      disabled={data.status === "Scheduled" && data.meeting_date < props.currentDate}
                      outlined
                      className={`bg-bg-White font-montserrat h-[33px] w-[134px]  border-[0.5px] border-solid border-[#312E81] px-4 py-2 text-[14px] font-semibold leading-normal text-[#312E81] ${
                        data.status === "Scheduled" &&
                        data.meeting_date < props.currentDate &&
                        "cursor-not-allowed border-[#BDBDBD] bg-[#F7F7F7] text-[#BDBDBD]"
                      }`}
                    >
                      Update Detail
                    </Button>
                  </div>
                </td>
                <td className="">
                  <div className="flex items-center justify-center">
                    <Button
                      onClick={() => {
                        props.setIsSessionDetailOpen(true)
                        props.handleUpdateDetailButton(
                          data.meeting_topic,
                          data.meeting_date,
                          data.meeting_ssmRemark,
                          data.meeting_agenda,
                          data.meeting_duration,
                          data.status || "",
                          data.rate || 0,
                          data.feedback || "",
                          data.problem_solving_milestone_due_date || "",
                          data.development_milestone_due_date || "",
                          data.problem_solving_milestone || "",
                          data.development_milestone || ""
                        )
                        props.setMenuVisible(true)
                      }}
                      outlined
                      className={`font-montserrat h-[33px] w-[136px]  border-[0.5px] border-solid  px-4 py-2 text-[14px] font-semibold leading-normal ${
                        data.status === "Scheduled"
                          ? "cursor-not-allowed border-[#BDBDBD] bg-[#F7F7F7] text-[#BDBDBD]"
                          : "bg-bg-White border-[#312E81] text-[#312E81]  active:bg-[#F7F7F7] active:text-[#312E81]"
                      }`}
                      disabled={data.status === "Scheduled"}
                    >
                      Session Detail
                    </Button>
                  </div>
                </td>
                <td className="text-black-500 .menu-button relative w-12 pl-[6px] font-bold">
                  <button
                    disabled={props.scheduledCnt == 0 || index != 0}
                    onClick={() => {
                      props.toggleMenu()
                    }}
                  >
                    <svg
                      className={`${
                        (data.status !== "Scheduled" && data.meeting_date > props.currentDate) ||
                        (data.status === "Scheduled" && data.meeting_date < props.currentDate)
                          ? "opacity-50"
                          : ""
                      } ${index != 0 && "opacity-50"}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 19.5C11 20.325 11.675 21 12.5 21C13.325 21 14 20.325 14 19.5C14 18.675 13.325 18 12.5 18C11.675 18 11 18.675 11 19.5ZM11 4.5C11 5.325 11.675 6 12.5 6C13.325 6 14 5.325 14 4.5C14 3.675 13.325 3 12.5 3C11.675 3 11 3.675 11 4.5ZM12.5 13.5C11.675 13.5 11 12.825 11 12C11 11.175 11.675 10.5 12.5 10.5C13.325 10.5 14 11.175 14 12C14 12.825 13.325 13.5 12.5 13.5Z"
                        fill="#6B6B6B"
                      />
                    </svg>
                  </button>
                  <div>
                    {props.isDeleteSSMPopUp && (
                      <DeleteSSMPopUp
                        onClose={props.handleIsDeleteSSMPopUp}
                        onDelete={props.handleDeleteMeeting}
                        data={array[0]}
                        email={props.email}
                        formatDate={props.formatDate}
                      />
                    )}
                  </div>
                  {props.menuVisible == 0 && index == 0 && data.status == "Scheduled" && (
                    <div
                      className={`font-montserrat  absolute right-10 top-[31px] z-[101] flex h-[98px]  w-[160px]   flex-col  items-start gap-2 rounded-sm bg-white  p-4 px-6 py-6 text-left text-[14px] text-sm font-semibold text-[#6B6B6B] shadow-[0px_3px_7px_rgba(0,_0,_0,_0.1)]`}
                    >
                      <div
                        className="cursor-pointer hover:rounded-sm hover:bg-[#F5F5F5]"
                        onClick={() => {
                          props.setIsDeleteSSMPopUp(true)
                          props.toggleMenu()
                        }}
                      >
                        Delete Session
                      </div>
                      <div
                        className="cursor-pointer hover:rounded-sm hover:bg-[#F5F5F5]"
                        onClick={
                          data.status === "Scheduled"
                            ? () => {
                                props.setIsCreateGroupSessionOpen(true)
                                props.handleUpdateButton(
                                  data.meeting_topic,
                                  data.meeting_date,
                                  data.meeting_agenda,
                                  data.meeting_duration
                                )
                                props.toggleMenu()
                              }
                            : undefined
                        }
                      >
                        Update Session
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            )
          })}
          {(props.historys.length == 1 || props.historys.length == 2) && (
            <div className={`${props.historys.length == 1 ? "h-[138px]" : "h-[69px]"}`}></div>
          )}
        </tbody>
      </table>
      {props.isCreateGroupSessionOpen && (
        <div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.85)]">
              <div className={"relative mx-auto w-full max-w-4xl  shadow-lg"}>
                <div className="relative">
                  <div className="">
                    <div className=" flex w-full flex-col items-start gap-4 rounded-[2px]  border-[0.5px] border-solid border-[#312E81] bg-new-solid-white p-6">
                      <div className="flex w-full">
                        <div className="font-montserrat flex w-full text-[18px] font-semibold text-[#312E81]">
                          Update Meeting
                        </div>
                        <div className="  flex justify-end">
                          <button
                            className="text-gray-500 hover:text-gray-600 focus:outline-none"
                            onClick={props.handleCreateGroupSession}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M10.586 12L5.63599 7.05001L7.04999 5.63601L12 10.586L16.95 5.63601L18.364 7.05001L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12Z"
                                fill="#6B6B6B"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className=" flex flex-row flex-wrap">
                        <div className="mr-4">
                          <div className="font-montserrat  text-[14px] font-semibold text-[#414141]">Meeting Topic</div>
                          <input
                            className="bg-whitepx-4 mt-2 w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                            type="text"
                            placeholder="Type here"
                            value={props.updatedMeeting}
                            onChange={props.handleUpdatedMeeting}
                          ></input>
                        </div>
                        <div className="mr-4">
                          <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">Date</div>
                          <input
                            className="w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                            type="date"
                            placeholder="Type here"
                            value={props.meetingUpdatedDate}
                            onChange={props.handleMeetingUpdatedDate}
                          ></input>
                        </div>
                        <div>
                          <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">Duration</div>
                          <input
                            className="w-[200px] rounded-sm border-[#6B6B6B] border-opacity-50 bg-white  px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                            type="number"
                            placeholder="Type here"
                            value={props.meetingUpdatedDuration}
                            onChange={props.handleMeetingUpdatedDuration}
                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement)?.blur()}
                          ></input>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="font-montserrat mb-2 text-[14px] font-semibold text-[#414141]">
                          Meeting Agenda
                        </div>
                        <textarea
                          onChange={props.handleMeetingUpdatedAgenda}
                          value={props.meetingUpdatedAgenda}
                          className="h-[65px] w-full rounded-sm border border-border bg-transparent py-2 px-4"
                          placeholder="Type here"
                        />
                      </div>
                      <div className="flex w-full  justify-between">
                        <div className="font-montserrat ">
                          <div className="text-[18px] font-semibold text-[#414141]">Update Meeting</div>
                          <div className="text-[14px] leading-4 text-[#6B6B6B]">Save your updated meeting data.</div>
                        </div>
                        <div className="ml-auto flex space-x-4">
                          <Button
                            onClick={props.handleDiscard}
                            outlined
                            className="h-[52px] w-[188px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                          >
                            Discard Changes
                          </Button>
                          <Button
                            className="font-montserrat h-[52px] w-[201px] text-[16px] font-medium  "
                            onClick={() => {
                              props.handleUpdateMeeting(props.email)
                            }}
                            disabled={
                              !props.updatedMeeting ||
                              !props.meetingUpdatedDate ||
                              !props.meetingUpdatedDuration ||
                              !props.meetingUpdatedAgenda ||
                              props.updatedMeeting.length === 0 ||
                              props.meetingUpdatedDate.length === 0 ||
                              props.meetingUpdatedDuration.length === 0 ||
                              props.meetingUpdatedAgenda.length === 0
                            }
                          >
                            Update Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toast data={props.toast} onClick={() => props.changeToastVisibility(false)} />
    </div>
  )
}
