import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { JobDashboardAPIDataSourceImpl } from "data/API/Admin/JobDashboardAPIDataSourceImpl"
import { JobDashboardRepositoryImpl } from "data/repository/Admin/JobDashboardRepositoryImpl"
import { JobApplications } from "domain/useCase/Admin/JobDashboard/GetJobsApplications"
import { SetApplicationStatus } from "domain/useCase/Admin/JobDashboard/SetApplicationStatus"
import React from "react"
const jobStatus = [
  "Applied for job",
  "Application in review by Bosscoder",
  "Resume forwared to HR",
  "Interview Scheduled",
  "Rejected",
  "Selected",
  "No Response",
]
export default function EligibleCandidateViewModel() {
  const [jobApplications, setJobApplications] = React.useState<any>([])
  const [selectJobStates, setSelectJobStates] = React.useState(
    jobStatus.map((status, index) => ({
      key: index,
      value: "",
    }))
  )
  const [loading, setLoading] = React.useState(false)
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getJobApplicationsUseCase = new JobApplications(
    new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl())
  )
  const updateCandidateJobStatusUseCase = new SetApplicationStatus(
    new JobDashboardRepositoryImpl(new JobDashboardAPIDataSourceImpl())
  )
  async function getJobApplications(job_id: string) {
    setLoading(true)
    const respose = await getJobApplicationsUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
    })

    if (respose.success) {
      setLoading(false)
      setJobApplications(respose.data)
    }
    setLoading(false)
  }

  async function setJobApplicationStatus(email: string, idx: number) {
    const job_id = window.location.pathname.split("/")[4]

    const response = await updateCandidateJobStatusUseCase.invoke({
      id_token: auth.id_token,
      job_id: job_id,
      email: email,
      status: idx?.toString(),
    })

    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response.data)
      getJobApplications(job_id)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.data)
    }
  }

  function onChangeJobStatus(e: any, idx: number, email: string) {
    const value = e.target.value
    const newSelectJobStates = [...selectJobStates]
    newSelectJobStates[idx] = { key: idx, value }
    setSelectJobStates(newSelectJobStates)
    const index = jobStatus.findIndex((status) => status === newSelectJobStates[idx].value)
    setJobApplicationStatus(email, index + 1)
  }

  return {
    toast,
    loading,
    jobApplications,
    jobStatus,
    selectJobStates,
    onChangeJobStatus,
    getJobApplications,
    setJobApplicationStatus,
    changeToastVisibility,
  }
}
