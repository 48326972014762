import { format } from "date-fns"

export function formatDate(date: Date) {
  return date.toISOString().split("T")[0]
}

export function toDate(timestamp: number) {
  return format(timestamp, "dd/MM/yyyy")
}

export function toTime(timestamp: number) {
  return format(timestamp, "hh:mm a")
}

export function toDateTime(timestamp: number) {
  return format(timestamp, "hh:mm a, dd/MM/yyyy")
}

export function minsDiff(start: number, end: number) {
  return Math.round((end - start) / 1000 / 60)
}
