import { cn } from "core/lib/utils"
import React, { useState } from "react"
import { CheckSuccessIcon } from "core/constants/svgs"
import Spinner from "core/components/Spinner"
import RemoveFilePopup from "./RemoveFilePopup"
import BlankCircle from "../assets/BlankCircle"
import TickCircle from "../assets/TickCircle"
import CrossCircle from "../assets/CrossCircle"
import CheckBox from "../assets/CheckBox"
import CheckBoxTick from "../assets/CheckBoxTick"
import Bin from "../assets/Bin"
import BinActive from "../assets/BinActive"

export default function UploadFiles({
  month,
  allFilesStatus,
  studentEMI,
  paymentDocuments,
  getPaymentDocuments,
  uploadPaymentDocument,
  deletePaymentDocument,
}: any) {
  const header = [
    <CheckSuccessIcon className="h-4 w-4 text-new-solid-white" />,
    "Document",
    "Remove File",
    "Admin Approval",
    "Upload Document",
  ]
  const documentIds = ["Aadhar Card", "Bank Detail", "Pan Card", "Payslip Detail", "Photo"]
  const [loading, setLoading] = useState<number>(-1)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [fileIndexToRemove, setFileIndexToRemove] = useState<number>(-1)

  const handleRemoveFile = async (index: number) => {
    setFileIndexToRemove(index)
    setShowPopup(true)
    await getPaymentDocuments()
  }

  const handleCancelRemove = () => {
    setShowPopup(false)
    setFileIndexToRemove(-1)
  }

  const handleConfirmRemove = async () => {
    setLoading(fileIndexToRemove)
    setShowPopup(false)
    await deletePaymentDocument(fileIndexToRemove)
    await getPaymentDocuments()
    setFileIndexToRemove(-1)
    setLoading(-1)
  }

  const handleChangeFile = async (e: any, index: number) => {
    setLoading(index)
    await uploadPaymentDocument(e.target.files[0], paymentDocuments[index].filename, index)
    await getPaymentDocuments()
    setLoading(-1)
  }

  return (
    <React.Fragment>
      {showPopup && (
        <RemoveFilePopup
          paymentDocuments={paymentDocuments}
          index={fileIndexToRemove}
          onCancel={handleCancelRemove}
          onConfirm={handleConfirmRemove}
        />
      )}

      <div className="mt-6">
        <div className="text-lg font-semibold text-[#312E81]">
          EMI {month} months
          {allFilesStatus ? (
            <span className="text-[#00C22B]"> (Upload Completed) </span>
          ) : (
            <span className="text-[#DC2626]"> (Upload Pending) </span>
          )}
          :
        </div>
        <div className="mb-6 text-sm font-normal leading-[160%] text-[#414141]">
          In order to proceed with EMI option please upload the necessary documents.
        </div>
        <div className={cn("relative overflow-x-auto rounded shadow-[0_0_10px_0_rgba(141,141,141,0.4)]")}>
          <table className="w-full text-center text-sm text-new-neutral-dark">
            <thead className="bg-new-editor-dark-500 text-new-solid-white">
              <tr>
                {header?.map((title: any, i: any) => (
                  <th
                    key={i}
                    className={cn(
                      "py-3 text-sm font-semibold leading-[17px]",
                      i === 1 ? "w-[20%] text-left" : "text-center",
                      i === 0 && "flex justify-center py-2 pr-4 pl-3",
                      i === 3 && "w-[20%]",
                      i === 4 && "text-right pr-10"
                    )}
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paymentDocuments.map((file: any, index: number) => (
                <tr className="bg-new-solid-white text-center hover:bg-[#F5F5F5]" key={index}>
                  <td className="flex justify-center py-5 pr-4 pl-3">
                    {loading === index ? (
                      <Spinner xs />
                    ) : file.is_uploaded === 1 ? (
                      <TickCircle />
                    ) : studentEMI.is_submitted === true ? (
                      <CrossCircle />
                    ) : (
                      <BlankCircle />
                    )}
                  </td>
                  <td
                    className={cn(
                      "py-5 text-left font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark"
                    )}
                  >
                    {file?.link ? (
                      <a className="underline" href={file.link} target="blank">
                        {documentIds[index]}
                      </a>
                    ) : (
                      <div>{file.filename}</div>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      disabled={
                        file.is_verified !== 1 || file.is_uploaded !== 0
                          ? studentEMI.is_submitted === true &&
                            (studentEMI.progress[2].status !== -1 || file.is_verified === 1)
                          : false
                      }
                    >
                      {file.is_verified !== 1 && file.is_uploaded !== 0 ? (
                        studentEMI.is_submitted === true && studentEMI.progress[2].status !== -1 ? (
                          <Bin />
                        ) : (
                          <BinActive />
                        )
                      ) : (
                        <Bin />
                      )}
                    </button>
                  </td>
                  <td className="flex justify-center py-5">
                    {file.is_verified === 1 ? (
                      <CheckBoxTick />
                    ) : file.is_verified === 0 ? (
                      <CheckBox />
                    ) : (
                      <CrossCircle />
                    )}
                  </td>
                  <td className="text-right pr-10 py-5">
                    <label
                      className={cn(
                        "text-sm font-medium leading-normal underline cursor-pointer",
                        (
                          file.is_verified === 1 || file.is_uploaded !== 0
                            ? true
                            : studentEMI.is_submitted === true && studentEMI.progress[2].status === 1
                        )
                          ? "text-[#ADADAD]"
                          : "text-[#312E81]"
                      )}
                    >
                      Upload Document
                      <input
                        type="file"
                        className="hidden"
                        accept="application/pdf"
                        disabled={
                          file.is_verified === 1 || file.is_uploaded !== 0
                            ? true
                            : studentEMI.is_submitted === true && studentEMI.progress[2].status === 1
                        }
                        onChange={(e) => handleChangeFile(e, index)}
                      />
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}
