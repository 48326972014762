import Spinner from "core/components/Spinner"
import { Button } from "core/components/v2/Button"
import { CrossIcon } from "core/constants/svgs"
import { useState } from "react"

export default function ConfirmationPopUpMentorRemoval(props: any) {
  const [loading, setLoading] = useState(false)
  const handleOperationalIssue = async () => {
    setLoading(true)
    await props.updateStudentsMentor()
    setLoading(false)
    props.setIsOpen(false)
  }

  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setIsOpen(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
          <div className="m-auto w-[350px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            {/* content goes here */}
            <div className="relative flex">
              <div className="text-[16px] font-[500] text-[#333]">Confirm Change</div>
              <button onClick={() => props.setIsOpen(false)} className="absolute right-0 mt-[3px]">
                <CrossIcon className="h-6 w-6 text-[#312E81]" />
              </button>
            </div>

            <div className="mt-[16px] text-[12px] font-[400] text-[#414141]">{props.heading}</div>
            <div className="mt-[8px] flex justify-end">
              <Button onClick={handleOperationalIssue} size="sm" disabled={loading}>
                {loading ? <Spinner small={true} /> : "Confirm"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
