import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface GetEligibleMentorsUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetEligibleMentors implements GetEligibleMentorsUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getEligibleMentors(auth)
  }
}
