import { Button } from "core/components/v2/Button"
import { STR_FAILURE } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import React from "react"

const CancelReasonModal = ({
  changeToastDetails,
  changeToastVisibility,
  cancelReason,
  setCancelReason,
  setCancelPopUpModal,
  activeSessionIndex,
  isOpen,
  onClose,
}: any) => {
  if (!isOpen) return null
  return (
    <div className="relative z-30">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0"></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative bg-white w-[450px] h-[535px] p-[24px] rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="flex justify-between">
            <div>
              <p className="text-[#DC2626] text-[16px] font-[600]">Mentor session Cancellation</p>
              <p>Mentor Session {activeSessionIndex + 1}</p>
            </div>
            <button className="hover:bg-slate-200 rounded-[4px] size-[24px]" onClick={onClose}>
              <CrossIcon className="size-[24px]" />
            </button>
          </div>

          <p className="text-[14px] font-[600] text-[#333] my-[16px]">
            Cancellation Reason <span className="text-[#DC2626]">*</span>
          </p>

          <textarea
            placeholder="Write your reason here..."
            className="h-[195px] w-full border-[#D3D3D3] focus:border-[#D3D3D3] rounded-[4px]"
            value={cancelReason}
            onChange={(e: any) => setCancelReason(e.target.value)}
          />

          <div className="absolute bottom-0 left-0 rounded-b-[4px] flex justify-end w-full h-[59px] bg-[#F9F9F9] px-[24px] py-[12px]">
            <Button
              onClick={() => {
                if (!cancelReason) {
                  changeToastDetails(STR_FAILURE, "Reason is required")
                  changeToastVisibility(true)
                  return
                }
                setCancelPopUpModal(true)
              }}
              size={"sm"}
              variant={"primary"}
              className="px-[16px] py-[9px]"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancelReasonModal
