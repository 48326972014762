import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import BasicCard from "./BasicCard"

const BasicModal = ({ ModalTitle, data, handleModalClose, type }: any) => {
  function convertMinutesToTimeFormat(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
  }
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#0c0c0c] bg-opacity-80">
      <div className="relative h-fit w-[770px] space-y-4 rounded-lg bg-white p-6">
        <div className="space-y-4">
          <p className="text-base font-semibold text-[#333]">{ModalTitle}</p>
          <div className="absolute top-2 right-12">
            <button className="fixed rounded hover:bg-[#e3e3e3]" onClick={handleModalClose}>
              <CloseSharpIcon />
            </button>
          </div>
        </div>
        <div className="gray-scrollbar flex min-w-[722px] gap-4 overflow-x-auto bg-white pb-2">
          {data?.map((data: any, index: number) => (
            <BasicCard
              step={`${index > 9 ? "0" + (index + 1) : "00" + (index + 1)}`}
              heading={data.name}
              description={`Duration: ${convertMinutesToTimeFormat(data.video_len)} Mins`}
              btnType="video"
              link={`/home/${type}/${data.video_id}?h=${data.video_secret || "default"}`}
              disabled={true}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BasicModal
