import { CreateSSMCall } from "domain/model/CreateSSMCall"
import { CreateSSMCallRepository } from "domain/repository/Admin/CreateSSMCallRepository"

export interface CreateSSMCallUseCase {
  invoke(createSSMCall: CreateSSMCall): Promise<any>
}

export class CreateSSMCalls implements CreateSSMCallUseCase {
  private repository: CreateSSMCallRepository

  constructor(repository: CreateSSMCallRepository) {
    this.repository = repository
  }

  async invoke(createSSMCall: CreateSSMCall) {
    return await this.repository.createSSMCall(createSSMCall)
  }
}
