import {
  API_V3_ENABLE_IS_INSTRUCTOR,
  API_V3_GET_ALL_MENTORS_DETAILS,
  API_V3_MENTORPROFILE,
  API_V3_UPDATE_MENTOR_DISABLE_STATE,
  API_v3_UPDATE_MENTOR_PROFILE,
  API_V3_ADMIN,
  API_V4_ADMIN,
  API_GET_MENTORS_DETAIL_LIST,
  API_V3_UPDATEMENTEESMENTOR,
  API_UPLOAD_MENTOR_IMAGE,
  API_DELETE_MENTOR_IMAGE,
  API_V3_DELETE_MENTEE,
  API_TOGGLE_GOAL_SETTING_MENTOR,
  API_TOGGLE_LOCK_MENTOR,
} from "core/constants/strings"
import { postAPI, postAPIForFiles, deleteAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import {
  disableMentors,
  enableInstructors,
  mentorProfile,
  MentorsDetails,
  updateMentorProfile,
} from "domain/model/MentorDashboard"
import MentorDashboardDataSource from "data/dataSource/Admin/MentorDashboardDataSource"
import { Auth } from "domain/model/Auth"

export class MentorDashboardAPIDataSourceImpl implements MentorDashboardDataSource {
  async getAllMentorsDetails(details: MentorsDetails): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V3_GET_ALL_MENTORS_DETAILS), {
      admin_uid: details,
    })
    return response
  }

  async disableMentor(disabled: disableMentors): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V3_UPDATE_MENTOR_DISABLE_STATE), disabled)
    return response
  }

  async enableInstructors(enabled: enableInstructors): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V3_ENABLE_IS_INSTRUCTOR), enabled)
    return response
  }

  async getMentorProfile(id: mentorProfile): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V3_MENTORPROFILE), id)
    return response
  }

  async updateMentorProfiles(id: updateMentorProfile): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_v3_UPDATE_MENTOR_PROFILE), id)
    return response
  }

  async getMentorsDetailList(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTORS_DETAIL_LIST), {
      id_token: auth?.id_token,
    })
    return response
  }

  async updateMenteesMentor(id: any): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V3_UPDATEMENTEESMENTOR), id)
    return response
  }

  async deleteMentee(email: string, mentor_email: any): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V3_DELETE_MENTEE), { email, mentor_email })
    return response
  }

  async uploadMentorImage(auth: Auth, profile_picture: File): Promise<any> {
    try {
      if (!profile_picture) {
        throw new Error("File object is invalid or empty.")
      }

      const data = new FormData()
      data.append("id_token", auth.id_token)
      data.append("img", profile_picture)

      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_UPLOAD_MENTOR_IMAGE), data)

      return response
    } catch (error) {
      return error
    }
  }
  async deleteMentorImage(auth: Auth, imageUrl: string): Promise<any> {
    try {
      const response = await deleteAPI(pn(API_V4_ADMIN, API_DELETE_MENTOR_IMAGE), {
        id_token: auth?.id_token,
        imageUrl,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async toggleGoalSettingMentor(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_TOGGLE_GOAL_SETTING_MENTOR), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async toggleLockMentor(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_TOGGLE_LOCK_MENTOR), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }
}
