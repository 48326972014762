import React from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import ContestAPIDataSourceImpl from "data/API/Student/ContestAPIDataSourceImpl"
import ContestRepositoryImpl from "data/repository/Student/ContestRepositoryImpl"
import GetAllContest from "domain/useCase/Student/Assessment/GetAllContest"
import { STR_FAILURE } from "core/constants/strings"
import { SuccessCheckboxIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"

export default function ContestViewModel() {
  const [tableData, setTableData] = React.useState<any>({
    header: [<SuccessCheckboxIcon className="h-4 w-4 text-[#FAFAFA]" />, "Name", "Date", "Time", "Link", "Exam Result"],
    rows: [],
  })
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [allContest, setAllContest] = useLocalStorage<any>("contest", {})
  const [loading, setLoading] = React.useState<boolean>(true)

  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const GetAllContestUseCase = new GetAllContest(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  async function getAllContest() {
    setLoading(true)

    const response = await GetAllContestUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }
    setAllContest(response?.data)
    const tabType = ["upcoming", "recorded"][activeButtonType]
    setTableData({ ...tableData, rows: response?.data[tabType] })
    setLoading(false)
  }

  function changeActiveButtonType(index: number) {
    const tabType = ["upcoming", "recorded"][index]
    setTableData({ ...tableData, rows: allContest[tabType] })
    setActiveButtonType(index)
  }

  return {
    tableData,
    allContest,
    loading,
    activeButtonType,
    toast,
    refreshed,
    changeActiveButtonType,
    getAllContest,
    changeToastVisibility,
    setActiveButtonType,
  }
}
