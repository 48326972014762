import { cn } from "core/lib/utils"
import { Fragment } from "react"

export default function QuestionView({
  header = true,
  responseTabs = true,
  question,
  activeResposeTab,
  setActiveResposeTab,
}: any) {
  return (
    <Fragment>
      {header && (
        <div className="border-b border-new-neutral-light pb-2 text-2xl font-semibold">
          {question?.questionType} Question
        </div>
      )}
      {responseTabs && question?.questionType === "Case Study" && (
        <div className="flex flex-wrap justify-start items-center gap-4">
          {question?.response?.map((_: any, index: number) => (
            <div
              key={index}
              className={cn(
                "py-1 px-6 bg-new-editor-dark-500 border border-new-neutral-light cursor-pointer rounded-sm",
                activeResposeTab === index ? "border-success text-success" : ""
              )}
              onClick={() => setActiveResposeTab(index)}
            >
              {index + 1}
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-col gap-2 pb-2 rounded-sm border-b border-dashed border-new-neutral-light">
        <h2>{question?.questionTitle}</h2>
        <div className="flex gap-4">
          <p>
            <strong>Topic: </strong> {question?.topic}
          </p>
          <p>
            <strong>Difficulty: </strong>
            <span
              className={
                question?.difficultyLevel === "easy"
                  ? "text-success"
                  : question?.difficultyLevel === "medium"
                    ? "text-orange-400"
                    : "text-failure"
              }
            >
              {question?.difficultyLevel?.toUpperCase()}
            </span>
          </p>
          <p>
            <strong>Max Marks: </strong>
            {parseInt(question?.coins) / 5}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4 pl-0">
        <p>
          <strong className="text-lg">Problem Statement:</strong>
        </p>
        {question?.fields?.map((field: any, index: number) => {
          if (field.type === "text") {
            return (
              <div
                key={index}
                className="whitespace-pre-line break-words text-justify"
                dangerouslySetInnerHTML={{ __html: field?.content }}
              />
            )
          } else if (field.type === "code") {
            return <pre className="p-2 bg-new-editor-dark-500 rounded-sm">{field?.content}</pre>
          } else if (field.type === "image") {
            return (
              <img
                src={field?.content}
                alt="img"
                title={field?.filename}
                onClick={() => window.open(field?.content)}
                className="cursor-pointer rounded-sm"
              />
            )
          }
          return <></>
        })}
      </div>
    </Fragment>
  )
}
