import React from "react"

export default function Group(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_746_14007)">
        <path
          d="M1.3335 14.667C1.3335 13.2525 1.8954 11.896 2.89559 10.8958C3.89579 9.89556 5.25234 9.33366 6.66683 9.33366C8.08132 9.33366 9.43787 9.89556 10.4381 10.8958C11.4383 11.896 12.0002 13.2525 12.0002 14.667H10.6668C10.6668 13.6061 10.2454 12.5887 9.49526 11.8386C8.74511 11.0884 7.7277 10.667 6.66683 10.667C5.60596 10.667 4.58855 11.0884 3.8384 11.8386C3.08826 12.5887 2.66683 13.6061 2.66683 14.667H1.3335ZM6.66683 8.66699C4.45683 8.66699 2.66683 6.87699 2.66683 4.66699C2.66683 2.45699 4.45683 0.666992 6.66683 0.666992C8.87683 0.666992 10.6668 2.45699 10.6668 4.66699C10.6668 6.87699 8.87683 8.66699 6.66683 8.66699ZM6.66683 7.33366C8.14016 7.33366 9.3335 6.14033 9.3335 4.66699C9.3335 3.19366 8.14016 2.00033 6.66683 2.00033C5.1935 2.00033 4.00016 3.19366 4.00016 4.66699C4.00016 6.14033 5.1935 7.33366 6.66683 7.33366ZM12.1895 9.80233C13.1264 10.2243 13.9215 10.9078 14.4792 11.7708C15.037 12.6338 15.3336 13.6395 15.3335 14.667H14.0002C14.0003 13.8963 13.7779 13.142 13.3596 12.4947C12.9412 11.8474 12.3449 11.3348 11.6422 11.0183L12.1888 9.80233H12.1895ZM11.7308 2.27566C12.4025 2.55252 12.9768 3.02268 13.3808 3.62648C13.7849 4.23027 14.0004 4.94049 14.0002 5.66699C14.0004 6.58188 13.6586 7.46381 13.0419 8.13954C12.4251 8.81528 11.5779 9.23597 10.6668 9.31899V7.97699C11.1608 7.90625 11.6191 7.67901 11.9744 7.32863C12.3297 6.97826 12.5633 6.52321 12.6409 6.03028C12.7186 5.53736 12.6361 5.03252 12.4058 4.58989C12.1754 4.14727 11.8091 3.79016 11.3608 3.57099L11.7308 2.27566Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_746_14007">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
