import React from "react"
import { useAuth } from "core/context/auth"
import { useParams, useNavigate } from "react-router-dom"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import GetContestDetails from "domain/useCase/User/Contest/GetContestDetails"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import ContestAPIDataSourceAdminImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import ContestRepositoryAdminImpl from "data/repository/Admin/ContestRepositoryImpl"
import GetContestStudentList from "domain/useCase/Admin/Contest/GetContestStudentList"
import GetContestSSMSubmissionEnableStatus from "domain/useCase/Admin/Contest/GetContestSSMSubmissionEnableStatus"
import GetContestFeedbackDetails from "domain/useCase/Admin/Contest/GetContestFeedbackDetails"
import GetStudentContestFeedbackResponse from "domain/useCase/Admin/Contest/GetStudentContestFeedbackResponse"

export default function ContestSubmissionViewModel() {
  const [studentData, setStudentData] = React.useState([])
  const [selectedContest, setSelectedContest] = React.useState("")
  // const [selectedBatch, setSelectedBatch] = React.useState("")
  const [date, setDate] = React.useState("")
  const [contestData, setContestData] = React.useState<any>([])
  const [page, setPage] = React.useState(1)
  const [totalDataList, setTotalDataList] = React.useState(0)
  const [selectedQuestion, setSelectedQuestion] = React.useState(0)
  const [student_email, setStudentEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [feedbackResponse, setFeedbackResponse] = React.useState<any>([])
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false)
  const [contest_timestamp, setContestTimestamp] = React.useState<any>([])
  const [buttonStatus, setButtonStatus] = React.useState<any>([])
  const { id } = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const pageLimit = 30
  //new
  const [selectedQuestionType, setSelectedQuestionType] = React.useState("Coding")
  const [selectedMainQuestion, setSelectedMainQuestion] = React.useState(0)

  const handleQuestionTypeClick = (type: any) => {
    setSelectedQuestionType(type)
    setSelectedMainQuestion(0)
    handleSelectedQuestionBtnClick(0)
  }

  const handleMainQuestionClick = (index: any) => {
    setSelectedMainQuestion(index)
    handleSelectedQuestionBtnClick(index)
  }

  const getContestDetailsUseCase = new GetContestDetails(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const getContestStudentListUseCase = new GetContestStudentList(
    new ContestRepositoryAdminImpl(new ContestAPIDataSourceAdminImpl())
  )

  // const getContestFeedbackDetailsUseCase = new GetContestFeedbackDetails(
  //   new ContestRepositoryAdminImpl(new ContestAPIDataSourceAdminImpl())
  // )

  const getContestFeedbackResponseUseCase = new GetStudentContestFeedbackResponse(
    new ContestRepositoryAdminImpl(new ContestAPIDataSourceAdminImpl())
  )
  const GetContestSSMSubmissionEnableStatusUseCase = new GetContestSSMSubmissionEnableStatus(
    new ContestRepositoryAdminImpl(new ContestAPIDataSourceAdminImpl())
  )

  const order = {
    rating: "Question Rating",
    marks: "Contest Marks",
    feedback_response: "SSM Feedback",
    code_snippet: "Code Snippet",
    files: "References",
  }

  const contest_id = id

  async function fetchContestSSMAndSubmissionStatus(student_email: string, contest_id: string, index: number) {
    const response = await GetContestSSMSubmissionEnableStatusUseCase.invoke(auth, student_email, contest_id)
    //NOT GOING IN FROM HERE
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.message)
      changeToastVisibility(true)
      return
    }
    setButtonStatus((prevState: any) => ({
      ...prevState,
      [index]: response?.data,
    }))
  }

  const fetchFeedbackResponse = async (student_email: string) => {
    if (!contest_id) {
      changeToastDetails(STR_FAILURE, "Contest ID not found")
      changeToastVisibility(true)
      return
    }
    const response = await getContestFeedbackResponseUseCase.invoke(auth, student_email, contest_id)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Feedbaack response not found")
      changeToastVisibility(true)
      return
    }
    setFeedbackResponse(response?.data?.feedback_response)

    setContestTimestamp(response?.data?.contest_timestamp)
    changeToastDetails(STR_SUCCESS, "Feedback response found")
    changeToastVisibility(true)
  }

  const fetchContestDetails = async () => {
    if (!contest_id) {
      changeToastDetails(STR_FAILURE, "Contest ID not found")
      changeToastVisibility(true)
      return
    }
    const response = await getContestDetailsUseCase.invoke(auth, contest_id)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.message)
      changeToastVisibility(true)
      return
    }
    const contests = response?.data
    setContestData(contests)
    // setSelectedBatch(response.data.batches[0])
    // if (response.data.batches[0] !== "") {
    fetchAllStudentList(contest_id, page, pageLimit)
    // }
  }
  const fetchAllStudentList = async (
    contest_id: any,
    // batch: string,
    page: number,
    pageLimit: number
  ) => {
    setLoading(true)
    const response = await getContestStudentListUseCase.invoke(
      auth,
      contest_id,
      // batch,
      page,
      pageLimit
    )
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error || "Failed to fetch student list")
      changeToastVisibility(true)
      setLoading(false)
      return
    }
    setStudentData(response?.student_list || [])
    setSelectedContest(response?.student_list[0]?.["contest_name"])
    setDate(response?.student_list[0]?.["contest_date"])
    setTotalDataList(response?.total_length || 0)
    setLoading(false)
  }

  // const handleSelectBatchClick = (item: any) => {
  //   setSelectedBatch(item)
  //   // fetchAllStudentList(contest_id, batch, 1, pageLimit)
  // }

  const handleNavigateClick = () => {
    navigate(`/admin/contests`)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    fetchAllStudentList(contest_id, page, pageLimit)
  }

  const handleSubmissionClick = (urlpath: string, student_email: string) => {
    // http://localhost:3000/admin/contests/tBt2MPuMmtDqUtJGjSTE/view/divyam.dubey@bosscoderacademy.com
    const path = urlpath.includes(student_email) ? urlpath : `${urlpath}/${student_email}`
    navigate(path)
  }
  const handleSSMFeedback = async (student_email: string) => {
    await fetchFeedbackResponse(student_email)
    setStudentEmail(student_email)
    setOpenFeedbackModal(true)
  }

  const handleCloseBtnClick = () => {
    setOpenFeedbackModal(false)
  }
  const handleSelectedQuestionBtnClick = (number: number) => {
    setSelectedQuestion(number)
  }

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    changeToastDetails(STR_SUCCESS, "Copied to clipboard")
    changeToastVisibility(true)
  }

  const getDate = (timestamp: number) => {
    const DateObj = new Date(timestamp)
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const month = monthNames[DateObj.getMonth()]
    return `${DateObj.getDate()} ${month} ${DateObj.getFullYear()}`
  }

  return {
    selectedContest,
    date,
    fetchAllStudentList,
    // handleSelectBatchClick,
    studentData,
    toast,
    changeToastVisibility,
    fetchContestDetails,
    handleNavigateClick,
    contestData,
    page,
    handlePageChange,
    totalDataList,
    pageLimit,
    loading,
    // selectedBatch,
    handleSubmissionClick,
    getDate,
    handleCopyToClipboard,
    buttonStatus,
    fetchContestSSMAndSubmissionStatus,
    handleSSMFeedback,
    feedbackResponse,
    handleCloseBtnClick,
    handleSelectedQuestionBtnClick,
    selectedQuestion,
    openFeedbackModal,
    student_email,
    contest_timestamp,
    order,
    handleQuestionTypeClick,
    handleMainQuestionClick,
    selectedQuestionType,
    selectedMainQuestion,
    // setSelectedQuestion,
  }
}
