import { Auth } from "domain/model/Auth"
import { NotificationRepository } from "domain/repository/Student/NotificationRepository"
import NotificationDataSource from "data/dataSource/Student/NotificationDataSource"

export class NotificationRepositoryImpl implements NotificationRepository {
  private dataSource: NotificationDataSource

  constructor(dataSource: NotificationDataSource) {
    this.dataSource = dataSource
  }

  async getNotificationDetails(auth: Auth): Promise<any> {
    return this.dataSource.getNotificationDetails(auth)
  }

  async updateNotificationRead(auth: Auth, type: any): Promise<any> {
    return this.dataSource.updateNotificationRead(auth, type)
  }
}
