export default function Bill({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 14.6673H13.3333C13.7015 14.6673 14 14.3688 14 14.0007V2.00065C14 1.63246 13.7015 1.33398 13.3333 1.33398H2.66667C2.29848 1.33398 2 1.63246 2 2.00065V14.0007C2 14.3688 2.29848 14.6673 2.66667 14.6673ZM12.6667 2.66732V13.334H3.33333V2.66732H12.6667ZM5.33333 6.00065H10.6667V7.33398H5.33333V6.00065ZM10.6667 8.66732H5.33333V10.0007H10.6667V8.66732Z"
        fill="currentColor"
      />
    </svg>
  )
}
