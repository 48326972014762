import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import MyMenteeAPIDataSourceImpl from "data/API/Mentor/MyMenteeAPIDataSourceImpl"
import { MyMenteeRepositoryImpl } from "data/repository/Mentor/MyMenteeRepositoryImpl"
import { format } from "date-fns"
import { addMentorFeedback } from "domain/useCase/Mentor/MyMentee/AddMentorFeedback"
import { allowExtraMentorSession } from "domain/useCase/Mentor/MyMentee/AllowExtraMentorSession"
import { GetMentorMenteeSessions } from "domain/useCase/Mentor/MyMentee/GetMenteeMentorSessions"
import React from "react"
import useMentorViewModel from "../../Student/Mentor/MentorViewModel"

export default function MyMenteeDetialsPageViewModel() {
  const { getMentorNameFromId } = useMentorViewModel()

  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const upcomingSessionsTableHeaders = ["Batch", "Mentor Name", "Date and Time ", "Status", "Join Meeting"]
  const [tableContent, setTableContent] = React.useState<any>({
    header: ["Mentor Name", "Date & Time", `Mentor's Feedback`, `Student's Review`],
    data: [],
  })
  const [mentorSessionData, setMentorSessionData] = React.useState<any>([])
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [extraSessionModel, setExtraSessionModel] = React.useState(false)
  function changeActiveButtonType(index: number) {
    setActiveButtonType(index)
    handleFinishedMentorSessions()
  }
  const getMenteeMentorSessionsUseCase = new GetMentorMenteeSessions(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const addMentorFeedbackUseCase = new addMentorFeedback(new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl()))
  const allowExtraMentorSessionUseCase = new allowExtraMentorSession(
    new MyMenteeRepositoryImpl(new MyMenteeAPIDataSourceImpl())
  )
  const BATCHES_TO_IGNORE_NMF = ["NLDSA B24", "BCA-Recorded"]

  async function getMenteeMentorSessions(email: string) {
    const response = await getMenteeMentorSessionsUseCase.invoke({
      id_token: auth.id_token,
      student_email: email,
    })
    if (response.success) {
      setMentorSessionData(response.data)
      handleFinishedMentorSessions()
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }
  function confirmEnableExtraSession() {
    setExtraSessionModel(!extraSessionModel)
  }
  async function addMentorFeedbacks(comment: string, room_id: string, actionItem: string) {
    await addMentorFeedbackUseCase.invoke({
      id_token: auth.id_token,
      feedback: comment,
      action_items: actionItem,
      room_id: room_id,
    })
  }
  function changeTableContent(data: any[]) {
    setTableContent((content: any) => ({ ...content, data: data }))
  }

  async function enableExtraMentorSession(email: string) {
    const response = await allowExtraMentorSessionUseCase.invoke(auth, email)
    setExtraSessionModel(!extraSessionModel)
    if (response.success) {
      changeToastDetails(STR_SUCCESS, "SESSION ENABLED")
    } else {
      changeToastDetails(STR_FAILURE, "ERROR")
    }
    changeToastVisibility(true)
  }

  async function handleFinishedMentorSessions() {
    const finishedSessionsData = await Promise.all(
      mentorSessionData?.response?.finished?.map(async (item: any) => {
        // const mentorDetails: any = await getMentorNameFromId(item?.mentor_id);
        // const mentorName = mentorDetails?.name;
        return [
          item?.mentor_name,
          format(new Date(item?.start_timestamp * 1000), "dd MMMM yyyy h:mm a"),
          item?.student_review,
          item?.mentor_feedback,
        ]
      }) || []
    )
    changeTableContent(finishedSessionsData)
  }
  return {
    activeButtonType,
    mentorSessionData,
    upcomingSessionsTableHeaders,
    tableContent,
    extraSessionModel,
    toast,
    BATCHES_TO_IGNORE_NMF,
    changeToastVisibility,
    enableExtraMentorSession,
    confirmEnableExtraSession,
    setExtraSessionModel,
    changeActiveButtonType,
    getMenteeMentorSessions,
    handleFinishedMentorSessions,
    addMentorFeedbacks,
    changeToastDetails,
  }
}
