import { Button } from "core/components/v2/Button"
import { Input } from "core/components/v2/Input"
import { useState } from "react"
import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import HomeAPIDataSourceImpl from "data/API/Student/HomeAPIDataSourceImpl"
import { HomeRepositoryImpl } from "data/repository/Student/HomeRepositoryImpl"
import { CreatePledgeResponse } from "domain/useCase/Student/Home/CreatePledgeResponse"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import Cross from "assets/svgs/Cross"
import StandardSelect from "core/components/v2/StandardSelect"
import { CrossIcon } from "lucide-react"

export default function PledgeModal({ isOpen, onClose, children, width, height }: any) {
  if (!isOpen) return null

  const { auth } = useAuth()
  const { student, setStudent } = useApp()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [pledgeName, setPledgeName] = useState<string>("")
  const [pledgeRole, setPledgeRole] = useState<CourseRole>("")
  const [pledgeCompany, setPledgeCompany] = useState<string>("")
  const [secretClose, setSecretClose] = useState<boolean>(false)
  const [reasonModal, setReasonModal] = useState<Boolean>(false)
  // const roleArray = ["SDE", "Data Analyst", "Data Scientist", "Data Engineer"]
  const roleArray = ["SDE", "Data Analyst", "Data Scientist", "Data Engineer"]
  type Role = (typeof roleArray)[number] // Create a union type of roles

  // Define a mapping of course roles to specific topics
  const CourseMap: Record<string, CourseRole> = {
    SDE: "software_developer",
    "Data Scientist": "data_scientist",
    "Data Analyst": "data_analyst",
    "Data Engineer": "data_engineer",
    "": "",
  }

  const [checkBox, setCheckBox] = useState<{ [key: string]: boolean }>({
    "1": false,
    "2": false,
    "3": false,
    "4": false,
  })

  interface Topic {
    key: string
    value: string
  }
  interface CheckoutBoxTopics {
    software_developer: Topic[]
    data_scientist: Topic[]
    data_analyst: Topic[]
    data_engineer: Topic[]
    "": Topic[]
  }

  const checkoutBoxTopics: CheckoutBoxTopics = {
    software_developer: [
      {
        key: "Solve questions regularly",
        value: "I will dedicate time each day to practice solving problems.",
      },
      {
        key: "Attend live classes regularly",
        value: "I will make a consistent effort to attend all live classes.",
      },
      {
        key: "Be consistent in my learning path",
        value: "I will maintain a steady pace in my learning journey.",
      },
      {
        key: "Stay focused towards my goal",
        value: "I will keep my ultimate goal in mind and work diligently to achieve it.",
      },
    ],
    data_scientist: [
      {
        key: "Deepen my understanding of ML algorithms",
        value: "I will explore various algorithms and their applications to real-world problems.",
      },
      {
        key: "Build and experiment with ML models",
        value: "I will actively practice building and refining machine learning models to improve their performance.",
      },
      {
        key: "Master data pre-processing and feature engineering",
        value: "I will focus on preparing data for analysis and creating meaningful features.",
      },
      {
        key: "Stay updated with the latest advancements in data science",
        value: "I will continuously learn about new research, techniques, and tools in the Data Science  field.",
      },
    ],
    data_analyst: [
      {
        key: "Explore and analyze data",
        value: "I will actively seek out datasets and practice analyzing them to uncover valuable insights.",
      },
      {
        key: "Master data visualization techniques",
        value: "I will strive to create clear and effective visualizations to communicate my findings effectively.",
      },
      {
        key: "Develop strong problem-solving skills",
        value: "I will tackle data-related challenges with a logical and analytical approach.",
      },
      {
        key: "Stay updated with industry trends",
        value: "I will continuously learn about new tools, techniques, and best practices in data analytics.",
      },
    ],
    data_engineer: [
      {
        key: "Master data pipelines and ETL processes",
        value: "I will focus on building efficient and scalable data pipelines to extract, transform, and load data.",
      },
      {
        key: "Develop proficiency in cloud platforms and tools",
        value: "I will explore cloud platforms like AWS, GCP, or Azure.",
      },
      {
        key: "Enhance my skills in data warehousing and data modeling",
        value: "I will deepen my understanding of data warehousing concepts and design effective data models.",
      },
      {
        key: "Stay updated with emerging technologies and trends",
        value: "I will continuously learn about new tools, techniques, and best practices in data engineering.",
      },
    ],
    "": [
      {
        key: "Solve questions regularly",
        value: "I will dedicate time each day to practice solving problems.",
      },
      {
        key: "Attend live classes regularly",
        value: "I will make a consistent effort to attend all live classes.",
      },
      {
        key: "Be consistent in my learning path",
        value: "I will maintain a steady pace in my learning journey.",
      },
      {
        key: "Stay focused towards my goal",
        value: "I will keep my ultimate goal in mind and work diligently to achieve it.",
      },
    ],
  }

  // Toggle checkbox value
  const handleCheckboxChange = (key: string) => {
    setCheckBox((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  const CreatePledgeResponseUseCase = new CreatePledgeResponse(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  const createPledgeResponse = async () => {
    if (
      pledgeName == "" ||
      pledgeCompany == "" ||
      pledgeRole == "" ||
      checkBox["1"] == false ||
      checkBox["2"] == false ||
      checkBox["3"] == false ||
      checkBox["4"] == false
    ) {
      if (pledgeName == "") {
        changeToastDetails(STR_FAILURE, "Name Required")
      } else if (pledgeRole == "") {
        changeToastDetails(STR_FAILURE, "Role Required")
      } else if (pledgeCompany == "") {
        changeToastDetails(STR_FAILURE, "Company Required")
      } else if (checkBox["1"] == false || checkBox["2"] == false || checkBox["3"] == false || checkBox["4"] == false) {
        changeToastDetails(STR_FAILURE, "Confirm Checkbox")
      }
      changeToastVisibility(true)
      return
    }
    const response = await CreatePledgeResponseUseCase.invoke(auth, student?.email)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error Occured")
      changeToastVisibility(true)
      return
    }
    changeToastDetails(STR_SUCCESS, "PLEDGE SAVED")
    changeToastVisibility(true)
    setStudent((student: any) => ({
      ...student,
      ["show_pledge"]: false, // Update the specific field with the new value
    }))
  }

  const handleOpenReasonModal = () => {
    setReasonModal(true)
  }

  type CourseRole = keyof typeof checkoutBoxTopics
  // const pledgeRole: CourseRole = 'Data Engineer'; // Example role

  return (
    <div className="relative z-50">
      <div
        className={`fixed inset-0 ${reasonModal === true ? "" : "backdrop-blur-[8px] bg-[rgba(12,12,12,0.85)] z-0"}`}
      ></div>
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen  opacity-100 items-center justify-center">
        <div className="flex relative border-[0.5px] rounded-[16px] border-solid  shadow-[-1_1px_4px_0px_rgba(34,41,48,0.20)] border-[#D3D3D3] justify-between  w-[832px] overflow-hidden bg-[#FFF]">
          <button onClick={onClose} className="cursor-default opacity-0 absolute bottom-0 text-new-solid-white left-0">
            <CrossIcon className="w-4 h-4" />
          </button>
          <div className="p-6 flex flex-col gap-y-[16px]">
            <h4 className="text-black font-inter text-[18px] font-semibold leading-normal">Solemn Pledge</h4>
            <div className="w-[784px] h1 flex flex-col">
              <div className="flex flex-col gap-y-[6px]">
                <div className="flex gap-x-[12px] items-center">
                  {" "}
                  <div className="text-[#333] font-inter text-[16px] font-normal leading-normal">
                    As I embark my learning journey this month , I
                  </div>
                  <Input
                    value={pledgeName}
                    onChange={(e) => setPledgeName(e.target.value)}
                    placeholder="Your Name"
                    className="flex font-['Inter'] text-sm font-normal text-black w-[163px] p-[8px_16px] items-start gap-[13px] rounded-[4px] border border-[#D3D3D3] bg-white"
                  ></Input>
                  <div className="text-[#333] font-inter text-[16px] font-normal leading-normal">
                    solemnly pledge to work towards
                  </div>
                </div>
                <div className="mb-[32px] flex gap-x-[12px] items-center">
                  {" "}
                  <div className="text-[#333] font-inter text-[16px] font-normal leading-normal">
                    my goal of becoming a skilled
                  </div>
                  <div className="flex w-[187px] text-black justify-between gap-0 font-semibold my-[8px]">
                    <StandardSelect
                      className="px-4 w-[163px] font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-black border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder={"Select Role"}
                      value={pledgeRole}
                      onChange={(e: any) => {
                        setPledgeRole(e)
                        checkBox["1"] = false
                        checkBox["2"] = false
                        checkBox["3"] = false
                        checkBox["4"] = false
                      }}
                      options={roleArray}
                      required
                    ></StandardSelect>
                  </div>
                  <div className="text-[#333] font-inter text-[16px] font-normal leading-normal">
                    at my dream company,
                  </div>
                  <Input
                    value={pledgeCompany}
                    onChange={(e) => setPledgeCompany(e.target.value)}
                    placeholder="Company Name"
                    className="flex w-[163px] font-['Inter'] text-sm font-normal text-black p-[8px_16px] items-start gap-[13px] rounded-[4px] border border-[#D3D3D3] bg-white"
                  ></Input>
                </div>
              </div>
              <div className="w-[784px]  bg-[#F9F9F9] flex border rounded-[4px]">
                <div className="flex flex-col p-4 items-start gap-3 self-stretch ">
                  {checkoutBoxTopics[CourseMap[pledgeRole]]?.map((topic: Topic, index: number) => (
                    <div className="flex gap-x-[16px] items-start">
                      <input
                        id={`pledge-keypoints-${index}`}
                        type="checkbox"
                        className="mr-1 mt-1 m-[2px] checked:appearance-none appearance-none checked:bg-[#07B42D] checked:hover:bg-[#07B42D] focus:ring-0"
                        checked={checkBox[index + 1]}
                        onChange={() => {
                          handleCheckboxChange((index + 1).toString())
                        }}
                      />
                      <label
                        htmlFor={`pledge-keypoints-${index}`}
                        className="text-[#333] font-inter font-normal text-[16px] leading-normal"
                      >
                        <span className="font-semibold">{topic.key} : </span>
                        {topic.value}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-[#333] mt-[32px] font-inter text-[16px] font-normal leading-normal">
                By committing to these goals, I believe I can make significant progress towards my career aspirations.
              </div>
            </div>
            <div className="mt-[30px] flex gap-x-[16px] ml-auto">
              <Button
                variant={"secondary"}
                className=" w-fit text-gray-500 border-[1px] bg-[#fff] border-solid border-[#D3D3D3]"
                onClick={handleOpenReasonModal}
              >
                Why Pledge?
              </Button>
              <Button variant={"primary"} onClick={createPledgeResponse} className=" w-[130px] ">
                Submit Pledge
              </Button>
            </div>
          </div>
        </div>
      </div>
      {reasonModal && (
        <div className="relative z-50">
          <div className="fixed inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-[8px] opacity-95  z-0"></div>
          <div className="fixed inset-0 overflow-y-auto flex min-h-screen items-center justify-center">
            <div className="flex gap-y-[16px] relative border-[0.5px] rounded-[16px] border-solid  shadow-[-1_1px_4px_0px_rgba(34,41,48,0.20)] border-[#D3D3D3] justify-betweenflex flex-col w-[543px]  p-6 items-start gap-6 overflow-hidden bg-[#FFF]">
              <div className="flex justify-between w-[495px] items-center mb-[4px]">
                <h4 className="text-black font-inter text-[18px] font-semibold leading-normal ">
                  Why you should take pledge?
                </h4>
                <Button
                  variant={"cancel"}
                  className="p-0 w-[20px] h-[20px] hover:bg-v2-white"
                  onClick={() => setReasonModal(false)}
                >
                  <Cross />
                </Button>
              </div>
              <div>
                <p className="text-[#333] font-inter text-[14px] font-normal leading-[150%]">
                  By committing to the pledge, you can foster a disciplined approach to learning, develop essential
                  skills, and enhance your overall growth.
                </p>
              </div>
              <div>
                <p className="text-[#333] font-inter text-[14px] font-normal leading-[150%]">
                  Adhering to the pledge can help you achieve academic excellence by ensuring consistent practice,
                  attendance, and focus on your goals.
                </p>
              </div>
              <div>
                <p className="text-[#333] font-inter text-[14px] font-normal leading-[150%]">
                  The pledge encourages you to develop the skills and knowledge necessary for success in data-driven
                  careers, positioning you for future opportunities and advancements.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
