import { Button } from "core/components/v2/Button"
import { CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import Avatar from "react-avatar"
import MentorCard from "./MentorCard"
import { STR_MENTOR_LIST, STR_NEXT_STEP_BUTTON, STR_SELECT_ATMAX_THREE_MENTORS } from "core/constants/strings"
import { Mentor } from "domain/model/Mentor"
import Spinner from "core/components/Spinner"
import { CustomScroll } from "react-custom-scroll"

const MentorListModal = ({
  isOpen,
  onClose,
  isAlloting,
  isTemp,
  mentorsList,
  selectedMentors,
  handleMentorAllotment,
  alloted,
  handleMentorSelection,
  fetchTempMentorAvailability,
  session_id,
  setBookSlotModal,
}: any) => {
  if (!isOpen) return null
  return (
    <div className="relative z-30">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0"></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="bg-white w-[795px] h-[700px] max-2xl:h-[600px] overflow-y-auto p-[24px] rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="border-[0.5px] boder-[#D3D3D3] rounded-[8px] px-[24px] py-[16px] mb-[24px]">
            <div className="flex justify-between">
              <p className="text-[#333] text-[16px] font-[600]">
                {isTemp ? "Select a temporary mentor" : "Mentor Selection"}
              </p>
              <button className="hover:bg-slate-200 rounded-[4px]" onClick={onClose}>
                <CrossIcon className="size-[24px]" />
              </button>
            </div>
            <p className="text-[14px] font-[400]">
              {isTemp ? "Select temporary mentor for a quick session." : "Complete your mentor selection process!"}
            </p>
          </div>

          <div className="space-y-6 rounded-[8px] pt-6 shadow-[0px_3px_7px_rgba(0,0,0,0.1)] overflow-y-auto h-[550px]">
            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
              <div className="px-6">
                <p className="text-[#333] text-[16px] font-[600]">Your mentor list</p>
                <p className="text-new-neutral text-[14px] font-[400]">
                  {isTemp
                    ? "Select one of the mentor to proceed further."
                    : "Select any three mentors to proceed further."}
                </p>
              </div>
              <div className="relative">
                <div className="flex flex-col gap-[9px] px-6 my-[8px]">
                  {mentorsList &&
                    mentorsList?.map((mentor: Mentor, i: number) => (
                      <MentorCard
                        selectionModalOpen={isOpen}
                        key={i}
                        index={i}
                        mentor={mentor}
                        selectedMentors={selectedMentors}
                        alloted={alloted}
                        isOpen={isOpen}
                        handleMentorSelection={handleMentorSelection}
                        fetchTempMentorAvailability={fetchTempMentorAvailability}
                        session_id={session_id}
                        setBookSlotModal={setBookSlotModal}
                      />
                    ))}
                </div>
                {isOpen !== "temp" && (
                  <div className="sticky h-[68px] bottom-0 flex w-full items-center justify-between bg-[#F9F9F9] p-6">
                    <div className="flex items-center gap-[8px]">
                      {/* <div className="flex gap-4"> */}
                      {Array(3)
                        .fill(0)
                        .map((_: any, i: number) => (
                          <div
                            key={i}
                            className={cn("size-[24px] flex justify-center items-center rounded-full bg-[#646464]")}
                          >
                            {selectedMentors.length > i && (
                              <Avatar
                                className="object-cover"
                                src={mentorsList[selectedMentors[i]]?.photo}
                                name={mentorsList[selectedMentors[i]]?.name}
                                size="24"
                                round
                              />
                            )}
                          </div>
                        ))}
                      {/* </div> */}
                      {/* <p className="font-medium text-gray-400">{STR_SELECT_ATMAX_THREE_MENTORS}</p> */}
                    </div>

                    <Button
                      size={"sm"}
                      className={cn("w-[138px]", selectedMentors.length < 3 && "bg-v2-gray-500 hover:bg-v2-gray-600")}
                      onClick={handleMentorAllotment}
                      disabled={isAlloting}
                    >
                      {isAlloting ? <Spinner small={true} /> : "Confirm Mentor"}
                    </Button>
                  </div>
                )}
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentorListModal
