import { Auth } from "domain/model/Auth"
import { UpdateWalkThroughRepository } from "domain/repository/Admin/UpdateWalkThrough"

export interface UpdateWalkThroughDateUseCase {
  invoke(auth: Auth, student_id: string, new_walkthrough_date: string): Promise<any>
}

export class UpdateWalkThroughDate implements UpdateWalkThroughDateUseCase {
  private repository: UpdateWalkThroughRepository

  constructor(repository: UpdateWalkThroughRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_id: string, new_walkthrough_date: string): Promise<any> {
    return await this.repository.updateWalkThroughDate(auth, student_id, new_walkthrough_date)
  }
}
