import CreateSSMCallDataSource from "data/dataSource/Admin/CreateSSMCallDataSource"
import { Auth } from "domain/model/Auth"
import { CreateSSMCall } from "domain/model/CreateSSMCall"
import { CreateSSMCallRepository } from "domain/repository/Admin/CreateSSMCallRepository"

export class CreateSSMCallRepositoryImpl implements CreateSSMCallRepository {
  private Datasource: CreateSSMCallDataSource

  constructor(Datasource: CreateSSMCallDataSource) {
    this.Datasource = Datasource
  }

  async createSSMCall(createSSMCall: CreateSSMCall): Promise<any> {
    return await this.Datasource.createSSMCall(createSSMCall)
  }
}
