import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface getContestFeedbackDetails {
  invoke(auth: Auth, student_email: string, contest_id: string): Promise<any>
}

export default class GetContestFeedbackDetails implements getContestFeedbackDetails {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_email: string, contest_id: string) {
    return await this.repository.getContestFeedbackDetails(auth, student_email, contest_id)
  }
}
