import WeeklyReportRepositry from "domain/repository/Admin/WeeklyReport"
import GetWeeklyReport from "domain/model/GetWeeklyReport"

export interface getWeeklyReportUseCase {
  invoke(details: GetWeeklyReport): Promise<any>
}

export class GetWeeklyReportData implements getWeeklyReportUseCase {
  private repository: WeeklyReportRepositry

  constructor(repository: WeeklyReportRepositry) {
    this.repository = repository
  }

  async invoke(details: GetWeeklyReport): Promise<any> {
    return await this.repository.getWeeklyReport(details)
  }
}
