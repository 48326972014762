import { Tooltip as TP } from "@mui/material"
import DonutChart from "core/components/DonutChart"
import { isEmpty } from "core/utils/misc"
import { ProfileMetric } from "domain/model/Profile"
import { Bar } from "react-chartjs-2"
import useOverAllReportViewModel from "./OverallReportViewModel"
import { useEffect } from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarController, BarElement } from "chart.js/auto"
import Loader from "core/components/Loader"

export default function OverallReport() {
  const {
    studentData,
    labelData,
    currentLabel,
    label,
    setCurrentLabel,
    showContent,
    setLabel,
    setShowContent,
    currContent,
    setCurrContent,
    getOverallReport,
  } = useOverAllReportViewModel()

  useEffect(() => {
    getOverallReport()
  }, [])

  useEffect(() => {
    if (currentLabel !== undefined) {
      setLabel(labelData[currentLabel])
    } else {
      setLabel(null)
    }
  }, [currentLabel, labelData])

  const VerticalDivider = () => <div className="h-11 border border-[#ADADAD]" />
  ChartJS.register(CategoryScale, LinearScale, BarController, BarElement)

  const calculatePercent = (solved: any, total: any): number => {
    const parsedSolved = parseFloat(solved)
    const parsedTotal = parseFloat(total)

    if (parsedSolved === 0 && parsedTotal === 0) {
      return 0
    }

    const percent = (parsedSolved / parsedTotal) * 100

    if (isNaN(percent)) {
      return 0
    }

    return parseFloat(percent.toFixed(2))
  }

  const getAttendenceOrTotalMarks = () => {
    return label?.present?.toString() || label?.marks_obtained
  }

  const getRecommendation = () => {
    return label?.recommended
  }

  const getTotal = () => {
    return label?.total !== undefined && label?.total !== null
      ? label?.total
      : label?.maximum !== undefined && label?.maximum !== null
        ? label?.maximum
        : label?.total_marks !== undefined && label?.total_marks !== null
          ? label?.total_marks
          : 0
  }

  const getStatus = (name: any, status: any) => {
    if (label?.recommended === 0 && label?.name === "Weekly Contests") return "No Contest"
    if (label?.total === 0 && label?.name === "Class Attendance") return "Class Not Started"
    if (name === "Average SSM Rating" || name === "Average Mentor Rating") {
      if (label?.present == 5) return "Excellent"
      else if (label?.present == 4) return "Good"
      else if (label?.present == 3) return "Average"
      else if (label?.present == 0) return "Not Rated"
      return "Poor"
    } else if (name === "Assignment Problems") {
      if (label?.recommended === 0) return "Not Assigned"
      let percent = calculatePercent(label?.solved, label?.total)
      if (percent >= 90) return "Excellent"
      else if (percent >= 75) return "Good"
      else if (percent >= 60) return "Average"
      return "Poor"
    }
    return status ? "Passed" : "Failed"
  }
  return labelData ? (
    <div className="mt-[24px]">
      <div className="text-center border-b-2 font-semibold text-grey-300"></div>
      <div className="flex justify-center">
        <div className="px-10 py-[24px] w-[900px]">
          <div className="bg-slate-50 flex justify-evenly items-center h-[95px] rounded-md py-4">
            <div className="flex p-3 w-[230px]">
              <div className="flex w-full">
                {!isEmpty(studentData) &&
                  studentData?.metrics?.map((item: any, index: number) => (
                    <div key={index} className="flex items-center gap-5 font-bold">
                      <span key={index}>
                        <DonutChart current={item.current} total={item.total} />
                      </span>
                      <TP title={item?.name}>
                        <div className="flex flex-col gap-1 ">
                          <h3 className="text-new-accent text-[16px] font-[700] ">{item.name}</h3>
                          <span className="text-xs leading-[19px] text-[12px] font-[400]">
                            {item.current / 100}/{item.total / 100}
                          </span>
                        </div>
                      </TP>
                    </div>
                  ))}
              </div>
            </div>

            <VerticalDivider />

            <div className="w-2/3 m-4">
              {currentLabel !== undefined && label?.name !== "Assignment Problems" && label?.name !== "Projects" ? (
                <div className="text-left ">
                  <p className="flex text-[#414141] text-[14px] font-[700] gap-3 ">
                    {label?.name}
                    {getAttendenceOrTotalMarks() >= getRecommendation() && label?.recommended !== 0 ? (
                      <span className="text-green-400">{getStatus(label?.name, true)}</span>
                    ) : (
                      <span className="text-red-600">{getStatus(label?.name, false)}</span>
                    )}
                  </p>
                  <div className="flex gap-7">
                    <div className="flex justify-between">
                      <p className="font-[400] text-[12px]">{label?.f1}</p>
                      <p className="px-4">:</p>
                      <p className="font-[700] text-[12px] text-[#6B6B6B]">
                        {(label?.name === "Average SSM Rating" ||
                          label?.name === "Average Mentor Rating" ||
                          label?.name === "Weekly Contests") &&
                        parseFloat(getAttendenceOrTotalMarks()) === 0 ? (
                          "NA"
                        ) : (
                          <span>
                            {getAttendenceOrTotalMarks()} / {getTotal()}
                            {(label?.name === "Class Attendance" || label?.name === "Weekly Contests") &&
                              ` ( ${calculatePercent(getAttendenceOrTotalMarks(), getTotal())}% )`}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="font-[400] text-[12px] ">{label?.f2}</p>
                      <p className="px-4">:</p>
                      <p className="font-[700] text-[12px] text-[#6B6B6B]">
                        {getRecommendation() === 0 ? "NA" : getRecommendation()}{" "}
                        {(label?.name === "Class Attendance" || label?.name === "Weekly Contests") && ` ( 70% )`}
                      </p>
                    </div>
                  </div>
                  <div>
                    {label?.f3 && (
                      <div className="flex gap-7">
                        <div className="flex justify-between w-[180px]">
                          <p className="font-[400] text-[12px]">{label?.f3}</p>
                          <p>:</p>
                          <p className="font-[700] text-[12px] text-[#6B6B6B]">{getAttendenceOrTotalMarks()}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : label?.name !== "Projects" ? (
                <div className="text-left ">
                  <p className="flex text-[#414141] text-[14px] font-[700] gap-3 ">
                    {"Assignment Progress"}
                    {calculatePercent(label?.solved, label?.total) >= 75 ? (
                      <span className="text-green-400">{getStatus(label?.name, true)}</span>
                    ) : (
                      <span className="text-red-600">{getStatus(label?.name, false)}</span>
                    )}
                  </p>
                  <div className="flex gap-7">
                    <div className="flex justify-between">
                      <p className="font-[400] text-[12px]">{label?.f1}</p>
                      <p className="px-4">:</p>
                      <p className="font-[700] text-[12px] text-[#6B6B6B]">{label?.leetcode_count}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="font-[400] text-[12px] ">{label?.f2}</p>
                      <p className="px-4">:</p>
                      <p className="font-[700] text-[12px] text-[#6B6B6B]">{label?.platform_count}</p>
                    </div>
                  </div>
                  <div className="flex gap-7">
                    <div className="flex justify-between">
                      <p className="font-[400] text-[12px]">{label?.f3}</p>
                      <p className="px-4">:</p>
                      <p className="font-[700] text-[12px] text-[#6B6B6B]">
                        {`${label?.solved} / ${label?.total} ( ${calculatePercent(label?.solved, label?.total)}% )`}
                      </p>
                    </div>
                    <div>
                      {label?.f4 && (
                        <div className="flex gap-7">
                          <div className="flex justify-between w-[180px]">
                            <p className="font-[400] text-[12px]">{label?.f4}</p>
                            <p>:</p>
                            <p className="font-[700] text-[12px] text-[#6B6B6B]">{`${label?.recommended}  ( 75% )`}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="flex text-[#414141] text-[14px] font-[700] gap-3">{label?.name}</span>
                    <span className="font-bold px-2">NA</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex justify-center flex-wrap  w-[825px]">
          {labelData.map((detail: any, index: number) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-3">
              <div
                key={index}
                className={`bg-white shadow-md rounded-md px-4 pt-2 text-center cursor-pointer relative ${index === currentLabel ? "border border-green-400" : ""}`}
                onMouseEnter={() => {
                  setShowContent(true)
                  setCurrContent(detail)
                }}
                onMouseLeave={() => {
                  setShowContent(false)
                  setCurrContent(null)
                }}
                onClick={() => setCurrentLabel(index)}
              >
                <div>
                  <Bar
                    data={{
                      labels: [
                        (detail?.present && "Present") ||
                          (detail?.solved && "Solved") ||
                          (detail?.marks_obtained && "Marks Obtained"),
                        detail?.recommended && "Recommended",
                        (detail?.maximum && "Maximum") ||
                          (detail?.total && "Total") ||
                          (detail?.total_marks && "Total Marks"),
                      ],
                      datasets: [
                        {
                          data: [
                            detail?.present?.toString() ||
                              detail?.solved?.toString() ||
                              detail?.marks_obtained.toString(),
                            detail?.recommended?.toString(),
                            detail?.total?.toString() || detail?.total_marks?.toString() || detail?.maximum?.toString(),
                          ],
                          backgroundColor: [
                            detail.present >= detail.recommended ||
                            detail.marks_obtained >= detail.recommended ||
                            detail.solved >= detail.recommended
                              ? `#7AF366`
                              : `#FE6E77`,
                            "#EBF0FF",
                            "#5030CE",
                          ],
                          barThickness: 65,
                          borderRadius: 5,
                        },
                      ],
                    }}
                    options={{
                      indexAxis: "x",
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          display: false,
                          position: "center",
                          grid: {
                            display: false,
                            color: "rgba(0, 0, 0, 0)",
                          },
                          ticks: {
                            display: false,
                          },
                        },
                        x: {
                          display: false,
                          grid: {
                            display: false,
                            color: "rgba(0, 0, 0, 0)",
                          },
                          ticks: {
                            display: false,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        // datalabels: {
                        //   display: false,
                        // },
                      },
                    }}
                  />
                </div>
                {showContent && detail === currContent && (detail.name === "Projects" || detail.recommended === 0) && (
                  <div className="absolute top-5 right-0 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 p-2 rounded-md">
                    <p className="font-mono">Data Not Yet Collected</p>
                  </div>
                )}
                <div className=" p-4 font-[400] text-[10px] text-[#6B6B6B] h-16">
                  <p>{detail.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
