import { ChevronLeftIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { redirectPath } from "core/lib/utils"
import { Link } from "react-router-dom"

export default function NotFound() {
  const { auth } = useAuth()
  return (
    <div className="flex h-screen">
      <div className="m-auto space-y-2 text-center">
        <p className=" text-4xl font-bold">404</p>
        <Link to={redirectPath(auth.role)} className="flex items-center gap-2 font-medium text-blue-700">
          <ChevronLeftIcon className="h-5 w-5" /> Back to home
        </Link>
      </div>
    </div>
  )
}
