import Button from "core/components/new/Button"
import DashboardLayout from "core/layouts/DashboardLayout"
import useDocumentationEditViewModel from "./DocumentationFileAddViewModel"
import { STR_ADD, STR_OPTIONS, STR_QUESTION } from "core/constants/strings"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { cn } from "core/lib/utils"
import React from "react"
import Toast from "core/components/Toast"
import ConfirmationPopUp from "./components/ConfirmationPopUp"

export default function DocumentationFileAddView() {
  const {
    id,
    name,
    feature,
    subFeature,
    file,
    allFieldsValid,
    loading,
    toast,
    uploadedFileName,
    changeToastVisibility,
    redirectToDocumentationFileList,
    checkIfAllFieldsAreFilled,
    handleChangeName,
    handleAddDocumentationFile,
    handleChangeFile,
    handleChangeFeature,
    handleChangeSubFeature,
    fetchDocumentationFile,
    handleUpdateDocumentationFile,
  } = useDocumentationEditViewModel()

  React.useEffect(() => {
    checkIfAllFieldsAreFilled()
  }, [name, feature, subFeature, file])

  React.useEffect(() => {
    if (id !== STR_ADD) {
      fetchDocumentationFile()
    }
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="flex justify-between ">
          <h3 className="text-new-accent">Documentation File ({id === STR_ADD ? "Add" : "Update"})</h3>
          <Button className="h-fit w-fit" failure outlined onClick={redirectToDocumentationFileList}>
            Go Back
          </Button>
        </div>
        <div className="round-3 mx-auto flex w-full max-w-[600px] flex-col gap-4 p-6 shadow">
          <div className="flex flex-col justify-center">
            <div className="space-y-6 p-6">
              <Input
                required
                placeholder="Select Feature to which it belongs"
                onChange={handleChangeFeature}
                value={feature}
              />
              <Input required value={subFeature} onChange={handleChangeSubFeature} placeholder="Sub-Feature" />
              <Input required placeholder="File Name" className="mb-5" value={name} onChange={handleChangeName} />
              {id === STR_ADD ? (
                <label className={cn("text-sm font-medium leading-normal underline cursor-pointer")}>
                  <div className="text-blue-700"> {uploadedFileName}</div>Upload File
                  <input
                    type="file"
                    className="hidden"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleChangeFile}
                  />
                </label>
              ) : (
                ""
              )}

              <Button
                outlined
                disabled={!allFieldsValid || loading}
                onClick={async () => {
                  if (id === STR_ADD) {
                    await handleAddDocumentationFile(file, name, feature, subFeature)
                  } else {
                    await handleUpdateDocumentationFile(id as string, name, feature, subFeature)
                  }
                }}
              >
                {id === STR_ADD ? "Add" : "Update"} File
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
