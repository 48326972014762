import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Student/ProfileRepository"

export interface UploadResumeUseCase {
  invoke(auth: Auth, blob: any, filename: string): Promise<any>
}

export class UploadResume implements UploadResumeUseCase {
  private repository: ProfileRepository

  constructor(repository: ProfileRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, blob: any, filename: string) {
    return await this.repository.uploadResume(auth, blob, filename)
  }
}
