import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import PaymentDetails from "./components/PaymentDetails"
import PaymentInvoiceViewModel from "./PaymentInvoiceViewModel"
import Button from "core/components/Button"
import Toast from "core/components/Toast"
import CopyIcon from "assets/svgs/CopyIcon"
import Input from "core/components/new/Input"
import Select from "core/components/Select"

export default function PaymentInvoiceView() {
  const {
    SelectList,
    Keys,
    handleInputChange,
    handleSaveChanges,
    editable,
    getDate,
    handleChange,
    handleUpdateClick,
    isDownloading,
    setIsDownloading,
    isLoading,
    setIsLoading,
    showPopup,
    handleViewBtnClick,
    handleCloseBtnClick,
    handleCheckboxChange,
    handleDateChange,
    handlePaymentMethodChange,
    handleSavePaymentMethod,
    getPaymentInvoices,
    handleVerificationChange,
    handleSaveVerificationStatus,
    handleDownloadClick,
    paymentInvoices,
    refreshed,
    tableHead,
    viewIdx,
    disabledKeys,
    // nextInvoiceLoading,
    // loadNextData,
    // getTop30PaymentInvoices,
    searchByEmail,
    emailInputRef,
    handleSearchByEmail,
    toast,
    changeToastVisibility,
    fetchStudentByFiltering,
    formatDateTime,
    handleCopyToClipboard,
    handleStatusTypeChange,
    fromDate,
    toDate,
    handleFromDateChange,
    handleToDateChange,
    statusType,
    handleClearFilter,
    paginate,
    currentPage,
    invoicesPerPage,
    totalInvoices,
    handleInvoiceLimitChange,
    timeoutRef,
    filtering,
    scrollContainerRef,
  } = PaymentInvoiceViewModel()

  React.useEffect(() => {
    const fetchData = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        if (filtering) {
          if (searchByEmail || fromDate || toDate || statusType) {
            fetchStudentByFiltering(searchByEmail, fromDate, toDate, statusType)
          } else {
            getPaymentInvoices(1, 20)
          }
        } else if (refreshed) {
          getPaymentInvoices(currentPage, invoicesPerPage)
          emailInputRef.current?.focus()
        }
      }, 800)
    }

    // fetchData();
    if (scrollContainerRef.current) {
      fetchData()
      scrollContainerRef.current.scrollTop = 0
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [filtering, searchByEmail, fromDate, toDate, statusType, refreshed, currentPage, invoicesPerPage])

  return (
    <DashboardLayout>
      <div className="relative rounded-md shadow-md mt-2">
        <div className="space-y-6 p-6">
          <div>
            <h2 className="text-new-accent">Payment Invoices</h2>
            <p>View, find, manage & update Payment Invoices</p>
          </div>
          <div className="flex w-full flex-wrap gap-4">
            <div className="space-y-2">
              <label>Email ID</label>
              <Input
                id="search-email"
                ref={emailInputRef}
                type="email"
                placeholder="Search with email"
                value={searchByEmail}
                onChange={handleSearchByEmail}
                containerClass="w-60"
                search
              />
            </div>
            <div className="flex space-x-2">
              <div className="space-y-2">
                <label>From Date</label>
                <Input
                  type="date"
                  placeholder="From Date"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  containerClass="w-50"
                />
              </div>
              <div className="space-y-2">
                <label>To Date</label>
                <Input
                  type="date"
                  placeholder="To Date"
                  value={toDate}
                  containerClass="w-50"
                  onChange={handleToDateChange}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <label>Status Type</label>
              <Select
                required
                options={SelectList}
                onChange={handleStatusTypeChange}
                value={statusType}
                className="w-10 h-[36px] bg-[#fff] rounded-sm text-base"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label>Page Per Limit</label>
              <select
                onChange={handleInvoiceLimitChange}
                className="block appearance-none w-full bg-white border border-gray-300 rounded px-4 py-[6px] pr-6 focus:outline-none focus:border-gray-500"
                defaultValue="20"
              >
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
            </div>
            <Button
              className="bg-new-accent text-new-solid-white mt-8 w-[150px] h-[36px] text-sm"
              onClick={handleClearFilter}
            >
              Clear Filters
            </Button>
            <button
              onClick={handleDownloadClick}
              disabled={isDownloading}
              className={`bg-new-accent text-new-solid-white mt-8 w-[150px] h-[36px] text-sm ${
                isDownloading ? "bg-gray-400 cursor-not-allowed" : ""
              }`}
            >
              {isDownloading ? (
                <div className="flex items-center">
                  <svg
                    className="w-4 h-4 mr-2 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Downloading...
                </div>
              ) : (
                "Download CSV"
              )}
            </button>
          </div>
          <div
            ref={scrollContainerRef}
            className="relative overflow-x-auto rounded-md shadow-md border-2 border-[#ADADAD] overflow-y-auto max-h-[400px] custom-scrollbar-2"
          >
            <table className="w-full text-left text-sm text-new-neutral-dark p-2 overflow-x-auto  ">
              <thead className="bg-new-neutral-dark text-new-solid-white rounded sticky top-0 z-10">
                <tr>
                  {tableHead.map((item, index) => (
                    <th className="px-6 py-3 whitespace-nowrap sticky" key={index}>
                      {item === "Amount" ? `${item} ( ₹. )` : item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paymentInvoices.map((item: any, index: any) => (
                  <tr className="px-3 py-4 hover:bg-gray-100" key={index}>
                    <td className="px-6 py-3 align-middle">{(currentPage - 1) * invoicesPerPage + (index + 1)}</td>
                    <td className="px-6 py-3">
                      <div className="flex items-center justify-between">
                        <p className="px-1">{item["student_name"]}</p>
                        <div className="ml-auto pl-3 cursor-pointer">
                          <button
                            className="flex items-center justify-center"
                            title={item["email_id"]}
                            onClick={() => handleCopyToClipboard(item["email_id"])}
                          >
                            <CopyIcon />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3 capitalize">{item["payment_type"]}</td>
                    <td
                      className={`px-6 py-3 font-semibold capitalize ${item["status"] === "paid" ? "text-green-500" : ""}
                    ${item["status"] === "refund" ? "text-[#DC2626]" : ""}`}
                    >
                      {item["status"]}
                    </td>
                    <td className="px-6 py-3">{item["amount"]}</td>
                    <td className="px-6 py-3">
                      {item["status"] === "created"
                        ? formatDateTime(item["due_date"])
                        : formatDateTime(item["date_time"])}
                    </td>
                    <td className="px-6 py-3">{item["invoice_id"]}</td>
                    <td className="px-7 py-3">
                      <button onClick={() => handleViewBtnClick(index)} className="focus:outline-none">
                        <VisibilityOutlinedIcon />
                      </button>
                    </td>
                    <td className="px-20 py-3">
                      <input
                        type="checkbox"
                        checked={item["verified_by_ops"] || false}
                        onChange={(e) => handleCheckboxChange(item["invoice_id"])}
                      />
                    </td>

                    <td className="px-2 py-3">
                      <Input
                        type="date"
                        value={item["date_field_bank"] || ""}
                        containerClass="w-40"
                        onChange={(e) => handleDateChange(item["invoice_id"], e.target.value)}
                      />
                    </td>

                    <td className="px-1 py-3">
                      <div className="flex items-center space-x-2">
                        <input
                          type="text"
                          placeholder="Enter Payment Method"
                          value={item.new_payment_method_type || ""}
                          onChange={(e) => handlePaymentMethodChange(item.invoice_id, e.target.value)}
                          className="w-24 px-2 py-1 border border-gray-300 rounded text-sm"
                        />
                        <button
                          onClick={() => handleSavePaymentMethod(item.invoice_id)}
                          className="bg-blue-500 text-white px-4 py-1 rounded text-sm"
                        >
                          Save
                        </button>
                      </div>
                    </td>

                    <td className="px-3 py-3">
                      <div className="flex items-center space-x-2">
                        <select
                          value={item["new_verified_by_accounts"] || "unverified"}
                          onChange={(e) => handleVerificationChange(item.invoice_id, e.target.value)}
                          className={`w-32 px-2 py-1 border border-gray-300 rounded text-sm ${
                            item["new_verified_by_accounts"] === "verified"
                              ? "bg-green-300 text-green-800"
                              : item["new_verified_by_accounts"] === "rejected"
                                ? "bg-red-300 text-red-800"
                                : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          <option value="unverified" className="bg-gray-100">
                            Unverified
                          </option>
                          <option value="verified" className="bg-green-100">
                            Verified
                          </option>
                          <option value="rejected" className="bg-red-100">
                            Rejected
                          </option>
                        </select>
                        <button
                          onClick={() => handleSaveVerificationStatus(item.invoice_id)}
                          className="bg-blue-500 text-white px-2 py-1 rounded text-sm"
                        >
                          Save
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full overflow-x-auto p-2">
          <div className="flex justify-center mt-3 ">
            <div className="overflow-x-auto w-56">
              <ul className="flex py-2">
                {Array.from({ length: Math.ceil(totalInvoices / invoicesPerPage) }, (_, index) => index + 1).map(
                  (number) => (
                    <li key={number} className="mr-2">
                      <button
                        onClick={() => paginate(number)}
                        className={`${
                          currentPage === number ? "bg-new-accent text-new-solid-white" : "bg-gray-200 text-new-accent"
                        } px-4 py-2 rounded`}
                      >
                        {number}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <PaymentDetails
          currentData={paymentInvoices[viewIdx]}
          onClose={handleCloseBtnClick}
          getDate={getDate}
          editable={editable}
          handleChange={handleChange}
          handleSaveChanges={handleSaveChanges}
          handleInputChange={handleInputChange}
          handleUpdateClick={handleUpdateClick}
          Keys={Keys}
          disabledKeys={disabledKeys}
        />
      )}

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
