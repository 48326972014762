import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Mentor/ProfileRepository"

export interface FetchMentorProfileUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class FetchMentorProfile implements FetchMentorProfileUseCase {
  private repository: ProfileRepository

  constructor(repository: ProfileRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.fetchMentorProfile(auth)
  }
}
