import { Auth } from "domain/model/Auth"
import CCAvPaymentAdminRepository from "domain/repository/Admin/CreateCCAvPaymentRepository"

export interface UpdateTransactionDetailAdmin {
  invoke(auth: Auth, paymentData: any): Promise<any>
}
export class UpdateCCAvTransactionDetailAdmin implements UpdateTransactionDetailAdmin {
  private repository: CCAvPaymentAdminRepository

  constructor(repository: CCAvPaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, paymentData: any) {
    return await this.repository.updateTransactionDetailAdmin(auth, paymentData)
  }
}
