import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface CancelSessionUseCase {
  invoke(auth: Auth, date: string, start: string, end: string): Promise<any>
}

export default class CancelSession implements CancelSessionUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, date: string, start: string, end: string): Promise<any> {
    return await this.repository.cancelSession(auth, date, start, end)
  }
}
