import {
  API_ALLOW_REDEEM_COINS,
  API_CREATE_COUPON,
  API_DELETE_COUPON,
  API_EDIT_COUPON,
  API_GET_ALL_COUPONS,
  API_GET_BATCHES_ALLOWED_REDEEM_COINS,
  API_V4_ADMIN,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { RedeemCoinsDataSource } from "data/dataSource/Admin/RedeemCoinsDataSource"
import { CreateCoupon, EditCoupon } from "domain/model/RedeemCoins"

export default class RedeemCoinsAPIDataSourceImpl implements RedeemCoinsDataSource {
  async createCoupon(createCoupon: CreateCoupon) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_COUPON), createCoupon)
      return response
    } catch (error) {
      return error
    }
  }

  async editCoupon(editCoupon: EditCoupon) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_EDIT_COUPON), editCoupon)
      return response
    } catch (error) {
      return error
    }
  }

  async getAllCoupons(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_COUPONS), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteCoupon(auth: Auth, id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_COUPON), {
        id_token: auth?.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async allowRedeemCoins(auth: Auth, batches: Array<string>) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_ALLOW_REDEEM_COINS), {
        id_token: auth?.id_token,
        batches,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getBatchesAllowedRedeemCoins(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_BATCHES_ALLOWED_REDEEM_COINS), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
