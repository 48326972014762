export default function TickCircle({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33334 8.00065C1.33334 11.6827 4.318 14.6673 8 14.6673C11.682 14.6673 14.6667 11.6827 14.6667 8.00065C14.6667 4.31865 11.682 1.33398 8 1.33398C4.318 1.33398 1.33334 4.31865 1.33334 8.00065ZM12.0487 5.95335L7.33533 10.6674L4.50667 7.83869L5.44933 6.89602L7.33533 8.78202L11.106 5.01069L12.0487 5.95335Z"
        fill="#22C55E"
      />
    </svg>
  )
}
