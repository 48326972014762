export default function MainLogo(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 218 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.5676 37.6372L38.3612 38.2945L37.9294 39.6715C37.7589 40.222 37.4168 40.7035 36.953 41.0455C36.4893 41.3876 35.9282 41.5723 35.3519 41.5726L34.0972 40.3532L31.1097 37.4661L27.6686 34.1283C27.2172 33.6939 26.6152 33.4513 25.9888 33.4513C25.3624 33.4513 24.7604 33.6939 24.309 34.1283L20.8598 37.4607L17.8723 40.3477L16.6148 41.5672C16.0391 41.5643 15.4793 41.3787 15.016 41.037C14.5528 40.6952 14.2101 40.2152 14.0374 39.666L13.6191 38.2945L13.4127 37.6372C13.7031 37.6292 13.9895 37.5675 14.2574 37.4553C14.525 37.3422 14.7691 37.1801 14.9771 36.9773L17.4214 34.609L22.0005 30.1685C22.5188 29.6662 23.2124 29.3857 23.9342 29.3863H28.3938C28.9 29.3831 29.3873 29.5781 29.7517 29.9295L30.3682 30.527L34.5725 34.6008L37.0168 36.9691C37.2239 37.1731 37.4682 37.3354 37.7365 37.4471C38.0044 37.5594 38.2908 37.6211 38.5812 37.6291L38.5676 37.6372Z"
        fill="white"
      />
      <path
        d="M30.3492 30.527C28.8467 30.5263 27.4031 31.1108 26.3242 32.1565L25.9902 32.4797L24.3172 34.1093L20.8598 37.4607L17.8723 40.3477L16.6148 41.5672C16.0391 41.5643 15.4793 41.3787 15.016 41.037C14.5528 40.6952 14.2101 40.2152 14.0374 39.666L13.6191 38.2945L13.4127 37.6372C13.7031 37.6292 13.9895 37.5675 14.2574 37.4553C14.525 37.3422 14.7691 37.1801 14.9771 36.9773L17.4214 34.609L22.0005 30.1685C22.5188 29.6662 23.2124 29.3857 23.9342 29.3863H28.3938C28.9 29.3831 29.3873 29.5781 29.7517 29.9295L30.3492 30.527Z"
        fill="white"
      />
      <path
        d="M21.0409 22.2704L19.0582 25.8826C18.8512 26.2591 18.5469 26.5731 18.1772 26.7919C17.8074 27.0106 17.3857 27.1262 16.9561 27.1265H12.4531C11.7221 27.1266 11.0022 26.9476 10.3564 26.605L3.34933 22.8842C3.2639 22.8411 3.16516 22.8326 3.07362 22.8605C2.98207 22.8883 2.90477 22.9503 2.85775 23.0336C2.82577 23.0899 2.80893 23.1535 2.80886 23.2183C2.81165 23.2794 2.82947 23.3389 2.86074 23.3915C2.892 23.4441 2.93575 23.4881 2.98811 23.5198L6.16303 25.5458C8.96143 27.3353 11.0395 30.0539 12.0321 33.2237L13.4118 37.6371L13.6182 38.2944L14.0473 39.6714C14.22 40.2205 14.5627 40.7006 15.026 41.0423C15.4893 41.384 16.0491 41.5697 16.6248 41.5725C16.248 41.9457 15.74 42.1563 15.2098 42.1591H7.97998C7.46634 42.1588 6.96621 41.9945 6.55244 41.6902C6.13867 41.3858 5.83287 40.9574 5.67959 40.4671L0.049482 22.4687C-0.000657497 22.3124 -0.0131037 22.1465 0.0131608 21.9845C0.0394253 21.8225 0.103655 21.669 0.2006 21.5365C0.297545 21.4041 0.424453 21.2965 0.570952 21.2225C0.717451 21.1485 0.879382 21.1102 1.04351 21.1107H20.37C20.5061 21.1099 20.6399 21.1452 20.7579 21.2128C20.8759 21.2804 20.974 21.3781 21.0421 21.4958C21.1102 21.6136 21.146 21.7473 21.1458 21.8833C21.1455 22.0193 21.1094 22.1529 21.0409 22.2704Z"
        fill="white"
      />
      <path
        d="M51.9226 22.4689L46.2925 40.4673C46.1388 40.957 45.8328 41.3849 45.419 41.6887C45.0053 41.9926 44.5054 42.1565 43.9921 42.1566H36.7759C36.2394 42.156 35.7248 41.9431 35.3446 41.5645C35.9209 41.5642 36.4819 41.3795 36.9457 41.0374C37.4095 40.6954 37.7516 40.2139 37.922 39.6634L38.3539 38.2864L38.5603 37.6291L39.7526 33.8133C40.8655 30.2559 43.199 27.2058 46.3414 25.2011L48.9894 23.5118C49.042 23.4784 49.0852 23.4322 49.1151 23.3776C49.145 23.323 49.1606 23.2617 49.1605 23.1994C49.162 23.135 49.1465 23.0713 49.1156 23.0146C49.0848 22.958 49.0397 22.9104 48.9847 22.8766C48.9298 22.8428 48.867 22.824 48.8025 22.8221C48.7381 22.8201 48.6742 22.835 48.6173 22.8654L41.613 26.5862C40.9671 26.9284 40.2472 27.1074 39.5163 27.1077H35.016C34.5863 27.1077 34.1645 26.9922 33.7947 26.7734C33.4249 26.5546 33.1207 26.2404 32.9139 25.8638L30.9312 22.2706C30.8665 22.1531 30.8336 22.0208 30.8357 21.8867C30.8377 21.7526 30.8747 21.6213 30.943 21.5059C31.0113 21.3904 31.1085 21.2948 31.225 21.2284C31.3416 21.162 31.4734 21.1271 31.6075 21.1272H50.9286C51.0907 21.128 51.2503 21.1666 51.3949 21.2398C51.5394 21.3131 51.6649 21.419 51.7614 21.5492C51.8579 21.6795 51.9227 21.8304 51.9507 21.99C51.9786 22.1496 51.969 22.3136 51.9226 22.4689Z"
        fill="white"
      />
      <path
        d="M37.9288 15.9669H31.1146C30.9371 15.9669 30.7619 15.9276 30.6013 15.8521C30.4407 15.7765 30.2988 15.6664 30.1857 15.5296L27 11.6622C26.887 11.5247 26.7474 11.4113 26.5896 11.3289C26.4319 11.2465 26.2591 11.1967 26.0817 11.1825C25.9042 11.1683 25.7258 11.19 25.5569 11.2463C25.388 11.3026 25.2323 11.3923 25.0988 11.5101C25.0459 11.5567 24.9968 11.6076 24.9522 11.6622L21.7664 15.5296C21.6533 15.6664 21.5114 15.7765 21.3508 15.8521C21.1903 15.9276 21.015 15.9669 20.8375 15.9669H14.0477C13.8235 15.9666 13.6039 15.9025 13.4146 15.7822C13.2253 15.662 13.0741 15.4904 12.9785 15.2876C12.8829 15.0847 12.8468 14.8588 12.8746 14.6363C12.9023 14.4137 12.9927 14.2037 13.1352 14.0305L21.0521 4.40794L23.8441 1.01576C24.0965 0.708454 24.4118 0.458794 24.7687 0.283581C25.1257 0.108369 25.5161 0.0116719 25.9136 0L26.0738 0C26.4709 0.0118807 26.8608 0.108685 27.2173 0.283896C27.5738 0.459107 27.8887 0.708659 28.1406 1.01576L28.3796 1.30636L30.9326 4.40794L32.7007 6.55624L38.8387 14.0305C38.981 14.2035 39.0713 14.4133 39.0992 14.6356C39.127 14.8579 39.0912 15.0836 38.996 15.2863C38.9007 15.4891 38.7499 15.6607 38.561 15.7812C38.3721 15.9016 38.1529 15.966 37.9288 15.9669Z"
        fill="white"
      />
      <path
        d="M32.6898 6.55624C31.5895 6.55827 30.5032 6.80268 29.5081 7.27208C28.5129 7.74149 27.6334 8.42436 26.9321 9.27216L26.0005 10.402L25.1015 11.4884C25.0486 11.535 24.9996 11.5859 24.9549 11.6404L21.7664 15.5296C21.6533 15.6664 21.5114 15.7765 21.3508 15.8521C21.1903 15.9276 21.015 15.9669 20.8375 15.9669H14.0477C13.8235 15.9666 13.6039 15.9025 13.4146 15.7822C13.2253 15.662 13.0741 15.4904 12.9785 15.2876C12.8829 15.0847 12.8468 14.8588 12.8746 14.6363C12.9023 14.4137 12.9927 14.2037 13.1352 14.0305L21.0521 4.40794L23.8441 1.01576C24.0965 0.708454 24.4118 0.458794 24.7687 0.283581C25.1257 0.108369 25.5161 0.0116719 25.9136 0L26.0738 0C26.4709 0.0118807 26.8608 0.108685 27.2173 0.283896C27.5738 0.459107 27.8887 0.708659 28.1406 1.01576L28.3796 1.30636L30.9326 4.40794L32.6898 6.55624Z"
        fill="white"
      />
      <path d="M32.6882 6.55625L30.9202 4.40796L32.6882 6.55625Z" fill="white" />
      <path
        d="M83.2981 11.0674C82.8908 10.4846 82.3254 10.0304 81.6686 9.75834C81.5474 9.70402 81.4232 9.65686 81.2965 9.61711C81.8295 9.34173 82.2844 8.93631 82.6191 8.4384C82.993 7.85615 83.1823 7.17459 83.1623 6.48294C83.1745 5.95613 83.057 5.43441 82.8202 4.96367C82.5834 4.49293 82.2346 4.08759 81.8044 3.78331C80.8991 3.0989 79.5456 2.7576 77.7441 2.75941H70.756V17.0832H78.1623C80.0454 17.0832 81.4712 16.7383 82.4399 16.0484C82.9054 15.7316 83.2827 15.3017 83.5363 14.7991C83.79 14.2965 83.9118 13.7376 83.8902 13.175C83.9158 12.4281 83.7089 11.6917 83.2981 11.0674ZM74.0477 5.25534H77.3448C77.9872 5.21721 78.6275 5.35824 79.1944 5.66273C79.4075 5.79635 79.5807 5.98487 79.6959 6.20851C79.8111 6.43215 79.8639 6.68267 79.8489 6.93378C79.8646 7.18454 79.8132 7.43495 79.7001 7.65929C79.587 7.88363 79.4162 8.0738 79.2052 8.21027C78.7761 8.48186 78.1596 8.63123 77.3557 8.63123H74.0477V5.25534ZM79.8815 14.1581C79.4306 14.4297 78.7951 14.5873 77.9369 14.5873H74.0477V11.0566H77.9477C78.7951 11.0566 79.4415 11.2005 79.8924 11.4884C80.1173 11.6325 80.299 11.8348 80.4181 12.0739C80.5372 12.3131 80.5892 12.58 80.5686 12.8463C80.5805 13.1063 80.5231 13.3647 80.4024 13.5951C80.2817 13.8256 80.1019 14.0199 79.8815 14.1581Z"
        fill="white"
      />
      <path
        d="M102.132 6.95264C101.752 6.06401 101.193 5.26333 100.489 4.60065C99.7705 3.92571 98.9249 3.4004 98.0014 3.05529C97.0041 2.68493 95.9473 2.50081 94.8835 2.5121C93.8176 2.49688 92.7583 2.6811 91.7602 3.05529C90.8368 3.40815 89.9916 3.93872 89.2724 4.61694C88.5699 5.27795 88.0098 6.07555 87.6266 6.96078C87.2281 7.89525 87.0292 8.90264 87.0426 9.91843C87.0314 10.9305 87.2302 11.9339 87.6266 12.8652C88.0085 13.7512 88.5687 14.549 89.2724 15.209C89.9952 15.8853 90.843 16.4139 91.7683 16.7653C93.7813 17.4932 95.9857 17.4932 97.9987 16.7653C98.9214 16.427 99.7678 15.9091 100.489 15.2416C101.196 14.5768 101.756 13.7721 102.135 12.8788C102.531 11.9435 102.73 10.9367 102.719 9.92114C102.731 8.90173 102.531 7.89097 102.132 6.95264ZM99.0226 11.7734C98.8046 12.3157 98.4816 12.8095 98.072 13.2264C97.667 13.6392 97.183 13.9662 96.6488 14.1879C96.088 14.4173 95.4867 14.5318 94.8808 14.5246C94.2705 14.5312 93.6649 14.4168 93.0991 14.1879C92.5632 13.9695 92.0786 13.6421 91.676 13.2264C91.2725 12.8068 90.9534 12.3135 90.7363 11.7734C90.5037 11.184 90.3884 10.5548 90.3968 9.92114C90.3882 9.2875 90.5035 8.65827 90.7363 8.06888C90.9534 7.52875 91.2725 7.03545 91.676 6.61586C92.0786 6.20021 92.5632 5.8728 93.0991 5.65443C93.6649 5.42551 94.2705 5.31105 94.8808 5.31765C95.4867 5.31045 96.088 5.42497 96.6488 5.65443C97.183 5.87612 97.667 6.2031 98.072 6.61586C98.4816 7.0328 98.8046 7.52661 99.0226 8.06888C99.2554 8.65827 99.3707 9.2875 99.3621 9.92114C99.3705 10.5548 99.2552 11.184 99.0226 11.7734Z"
        fill="white"
      />
      <path
        d="M117.072 11.0348C116.776 10.5477 116.366 10.1403 115.877 9.84799C115.366 9.54792 114.824 9.30475 114.261 9.12284C113.671 8.93272 113.079 8.77248 112.479 8.64212C111.879 8.51176 111.333 8.38411 110.85 8.25374C110.422 8.15358 110.018 7.9689 109.663 7.71056C109.518 7.6055 109.401 7.46683 109.321 7.30645C109.242 7.14608 109.202 6.9688 109.206 6.78986C109.204 6.49565 109.3 6.209 109.478 5.97508C109.705 5.70009 110.007 5.49717 110.347 5.39116C110.857 5.23115 111.391 5.15861 111.925 5.1766C112.589 5.1799 113.249 5.27123 113.889 5.44819C114.584 5.63607 115.251 5.91458 115.874 6.27655L116.917 3.78062C116.204 3.34712 115.427 3.02991 114.614 2.84091C113.741 2.62344 112.844 2.51308 111.944 2.51228C110.792 2.47586 109.646 2.68222 108.579 3.11793C107.77 3.45002 107.076 4.01181 106.583 4.73391C106.147 5.39934 105.917 6.17892 105.923 6.97454C105.899 7.62649 106.051 8.27286 106.363 8.84581C106.654 9.33906 107.063 9.75245 107.552 10.049C108.063 10.3618 108.61 10.6091 109.182 10.785C109.774 10.966 110.363 11.1227 110.95 11.2548C111.537 11.387 112.08 11.5237 112.58 11.6649C113.016 11.7753 113.43 11.9639 113.799 12.2217C113.943 12.3254 114.059 12.4628 114.138 12.6219C114.217 12.781 114.255 12.9569 114.25 13.1343C114.254 13.4227 114.152 13.7027 113.965 13.9219C113.722 14.1796 113.414 14.3673 113.074 14.4651C112.56 14.6187 112.024 14.6884 111.488 14.6715C110.611 14.6707 109.741 14.5276 108.91 14.2478C108.134 14.0005 107.401 13.634 106.738 13.1614L105.624 15.6709C106.392 16.2234 107.256 16.6294 108.172 16.8687C109.246 17.1776 110.359 17.3321 111.477 17.3276C112.633 17.365 113.784 17.1558 114.853 16.7138C115.665 16.3779 116.364 15.8173 116.868 15.0979C117.307 14.4446 117.539 13.6742 117.533 12.8871C117.557 12.2386 117.397 11.5966 117.072 11.0348Z"
        fill="white"
      />
      <path
        d="M131.662 11.035C131.365 10.5488 130.955 10.1416 130.467 9.84814C129.952 9.54683 129.406 9.30362 128.837 9.12299C128.251 8.93288 127.658 8.77264 127.058 8.64227C126.458 8.51191 125.912 8.38426 125.429 8.2539C125.001 8.15373 124.598 7.96905 124.242 7.71071C124.099 7.60461 123.983 7.46551 123.905 7.30523C123.827 7.14495 123.789 6.96821 123.794 6.79001C123.792 6.49584 123.887 6.20925 124.066 5.97524C124.292 5.70025 124.594 5.49733 124.935 5.39131C125.445 5.2311 125.978 5.15855 126.513 5.17675C127.176 5.18005 127.837 5.27138 128.476 5.44835C129.171 5.63623 129.839 5.91473 130.462 6.2767L131.504 3.78077C130.792 3.34632 130.014 3.02819 129.201 2.83834C128.328 2.62087 127.432 2.51052 126.532 2.50972C125.38 2.4733 124.233 2.67966 123.167 3.11537C122.358 3.44745 121.664 4.00924 121.17 4.73134C120.736 5.39738 120.508 6.17691 120.516 6.97198C120.492 7.62392 120.644 8.2703 120.956 8.84325C121.247 9.33781 121.657 9.75219 122.148 10.0491C122.658 10.362 123.206 10.6093 123.778 10.7851C124.37 10.9662 124.959 11.1228 125.546 11.255C126.132 11.3872 126.676 11.5239 127.175 11.6651C127.604 11.7737 128.009 11.9576 128.373 12.2083C128.517 12.312 128.633 12.4494 128.712 12.6085C128.79 12.7676 128.829 12.9435 128.824 13.1208C128.828 13.4098 128.725 13.6902 128.536 13.9084C128.295 14.1668 127.988 14.3545 127.648 14.4516C127.134 14.6052 126.598 14.6749 126.062 14.658C125.192 14.6561 124.328 14.514 123.503 14.2371C122.727 13.9904 121.994 13.6238 121.331 13.1507L120.206 15.6466C120.975 16.2086 121.842 16.623 122.762 16.8688C123.836 17.1777 124.949 17.3322 126.067 17.3278C127.223 17.3651 128.374 17.1559 129.443 16.714C130.253 16.3772 130.95 15.8166 131.453 15.098C131.892 14.4447 132.124 13.6743 132.118 12.8873C132.142 12.2394 131.984 11.5978 131.662 11.035Z"
        fill="white"
      />
      <path
        d="M144.942 14.0848C144.326 14.3803 143.651 14.5308 142.968 14.5248C142.338 14.5322 141.713 14.4179 141.126 14.188C140.582 13.9724 140.088 13.6481 139.673 13.2348C139.261 12.8216 138.937 12.328 138.723 11.7845C138.491 11.1911 138.375 10.5584 138.383 9.92133C138.375 9.28422 138.491 8.65158 138.723 8.05821C138.936 7.5135 139.26 7.01879 139.673 6.60515C140.087 6.19151 140.582 5.86789 141.126 5.65462C141.713 5.42479 142.338 5.31044 142.968 5.31784C143.65 5.31087 144.325 5.45754 144.942 5.74696C145.571 6.04751 146.126 6.48015 146.572 7.01529L148.72 5.05168C148.044 4.21888 147.173 3.56585 146.184 3.15053C144.117 2.3258 141.82 2.28907 139.728 3.04733C138.807 3.39189 137.965 3.91735 137.251 4.59269C136.554 5.25841 136 6.05854 135.621 6.94468C135.226 7.8838 135.028 8.89428 135.04 9.91318C135.028 10.9312 135.226 11.9409 135.621 12.879C136.003 13.7731 136.562 14.5801 137.266 15.2507C137.97 15.9212 138.803 16.4413 139.714 16.779C140.699 17.1473 141.743 17.3314 142.794 17.3222C143.96 17.3401 145.116 17.1173 146.192 16.6677C147.179 16.2499 148.05 15.5972 148.728 14.7665L146.58 12.8029C146.131 13.3408 145.572 13.7776 144.942 14.0848Z"
        fill="white"
      />
      <path
        d="M166.346 6.95266C165.965 6.06379 165.405 5.2631 164.7 4.60067C163.981 3.92688 163.136 3.40172 162.213 3.05531C161.215 2.68462 160.157 2.50051 159.092 2.51213C158.027 2.49666 156.969 2.68091 155.972 3.05531C155.049 3.40849 154.205 3.93904 153.486 4.61697C152.783 5.27737 152.221 6.07507 151.838 6.96081C151.441 7.89561 151.242 8.90277 151.254 9.91845C151.244 10.9304 151.443 11.9335 151.838 12.8652C152.22 13.7518 152.781 14.5498 153.486 15.2091C154.209 15.8859 155.057 16.4146 155.982 16.7653C157.995 17.4932 160.2 17.4932 162.213 16.7653C163.136 16.4189 163.981 15.8937 164.7 15.2199C165.407 14.5551 165.968 13.7504 166.346 12.8571C166.741 11.9214 166.94 10.9148 166.93 9.89944C166.939 8.88765 166.74 7.88486 166.346 6.95266ZM163.228 11.7734C163.01 12.3158 162.686 12.8096 162.275 13.2264C161.871 13.6391 161.388 13.9661 160.855 14.1879C160.293 14.4171 159.691 14.5316 159.084 14.5247C158.475 14.5313 157.87 14.4169 157.305 14.1879C156.774 13.9679 156.294 13.6406 155.895 13.2264C155.491 12.807 155.171 12.3137 154.953 11.7734C154.721 11.1838 154.607 10.5546 154.616 9.92117C154.607 9.2877 154.721 8.65849 154.953 8.06891C155.171 7.52863 155.491 7.03533 155.895 6.61589C156.298 6.20023 156.783 5.87282 157.319 5.65445C157.883 5.42547 158.488 5.311 159.098 5.31768C159.704 5.31078 160.306 5.42528 160.868 5.65445C161.402 5.87621 161.885 6.2032 162.289 6.61589C162.694 7.03392 163.014 7.52763 163.228 8.06891C163.46 8.65849 163.575 9.2877 163.565 9.92117C163.575 10.5546 163.46 11.1838 163.228 11.7734Z"
        fill="white"
      />
      <path
        d="M184.364 6.14598C183.69 5.06844 182.725 4.20326 181.58 3.65004C180.298 3.03438 178.89 2.7292 177.468 2.75922H170.95V17.083H177.468C178.89 17.113 180.298 16.8078 181.58 16.1922C182.724 15.6382 183.689 14.7732 184.364 13.6962C185.05 12.5588 185.398 11.2492 185.366 9.92111C185.398 8.59299 185.051 7.28328 184.364 6.14598ZM181.425 12.284C181.049 12.9557 180.482 13.5009 179.796 13.8511C179.023 14.2284 178.171 14.4147 177.311 14.3942H174.296V5.45885H177.305C178.165 5.43835 179.017 5.62464 179.79 6.00203C180.472 6.34755 181.039 6.88463 181.42 7.54739C181.823 8.2722 182.025 9.0919 182.004 9.92111C182.024 10.7461 181.824 11.5614 181.425 12.284Z"
        fill="white"
      />
      <path
        d="M200.487 14.4216V17.0805H189.395V2.7594H200.221V5.41829H192.692V8.50901H199.341V11.1054H192.692V14.4216H200.487Z"
        fill="white"
      />
      <path
        d="M214.156 12.4771H214.178C215.053 12.1174 215.801 11.5046 216.326 10.7171C216.847 9.88771 217.11 8.92298 217.084 7.94419C217.108 6.96912 216.844 6.00862 216.326 5.18209C215.802 4.38745 215.054 3.76495 214.178 3.3923C213.129 2.9515 211.999 2.73857 210.862 2.76764H204.661V17.0833H207.997V13.0718H211.006L213.787 17.0833H217.347L214.156 12.4771ZM212.969 9.77743C212.452 10.2138 211.687 10.432 210.677 10.432H207.997V5.46183H210.677C211.687 5.46183 212.451 5.67367 212.969 6.10279C213.23 6.32862 213.436 6.61139 213.57 6.92926C213.704 7.24712 213.764 7.59158 213.743 7.93604C213.763 8.28167 213.704 8.62727 213.57 8.94643C213.436 9.26558 213.23 9.54983 212.969 9.77743Z"
        fill="white"
      />
      <path
        d="M68.8878 42.287L74.6863 29.2506H77.6738L83.5266 42.287H80.3544L75.5636 30.7661H76.7749L71.9731 42.287H68.8878ZM71.8156 39.4896L72.5788 37.1974H79.3278L80.1209 39.4896H71.8156Z"
        fill="white"
      />
      <path
        d="M91.6898 42.4363C90.6903 42.4552 89.7016 42.2276 88.8109 41.7736C88.2011 41.4423 87.6643 40.9913 87.2328 40.4477C86.8012 39.9041 86.4838 39.279 86.2995 38.6099C86.1151 37.9407 86.0677 37.2413 86.1599 36.5534C86.2522 35.8655 86.4823 35.2032 86.8365 34.6063C87.3187 33.831 88.0007 33.1996 88.8109 32.7785C89.7008 32.3188 90.6912 32.0883 91.6925 32.1077C92.6569 32.0846 93.6107 32.313 94.46 32.7704C95.2243 33.1827 95.8257 33.8425 96.1656 34.6416L93.9304 35.8747C93.6994 35.4476 93.358 35.0902 92.9418 34.8399C92.555 34.6215 92.1177 34.5082 91.6735 34.5113C91.2119 34.5066 90.7567 34.6197 90.3509 34.8399C89.9511 35.0573 89.6207 35.383 89.3976 35.7796C89.1502 36.2367 89.0284 36.7512 89.0445 37.2706C89.0287 37.7936 89.1504 38.3115 89.3976 38.7725C89.6187 39.1735 89.9493 39.5032 90.3509 39.7231C90.7569 39.9424 91.2121 40.0545 91.6735 40.049C92.1175 40.053 92.5548 39.9407 92.9418 39.7231C93.3615 39.4689 93.7035 39.1044 93.9304 38.6693L96.1656 39.8997C95.82 40.6937 95.22 41.3502 94.46 41.7655C93.6116 42.2285 92.6561 42.4599 91.6898 42.4363Z"
        fill="white"
      />
      <path
        d="M103.207 42.4362C102.521 42.4544 101.839 42.317 101.213 42.0343C100.69 41.7977 100.24 41.422 99.9153 40.9479C99.6115 40.4892 99.4526 39.9499 99.459 39.3998C99.4391 38.8253 99.6067 38.26 99.9364 37.7891C100.266 37.3183 100.74 36.9675 101.287 36.7898C102.09 36.5138 102.937 36.3877 103.785 36.4177H106.433V38.0935H104.084C103.4 38.0935 102.93 38.2075 102.675 38.4302C102.554 38.5337 102.457 38.6625 102.391 38.8076C102.325 38.9526 102.292 39.1103 102.294 39.2695C102.288 39.4417 102.325 39.6127 102.402 39.7672C102.478 39.9217 102.591 40.0549 102.732 40.1549C103.081 40.3886 103.496 40.5029 103.916 40.4808C104.382 40.4888 104.842 40.3735 105.249 40.1467C105.638 39.9162 105.928 39.5508 106.064 39.1201L106.512 40.478C106.313 41.0834 105.902 41.5965 105.355 41.9229C104.7 42.2878 103.957 42.4654 103.207 42.4362ZM106.265 42.2869V40.3287L106.078 39.8996V36.3933C106.092 36.1243 106.048 35.8554 105.95 35.6044C105.852 35.3535 105.702 35.1262 105.51 34.9376C105.13 34.5899 104.549 34.4161 103.766 34.4161C103.231 34.4162 102.7 34.5015 102.191 34.6687C101.711 34.8178 101.26 35.0474 100.858 35.3477L99.8338 33.3162C100.431 32.8958 101.1 32.5892 101.808 32.4118C102.575 32.2082 103.364 32.105 104.158 32.1049C105.686 32.1049 106.873 32.467 107.718 33.1912C108.564 33.9155 108.986 35.0408 108.986 36.5671V42.2869H106.265Z"
        fill="white"
      />
      <path
        d="M118.257 42.4361C117.364 42.4492 116.482 42.2273 115.702 41.7924C114.95 41.3699 114.331 40.7476 113.912 39.9945C113.486 39.1526 113.263 38.2222 113.263 37.2785C113.263 36.3349 113.486 35.4045 113.912 34.5626C114.33 33.8089 114.95 33.1864 115.702 32.7647C116.482 32.3298 117.364 32.1078 118.257 32.121C119.034 32.1041 119.8 32.2975 120.476 32.6805C121.132 33.0782 121.647 33.6719 121.948 34.3779C122.327 35.2985 122.504 36.2892 122.47 37.284C122.501 38.2776 122.33 39.2672 121.967 40.1927C121.683 40.9027 121.176 41.5013 120.522 41.8983C119.829 42.2769 119.047 42.4626 118.257 42.4361ZM118.741 40.0488C119.197 40.0561 119.647 39.9438 120.047 39.7229C120.44 39.4975 120.76 39.165 120.97 38.7641C121.208 38.3029 121.327 37.7893 121.315 37.2704C121.33 36.752 121.211 36.2386 120.97 35.7793C120.756 35.3861 120.436 35.0606 120.047 34.8396C119.648 34.6178 119.197 34.5045 118.741 34.511C118.291 34.5059 117.848 34.6192 117.456 34.8396C117.066 35.0599 116.746 35.3856 116.533 35.7793C116.29 36.238 116.171 36.7519 116.188 37.2704C116.175 37.7895 116.293 38.3034 116.533 38.7641C116.742 39.1655 117.063 39.4983 117.456 39.7229C117.848 39.9425 118.291 40.0549 118.741 40.0488ZM121.402 42.2867V40.2362L121.459 37.2487L121.272 34.2829V28.4518H124.181V42.2867H121.402Z"
        fill="white"
      />
      <path
        d="M134.085 42.4363C133.045 42.4602 132.014 42.2299 131.082 41.7655C130.251 41.3504 129.555 40.7071 129.077 39.9111C128.598 39.1151 128.356 38.1992 128.379 37.2706C128.364 36.3375 128.599 35.4174 129.061 34.6063C129.509 33.8271 130.168 33.1905 130.962 32.7703C131.808 32.3205 132.753 32.0925 133.711 32.1076C134.636 32.0943 135.55 32.3118 136.37 32.7405C137.146 33.1543 137.795 33.7734 138.244 34.5302C138.722 35.3763 138.961 36.337 138.933 37.3086C138.933 37.42 138.933 37.5476 138.914 37.6916C138.895 37.8355 138.89 37.9632 138.876 38.0908H130.729V36.3934H137.347L136.209 36.8958C136.233 36.4249 136.13 35.9563 135.913 35.5379C135.71 35.1613 135.406 34.8483 135.036 34.6335C134.638 34.4096 134.186 34.2971 133.73 34.3075C133.27 34.2981 132.817 34.4106 132.415 34.6335C132.04 34.8479 131.733 35.1643 131.53 35.546C131.31 35.9759 131.202 36.454 131.215 36.9366V37.3847C131.201 37.8942 131.33 38.3974 131.587 38.8377C131.83 39.2444 132.185 39.5718 132.611 39.7801C133.101 40.0089 133.637 40.1205 134.178 40.106C134.652 40.1142 135.123 40.0322 135.566 39.8643C135.979 39.6956 136.357 39.4484 136.676 39.1365L138.225 40.8149C137.749 41.3465 137.154 41.7578 136.489 42.0153C135.722 42.3084 134.906 42.4513 134.085 42.4363Z"
        fill="white"
      />
      <path
        d="M155.973 32.1076C156.706 32.0927 157.432 32.2558 158.088 32.5829C158.709 32.9051 159.215 33.413 159.533 34.0359C159.909 34.8174 160.088 35.6789 160.055 36.5454V42.2868H157.146V36.9908C157.146 36.1851 156.982 35.5885 156.654 35.201C156.481 35.007 156.265 34.8545 156.025 34.7548C155.784 34.6552 155.524 34.6109 155.264 34.6252C154.86 34.6168 154.462 34.7162 154.11 34.9131C153.768 35.1108 153.497 35.4111 153.335 35.7714C153.136 36.2394 153.044 36.7459 153.064 37.2542V42.2868H150.155V36.9908C150.155 36.176 149.992 35.5867 149.661 35.201C149.481 35.0043 149.26 34.8505 149.013 34.7508C148.767 34.6512 148.501 34.6082 148.235 34.6252C147.837 34.6185 147.444 34.7179 147.097 34.9131C146.755 35.1135 146.483 35.4128 146.315 35.7714C146.113 36.2386 146.02 36.7457 146.043 37.2542V42.2868H143.129V32.2569H145.907V34.9729L145.386 34.1907C145.699 33.5289 146.215 32.9838 146.858 32.6345C147.524 32.2711 148.272 32.0841 149.031 32.0913C149.891 32.0744 150.736 32.3177 151.456 32.7893C152.176 33.2943 152.671 34.0601 152.836 34.924L151.809 34.6524C152.143 33.8852 152.703 33.238 153.414 32.7974C154.182 32.3248 155.071 32.0851 155.973 32.1076Z"
        fill="white"
      />
      <path
        d="M166.023 46.0541C165.504 46.0526 164.988 45.9701 164.494 45.8097C164.031 45.674 163.601 45.4458 163.229 45.1388L164.293 43.0503C164.515 43.262 164.778 43.4273 165.064 43.5364C165.348 43.6463 165.649 43.7034 165.953 43.7048C166.296 43.7212 166.635 43.6186 166.911 43.4142C167.204 43.1505 167.431 42.8223 167.574 42.4555L168.095 41.2252L168.318 40.9074L171.936 32.2572H174.714L170.181 42.9118C169.929 43.6076 169.553 44.2518 169.07 44.8129C168.695 45.2398 168.221 45.5697 167.691 45.7744C167.156 45.9654 166.591 46.0602 166.023 46.0541ZM167.737 42.6782L163.283 32.2572H166.27L169.758 40.6766L167.737 42.6782Z"
        fill="white"
      />
    </svg>
  )
}
