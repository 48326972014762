import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import useLiveClassesViewModel from "./LiveClassViewModel"
import SessionCard from "./components/SessionCard"
import ButtonGroup from "core/components/ButtonGroup"
import {
  STR_LIVE_CLASSES,
  STR_LIVE_CLASSES_SUBTITLE,
  STR_UPCOMING_SESSIONS,
  STR_PAST_SESSIONS,
} from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import Toast from "core/components/Toast"

export default function LiveClassView() {
  const TABS = [STR_UPCOMING_SESSIONS, STR_PAST_SESSIONS]

  const { toast, sessions, refreshed, fetchAllClasses, changeToastVisibility } = useLiveClassesViewModel()

  const [activeTab, setActiveTab] = React.useState(0)

  const handleTabChange = (i: number) => {
    setActiveTab(i)
  }
  const currentTimestamp = Math.floor(Date.now() / 1000)
  const pastSessions = !isEmpty(sessions)
    ? sessions.filter((session) => session.session_timestamp + 3 * 60 * 60 < currentTimestamp)
    : []

  const upcomingSessions = !isEmpty(sessions)
    ? sessions.filter((session) => session.session_timestamp + 3 * 60 * 60 >= currentTimestamp).reverse()
    : []

  const isRecorded = TABS[activeTab] === STR_PAST_SESSIONS

  React.useEffect(() => {
    if (refreshed) {
      fetchAllClasses()
    }
  }, [refreshed])

  return (
    <DashboardLayout isLoading={isEmpty(sessions)}>
      <div className="space-y-6 p-6">
        {activeTab == 0 && (
          <div>
            <h2 className="text-new-accent">{STR_LIVE_CLASSES}</h2>
            <p>{STR_LIVE_CLASSES_SUBTITLE}</p>
          </div>
        )}
        {activeTab == 1 && (
          <div>
            <h2 className="text-new-accent">Past Sessions</h2>
          </div>
        )}
        <ButtonGroup buttons={TABS} active={activeTab} onChange={handleTabChange} />
        <div className="flex flex-col gap-6">
          {(isRecorded ? pastSessions : upcomingSessions).map((session: any, i: number) => (
            <SessionCard key={i} session={session} isRecorded={isRecorded} />
          ))}
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
