import NewUpdatesDataSource from "data/dataSource/Student/NewUpdatesDataSource"
import { NewUpdates } from "domain/model/NewUpdates"
import { NewUpdatesRepository } from "domain/repository/Student/NewUpdatesRepository"

export class NewUpdatesRepositoryImpl implements NewUpdatesRepository {
  private Datasource: NewUpdatesDataSource
  constructor(Datasource: NewUpdatesDataSource) {
    this.Datasource = Datasource
  }
  async newUpdates(newUpdates: NewUpdates): Promise<any> {
    return await this.Datasource.newUpdates(newUpdates)
  }
}
