import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { useEffect, useState } from "react"
import TabGroup from "./components/TabGroup"
import { Button } from "core/components/v2/Button"
import ReferralTable from "./components/ReferralTable"
import ReferralModal from "./components/ReferralModal"
import Input from "core/components/Input"
import StandardSelect from "core/components/v2/StandardSelect"
import ReferralWorkingModal from "./components/ReferralWorkingModal"
import useReferViewModel from "./ReferViewModel"
import ReferralTableTabGroup from "./components/ReferralTableTabGroup"
import Toast from "core/components/Toast"
import ReferBanner from "assets/svgs/ReferBanner"
import LineBottom from "core/components/payment-method/LineBottom"
import Line from "core/components/payment-method/Line"
import ExclusiveBanner from "assets/svgs/ExclusiveBanner"
import HomePageGradient from "core/components/new/HomePageGradient"
import Spinner from "core/components/Spinner"
import GoldCoin from "assets/svgs/GoldCoin"
import ReferralRewardsModal from "./components/ReferralRewardsModal"
import LinearGradientBottomLeft from "assets/svgs/LinearGradientBottomLeft"
import PhoneInput from "react-phone-number-input"
import { Head } from "core/components/seo"

const ReferEarnView = () => {
  const {
    myReferralCode,
    ReferredByActive,
    getReferralData,
    postReferralFormData,
    candiDate,
    myreferredCandidates,
    handleCopy,
    toast,
    setReferredByActive,
    changeToastVisibility,
    handleViewReward,
    editReferDetails,
    setEditReferDetails,
    bankDetails,
    setBankDetails,
    editBankDetails,
    setEditBankDetails,
    courseOptions,
    setCourseOptions,
    relationOptions,
    isModalOpen,
    setIsModalOpen,
    student,
    formData,
    handleCourseOptions,
    handleRealationOptions,
    setFormData,
    handleSubmit,
    handleEditSubmit,
    handleDeleteSubmit,
    handleBankSubmit,
    handleEditBankSubmit,
    handleChange2,
    handlePhoneNumber,
    cancelForm,
    toggleCheckbox,
    loading,
    fetchLoading,
    setLoading,
    handleCopyToClipboard,
    handleLinkCopyToClipboard,
  } = useReferViewModel()

  const [activeButtonType, setActiveButtonType] = useState<string>("Refer & Earn")
  const [activeTableButtonType, setActiveTableButtonType] = useState<string>("My Referrals")
  const activeButtons = ["Refer & Earn", "Exclusive Offers"]
  const activeTableButtons = ["My Referrals", "Referred By"]

  useEffect(() => {
    getReferralData()
  }, [])

  function changeActiveButtonType(index: number) {
    const tabType = ["Refer & Earn", "Exclusive Offers"][index]
    setActiveButtonType(tabType)
  }
  function changeActiveTableButtonType(index: number) {
    const tabType = ["My Referrals", "Referred By"][index]
    setActiveTableButtonType(tabType)
  }

  const [referWorkingModal, setReferWorkingModal] = useState<boolean>(false)
  const [referRewardsModal, setReferRewardsModal] = useState<boolean>(false)

  return (
    <DashboardLayoutv2>
      <Head title="Refer & Earn" />

      <div className="p-[24px]">
        <ReferralModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
          }}
        >
          <div className="w-[347px] h-[556px] bg-white p-[24px] rounded-[8px]">
            <p className="text-[#1C3474] text-[16px] font-[600] mb-[16px]">Refer Details</p>
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Name (You want to refer)
              <span className="text-red-600">*</span>
            </div>

            <Input
              placeholder="Type here"
              type="text"
              name="referName"
              value={formData.referName}
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              onChange={handleChange2}
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Email (You want to refer)
              <span className="text-red-600">*</span>
            </div>
            <Input
              placeholder="Type here"
              type="text"
              name="referEmail"
              value={formData.referEmail}
              onChange={handleChange2}
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[3px]">
              Contact Number (You want to refer)
              <span className="text-red-600">*</span>
            </div>
            <PhoneInput
              defaultCountry="IN"
              value={formData.referNumber}
              onChange={handlePhoneNumber}
              placeholder="Phone Number"
              limitMaxLength
              className="mb-[16px]"
            />

            <div className="flex flex-col justify-between gap-0 font-semibold">
              <div className="text-new-solid-black text-[14px] font-normal">
                Relationship<span className="text-red-600">*</span>
              </div>
              <StandardSelect
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="relation"
                value={formData.relation}
                onChange={handleRealationOptions}
                options={relationOptions}
                required
              ></StandardSelect>
            </div>

            <div className="my-[16px] flex flex-col justify-between gap-0 font-semibold">
              <div className="text-new-solid-black text-[14px] font-normal">
                Course<span className="text-red-600">*</span>
              </div>
              <StandardSelect
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="course"
                value={formData.course}
                onChange={handleCourseOptions}
                options={courseOptions}
                required
              ></StandardSelect>
            </div>

            <div className="flex flex-row justify-between my-[16px]">
              <label className="flex items-start cursor-pointer">
                <input
                  type="checkbox"
                  className="mr-1 m-[2px] checked:bg-[#07B42D]"
                  checked={formData.agreedToTerms}
                  onChange={toggleCheckbox}
                />
                <span className="pl-3 pt-0 w-[265px] h-[34px] font-['Inter'] text-[14px] leading-[17px]">
                  I have cross-checked the information provided above.
                </span>
              </label>
            </div>

            <div className="absolute bottom-[20px] right-[24px] flex gap-[14px] mt-[8px]">
              <Button
                onClick={() => setIsModalOpen(false)}
                size="sm"
                border={"thin"}
                variant={"cancel"}
                className="w-[80px] h-[35px]"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                className="w-[80px] h-[35px]"
                size="sm"
                border={"thin"}
                variant={"primary"}
                disabled={
                  !formData.referName ||
                  !formData.referEmail ||
                  !formData.referNumber ||
                  !formData.relation ||
                  !formData.course ||
                  !formData.agreedToTerms ||
                  loading
                }
              >
                {loading ? <Spinner small={true} /> : "Submit"}
              </Button>
            </div>
          </div>
        </ReferralModal>

        <ReferralRewardsModal
          isOpen={referRewardsModal}
          onClose={() => setReferRewardsModal(false)}
          student={student}
        />

        <div className="flex items-center justify-between mb-[24px] h-[52px] bg-[#F9F9F9] rounded-[8px] px-[24px] py-[12px]">
          <div className="flex gap-[8px] items-center">
            <GoldCoin />
            <p className="text-[#414141] text-[14px] font-[500]">
              Want to know more about your referral rewards, Click to view more!{" "}
            </p>
          </div>
          <Button
            className="px-[16px] bg-[#333] text-white hover:text-white hover:bg-[#161616]"
            border="thin"
            size="sm"
            variant={"secondary"}
            onClick={() => setReferRewardsModal(true)}
          >
            Click to View
          </Button>
        </div>

        <TabGroup
          buttons={activeButtons}
          active={activeButtons.indexOf(activeButtonType)}
          onChange={changeActiveButtonType}
        />

        {activeButtonType === "Refer & Earn" && (
          <div className="my-[24px]">
            <div className="relative bg-[#333] h-[220px] w-full rounded-[8px] shadow-md overflow-hidden">
              <div className="absolute -top-[130px] animate-moveWhite">
                <Line />
              </div>
              <div className="absolute -top-[130px] animate-moveWhiteDelayed">
                <Line />
              </div>

              <div className="absolute -bottom-[130px] animate-moveBottomWhite">
                <LineBottom />
              </div>
              <div className="absolute -bottom-[130px] animate-moveBottomWhiteDelayed">
                <LineBottom />
              </div>

              <div
                style={{
                  background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="text-[43.643px] font-[700] mt-[35px] ml-[42px] max-xl:text-[25px]"
              >
                Refer & Earn
              </div>
              <hr className="w-[258.597px] h-[1.134px] ml-[42px]" />
              <p className="text-[16px] text-[#fff] font-[400] w-[270px] mt-[15px] ml-[42px]">
                Help your friends switch to a product-based company and get rewarded with up to ₹1 lakh!
              </p>
              <ReferBanner className="absolute  -top-[95px] right-0" />
              <span className="absolute text-[6px] font-[400] bottom-[4px] right-[11px] text-white">* T&C applied</span>
            </div>
            <div className="flex justify-between max-xl:gap-[3%] h-[150px] my-[24px] ">
              <div className="overflow-hidden relative w-[280px] bg-[#333] rounded-[8px] py-[16px] px-[18px] min-[1920px]:w-[340px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-[#FFF] ">Your current referral balance!</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[43.643px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹{" "}
                  {myreferredCandidates?.reduce((acc: number, user: any) => {
                    if (user?.studentFinalized && !user?.referrer_reedem) {
                      return acc + (user?.amount || 0) + (user?.bonus || 0)
                    }
                    return acc
                  }, 0) + (candiDate ? (candiDate.referee_redeem ? 0 : candiDate?.amount) : 0)}{" "}
                </div>

                <p className="absolute bottom-[15px] text-[14px] font-[400] text-[#fff]">
                  Total Earning : ₹{" "}
                  {myreferredCandidates?.reduce((acc: any, user: any) => {
                    if (user?.studentFinalized) {
                      return acc + (user?.amount || 0) + (user?.bonus || 0)
                    }
                    return acc
                  }, 0) + (candiDate?.amount ? candiDate?.amount : 0)}{" "}
                </p>
              </div>

              <div className="w-[580px] min-[1920px]:w-[780px] px-[32px] py-[27px] bg-[#F9F9F9] rounded-[8px]">
                <p className="text-[14px] font-[600] text-[#333]">Refer your friend and win exclusive offers!</p>
                <p className="text-[12px] font-[500] text-[#525252]">
                  <span
                    className="text-[#1C3474] font-[600] underline cursor-pointer"
                    onClick={() => setReferWorkingModal(true)}
                  >
                    Click Here
                  </span>
                  {"  "}to know the referral process and FAQ's.
                </p>
                <div className="flex gap-[9px] mt-[25px] max-xl:mt-[5px]">
                  <Button
                    size={"sm"}
                    className="hover:bg-blue-950"
                    onClick={() =>
                      handleLinkCopyToClipboard(`https://platform.bosscoderacademy.com/referlink/${student?.email}`)
                    }
                  >
                    Share referral Link
                  </Button>
                  <Button onClick={() => setIsModalOpen(true)} size={"sm"} variant={"ternary"} border={"thin"}>
                    + Refer from Here{" "}
                  </Button>
                </div>
              </div>

              <ReferralWorkingModal isOpen={referWorkingModal} onClose={() => setReferWorkingModal(false)} />
            </div>

            <div>
              <p className="text-[16px] font-[600] text-[#333]">Referral List</p>
              <p className="text-[14px] font-[400] text-[#646464]">Track all your referrals from here!</p>
            </div>

            <div>
              <div className="my-[24px]">
                <ReferralTableTabGroup
                  buttons={activeTableButtons}
                  active={activeTableButtons.indexOf(activeTableButtonType)}
                  onChange={changeActiveTableButtonType}
                />
              </div>

              {activeTableButtonType === "My Referrals" && (
                <ReferralTable
                  bankDetails={bankDetails}
                  editBankDetails={editBankDetails}
                  setEditBankDetails={setEditBankDetails}
                  setBankDetails={setBankDetails}
                  handleBankSubmit={handleBankSubmit}
                  handleEditBankSubmit={handleEditBankSubmit}
                  editReferDetails={editReferDetails}
                  handleEditSubmit={handleEditSubmit}
                  handleDeleteSubmit={handleDeleteSubmit}
                  loading={loading}
                  fetchLoading={fetchLoading}
                  relationOptions={relationOptions}
                  courseOptions={courseOptions}
                  setEditReferDetails={setEditReferDetails}
                  handleCopyToClipboard={handleCopyToClipboard}
                  tableHeaders={[
                    "Sr. No.",
                    "Student Name",
                    "",
                    "Course",
                    "Enrollment Status",
                    "Amount",
                    "Bonus",
                    "Redeem status",
                    "",
                    "",
                  ]}
                  myreferredCandidates={myreferredCandidates}
                />
              )}

              {activeTableButtonType === "Referred By" && (
                <ReferralTable
                  bankDetails={bankDetails}
                  editBankDetails={editBankDetails}
                  setEditBankDetails={setEditBankDetails}
                  setBankDetails={setBankDetails}
                  handleBankSubmit={handleBankSubmit}
                  handleEditBankSubmit={handleEditBankSubmit}
                  tableHeaders={["Student Name", "Relationship", "Amount", "Redeem status", "", ""]}
                  referrerDetails={candiDate}
                  fetchLoading={fetchLoading}
                  loading={loading}
                />
              )}
            </div>
          </div>
        )}
        {activeButtonType === "Exclusive Offers" && (
          <div className="py-[24px]">
            <div className="relative bg-[#333] h-[220px] w-full rounded-[8px] shadow-md overflow-hidden">
              <div className="absolute -top-[130px] animate-moveWhite">
                <Line />
              </div>
              <div className="absolute -top-[130px] animate-moveWhiteDelayed">
                <Line />
              </div>

              <div className="absolute -bottom-[130px] animate-moveBottomWhite">
                <LineBottom />
              </div>
              <div className="absolute -bottom-[130px] animate-moveBottomWhiteDelayed">
                <LineBottom />
              </div>

              <div
                style={{
                  background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="text-[43.643px] font-[700] mt-[35px] ml-[42px] max-xl:text-[25px]"
              >
                OFFERS
              </div>
              <hr className="w-[258.597px] h-[1.134px] ml-[42px]" />
              <p className="text-[16px] text-[#fff] font-[400] w-[270px] mt-[15px] ml-[42px]">
                Help your friends switch to a product-based company and get rewarded with up to ₹1 lakh!
              </p>
              <ExclusiveBanner className="absolute right-0 top-0" />
              <span className="absolute text-[6px] font-[400] bottom-[4px] right-[11px] text-white">* T&C applied</span>
            </div>
            <div className="flex justify-between gap-[10px] my-[24px]">
              <div className="relative overflow-hidden w-1/3 h-[165px] p-[16px] bg-[#333] hover:bg-[#310000] duration-1000 rounded-[8px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-white">On 3 Successful Referrals</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[30px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹ 50,000
                </div>

                <Button
                  onClick={() => window.open("https://forms.gle/3wHYC4tFxtyLMUyU7", "_blank", "noopener,noreferrer")}
                  className="mt-[18px] px-[16px] relative z-10"
                  size="sm"
                  variant={"ternary"}
                >
                  Redeem Now
                </Button>

                <p className="text-[8px] font-[400] text-[#FFF] my-[4px]">
                  <sup>*</sup>T & C Mentioned in the form
                </p>
              </div>
              <div className="relative overflow-hidden w-1/3 h-[165px] p-[16px] bg-[#333] hover:bg-[#310000] duration-1000 rounded-[8px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-white">On 2 Successful Referrals</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[30px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹ 25,000
                </div>

                <Button
                  onClick={() => window.open("https://forms.gle/3wHYC4tFxtyLMUyU7", "_blank", "noopener,noreferrer")}
                  className="mt-[18px] px-[16px] relative z-10"
                  size="sm"
                  variant={"ternary"}
                >
                  Redeem Now
                </Button>

                <p className="text-[8px] font-[400] text-[#FFF] my-[4px]">
                  <sup>*</sup>T & C Mentioned in the form
                </p>
              </div>
              <div className="relative overflow-hidden w-1/3 h-[165px] p-[16px] bg-[#333] hover:bg-[#310000] duration-1000 rounded-[8px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-white">
                  On 1 Successful Referral{" "}
                  <span className="ml-[2px] rounded-[4px]  bg-[#ffffff4f] px-[4px] py-[2px] text-[12px] text-[#FFF] font-[500] font-[Inter] shadow-[4px_4px_0px_0px_rgba(0,0,0,0.25)]">
                    Upto
                  </span>
                </p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[30px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹ 12,000
                </div>

                <Button
                  onClick={() => window.open("https://forms.gle/3wHYC4tFxtyLMUyU7", "_blank", "noopener,noreferrer")}
                  className="mt-[18px] px-[16px] relative z-10"
                  size="sm"
                  variant={"ternary"}
                >
                  Redeem Now
                </Button>

                <p className="text-[8px] font-[400] text-[#FFF] my-[4px]">
                  <sup>*</sup>T & C Mentioned in the form
                </p>
              </div>
            </div>

            <div className="flex justify-between items-center py-[16px] px-[24px] bg-[#333] text-white rounded-[8px]">
              <div>
                <p className="text-[14px] font-[600]">Do you want to refer?</p>
                <p className="text-[12px] font-[500]">Click on the refer to proceed with referral process.</p>
              </div>
              <div className="flex gap-[8px]">
                <Button
                  size="md"
                  variant={"ternary"}
                  onClick={() =>
                    handleLinkCopyToClipboard(`https://platform.bosscoderacademy.com/referlink/${student?.email}`)
                  }
                >
                  Share referral Link
                </Button>
                <Button
                  className="bg-[#333] text-white hover:text-[#333] focus:text-[#333] focus:bg-v2-gray-200"
                  border={"thin"}
                  size="md"
                  variant={"ternary"}
                  onClick={() => setIsModalOpen(true)}
                >
                  + Refer from Here{" "}
                </Button>
              </div>
            </div>
          </div>
        )}
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </div>
    </DashboardLayoutv2>
  )
}

export default ReferEarnView
