import { Auth } from "domain/model/Auth"

import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface GetStudentEMIUseCase {
  invoke(auth: Auth, student_email: string): Promise<any>
}

export class GetStudentEMI implements GetStudentEMIUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_email: string) {
    return await this.repository.getStudentEMI(auth, student_email)
  }
}
