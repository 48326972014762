import MonthlyReportDataSource from "data/dataSource/Admin/MonthlyReportDataSource"
import GetMonthlyReport from "domain/model/GetMonthlyReport"
import MonthlyReportRepositry from "domain/repository/Admin/MonthlyReport"

export class MonthlyReportRepositoryImpl implements MonthlyReportRepositry {
  private Datasource: MonthlyReportDataSource

  constructor(Datasource: MonthlyReportDataSource) {
    this.Datasource = Datasource
  }

  async getMonthlyReport(MonthlyReport: GetMonthlyReport): Promise<any> {
    return await this.Datasource.getMonthlyReport(MonthlyReport)
  }
}
