import { Auth } from "domain/model/Auth"
import { RedeemCoinsRepository } from "domain/repository/Student/RedeemCoinsRepository"

export interface GetExtraSessionUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetExtraSession implements GetExtraSessionUseCase {
  private repository: RedeemCoinsRepository

  constructor(repository: RedeemCoinsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getExtraSession(auth)
  }
}
