import { UpdateClass, UpdateClassAndTiming } from "domain/model/ScheduledClass"
import { ScheduledClassRepository } from "domain/repository/Admin/ScheduledClassRepository"

export interface UpdateScheduledClassUseCase {
  invoke(updateClassAndTiming: UpdateClassAndTiming): Promise<any>
}

export class UpdatePastClassAndTiming implements UpdateScheduledClassUseCase {
  private repository: ScheduledClassRepository

  constructor(repository: ScheduledClassRepository) {
    this.repository = repository
  }

  async invoke(updateClassAndTiming: UpdateClassAndTiming) {
    return await this.repository.updatePastClassAndTiming(updateClassAndTiming)
  }
}
