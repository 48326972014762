import { Auth } from "domain/model/Auth"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface getAllModulesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllModules implements getAllModulesUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllModules(auth)
  }
}
