import CCAvPaymentAdminDataSource from "data/dataSource/Admin/CCAvenue"
import { Auth } from "domain/model/Auth"
import { TPaymentDocumentItem } from "domain/model/Payment"
import CreateCCAvPayment from "domain/repository/Admin/CreateCCAvPaymentRepository"

export default class CCAvPaymentAdminRepository implements CreateCCAvPayment {
  private dataSource: CCAvPaymentAdminDataSource

  constructor(dataSource: CCAvPaymentAdminDataSource) {
    this.dataSource = dataSource
  }
  createPayment(auth: Auth, paymentData: any): Promise<any> {
    return this.dataSource.createCCAvPayment(auth, paymentData)
  }
  getPaymentDetailAdmin(auth: Auth, paymentData: any): Promise<any> {
    return this.dataSource.getCCAvPaymentDetailAdmin(auth, paymentData)
  }
  getStudentEMI(auth: Auth, student_email: string): Promise<any> {
    return this.dataSource.getStudentEMI(auth, student_email)
  }
  updateTransactionDetailAdmin(auth: Auth, paymentData: any): Promise<any> {
    return this.dataSource.updateTransactionDetailAdmin(auth, paymentData)
  }
}
