import { Auth } from "domain/model/Auth"
import DocumentationFileDataSource from "data/dataSource/Admin/DocumentationFileDataSource"
import pn from "core/utils/pn"
import { postAPI, postAPIForFiles } from "core/utils/axios"
import {
  API_CREATE_DOCUMENTATION_FILE,
  API_GET_DOCUEMENTATION_FILE,
  API_GET_DOCUEMENTATION_FILES,
  API_UPDATE_DOCUMENTATION_FILE,
  API_DELETE_DOCUMENTATION_FILES,
  API_V4_ADMIN,
} from "core/constants/strings"

export default class DocumentationFilesAPIDataSourceImpl implements DocumentationFileDataSource {
  async delete_document_file(auth: Auth, id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_DOCUMENTATION_FILES), {
        id_token: auth?.id_token,
        id: id,
      })
      return response
    } catch ({ response: { daa: error } }: any) {
      return error
    }
  }
  async get_document_files(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_DOCUEMENTATION_FILES), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async create_document_file(auth: Auth, file: File, name: string, feature: string, subFeature: string) {
    try {
      let data = new FormData()
      data.append("id_token", auth?.id_token)
      data.append("file", file)
      data.append("name", name)
      data.append("feature", feature)
      data.append("subFeature", subFeature)

      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_CREATE_DOCUMENTATION_FILE), data)
      return response
    } catch (error) {
      return error
    }
  }

  async get_document_file(auth: Auth, id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_DOCUEMENTATION_FILE), {
        id_token: auth?.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async update_document_file(auth: Auth, id: string, name: string, feature: string, subFeature: string) {
    let data = new FormData()
    data.append("id_token", auth?.id_token)
    data.append("id", id)
    data.append("name", name)
    data.append("feature", feature)
    data.append("subFeature", subFeature)
    try {
      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_UPDATE_DOCUMENTATION_FILE), data)
      return response
    } catch (error) {
      return error
    }
  }
}
