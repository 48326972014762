export default function FieldsView({ headerTitle, fields }: any) {
  return (
    <div className="flex flex-col gap-4 p-4 border border-new-neutral-light bg-new-editor-dark-500 rounded-sm">
      <p className="mb-2">
        <strong className="text-lg">{headerTitle}</strong>
      </p>
      {fields?.map((field: any, index: number) => {
        if (field?.type === "text") {
          return (
            <div
              key={index}
              className="text-justify whitespace-pre-line break-words"
              dangerouslySetInnerHTML={{ __html: field.content }}
            />
          )
        } else if (field.type === "image") {
          return (
            <img
              src={field?.content}
              alt="img"
              title={field?.filename}
              onClick={() => window.open(field?.content)}
              className="cursor-pointer rounded-sm"
            />
          )
        } else if (field.type === "document") {
          return (
            <div
              title="Click to open the document"
              className="p-2 text-pretty text-sm cursor-pointer text-blue-500 bg-new-editor-dark-600 rounded-sm"
              onClick={() => window.open(field?.content)}
            >
              {"📄" + field?.filename}
            </div>
          )
        } else if (field.type === "code") {
          return (
            <pre className="p-2 bg-new-editor-dark-600 rounded-sm" key={index}>
              {field.content}
            </pre>
          )
        }
        return <></>
      })}
    </div>
  )
}
