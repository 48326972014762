import { Auth } from "domain/model/Auth"
import DocumentationFileRepositry from "domain/repository/Admin/DocumentationFileRepository"

export interface CreateDocumentationFileUseCase {
  invoke(auth: Auth, file: File, name: string, feature: string, subFeature: string): Promise<any>
}

export class CreateDocumentationFile implements CreateDocumentationFileUseCase {
  private repository: DocumentationFileRepositry

  constructor(repository: DocumentationFileRepositry) {
    this.repository = repository
  }

  async invoke(auth: Auth, file: File, name: string, feature: string, subFeature: string): Promise<any> {
    return await this.repository.create_documentation_file(auth, file, name, feature, subFeature)
  }
}
