import CreateOnboardingMeetDataSource from "data/dataSource/Admin/CreateOnboardingMeetDataSource"
import Email from "data/dataSource/Admin/Email"
import { Auth } from "domain/model/Auth"
import { ManagerFeedback, OnboardingData } from "domain/model/OnboardingMeet"
import { CreateOnboardingMeetRepository } from "domain/repository/Admin/CreateOnboardingMeetRepository"

export class CreateOnboardingMeetRepositoryImpl implements CreateOnboardingMeetRepository {
  private Datasource: CreateOnboardingMeetDataSource

  constructor(Datasource: CreateOnboardingMeetDataSource) {
    this.Datasource = Datasource
  }

  async createOnboardingMeet(createOnboardingMeet: OnboardingData, auth: Auth): Promise<any> {
    return await this.Datasource.createOnboardingMeet(createOnboardingMeet, auth)
  }
  async getOnboardingMeet(auth: Auth, email: Email): Promise<any> {
    return await this.Datasource.getOnboardingMeet(auth, email)
  }
  async postOnboardingFeedback(postOnboardingFeedback: ManagerFeedback, auth: Auth): Promise<any> {
    return await this.Datasource.postOnboardingFeedback(postOnboardingFeedback, auth)
  }
}
