import React, { useState, useEffect } from "react"
import CertificateGradient from "core/components/new/CertificateGradient"
import { Button } from "core/components/v2/Button"
import lockImage from "assets/images/lock.png"
import CertificateImage from "assets/images/JohnDoe.jpg"
import celebrationImage from "assets/images/celebration.png"

interface CourseCompletionCardProps {
  courseCompleted: boolean
  course_name: string
  certificate_url: string
}

const CourseCompletionCard: React.FC<CourseCompletionCardProps> = ({
  courseCompleted,
  certificate_url,
  course_name,
}) => {
  const openPDFInNewWindow = () => {
    const pdfUrl =
      "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/Bosscoder%20Placement%20Policy.pdf?alt=media&token=7d2a1aac-c915-4035-b473-1b1ff7b91cdf"
    window.open(pdfUrl, "_blank")
  }

  return (
    <div className="relative">
      <div className={`relative w-full overflow-hidden rounded-lg border-[0.5px] bg-[#333] px-6 py-4 color-[#333]`}>
        <div className="flex items-center justify-between">
          <div className="max-w-[600px] flex-col gap-[5px]">
            <div className="text-white font-inter text-[16px] font-semibold flex">Placement Policy</div>
            <div className="text-white flex font-inter mt-[5px] text-[12px] font-semibold">
              The Placement Policy outlines the guidelines and procedures for Bosscoder learners regarding job
              placements, career support and the correct code of conduct.
            </div>
          </div>
          <div>
            <Button
              onClick={openPDFInNewWindow}
              className="px-[16px] py-[9px] text-white rounded-md border border-white bg-[#333] hover:bg-white hover:text-[#333]"
            >
              Know More
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCompletionCard
