import { CancelScheduledClass, ScheduledClass, UpdateClass, UpdateClassAndTiming } from "domain/model/ScheduledClass"
import { ScheduledClassRepository } from "domain/repository/Admin/ScheduledClassRepository"
import { ScheduledClassDataSource } from "data/dataSource/Admin/ScheduledClassDataSource"

export class ScheduleClassRepositoryImpl implements ScheduledClassRepository {
  private dataSource: ScheduledClassDataSource

  constructor(dataSource: ScheduledClassDataSource) {
    this.dataSource = dataSource
  }

  scheduleClass(scheduleclass: ScheduledClass) {
    return this.dataSource.scheduleClass(scheduleclass)
  }

  cancelScheduledClass(cancelScheduledClass: CancelScheduledClass) {
    return this.dataSource.cancelScheduledClass(cancelScheduledClass)
  }

  updateScheduledClass(updateScheduledClass: UpdateClass) {
    return this.dataSource.updateScheduledClass(updateScheduledClass)
  }
  updatePastClassAndTiming(updateClassAndTiming: UpdateClassAndTiming) {
    return this.dataSource.updatePastClassAndTiming(updateClassAndTiming)
  }
}
