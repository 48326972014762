import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface GetSubCaseStudyQuestionUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export class GetSubCaseStudyQuestion implements GetSubCaseStudyQuestionUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: any) {
    return this.repository.getSubjectiveCaseStudyQuestion(auth, id)
  }
}
