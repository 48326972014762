import React from "react"

export default function Search({ classes }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8757 13.9328L12.0203 11.0782C12.8718 10.016 13.3349 8.69483 13.333 7.3335C13.333 4.0215 10.645 1.3335 7.33301 1.3335C4.02101 1.3335 1.33301 4.0215 1.33301 7.3335C1.33301 10.6455 4.02101 13.3335 7.33301 13.3335C8.69434 13.3354 10.0155 12.8723 11.0777 12.0208L13.9323 14.8762L14.8757 13.9328ZM11.9997 7.3335C12.0016 8.54712 11.5291 9.71342 10.683 10.5835L10.583 10.6835C9.71293 11.5296 8.54663 12.0021 7.33301 12.0002C4.75434 12.0002 2.66634 9.9115 2.66634 7.3335C2.66634 4.75483 4.75434 2.66683 7.33301 2.66683C9.91101 2.66683 11.9997 4.75483 11.9997 7.3335Z"
        fill="#646464"
      />
    </svg>
  )
}
