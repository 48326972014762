import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import BatchAssessmentViewModel from "./BatchAssessmentViewModel"
import BatchAssessmentCard from "./components/BatchAssessmentCard"
import Toast from "core/components/Toast"
import Pagination from "./components/Pagination"
import React from "react"
import SSMFeedbackModal from "./components/FeedbackModal"

const BatchAssessmentView = () => {
  const {
    handleSelectBatchClick,
    selectedBatch,
    fetchAllStudentList,
    studentData,
    toast,
    changeToastVisibility,
    fetchClassDetails,
    handleNavigateClick,
    classData,
    page,
    handlePageChange,
    pageLimit,
    totalDataList,
    loading,
    handleSubmissionClick,
    handleSSMFeedback,
    handleCloseBtnClick,
    feedbackResponse,
    session_timestamp,
    openFeedbackModal,
    selectedQuestion,
    getDate,
    handleSelectedQuestionBtnClick,
    student_email,
    order,
    handleCopyToClipboard,
    fetchSSMAndSubmissionStatus,
    buttonStatus,
  } = BatchAssessmentViewModel()

  React.useEffect(() => {
    fetchClassDetails()
  }, [])

  React.useEffect(() => {
    if (selectedBatch) {
      fetchAllStudentList(selectedBatch, page, pageLimit)
    }
  }, [selectedBatch, page, pageLimit])

  // Generate skeleton items
  const skeletonItems = Array.from({ length: 10 }, (_, index) => index)

  return (
    <DashboardLayout>
      <div className="p-4 space-y-6">
        <div className="flex justify-between">
          <div className="space-y-1">
            <h2>Batch Assignments</h2>
            <p>{selectedBatch} - All Assignment Sheet</p>
          </div>
          <Button outlined failure className="w-[160px] h-[40px] px-4 py-2" onClick={handleNavigateClick}>
            Go Back
          </Button>
        </div>
        <div className="flex space-x-2">
          {classData?.batches?.map((item: any, index: number) => (
            <Button
              key={index}
              outlined
              selected={selectedBatch === item}
              className={`w-fit`}
              onClick={() => {
                handleSelectBatchClick(item)
              }}
            >
              {item}
            </Button>
          ))}
        </div>
        {/* Render BatchAssessmentCard components or skeleton UI based on loading state */}
        {loading
          ? // Skeleton UI
            skeletonItems.map((index) => (
              <div key={index} className="p-6 text-new-neutral-dark shadow animate-pulse">
                <div className="flex">
                  <div className="h-6 w-6 bg-gray-200 rounded-full mr-2"></div>
                  <div className="h-4 bg-gray-200 w-32 rounded-lg"></div>
                </div>
                <div className="h-4 bg-gray-200 w-16 rounded-lg mt-2"></div>
                <div className="flex justify-between mt-4">
                  <div className="h-4 bg-gray-200 w-32 rounded-lg"></div>
                  <div className="flex space-x-4">
                    <div className="h-8 bg-gray-200 w-20 rounded-lg"></div>
                    <div className="h-8 bg-gray-200 w-20 rounded-lg"></div>
                  </div>
                </div>
              </div>
            ))
          : // Render actual BatchAssessmentCard components
            studentData?.length > 0 &&
            studentData.map((data: any, index: any) => (
              <BatchAssessmentCard
                key={index}
                studentData={data}
                batch={selectedBatch}
                classData={classData}
                handleSubmissionClick={handleSubmissionClick}
                handleSSMFeedback={handleSSMFeedback}
                handleCopyToClipboard={handleCopyToClipboard}
                buttonStatus={buttonStatus[index]}
                fetchSSMAndSubmissionStatus={fetchSSMAndSubmissionStatus}
                index={index}
              />
            ))}
      </div>
      {studentData?.length > 0 && !loading && (
        <div className="p-4 sticky top-0 bg-white z-10">
          <Pagination
            currentPage={page}
            totalPages={Math.ceil(totalDataList / pageLimit)}
            onPageChange={handlePageChange}
          />
        </div>
      )}

      {openFeedbackModal && (
        <SSMFeedbackModal
          feedback_response={feedbackResponse}
          onClose={handleCloseBtnClick}
          selectedQuestion={selectedQuestion}
          handleSelectedQuestionBtnClick={handleSelectedQuestionBtnClick}
          getDate={getDate}
          session_timestamp={session_timestamp}
          handleSubmissionClick={handleSubmissionClick}
          student_email={student_email}
          order={order}
        />
      )}

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}

export default BatchAssessmentView
