import Avatar from "react-avatar"
import { cn } from "core/lib/utils"
import { companyLogos } from "../utils/Company"

export default function PlacementCard({ name, company_name, bg, student_pic }: any) {
  return (
    <div className="flex h-[270px] w-[241px] shrink-0 flex-col items-center gap-2 rounded-[4px] border border-new-neutral-light text-new-accent shadow-[0px_4px_8px_rgba(134,134,134,0.1)] mr-[16px] my-6">
      <div className={cn(" rounded relative h-[66px] w-full", bg)}>
        <div className="absolute -bottom-[calc(50%+4px)] left-1/2 w-fit -translate-x-1/2 rounded-full border border-new-accent-light bg-new-solid-white p-1">
          <Avatar src={student_pic} name={name} round size="70" />
        </div>
      </div>
      <div className="mt-[46px] min-h-[44px] w-full px-6 text-center">
        <h3 className="text-center">{name}</h3>
      </div>
      {/* <div className="flex items-center justify-center px-6 py-4 text-center">
        <h4>{company_name}</h4>
      </div> */}
      <img className="w-[193px] h-[73px] object-contain" src={companyLogos[company_name]} />
      {/* <Avatar className="w-[193px] h-[73px]" src={} size={"193"} /> */}
    </div>
  )
}
