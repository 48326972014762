import ButtonGroup from "core/components/ButtonGroup"
import Button from "core/components/new/Button"
import DashboardLayout from "core/layouts/DashboardLayout"
import useMcqEditViewModel from "./McqEditViewModel"
import { AddIcon, CheckboxEmptyIcon, CrossIcon } from "core/constants/svgs"
import { STR_ADD, STR_OPTIONS, STR_QUESTION } from "core/constants/strings"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { cn } from "core/lib/utils"
import React from "react"
import Toast from "core/components/Toast"
import { isEmpty } from "core/utils/misc"
import Fields from "../QuestionChamber/Components/Fields"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"

export default function McqEditView() {
  const {
    id,
    activeMcqTab,
    tag,
    tags,
    difficulty,
    name,
    question,
    activeOptionTab,
    options,
    TABS,
    solution,
    allFieldsValid,
    pageLoading,
    loading,
    toast,
    changeToastVisibility,
    handleOptionTabChange,
    handleMcqTabChange,
    redirectToMcqs,
    handleAddTag,
    handleTagChange,
    handleRemoveTag,
    handleDifficultyChange,
    handleNameChange,
    handleRemoveQuestionContent,
    handleAddQuestionContent,
    addAndRemoveoption,
    handleQuestionTypeChange,
    handleQuestionContentChange,
    handleAddOptionContent,
    handleRemoveOptionContent,
    handleOptionTypeChange,
    handleOptionContentChange,
    handleSolutionChange,
    checkIfAllFieldsAreFilled,
    handleSubmitQuestion,
    fetchMcqQuestionDetails,
    handleUpdateMcqQuestion,
    handleImageChange,
    fetchAllCourses,
    allCourses,
    allModules,
    allTopics,
    course,
    module,
    topic,
    handleCourseChange,
    handleModuleChange,
    handleTopicChange,
    handleSolutionFieldChange,
    solutionFields,
    handleSolutionFields,
  } = useMcqEditViewModel()

  React.useEffect(() => {
    checkIfAllFieldsAreFilled()
  }, [name, course, topic, module, difficulty, question, options, solution, solutionFields])

  React.useEffect(() => {
    if (id !== STR_ADD) {
      fetchMcqQuestionDetails()
    }
    fetchAllCourses()
  }, [])

  return (
    <DashboardLayout>
      <ConditionalLoader isLoading={pageLoading} loader={<Loader />}>
        <div className="flex h-full text-new-neutral-dark">
          <div className="flex h-full w-3/5 flex-col gap-6 overflow-y-auto p-6">
            <div className="flex justify-between gap-6">
              <h3 className="text-new-accent">Multiple Choice Questions ({id === STR_ADD ? "Add" : "Update"})</h3>
              <Button className="h-fit w-fit" failure outlined onClick={redirectToMcqs}>
                Go Back
              </Button>
            </div>
            <div className="flex flex-col gap-4">
              <Input
                containerClass="w-full max-w-[400px]"
                type="text"
                placeholder="Title of the question"
                value={name}
                onChange={handleNameChange}
              />
              <StandaredSelect
                className="w-full max-w-[400px]"
                placeholder="Course"
                value={course}
                onChange={handleCourseChange}
                options={allCourses}
              />
              <StandaredSelect
                className="w-full max-w-[400px]"
                placeholder="Module"
                value={module}
                onChange={handleModuleChange}
                options={allModules}
              />
              <StandaredSelect
                className="w-full max-w-[400px]"
                placeholder="Topic"
                value={topic}
                onChange={handleTopicChange}
                options={allTopics}
              />
              <form className="flex flex-col gap-4" onSubmit={handleAddTag}>
                <Input
                  containerClass="w-full max-w-[400px]"
                  type="text"
                  placeholder="Add tag"
                  value={tag}
                  onChange={handleTagChange}
                />
                {tags.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {tags.map((tag: any) => (
                      <div
                        key={tag?.id}
                        className="flex w-fit items-center gap-2 rounded-sm border border-new-neutral-light py-1 px-2 text-new-neutral-dark"
                      >
                        <p>{tag?.tag}</p>
                        <button
                          type="button"
                          className="inline-flex h-4 w-4 items-center justify-center"
                          onClick={() => handleRemoveTag(tag?.id)}
                        >
                          <CrossIcon className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-new-neutral-light">No tags added yet!</p>
                )}
              </form>
              <StandaredSelect
                className="w-full max-w-[400px]"
                placeholder="Select Difficulty"
                value={difficulty}
                onChange={handleDifficultyChange}
                options={["Easy", "Medium", "Hard"]}
              />
            </div>
            <ButtonGroup buttons={TABS} active={activeMcqTab} onChange={handleMcqTabChange} />
            {TABS[activeMcqTab] === STR_QUESTION ? (
              <>
                <button
                  className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
                  onClick={handleAddQuestionContent}
                >
                  <AddIcon className="h-4 w-4" />
                  <p>Add Fields</p>
                </button>
                <div
                  className={cn(
                    "flex w-full flex-1 justify-center",
                    question?.length > 0 ? "items-start" : "items-center"
                  )}
                >
                  {question?.length > 0 ? (
                    <div className="flex w-full flex-col gap-4">
                      {question?.map((field: any) => (
                        <div
                          key={field?.id}
                          className="flex w-full items-start gap-2 rounded-sm border border-new-neutral-light p-2"
                        >
                          <StandaredSelect
                            type="text"
                            className="w-[120px]"
                            placeholder="Type"
                            value={field?.type}
                            onChange={(e: any) => handleQuestionTypeChange(e, field?.id)}
                            options={["text", "image", "code"]}
                          />
                          {field.type === "image" ? (
                            <input
                              type="file"
                              name="img"
                              accept="image/*"
                              className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                              onChange={(e: any) => {
                                handleImageChange(e, field.id, true)
                              }}
                            />
                          ) : (
                            <textarea
                              className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                              placeholder="Content"
                              value={field?.content}
                              onChange={(e: any) => handleQuestionContentChange(e, field?.id)}
                            />
                          )}
                          <button className="p-2" onClick={() => handleRemoveQuestionContent(field?.id)}>
                            <CrossIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4 className="text-new-neutral-light">Add data type fields to proceed further!</h4>
                  )}
                </div>
              </>
            ) : TABS[activeMcqTab] === STR_OPTIONS ? (
              <>
                <div className="flex gap-4">
                  {Object.keys(options).map((key: any, i: number) => (
                    <button
                      key={i}
                      className={cn(
                        "px-4 py-2 text-sm font-medium leading-[20px]",
                        activeOptionTab === i + 1
                          ? "bg-new-neutral text-new-solid-white"
                          : "bg-new-neutral-light text-new-neutral-dark"
                      )}
                      onClick={() => handleOptionTabChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    className="px-4 py-2 text-sm font-medium leading-[20px] bg-new-neutral-light text-new-solid-white"
                    onClick={() => {
                      addAndRemoveoption("add", Object.keys(options)?.length + 1)
                    }}
                  >
                    <AddIcon className="h-4 w-4" />
                  </button>
                </div>
                <div className="text-right">
                  <button
                    className="w-fit px-4 py-2 border border-new-failure text-failure bg-white hover:bg-new-failure hover:text-white float-right"
                    onClick={() => {
                      addAndRemoveoption("remove", activeOptionTab)
                    }}
                  >
                    Delete option
                  </button>
                </div>
                <button
                  className="inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
                  onClick={() => handleAddOptionContent()}
                >
                  <AddIcon className="h-4 w-4" />
                  <p>Add Fields</p>
                </button>
                <div
                  className={cn(
                    "flex w-full flex-1 justify-center",
                    options[String(activeMcqTab)]?.length > 0 ? "items-start" : "items-center"
                  )}
                >
                  {options[String(activeOptionTab)]?.length > 0 ? (
                    <div className="flex w-full flex-col gap-4">
                      {options[String(activeOptionTab)]?.map((field: any) => (
                        <div
                          key={field?.id}
                          className="flex w-full items-start gap-2 rounded-sm border border-new-neutral-light p-2"
                        >
                          <StandaredSelect
                            type="text"
                            className="w-[120px]"
                            placeholder="Type"
                            value={field?.type}
                            onChange={(e: any) => handleOptionTypeChange(e, field?.id)}
                            options={["text", "image", "code"]}
                          />
                          {field.type === "image" ? (
                            <input
                              type="file"
                              name="img"
                              accept="image/*"
                              className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                              onChange={(e: any) => {
                                handleImageChange(e, field.id, false)
                              }}
                            />
                          ) : (
                            <textarea
                              className="w-full focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                              placeholder="Content"
                              value={field?.content}
                              onChange={(e: any) => handleOptionContentChange(e, field?.id)}
                            />
                          )}
                          <button className="p-2" onClick={() => handleRemoveOptionContent(field?.id)}>
                            <CrossIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h4 className="text-new-neutral-light">Add data type fields to proceed further!</h4>
                  )}
                </div>
              </>
            ) : (
              <div>
                <div className="py-5">
                  <StandaredSelect
                    className="w-full max-w-[400px]"
                    placeholder="Select Solution"
                    value={solution}
                    onChange={handleSolutionChange}
                    options={Object.keys(options).map((key) => key)}
                  />
                </div>
                <Fields
                  fields={solutionFields}
                  fieldTypes={["description", "image", "document", "code"]}
                  onFieldChange={handleSolutionFieldChange}
                  onDeleteField={handleSolutionFields}
                />
                <button
                  className="mt-6 inline-flex w-full items-center justify-center gap-4 rounded-sm border border-new-neutral py-3 text-new-neutral"
                  onClick={() => handleSolutionFields("Add", -1)}
                >
                  <AddIcon className="h-4 w-4" />
                  <p>Add Solution Fields</p>
                </button>
              </div>
            )}
            <div className="flex items-center gap-4">
              <Button outlined className="ml-auto h-fit w-fit" failure onClick={redirectToMcqs}>
                Discard
              </Button>
              <Button
                outlined
                disabled={!allFieldsValid}
                className="h-fit w-fit"
                onClick={id === STR_ADD ? handleSubmitQuestion : handleUpdateMcqQuestion}
                loading={loading}
              >
                {id === STR_ADD ? "Add" : "Update"} Question
              </Button>
            </div>
          </div>
          <div className="h-full w-2/5 overflow-y-auto bg-new-editor-dark-700 p-6">
            <div className="w-full space-y-4 rounded-sm border border-new-neutral-light bg-new-editor-dark-500 px-6 py-4 text-new-editor-dark-50">
              <div className="flex gap-2 text-new-solid-white">
                <h3 className="h-6 w-6 shrink-0">1.</h3>
                <div className="flex w-full max-w-[calc(100%-32px)] flex-col gap-4">
                  {question?.length > 0 &&
                    question?.map((content: any) =>
                      content?.type === "text" ? (
                        <h3 key={content?.id}>{content?.content}</h3>
                      ) : content?.type === "image" ? (
                        content.content && (
                          <img key={content?.id} src={content?.content ?? content} alt="question banner" />
                        )
                      ) : (
                        <div
                          key={content?.id}
                          className="w-full overflow-x-auto whitespace-pre border bg-new-editor-dark-700 p-2 font-mono"
                        >
                          <p className="">{content?.content}</p>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                {!isEmpty(options) &&
                  Object.keys(options)?.length > 0 &&
                  Object.keys(options)?.map((optionKey, i) => (
                    <div key={options[optionKey]?.id} className="flex w-full items-center gap-2">
                      <span className="h-6 w-6 shrink-0 self-start">{i + 1}</span>
                      <div className="flex w-full max-w-[calc(100%-64px)] flex-col gap-2">
                        {options[optionKey]?.map((option: any) =>
                          option?.type === "text" ? (
                            <div
                              key={option?.id}
                              className="whitespace-pre-line break-words text-sm font-medium"
                              dangerouslySetInnerHTML={{ __html: option?.content }}
                            />
                          ) : option?.type === "image" ? (
                            option?.content && (
                              <img
                                key={option?.id}
                                src={option?.content}
                                alt="question banner"
                                className="w-fit max-w-full"
                              />
                            )
                          ) : (
                            <div className="w-full overflow-x-auto whitespace-pre border bg-new-editor-dark-700 p-2 font-mono">
                              <p>{option?.content}</p>
                            </div>
                          )
                        )}
                      </div>
                      <button className="h-6 w-6 shrink-0 hover:text-new-solid-white">
                        <CheckboxEmptyIcon className="h-6 w-6" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ConditionalLoader>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
