import { Auth } from "domain/model/Auth"
import SubmissionRepository from "domain/repository/Student/SubmissionRepository"

export interface submitAssignmentQuestionInterface {
  invoke(auth: Auth, data: any): Promise<any>
}

export default class SubmitAssignmentQuestion implements submitAssignmentQuestionInterface {
  private repository: SubmissionRepository

  constructor(repository: SubmissionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return this.repository.submitAssignmentQuestion(auth, data)
  }
}
