import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface GetAllSessionOfMonthYearUseCase {
  invoke(auth: Auth, instructor: string, year: string, month: string): Promise<any>
}

export default class GetAllSessionOfMonthYear implements GetAllSessionOfMonthYearUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor: string, year: string, month: string) {
    return await this.repository.getAllSessionOfMonthYear(auth, instructor, year, month)
  }
}
