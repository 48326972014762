import React, { useState } from "react"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import { useNavigate, useParams } from "react-router-dom"
import { STR_FAILURE } from "core/constants/strings"
import { GetDailyReportData } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetDailyReportData"
import { DailyReportRepositoryImpl } from "data/repository/Admin/DailyReportRepositoryImpl"
import { DailyReportAPIDataSourceImpl } from "data/API/Admin/DailyReportDataSourceImpl"

export default function ProgressRoadmapViewModel() {
  const [student, setStudent] = useState<any>({})
  const [joiningDate, setJoiningDate] = useState("")
  const navigate = useNavigate()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth, refreshed } = useAuth()
  const { id }: any = useParams()
  const [todayDate, setTodayDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10).replace(/-/g, "")
  )
  const [dataLoading, setDataLoading] = useState(false)
  const [daily, setDaily] = useState<any>([])
  const [ind, setInd] = useState(0)

  const handleDecInd = () => {
    if (ind === 0) return
    else setInd((prev: number) => prev - 1)
  }
  const handleIncInd = async () => {
    if (ind == daily.length - 2) {
      setInd((prev: number) => prev + 1)
      setDataLoading(true)
      try {
        const response = await getDailyReportDataUseCase.invoke({
          id_token: auth.id_token,
          email: id,
          date: getPreviousDate(todayDate),
        })
        setDaily((prevItems: any) => [...prevItems, ...response?.data])
        setTodayDate(getPreviousDate(todayDate))
        // setInd((prev: number) => prev + 1);
      } catch (error) {
        setInd((prev: number) => prev - 1)
        console.error(error)
      } finally {
        setDataLoading(false)
      }
    } else setInd((prev: number) => prev + 1)
  }
  function formatDateString(dateString: string): string {
    const year = parseInt(dateString.slice(0, 4), 10)
    const month = parseInt(dateString.slice(4, 6), 10)
    const day = parseInt(dateString.slice(6, 8), 10)
    const date = new Date(year, month - 1, day) // Month is 0-indexed in Date constructor
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      month: "short",
      day: "2-digit",
    }
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date)
    let suffix = "th"
    if (day !== 11 && day !== 12 && day !== 13) {
      const lastDigit = day % 10
      if (lastDigit === 1) suffix = "st"
      else if (lastDigit === 2) suffix = "nd"
      else if (lastDigit === 3) suffix = "rd"
    }
    const dd: string = formattedDate.slice(-2)
    const mm: string = formattedDate.slice(-6, -3)
    const wday: string = formattedDate.slice(0, -8)
    return `${dd}${suffix} ${mm} ${year.toString()}, ${wday}`
  }
  function getPreviousDate(dateString: string) {
    const dateObject = new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`)
    dateObject.setDate(dateObject.getDate() - 7)
    return dateObject.toISOString().slice(0, 10).replace(/-/g, "")
  }

  const getDayOfWeek = (dateString: string): string => {
    const [year, month, day] = [
      parseInt(dateString.slice(0, 4)),
      parseInt(dateString.slice(4, 6)) - 1,
      parseInt(dateString.slice(6, 8)),
    ]
    return new Date(year, month, day).toLocaleString("en-US", {
      weekday: "short",
    })
  }

  const getUserDataUseCase = new GetAllStudentData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const getDailyReportDataUseCase = new GetDailyReportData(
    new DailyReportRepositoryImpl(new DailyReportAPIDataSourceImpl())
  )
  const getUserData = async (email: string) => {
    const response = await getUserDataUseCase.invoke({
      admin_uid: auth.local_id,
      email: email,
    })

    if (!response) {
      changeToastDetails(STR_FAILURE, "Error while fetching user data")
      changeToastVisibility(true)
      return
    }

    const userData = response?.response
    setJoiningDate(userData.student.joining_date.slice(0, 8))
    setStudent(userData?.student)
  }
  const getDailyReportData = async (date: string) => {
    if (todayDate <= joiningDate) return
    setDataLoading(true)
    try {
      const response = await getDailyReportDataUseCase.invoke({
        id_token: auth.id_token,
        email: id,
        date: getPreviousDate(todayDate),
      })
      setDaily((prevItems: any) => [...prevItems, ...response?.data])
      setTodayDate(getPreviousDate(todayDate))
    } catch (error) {
      console.error(error)
    } finally {
      setDataLoading(false)
    }
  }
  return {
    getUserData,
    refreshed,
    id,
    student,
    navigate,
    joiningDate,
    formatDateString,
    getDayOfWeek,
    getPreviousDate,
    daily,
    dataLoading,
    getDailyReportData,
    todayDate,
    ind,
    setInd,
    handleIncInd,
    handleDecInd,
  }
}
