import { format } from "date-fns"
import Avatar from "react-avatar"
import { Link } from "react-router-dom"
type Props = {
  email: string
  name: string
  imageURL: string
  phone: string
  batch: string
  batchPaused: Boolean
  batchPausedTimeStamp: number
}
export default function StudentCard({ email, name, imageURL, phone, batch, batchPaused, batchPausedTimeStamp }: Props) {
  return (
    <>
      <div>
        <div className="font-montserrat flex gap-4 rounded-sm bg-new-solid-white px-6 py-4  shadow-[0px_3px_7px_#C7DFEC]">
          <Avatar className="shrink-0 rounded-sm object-cover" src={imageURL} name={name} size="67" />
          <div className="flex w-full flex-col justify-between">
            <div className="flex w-full items-start justify-between">
              <div className="space-y-1 text-[18px] font-semibold text-[#414141]">
                <h3>{name}</h3>
                <p className="text-[10px] font-medium leading-3 text-[#414141]">{"Student"}</p>
              </div>
              <div className="h-[22px] text-[18px] font-semibold text-[#00C22B]">Batch : {batch}</div>
            </div>
            <div className="flex items-end justify-between text-[14px] leading-6 text-[#414141]">
              {email} | {phone}
              <Link to={`/admin/students/${email}/progress-roadmap`}>
                <button
                  disabled={email === "email"}
                  className="flex disabled:opacity-50 disabled:hover:bg-white disabled:hover:text-[#312E81] h-[33px] w-[210px] items-center rounded-sm border-[0.5px] border-[#312E81] px-4 py-2 text-[14px] font-medium text-[#312E81]  hover:bg-[#312E81] hover:text-white"
                >
                  Student Progress Report
                </button>
              </Link>
            </div>
          </div>
        </div>
        {batchPaused && (
          <p className=" text-[16px] font-semibold  mt-2 text-red-500">
            The course has been on pause since {format(new Date(batchPausedTimeStamp * 1000), "do MMMM")}.
          </p>
        )}
      </div>
    </>
  )
}
