import Avatar from "react-avatar"
import { Tooltip } from "@mui/material"
import Button from "core/components/new/Button"
import { minsDiff } from "core/utils/date"
import { isEmpty } from "core/utils/misc"
import { format } from "date-fns"
import React from "react"
import useLiveClassesViewModel from "../LiveClassViewModel"

export default function SessionCard({ isRecorded, session }: any) {
  const [linkDisable, setLinkDisable] = React.useState<boolean>(false)
  const { classReminderScheduling } = useLiveClassesViewModel()

  const handleOnclick = async () => {
    window.open(session?.class_link)
    window.open(session?.class_link)
    // classReminderScheduling(session?.id)
  }

  React.useEffect(() => {
    const isLinkActive = () => {
      const timestamp = parseInt(session?.session_timestamp) * 1000
      const diff = minsDiff(Date.now(), timestamp)
      return diff <= 30 && diff > -120
    }

    const checkLinkStatus = () => {
      setLinkDisable(!isLinkActive())
    }

    checkLinkStatus()

    const interval = setInterval(checkLinkStatus, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex gap-4 rounded-sm p-6 shadow">
      <div className="grid aspect-square h-[120px] place-items-center">
        <Avatar size="100%" className="object-cover" src={session?.instructor} name={session?.chapter_name} />
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-new-accent">{session?.chapter_name}</h3>
        <p>Batches: {session?.batches?.join(", ")}</p>
        <p>Course Applied: {session?.track}</p>
        <h3 className="mt-4 text-new-failure">
          {format(new Date(session?.session_timestamp * 1000), "dd MMMM yyyy | h:mm a")}
        </h3>
      </div>
      {!isRecorded && (
        <div className="ml-auto self-end">
          <Tooltip title={linkDisable ? "Link will be active half an hour before the class timing." : ""}>
            <span>
              <Button small outlined disabled={linkDisable} onClick={handleOnclick} className="!w-32">
                Join meet
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
