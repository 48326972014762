import McqRepository from "domain/repository/Admin/McqRepository"
import { Auth } from "domain/model/Auth"

export interface CreateMcqQuestionUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class CreateMcqQuestion implements CreateMcqQuestionUseCase {
  private repository: McqRepository

  constructor(repository: McqRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.create_mcq_question(auth, data)
  }
}
