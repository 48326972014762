import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface GetQuestionWithAnswerUseCase {
  invoke(auth: Auth, topic: string, is_contest?: boolean): Promise<any>
}

export class GetQuestionWithAnswer implements GetQuestionWithAnswerUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, topic: string, is_contest?: boolean) {
    return this.repository.getQuestionWithAnswer(auth, topic, is_contest)
  }
}
