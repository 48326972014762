import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface GetMentorSessionOfMonthYearUseCase {
  invoke(auth: Auth, mentor_id: string, batch: string, year: string, month: string): Promise<any>
}

export default class GetMentorSessionOfMonthYear implements GetMentorSessionOfMonthYearUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, batch: string, year: string, month: string) {
    return await this.repository.getMentorSessionOfMonthYear(auth, mentor_id, batch, year, month)
  }
}
