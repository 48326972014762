import React from "react"
import { cn } from "core/lib/utils"

export default function Input({
  id,
  className,
  type,
  value,
  checked,
  placeholder,
  name,
  max,
  min,
  required,
  readOnly,
  onChange,
  onBlur,
  onFocus,
  border,
  disabled,
}: InputProps) {
  return (
    <input
      // style={border ? { borderColor: "#6B6B6B" } : {}}

      className={cn(
        "para w-full rounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-500 outline-none",
        className,
        border ? "border-[#6B6B6B]" : ""
      )}
      type={type ?? "text"}
      id={id}
      value={value}
      onChange={onChange}
      checked={checked}
      placeholder={placeholder}
      name={name}
      max={max}
      min={min}
      onBlur={onBlur}
      onFocus={onFocus}
      autoComplete="false"
      required={required}
      readOnly={readOnly}
      disabled={disabled}
    />
  )
}

export type InputProps = {
  id?: string
  required?: any
  className?: string
  type?: string
  value?: any
  checked?: boolean
  placeholder?: string
  border?: boolean
  name?: string
  max?: string
  min?: string
  options?: string[]
  readOnly?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
