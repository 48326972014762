import Button from "core/components/Button"
import { isEmpty } from "core/utils/misc"
import { format } from "date-fns"
import React from "react"
import GenerateInvoiceViewModel from "../GenerateInvoiceViewModel"
import { useNavigate } from "react-router-dom"
import { loader } from "@monaco-editor/react"
import Loader from "core/components/Loader"
import Spinner from "core/components/Spinner"
import Avatar from "react-avatar"

export default function InstructorSheet({ data }: any) {
  const { auth, instructorAverageRating, fetchInstructorAverageRating } = GenerateInvoiceViewModel()
  const navigate = useNavigate()
  React.useEffect(() => {
    fetchInstructorAverageRating(auth, data.email)
  }, [auth, data.email])

  const viewInstructorDetails = (id: string) => {
    navigate(`/admin/generate-invoice/${id}`)
  }
  return (
    <div className="">
      <div className="mx-2rem my-3rem flex justify-between px-6 py-4 text-new-neutral-dark shadow">
        <div className="flex items-center gap-x-4">
          <div className="">
            <Avatar name={data.photo} src={data.photo} round size="60" />
          </div>
          <div className="flex items-end gap-x-6">
            <div className="space-y-1">
              <h4 className="mb-1">{data.name}</h4>
              <p className="">
                {data.current_company} | {data.experience} years
              </p>

              <p className="">
                {data.email} | +91 {data.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-x-12">
          <div className="">
            <p className="">{data.status}</p>
          </div>
          <div className="flex flex-col gap-y-5">
            {!isEmpty(instructorAverageRating) ? (
              instructorAverageRating.average_rating != 0 ? (
                <div className="">
                  {" "}
                  <h3 className="text-new-success">{instructorAverageRating.average_rating}/5</h3>
                  <p className="">Rating</p>
                </div>
              ) : (
                <div className="">Not Rated</div>
              )
            ) : (
              <Spinner small />
            )}
            <Button outlined className="w-fit" onClick={() => viewInstructorDetails(data.email)}>
              View Details
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
