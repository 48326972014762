import { CrossIcon } from "core/constants/svgs"
import CurrentMentorCard from "./CurrentMentorCard"
import { cn } from "core/lib/utils"
import LinkedInIcon from "assets/svgs/LinkedInIcon"
import StarRating from "core/components/StarRating"
import Avatar from "react-avatar"

export default function SelectMentorModal(props: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setOpenMentorToSelect(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="flex h-screen py-4">
          <div className="relative m-auto h-[500px] w-[800px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            {/* content goes here */}
            <div className="relative flex">
              <div className="flex gap-x-1 text-lg font-semibold">
                <div className="">Select Mentor</div>
                <div className="text-red-600">*</div>
              </div>
              <button onClick={() => props.setOpenMentorToSelect(false)} className="absolute right-0 mt-[3px]">
                <CrossIcon className="h-6 w-6 text-[#312E81]" />
              </button>
            </div>

            <div className="mt-4 flex h-[425px] flex-col gap-y-4 overflow-y-auto rounded-sm pb-[50px]">
              {props.mentors.map((mentor: any, i: number) => (
                <CurrentMentorCard
                  key={i}
                  details={mentor}
                  state="Select"
                  setSelectedMentor={props.setSelectedMentor}
                  setOpenMentorToSelect={props.setOpenMentorToSelect}
                />
              ))}
            </div>
            {Object.keys(props.selectedMentor).length === 0 ? (
              <div className="absolute left-0 bottom-0 z-10 flex h-[48px] w-full items-center justify-center bg-[#F5F5F5] ">
                <div className="text-[10px] font-medium">Mentor not yet selected!</div>
              </div>
            ) : (
              <div className="absolute left-0 bottom-0 z-10 h-[48px] w-full bg-[#F5F5F5]">
                <div className="relative my-3 ml-6 flex gap-4">
                  <Avatar
                    className="shrink-0 rounded-sm object-cover"
                    src={props.selectedMentor["photo"]}
                    name={props.selectedMentor["name"]}
                    size="27"
                  />
                  <div className="mt-[3px] items-center justify-center text-sm font-semibold">
                    {props.selectedMentor["name"]}
                  </div>
                  <StarRating rating={Math.round(props.selectedMentor["rating"] * 10) / 10} total={5} />
                  <div className="mt-[6px] flex gap-x-1 text-[10px] font-medium text-new-neutral-dark">
                    <span>{props.selectedMentor["current_company"]}</span>
                    <span>|</span>
                    <span>{props.selectedMentor["experience"]}yrs</span>
                  </div>
                  <div className="absolute right-6 text-sm font-semibold text-[#DC2626]">Selected Mentor</div>
                </div>
              </div>
            )}
            {/* ends here */}
          </div>
        </div>
      </div>
    </div>
  )
}
