import McqRepository from "domain/repository/Admin/McqRepository"
import { Auth } from "domain/model/Auth"

export interface DeleteMcqQuestionUseCase {
  invoke(auth: Auth, id: string): Promise<any>
}

export class DeleteMcqQuestion implements DeleteMcqQuestionUseCase {
  private repository: McqRepository

  constructor(repository: McqRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string): Promise<any> {
    return await this.repository.delete_mcq_question(auth, id)
  }
}
