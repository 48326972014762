import StandaredSelect from "core/components/StandaredSelect"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"
import StoreViewModel from "../StoreViewModel"
import { useAuth } from "core/context/auth"
import { format } from "date-fns"
import EditCouponViewModel from "./EditCouponViewModel"

export default function EditCoupon({ data, updateCouponLoader, UpdateCoupon }: any) {
  const {
    couponId,
    couponType,
    amount,
    purchaseDate,
    expiryDate,
    referenceId,
    setCouponId,
    setAmount,
    setCouponType,
    setExpiryDate,
    setReferenceId,
    setPurchaseDate,
    handleReferenceId,
    handleChangeCouponId,
    handleCouponType,
    handleAmount,
    handleChangePurchaseDate,
    handleChangeExpiryDate,
  } = EditCouponViewModel()

  const { auth } = useAuth()

  React.useEffect(() => {
    setReferenceId(data.reference_id)
    setAmount(data.amount)
    setCouponId(data.coupon_id)
    setPurchaseDate(format(parseInt(data.date_of_purchase) * 1000, "yyyy-MM-dd"))
    setExpiryDate(format(parseInt(data.date_of_expiry) * 1000, "yyyy-MM-dd"))
  }, [auth, data])

  const COUPONARRAY = ["Amazon Coupon", "Mentor Session"]
  const AMOUNTARRAY = ["Amount", 50, 75, 100, 200, 500]

  return (
    <div className="space-y-4">
      <p className="text-xl font-medium">Edit Coupon</p>
      <Input type={STR_TEXT_FIELD} value={referenceId} onChange={handleReferenceId} placeholder="Reference ID" />
      <Input type={STR_TEXT_FIELD} value={couponId} onChange={handleChangeCouponId} placeholder="Coupon Code" />
      <StandaredSelect required options={AMOUNTARRAY} value={amount} onChange={handleAmount} placeholder="Amount" />
      <div className="flex items-center justify-between gap-x-2">
        <h4 className="">Purchase Date -- </h4>
        <Input
          className="w-[320px]"
          type="text"
          value={purchaseDate}
          onChange={handleChangePurchaseDate}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          placeholder="Purchase Date"
        />
      </div>
      <div className="flex items-center justify-between gap-x-2">
        <h4 className="">Expiry Date -- </h4>
        <Input
          className="w-[320px]"
          type="text"
          value={expiryDate}
          onChange={handleChangeExpiryDate}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          placeholder="Expiry Date"
        />
      </div>

      <Button
        disabled={amount && purchaseDate && expiryDate && couponId && referenceId ? false : true}
        loading={updateCouponLoader}
        onClick={() => UpdateCoupon(data.id, amount, couponId, couponType, purchaseDate, expiryDate, referenceId)}
      >
        Edit Coupon
      </Button>
    </div>
  )
}
