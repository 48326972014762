import Input from "core/components/Input"
import Button from "core/components/new/Button"
import { STR_DATE_AND_TIME_FIELD, STR_ERROR, STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import UpdateWalkThroughViewModel from "./UpdateWalkThroughViewModel"
import Toast from "core/components/Toast"

export default function UpdateWalkThroughView() {
  const {
    WalkThrough,
    student_id,
    new_walkthrough_date,
    timeValue,
    loading,
    toast,
    changeToastDetails,
    changeToastVisibility,
    setLoading,
    setNewWalkThroughDate,
    fetchWalkThroughDate,
    walkThroughDateChange,
    setStudentId,
    handleStudentChange,
    setWalkThrough,
  } = UpdateWalkThroughViewModel()
  const [date, setDate] = React.useState()

  async function onSubmit() {
    setLoading(true)

    const value = await fetchWalkThroughDate()
    changeToastVisibility(true)
    if (value != "success") changeToastDetails(STR_FAILURE, value)
    else changeToastDetails(STR_SUCCESS, value)
    setLoading(false)
  }

  return (
    <DashboardLayout>
      <div className="">
        <div className="w-full">
          <h1 className="text-2xl font-bold leading-[29px] text-new-accent">Update WalkThrough</h1>
          <p className="font-medium">Update Walk Through for students</p>
        </div>
        <div className="flex justify-center py-12">
          <div className="round-2 flex w-full max-w-xl flex-col  items-center  p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
            <Input className="mb-4" placeholder="Student Email" onChange={handleStudentChange} value={student_id} />

            <Input
              type="date"
              placeholder="Walk Through Date"
              onChange={walkThroughDateChange}
              value={timeValue}
              className=""
            />
            <Button loading={loading} className="mt-4" onClick={onSubmit}>
              Update WalkThrough
            </Button>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
