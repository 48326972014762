import DailyReportDataSource from "data/dataSource/Admin/DailyReportDataSource"
import OverallReportDataSource from "data/dataSource/Admin/OverallReportDataSource"
import GetOverAllReport from "domain/model/GetOverAllReport"
import OverAllReportRepositry from "domain/repository/Admin/OverallReport"

export class OverallReportRepositoryImpl implements OverAllReportRepositry {
  private Datasource: OverallReportDataSource

  constructor(Datasource: OverallReportDataSource) {
    this.Datasource = Datasource
  }

  async getOverallReport(overallReport: GetOverAllReport): Promise<any> {
    return await this.Datasource.getOverallReport(overallReport)
  }
}
