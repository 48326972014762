import Button from "core/components/new/Button"
import React from "react"
import StoreItem from "./components/StoreItem"
import useStoreViewModel from "./StoreViewModel"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function StoreView() {
  const { toast, storeItems, fetchStoreItems, handleRedirectToHistory, changeToastVisibility } = useStoreViewModel()

  React.useEffect(() => {
    fetchStoreItems()
  }, [])

  return (
    <DashboardLayoutv2>
      <div className="p-6">
        <div className="space-y-6 text-new-neutral-dark">
          <div className="flex justify-between gap-8">
            <div className="space-y-1">
              <h2 className="text-new-accent">My Store</h2>
              <p>Now you can purchase exciting goodies with exchange of your hard earned points.</p>
            </div>
            <Button outlined onClick={handleRedirectToHistory} className="h-fit w-fit">
              Order History
            </Button>
          </div>
          {storeItems && storeItems.length > 0 ? (
            <div className="grid grid-cols-3 gap-11">
              {storeItems.map((item: any) => (
                <StoreItem key={item.item_id} item={item} />
              ))}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
