import { cn } from "core/lib/utils"
import React, { useEffect, useState } from "react"
import More from "../assets/More"
import DocumentPopup from "./DocumentPopUp"
import Spinner from "core/components/Spinner"
import Toggle from "core/components/Toggle"

export default function PaymentVerification({
  studentEmail,
  paymentDocuments,
  studentEMI,
  getPaymentDocuments,
  setPaymentDocuments,
  updatePaymentDocument,
  uploadPaymentDocument,
  documentVerified,
}: any) {
  const [loading, setLoading] = React.useState<number>(-1)
  const [viewPopUp, setViewPopUp] = React.useState<boolean>(false)
  const [activeFileIndex, setActiveFileIndex] = React.useState<number>(-1)

  const months = [6, 9, 12, 15, 18]
  const header = ["File Name", "View Document", "File Status", "Received", "", ""]
  // useEffect(() => {}, [studentEMI, paymentDocuments]);
  const approveButtonHandler = async (index: number) => {
    const updatedFiles = [...paymentDocuments]
    updatedFiles[index].is_verified = 1
    updatedFiles[index].is_uploaded = 1
    updatedFiles[index].documentId = index
    setPaymentDocuments(updatedFiles)
    await updatePaymentDocument(studentEmail, updatedFiles[index])
  }

  const rejectButtonHandler = async (index: number) => {
    const updatedFiles = [...paymentDocuments]
    updatedFiles[index].is_uploaded = 0
    updatedFiles[index].is_verified = -1
    updatedFiles[index].documentId = index

    setPaymentDocuments(updatedFiles)
    await updatePaymentDocument(studentEmail, updatedFiles[index])
  }

  const documentReceiveHandler = async (index: number) => {
    const updatedFiles = [...paymentDocuments]
    const FILE = updatedFiles[index]

    FILE.is_received = FILE.is_received === true ? false : true

    if (FILE.is_received === true) {
      FILE.is_submitted = true
      FILE.is_verified = 1
      FILE.is_uploaded = 1
      FILE.link = ""
    }
    if (FILE.is_received === false) {
      updatedFiles[index].is_verified = 0
      updatedFiles[index].is_submitted = -1
    }
    FILE.documentId = index
    setPaymentDocuments(updatedFiles)
    await updatePaymentDocument(studentEmail, FILE)
    await getPaymentDocuments(studentEmail)
  }

  const documentIds = [
    "Aadhar Card",
    "Last 3-Month Salary Bank Statement",
    "Pan Card",
    "Last 3-months Pay Slips",
    "Passport size Photo",
  ]

  const togglePopUp = (index: number) => {
    viewPopUp === true ? setActiveFileIndex(-1) : setActiveFileIndex(index)
    setViewPopUp((prev) => !prev)
  }

  const handleChangeFile = async (e: any, index: number) => {
    setLoading(index)
    const file = e.target.files[0] // Get the file object from the event
    const filename = file.name // Extract filename directly from the file object

    if (paymentDocuments[index]?.filename) {
      try {
        const updatedFiles = [...paymentDocuments]
        const FILE = updatedFiles[index]
        FILE.filename = filename
        FILE.is_uploaded = 1
        FILE.is_received = true
        FILE.documentId = index
        FILE.is_verified = 1
        await updatePaymentDocument(studentEmail, FILE)
      } catch (err) {}
    }

    const filenameWithoutExtension = filename.split(".").slice(0, -1).join(".")
    try {
      await uploadPaymentDocument(studentEmail, file, filenameWithoutExtension, index)
    } catch (err) {}

    // const updatedFiles = [...paymentDocuments];
    // updatedFiles[index] = {
    //   filename: filename,
    //   is_received: true,
    //   is_verified: 0,
    //   documentId: index,
    // };

    // setPaymentDocuments(updatedFiles);

    setLoading(-1)
    if (viewPopUp) {
      setViewPopUp(false)
      setActiveFileIndex(-1)
    }
  }

  const getFileStatus = (is_received: boolean, is_verified: number, is_uploaded: number) => {
    if (is_verified === 1) return { value: "Approved", color: "text-[#00C22B]" }
    if (is_verified === -1) return { value: "Rejected", color: "text-[#DC2626]" }
    if (is_uploaded === 1) return { value: "Uploaded", color: "text-[#00C22B]" }
    if (is_received === true) return { value: "Received", color: "text-[#6B6B6B]" }
    return { value: "Not Uploaded", color: "text-[#DC2626]" }
  }

  return (
    <div className="my-6">
      <div className="text-[#312E81] text-lg font-semibold leading-normal">User NBFC Documents:</div>
      <div className="text-[#737373] text-base">
        Here, you can find all the essential user documents required for the NBFC payment process.
      </div>
      <br />
      <div className="text-[#414141] font-semibold text-base flex justify-between">
        <p>This User has opted for {months[studentEMI?.data?.duration_index]} Month EMI through NBFC.</p>
        <p>
          {" "}
          Loan Approved &nbsp;&nbsp;{" "}
          <Toggle
            checked={studentEMI.data.progress[2].status === 1 ? true : false}
            onChange={() => documentVerified(studentEmail, studentEMI.data.progress[2].status === 1 ? false : true)}
          />{" "}
        </p>
      </div>
      <div className="text-sm mt-6 ">
        <table className="w-full text-center overflow-hidden text-sm text-new-neutral-dark shadow-[0_3px_3px_0_rgba(0,0,0,0.10)] rounded">
          <thead className="bg-new-editor-dark-500 text-new-solid-white font-semibold">
            <tr>
              {header?.map((title: any, i: any) => (
                <th
                  key={i}
                  className={cn(
                    "py-3 text-sm font-semibold leading-[17px]",
                    i === 0 && "text-left pl-6 w-[15%]",
                    i === 1 && "w-[15%]",
                    i === 2 && "text-left",
                    i === 3 && "w-[10%]"
                  )}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paymentDocuments.map((file: any, index: number) => (
              <tr className="bg-new-solid-white hover:bg-[#F5F5F5]" key={index}>
                <td
                  className={cn(
                    "whitespace-nowrap font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark text-left py-3 pl-6"
                  )}
                >
                  {documentIds[index]}
                </td>
                <td className="py-3">
                  {file.link ? (
                    <a href={file.link} target="blank" className="underline font-medium">
                      View Document
                    </a>
                  ) : (
                    <span className="text-gray-400 cursor-not-allowed underline font-medium">View Document</span>
                  )}
                </td>
                <td className="text-left py-3">
                  {
                    <div className={cn(getFileStatus(file.is_received, file.is_verified, file.is_uploaded).color)}>
                      {getFileStatus(file.is_received, file.is_verified, file.is_uploaded).value}
                    </div>
                  }
                </td>
                <td
                  className="flex justify-center py-3 mt-3"
                  onClick={() => documentReceiveHandler(index)}
                  style={{ cursor: "pointer" }}
                >
                  {index === loading ? (
                    <Spinner small />
                  ) : file.is_received ? (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM6.76 11.757L11.003 16L18.074 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757Z"
                        fill="#414141"
                      />
                    </svg>
                  ) : (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4C21 3.44772 20.5523 3 20 3ZM5 19V5H19V19H5Z"
                        fill="#ADADAD"
                      />
                    </svg>
                  )}
                </td>
                <td className="py-5 text-right pr-6">
                  <button
                    className="font-medium w-[120px] py-2 rounded-sm border-[0.5px] text-[#312E81] border-[#312E81] hover:bg-[#312E81] hover:text-white"
                    onClick={() => approveButtonHandler(index)}
                  >
                    Approve
                  </button>
                  <button
                    className="ml-6 font-medium w-[120px] py-2 rounded-sm border-[0.5px] text-[#DC2626] border-[#DC2626]  hover:text-white hover:bg-[#DC2626]"
                    onClick={() => rejectButtonHandler(index)}
                  >
                    Reject
                  </button>
                </td>
                <td className="relative py-3">
                  <div onClick={() => togglePopUp(index)}>
                    <More />
                  </div>

                  {viewPopUp && activeFileIndex === index && (
                    <DocumentPopup
                      documentId={index}
                      isLast={index === paymentDocuments.length - 1}
                      index={activeFileIndex}
                      handleChangeFile={(e: any) => handleChangeFile(e, index)}
                      link={paymentDocuments[activeFileIndex].link}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
