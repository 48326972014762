import React from "react"
import { NavBarIconHolder } from "./NavBarIconHolder"
import { cn } from "core/lib/utils"

export default function SidePanelButton({ name, Icon, onClick, active }: any) {
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <button
      className={cn("relative flex justify-between rounded-l", active ? "bg-v2-gray-700" : "bg-v2-gray-600")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div
        className={cn(
          "w-full flex gap-x-1.5 rounded-l-md p-1",
          active || isHovered ? "font-medium text-white" : "font-normal text-v2-gray-400"
        )}
      >
        <NavBarIconHolder isHovered={isHovered} active={active} Icon={Icon} />
        <h6>{name}</h6>
      </div>
      {active && <div className="h-8 w-1.5 rounded-l-sm bg-white" />}
    </button>
  )
}
