import { isEmpty } from "./misc"

export default function pn(...paths: string[]): string {
  return "/" + paths.filter(Boolean).join("/")
}

export function query(path: string, queryParams: any): string {
  if (isEmpty(queryParams)) return path

  let updatedPath = path
  let isFirstQueryParam = true

  for (const key in queryParams) {
    const prefix = isFirstQueryParam ? "?" : "&"
    const param = `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    updatedPath += `${prefix}${param}`
    isFirstQueryParam = false
  }
  return updatedPath
}
