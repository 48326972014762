import React from "react"

export default function Feedback2(props: any) {
  const { selectedColor, onHover, onLeave, onClick, index, isSelected } = props

  return (
    <svg
      className={props.className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onLeave()}
      onClick={() => onClick(index)}
      fill={isSelected ? selectedColor : "#ADADAD"}
    >
      <rect width="64" height="64" rx="8" fill={isSelected ? selectedColor : "#D3D3D3"} />
      <circle cx="22.7441" cy="24.4043" r="3.40426" fill="white" />
      <circle cx="40.7871" cy="24.4043" r="3.40426" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5795 42.1104C44.2669 42.7943 43.4589 43.0953 42.775 42.7826L38.648 40.896C34.0914 38.813 28.84 38.8884 24.3451 41.1013L20.9638 42.7659C20.2891 43.0981 19.4729 42.8204 19.1407 42.1457C18.8085 41.4709 19.0862 40.6547 19.7609 40.3225L23.1422 38.6579C28.3709 36.0838 34.4798 35.9961 39.7803 38.4192L43.9072 40.3058C44.5912 40.6185 44.8922 41.4264 44.5795 42.1104Z"
        fill="white"
      />
    </svg>
  )
}
