import { Auth } from "domain/model/Auth"

import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface PaymentAdminUseCase {
  invoke(auth: Auth, paymentData: any): Promise<any>
}

export class PaymentAdmin implements PaymentAdminUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, paymentData: any) {
    return await this.repository.createPayment(auth, paymentData)
  }
}
