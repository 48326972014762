import OnboardingManagerNameDataSource from "data/dataSource/Admin/OnboardingManagerNameDataSource"
import { Auth } from "domain/model/Auth"
import { OnboardingManagerName } from "domain/model/OnboardingManagerName"
import { OnboardingManagerNameRepository } from "domain/repository/Admin/OnboardingManagerNameRepository"

export class OnboardingManagerNameRepositoryImpl implements OnboardingManagerNameRepository {
  private Datasource: OnboardingManagerNameDataSource

  constructor(Datasource: OnboardingManagerNameDataSource) {
    this.Datasource = Datasource
  }

  async onboardingManagerName(auth: Auth, onboardingManagerName: OnboardingManagerName): Promise<any> {
    return await this.Datasource.onboardingManagerName(auth, onboardingManagerName)
  }
}
