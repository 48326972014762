import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface GetStudentPaymentDetailsAllUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetStudentPaymentDetailsAll implements GetStudentPaymentDetailsAllUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getStudentPaymentDetailsAll(auth)
  }
}
