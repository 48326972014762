import React from "react"
import { StarIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"

export default function StarRating({ rating, total, className }: any) {
  return (
    <div className="flex items-center gap-2">
      <StarIcon className="h-[14px] w-[14px]" />
      <h4 className={cn("text-new-accent", className)}>
        {rating}/{total}
      </h4>
    </div>
  )
}
