import React from "react"

export default function OngoingDoubtRectangle({ className }: any) {
  return (
    <svg
      width="28"
      height="35"
      // viewBox="0 0 14 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H14V35L0 22V0Z" fill="#FBBF24" />
    </svg>
  )
}
