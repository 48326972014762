import Button from "core/components/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/Input"
import Model from "core/components/Model"
import StandardSelect from "core/components/StandaredSelect"
import Toast from "core/components/Toast"
import { STR_DATE_AND_TIME_FIELD, STR_NUMBER_FILED, STR_TEXT_FIELD } from "core/constants/strings"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import CreateCompany from "./components/CreateCompany"
import useCreateClassViewModel from "./CreateJobViewModel"
export default function CreateJobView() {
  const {
    toast,
    jobType,
    selectContactList,
    othersCriteria,
    status,
    source,
    selectOthersCriteria,
    selectValues,
    companiesList,
    selectCompany,
    isOpen,
    contactList,
    setContactList,
    addContact,
    handleChange,
    onCompanyChange,
    handleCloseModel,
    onChangeJobType,
    onChangeOthersCriteria,
    onChangeStatus,
    removeOthersCriteria,
    handleOthersCriteria,
    createJob,
    createCompany,
    onChangeSource,
    changeToastVisibility,
    onChangeContactList,
    removeItem,
    getCompaniesList,
  } = useCreateClassViewModel()

  const job_type = ["Full Time", "Part Time", "Internship"]

  const source_type = ["internal", "external"]
  const job_status = ["active", "expired"]

  React.useEffect(() => {
    getCompaniesList()
  }, [])

  return (
    <DashboardLayout>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="p-6">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Create Jobs</h1>
          <p className="font-medium">Overall Job related information</p>
        </div>
      </div>

      <Model isOpen={isOpen} onClose={handleCloseModel}>
        <CreateCompany
          selectCompany={selectCompany}
          onCompanyChange={onCompanyChange}
          selectValues={selectValues}
          handleChange={handleChange}
          createCompany={createCompany}
          onChangeContactList={onChangeContactList}
          selectContactList={selectContactList}
          contactList={contactList}
          setContactList={setContactList}
          addContact={addContact}
          removeItem={removeItem}
        />
      </Model>
      <div>
        <div className="flex justify-center py-[50px]">
          <div className="round-2 flex w-2/4 flex-col items-center p-6 shadow-[0px_4px_16px_rgba(32,76,188,0.2)]">
            <form className="mb-4 w-full" id="main-form" onSubmit={createJob}>
              <select
                className="para dark:focus:[] mb-4 w-full cursor-pointer appearance-none rounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-500 outline-none dark:text-white dark:focus:ring-blue-500 "
                onChange={onCompanyChange}
                value={selectCompany}
                style={{ color: "rgb(64, 64, 64,1) " }}
                required
              >
                {!selectCompany && (
                  <option value="" className="text-neutral-500">
                    Select Company
                  </option>
                )}
                {companiesList?.map((header: any, index: any) => (
                  <option className=" bg-neutral-100 text-neutral-800" value={header} key={index}>
                    {header}
                  </option>
                ))}
                <option value="others">others</option>
              </select>

              <Input
                type={STR_DATE_AND_TIME_FIELD}
                value={selectValues.expiredDate}
                onChange={handleChange}
                placeholder="Last Data to Apply"
                className="mb-4"
                required
                name="expiredDate"
              />

              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.role}
                onChange={handleChange}
                placeholder="Role"
                className="mb-4"
                required
                name="role"
              />
              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.CTC}
                onChange={handleChange}
                placeholder="CTC"
                className="mb-4"
                name="CTC"
              />
              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.job_link}
                onChange={handleChange}
                placeholder="Job link"
                className="mb-4"
                name="job_link"
              />
              <Input
                type={STR_TEXT_FIELD}
                value={selectValues.location}
                onChange={handleChange}
                placeholder="Location"
                className="mb-4"
                name="location"
              />
              <StandardSelect
                required
                className="mb-4"
                options={source_type}
                placeholder="Source"
                name="source"
                onChange={onChangeSource}
                value={source}
              />
              <StandardSelect
                required
                className="mb-4"
                options={job_type}
                placeholder="Job Type"
                name="jobType"
                onChange={onChangeJobType}
                value={jobType}
              />
              <ul className="mb-4 flex w-full list-disc flex-col border pl-8">
                {Object.keys(othersCriteria)?.map((key) => (
                  <li key={key}>
                    {key}
                    <IconButton
                      className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
                      onClick={() => removeOthersCriteria(key)}
                      text="X"
                    >
                      X
                    </IconButton>
                  </li>
                ))}
              </ul>
              <form className="flex w-full justify-between gap-4" id="form-2">
                <Input
                  type={STR_TEXT_FIELD}
                  value={selectOthersCriteria}
                  onChange={onChangeOthersCriteria}
                  placeholder="Other Criteria"
                  className="mb-4"
                />

                <Button form="form-2" onClick={handleOthersCriteria} className="ml-auto h-fit w-fit">
                  +
                </Button>
              </form>
              <Input
                type={STR_NUMBER_FILED}
                value={selectValues.problemSolved}
                onChange={handleChange}
                placeholder="Problem Solve"
                className="mb-4"
                required
                name="problemSolved"
              />
              <Input
                type={STR_NUMBER_FILED}
                value={selectValues.experience}
                onChange={handleChange}
                placeholder="Experience"
                className="mb-4"
                required
                name="experience"
              />
              <StandardSelect
                required
                className=""
                options={job_status}
                placeholder="Status"
                onChange={onChangeStatus}
                value={status}
                name="status"
              />
            </form>
            <textarea
              className="para dark:focus:[] mb-4 w-full appearance-none rounded border border-neutral-300 bg-neutral-50 px-4 py-2 text-neutral-700 outline-none dark:text-white dark:focus:ring-blue-500"
              rows={4}
              placeholder="Job Description"
              value={selectValues.jobDescription}
              onChange={handleChange}
              name="jobDescription"
              style={{ color: "rgb(64, 64, 64,1) " }}
            ></textarea>
            <Button form="main-form">Create</Button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}
