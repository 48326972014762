import React, { useState } from "react"
import { EllipseIcon } from "core/constants/svgs"
import { format } from "date-fns"
import Button from "core/components/Button"
import ChevronDown from "assets/svgs/ChevronDown"
import YellowProblemHistoryRectangle from "assets/svgs/YellowProblemHistoryRectangle"
import GreenProblemHistoryRectangle from "assets/svgs/GreenProblemHistoryRectangle"
import { cn } from "core/lib/utils"
import QuestionThumbnail from "assets/images/QuestionThumbnail.png"
import DoubtSupportViewModel from "../DoubtSupportViewModel"
import VideoThumbnail from "assets/svgs/VideoThumbnail"
import BlogThumbnail from "assets/svgs/BlogThumbnail"

export default function ProblemCard(props: any) {
  const { updateDoubtStatus, searchStudentDoubt } = DoubtSupportViewModel()
  const date: any = parseInt(props.history["timestamp"], 10) * 1000

  const [isOpen, setIsOpen] = useState(false)
  // const [instantResolve, setInstantResolve] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div id={props.id} className={cn("custom-shadow relative mb-[18px] flex", isOpen ? "h-[445px]" : "h-[215px]")}>
      {props.history["progress"] === "RESOLVED" ? (
        <GreenProblemHistoryRectangle />
      ) : props.instantResolve ? (
        <GreenProblemHistoryRectangle />
      ) : (
        <YellowProblemHistoryRectangle />
      )}

      <div className="m-6 flex flex-1 flex-col gap-x-4">
        <div className="relative flex">
          <div className="flex flex-col gap-y-[10px]">
            <div className="flex flex-col gap-y-1">
              <h3 className="text-lg font-semibold text-[#312E81]">{props.history["problem_name"]}</h3>
              <div className="flex gap-x-1">
                <h5 className="pt-1 text-[10px] font-medium">Date: </h5>
                <h4 className="text-sm font-semibold">{format(new Date(parseInt(date)), "do MMMMMMMMMMMMM, yyyy")}</h4>
              </div>
            </div>
            <div className="relative flex w-[400px] gap-x-1 text-sm">
              <div className="w-18 relative flex">
                <div className="absolute left-0">
                  <EllipseIcon></EllipseIcon>
                </div>
                <div className="absolute left-3">
                  <EllipseIcon></EllipseIcon>
                </div>
                <div className="absolute left-6">
                  <EllipseIcon></EllipseIcon>
                </div>
                <div className="absolute left-9">
                  <EllipseIcon></EllipseIcon>
                </div>
                <div className="absolute left-12">
                  <EllipseIcon></EllipseIcon>
                </div>
              </div>
              <div className="absolute left-20 top-[3px] flex h-[24px] gap-x-1">
                <div className="font-semibold">{props.history["num_queries_raised"]}+</div>
                <div className="font-normal">People raised the same problem</div>
              </div>
            </div>
          </div>
          <div
            className={cn(
              "absolute top-[50px] right-0 flex gap-x-[10px]",
              props.instantResolve || props.history["progress"] === "RESOLVED" ? "hidden" : ""
            )}
          >
            <Button
              onClick={async () => {
                // await updateDoubtStatus(0, props.id);
                props.setDoubtResolvedFromTop(props.id)
                props.setPopUp(true)
                props.setTopic("")
              }}
              className="h-[36px] w-[146px] cursor-pointer border-[#EF4444] text-[#EF4444] hover:bg-red-100"
            >
              {/* <a
                href={
                  'https://api.whatsapp.com/send?phone=919870258716&text=' +
                  'Hi,%0AI have a Doubt related to%0AQuestion: ' +
                  props.history['problem_name'] +
                  '.%0AI have gone through the provided solution on the website.'
                }
                target="_blank"
              > */}
              Not-Resolved
              {/* </a> */}
            </Button>
            <Button
              onClick={async () => {
                // setInstantResolve(true);
                await searchStudentDoubt(props.history["problem_name"])
                // await updateDoubtStatus(1, props.id);
                props.setResolve(!props.resolve)
                props.setDoubtResolvedFromTop(props.id)
                props.setPopUpResolved(true)
                props.setTopic("")
              }}
              className="h-[36px] w-[146px] cursor-pointer bg-new-gradient text-white hover:bg-[#3941ED] hover:bg-none"
            >
              Resolved
            </Button>
          </div>
        </div>
        <div
          onClick={toggleDropdown}
          className={cn(
            "absolute top-[125px] left-6 right-6 flex flex-col border",
            isOpen ? "h-[295px]" : "h-[65px] items-center justify-center"
          )}
        >
          <div
            className={cn(
              "absolute left-8 right-8 flex h-[33px] cursor-pointer items-center justify-center gap-x-[10px] bg-gray-100 py-4 px-8",
              isOpen && "mt-4"
            )}
          >
            <div className="text-center text-sm font-medium">Show Solution</div>
            <ChevronDown className={cn("h-4 w-4", isOpen && "rotate-180")} />
          </div>
          {isOpen && (
            <div className="mt-9 mr-[14px] flex h-[300px] w-full flex-col p-6 font-normal">
              <h2 className="mt-2 text-new-accent">{props.history["problem_name"]}</h2>
              <div className="flex gap-x-1">
                <h4 className="mt-1 ">{props.history["num_resolved"]}+</h4>
                People find the solution for the problem relevant and helpful.
              </div>
              <div className="mt-6 flex gap-x-8">
                <div className="flex flex-col gap-y-2">
                  <h4>Video Solution</h4>
                  <a
                    href={props.history["resources"][1] ? props.history["resources"][1]["link"] : "www.google.com"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex cursor-pointer ">
                      <VideoThumbnail h={90} w={172} />
                    </div>
                    <div className="mt-1 cursor-pointer text-center text-sm font-medium underline hover:text-sm hover:font-semibold hover:text-indigo-700">
                      Click here to view
                    </div>
                  </a>
                </div>
                <div className="flex flex-col gap-y-2">
                  <h4>Blog Article</h4>
                  <a
                    href={props.history["resources"][0] ? props.history["resources"][0]["link"] : "www.google.com"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex cursor-pointer">
                      <BlogThumbnail w={172} h={90} />
                    </div>
                    <div className="mt-1 cursor-pointer text-center text-sm font-medium underline hover:text-sm hover:font-semibold hover:text-indigo-700">
                      Click here to view
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
