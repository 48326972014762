import UpdateSSMCallDetailDataSource from "data/dataSource/Admin/UpdateSSMCallDetailDataSource"
import { Auth } from "domain/model/Auth"
import { UpdateSSMCallDetail } from "domain/model/UpdateSSMCallDetail"
import { UpdateSSMCallDetailRepository } from "domain/repository/Admin/UpdateSSMCallDetailRepository"

export class UpdateSSMCallDetailRepositoryImpl implements UpdateSSMCallDetailRepository {
  private Datasource: UpdateSSMCallDetailDataSource

  constructor(Datasource: UpdateSSMCallDetailDataSource) {
    this.Datasource = Datasource
  }

  async updateSSMCallDetail(updateSSMCallDetail: UpdateSSMCallDetail): Promise<any> {
    return await this.Datasource.updateSSMCallDetail(updateSSMCallDetail)
  }
}
