import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "core/lib/utils"

const headingVariants = cva("text-v2-accent-400", {
  variants: {
    variant: {
      h1: "text-[42px]",
      h2: "text-[32px]",
      h3: "text-[24px]",
      h4: "text-[18px]",
      h5: "text-[16px]",
      h6: "text-[14px]",
    },
    fontStyle: {
      semiBold: "font-semibold",
      medium: "font-medium",
      regular: "font-normal",
    },
  },
  defaultVariants: {
    variant: "h1",
    fontStyle: "semiBold",
  },
})

interface HeadingsProps extends VariantProps<typeof headingVariants> {
  className?: string
  children: React.ReactNode
}

const Headings: React.FC<HeadingsProps> = ({ className, variant, fontStyle, children }) => {
  return (
    <div className="flex content-center items-center flex-shrink-0">
      <div className={cn(headingVariants({ variant, fontStyle }), className)}>{children}</div>
    </div>
  )
}

Headings.displayName = "heading"
export { Headings, headingVariants }
