import { getStudentDetails } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface getTop100StudentsUseCase {
  invoke(details: getStudentDetails): Promise<any>
}
export class GetTop100Students implements getTop100StudentsUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: getStudentDetails): Promise<any> {
    return await this.repository.getTop100Students(details)
  }
}
