import React from "react"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { getBatchDetails } from "domain/useCase/Admin/Batch/getBatchDetails"

import { UpdateBatchDetails } from "domain/useCase/Admin/Batch/updateBatchDetails"

export default function EditBatchViewModel() {
  const [_selectTrack, _setSelectTrack] = React.useState<any>([])
  const [_currentSelectTrack, _setCurrentSelectTrack] = React.useState<any>([])
  const [_selectModule, _setSelectModule] = React.useState<any>([])
  const [_currentSelectModule, _setCurrentSelectModule] = React.useState<any>([])
  // --------------------------------
  const [batch, setBatch] = React.useState([])
  const [selectBatch, setSelectBatch] = React.useState<any>([])
  const [currentSelectBatch, setCurrentSelectBatch] = React.useState("")
  const [editMode, setEditMode] = React.useState(false)

  // ----------------------------

  const [_batchName, _setBatchName] = React.useState("")
  const [_meetingId, _setMeetingId] = React.useState("82906613084")
  const [_batchStartDate, _setBatchStartDate] = React.useState<any>("")
  const [_payable_start_date, _setpayable_start_date] = React.useState<any>("")
  const [_googleGroupEmail, _setGoogleGroupEmail] = React.useState<any>("manish.garg@bosscoderacademy.com")
  const [_modules, _setModules] = React.useState<any>({})
  const [_showModules, _setShowModules] = React.useState<any>([])

  const [track, setTrack] = React.useState<string[]>([])

  React.useState("")
  const [loading, setLoading] = React.useState(false)

  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  function handleBatchName(e: any) {
    _setBatchName(e.target.value)
  }
  function handleMeetingId(e: any) {
    _setMeetingId(e.target.value)
  }
  function handleGoogleGroupEmail(e: any) {
    _setGoogleGroupEmail(e.target.value)
  }

  function handleTrackChange(e: any) {
    const selectedTrack = e.target.value
    if (!_selectTrack.includes(selectedTrack)) {
      _setSelectTrack((prevSelectTrack: any) => [...prevSelectTrack, selectedTrack])
    }
    _setCurrentSelectTrack(selectedTrack)
  }
  // -----------------------------------
  async function handleBatchChange(e: any) {
    const selectedBatch = e.target.value
    setCurrentSelectBatch(selectedBatch)
    await getBatchDetail(selectedBatch)
  }

  function handleModuleChange(e: any) {
    const selectedModule = e.target.value
    if (!_selectModule.includes(selectedModule)) {
      _setSelectModule((prevSelectModule: any) => [...prevSelectModule, selectedModule])
    }
    _setCurrentSelectModule(selectedModule)
  }

  function handleBatchStartDate(e: any) {
    _setBatchStartDate(e.target.value)
  }
  function handlePayable_start_date(e: any) {
    _setpayable_start_date(e.target.value)
  }

  // ------------------------------
  function handleEdit() {
    setEditMode(true)
  }

  function handleCancel() {
    setEditMode(false)
  }

  //  ---------------------------------

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const getAllTracksUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getModuleBasedClassUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  // --------------------------------------

  const getBatchDetailsUseCase = new getBatchDetails(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))

  const updateBatchDetailsUseCase = new UpdateBatchDetails(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))

  //---------------------------------------------
  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatch(response.data)
    }
  }

  async function getBatchDetail(selectedBatch: any) {
    const response = await getBatchDetailsUseCase.invoke(auth, selectedBatch)

    if (response.success) {
      _setBatchName(response.data.batch_name)
      _setMeetingId(response.data.meeting_id)
      _setBatchStartDate(response.data.start_date)
      _setpayable_start_date(response.data.payable_start_date)
      _setGoogleGroupEmail(response.data.google_group_email)
      _setSelectTrack(response.data.tracks)
      _setSelectModule(response.data.modules)
    }
  }
  async function getAllTracks() {
    const response = await getAllTracksUseCase.invoke(auth)

    if (response.success) {
      setTrack(response.data)
      response.data.forEach((tr: any) => {
        _getModuleBasedTrack(tr)
      })
    }
  }

  async function _getModuleBasedTrack(track: string) {
    const response = await getModuleBasedClassUseCase.invoke(auth, track)
    if (response.success) {
      _setModules((prevModule: any) => ({
        ...prevModule,
        [track]: [response.data.modules],
      }))
    }
  }
  function removeHyphens(inputString: any) {
    return inputString.replace(/-/g, "")
  }

  // --------------------------------------
  async function handleSaveChanges() {
    setLoading(true)
    const response = await updateBatchDetailsUseCase.invoke({
      id_token: auth.id_token.trim(),
      batch_name: currentSelectBatch,
      start_date: removeHyphens(_batchStartDate),
      meeting_id: _meetingId.trim(),
      modules: _selectModule,
      tracks: _selectTrack,
      google_group_email: _googleGroupEmail.trim(),
      payable_start_date: removeHyphens(_payable_start_date),
      one_shot_payment_discount: "",
      offer_discount_in_percent: "",
      mentorship_template_to_inherit: "",
    })
    setLoading(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Batch Updated successfully!")
    setEditMode(false)
  }

  // -------------------------------

  return {
    _selectTrack,
    _currentSelectTrack,
    _batchName,
    _meetingId,
    _batchStartDate,
    _modules,
    _showModules,
    _currentSelectModule,
    _selectModule,
    _googleGroupEmail,
    _payable_start_date,
    toast,
    track,
    loading,
    refreshed,
    _setSelectTrack,
    _setShowModules,
    _setSelectModule,
    handleModuleChange,
    handleBatchName,
    handleGoogleGroupEmail,
    handlePayable_start_date,
    handleMeetingId,
    handleBatchStartDate,
    _setCurrentSelectModule,
    _setCurrentSelectTrack,
    handleTrackChange,
    changeToastVisibility,
    getAllTracks,
    selectBatch,
    setSelectBatch,
    setCurrentSelectBatch,
    batch,
    getBatches,
    handleBatchChange,
    currentSelectBatch,
    handleEdit,
    handleCancel,
    editMode,
    handleSaveChanges,
    getBatchDetail,
  }
}
