import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface GetFeedbackDetailsUseCase {
  invoke(auth: Auth, class_id: string): Promise<any>
}

export class GetFeedbackDetails implements GetFeedbackDetailsUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, class_id: string) {
    return this.repository.getFeedbackDetails(auth, class_id)
  }
}
