import SearchInput from "core/components/SearchInput"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import MentorDashboardTable from "./components/MentorDashboardTable"
import useMentorDashboardViewModel from "./MentorDashboardViewModel"

export default function MentorDashboardView() {
  const {
    mentorData,
    searchByEmail,
    searchByCourse,
    getMentorsData,
    handleSearchByEmail,
    handleSearchByCourse,
    searchingforEmail,
    searchingByCourse,
    handleInstructor,
    handleDisableMentor,
    toggleGoalSettingMentor,
    handleLockMentor,
  } = useMentorDashboardViewModel()

  const tableHeaders = [
    "",
    "General Info",
    "No. of Mentees",
    "Bandwidth",
    "Current Company",
    "Experience",
    "Instructor",
    "Disable",
    "Lock Mentor",
    "Goal Setting Mentor",
    "Edit",
  ]

  React.useEffect(() => {
    getMentorsData()
  }, [])

  return (
    <DashboardLayout>
      <div className="mt-[40px] mb-8 flex flex-row">
        <SearchInput
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
          placeholder="Search by Email !"
          value={searchByEmail}
          onChange={handleSearchByEmail}
        />
        {/* <SearchInput
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
          placeholder="Search by course !"
          value={searchByCourse}
          onChange={handleSearchByCourse}
        /> */}
        <select
          name=""
          id=""
          onChange={handleSearchByCourse}
          value={searchByCourse}
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
        >
          <option value="">Select Course</option>
          <option value="Both">Both</option>
          <option value="DSML">DSML</option>
          <option value="Evolve">Evolve</option>
          <option value="SDE">SDE</option>
        </select>
      </div>

      <MentorDashboardTable
        tableHeaders={tableHeaders}
        mentorData={mentorData}
        searchByEmail={searchByEmail}
        searchByCourse={searchByCourse}
        searchingforEmail={searchingforEmail}
        searchingByCourse={searchingByCourse}
        handleInstructor={handleInstructor}
        handleDisableMentor={handleDisableMentor}
        handleDisableGoalSettingMentor={toggleGoalSettingMentor}
        handleLockMentor={handleLockMentor}
      />
    </DashboardLayout>
  )
}
