import React from "react"
import GetAllClassesViewModel from "./GetAllClassesViewModel"
import { useNavigate, useParams } from "react-router-dom"
import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/Button"
import CheckBoxCircle from "assets/svgs/CheckBoxCircle"
import CheckboxChecked from "assets/svgs/CheckboxChecked"
import CheckBox from "assets/svgs/CheckBox"

export default function RatingScaleView({ isModalOpen, toggleModal, data }: any) {
  const review = [
    {
      title: "Was the session interactive",
    },
    {
      title: "Was all your questions addresed",
    },
    {
      title: "Rate your speaker on knowledge",
    },
    {
      title: "Did you find the content relavent",
    },
    {
      title: "Please rate the instructor",
    },
  ]
  const recorded_review = [
    {
      title: "Was the content of the lecture clear and easy to understand?",
    },
    {
      title: "Did the lecture cover the necessary topics adequately?",
    },
    {
      title: "How engaging was the speaker's presentation style?",
    },
    {
      title: "Were the key points and concepts explained clearly?",
    },
    {
      title: "How satisfied are you with the recorded lecture?",
    },
  ]
  return (
    <div className="gap y-6 flex-col">
      <div className="flex" style={{ margin: "1rem" }}>
        <div className="flex-col">
          <h3>User Feedback {data?.student_email}</h3>
          <p>Please fill this small survey in order to proceed with your assignment.</p>
        </div>
      </div>
      {data.live_check === true
        ? review.map(({ title }, index) => (
            <div key={index} className="flex-col gap-y-6" style={{ margin: "1rem" }}>
              <div className="flex justify-between">
                <p>{title}</p>
                <div className="flex gap-x-[24px]">
                  <h4>Annoyed</h4>
                  <div className="flex ">
                    {Array(data[String(index + 1)])
                      .fill(0)
                      .map((_, i) => (
                        <CheckBox key={i} className={"w-[24px]"} />
                      ))}
                    {Array(5 - parseInt(data[String(index + 1)]))
                      .fill(0)
                      .map((_) => (
                        <CheckBoxCircle />
                      ))}
                  </div>
                  <h4>Awesome</h4>
                </div>
              </div>
            </div>
          ))
        : recorded_review.map(({ title }, index) => (
            <div key={index} className="flex-col gap-y-6" style={{ margin: "1rem" }}>
              <div className="flex justify-between">
                <p>{title}</p>
                <div className="flex gap-x-[24px]">
                  <h4>Annoyed</h4>
                  <div className="flex ">
                    {Array(data[String(index + 1)])
                      .fill(0)
                      .map((_, i) => (
                        <CheckBox key={i} className={"w-[24px]"} />
                      ))}
                    {Array(5 - parseInt(data[String(index + 1)]))
                      .fill(0)
                      .map((_) => (
                        <CheckBoxCircle />
                      ))}
                  </div>
                  <h4>Awesome</h4>
                </div>
              </div>
            </div>
          ))}
      <div className="flex-col" style={{ margin: "1rem" }}>
        <h3> Feedback received from student</h3>
        <p>{data.suggestion}</p>
      </div>
    </div>
  )
}
