import BatchWeeklyReportRepositry from "domain/repository/Admin/BatchWeeklyReport"
import GetBatchWeeklyReport from "domain/model/GetBatchWeeklyReport"

export interface getBatchWeeklyReportUseCase {
  invoke(details: GetBatchWeeklyReport): Promise<any>
}

export class GetBatchWeeklyReportData implements getBatchWeeklyReportUseCase {
  private repository: BatchWeeklyReportRepositry

  constructor(repository: BatchWeeklyReportRepositry) {
    this.repository = repository
  }

  async invoke(details: GetBatchWeeklyReport): Promise<any> {
    return await this.repository.getBatchWeeklyReport(details)
  }
}
