import React from "react"

export default function MainLogoNd(props: any) {
  return (
    <div>
      <svg
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="25"
        viewBox="0 0 120 25"
        fill="none"
      >
        <path
          d="M21.2933 20.4314L21.1793 20.7881L20.9409 21.5356C20.8468 21.8344 20.6579 22.0958 20.4019 22.2815C20.1458 22.4672 19.8361 22.5675 19.5179 22.5676L18.8251 21.9057L17.1757 20.3385L15.2758 18.5266C15.0266 18.2908 14.6942 18.1591 14.3484 18.1591C14.0026 18.1591 13.6702 18.2908 13.421 18.5266L11.5166 20.3355L9.86719 21.9027L9.17292 22.5647C8.85511 22.5631 8.54601 22.4623 8.29024 22.2768C8.03446 22.0914 7.84525 21.8308 7.74991 21.5327L7.51899 20.7881L7.40503 20.4314C7.56534 20.427 7.72345 20.3935 7.87137 20.3326C8.01912 20.2712 8.15389 20.1832 8.26873 20.0731L9.61827 18.7875L12.1464 16.377C12.4326 16.1044 12.8156 15.9521 13.214 15.9524H15.6762C15.9557 15.9507 16.2248 16.0565 16.4259 16.2473L16.7663 16.5716L19.0875 18.7831L20.4371 20.0687C20.5514 20.1794 20.6863 20.2675 20.8344 20.3282C20.9824 20.3891 21.1405 20.4226 21.3008 20.4269L21.2933 20.4314Z"
          fill="white"
        />
        <path
          d="M16.7558 16.5716C15.9263 16.5713 15.1292 16.8885 14.5336 17.4562L14.3492 17.6316L13.4255 18.5162L11.5166 20.3355L9.86719 21.9027L9.17292 22.5647C8.85511 22.5631 8.54601 22.4623 8.29024 22.2768C8.03446 22.0914 7.84525 21.8308 7.74991 21.5327L7.51899 20.7881L7.40503 20.4314C7.56534 20.427 7.72345 20.3935 7.87137 20.3326C8.01912 20.2712 8.15389 20.1832 8.26873 20.0731L9.61827 18.7875L12.1464 16.377C12.4326 16.1044 12.8156 15.9521 13.214 15.9524H15.6762C15.9557 15.9507 16.2248 16.0565 16.4259 16.2473L16.7558 16.5716Z"
          fill="white"
        />
        <path
          d="M11.617 12.0894L10.5224 14.0502C10.4081 14.2546 10.2401 14.425 10.0359 14.5438C9.83179 14.6625 9.59897 14.7253 9.36176 14.7254H6.87561C6.47202 14.7255 6.07456 14.6283 5.71801 14.4424L1.84933 12.4226C1.80217 12.3992 1.74765 12.3946 1.69711 12.4097C1.64657 12.4248 1.60388 12.4584 1.57792 12.5037C1.56027 12.5342 1.55097 12.5688 1.55093 12.6039C1.55248 12.6371 1.56231 12.6694 1.57958 12.6979C1.59684 12.7265 1.62099 12.7504 1.6499 12.7676L3.4028 13.8674C4.94783 14.8388 6.09518 16.3145 6.64319 18.0352L7.40493 20.431L7.51889 20.7878L7.75581 21.5352C7.85116 21.8333 8.04037 22.0939 8.29614 22.2794C8.55191 22.4649 8.86101 22.5657 9.17883 22.5673C8.97083 22.7698 8.69036 22.8842 8.39759 22.8857H4.40596C4.12237 22.8855 3.84625 22.7964 3.6178 22.6312C3.38935 22.4659 3.22052 22.2333 3.13589 21.9672L0.0274543 12.197C-0.000228218 12.1122 -0.00709986 12.0221 0.00740102 11.9341C0.0219019 11.8462 0.0573637 11.7629 0.110888 11.691C0.164412 11.6191 0.234479 11.5607 0.315363 11.5205C0.396246 11.4803 0.48565 11.4595 0.576267 11.4598H11.2466C11.3217 11.4594 11.3956 11.4785 11.4608 11.5152C11.5259 11.552 11.5801 11.605 11.6177 11.6689C11.6553 11.7328 11.675 11.8054 11.6749 11.8792C11.6748 11.9531 11.6548 12.0256 11.617 12.0894Z"
          fill="white"
        />
        <path
          d="M28.667 12.1968L25.5586 21.967C25.4737 22.2329 25.3047 22.4652 25.0763 22.6301C24.8479 22.795 24.5719 22.884 24.2885 22.8841H20.3044C20.0081 22.8837 19.7241 22.7682 19.5142 22.5627C19.8323 22.5625 20.1421 22.4622 20.3981 22.2765C20.6542 22.0908 20.8431 21.8295 20.9372 21.5306L21.1756 20.7832L21.2895 20.4264L21.9478 18.355C22.5623 16.4239 23.8506 14.7682 25.5856 13.68L27.0476 12.7629C27.0766 12.7448 27.1004 12.7197 27.117 12.6901C27.1335 12.6605 27.1421 12.6272 27.142 12.5934C27.1428 12.5584 27.1343 12.5238 27.1173 12.4931C27.1002 12.4623 27.0753 12.4365 27.045 12.4182C27.0147 12.3998 26.98 12.3896 26.9444 12.3885C26.9088 12.3875 26.8736 12.3956 26.8421 12.4121L22.975 14.4319C22.6184 14.6176 22.2209 14.7148 21.8174 14.7149H19.3327C19.0955 14.7149 18.8626 14.6523 18.6584 14.5335C18.4543 14.4147 18.2863 14.2442 18.1721 14.0397L17.0775 12.0892C17.0418 12.0254 17.0236 11.9536 17.0247 11.8808C17.0258 11.808 17.0463 11.7367 17.084 11.6741C17.1217 11.6114 17.1753 11.5595 17.2397 11.5234C17.304 11.4874 17.3768 11.4684 17.4509 11.4685H28.1182C28.2077 11.4689 28.2958 11.4899 28.3756 11.5296C28.4555 11.5694 28.5247 11.6269 28.578 11.6976C28.6313 11.7683 28.6671 11.8502 28.6825 11.9369C28.698 12.0235 28.6927 12.1125 28.667 12.1968Z"
          fill="white"
        />
        <path
          d="M20.9409 8.66744H17.1787C17.0807 8.66741 16.9839 8.64613 16.8953 8.6051C16.8066 8.56407 16.7283 8.5043 16.6658 8.43008L14.9069 6.33067C14.8445 6.25602 14.7675 6.19449 14.6804 6.14975C14.5933 6.10501 14.4979 6.07799 14.3999 6.07028C14.302 6.06258 14.2034 6.07435 14.1102 6.1049C14.017 6.13545 13.931 6.18416 13.8573 6.24811C13.8281 6.27342 13.801 6.30103 13.7763 6.33067L12.0174 8.43008C11.955 8.5043 11.8766 8.56407 11.788 8.6051C11.6993 8.64613 11.6026 8.66741 11.5046 8.66744H7.75588C7.63206 8.66724 7.51084 8.63248 7.40633 8.5672C7.30182 8.50191 7.21831 8.40879 7.16553 8.29866C7.11274 8.18853 7.09285 8.06593 7.10817 7.94513C7.12349 7.82432 7.17338 7.71028 7.25205 7.61626L11.6231 2.3928L13.1645 0.55139C13.3039 0.384576 13.478 0.24905 13.6751 0.153939C13.8721 0.0588268 14.0877 0.00633597 14.3071 0L14.3956 0C14.6148 0.00644927 14.8301 0.0589981 15.0269 0.154109C15.2238 0.249221 15.3976 0.384687 15.5367 0.55139L15.6687 0.709141L17.0782 2.3928L18.0544 3.55897L21.4432 7.61626C21.5218 7.71018 21.5717 7.82409 21.587 7.94476C21.6024 8.06544 21.5826 8.18792 21.53 8.298C21.4774 8.40807 21.3942 8.50121 21.2899 8.56661C21.1856 8.632 21.0646 8.66697 20.9409 8.66744Z"
          fill="white"
        />
        <path
          d="M18.0484 3.55897C17.4409 3.56008 16.8411 3.69275 16.2917 3.94756C15.7423 4.20237 15.2567 4.57306 14.8694 5.03328L14.3551 5.64659L13.8588 6.23631C13.8296 6.26163 13.8025 6.28924 13.7778 6.31887L12.0174 8.43008C11.955 8.5043 11.8766 8.56407 11.788 8.6051C11.6993 8.64613 11.6026 8.66741 11.5046 8.66744H7.75588C7.63206 8.66724 7.51084 8.63248 7.40633 8.5672C7.30182 8.50191 7.21831 8.40879 7.16553 8.29866C7.11274 8.18853 7.09285 8.06593 7.10817 7.94513C7.12349 7.82432 7.17338 7.71028 7.25205 7.61626L11.6231 2.3928L13.1645 0.55139C13.3039 0.384576 13.478 0.24905 13.6751 0.153939C13.8721 0.0588268 14.0877 0.00633597 14.3071 0L14.3956 0C14.6148 0.00644927 14.8301 0.0589981 15.0269 0.154109C15.2238 0.249221 15.3976 0.384687 15.5367 0.55139L15.6687 0.709141L17.0782 2.3928L18.0484 3.55897Z"
          fill="white"
        />
        <path d="M18.0477 3.5593L17.0715 2.39313L18.0477 3.5593Z" fill="white" />
        <path
          d="M45.9898 6.0074C45.765 5.69104 45.4528 5.44448 45.0901 5.29679C45.0232 5.2673 44.9546 5.2417 44.8847 5.22012C45.179 5.07063 45.4302 4.85056 45.615 4.58027C45.8214 4.26421 45.9259 3.89423 45.9148 3.51877C45.9216 3.23281 45.8567 2.9496 45.726 2.69406C45.5952 2.43852 45.4026 2.21849 45.1651 2.05331C44.6653 1.68179 43.918 1.49652 42.9234 1.4975H39.0652V9.27299H43.1543C44.1939 9.27299 44.9812 9.08575 45.516 8.71128C45.773 8.53932 45.9813 8.30595 46.1213 8.0331C46.2614 7.76025 46.3286 7.45687 46.3167 7.15146C46.3308 6.74603 46.2166 6.34629 45.9898 6.0074ZM40.8826 2.85239H42.7029C43.0576 2.83169 43.4111 2.90825 43.7241 3.07353C43.8418 3.14607 43.9374 3.2484 44.001 3.3698C44.0646 3.49121 44.0938 3.6272 44.0855 3.76351C44.0941 3.89963 44.0658 4.03556 44.0033 4.15734C43.9409 4.27912 43.8466 4.38235 43.7301 4.45643C43.4932 4.60386 43.1528 4.68495 42.7089 4.68495H40.8826V2.85239ZM44.1035 7.68516C43.8546 7.83259 43.5037 7.9181 43.0298 7.9181H40.8826V6.0015H43.0358C43.5037 6.0015 43.8605 6.07964 44.1095 6.23592C44.2337 6.31412 44.334 6.42398 44.3997 6.55378C44.4655 6.68359 44.4942 6.82849 44.4828 6.97307C44.4894 7.11416 44.4577 7.25443 44.3911 7.37954C44.3244 7.50465 44.2252 7.61012 44.1035 7.68516Z"
          fill="white"
        />
        <path
          d="M56.3884 3.77406C56.1785 3.29168 55.8697 2.85704 55.4812 2.49731C55.0844 2.13093 54.6175 1.84577 54.1077 1.65843C53.5571 1.45739 52.9736 1.35744 52.3863 1.36357C51.7978 1.35531 51.2129 1.45531 50.6618 1.65843C50.152 1.84998 49.6854 2.13799 49.2883 2.50616C48.9004 2.86498 48.5912 3.29794 48.3796 3.77848C48.1596 4.28575 48.0498 4.8326 48.0572 5.384C48.051 5.93339 48.1608 6.47808 48.3796 6.98362C48.5905 7.46455 48.8998 7.89765 49.2883 8.25595C49.6874 8.62303 50.1555 8.90999 50.6663 9.10073C51.7777 9.49586 52.9948 9.49586 54.1062 9.10073C54.6156 8.91708 55.0829 8.63596 55.4812 8.27364C55.8713 7.91272 56.1808 7.47591 56.3899 6.99099C56.6084 6.48329 56.7182 5.93674 56.7123 5.38548C56.7187 4.8321 56.6084 4.28342 56.3884 3.77406ZM54.6715 6.39095C54.5511 6.68532 54.3728 6.95338 54.1467 7.17971C53.923 7.40377 53.6558 7.58126 53.3609 7.70161C53.0513 7.82617 52.7193 7.88833 52.3848 7.88442C52.0478 7.88801 51.7135 7.82587 51.4011 7.70161C51.1052 7.58307 50.8376 7.40534 50.6154 7.17971C50.3926 6.95194 50.2164 6.68415 50.0965 6.39095C49.9681 6.07098 49.9044 5.72943 49.9091 5.38548C49.9043 5.04151 49.968 4.69994 50.0965 4.38C50.2164 4.0868 50.3926 3.81902 50.6154 3.59125C50.8376 3.36561 51.1052 3.18788 51.4011 3.06934C51.7135 2.94508 52.0478 2.88294 52.3848 2.88653C52.7193 2.88262 53.0513 2.94479 53.3609 3.06934C53.6558 3.18969 53.923 3.36718 54.1467 3.59125C54.3728 3.81757 54.5511 4.08563 54.6715 4.38C54.8 4.69994 54.8637 5.04151 54.8589 5.38548C54.8636 5.72943 54.7999 6.07098 54.6715 6.39095Z"
          fill="white"
        />
        <path
          d="M64.6367 5.9898C64.4735 5.72538 64.247 5.5042 63.977 5.34553C63.6951 5.18264 63.3959 5.05064 63.0848 4.95189C62.7594 4.84869 62.4325 4.76171 62.1011 4.69094C61.7697 4.62017 61.4683 4.55088 61.2014 4.48011C60.9652 4.42574 60.7424 4.32549 60.5461 4.18525C60.4661 4.12822 60.4014 4.05295 60.3575 3.96589C60.3137 3.87883 60.2919 3.7826 60.2942 3.68546C60.2929 3.52576 60.3457 3.37015 60.4442 3.24317C60.5694 3.0939 60.7361 2.98375 60.924 2.9262C61.2057 2.83934 61.5002 2.79996 61.7952 2.80973C62.1616 2.81152 62.5262 2.86109 62.8793 2.95716C63.2632 3.05915 63.6317 3.21033 63.9755 3.40682L64.5513 2.05193C64.1576 1.81661 63.7284 1.64442 63.2797 1.54182C62.7976 1.42377 62.3026 1.36387 61.8057 1.36343C61.1698 1.34366 60.5368 1.45568 59.9478 1.6922C59.5012 1.87247 59.1181 2.17743 58.8457 2.56941C58.6049 2.93064 58.4781 3.35382 58.4814 3.78572C58.4681 4.13962 58.552 4.49049 58.7243 4.80151C58.8849 5.06926 59.1106 5.29367 59.381 5.45463C59.6627 5.62447 59.9649 5.75871 60.2807 5.85417C60.6076 5.95245 60.933 6.03747 61.2569 6.10922C61.5808 6.18097 61.8807 6.25518 62.1566 6.33184C62.3978 6.39174 62.6259 6.49414 62.8299 6.63407C62.9093 6.69038 62.9736 6.76497 63.017 6.85132C63.0604 6.93767 63.0816 7.03316 63.0788 7.12944C63.0809 7.28604 63.0249 7.43802 62.9213 7.55699C62.7873 7.69691 62.6174 7.79877 62.4295 7.85185C62.1456 7.93524 61.8499 7.97307 61.5538 7.9639C61.0699 7.96349 60.5893 7.88582 60.1308 7.73391C59.7022 7.59966 59.2975 7.40071 58.9312 7.14418L58.3164 8.50644C58.7406 8.80633 59.2174 9.02674 59.7229 9.15661C60.3161 9.32429 60.9306 9.40818 61.5478 9.40577C62.1861 9.42604 62.8215 9.31246 63.4117 9.07258C63.8599 8.89021 64.2459 8.58589 64.5243 8.19536C64.7666 7.84072 64.8946 7.42251 64.8917 6.99528C64.9045 6.64322 64.8162 6.29474 64.6367 5.9898Z"
          fill="white"
        />
        <path
          d="M72.692 5.9903C72.5282 5.72636 72.3018 5.50531 72.0323 5.34603C71.7481 5.18246 71.4464 5.05044 71.1326 4.95239C70.8087 4.84919 70.4813 4.7622 70.1504 4.69144C69.819 4.62067 69.5176 4.55138 69.2507 4.48061C69.0145 4.42624 68.7917 4.32599 68.5954 4.18575C68.5163 4.12815 68.4526 4.05265 68.4095 3.96564C68.3664 3.87863 68.3453 3.78269 68.348 3.68596C68.3468 3.52627 68.3995 3.3707 68.498 3.24367C68.6232 3.09439 68.7899 2.98424 68.9778 2.92669C69.2594 2.83972 69.554 2.80034 69.849 2.81022C70.2154 2.81202 70.58 2.86159 70.9331 2.95765C71.317 3.05964 71.6855 3.21083 72.0293 3.40732L72.6051 2.05243C72.2115 1.8166 71.7823 1.6439 71.3335 1.54085C70.8513 1.4228 70.3564 1.36289 69.8595 1.36246C69.2236 1.34269 68.5906 1.45471 68.0016 1.69123C67.555 1.87149 67.1718 2.17646 66.8995 2.56844C66.6597 2.92999 66.534 3.35315 66.5381 3.78474C66.5249 4.13864 66.6088 4.48952 66.781 4.80054C66.9419 5.069 67.1681 5.29394 67.4393 5.45513C67.7209 5.62497 68.0232 5.75921 68.339 5.85466C68.6659 5.95295 68.9913 6.03797 69.3152 6.10972C69.6391 6.18147 69.939 6.25568 70.2149 6.33234C70.4513 6.3913 70.6752 6.49114 70.8761 6.6272C70.9556 6.68351 71.0199 6.7581 71.0633 6.84445C71.1067 6.9308 71.1279 7.02629 71.1251 7.12257C71.1273 7.27945 71.0707 7.43165 70.9661 7.55012C70.8329 7.69033 70.6634 7.79226 70.4758 7.84498C70.1919 7.92836 69.8962 7.9662 69.6001 7.95702C69.1198 7.95598 68.6429 7.87882 68.1876 7.72851C67.7589 7.59458 67.3542 7.3956 66.988 7.13878L66.3672 8.49367C66.7917 8.79875 67.2702 9.0237 67.7782 9.15711C68.3714 9.32479 68.9859 9.40868 69.6031 9.40627C70.2414 9.42654 70.8768 9.31296 71.4669 9.07307C71.9141 8.89023 72.2989 8.58595 72.5766 8.19586C72.8189 7.84122 72.9469 7.42301 72.9439 6.99578C72.957 6.64408 72.8697 6.29581 72.692 5.9903Z"
          fill="white"
        />
        <path
          d="M80.0243 7.64575C79.6842 7.80611 79.3113 7.88781 78.9342 7.88459C78.5865 7.88861 78.2413 7.82653 77.9175 7.70177C77.6171 7.58473 77.3441 7.40865 77.1153 7.18429C76.8873 6.96003 76.7087 6.69205 76.5905 6.39701C76.4623 6.07491 76.3987 5.73149 76.4031 5.38564C76.3987 5.03979 76.4623 4.69637 76.5905 4.37427C76.7082 4.07858 76.8869 3.81003 77.1153 3.58549C77.3437 3.36095 77.6168 3.18528 77.9175 3.06951C78.2413 2.94475 78.5865 2.88267 78.9342 2.88669C79.3107 2.88291 79.6833 2.96252 80.0243 3.11963C80.3711 3.28278 80.678 3.51763 80.924 3.80813L82.1101 2.74221C81.7368 2.29013 81.2557 1.93564 80.7096 1.7102C79.5687 1.2625 78.3002 1.24256 77.1453 1.65417C76.6371 1.84121 76.1721 2.12645 75.7778 2.49305C75.3931 2.85443 75.0871 3.28877 74.8781 3.7698C74.6598 4.27959 74.5505 4.82812 74.5572 5.38122C74.5503 5.93386 74.6596 6.48194 74.8781 6.99116C75.0886 7.47652 75.3976 7.91459 75.7861 8.27859C76.1746 8.6426 76.6346 8.9249 77.1378 9.10826C77.6814 9.30818 78.2578 9.40812 78.8382 9.40312C79.4817 9.41284 80.1203 9.29188 80.7141 9.04781C81.2594 8.821 81.7401 8.46674 82.1146 8.0158L80.9285 6.94988C80.6803 7.24184 80.3722 7.47897 80.0243 7.64575Z"
          fill="white"
        />
        <path
          d="M91.8415 3.77408C91.6312 3.29156 91.3218 2.85692 90.9328 2.49733C90.5356 2.13156 90.0688 1.84649 89.5593 1.65845C89.0082 1.45722 88.4242 1.35728 87.8364 1.36359C87.2484 1.35519 86.664 1.4552 86.1135 1.65845C85.6042 1.85016 85.138 2.13817 84.7414 2.50617C84.3528 2.86466 84.043 3.29768 83.8312 3.7785C83.6118 4.28594 83.502 4.83267 83.5088 5.38402C83.5034 5.93334 83.6131 6.47788 83.8312 6.98364C84.0421 7.46493 84.352 7.89811 84.7414 8.25596C85.1402 8.62338 85.6084 8.91038 86.1195 9.10074C87.2308 9.49587 88.4479 9.49587 89.5593 9.10074C90.0688 8.9127 90.5356 8.62762 90.9328 8.26186C91.323 7.90094 91.6324 7.46413 91.8415 6.97921C92.0593 6.4713 92.169 5.9249 92.1639 5.3737C92.1684 4.82446 92.0587 4.2801 91.8415 3.77408ZM90.1201 6.39097C89.9992 6.6854 89.8203 6.95345 89.5938 7.17972C89.3707 7.40374 89.104 7.58125 88.8095 7.70162C88.4993 7.82603 88.1669 7.88818 87.8319 7.88444C87.4954 7.88806 87.1615 7.82592 86.8497 7.70162C86.5565 7.58219 86.2916 7.40451 86.0715 7.17972C85.8482 6.95203 85.6716 6.68425 85.5512 6.39097C85.4232 6.07089 85.3601 5.72935 85.3652 5.38549C85.36 5.04162 85.4231 4.70006 85.5512 4.38001C85.6716 4.08673 85.8482 3.81895 86.0715 3.59126C86.2938 3.36563 86.5613 3.1879 86.8572 3.06936C87.169 2.94506 87.5029 2.88292 87.8394 2.88654C88.1744 2.8828 88.5068 2.94495 88.817 3.06936C89.1115 3.18973 89.3782 3.36724 89.6013 3.59126C89.8251 3.81818 90.0014 4.08619 90.1201 4.38001C90.2481 4.70006 90.3113 5.04162 90.306 5.38549C90.3112 5.72935 90.248 6.07089 90.1201 6.39097Z"
          fill="white"
        />
        <path
          d="M101.79 3.33614C101.417 2.75121 100.884 2.28156 100.253 1.98125C99.5449 1.64704 98.7672 1.48138 97.9823 1.49768H94.3835V9.27316H97.9823C98.7672 9.28946 99.5449 9.1238 100.253 8.78959C100.884 8.48888 101.417 8.01932 101.79 7.43471C102.168 6.81724 102.36 6.10635 102.343 5.38542C102.361 4.66446 102.169 3.95351 101.79 3.33614ZM100.167 6.66807C99.9591 7.03269 99.6461 7.32867 99.2674 7.51874C98.8406 7.72361 98.3701 7.82473 97.8953 7.8136H96.2309V2.96314H97.8924C98.3671 2.95201 98.8376 3.05313 99.2644 3.258C99.6409 3.44556 99.9536 3.73711 100.164 4.09688C100.387 4.49033 100.498 4.93529 100.486 5.38542C100.497 5.83323 100.387 6.27586 100.167 6.66807Z"
          fill="white"
        />
        <path
          d="M110.691 7.82816V9.27151H104.567V1.4975H110.544V2.94084H106.388V4.6186H110.058V6.02804H106.388V7.82816H110.691Z"
          fill="white"
        />
        <path
          d="M118.238 6.77341H118.25C118.733 6.57816 119.146 6.24551 119.436 5.81806C119.723 5.36781 119.869 4.84412 119.855 4.31279C119.868 3.78349 119.722 3.26209 119.436 2.81342C119.146 2.38206 118.734 2.04415 118.25 1.84186C117.671 1.60257 117.047 1.48699 116.419 1.50277H112.996V9.27383H114.837V7.09628H116.499L118.034 9.27383H120L118.238 6.77341ZM117.583 5.30795C117.297 5.54482 116.875 5.66326 116.317 5.66326H114.837V2.96528H116.317C116.875 2.96528 117.296 3.08027 117.583 3.31321C117.727 3.4358 117.84 3.5893 117.914 3.76185C117.988 3.9344 118.021 4.12139 118.01 4.30837C118.021 4.49599 117.988 4.6836 117.914 4.85685C117.84 5.03009 117.727 5.18439 117.583 5.30795Z"
          fill="white"
        />
        <path
          d="M38.0337 22.9551L41.2351 15.8785H42.8845L46.1159 22.9551H44.3645L41.7194 16.7011H42.3882L39.7371 22.9551H38.0337ZM39.6501 21.4366L40.0715 20.1923H43.7977L44.2356 21.4366H39.6501Z"
          fill="white"
        />
        <path
          d="M50.6231 23.0364C50.0712 23.0467 49.5253 22.9231 49.0336 22.6767C48.6969 22.4968 48.4005 22.252 48.1623 21.9569C47.924 21.6618 47.7488 21.3225 47.647 20.9593C47.5452 20.596 47.519 20.2164 47.57 19.8429C47.6209 19.4695 47.7479 19.11 47.9435 18.786C48.2098 18.3651 48.5863 18.0224 49.0336 17.7938C49.5249 17.5443 50.0717 17.4191 50.6246 17.4296C51.157 17.4171 51.6836 17.5411 52.1525 17.7894C52.5745 18.0132 52.9065 18.3714 53.0942 18.8052L51.8601 19.4745C51.7325 19.2426 51.5441 19.0487 51.3143 18.9128C51.1007 18.7942 50.8593 18.7327 50.6141 18.7344C50.3592 18.7319 50.1079 18.7933 49.8838 18.9128C49.6631 19.0308 49.4807 19.2076 49.3575 19.4229C49.2209 19.671 49.1537 19.9503 49.1626 20.2323C49.1538 20.5161 49.221 20.7973 49.3575 21.0476C49.4796 21.2652 49.6621 21.4442 49.8838 21.5636C50.108 21.6826 50.3593 21.7435 50.6141 21.7405C50.8592 21.7427 51.1006 21.6817 51.3143 21.5636C51.546 21.4256 51.7348 21.2277 51.8601 20.9916L53.0942 21.6594C52.9034 22.0905 52.5721 22.4468 52.1525 22.6723C51.6841 22.9236 51.1566 23.0492 50.6231 23.0364Z"
          fill="white"
        />
        <path
          d="M56.9815 23.0361C56.6026 23.0459 56.2264 22.9713 55.8809 22.8179C55.5916 22.6894 55.3437 22.4855 55.1642 22.2281C54.9965 21.9792 54.9087 21.6864 54.9123 21.3878C54.9013 21.0759 54.9938 20.769 55.1758 20.5134C55.3579 20.2578 55.6196 20.0674 55.9214 19.971C56.3648 19.8212 56.8324 19.7527 57.3009 19.769H58.7629V20.6786H57.4659C57.088 20.6786 56.8286 20.7406 56.6876 20.8615C56.6208 20.9176 56.5674 20.9875 56.5311 21.0663C56.4949 21.145 56.4766 21.2306 56.4777 21.317C56.4744 21.4105 56.4948 21.5033 56.5369 21.5872C56.579 21.6711 56.6416 21.7434 56.7191 21.7976C56.9119 21.9245 57.1413 21.9866 57.3729 21.9746C57.6303 21.9789 57.8843 21.9163 58.1092 21.7932C58.3236 21.6681 58.4838 21.4697 58.559 21.2359L58.8064 21.9731C58.6966 22.3017 58.4698 22.5802 58.1676 22.7574C57.8057 22.9555 57.3954 23.0519 56.9815 23.0361ZM58.67 22.955V21.892L58.5665 21.6591V19.7557C58.5742 19.6097 58.5502 19.4637 58.4962 19.3275C58.4422 19.1913 58.3593 19.0679 58.2531 18.9655C58.0432 18.7768 57.7223 18.6824 57.2904 18.6824C56.995 18.6825 56.7014 18.7288 56.4207 18.8195C56.1559 18.9005 55.9069 19.0251 55.6845 19.1881L55.1192 18.0853C55.4487 17.8571 55.8183 17.6907 56.2093 17.5944C56.6325 17.4839 57.0685 17.4279 57.5064 17.4278C58.3501 17.4278 59.0054 17.6244 59.4722 18.0175C59.939 18.4107 60.1725 19.0215 60.1725 19.8501V22.955H58.67Z"
          fill="white"
        />
        <path
          d="M65.2913 23.0358C64.7979 23.0429 64.3113 22.9224 63.8803 22.6864C63.4655 22.457 63.1235 22.1192 62.8921 21.7104C62.6568 21.2534 62.5342 20.7483 62.5342 20.2361C62.5342 19.7238 62.6568 19.2188 62.8921 18.7618C63.1231 18.3526 63.4652 18.0147 63.8803 17.7858C64.3113 17.5497 64.7979 17.4292 65.2913 17.4364C65.72 17.4272 66.1433 17.5322 66.5164 17.7401C66.8787 17.956 67.163 18.2783 67.3291 18.6615C67.5381 19.1612 67.6362 19.699 67.617 20.239C67.6343 20.7784 67.54 21.3156 67.3396 21.818C67.1827 22.2034 66.9027 22.5283 66.5419 22.7439C66.1591 22.9494 65.7272 23.0502 65.2913 23.0358ZM65.5582 21.7399C65.8102 21.7439 66.0589 21.6829 66.2795 21.5629C66.4962 21.4406 66.6731 21.2601 66.7893 21.0425C66.9207 20.7921 66.9862 20.5133 66.9797 20.2316C66.9878 19.9503 66.9223 19.6716 66.7893 19.4223C66.671 19.2088 66.4945 19.0321 66.2795 18.9121C66.059 18.7917 65.8103 18.7302 65.5582 18.7338C65.31 18.731 65.0653 18.7925 64.8489 18.9121C64.6337 19.0317 64.457 19.2085 64.3391 19.4223C64.2052 19.6712 64.1396 19.9502 64.1487 20.2316C64.1414 20.5134 64.207 20.7924 64.3391 21.0425C64.4549 21.2604 64.6318 21.441 64.8489 21.5629C65.0655 21.6821 65.3101 21.7432 65.5582 21.7399ZM67.0277 22.9547V21.8416L67.0592 20.2199L66.9557 18.6099V15.4446H68.5617V22.9547H67.0277Z"
          fill="white"
        />
        <path
          d="M74.0302 23.0359C73.4555 23.0489 72.8862 22.9239 72.3717 22.6718C71.913 22.4465 71.529 22.0973 71.2648 21.6652C71.0005 21.233 70.867 20.7359 70.8797 20.2318C70.8713 19.7253 71.0013 19.2258 71.2561 18.7855C71.5033 18.3625 71.8672 18.017 72.3058 17.7889C72.7725 17.5447 73.2946 17.4209 73.8232 17.4292C74.334 17.4219 74.8386 17.54 75.2912 17.7727C75.7201 17.9973 76.078 18.3334 76.3259 18.7442C76.5902 19.2035 76.7219 19.725 76.7067 20.2525C76.7067 20.3129 76.7068 20.3822 76.6963 20.4603C76.6858 20.5385 76.6828 20.6078 76.6753 20.6771H72.1768V19.7556H75.831L75.2028 20.0284C75.2156 19.7728 75.1592 19.5184 75.0393 19.2912C74.927 19.0868 74.7594 18.9169 74.555 18.8003C74.3351 18.6788 74.0859 18.6177 73.8337 18.6233C73.5802 18.6182 73.3297 18.6793 73.108 18.8003C72.9007 18.9167 72.7313 19.0884 72.6191 19.2956C72.4979 19.529 72.4381 19.7885 72.4452 20.0505V20.2937C72.4377 20.5703 72.5089 20.8435 72.6506 21.0825C72.7847 21.3033 72.9811 21.481 73.2159 21.5941C73.4865 21.7183 73.7827 21.7788 74.0811 21.771C74.3427 21.7754 74.6028 21.7309 74.8474 21.6398C75.0758 21.5482 75.2841 21.414 75.4607 21.2447L76.3154 22.1558C76.0528 22.4443 75.7245 22.6676 75.3572 22.8074C74.9338 22.9665 74.4833 23.0441 74.0302 23.0359Z"
          fill="white"
        />
        <path
          d="M86.1145 17.4295C86.5194 17.4214 86.9202 17.5099 87.2826 17.6875C87.6255 17.8624 87.9044 18.1381 88.0803 18.4762C88.2878 18.9004 88.3867 19.3681 88.3682 19.8385V22.9552H86.7623V20.0803C86.7623 19.6429 86.6718 19.319 86.4909 19.1087C86.3949 19.0034 86.2761 18.9206 86.1432 18.8665C86.0103 18.8124 85.8668 18.7883 85.7231 18.7961C85.5004 18.7915 85.2804 18.8455 85.0859 18.9524C84.8972 19.0597 84.7477 19.2227 84.6585 19.4183C84.5485 19.6724 84.4973 19.9473 84.5086 20.2233V22.9552H82.9026V20.0803C82.9026 19.638 82.8126 19.318 82.6297 19.1087C82.5307 19.0019 82.4085 18.9184 82.2723 18.8643C82.136 18.8102 81.9891 18.7869 81.8425 18.7961C81.6226 18.7925 81.4058 18.8464 81.2142 18.9524C81.0256 19.0612 80.8751 19.2236 80.7823 19.4183C80.6708 19.6719 80.6195 19.9472 80.6324 20.2233V22.9552H79.0234V17.5105H80.5574V18.9849L80.2695 18.5602C80.4424 18.201 80.727 17.9051 81.0822 17.7155C81.45 17.5182 81.8629 17.4167 82.2818 17.4206C82.7568 17.4114 83.2235 17.5435 83.6209 17.7995C84.0184 18.0737 84.2916 18.4893 84.3826 18.9583L83.8158 18.8109C84.0001 18.3944 84.3093 18.0431 84.702 17.8039C85.1261 17.5474 85.6167 17.4173 86.1145 17.4295Z"
          fill="white"
        />
        <path
          d="M91.6636 24.9998C91.3769 24.999 91.092 24.9542 90.8194 24.8672C90.5638 24.7935 90.3261 24.6696 90.1206 24.503L90.7084 23.3693C90.8311 23.4842 90.9759 23.5739 91.1343 23.6332C91.2907 23.6928 91.4568 23.7238 91.6246 23.7246C91.8142 23.7335 92.0012 23.6777 92.1539 23.5668C92.3154 23.4237 92.4407 23.2455 92.5198 23.0464L92.8077 22.3785L92.9306 22.206L94.928 17.5104H96.4619L93.9593 23.2941C93.8202 23.6718 93.6124 24.0215 93.346 24.3261C93.1385 24.5578 92.8772 24.7369 92.5843 24.848C92.2889 24.9517 91.9772 25.0031 91.6636 24.9998ZM92.6098 23.1673L90.1506 17.5104H91.8L93.7254 22.0807L92.6098 23.1673Z"
          fill="white"
        />
      </svg>
    </div>
  )
}
