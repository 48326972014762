import React from "react"

const Info = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 8.50016C1.3335 12.1822 4.31816 15.1668 8.00016 15.1668C11.6822 15.1668 14.6668 12.1822 14.6668 8.50016C14.6668 4.81816 11.6822 1.8335 8.00016 1.8335C4.31816 1.8335 1.3335 4.81816 1.3335 8.50016ZM13.3335 8.50016C13.3335 11.4457 10.9457 13.8335 8.00016 13.8335C5.05464 13.8335 2.66683 11.4457 2.66683 8.50016C2.66683 5.55464 5.05464 3.16683 8.00016 3.16683C10.9457 3.16683 13.3335 5.55464 13.3335 8.50016ZM8.66683 5.16683V6.50016H7.3335V5.16683H8.66683ZM8.66683 11.8335V7.8335H7.3335V11.8335H8.66683Z"
        fill="#D3D3D3"
      />
    </svg>
  )
}

export default Info
