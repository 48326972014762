// import React from "react"
// import Avatar from "react-avatar"

// export default function MenteesTable(props: any) {

//   return (
//     <div className="relative overflow-x-auto rounded-md shadow-md">
//       <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
//         <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
//           <tr>
//             <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium "></th>
//             <th className="whitespace-nowrap px-10 py-4 text-[16px] font-medium ">Mentees</th>
//           </tr>
//         </thead>
//         <tbody>
//           {props.mentees?.map((data: any, index: any) => (
//             <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
//               <td className="px-6 py-4 ">
//                 <Avatar name={data} round size="50" />
//               </td>
//               <td className="px-6 py-4 ">
//                 <p>{data}</p>
//                 <p className="font-bold">{data.email}</p>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   )
// }
import React, { useEffect, useState } from "react"
import Avatar from "react-avatar"

interface Mentee {
  id: string
  name: string
  email: string
}

interface MenteesTableProps {
  mentees: Mentee[]
  upDateMentorsMentee: (email: string) => void
}

const MenteesTable: React.FC<MenteesTableProps> = (props) => {
  let [mentees, setMentees] = useState<Mentee[]>(props.mentees)
  const [showConfirm, setShowConfirm] = useState(false)
  const [menteeToDelete, setMenteeToDelete] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteClick = (mentee: Mentee) => {
    setMenteeToDelete(mentee)
    setShowConfirm(true)
  }

  const handleConfirmDelete = () => {
    if (menteeToDelete) {
      props.upDateMentorsMentee(menteeToDelete)
      const newMentees = mentees.filter((mentee) => mentee !== menteeToDelete)
      setMentees(newMentees)
      setShowConfirm(false)
      setMenteeToDelete(null)
    }
  }

  // useEffect(()=>{
  //   handleConfirmDelete
  // },[mentees, props.mentees])

  const handleCancelDelete = () => {
    setShowConfirm(false)
    setMenteeToDelete(null)
  }

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
        <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
          <tr>
            <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium"></th>
            <th className="whitespace-nowrap px-10 py-4 text-[16px] font-medium">Mentees</th>
            <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">Actions</th>
          </tr>
        </thead>
        <tbody>
          {mentees.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4">
                <Avatar name={data} round size="50" />
              </td>
              <td className="px-6 py-4">
                <p>{data}</p>
                <p className="font-bold">{data.email}</p>
              </td>
              <td className="px-6 py-4">
                <button onClick={() => handleDeleteClick(data)} className="text-red-500 hover:text-red-700">
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <h3 className="text-lg font-bold">Confirm Deletion</h3>
            <p>Are you sure you want to remove this mentee?</p>
            <div className="mt-4 flex justify-end space-x-4">
              <button onClick={handleCancelDelete} className="px-4 py-2 bg-gray-300 text-gray-700 rounded">
                No
              </button>
              <button onClick={handleConfirmDelete} className="px-4 py-2 bg-red-500 text-white rounded">
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MenteesTable
