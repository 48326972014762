import React from "react"

const ReferWallet = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <path
        d="M14.3336 4.05556H17.0002C17.236 4.05556 17.4621 4.14921 17.6288 4.31591C17.7955 4.4826 17.8891 4.7087 17.8891 4.94445V15.6111C17.8891 15.8469 17.7955 16.073 17.6288 16.2397C17.4621 16.4064 17.236 16.5 17.0002 16.5H1.00022C0.764469 16.5 0.538377 16.4064 0.371678 16.2397C0.204979 16.073 0.111328 15.8469 0.111328 15.6111V1.38889C0.111328 1.15314 0.204979 0.927049 0.371678 0.76035C0.538377 0.59365 0.764469 0.5 1.00022 0.5H14.3336V4.05556ZM1.88911 5.83333V14.7222H16.1113V5.83333H1.88911ZM1.88911 2.27778V4.05556H12.5558V2.27778H1.88911ZM11.6669 9.38889H14.3336V11.1667H11.6669V9.38889Z"
        fill="#646464"
      />
    </svg>
  )
}

export default ReferWallet
