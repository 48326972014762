import React from "react"
import useToast from "core/hooks/useToast"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"
import FetchMonthlyMentorSession from "domain/useCase/Mentor/Session/FetchMonthlyMentorSession"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import { isEmpty } from "core/utils/misc"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { TTableData } from "core/constants/types"
import { useAuth } from "core/context/auth"

export default function MonthlySessionsViewModel() {
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const [fromDate, setFromDate] = React.useState("")
  const [toDate, setToDate] = React.useState("")
  const [sessionTableData, setSessionTableData] = React.useState<TTableData>({} as TTableData)
  const [loading, setLoading] = React.useState(false)

  const fetchMonthlyMentorSessionUseCase = new FetchMonthlyMentorSession(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )

  const handleFromDateChange = (e: any) => {
    setFromDate(e.target.value)
  }

  const handleToDateChange = (e: any) => {
    setToDate(e.target.value)
  }

  const handleFetchMonthlyMentorSession = async (e: any) => {
    e.preventDefault()

    if (isEmpty(fromDate) || isEmpty(toDate)) {
      changeToastDetails(STR_FAILURE, "Dates can't be empty")
      changeToastVisibility(true)
      return
    }

    setLoading(true)

    const response = await fetchMonthlyMentorSessionUseCase.invoke(auth, fromDate, toDate)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, "Unknown error occurred")
      changeToastVisibility(true)
      return
    }

    handleDataToTable(response?.all_session)
  }

  const handleDataToTable = (data: any) => {
    const header = ["Date", "Start", "End", "Student Id", "Mentor Id", "Room Id"]

    const rows = data?.map((s: any) => [s?.date, s?.start, s?.end, s?.student_id, s?.mentor_id, s?.room_id])

    setSessionTableData({
      header,
      rows,
    })
  }

  return {
    fromDate,
    toDate,
    sessionTableData,
    toast,
    loading,
    handleFromDateChange,
    handleToDateChange,
    handleFetchMonthlyMentorSession,
    changeToastVisibility,
  }
}
