import Button from "core/components/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/Input"
import { STR_NUMBER_FILED, STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"

export default function CreateCompany({
  selectCompany,
  onCompanyChange,
  selectValues,
  handleChange,
  createCompany,
  onChangeContactList,
  selectContactList,
  removeItem,
  contactList,
  setContactList,
  addContact,
}: any) {
  return (
    <>
      <div className="w-full p-4">
        <h1 className="mb-2 text-2xl font-bold leading-[29px] text-new-accent">Company Details</h1>
        <p className="font-medium">Add the company details here</p>
      </div>
      <form className="p-4">
        <Input
          type={STR_TEXT_FIELD}
          value={selectCompany}
          onChange={onCompanyChange}
          placeholder="Company Name"
          className="mb-4"
          required
        />
        <Input
          name="location"
          type={STR_TEXT_FIELD}
          value={selectValues.location}
          onChange={handleChange}
          placeholder="Location"
          className="mb-4"
          required
        />
        <ul className="mb-4 flex w-full list-disc flex-col overflow-auto border pl-8">
          {contactList?.map((assignment: any, index: any) => (
            <li className="inline-flex items-center gap-2 py-2">
              {assignment}
              <IconButton
                className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
                onClick={() => setContactList(removeItem(contactList, index))}
                text="X"
              >
                X
              </IconButton>
            </li>
          ))}
        </ul>
        <form
          className="flex w-full justify-between gap-4"
          // onSubmit={addContact}
        >
          <Input
            type={STR_NUMBER_FILED}
            value={selectContactList}
            onChange={onChangeContactList}
            placeholder="Contact List"
            className="mb-4"
          />

          <Button onClick={addContact} className="ml-auto h-fit w-fit">
            +
          </Button>
        </form>
        <Input
          name="companyLogo"
          type={STR_TEXT_FIELD}
          value={selectValues.companyLogo}
          onChange={handleChange}
          placeholder="Company Logo"
          className="mb-4"
          required
        />
        <Button onClick={createCompany} className="">
          Submit
        </Button>
      </form>
    </>
  )
}
