import { cn } from "core/lib/utils"
import React from "react"

type TProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  className?: string
  children?: any
  defaultOption?: string | boolean
  options?: TOption[]
}

type TOption = {
  label: string | number
  value: string | number
}

export default function Select({ className, children, defaultOption, options, value, ...props }: TProps) {
  return (
    <select
      className={cn(
        "appearance-none rounded-sm border border-new-neutral bg-new-solid-white py-2 pr-8 pl-4 text-sm leading-4 text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent",
        props.disabled ? "cursor-not-allowed" : "cursor-pointer",
        className
      )}
      {...props}
      value={value}
    >
      {defaultOption && (
        <option disabled value="">
          {typeof defaultOption === "string" ? defaultOption : "Select an option"}
        </option>
      )}
      {children ||
        options?.map((option: TOption, i: number) => (
          <option key={i} value={option?.value}>
            {option?.label}
          </option>
        ))}
    </select>
  )
}
