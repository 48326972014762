import { Auth } from "domain/model/Auth"
import { TPermanentDisableStudentReasonUpdate } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface PermanentDisableStudentReasonUpdateUseCase {
  invoke(auth: Auth, data: TPermanentDisableStudentReasonUpdate): Promise<any>
}

export default class PermanentDisableStudentReasonUpdate implements PermanentDisableStudentReasonUpdateUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: TPermanentDisableStudentReasonUpdate): Promise<any> {
    return await this.repository.permanentDisableStudentReasonUpdate(auth, data)
  }
}
