import { API_V4_ADMIN, API_V4_UPDATE_SSM_CALL } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { UpdateSSMCall } from "domain/model/UpdateSSMCall"
import UpdateSSMCallDataSource from "data/dataSource/Admin/UpdateSSMCallDataSource"
import { RequestQuote } from "@mui/icons-material"

export class UpdateSSMCallAPIDataSourceImpl implements UpdateSSMCallDataSource {
  async updateSSMCall(updateSSMCall: UpdateSSMCall): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_UPDATE_SSM_CALL), updateSSMCall)
      return response
    } catch (error) {
      return error
    }
  }
}
