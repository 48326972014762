import IconButton from "core/components/IconButton"
import { format } from "date-fns"
import { Link } from "react-router-dom"

export default function JobDashboardTable({
  tableHeaders,
  jobList,
  handleOpenModel,
  getJobDetails,
  getJobApplications,
  deleteJob,
}: any) {
  // 'Company Name',
  //   'Created / Expired Date',
  //   'Update Job',
  //   'View Candidates',
  //   'Eligible Candidate',
  //   'Status',
  //   'Delete Job',
  // ];
  return (
    <div className="relative my-4 overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
        <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
          <tr>
            {tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="px-4 py-4 text-[16px] font-medium  ">
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jobList?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4 ">
                {data.company}
                <td className=" font-medium text-new-accent"> {data.role}</td>
              </td>
              <td className="px-6 py-4 text-[14px]">
                {format(new Date(data.date_created * 1000), "dd MMMM yyyy")}
                <td>{format(new Date(data.end_date * 1000), "dd MMMM yyyy")}</td>
              </td>
              <td className="px-6 py-4 text-[14px] ">
                <IconButton
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-edit"
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  }
                  onClick={() => {
                    getJobDetails(data.job_id)
                    handleOpenModel()
                  }}
                />
              </td>
              <td className="px-6 py-4 text-[14px]">
                <Link to={`eligible-candidates/${data.job_id}`}>View</Link>
              </td>
              <td className={`px-6 py-4 text-[14px]`}>View</td>
              <td className="px-6 py-4 text-[16px]">{data.status}</td>
              <td className="px-6 py-4 text-[16px] ">
                <IconButton
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="black"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  }
                  onClick={() => {
                    deleteJob(data.job_id)
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
