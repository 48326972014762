import { User } from "domain/model/User"
import { AuthRepository } from "domain/repository/Admin/AuthRepository"
import AuthDataSource from "data/dataSource/Common/AuthDataSource"

export class AuthRepositoryImpl implements AuthRepository {
  private dataSource: AuthDataSource

  constructor(dataSource: AuthDataSource) {
    this.dataSource = dataSource
  }

  async login(user: User): Promise<any> {
    return this.dataSource.login(user)
  }

  async resetPassword(email: string): Promise<any> {
    return this.dataSource.resetPassword(email)
  }

  async signUp(user: User): Promise<any> {
    return this.dataSource.signUp(user)
  }

  async tokenRefresh(refresh_token: string): Promise<any> {
    return this.dataSource.tokenRefresh(refresh_token)
  }
}
