import { Auth } from "domain/model/Auth"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export interface CancelMentorSessionByMentorUseCase {
  invoke(auth: Auth, session_id: any, type: any, student_id: any, cancelReason: string): Promise<any>
}

export class CancelMentorSessionByMentor implements CancelMentorSessionByMentorUseCase {
  private repository: MyMenteeRepository

  constructor(repository: MyMenteeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: any, type: any, student_id: any, cancelReason: string): Promise<any> {
    return await this.repository.cancelMentorSessionByMentor(auth, session_id, type, student_id, cancelReason)
  }
}
