import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface GetContestRemainderUseCase {
  invoke(auth: Auth, type: string): Promise<any>
}

export class GetContestRemainder implements GetContestRemainderUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getContestRemainder(auth)
  }
}
