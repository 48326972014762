import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import React, { useEffect, useState } from "react"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { EndBatch } from "domain/useCase/Admin/Batch/EndBatch"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { GetAllActiveBatches } from "domain/useCase/Admin/Batch/GetAllActiveBatch"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { EndBatchViewModel } from "./EndBatchViewModel"
import Loader from "core/components/Loader"
type EndBatchViewProps = {}

export default function EndBatchView() {
  const {
    batch,
    isEnding,
    setIsEnding,
    setBatch,
    batches,
    setBatches,
    toast,
    handleChange,
    handleClick,
    getActiveBatches,
    changeToastVisibility,
  } = EndBatchViewModel()
  useEffect(() => {
    getActiveBatches()
  }, [])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">End Batch </h2>
          <p>Choose a batch where the course has been completed.</p>
        </div>
        <div className="round-2 mx-auto flex w-full max-w-[600px] flex-col gap-12 p-4 shadow">
          <StandaredSelect placeholder="Select Batch" options={batches} onChange={handleChange} value={batch} />
          <Button disabled={isEnding} onClick={handleClick}>
            {isEnding ? <Loader height="48px" /> : "End Batch"}
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
