import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface LikeQuestionUseCase {
  invoke(auth: Auth, topic: string, like: boolean): Promise<any>
}

export class LikeQuestion implements LikeQuestionUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, topic: string, like: boolean) {
    return this.repository.likeQuestion(auth, topic, like)
  }
}
