import React from "react"
import MentorCalendar from "./MentorCalendar"
import MentorshipCard from "./MentorshipCard"
import OldMentorshipCard from "./OldMentorshipCard"

import { formatDate } from "core/utils/date"
import {
  STR_MENTOR_NOT_AVAILABLE,
  STR_SCHEDULE_MENTORSHIP,
  STR_SCHEDULE_MENTORSHIP_DESCRIPTION,
} from "core/constants/strings"
import { useApp } from "core/context/app"
import NewMentorCalendar from "./NewMentorCalendar"

const header = ["S", "M", "T", "W", "Th", "F", "Sa"]
export default function MentorSchedule({ availability, mentor, onBooking }: any) {
  const [daysList, setDaysList] = React.useState<any>([])
  const [activeDate, setActiveDate] = React.useState(new Date())
  const [lastMonth, setLastMonth] = React.useState<any>({
    lastDate: new Date(activeDate.getFullYear(), activeDate.getMonth(), 0),
  })
  const [activeMonth, setActiveMonth] = React.useState<any>({
    firstDate: new Date(activeDate.getFullYear(), activeDate.getMonth(), 1),
    lastDate: new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0),
  })
  const [nextMonth, setNextMonth] = React.useState<any>({
    firstDate: new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1),
  })
  const [mentorSessions, setMentorSessions] = React.useState<any>([])
  const { student } = useApp()

  function getAllDaysInCalendar() {
    let days: any[] = []
    for (let i = activeMonth.firstDate.getDay() - 1; i >= 0; i--) {
      days = [
        ...days,
        {
          date: formatDate(
            new Date(
              lastMonth.lastDate.getFullYear(),
              lastMonth.lastDate.getMonth(),
              lastMonth.lastDate.getDate() - i + 1
            )
          ),
        },
      ]
    }
    for (let i = 1; i <= activeMonth.lastDate.getDate(); i++) {
      const formattedDate = formatDate(
        new Date(
          activeMonth.firstDate.getFullYear(),
          activeMonth.firstDate.getMonth(),
          activeMonth.firstDate.getDate() + i
        )
      )
      days = [
        ...days,
        {
          date: formattedDate,
          isActiveDate: formattedDate === formatDate(new Date(activeDate)),
          isCurrentMonth: true,
          sessions: [],
        },
      ]
      if (formattedDate === formatDate(new Date(activeDate))) handleMentorSessions(formattedDate)
    }
    for (let i = 0; i < 6 - activeMonth.lastDate.getDay(); i++) {
      days = [
        ...days,
        {
          date: formatDate(
            new Date(
              nextMonth.firstDate.getFullYear(),
              nextMonth.firstDate.getMonth(),
              nextMonth.firstDate.getDate() + i + 1
            )
          ),
        },
      ]
    }
    for (let i = 0; i < availability.length; i++) {
      if (new Date(availability[i].date).getMonth() !== activeDate.getMonth()) {
        continue
      }
      const index = days.findIndex((day: any) => day.date === formatDate(new Date(availability[i].date)))
      if (index !== -1) {
        days[index] = {
          ...days[index],
          sessions: [
            ...days[index].sessions,
            {
              start_timestamp: availability[i].start_timestamp * 1000,
              end_timestamp: availability[i].end_timestamp * 1000,
              date: availability[i].date,
              start: availability[i].start,
              end: availability[i].end,
            },
          ],
        }
      }
    }
    setDaysList(days)
    if (availability.length === 0) setMentorSessions([])
  }

  function handleMentorSessions(date: string) {
    const index = daysList.findIndex((day: any) => day.date === date)
    const sessions = daysList[index]?.sessions
    setMentorSessions(sessions)
    setDaysList((daysList: any) =>
      daysList.map((day: any) => {
        return day.date === date ? { ...day, isActiveDate: true } : { ...day, isActiveDate: false }
      })
    )
  }

  function handlePrevMonth() {
    const updatedDate = new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1)
    handleSetMonths(updatedDate)
  }

  function handleNextMonth() {
    const updatedDate = new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1)
    handleSetMonths(updatedDate)
  }

  function handleSetMonths(updatedDate: Date) {
    setActiveDate(updatedDate)
    setLastMonth({
      lastDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth(), 0),
    })
    setActiveMonth({
      firstDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth(), 1),
      lastDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0),
    })
    setNextMonth({
      firstDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 1),
    })
  }

  React.useEffect(() => {
    getAllDaysInCalendar()
  }, [activeDate, availability])

  return (
    <div className={`relative space-y-6 z-0`}>
      {student.is_batch_paused === true && (
        <div className="absolute bottom-1/2 w-full text-center z-10 animate-bounce font-semibold text-red-700">
          Please Resume Your Course to book a Mentor Session
        </div>
      )}
      <div className={`${student.is_batch_paused === true && "opacity-20"} `}>
        <div>
          <div className="flex gap-4">
            <h2 className="text-new-accent">{STR_SCHEDULE_MENTORSHIP}</h2>
            {student?.new_updates?.my_mentor < 3 && (
              <div className="bg-[#FBBF24] font-semibold text-[14px] my-[2px] text-white px-6 py-1 rounded-[45px] text-center">
                New Feature Available
              </div>
            )}
          </div>
          <p className="text-new-neutral-dark">{STR_SCHEDULE_MENTORSHIP_DESCRIPTION}</p>
        </div>
      </div>
      <div className={`p-8 shadow ${student.is_batch_paused === true && "opacity-20"}`}>
        <div className="flex w-full gap-8">
          <MentorCalendar
            header={header}
            days={daysList}
            activeDate={activeDate}
            onClick={handleMentorSessions}
            onPrevMonth={handlePrevMonth}
            onNextMonth={handleNextMonth}
            disabled={availability?.length === 0 || student.is_batch_paused === true}
          />

          <div className="flex max-h-[336px] w-full flex-col gap-4 overflow-y-auto">
            {mentorSessions?.length > 0 ? (
              mentorSessions?.map((session: any, i: number) => (
                <OldMentorshipCard key={i} session={session} mentor={mentor} onBooking={onBooking} />
              ))
            ) : (
              <p className="m-auto w-[230px] text-center">{STR_MENTOR_NOT_AVAILABLE}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
