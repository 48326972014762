import React from "react"

export default function LinkedInIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.53516 4.05049C6.06582 4.20342 5.7916 4.3669 5.44883 4.70967C4.9373 5.2212 4.68945 5.81709 4.68945 6.53428C4.68945 7.59424 5.25371 8.496 6.20293 8.94424C6.57734 9.12354 6.66172 9.14463 7.15215 9.16045C7.81133 9.18682 8.2543 9.0708 8.74473 8.74385C9.15605 8.46963 9.40391 8.17959 9.62539 7.7208C10.0104 6.92452 9.9998 6.12295 9.6043 5.32139C9.43027 4.96807 8.92402 4.45127 8.56016 4.25616C7.99063 3.9503 7.12051 3.86065 6.53516 4.05049Z"
        fill="currentColor"
      />
      <path
        d="M20.3779 10.7836C18.7168 11.0209 17.5461 11.6959 16.8236 12.8402L16.6602 13.0934V12.1072V11.1211H14.498H12.3359V18.3457V25.5703H14.6035H16.8658L16.8816 21.3094C16.9027 17.1381 16.9027 17.0432 17.0135 16.6477C17.3457 15.477 17.9891 14.8863 19.0754 14.7703C20.4834 14.6121 21.3271 15.1922 21.6541 16.5369C21.7332 16.8533 21.7437 17.407 21.7596 21.2303L21.7807 25.5703H24.0482H26.3158L26.2947 20.6502C26.2736 16.3893 26.2631 15.6668 26.184 15.2396C25.8992 13.5996 25.3561 12.4922 24.4965 11.7961C23.9322 11.3426 23.2467 11.0473 22.3713 10.8891C21.9336 10.8047 20.6732 10.7414 20.3779 10.7836Z"
        fill="currentColor"
      />
      <path d="M5.00586 18.3457V25.5703H7.27344H9.54102V18.3457V11.1211H7.27344H5.00586V18.3457Z" fill="currentColor" />
    </svg>
  )
}
