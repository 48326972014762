import { ChevronDownIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import React from "react"

export default function FaqDisclosure({ label, children, shadow }: any) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div className={cn("space-y-2 rounded px-8 py-4", shadow && "shadow-[0px_0px_7px_0px_rgba(158,158,158,0.23)]")}>
        <div className="flex cursor-pointer items-center justify-between gap-4" onClick={() => setOpen(!open)}>
          <h4 className={open ? "text-new-accent-shallow" : "text-new-accent"}>{label}</h4>
          <span className="text-new-accent-dark">
            <ChevronDownIcon className={cn("h-4 w-4", open && "rotate-180")} />
          </span>
        </div>
        {open && <div className="flex-1 border-b border-dashed border-new-neutral-light" />}
        <p className={cn("w-full text-new-accent-dark", !open && "hidden")}>{children}</p>
      </div>
    </>
  )
}
