import { Auth } from "domain/model/Auth"
import { ReferAndEarnRepository } from "domain/repository/Student/ReferAndEarnRepository"

export interface deleteReferralFormDataUseCase {
  invoke(email: string, auth: Auth): Promise<any>
}
export class DeleteReferralFormData implements deleteReferralFormDataUseCase {
  private repository: ReferAndEarnRepository

  constructor(repository: ReferAndEarnRepository) {
    this.repository = repository
  }

  async invoke(email: string, auth: Auth): Promise<any> {
    return await this.repository.deleteReferralFormData(email, auth)
  }
}
