import { companiesList } from "domain/model/Placement"
import { AddPlacementRepository } from "domain/repository/Admin/AddPlacementRepository"

export interface GetCompaniesUseCase {
  invoke(request: companiesList): Promise<any>
}

export class GetCompanies implements GetCompaniesUseCase {
  private repository: AddPlacementRepository

  constructor(repository: AddPlacementRepository) {
    this.repository = repository
  }
  async invoke(request: companiesList): Promise<any> {
    return await this.repository.getCompaniesList(request)
  }
}
