import { cn } from "core/lib/utils"

export default function DocumentPopup({ index, handleChangeFile, link, isLast }: any) {
  return (
    <div
      className={cn(
        "flex flex-col items-center absolute w-[145px] right-10 z-20 bg-white p-6 gap-4 text-[#6B6B6B] font-semibold leading-normal shadow-[0_3px_7px_0_rgba(0,0,0,0.10)]",
        isLast ? "bottom-[calc(50%+16px)]" : "top-[calc(50%+16px)]"
      )}
    >
      <label className="cursor-pointer">
        Upload Doc
        <input type="file" className="hidden" accept="application/pdf" onChange={(e) => handleChangeFile(e, index)} />
      </label>
      {link !== "" ? (
        <a href={link} download target="_blank">
          Download
        </a>
      ) : (
        <div className="cursor-not-allowed text-gray-400">Download</div>
      )}
    </div>
  )
}
