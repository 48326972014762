import Button from "core/components/new/Button"
import { useEffect, useState } from "react"
import useViewModel from "./SubCaseQuestionViewModel"
import Toast from "core/components/Toast"
import ButtonGroup from "core/components/ButtonGroup"
import Question from "../Components/Question"
import Solution from "../Components/Solution"
import QuestionView from "../Components/QuestionView"
import Response from "../Components/Response"
import { isEmpty } from "core/utils/misc"

export default function SubjectiveQuestionView() {
  const {
    TABS,
    allCourses,
    allModules,
    allTopics,
    GoBack,
    loading,
    toast,
    changeToastVisibility,
    questionFields,
    solutionFields,
    questionDetails,
    questionResponse,
    questionResponseFields,
    handleQuestionFields,
    handleAddSolutionFields,
    handleRemoveSolutionFields,
    handleQuestionFieldChange,
    handleQuestionDetails,
    addQuestionResponse,
    removeQuestionResponse,
    handleQuestionResponse,
    addQuestionResponseFields,
    removeQuestionResponseFields,
    handleQuestionResponseFieldsChange,
    questionResponseOptions,
    addQuestionResponseOptions,
    removeQuestionResponseOptions,
    addQuestionResponseOptionField,
    removeQuestionResponseOptionField,
    handleQuestionResponseOptionField,
    subjectiveSolution,
    caseStudySolution,
    handleSubjectiveQuestionSolution,
    handleSolutionFieldChange,
    handleCaseStudyQuestionSolution,
    handleQuestionSaveChanges,
  } = useViewModel()

  const [activeTab, setActiveTab] = useState<number>(0)
  const [activeResponseTab, setActiveResponseTab] = useState<number>(0)

  useEffect(() => {
    /**
     * Controlling the tabs
     */
    if (document !== undefined) {
      const responseButton: HTMLButtonElement | null = document.getElementById("Response") as HTMLButtonElement
      const solutionButton: HTMLButtonElement | null = document.getElementById("Solution") as HTMLButtonElement

      if (isEmpty(questionDetails)) {
        responseButton.disabled = true
        responseButton.classList.add("opacity-25")
        solutionButton.disabled = true
        solutionButton.classList.add("opacity-25")
      }

      if (questionDetails?.type === "Subjective") {
        responseButton.disabled = true
        responseButton.classList.add("opacity-25")
        solutionButton.disabled = false
        solutionButton.classList.remove("opacity-25")
      }

      if (questionDetails?.type === "Case Study") {
        responseButton.disabled = false
        responseButton.classList.remove("opacity-25")
        solutionButton.disabled = false
        solutionButton.classList.remove("opacity-25")
      }
    }
  }, [questionDetails])

  return (
    <div className="flex w-full h-screen">
      <div className="h-screen flex-1 overflow-y-auto p-6">
        <div className="flex p-6 flex-col items-start gap-4">
          <div className="w-full flex justify-between">
            <div className="w-fit">
              <h2 className="text-new-accent">Question Bank</h2>
            </div>
            <button
              onClick={() => GoBack()}
              className="inline-flex py-2 px-4 items-start gap-2.5 border-solid border text-red-500 font-medium border-red-500 rounded-sm"
            >
              Go Back
            </button>
          </div>
          <div className="w-full">
            <ButtonGroup buttons={TABS} active={activeTab} onChange={setActiveTab} />
          </div>
          {activeTab === 0 ? (
            <Question
              allCourses={allCourses}
              allModules={allModules}
              allTopics={allTopics}
              loading={loading}
              questionFields={questionFields}
              questionDetails={questionDetails}
              handleQuestionFields={handleQuestionFields}
              handleQuestionFieldChange={handleQuestionFieldChange}
              handleQuestionDetails={handleQuestionDetails}
            />
          ) : activeTab === 1 ? (
            <Response
              questionResponse={questionResponse}
              addQuestionResponse={addQuestionResponse}
              removeQuestionResponse={removeQuestionResponse}
              handleQuestionResponse={handleQuestionResponse}
              questionResponseFields={questionResponseFields}
              addQuestionResponseFields={addQuestionResponseFields}
              removeQuestionResponseFields={removeQuestionResponseFields}
              handleQuestionResponseFieldsChange={handleQuestionResponseFieldsChange}
              questionResponseOptions={questionResponseOptions}
              addQuestionResponseOptions={addQuestionResponseOptions}
              removeQuestionResponseOptions={removeQuestionResponseOptions}
              addQuestionResponseOptionField={addQuestionResponseOptionField}
              removeQuestionResponseOptionField={removeQuestionResponseOptionField}
              handleQuestionResponseOptionField={handleQuestionResponseOptionField}
              activeResponseTab={activeResponseTab}
              setActiveResponseTab={setActiveResponseTab}
            />
          ) : (
            <Solution
              questionDetails={questionDetails}
              questionResponse={questionResponse}
              questionResponseOptions={questionResponseOptions}
              subjectiveSolution={subjectiveSolution}
              caseStudySolution={caseStudySolution}
              handleSubjectiveQuestionSolution={handleSubjectiveQuestionSolution}
              handleCaseStudyQuestionSolution={handleCaseStudyQuestionSolution}
              handleSolutionFieldChange={handleSolutionFieldChange}
              handleAddSolutionFields={handleAddSolutionFields}
              handleRemoveSolutionFields={handleRemoveSolutionFields}
              solutionFields={solutionFields}
            />
          )}
          <div className="flex p-4 content-center items-center gap-14 bg-[#F5F5F5] rounded-sm">
            <div className="basis-5/12">
              <h3>Save Current Changes :</h3>
            </div>
            <div className="flex justify-between gap-4">
              <Button className="basis-6/12 py-4 px-8" outlined failure onClick={() => GoBack()}>
                Discard Changes
              </Button>
              <Button
                className="basis-6/12"
                onClick={async () => {
                  await handleQuestionSaveChanges()
                  setActiveTab(0)
                }}
                loading={loading}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-screen flex-1 overflow-y-auto bg-new-editor-dark-700">
        <QuestionView
          questionDetails={questionDetails}
          questionFields={questionFields}
          activeResponseTab={activeResponseTab}
          questionResponse={questionResponse}
          questionResponseFields={questionResponseFields}
          questionResponseOptions={questionResponseOptions}
        />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
