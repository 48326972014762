import { UpdateWalkThroughDataSource } from "data/dataSource/Admin/UpdateWalkThroughDataSource"
import { Auth } from "domain/model/Auth"
import { UpdateWalkThroughRepository } from "domain/repository/Admin/UpdateWalkThrough"

export class UpdateWalkThroughRepositoryImpl implements UpdateWalkThroughRepository {
  private Datasource: UpdateWalkThroughDataSource

  constructor(Datasource: UpdateWalkThroughDataSource) {
    this.Datasource = Datasource
  }

  async updateWalkThroughDate(auth: Auth, student_id: string, new_walkthrough_date: string) {
    return await this.Datasource.updateWalkThroughDate(auth, student_id, new_walkthrough_date)
  }
}
