import { STR_UNKNOWN_ERROR_OCCURRED } from "core/constants/strings"

export function capitalize(s: string): string {
  if (typeof s !== "string") {
    return ""
  }

  const modified = s.replace(/\b\w/g, (match) => match.toUpperCase())
  return modified.split(" ").filter(Boolean).join(" ")
}

export function genError(response: any, message = STR_UNKNOWN_ERROR_OCCURRED) {
  if (response) {
    return message
  }
  if ("error" in response) {
    if ("message" in response.error) {
      return response.error.message
    }
    return response.error
  }

  if ("data" in response && typeof response.data === "string") {
    return response.data
  }

  return message
}

export function parseTexts(inputString: string, delimiter = ",") {
  const textsArray = inputString
    .split(delimiter)
    .map((t) => t.trim())
    .filter(Boolean)
  return textsArray
}
