import { useEffect, useState } from "react"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import { PaymentAPIDataSourceImpl } from "data/API/Student/PaymentAPIDataSourceImpl"
import { PaymentRepositoryImpl } from "data/repository/Student/PaymentRepositoryImpl"
import { GetFeeAfterDiscount } from "domain/useCase/Student/Payment/GetFeeAfterDiscount"
// import { GetPaymentMethod } from "domain/useCase/Student/Payment/GetPaymentMethod";
import React from "react"
import { GetMethodDiscount } from "domain/useCase/Student/Payment/GetMethodDiscount"
import { FetchScratchCardDetails } from "domain/useCase/Student/Payment/FetchScratchCardDetails"

export default function PaymentMethodsViewModel() {
  const { auth, refreshed } = useAuth()
  const { student } = useApp()

  const [scratchCardDetails, setScratchCardDetails] = React.useState<any>({})
  const [loading, setLoading] = React.useState<any>(false)

  const [paymentDiscount, setPaymentDiscount] = React.useState<any>({})
  const [paymentMethod, setPaymentMethod] = React.useState<any>({})
  const [paidOrNot, setPaid] = React.useState<any>({})
  const [payableMonth, setPayableMonth] = useState("")

  const [isDSML, setIsDSML] = useState(true)
  const [isEV, setIsEV] = useState(false)
  const [isAccelerator, setIsAccelerator] = useState(false)
  const [isAC2, setIsAC2] = React.useState(false)
  const [isAC, setIsAC] = React.useState(false)

  // const [paymentDiscount, setPaymentDiscount] = useState<any>({});

  const getMethodDiscountUseCase = new GetMethodDiscount(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  const fetchScratchCardDetailsUseCase = new FetchScratchCardDetails(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )

  const fetchPaymentDetails = async () => {
    const response = await getMethodDiscountUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
  }

  const fetchScratchCardDetails = async () => {
    setLoading(true)
    const response = await fetchScratchCardDetailsUseCase.invoke(auth)
    if (!response?.success) {
      setLoading(false)

      return
    }
    setLoading(false)
    setScratchCardDetails(response?.data)
    // setPaymentDiscount(response?.data)
  }

  function parseDate(inputString: any) {
    if (!inputString) return ""
    const year = inputString?.substring(0, 4)
    const month = inputString?.substring(4, 6)
    const dateObject = new Date(`${year}-${month}-01`)
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
    }).format(dateObject)
    return formattedDate
  }
  return {
    refreshed,
    student,
    paidOrNot,
    paymentMethod,
    paymentDiscount,
    isEV,
    isDSML,
    isAccelerator,
    payableMonth,
    isAC2,
    isAC,
    scratchCardDetails,
    loading,
    setIsAC2,
    setIsAC,
    setIsAccelerator,
    setIsEV,
    setIsDSML,
    setPayableMonth,
    fetchPaymentDetails,
    parseDate,
    fetchScratchCardDetails,
    setScratchCardDetails,
  }
}
