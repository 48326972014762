import React from "react"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { CreateBatches } from "domain/useCase/Admin/Batch/CreateBatch"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { GetAllMentorshipTemplates } from "domain/useCase/Admin/Batch/GetAllMentorshipTemplates"

export default function CreateBatchViewModel() {
  const [_selectTrack, _setSelectTrack] = React.useState<any>([])
  const [_currentSelectTrack, _setCurrentSelectTrack] = React.useState<any>([])
  const [_selectModule, _setSelectModule] = React.useState<any>([])
  const [_currentSelectModule, _setCurrentSelectModule] = React.useState<any>([])
  const [_batchName, _setBatchName] = React.useState("")
  const [_offerDiscount, _setOfferDiscount] = React.useState("")
  const [_oneShotDiscount, _setOneShotDiscount] = React.useState("")
  const [_meetingId, _setMeetingId] = React.useState("82906613084")
  const [_batchStartDate, _setBatchStartDate] = React.useState<any>("")
  const [_payable_start_date, _setpayable_start_date] = React.useState<any>("")
  const [_googleGroupEmail, _setGoogleGroupEmail] = React.useState<any>("manish.garg@bosscoderacademy.com")
  const [_modules, _setModules] = React.useState<any>({})
  const [_showModules, _setShowModules] = React.useState<any>([])
  const [allMentorshipTemplates, setAllMentorshipTemplates] = React.useState<any>([])
  const [selectedMentorshiptemplate, setSelectedMentorshipTemplate] = React.useState("")

  const [track, setTrack] = React.useState<string[]>([])

  React.useState("")
  const [loading, setLoading] = React.useState(false)

  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  function handleBatchName(e: any) {
    _setBatchName(e.target.value)
  }
  function handleOneShotDiscount(e: any) {
    const input = e.target.value
    const discount = parseInt(input)
    if (isNaN(discount) || (discount >= 0 && discount <= 100)) {
      _setOneShotDiscount(e.target.value)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Invalid format!")
    }
  }
  function handleOfferDiscount(e: any) {
    const input = e.target.value
    const discount = parseInt(input)
    if (isNaN(discount) || (discount >= 0 && discount <= 100)) {
      _setOfferDiscount(e.target.value)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Invalid format!")
    }
  }
  function handleMeetingId(e: any) {
    _setMeetingId(e.target.value)
  }
  function handleGoogleGroupEmail(e: any) {
    _setGoogleGroupEmail(e.target.value)
  }

  function handleTrackChange(e: any) {
    const selectedTrack = e.target.value
    if (!_selectTrack.includes(selectedTrack)) {
      _setSelectTrack((prevSelectTrack: any) => [...prevSelectTrack, selectedTrack])
    }
    _setCurrentSelectTrack(selectedTrack)
  }

  function handleModuleChange(e: any) {
    const selectedModule = e.target.value
    if (!_selectModule.includes(selectedModule)) {
      _setSelectModule((prevSelectModule: any) => [...prevSelectModule, selectedModule])
    }
    _setCurrentSelectModule(selectedModule)
  }

  function handleBatchStartDate(e: any) {
    _setBatchStartDate(e.target.value)
  }
  function handleMentorshipTemplateChange(e: any) {
    setSelectedMentorshipTemplate(e.target.value)
  }
  function handlePayable_start_date(e: any) {
    _setpayable_start_date(e.target.value)
  }

  const getAllTracksUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const createBatchUseCase = new CreateBatches(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))

  const getAllMentorshiptemplatesUseCase = new GetAllMentorshipTemplates(
    new BatchRepositoryImpl(new BatchAPIDataSourceImpl())
  )

  const getModuleBasedClassUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  async function getAllTracks() {
    const response = await getAllTracksUseCase.invoke(auth)

    if (response.success) {
      setTrack(response.data)
      response.data.map((tr: any) => {
        _getModuleBasedTrack(tr)
      })
    }
  }

  async function getAllMentorshipTemplates() {
    const response = await getAllMentorshiptemplatesUseCase.invoke(auth)

    if (response?.success) {
      setAllMentorshipTemplates(response?.data)
    }
  }

  async function _getModuleBasedTrack(track: string) {
    const response = await getModuleBasedClassUseCase.invoke(auth, track)
    if (response.success) {
      _setModules((prevModule: any) => ({
        ...prevModule,
        [track]: [response.data.modules],
      }))
    }
  }
  function removeHyphens(inputString: any) {
    return inputString.replace(/-/g, "")
  }
  async function createBatches() {
    setLoading(true)
    const response = await createBatchUseCase.invoke({
      id_token: auth.id_token.trim(),
      batch_name: _batchName.trim(),
      start_date: removeHyphens(_batchStartDate),
      meeting_id: _meetingId.trim(),
      modules: _selectModule,
      tracks: _selectTrack,
      google_group_email: _googleGroupEmail.trim(),
      payable_start_date: removeHyphens(_payable_start_date),
      one_shot_payment_discount: _oneShotDiscount,
      offer_discount_in_percent: _offerDiscount,
      mentorship_template_to_inherit: selectedMentorshiptemplate,
    })

    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Batch created successfully!")
  }

  return {
    _selectTrack,
    _currentSelectTrack,
    _batchName,
    _oneShotDiscount,
    _offerDiscount,
    _meetingId,
    _batchStartDate,
    _modules,
    _showModules,
    _currentSelectModule,
    _selectModule,
    _googleGroupEmail,
    _payable_start_date,
    toast,
    track,
    loading,
    refreshed,
    allMentorshipTemplates,
    selectedMentorshiptemplate,
    setSelectedMentorshipTemplate,
    handleMentorshipTemplateChange,
    getAllMentorshipTemplates,
    _setSelectTrack,
    _setShowModules,
    _setSelectModule,
    handleModuleChange,
    handleBatchName,
    handleGoogleGroupEmail,
    handlePayable_start_date,
    handleMeetingId,
    handleBatchStartDate,
    handleOfferDiscount,
    handleOneShotDiscount,
    _setCurrentSelectModule,
    _setCurrentSelectTrack,
    handleTrackChange,
    changeToastVisibility,
    getAllTracks,
    createBatches,
  }
}
