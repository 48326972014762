import CoinCard from "./CoinCard"
import { Headings } from "./Headings"
import BulletPoint from "./BulletPoint"
import RightPanelPicture from "assets/svgs/v2/smart-people"
import OfferAd from "../../../presentation/Student/Payment/component/OfferAd"
import { CustomScroll } from "react-custom-scroll"

export default function RightPanel({ rightBar, student }: any) {
  const streakMetric = student?.metrics?.find((metric: any) => metric.title === "All Streak")
  const currentStreak = streakMetric ? streakMetric.current : 0
  const getOfferEligibility = () => {
    if (!student?.is_payment_done && student?.walk_through_date) {
      const walk_through_date = student?.walk_through_date

      const walk_through_date_in_millis = walk_through_date * 1000

      const current_date = new Date()

      const future_date = walk_through_date_in_millis + 2 * 24 * 60 * 60 * 1000

      if (current_date.getTime() > future_date) {
        return false
      }
      return true
    }
  }

  return rightBar ? (
    <div className="flex h-full min-w-[188px] max-w-[284px] flex-col justify-between overflow-y-auto rounded-lg border border-[#DEDEDE]">
      <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
        <div className="border-b p-6 text-center">
          <Headings variant="h4" fontStyle={"regular"} className="flex w-full justify-center text-center font-[600]">
            {rightBar.name}
          </Headings>
          <div className="mt-2 rounded-md border p-2 py-1">
            <Headings
              variant={"h6"}
              fontStyle="regular"
              className="flex w-full justify-center text-center font-normal text-[#646464]"
            >
              Batch : {rightBar.currentBatch}
            </Headings>
          </div>
        </div>

        <div className="flex items-center justify-center border-b py-6 text-center">
          <CoinCard coins={student?.points} />
        </div>

        <div className="p-6 2xl:px-10">
          {!student?.batch?.includes("Accelerator") ? (
            <div className="flex flex-col gap-6 rounded-[8px] bg-[#EEF8FD] px-4 py-6">
              <div className="flex items-start gap-[10px]">
                <div className="pt-1">
                  <BulletPoint />
                </div>
                <div className="text-left">
                  <Headings variant="h4" fontStyle="semiBold" className="font-[600] text-[#162456]">
                    {rightBar?.class}
                  </Headings>
                  <div className="text-[10px] text-[#162456]">Classes Attended</div>
                </div>
              </div>
              {!student?.batch?.includes("DSML") && (
                <div className="flex items-start gap-[10px]">
                  <div className="pt-1">
                    <BulletPoint />
                  </div>
                  <div className="text-left">
                    <Headings variant="h4" fontStyle="semiBold" className="font-[600] text-[#162456]">
                      {rightBar?.Problems}
                    </Headings>
                    <div className="text-[10px] text-[#162456]">Problems solved</div>
                  </div>
                </div>
              )}
              {!student?.batch?.includes("KCE") && (
                <div className="flex items-start gap-[10px]">
                  <div className="pt-1">
                    <BulletPoint />
                  </div>
                  <div className="text-left">
                    <Headings variant="h4" fontStyle="semiBold" className="font-[600] text-[#162456]">
                      {rightBar?.mentor}
                    </Headings>
                    <div className="text-[10px] text-[#162456]">Mentor sessions taken</div>
                  </div>
                </div>
              )}
              {!student?.batch?.includes("KCE") && !student?.batch?.includes("DSML") && (
                <div className="flex items-start gap-[10px]">
                  <div className="pt-1">
                    <BulletPoint />
                  </div>
                  <div className="text-left">
                    <Headings variant="h4" fontStyle="semiBold" className="font-[600] text-[#162456]">
                      {currentStreak}
                    </Headings>
                    <div className="text-[10px] text-[#162456]">Streak</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex h-[200px] w-[170px] flex-col gap-6 rounded-[8px] bg-[#EEF8FD] px-4 py-6" />
          )}
        </div>

        <div className="mt-auto w-full px-[16px] flex flex-col gap-5 mb-[20px]">
          {!student?.is_payment_done && student?.walk_through_date && getOfferEligibility() ? (
            <OfferAd img_size={150} height={190} width={155} cut_gap={7} transparent_color={"bg-white"} />
          ) : (
            <RightPanelPicture />
          )}
        </div>
      </CustomScroll>
    </div>
  ) : (
    <div className="w-[15%] rounded-lg border-2 border-gray-50 p-[16px]"></div>
  )
}
