import { Auth } from "domain/model/Auth"
import { UpdateBatchRepository } from "domain/repository/Admin/UpdateBatchRepository"

export interface ShiftBatchUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class ShiftBatch implements ShiftBatchUseCase {
  private repository: UpdateBatchRepository

  constructor(repository: UpdateBatchRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.shiftBatch(auth, data)
  }
}
