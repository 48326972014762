import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Student/StoreRepository"

export interface OrderStatusUseCase {
  invoke(auth: Auth, order_id: string): Promise<any>
}

export class OrderStatus implements OrderStatusUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, order_id: any) {
    return this.repository.orderStatus(auth, order_id)
  }
}
