import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface SetPlacementReadyUseCase {
  invoke(auth: Auth, placement_details: any): Promise<any>
}

export class SetPlacementReady implements SetPlacementReadyUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, placement_details: any): Promise<any> {
    return await this.repository.setPlacementReady(auth, placement_details)
  }
}
