import { cn } from "core/lib/utils"

export default function EdgeCasePage(props: any) {
  return (
    <div className="custom-shadow border-1 flex h-[482px] w-full border">
      <div className="m-auto flex h-[108px] w-[550px] flex-col gap-y-4">
        <div className="m-auto text-2xl font-bold text-[#312E81]">{props.h1}</div>
        <div className="flex flex-col gap-y-1">
          <div className="m-auto text-sm font-semibold text-[#DC2626]">{props.h2}</div>
          <div className={cn("m-auto text-sm font-medium", props.className)}>{props.h3}</div>
        </div>
      </div>
    </div>
  )
}
