import React, { useEffect, useState } from "react"
import { CrossIcon } from "core/constants/svgs"
import Progress from "./Progress"
import { cn } from "core/lib/utils"
import Button from "./Button"
import { isEmpty } from "core/utils/misc"

export default function RunCodeDetails({ data, running, onClose, onErrorInfo, language }: any) {
  const [activeTestCaseIndex, setActiveTestCaseIndex] = useState<any>(0)
  const [progress, setProgress] = useState(0)
  const [errorInCode, setErrorInCode] = useState(false)
  const activeTestCase = data?.stdout[activeTestCaseIndex]
  const handleTestCaseChange = (index: number) => setActiveTestCaseIndex(index)

  useEffect(() => {
    if (running) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval)
            return prev
          }
          return prev + 2
        })
      }, 100)
      return () => clearInterval(interval)
    } else {
      setProgress(0)
    }
  }, [running])

  useEffect(() => {
    setErrorInCode(data?.stderr !== "" && data?.stdoud?.length === 0)
  }, [data])

  const getErrorLineInfo = (stderrInput: string | string[]) => {
    const fileLineRegexPy = /File "([^"]+)", line (\d+),?/
    const fileLineRegexJs = /at [\w.]+ \((\/box\/codefile\.js):(\d+):\d+\)/
    const fileLineRegexCpp = /\/box\/codefile\.(cpp|h):(\d+):\d+/
    const fileLineRegexHeader = /codefile\.h:(\d+):\d+/
    const fileLineRegexJava = /\.\/codefile\.java:(\d+):\s+error/

    let errorInfo = null
    const stderrArray = Array.isArray(stderrInput) ? stderrInput : stderrInput.split("\n")

    for (let i = 0; i < stderrArray.length; i++) {
      const line = stderrArray[i]
      let match = line.match(fileLineRegexPy)
      if (match && match[1].includes("codefile.py")) {
        errorInfo = { filename: match[1], lineNumber: parseInt(match[2], 10) }
        break
      }
      match = line.match(fileLineRegexJs)
      if (match && match[1].includes("codefile.js")) {
        errorInfo = { filename: match[1], lineNumber: parseInt(match[2], 10) }
        break
      }
      match = line.match(fileLineRegexCpp)
      if (match && (match[0].includes("codefile.cpp") || match[0].includes("codefile.h"))) {
        errorInfo = {
          filename: match[0].includes("codefile.cpp") ? "codefile.cpp" : "codefile.h",
          lineNumber: parseInt(match[2], 10),
        }
        break
      }
      match = line.match(fileLineRegexHeader)
      if (match) {
        errorInfo = { filename: "codefile.h", lineNumber: parseInt(match[1], 10) }
        break
      }
      match = line.match(fileLineRegexJava)
      if (match) {
        errorInfo = { filename: "codefile.java", lineNumber: parseInt(match[1], 10) }
        break
      }
    }

    return errorInfo
  }

  const errorInfo = getErrorLineInfo(data?.stderr || data?.compile_output)

  useEffect(() => {
    if (errorInfo && onErrorInfo) {
      onErrorInfo(errorInfo)
    }
  }, [errorInfo, onErrorInfo])

  return (
    <div className="z-20 relative flex w-full">
      <div className="absolute bottom-0 w-[inherit] space-y-4 border-t border-new-neutral-light bg-new-editor-dark-700 py-4 px-6 text-new-solid-white">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <h4>{running ? "Run Result : Processing" : "Result"}</h4>
            {running && <Progress fg="bg-new-solid-white" bg="bg-new-editor-dark-400" progress={progress} />}
          </div>
          <button onClick={onClose}>
            <CrossIcon className="h-6 w-6" />
          </button>
        </div>
        {!running && (
          <div className="max-h-[220px] space-y-4 overflow-y-auto rounded-sm border border-new-neutral-dark bg-new-editor-dark-600 p-4">
            {!errorInCode && data?.stdout?.length > 0 && (
              <div className="flex flex-wrap items-center gap-4">
                {data?.stdout?.map((item: any, i: number) => (
                  <Button
                    key={i}
                    className={cn(
                      "w-[120px] border border-new-editor-dark-400",
                      item?.output !== item?.expected_output ? "text-new-failure" : "text-new-success",
                      i === activeTestCaseIndex && "bg-new-editor-dark-500"
                    )}
                    onClick={() => handleTestCaseChange(i)}
                  >
                    Test Case {i + 1}
                  </Button>
                ))}
              </div>
            )}
            {!errorInCode && typeof activeTestCase?.input === "string" && (
              <div className="space-y-1">
                <h4>Input</h4>
                <pre className="border border-new-neutral-dark py-2 px-4 font-sans">{activeTestCase?.input}</pre>
              </div>
            )}
            {!errorInCode && !isEmpty(activeTestCase?.debug) && (
              <div className="space-y-1">
                <h4>Debug</h4>
                <pre
                  className="border border-new-neutral-dark py-2 px-4 font-sans"
                  dangerouslySetInnerHTML={{
                    __html: activeTestCase?.debug,
                  }}
                />
              </div>
            )}
            {!errorInCode && typeof activeTestCase?.expected_output === "string" && (
              <div className="space-y-1">
                <h4>Expected Output</h4>
                <pre className="border border-new-neutral-dark py-2 px-4 font-sans">
                  {activeTestCase?.expected_output}
                </pre>
              </div>
            )}
            {!errorInCode && typeof activeTestCase?.output === "string" && (
              <div className="space-y-1">
                <h4>Your Output</h4>
                <pre
                  className={cn(
                    "border border-new-neutral-dark py-2 px-4 font-sans",
                    activeTestCase?.output === "" && "italic"
                  )}
                >
                  {activeTestCase?.output === "" ? "No Output" : activeTestCase?.output}
                </pre>
              </div>
            )}
            {(language === "python" || language === "javascript") && !errorInCode && data?.stderr?.length !== 0 && (
              <div className="space-y-1">
                <h4>Warning</h4>
                <pre
                  className={cn(
                    "border border-new-neutral-dark py-2 px-4 font-sans flex flex-row overflow-auto",
                    data?.stderr === "" && "italic"
                  )}
                >
                  {data?.stderr === "" ? "No Output" : data?.stderr.join("\n")}
                </pre>
              </div>
            )}
            {(errorInCode || !activeTestCase?.hasOwnProperty("result")) && (
              <div className="space-y-1">
                <h4>Error</h4>
                <pre className="flex flex-col overflow-x-auto border border-new-neutral-dark py-2 px-4 font-sans">
                  {!isEmpty(data?.description) ? (
                    <>
                      <p>
                        {data?.description !== "Accepted"
                          ? data?.description
                          : data?.stderr.map((line: any, index: any) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))}
                      </p>
                      {!isEmpty(data?.compile_output) && <p>{data?.compile_output}</p>}
                    </>
                  ) : !isEmpty(data?.stderr) ? (
                    data?.stderr?.map((item: any, i: number) => <p key={i}>{item}</p>)
                  ) : (
                    <p>Unknown error occurred!</p>
                  )}
                </pre>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
