import { getAPI, postAPI } from "core/utils/axios"
import SheetsDataSource from "data/dataSource/Admin/SheetsDataSource"
import pn, { query } from "core/utils/pn"
import {
  API_GET_BATCH_STUDENT_DETAILS,
  API_GET_BATCH_YEAR_DATA,
  API_GET_DOUBT_RAISED_STATS,
  API_GET_MENTOR_LIST_BATCH,
  API_UPDATE_BATCH_DATA,
  API_V4_ADMIN,
  API_ALLOT_MENTORS,
} from "core/constants/strings"
import { Auth } from "domain/model/Auth"

export class SheetsAPIDataSourceImpl implements SheetsDataSource {
  async getBatchStudentDetails(data: any): Promise<any> {
    // const batch = data.batch.replace(/\s/g, ' '); // Keeps spaces intact
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_BATCH_STUDENT_DETAILS), {
          batch: data.batch,
          from_date: data.fromDate,
          to_date: data.toDate,
        })
      )

      return response
    } catch (error) {
      return error
    }
  }
  async getBatchYearData(auth: Auth, batch: string, month: any, year: string): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_BATCH_YEAR_DATA), {
          id_token: auth?.id_token,
          batch: batch,
          month: month,
          year: year,
        })
      )
      return response
    } catch (error) {
      return error
    }
  }
  async getMentorListData(auth: Auth, batch: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_LIST_BATCH), {
        id_token: auth?.id_token,
        batch: batch,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getDoubtRaisedStats(data: any): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_DOUBT_RAISED_STATS), {
          from_date: data.fromDate2,
          to_date: data.toDate2,
        })
      )
      return response
    } catch (error) {
      return error
    }
  }
  async updateBatchData(auth: Auth, batch: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_BATCH_DATA), {
        id_token: auth?.id_token,
        batch: batch,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async allotMentors(auth: Auth, batch: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_ALLOT_MENTORS), {
        id_token: auth?.id_token,
        batch: batch,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
