import DailyReportDataSource from "data/dataSource/Admin/DailyReportDataSource"
import { GetDailyReport } from "domain/model/DailyReport"
import DailyReportRepository from "domain/repository/Admin/DailyReportRepository"
export class DailyReportRepositoryImpl implements DailyReportRepository {
  private Datasource: DailyReportDataSource

  constructor(Datasource: DailyReportDataSource) {
    this.Datasource = Datasource
  }

  async getDailyReport(dailyReport: GetDailyReport): Promise<any> {
    return await this.Datasource.getDailyReport(dailyReport)
  }
}
