import DocumentationFileRepositry from "domain/repository/Admin/DocumentationFileRepository"
import { Auth } from "domain/model/Auth"

export interface GetDocumentationFilesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetDocumentationFiles implements GetDocumentationFilesUseCase {
  private repository: DocumentationFileRepositry

  constructor(repository: DocumentationFileRepositry) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.get_documentation_files(auth)
  }
}
