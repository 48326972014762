import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetBatchStudentDetailsUseCase {
  invoke(data: any): Promise<any>
}

export class GetBatchStudentDetails implements GetBatchStudentDetailsUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(data: any) {
    return this.repository.getBatchStudentDetails(data)
  }
}
