import React from "react"

const ArrowBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect y="0.5" width="16" height="16" rx="2" fill="#F9F9F9" />
      <path
        d="M3.88533 5.16662L5.57599 6.85728L4.63333 7.79995L1.33333 4.49995L4.63333 1.19995L5.57599 2.14262L3.88533 3.83328H8.66666C11.6122 3.83328 14 6.2211 14 9.16662C14 12.1121 11.6122 14.5 8.66666 14.5H2.66666V13.1666H8.66666C10.8758 13.1666 12.6667 11.3758 12.6667 9.16662C12.6667 6.95748 10.8758 5.16662 8.66666 5.16662H3.88533Z"
        fill="#646464"
      />
    </svg>
  )
}

export default ArrowBack
