import StandaredSelect from "core/components/StandaredSelect"
import Input from "core/components/new/Input"
import React from "react"
import MentorsViewModel from "../MentorsViewModel"
import Button from "core/components/new/Button"
import { CrossIcon } from "core/constants/svgs"
import CreateGroupMentorSession from "domain/useCase/Admin/MentorSessions/CreateGroupMentorSession"
import { MentorSessionsRepositoryImpl } from "data/repository/Admin/MentorSessionsRepositoryImpl"
import MentorSessionsAPIDataSourceImpl from "data/API/Admin/MentorSessionsAPIDataSourceImpl"
import { GroupMentorSession } from "domain/model/GroupMentorSession"
import { useAuth } from "core/context/auth"
import { useParams } from "react-router-dom"
import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function CreateGroupSessionForm({ updateCount, setUpdateCount }: any) {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { fetchGroupMentorSession } = MentorsViewModel()
  const { auth } = useAuth()
  const { getBatchList, allMentorsList, fetchAllMentorList, getBatches } = MentorsViewModel()
  const [mentorForGroupSession, setMentorForGroupSession] = React.useState<string>("")
  const [batchForGroupSession, setBatchForGroupSession] = React.useState("")
  const [batchArray, setBatchArray] = React.useState<Array<string>>([])
  const [startTime, setStartTime] = React.useState<number>(0)
  const [endTime, setEndTime] = React.useState<number>(startTime)
  const [date, setDate] = React.useState<any>()
  const [duration, setDuration] = React.useState<number>(60)
  const [studentId, setStudentId] = React.useState<string>("")
  const [studentArray, setStudentArray] = React.useState<Array<string>>([])
  const [createGroupSession, setCreatedGroupSession] = React.useState<any>()

  function handleSearchForGroupSessionMentor(e: any) {
    setMentorForGroupSession(e.target.value)
  }
  function handleBatchForGroupSession(e: any) {
    setBatchForGroupSession(e.target.value)
  }
  function handleBatchArray() {
    if (batchForGroupSession === "") return
    if (batchArray.includes(batchForGroupSession)) return

    setBatchArray([...batchArray, batchForGroupSession])
    setBatchForGroupSession("")
  }
  function handleRemoveBatch(index: number) {
    let newBatchArray = batchArray
    newBatchArray = newBatchArray.filter((m: string, i: number) => i !== index)
    setBatchArray(newBatchArray)
  }

  function handleStartTime(e: any) {
    setStartTime(e.target.value)
  }

  function handleEndTime(e: any) {
    setEndTime(e.target.value)
  }

  function handleDateChange(e: any) {
    setDate(e.target.value)
  }

  function handleDuration(e: any) {
    setDuration(e.target.value)
  }

  function handleStudentId(e: any) {
    setStudentId(e.target.value)
  }

  function handleStudentArray() {
    if (studentId === "") return
    if (studentArray.includes(studentId)) return

    setStudentArray([...studentArray, studentId])
    setStudentId("")
  }

  function handleRemoveStudent(index: number) {
    let newStudentArray = studentArray
    newStudentArray = studentArray.filter((m: string, i: number) => i !== index)
    setStudentArray(newStudentArray)
  }

  const CreateGroupMentorSessionUseCase = new CreateGroupMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )
  async function createGroupMentorSession() {
    setLoading(true)
    const groupSession: GroupMentorSession = {
      auth,
      student_ids: studentArray,
      mentor_id: mentorForGroupSession,
      start_time: startTime.toString(),
      end_time: endTime.toString(),
      date: date.toString(),
      batches: batchArray,
      duration: duration.toString(),
    }
    const response = await CreateGroupMentorSessionUseCase.invoke(auth, groupSession)
    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }
    changeToastDetails(STR_SUCCESS, "Created Session")
    changeToastVisibility(true)
    setUpdateCount(!updateCount)
    setCreatedGroupSession(response?.data)
  }

  React.useEffect(() => {
    fetchAllMentorList()
    getBatches()
    setMentorForGroupSession(id as string)
  }, [auth])

  return (
    <div className="">
      <div className='="flex flex-col space-y-6'>
        <div className="flex flex-col">
          <h4>Date Of Session</h4>
          <Input placeholder="Date" type="date" value={date} onChange={handleDateChange} />
        </div>
        <div className="flex flex-col">
          <h4>Start Time</h4>
          <Input
            placeholder="Start Time in 24 hours format"
            type="number"
            max="24"
            min="0"
            value={startTime}
            onChange={handleStartTime}
          />
        </div>
        <div className="flex flex-col">
          <h4>End Time</h4>
          <Input
            placeholder="End Time in 24 hours format"
            type="number"
            min={startTime}
            max="24"
            value={endTime}
            onChange={handleEndTime}
          />
        </div>
        <div className="flex flex-col">
          <h4>Batches</h4>
          <div className="flex flex-col gap-y-1">
            <div className="flex">
              {" "}
              <select value={batchForGroupSession} onChange={handleBatchForGroupSession} className="py-1">
                <option value="">Select Batch</option>
                {getBatchList?.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <Button type="button" onClick={handleBatchArray}>
                Add Batch
              </Button>
            </div>
          </div>
          <div className="flex gap-x-1">
            {batchArray?.map((batchValue: any, i: number) => (
              <div
                key={i}
                className="flex w-fit items-center gap-2 rounded-sm border border-new-neutral-light py-1 px-2 text-new-neutral-dark"
              >
                <p>{batchValue}</p>
                <button
                  type="button"
                  className="inline-flex h-4 w-4 items-center justify-center"
                  onClick={() => handleRemoveBatch(i)}
                >
                  <CrossIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col">
          <h4>Student ID's</h4>
          <div className="flex flex-col gap-y-1">
            <div className="flex">
              {" "}
              <Input
                type="text"
                value={studentId}
                placeholder="Student Email"
                onChange={handleStudentId}
                className="h-[60px] w-[209px] py-1"
              ></Input>
              <Button type="button" onClick={handleStudentArray}>
                Add Student
              </Button>
            </div>
            <div className="flex gap-x-1">
              {studentArray?.map((name: any, i: number) => (
                <div
                  key={i}
                  className="flex w-fit items-center gap-2 rounded-sm border border-new-neutral-light py-1 px-2 text-new-neutral-dark"
                >
                  <p>{name}</p>
                  <button
                    type="button"
                    className="inline-flex h-4 w-4 items-center justify-center"
                    onClick={() => handleRemoveStudent(i)}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h4>Duration Of Session</h4>
          <Input placeholder="Duration In Minutes" type="number" onChange={handleDuration} value={duration} />
        </div>

        <Button loading={loading} onClick={createGroupMentorSession}>
          Add Session
        </Button>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
