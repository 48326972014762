import TestSeriesDataSource from "data/dataSource/Admin/TestSeriesDashboardDataSource"
import { Auth } from "domain/model/Auth"
import {
  CreateTest,
  deleteScheduleTest,
  deleteTest,
  scheduleTest,
  updateScheduleTest,
  updateTest,
} from "domain/model/TestSeriesDashboard"
import { TestSeriesDashboardRepository } from "domain/repository/Admin/TestSeriesDashboardRepository"

export class TestSeriesDataSourceRepositoryImpl implements TestSeriesDashboardRepository {
  private dataSource: TestSeriesDataSource

  constructor(dataSource: TestSeriesDataSource) {
    this.dataSource = dataSource
  }

  getAllWeeklyTest(request: Auth): Promise<any> {
    return this.dataSource.getAllWeeklyTest(request)
  }
  getAllWeeklyScheduleTest(request: Auth): Promise<any> {
    return this.dataSource.getAllWeeklyScheduleTest(request)
  }
  createWeeklyTest(request: CreateTest): Promise<any> {
    return this.dataSource.createWeeklyTest(request)
  }
  updateScheduledTest(request: updateScheduleTest): Promise<any> {
    return this.dataSource.updateScheduledTest(request)
  }
  deleteWeeklyTest(request: deleteTest): Promise<any> {
    return this.dataSource.deleteWeeklyTest(request)
  }
  updateWeeklyTest(request: updateTest): Promise<any> {
    return this.dataSource.updateWeeklyTest(request)
  }
  deleteScheduleTest(request: deleteScheduleTest): Promise<any> {
    return this.dataSource.deleteScheduleTest(request)
  }
  scheduleWeeklyTest(request: scheduleTest): Promise<any> {
    return this.dataSource.scheduleWeeklyTest(request)
  }
}
