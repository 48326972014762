import MainLogo from "assets/svgs/MainLogo"
import { Fragment, useEffect, useState } from "react"
import Options from "../Mcq/Components/Options"

export default function ResponseView({
  response,
  activeResposeTab,
  optionSelected,
  handleOptionChange,
  solution,
  handleSolutionChange,
}: any) {
  const [currentResponse, setCurrentResponse] = useState<any>({})

  useEffect(() => {
    if (response) setCurrentResponse(response[activeResposeTab])
  }, [activeResposeTab])

  return (
    <div className="realtive p-8 flex flex-col gap-6">
      <div className="w-full">
        <MainLogo className="w-40 h-10 float-right" />
      </div>
      <div className="flex flex-col gap-2 border border-new-neutral-light bg-new-editor-dark-500 p-4 rounded-sm">
        <h3 className="mb-4">{currentResponse?.subTitle}</h3>
        <p>
          <strong>Problem Statement:</strong>
        </p>
        <p>{currentResponse?.content}</p>
        {currentResponse?.fields?.map((field: any, index: number) => {
          if (field?.type === "text") {
            return (
              <p key={index} className="text-justify">
                {field.content}
              </p>
            )
          } else if (field.type === "image") {
            return (
              <img
                src={field?.content}
                alt="img"
                title={field?.filename}
                onClick={() => window.open(field?.content)}
                className="cursor-pointer rounded-sm"
              />
            )
          } else if (field.type === "document") {
            return (
              <div
                title="Click to open the document"
                className="p-2 text-pretty text-sm cursor-pointer text-blue-500 bg-new-editor-dark-600 rounded-sm"
                onClick={() => window.open(field?.content)}
              >
                {"📄" + field?.filename}
              </div>
            )
          } else if (field.type === "code") {
            return (
              <pre className="p-2 bg-new-editor-dark-600 rounded-sm" key={index}>
                {field.content}
              </pre>
            )
          }
          return <></>
        })}
      </div>
      {currentResponse?.questionType === "MCQ" && currentResponse?.options ? (
        <Options
          options={currentResponse.options}
          multiple={true}
          responseId={response[activeResposeTab].id}
          optionSelected={optionSelected}
          handleOptionChange={handleOptionChange}
        />
      ) : (
        <Fragment>
          <p>Write your solution here:</p>
          <textarea
            maxLength={4000}
            className="bg-new-editor-dark-500 h-80 max-h-96 focus:border-none focus:ring-0 rounded-sm"
            value={solution?.[response[activeResposeTab].id] || ""}
            onChange={(e) => handleSolutionChange(e, response[activeResposeTab].id)}
          />
        </Fragment>
      )}
    </div>
  )
}
