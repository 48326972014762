export default function StoreTable({ data }: any) {
  return (
    <div className="relative my-4 overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
        <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-base text-white">
          <tr>
            {data.header?.map((title: any, index: any) => (
              <th key={index} className="px-4 py-4 text-[16px] font-medium  ">
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows?.length > 0 ? (
            data.rows?.map((row: any, index: any) => (
              <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                {Object.keys(row).map((key: any, index: any) => (
                  <td key={index} className="border-gray-200 px-4 py-4 font-medium">
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-[#F5F5F5]">
              <td colSpan={data.header.length} className="py-2 text-center font-medium leading-6 text-gray-500">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
