import PaymentInvoiceDataSource from "data/dataSource/Admin/PaymentInvoiceDataSource"
import { Auth } from "domain/model/Auth"
import { PaymentInvoice, InvoiceSearch } from "domain/model/PaymentInvoice"
import { PaymentInvoiceRepository } from "domain/repository/Admin/PaymentInvoiceRepository"

export class PaymentInvoicesImpl implements PaymentInvoiceRepository {
  private Datasource: PaymentInvoiceDataSource

  constructor(Datasource: PaymentInvoiceDataSource) {
    this.Datasource = Datasource
  }

  async getInvoices(InvoiceFetchDetails: InvoiceSearch): Promise<any> {
    return await this.Datasource.getInvoices(InvoiceFetchDetails)
  }

  async updateInvoice(auth: Auth, updateInvoice: PaymentInvoice, oldInvoice: PaymentInvoice): Promise<any> {
    return await this.Datasource.updateInvoice(auth, updateInvoice, oldInvoice)
  }
  getInvoiceByFilterSearch(InvoiceFilterSearch: InvoiceSearch): Promise<any> {
    return this.Datasource.getInvoiceByFilterSearch(InvoiceFilterSearch)
  }
}
