import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface UpdateMentorUseCase {
  invoke(auth: Auth, emails: string[3]): Promise<any>
}

export class UpdateMentor implements UpdateMentorUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, emails: string[3]): Promise<any> {
    return await this.repository.updateMentor(auth, emails)
  }
}
