import { cn } from "core/lib/utils"
import React from "react"

const Stage2 = ({ className }: any) => {
  return (
    <svg className={cn(className)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2314_62128)">
        <rect width="32" height="32" rx="4" fill="#333333" />
        <path
          d="M12.4383 34V29.4171L23.1465 19.5022C24.0572 18.6208 24.821 17.8276 25.4379 17.1226C26.0646 16.4175 26.5396 15.7271 26.8627 15.0515C27.1859 14.366 27.3475 13.6266 27.3475 12.8335C27.3475 11.9521 27.1467 11.1932 26.7452 10.5567C26.3437 9.91038 25.7953 9.41586 25.1001 9.07312C24.4048 8.72059 23.6165 8.54432 22.7352 8.54432C21.8147 8.54432 21.0117 8.73038 20.3262 9.1025C19.6407 9.47461 19.1119 10.0083 18.7398 10.7036C18.3677 11.3988 18.1817 12.2263 18.1817 13.186H12.1446C12.1446 11.2177 12.5901 9.50889 13.4812 8.05959C14.3724 6.6103 15.6209 5.48906 17.2269 4.69586C18.8329 3.90267 20.6836 3.50607 22.7792 3.50607C24.9336 3.50607 26.8089 3.88798 28.4051 4.65179C30.011 5.40582 31.2596 6.45362 32.1507 7.7952C33.0418 9.13677 33.4874 10.6742 33.4874 12.4075C33.4874 13.5434 33.2622 14.6647 32.8117 15.7712C32.371 16.8778 31.5827 18.1067 30.4468 19.4581C29.3109 20.7997 27.7098 22.4105 25.6436 24.2907L21.2516 28.5945V28.8002H33.884V34H12.4383Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2314_62128">
          <rect width="32" height="32" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Stage2
