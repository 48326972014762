import { jobDetails } from "domain/model/JobDashboard"
import { JobDashboardRepository } from "domain/repository/Admin/JobDashboardRepository"

export interface GetJobDetailsUseCase {
  invoke(request: jobDetails): Promise<any>
}

export class GetJobDetails implements GetJobDetailsUseCase {
  private repository: JobDashboardRepository

  constructor(repository: JobDashboardRepository) {
    this.repository = repository
  }

  async invoke(request: jobDetails): Promise<any> {
    return await this.repository.getJobDetails(request)
  }
}
