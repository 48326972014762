export default function SuccessCheckbox({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10ZM16.073 6.929L9.003 14L4.76 9.757L6.174 8.343L9.003 11.172L14.659 5.515L16.073 6.929Z"
        fill="currentColor"
      />
    </svg>
  )
}
