import GenerateInvoiceDataSource from "data/dataSource/Admin/GenerateInvoiceDataSource"
import { Auth } from "domain/model/Auth"

export class GenerateInvoiceRepositoryImpl implements GenerateInvoiceRepositoryImpl {
  private dataSource: GenerateInvoiceDataSource

  constructor(dataSource: GenerateInvoiceDataSource) {
    this.dataSource = dataSource
  }

  async getAllInstructorDetails(auth: Auth): Promise<any> {
    return this.dataSource.getAllInstructorDetails(auth)
  }

  async getInstructorDetails(auth: Auth, instructor_id: string): Promise<any> {
    return this.dataSource.getInstructorDetails(auth, instructor_id)
  }

  async updateInstructorCompensationDetails(
    auth: Auth,
    instructor_id: string,
    compensation_amount: number,
    year_month: string
  ): Promise<any> {
    return this.dataSource.updateInstructorCompensationDetails(auth, instructor_id, compensation_amount, year_month)
  }

  async updateInstructorDetails(auth: Auth, instructor_id: string, time_given: string): Promise<any> {
    return this.dataSource.updateInstructorDetails(auth, instructor_id, time_given)
  }

  async getAllSessionOfMonthYear(auth: Auth, instructor: string, year: string, month: string): Promise<any> {
    return this.dataSource.getAllSessionOfMonthYear(auth, instructor, year, month)
  }

  async finaliseInvoiceOfAMentor(auth: Auth, instructor_id: string, year_month: string): Promise<any> {
    return this.dataSource.finaliseInvoiceOfAMentor(auth, instructor_id, year_month)
  }

  async getInstructorAverageRating(auth: Auth, instructor_id: string): Promise<any> {
    return this.dataSource.getInstructorAverageRating(auth, instructor_id)
  }

  async updateInstructorIncentiveDetails(
    auth: Auth,
    instructor_id: string,
    incentive_amount: number,
    incentive_year_month: string
  ): Promise<any> {
    return this.dataSource.updateInstructorIncentiveDetails(auth, instructor_id, incentive_amount, incentive_year_month)
  }

  async getInstructorIncentiveDetails(auth: Auth, instructor_id: string): Promise<any> {
    return this.dataSource.getInstructorIncentiveDetails(auth, instructor_id)
  }

  async confirmInvoiceOfMentor(auth: Auth, instructor_id: string, year_month: string): Promise<any> {
    return this.dataSource.confirmInvoiceOfMentor(auth, instructor_id, year_month)
  }

  async updateMentorCompensationDetails(
    auth: Auth,
    mentor_id: string,
    compensation_amount: number,
    year_month: string
  ): Promise<any> {
    return this.dataSource.updateMentorCompensationDetails(auth, mentor_id, compensation_amount, year_month)
  }

  async updateMentorDetails(auth: Auth, instructor_id: string, time_given: string): Promise<any> {
    return this.dataSource.updateMentorDetails(auth, instructor_id, time_given)
  }

  async getMentorSessionOfMonthYear(
    auth: Auth,
    mentor_id: string,
    batch: string,
    year: string,
    month: string
  ): Promise<any> {
    return this.dataSource.getMentorSessionOfMonthYear(auth, mentor_id, batch, year, month)
  }

  async getAllMentorsList(auth: Auth): Promise<any> {
    return this.dataSource.getAllMentorsList(auth)
  }

  async getGroupMentorSessionOfMonthYear(
    auth: Auth,
    mentor_id: string,
    batch: string,
    year: string,
    month: string
  ): Promise<any> {
    return this.dataSource.getGroupMentorSessionOfMonthYear(auth, mentor_id, batch, year, month)
  }

  async removeCompensation(auth: Auth, instructor_id: string, index: number): Promise<any> {
    return this.dataSource.removeCompensation(auth, instructor_id, index)
  }
  async removeMentorCompensation(auth: Auth, instructor_id: string, index: number): Promise<any> {
    return this.dataSource.removeMentorCompensation(auth, instructor_id, index)
  }
  async editCompensation(auth: Auth, instructor_id: string, index: number, amount: number): Promise<any> {
    return this.dataSource.editCompensation(auth, instructor_id, index, amount)
  }
  async editMentorCompensation(auth: Auth, instructor_id: string, index: number, amount: number): Promise<any> {
    return this.dataSource.editMentorCompensation(auth, instructor_id, index, amount)
  }
  async removeIncentive(auth: Auth, instructor_id: string, incentive_year_month: string): Promise<any> {
    return this.dataSource.removeIncentive(auth, instructor_id, incentive_year_month)
  }
}
