import Button from "core/components/Button"
import { cn } from "core/lib/utils"
import { format } from "date-fns"

export default function UpcomingSessionTable({ tableHeaders, data, batch }: any) {
  return (
    <div>
      <div className="my-8">
        <h3 className="pb-2 text-2xl font-bold text-new-accent">Upcoming Sessions</h3>
        <p className="text-[16px]"> List of Upcoming Mentor Sessions.</p>
      </div>
      <div className="relative my-4 overflow-x-auto  shadow-md">
        <table className=" w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
          <thead className="rounded-lg bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-white">
            <tr>
              {tableHeaders?.map((title: any, index: any) => (
                <th
                  key={index}
                  className={cn(
                    "whitespace-nowrap py-2 font-medium leading-6",
                    index === 0 ? "rounded-l-lg pl-8 pr-2" : "px-2",
                    index === tableHeaders?.length - 1 && "rounded-r-lg pl-2 pr-8 text-right",
                    index < 3
                      ? "text-left"
                      : index === tableHeaders?.length - 1
                        ? "pl-2 pr-6 text-right"
                        : "text-center"
                  )}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((data: any, index: any) => (
                <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={data?.id}>
                  <td className="pl-8  pt-1 text-left">{batch}</td>
                  <td className="py-2 pl-2 text-left font-medium  text-new-neutral">{data?.mentor_name}</td>
                  <td className="py-2 pl-2 text-left font-medium text-new-neutral">
                    {format(new Date(data?.start_timestamp * 1000), "dd MMMM yyyy")}|
                    {format(new Date(data?.start_timestamp * 1000), " h:mm a")}
                  </td>
                  <td className="pl-8  pt-1 text-left">{data?.status}</td>

                  <td className="flex justify-end py-2 pr-4 text-right">
                    <a
                      href={`${data?.meet_link}`}
                      target="_blank"
                      rel="noreferrer"
                      className={
                        (data?.start_timestamp * 1000 - Date.now() > 30 * 60 * 1000 ||
                          Date.now() - data?.start_timestamp * 1000 > 60 * 60 * 1000) === true
                          ? "pointer-events-none"
                          : ""
                      }
                    >
                      <Button
                        disabled={
                          data?.start_timestamp * 1000 - Date.now() > 30 * 60 * 1000 ||
                          Date.now() - data?.start_timestamp * 1000 > 60 * 60 * 1000
                        }
                        className="w-fit"
                      >
                        Join Here
                      </Button>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableHeaders?.length} className="py-2 text-center font-medium leading-6 text-gray-500">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
