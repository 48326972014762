import { Auth } from "domain/model/Auth"
import CompanyRepository from "domain/repository/Admin/CompanyRepository"

export interface DeleteCompanyUseCase {
  invoke(auth: Auth, name: string): Promise<any>
}

export class DeleteCompany implements DeleteCompanyUseCase {
  private repository: CompanyRepository

  constructor(repository: CompanyRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, name: string) {
    return await this.repository.deleteCompany(auth, name)
  }
}
