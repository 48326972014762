import { EditorDataSource } from "data/dataSource/Student/EditorDataSource"
import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export class EditorRepositoryImpl implements EditorRepository {
  private dataSource: EditorDataSource

  constructor(dataSource: EditorDataSource) {
    this.dataSource = dataSource
  }

  async importTemplateCode(topic: string, auth: Auth): Promise<any> {
    return this.dataSource.importTemplateCode(topic, auth)
  }

  async getQuestion(auth: Auth, topic: string, is_contest?: boolean): Promise<any> {
    return this.dataSource.getQuestion(auth, topic, is_contest)
  }

  async getQuestionWithAnswer(auth: Auth, topic: string, is_contest?: boolean): Promise<any> {
    return this.dataSource.getQuestionWithAnswer(auth, topic, is_contest)
  }

  async getSubjectiveCaseStudyQuestion(auth: Auth, id: any): Promise<any> {
    return this.dataSource.getSubjectiveCaseStudyQuestion(auth, id)
  }

  async getSubmissions(auth: Auth, topic: string): Promise<any> {
    return this.dataSource.getSubmissions(auth, topic)
  }

  async getSubmission(auth: Auth, token: string): Promise<any> {
    return this.dataSource.getSubmission(auth, token)
  }

  async createSubmission(auth: Auth, data: any): Promise<any> {
    return this.dataSource.createSubmission(auth, data)
  }

  async runCode(auth: Auth, data: any): Promise<any> {
    return this.dataSource.runCode(auth, data)
  }

  async likeQuestion(auth: Auth, topic: string, like: boolean): Promise<any> {
    return this.dataSource.likeQuestion(auth, topic, like)
  }

  async dislikeQuestion(auth: Auth, topic: string, dislike: boolean): Promise<any> {
    return this.dataSource.dislikeQuestion(auth, topic, dislike)
  }
}
