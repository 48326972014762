import React from "react"

export default function EditCouponViewModel() {
  const [amount, setAmount] = React.useState<any>()
  const [couponId, setCouponId] = React.useState<string>("")
  const [couponType, setCouponType] = React.useState<string>("Amazon Coupon")
  const [purchaseDate, setPurchaseDate] = React.useState<any>()
  const [referenceId, setReferenceId] = React.useState<any>("")
  const [expiryDate, setExpiryDate] = React.useState<any>()

  function handleAmount(e: any) {
    setAmount(e.target.value)
  }

  function handleChangePurchaseDate(e: any) {
    setPurchaseDate(e.target.value)
  }

  function handleReferenceId(e: any) {
    setReferenceId(e.target.value)
  }

  function handleChangeExpiryDate(e: any) {
    setExpiryDate(e.target.value)
  }

  function handleCouponType(e: any) {
    setCouponType(e.target.value)
  }

  function handleChangeCouponId(e: any) {
    setCouponId(e.target.value)
  }

  return {
    amount,
    couponId,
    couponType,
    expiryDate,
    purchaseDate,
    referenceId,
    setCouponId,
    setAmount,
    setCouponType,
    setExpiryDate,
    setPurchaseDate,
    setReferenceId,
    handleAmount,
    handleChangeCouponId,
    handleCouponType,
    handleChangeExpiryDate,
    handleChangePurchaseDate,
    handleReferenceId,
  }
}
