import { Auth } from "domain/model/Auth"

import PaymentAdminRepository from "domain/repository/Admin/CreatePaymentRepository"

export interface documentVerifiedUseCase {
  invoke(auth: Auth, student_email: string, status: boolean): Promise<any>
}

export class DocumentVerified implements documentVerifiedUseCase {
  private repository: PaymentAdminRepository

  constructor(repository: PaymentAdminRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, student_email: string, status: boolean) {
    return await this.repository.documentVerified(auth, student_email, status)
  }
}
