import CopyButton from "core/components/Button"
import RedeemButton from "core/components/new/Button"
import { CoinIcon, CopyIcon } from "core/constants/svgs"
import { format } from "date-fns"
import React from "react"
import amazon_vocher from "assets/images/amazon_voucher.png"
import extra_mentor_session from "assets/images/extra_mentor_session.png"

export default function MentorSessionCouponCard(order: any) {
  return (
    <div className="flex h-[162px] overflow-hidden rounded px-[32px] py-[24px] shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
      <div className="h-full w-full max-w-[240px] bg-gray-400">
        <img className="h-full" src={extra_mentor_session} alt="" />
      </div>
      <div className="flex w-full justify-between pl-6">
        <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
          <div className="flex justify-between">
            <div className="space-y-[4px]">
              <h6>Order ID : #{order?.order?.order_id}</h6>
              <h3 className="text-[#312E81]">Mentor Session</h3>
              <div className="flex gap-x-1">
                <span className="text-[#A3A3A3]">Date of Booking : </span>
                <span className="">{format(order?.order?.order_date * 1000, "do MMM, yyyy")}</span>
              </div>
            </div>
            <div className="flex items-center gap-2 self-start">
              <span>
                <CoinIcon className="h-6 w-6" />
              </span>
              <span className="text-xl font-bold text-[#FBBF24]">100000</span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="w-[450px]">
              The team will schedule a mentor session and update you. If you don't hear back within 2 working days,
              contact us at help@bosscoderacademy.com.
            </div>
            <RedeemButton
              success={true}
              disabled={true}
              className="w-fit bg-green-50 px-[36px] py-[8px] text-[#00C22B]"
            >
              Redeem Successful
            </RedeemButton>
          </div>
        </div>
      </div>
    </div>
  )
}
