import ButtonGroup from "core/components/ButtonGroup"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import AssessmentTable from "./components/ContestTable"
import useContestViewModel from "./ContestViewModel"
import Toast from "core/components/Toast"
import {
  STR_COMPLETED_TEST,
  STR_ONGOING_TEST,
  STR_WEEKLY_TEST_SERIES,
  STR_WEEKLY_TEST_SERIES_SUBTITLE,
} from "core/constants/strings"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import Loader from "core/components/Loader"

export default function ContestView() {
  const {
    loading,
    activeButtonType,
    tableData,
    toast,
    refreshed,
    changeActiveButtonType,
    setActiveButtonType,
    getAllContest,
    changeToastVisibility,
  } = useContestViewModel()

  React.useEffect(() => {
    if (refreshed) {
      getAllContest()
    }
  }, [refreshed])

  return (
    <DashboardLayoutv2>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h1 className="text-xl font-bold leading-[29px] text-new-accent">{STR_WEEKLY_TEST_SERIES}</h1>
          <p>{STR_WEEKLY_TEST_SERIES_SUBTITLE}</p>
        </div>
        <ButtonGroup
          buttons={[STR_ONGOING_TEST, STR_COMPLETED_TEST]}
          active={activeButtonType}
          onChange={changeActiveButtonType}
        />
        {loading ? <Loader></Loader> : <AssessmentTable data={tableData} buttonType={activeButtonType} />}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
