import { Auth } from "domain/model/Auth"
import { AvailabilityRepository } from "domain/repository/Mentor/AvailabilityRepository"

export interface UpdateGoalSettingAvailabilityUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UpdateGoalSettingAvailability implements UpdateGoalSettingAvailabilityUseCase {
  private repository: AvailabilityRepository

  constructor(repository: AvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.updateGoalSettingAvailability(auth, data)
  }
}
