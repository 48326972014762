import placementUpdateImage from "../assets/placementUpateImage.png"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

export default function PlacementUpdate() {
  return (
    <div className="relative">
      <div className="flex flex-col shadow p-8 gap-6 rounded">
        {/* placementuoodate */}
        <div className="flex flex-col gap-2">
          <h2 className="text-new-accent text-2xl">Placement Update</h2>
          <p className="font-normal text-sm">
            As an effort to strengthen our Alumni Network, Bosscoder Academy is happy to extend exclusive benefits for
            its students who have bagged an offer, post-enrollment.
          </p>
        </div>
        <div className="flex">
          <div className="space-y-2 w-1/2">
            <h3 className="text-[#FBBF24] text-lg">Get some exciting rewards!!</h3>
            <ul className="list-disc space-y-4 text-left gap-2 ml-6 mt-4">
              <li className="text-sm">
                <span className="font-bold">Amazon vouchers </span>worth Rs. 1000 + Surprise goodies
              </li>
              <li className="text-sm">
                <span className="font-bold">Admission to Alumni Community :</span> Be a part of the Bosscoder Alumni
                Cell, get amazing networking benefits with the smartest people in tech. Get rewards every week by
                participating in weekly events!
              </li>
              <li className="text-sm">
                Get a chance to become a <span className="font-bold">mentor at Bosscoder Academy</span> - Get extra
                income benefits every month!
              </li>
              <li className="text-sm">
                Get <span className="font-bold">lifetime referral opportunities</span> via Alumni Cell, for MAANG type
                companies.
              </li>
            </ul>
            <div>
              <p className="text-[#DC2626] font-bold text-center my-4">
                Submit your details in the form attached below, only if you're placed after enrolling into Bosscoder, to
                win the rewards!
              </p>

              <a
                href="https://forms.gle/R7sFoGEeBBNx7Fr88"
                target="_blank"
                className="block font-semibold text-[18px] bg-[#312E81] text-white text-center py-3 px-3 my-2 w-full rounded  leading-[24px] items-center justify-center flex gap-1"
              >
                Form Link
                <ArrowForwardIcon className="inline-block w-4 h-4 ml-1 text-white " />
              </a>
            </div>
          </div>
          <div className="flex w-1/2 items-center justify-center">
            <img
              className="shrink-0 h-[400px] p-8 object-center "
              src={placementUpdateImage}
              alt="Placement Update Image"
            />
          </div>
        </div>
        <p className="py-4 px-[18px] bg-[#EDFFF2] font-normal text-center">
          <span className="font-semibold">15 Bosscoder Alumni</span> have been onboarded as Mentors, and are earning an
          average of <span className="font-semibold">Rs. 15,000 per month.</span>
        </p>
      </div>
    </div>
  )
}
