import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface ValidateSubjectiveQuestionUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export class ValidateSubjectiveQuestion implements ValidateSubjectiveQuestionUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: any) {
    return await this.repository.validateSubjectiveQuestion(auth, id)
  }
}
