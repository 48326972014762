import React from "react"

const LockOpen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.03271 6.13548V6.16667H2.69938C2.33119 6.16667 2.03271 6.46514 2.03271 6.83333V14.8333C2.03271 15.2015 2.33119 15.5 2.69938 15.5H13.366C13.7342 15.5 14.0327 15.2015 14.0327 14.8333V6.83333C14.0327 6.46514 13.7342 6.16667 13.366 6.16667H12.0327V5.5C12.0327 3.29086 10.2419 1.5 8.03271 1.5C7.36631 1.5 6.73796 1.66297 6.18536 1.95122L7.09257 3.00378C7.38495 2.89361 7.7018 2.83333 8.03271 2.83333C9.50547 2.83333 10.6994 4.02724 10.6994 5.5V6.16667H5.36605V6.13548H4.03271ZM3.36605 7.5V14.1667H12.6994V7.5H3.36605ZM6.74483 9.82152C6.58864 10.4044 6.84345 11.0196 7.36607 11.3213V12.8333H8.6994V11.3213C9.22203 11.0196 9.47683 10.4044 9.32064 9.82152C9.16445 9.23861 8.63621 8.83328 8.03274 8.83328C7.42926 8.83328 6.90103 9.23861 6.74483 9.82152Z"
        fill="#FBBF24"
      />
    </svg>
  )
}

export default LockOpen
