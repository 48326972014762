import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface deletePlacementCompanyUseCase {
  invoke(auth: Auth, company_details: any): Promise<any>
}

export class DeletePlacementCompany implements deletePlacementCompanyUseCase {
  private repository: StudentDashboardRepository
  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, company_details: any): Promise<any> {
    return await this.repository.deletePlacementCompany(auth, company_details)
  }
}
