import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface GetMentorSessionsUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetMentorSessions implements GetMentorSessionsUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getMentorSessions(auth)
  }
}
