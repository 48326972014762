import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import SessionsCard from "./components/SessionsCard"
import GenerateInvoiceViewModel from "./GenerateInvoiceViewModel"
import { isEmpty } from "core/utils/misc"
import { useParams } from "react-router-dom"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import { STR_SUCCESS } from "core/constants/strings"
import Modal from "core/components/Modal"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import useToast from "core/hooks/useToast"

export default function MonthSessionsView() {
  const {
    auth,
    batch,
    allSessionMonthYear,
    confirmStatus,
    getBatchList,
    sessionName,
    sessionDate,
    originalData,
    updateClassBool,
    setUpdateClassBool,
    getBatches,
    setBatch,
    setSessionDate,
    setSessionName,
    handleSessionName,
    handleSessionsDate,
    handleSearchByBatch,
    fetchInvoiceOfMentor,
    fetchUpdatedInstructorDetails,
    fetchConfirmedInvoiceOfMentor,
    fetchAllSessionMonthyear,
    setAllSessionMonthYear,
  } = GenerateInvoiceViewModel()
  const { id, yearMonth } = useParams()
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false)
  const [status, setStatus] = React.useState("")
  const [monthName, setMonthName] = React.useState<string>("")
  const [apiData, setData] = React.useState<any>()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  function handleConfirmModal() {
    setConfirmModal(!confirmModal)
  }
  async function handleInvoice() {
    await setConfirmModal(!confirmModal)
    await fetchConfirmedInvoiceOfMentor(auth, id as string, yearMonth as string)
  }

  const STATUSARRAY: string[] = [
    "Scheduled Class",
    "Class Taken",
    "Cancelled Ontime",
    "Substituted Class",
    "Cancelled Post time",
  ]

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  function radioFunction(i: number) {
    let module = ""
    if (i === 2) {
      module = "Hourly Class"
    }
    if (i == 3) {
      module = "Module Based Class"
    }
    const filter_data = (originalData?.v4_session_classes || []).filter(
      (item: any, key: number) => module === "" || item.compensation_status === module
    )

    const obj = {
      v4_session_classes: filter_data,
    }
    setAllSessionMonthYear(obj)
  }
  function handleSearchByStatus(e: any) {
    setStatus(e.target.value)
  }

  async function hanldeFilter() {
    const filter_data = (originalData?.v4_session_classes || []).filter(
      (item: any, key: number) =>
        (sessionName === "" || item.chatper_name === sessionName) &&
        (batch === "" || item.batches.includes(batch)) &&
        (status === "" || item.status === status) &&
        (sessionDate === "" || timestampToDate(item.session_timestamp) === sessionDate)
    )

    const obj = {
      v4_session_classes: filter_data,
    }
    setBatch("")
    setSessionName("")
    setSessionDate("")
    setStatus("")
    setAllSessionMonthYear(obj)
  }

  function timestampToDate(timestamp: any) {
    const date = new Date(timestamp * 1000)
    const formattedDate = date.toISOString().split("T")[0]
    return formattedDate
  }
  async function previousData() {
    const rspns = await fetchUpdatedInstructorDetails(auth, id as string, yearMonth as string)
    setData(rspns)
    return rspns
  }
  async function handleInvoiceSubmit() {
    setConfirmModal(!confirmModal)
    const value = await fetchInvoiceOfMentor(auth, id as string, yearMonth as string)
    changeToastDetails(STR_SUCCESS, `Invoice Generation ${value}`)
    changeToastVisibility(true)
  }

  React.useEffect(() => {
    const yearMonthString = yearMonth as string
    const year = yearMonthString.slice(0, 4)
    const month = yearMonthString.slice(4)
    setMonthName(months[parseInt(month, 10) - 1])
    fetchAllSessionMonthyear(auth, id as string, year, months[parseInt(month, 10) - 1])
    previousData()
    getBatches()
  }, [id, yearMonth, updateClassBool])
  return (
    <DashboardLayout>
      <div className="flex flex-col gap-y-6">
        <div className="">
          <h1>{`${monthName} ${(yearMonth as string).slice(0, 4)}  All Sessions`}</h1>
        </div>
        <div className="mx-4 flex items-end space-x-6">
          <div className="flex flex-col space-y-1">
            <h4>Session Name</h4>
            <Input type="text" placeholder="Session Name" onChange={handleSessionName} value={sessionName}></Input>
          </div>
          <div className="flex flex-col space-y-1">
            <h4>Session Status</h4>
            <StandaredSelect
              required
              id="Batch"
              className="w-[209px]"
              options={STATUSARRAY}
              placeholder="Select Batch"
              onChange={handleSearchByStatus}
              value={status}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <h4>Date</h4>
            <Input type="date" value={sessionDate} onChange={handleSessionsDate}></Input>
          </div>
          <div className="flex flex-col space-y-1">
            <h4>Batch</h4>
            <StandaredSelect
              required
              id="Batch"
              className="w-[209px]"
              options={getBatchList}
              placeholder="Select Batch"
              onChange={handleSearchByBatch}
              value={batch}
            />
          </div>
          <Button className="h-[37px] w-fit" onClick={hanldeFilter}>
            Submit
          </Button>
        </div>
        {!isEmpty(allSessionMonthYear) ? (
          <div className="mx-4 flex flex-col space-y-6">
            <div className="flex space-x-2">
              <div className="me-4 flex items-center">
                <input
                  checked
                  id="radio-1"
                  type="radio"
                  name="radio-group"
                  className=""
                  onChange={() => radioFunction(1)}
                />
                <label htmlFor="radio-1" className="">
                  All
                </label>
              </div>
              <div className="me-4 flex items-center">
                <input id="radio-2" type="radio" name="radio-group" className="" onChange={() => radioFunction(2)} />
                <label htmlFor="radio-2" className="">
                  Hourly
                </label>
              </div>
              <div className="me-4 flex items-center">
                <input id="radio-3" type="radio" name="radio-group" className="" onChange={() => radioFunction(3)} />
                <label htmlFor="radio-3" className="">
                  Module
                </label>
              </div>
            </div>

            <div className="space-y-6">
              {!isEmpty(allSessionMonthYear?.v4_session_classes) ? (
                allSessionMonthYear?.v4_session_classes?.map((data: any, i: number) => (
                  <SessionsCard
                    toast={toast}
                    changeToastDetails={changeToastDetails}
                    changeToastVisibility={changeToastVisibility}
                    updateClassBool={updateClassBool}
                    setUpdateClassBool={setUpdateClassBool}
                    key={i}
                    index={i}
                    data={data}
                    onChange={setAllSessionMonthYear}
                    sessions={allSessionMonthYear}
                  />
                ))
              ) : (
                <div>
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
        {apiData?.monthly_data.invoice_id != "" && (
          <div>
            <p className="">Do you want to Generate Invoice Again </p>
            <h2>Initial Amount={apiData?.monthly_data.payable_amount}</h2>
          </div>
        )}
        <div className="justify-end">
          <Button onClick={handleInvoice}>Generate Invoice</Button>
        </div>
      </div>
      <Modal open={confirmModal} onClose={handleConfirmModal}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Generate Invoice</h3>
            <p>Are you sure you want to generate invoice for the month {monthName}</p>
            <p>Compensation Amount Rs {confirmStatus?.compensation_amount}</p>
            <p>Incentive Amount Rs {confirmStatus?.incentive_amount}</p>
            <p>Module Sessions Rs{confirmStatus?.module_amount}</p>
            <p>Total Amount Rs {confirmStatus?.total_amount}</p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={handleConfirmModal}>
              No
            </Button>
            <Button outlined onClick={handleInvoiceSubmit}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
