import {
  API_ADMIN,
  API_V4_CREATE_COMPANY,
  API_GET_COMPANY,
  API_UPDATE_COMPANY,
  API_DELETE_COMPANY,
  API_COMPANY,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { Company, EditCompany } from "domain/model/Questions"
import CompanyDataSource from "data/dataSource/Admin/CompanyDataSource"
const server = new Server()

export class CompanyAPIDataSourceImpl implements CompanyDataSource {
  async createCompany(company: Company): Promise<any> {
    try {
      const response = await server.post(pn(API_ADMIN, API_COMPANY, API_V4_CREATE_COMPANY), company, {
        Authorization: `Bearer ${company?.auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getCompany(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(API_ADMIN, API_COMPANY, API_GET_COMPANY), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateCompany(editCompany: EditCompany): Promise<any> {
    try {
      const response = await server.post(pn(API_ADMIN, API_COMPANY, API_UPDATE_COMPANY), editCompany, {
        Authorization: `Bearer ${editCompany?.auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteCompany(auth: Auth, name: string): Promise<any> {
    try {
      const response = await server.post(
        //todo: add variables with name
        pn(API_ADMIN, API_COMPANY, API_DELETE_COMPANY),
        { name: name },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
