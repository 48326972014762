import React from "react"
import { cn } from "core/lib/utils"

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode
  className?: string
  outlined?: boolean
  selected?: boolean
}

const Tags = ({ selected, children, className, outlined, ...props }: Props) => {
  return (
    <button
      className={cn(
        "flex w-fit items-center justify-center rounded font-medium",
        className,
        outlined ? "border border-[#848484] py-1.5 px-4 text-sm leading-4" : "py-1.5 px-4 text-sm leading-4",
        selected
          ? "bg-new-accent text-white font-medium text-sm hover:bg-[#03249A]"
          : `${outlined ? "bg-white" : "bg-[#f9f9f9]"} text-[#646464] font-medium text-sm hover:bg-[#EBEBEB]`
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default Tags
