import { Link } from "react-router-dom"
import { MainLogoBlack } from "core/constants/svgs"
import { STR_ROOT_PATH } from "core/constants/strings"

export default function LoginLayout({ children }: any) {
  return (
    <div className="flex flex-col h-screen justify-center text-new-neutral overflow-x-hidden">
      <div className="mx-auto mt-16 flex justify-center w-[127.5px] p-1 left-1/2 -translate-x-[110px]">
        <Link to={STR_ROOT_PATH}>
          <MainLogoBlack className="w-logo" />
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center items-center">
          <div className="border-[0.5px] border-[#D3D3D3] rounded-[8px] h-[374px] w-[368px] flex justify-center shadow-[0px_1px_4px_0px_rgba(34,41,48,0.2)] gap-8 p-[34px] mt-8 bg-new-solid-white z-20">
            <div className="relative flex h-full flex-col items-center justify-center gap-20 space-y-2 bg-new-solid-white text-sm">
              <div className="flex w-[300px] flex-col justify-center gap-8">{children}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-center z-20">
          <div className="w-full max-w-[543px] text-center text-sm leading-[22px] text-black pt-[51px]">
            Note : In case any changes are required, our onboarding team will connect with you soon! You can also reach
            us at{" "}
            <a href="mailto:help@bosscoderacademy.com" className="font-semibold no-underline">
              help@bosscoderacademy.com
            </a>{" "}
            for more help!
          </div>
        </div>
      </div>
    </div>
  )
}
