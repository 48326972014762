import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { DeleteIcon, EditIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { convertToAddress } from "core/utils/misc"
import { StoreAPIDataSourceImpl } from "data/API/Admin/StoreAPIDataSourceImpl"
import { StoreRepositoryImpl } from "data/repository/Admin/StoreRepositoryImpl"
import { AddStoreItem } from "domain/useCase/Admin/Store/AddStoreItem"
import { DeleteStoreItem } from "domain/useCase/Admin/Store/DeleteStoreItem"
import { GetStoreItems } from "domain/useCase/Admin/Store/GetStoreItems"
import { GetStoreOrders } from "domain/useCase/Admin/Store/GetStoreOrders"
import { UpdateStoreItem } from "domain/useCase/Admin/Store/UpdateStoreItem"
import { UpdateStoreOrderStatus } from "domain/useCase/Admin/Store/UpdateStoreOrderStatus"
import React from "react"

const statuses = ["Order Placed", "Dispatched ", "Delivered"]

export default function StoreViewModel() {
  const { auth } = useAuth()
  const [storeItemsLocally, setStoreItemsLocally] = useLocalStorage<any>("store-items", [])
  const [storeOrdersLocally, setStoreOrdersLocally] = useLocalStorage<any>("store-orders", [])
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [tableData, setTableData] = React.useState<any>({
    header: [],
    rows: [],
  })
  const [storeItems, setStoreItems] = React.useState<any>(() => storeItemsLocally)
  const [modalDetails, setModalDetails] = React.useState<any>({
    open: false,
    type: "",
  })
  const [itemDetails, setItemDetails] = React.useState<any>({
    name: "",
    image_url: "",
    coins: 0,
    quantity: 0,
  } as any)
  const [loading, setLoading] = React.useState(false)
  const [storeOrders, setStoreOrders] = React.useState<any>(() => storeOrdersLocally)

  const getStoreItemsUseCase = new GetStoreItems(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const addStoreItemUseCase = new AddStoreItem(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const updateStoreItemUseCase = new UpdateStoreItem(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const deleteStoreItemUseCase = new DeleteStoreItem(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const getStoreOrdersUseCase = new GetStoreOrders(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const updateStoreOrderStatus = new UpdateStoreOrderStatus(new StoreRepositoryImpl(new StoreAPIDataSourceImpl()))

  const fetchAllStoreItems = async () => {
    if (activeTabIndex === 0) handleStoreItemsTableData(storeItems)

    setLoading(true)
    const response = await getStoreItemsUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Couldn't fetch store items")
      changeToastVisibility(true)
      return
    }

    setStoreItems(response?.data)
    setStoreItemsLocally(response?.data)

    if (activeTabIndex === 0) handleStoreItemsTableData(response?.data)
  }

  const fetchAllStoreOrders = async () => {
    if (activeTabIndex === 1) handleAllOrdersTableData(storeOrders)

    setLoading(true)
    const response = await getStoreOrdersUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Couldn't fetch store orders")
      changeToastVisibility(true)
      return
    }

    setStoreOrders(response?.data)
    setStoreOrdersLocally(response?.data)

    if (activeTabIndex === 1) handleAllOrdersTableData(response?.data)
  }

  const handleStoreItemsTableData = (data: any) => {
    const rows = data?.map((item: any) => {
      return {
        name: item?.name,
        image: item?.image_url ? (
          <img src={item?.image_url} alt={item?.name} className="h-10 w-10 object-contain" />
        ) : (
          "No Image"
        ),
        quantity: item?.quantity,
        coins: item?.coins,
        update: (
          <button
            className="text-black"
            onClick={() => {
              handleModalDetails("update", true)
              setItemDetails(item)
            }}
          >
            <EditIcon className="h-5 w-5" />
          </button>
        ),
        delete: (
          <button
            className="text-red-500"
            onClick={() => {
              handleModalDetails("delete", true)
              setItemDetails(item)
            }}
          >
            <DeleteIcon className="h-5 w-5" />
          </button>
        ),
      }
    })

    setTableData({
      header: ["Name", "Image", "Quantity", "Coins", "Update", "Delete"],
      rows,
    })
  }

  const handleAllOrdersTableData = (data: any) => {
    const rows = data?.map((row: any) => {
      return {
        user: row?.email,
        product: row?.product?.name,
        status: statuses[parseInt(row?.status) - 1],
        address: row?.address ? convertToAddress(row?.address) : "No Address",
        date: new Date(parseInt(row?.timestamp) * 1000).toLocaleDateString(),
        update: (
          <select
            className="rounded-lg text-sm text-black"
            onChange={(e) => handleOrderStatusChange(row?.order_id, e)}
            value={parseInt(row?.status)}
          >
            {statuses.map((status, index) => (
              <option key={index} value={index + 1}>
                {status}
              </option>
            ))}
          </select>
        ),
      }
    })
    const tableData = {
      header: ["User", "Product", "Status", "Address", "Date", "Update"],
      rows,
    }
    setTableData(tableData)
  }

  const handleActiveTab = (tab: number) => {
    setActiveTabIndex(tab)

    if (tab === 0) handleStoreItemsTableData(storeItems)
    else handleAllOrdersTableData(storeOrders)
  }

  const handleModalDetails = (type: string, open: boolean) => {
    setModalDetails({ type, open })
  }

  const handleModalClose = () => {
    setModalDetails({ type: "", open: false })
  }

  const handleItemDetailsChange = (e: any) => {
    const { name, value, type } = e.target

    setItemDetails((prevState: any) => ({
      ...prevState,
      [name]: type === "number" ? parseInt(value) : value,
    }))
  }

  const handleAddStoreItem = async (e: any) => {
    e.preventDefault()

    setLoading(true)
    const response = await addStoreItemUseCase.invoke(auth, itemDetails)
    setLoading(false)

    handleModalClose()

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Couldn't add store item")
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Store item added successfully")
    changeToastVisibility(true)

    fetchAllStoreItems()
  }

  const handleUpdateStoreItem = async (e: any) => {
    e.preventDefault()

    const { item_id, ...item } = itemDetails

    setLoading(true)
    const response = await updateStoreItemUseCase.invoke(auth, item, item_id)
    setLoading(false)

    handleModalClose()

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Couldn't update store item")
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Store item updated successfully")
    changeToastVisibility(true)

    fetchAllStoreItems()
  }

  const handleDeleteStoreItem = async () => {
    setLoading(true)
    const response = await deleteStoreItemUseCase.invoke(auth, itemDetails?.item_id)
    setLoading(false)

    handleModalClose()

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Couldn't delete store item")
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Store item deleted successfully")
    changeToastVisibility(true)

    fetchAllStoreItems()
  }

  const fetchStoreStuffs = () => {
    fetchAllStoreItems()
    fetchAllStoreOrders()
  }

  const handleOrderStatusChange = async (order_id: string, e: any) => {
    const status = e.target.value

    setLoading(true)
    const response = await updateStoreOrderStatus.invoke(auth, order_id, status)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Couldn't update order status")
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Order status updated successfully")
    changeToastVisibility(true)

    fetchAllStoreOrders()
  }

  return {
    toast,
    loading,
    tableData,
    activeTabIndex,
    modalDetails,
    storeItems,
    itemDetails,
    storeOrders,
    handleActiveTab,
    handleStoreItemsTableData,
    handleAllOrdersTableData,
    changeToastVisibility,
    fetchAllStoreItems,
    handleModalDetails,
    handleModalClose,
    handleAddStoreItem,
    handleItemDetailsChange,
    handleUpdateStoreItem,
    handleDeleteStoreItem,
    fetchStoreStuffs,
  }
}
