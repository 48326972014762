import React from "react"
import useToast from "core/hooks/useToast"
import delay from "core/utils/delay"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import { GetResume } from "domain/useCase/Student/Profile/GetResume"
import { GetStudentProfileDetails } from "domain/useCase/Student/Profile/GetStudentProfileDetails"
import { UpdateProfile } from "domain/useCase/Student/Profile/UpdateProfile"
import { UploadProfilePicture } from "domain/useCase/Student/Profile/UploadProfilePicture"
import { useNavigate } from "react-router-dom"

export default function ProfileEditViewModel() {
  const { auth } = useAuth()
  const { student } = useApp()

  const [profileData, setProfileData] = React.useState({
    name: "",
    email: "",
    batch: "",
    gender: "",
    photo: "",
    linkedin_profile: "",
    experience: "",
    current_position: "",
    current_ctc: "",
    skills: [] as string[],
    dream_company: "",
    preferred_role: "",
    college: "",
    year_of_passing: "",
    leetcode: "",
  })
  const [profileImage, setProfileImage] = React.useState<any>(() => student?.photo)
  const [profileDataLoading, setProfileDataLoading] = React.useState<any>(false)
  const [resumes, setResumes] = React.useState<any>([])

  const navigate = useNavigate()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const SKILLS_LIST = [
    "Front-end",
    "Back-end",
    "Full-stack",
    "ReactJS",
    "NodeJS",
    "MongoDB",
    "R",
    "Python",
    "SQL",
    "Spreadsheet",
    "Tableau",
    "Power BI",
    "Cloud computing",
    "Machine learning",
    "Deep Learning",
    ".NET",
    "Java",
    "SpringBoot",
    "C#",
    "AWS",
    "None",
  ]

  const handleUpdateProfileData = (e: any) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value })
  }

  const updateProfileUseCase = new UpdateProfile(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const uploadProfilePictureUseCase = new UploadProfilePicture(
    new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl())
  )

  const getStudentProfileDetailsUseCase = new GetStudentProfileDetails(
    new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl())
  )

  const getResumeUseCase = new GetResume(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const fetchResumes = async () => {
    const response = await getResumeUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(
        STR_FAILURE,
        (typeof response?.data === "string" && response?.data) ||
          (typeof response?.data === "string" && response?.error) ||
          "Error in fetching resumes!"
      )
      changeToastVisibility(true)
      return
    }

    setResumes(response?.data)
  }

  const handleSubmitProfile = async (e: any) => {
    e.preventDefault()

    const response = await updateProfileUseCase.invoke(auth, profileData)

    if (response?.success) {
      changeToastDetails(STR_SUCCESS, "Profile Updated Successfully")
      changeToastVisibility(true)
      await delay(2000)
      navigate("/placement")
    }
  }

  const uploadProfilePicture = async (e: any) => {
    const { files } = e.target
    if (files && files.length > 0) {
      const file = files[0]

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => e.target && setProfileImage(e.target.result)

      const response = await uploadProfilePictureUseCase.invoke(auth, files[0])

      if (response?.success) {
        changeToastDetails(STR_SUCCESS, "Profile Picture Updated Successfully")
        changeToastVisibility(true)
        await fetchStudentProfileDetails()
        changeToastVisibility(false)
      }
    }
  }

  const handleSkillChange = (index: any) => {
    const i = profileData.skills?.indexOf(SKILLS_LIST[index])
    const skills = profileData.skills ? [...profileData.skills] : []

    if (i === -1) {
      skills.push(SKILLS_LIST[index])
    } else {
      skills.splice(i, 1)
    }

    setProfileData({ ...profileData, skills: skills || [] })

    handleUpdateProfileData({
      target: {
        name: "skills",
        value: skills,
      },
    })
  }

  const handleRedirectToResumePage = () => {
    navigate("/placement/edit/resume")
  }

  const fetchStudentProfileDetails = async () => {
    setProfileDataLoading(true)
    const response = await getStudentProfileDetailsUseCase.invoke(auth)
    setProfileDataLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error in fetching profile details!")
      changeToastVisibility(true)
      return
    }

    setProfileData(response?.data)
  }

  return {
    toast,
    resumes,
    SKILLS_LIST,
    profileData,
    profileImage,
    profileDataLoading,
    changeToastVisibility,
    uploadProfilePicture,
    handleUpdateProfileData,
    handleSubmitProfile,
    handleSkillChange,
    handleRedirectToResumePage,
    fetchStudentProfileDetails,
    fetchResumes,
  }
}
