import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"
import { loadNextData } from "domain/model/StudentDashboard"

export interface getUserDataUseCase {
  invoke(details: loadNextData): Promise<any>
}

export class GetAllStudentData implements getUserDataUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: loadNextData): Promise<any> {
    return await this.repository.getUserData(details)
  }
}
