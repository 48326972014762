import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface SctatchCardUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class ScratchCardDetails implements SctatchCardUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.scratchCardDetails(auth, data)
  }
}
