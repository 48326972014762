export default function Rewards({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5677_26486)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.71094 2.33203C3.71094 2.05589 3.9348 1.83203 4.21094 1.83203H11.7859C12.0621 1.83203 12.2859 2.05589 12.2859 2.33203V6.59453C12.2859 8.4414 11.0889 10.0111 9.42838 10.5685C9.44197 10.614 9.44927 10.6622 9.44927 10.712V11.8004H11.2918C11.5679 11.8004 11.7918 12.0242 11.7918 12.3004V13.667C11.7918 13.9432 11.5679 14.167 11.2918 14.167H4.7051C4.42896 14.167 4.2051 13.9432 4.2051 13.667V12.3004C4.2051 12.0242 4.42896 11.8004 4.7051 11.8004H6.55927V10.712C6.55927 10.6634 6.5662 10.6165 6.57913 10.5721C4.91354 10.0179 3.71094 8.44598 3.71094 6.59453V2.33203ZM7.55927 10.7715V12.3004C7.55927 12.5765 7.33541 12.8004 7.05927 12.8004H5.2051V13.167H10.7918V12.8004H8.94927C8.67313 12.8004 8.44927 12.5765 8.44927 12.3004V10.7706C8.33251 10.7804 8.21439 10.7854 8.0951 10.7854H7.90177C7.78646 10.7854 7.67224 10.7807 7.55927 10.7715ZM4.71094 2.83203V6.59453C4.71094 8.35582 6.14117 9.78536 7.90177 9.78536H8.0951C9.85639 9.78536 11.2859 8.35513 11.2859 6.59453V2.83203H4.71094Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.37126 3.71376C1.40676 3.46775 1.61758 3.28516 1.86614 3.28516H4.15031C4.42645 3.28516 4.65031 3.50901 4.65031 3.78516C4.65031 4.0613 4.42645 4.28516 4.15031 4.28516H2.33296C2.33242 4.31401 2.33209 4.34402 2.33204 4.3751C2.33148 4.70399 2.36152 5.13997 2.47871 5.58488C2.59592 6.02984 2.7952 6.4649 3.12027 6.81505C3.43955 7.15897 3.90194 7.44507 4.59297 7.56692C4.86491 7.61487 5.0465 7.8742 4.99854 8.14615C4.95059 8.4181 4.69126 8.59968 4.41931 8.55173C3.52242 8.39358 2.86086 8.00541 2.3874 7.49542C1.91973 6.99166 1.65751 6.39318 1.5117 5.8396C1.36587 5.28596 1.33138 4.75841 1.33204 4.37339C1.33237 4.17966 1.34162 4.01886 1.35101 3.90483C1.3557 3.84774 1.36046 3.80216 1.36418 3.76979C1.36605 3.7536 1.36766 3.7407 1.36888 3.73129C1.3695 3.72659 1.37001 3.72276 1.37041 3.71983C1.37061 3.71837 1.37079 3.71712 1.37093 3.71611L1.37112 3.71476L1.3712 3.71422C1.37123 3.71398 1.37126 3.71376 1.86614 3.78516L1.37126 3.71376ZM11.347 3.78516C11.347 3.50901 11.5708 3.28516 11.847 3.28516H14.1311C14.3797 3.28516 14.5905 3.46775 14.626 3.71376L14.1311 3.78516C14.626 3.71376 14.626 3.71398 14.6261 3.71422L14.6262 3.71476L14.6264 3.71611C14.6265 3.71712 14.6267 3.71836 14.6269 3.71983C14.6273 3.72276 14.6278 3.72659 14.6284 3.73129C14.6296 3.7407 14.6312 3.7536 14.6331 3.76979C14.6368 3.80216 14.6416 3.84774 14.6463 3.90483C14.6557 4.01886 14.6649 4.17966 14.6652 4.37339C14.6659 4.75841 14.6314 5.28596 14.4856 5.8396C14.3398 6.39318 14.0775 6.99166 13.6099 7.49542C13.1364 8.00541 12.4749 8.39358 11.578 8.55173C11.306 8.59968 11.0467 8.4181 10.9987 8.14615C10.9508 7.8742 11.1324 7.61487 11.4043 7.56692C12.0953 7.44507 12.5577 7.15897 12.877 6.81505C13.2021 6.4649 13.4014 6.02984 13.5186 5.58488C13.6358 5.13997 13.6658 4.70399 13.6652 4.3751C13.6652 4.34402 13.6649 4.31401 13.6643 4.28516H11.847C11.5708 4.28516 11.347 4.0613 11.347 3.78516Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5677_26486">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
