import { CrossIcon } from "core/constants/svgs"

export default function EditScheduledClassModel({ isOpen, onClose, children }: any) {
  if (!isOpen) return null
  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.75)] p-6">
          <div className="relative mx-auto w-full max-w-2xl rounded-lg bg-new-solid-white shadow-lg">
            <div className="relative p-6">
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-600 focus:outline-none" onClick={onClose}>
                  <CrossIcon className="h-4 w-4" />
                </button>
              </div>
              <div className="mt-4">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
