import { CrossIcon, StarIcon } from "core/constants/svgs"
import Button from "core/components/new/Button"
export default function DeleteSSMPopUp({ onClose, onDelete, email, data, formatDate }: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-[rgba(0,0,0,0.85)]"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="font-montserrat m-auto flex w-[304px] transform flex-col gap-3 rounded border bg-new-solid-white p-4">
            <div className="flex items-center justify-between py-0">
              <div>
                <h3 className="text-new-failure">Delete Session</h3>
              </div>
              <div>
                <button onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <p className="text-wrap font-medium"> You are about to delete your scheduled session from </p>
            <h4 className="font-montserrat mb-2 w-[218px] font-semibold">
              {" "}
              {formatDate(Object.keys(data)[0], "Scheduled")}
            </h4>
            <div className="flex gap-4">
              <Button
                outlined
                className="h-9 w-[134px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                onClick={onClose}
              >
                No
              </Button>
              <Button
                className="h-9 w-[134px] whitespace-nowrap rounded-sm bg-transparent px-4 py-2"
                onClick={() => {
                  onClose()
                  onDelete(Object.keys(data)[0], email)
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
