import { BATCH_WEEKLY_REPORT } from "core/constants/strings"
import { Server, postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import GetBatchWeeklyReport from "domain/model/GetBatchWeeklyReport"
import BatchWeeklyReportDataSource from "data/dataSource/Admin/BatchWeeklyReportDataSource"
const server = new Server()

export class BatchWeeklyReportAPIDataSourceImpl implements BatchWeeklyReportDataSource {
  async getBatchWeeklyReport(getBatchWeeklyReport: GetBatchWeeklyReport): Promise<any> {
    try {
      const response = await server.post(pn(BATCH_WEEKLY_REPORT), getBatchWeeklyReport, {
        Authorization: `Bearer ${getBatchWeeklyReport.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
