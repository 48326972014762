import { UserSignUp } from "domain/model/UserSignup"
import { UserSignUpRepository } from "domain/repository/Admin/UserSignUpRepository"
import UserSignUpDataSource from "data/dataSource/Admin/UserSignUpDataSourse"

export class UserSignUpRepositoryImpl implements UserSignUpRepository {
  private Datasource: UserSignUpDataSource

  constructor(Datasource: UserSignUpDataSource) {
    this.Datasource = Datasource
  }

  async signup(userSignup: UserSignUp) {
    return await this.Datasource.signup(userSignup)
  }
}
