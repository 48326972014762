// import { getStudentDetails } from "domain/model/StudentDashboard"
import { Auth } from "domain/model/Auth"
import ReferandEarnRepository from "domain/repository/Admin/ReferandEarnRepository"

export interface getTop100ReferandEarnUseCase {
  invoke(auth: Auth): Promise<any>
}
export class GetTop100ReferandEarnRequests implements getTop100ReferandEarnUseCase {
  private repository: ReferandEarnRepository

  constructor(repository: ReferandEarnRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getTop100ReferandEarnRequests(auth)
  }
}
