import { cn } from "core/lib/utils"
import React from "react"

export default function CustomModal({ isOpen, onClose, children, width, height, heading }: any) {
  if (!isOpen) return null
  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.75)]">
          <div
            className={cn(
              "relative mx-auto w-full rounded-lg bg-new-solid-white shadow-lg",
              width ? width : "max-w-2xl"
            )}
          >
            <div className="relative p-6">
              <div className="flex justify-between items-center mb-2">
                <h1 className="text-[24px] text-slate-700">{heading}</h1>
                <button className="text-gray-500 hover:text-gray-600 focus:outline-none" onClick={onClose}>
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
