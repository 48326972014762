import { Auth } from "domain/model/Auth"
import { SessionDetails } from "domain/model/Course"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface GetSSMFeedbackResponseUseCase {
  invoke(auth: Auth, session_id: string, questionName: string, questionType: string): Promise<SessionDetails>
}

export class GetSSMFeedbackResponse implements GetSSMFeedbackResponseUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string, questionName: string, questionType: string) {
    return this.repository.getSSMFeedbackResponse(auth, session_id, questionName, questionType)
  }
}
