import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface CreateSubmissionUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class CreateSubmission implements CreateSubmissionUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.createSubmission(auth, data)
  }
}
