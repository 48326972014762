import React from "react"
import { cn } from "core/lib/utils"

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode
  disabled?: boolean
  className?: string
  outlined?: boolean
  selected?: boolean
}

const TableButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ selected, children, className, outlined, disabled, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          "flex w-full cursor-pointer items-center justify-center rounded-[4px] font-medium",
          "bg-white py-[5px] px-4 text-[12px] font-medium leading-[15px] text-[#646464] hover:bg-[#EBEBEB]",
          disabled && "cursor-not-allowed bg-white text-[#d3d3d3]",
          outlined && "border-[0.5px] border-[#d3d3d3]",
          selected && "bg-new-accent text-[12px] font-medium text-white hover:bg-[#03249A]",
          className
        )}
        {...props}
      >
        {children}
      </button>
    )
  }
)

export default TableButton
