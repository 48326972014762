import { CoinIcon, CopyIcon, DeleteIcon, EditIcon } from "core/constants/svgs"
import CopyButton from "core/components/Button"
import Button from "core/components/new/Button"
import { format } from "date-fns"
import React from "react"
import amazon_vocher from "assets/images/amazon_voucher.png"

export default function CouponCard({ data, handleDeleteModel, handleEditModel }: any) {
  const [copyColor, setCopyColor] = React.useState(false)
  function handleCopyCoupon() {
    setCopyColor(true)
    navigator.clipboard.writeText(data.coupon_id)
  }
  const amountToCoinsMap: { [key: number]: string } = {
    50: "10000",
    75: "15000",
    100: "19000",
    200: "35000",
    500: "90000",
  }
  return (
    <div className="flex h-[162px] overflow-hidden rounded border border-gray-400 px-[32px] py-[24px] shadow-[0px_1px_17px_rgba(0,0,0,0.1)]">
      <div className="h-full w-full max-w-[240px] bg-gray-400">
        <img className="h-full" src={amazon_vocher} alt="" />
      </div>
      <div className="flex w-full justify-between pl-6">
        <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
          <div className="flex justify-between">
            <div className="space-y-[4px]">
              <h6>Reference Id : {data?.reference_id}</h6>
              <h3 className="text-[#312E81]">
                {data?.coupon_type} ({data.amount} Rupees)
              </h3>
              <div className="flex">
                <span className="text-[#A3A3A3]">Date of Purchase / Expiry :</span>
                <span className="">
                  {" "}
                  {format(data?.date_of_purchase * 1000, "do MMM, yyyy")} -{" "}
                  {format(data?.date_of_expiry * 1000, "do MMM, yyyy")}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 self-start">
              <span>
                <CoinIcon className="h-6 w-6" />
              </span>
              <span className="text-xl font-bold text-[#FBBF24]">{amountToCoinsMap[data.amount]}</span>
            </div>
          </div>
          <div className="flex items-end justify-between">
            <div className="flex flex-col gap-y-[2px]">
              <div className="text-[#A3A3A3]">Coupon Code</div>
              <span className="flex w-full max-w-[330px] items-center gap-x-[8px] font-medium text-[#525252]">
                <h4 className="">{data?.coupon_id}</h4>
                <CopyButton
                  outlined
                  onClick={handleCopyCoupon}
                  className={copyColor ? "border-0 px-0 py-0 text-blue-500" : "border-0 px-0 py-0"}
                >
                  <CopyIcon stroke={copyColor ? "#00FF00" : "#0000FF"}></CopyIcon>
                </CopyButton>
              </span>
            </div>
            <div className="flex gap-2">
              <Button outlined className="h-fit w-fit" onClick={() => handleEditModel(data)}>
                <EditIcon className="w-5" />
              </Button>
              <Button
                outlined
                className="h-fit w-fit border-red-500 text-red-500 hover:bg-red-500"
                onClick={() => handleDeleteModel(data)}
              >
                <DeleteIcon className="w-5" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
