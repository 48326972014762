import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-phone-number-input/style.css"
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "core/context/auth"
import { AppProvider } from "core/context/app/AppContext"
import { HelmetProvider } from "react-helmet-async"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AppProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </AppProvider>
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>
)
