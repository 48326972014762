import { NewUpdates } from "domain/model/NewUpdates"
import { NewUpdatesRepository } from "domain/repository/Student/NewUpdatesRepository"

export interface NewUpdatesCallUseCase {
  invoke(newUpdates: NewUpdates): Promise<any>
}
export class NewUpdatesClass implements NewUpdatesCallUseCase {
  private repository: NewUpdatesRepository
  constructor(repository: NewUpdatesRepository) {
    this.repository = repository
  }
  async invoke(newUpdates: NewUpdates) {
    return await this.repository.newUpdates(newUpdates)
  }
}
