import { ModuleClasses } from "domain/model/CreateClass"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface getModuleClassesUseCase {
  invoke(request: ModuleClasses): Promise<any>
}

export class GetModuleClasses implements getModuleClassesUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }

  async invoke(request: ModuleClasses): Promise<any> {
    return await this.repository.getModuleClasses(request)
  }
}
