import { cn } from "core/lib/utils"

export default function EMIOptionCard({ index, month, onView, onChoose, active, checked, disabled }: any) {
  const handleClick = () => {
    onView(index)
  }

  const handleCheckboxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onChoose(index)
  }

  return (
    <div
      className={cn(
        "flex text-sm text-[#414141] px-4 py-3 mb-1 w-[100%] rounded shadow-[0_4px_5px_0_rgba(133,133, 133, 0.06)] gap-6 ",
        active ? "border-[#312E81] border-[2px]" : "border-[#ADADAD] border-[0.5px]",
        disabled && !active ? "opacity-30" : "opacity-1"
      )}
      onClick={handleClick}
    >
      <div className="relative">
        <div className="absolute top-0 left-0">
          {checked ? (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3333 2H2.66667C2.29848 2 2 2.29848 2 2.66667V13.3333C2 13.7015 2.29848 14 2.66667 14H13.3333C13.7015 14 14 13.7015 14 13.3333V2.66667C14 2.29848 13.7015 2 13.3333 2ZM12.0487 5.9527L7.33533 10.6667L4.50667 7.83804L5.44933 6.89537L7.33533 8.78137L11.106 5.01004L12.0487 5.9527Z"
                fill="#312E81"
              />
            </svg>
          ) : (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3333 2H2.66667C2.29848 2 2 2.29848 2 2.66667V13.3333C2 13.7015 2.29848 14 2.66667 14H13.3333C13.7015 14 14 13.7015 14 13.3333V2.66667C14 2.29848 13.7015 2 13.3333 2ZM3.33333 12.6667V3.33333H12.6667V12.6667H3.33333Z"
                fill="#ADADAD"
              />
            </svg>
          )}
        </div>
        <input
          type="checkbox"
          className="mr-2 absolute top-0 left-0 h-4 w-4 opacity-0 cursor-pointer"
          onChange={handleCheckboxToggle}
          checked={checked}
          disabled={disabled}
        />
      </div>

      <div>
        <div className="font-[#414141] font-semibold">EMI {month} months:</div>
        <div className="">Easy EMI loan through our NBFC partners:</div>
      </div>
    </div>
  )
}
