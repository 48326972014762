import { UpdateSSMCall } from "domain/model/UpdateSSMCall"
import { UpdateSSMCallRepository } from "domain/repository/Admin/UpdateSSMCallRepository"

export interface UpdateSSMCallUseCase {
  invoke(updateSSMCall: UpdateSSMCall): Promise<any>
}

export class UpdateSSMCalls implements UpdateSSMCallUseCase {
  private repository: UpdateSSMCallRepository

  constructor(repository: UpdateSSMCallRepository) {
    this.repository = repository
  }

  async invoke(updateSSMCall: UpdateSSMCall) {
    return await this.repository.updateSSMCall(updateSSMCall)
  }
}
