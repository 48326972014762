import { Fragment, useEffect, useState } from "react"

export default function ResponseView({
  response,
  solution,
  activeCasestudyQuestionIndex,
  activeResposeTab,
  handleSolutionChange,
}: any) {
  const [currentResponse, setCurrentResponse] = useState<any>({})

  useEffect(() => {
    const sortedResponse = response?.sort((a: any, b: any) => a.order - b.order)
    if (sortedResponse) {
      setCurrentResponse(sortedResponse[activeResposeTab])
    }
  }, [activeResposeTab, response])

  return (
    <div className="realtive p-8 flex flex-col gap-6">
      <div className="flex flex-col gap-4 border border-new-neutral-light bg-new-editor-dark-500 p-4 rounded-sm">
        <h3 className="mb-4">{currentResponse?.subTitle}</h3>
        <p>
          <strong>Problem Statement:</strong>
        </p>
        <p className="leading-relaxed text-justify">{currentResponse?.content}</p>
        {currentResponse?.fields?.map((field: any, index: number) => {
          if (field?.type === "text") {
            return <p className="leading-relaxed text-justify">{field?.content}</p>
          } else if (field?.type === "image") {
            return (
              <img
                src={field?.content}
                alt="img"
                title={field?.filename}
                onClick={() => window.open(field?.content)}
                className="cursor-pointer rounded-sm w-fit"
              />
            )
          } else if (field?.type === "code") {
            return <pre className="p-2 bg-new-editor-dark-400 rounded-sm">{field?.content}</pre>
          }
          return <></>
        })}
      </div>
      {currentResponse?.questionType === "MCQ" && currentResponse?.options ? (
        <Options
          options={currentResponse?.options}
          responseId={activeResposeTab}
          handleOptionChange={handleSolutionChange}
          solution={solution}
          activeCasestudyQuestionIndex={activeCasestudyQuestionIndex}
          questionId={currentResponse?.id}
        />
      ) : (
        <Fragment>
          <p>Write your solution here:</p>
          <textarea
            maxLength={2000}
            className="bg-new-editor-dark-500 h-80 max-h-96 focus:border-none focus:ring-0 rounded-sm"
            value={solution?.[activeCasestudyQuestionIndex]?.[currentResponse?.id] || ""}
            onChange={(e) => handleSolutionChange(e, "subjective", currentResponse?.id)}
          />
        </Fragment>
      )}
    </div>
  )
}

function Options({ options, handleOptionChange, solution, activeCasestudyQuestionIndex, responseId, questionId }: any) {
  return (
    <div className="flex flex-col gap-4 border border-new-neutral-light p-6 bg-new-editor-dark-500 rounded-sm">
      {Object.values(options).map((option: any, id: number) => {
        return (
          <div key={id} className="w-full flex gap-4 justify-between">
            <div className="flex gap-2">
              <p>{id + 1}.</p>
              <p>
                {option?.map((field: any, index: number) => {
                  if (field?.type === "text") {
                    return <p>{field.content}</p>
                  } else if (field?.type === "code") {
                    return <pre className="p-2 bg-new-editor-dark-400 rounded-sm">{field?.content}</pre>
                  } else {
                    return (
                      <img
                        src={field?.content}
                        alt="img"
                        title={field?.filename}
                        onClick={() => window.open(field?.content)}
                        className="cursor-pointer rounded-sm"
                      />
                    )
                  }
                })}
              </p>
            </div>
            <input
              type="checkbox"
              name="answer"
              className="bg-opacity-0 px-2 py-2 border-white bg-new-editor-dark-300"
              value={id + 1}
              checked={solution?.[activeCasestudyQuestionIndex]?.[questionId]?.[id + 1] ? true : false}
              onChange={(e) => handleOptionChange(e, "mcq", questionId)}
            />
          </div>
        )
      })}
    </div>
  )
}
