import React, { Component } from "react"
import GetAllClassesViewModel from "./GetAllClassesViewModel"
import { useNavigate, useParams } from "react-router-dom"
import { format } from "date-fns"
import Loader from "core/components/Loader"
import DashboardLayout from "core/layouts/DashboardLayout"
import Model from "core/components/Model"
import RatingScaleView from "./RatingScaleView"
import { isEmpty } from "core/utils/misc"
import GetAllClassesView from "./GetAllClassesView"
import Button from "core/components/new/Button"

export default function DetailedRatingView() {
  const { ClassAllRating, fetchAllClassRatingDetails } = GetAllClassesViewModel()
  const { id } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    fetchAllClassRatingDetails(id as string)
  }, [id])

  const [studentData, setStudentData] = React.useState(null)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const toggleModal = (item?: any) => {
    setIsModalOpen(!isModalOpen)
    if (!isEmpty(item)) setStudentData(item)
  }

  const redirectToAllClassses = () => {
    navigate(`/admin/all-classes/`)
  }

  if (!ClassAllRating || !ClassAllRating.detailed_ratings) {
    return (
      <DashboardLayout>
        <Loader />
      </DashboardLayout>
    )
  } else if (ClassAllRating.detailed_ratings.length === 0) {
    return (
      <DashboardLayout>
        <h1 className="">No Data Found</h1>
      </DashboardLayout>
    )
  }
  return (
    <DashboardLayout>
      <div className="flex flex-col">
        <Button className="fixed right-5 top-1 m-2 h-[37px] w-fit" onClick={redirectToAllClassses}>
          Back
        </Button>
        {ClassAllRating?.detailed_ratings?.map((item: any, i: number) => (
          <div
            key={i}
            className="mb-4 mt-4 mr-5 ml-5 flex flex-col rounded-lg bg-gray-100 shadow-md "
            // style={{ padding: '2rem', margin: '2rem' }}
          >
            <div className="flex flex-col">
              <div className="flex justify-between px-6 py-4 text-new-neutral-dark shadow-md">
                <div className="flex items-end gap-x-6">
                  <div className="space-y-1">
                    <h4 className="mb-1">{item.student_name}</h4>
                    <div className="flex gap-x-2">
                      <p className="">{item.student_email}</p>
                      <p className="">{item.batch}</p>
                    </div>
                    <p className="">{format(parseInt(item.timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}</p>
                  </div>
                  {item.average_student_rating != 0 ? (
                    <div className="inline-flex items-baseline gap-x-1">
                      <h3 className="text-new-success">{item.average_student_rating}/5</h3>
                      <p className="">Average Student Rating</p>
                    </div>
                  ) : (
                    <div className="inline-flex items-baseline gap-x-1"> Not Rated</div>
                  )}
                </div>
                <div className="flex gap-x-12">
                  <div className="">
                    <p className="">{item.status}</p>
                  </div>

                  <div className="flex flex-col gap-y-5">
                    <div className="flex gap-x-2">
                      <p className="">{item.total}/25</p>
                      {item.live_check == true ? "Live" : "Recorded"}
                    </div>
                    <Button onClick={() => toggleModal(item)}>Rating Scale</Button>
                  </div>
                </div>
              </div>
              <div className="px-4 py-4">
                <h3>Suggestion</h3>
                <p>{item.suggestion}</p>
              </div>
            </div>
          </div>
        ))}
        <Model isOpen={isModalOpen} onClose={toggleModal} width={"max-w-5xl"}>
          <RatingScaleView data={studentData} isModalOpen={isModalOpen} toggleModal={toggleModal} />
        </Model>
      </div>
    </DashboardLayout>
  )
}
