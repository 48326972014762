import Button from "core/components/new/Button"
import Toggle from "core/components/Toggle"
import { EyeIcon } from "core/constants/svgs"
import { useState } from "react"
import CustomModel from "./CustomModel"
export default function ApproveTable(props: any) {
  const [model, setModel] = useState(false)
  const [reason, setReason] = useState("")
  const handleModalClose = () => setModel(!model)

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <CustomModel isOpen={model} heading={"Reason"} onClose={handleModalClose}>
        <p>{reason}</p>
      </CustomModel>
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {!props.studentContests?.length && !props.loading && <div className="m-2">No data found</div>}
        <tbody>
          {props.studentContests?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4 ">
                <p>{data.student.name}</p>
                <p className="font-medium">{data.student.email}</p>
                <p> {data.student.phone}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.student.batch}</td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.name}</td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setReason(data?.approval_reason)
                    setModel(true)
                  }}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
              <td className="px-6 py-4">
                <Toggle
                  checked={data.is_approved}
                  onChange={() => {
                    props.approveForContest({
                      email: data.student.email,
                      contest_id: data.contest_id,
                    })
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
