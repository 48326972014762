import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface GetInstructorAverageRatingUseCase {
  invoke(auth: Auth, instructor_id: string): Promise<any>
}

export default class GetInstructorAverageRating implements GetInstructorAverageRatingUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, instructor_id: string) {
    return await this.repository.getInstructorAverageRating(auth, instructor_id)
  }
}
