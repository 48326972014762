import React from "react"

interface OptionFieldProps {
  icon: React.ComponentType // Expecting a React component
  text: string // Text remains a string
  onClick: React.MouseEventHandler<HTMLDivElement>
  selected: boolean
}

const OptionField: React.FC<OptionFieldProps> = ({ icon: Icon, text, onClick, selected }) => {
  return (
    <div
      onClick={onClick}
      className={`w-[112px] cursor-pointer mx-3 h-[68px] p-[12px_16px] gap-[4px] rounded-[4px] font-inter text-[12px] font-semibold leading-[12.1px] text-center flex flex-col items-center ${
        selected ? "bg-[#163756] text-white" : "bg-[#FFFFF] text-[#163756]"
      }`}
    >
      <Icon />
      <div>{text}</div>
    </div>
  )
}

export default OptionField
