import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"

export interface CreatePledgeResponseUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}

export class CreatePledgeResponse implements CreatePledgeResponseUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string) {
    return this.repository.createPledgeResponse(auth, email)
  }
}
