export default function MentorGlobe({ className }: any) {
  return (
    <svg
      className={className}
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5047 33.482C36.4743 33.482 43.7457 26.2106 43.7457 17.241C43.7457 8.27134 36.4743 1 27.5047 1C18.535 1 11.2637 8.27134 11.2637 17.241C11.2637 26.2106 18.535 33.482 27.5047 33.482Z"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2863 27.2719H33.1799C33.589 27.2719 33.9207 26.9403 33.9207 26.5311V21.9401C33.9207 20.5968 32.8317 19.5078 31.4884 19.5078H23.524C22.1807 19.5078 21.0918 20.5968 21.0918 21.9401V26.5311C21.0918 26.9403 21.4234 27.2719 21.8326 27.2719H25.1898"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.334 15.4473C31.68 16.3566 32.565 17.8965 32.565 19.6432"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4434 19.644C22.4434 17.8287 23.3993 16.2367 24.8352 15.3438"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5015 16.257C25.3595 16.257 23.623 14.5205 23.623 12.3787V9.85831C23.623 8.39402 24.81 7.20703 26.2743 7.20703H28.7284C30.1927 7.20703 31.3797 8.39402 31.3797 9.85831V12.3787C31.3798 14.5205 29.6434 16.257 27.5015 16.257Z"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.623 11.1836C25.1657 11.5223 26.6261 11.5182 27.8553 10.5498C28.3268 10.1783 28.9678 10.1044 29.5156 10.3496L31.3798 11.1834"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5165 54.0571H13.0627C13.4718 54.0571 13.8035 53.7254 13.8035 53.3163V48.7253C13.8035 47.3819 12.7145 46.293 11.3712 46.293H3.40679C2.06348 46.293 0.974609 47.3819 0.974609 48.7253V53.3163C0.974609 53.7254 1.30625 54.0571 1.7154 54.0571H8.42009"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2168 42.2324C11.5628 43.1418 12.4478 44.6817 12.4478 46.4284"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.32617 46.4292C2.32617 44.6138 3.28211 43.0218 4.71799 42.1289"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.38427 43.0421C5.2423 43.0421 3.50586 41.3057 3.50586 39.1638V36.6435C3.50586 35.1792 4.69285 33.9922 6.15713 33.9922H8.6112C10.0755 33.9922 11.2625 35.1792 11.2625 36.6435V39.1638C11.2626 41.3058 9.52624 43.0421 7.38427 43.0421Z"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.50586 37.9687C5.04851 38.3075 6.50895 38.3033 7.73812 37.3349C8.20963 36.9634 8.85058 36.8896 9.39843 37.1347L11.2626 37.9686"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7509 54.0571H53.297C53.7062 54.0571 54.0378 53.7254 54.0378 53.3163V48.7253C54.0378 47.3819 52.9489 46.293 51.6056 46.293H43.6412C42.2979 46.293 41.209 47.3819 41.209 48.7253V53.3163C41.209 53.7254 41.5406 54.0571 41.9498 54.0571H48.6545"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.4512 42.2324C51.7971 43.1418 52.6822 44.6817 52.6822 46.4284"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5605 46.4292C42.5605 44.6138 43.5165 43.0218 44.9524 42.1289"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.6186 43.0421C45.4767 43.0421 43.7402 41.3057 43.7402 39.1638V36.6435C43.7402 35.1792 44.9272 33.9922 46.3915 33.9922H48.8456C50.3099 33.9922 51.4968 35.1792 51.4968 36.6435V39.1638C51.4971 41.3058 49.7606 43.0421 47.6186 43.0421Z"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.7402 37.9687C45.2829 38.3075 46.7433 38.3033 47.9725 37.3349C48.444 36.9634 49.085 36.8896 49.6328 37.1347L51.497 37.9686"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4746 33.4824V44.2988"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3808 49.9727H14.123"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8414 49.9727H33.3809"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6543 49.9727H28.2919"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5401 52.6129C22.0035 52.6129 21.5684 52.1779 21.5684 51.6412V48.3032C21.5684 47.7666 22.0034 47.3315 22.5401 47.3315H24.3483C24.764 47.3315 25.1009 46.9945 25.1009 46.5789V45.0515C25.1009 44.6358 25.4379 44.2988 25.8536 44.2988H29.0912C29.5069 44.2988 29.8439 44.6358 29.8439 45.0515V46.5789C29.8439 46.9945 30.1809 47.3315 30.5965 47.3315H32.298C32.8346 47.3315 33.2697 47.7665 33.2697 48.3032V51.6412C33.2697 52.1778 32.8347 52.6129 32.298 52.6129H22.5401Z"
        stroke="#319BD3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
