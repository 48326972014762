export default function RightArrow({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M10.782 8.66688L2.66732 8.66688L2.66732 7.33355L10.782 7.33355L7.20599 3.75755L8.14865 2.81488L13.334 8.00021L8.14865 13.1855L7.20598 12.2429L10.782 8.66688Z"
        fill="white"
      />
    </svg>
  )
}
