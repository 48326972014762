import React from "react"
import CopyIcon from "assets/svgs/CopyIcon"
import Button from "core/components/new/Button"
import { format } from "date-fns"
import { useLocation } from "react-router-dom"
import { Tooltip } from "@mui/material"

const ContestSubmissionCard = ({
  contestData,
  batch,
  studentData,
  handleSubmissionClick,
  handleSSMFeedback,
  handleCopyToClipboard,
  buttonStatus,
  fetchContestSSMAndSubmissionStatus,
  index,
  refreshed,
}: any) => {
  const location = useLocation()

  React.useEffect(() => {
    fetchContestSSMAndSubmissionStatus(studentData["student_email"], location.pathname.split("/")[3], index)
  }, [refreshed, studentData])

  return (
    <div className="p-6 text-new-neutral-dark shadow">
      <div className="flex items-center">
        <div className="cursor-pointer">
          <button
            className="flex items-center justify-center"
            title={studentData["email"]}
            onClick={() => handleCopyToClipboard(studentData["student_email"])}
          >
            <CopyIcon />
          </button>
        </div>
        <h3 className="ml-2">{studentData["student_name"]}</h3>
      </div>
      <div className="mt-2">
        <h4>{studentData["student_batch"]}</h4>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex flex-wrap items-center">
          {/* <p className="text-[#ADADAD] font-semibold">{studentData["contest_name"]}</p> */}
          {/* <span className="mx-1 text-[#ADADAD]">|</span> */}
          {/* <p className="text-[#ADADAD] font-semibold">
            {format(
              parseInt(studentData["contest_date"]) * 1000,
              "do MMMM, yyyy | hh:mm a"
            )}
          </p> */}
        </div>
        <div className="flex space-x-4">
          {buttonStatus?.["ssmEnabled"] ? (
            <Button
              outlined
              className="w-fit"
              onClick={() => handleSSMFeedback(studentData["student_email"])}
              disabled={!buttonStatus?.["ssmEnabled"]}
            >
              SSM Feedback
            </Button>
          ) : (
            <Tooltip title="No Feedback Exists" placement="bottom">
              <span>
                <Button
                  outlined
                  className="w-fit"
                  onClick={() => handleSSMFeedback(studentData["student_email"])}
                  disabled={!buttonStatus?.["ssmEnabled"]}
                >
                  SSM Feedback
                </Button>
              </span>
            </Tooltip>
          )}
          <Button
            outlined
            className="w-fit"
            onClick={() => handleSubmissionClick(location.pathname, studentData["student_email"])}
          >
            View Submissions
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ContestSubmissionCard
