export function getTotal(current: number, gap: number = 100) {
  return Math.floor((current + gap) / gap) * gap
}

export function convertToAddress(address: any) {
  const parts = [
    address?.flat && `Flat no.-${address.flat}`,
    address?.block && `Block ${address.block}`,
    address?.nearby_landmark,
    address?.area,
    address?.city,
    address?.pincode,
    address?.state,
    address?.country,
  ].filter(Boolean)

  return parts.join(", ")
}

export function isEmpty(val: any) {
  if (val == null) return true
  if (typeof val === "string" || Array.isArray(val)) return val.length === 0
  return typeof val === "object" && Object.keys(val).length === 0
}

export function removeAtIndex(array: any[], index: number) {
  return array.filter((_, i) => i !== index)
}
