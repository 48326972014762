import Button from "core/components/new/Button"
import Select from "core/components/new/Select"
import { capitalize } from "core/utils/string"
import { Fragment } from "react"

export default function Fields({
  fields,
  questionType,
  selectedQuestion,
  fieldTypes,
  onFieldChange,
  onDeleteField,
}: any) {
  return (
    <Fragment>
      {fields[selectedQuestion]?.map((field: any, fieldId: number) => (
        <div
          key={fieldId}
          className={`w-full flex flex-col items-start gap-6 ${fields[selectedQuestion]?.length > 0 && "py-2"}`}
        >
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-row justify-between">
              <Select
                className="basis-2/4 border border-new-neutral text-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent cursor-pointer"
                value={field.type || "text"}
                onChange={(e) => onFieldChange(e, questionType, selectedQuestion, fieldId, "type")}
              >
                {fieldTypes.map((type: string, idx: number) => (
                  <option key={idx} value={type}>
                    {capitalize(type)}
                  </option>
                ))}
              </Select>
              <Button
                className="basis-1/4"
                outlined
                failure
                onClick={(e) => {
                  onDeleteField("Remove", questionType, selectedQuestion, fieldId)
                }}
              >
                Delete Field
              </Button>
            </div>
            {FieldView(field, questionType, selectedQuestion, fieldId, onFieldChange)}
            <div className="w-full h-px border border-dashed border-gray-400"></div>
          </div>
        </div>
      ))}
    </Fragment>
  )
}

function FieldView(
  field: any,
  questionType: string,
  selectedQuestion: string,
  fieldId: number,
  onFieldChange: Function
) {
  if (field.type === "text" || field.type === "description") {
    return (
      <textarea
        onChange={(e) => {
          onFieldChange(e, questionType, selectedQuestion, fieldId, "content")
        }}
        value={field.content}
        className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent overflow-auto text-new-netural"
      />
    )
  } else if (field.type === "code") {
    return (
      <textarea
        onChange={(e) => {
          onFieldChange(e, questionType, selectedQuestion, fieldId, "content")
        }}
        value={field.content}
        className="w-full h-[11.0625rem] resize-none p-4 text-new-neutral border border-solid border-new-neutral rounded-sm outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent cursor-pointer overflow-auto text-new-netural"
      />
    )
  } else if (field.type === "image") {
    return (
      <div className="flex flex-row items-start gap-4 text-new-netural">
        <div className="basis-2/5 h-[48px] border border-solid border-new-accent relative overflow-hidden inline-block rounded-sm shadow-sm">
          <div className="flex items-center justify-center h-full w-full text-new-neutral">
            <p className="p-0 text-[1rem]">Upload Image +</p>
          </div>
          <input
            className="opacity-0 absolute top-0 left-0 cursor-pointer w-full h-full"
            type="file"
            name="img"
            id="img"
            accept="image/*"
            onChange={(e) => {
              onFieldChange(e, questionType, selectedQuestion, fieldId, "image")
            }}
          />
        </div>
        <div className="w-full border border-solid border-new-neutral py-3 p-4 rounded-sm">
          <input
            type="text"
            placeholder="img.png"
            disabled
            value={typeof field?.content === "string" ? field.content.split("@")[0] : field.content}
            className="p-0 m-0 w-full border-none"
          />
        </div>
      </div>
    )
  } else if (field.type === "document") {
    return (
      <div className="flex flex-row items-start gap-4 text-new-netural">
        <div className="basis-2/5 h-[48px] border border-solid border-new-accent relative overflow-hidden inline-block rounded-sm shadow-sm">
          <div className="flex items-center justify-center h-full w-full text-new-neutral">
            <p className="p-0 text-[1rem]">Upload Doc +</p>
          </div>
          <input
            className="opacity-0 absolute top-0 left-0 cursor-pointer w-full h-full"
            type="file"
            name="doc"
            id="file"
            accept=".pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => {
              onFieldChange(e, questionType, selectedQuestion, fieldId, "document")
            }}
          />
        </div>
        <div className="w-full border border-solid border-new-neutral py-3 p-4 rounded-sm">
          <input
            type="text"
            placeholder="mydoc.pdf"
            disabled
            value={field.content}
            className="p-0 m-0 w-full border-none"
          />
        </div>
      </div>
    )
  } else {
    return <div className="w-full p-2">No Field is selected!</div>
  }
}
