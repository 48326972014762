import { Auth } from "domain/model/Auth"
import { ClassRepository } from "domain/repository/Mentor/ClassRepository"

export interface GetAllClassesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllClasses implements GetAllClassesUseCase {
  private repository: ClassRepository

  constructor(repository: ClassRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllClasses(auth)
  }
}
