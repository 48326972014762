import { cn } from "core/lib/utils"
import React from "react"

const BronzeMedal = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={cn(className)} viewBox="0 0 125 144" fill="none">
      <path
        d="M63.0031 0L93.9646 17.7522L125 36L125 71.9744V108L93.9646 126.248L63.0031 144L31.5 126L0 108L0 72L0 36L31.3215 17.7522L63.0031 0Z"
        fill="#AB765E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 144L31.5 126L0 108L0 64L31 38L62 66L93 49L86 61L93 96L63 144Z"
        fill="#6B453A"
      />
      <path
        d="M31.0006 36.0034L33.0006 36.0035L62 52L91.0006 36.0037L93.0006 36.0039L93 38L62 55L31.0006 38L31.0006 36.0034Z"
        fill="#F8E2D4"
      />
      <path d="M31 49.0023V38L62 55.0023V66.0023L31 49.0023Z" fill="#B97256" />
      <path d="M93 49.0023V38L62 55.0023V66.0023L93 49.0023Z" fill="#FED3C3" />
      <path d="M93 38L62 66L93 49V38Z" fill="#DBA382" />
      <path d="M31 38L62 66L31 49V38Z" fill="#895150" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34 58.0019L62 77.0136L90 58.0019L93 58V94.0136L62 112.014L31 94.0136L31 58L34 58.0019Z"
        fill="#F8E2D4"
      />
      <path d="M62 79L31 58L31 96L62 114L62 79Z" fill="#B97256" />
      <path d="M62 79L93 58V96L62 114L62 79Z" fill="#FED3C3" />
      <path d="M62 114L31 58L31 96L62 114Z" fill="#895150" />
      <path d="M62 114L93 58V96L62 114Z" fill="#DBA382" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 9L35.291 24.6983L8.0004 41L8.0004 103.507L35.2911 119.302L62.9961 134.996L89.9471 119.33L117 104.01L117 40.5765L89.9471 24.6705L63 9ZM93.9646 17.7522L63.0031 0L31.3215 17.7522L0 36L0 108L16 117L31.5 126L63.0031 144L93.9646 126.248L125 108L125 36L93.9646 17.7522Z"
        fill="#F2B483"
      />
      <path d="M66.002 10.7526L68.9996 3.44141L117.164 31.3945L111 37.0156L66.002 10.7526Z" fill="#F8E2D4" />
      <path d="M63 144V135L8 103.5L8 41L0 36L0 108L63 144Z" fill="#AA6D44" />
    </svg>
  )
}

export default BronzeMedal
