import React from "react"

export default function Placement(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99458 0C9.36249 0 9.66108 0.298592 9.66108 0.6665V1.9995H12.9936C13.3615 1.9995 13.6601 2.29809 13.6601 2.666V11.3305C13.6601 11.6984 13.3615 11.997 12.9936 11.997H0.996578C0.62867 11.997 0.330078 11.6984 0.330078 11.3305V2.666C0.330078 2.29809 0.62867 1.9995 0.996578 1.9995H4.32908V0.6665C4.32908 0.298592 4.62767 0 4.99558 0H8.99458ZM9.66108 3.3325H4.32908V10.664H9.66108V3.3325ZM1.66308 3.3325V10.664H2.99608V3.3325H1.66308ZM8.32808 1.333H5.66208V1.9995H8.32808V1.333ZM10.9941 3.3325V10.664H12.3271V3.3325H10.9941Z"
        fill="currentColor"
      />
    </svg>
  )
}
