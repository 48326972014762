import MonthlyFeedbackRepository from "domain/repository/Student/MonthlyFeedbackRepository"

export interface SubmitMonthlyFeedbackUseCase {
  invoke(auth: any, data: any): Promise<any>
}

export class SubmitMonthlyFeedback implements SubmitMonthlyFeedbackUseCase {
  private readonly repository: MonthlyFeedbackRepository

  constructor(repository: MonthlyFeedbackRepository) {
    this.repository = repository
  }

  async invoke(auth: any, data: any): Promise<any> {
    return await this.repository.submitMonthlyFeedback(auth, data)
  }
}
