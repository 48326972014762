import React from "react"
import Button from "core/components/Button"
import Cross from "assets/svgs/Cross"
import { capitalize } from "../../../../core/utils/string"

import useStudentProfileViewModel from "../StudentProfileViewModel"

export default function TransactionDetailPopUp(props: any) {
  function handleCross() {
    props.setUpdateTransactionPopup(false)
    props.handlePaymentViewDetails({})
  }
  function handleUpdateTransactionDetail() {
    props.setOpenPaymentViewDetail(false)
    props.setUpdateTransactionPopup(true)
  }

  const formatDate = (dateTimeString: string) => {
    const date = new Date(dateTimeString)
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    return `${formattedDate}, ${formattedTime}`
  }

  function formatPaidAt(timestamp: any): string {
    const date = new Date(parseInt(timestamp) * 1000)
    const day = date.getDate()
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
    const monthIndex = date.getMonth()
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, "0")

    if (props.instalmentPayInfo.paymentInfo.payment_type !== "seat_booking") {
      return `${day} ${monthNames[monthIndex]}, ${year}, ${hours}:${minutes}`
    }
    return `${day} ${monthNames[monthIndex]},${year}`
  }

  function formatDueDateWithOutGMT(dueTime: string): string {
    const dueDateParts = dueTime.split(" ")
    return dueDateParts.slice(1, 4).join(" ")
  }

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto">
          <div className=" m-auto flex w-[593px] transform justify-between  rounded border border-new-accent bg-new-solid-white p-[24px] font-medium">
            <div className="flex w-[162px]  justify-between">
              <div className="w-all flex h-[200px]  flex-col items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-[18px] font-bold text-[#312E81]">
                    {props.instalmentPayInfo.paymentInfo.payment_type === "seat_booking" ? (
                      <>Seat Booking</>
                    ) : props.instalmentPayInfo.paymentInfo.payment_type === "refund" ? (
                      <>Refund {props.instalmentPayInfo.paymentIdx}</>
                    ) : (
                      <> {`Instalment ${props?.instalmentPayInfo?.paymentIdx}`}</>
                    )}
                  </div>
                  <p className="text-[14px] text-[#ADADAD]">
                    Sr. No. :{" "}
                    <span className="text-[14px] font-semibold text-[#414141]">{`00${props?.instalmentPayInfo?.index}`}</span>
                  </p>
                </div>
                <div className="flex justify-center">
                  {/* {props.instalmentPayInfo.paymentInfo.status !== "paid" && ( */}
                  <Button
                    onClick={handleUpdateTransactionDetail}
                    className="flex w-[119px] items-center justify-center border-[#312E81] text-[#312E81] "
                  >
                    Update
                  </Button>
                  {/* )} */}
                </div>
              </div>
              {/* Overlaying horizontal line */}
              <div className="fixed top-0 bottom-0 left-[165px] h-full border-[0.063px] border-[#F5F5F5]"></div>
            </div>

            <div className="flex w-[431px] justify-between pl-[24px] ">
              <div className="flex w-[347px] flex-col ">
                <div className="font-montserrat text-base-600 flex justify-start  font-semibold  text-[#414141]">
                  Transaction Detail
                </div>
                <div className="w-all mt-3 flex flex-col gap-[8px]">
                  <div className="flex justify-between">
                    <span className="text-[12px] font-normal text-[#414141]">Reference No. :</span>
                    <span className="h-[19px] w-[150px] text-left text-[14px]  font-semibold text-[#6B6B6B]">
                      {props.instalmentPayInfo?.paymentInfo?.invoice_number}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[12px] font-normal text-[#414141]">Payment Status:</span>
                    <span
                      className={`h-[19px] w-[150px] text-left text-[14px] font-semibold  ${
                        props.instalmentPayInfo.paymentInfo.status === "paid"
                          ? "text-[#00C22B]"
                          : props.instalmentPayInfo.paymentInfo.status === "refund"
                            ? "text-[#DC2626]"
                            : "text-[#6B6B6B]"
                      }`}
                    >
                      {capitalize(props.instalmentPayInfo.paymentInfo.status)}
                    </span>
                  </div>
                  {(props.instalmentPayInfo.paymentInfo.status === "paid" ||
                    props.instalmentPayInfo.paymentInfo.status === "refund") && (
                    <div className="flex justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">
                        {props.instalmentPayInfo.paymentInfo.status === "paid" ? "Received On: " : "Refund On: "}
                      </span>
                      <span className="h-[19px] w-[150px] text-left text-[14px] font-semibold text-[#6B6B6B]">
                        {props.instalmentPayInfo.paymentInfo.status === "paid"
                          ? formatPaidAt(props.instalmentPayInfo.paymentInfo.paid_at)
                          : formatDate(props.instalmentPayInfo.paymentInfo.refund_time)}
                      </span>
                    </div>
                  )}

                  <div className="flex justify-between">
                    <span className="text-[12px] font-normal text-[#414141]">Payment ID :</span>
                    <span className="h-[19px] w-[150px] text-left text-[14px] font-semibold  text-[#6B6B6B]">
                      {props.instalmentPayInfo.paymentInfo.payment_id !== undefined
                        ? props.instalmentPayInfo.paymentInfo.payment_id
                        : props.instalmentPayInfo.paymentInfo.status === "refund"
                          ? props.instalmentPayInfo.paymentInfo.refund_id
                          : "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[12px] font-normal text-[#414141]">Amount ( Rs. ) :</span>
                    <span className="h-[19px] w-[150px] text-left text-[14px] font-semibold text-[#6B6B6B]">{`${props.instalmentPayInfo.paymentInfo.amount}`}</span>
                  </div>
                  {props.instalmentPayInfo.paymentInfo.status !== "refund" && (
                    <div className="flex justify-between">
                      <span className="text-[12px] font-normal text-[#414141]">Due date :</span>
                      <span className="h-[19px] w-[150px] text-left text-[14px] font-semibold text-[#6B6B6B]">{`${formatDueDateWithOutGMT(
                        `${props.instalmentPayInfo.paymentInfo.due_time}`
                      )}`}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-[12px] font-normal text-[#414141]">Payment method :</span>
                    <span className="h-[19px] w-[150px] text-left text-[14px] font-semibold  text-[#6B6B6B]">
                      {props.instalmentPayInfo.paymentInfo.payment_method !== undefined
                        ? capitalize(props.instalmentPayInfo.paymentInfo.payment_method)
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>

              <button
                className="text-500 h-[24px] w-[24px] text-[#312E81] hover:text-gray-600 focus:outline-none"
                onClick={() => handleCross()}
              >
                <Cross />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
