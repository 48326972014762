import { Auth } from "domain/model/Auth"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export interface GetMentorMenteeUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetMentorMentee implements GetMentorMenteeUseCase {
  private repository: MyMenteeRepository

  constructor(repository: MyMenteeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getMentorMentee(auth)
  }
}
