import React from "react"

const LinearGradientTopLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="123" viewBox="0 0 128 123" fill="none">
      <path d="M54.194 -5H129.861L75.6671 123H0L54.194 -5Z" fill="url(#paint0_linear_2314_62108)" />

      <path d="M89.3327 -5H165L110.806 123H35.1387L89.3327 -5Z" fill="url(#paint1_linear_2314_62108)" />

      <defs>
        <linearGradient
          id="paint0_linear_2314_62108"
          x1="123.726"
          y1="-98.281"
          x2="-40.1031"
          y2="41.886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DFEEFF" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2314_62108"
          x1="158.865"
          y1="-98.281"
          x2="-4.96447"
          y2="41.886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DFEEFF" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LinearGradientTopLeft
