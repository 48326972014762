import { Auth } from "domain/model/Auth"
import { ProjectSectionRepository } from "domain/repository/Admin/ProjectSectionRepository"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface GetOrderHistoryUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetOrderHistory implements GetOrderHistoryUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.getOrderHistory(auth, data)
  }
}
