import React from "react"

const QuestionInfo = ({ questions, selectedQuestion }: any) => (
  <div className="my-2">
    <h3 className="text-lg font-semibold">
      {selectedQuestion + 1}. {questions?.problem_name}
    </h3>
    <div className="flex text-base space-x-2">
      <p className="font-semibold">Max Marks : </p>
      <p>{questions?.maxMarks}</p>
      <div className="flex justify-between space-x-1">
        <p className="font-semibold">Difficulty Level : </p>
        <p
          className={`${
            questions?.difficulty_level === "easy"
              ? "text-emerald-500"
              : questions?.difficulty_level === "medium"
                ? "text-orange-500"
                : "text-red-500"
          } capitalize`}
        >
          {questions?.difficulty_level}
        </p>
      </div>
    </div>
  </div>
)

const ProblemStatement = ({ questions }: any) => (
  <div className="p-4 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
    <h4 className="py-2 text-new-neutral-light">Problem Statement</h4>
    <div
      className="text-new-neutral-light break-words"
      dangerouslySetInnerHTML={{
        __html: questions?.question_details,
      }}
    ></div>
  </div>
)

const SolutionTabs = ({ questions }: any) => (
  <div className="space-y-2 ">
    {questions?.solution?.map((solution: any, index: any) => (
      <div key={index} className="py-4 px-2">
        <h4 className="py-2 text-new-neutral-light">{solution.heading}</h4>
        <div className="bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 overflow-x-auto p-2 rounded">
          <pre
            className="text-new-neutral-light"
            dangerouslySetInnerHTML={{
              __html: solution.description,
            }}
            key={`${index}-desc`}
          ></pre>
        </div>
      </div>
    ))}
  </div>
)

const Coding = ({ questions, selectedQuestion, TABS, activeTabContent, activeTab }: any) => {
  return (
    <>
      {questions?.question === "Question not found" ? (
        <div className="flex items-center justify-center h-full">
          <h3 className="text-2xl text-new-neutral-light">Question not found or This not Platform Question</h3>
        </div>
      ) : (
        <div className="space-y-2">
          <div className="flex flex-col p-3 bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500 rounded">
            <QuestionInfo questions={questions} selectedQuestion={selectedQuestion} />
          </div>
          <div
          // className={`flex flex-col ${activeTabContent === 1 && "bg-new-editor-dark-500 text-new-neutral-light border-new-editor-dark-500"}`}
          >
            {activeTabContent === 0 ? (
              <ProblemStatement questions={questions} />
            ) : (
              <SolutionTabs questions={questions} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Coding
