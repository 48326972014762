import { Fragment, useLayoutEffect, useState } from "react"
import FieldsView from "./FieldsView"

export default function SolutionView({ question, activeResposeTab }: any) {
  const [solution, setSolution] = useState<string>("")
  const [solutionDescription, setSolutionDescription] = useState<Array<any>>([])

  useLayoutEffect(() => {
    if (question.questionType === "Subjective") {
      setSolution(question?.solution)
    } else {
      const questionResponse = question?.response?.[activeResposeTab] || {}
      const sol = questionResponse?.solution

      if (questionResponse?.questionType === "MCQ") {
        let _sol = "Correct Solution is option "
        Object.keys(sol).forEach((key) => {
          _sol += parseInt(key) + 1 + ", "
        })
        _sol = _sol.slice(0, -2) + "."
        setSolution(_sol)
        if (questionResponse?.solutionDescription) {
          setSolutionDescription(questionResponse?.solutionDescription)
        }
      } else {
        setSolution(sol)
      }
    }
  }, [activeResposeTab])

  return (
    <Fragment>
      <div className="border-b border-new-neutral-light pb-2 text-2xl font-semibold">Solution</div>
      <div className="p-2 pl-0">
        <p className="mb-2">
          <strong className="text-lg">Problem solution:</strong>
        </p>
        <p className="text-justify">{solution}</p>
      </div>
      {solutionDescription.length !== 0 && <FieldsView headerTitle={"Description:"} fields={solutionDescription} />}
    </Fragment>
  )
}
