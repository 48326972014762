import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface SubmitContestApprovalUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export default class SubmitContestApproval implements SubmitContestApprovalUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.submitContestApproval(auth, data)
  }
}
