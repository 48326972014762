import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface MarkSubmitProctoredUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export default class MarkSubmitProctored implements MarkSubmitProctoredUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: any) {
    return this.repository.markSubmitProctored(auth, id)
  }
}
