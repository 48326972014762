import React from "react"

function Alert() {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0003 1.3335H4.00033C3.6467 1.3335 3.30756 1.47397 3.05752 1.72402C2.80747 1.97407 2.66699 2.31321 2.66699 2.66683V13.3335C2.66699 13.6871 2.80747 14.0263 3.05752 14.2763C3.30756 14.5264 3.6467 14.6668 4.00033 14.6668H12.0003C12.3539 14.6668 12.6931 14.5264 12.9431 14.2763C13.1932 14.0263 13.3337 13.6871 13.3337 13.3335V4.66683L10.0003 1.3335Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33301 1.3335V4.00016C9.33301 4.35378 9.47348 4.69292 9.72353 4.94297C9.97358 5.19302 10.3127 5.3335 10.6663 5.3335H13.333"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66699 7.3335L10.0003 9.3335L6.66699 11.3335V7.3335Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default Alert
