import DashboardLayout from "core/layouts/DashboardLayout"
import { cn } from "core/lib/utils"
import FaqDisclosure from "./components/FaqDisclosure"
import { useEffect } from "react"
import useMastersProgramViewModel from "./MastersProgramViewModel"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import Trapezium from "assets/svgs/masters/Trapezium"
import RegisteredModal from "./components/RegisteredModal"
import Spinner from "core/components/Spinner"
import woolf from "assets/svgs/masters/woolf.svg"
import x from "assets/svgs/masters/x.svg"
import bosscoder from "assets/svgs/masters/bosscoder.svg"
import BannerMark from "assets/svgs/masters/BannerMark"

export default function MastersProgramView() {
  const {
    toast,
    DATA,
    loading,
    registeredModalOpen,
    registered,
    isRegistering,
    fetchMastersStatus,
    changeToastVisibility,
    onRegister,
    onRedirect,
    onCloseRegisteredModal,
  } = useMastersProgramViewModel()

  useEffect(() => {
    fetchMastersStatus()
  }, [])

  if (loading) {
    return (
      <DashboardLayout>
        <Loader />
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <RegisteredModal show={registeredModalOpen} onRedirect={onRedirect} onClose={onCloseRegisteredModal} />
      <div className="text-new-neutral-dark">
        <div className="relative flex h-[335px] justify-between shadow">
          {/* <img
            src={main_bg}
            alt="masters"
            className="absolute top-0 left-0 -z-10 h-full w-full object-cover object-center"
          /> */}
          <div className="flex h-full flex-col justify-between gap-4 p-8">
            <div className="flex items-center gap-2">
              <img src={woolf} alt="woolf" className="h-4" />
              <img src={x} alt="x" className="h-2" />
              <img src={bosscoder} alt="bosscoder" className="h-[18px]" />
            </div>
            <div>
              <h1 className="text-[36px] font-bold leading-[54px] text-new-neutral-dark">EUROPEAN CERTIFIED</h1>
              <BannerMark />
            </div>
            <div className="mt-auto space-y-4">
              <div className="w-fit">
                <button
                  disabled={registered || isRegistering}
                  onClick={onRegister}
                  className={cn(
                    "mt-auto flex w-[300px] justify-center rounded-sm px-16 py-4 text-base font-medium leading-[20px]",
                    registered
                      ? "cursor-default bg-new-solid-green-dark text-new-solid-white"
                      : "bg-new-accent text-new-solid-white hover:opacity-90 disabled:opacity-75"
                  )}
                >
                  {isRegistering ? <Spinner xs /> : registered ? "Already applied" : "I'm Interested"}
                </button>
              </div>
              <div className="flex items-center gap-3">
                <h4 className="rounded-sm bg-new-failure px-4 py-1 text-new-solid-white">Note</h4>
                <p className="text-new-neutral-dark">
                  Exclusive offer for our <strong>Bosscoder students with bachelors degree</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 px-6 py-8">
          <div className="space-y-1">
            <h2 className="text-new-accent">Why Bosscoder University?</h2>
            <p>An outcome focussed Master's Program accredited with ECTS</p>
          </div>
          <div className="flex items-center gap-x-[18px] overflow-x-auto pb-4 text-new-neutral-dark">
            {DATA.reasons.map((reason, i) => (
              <div
                key={i}
                className="relative flex h-[282px] w-40 shrink-0 flex-col gap-y-2 rounded border px-4 pt-16 pb-6 shadow-[0px_0px_10px_0px_rgba(158,158,158,0.15)]"
                style={{
                  border: `1px solid ${reason.color}`,
                }}
              >
                <h3>{reason.title}</h3>
                <p className="text-xs leading-5 text-new-neutral">{reason.description}</p>
                <div
                  className="absolute top-0 left-4"
                  style={{
                    color: reason.color,
                  }}
                >
                  <Trapezium />
                </div>
                <div
                  className="absolute top-0 left-6"
                  style={{
                    color: reason.color,
                  }}
                >
                  <Trapezium />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-6 bg-[#F8FCFF] px-6 py-8 text-new-accent-dark">
          <h2 className="text-new-accent">Our Curriculum Structure:</h2>
          <div className="flex gap-6">
            {DATA.curriculum.map((course, i) => (
              <div
                key={i}
                className="min-h-[440px] w-full space-y-4 bg-new-solid-white py-6 shadow-[0px_0px_10px_0px_rgba(158,158,158,0.15)]"
              >
                <div className="flex items-center gap-4">
                  <div className="h-fit bg-gradient-to-tr from-new-accent to-new-accent-dark/90 py-2 px-[18px]">
                    <h3 className="text-new-solid-white">{i + 1}</h3>
                  </div>
                  <h3 className="pr-6 text-new-accent">{course.name}</h3>
                </div>
                <div className="divide-y divide-dashed divide-new-neutral-light px-6">
                  {course.modules.map((module, j) => (
                    <div key={j} className="flex justify-between gap-7 py-4">
                      <p className="text-new-neutral-dark">{module.topic}</p>
                      <h4 className="flex h-7 w-10 shrink-0 items-center justify-center bg-new-solid-blue-light text-new-neutral-dark">
                        {module.credits}
                      </h4>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-6 px-6 py-8">
          <h2 className="w-[424px] text-new-accent">
            Our Alumni <span className="font-medium">are paving their pathway to success. You can too!</span>
          </h2>
          <div className="grid grid-cols-6 gap-y-4">
            {DATA.companies.map((cpmpany, i) => (
              <div key={i} className="flex h-[54px] w-full items-center justify-center">
                <img
                  src={cpmpany.image}
                  alt={cpmpany.name}
                  className="h-full max-h-[30px] w-full max-w-[100px] object-contain"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-6 bg-new-solid-green-light px-6 py-8 text-new-accent">
          <h2 className="">Let Your Skills Certify You!</h2>
          <div className="flex gap-x-10 rounded bg-new-solid-white px-6 py-8 shadow-[0px_0px_10px_0px_rgba(158,158,158,0.15)]">
            <div className="space-y-3 py-3 pl-3">
              <h3 className="">
                90 ECTS Credits - <span className="font-normal">On Course Completion</span>
              </h3>
              <ul className="list-disc space-y-2 pl-3 text-new-neutral-dark">
                {DATA.completion_benefits?.map((benefit, i) => (
                  <li
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: benefit,
                    }}
                  />
                ))}
              </ul>
            </div>
            <div className="h-[212px] shrink-0 overflow-hidden rounded border border-new-neutral-light bg-v2-gray-300">
              {/* <img src={certificate} alt="certificate" className="h-full w-full" /> */}
            </div>
          </div>
        </div>
        <div className="space-y-6 px-6 py-8">
          <h2 className="text-new-accent">
            FAQ <span className="font-normal">Frequently Asked Questions:</span>
          </h2>
          <div className="space-y-4">
            {DATA.faqs.map((faq, i) => (
              <FaqDisclosure key={i} shadow label={faq.question}>
                {faq.answer}
              </FaqDisclosure>
            ))}
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
