import CreateClassDataSource from "data/dataSource/Admin/CreateClassDataSource"
import { Auth } from "domain/model/Auth"
import { classTemplate, CreateClass, ModuleClasses } from "domain/model/CreateClass"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export class CreateClassRepositoryImpl implements CreateClassRepository {
  private Datasource: CreateClassDataSource

  constructor(Datasource: CreateClassDataSource) {
    this.Datasource = Datasource
  }

  async getAllBatchesList(auth: Auth) {
    return await this.Datasource.getAllBatches(auth)
  }

  async getAllInstructors(auth: Auth): Promise<any> {
    return await this.Datasource.getAllInstructors(auth)
  }

  async getAllModules(auth: Auth): Promise<any> {
    return await this.Datasource.getAllModules(auth)
  }

  async getAllTracks(auth: Auth): Promise<any> {
    return await this.Datasource.getAllTracks(auth)
  }

  async createClass(createClass: CreateClass): Promise<any> {
    return await this.Datasource.createClass(createClass)
  }

  async getModuleClasses(request: ModuleClasses): Promise<any> {
    return await this.Datasource.getModuleClasses(request)
  }

  async getClassTemplate(request: classTemplate): Promise<any> {
    return await this.Datasource.getClassTemplate(request)
  }

  async getModulesBasedOnTrack(auth: Auth, track: string): Promise<any> {
    return await this.Datasource.getModulesBasedOnTrack(auth, track)
  }
}
