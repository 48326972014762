export default function CheckBoxCircleFilled(props: any) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00065 14.1654C3.31865 14.1654 0.333984 11.1807 0.333984 7.4987C0.333984 3.8167 3.31865 0.832031 7.00065 0.832031C10.6827 0.832031 13.6673 3.8167 13.6673 7.4987C13.6673 11.1807 10.6827 14.1654 7.00065 14.1654ZM7.00065 12.832C9.94617 12.832 12.334 10.4442 12.334 7.4987C12.334 4.55318 9.94617 2.16536 7.00065 2.16536C4.05513 2.16536 1.66732 4.55318 1.66732 7.4987C1.66732 10.4442 4.05513 12.832 7.00065 12.832ZM3.50732 7.33673L6.33599 10.1654L11.05 5.4514L10.1067 4.50873L6.33599 8.28007L4.44999 6.39407L3.50732 7.33673Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
