import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Student/SessionRepository"
import SessionDataSource from "data/dataSource/Student/SessionDataSource"

export class SessionRepositoryImpl implements SessionRepository {
  private dataSource: SessionDataSource

  constructor(dataSource: SessionDataSource) {
    this.dataSource = dataSource
  }

  async getAllSessions(auth: Auth, timestamp: number): Promise<any> {
    return this.dataSource.getAllSessions(auth, timestamp)
  }

  async getNextClassTimestamp(auth: Auth): Promise<any> {
    return this.dataSource.getNextClassTimestamp(auth)
  }
}
