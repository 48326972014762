import UpdateSSMCallDataSource from "data/dataSource/Admin/UpdateSSMCallDataSource"
import { Auth } from "domain/model/Auth"
import { UpdateSSMCall } from "domain/model/UpdateSSMCall"
import { UpdateSSMCallRepository } from "domain/repository/Admin/UpdateSSMCallRepository"

export class UpdateSSMCallRepositoryImpl implements UpdateSSMCallRepository {
  private datasource: UpdateSSMCallDataSource

  constructor(datasource: UpdateSSMCallDataSource) {
    this.datasource = datasource
  }

  async updateSSMCall(updateSSMCall: UpdateSSMCall): Promise<any> {
    return await this.datasource.updateSSMCall(updateSSMCall)
  }
}
