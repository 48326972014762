import {
  API_ADMIN,
  API_CONTEST_APPROVE,
  API_GET_TOP_100_APPROVE_REQUESTS,
  API_APPROVE_STUDENT,
  API_GET_APPROVAL_BY_DATE,
  API_GET_APPROVAL_BY_EMAIL,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { ReferandEarnDataSource } from "data/dataSource/Admin/ReferandEarnDataSource"
const server = new Server()

export class ReferandEarnAPIDataSourceImpl implements ReferandEarnDataSource {
  async getTop100ReferandEarnRequests(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(API_ADMIN, "referral", "get_top_100_referandearn"), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async updateReferandEarnRequests(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn(API_ADMIN, "referral", "update_referandearn"), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getFilterReferandEarnRequests(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn(API_ADMIN, "referral", "get_filter_referandearn"), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
