import { Auth } from "domain/model/Auth"
import { SessionDetails } from "domain/model/Course"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface GetSessionDetailsUseCase {
  invoke(auth: Auth, session_id: string): Promise<SessionDetails>
}

export class GetSessionDetails implements GetSessionDetailsUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string) {
    return this.repository.getSessionDetail(auth, session_id)
  }
}
