import { Auth } from "domain/model/Auth"
import { NewMentorSessionsRepository } from "domain/repository/Admin/NewMentorSessionsRepository"

export interface EditMentorTemplateForStudentUseCase {
  invoke(auth: Auth, template: any): Promise<any>
}

export class EditMentorTemplateForStudent implements EditMentorTemplateForStudentUseCase {
  private repository: NewMentorSessionsRepository

  constructor(repository: NewMentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, template: any) {
    return await this.repository.editMentorTemplateForStudent(auth, template)
  }
}
