import HomeToggleCheck from "presentation/Student/Home/components/Cards/HomeToggleCheck"
import Input from "core/components/Input"
import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import ProjectsSectionViewModel from "../CreateProjectsSectionViewModel"
import React from "react"
import Toast from "core/components/Toast"

export function CreateProjectCard() {
  const {
    customSystemRequirement,
    customTechStack,
    links,
    selectedRequirement,
    projectTitle,
    projectLevel,
    techStack,
    industry,
    tags,
    inputValue,
    courseType,
    problemStatement,
    requirements,
    techStacks,
    courses,
    industries,
    isCreatingNewProject,
    isEditingProject,
    projectActive,
    projectsLevelList,
    isOtherSelected,
    isOtherRequirementSelected,
    isOtherIndustrySelected,
    selectedBatch,
    batches,
    customIndustry,
    batch,
    toast,
    dataScienceTypeOptions,
    dataScienceType,
    changeToastDetails,
    addTag,
    handleDataScienceTypeChange,
    changeToastVisibility,
    getBatches,
    goback,
    addLink,
    deleteLastLink,
    handleChange,
    handleSystemRequirementChange,
    handleProjectTitleChange,
    handleProjectLevelChange,
    handleIndustryChange,
    handleTechStackChange,
    handleInputChange,
    handleInputKeyDown,
    removeTag,
    handleCourseTypeChange,
    handleProblemStatementChange,
    handleDelete,
    onClickHandler,
    toggleProjectActiveEdit,
    handleOtherTechStackInput,
    handleOtherSystemRequirementInput,
    handleOtherIndustryInput,
    handleBatchChange,
    handleBatchChangeCollective,
    handleKeyPress,
    setTags,
    addTags,
    toggleProjectActive,
  } = ProjectsSectionViewModel()
  React.useEffect(() => {
    getBatches()
  }, [])
  React.useEffect(() => {
    if (isCreatingNewProject && courseType === "DSML") {
      const dsmlBatches = batch.filter((batches: any) => batches.startsWith("DSML"))
      setTags(dsmlBatches)
      addTags(dsmlBatches)
    }
  }, [isCreatingNewProject, courseType])
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="py-6 px-6 shadow-md flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="text-neutral-700 font-montserrat font-small text-sm">{/* # 0225112 */}</div>
            <div className="flex flex-col">
              <div className="flex justify-end">
                {/* <HomeToggleCheck onChange={toggleProjectActive} withoutHeader /> */}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-lg text-neutral-700 font-semibold">Project Title</div>
            {/* <div>Set Project Active</div> */}
          </div>
          <div className="flex flex-col gap-4">
            <Input
              border
              className="w-80 mt-2 h-10 font-[14px]"
              value={projectTitle}
              placeholder="Type Here!"
              onChange={handleProjectTitleChange}
            />
            <div className="flex flex-row gap-4">
              <div className="flex flex-col">
                <div className="text-lg text-neutral-700 font-semibold">Course Type</div>
                <StandaredSelect
                  onChange={handleCourseTypeChange}
                  options={courses}
                  value={courseType}
                  className="w-[319px] mt-2 border-neutral-500 h-10"
                  defaultOption="Select Here"
                  placeholder="Select Here"
                />
              </div>
              {courseType === "DSML" && (
                <div className="flex flex-col">
                  <div className="text-lg text-neutral-700 font-semibold">Course sub-type</div>
                  <StandaredSelect
                    options={dataScienceTypeOptions}
                    onChange={handleDataScienceTypeChange}
                    value={dataScienceType}
                    className="w-[319px] mt-2 border-neutral-500 h-10"
                    defaultOption="Select Here"
                    placeholder="Select Here"
                  />
                </div>
              )}
              <div className="flex flex-col">
                <div className="text-lg text-neutral-700 font-semibold">Project Level</div>
                <StandaredSelect
                  options={projectsLevelList}
                  onChange={handleProjectLevelChange}
                  value={projectLevel}
                  className="w-[319px] mt-2 border-neutral-500 h-10"
                  defaultOption="Select Here"
                  placeholder="Select Here"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-6 px-6 shadow-md flex flex-col">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col text-lg text-neutral-700 font-semibold">Data Fields</div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-8">
                <div className="h-10 px-4 py-2 border border-gray-400 bg-white w-80">System Requirement</div>
                <div>
                  <StandaredSelect
                    options={requirements}
                    onChange={handleSystemRequirementChange}
                    value={selectedRequirement}
                    className="w-[576px] h-10 border-neutral-500"
                    defaultOption="Select Here"
                    placeholder="Select Here"
                  />
                </div>
              </div>
              {isOtherRequirementSelected && (
                <div className="flex flex-row gap-8">
                  <div className="w-80"></div>
                  <input
                    type="text"
                    value={customSystemRequirement}
                    onChange={handleOtherSystemRequirementInput}
                    className="text-sm w-[576px] h-10 border-neutral-500"
                    placeholder="Enter other System Requirement"
                  />
                </div>
              )}

              <div className="flex flex-row gap-8">
                <div className="h-10 px-4 py-2 border border-gray-400 bg-white w-80">Tech</div>
                <div>
                  <StandaredSelect
                    options={techStacks}
                    onChange={handleTechStackChange}
                    value={techStack}
                    className="w-[576px] h-10 border-neutral-500"
                    defaultOption="Select Here"
                    placeholder="Select Here"
                  />
                </div>
              </div>
              {isOtherSelected && (
                <div className="flex flex-row gap-8">
                  <div className="w-80"></div>
                  <input
                    type="text"
                    value={customTechStack}
                    onChange={handleOtherTechStackInput}
                    className="text-sm w-[576px] h-10 border-neutral-500"
                    placeholder="Enter other Tech"
                  />
                </div>
              )}

              <div className="flex flex-row gap-8">
                <div className="px-4 py-2 border border-gray-400 bg-white w-80">Industry</div>
                <div>
                  <StandaredSelect
                    options={industries}
                    onChange={handleIndustryChange}
                    value={industry}
                    className="w-[576px] h-10 border-neutral-500"
                    defaultOption="Select Here"
                    placeholder="Select Here"
                  />
                </div>
              </div>
              {isOtherIndustrySelected && (
                <div className="flex flex-row gap-8">
                  <div className="w-80"></div>
                  <input
                    type="text"
                    value={customIndustry}
                    onChange={handleOtherIndustryInput}
                    className="text-sm w-[576px] h-10 border-neutral-500"
                    placeholder="Enter other Industry"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-8 h-10">
                <div className="px-4 py-2 border border-gray-400 bg-white w-80">Batch</div>
                <div className="w-[576px]">
                  <StandaredSelect
                    options={courseType === "DSML" ? batch.filter((batches: any) => batches.includes("DSML")) : batch}
                    onChange={handleBatchChange}
                    value={selectedBatch}
                    className="h-10"
                    defaultOption="Select Here"
                    placeholder="Select Batch"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-8">
                <div className="w-80"></div>
                <div className="h-[39px] overflow-x-auto w-[576px] flex flex-wrap flex-col gap-2 border border-[#6B6B6B] rounded px-[2px] py-[2px] overflow-hidden">
                  {tags.map((tag, index) => (
                    <div
                      key={index}
                      className="inline-tags-input-tag flex items-center gap-2 border border-gray-300 rounded px-2 py-1 bg-neutral-500 text-white"
                    >
                      {tag}
                      <button onClick={() => removeTag(index)} className="tag-remove-button text-white">
                        x
                      </button>
                    </div>
                  ))}
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    className="w-full border-none outline-none focus:ring-transparent"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-8 ">
              <div className="h-10 px-4 py-2 border border-gray-400 bg-white w-80">Problem Statement</div>
              <div>
                <textarea
                  value={problemStatement}
                  onChange={handleProblemStatementChange}
                  className="w-[576px] h-36"
                  placeholder="Type your response here"
                ></textarea>
              </div>
            </div>
            <div className="flex flex-row gap-8 ">
              <div className="h-10 px-4 py-2 border border-gray-400 bg-white w-80">Reference Url</div>

              <div className="flex flex-col gap-4 w-[576px]">
                {links.map((link, index) => (
                  <input
                    onKeyDown={(event) => handleKeyPress(index, event)}
                    key={index}
                    type="text"
                    placeholder={`Reference link ${index + 1}`}
                    value={link}
                    className="w-[576px]"
                    onChange={(event) => handleChange(index, event)}
                  ></input>
                ))}
              </div>
              {links.length > 1 && (
                <Button outlined failure className="text-lg font-semibold w-48 h-12" onClick={deleteLastLink}>
                  Delete Field
                </Button>
              )}
            </div>
            <button onClick={addLink} className="text-lg font-semibold w-full h-[80px] bg-gray-200">
              + Add Reference link
            </button>
          </div>
        </div>
        <div className="py-6 px-6 shadow-md flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="text-lg text-neutral-700 font-semibold">Save Project Detail:</div>
            <div className="flex flex-row gap-2">
              {isEditingProject ? (
                <>
                  <Button onClick={handleDelete} className="h-14 w-[180px]" outlined failure>
                    Delete Project
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button onClick={goback} className="h-14 w-[180px]" outlined failure>
                Discard Changes
              </Button>
              <Button
                disabled={
                  !projectTitle.trim() ||
                  tags.length === 0 ||
                  !problemStatement.trim() ||
                  !projectLevel.trim() ||
                  !courseType ||
                  (courseType === "DSML" && !dataScienceType.trim())
                }
                onClick={onClickHandler}
                className="h-14 w-[180px]"
              >
                {isCreatingNewProject ? "Create Project" : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </div>
    </>
  )
}
