import React, { ChangeEvent } from "react"
import Button from "core/components/new/Button"
import Modal from "core/components/Modal"
import HomeToggleCheck from "presentation/Student/Home/components/Cards/HomeToggleCheck"
import { useNavigate } from "react-router-dom"
import { ToggleProjectStatus } from "domain/useCase/Admin/ProjectSection/ToggleProjectStatus"
import { ProjectSectionRepositoryImpl } from "data/repository/Admin/ProjectSectionRepositoryImpl"
import ProjectSectionDataSourceImpl from "data/API/Admin/ProjectSectionDataSourceImpl"
import { useAuth } from "core/context/auth"
import ProjectsSectionViewModel from "../CreateProjectsSectionViewModel"
import Vector from "assets/images/Vector 180.png"

export default function ProjectsCard({ project, clicked, setClicked, index }: any) {
  const { auth } = useAuth()
  const ToggleProjectStatusUseCase = new ToggleProjectStatus(
    new ProjectSectionRepositoryImpl(new ProjectSectionDataSourceImpl())
  )
  const updatedUrls = project.reference_url.map((url: any) => {
    // Check if the URL starts with "http://" or "https://"
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url // If it does, return the URL as is
    } else {
      return `http://${url}` // If not, prepend "http://" to the URL
    }
  })
  const handleProjectStatusChange = async (is_active: boolean) => {
    const data = { projectId: project.id, isActive: is_active }
    const response = await ToggleProjectStatusUseCase.invoke(auth, data)
    if (response.success) {
      setProjectActive(is_active)
      setClicked(!clicked)
    }
  }

  const [projectActive, setProjectActive] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  React.useEffect(() => {
    setProjectActive(project.is_active)
    // handleProjectStatusChange(project.is_active);
  }, [project.is_active, projectActive])
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleProjectActive = () => {
    setProjectActive(!projectActive)
    handleProjectStatusChange(!projectActive)
  }
  const getColorClass = (level: any) => {
    switch (level) {
      case "Easy":
        return "text-green-600"
      case "Medium":
        return "text-yellow-600"
      case "Hard":
        return "text-red-600"
      default:
        return "text-gray-800"
    }
  }
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`edit-project/${project.id}`)
  }

  return (
    <div className="py-6 px-6 shadow-md flex flex-row justify-between rounded-[2px]">
      <div className="flex flex-col gap-0.5 content-between">
        <div className="text-neutral-700 font-montserrat font-small text-xs"></div>
        <div className="text-indigo-900 font-semibold text-lg">
          {index}. {project.project_title}
        </div>
        <div className="text-neutral-700 font-[14px]">
          {project.course_type === "DSML" ? project.data_science_or_data_analytics + " |" : project.course_type + " |"}{" "}
          {project.tech_stack ? project.tech_stack + " |" : ""} {project.industry ? project.industry + " |" : ""}{" "}
          {project.project_level ? project.project_level : ""}{" "}
        </div>
        <div className="text-neutral-700 font-semibold">{project.course_type}</div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 justify-end align-center">
          <div>Set Project Active</div>
          <div className="flex flex-col">
            <HomeToggleCheck isChecked={project.is_active} onChange={toggleProjectActive} withoutHeader />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button onClick={() => toggleModal()} outlined className="w-[150px]">
            View Project
          </Button>
          <Button onClick={handleClick} outlined className="w-[150px]">
            Edit Project
          </Button>
        </div>
      </div>
      <Modal className="p-0 mt-0" padding open={isModalOpen} onClose={toggleModal}>
        <div className="mt-0 flex flex-row gap-8">
          <div className="min-w-36 flex flex-col text-lg font-semibold text-indigo-900 p-[24px]">
            {project.course_type === "DSML" ? project.data_science_or_data_analytics : project.course_type} Project
          </div>
          <img src={Vector}></img>
          <div className="font-semibold text-neutral-700 p-[24px] w-full text-[15px]">
            <div className="text-lg">
              {index}. {project.project_title}
            </div>
            <div className="flex flex-row">
              <div className="mt-[16px] flex flex-col gap-[8px]">
                {project.tech_stack && (
                  <div className="inline-flex flex-row justify-start gap-[10px]">
                    <div className="min-w-[220px] font-normal">Tech</div>
                    <div>{project.tech_stack}</div>
                  </div>
                )}
                {project.system_requirement && (
                  <div className="inline-flex flex-row justify-start gap-[10px]">
                    <div className="min-w-[220px] font-normal">System Requirement</div>
                    <div>{project.system_requirement}</div>
                  </div>
                )}
                {project.industry && (
                  <div className="inline-flex flex-row justify-start gap-[10px]">
                    <div className="min-w-[220px] font-normal">Industry</div>
                    <div>{project.industry}</div>
                  </div>
                )}
                <div className="inline-flex flex-row justify-start gap-[10px]">
                  <div className="min-w-[220px] font-normal">Project Level</div>
                  <div className={`${getColorClass(project.project_level)} font-semibold`}>{project.project_level}</div>
                </div>
                <div className="inline-flex flex-row justify-start gap-[10px]">
                  <div className="  min-w-[220px] font-normal">Problem Statement</div>
                  <div className="bg-neutral-100 rounded py-[8px] px-[4px] max-w-[387px]">
                    {project.problem_statement}
                  </div>
                </div>
                {project.reference_url && project.reference_url[0] && project.reference_url[0].trim() !== "" && (
                  <div className="inline-flex gap-[10px] flex-row justify-start">
                    <div className="min-w-[220px] font-normal">References</div>
                    <div className="py-[8px] px-[4px] bg-neutral-100 rounded min-w-[387px]">
                      <ul className="list-disc list-inside underline">
                        {updatedUrls.map((url: any, index: any) => (
                          <li key={index}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              Reference {index + 1}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
