import { cn } from "core/lib/utils"

export default function ButtonGroup({ buttons, active, onChange, ...props }: any) {
  return (
    <div className="flex divide-x divide-new-accent rounded border border-new-accent">
      {buttons.map((button: any, i: number) => (
        <button
          {...props}
          id={button}
          key={i}
          className={cn(
            "w-full flex-1 select-none rounded-sm py-2 text-lg leading-[22px]",
            active === i ? "bg-new-gradient font-semibold text-new-solid-white" : "font-normal text-new-neutral"
          )}
          onClick={() => onChange(i)}
        >
          {button}
        </button>
      ))}
    </div>
  )
}
