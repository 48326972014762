import Vector from "assets/images/Vector 180.png"
import ButtonGroup from "core/components/ButtonGroup"
import Modal from "core/components/Modal"
import Button from "core/components/new/Button"
import Table from "core/components/new/Table"
import ProjectsSectionView from "presentation/Admin/ProjectSection/CreateProjectsSectionView"
import ProjectsSectionViewModel from "presentation/Admin/ProjectSection/CreateProjectsSectionViewModel"
import React from "react"
import CourseViewModel from "../CourseViewModel"
interface Project {
  project_title: string
  tech_stack: string
  project_level: string
  industry: string
  course_type: string
  problem_statement: string
  system_requirement: string
  data_science_or_data_analytics: string
  reference_url: Array<string>
}
const ProjectModuleComponent = () => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null)
  const [activeTab, setActiveTab] = React.useState(0)
  const [filter, setFilter] = React.useState("All")
  const [selectedProject, setSelectedProject] = React.useState<Project | null>(null)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { student } = CourseViewModel()
  const { getProjects, visibleProjects, getColorClass } = ProjectsSectionViewModel()
  React.useEffect(() => {
    getProjects()
  }, [])

  const headers = ["Sr. No.", "Project Name", "Tech", "Level", "Industry", "Project Details"]
  const handleTabChange = (value: any) => {
    setActiveTab(value)
  }
  const toggleModal = (project = null, index: number | null = null) => {
    setSelectedProject(project)
    setSelectedIndex(index)
  }
  const preprocessUrls = (urls: any) => {
    return urls.map((url: any) => {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return `https://${url}`
      }
      return url
    })
  }
  const filteredProjects = visibleProjects.filter((project: any) => {
    const isActive = project.is_active === true // Keep the active project filter
    const matchesLevel = filter === "All" || project.project_level === filter // Keep the level filter
    const isBatchMatch = project.batch.includes(student.batch)
    const matchesCategory =
      activeTab === 0
        ? project.data_science_or_data_analytics === "Data Science"
        : project.data_science_or_data_analytics === "Data Analytics"

    return isActive && matchesLevel && matchesCategory && isBatchMatch
  })
  const rows = filteredProjects.map((project: any, index: any) => [
    index + 1,
    project.project_title,
    project.tech_stack ? project.tech_stack : "N/A",
    <span
      className={`${
        project.project_level === "Easy"
          ? "text-green-500"
          : project.project_level === "Medium"
            ? "text-yellow-500"
            : project.project_level === "Hard"
              ? "text-red-500"
              : "text-black"
      }`}
    >
      {project.project_level}
    </span>,

    project.industry ? project.industry : "N/A",

    <Button onClick={() => toggleModal(project, index)} outlined className="max-w-[180px]">
      View Details
    </Button>,
  ])

  const tableData = {
    header: headers,
    rows,
  }
  const dstabs = [
    { label: "Data Science Projects", value: "Data Science" },
    { label: "Data Analytics Projects", value: "Data Analytics" },
  ]

  return (
    <div className="flex px-6 py-6 flex-col gap-6" id="course-session">
      <ButtonGroup active={activeTab} buttons={dstabs.map((tab) => tab.label)} onChange={handleTabChange}></ButtonGroup>
      <div className="flex flex-row gap-4">
        <Button
          className="max-w-[150px]"
          onClick={() => setFilter("All")}
          outlined
          selected={filter === "All" ? true : false}
        >
          All
        </Button>
        <Button
          className="max-w-[150px]"
          onClick={() => setFilter("Easy")}
          outlined
          selected={filter === "Easy" ? true : false}
        >
          Easy
        </Button>
        <Button
          className="max-w-[150px]"
          onClick={() => setFilter("Medium")}
          outlined
          selected={filter === "Medium" ? true : false}
        >
          Medium
        </Button>
        <Button
          className="max-w-[150px]"
          onClick={() => setFilter("Hard")}
          outlined
          selected={filter === "Hard" ? true : false}
        >
          Hard
        </Button>
      </div>
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <h2 className="text-new-accent">DSML Projects</h2>
          <p>
            You can find the resources of various {activeTab === 0 ? "Data Science" : "Data Analytics"} projects here.
          </p>
        </div>
      </div>
      <Table data={tableData} outlined={true} />
      {selectedProject && (
        <Modal padding open={Boolean(selectedProject)} onClose={() => toggleModal(null)}>
          <div className="mt-0 flex flex-row gap-8 w-full">
            <div className="min-w-36 flex flex-col text-lg font-semibold text-indigo-900 p-[24px]">
              {selectedProject.course_type === "DSML"
                ? selectedProject.data_science_or_data_analytics
                : selectedProject.course_type}{" "}
              Project
            </div>
            <img src={Vector}></img>
            <div className="font-semibold text-neutral-700 p-[24px] w-full">
              {selectedIndex !== null ? `${selectedIndex + 1} ` : ""}
              {". "}
              {selectedProject.project_title}
              <div className="flex flex-row text-[15px]">
                <div className="mt-[16px] flex flex-col gap-[8px]">
                  <div className="inline-flex flex-row justify-start gap-[10px]">
                    <div className="min-w-[220px] font-normal">Tech Stack</div>
                    <div>{selectedProject.tech_stack}</div>
                  </div>
                  {selectedProject.system_requirement && (
                    <div className="inline-flex flex-row justify-start gap-[10px]">
                      <div className="min-w-[220px] font-normal">System Requirement</div>
                      <div>{selectedProject.system_requirement}</div>
                    </div>
                  )}
                  {selectedProject.industry && (
                    <div className="inline-flex flex-row justify-start gap-[10px]">
                      <div className="min-w-[220px] font-normal">Industry</div>
                      <div>{selectedProject.industry}</div>
                    </div>
                  )}
                  <div className="inline-flex flex-row justify-start gap-[10px]">
                    <div className="min-w-[220px] font-normal">Project Level</div>
                    <div className={`${getColorClass(selectedProject.project_level)} font-semibold`}>
                      {selectedProject.project_level}
                    </div>
                  </div>
                  <div className="inline-flex flex-row justify-start gap-[10px]">
                    <div className="  min-w-[220px] font-normal">Problem Statement</div>
                    <div className="bg-neutral-100 rounded py-[8px] px-[4px] max-w-[387px]">
                      {selectedProject.problem_statement}
                    </div>
                  </div>
                  {selectedProject.reference_url &&
                    selectedProject.reference_url[0] &&
                    selectedProject.reference_url[0].trim() !== "" && (
                      <div className="inline-flex gap-[10px] flex-row justify-start">
                        <div className="min-w-[220px] font-normal">References</div>
                        <div className="py-[8px] px-[4px] bg-neutral-100 rounded min-w-[387px]">
                          <ul className="list-disc list-inside underline">
                            {preprocessUrls(selectedProject.reference_url).map((url: any, index: any) => (
                              <li key={index}>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                  Reference {index + 1}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ProjectModuleComponent
