import React from "react"
import { useNavigate } from "react-router-dom"
import ScheduledClassesViewModel from "../ViewScheduledClasses/ScheduledClassesViewModel"

export default function MentorshipViewModel() {
  const navigate = useNavigate()
  const [genericBatchType, setGenericBatchType] = React.useState("")
  const [genericStudentType, setGenericStudentType] = React.useState("")
  const [studentType, setStudentType] = React.useState("")
  const [studentEmail, setStudentEmail] = React.useState("")
  const [isStudentTypeDisabled, setIsStudentTypeDisabled] = React.useState(false)
  const genericBatchTypes = ["SDE_BEG_DEFAULT", "SDE_ADV_DEFAULT", "DSML_BEG_DEFAULT", "DSML_ADV_DEFAULT", "EVOLVE"]
  const genericStudentTypes = ["Student", "Working"]
  const navigateToEditGeneric = (e: any) => {
    navigate(`${genericBatchType}/${genericStudentType}`)
  }

  const handleGenericBatchChangeType = (e: any) => {
    const selectedBatchType = e.target.value
    setGenericBatchType(selectedBatchType)
    if (selectedBatchType === "EVOLVE") {
      setGenericStudentType("Working")
      setIsStudentTypeDisabled(true)
    } else {
      setGenericStudentType("")
      setIsStudentTypeDisabled(false)
    }
  }

  const handleGenericStudentChangeType = (e: any) => {
    if (!isStudentTypeDisabled) {
      setGenericStudentType(e.target.value)
    }
  }
  const [batch, setBatch] = React.useState("")

  const { getBatchList, getBatches } = ScheduledClassesViewModel()

  function handleBatchChange(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }
  function handleStudentEmailChange(e: any) {
    setStudentEmail(e.target.value)
  }
  const handleStudentChangeType = (e: any) => {
    setStudentType(e.target.value)
  }
  const navigateToEdit = (e: any) => {
    navigate(`${batch}/${studentType}`)
  }
  const navigateToEditByStudent = (e: any) => {
    navigate(`${studentEmail}`)
  }
  return {
    genericBatchTypes,
    genericStudentTypes,
    genericBatchType,
    genericStudentType,
    studentType,
    studentEmail,
    isStudentTypeDisabled,
    batch,
    handleGenericBatchChangeType,
    handleGenericStudentChangeType,
    handleBatchChange,
    handleStudentEmailChange,
    handleStudentChangeType,
    navigateToEditGeneric,
    navigateToEdit,
    navigateToEditByStudent,
    getBatchList,
    getBatches,
  }
}
