import IconButton from "core/components/IconButton"
import Spinner from "core/components/Spinner"
import Toggle from "core/components/Toggle"
import { STR_PAID } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import React from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import GenerateInvoiceViewModel from "../GenerateInvoiceViewModel"
import Button from "core/components/new/Button"
import { stringToAmount } from "core/utils/stringToAmount"
import MonthDataColoumn from "./MonthDataColoumn"

export default function MonthlySessionTable(props: any) {
  const {
    yearMonth,
    Loading,
    confirmStatus,
    fetchUpdatedInstructorDetails,
    setLoading,
    fetchConfirmedInvoiceOfMentor,
    fetchAllSessionMonthyear,
  } = GenerateInvoiceViewModel()

  const MonthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const { auth } = useAuth()

  const [searchYear, setSearchYear] = React.useState<string>(new Date().getFullYear().toString())
  const [apiData, setApiData] = React.useState<any[]>([])
  const [year, setYear] = React.useState<string>(new Date().getFullYear().toString())
  const { id } = useParams()
  const navigate = useNavigate()
  function handleSubmit() {
    setLoading(true)
    setYear(searchYear)
    setLoading(false)
  }
  const redirectToSessions = (id: string, index: number) => {
    const monthString = (index + 1).toString().padStart(2, "0")
    const time_given = `${year}${monthString}`
    navigate(`/admin/generate-invoice/${id}/${time_given}`)
  }

  const fetchData = async (i: number) => {
    const monthString = i.toString().padStart(2, "0")
    const time_given = `${year}${monthString}`
    const data = await fetchUpdatedInstructorDetails(auth, id as string, time_given)
    if (typeof data == "string") {
      return data
    }
    const addData = await fetchConfirmedInvoiceOfMentor(auth, id as string, time_given)
    const dataWithAdditionalField = {
      ...data,
      invoice_amount: addData.total_amount,
      month_index: i,
    }

    return dataWithAdditionalField
  }

  const [counter, setCounter] = React.useState(12)
  React.useEffect(() => {
    setCounter(12)
    setApiData([])
    fetchConfirmedInvoiceOfMentor(auth, id as string, yearMonth)
  }, [year, id as string, props.reloadData])

  React.useEffect(() => {
    async function getData() {
      const data = await fetchData(counter)
      if (typeof data !== "string") {
        setApiData([...apiData, data])
      }
      setCounter(counter - 1)
    }
    if (counter > 0) {
      getData()
    }
  }, [counter])

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex gap-x-10">
        <input
          type="text"
          placeholder="Search by year..."
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
        <Button loading={Loading} className="w-fit" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <div className="relative overflow-x-auto rounded-md shadow-md">
        <table className="w-full text-left text-sm text-new-neutral-dark">
          <thead className="bg-new-neutral-dark text-new-solid-white">
            <tr>
              {props.TableHeader?.map((title: any, index: any) => (
                <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {apiData?.map((data: any, index: any) => (
              <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                <td className="px-6 py-4 ">
                  <p>{MonthName[data.month_index - 1]}</p>
                </td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                  {data.monthly_data.total_session}
                </td>
                {data.monthly_data.average_rating != -1 ? (
                  data.monthly_data.average_rating != 0 ? (
                    <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                      {data.monthly_data.average_rating}/5
                    </td>
                  ) : (
                    <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">Not Rated</td>
                  )
                ) : (
                  <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">Not Rated</td>
                )}

                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                  {stringToAmount(parseInt(data.invoice_amount ? data.invoice_amount : 0))}
                </td>
                <td className="px-6 py-4 text-[16px]">
                  {" "}
                  <Button onClick={() => redirectToSessions(id as string, data.month_index - 1)}>View Sessions</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
