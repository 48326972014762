import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"

export default function SheetsTable(props: any) {
  return (
    <div
      className={cn(
        "relative overflow-x-auto rounded-sm",
        props.outlined ? "border border-new-neutral-light" : "shadow-[0px_4px_16px_rgba(134,134,134,0.2)]"
      )}
    >
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-editor-dark-500 text-new-solid-white">
          <tr>
            {props.data?.header?.map((title: any, i: any) => (
              <th
                key={i}
                className={cn(
                  "px-6 py-2 text-sm font-semibold leading-[17px]",
                  i > 0 && i === props.data.header.length - 1 && "text-right"
                )}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmpty(props.data?.rows) ? (
            props.data?.rows?.map((row: any, ind: any) => (
              <tr className="group relative bg-new-solid-white" key={ind}>
                {Object.keys(row).map((key: any, i: any) => (
                  <td
                    key={key}
                    className={cn(
                      "relative whitespace-nowrap px-6 py-4 font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark",
                      i > 0 && i === Object.keys(row).length - 1 && "text-right"
                    )}
                  >
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-new-misc-gray">
              <td
                colSpan={props.data?.header?.length}
                className="py-2 text-center font-medium leading-6 text-new-neutral-light"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
