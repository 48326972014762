import Arrow from "assets/svgs/Arrow"
import Cut from "assets/svgs/Cut"
import LeftArrow from "assets/svgs/LeftArrow"
import RightArrow from "assets/svgs/RightArrow"
import Loader from "core/components/Loader"

function formatDate(dateString: string, status: string): string {
  const year = dateString.slice(0, 4)
  const month = dateString.slice(4, 6)
  const day = dateString.slice(6, 8)

  const currentDate = new Date(`${year}-${month}-${day}`)
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }

  if (status === "Scheduled") {
    const futureDate = new Date(currentDate)
    futureDate.setDate(currentDate.getDate() + 7)

    const formattedCurrentDate = currentDate.toLocaleDateString("en-US", options)
    const formattedFutureDate = futureDate.toLocaleDateString("en-US", options)

    return `${formattedCurrentDate} - ${formattedFutureDate}`
  } else if (status != "Scheduled") {
    const formattedDate = currentDate.toLocaleDateString("en-US", options)
    const day = currentDate.getDate()
    const daySuffix =
      day === 1 || day === 21 || day === 31
        ? "st"
        : day === 2 || day === 22
          ? "nd"
          : day === 3 || day === 23
            ? "rd"
            : "th"
    return `${day}${daySuffix} ${currentDate.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    })}`
  } else {
    return "Invalid status"
  }
}
function removeHypens(dateString: string) {
  return dateString.replace(/-/g, "")
}
function formatDateTodayLongAndShortMonthYear(dateString: string): [string, string] {
  const year = parseInt(dateString.slice(0, 4), 10)
  const month = parseInt(dateString.slice(4, 6), 10)
  const day = parseInt(dateString.slice(6, 8), 10)
  const date = new Date(year, month - 1, day) // Month is 0-indexed in Date constructor
  const options1: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "2-digit",
  }
  const formattedDate1 = new Intl.DateTimeFormat("en-US", options1).format(date)
  let suffix = "th"
  if (day !== 11 && day !== 12 && day !== 13) {
    const lastDigit = day % 10
    if (lastDigit === 1) suffix = "st"
    else if (lastDigit === 2) suffix = "nd"
    else if (lastDigit === 3) suffix = "rd"
  }
  const dd: string = formattedDate1.slice(-2)
  const mml: string = formattedDate1.slice(-6, -3)
  const options2: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "2-digit",
  }
  const formattedDate2 = new Intl.DateTimeFormat("en-US", options2).format(date)
  const mm: string = formattedDate2.slice(0, -3)
  return [`${dd}${suffix} ${mm}, ${year.toString()}`, `${dd} ${mml}, ${year.toString()}`]
}
function timestampToDate(timestamp: number) {
  if (timestamp != 9999999999999) timestamp *= 1000
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}${month}${day}`
}
function DetailedDailyReport(props: any) {
  const obj = props.daily.length != 0 ? props.daily[props.ind] : {}
  const key = Object.keys(obj)[0]
  const batches = props.student.batch_v4
  let btch = ""
  for (const key in batches) {
    if (
      Object.keys(obj)[0] <= timestampToDate(parseInt(batches[key].timestamp_end)) &&
      Object.keys(obj)[0] >= timestampToDate(parseInt(batches[key].timestamp_start))
    ) {
      btch = batches[key].batch_id
      break
    }
  }
  return (
    <>
      <div>
        {props.isOpen && (
          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.85)]">
              <div className={"relative mx-auto  shadow-lg"}>
                <div className="relative">
                  <div className="font-montserrat  flex h-[65vh] min-w-[55vw] flex-row rounded-md bg-new-solid-white static">
                    <div className=" flex flex-col items-center border-r-[1px]  border-[#] py-6 px-6 ">
                      <div className=" flex flex-col h-full w-[185px]">
                        <div className="text-[18px] break-words whitespace-nowrap font-semibold text-[#312E81]">
                          {formatDateTodayLongAndShortMonthYear(key)[0]}
                          {/* 29 September, 2099 */}
                        </div>
                        <div className="text-[12px] font-normal whitespace-nowrap leading-[140%] text-[#414141]">
                          Student daily progress report
                        </div>
                        <div className="flex">
                          <div className="text-[14px] px-4 py-2 mt-2 bg-[#312E81] text-white rounded-sm w-auto">
                            {btch}
                          </div>
                          <div className="flex-1"></div>
                        </div>
                        <div className="w-full flex-none flex  mt-auto">
                          <button
                            className="bg-[#312E81] rounded-sm h-[30px] w-[30px] px-[7px] py-[7px] text-white disabled:opacity-50 "
                            disabled={props.ind === 0}
                            onClick={() => {
                              props.handleDecInd()
                            }}
                          >
                            <LeftArrow className="h-4 w-4" />
                          </button>
                          <div className="flex-1 text-[12px] m-auto text-center">
                            {formatDateTodayLongAndShortMonthYear(key)[1]}
                          </div>

                          <div className="">
                            {props.ind === props.daily.length - 1 ? (
                              <div className="h-[30px] w-[30px]">
                                <Loader height="30px" />
                              </div>
                            ) : (
                              <button
                                className="bg-[#312E81] rounded-sm h-[30px] w-[30px] px-[7px] py-[7px] text-white disabled:opacity-50 "
                                disabled={props.joiningDate >= key}
                                onClick={() => {
                                  props.handleIncInd()
                                }}
                              >
                                <RightArrow className="h-4 w-4" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {obj[key]?.attendance ||
                    obj[key]?.weekly_assessment ||
                    obj[key]?.problem_solving_track ||
                    obj[key]?.mentor_session ||
                    obj[key]?.ssm ? (
                      <div className=" overflow-y-scroll no-scrollbar w-full ">
                        {obj[key]?.attendance && (
                          <div>
                            <div className="ml-6 mt-6">
                              <div className="text-[14px] font-semibold text-[#414141] mb-[2px]">Class Attendance</div>
                              <div className="text-[12px] leading-6 text-[#414141] mb-4">
                                {obj[key]?.attendance?.topic}
                              </div>
                              <div className="justify-between space-y-2 ">
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Instructor:
                                  </div>
                                  <div className={`text-[14px] font-semibold leading-normal`}>
                                    {obj[key]?.attendance?.instructor || "N/A"}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Attendance :
                                  </div>
                                  <div
                                    className={`text-[14px] font-semibold leading-normal  ${
                                      obj[key]?.attendance?.attendance === "True" ? "text-[#00C22B]" : "text-[#DC2626]"
                                    }`}
                                  >
                                    {obj[key]?.attendance?.attendance === "True" ? "Present" : "Absent"}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Session Duration :
                                  </div>
                                  <div className="text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                                    {Math.floor((obj[key]?.attendance?.duration || 0) / 3600) +
                                      " Hr " +
                                      Math.floor(((obj[key]?.attendance?.duration || 0) % 3600) / 60) +
                                      " Min"}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Assignment :
                                  </div>
                                  <div className="text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                                    {obj[key]?.attendance?.total
                                      ? obj[key]?.attendance?.solved + " / " + obj[key]?.attendance?.total
                                      : "N/A"}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className={`w-48 text-[12px] font-normal leading-[140%] text-[#414141]`}>
                                    • Class Rating :
                                  </div>
                                  <div
                                    className={`text-[14px] font-semibold leading-normal   ${obj[key]?.attendance?.rating ? "text-[#FBBF24]" : "text-[#6B6B6B]"}`}
                                  >
                                    {obj[key]?.attendance?.rating || "N/A"}
                                  </div>
                                </div>
                                <div className=" flex">
                                  <div className="w-48   text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Class Feedback : <br /> <br />
                                  </div>
                                  <div className="p-1/4 flex-1 mr-[20px] items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                                    <pre className="text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                                      <div className="break-words">{obj[key]?.attendance?.feedback || "-"}</div>
                                    </pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 border-t-[0.5px] h-0"></div>
                          </div>
                        )}
                        {obj[key]?.weekly_assessment && (
                          <div>
                            <div className="ml-6 mt-6">
                              <div className="text-[14px] font-semibold text-[#414141] mb-[2px] ">Contest</div>
                              <div className="text-[12px] leading-6 text-[#414141] mb-4">
                                {obj[key]?.weekly_assessment?.topic}
                              </div>
                              <div className="justify-between space-y-2 ">
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Attendance :
                                  </div>
                                  <div
                                    className={`text-[14px] font-semibold leading-normal text-[#6B6B6B] ${
                                      obj[key]?.weekly_assessment?.attendance === true
                                        ? "text-[#00C22B]"
                                        : "text-[#DC2626]"
                                    }`}
                                  >
                                    {obj[key]?.weekly_assessment?.attendance === true ? "Present" : "Absent"}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Score :
                                  </div>
                                  <div
                                    className={`text-[14px] font-semibold leading-normal  ${obj[key]?.weekly_assessment?.total_score ? "text-[#FBBF24]" : "text-[#6B6B6B]"}`}
                                  >
                                    {obj[key]?.weekly_assessment?.total_score
                                      ? `${obj[key]?.weekly_assessment?.score_got + "/" + obj[key]?.weekly_assessment?.total_score}`
                                      : "N/A"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 border-t-[0.5px] h-0"></div>
                          </div>
                        )}
                        {obj[key]?.problem_solving_track && (
                          <div>
                            <div className="ml-6 mt-6">
                              <div className="text-[14px] font-semibold text-[#414141] mb-4 ">
                                Total Proplems Solved
                              </div>
                              <div className="justify-between space-y-2 ">
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Leet Code Problems :
                                  </div>
                                  <div className={`text-[14px] font-semibold leading-normal text-[#6B6B6B] `}>
                                    {obj[key]?.problem_solving_track?.leetcode_count}
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                    • Platform Problems :
                                  </div>
                                  <div className={`text-[14px] font-semibold leading-normal text-[#6B6B6B] `}>
                                    {obj[key]?.problem_solving_track?.platform_count}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 border-t-[0.5px] h-0"></div>
                          </div>
                        )}
                        {obj[key]?.mentor_session && <div>Mentor Session</div>}
                        {obj[key]?.ssm && (
                          <div className="ml-6 mt-6">
                            <div className="text-[14px] font-semibold text-[#414141] mb-[16px] ">
                              Student Progress Meeting
                            </div>
                            <div className="justify-between space-y-2 pb-6">
                              <div className="flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Status:
                                </div>
                                <div
                                  className={`text-[14px] font-semibold leading-normal ${
                                    obj[key]?.ssm?.status === "Scheduled"
                                      ? "text-yellow-500"
                                      : obj[key]?.ssm?.status === "N/A"
                                        ? "text-gray-500"
                                        : obj[key]?.ssm?.status === "Student Absent" ||
                                            obj[key]?.ssm?.status === "SSM Absent"
                                          ? "text-red-500"
                                          : obj[key]?.ssm?.status === "Completed"
                                            ? "text-[#00C22B]"
                                            : "text-black-500"
                                  }`}
                                >
                                  {obj[key]?.ssm?.status}
                                </div>
                              </div>
                              <div className="flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Date :
                                </div>
                                <div className="text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                                  {formatDate(removeHypens(obj[key]?.ssm?.meeting_date), obj[key]?.ssm?.status)}
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48  text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Meeting Agenda : <br /> <br />
                                </div>
                                <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                                  <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                                    <div className="break-words">{obj[key]?.ssm?.meeting_agenda}</div>
                                  </pre>
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Student Rating <br />
                                  <div className="flex text-[#ADADAD]">
                                    <div>&nbsp;(Given by SSM)</div>
                                    <div className="text-[#414141]">:</div>
                                  </div>
                                </div>
                                <div className=" text-[14px] font-semibold leading-normal text-[#FBBF24]">
                                  {obj[key]?.ssm?.rate ? obj[key]?.ssm?.rate + "/5" : "N/A"}
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48  text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • SSM Remark :<br /> <br />
                                </div>
                                <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                                  <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                                    <div className="break-words">
                                      {obj[key]?.ssm?.meeting_ssmRemark ? obj[key]?.ssm?.meeting_ssmRemark : "-"}
                                    </div>
                                  </pre>
                                </div>
                              </div>
                              <div className=" flex">
                                <div className=" w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Student's Feedback : <br /> <br />
                                </div>
                                <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                                  <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal ">
                                    <div className="break-words">
                                      {obj[key]?.ssm?.feedback ? obj[key]?.ssm?.feedback : "-"}
                                    </div>
                                  </pre>
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Problem Solving <br /> &nbsp; Milestone Due date :
                                </div>
                                <div className=" text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                                  {obj[key]?.ssm?.problem_solving_milestone_due_date
                                    ? obj[key]?.ssm?.problem_solving_milestone_due_date &&
                                      formatDate(
                                        removeHypens(obj[key]?.ssm?.problem_solving_milestone_due_date),
                                        obj[key]?.ssm?.status
                                      )
                                    : "N/A"}
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Problem Solving <br />
                                  &nbsp; Milestone : <br />
                                </div>
                                <div className="p-1/4  items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                                  <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                                    <div className="break-words">
                                      {obj[key]?.ssm?.problem_solving_milestone
                                        ? obj[key]?.ssm?.problem_solving_milestone
                                        : "-"}
                                    </div>
                                  </pre>
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Development Milestone <br />
                                  &nbsp; Due date:
                                </div>
                                <div className="text-[14px] font-semibold leading-normal text-[#6B6B6B]">
                                  {obj[key]?.ssm?.development_milestone_due_date
                                    ? formatDate(
                                        removeHypens(obj[key]?.ssm?.development_milestone_due_date),
                                        obj[key]?.ssm?.status
                                      )
                                    : "N/A"}
                                </div>
                              </div>
                              <div className=" flex">
                                <div className="w-48 text-[12px] font-normal leading-[140%] text-[#414141]">
                                  • Development Milestone : <br />
                                  <br />
                                </div>
                                <div className="p-1/4   items-center justify-center gap-10 rounded-[4px] bg-[#F7F7F7] px-2 pt-1 pb-2">
                                  <pre className="w-[320px] text-[12px] font-normal leading-[140%] text-[#414141] whitespace-normal  ">
                                    <div className="break-words">
                                      {obj[key]?.ssm?.development_milestone
                                        ? obj[key]?.ssm?.development_milestone
                                        : "-"}
                                    </div>
                                  </pre>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 border-t-[0.5px] h-0"></div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="overflow-y-scroll no-scrollbar text-center pt-20 w-full">Data Not Available</div>
                    )}
                    <div className="absolute top-0 right-0  pt-0 flex h-6 mt-6 mr-6 ">
                      <button
                        className="text-gray-500 hover:text-gray-600 focus:outline-none"
                        onClick={props.handleToggleDetails}
                      >
                        <Cut />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* </Model> */}
    </>
  )
}

export default DetailedDailyReport
