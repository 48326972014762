import { Auth } from "domain/model/Auth"
import { LeaderboardRepository } from "domain/repository/Student/LeaderboardRespository"

export interface GetLeaderboardUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetLeaderboard implements GetLeaderboardUseCase {
  private repository: LeaderboardRepository

  constructor(repository: LeaderboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getLeaderboard(auth)
  }
}
