import { Auth } from "domain/model/Auth"
import { loadNextData } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface RemoveMentorUseCase {
  invoke(auth: Auth, email: string): Promise<any>
}

export default class RemoveMentor implements RemoveMentorUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string): Promise<any> {
    return this.repository.removeMentor(auth, email)
  }
}
