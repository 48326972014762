// import { getStudentDetails } from "domain/model/StudentDashboard"
import { Auth } from "domain/model/Auth"
import ContestApproveRepository from "domain/repository/Admin/ContestApproveRepository"

export interface getTop100ApproveRequestsUseCase {
  invoke(auth: Auth): Promise<any>
}
export class GetTop100ApproveRequests implements getTop100ApproveRequestsUseCase {
  private repository: ContestApproveRepository

  constructor(repository: ContestApproveRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getTop100ApproveRequests(auth)
  }
}
