import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface AllotMentorsUseCase {
  invoke(auth: Auth, batch: any): Promise<any>
}

export class AllotMentors implements AllotMentorsUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch: any) {
    return this.repository.allotMentors(auth, batch)
  }
}
