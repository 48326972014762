import React from "react"

export default function TransactionIcon({ className, ...props }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33008 1.99658C1.33008 1.62848 1.62848 1.33008 1.99658 1.33008H13.9936C14.3617 1.33008 14.6601 1.62848 14.6601 1.99658V13.9936C14.6601 14.3617 14.3617 14.6601 13.9936 14.6601H1.99658C1.62848 14.6601 1.33008 14.3617 1.33008 13.9936V1.99658ZM2.66308 2.66308V13.3271H13.3271V2.66308H2.66308Z"
        fill="white"
      />
      <path
        d="M6.17076 7.54471L7.15339 8.44545L6.34432 9.32808H11.3276V10.6611H6.34431L7.15339 11.5437L6.17077 12.4445L3.92505 9.99459L6.17076 7.54471Z"
        fill="white"
      />
      <path
        d="M9.81939 8.44546L12.0651 5.99558L9.81939 3.54571L8.83676 4.44645L9.64584 5.32908H4.66258V6.66208H9.64585L8.83676 7.54472L9.81939 8.44546Z"
        fill="white"
      />
    </svg>
  )
}
