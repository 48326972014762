import { Auth } from "domain/model/Auth"
import McqDataSource from "data/dataSource/Admin/McqDataSource"
import pn from "core/utils/pn"
import { postAPI, deleteAPI } from "core/utils/axios"
import {
  API_CREATE_MCQ_QUESTION,
  API_GET_MCQ_QUESTION,
  API_GET_MCQ_QUESTIONS,
  API_UPDATE_MCQ_QUESTION,
  API_DELETE_MCQ_QUESTION,
  API_V4_ADMIN,
} from "core/constants/strings"

export default class McqAPIDataSourceImpl implements McqDataSource {
  async get_mcq_questions(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MCQ_QUESTIONS), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async create_mcq_question(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_MCQ_QUESTION), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async get_mcq_question(auth: Auth, id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MCQ_QUESTION), {
        id_token: auth?.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async update_mcq_question(auth: Auth, id: string, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_MCQ_QUESTION), {
        id_token: auth?.id_token,
        id,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async delete_mcq_question(auth: Auth, id: string) {
    try {
      const response = await deleteAPI(pn(API_V4_ADMIN, API_DELETE_MCQ_QUESTION), {
        id_token: auth?.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
