import { Auth } from "domain/model/Auth"
import { GroupMentorSession } from "domain/model/GroupMentorSession"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface UpdateGroupMentorSessionUseCase {
  invoke(auth: Auth, session_id: string, group_session: GroupMentorSession): Promise<any>
}

export default class UpdateGroupMentorSession implements UpdateGroupMentorSessionUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string, group_session: GroupMentorSession) {
    return await this.repository.updateGroupMentorSession(auth, session_id, group_session)
  }
}
