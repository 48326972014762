import Button from "core/components/new/Button"
import Input from "core/components/new/Input"

export default function AddressModal({ show, onHide, editing, address, onChange, onSubmit }: any) {
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      !address?.flat ||
      !address?.block ||
      !address?.area ||
      !address?.nearby_landmark ||
      !address?.city ||
      !address?.state ||
      !address?.pincode ||
      !address?.country
    ) {
      return
    }

    onSubmit()
  }

  if (!show) return null

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onHide} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
        <div className="no-scrollbar h-screen overflow-y-auto py-5">
          <form className="flex w-[600px] transform flex-col rounded-lg bg-new-solid-white" onSubmit={handleSubmit}>
            <div className="flex items-center justify-between p-8">
              <h3 className="text-new-accent">{!editing ? "New" : "Edit"} Address</h3>
              <Button type="button" onClick={onHide} className="flex w-fit items-center gap-1.5" outlined failure>
                Close
              </Button>
            </div>
            <div className="flex flex-col gap-4 px-8">
              <Input
                type="text"
                label="Flat / House / Building Name / Apartment"
                name="flat"
                id="flat"
                value={address?.flat}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="Block / Floor No."
                name="block"
                id="block"
                value={address?.block}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="Area, Street, Sector, Village"
                name="area"
                id="area"
                value={address?.area}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="Nearby Landmark"
                name="nearby_landmark"
                id="nearby_landmark"
                value={address?.nearby_landmark}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="Town / City"
                name="city"
                id="city"
                value={address?.city}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="State"
                name="state"
                id="state"
                value={address?.state}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="Pincode"
                name="pincode"
                id="pincode"
                value={address?.pincode}
                onChange={onChange}
                required
              />
              <Input
                type="text"
                label="Country"
                name="country"
                id="country"
                value={address?.country}
                onChange={onChange}
                required
              />
            </div>
            <div className="mt-8 flex justify-center gap-2 border-t p-8">
              <Button outlined type="button" onClick={onHide} className="w-[156px]" failure>
                Cancel
              </Button>
              <Button small className="w-[156px]">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
