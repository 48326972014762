import HomeDataSource from "data/dataSource/Admin/HomeDataSource"
import { Auth } from "domain/model/Auth"
import { FilterData } from "domain/model/FilterData"

export class HomeRepositoryImpl implements HomeRepositoryImpl {
  private dataSource: HomeDataSource

  constructor(dataSource: HomeDataSource) {
    this.dataSource = dataSource
  }

  async getFilteredData(auth: Auth, data: FilterData): Promise<any> {
    return this.dataSource.getFilteredData(auth, data)
  }

  async getAllBatches(auth: Auth): Promise<any> {
    return this.dataSource.getAllBatches(auth)
  }

  async getAllMentors(auth: Auth): Promise<any> {
    return this.dataSource.getAllMentors(auth)
  }

  async getProductAnalytics(data: any): Promise<any> {
    return await this.dataSource.getProductAnalytics(data)
  }
}
