import DoubtSupportViewModel from "../DoubtSupportViewModel"
import React from "react"
import Button from "core/components/Button"
import BatchShiftModal from "./BatchShiftModal"
import { cn } from "core/lib/utils"
import CurrentMentorCard from "./CurrentMentorCard"
import SelectMentorModal from "./SelectMentorModal"
import Loader from "core/components/Loader"
import IssueResolvedPage from "./IssueResolvedPage"
import EdgeCasePage from "./EdgeCasePage"
import OperationalIssueTable from "./OperationalIssueTable"

export default function OperationalIssueCard(props: any) {
  const {
    fetchFutureBatches,
    futureBatchDes,
    selectedBatch,
    batchChangeStatus,
    fetchEligibleMentors,
    eligibleForBatchChange,
    eligibleMentorDetails,
    futureBatchEligiblity,
  } = DoubtSupportViewModel()
  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [heading, setHeading] = React.useState<any>()
  const [reason, setReason] = React.useState<any>("")
  const [openMentorToSelect, setOpenMentorToSelect] = React.useState<any>(false)

  const [selectedMentor, setSelectedMentor] = React.useState<any>({})
  const [discordUsername, setDiscordUsername] = React.useState<any>("")

  React.useEffect(() => {
    fetchFutureBatches()
    eligibleForBatchChange()
    fetchEligibleMentors()
  }, [])

  return (
    <>
      {/* Confirmation Modal  */}
      {isOpen && (
        <BatchShiftModal
          setIsOpen={setIsOpen}
          heading={heading}
          selectedBatch={selectedBatch}
          futureBatchDes={futureBatchDes}
          selectedMentor={selectedMentor}
          reason={reason}
          topic={props.topic}
          setStudentBatchShift={props.setStudentBatchShift}
          setStudentBatchPause={props.setStudentBatchPause}
          setSlidingPopUpText={props.setSlidingPopUpText}
          setMentor={props.setMentor}
          discordUsername={discordUsername}
        />
      )}

      {/* Browse Mentors Modal */}
      {openMentorToSelect && (
        <SelectMentorModal
          mentors={eligibleMentorDetails?.mentor}
          setOpenMentorToSelect={setOpenMentorToSelect}
          setSelectedMentor={setSelectedMentor}
          selectedMentor={selectedMentor}
        />
      )}

      {/* Code for Batch Change */}
      {props.topic === "Batch Change" &&
        (Object.keys(batchChangeStatus).length === 0 ? (
          <div className="custom-shadow border-1 flex h-[482px] w-full items-center justify-center border">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Batch Change" h2="Your Batch has been changed successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Batch Change" h2="Your Batch cannot be changed as your course has concluded" />
        ) : batchChangeStatus["is_batch_changed"] === 1 || batchChangeStatus["valid_batch_change"] >= 2 ? (
          <EdgeCasePage
            h1="Batch Change"
            h2="You're not eligible for batch change"
            h3="You have already changed your batch once"
          />
        ) : batchChangeStatus["is_batch_paused_recently"] === 1 ? (
          <EdgeCasePage
            h1="Batch Change"
            h2="Batch Change is currently disabled as your Course is on Pause"
            h3="Please Resume your Course to proceed with choosing a preferred
          batch"
          />
        ) : (
          <div className="custom-shadow border-1 h-[482px] w-full overflow-y-scroll border">
            <div className="m-4 flex flex-col gap-y-6">
              <div className="text-lg font-semibold text-[#312E81]">{props.topic + " :"}</div>
              <div className="flex flex-col gap-y-2">
                <div className="relative flex">
                  <div className="flex gap-x-1 text-sm font-semibold">
                    <div className="">Opt for a New Batch</div>
                    <div className="text-red-600">*</div>
                  </div>
                  <div className="absolute right-0 flex gap-x-1 text-sm font-semibold">
                    <div className="">Selected Batch:</div>
                    <div className={cn(selectedBatch === -1 ? "text-black" : "text-[#00C22B]")}>
                      {selectedBatch === -1 ? "N/A" : futureBatchDes["rows"][selectedBatch]["batch"]}
                    </div>
                  </div>
                </div>
                {futureBatchDes["header"].length === 0 ? (
                  <Loader />
                ) : (
                  <OperationalIssueTable
                    data={futureBatchDes}
                    OperationalIssue={true}
                    futureBatchEligiblity={futureBatchEligiblity}
                  />
                )}
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="text-[10px] font-medium">
                  <span className="text-red-600">** Note : </span>
                  You can change your batch only once throughout the course
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="flex gap-x-1 text-sm font-semibold">
                  <div className="">Reason to change Batch</div>
                  <div className="text-red-600">*</div>
                </div>
                <textarea
                  id="reason"
                  name="reason"
                  placeholder="Comment here!"
                  onChange={(event) => {
                    setReason(event.target.value)
                  }}
                />
                <div className="text-[10px] font-medium">400 characters</div>
              </div>

              {/* <div className="flex flex-col gap-y-2">
                <div className="flex gap-x-1 text-sm font-semibold">
                  <div className="">Your Discord Username</div>
                  <div className="text-red-600">*</div>
                </div>
                <textarea
                  id="discord"
                  name="discord"
                  placeholder="Username"
                  onChange={(event) => {
                    setDiscordUsername(event.target.value);
                  }}
                />
              </div> */}
            </div>
            <div className="sticky bottom-0 bg-white p-4">
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setHeading("Are you sure you want to change your current batch to selected batch?")
                }}
                className="border-1 h-[36px] w-[146px] rounded-sm border bg-new-gradient text-sm font-medium text-white hover:bg-[#3941ED] hover:bg-none"
                disabled={
                  selectedBatch === -1 || reason === ""
                  // discordUsername === ""
                }
              >
                Confirm
              </Button>
            </div>
          </div>
        ))}

      {props.topic === "Pause Course" &&
        (Object.keys(batchChangeStatus).length === 0 ? (
          <div className="custom-shadow border-1 flex h-[482px] w-full items-center justify-center border">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Pause Course" h2="You Course has been Paused successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Pause Course" h2="Your Course cannot be Paused as your course has concluded" />
        ) : batchChangeStatus["is_batch_paused"] === 1 ? (
          <EdgeCasePage
            h1="Pause Course"
            h2="You are not eligible for Course Pause"
            h3="Your Course is already Paused"
          />
        ) : (
          <div className="custom-shadow border-1 relative flex h-[482px] w-full flex-col gap-y-6 overflow-y-scroll border p-4">
            <div className="text-lg font-semibold text-[#312E81]">{props.topic + " :"}</div>
            <div className="flex flex-col gap-y-2">
              <div className="flex gap-x-1 text-sm font-semibold">
                <div className="">Reason to Pause Course</div>
                <div className="text-red-600">*</div>
              </div>
              <textarea
                id="reason"
                name="reason"
                placeholder="Comment here!"
                onChange={(event) => {
                  setReason(event.target.value)
                }}
              />
              <div className="text-[10px] font-medium">400 characters</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="text-[10px] font-medium">
                <span className="text-red-600">** Note : </span>
                You can pause your course only once throughout the course for a maximum period of 3 months.
              </div>
            </div>
            <div className="absolute bottom-4">
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setHeading("Are you sure you want to pause your Course?")
                }}
                className="border-1 h-[36px] w-[146px] rounded-sm border bg-new-gradient text-sm font-medium text-white hover:bg-[#3941ED] hover:bg-none"
                disabled={reason === ""}
              >
                Confirm
              </Button>
            </div>
          </div>
        ))}

      {props.topic === "Resume Course" &&
        (Object.keys(batchChangeStatus).length === 0 ? (
          <div className="custom-shadow border-1 flex h-[482px] w-full items-center justify-center border">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Resume Course" h2="You Course has been Resumed successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Resume Course" h2="Your Course cannot be Resumed as your course has concluded" />
        ) : batchChangeStatus["is_batch_resume"] === 1 || batchChangeStatus["is_batch_paused"] === 0 ? (
          <EdgeCasePage
            className="text-[#DC2626]"
            h1="Resume Course"
            h2="Course Resumption is applicable to students"
            h3="who had paused their course earlier"
          />
        ) : (
          <div className="custom-shadow border-1 relative h-[482px] w-full overflow-y-scroll border">
            <div className="m-4 flex flex-col gap-y-6">
              <div className="text-lg font-semibold text-[#312E81]">{props.topic + " :"}</div>
              <div className="flex flex-col gap-y-2">
                <div className="relative flex">
                  <div className="flex gap-x-1 text-sm font-semibold">
                    <div className="">Opt for a New Batch to Resume your Course</div>
                    <div className="text-red-600">*</div>
                  </div>
                  <div className="absolute right-0 flex gap-x-1 text-sm font-semibold">
                    <div className="">Selected Batch:</div>
                    <div className={cn(selectedBatch === -1 ? "text-black" : "text-[#00C22B]")}>
                      {selectedBatch === -1 ? "N/A" : futureBatchDes["rows"][selectedBatch]["batch"]}
                    </div>
                  </div>
                </div>
                {futureBatchDes["header"].length === 0 ? (
                  <Loader />
                ) : (
                  <OperationalIssueTable
                    data={futureBatchDes}
                    OperationalIssue={true}
                    futureBatchEligiblity={futureBatchEligiblity}
                  />
                )}
              </div>
              {/* <div className="flex flex-col gap-y-2">
                <div className="flex gap-x-1 text-sm font-semibold">
                  <div className="">Your Discord Username</div>
                  <div className="text-red-600">*</div>
                </div>
                <textarea
                  id="discord"
                  name="discord"
                  placeholder="Username"
                  onChange={(event) => {
                    setDiscordUsername(event.target.value);
                  }}
                />
              </div> */}
            </div>
            <div className="sticky bottom-0 w-full bg-white p-4">
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setHeading("Are you sure you want to resume your course from selected batch?")
                }}
                className="border-1 h-[36px] w-[146px] rounded-sm border bg-new-gradient text-sm font-medium text-white hover:bg-[#3941ED] hover:bg-none"
                disabled={selectedBatch === -1}
              >
                Confirm
              </Button>
            </div>
          </div>
        ))}

      {/* Code for Mentor Change */}
      {props.topic === "Mentor Change" &&
        (Object.keys(eligibleMentorDetails).length === 0 ? (
          <div className="custom-shadow border-1 flex h-[482px] w-full items-center justify-center border">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Mentor Change" h2="You Mentor has been changed successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Mentor Change" h2="Your Mentor cannot be changed as your course has concluded" />
        ) : eligibleMentorDetails?.mentor_history.length === 0 ? (
          <EdgeCasePage
            h1="Mentor Change"
            h2="You haven't selected your Mentor yet"
            h3="Please complete the Mentor selection process"
          />
        ) : (
          <div className="custom-shadow border-1 h-[482px] w-full overflow-y-scroll border">
            <div className=" relative flex flex-col gap-y-6 p-4">
              <div className="flex flex-col">
                <div className="flex gap-x-1 text-lg font-semibold">
                  <div className="">Choose another Mentor</div>
                  <div className="text-red-600">*</div>
                </div>

                <button
                  onClick={() => setOpenMentorToSelect(true)}
                  className={cn(
                    "mt-2 flex h-[33px] w-[170px] items-center justify-center rounded-sm	border border-[#312E81] text-sm font-medium text-[#312E81]",
                    batchChangeStatus["mentor_changed"] >= 6 ? "cursor-not-allowed opacity-60" : ""
                  )}
                  disabled={batchChangeStatus["mentor_changed"] >= 6}
                >
                  <div>Browse Mentor List</div>
                </button>

                <div className="mt-4 text-[12px] font-medium">
                  {batchChangeStatus["mentor_changed"] < 6 ? (
                    <>
                      <span className="text-red-600">** Note : </span>
                      <>
                        Each student will have the opportunity to request a mentor change a maximum of five times
                        throughout the duration of their course.
                      </>{" "}
                    </>
                  ) : (
                    <>
                      <span className="text-red-600">** Note : </span>
                      <>
                        You are no longer eligible for mentor change as you have already changed your mentor five times.
                      </>
                    </>
                  )}
                </div>
              </div>

              {Object.keys(selectedMentor).length !== 0 && (
                <>
                  <CurrentMentorCard details={selectedMentor} state="Selected" />
                  <div className="flex flex-col gap-y-2">
                    <div className="flex gap-x-1 text-sm font-semibold">
                      <div className="">Reason to change Mentor</div>
                      <div className="text-red-600">*</div>
                    </div>
                    <textarea
                      id="reason"
                      name="reason"
                      placeholder="Comment here!"
                      onChange={(event) => {
                        setReason(event.target.value)
                      }}
                    />
                    <div className="text-[10px] font-medium">400 characters</div>
                  </div>
                </>
              )}

              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-1 text-lg font-semibold">
                  <div className="">Mentor Change History</div>
                  <div className="text-red-600">*</div>
                </div>

                <div className="flex flex-col gap-y-4 rounded-sm p-6 shadow-[0px_3px_7px_rgba(0,0,0,0.1)]">
                  {eligibleMentorDetails?.mentor_history?.map((mentor: any, i: number) => (
                    <CurrentMentorCard key={i} details={mentor} state={i == 0 ? "Current" : "Previous"} />
                  ))}
                </div>
              </div>
            </div>
            <div className="sticky bottom-0 bg-white p-4">
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setHeading("Are you sure you want to change your Mentor?")
                }}
                className="border-1 h-[36px] w-[146px] rounded-sm border bg-new-gradient p-1 text-sm font-medium text-white hover:bg-[#3941ED] hover:bg-none"
                disabled={
                  reason === "" || Object.keys(selectedMentor).length === 0 || batchChangeStatus["mentor_changed"] >= 6
                }
              >
                Confirm
              </Button>
            </div>
          </div>
        ))}

      {props.topic === "Other" && (
        <div className="custom-shadow border-1 flex h-[482px] w-full items-center justify-center border p-6 font-normal">
          <div className="flex w-full flex-col font-semibold text-red-500">
            <div className="text-center">For any operational issues, kindly</div>
            <div className="text-center">mail us at help@bosscoderacademy.com</div>
          </div>
        </div>
      )}
    </>
  )
}
