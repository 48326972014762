import { InvoiceSearch } from "domain/model/PaymentInvoice"
import { PaymentInvoiceRepository } from "domain/repository/Admin/PaymentInvoiceRepository"

export interface GetInvoiceByFilterSearchUseCase {
  invoke(InvoiceFilterSearch: InvoiceSearch): Promise<any>
}
export class GetInvoiceByFilterSearch implements GetInvoiceByFilterSearchUseCase {
  private repository: PaymentInvoiceRepository

  constructor(repository: PaymentInvoiceRepository) {
    this.repository = repository
  }

  async invoke(InvoiceFilterSearch: InvoiceSearch): Promise<any> {
    return await this.repository.getInvoiceByFilterSearch(InvoiceFilterSearch)
  }
}
