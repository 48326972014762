import { cn } from "core/lib/utils"
import React from "react"

const BronzeFlip = ({ className }: any) => {
  return (
    <svg className={cn(className)} viewBox="0 0 125 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.0031 0L93.9646 17.7522L125 36L125 71.9744V108L93.9646 126.248L63.0031 144L31.5 126L0 108L0 72L0 36L31.3215 17.7522L63.0031 0Z"
        fill="#AB765E"
      />
      <path d="M8 91L44 55L77 65L59 134L7.5 106L8 91Z" fill="#6B453A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 9L35.291 24.6983L8.0004 41L8.0004 103.507L35.2911 119.302L62.9961 134.996L89.9471 119.33L117 104.01L117 40.5765L89.9471 24.6705L63 9ZM93.9646 17.7522L63.0031 0L31.3215 17.7522L0 36L0 108L16 117L31.5 126L63.0031 144L93.9646 126.248L125 108L125 36L93.9646 17.7522Z"
        fill="#F2B483"
      />
      <path d="M66.002 10.7526L68.9996 3.44141L117.164 31.3945L111 37.0156L66.002 10.7526Z" fill="#F8E2D4" />
      <path d="M63 144V135L8 103.5L8 41L0 36L0 108L63 144Z" fill="#AA6D44" />
      <circle cx="62" cy="55" r="18" fill="#92573D" />
      <g filter="url(#filter0_i_1439_59214)">
        <circle cx="62" cy="55" r="16" stroke="#F2B483" stroke-width="4" />
      </g>
      <defs>
        <filter
          id="filter0_i_1439_59214"
          x="44"
          y="37"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1439_59214" />
        </filter>
      </defs>
    </svg>
  )
}

export default BronzeFlip
