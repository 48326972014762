import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer"
import InvoiceTitleImg from "assets/images/invoice.jpg"

export default function InvoiceTemplate({ data }: any) {
  Font.register({
    family: "Montserrat",
    src: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap", // Replace with the URL of the Montserrat font file
  })

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      height: "100%",
      fontFamily: "Times-Roman",
      padding: 24,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      gap: 20,
      height: "123px",
      backgroundColor: `#203580`,
      fontFamily: "Times-Roman",
      color: "white",
      padding: 2,
      marginBottom: 16,
      alignItems: "center",
      justifyContent: "center",
    },
    headerTextTitle: {
      fontSize: 18,
      marginBottom: 8,
      display: "flex",
      flexDirection: "column",
      fontFamily: "Times-Roman",
    },
    headerText: {
      fontSize: 10,
      marginBottom: 8,
      display: "flex",
      flexDirection: "column",
      fontFamily: "Times-Roman",
    },
    headerContent: {
      fontSize: 10,
    },
    section: {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      marginTop: 30,
    },
    sectionTitle: {
      fontSize: 14,
      marginBottom: 8,
      color: `#203580`,
    },
    contents: {
      display: "flex",
      flexDirection: "row",
      gap: 80,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    belowcontents: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    belowcontent: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    lastcontent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: 10,
    },
    contentTitle: {
      fontSize: 12,
      fontFamily: "Times-Bold",
      color: `#312E81`, // Change color as desired
    },
    belowcontentTitle: {
      fontSize: 12,
      fontFamily: "Times-Bold",
      color: `black`, // Change color as desired
    },
    contentText: {
      fontSize: 10,
      color: "black", // Change color as desired
      textTransform: "capitalize",
    },
    belowcontentText: {
      fontSize: 10,
      color: "black",
    },
    belowrightcontent: {
      display: "flex",
      flexDirection: "row",
      gap: 30,
    },
    imageContainer: {
      display: "flex",
    },
    image: {
      // width: 100,
      // height: 100,
    },
    horizontalLine: {
      borderBottomColor: "#312E81",
      borderBottomWidth: "3px",
      marginVertical: 10, // adjust margin as needed
    },
  })

  // Format timestamp to date
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp * 1000)
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions
    return date.toLocaleDateString("en-US", options) // Format like: 12 Feb, 2023
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={InvoiceTitleImg} style={styles.image} />
        <View style={styles.section}>
          <View style={styles.contents}>
            <View style={styles.content}>
              <Text style={styles.contentTitle}>Invoice Number:</Text>
              <Text style={styles.contentText}>{data.payment.invoice_number}</Text>
            </View>

            <View style={styles.content}>
              <Text style={styles.contentTitle}>Date of payment:</Text>
              <Text style={styles.contentText}>{formatDate(data.payment.paid_at)}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.contentTitle}>Due Date:</Text>
              <Text style={styles.contentText}>{data.payment.due_time}</Text>
            </View>
          </View>
          <View style={styles.contents}>
            <View style={styles.content}>
              <Text style={styles.contentTitle}>Payment Method:</Text>
              <Text style={styles.contentText}>{data.payment.payment_method}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.contentTitle}>Payment Status:</Text>
              <Text style={styles.contentText}>{data.payment.status}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.contentTitle}>Amount:</Text>
              <Text style={styles.contentText}>{`${data.payment.amount} Rs`}</Text>
            </View>
          </View>
          <View style={styles.horizontalLine} />

          <View style={styles.belowcontents}>
            <View style={styles.belowcontent}>
              <Text style={styles.belowcontentTitle}>Description:</Text>
              <Text style={styles.belowcontentText}>{`${data.description} `}</Text>
            </View>
            <View style={styles.belowrightcontent}>
              {data.payment_type === "seat_booking" ? (
                <Text style={styles.belowcontentTitle}>Fee Type: Seat Booking</Text>
              ) : (
                <View style={styles.belowcontent}>
                  <Text style={styles.belowcontentTitle}>Instalment No.</Text>
                  <Text style={styles.contentText}>{`Instalment ${data.index - 1}`}</Text>
                </View>
              )}
              <View style={styles.belowcontent}>
                <Text style={styles.belowcontentTitle}>Amount (In Rs.)</Text>
                <Text style={styles.belowcontentTitle}>{`${data.payment.amount} Rs`}</Text>
              </View>
            </View>
          </View>

          <View style={styles.lastcontent}>
            <Text style={styles.contentTitle}>Created on:</Text>
            <Text style={styles.contentText}>{formatDate(data.payment.created_at)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
