import { Auth } from "domain/model/Auth"
import { GroupMentorSession } from "domain/model/GroupMentorSession"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface CreateGroupMentorSessionUseCase {
  invoke(auth: Auth, group_session: GroupMentorSession): Promise<any>
}

export default class CreateGroupMentorSession implements CreateGroupMentorSessionUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, group_session: GroupMentorSession) {
    return await this.repository.createGroupMentorSession(auth, group_session)
  }
}
