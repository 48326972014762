import React from "react"

export default function File(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 13.995V5.33301L9.998 1.33301H2.668C2.29933 1.33301 2 1.63634 2 1.99434V14.005C2.00037 14.3704 2.29665 14.6663 2.662 14.6663H13.338C13.5148 14.6651 13.6839 14.5937 13.808 14.4678C13.9322 14.3419 14.0012 14.1718 14 13.995ZM9.33333 5.99967H12.6667V13.333H3.33333V2.66634H9.33333V5.99967Z"
        fill="currentColor"
      />
    </svg>
  )
}
