import { cn } from "core/lib/utils"
import { useEffect, useState } from "react"

interface ToggleProps {
  label?: string
  checked: boolean
  className?: string
  status?: boolean
  onChange: (checked: boolean) => any
}

const Toggle = ({ label, checked, className, status, onChange }: ToggleProps) => {
  const [toggleChecked, setToggleChecked] = useState<boolean>(checked)

  useEffect(() => {
    setToggleChecked(checked)
  }, [checked])

  const handleChange = () => {
    onChange(!toggleChecked)
    setToggleChecked(!toggleChecked)
  }

  return (
    <label className="relative inline-flex cursor-pointer items-center">
      <input
        type="checkbox"
        value=""
        // disabled={checked}
        className="peer sr-only"
        checked={toggleChecked}
        onChange={handleChange}
      />
      <div
        className={cn(
          `peer h-6 w-11 rounded-full bg-gray-200 peer-focus:ring-4 dark:bg-gray-700 ${
            toggleChecked &&
            `peer-checked:after:translate-x-full ${status ? "peer-checked:bg-red-600" : "peer-checked:bg-green-600"}`
          } peer-focus: ${
            status ? "peer-focus:ring-4 peer-focus:ring-red-300" : "ring-green-300 dark:peer-focus:ring-green-800"
          }after:content-[''] after: after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-new-solid-white after:transition-all dark:border-gray-600 ${
            toggleChecked ? "peer-checked:border-white" : ""
          }`,

          className
        )}
      ></div>
      <span className="text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
    </label>
  )
}

export default Toggle
