import SearchInput from "core/components/SearchInput"
import Select from "core/components/Select"
import DashboardLayout from "core/layouts/DashboardLayout"
import useMenteeViewModel from "./MyMenteesViewModel"
import React from "react"
import MyMenteeCard from "./components/MyMenteeCard"
import Spinner from "core/components/Spinner"
import ConditionalLoader from "core/components/ConditionalLoader"
import { STR_MENTEE_LIST, STR_MENTEE_LIST_SUBTITLES } from "core/constants/strings"
import { ErrorCloseIcon } from "core/constants/svgs"
import Toast from "core/components/Toast"

export default function MyMenteesView() {
  const {
    searchByBatch,
    allMenteeList,
    searchByEmail,
    loading,
    toast,
    newMentorshipFlow,
    setNewMentorshipFlow,
    changeToastVisibility,
    getMentorMentee,
    searchingForBatch,
    searchingForEmail,
    handleSearchByBatch,
    handleSearchByEmail,
  } = useMenteeViewModel()
  React.useEffect(() => {
    getMentorMentee()
  }, [])

  const batchList = Array.from(new Set(allMenteeList.map((m: any) => m.batch)))
  return (
    <>
      <DashboardLayout>
        <ConditionalLoader
          isLoading={loading}
          loader={
            <div className="mt-[60px] flex items-center justify-center">
              <Spinner />
            </div>
          }
        >
          <div>
            {allMenteeList.length === 0 && (
              <div className="m-8 mt-[10%] flex gap-4 rounded p-8 shadow-[0px_4px_16px_rgba(134,134,134,0.2)]">
                <span>
                  <ErrorCloseIcon className="h-4 w-4" />
                </span>
                <p>No Mentee allotted yet!</p>
              </div>
            )}
            <div className="flex items-end justify-between px-6 pt-6 pb-6">
              <div className="flex w-full flex-col">
                <h1 className="mb-2 text-xl font-bold leading-[29px] text-new-accent">{STR_MENTEE_LIST}</h1>
                <p className="font-medium">{STR_MENTEE_LIST_SUBTITLES}</p>
              </div>
              <div className="flex flex-row gap-4">
                <SearchInput
                  className="h-[36px] w-[292px] rounded-[8px]"
                  placeholder="Search by email"
                  value={searchByEmail}
                  onChange={handleSearchByEmail}
                />

                <Select
                  className="h-[36px] w-[292px] rounded-[8px] text-new-neutral"
                  options={batchList}
                  onChange={handleSearchByBatch}
                  value={searchByBatch}
                  mentorFilter
                />
              </div>
            </div>
            <div className="flex flex-col gap-8 p-6">
              {allMenteeList
                ?.filter(searchingForEmail(searchByEmail))
                ?.filter(searchingForBatch(searchByBatch))
                ?.map((mentee: any) => (
                  <MyMenteeCard
                    isNewMentorshipFlow={mentee?.batch_start_date >= "20240701"}
                    key={mentee.id}
                    menteeName={mentee.name}
                    menteebatch={mentee.batch}
                    menteeProfilePic={mentee.profile_pic}
                    menteeEmail={mentee.id}
                    menteeLeetcodeCount={mentee.leetcode_count}
                    menteePhone={mentee.phone}
                    isButtonEnabled={true}
                  />
                ))}
            </div>
          </div>
        </ConditionalLoader>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </DashboardLayout>
    </>
  )
}
