import React from "react"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import Toast from "core/components/Toast"
import {
  STR_BANDWIDTH,
  STR_EMAIL,
  STR_EMAIL_FIELD,
  STR_FEES,
  STR_FULLNAME,
  STR_NUMBER_FILED,
  STR_SIGN_UP,
  STR_TEXT_FIELD,
  STR_YEARS_OF_EXPERIENCE,
} from "core/constants/strings"
import useStudentViewModel from "./UserSignupViewModel"
import DashboardLayout from "core/layouts/DashboardLayout"
import Select from "core/components/new/Select"
import PhoneInput from "react-phone-number-input"
import useStudentProfileViewModel from "../StudentDashboard/StudentProfileViewModel"

export default function AddStudentView() {
  const {
    courseType,
    programType,
    batch,
    onboardingManagerId,
    milestoneSessionMentor,
    role,
    email,
    fullName,
    phoneNumber,
    yearsOfExperience,
    fees,
    filterOptions,
    programOptions,
    roleOptions,
    batches,
    onboardingManagersList,
    mentorList,
    loading,
    toast,
    timeValue,
    timeValue1,
    bandwidth,
    standardDiscount,
    seat_booking_amount,
    refreshed,
    paymentId_of_seat_booking,
    payment_submethod,
    countryOptions,
    country,
    stateOptions,
    state,
    PAYMENT_SUBMETHOD_OPTIONS,
    getOnboardingManagerList,
    seatBookingAmountChange,
    getMentorsList,
    standardDiscountChange,
    emailChange,
    fullNameChange,
    handlePhoneNumber,
    yearsOfExperienceChange,
    bandwidthChange,
    feesChange,
    batchChange,
    onSelectChangeOnboardingManager,
    onSelectMilestoneSessionMentor,
    roleChange,
    walkThroughDateChange,
    coursetypeOnChange,
    programtypeOnChange,
    signup,
    changeToastVisibility,
    fetchBatches,
    alumniOnChange,
    mentorOnChange,
    paymentId_of_seat_bookingChange,
    Seat_booking_received_dateChange,
    payment_submethodChange,
    handleCountryChange,
    handlestateChange,
  } = useStudentViewModel()

  const { id } = useStudentProfileViewModel()

  React.useEffect(() => {
    if (refreshed) {
      fetchBatches()
      getOnboardingManagerList()
      getMentorsList(id)
    }
  }, [refreshed])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">User SignUp</h2>
          <p>Create a new user credential</p>
        </div>
        <form onSubmit={signup} className="round-2 mx-auto flex w-full max-w-xl flex-col items-center gap-4 p-6 shadow">
          <h3>User Signup</h3>
          <Select
            required
            className="w-full"
            options={roleOptions}
            value={role}
            onChange={roleChange}
            defaultOption="Select Role"
          />
          <Input
            containerClass="w-full"
            type={STR_TEXT_FIELD}
            value={fullName}
            onChange={fullNameChange}
            placeholder={STR_FULLNAME}
            name={STR_FULLNAME}
            required
          />
          <Input
            containerClass="w-full"
            type={STR_EMAIL_FIELD}
            value={email}
            onChange={emailChange}
            placeholder={STR_EMAIL}
            name={STR_EMAIL}
            required
          />
          <PhoneInput
            defaultCountry="IN"
            value={phoneNumber}
            onChange={handlePhoneNumber}
            placeholder="Phone Number"
            limitMaxLength
          />
          {role == "user" && (
            <>
              <Select
                className="w-full"
                options={countryOptions}
                value={country}
                onChange={handleCountryChange}
                defaultOption="Select Country"
              />
              <Select
                className="w-full"
                options={stateOptions}
                value={state}
                onChange={handlestateChange}
                defaultOption="Select State"
              />
            </>
          )}
          {role !== "admin" && (
            <Select
              required
              className="w-full"
              options={filterOptions}
              defaultOption="Select Course Type"
              onChange={coursetypeOnChange}
              value={courseType}
            />
          )}
          {role !== "admin" && (
            <Select
              required
              className="w-full"
              options={programOptions}
              defaultOption="Select Program Type"
              onChange={programtypeOnChange}
              value={programType}
            />
          )}
          {role !== "admin" && (
            <Input
              containerClass="w-full"
              type={STR_NUMBER_FILED}
              value={yearsOfExperience}
              onChange={yearsOfExperienceChange}
              placeholder={STR_YEARS_OF_EXPERIENCE}
              name={STR_YEARS_OF_EXPERIENCE}
              disabled={courseType === "student" ? true : false}
              required
            />
          )}
          {role !== "admin" && role !== "mentor" && (
            <Select
              required
              className="w-full"
              defaultOption="Select Batch"
              value={batch}
              options={batches}
              onChange={batchChange}
            />
          )}
          {role !== "admin" && role !== "mentor" && (
            <Select
              // required
              className="w-full"
              defaultOption="Select Milestone Session Mentor"
              value={milestoneSessionMentor}
              options={mentorList}
              onChange={onSelectMilestoneSessionMentor}
            />
          )}
          {role !== "admin" && role !== "mentor" && (
            <Select
              required
              className="w-full"
              defaultOption="Select Onboarding Manager"
              options={onboardingManagersList}
              onChange={onSelectChangeOnboardingManager}
              value={onboardingManagerId}
            />
          )}
          {role === "user" && (
            <Input
              containerClass="w-full"
              type={STR_TEXT_FIELD}
              value={fees}
              onChange={feesChange}
              placeholder="Select Fees"
              name={STR_FEES}
              required
            />
          )}
          {role !== "admin" && (
            <Input
              containerClass="w-full"
              type="text"
              value={timeValue}
              onChange={walkThroughDateChange}
              placeholder={role === "mentor" ? "Joining Date" : "Walk Through Date"}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          )}
          {role === "mentor" && (
            <Select required className="w-full" onChange={alumniOnChange}>
              <option value="" disabled selected hidden>
                Is_Alumni
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </Select>
          )}
          {role === "mentor" && (
            <Select required className="w-full" onChange={mentorOnChange}>
              <option value="" disabled selected hidden>
                Is_Mentor
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </Select>
          )}
          {role === "mentor" && (
            <Input
              containerClass="w-full"
              type={STR_NUMBER_FILED}
              value={bandwidth}
              onChange={bandwidthChange}
              placeholder={STR_BANDWIDTH}
            />
          )}
          {role === "user" && (
            <Input
              containerClass="w-full"
              type="text"
              value={seat_booking_amount}
              onChange={seatBookingAmountChange}
              placeholder={"Seat Booking Amount"}
            />
          )}

          {role === "user" && (
            <Input
              required
              type="text"
              containerClass="w-full"
              value={timeValue1}
              placeholder={"Seat Booking Amount Received Date"}
              onChange={Seat_booking_received_dateChange}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          )}
          {role === "user" && (
            <Select
              value={payment_submethod}
              className="w-full py-1 px-4 text-[14px]"
              onChange={payment_submethodChange}
            >
              <option value="" disabled>
                Select Seat Booking Payment Sub-method
              </option>
              {PAYMENT_SUBMETHOD_OPTIONS.map((method) => (
                <option key={method} value={method}>
                  {method}
                </option>
              ))}
            </Select>
          )}

          {role === "user" && (
            <Input
              containerClass="w-full"
              type="text"
              value={paymentId_of_seat_booking}
              onChange={paymentId_of_seat_bookingChange}
              placeholder={"Payment ID of Seat Booking"}
            />
          )}
          {role === "user" && (
            <Input
              containerClass="w-full"
              type="text"
              value={standardDiscount}
              onChange={standardDiscountChange}
              placeholder={"Standard Discount"}
            />
          )}
          <Button loading={loading}>{STR_SIGN_UP}</Button>
        </form>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
