import { Auth } from "domain/model/Auth"
import CompanyRepository from "domain/repository/Admin/CompanyRepository"

export interface GetCompanyUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetCompany implements GetCompanyUseCase {
  private repository: CompanyRepository

  constructor(repository: CompanyRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getCompany(auth)
  }
}
