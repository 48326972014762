import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface TogglePlatformProblemStatusUseCase {
  invoke(auth: Auth, problem_id: string, status: boolean): Promise<void>
}

export class TogglePlatformProblemStatus implements TogglePlatformProblemStatusUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, problem_id: string, status: boolean) {
    return this.repository.togglePlatformProblemStatus(auth, problem_id, status)
  }
}
