import { STR_ADD, STR_FAILURE, STR_OPTIONS, STR_QUESTION, STR_SOLUTION, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import DocumentationFilesAPIDataSourceImpl from "data/API/Admin/DocumentationFilesAPIDataSourceImpl"
import { DocumentationFilesRepositoryImpl } from "data/repository/Admin/DocumentationFilesRepositoryImpl"
import { CreateDocumentationFile } from "domain/useCase/Admin/DocumentationFiles/CreateDocumentationFile"
import { DeleteDocumentationFile } from "domain/useCase/Admin/DocumentationFiles/DeleteDocumentationFile"
import { GetDocumentationFile } from "domain/useCase/Admin/DocumentationFiles/GetDocumentationFile"
import { UpdateDocumentationFile } from "domain/useCase/Admin/DocumentationFiles/UpdateDocumentFiles"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export default function DocumentationFileEditViewModel() {
  const [name, setName] = React.useState<string>("")
  const [loading, setLoading] = React.useState<boolean>(false)
  const [feature, setFeature] = React.useState<string>("")
  const [uploadedFileName, setUploadedFileName] = React.useState<string>("")
  const [subFeature, setSubFeature] = React.useState<string>("")
  const [file, setFile] = React.useState<any>()
  const [link, setLink] = React.useState<string>("")
  const [allFieldsValid, setAllFieldsValid] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const createDocumentationUseCase = new CreateDocumentationFile(
    new DocumentationFilesRepositoryImpl(new DocumentationFilesAPIDataSourceImpl())
  )
  const getDocumentationFileUseCase = new GetDocumentationFile(
    new DocumentationFilesRepositoryImpl(new DocumentationFilesAPIDataSourceImpl())
  )
  const updateDocumentationFileUseCase = new UpdateDocumentationFile(
    new DocumentationFilesRepositoryImpl(new DocumentationFilesAPIDataSourceImpl())
  )
  const deleteDocumentationFileUseCase = new DeleteDocumentationFile(
    new DocumentationFilesRepositoryImpl(new DocumentationFilesAPIDataSourceImpl())
  )

  const fetchDocumentationFile = async () => {
    setLoading(true)
    const response = await getDocumentationFileUseCase.invoke(auth, id as string)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in fetching question details"))
      changeToastVisibility(true)
    }

    const fileData = response?.data
    setName(fileData?.name)
    setFeature(fileData?.feature)
    setSubFeature(fileData?.subFeature)
    setLink(fileData?.link)
    setLoading(false)
  }

  // const fetchDocumentationCategory = async ()=>{
  //   const response = await fetchDocumentationCategoryUseCase.invoke(auth);
  //   setFeature(response?.data)
  // }

  const handleAddDocumentationFile = async (file: File, name: string, feature: string, subFeature: string) => {
    setLoading(true)
    const response = await createDocumentationUseCase.invoke(auth, file, name, feature, subFeature)
    setLoading(false)
    setName("")
    setFeature("")
    setSubFeature("")
    setFile("")
    setUploadedFileName("")
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in creating question"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "File created successfully")
    changeToastVisibility(true)
  }

  const checkIfAllFieldsAreFilled = () => {
    if (name && feature && subFeature) {
      if (file || STR_ADD !== id) setAllFieldsValid(true)
    } else {
      setAllFieldsValid(false)
    }
  }

  const handleUpdateDocumentationFile = async (id: string, name: string, feature: string, subFeature: string) => {
    const data = {
      name,
      feature,
      subFeature,
    }
    setLoading(true)
    const response = await updateDocumentationFileUseCase.invoke(auth, id, name, feature, subFeature)
    setLoading(false)
    setName("")
    setFeature("")
    setSubFeature("")
    setFile("")
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in updating file"))
      changeToastVisibility(true)
    }

    changeToastDetails(STR_SUCCESS, "File updated successfully")
    changeToastVisibility(true)
  }

  const deleteDocumentationFile = (id: string) => {
    const response = deleteDocumentationFileUseCase.invoke(auth, id)
    if (!response) {
      changeToastDetails(STR_FAILURE, genError(STR_SUCCESS, "Error in deleting file"))
      changeToastVisibility(true)
    }

    changeToastDetails(STR_SUCCESS, "File deleted successfully")
    changeToastVisibility(true)
    window.location.reload()
  }
  const handleChangeFeature = (e: any) => {
    setFeature(e.target.value)
  }
  const redirectToDocumentationFileList = () => {
    navigate("/admin/documentation_files")
    changeToastDetails(STR_SUCCESS, "Document deleted successfully")
    changeToastVisibility(true)
  }
  const handleChangeSubFeature = (e: any) => {
    setSubFeature(e.target.value)
  }
  const handleChangeName = (e: any) => {
    setName(e.target.value)
  }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      setUploadedFileName(file.name)
      setFile(file)
    }
  }
  return {
    id,
    name,
    feature,
    subFeature,
    file,
    allFieldsValid,
    loading,
    toast,
    uploadedFileName,
    changeToastDetails,
    changeToastVisibility,
    deleteDocumentationFile,
    redirectToDocumentationFileList,
    checkIfAllFieldsAreFilled,
    handleChangeName,
    handleAddDocumentationFile,
    handleChangeFile,
    handleChangeFeature,
    handleChangeSubFeature,
    fetchDocumentationFile,
    handleUpdateDocumentationFile,
  }
}
