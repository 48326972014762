import SendEmailDataSource from "data/dataSource/Admin/SendEmailDataSource"
import { Auth } from "domain/model/Auth"
import SendEmailRepository from "domain/repository/Admin/SendEmailRepository"

export class SendEmailRepositoryImpl implements SendEmailRepository {
  private dataSource: SendEmailDataSource

  constructor(dataSource: SendEmailDataSource) {
    this.dataSource = dataSource
  }

  async sendEmail(auth: Auth, data: FormData): Promise<any> {
    return this.dataSource.sendEmail(auth, data)
  }
  async sendEmailtoprogramme(auth: Auth, data: FormData): Promise<any> {
    return this.dataSource.sendEmailtoprogramme(auth, data)
  }
  async sendEmailtobatch(auth: Auth, data: FormData): Promise<any> {
    return this.dataSource.sendEmailtobatch(auth, data)
  }
  async sendEmailToGroupOfStudents(auth: Auth, data: FormData): Promise<any> {
    return this.dataSource.sendEmailToGroupOfStudents(auth, data)
  }
}
