import { Auth } from "domain/model/Auth"
import { TStudentEMI } from "domain/model/Payment"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface GetPaymentMethodUseCase {
  invoke(auth: Auth, student_payment_emi: TStudentEMI): Promise<any>
}

export class GetPaymentMethod implements GetPaymentMethod {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getPaymentMethod(auth)
  }
}
