export default function CheckBox({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 7.99967C1.33325 11.6817 4.31792 14.6663 7.99992 14.6663C11.6819 14.6663 14.6666 11.6817 14.6666 7.99967C14.6666 4.31767 11.6819 1.33301 7.99992 1.33301C4.31792 1.33301 1.33325 4.31767 1.33325 7.99967ZM12.0486 5.95238L7.33525 10.6664L4.50658 7.83771L5.44925 6.89504L7.33525 8.78104L11.1059 5.00971L12.0486 5.95238Z"
        fill="currentColor"
      />
    </svg>
  )
}
