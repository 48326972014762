import DashboardLayout from "core/layouts/DashboardLayout"
import StoreTable from "./components/StoreTable"
import ButtonGroup from "core/components/ButtonGroup"
import useStoreViewModel from "presentation/Admin/Store/StoreViewModel"
import Button from "core/components/Button"
import Toast from "core/components/Toast"
import React from "react"
import StoreModal from "./components/StoreModal"

export default function StoreView() {
  const {
    toast,
    loading,
    tableData,
    activeTabIndex,
    modalDetails,
    itemDetails,
    handleModalClose,
    handleActiveTab,
    fetchStoreStuffs,
    changeToastVisibility,
    handleModalDetails,
    handleItemDetailsChange,
    handleAddStoreItem,
    handleUpdateStoreItem,
    handleDeleteStoreItem,
  } = useStoreViewModel()

  React.useEffect(() => {
    fetchStoreStuffs()
  }, [])

  return (
    <DashboardLayout>
      <StoreModal
        type={modalDetails.type}
        open={modalDetails.open}
        item={itemDetails}
        loading={loading}
        onClose={handleModalClose}
        onChange={handleItemDetailsChange}
        onSubmit={modalDetails.type === "add" ? handleAddStoreItem : handleUpdateStoreItem}
        onClick={handleDeleteStoreItem}
      />
      <div className="space-y-4 p-6">
        <ButtonGroup buttons={["Store Items", "All Orders"]} active={activeTabIndex} onChange={handleActiveTab} />
        {activeTabIndex === 0 && (
          <div className="flex justify-end">
            <Button className="w-fit" onClick={() => handleModalDetails("add", true)}>
              Add Item
            </Button>
          </div>
        )}
        <StoreTable data={tableData} />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
