import React from "react"

export default function Refer(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3332 10.1634V14.7447C11.3332 14.8037 11.3175 14.8616 11.2879 14.9126C11.2582 14.9635 11.2155 15.0057 11.1642 15.0348C11.1129 15.0638 11.0548 15.0788 10.9958 15.0781C10.9368 15.0774 10.8791 15.061 10.8285 15.0307L7.99984 13.3334L5.17117 15.0307C5.12053 15.061 5.06274 15.0774 5.00371 15.0781C4.94468 15.0788 4.88653 15.0638 4.8352 15.0346C4.78387 15.0054 4.7412 14.9632 4.71156 14.9121C4.68192 14.8611 4.66637 14.8031 4.6665 14.744V10.164C3.80394 9.47342 3.17717 8.53194 2.87281 7.46972C2.56845 6.40749 2.60154 5.27695 2.9675 4.23434C3.33345 3.19173 4.01421 2.28852 4.91569 1.64954C5.81717 1.01056 6.89487 0.667358 7.99984 0.667358C9.10481 0.667358 10.1825 1.01056 11.084 1.64954C11.9855 2.28852 12.6662 3.19173 13.0322 4.23434C13.3981 5.27695 13.4312 6.40749 13.1269 7.46972C12.8225 8.53194 12.1957 9.47342 11.3332 10.164V10.1634ZM5.99984 10.9454V12.9787L7.99984 11.7787L9.99984 12.9787V10.9454C9.3644 11.2024 8.68528 11.3341 7.99984 11.3334C7.3144 11.3341 6.63527 11.2024 5.99984 10.9454ZM7.99984 10C9.0607 10 10.0781 9.5786 10.8283 8.82845C11.5784 8.07831 11.9998 7.06089 11.9998 6.00002C11.9998 4.93916 11.5784 3.92174 10.8283 3.1716C10.0781 2.42145 9.0607 2.00002 7.99984 2.00002C6.93897 2.00002 5.92156 2.42145 5.17141 3.1716C4.42126 3.92174 3.99984 4.93916 3.99984 6.00002C3.99984 7.06089 4.42126 8.07831 5.17141 8.82845C5.92156 9.5786 6.93897 10 7.99984 10Z"
        fill="currentColor"
      />
    </svg>
  )
}
