import { useState } from "react"
import { NavBarIconHolder } from "./NavBarIconHolder"
import { useNavigate } from "react-router-dom"
import { cn } from "core/lib/utils"
import { useAuth } from "core/context/auth"

export default function LogoutButton({ name, Icon, active }: any) {
  const [isHovered, setIsHovered] = useState(false)

  const navigate = useNavigate()

  const { logout } = useAuth()

  const handleLogout = async () => {
    await logout()
    navigate("/login", { replace: true })
  }

  return (
    <button
      className="flex justify-between rounded-tl rounded-bl bg-[#0C0C0C]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleLogout}
    >
      <div className="flex gap-x-[6px] rounded-tl-md rounded-bl-md p-[4px]">
        <NavBarIconHolder
          isHovered={isHovered}
          active={active}
          Icon={Icon}
          className={cn(
            "flex h-6 w-6 items-center rounded-[4px] p-[4px]",
            active
              ? "bg-white"
              : isHovered
                ? "rounded-[4px] border border-gray-500"
                : "rounded-[4px] border border-[#0C0C0C]"
          )}
        />
        <h6
          className={`${active === true ? "font-medium text-[#FFF]" : `${isHovered === true ? "font-medium text-white" : "font-normal text-[#D3D3D3]"}`} font-inter`}
        >
          {name}
        </h6>
      </div>
      {active === true && <div className="h-[32px] w-[6px] rounded-tl-sm rounded-bl-sm bg-[#FFFFFF]"></div>}
    </button>
  )
}
