import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useState } from "react"
import {
  STR_AVAILABLE_PAYMENT_OPTIONS,
  STR_PAYMENT_OPTION1,
  STR_PAYMENT_OPTION2,
  STR_PAY_NOW,
  STR_GENERATE_NOW,
  // STR_RECORDED_CLASSES,
  // STR_RECORDED_LECTURES,
  // STR_UPCOMING_LECTURES,
} from "core/constants/strings"
import ButtonGroup from "core/components/ButtonGroup"
import usePaymentOptionsViewModel from "./PaymentOptionsViewModel"
import { isEmpty } from "core/utils/misc"
import Button from "core/components/Button"
import { WindowSharp } from "@mui/icons-material"
import EMIOptions from "./components/EMIOptions"
import PaymentPopUpBreakdown from "./components/PaymentPopUpBreakdown"
import AdminTable2 from "core/components/new/AdminTable2"
import Toast from "core/components/Toast"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { useApp } from "core/context/app"

export default function PaymentOptionsView() {
  const {
    activePaymentButtonType,
    openPopUp,
    paymentMethod,
    refreshed,
    payData,
    payDataAll,
    paymentDocuments,
    studentEMI,
    paymentOptionDiscount,
    paymentLink,
    isCompletelyPaid,
    showPayNow,
    disableInitiateNow,
    activeInstallment,
    student,
    setStudentEMI,
    getStudentEMI,
    createStudentEMI,
    uploadPaymentDocument,
    deletePaymentDocument,
    getPaymentDocuments,
    setPaymentDocuments,
    handleGeneratePaylink,
    CreatePaymentOneShot,
    setStudentDataForOneShot,
    changeActivePaymentButtonType,
    handleEMIOption,
    fetchPaymentOptionDetails,
    handleDirectPayment,
    handleEMIPayment,
    getStudentPaymentDetails,
    getStudentPaymentDetailsAll,
    handlePopUp,
    formatDueDateWithOutGMT,
    fetchPaymentMethod,
    setStudentTransactionTableData,
    emiAmount,
    calculateOneshotDiscount,
    studentTransactionTableData,
    setEmiInterest,
    emiInterest,
    toast,
    changeToastDetails,
    changeToastVisibility,
  } = usePaymentOptionsViewModel()
  const interestRate =
    student?.batch[0] === "B"
      ? [0, 1.9, 8.9, 11.9]
      : student?.batch[0] === "D"
        ? [0, 0, 8.9, 11.9, 14.9, 16.9]
        : [0, 1.9, 8.9, 11.9, 14.9, 16.9]
  React.useEffect(() => {
    if (activePaymentButtonType === 0) {
      handleDirectPayment()
    } else {
      handleEMIPayment()
    }
  }, [activePaymentButtonType])

  React.useEffect(() => {
    getStudentEMI()
    fetchPaymentOptionDetails()
    getStudentPaymentDetails()
    fetchPaymentMethod()
    calculateAmountPaid()
  }, [])
  React.useEffect(() => {
    fetchPaymentOptionDetails()
  }, [studentEMI])
  // Set initial state based on payDataAll
  const today = new Date().toISOString().split("T")[0]
  let currentStartDate = new Date(today)
  const year = currentStartDate.getFullYear()
  const month = currentStartDate.getMonth() + 1

  let temp_due_date = `${year}-${String(month).padStart(2, "0")}-${String(currentStartDate.getDate()).padStart(2, "0")}`

  React.useEffect(() => {
    const { student_email, fees, standard_discount, seat_booking_amount } = payDataAll
    setStudentDataForOneShot({
      student_email,
      instalments: [
        {
          amount: calculateAmount()?.toString(),
          due_time: temp_due_date,
          status: "created",
        },
      ],
      course_fees: fees?.toString(),
      standard_discount,
      seat_booking_amount,
    })
  }, [payDataAll, payData])

  const [expired, setExpired] = useState(true)
  const [offerDate, setOfferDate] = useState("")

  const formatDate = (date: Date) => {
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "short" })
    const year = date.getFullYear()

    // Get the correct suffix for the day
    const daySuffix = (day: number) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }

    return `${day}${daySuffix(day)} ${month} ${year}`
  }

  React.useEffect(() => {
    if (!student?.is_payment_done && student?.walk_through_date) {
      // Declare intervalId here so it can be accessed within the checkExpiration function
      let intervalId: any

      const checkExpiration = () => {
        const walk_through_date = student?.walk_through_date

        const walk_through_date_in_millis = walk_through_date * 1000

        const current_date = new Date()

        const future_date = walk_through_date_in_millis + 66.5 * 60 * 60 * 1000

        setOfferDate(formatDate(new Date(future_date - 1)))

        if (current_date.getTime() > future_date) {
          setExpired(true)
          clearInterval(intervalId)
        } else {
          if (expired) setExpired(false)
        }
      }

      checkExpiration()

      intervalId = setInterval(checkExpiration, 60000)

      return () => clearInterval(intervalId)
    }
  }, [student?.email])

  const calculateOfferDiscount = () => {
    if (!expired) return payDataAll?.offer_discount
    return 0
  }

  const calculateAmount = (): any | any => {
    if (payData.length <= 2) {
      return paymentOptionDiscount?.fees_after_discount - calculateOneshotDiscount()
    } else {
      const createdInstallments = payData.filter(
        (installment: { status: string; due_date: string }) => installment.status === "created"
      )

      if (createdInstallments.length > 0) {
        return createdInstallments[0].amount
      } else {
        return 0
      }
    }
  }
  const calculateAmountPaid = (): any | any => {
    let amount_paid = 0
    payData?.map((item: any) => {
      if (item.status === "paid") {
        amount_paid += item.amount
      }
    })
    return amount_paid
  }

  const handleClickPay = (e: any) => {
    e.preventDefault()
    const url = paymentLink

    if (url) {
      window.open(url, "_blank")
    } else {
      console.error("Invalid URL")
    }
  }

  React.useEffect(() => {
    getStudentPaymentDetailsAll()
  }, [emiInterest, emiAmount])
  React.useEffect(() => {
    paymentOptionDiscount.fees_after_discount &&
      studentEMI?.duration_index !== -1 &&
      setEmiInterest(
        Math.ceil((paymentOptionDiscount.fees_after_discount * interestRate[studentEMI.duration_index]) / 100)
      )
  })
  const renderPaymentOptions = () => {
    if (activePaymentButtonType === 0) {
      // Direct Payment
      return (
        <div className="flex flex-col">
          <div className="mt-[24px] flex h-[60vh] flex-col justify-between p-4 shadow-[0_0_10px_0_rgba(141,141,141,0.2)]">
            {payData.length <= 2 ? (
              <div className="grid h-full grid-cols-2 gap-8">
                <div className="flex h-[54px] w-full flex-col rounded border-[0.5px] border-[#312E81] px-4 py-3 text-[14px]">
                  <h3 className="items-center text-sm text-new-accent">Direct to Bosscoder</h3>
                  <p>{`Pay directly to us without any Hussle`} </p>
                </div>
                <div className="relative flex h-full w-full flex-col">
                  <h3 className="text-sm text-new-accent">Direct to Bosscoder (One Shot Payment):</h3>

                  <div>
                    <div className="pl-5">
                      <>
                        <li> Pay directly to Bosscoder in one go.</li>
                        <li>{`Discount of Rs. ${calculateOneshotDiscount()} will be provided on this payment method.`}</li>
                      </>
                    </div>
                    <div className="mt-4 flex justify-between">
                      <div className='font-montserrat text-#414141 leading-6" text-sm font-normal'>
                        View payment breakdown
                      </div>
                      <button
                        onClick={() => handlePopUp()}
                        className="font-montserrat text-sm font-medium text-[#312E81] underline"
                      >
                        Click here
                      </button>
                    </div>
                  </div>

                  {!isCompletelyPaid && (
                    <div className="absolute bottom-0 flex w-full justify-between">
                      <div className="w-all flex w-full justify-between">
                        {/* initiate now */}
                        {disableInitiateNow ? (
                          <>
                            {payData.length <= 2 &&
                              !showPayNow &&
                              student?.payment_method !== "ccavenue" &&
                              disableInitiateNow && (
                                <Button className="w-fit border-[#312E81] text-[#312E81]" disabled>
                                  {STR_GENERATE_NOW}
                                </Button>
                              )}
                          </>
                        ) : (
                          <>
                            {payData.length <= 2 &&
                              !showPayNow &&
                              student?.payment_method !== "ccavenue" &&
                              !disableInitiateNow && (
                                <Button
                                  className="w-fit border-[#312E81] text-[#312E81]"
                                  onClick={handleGeneratePaylink}
                                >
                                  {STR_GENERATE_NOW}
                                </Button>
                              )}
                          </>
                        )}
                        {/* Total amount */}
                        <div className="flex flex-col justify-evenly">
                          {payData?.length > 2 && (
                            <div className="flex flex-col">
                              <div className="mb-1 flex h-[12px] w-[292px] text-[10px]">
                                <span className="text-[#414141]">{`Installment ${activeInstallment?.number} `}</span>
                                <span className="text-[#DC2626]">{`( Pay by- ${formatDueDateWithOutGMT(
                                  activeInstallment?.due_time
                                )} )`}</span>
                              </div>
                              <h3>{`Rs ${calculateAmount()}`}</h3>
                            </div>
                          )}
                          {payData.length <= 2 && (
                            <div className="mb-1 flex flex-col">
                              <span className="mb-1 text-[10px] text-[#414141]">Total Amount</span>
                              <h3>{`Rs ${calculateAmount()}`}</h3>
                            </div>
                          )}
                        </div>

                        {(showPayNow || payData.length >= 3 || student?.payment_method === "ccavenue") && (
                          <Button
                            className="w-fit border-[#312E81] text-[14px] text-[#312E81]"
                            onClick={handleClickPay}
                          >
                            {STR_PAY_NOW}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={`grid h-full grid-cols-2 gap-8`}>
                <div className="flex w-full flex-col">
                  <div className="h-[78px] w-full rounded border-[0.5px] border-[#312E81] px-4 py-3 text-[14px] opacity-20">
                    <h3 className="text-[14px] text-new-accent">Direct to Bosscoder</h3>
                    <p>
                      {`Direct payment: ${calculateOneshotDiscount()} Rs. Off Pay directly to us without any Hussle`}{" "}
                    </p>
                  </div>
                  <div className="mt-5 h-[78px] w-full rounded border-[0.5px] border-[#312E81] px-4 py-3 text-sm">
                    <h3 className="text-sm text-new-accent">Bosscoder Direct</h3>
                    <p>{`Pay directly to Bosscoder in ${payData.length - 1} monthly instalments `}</p>
                  </div>
                </div>

                <div className="relative flex h-full w-full flex-col">
                  <h3 className="text-sm text-new-accent">Direct to Bosscoder (Monthly instalments)</h3>
                  <div>
                    <div className="pl-5">
                      <>
                        <li>Pay directly to Bosscoder in monthly instalment</li>
                        <li>{`One shot discount Rs. ${calculateOneshotDiscount()}  will not be applicable in this case.`}</li>
                      </>
                    </div>
                    <div className="mt-4 grid w-full grid-cols-2">
                      <div className='font-montserrat text-#414141 leading-6" text-sm font-normal'>
                        view payment breakdown
                      </div>
                      <button
                        onClick={() => handlePopUp()}
                        className="font-montserrat ml-auto pr-[12px] text-sm font-medium text-[#312E81] underline"
                      >
                        Click here
                      </button>
                    </div>
                  </div>
                  {!isCompletelyPaid && (
                    <div className="absolute bottom-0 w-full justify-between">
                      <div className="flex justify-between">
                        {disableInitiateNow ? (
                          <>
                            {payData.length <= 1 && !showPayNow && disableInitiateNow && (
                              <Button className="w-fit border-[#312E81] text-[#312E81]" disabled>
                                {STR_GENERATE_NOW}
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {payData.length <= 1 && !showPayNow && !disableInitiateNow && (
                              <Button className="w-fit border-[#312E81] text-[#312E81]" onClick={handleGeneratePaylink}>
                                {STR_GENERATE_NOW}
                              </Button>
                            )}
                          </>
                        )}

                        <div className="flex flex-col justify-evenly">
                          {payData?.length > 2 && (
                            <div className="flex flex-col">
                              <div className="mb-1 flex h-[12px] w-[292px] text-[10px]">
                                <span className="text-[#414141]">{`Installment ${
                                  activeInstallment?.number - 1
                                } `}</span>
                                <span className="text-[#DC2626]">{`( Pay by- ${formatDueDateWithOutGMT(
                                  activeInstallment?.due_time
                                )} )`}</span>
                              </div>
                              <h3>{`Rs ${calculateAmount()}`}</h3>
                            </div>
                          )}
                          {payData.length <= 2 && (
                            <div className="mb-1 flex flex-col">
                              <span className="mb-1 text-[10px] text-[#414141]">Total Amount</span>
                              <h3>{`Rs ${calculateAmount()}`}</h3>
                            </div>
                          )}
                        </div>

                        {(showPayNow || payData.length >= 2) && (
                          <Button
                            className="w-fit border-[#312E81] py-[6px] px-[10px] text-[14px] text-[#312E81]"
                            onClick={handleClickPay}
                          >
                            {STR_PAY_NOW}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {isCompletelyPaid && (
              <div className="flex w-full justify-between">
                <div className="flex flex-col justify-evenly">
                  <p>Total Amount Paid</p>
                  <h3>{`Rs ${emiAmount ? emiAmount + parseInt(payDataAll?.seat_booking_amount) : calculateAmountPaid()}`}</h3>
                </div>
                <div className="text-sm font-semibold text-[#00C22B] ">
                  {`You have successfully paid payment of Rs ${emiAmount ? emiAmount + parseInt(payDataAll?.seat_booking_amount) : calculateAmountPaid()}`}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
    if (activePaymentButtonType === 1)
      // EMI Payment
      return (
        <EMIOptions
          amount={paymentOptionDiscount?.fees_after_discount}
          paymentDocuments={paymentDocuments}
          studentEMI={studentEMI}
          setStudentEMI={setStudentEMI}
          setPaymentDocuments={setPaymentDocuments}
          getPaymentDocuments={getPaymentDocuments}
          uploadPaymentDocument={uploadPaymentDocument}
          deletePaymentDocument={deletePaymentDocument}
          getStudentEMI={getStudentEMI}
          createStudentEMI={createStudentEMI}
          changeToastDetails={changeToastDetails}
          changeToastVisibility={changeToastVisibility}
        />
      )
  }

  return (
    <DashboardLayoutv2>
      {openPopUp && (
        <PaymentPopUpBreakdown
          handlePopUp={handlePopUp}
          payData={payData}
          payDataAll={payDataAll}
          paymentOptionDiscount={paymentOptionDiscount}
          isCompletelyPaid={isCompletelyPaid}
          expired={expired}
          offerDate={offerDate}
        />
      )}
      <div className="p-6">
        <div className="">
          <h2 className="text-new-accent">{STR_AVAILABLE_PAYMENT_OPTIONS}</h2>
          <p>Please select your payment option.</p>

          <div className="mt-6 space-y-4">
            <ButtonGroup
              buttons={[STR_PAYMENT_OPTION1, STR_PAYMENT_OPTION2]}
              active={activePaymentButtonType}
              onChange={changeActivePaymentButtonType}
            />
          </div>
          {renderPaymentOptions()}
        </div>
      </div>
      <div className="mt-4 p-5">
        <h2 className="text-[24px] text-new-accent">Your Transactions</h2>
        <p>Track all your transactions here.</p>
        <div className="flex w-full items-center justify-center p-1 pt-4">
          <AdminTable2
            data={studentTransactionTableData}
            outlined={true} // Set to true if you want an outlined table
            headerWidths={["[25%]", "[150px]", "[200px]", "[200px]", "[200px]", "[200px]", "full"]}
            rowWidths={["[50px]", "[150px]", "[200px]", "[200px]", "[200px]", "[200px]", "full"]}
            paddingInfo={["24px", "16px", "24px", "16px"]}
          />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
