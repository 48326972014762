import { Fragment } from "react"
import { isEmpty } from "core/utils/misc"
import columnTitle from "core/utils/columnTitle"
import Button from "core/components/new/Button"
import Accordion from "./Accordion"
import Fields from "./Fields"

export default function Options({
  isOptionFieldAdded,
  activeResponseTab,
  options,
  removeOption,
  onOptionFieldChange,
  removeOptionField,
}: any) {
  return (
    <Fragment>
      {!isEmpty(options) &&
        Object.keys(options)?.map((key: any) => (
          <div
            key={parseInt(key, 10)}
            className="w-full flex py-4 px-6 flex-col items-end content-center gap-6 border border-solid border-new-neutral rounded-sm"
          >
            <Accordion text={"Option " + columnTitle(parseInt(key, 10) + 1)} deps={isOptionFieldAdded}>
              <div className="flex flex-col gap-6">
                <Fields
                  fields={options[key]}
                  fieldTypes={["text", "image", "code"]}
                  onFieldChange={onOptionFieldChange}
                  onDeleteField={removeOptionField}
                  isResponseField={true}
                  isOptionField={true}
                  responseId={activeResponseTab}
                  optionId={parseInt(key, 10)}
                />
              </div>
            </Accordion>
            <div className="w-1/3">
              <Button outlined failure className="float-right" onClick={() => removeOption(activeResponseTab, key)}>
                Delete Option
              </Button>
            </div>
          </div>
        ))}
    </Fragment>
  )
}
