import { SSMCallHistoryDataSource } from "data/dataSource/Admin/SSMCallHistoryDataSource"
import SSMCallHistoryRepository from "domain/repository/Admin/SSMCallHistoryRepository"
import { SSMCallHistory } from "domain/model/SSMCallHistory"
import { Auth } from "domain/model/Auth"

export class SSMCallHistoryRepositoryImpl implements SSMCallHistoryRepository {
  private dataSource: SSMCallHistoryDataSource
  constructor(dataSource: SSMCallHistoryDataSource) {
    this.dataSource = dataSource
  }
  async SSMCallHistory(data: SSMCallHistory): Promise<any> {
    return await this.dataSource.getSSMCallHistory(data)
  }
}
