import { Auth } from "domain/model/Auth"
import { PaymentRepository } from "domain/repository/Student/PaymentRepository"

export interface UploadPaymentDocumentUseCase {
  invoke(auth: Auth, file: File, filename: string, documentId: number): Promise<any>
}

export class UploadPaymentDocument implements UploadPaymentDocumentUseCase {
  private repository: PaymentRepository

  constructor(repository: PaymentRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, file: File, filename: string, documentId: number): Promise<any> {
    return await this.repository.uploadPaymentDocument(auth, file, filename, documentId)
  }
}
