import React from "react"

export default function Feedback3(props: any) {
  const { selectedColor, onHover, onLeave, onClick, index, isSelected } = props

  return (
    <svg
      className={props.className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onLeave()}
      onClick={() => onClick(index)}
      fill={isSelected ? selectedColor : "#ADADAD"}
    >
      <rect width="64" height="64" rx="8" fill={isSelected ? selectedColor : "#D3D3D3"} />
      <path
        d="M27.5834 25.1789C28.2221 25.1789 28.7504 24.6571 28.6372 24.0285C28.5625 23.6134 28.4474 23.2065 28.2931 22.8143C27.9981 22.0647 27.5657 21.3835 27.0206 20.8098C26.4755 20.236 25.8284 19.7809 25.1163 19.4703C24.4041 19.1598 23.6408 19 22.8699 19C22.0991 19 21.3358 19.1598 20.6236 19.4703C19.9114 19.7809 19.2643 20.236 18.7193 20.8098C18.1742 21.3835 17.7418 22.0647 17.4468 22.8143C17.2925 23.2065 17.1774 23.6134 17.1026 24.0285C16.9895 24.6571 17.5178 25.1789 18.1565 25.1789C18.7952 25.1789 19.2962 24.6502 19.4818 24.039C19.5118 23.9401 19.5458 23.8423 19.5837 23.746C19.7624 23.2918 20.0245 22.879 20.3548 22.5313C20.6851 22.1836 21.0772 21.9078 21.5087 21.7197C21.9403 21.5315 22.4028 21.4347 22.8699 21.4347C23.3371 21.4347 23.7996 21.5315 24.2311 21.7197C24.6627 21.9078 25.0548 22.1836 25.3851 22.5313C25.7154 22.879 25.9774 23.2918 26.1562 23.746C26.1941 23.8423 26.2281 23.9401 26.2581 24.039C26.4437 24.6502 26.9447 25.1789 27.5834 25.1789Z"
        fill="white"
      />
      <path
        d="M46.7377 25.1789C47.3764 25.1789 47.9047 24.6571 47.7915 24.0285C47.7168 23.6134 47.6017 23.2065 47.4474 22.8143C47.1524 22.0647 46.72 21.3835 46.1749 20.8098C45.6298 20.236 44.9827 19.7809 44.2706 19.4703C43.5584 19.1598 42.7951 19 42.0242 19C41.2534 19 40.4901 19.1598 39.7779 19.4703C39.0657 19.7809 38.4186 20.236 37.8736 20.8098C37.3285 21.3835 36.8961 22.0647 36.6011 22.8143C36.4468 23.2065 36.3316 23.6134 36.2569 24.0285C36.1438 24.6571 36.6721 25.1789 37.3108 25.1789C37.9495 25.1789 38.4505 24.6502 38.6361 24.039C38.6661 23.9401 38.7001 23.8423 38.738 23.746C38.9167 23.2918 39.1788 22.879 39.5091 22.5313C39.8393 22.1836 40.2315 21.9078 40.663 21.7197C41.0946 21.5315 41.5571 21.4347 42.0242 21.4347C42.4913 21.4347 42.9539 21.5315 43.3854 21.7197C43.817 21.9078 44.2091 22.1836 44.5394 22.5313C44.8697 22.879 45.1317 23.2918 45.3105 23.746C45.3484 23.8423 45.3824 23.9401 45.4124 24.039C45.598 24.6502 46.099 25.1789 46.7377 25.1789Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8873 39.2959C19.1999 38.6119 20.0079 38.3109 20.6918 38.6236L24.8188 40.5102C29.3754 42.5932 34.6268 42.5178 39.1217 40.305L42.503 38.6404C43.1777 38.3082 43.9939 38.5859 44.3261 39.2606C44.6583 39.9353 44.3806 40.7515 43.7059 41.0837L40.3246 42.7483C35.0959 45.3225 28.987 45.4101 23.6865 42.9871L19.5596 41.1005C18.8756 40.7878 18.5746 39.9799 18.8873 39.2959Z"
        fill="white"
      />
    </svg>
  )
}
