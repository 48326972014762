import { cn } from "core/lib/utils"

export default function Silver({ className }: any) {
  return (
    <svg
      // className={cn(className ?? "h-full w-32")}
      viewBox="0 0 128 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_814_24063)">
        <path
          d="M65.3966 144.002L4.1126 109.76L2.73999 38.8668L62.6258 2.18817L123.884 36.4026L125.256 107.322L65.3966 144.002Z"
          fill="#8697A7"
        />
        <path
          d="M2.297 110.812L48.8956 136.856C49.761 135.945 50.6527 135.034 51.4938 134.043L5.93293 108.585L5.30883 76.368C4.05507 77.0292 2.85322 77.7184 1.67631 78.4616L2.297 110.812Z"
          fill="#3C4144"
        />
        <path
          d="M62.5836 -0.0545673L0.87945 37.7508L1.68138 78.456C2.85829 77.7128 4.08711 76.9977 5.31389 76.3625L4.61607 39.8218L62.658 4.24257L69.6376 8.13166C70.3743 7.11029 71.1102 6.11558 71.8725 5.12155L62.5836 -0.0545673Z"
          fill="#758481"
        />
        <path
          d="M127.124 108.277L125.701 35.2207L123.599 34.0457C122.603 34.927 121.529 35.7529 120.482 36.5795L122.065 37.4475L123.387 106.206L65.3178 141.759L51.4882 134.037C50.647 135.029 49.7552 135.94 48.8898 136.851L65.4178 146.083L127.124 108.277Z"
          fill="#758481"
        />
        <path
          d="M120.486 36.5466C121.533 35.7201 122.607 34.8942 123.603 34.0131L71.8695 5.11641C71.1073 6.11036 70.3714 7.10499 69.6348 8.12628L120.486 36.5466Z"
          fill="#E9FEFF"
        />
        <path
          d="M35.6246 60.8789L5.4957 85.4956L5.98256 108.664L65.4017 141.683L97.4964 100.898L65.2122 118.202L34.3806 99.2625L35.6246 60.8789Z"
          fill="#545D62"
        />
        <path
          d="M97.5053 100.899L65.2188 118.191L34.3763 99.2485L35.5912 61.6748L66.1098 83.0088L98.1873 63.5777L97.5053 100.899Z"
          fill="#4E7072"
        />
        <path d="M97.5049 100.895L65.2098 118.19L66.1011 83.002L98.1871 63.5674L97.5049 100.895Z" fill="#BBDEE2" />
        <path d="M98.1874 63.5767L65.2187 118.19L35.5911 61.6738L66.1098 83.0079L98.1874 63.5767Z" fill="#E5FDFF" />
        <path d="M66.1188 83.0118L65.2275 118.2L35.5916 61.6738L66.1188 83.0118Z" fill="#668A95" />
        <path
          d="M35.6051 61.6765L35.6255 60.877L66.1474 81.6702L98.2143 62.5023L98.1871 63.5683L66.1134 83.0027L35.6051 61.6765Z"
          fill="#DCFCFF"
        />
        <path d="M66.602 64.0803L66.3108 75.5422L35.2017 56.8603L35.5063 44.8652L66.602 64.0803Z" fill="#E09E7E" />
        <path d="M66.5948 64.0721L98.6248 46.5022L98.3201 58.4973L66.3037 75.5341L66.5948 64.0721Z" fill="#C1DEE4" />
        <path d="M66.602 64.0803L66.3108 75.5422L35.2017 56.8603L35.5063 44.8652L66.602 64.0803Z" fill="#536E75" />
        <path d="M98.6247 46.5052L66.3061 75.537L35.5085 44.8652L66.5981 64.076L98.6247 46.5052Z" fill="#DBF9FB" />
        <path d="M66.6008 64.0793L66.3087 75.5422L35.5088 44.8652L66.6008 64.0793Z" fill="#668A95" />
        <path
          d="M35.5107 44.8648L35.5378 43.7988L66.6263 63.0059L98.6522 45.4377L98.6251 46.5036L66.5991 64.0718L35.5107 44.8648Z"
          fill="#DCFCFF"
        />
        <path
          d="M98.3183 58.4982L94.7429 64.5409L66.1483 81.6727L35.6284 60.8756L5.52821 85.4375L5.15487 69.1558L35.5385 43.801L35.2047 56.8633L66.305 75.5414L98.3183 58.4982Z"
          fill="#545D62"
        />
        <path d="M63.8248 61.3875L75.8143 27.2995L55.1647 28.2389L38.8452 45.7525L63.8248 61.3875Z" fill="#545D62" />
        <path d="M75.8165 27.2998L55.1648 28.2422L66.1006 41.3169L75.8165 27.2998Z" fill="#BCDDE4" />
        <path d="M55.9048 27.0087L55.1644 28.2382L75.8141 27.297L74.9661 26.14L55.9048 27.0087Z" fill="#E8FDFF" />
        <path d="M65.8136 34.9416L55.1648 28.2383L66.1016 41.3175L75.8144 27.2989L65.8136 34.9416Z" fill="#668A95" />
      </g>
      <defs>
        <clipPath id="clip0_814_24063">
          <rect width="128" height="146" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
