import GetAllClassesDataSource from "data/dataSource/Admin/GetAllClassesDataSource"
import { DuplicateClass } from "domain/model/AllClasses"
import { Auth } from "domain/model/Auth"
import { FeedbackResponse } from "domain/model/FeedbackResponse"
import DeleteFeedbackFile from "domain/useCase/Admin/AllClasses/DeleteFeedbackFile"

export class GetAllClassesRepositoryImpl implements GetAllClassesRepositoryImpl {
  private dataSource: GetAllClassesDataSource

  constructor(dataSource: GetAllClassesDataSource) {
    this.dataSource = dataSource
  }

  async getAllClasses(auth: Auth, batch: string, month: string, instructor: string): Promise<any> {
    return this.dataSource.getAllClasses(auth, batch, month, instructor)
  }

  async getClassAllRating(auth: Auth, class_id: string): Promise<any> {
    return this.dataSource.getClassAllRating(auth, class_id)
  }

  async getClassAverageRating(auth: Auth, class_id: string): Promise<any> {
    return this.dataSource.getClassAverageRating(auth, class_id)
  }

  async updateAllClassDetails(
    auth: Auth,
    session_id: string,
    status: string,
    actual_duration: number,
    considered_duration: number
  ): Promise<any> {
    return this.dataSource.updateAllClassDetails(auth, session_id, status, actual_duration, considered_duration)
  }

  async getUpcomingClasses(auth: Auth, batch: string, month: string, instructor: string): Promise<any> {
    return this.dataSource.getUpcomingClasses(auth, batch, month, instructor)
  }

  async getDuplicateClass(duplicateClass: DuplicateClass): Promise<any> {
    return this.dataSource.getDuplicateClass(duplicateClass)
  }

  async getStudentListofBatch(auth: Auth, batch: string, page: number, pageLimit: number): Promise<any> {
    return this.dataSource.getStudentListofBatch(auth, batch, page, pageLimit)
  }

  async getBatchAssignment(auth: Auth, session_id: string): Promise<any> {
    return this.dataSource.getBatchAssignment(auth, session_id)
  }
  async getSubmission(auth: Auth, session_id: string, student_email: string): Promise<any> {
    return this.dataSource.getSubmission(auth, session_id, student_email)
  }

  async createFeedbackResponse(
    auth: Auth,
    session_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any> {
    return this.dataSource.createFeedbackResponse(auth, session_id, student_email, formData, feedbackResponse)
  }
  async getFeedbackResponse(auth: Auth, session_id: string, student_email: string): Promise<any> {
    return this.dataSource.getFeedbackResponse(auth, session_id, student_email)
  }

  async getSSMAndSubmissionButtonStatus(auth: Auth, session_id: string, student_email: string): Promise<any> {
    return this.dataSource.getSSMAndSubmissionButtonStatus(auth, session_id, student_email)
  }

  async getFeedbackResponseSubmission(auth: Auth, session_id: string, student_email: string): Promise<any> {
    return this.dataSource.getFeedbackResponseSubmission(auth, session_id, student_email)
  }

  async deleteFeedbackFile(
    auth: Auth,
    session_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any> {
    return this.dataSource.deleteFeedbackFile(auth, session_id, student_email, fileurl, questionName, type)
  }

  async getClassReminderData(auth: Auth, id: any): Promise<any> {
    return this.dataSource.getClassReminderData(auth, id)
  }
}
