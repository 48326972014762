import { CheckboxEmptyIcon } from "core/constants/svgs"
import columnTitle from "core/utils/columnTitle"
import { isEmpty } from "core/utils/misc"
import { Fragment } from "react"

export default function QuestionView({
  questionDetails,
  questionFields,
  questionResponse,
  activeResponseTab,
  questionResponseFields,
  questionResponseOptions,
}: any) {
  function isValidHttpUrl(urlstr: string) {
    let url: any
    try {
      url = new URL(urlstr)
    } catch (_) {
      return false
    }
    return url.protocol === "http:" || url.protocol === "https:"
  }

  function openUrl(url: any) {
    if (url) {
      window.open(url, "_blank")
    } else {
      console.error("URL is not provided:", url)
    }
  }

  function openFile(file: any) {
    if (file instanceof Blob) {
      const documentUrl = URL.createObjectURL(file)
      window.open(documentUrl, "_blank")
    } else {
      console.error("Unsupported file type:", file)
    }
  }

  return (
    <div className="w-full flex p-6 gap-6 flex-col content-center items-center text-white">
      <div className="w-full flex px-3 py-6 flex-col justify-center items-start gap-2 bg-[#303030] border border-solid border-white rounded-sm">
        <div className="w-full flex flex-row items-start">
          <div className="basis-8">
            <CheckboxEmptyIcon className="w-5 h-5" />
          </div>
          <div className="w-full">
            <h3>{questionDetails?.title || "N/A"}</h3>
          </div>
        </div>
        <div className="w-full">
          <div className="basis-1/4">
            <p>Max Marks :- {questionDetails?.coins && questionDetails?.coins / 5}</p>
          </div>
          <div className="basis-1/4">
            <p>
              Difficulty Level :-{" "}
              {questionDetails?.difficultyLevel &&
                questionDetails?.difficultyLevel.charAt(0).toUpperCase() + questionDetails?.difficultyLevel.slice(1)}
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 py-6 w-full flex flex-col gap-4 bg-[#303030] border border-solid border-new-editor-dark-300 rounded-sm">
        <p>Problem Statement :-</p>
        {questionFields
          .sort((a: any, b: any) => (a.order !== undefined && b.order !== undefined ? a.order - b.order : 0))
          .map((questionField: any, index: any) =>
            questionField.type === "text" ? (
              <div
                key={index}
                className="text-pretty break-words whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: questionField.content }}
              />
            ) : questionField.type === "code" ? (
              <pre
                key={index}
                className="text-pretty bg-[#434343] border-2 border-solid border-black py-4 px-6 rounded-sm"
              >
                {questionField.content}
              </pre>
            ) : questionField.type === "document" ? (
              <div key={index} className="w-fit">
                {isValidHttpUrl(questionField?.content) ? (
                  <div
                    key={index}
                    className="text-pretty bg-[#434343] py-4 px-6 rounded-sm cursor-pointer text-blue-500"
                    onClick={(e) => openUrl(questionField?.content)}
                  >
                    {questionField?.filename}
                  </div>
                ) : (
                  <div
                    key={index}
                    className="text-pretty bg-[#434343] py-4 px-6 rounded-sm cursor-pointer"
                    onClick={(e) => openFile(questionField?.file)}
                  >
                    {questionField?.filename}
                  </div>
                )}
              </div>
            ) : (
              <div key={index} className="w-fit">
                <img src={questionField?.content} alt={questionField?.filename} />
              </div>
            )
          )}
      </div>
      {/* Question Response */}
      {questionResponse.length !== 0 && (
        <Fragment>
          <div className="w-full text-left">
            <h3>Responses</h3>
          </div>
          <div className="w-full flex flex-col items-start">
            <button
              className="flex-none w-40 py-2 px-4 border border-b-0 border-solid border-new-editor-dark-300 rounded-sm bg-[#303030]"
              disabled
            >
              Question #{activeResponseTab + 1}
            </button>
            <div className="w-full h-[1px] mt-[-1px] mr[1px] bg-new-editor-dark-300 rounded-sm"></div>
          </div>
        </Fragment>
      )}
      {questionResponse?.[activeResponseTab] && (
        <div className="px-3 py-6 w-full flex flex-col gap-4 bg-[#303030] border border-solid border-new-editor-dark-300 rounded-sm">
          <div className="text-pretty">{questionResponse?.[activeResponseTab]?.type}</div>
          <div className="text-pretty">
            <h3>{questionResponse?.[activeResponseTab]?.subTitle}</h3>
          </div>
          <pre className="text-pretty whitespace-pre-wrap">{questionResponse?.[activeResponseTab]?.content}</pre>
          {questionResponseFields?.[activeResponseTab]?.map((field: any, fieldId: number) => (
            <div key={fieldId} className="text-pretty">
              {field?.type === "text" ? (
                <div className="text-pretty">{field?.content}</div>
              ) : field?.type === "code" ? (
                <pre className="text-pretty bg-[#434343] border-2 border-solid border-black py-4 px-6 rounded-sm">
                  {field?.content}
                </pre>
              ) : field?.type === "image" ? (
                field?.content && (
                  <div className="w-fit">
                    <img src={field?.content?.split("@")[1] ?? field?.content} alt={field?.type} />
                  </div>
                )
              ) : field?.type === "document" && field?.content ? (
                <div className="text-pretty text-blue-500">{field?.content}</div>
              ) : null}
            </div>
          ))}
          {/* Question response options */}
          {!isEmpty(questionResponseOptions?.[activeResponseTab]) &&
            Object.keys(questionResponseOptions[activeResponseTab])?.map((key: any) => (
              <div key={key} className="w-full flex items-start justify-start gap-4">
                <h3>{columnTitle(parseInt(key, 10) + 1) + ")"}</h3>
                <div className="w-full flex flex-col items-start justify-start gap-4">
                  {questionResponseOptions[activeResponseTab][key]?.map((field: any, fieldId: number) => {
                    if (field?.type === "text") {
                      return (
                        <div key={fieldId} className="text-pretty">
                          {field?.content}
                        </div>
                      )
                    } else if (field?.type === "code") {
                      return (
                        <pre
                          key={fieldId}
                          className="text-pretty bg-[#434343] border-2 border-solid border-black py-4 px-6 rounded-sm"
                        >
                          {field?.content}
                        </pre>
                      )
                    } else if (field?.type === "image") {
                      return (
                        field?.content && (
                          <div key={fieldId} className="w-fit">
                            <img src={field?.content?.split("@")[1] ?? field?.content} alt={field?.type} />
                          </div>
                        )
                      )
                    } else if (field?.type === "document" && field?.content) {
                      return (
                        <div key={fieldId} className="text-pretty cursor-pointer text-blue-500">
                          {field?.filename}
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
                <CheckboxEmptyIcon className="w-5 h-5" />
              </div>
            ))}
        </div>
      )}
    </div>
  )
}
