import { Batch } from "domain/model/Batch"
import { BatchRepository } from "domain/repository/Admin/BatchRepository"

export interface CreateBatchUseCase {
  invoke(createBatch: Batch): Promise<any>
}

export class CreateBatches implements CreateBatchUseCase {
  private repository: BatchRepository

  constructor(repository: BatchRepository) {
    this.repository = repository
  }

  async invoke(createBatch: Batch) {
    return await this.repository.createBatch(createBatch)
  }
}
