import Alert from "assets/svgs/Alert"
import { useApp } from "core/context/app"
import { format } from "date-fns"
function CoursePausedBanner() {
  const { student } = useApp()
  return (
    <>
      <div className="py-4 bg-[#FFEDED] px-6 mx-6 mt-6 rounded-md">
        <div className="flex items-center space-x-[10px] ">
          <Alert />
          <div className="text-[16px] text-[#DC2626] font-montserrat">
            You have successfully paused your course on{" "}
            {format(new Date(student?.batch_paused_date * 1000), "do MMMM, yyyy")}.
          </div>
        </div>
      </div>
    </>
  )
}

export default CoursePausedBanner
