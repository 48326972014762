export default function ThumbUpFilled(props: any) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1285 0.871411L4.86184 5.13808C4.7368 5.26307 4.66654 5.43261 4.6665 5.60941V13.3334C4.6665 13.7016 4.96498 14.0001 5.33317 14.0001H12.5532C12.8234 14.0002 13.0669 13.8372 13.1698 13.5874L15.2332 8.57741C15.2994 8.41624 15.3334 8.24365 15.3332 8.06941V6.66674C15.3332 5.93036 14.7362 5.33341 13.9998 5.33341H9.73317L10.5018 2.31341C10.6016 1.92209 10.4561 1.50919 10.1332 1.26674L9.5645 0.840077C9.43165 0.74047 9.24575 0.75383 9.1285 0.871411ZM3.33317 6.00012H1.33317C0.964981 6.00012 0.666504 6.29859 0.666504 6.66678V13.3335C0.666504 13.7016 0.964981 14.0001 1.33317 14.0001H3.33317V6.00012Z"
        fill="currentColor"
      />
    </svg>
  )
}
