export default function Wallet({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0002 4.66667H14.0002C14.177 4.66667 14.3465 4.7369 14.4716 4.86193C14.5966 4.98695 14.6668 5.15652 14.6668 5.33333V13.3333C14.6668 13.5101 14.5966 13.6797 14.4716 13.8047C14.3465 13.9298 14.177 14 14.0002 14H2.00016C1.82335 14 1.65378 13.9298 1.52876 13.8047C1.40373 13.6797 1.3335 13.5101 1.3335 13.3333V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2H12.0002V4.66667ZM2.66683 6V12.6667H13.3335V6H2.66683ZM2.66683 3.33333V4.66667H10.6668V3.33333H2.66683ZM10.0002 8.66667H12.0002V10H10.0002V8.66667Z"
        fill="currentColor"
      />
    </svg>
  )
}
