import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { isEmpty } from "core/utils/misc"
import QuestionChamberDataSourceImpl from "data/API/Admin/QuestionChamberDataSourceImpl"
import { QuestionChamberRepositoryImpl } from "data/repository/Admin/QuestionChamberRepositoryImpl"
import { DeleteSubCaseQuestion } from "domain/useCase/Admin/QuestionChamber/DeleteSubCaseQuestion"
import { CountSubCaseQuestions } from "domain/useCase/Admin/QuestionChamber/CountSubCaseQuestions"
import { FilterSubCaseQuestion } from "domain/useCase/Admin/QuestionChamber/FilteredSubCaseQuestions"

export default function QuestionsChamberViewModel() {
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [allQuestions, setAllQuestion] = useState<{ [key: number]: [] }>({
    0: [],
    1: [],
  })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<any>({
    active: [],
    inactive: [],
  })
  const [filters, setFilters] = useState<any>({})

  /**
   * API Use cases *********************************************************
   */
  const countQuestion = new CountSubCaseQuestions(
    new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl())
  )

  const getFilteredSubCaseQuestion = new FilterSubCaseQuestion(
    new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl())
  )

  const deleteSubCaseQuestionUseCase = new DeleteSubCaseQuestion(
    new QuestionChamberRepositoryImpl(new QuestionChamberDataSourceImpl())
  )

  /**
   * Fetch all questions
   * @param data
   */
  const fetchAllQuestions: Function = async (by: any = {}) => {
    const response = await getFilteredSubCaseQuestion.invoke(auth, by)
    const activeQuestions: any = []
    const inactiveQuestion: any = []

    if (!isEmpty(response?.data)) {
      const questions = response.data
      for (let question of questions) {
        if (question.status === "active") {
          activeQuestions.push(question)
        } else if (question.status === "inactive") {
          inactiveQuestion.push(question)
        }
      }
    }

    setAllQuestion({
      ...allQuestions,
      0: activeQuestions,
      1: inactiveQuestion,
    })
  }

  /**
   * Fetch the total questions count
   * @param data
   */
  const fecthQuestionCount: Function = async (filters: any = {}) => {
    const response = await countQuestion.invoke(auth, filters)
    if (!response.success) {
      throw new Error("Failed to load the questions count")
    }
    const caseStudyCount = response.data?.casestudy
    const subjectiveCount = response.data?.subjective
    const totalActive = caseStudyCount.active + subjectiveCount.active
    const totalInactive = caseStudyCount.inactive + subjectiveCount.inactive

    let navigationPagesActive = []
    let navigationPagesInActive = []

    for (let index = 1; index <= Math.ceil(totalActive / 10); index++) {
      navigationPagesActive.push(index)
    }

    for (let index = 1; index <= Math.ceil(totalInactive / 10); index++) {
      navigationPagesInActive.push(index)
    }

    setTotalPages({
      ...totalPages,
      active: navigationPagesActive,
      inactive: navigationPagesInActive,
    })
  }

  const loadQuestionsData: Function = async (filters: {}) => {
    setPageLoading(true)
    try {
      await Promise.all([fetchAllQuestions({ ...filters, page: currentPage, limit: 10 }), fecthQuestionCount({})])
    } catch (error: any) {
      changeToastDetails(STR_FAILURE, genError(error?.message, "Failed to load the questions data"))
      changeToastVisibility(true)
    }
    setPageLoading(false)
  }

  const handelFiltersChange: Function = async (e: any, name: string) => {
    setFilters({
      ...filters,
      [name]: e.target.value,
    })
  }

  const filterQuestions: Function = async () => {
    await loadQuestionsData(filters)
  }

  const clearFilters: Function = async () => {
    setFilters({})
    await loadQuestionsData({})
  }

  /**
   * Add new question
   */
  const AddQuestion: Function = () => {
    navigate("add")
  }

  // duplicate the question
  const duplicateQuestion: Function = (id: string) => {
    navigate(`duplicate/${id}`)
  }

  // edit the question
  const editQuestion: Function = (id: string) => {
    navigate(`edit/${id}`)
  }

  // delete the question
  const onDeleteQuestion: Function = async (id: string, questionType: string) => {
    if (isEmpty(questionType)) return

    setPageLoading(true)
    const response = await deleteSubCaseQuestionUseCase.invoke(auth, { id })

    setPageLoading(false)

    if (!response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Failed to delete the Question")
      return
    }

    loadQuestionsData({})
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Question deleted")
  }

  return {
    pageLoading,
    toast,
    changeToastVisibility,
    AddQuestion,
    duplicateQuestion,
    editQuestion,
    onDeleteQuestion,
    allQuestions,
    setAllQuestion,
    filterQuestions,
    clearFilters,
    filters,
    handelFiltersChange,
    currentPage,
    setCurrentPage,
    totalPages,
    loadQuestionsData,
  }
}
