import { Auth } from "domain/model/Auth"
import MastersDataSource from "data/dataSource/Student/MastersDataSource"
import MastersRepository from "domain/repository/Student/MastersRepository"

export class MastersRepositoryImpl implements MastersRepository {
  private dataSource: MastersDataSource

  constructor(dataSource: MastersDataSource) {
    this.dataSource = dataSource
  }

  async checkBatchForMasters(auth: Auth): Promise<Auth> {
    return this.dataSource.checkBatchForMasters(auth)
  }

  async registerMastersProgram(auth: Auth): Promise<Auth> {
    return this.dataSource.registerMastersProgram(auth)
  }
}
