import { StoreDataSource } from "data/dataSource/Student/StoreDataSource"
import { Auth } from "domain/model/Auth"
import { StoreRepository } from "domain/repository/Student/StoreRepository"

export class StoreRepositoryImpl implements StoreRepository {
  private dataSource: StoreDataSource

  constructor(dataSource: StoreDataSource) {
    this.dataSource = dataSource
  }

  async getStoreItems(auth: Auth): Promise<any> {
    return this.dataSource.getStoreItems(auth)
  }

  async getStudentAllAddresses(auth: Auth): Promise<any> {
    return this.dataSource.getStudentAllAddresses(auth)
  }

  async addStudentAddress(auth: Auth, address: any): Promise<any> {
    return this.dataSource.addStudentAddress(auth, address)
  }

  async updateStudentAddress(auth: Auth, address: any): Promise<any> {
    return this.dataSource.updateStudentAddress(auth, address)
  }

  async deleteStudentAddress(auth: Auth, address_id: any): Promise<any> {
    return this.dataSource.deleteStudentAddress(auth, address_id)
  }

  async placeOrder(auth: Auth, product_id: any, address_id: any): Promise<any> {
    return this.dataSource.placeOrder(auth, product_id, address_id)
  }

  async orderHistory(auth: Auth): Promise<any> {
    return this.dataSource.orderHistory(auth)
  }

  async orderStatus(auth: Auth, order_id: any): Promise<any> {
    return this.dataSource.orderStatus(auth, order_id)
  }
}
