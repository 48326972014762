import { cn } from "core/lib/utils"

export default function IconButton({ icon, text, onClick, className }: any) {
  return (
    <button
      className={cn(
        "flex items-center justify-center gap-1 px-4 py-2 font-medium text-white rounded-md focus:outline-none",
        "",
        className
      )}
      onClick={onClick}
    >
      {icon}
      <span>{text}</span>
    </button>
  )
}
