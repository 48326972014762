import { Auth } from "domain/model/Auth"
import AuthDataSource from "data/dataSource/Common/AuthDataSource"
import { API_FREE_SIGNUP, API_V4_RESET, LOGIN, TOKEN_REFRESH } from "core/constants/strings"
import { User } from "domain/model/User"
import pn from "core/utils/pn"
import { Server, postAPI } from "core/utils/axios"

const server = new Server()

export default class AuthAPIDataSourceImpl implements AuthDataSource {
  async login(user: User): Promise<any> {
    try {
      const response = await server.post(pn(LOGIN), user)
      return response
    } catch (error) {
      return error
    }
  }

  async resetPassword(email: string) {
    try {
      const response = await postAPI(pn(API_V4_RESET), { email: email })
      return response
    } catch (error) {
      return error
    }
  }

  async signUp(user: User) {
    try {
      const response = await postAPI(pn(API_FREE_SIGNUP), user)
      return response
    } catch (error) {
      return error
    }
  }

  async tokenRefresh(refresh_token: string) {
    return await server.post(pn(TOKEN_REFRESH), { refresh_token })
  }
}
