import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface BookMentorSessionUseCase {
  invoke(auth: Auth, date: string, start: string, end: string, session_id: string, mentor_id: string): Promise<any>
}

export class BookMentorSession implements BookMentorSessionUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    date: string,
    start: string,
    end: string,
    session_id: string,
    mentor_id: string
  ): Promise<any> {
    return this.repository.bookMentorSession(auth, date, start, end, session_id, mentor_id)
  }
}
